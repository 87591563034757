import axios from 'axios';

//Profesores matriculados al AA
export const getTeachersAAServices = async (repositorio_partner_id) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/academic_advisor/teachers/${repositorio_partner_id}`;
    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
};
