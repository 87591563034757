import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getSubmissionByUser } from "../../redux/api/studyPlan"

const useMyTasks = () => {

    const { user } = useSelector(state => state.auth)
    const { repo_id } = user
    
    // STATES
    const [submissions, setSubmissions] = useState(null)
    const [submissionsByOa, setSubmissionsByOa] = useState(null)
    const [assignmentSelected, setAssignmentSelected] = useState(null)
    const [assignment, setAssignment] = useState(null)

    // EFFECTS
    useEffect(() => {
        !submissions && getTasks(repo_id)
        if(Array.isArray(submissions)) {
            getOa(submissions); 
            handleDefaultSelect();
        }
    }, [submissions])
    
    // FUNCTIONS
    const getTasks = async id => {
        const request = await getSubmissionByUser(id)
        if (request.error) {
            console.error(request.error);
            setAssignment('error')
        }
        else{
            setSubmissions(request)            
        }
    }

    const getOa = tasks => {      
        let assignment = []
        tasks.filter(task => {
            const isExist = assignment.find(assignment => assignment?.id === task?.assignment?.id)
            if(!isExist && task?.assignment) assignment = [...assignment, task?.assignment]
        });
        setAssignment(assignment)
    }

    const handleDefaultSelect = () => {
        handleSelectA(submissions.find(item => !!item.assignment))
    }

    const handleSelectA = (id_assig) => {
        const filtered = submissions.filter(task => task?.assignment?.id === id_assig)
        const filteredWithOrder = filtered.map((element, index) => ({
            ...element,
            order: index
        }));
        setSubmissionsByOa(filteredWithOrder)
        setAssignmentSelected(submissions.find(task => task?.assignment?.id === id_assig)?.assignment)
    }


    return { submissions, assignment, assignmentSelected, submissionsByOa, handleSelectA }
}

export default useMyTasks