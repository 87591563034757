import { useEffect, useState } from "react";
import useNotesCourse from "../../../../../../hooks/notesCourse/useNotesCourse";
import { getReviewQuestionsResource, postReviewQuestionsModule } from "../api/ai.services";

const useIA = (vimeoTranscript, getNotes, resources, promptAI, resourceName) => {

    const [loadingResume, setLoadingResume] = useState(false);
    const [summary, setSummary] = useState('');
    const [loadingQuestions, setLoadingQuestions] = useState(null);
    const [questionsError, setQuestionsError] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [typeSelected, setTypeSelected] = useState(null);
    const [showAnswers, setShowAnswers] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const { createNote, LoadingPostNote, errorPost } = useNotesCourse()

    const getSummary = async () => {
        setLoadingResume(true);
        setSummary('');
        setQuestionsError(false);
        try {
            const response = await fetch(
                'https://api.openai.com/v1/chat/completions',
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        messages: [
                            {
                                role: 'user',
                                content:
                                    promptAI +
                                    "Be sure to include HTML tags like <h1>, <h2>, <p>, and <ul> to structure your content. The content must begin with the title of the title of the video which you can get from " + resourceName + " and make it an <h1> yes or yes. Video transcript:" +
                                    vimeoTranscript,
                            },
                        ],
                        model: 'gpt-4o-mini',
                        max_tokens: 1024,
                        temperature: 0.2,
                        top_p: 1,
                        stream: true, // Enable streaming
                    }),
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let done = false;
            let buffer = '';

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                buffer += decoder.decode(value, { stream: true });

                let lines = buffer.split('\n');
                buffer = lines.pop();

                for (let line of lines) {
                    if (line.trim().startsWith('data: ')) {
                        const jsonString = line.trim().substring(6);
                        if (jsonString !== '[DONE]') {
                            try {
                                const parsedChunk = JSON.parse(jsonString);
                                let content = parsedChunk.choices[0]?.delta?.content || '';
                                setSummary(prev => prev + content);
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching summary:', error);
            setSummary('Error al obtener resumen. Inténtalo de nuevo.');
        } finally {
            setLoadingResume(false);
        }
    };

    const clearResponse = () => {
        setSummary('');
        setQuestions([]);
        setTypeSelected(null);
        setShowAnswers(false);
    }

    const generateWithIA = (params) => {
        const actionMap = {
            1: getSummary,
            2: getModuleQuestions,
            3: getResourceQuestions
        };

        const action = actionMap[typeSelected];
        if (action) {
            if (typeSelected === 3 && params) {
                action(params); // Pass params to getResourceQuestions
            } else {
                action();
            }
        }
    }

    // Se filtra por los cursos que contienen transcripción y están habilitados desde el repo para ser accedidos por la IA
    const resourcesWithTranscriptionAndEnabled = resources?.filter(resource => resource?.vimeo_transcript && resource?.enable_chat_ai);
    // Necesito hacer un array solo con enteros que serían los ID de resourcesWithTranscription
    const resourcesIds = resourcesWithTranscriptionAndEnabled?.map(resource => resource.id);

    const getModuleQuestions = async () => {
        setQuestionsError(false)
        setLoadingQuestions(true);
        try {
            const response = await postReviewQuestionsModule(resourcesIds);
            if (!!response?.response?.status && response?.response?.status !== 200) {
                setQuestionsError(true)
            }
            setQuestions(response)
        } catch (e) {
            console.error("Error al obtener preguntas")
            setQuestions([])
        }
        setLoadingQuestions(false);
    }
    
    const getResourceQuestions = async (resourceId) => {
        setQuestionsError(false)
        setLoadingQuestions(true);
        try {
            const response = await getReviewQuestionsResource(resourceId);
            if (!!response?.response?.status && response?.response?.status !== 200) {
                setQuestionsError(true)
            }
            setQuestions(response)
        } catch (e) {
            setQuestions([])
        }

        setLoadingQuestions(false);
    }


    const cleanHTML = (htmlText) => {
        // Reemplaza las etiquetas HTML con un espacio vacío
        const cleanText = htmlText
            .replace(/<[^>]*>/g, '')  // Elimina todas las etiquetas HTML
            .replace(/\\n/g, '\n')    // Reemplaza \n por saltos de línea
            .trim();                  // Elimina espacios en blanco innecesarios

        return cleanText;
    };

    const createNoteHandler = (partnerId, resourceId) => {
        createNote({
            content: cleanHTML(summary),
            partner_id: partnerId,
            resource_id: resourceId,
            video_time: 0,
        });
    }

    useEffect(() => {
        if (errorPost === false) {
            setShowSuccessMessage(true);
            getNotes();
            setTimeout(() => {
                setShowSuccessMessage(false);
            }, 3000); // Mostrar el mensaje de éxito por 3 segundos
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorPost]);

    return {
        loadingResume,
        loadingQuestions,
        questionsError,
        summary,
        questions,
        typeSelected,
        showAnswers,
        LoadingPostNote,
        showSuccessMessage,
        clearResponse,
        generateWithIA,
        setTypeSelected,
        setShowAnswers,
        createNoteHandler
    };

}

export default useIA;