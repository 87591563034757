import { Card } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import TabsComponent from "../../common/TabsComponent";
import ErrorResponse from "../../common/ErrorResponse";
import SimpleLoading from "../../common/SimpleLoading";
import { useSelector } from "react-redux";
import useTeachersAA from "../../../hooks/useTeachersAA";
import StudentsNowAA from "./allStudents/StudentsNowAA";
import TeachersNowAA from "./TeachersNowAA";

const ContactsAcademic = () => {
    const tabs = [
        { label: "Mis alumnos", id: 0 },
        { label: "Profesores", id: 1 },
    ];

    const AARepositorioPartnerId = useSelector(
        (state) => state?.auth?.user?.repositorio_data?.repositorio_id
    );
    const { teachers } = useTeachersAA();
    const [tab, setTab] = useState(0);

    const handleTab = (e, value) => {
        setTab(value);
    };

    //RETURN
    if (teachers === null) {
        return (
            <ContactsWrapper>
                <SimpleLoading />
            </ContactsWrapper>
        );
    }
    if (AARepositorioPartnerId === null || AARepositorioPartnerId === false) {
        return (
            <ContactsWrapper>
                <ErrorResponse />
            </ContactsWrapper>
        );
    }
    return (
        <ContactsWrapper>
            {/* <WrapperTabs>
                    {tabs.map((tab) => (
                        <Title
                            key={tab.id}
                            onClick={() => handleTab(tab.id)}
                            active={tab.id === activeTab ? true : false}
                        >
                            {tab.label}
                        </Title>
                    ))}
                </WrapperTabs> */}
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            <Grid>{tab === 0 ? <StudentsNowAA /> : <TeachersNowAA />}</Grid>
        </ContactsWrapper>
    );
};

export default ContactsAcademic;

const ContactsWrapper = styled(Card)`
    grid-area: contacts;
    display: flex;
    flex-direction: column;
    border-radius: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Grid = styled.div`
    gap: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0.5rem;
    ::-webkit-scrollbar {
        background-color: #f9f9f9;
        border-radius: 6px;
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f9f9f9;
        width: 6px;
        border-radius: 6px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: #eaeaea;
        width: 6px;
    }

    @media (max-width: 375px) {
        padding: 0;
    }
`;

const WrapperTabs = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 1.5rem;
    margin-top: 3rem;
`;

const Title = styled.div`
    cursor: pointer;
    color: ${(p) => (p.active ? "#b31d15" : "#555555")};
    font-weight: 700;
    font-size: 1rem;
    background-color: #fff;
    border: ${(p) => (p.active ? "0.5px solid #f3f3f3" : "none")};
    box-shadow: ${(p) =>
        !!p.active
            ? `0px 3px 5px rgba(0, 0, 0, 0.15), 
        0px 5px 10px rgba(0, 0, 0, 0.06)`
            : "none"};
    border-radius: 5px;
    padding: 8px 14px;
    transition: all 0.3s ease-in-out;

    :hover {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15),
            0px 5px 10px rgba(0, 0, 0, 0.06);
    }
`;
