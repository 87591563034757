import styled from "styled-components";
import React, { useState } from "react";

// Components
import { Text } from "../../../../../../components/common/Texts";
import AchievementCardModal from "./AchievementCardModal";
import { Icon } from "@iconify/react/dist/iconify.js";

const AchievementCard = (props) => {
    const { value } = props;

    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTIONS
    function handleOpenModal() {
        setIsOpen(true);
    }

    function handleCloseModal() {
        setIsOpen(false);
    }

    return (
        <>
            <AchievementCardContainer onClick={handleOpenModal}>
                <IconWrapper>
                    <Icon icon="dashicons:awards" width="24px" height="24px" />
                </IconWrapper>
                <Body>
                    <Text color="#616161" fontSize="12px" fontWeight="400">
                        {value.tipo?.toUpperCase()}: <b>{value.nombre?.toUpperCase()}</b>
                    </Text>
                </Body>
            </AchievementCardContainer>
            <AchievementCardModal
                isOpen={isOpen}
                handleCloseModal={handleCloseModal}
                achievement={value}
            />
        </>
    );
};

export default AchievementCard;

const AchievementCardContainer = styled.div`
    height: 85px !important;
    width: 330px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 1rem;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    border: 1px solid #A8A8A8;
    padding: 1rem;
`;

const IconWrapper = styled.div`
    width: 50px;
    height: 50px;
    border: 1px solid #b31d15;
    background-color: #f1f1f1;
    border-radius: 50%;
    color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Body = styled.div`
    width: 80%;
    text-align: start;
    gap: 0.5rem;
`;