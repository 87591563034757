import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getOrders } from "../../../../redux/api/faculty";
import styled from "styled-components";
import SimpleLoading from "../../../common/SimpleLoading";
import usePurchaseOrder from "../../../../hooks/teacher/usePurchaseOrder";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const ModalPurchaseOrder = (props) => {
    const { open, handleClose, currentNameOrder } = props;

    const { purchaseOrder } = usePurchaseOrder(currentNameOrder, open);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {purchaseOrder === null ? (
                <CustomBox>
                    <SimpleLoading />
                </CustomBox>
            ) : (
                <>
                    {!purchaseOrder ? (
                        <p>No se pudieron cargar ordenes de pago</p>
                    ) : (
                        <CustomBox sx={style}>
                            <Header>
                                <p>
                                    Orden: <span>{purchaseOrder.name}</span>
                                </p>
                            </Header>
                            <Notes>
                                <label>Nota:</label>
                                <p>{purchaseOrder.notes}</p>
                            </Notes>
                            {purchaseOrder?.products?.length > 0 && (
                                <Scroll>
                                    {purchaseOrder.products.map(
                                        (product, index) => (
                                            <ProductsContainer key={index}>
                                                <div>
                                                    <h3>producto</h3>
                                                    <p>{product.product}</p>
                                                </div>
                                                <div>
                                                    <h3>cantidad</h3>
                                                    <p>{product.product_qty}</p>
                                                </div>
                                                <div>
                                                    <h3>Subtotal</h3>
                                                    <p>
                                                        {product.price_subtotal}
                                                    </p>
                                                </div>
                                            </ProductsContainer>
                                        )
                                    )}
                                </Scroll>
                            )}
                        </CustomBox>
                    )}
                </>
            )}
        </Modal>
    );
};

export default ModalPurchaseOrder;

const CustomBox = styled(Box)`
    outlined: none;
    border: none;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const Header = styled.div`
    width: 100%;
    text-align: left;

    p {
        color: #b31d15;
        font-size: 1.1rem;

        span {
            color: #222222;
            font-weight: bold;
        }
    }
`;

const Notes = styled.div`
    padding: 1rem;
    width: calc(100% - 2rem);
    min-height: 200px;
    background-color: #f8f8f8;
    max-height: 350px;
    overflow: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    label {
        color: #b31d15;
        font-size: 1.1rem;
    }

    p {
        color: #222222;
        font-size: 0.9rem;
    }
`;

const Scroll = styled.div`
    width: 100%;
    padding-right: 0.3rem;
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const ProductsContainer = styled.div`
    width: calc(100% - 1rem);
    display: flex;
    gap: 0.5rem;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 0.5rem;
    min-height: 80px;
    max-height: 80px;
    gap: 1rem;
    justify-content: space-between;

    div {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        align-items: center;

        h3 {
            color: #b31d15;
        }
        p {
            color: #222222;
            font-weight: bold;
        }
    }
`;
