import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ProgramTable from "../../molecules/my-progress/ProgramTable";
import { InputAdornment, TextField } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";

const ProgramsModalMain = (props) => {
    const { program } = props;
    // STATE
    const [search, setSearch] = useState("");
    const [values, setValues] = useState(program.courses);

    // EFFECTS
    useEffect(() => {
        setValues(
            program.courses.filter((propsValue) => {
                return propsValue.name
                    .toLowerCase()
                    .includes(search.toLowerCase());
            })
        );
    }, [search, program]);

    // FUNCTIONS
    function handleChange(e) {
        setSearch(e.target.value);
    }

    // RETURN
    return (
        <ProgramsModalMainContainer>
            <Overflow>
                {/** Progreso de carrera */}
                <RowWrapper>
                    <Title>El progreso de tu carrera</Title>
                    <ProgressWrapper>
                        <LinearProgressWrapper>
                            <LinearProgress
                                porcentaje={program.total_progress}
                            />
                        </LinearProgressWrapper>
                        <Percentage>{program.total_progress}%</Percentage>
                    </ProgressWrapper>
                    <CoursesWrapper>
                        {program.from === "sis" && !!program.subjects_qty && (
                            <CourseWrapper>
                                <Quantity>{program.subjects_qty}</Quantity>
                                <CourseText>Cant. asignaturas</CourseText>
                            </CourseWrapper>
                        )}
                        <CourseWrapper>
                            <Quantity>{program.in_progress}</Quantity>
                            <CourseText>Cursos en progreso</CourseText>
                        </CourseWrapper>
                        <CourseWrapper>
                            <Quantity>{program.finished}</Quantity>
                            <CourseText>Cursos terminados</CourseText>
                        </CourseWrapper>
                        <CourseWrapper>
                            <Quantity>{program.pending}</Quantity>
                            <CourseText>Cursos pendientes</CourseText>
                        </CourseWrapper>
                    </CoursesWrapper>
                </RowWrapper>
                {/** Estado academico */}
                <RowWrapper>
                    <DateWrapper>
                        <p>
                            Fecha de alta: <span>{program.discharge_date}</span>
                        </p>
                    </DateWrapper>
                </RowWrapper>
                <RowWrapper>
                    <Title>Estado académico</Title>
                    {/* <FakeTextField
                        value={search}
                        onChange={handleChange}
                        size="small"
                        label=""
                        placeholder="Buscar..."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchRounded />
                                </InputAdornment>
                            ),
                        }}
                    /> */}
                    <ProgramTable cursos={values} />
                </RowWrapper>
            </Overflow>
        </ProgramsModalMainContainer>
    );
};

export default ProgramsModalMain;

const ProgramsModalMainContainer = styled.div`
    height: 100%;
    position: relative;
`;

const Overflow = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding-right: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Title = styled.h2`
    font-size: 1.25rem;
    font-weight: 700;
    color: #222222;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
`;

const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 10px;
    background-color: #eaeaea;
`;

const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(props) => `${props.porcentaje}%`};
    height: 10px;
    background-color: #3bd39c;
`;

const Percentage = styled.span`
    font-size: 1.5rem;
    color: #3bd39c;
    flex-wrap: wrap;
`;

const CoursesWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
`;

const CourseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 100%;
`;

const Quantity = styled.span`
    font-size: 1.1rem;
    text-align: center;
    border-radius: 5px 5px 0 0;
    color: #616161;
    background-color: #eaeaea;
    padding: 0.25rem 0;
`;

const CourseText = styled.span`
    font-size: 0.9rem;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0 0 5px 5px;
    color: #ffffff;
    background-color: #ababab;
    flex-wrap: nowrap;
    padding: 0.25rem 0;
`;

const FakeTextField = styled(TextField)`
    width: max-content;
`;

const DateWrapper = styled.div`
    display: flex;
    gap: 0.3rem;
    display: flex;
    align-items: center;
    right: 0.8rem;
    top: 1.3rem;
    z-index: 4;
    padding: 0.2rem;
    background-color: #3bd39c;
    border-radius: 5px;
    width: fit-content;

    p {
        color: #fff;
        font-size: 0.7rem;
    }
`;
