import React, { useState, useEffect } from "react";
import styled from "styled-components";

import GridResource from "../../common/GridResource";
import { useDispatch, useSelector } from "react-redux";
import TryAgain from "../../common/TryAgain";
import { workshopsActions } from "../../../redux/actions";
import SharedResource from "../../common/SharedResource";
import WorkshopCard from "../../ui/molecules/campus/WorkshopCard";
import EmptyGridResource from "../../common/EmptyGridResource";
import WorkshopCardSkeleton from "../../common/WorkshopCardSkeleton";

const WorkshopsMain = () => {
    // REDUX
    const dispatch = useDispatch();
    const workshops = useSelector((state) => state.workshops.workshops);
    const loadingList = useSelector((state) => state.workshops.loadingList);
    const errorList = useSelector((state) => state.workshops.errorList);

    // STATE
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sharedItem, setSharedItem] = useState(null);

    const emptyItems = new Array(6).fill(null);

    // EFFECTS
    useEffect(() => {
        if (workshops === null) {
            dispatch(workshopsActions.getWorkshopsRequest());
        }
    }, [workshops]);

    // FUNCTIONS
    function handleOpenModal(id, nombre_publicacion) {
        setModalIsOpen(true);
        setSharedItem({
            lp_type: "workshop",
            type: "objeto_aprendizaje",
            id,
            nombre_publicacion,
        });
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    function handleTryAgain() {
        dispatch(workshopsActions.getWorkshopsRequest());
    }

    // RETURN
    if (errorList !== null) {
        return <TryAgain padding="6rem" handleTryAgain={handleTryAgain} />;
    }

    return (
        <>
            {loadingList || workshops == null ? (
                <Container>
                    <EmptyGridResource
                        propsValues={emptyItems}
                        Card={WorkshopCardSkeleton}
                    />
                </Container>
            ) : (
                <Container>
                    <SharedResource
                        modalIsOpen={modalIsOpen}
                        handleCloseModal={handleCloseModal}
                        sharedItem={sharedItem}
                    />
                    <GridResource
                        propsValues={workshops}
                        Card={WorkshopCard}
                        handleOpenModal={handleOpenModal}
                        type={"workshop"}
                    />
                </Container>
            )}
        </>
    );
};

export default WorkshopsMain;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;
