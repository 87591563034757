import React from 'react';
import styled from 'styled-components';
import Text from '../../../common/Text';
import { Link } from 'react-router-dom';

const NextCourseCard = () => {
    return (
        <Container>
            <Date>
                <Text
                    variant={'h5'}
                    color={'primary'}
                    style={{ fontWeight: `${700}` }}
                    align={'center'}
                >
                    20/10/2021
                </Text>
                <Text
                    variant={'h6'}
                    color={'primary'}
                    style={{ fontWeight: `${400}` }}
                    align={'center'}
                >
                    ¡Termina en 20 dias!
                </Text>
            </Date>
            <Description>
                <Text
                    variant={'subtitle1'}
                    color={'textPrimary'}
                    style={{ fontWeight: `${400}` }}
                    align={'left'}
                >
                    Estrategia corporativa
                </Text>
                <Text
                    variant={'body2'}
                    color={'textSecondary'}
                    style={{ fontWeight: `${400}` }}
                    align={'left'}
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi posuere mi volutpat rutrum tincidunt. Aliquam
                    vulputate velit eget posuere semper.
                </Text>
            </Description>
            <LinkFake to="auth/registration">¡Accede ahora!</LinkFake>
        </Container>
    );
};

export default NextCourseCard;

const Container = styled.div`
    margin: 1rem;
    padding: 0.5rem;
    max-width: 300px;
`;

const Date = styled.div`
    background-color: #f5e5e4;
    justify-content: center;
    text-align: center;
    align-content: center;
    padding: 1rem;
`;

const Description = styled.div`
    justify-content: left;
    text-align: left;
    align-content: center;
    padding: 0.5rem;
`;

const LinkFake = styled(Link)`
    background-color: #b31d15;
    color: #ffffff;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-weight: 700;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    :hover {
        background-color: #921009;
        color: #ffffff;
    }
`;
