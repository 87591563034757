import axios from "axios";

export const enrollmentPause = async (body) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/change_enrollment_status?partner_id=${body.partnerId}&programa_id=${body.programId}&asignatura_id=${body.assignment}&estado=${body.state}`;
    try {
        const response = await axios.post(URL);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};
