import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { postGraduate } from "../../redux/api/teacher/teacher";

const useGraduateStudent = () => {
    const { id } = useParams();

    const handleGraduate = async (repoId, finalGrade, enrollmentId) => {
        try {
            await postGraduate({
                enrollment_id: enrollmentId,
                grade: parseInt(finalGrade),
            });
            return true;
        } catch (error) {
            return false;
        }
    };

    return { handleGraduate };
};

export default useGraduateStudent;
