import styled from "styled-components";
import moment from "moment";
import React from "react";

// Components
import { Icon } from '@iconify/react';

const CardHistorySubmission = (props) => {
    const { 
        submission: {
            id,
            order,
            grade,
            create_date
        }, 
        idAssignment,
        last
    } = props;

    // STATES
    const convertToLocalTime = (date, timezone) => {
        // Convierte la fecha y hora del evento a la zona horaria especificada
        const eventTime = moment.tz(date, timezone);

        // Convierte la hora del evento a la hora local
        const localTime = eventTime.clone().tz(moment.tz.guess());

        return localTime.format('DD/MM/YYYY HH:mm:ss');
    };

    const formatDate = convertToLocalTime(create_date, "UTC");

    const link = `{"id_task":${id.toString()},"id_assignment":${idAssignment.toString()}}`

    const base64Link = Buffer.from(link).toString('base64');

    return (
        <Card>
            <Header>
                <Chip>{order}° Entrega</Chip>
                <Date>Entregado el {formatDate}</Date>
            </Header>
            <Body>
                <Note>
                    <p>
                        <span>{grade}</span> / 100
                    </p>
                </Note>
                <CustomButton
                    href={`/profesor/calificador/${base64Link}${!last ? "?old" : ""}`}
                    target="_blank"
                >
                    Ver entrega <Icon icon="ph:arrow-right-light" width="24px" height="24px" />
                </CustomButton>
            </Body>
        </Card>
    );
};

export default CardHistorySubmission;

const Card = styled.div`
    width: 100%;
    padding: 1.1rem 1.1rem 1.1rem calc(1.1rem + 9px);
    box-shadow: 0px 12px 24px -15px rgba(0, 0, 0, 0.1),
        0px 0px 10px -6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    :before {
        content: "";
        position: absolute;
        width: 9px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #b31d15;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 1rem;
    align-items: center;
`;
const Chip = styled.div`
    color: #b31d15;
    background-color: #fce9e8;
    padding: 0.4rem;
    font-size: 14px;
    border-radius: 5px;
    font-weight: bold;
`;
const Date = styled.div`
    color: #222222;
    font-size: 12px;
    width: 60%;
`;
const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Note = styled.div`
    p {
        color: #a8a8a8;
        font-size: 24px;

        span {
            color: #616161;
            font-weight: bold;
            font-size: 24px;
        }
    }
`;
const CustomButton = styled.a`
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-weight: bold;
    color: #616161;
    font-size: 14px;
    cursor: pointer;
`;
