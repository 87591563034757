import React from 'react';
import styled from 'styled-components';
import RatingSummary from './statistics/RatingSummary';
import StatisticsSummary from './statistics/StatisticsSummary';

const AverageWrapper = (props) => {
    const { review } = props;
    return (
        <AverageBody>
            <RatingSummary review={review} />

            <StatisticsSummary review={review} />
        </AverageBody>
    );
};

export default AverageWrapper;

const AverageBody = styled.div`
    min-height: 120px;
    width: 95%;
    border-radius: 8px;
    margin-bottom: 1rem;
    display: flex;
`;
