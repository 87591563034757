import { useState, useEffect } from 'react';
import { getStudents } from '../../redux/api/teacher';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../../utils/snackStyles';

const useRankingByCourse = (id) => {

    const [openSnackbar] = useSnackbar(optionsStyle);

    const [students, setStudents] = useState(null);

    useEffect(() => {
        !students && getStudentsService(id)
    }, [students]);


    const getStudentsService = async () => {
        const request = await getStudents(id);
        if (request.error) {
            // openSnackbar('¡No se pudieron cargar los estudiantes!');
            return;
        }
        sanitizerRanking(request.students)
        // setStudentsUpDate(request);
    };

    const sanitizerRanking = (values) => {
        const ranking = values?.map(student => {
            const submission = student.activities.filter(act => !!act.submission && !act?.area?.toLowerCase()?.includes('certificado')).length
            const totalActivities = student.activities.filter(act => !act?.area?.toLowerCase()?.includes('certificado')).length
            return {
                id: student.id,
                name: student.name,
                image: student.image_url,
                progress: ((submission * 100) / totalActivities)
            }
        }).sort((a, b) => b.progress - a.progress)
        setStudents(ranking);
    }

    return {
        students,
    };
};

export default useRankingByCourse;
