import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStudentsCanvas } from '../../redux/api/students';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../../utils/snackStyles';

const useStudentsCanvas = () => {
    const [students, setStudents] = useState(null);
    const [initialStudents, setInitialStudents] = useState(null);

    const { professor_sis_id } = useSelector(state => state.auth.user.repositorio_data)

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!students && !!professor_sis_id) getStudents();
    }, [students]);

    const getStudents = async () => {
        const request = await getStudentsCanvas(professor_sis_id);
        if (request.error) {
            openSnackbar('¡No se pudieron cargar los estudiantes!');
            setStudents(false);
            return;
        }
        setStudents(sanitizerData(request));
        setInitialStudents(sanitizerData(request));
    };

    const handleStudents = (newStudents) => {
        setStudents(newStudents);
    };

    const sanitizerData = array => {
        return array.map(student => ({
            id: student.sis_id,
            sis_id: student.sis_id,
            email: student.email,
            name: student.nombre
        }))
    }

    return { initialStudents, studentsCanvas: students, handleStudents };
};

export default useStudentsCanvas;
