import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import InputLabel from '@mui/material/InputLabel';
// import MenuItem from "@mui/material/MenuItem";
import FormControl from '@mui/material/FormControl';
// import Select from "@mui/material/Select";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { FilterListRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { utilsActions } from '../../../redux/actions';
import SimpleLoading from '../../common/SimpleLoading';
import Button from '../../ui/atoms/Button';
import { TextField } from '@mui/material';
import { checkRoles } from '../../../helpers/roles.helper';

const Filters = (props) => {
    const { sendFilters } = props;
    const is_student = checkRoles('Admin') || checkRoles('alumno');

    const countries = useSelector((state) => state.utils.countries);
    const programs = useSelector((state) => state.utils.programsOptions);
    const loadingCountries = useSelector(
        (state) => state.utils.loadingCountries
    );
    const loadingPrograms = useSelector(
        (state) => state.utils.loadingProgramsOptions
    );
    const dispatch = useDispatch();

    ////////// States
    const [form, setForm] = useState({
        name: '',
        country_id: 0,
        programa_id: 0,
        empresa: '',
        cargo: '',
        intereses_ids: []
    });
    const [country, setCountry] = useState(null);
    const [programOption, setProgramOption] = useState(null);
    const { name, empresa, cargo } = form;
    ////////// Effects
    useEffect(() => {
        if (countries === null) {
            dispatch(utilsActions.getCountriesRequest());
        }
        if (is_student && programs === null) {
            dispatch(utilsActions.getProgramsOptionsRequest());
        }
    }, []);

    useEffect(() => {
        handleSubmit();
    }, [form.country_id, form.programa_id]);

    ///////// Methods
    function handleFiltersChange(event) {
        event.preventDefault();
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    }

    function handleChangeAutoCompleteCountry(e, newValue) {
        setCountry(newValue);
        if (newValue) {
            setForm({
                ...form,
                country_id: newValue.id
            });
        } else {
            setForm({
                ...form,
                country_id: 0
            });
        }
    }

    function handleChangeAutoCompleteProgram(e, newValue) {
        setProgramOption(newValue);
        if (newValue) {
            setForm({
                ...form,
                programa_id: newValue.id
            });
        } else {
            setForm({
                ...form,
                programa_id: 0
            });
        }
    }

    function clearAutoComplete() {
        // console.log(country);
    }

    function handleSubmit() {
        sendFilters({ ...form });
    }

    function handleKeypress(event) {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }

    // RETURN
    if (
        countries === null ||
        loadingCountries ||
        (is_student && (programs === null || loadingPrograms))
    ) {
        return (
            <FiltersContainer>
                <SimpleLoading padding="4rem" />
            </FiltersContainer>
        );
    }

    return (
        <FiltersContainer>
            <HeaderWrapper>
                <FilterListRounded fontSize="small" />
                <Title>Filtrar</Title>
            </HeaderWrapper>
            <FormFilters>
                {/* NOMBRE */}
                <FormControl>
                    <InputLabel>Nombre</InputLabel>
                    <Input
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleFiltersChange}
                        onKeyPress={handleKeypress}
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon color="secondary" />
                            </InputAdornment>
                        }
                    />
                </FormControl>

                {/* PROGRAMA - validar matriculación en asignatura! */}
                {is_student ? (
                    <Autocomplete
                        clearOnEscape
                        value={programOption}
                        onChange={handleChangeAutoCompleteProgram}
                        options={programs}
                        getOptionLabel={(option) => option.name}
                        onInputChange={clearAutoComplete}
                        renderInput={(params) => (
                            <TextField {...params} label="Programa" />
                        )}
                    />
                ) : (
                    ''
                )}

                {/* Nacionalidad */}
                <Autocomplete
                    clearOnEscape
                    value={country}
                    onChange={handleChangeAutoCompleteCountry}
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    onInputChange={clearAutoComplete}
                    renderInput={(params) => (
                        <TextField {...params} label="Nacionalidad" />
                    )}
                />

                {/* ROL */}
                {/* <FormControl>
                    <InputLabel id='select-rol-label'>Rol</InputLabel>
                    <Select labelId='select-rol-label' id='select-rol'>
                        <MenuItem value={'Alumno'}>Alumno</MenuItem>
                        <MenuItem value={'Visita'}>Visita</MenuItem>
                        <MenuItem value={'Profesor'}>Profesor</MenuItem>
                    </Select>
                </FormControl> */}

                {/* EMPRESA */}
                <FormControl>
                    <InputLabel>Empresa</InputLabel>
                    <Input
                        type="text"
                        name="empresa"
                        value={empresa}
                        onChange={handleFiltersChange}
                        onKeyPress={handleKeypress}
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon color="secondary" />
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {/* CARGO */}
                <FormControl>
                    <InputLabel>Cargo</InputLabel>
                    <Input
                        type="text"
                        name="cargo"
                        value={cargo}
                        onChange={handleFiltersChange}
                        onKeyPress={handleKeypress}
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon color="secondary" />
                            </InputAdornment>
                        }
                    />
                </FormControl>

                {/* INTERESES */}
                {/* <FormControl component='fieldset'>
                    <FormLabel component='legend'>Intereses</FormLabel>
                    <FormGroup>
                        <CheckBoxes>
                            <FormControlLabel control={<Checkbox name='1' />} label='Blockchain' style={{ margin: 0, padding: 0 }} />
                            <FormControlLabel control={<Checkbox name='1' />} label='Blockchain' style={{ margin: 0, padding: 0 }} />
                            <FormControlLabel control={<Checkbox name='1' />} label='Blockchain' style={{ margin: 0, padding: 0 }} />
                            <FormControlLabel control={<Checkbox name='1' />} label='Blockchain' style={{ margin: 0, padding: 0 }} />
                            <FormControlLabel control={<Checkbox name='1' />} label='Blockchain' style={{ margin: 0, padding: 0 }} />
                        </CheckBoxes>
                    </FormGroup>
                </FormControl> */}

                {/* BUTTON */}
                <Button
                    color="primary"
                    variant="outlined"
                    label="Filtrar"
                    handleClick={handleSubmit}
                />
            </FormFilters>
        </FiltersContainer>
    );
};

export default Filters;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 20px; */
    color: #bfbfbf;
    border-radius: 5px;
    width: calc(100% - 4rem);
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;

const Title = styled.h1`
    font-size: 1.2rem;
    font-weight: 700;
`;

const FormFilters = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

const CheckBoxes = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    * {
        /* margin: 0; */
        padding: 0;
    }
`;
