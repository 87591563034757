import { useState } from "react";
import styled from "styled-components";
import {
  getDateFullYear,
  getDateNumber,
  getMonthName,
} from "../../../../utils/dates";
import { Text } from "../../../../components/common/Texts";
import LazyImg from "../../../../modules/acropolisCommon/components/LazyImg";
import InscriptionModal from "./InscriptionModal";
import { Button } from "@mui/material";
import { PlayArrow, AccessTime, FmdGood } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import * as base64 from "nodejs-base64-encode";
import { postEvets } from "../../../../redux/api/events/events";
import { postAssignmentPrograms } from "../../../../redux/api/studyPlan";
import { useSelector } from "react-redux";
import GenericSnackbar from "../../../../components/common/GenericSnackbar";
import { partner } from "../../../../redux/reducers/chat";

const CampCard = (props) => {
  const { camp, blocked, blockLearningObject } = props;

  const history = useHistory();
  const { partner_id, repo_id } = useSelector((state) => state.auth.user);

  const [open, setOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);

  const handleRedirection = (id) => {
    const parsedId = Number(id);
    const hash = base64.encode(parsedId + "", "base64");
    history.push(`/curso/${hash}`);
  };

  const handleModal = (e, index) => {
    e.stopPropagation();
    setOpen(!open);
    setSelectedModal(index);
    setMessage(false);
  };

  const handleInscription = async (e, eventId) => {
    setLoading(true);
    e.stopPropagation();
    const request = await postEvets({
      partner_id: partner_id,
      event_id: Number(eventId),
      type: null,
    });

    if (!!request.error) {
      setMessage("¡Ya se encuentra registrado en este evento!");
      setLoading(false);
      return;
    }

    setMessage("¡Se inscribió exitosamente!");
    setLoading(false);
  };

  const handleOAenrollment = async (e, oaId) => {
    setLoading(true);
    e.stopPropagation();
    const request = await postAssignmentPrograms(repo_id, {
      type: "oa",
      assignment: Number(oaId),
      project100: "true",
    });

    if (!!request.error) {
      console.log("Ocurrió un error");
      setLoading(false);
      return;
    }

    handleRedirection(oaId);
    setLoading(false);
  };

  const toggleCampCards = (props) => {
    const { learningObjects, arenaCamps, impactCamps } = props;

    const type = Object.keys(props)[0];

    const cases = {
      learningObjects: (
        <Container>
          {learningObjects?.map((object, i) => (
            <Wrapper key={object?.title}>
              <InscriptionModal
                open={open && i === selectedModal}
                handleClose={handleModal}
                campEvent={object?.title}
                campType="TrainingCamp"
                eventId={object?.id}
                handlePost={(e) => handleOAenrollment(e, object?.repoId)}
                loading={loading}
              />
              <ModalityWrapper>
                <Text color="#ACACAC" fontSize="10px" fontWeight="700">
                  ONLINE
                </Text>
              </ModalityWrapper>
              <Header>
                <Text color="#B31D15" fontSize="16px" fontWeight="700">
                  Train Camp
                </Text>
              </Header>
              <Body>
                <ThumbnailContainer>
                  <PlayIcon />
                  <LazyImg
                    src={object?.image}
                    width="100%"
                    borderRadius="20px 20px 0 0"
                  />
                  <ImageDescription>
                    <Text color="#000000" fontSize="12px" fontWeight="700">
                      {object?.title}
                    </Text>
                  </ImageDescription>
                </ThumbnailContainer>
              </Body>
              <Footer>
                <ContentWapper>
                  <CourseButton
                    // onClick={(e) => handleOAenrollment(e, object?.repoId)}
                    onClick={(e) => handleModal(e, i)}
                    // disabled={!!blocked ? blockLearningObject : !blocked}
                    color="tertiary"
                    variant="contained"
                  >
                    Ver ahora
                  </CourseButton>
                </ContentWapper>
              </Footer>
            </Wrapper>
          ))}
        </Container>
      ),

      arenaCamps: (
        <Container>
          {arenaCamps?.map((arena, i) => (
            <Wrapper key={i} type={"arenaCamp"} index={i}>
              <InscriptionModal
                open={open && i === selectedModal}
                handleClose={handleModal}
                campEvent={arena?.title}
                campType="ArenaCamp"
                eventId={arena?.id}
                handlePost={handleInscription}
                loading={loading}
                message={message}
              />
              <ModalityWrapper>
                <Text color="#ACACAC" fontSize="10px" fontWeight="700">
                  PRESENCIAL
                </Text>
              </ModalityWrapper>
              <Header>
                <Text color="#B31D15" fontSize="16px" fontWeight="700">
                  Arena Camp
                </Text>
              </Header>
              <Body>
                <InfoWrapper>
                  <DateWrapper>
                    <Date>
                      <Text color="#ffffff" fontSize="14px" fontWeight="700">
                        {getDateNumber(arena?.date)}
                      </Text>
                    </Date>
                    <Text color="#616161" fontSize="14px" fontWeight="700">
                      {getMonthName(arena?.date)} {getDateFullYear(arena?.date)}
                    </Text>
                  </DateWrapper>
                  <TimeWrapper>
                    <AccessTime />
                    <Text color="#616161" fontSize="12px" fontWeight="400">
                      {arena?.schedule}
                    </Text>
                  </TimeWrapper>
                  <LocationWrapper>
                    <FmdGood />
                    <Text color="#616161" fontSize="12px" fontWeight="400">
                      {arena?.eventLocation}
                    </Text>
                  </LocationWrapper>
                </InfoWrapper>
              </Body>
              <Footer>
                <ContentWapper>
                  {/* <Text color="#616161" fontSize="14px" fontWeight="500">
                    Clase presencial | Sincrónico
                  </Text> */}
                  <CourseButton
                    onClick={(e) => handleModal(e, i)}
                    // disabled={!!blocked ? blockLearningObject : !blocked}
                    color="tertiary"
                    variant="contained"
                  >
                    Inscribirme
                  </CourseButton>
                </ContentWapper>
              </Footer>
            </Wrapper>
          ))}
        </Container>
      ),

      impactCamps: (
        <Container>
          {impactCamps?.map((impactCamp, i) => (
            <Wrapper key={i}>
              <InscriptionModal
                open={open && i === selectedModal}
                handleClose={handleModal}
                campEvent={impactCamp?.title}
                campType="ImpactCamp"
                eventId={impactCamp?.id}
                handlePost={handleInscription}
                loading={loading}
                message={message}
              />
              <ModalityWrapper>
                <Text color="#ACACAC" fontSize="10px" fontWeight="700">
                  PRESENCIAL
                </Text>
              </ModalityWrapper>
              <Header>
                <Text color="#B31D15" fontSize="16px" fontWeight="700">
                  Impact Camp
                </Text>
              </Header>
              <Body>
                <InfoWrapper>
                  <DateWrapper>
                    <Date>
                      <Text color="#ffffff" fontSize="14px" fontWeight="700">
                        {getDateNumber(impactCamp?.date)}
                      </Text>
                    </Date>
                    <Text color="#616161" fontSize="14px" fontWeight="700">
                      {getMonthName(impactCamp?.date)}{" "}
                      {getDateFullYear(impactCamp?.date)}
                    </Text>
                  </DateWrapper>
                  <TimeWrapper>
                    <AccessTime />
                    <Text color="#616161" fontSize="12px" fontWeight="400">
                      {impactCamp?.schedule}
                    </Text>
                  </TimeWrapper>
                  <LocationWrapper>
                    <FmdGood />
                    <Text color="#616161" fontSize="12px" fontWeight="400">
                      {impactCamp?.eventLocation}
                    </Text>
                  </LocationWrapper>
                </InfoWrapper>
              </Body>
              <Footer>
                <ContentWapper>
                  {/* <Text color="#616161" fontSize="14px" fontWeight="500">
                    Evento presencial | Sincrónico
                  </Text> */}
                  <CourseButton
                    onClick={(e) => handleModal(e, i)}
                    // disabled={!!blocked ? blockLearningObject : !blocked}
                    color="tertiary"
                    variant="contained"
                  >
                    Inscribirme
                  </CourseButton>
                </ContentWapper>
              </Footer>
            </Wrapper>
          ))}
        </Container>
      ),
    };

    return cases[type] || cases["impactCamp"];
  };

  return <>{toggleCampCards(camp)}</>;
};

export default CampCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5.5rem;
`;

const Wrapper = styled.div`
  position: relative;
  width: 200px;
  height: 263px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  ${(p) =>
    p.type === "arenaCamp" &&
    p.index === 1 &&
    `
  &::after {
    position: absolute;
    content: " ";
    width: calc(100% + 28rem);
    height: 1px;
    background: #B31D15;
    margin: auto;
    top: -2.9rem;
    right: 0;
    left: -14rem;
  }
  `}
`;

const ModalityWrapper = styled.div`
  position: absolute;
  margin: auto;
  top: -1.3rem;
  right: 0;
  left: 0;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.7rem;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
`;

const Footer = styled.div``;

const ThumbnailContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 155px;
  height: 147px;
  border-radius: 20px;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.04),
    0px 8px 8px -4px rgba(24, 39, 75, 0.04);
`;

const PlayIcon = styled(PlayArrow)`
  position: absolute;
  color: #ffffff;
  width: 30px;
  height: 30px;
  margin: auto;
  right: 0;
  left: 0;
  top: 0;
  bottom: 34px;
  z-index: 2;
`;

const ImageDescription = styled.div`
  box-sizing: border-box;
  text-align: center;
  display: grid;
  padding: 0.5rem;
  place-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0 0 20px 20px;
  background-color: #ffffff;
`;

const ContentWapper = styled.div`
  height: 50px;
  display: grid;
  place-items: center;
  background-color: #f1f1f1;
  border-radius: 0 0 20px 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  height: 147px;
  justify-content: space-around;
  flex-direction: column;
  width: calc(100% - 2rem);
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

const Date = styled.div`
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #616161;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;

  svg {
    width: 16px;
    height: 16px;
    color: #a8a8a8;
  }
`;

const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;

  svg {
    width: 16px;
    height: 16px;
    color: #a8a8a8;
  }
`;

const CourseButton = styled(Button)`
  width: 160px;
  height: 34px;
  border-radius: 50px;
`;
