import React, { useState } from "react";
import styled from "styled-components";
import TabsComponent from "../../../common/TabsComponent";
import SubjectsRepo from "../../../templates/academic/oneStudent/SubjectsRepo";
import SimpleLoading from "../../../common/SimpleLoading";
import ProgramsRepo from "../../../templates/academic/oneStudent/ProgramsRepo";

const StudentTabsRepo = (props) => {
    const {
        subjectsRepo,
        loadingSubjects,
        repoId,
        programsRepo,
        loadingPrograms,
    } = props;

    const tabs = [
        { label: `Asignaturas`, id: 0 },
        { label: `Programas`, id: 1 },
    ];

    const [tab, setTab] = useState(0);

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
    };

    function ToggleMain(props) {
        const { tab } = props;

        const cases = {
            0: (
                <SubjectsRepo
                    subjectsRepo={subjectsRepo}
                    loadingSubjects={loadingSubjects}
                    repoId={repoId}
                />
            ),
            1: (
                <ProgramsRepo
                    programsRepo={programsRepo}
                    loadingPrograms={loadingPrograms}
                    repoId={repoId}
                />
            ),
        };

        return (
            cases[String(tab)] || (
                <SubjectsRepo
                    subjectsRepo={subjectsRepo}
                    loadingSubjects={loadingSubjects}
                    repoId={repoId}
                />
            )
        );
    }

    if (tab === 3) {
        return <SimpleLoading />;
    }

    return (
        <Container>
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            <BodyWrapper>
                <ToggleMain tab={tab} />
            </BodyWrapper>
        </Container>
    );
};
export default StudentTabsRepo;

const Container = styled.div`
    border-radius: 20px;
    background-color: #fff;
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* border: solid 1px red; */
`;

const BodyWrapper = styled.div`
    padding: 1rem;
    width: calc(100% - 2.099rem);
    max-width: 100%;
`;
