import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { Button, CircularProgress } from "@mui/material";
import DefaultComplete from "../../../common/DefaultComplete";
import DefaultField from "../../../common/DefaultField";
import { afterSalesScheme } from "../../../../utils/schemas";
import { useSnackBar } from "../../../../app/campus/hooks/useSnackBar";
// import { SnackBarGenerico } from "../../../../app/campus/components/SnackBarGenerico";
import { Text } from "../../../common/Texts";
import axios from "axios";

const AfterSalesForm = (props) => {
  const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();


  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const responsable_areas = [
    {
      _id: 1,
      name: "Académico Online",
    },
    {
      _id: 2,
      name: "Académico Sede",
    },
    {
      _id: 3,
      name: "Comercial EC",
    },
    {
      _id: 4,
      name: "Comercial Sede",
    },
    {
      _id: 5,
      name: "Experiencia",
    },
    {
      _id: 6,
      name: "Finanzas OL",
    },
    {
      _id: 7,
      name: "Finanzas Sede",
    },
    {
      _id: 8,
      name: "Incompany",
    },
    {
      _id: 9,
      name: "Marketing",
    },
    {
      _id: 10,
      name: "Operaciones",
    },
    {
      _id: 11,
      name: "Tecnología",
    },
  ];

  const tipo_solicitud = [
    {
      _id: 1,
      name: "Entrevista Salida",
    },
    {
      _id: 2,
      name: "Gestión del bloqueado",
    },
    {
      _id: 3,
      name: "Reclamo",
    },
    {
      _id: 4,
      name: "Reclamo COVID-19",
    },
    {
      _id: 5,
      name: "Recupero",
    },
    {
      _id: 6,
      name: "Solicitud",
    },
    {
      _id: 7,
      name: "Solicitud apoyo económico",
    },
    {
      _id: 8,
      name: "Sugerencia",
    },
  ];

  // STATE
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    date: "",
    typeofrequest: { _id: "", name: "" },
    description: "",
    referralPerson: "",
    area: { _id: "", name: "" },
    // file: '',
  };

  const FormData = require("form-data");

  // FUNCTIONS
  function validate(value) {
    let error;
    if (!value) {
      error = "Campo requerido";
    } else if (value.name === "") {
      error = "Campo requerido";
    }
    return error;
  }


  const handleFile = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };


    const handleSubmit = async (values) => {
        setLoading(true);
        const data = new FormData();
            data.append("nombre", values.name || "");
            data.append("email", values.email || "");
            data.append("adjunto", file || "");
            data.append("telefono", values.phone || "");
            data.append("fecha", values.date || "");
            data.append("tipo_solicitud", values.typeofrequest.name || "");
            data.append("mas_info", values.description || "");
            data.append("derivador", values.referralPerson || "");
            data.append("departamento", values.area.name || "");

        await axios.post("https://landings.aden.org/aden_forms/posventa", data)
        .then(function (response) {
            setLoading(false);
            openSnackBar("success", `Formulario enviado con éxito!`);
        })
        .catch(function (error) {
            console.log(error);
            openSnackBar("error", `El formulario no puede enviarse en este momento, intente por favor nuevamente en otro momento.`);
        });
    };


  // RETURN
  return (
    <>
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validattionSchema={afterSalesScheme}
        >
            {({ values, setFieldValue, handleReset }) => (
                <Container>
                    <Text
                    fontSize="1.05rem"
                    fontWeight="700"
                    component="h1"
                    textAlign="start"
                    >
                    Datos del alumno
                    </Text>
                    <RowWrapper>
                        <DefaultField
                            name="name"
                            type="text"
                            variant="outlined"
                            label="Nombre"
                            required
                        />
                        <DefaultField
                            name="email"
                            type="email"
                            variant="outlined"
                            label="Correo electrónico"
                            required
                        />
                    </RowWrapper>

                    <RowWrapper>
                        <DefaultField
                            name="phone"
                            type="number"
                            variant="outlined"
                            label="Teléfono"
                            required
                        />
                        <DefaultField
                            name="date"
                            type="date"
                            variant="outlined"
                            label="Fecha"
                            required
                        />
                    </RowWrapper>

                    <Text
                        fontSize="1.05rem"
                        fontWeight="700"
                        component="h1"
                        textAlign="start"
                    >
                    Detalles de la solicitud
                    </Text>
                    <RowWrapper>
                        <DefaultComplete
                            name="area"
                            value={values.area}
                            label="Departamento Derivador"
                            variant="outlined"
                            setFieldValue={setFieldValue}
                            options={responsable_areas}
                            validate={validate}
                            fullWidth
                        />
                        <DefaultComplete
                            name="typeofrequest"
                            value={values.typeofrequest}
                            label="Tipo de solicitud"
                            variant="outlined"
                            setFieldValue={setFieldValue}
                            options={tipo_solicitud}
                            validate={validate}
                            fullWidth
                        />
                    </RowWrapper>

                    <DefaultField
                    name="referralPerson"
                    type="text"
                    variant="outlined"
                    label="Nombre de la persona derivadora"
                    required
                    />

                    <DefaultField
                    name="description"
                    type="text"
                    label="Más Info"
                    variant="outlined"
                    multiline
                    rows={5}
                    // required
                    />

                    <DefaultField
                    name="file"
                    type="file"
                    label="Adjuntar archivo"
                    variant="outlined"
                    onChange={handleFile}
                    />

                    {/** Botones */}
                    <ButtonsWrapper>
                    {loading ? (
                        <CircularProgress color="primary" size={32} />
                    ) : (
                        <>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleReset}
                        >
                            Resetear
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            Enviar solicitud
                        </Button>
                    </>
                    )}
                    </ButtonsWrapper>
                    <Text
                    fontSize="1.05rem"
                    fontWeight="700"
                    component="h1"
                    textAlign="center"
                    >
                    {snackBar.message}
                    </Text>
                 
                </Container>
            )}
        </Formik>
        {/* <SnackBarGenerico
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        /> */}
        
    </>
  );
};

export default AfterSalesForm;

const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem 4rem;

  .MuiInputBase-root {
    min-height: 70px;
    input {
      /* text-align: end; */
      align-self: flex-end;
      /* text-align-last: right; */
    }
  }

  @media (max-width: 768px) {
    padding: 2rem;
  }

  @media (max-width: 425px) {
    padding: 2rem 1rem;
  }
`;

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 2rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem 2rem;
  padding: 0 30%;

  @media (max-width: 1024px) {
    padding: 0 20%;
  }

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;
