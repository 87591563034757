import React from 'react';
import AverageWrapper from './reviews/AverageWrapper';
import CardWrapper from './reviews/CardWrapper';

const ReviewProduct = (props) => {
    const { review } = props;
    return (
        <>
            <AverageWrapper review={review} />
            <CardWrapper review={review} />
        </>
    );
};

export default ReviewProduct;
