import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DoubleQuote from '../../img/experiencias/double-quote.svg';
import flagArgentina from '../../img/experiencias/flag-argentina.svg';
import flagColombia from '../../img/experiencias/flag-colombia.svg';
import flagEcuador from '../../img/experiencias/flag-ecuador.svg';
import flagPanama from '../../img/experiencias/flag-panama.svg';

export const ExperienceCard = (props) => {
    const { name, text, image, pais } = props.experiencia;

    const [currentFlag, setCurrentFlag] = useState(null);

    useEffect(() => {
        selectFlag(pais);
    }, []);

    const selectFlag = (pais) => {
        const flags = {
            argentina: flagArgentina,
            colombia: flagColombia,
            ecuador: flagEcuador,
            panama: flagPanama
        };
        setCurrentFlag(flags[pais]);
    };

    return (
        <Container>
            {/** Image */}
            <ImgCard loading="lazy" src={image} alt="user" />
            {/** Quotes */}
            <div>
                <img
                    loading="lazy"
                    src={DoubleQuote}
                    alt="quotes"
                    width="30px"
                />
            </div>
            {/** Text */}
            <Paragraph>{text}</Paragraph>
            {/** Name */}
            <DivCard>
                <img loading="lazy" src={currentFlag} alt="country" />
                <h2>{name}</h2>
            </DivCard>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 3rem 2rem;
    background-color: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.06);
    width: 90%;
    height: 350px;
    position: relative;
    /* padding: 2rem; */

    @media (max-width: 1024px) {
        width: 80%;
        height: 300px;
    }
`;

const ImgCard = styled.img`
    position: absolute;
    top: -60px;
    right: -30px;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    object-fit: cover;
    object-position: top;

    @media (max-width: 1024px) {
        top: -20px;
        right: -10px;
        width: 90px;
        height: 90px;
    }
`;

const Paragraph = styled.p`
    font-size: 1rem;
    font-weight: 500;
    box-sizing: border-box;
    height: 260px;
    padding-right: 5px;
    overflow-y: scroll;
    margin: 0;

    @media (max-width: 1024px) {
        height: 200px;
    }

    ::-webkit-scrollbar {
        width: 1px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgb(200, 200, 200);
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgb(170, 170, 170);
    }
`;

const DivCard = styled.div`
    position: absolute;
    bottom: 3rem;
    left: 0;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;

    img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    h2 {
        font-size: 1rem;
        font-weight: 900;
        margin: 0;
    }
`;
