import styled from "styled-components";
import React from "react";
import useTheme from "../../../hooks/useTheme";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AlertDialog from "../../common/AlertDialog";
import { useShow } from "../../../hooks/useShow";
import ReactPlayer from "react-player";

export default function TutorialVideo(props) {
  const { primary } = useTheme();
  const [show, handleShow, handleClose, toggleShow] = useShow();
  const  { bannerExists } = props;
  return (
    <>
      <TutorialVideoWrapper onClick={handleShow} bg={primary} bannerExists={bannerExists}  data-tut="reactour__tutoriales_ondemand"> 
        <HelpOutlineIcon />
        <b>Tutorial de ayuda</b>
      </TutorialVideoWrapper>
      <AlertDialog
        w="sm"
        mensaje={
          <VideoWrapper>
            <ReactPlayerFake
              controls={true}
              url={
                "https://player.vimeo.com/external/642049362.hd.mp4?s=6b43a0e5383c5ab62ec09768c6e769174959fe32&profile_id=174"
              }
            />
          </VideoWrapper>
        }
        titulo={"Tutorial de la plataforma"}
        open={show}
        onClose={handleClose}
        accion={"Cerrar tutorial"}
      />
    </>
  );
}

const TutorialVideoWrapper = styled.div`
  width: 170px;
  height: 60px;
  background-color: ${(p) => p.bg};
  position: fixed;
  bottom: 20px;
  left: 70px;
  transition: 0.3s ease-in-out all;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 1rem;
  gap: 0.2rem;
  z-index: 9;
  :hover {
    filter: opacity(0.9);
    cursor: pointer;
  }
  svg {
    color: white;
    font-size: 1.5rem;
    height: 100%;
  }
  b {
    color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    right: 0px;
    top: ${(p) => p.bannerExists ? '180px' : '70px'};
    left: auto;
    height: 50px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: all 0.3s ease;
    width: 50px;

    svg {
      font-size: 2.7rem;
      height: 100%;
      transition: all 0.3s ease;
    }
    b {
      display: none;
      opacity: 0;
    }
    :hover {
      width: 170px;
      svg {
        font-size: 2rem;
        height: 100%;
      }
      b {
        display: flex;
        opacity: 1;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

const ReactPlayerFake = styled(ReactPlayer)`
  width: 100%;
  height: 450px;
  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
