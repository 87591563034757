import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { authActions } from '../../../../redux/actions';
import { loginSchema } from '../../../../utils/schemas';
import { useSnackbar } from "react-simple-snackbar";

// COMPONENTS
import {
    Button,
    IconButton,
    CircularProgress,
    InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import DefaultField from '../../../common/DefaultField';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { optionsStyle } from '../../../../utils/snackStyles';

const LoginForm = (props) => {
    const {
        loading,
        error,
        loginUserRequest,
        clearErrorLogin,
        avaliable
    } = props;

    let query = useQueryParams();

    // STATE
    const initialValues = {
        user: query.get('email') || '',
        password: ''
    };
    // const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();
    const [showPassword, setShowPassword] = useState(false);
    const [openSnackbar] = useSnackbar(optionsStyle);

    // EFFECTS
    useEffect(() => {
        if (error !== null) {
            console.info(
                '%c INFO SOPORTE - LOGIN ',
                'background: #922; color: #f5f5f5',
                error.response.data
            );
            if (error.response.status === 400)
                openSnackbar('Usuario y/o contraseña incorrectos');
            else
                openSnackbar('¡Ocurrio un error, por favor contactar con soporte!');
            setTimeout(() => {
                clearErrorLogin();
            }, 1000);
        }
    }, [error]);

    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }

    function handleSubmit(values) {
        loginUserRequest(values);
    }

    // RETURN
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={loginSchema}
            >
                {({ values }) => (
                    <Container>
                        {/** Usuario */}
                        <DefaultField
                            name="user"
                            type="email"
                            label="Correo electrónico"
                            required
                        />
                        {/** Password */}
                        <DefaultField
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Contraseña"
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? (
                                                <Visibility fontSize="small" />
                                            ) : (
                                                <VisibilityOff fontSize="small" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/** Boton ingresar */}
                        {loading ? (
                            <CircularProgress color="primary" size={32} />
                        ) : (
                            <Button
                                disabled={!!avaliable}
                                variant="contained"
                                type="submit"
                                color="primary"
                                fullWidth
                            >
                                Ingresar
                            </Button>
                        )}
                        {/* Recuperar contraseña */}
                        <a
                            className="text-center text-muted"
                            href="https://fa.aden.org/password/forgot?tenantId=a988e21d-96c6-6310-28e5-d8b43c06852b&client_id=2c54fc21-43b7-4c96-ba29-a4a66fd54342&nonce=&redirect_uri=https://app.aden.org/auth/login&response_type=code&scope=&state=S8pPorNxtMGAc8Agg5gGMmVihCakJz4_IXyfgaWOTxQ&timezone=America%2FArgentina%2FBuenos_Aires&metaData.device.name=Windows%20Chrome&metaData.device.type=BROWSER"
                        >
                            Olvidé mi contraseña
                        </a>
                        {/** Snackbar de error */}
                        {/* <SnackBarGenerico
							show={snackBar.show}
							handleClose={closeSnackBar}
							message={snackBar.message}
							variant={snackBar.variant}
						/> */}
                        {/** Form Canvas */}
                        {/* <form id="formlogin" onSubmit={onHiddenFormSubmit} action={faActionUrl} method="post">
							<input type="hidden" name="client_id" value="2c54fc21-43b7-4c96-ba29-a4a66fd54342" />
							<input type="hidden" name="redirect_uri" value={`${window.location.origin}/campus`} />
							<input type="hidden" name="response_type" value="code" />
							<input type="hidden" name="state" value="iY3A4ZPnWt_MMtEtMIaKDbpjQ5BQFm0M2mDevG3UrVI" />
							<input type="hidden" name="timezone" value="America/Argentina/Buenos_Aires" />
							<input type="hidden" name="loginId" value={values.user} />
							<input type="hidden" name="password" value={values.password} />
						</form> */}
                    </Container>
                )}
            </Formik>
        </>
    );
};

const mapStateToProps = (state) => ({
    loading: state.auth.loading,
    error: state.auth.error
});

const mapDispatchToProps = (dispatch) => {
    return {
        loginUserRequest: (values) =>
            dispatch(authActions.loginUserRequest(values)),
        clearErrorLogin: () => dispatch(authActions.clearErrorLogin())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

const Container = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    width: 100%;
    padding: 0 25%;

    @media screen and (max-width: 425px) {
        padding: 0 10%;
        width: 80%;
    }
`;
