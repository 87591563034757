import React from "react";
import { createChannel } from "../../../helpers/createChannelChat.helper";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../helpers/userActions.helper";
import {
    changeChannel,
    setParticipants,
} from "../../../redux/chat/chatActions";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import { IconButton } from "@mui/material";
import { Text } from "../Texts";

const ChatToTeacher = (props) => {
    const { value } = props;
    const history = useHistory();
    const userLogin = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    //Chat students of subject
    const partnerId = value.partner_id ? value.partner_id : value.id;

    //Chat
    const chatGeneration = () => {
        const hash = createChannel(userLogin.partner_id, partnerId);
        const contantFormated = {
            nombre: value.name,
            apellido: "",
            partner_id: parseInt(partnerId, 10),
            foto: value.image,
            contactData: value,
        };
        userAction({
            object_id: partnerId,
            object: "Chat acropolis",
            name: `Se inició el chat con ${value.name}`,
            activity: "Iniciar chat",
            medio: "Chat",
        });
        dispatch(changeChannel(hash));
        dispatch(
            setParticipants([
                contantFormated,
                { ...userLogin, nombre: userLogin.name },
            ])
        );
        setTimeout(() => {
            history.push("/networking/chat");
        }, 200);
    };

    return (
        <BtnSocial onClick={chatGeneration} variant="outlined">
            <ForumRoundedIcon /> 
            {' '}
            <Text
                fontSize="0.8rem"
                fontWeight="900"
                color="#ffff"
            >Chat</Text>
        </BtnSocial>
    );
};

export default ChatToTeacher;

const BtnSocial = styled(IconButton)`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    background: #B31D15;
    border-radius: 4px;
    
    &.MuiIconButton-root:hover {
    background-color: #B31D15;
    }

    svg {
        color: #ffff;
        font-size: 22px;
        margin-right: 1rem;
    }
`;
