import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styled from "styled-components";

const ModalCourseConditions = (props) => {
    const { open, courseConditions, handleClose } = props;

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    return (
        <ModalCustom
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <BoxCustom sx={style}>
                <h2>Condiciones de cursado</h2>

                <PdfContainer>
                    <Iframe src={courseConditions}></Iframe>
                </PdfContainer>
            </BoxCustom>
        </ModalCustom>
    );
};

export default ModalCourseConditions;

const ModalCustom = styled(Modal)``;

const BoxCustom = styled(Box)`
    border-radius: 20px !important;
    width: 55vw;
    height: 85vh;

    h2 {
        font-size: 1.2rem;
        color: #b31d15;
    }

    @media (max-width: 1000px) {
        width: 75vw;
    }
`;

const PdfContainer = styled.div`
    width: 100%;
    height: calc(100% - 3.5rem);
    margin-top: 2rem;
`;

const Iframe = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
`;
