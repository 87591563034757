import styled from "styled-components";

// Components
import GenericChips from "../../../../components/common/GenericChip";
import { Text } from "../../../../components/common/Texts";

// Material UI
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import Card from "@mui/material/Card";

const CardCorrectTask = (props) => {

    const {
        formatDate,
        correctionDate,
        studentComment,
        correctionWithCheck,
        check,
        setCheck,
        correctedSubmission,
        isEdit,
        editSuccess,
        valuesCorrection,
        note,
        redo,
        handleNote,
        handleRedo,
        old,
        order
    } = props;

    return (
        <WrapperCardRedo>
            <CardCorrection old={old}>
                <CardHeader>
                    <GenericChips
                        radius="5px"
                        textColor={old ? "#B31D15" : "#C29F43"}
                        fontSize="14px"
                        fontWeight="bold"
                        color={old ? "#FCE9E8" : "#F9F5EC"}
                        padding="15px 8px"
                        title={`${order}° Entrega`}
                    />
                    <Text
                        fontWeight="bold"
                        fontSize="14px"
                    >
                        {
                            !!formatDate && (
                                `Entregado el${' '}${formatDate}`
                            )
                        }
                    </Text>
                </CardHeader>
                {studentComment && (
                    <CommentStudent>
                        <span>Comentario del alumno:</span>
                        <p>{studentComment}</p>
                    </CommentStudent>
                )}
                <CardBody>
                    {correctionWithCheck ? (
                        <Flex>
                            <FormControlLabelFake
                                checked={check}
                                onChange={() =>
                                    setCheck(!check)
                                }
                                control={
                                    <Checkbox
                                        sx={{
                                            '& .MuiSvgIcon-root':
                                            {
                                                fontSize: 32,
                                            },
                                        }}
                                    />
                                }
                                label="Entrega revisada"
                            />
                        </Flex>
                    ) : (
                        <Flex>
                            {
                                !old ?
                                    <>
                                        {
                                            correctedSubmission ?
                                                <TextFieldFake
                                                    value={
                                                        !isEdit &&
                                                            !editSuccess
                                                            ? valuesCorrection.grade
                                                            : note
                                                    }
                                                    type="number"
                                                    disabled={
                                                        redo || !isEdit
                                                    }
                                                    onChange={handleNote}
                                                />
                                                :
                                                <TextFieldFake
                                                    value={note}
                                                    type="number"
                                                    disabled={redo}
                                                    onChange={handleNote}
                                                />
                                        }
                                    </>
                                    :
                                    <Text
                                        fontSize="20px"
                                        color="#000"
                                        fontWeight="bold"
                                    >
                                        {note}
                                    </Text>
                            }
                            <Text
                                fontSize="20px"
                                color="#aaa"
                                fontWeight="bold"
                            >
                                /100
                            </Text>
                        </Flex>
                    )}
                </CardBody>
                {
                    correctionDate !== "Invalid date" &&
                    <Text
                        fontWeight="bold"
                        fontSize="14px"
                    >
                        Corregido el {correctionDate}
                    </Text>
                }

            </CardCorrection>
            {!correctionWithCheck && !old && (
                <FormControlLabelFake
                    checked={redo}
                    onChange={handleRedo}
                    control={<Checkbox size="small" />}
                    label="¿Rehacer actividad?"
                />
            )}
        </WrapperCardRedo>
    )
}

export default CardCorrectTask;


const WrapperCardRedo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;

    :after {
        content: '';
        position: absolute;
        height: 1px;
        bottom: 0;
        width: calc(100% + 3rem);
        left: -1.5rem;
        background-color: #f1f1f1;
    }
`;

const CardCorrection = styled(Card)`
    margin: 16px 0 0 0;
    padding: 1rem;
    min-height: 100px;
    border-radius: 12px;
    background-color: #f7f7f7;
    box-shadow: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1.5rem;
    gap: 0.8rem;

    ::before {
        content: '';
        width: 12px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${(props) => props.old ? "#B31D15" : "#C29F43"};
    }
`;

const CardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const CardBody = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const CommentStudent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    box-sizing: border-box;
    padding: 0.5rem;
    background-color: #f1f1f1;
    border-radius: 8px;

    span {
        color: #b31d15;
        font-weight: bold;
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 18px;
    align-items: center;
`;

const FormControlLabelFake = styled(FormControlLabel)`
    margin-left: 1px;

    .MuiTypography-root {
        font-size: 14px;
        font-weight: 700;
        color: #222;
    }
`;

const TextFieldFake = styled(TextField)`
    width: 60px;
    background-color: #ffffff00;
    fieldset {
        border: 2px solid #ffffff00;
    }
    input {
        ::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }
    }
    :focus {
        input {
            border-width: 0px !important;
            border: none !important;
        }
    }
`;
