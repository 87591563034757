import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import ModalInfo from './ModalInfo';

// Material UI
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DescriptionIcon from '@mui/icons-material/Description';
import VideocamIcon from '@mui/icons-material/Videocam';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CreateIcon from '@mui/icons-material/Create';
import { Collapse, List, ListItem, ListItemText } from '@mui/material';

// Hooks
import useTheme from '../../../../../hooks/useTheme';

// Assets
import AdenBotHead from '../../../../../assets/media/aden/AdenBotCabeza.png'
import ModalIA from '../../main/IA/ModalIA';

const AsideListItem = (props) => {
    const {
        unidad,
        currentMedia,
        changeCurrentMedia,
        id,
        currentUnit,
        index,
        isEnabled,
        changeStatus,
        setChangeStatus,
        setViewDelivery,
        unitsEnabled,
    } = props;

    //STATES
    const [isOpen, setIsOpen] = useState(false);
    const [claseFake, setClaseFake] = useState(unidad?.clases);
    const [openModuleInfo, setOpenModuleInfo] = useState(false);
    const [openModalIA, setOpenModalIA] = useState(false);

    //TEMA
    const { primary } = useTheme();

    useEffect(() => {
        if (id === 0) {
            setIsOpen(true);
        }
    }, [id]);

    useEffect(() => {
        if (currentUnit === unidad.nombre) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMedia]);

    useEffect(() => {
        if (!!changeStatus) {
            handleStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeStatus]);

    function handleSetIsOpen() {
        setIsOpen(!isOpen);
    }

    const handleStatus = () => {
        let clase = claseFake;

        for (let index = 0; index < clase.length; index++) {
            if (
                clase[index].nombre_publicacion ===
                currentMedia.nombre_publicacion
            ) {
                clase[index].state = 'pendiente_correccion';
            }
        }
        setClaseFake(clase);
        setChangeStatus(false);
    };

    const getState = (status) => {
        const cases = {
            pendiente_entrega: {
                name: 'No entregado',
                color: '#C29F43',
                backgroundColor: '#F9F5EC',
            },
            pendiente: {
                name: 'No realizado',
                color: '#C29F43',
                backgroundColor: '#F9F5EC',
            },
            pendiente_correccion: {
                name: 'Pendiente corrección',
                color: '#5E80DB',
                backgroundColor: '#F7F8FD',
            },
            aprobado: {
                name: 'Aprobado',
                color: '#1E8065',
                backgroundColor: '#F7FDFB',
            },
            entregado: {
                name: 'Aprobada',
                color: '#1E8065',
                backgroundColor: '#F7FDFB',
            },
            desaprobado: {
                name: 'Realizado',
                color: '#B31D15',
                backgroundColor: '#FFF5F5',
            },
            rehacer: {
                name: 'Rehacer',
                color: '#B31D15',
                backgroundColor: '#FFF5F5',
            },
        };
        return cases[status] || cases["pendiente_entrega"];
    };

    const renderIconFormat = (type, seen, active) => {
        if (type === "evaluacion" && !seen) {
            return (<ListAltIcon style={{ color: active ? primary : "#fff" }} />)
        }
        if (type === "presentacion" && !seen) {
            return (<CreateIcon style={{ color: active ? primary : "#fff" }} />)
        }
        if (type === "material_plano" && !seen) {
            return (<DescriptionIcon style={{ color: active ? primary : "#fff" }} />)
        }
        if (type === "audiovisual" && !seen) {
            return (<VideocamIcon style={{ color: active ? primary : "#fff" }} />)
        }
        if (seen) {
            return <CheckCircleRoundedIcon style={{ color: "#3BD39C", height: "30px", width: "30px" }} />;
        }
    };

    const showModuleIA = () => {
        return unidad?.clases.some((item) =>
            item.subtipo === "Vimeo" &&
            item.enable_chat_ai === true &&
            typeof item.vimeo_transcript === "string" && item.vimeo_transcript.trim() !== ""
        ) || false;
    }

    /** RETURN ----------------------------------------------------------------------- */
    return (
        <>
            <ModalIA
                open={openModalIA}
                handleClose={() => setOpenModalIA(false)}
                publicationName={unidad?.nombre}
                type="module"
                unityId={unidad?.id}
                resources={unidad?.clases}
            />
            <FakeListItem
                color={primary}
                button
                open={isOpen}
                isCurrent={currentUnit === unidad.nombre}
                enable={isEnabled}
                // disabled={!!isOpen ? false : !isEnabled}
                onClick={handleSetIsOpen}
            >
                <ItemContainer>
                    <ItemIndex
                        isCurrent={currentUnit === unidad.nombre}
                        isOpen={!!isOpen}
                        enable={isEnabled}
                    >
                        {index}.
                    </ItemIndex>
                    <FakeListItemText primary={unidad.nombre} />
                </ItemContainer>
                <ArrowForwardIosIcon />
            </FakeListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List>
                    {unidad.clases &&
                        claseFake.map((clase, index) => {
                            if (
                                clase.nombre_publicacion ===
                                currentMedia.nombre_publicacion
                            ) {
                                setViewDelivery(clase.visto);
                            }
                            return (
                                <FakeListItemTwo
                                    view={clase.visto ? true : false}
                                    button
                                    key={index}
                                    onClick={() => changeCurrentMedia(clase)}
                                    onPointerEnterCapture={
                                        clase.orden === currentMedia.orden
                                    }
                                    disabled={!isEnabled}
                                    open={
                                        clase.nombre_publicacion ===
                                        currentMedia.nombre_publicacion
                                    }
                                >
                                    <FakeItemIcon
                                        open={
                                            clase.nombre_publicacion ===
                                            currentMedia.nombre_publicacion
                                        }
                                        view={clase.visto ? true : false}
                                    >
                                        {renderIconFormat(
                                            clase.formato,
                                            clase.visto,
                                            clase.nombre_publicacion ===
                                            currentMedia.nombre_publicacion
                                        )}
                                    </FakeItemIcon>
                                    <ListItemTextFake>
                                        <Name
                                            view={clase.visto ? true : false}
                                            open={
                                                clase.nombre_publicacion ===
                                                currentMedia.nombre_publicacion
                                            }
                                            color={primary}
                                        >
                                            {clase.nombre_publicacion.replace(
                                                /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
                                                ''
                                            )}
                                        </Name>
                                        {!!clase.state ? (
                                            !!changeStatus ? (
                                                <State
                                                    color="#3bd39c"
                                                    backgroundColor="#fff"
                                                >
                                                    ENTREGADO
                                                </State>
                                            ) : (
                                                <State
                                                    color={
                                                        getState(clase.state)
                                                            ?.color
                                                    }
                                                    backgroundColor={
                                                        getState(clase.state)
                                                            ?.backgroundColor
                                                    }
                                                >
                                                    {
                                                        getState(clase.state)
                                                            ?.name
                                                    }
                                                </State>
                                            )
                                        ) : null}
                                    </ListItemTextFake>
                                </FakeListItemTwo>
                            );
                        })}
                    {
                        showModuleIA() &&
                        <ListItemIA
                            disabled={!unitsEnabled.find((item) => item === (unidad.id + 1))}
                            onClick={() => setOpenModalIA(true)}
                        >
                            <ToastIA>
                                Esta herramienta de IA se habilitará <br />
                                cuando finalices este módulo
                            </ToastIA>
                            <ItemIconIA>
                                <img src={AdenBotHead} alt="IA" style={{ width: "25px", height: "18px" }} />
                            </ItemIconIA>
                            <label>
                                ADI | Asistente con inteligencia artificial
                            </label>
                        </ListItemIA>
                    }
                </List>
            </Collapse>
            {!isEnabled && (
                <DisabledInfo onClick={() => setOpenModuleInfo(true)}>
                    <Icon icon="octicon:info-24" width="24px" height="24px" />
                    <label>Recuerda que para habilitar este módulo debes <b>completar el módulo anterior.</b></label>
                </DisabledInfo>
            )}
            <ModalInfo
                isOpen={openModuleInfo}
                handleCloseModal={() => setOpenModuleInfo(false)}
                handleClick={() => setOpenModuleInfo(false)}
            />
        </>
    );
};

export default AsideListItem;

const FakeListItem = styled(ListItem)`
    color: ${(props) => (props.isCurrent ? '#fff' : '#616161')};
    background-color: ${(props) =>
        props.isCurrent ? `${props.color}` : props.open ? '#f1f1f1' : '#fff'};
    padding: 1rem 1.5rem 1rem 0;
    min-height: 60px;
    max-height: 60px;
    font-size: 18px;
    border-radius: 20px;
    transition: all 0.4s ease-in-out;
    margin: 16px 10px;
    overflow: hidden;
    width: calc(100% - 20px);
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;

    span {
        font-weight: ${(p) => (p.open ? '700' : '400')};
        color: ${(props) =>
        props.isCurrent
            ? '#fff'
            : props.open && props.enable
                ? '#b31d15'
                : '#616161'};
    }

    svg {
        font-size: 16px;
        color: ${(props) =>
        props.isCurrent
            ? '#fff'
            : props.open && props.enable
                ? '#b31d15'
                : '#616161'};
        transition: all 0.3s ease-in-out;
        transform: ${(p) => (p.open ? 'rotate(270deg)' : 'rotate(90deg)')};
    }

    display: flex;
    justify-content: space-between;

    .MuiListItemIcon-root {
        max-width: 36px;
        min-width: 36px;
    }

    display: flex;
    justify-content: space-between;

    .MuiListItemIcon-root {
        max-width: 36px;
        min-width: 36px;
    }

    :hover {
        background-color: #f1f1f1;

        span {
            font-weight: 700;
            color: ${(props) =>
        props.isCurrent
            ? '#fff'
            : props.enable
                ? '#b31d15'
                : '#616161'};
        }

        svg {
            color: ${(props) =>
        props.isCurrent
            ? '#fff'
            : props.enable
                ? '#b31d15'
                : '#616161'};
        }
    }

    @media (max-width: 1400px) {
        padding: 0.4rem;
        svg {
            font-size: 1rem;
            margin-right: 1rem;
        }
    }
`;

const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const ItemIndex = styled.div`
    width: 71px;
    height: 70px;
    background: ${(p) => (p.enable ? '#b31d15' : '#E5E5E5')};
    border-radius: 38px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 900;
    margin-left: -5px;

    @media (max-width: 1400px) {
        margin-left: -10px;
    } ;
`;

const FakeListItemTwo = styled(ListItem)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
    padding: 4px;
    background-color: ${(props) => (props.open ? '#b31d15' : '#fff')};
    list-style: none;
    border-radius: 20px;
    width: calc(100% - 20px);
    margin: 3px 10px;
    svg {
        color: ${(props) =>
        props.open
            ? '#b31d15'
            : props.view === true
                ? '#c3c7c3'
                : '#616161'};
    }
    :hover {
        background-color: ${(props) => props.open && '#b31d15'} !important;
    }
`;

const FakeListItemText = styled(ListItemText)`
    font-size: 1.25rem;
    font-weight: 400;
    @media (max-width: 1400px) {
        font-size: 0.6rem;
    }
`;

const Name = styled.span`
    font-size: 13px;
    font-weight: ${(props) => (props.open ? '700' : '400')};
    color: ${(props) => (props.open ? '#fff' : '#616161')};
    @media (max-width: 1400px) {
        font-size: 0.7rem;
    }
`;

const ListItemTextFake = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    gap: 8px;
`;

const FakeItemIcon = styled.div`
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.view ? '#fff' : props.open ? '#fff' : '#616161'};
    border-radius: 1000px;

    svg {
        width: ${(props) => !props.view && '18px'};
        height: ${(props) => !props.view && '18px'};
    }
`;

const State = styled.div`
    font-size: 12px;
    background-color: ${(p) => p.backgroundColor};
    color: ${(p) => p.color};
    padding: 4px 16px;
    border: 1px solid ${(p) => p.color};
    border-radius: 100px;
    width: fit-content;
`;

const ListItemIA = styled.button`
    cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
    padding: 4px;
    background: ${({ disabled }) => disabled ? "#FFF" : "linear-gradient(180deg, #E51A1A 0%, #B31D15 100%)"};
    list-style: none;
    border-radius: 20px;
    width: calc(100% - 20px);
    margin: 3px 10px;
    border: ${({ disabled }) => disabled && "1px solid #A8A8A8"};

    img {
        filter: ${({ disabled }) => disabled && "grayscale(100%)"};
    }

    label {
        font-size: 13px;
        color: ${({ disabled }) => disabled ? "#A8A8A8" : "#FFF"};
        cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
    }

    span {
        display: none;
    }

    :hover {
        span {
            display: ${({ disabled }) => disabled && "block"};
        }
    }
`

const ItemIconIA = styled.div`
    min-width: 24px;
    max-width: 24px;
    min-height: 20px;
    max-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    margin-bottom: 4px;
`;

const ToastIA = styled.span`
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: 50%; /* Centra el triángulo horizontalmente */
    transform: translateX(-50%); /* Ajuste para centrar */
    bottom: 3.2rem;
    padding: 8px;
    background-color: #222;
    border-radius: 5px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 12px;
    color: #fff;
    text-align: start;
    z-index: 10; 
    white-space: nowrap;

    ::after {
        content: '';
        position: absolute;
        left: 50%; /* Centra el triángulo horizontalmente */
        transform: translateX(-50%); /* Ajuste para centrar */
        bottom: -.7rem;
        border-width: 6px;
        border-style: solid;
        border-color: #222 transparent transparent transparent; /* Color superior, luego lados transparentes */
    }
`

const DisabledInfo = styled.button`
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    margin-left: 10px;

    svg {
        color: #c29f43;
    }

    label {
        color: #616161;
        cursor: pointer;
        text-align: start;
    }

    :hover {
        label {
            text-decoration: underline;
        }
    }
`;
