import React, { useEffect, useState } from 'react';

export default function useWindowWidth() {
    const [widthSize, setWidthSize] = useState(undefined);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleWidth() {
                setWidthSize(window.innerWidth);
            }

            window.addEventListener('resize', handleWidth);
            handleWidth();

            return () => {
                window.addEventListener('resize', handleWidth);
            };
        }
    }, []);

    return widthSize;
}
