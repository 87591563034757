
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';

const SkeletonTabsAcordeonAA = (props) => {
  return (
    <Container>
            <FakeSkeleton variant="rect" animation="wave" />
            <FakeSkeleton variant="rect" animation="wave" />
            <FakeSkeleton variant="rect" animation="wave" />
            <FakeSkeleton variant="rect" animation="wave" />
    </Container>
  )
}

export default SkeletonTabsAcordeonAA;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
`;

const FakeSkeleton = styled(Skeleton)`
    margin: 1rem;
    border-radius: 5px;
    height: 75px;
`;




