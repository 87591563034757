import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import styled from 'styled-components';

const AddButton = (props) => {
    const { title, color, width, height, border, background } = props;
    return (
        <Container
            color={color}
            width={width}
            height={height}
            border={border}
            background={background}
        >
            <AddRoundedIcon />
            <Title>{title}</Title>
        </Container>
    );
};

export default AddButton;

const Container = styled.div`
    color: ${(p) => (p.color ? p.color : 'white')};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    width: ${(p) => (p.width ? p.width : '100px')};
    height: ${(p) => (p.height ? p.height : '40px')};
    box-sizing: border-box;
    background: ${(p) => (p.background ? p.background : '#B31D15')};
    border-radius: 5px;
    border: ${(p) => (p.border ? p.border : 'none')};
`;

const Title = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
`;
