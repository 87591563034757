import styled from 'styled-components'; 
import { Card } from '@mui/material';
import LazyImg from '../../../components/common/LazyImg';
import ErrorImg from '../../../assets/media/aden/error.png'
const MessagesTeacherErrorFallback = () => {
    return (
        <ErrorWrapper>
            <LazyImg  
                src={ErrorImg}
                backgroundColor={'transparent'}
                width={'200px'}
                height={'auto'}
            />
            <h4>¡Hubo un error al cargar los mensajes!</h4>
        </ErrorWrapper>
    )
};

export default MessagesTeacherErrorFallback;

const ErrorWrapper = styled(Card)`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 1rem;
border-radius: 2rem;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`