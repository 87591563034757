import { useEffect, useState } from "react"
import { getBySubDomain } from "../redux/api/ondemand"

// const FONT = `data:@file/vnd.ms-opentype;base64,`
const IMAGE_DEFAULT = 'https://app.acropolislab.com/assets/aden/login-acropolis-lab.png'
const COLOR_DEFAULT = '#b31d15'

const useAlabLayout = (domain) => {

    const [layout, setLayout] = useState(false)

    useEffect(() => {
        domain && getAlab()
    }, [])

    const getAlab = async () => {
        const layout = await getBySubDomain(domain)
        setLayout(layout)
        changeColorCrisp(layout.color_1)
    }
    
    const changeColorCrisp = color => {
        setTimeout(() => {
            let crisp = document.getElementById("crisp-chatbox")
            console.log('crisp', crisp)
            if (crisp) {
                crisp = crisp.firstChild
                crisp = crisp.firstChild
                crisp = crisp.childNodes[1]
                crisp.style.setProperty('background-color', color, 'important');
            }
        }, 10000);
    }

    return { 
        primaryColor: layout?.color_1 || COLOR_DEFAULT,
        secondaryColor: layout?.color_2 || false,
        font: layout?.typography || false,
        imageLeft: layout?.image_login_1 || IMAGE_DEFAULT,
        logoAlab: layout?.logo_login || false,
        logoAlabColor: layout?.logo_color || COLOR_DEFAULT,        
        inverted: layout?.invert_form || false
    }
}

export default useAlabLayout