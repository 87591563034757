import { Icon } from '@iconify/react/dist/iconify.js';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'

const SelectInput = (props) => {

    const { options, setFieldValue, value, setValue, name, placeholder } = props;

    const [filteredOptions, setFilteredOptions] = useState(options);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const debounceTimeout = useRef(null);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    const handleOnChange = (e) => {
        setValue(e.target.value);
        setDropdownOpen(true);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            const searchTerm = e.target.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const filteredOptions = options.filter(option =>
                option.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchTerm)
            ); // Limit the number of filtered options to 10
            setFilteredOptions(filteredOptions);
        }, 500); // 500ms second delay
    };

    const selectItem = (option) => {
        setFieldValue(name, option.name);
        setValue(option.name);
        setDropdownOpen(false);
    };

    return (
        <SelectContainer>
            <SelectInputSearch placeholder={placeholder} value={value} type="text" onChange={handleOnChange} />
            {
                dropdownOpen &&
                <SelectDropdown>
                    {
                        filteredOptions.length === 0 ?
                            <SelectDropdownItem disabled>Estudiante no encontrado</SelectDropdownItem>
                            : <>
                                {filteredOptions.slice(0, 10).map(option => (
                                    <SelectDropdownItem onClick={() => selectItem(option)} key={option.id}>{option.name}</SelectDropdownItem>
                                ))}
                            </>
                    }
                </SelectDropdown>
            }
            {
                !!value &&
                <DropdownDelete onClick={() => setValue("")}>
                    <Icon icon="f7:xmark" width="20px" height="20px" />
                </DropdownDelete>
            }
            <DropdownArrow dropdownOpen={dropdownOpen} onClick={() => setDropdownOpen(!dropdownOpen)}>
                <Icon icon="gridicons:dropdown" width="28px" height="28px" />
            </DropdownArrow>
        </SelectContainer>
    )
}

export default SelectInput;

const SelectContainer = styled.div`
    width: 100%;
    position: relative;
`

const SelectInputSearch = styled.input`
    width: calc(100% - 5rem - 9px);
    height: 42px;
    border-radius: 4px;
    border: 1px solid #A8A8A8;
    padding: 9px 5rem 9px 9px;
    font-size: 20px;

    :hover {
        border-color: #0000008A;
    }
`

const SelectDropdown = styled.div`
    width: 100%;
    position: absolute;
    height: 200px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 9999;
    margin-top: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const SelectDropdownItem = styled.button`
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    text-align: left;

    :hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    :disabled {
        :hover {
            cursor: not-allowed;
            background-color: #fff;
        }
    }
`

const DropdownDelete = styled.span`
    cursor: pointer;
    width: 28px;
    height: 28px;
    margin: auto 0;
    position: absolute;
    right: 40px;
    top: 0;
    bottom: 0;
    color: #0000008A;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`

const DropdownArrow = styled.span`
    cursor: pointer;
    width: 28px;
    height: 28px;
    margin: auto 0;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    color: #0000008A;
    border-radius: 100px;

    :hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    svg {
        transform: ${props => props.dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
`