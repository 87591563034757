import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import RegisterForm from "../../molecules/auth/RegisterForm";
import RecoverForm from "../../molecules/auth/RecoverForm";
import LoginForm from "../../molecules/auth/LoginForm";

// Hooks
import { useQueryParams } from "../../../../hooks/useQueryParams";

const AuthComponent = () => {

	const params = useQueryParams();

	// STATES
	const [tab, setTab] = useState(params.get("registration") ? 1 : 0);

	// Si se ingresa al path recover y tiene id se
	// redirecciona al reestablecimiento de la contraseña
	useEffect(() => {
		window.location.href.includes("/recover") && setTab(2);
	}, []);



	function handleTabsComp(value) {
		setTab(value);
	}

	return (
		<AuthComponentWrapper>
			{tab === 0 && <LoginForm handleTab={handleTabsComp} />}
			{tab === 1 && <RegisterForm handleTab={handleTabsComp} />}
			{tab === 2 && <RecoverForm handleTab={handleTabsComp} />}
		</AuthComponentWrapper>
	);
};

export default AuthComponent;

const AuthComponentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 65%;
`;
