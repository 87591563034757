import React from "react";
import styled from "styled-components";

import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmDialog = (props) => {
    const { onClose, selectedCourse, open } = props;

    const handleClose = () => {
        onClose(false, selectedCourse);
    };

    const handleConfirm = () => {
        onClose(true, selectedCourse);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CustomDialogTitle id="alert-dialog-title">
                ¿Estás seguro que deseas inciar el curso {selectedCourse?.curso}?
            </CustomDialogTitle>
            <CustomDialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                    autoFocus
                >
                    Confirmar
                </Button>
            </CustomDialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;

const CustomDialogActions = styled(DialogActions)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`;

const CustomDialogTitle = styled(DialogTitle)`
    text-align: center;
`;
