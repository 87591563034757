import React from "react";
import styled from "styled-components";
import { Img } from "../../../common/Image";
import { Text } from "../../../common/Texts";
import gif from "../../../../assets/media/gif/titulaciones-example.gif";
import CertificateTracking from "../../molecules/academic/CertificateTracking";
import DefaultCardIcon from "../../../common/DefaultCardIcon";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SimpleLoading from "../../../common/SimpleLoading";

const TitulationState = (props) => {
  const {
    degreeStatus,
    statusLog,
    lastStep,
    addressComplete,
    typeDelivery,
    indexState,
    isShowCard,
    documents,
  } = props;
  
  if (documents === null ) {
    return (
      <TitulatioWrapper></TitulatioWrapper>
    )
  }
  
  return (
    <TitulatioWrapper>
      <WrapperHeader>
        <Text color="#333333" fontWeight="600" fontSize="1.2rem">
          Estado titulación
        </Text>
        {(documents[indexState]?.domicilio_confirmado) && isShowCard ? (
          addressComplete !== "" ? (
            <DefaultCardIcon
              subtitle={typeDelivery}
              type="tracking"
              title={addressComplete}
              icon={
                typeDelivery === "sede" ? <BusinessIcon /> : <LocationOnIcon />
              }
            />
          ) : (
            <SimpleLoading />
          )
        ) : null}
      </WrapperHeader>
      <Content statusLog={statusLog} degreeStatus={degreeStatus}>
        {!statusLog ? (
          <>
            <Img w="190px" src={gif} />
            <TextFake color="#333333" fontWeight="600" fontSize="1.4rem">
              ¡Selecciona una titulación!
            </TextFake>
            <Text
              textAlign="center"
              color="#aaaaaa"
              fontWeight="600"
              fontSize="1rem"
            >
              En este panel podrás ver el tracking de tus títulos, selecciona
              una titulación en el panel de documentación para ver su estado.
            </Text>
          </>
        ) : (
          statusLog.length >= 1 && (
            <>
              <CertificateTracking statusLog={statusLog} lastStep={lastStep} />
              <WrapperText>
                <SendText>
                  Medio de envio:{" "}
                  <span>
                    {documents[indexState].medio_de_envio
                      ? documents[indexState].medio_de_envio
                      : `No definido.`}
                  </span>{" "}
                </SendText>
                <SendText>
                  Número de seguimiento:{" "}
                  <span>
                    {documents[indexState].nro_seguimiento_envio_titulo
                      ? documents[indexState].nro_seguimiento_envio_titulo
                      : `No definido.`}
                  </span>
                </SendText>
              </WrapperText>
            </>
          )
        )}
      </Content>
    </TitulatioWrapper>
  );
};

export default TitulationState;

const TitulatioWrapper = styled.div`
  padding: 32px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  height: 450px;
  max-height: 450px;
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  height: ${(p) => (p.statusLog.length > 1 ? "auto" : "100%")};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  overflow-y: auto;
  cursor: ${(p) => (p.cursor ? "pointer" : "default")};
`;


const TextFake = styled(Text)`
  text-align: center;
`;

const WrapperHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const WrapperText = styled.div`
  margin: 0;
  width: 100%;
`;

const SendText = styled.p`
  color: #616161;
  font-size: 0.9rem;
  text-align: start;
  margin: 0;

  span {
    color: #b31d15;
    font-weight: bold;
  }
`;
