import React, { useState } from 'react';
import styled from 'styled-components';
import QrReader from 'react-qr-reader';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import { titleHTML } from '../../helpers/title.helper';

const Asistencia = () => {
    titleHTML("Asistencia");

    const [qrValue, setQrValue] = useState({
        result: ''
    });
    console.log("Aca");
    const [assistsOk, setAssistsOk] = useState(false);
    const handleError = (error) => {
        console.error(error);
    };

    const user = useSelector((state) => state.auth.user);

    console.log(user);

    const handleScan = (data) => {
        if (data) {
            setQrValue({
                result: data
            });
            if (qrValue.result !== '') {
                setAssistsOk(true);
            }
        }
    };

    return (
        <>
            <QrFake assistsOk={assistsOk}>
                <h1>Dar Asistencia</h1>
                {qrValue.result === '' ? (
                    <QrReaderFake
                        delay={200}
                        onError={handleError}
                        onScan={handleScan}
                        facingMode="environment"
                        showViewFinder="true"
                    />
                ) : null}
                {qrValue.result !== '' ? (
                    <>
                        <Assists> Asistencia verificada!</Assists>
                        <Card>
                            <CardInfo>
                                <h2>Nombre</h2>
                                <p>{user.name}</p>
                            </CardInfo>
                            <CardInfo>
                                <h2>Asignatura</h2>
                                <p>{qrValue.result}</p>
                            </CardInfo>
                        </Card>
                    </>
                ) : null}
            </QrFake>
        </>
    );
};

export default Asistencia;

const QrFake = styled.div`
    width: 500px;
    height: 100vh;
    margin: 2rem auto;

    @media (max-width: 768px) {
        width: 100%;
        min-height: 300px;
    }

    h1 {
        color: #222222;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1rem;
    }

    .sc-bpOzbH {
        section {
            div {
                border: solid 40px #e1e1e1 !important;
                box-shadow: ${(p) =>
                    p.assistsOk
                        ? '#3bd39c 0px 0px 0px 5px inset'
                        : '#b31d15 0px 0px 0px 5px inset'} !important;
            }
        }
    }
`;
const QrReaderFake = styled(QrReader)`
    border-radius: 2rem;
`;
const Assists = styled.p`
    margin: 1rem auto;
    color: #fff;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem;
    border-radius: 5px;
    background-color: #3bd39c;
`;
const CardInfo = styled.div`
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    h2 {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        color: #222222;
    }
    p {
        color: #b31d15;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: bold;
    }
`;
