
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';

const SkeletonTabsGridAA = (props) => {
  return (
      <Container>
           <Header>
              <Skeleton animation="wave" height={50} width={250} />
              <RigthButtons>
                  <Skeleton animation="wave" height={50} width={100}/>
                  <Skeleton animation="wave" height={50} width={100}/>    
              </RigthButtons>
          </Header>
          <AutoScroll>
              <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={180}
              />
              <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={180}
              />
              <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={180}
              />
              <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={180}

              />       
          </AutoScroll>
          <Footer>
              <Skeleton animation="wave" height={50} width={100} />
          </Footer>
      </Container>
  );
}

export default SkeletonTabsGridAA;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    min-height: 200px;
    margin: 1rem;
`;

const Header = styled.div`
    width: calc(100% - 4rem);
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1rem;
`;

const RigthButtons = styled.div`
    gap: 1rem;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const AutoScroll = styled.div`
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 0 2rem;
`;

const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-top: auto;
`;


