import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TabsComponent from '../../components/common/TabsComponent';
import MainSettlements from '../../components/templates/faculty/MainSettlements';
import { titleHTML } from '../../helpers/title.helper';
import useLayout from '../../hooks/useLayout';

const FacultyPage = () => {
    titleHTML("Faculty")

    const tabs = [
        { label: 'Mis honorarios', id: 1 },
        // { label: 'Liquidaciones', id: 1 },
    ];

    const [tab, setTab] = useState(1);

    function handleTab(e, value) {
        setTab(value);
    }

    const { setMenu } = useLayout()

    useEffect(() => {
        setMenu("Honorarios y liquidaciones")
    }, [])

    return (
        <>
            {/* <TabsWrapper>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            </TabsWrapper> */}
            <ContentWrapper>
                {/* {tab === 0 && <MainFaculty />} */}
                {/* {tab === 1 && <MainSettlements />} */}
                <MainSettlements />
            </ContentWrapper>
        </>
    );
};

export default FacultyPage;

const TabsWrapper = styled.div`
    width: calc(100% - 6rem - 70px);
    position: fixed;
    left: 70px;
    top: 70px;
    z-index: 10;
    border-top: 1px solid #f7f7f7;
    padding: 0 3rem;
    background-color: #ffffff;
    @media (max-width: 768px) {
        left: 0;
        width: calc(100% - 6rem);
    }
`;

const ContentWrapper = styled.div`
    width: 100%;
`;