import { Icon } from "@iconify/react/dist/iconify.js";
import { Text } from "../../../../../../components/common/Texts";
import styled from "styled-components";
import { LargeLinearProgress } from "../../../../../../styled-components";
import { useHistory } from 'react-router-dom';

const ProgramCard = ({ value }) => {

    const history = useHistory();

    return (
        <CardContainer onClick={() => history.push('/mi-progreso')}>
            <IconWrapper>
                <Icon icon="mage:chart" width="24px" height="24px" />
            </IconWrapper>
            <InfoWrapper>
                <Progress>
                    <LinearProgressWrapper>
                        <LargeLinearProgress
                            variant="determinate"
                            value={
                                // Esta validación se realiza por manqueada del back
                                Math.trunc(value?.total_progress) > 100
                                    ? 100
                                    : Math.trunc(value?.total_progress)
                            }
                        />
                    </LinearProgressWrapper>
                    <Text color="#222" fontSize="12px" fontWeight="400">
                        {
                            // Esta validación se realiza por manqueada del back
                            Math.trunc(value?.total_progress) > 100
                                ? 100
                                : Math.trunc(value?.total_progress)
                        }
                        %
                    </Text>
                </Progress>
                <Text color="#616161" fontSize="12px" fontWeight="400">
                    <b>{value?.program?.toUpperCase()}</b>
                </Text>
            </InfoWrapper>
        </CardContainer>
    )
}

export default ProgramCard;

const CardContainer = styled.div`
    cursor: pointer;
    width: 400px;
    height: 100px;
    border: 1px solid #A8A8A8;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
`

const IconWrapper = styled.div`
    width: 50px;
    height: 50px;
    border: 1px solid #b31d15;
    background-color: #f1f1f1;
    border-radius: 50%;
    color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 80%;
`

const Progress = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media (max-width: 1260px) {
        justify-content: flex-start;
        width: calc(100% - 100px);
    }
`;

const LinearProgressWrapper = styled.div`
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 16px;
    background-color: #eaeaea;

    @media (max-width: 1336px) {
        justify-content: flex-start;
    }
`;