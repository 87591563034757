import axios from "axios";

export const getStudentInfo = async (partnerId) => {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/ondemand/get_partner/${partnerId}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getSubjectsRepo = async (id, type) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/${id}?type=${type}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getActivitiesRepo = async (idSubject, repoId) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/student_activities/${idSubject}/${repoId}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const changeAttempt = async (activityId, repoId, attempt) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/academic_advisor/add_attempt/${activityId}?estudiante_id=${repoId}&cantidad_intentos=${attempt}`;

    try {
        const response = await axios.post(URL);
        return response.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postChangeDate = async (date, repoId, subjectId) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/expiration_date_change/${repoId}?asignatura_id=${subjectId}`;
    const body = {
        expiration_date: date,
    };

    try {
        const response = await axios.post(URL, body);
        return response.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};
