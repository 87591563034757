import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';
import { AvatarGroup } from '@mui/material';
import { Avatar } from '@mui/material';

const CourseCardSkeleton = (props) => {
    return (
        <CourseCardContainer>
            <ImgWrapper>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height={110}
                />
            </ImgWrapper>
            <Body>
                <DateWrapper>
                    <Skeleton animation="wave" height={8} width="40%" />
                </DateWrapper>
                <Skeleton animation="wave" height={30} />
                <Skeleton animation="wave" height={15} width="100%" />
            </Body>
            <Footer>
                <UsersWrapper>
                    {/* <AvatarsWrapper>
                        <Skeleton animation='wave' height={8} width='80%' />
                        <FakeAvatarGroup max={4}>
                            <Skeleton animation='wave' variant='circle' width={40} height={40} />
                            <Skeleton animation='wave' variant='circle' width={40} height={40} />
                        </FakeAvatarGroup>
                    </AvatarsWrapper> */}
                    <AvatarsWrapper>
                        <Skeleton animation="wave" height={8} width="50%" />
                        <FakeAvatarGroup max={10}>
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                            />
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                            />
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                            />
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                            />
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                            />
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                            />
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                            />
                        </FakeAvatarGroup>
                    </AvatarsWrapper>
                </UsersWrapper>
                <BottomFooterWrapper>
                    <Skeleton animation="wave" height={8} width="30%" />
                    <FakeButton animation="wave" />
                </BottomFooterWrapper>
            </Footer>
        </CourseCardContainer>
    );
};

export default CourseCardSkeleton;

const CourseCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    min-height: 380px;
    width: 100%;
    border: 1px solid #f2f2f2;
    border-radius: 20px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    height: 100%;
`;

const ImgWrapper = styled.div`
    position: relative;
    border-radius: 20px;
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: #b7b7b7;

    svg {
        font-size: 0.8rem;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.5rem;
    margin-top: auto;
    border-top: 1px solid #e8e8e8;
`;

const UsersWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const AvatarsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

const FakeAvatarGroup = styled(AvatarGroup)`
    cursor: pointer;
`;

const BottomFooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const FakeButton = styled(Skeleton)`
    width: 100px;
    height: 50px;
`;
