import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GridResource from '../../../common/GridResource';
import ContactCardFav from '../campus/ContactCardFav';

const ContactsFavs = (props) => {
    const { contacts = [] } = props;

    const [contactsState, setContactsState] = useState(contacts);

    useEffect(() => {
        contactsState === null && setContactsState(mutateResource(contacts));
    }, [contactsState]);

    const mutateResource = (array) =>
        array.map((item) => ({ ...item, favorito: true }));

    const handleRemoveItem = (id, type) => {
        setContactsState(null);
        if (type === 'contact') {
            setContactsState(
                contactsState.filter((item) => item.partner_id !== id)
            );
        }
    };

    return (
        <GridMain>
            <GridResource
                propsValues={contactsState}
                Card={ContactCardFav}
                handleOpenModal={() => {}}
                type={'contact'}
                handleRemoveItem={handleRemoveItem}
            />
        </GridMain>
    );
};

export default ContactsFavs;

const GridMain = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 5px;
`;
