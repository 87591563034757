import { createContext, useState, useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSummitEvents, getSummitOA } from "../../redux/api/summit";
import {
  createEventsAdapter,
  createSummitLOAdapter,
} from "../../pages/summit/adapters";
import { removeAccents } from "../../utils/removeAccents";

export const SummitContext = createContext(null);

export const SummitProvider = ({ children }) => {
  const country = useSelector((state) => state?.auth?.user?.country?.name);

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      summitEvents: null,
      summitLOs: null,
      conversations: null,
      conversationDates: null,
      summitThemes: null,
      themes: null,
      loading: false,
    }
  );

  useEffect(() => {
    getAllSummitEvents(country);
    getSummitLOs();
  }, [country]);

  useEffect(() => {
    if (!!state.summitEvents) {
      getConversationEvents(state.summitEvents);
      getSummitThemes(state.summitEvents);
    }
  }, [state.summitEvents]);

  useEffect(() => {
    !!state.conversations && getConversationDates(state.conversations);
  }, [state.conversations]);

  useEffect(() => {
    if (!!state.summitLOs && !!state.summitThemes) {
      groupThemes(state.summitThemes, state.summitLOs);
    }
  }, [state.summitLOs, state.summitThemes]);

  const getAllSummitEvents = async (country) => {
    updateState({ loading: true });
    const response = await getSummitEvents(removeAccents(country));

    if (response.error) {
      console.log("response.error", response.error);
      return;
    }

    const adaptedEvents = createEventsAdapter(response.data.attributes.Eventos);

    updateState({ summitEvents: adaptedEvents, loading: false });
  };

  const getSummitLOs = async () => {
    const response = await getSummitOA();

    if (response.error) {
      console.log("response.error", response.error);
      return;
    }

    const adaptedLOs = createSummitLOAdapter(
      response.data.attributes.ObjetosDeAprendizaje
    );

    updateState({ summitLOs: adaptedLOs });
  };

  const getConversationEvents = (events) => {
    const filteredEvents = events.filter(
      (event) => event.type === "conversatorio"
    );

    updateState({ conversations: filteredEvents });
  };

  const getConversationDates = (events) => {
    const dates = events.reduce((acc, curr) => {
      acc?.push(curr.date);
      return acc;
    }, []);

    updateState({ conversationDates: dates });
  };

  const getSummitThemes = (events) => {
    const filteredThemes = events.filter(
      (event) => event.type === "eje_tematico"
    );
    updateState({ summitThemes: filteredThemes });
  };

  const groupThemes = (themedEvents, learningObjects) => {
    const impactEvent = themedEvents.filter(
      (event) => event.title === "Impact Camp"
    );

    const arenaCamps = themedEvents
      .map((theme) => {
        return {
          id: theme.id,
          eventLocation: theme.eventLocation,
          date: theme.date,
          schedule: theme.schedule,
          title: theme.title,
        };
      })
      .filter((theme) => theme.title !== "Impact Camp");

    const restructuredThemes = themedEvents.map((theme) => {
      const arenaCampsOwned = arenaCamps.filter(
        (camp) => camp.title === theme.title
      );

      return {
        ...theme,
        camps: [
          {
            learningObjects: Array(
              arenaCampsOwned?.length > 1 ? arenaCampsOwned?.length : 1
            ).fill(
              learningObjects.find(
                (learningObject) => learningObject.title === theme.title
              )
            ),
          },
          {
            arenaCamps: arenaCampsOwned,
          },
          {
            impactCamps:
              arenaCampsOwned?.length > 1
                ? impactEvent
                : Array(1).fill(impactEvent[0]),
          },
          // {
          //   impactCamps: Array(
          //     arenaCampsOwned?.length > 1 ? arenaCampsOwned?.length : 1
          //   ).fill(impactEvent),
          // },
        ],
      };
    });

    const removedImpactCamp = restructuredThemes
      .filter((theme) => theme.title !== "Impact Camp")
      .slice(0, 8);

    updateState({ themes: removedImpactCamp });
  };

  return (
    <SummitContext.Provider
      value={{
        summitEvents: state.summitEvents,
        conversations: state.conversations,
        conversationDates: state.conversationDates,
        themes: state.themes,
        getAllSummitEvents,
        loading: state.loading,
      }}
    >
      {children}
    </SummitContext.Provider>
  );
};

export default SummitProvider;
