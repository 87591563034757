import { CLEAR_MENU, SET_MENU } from '../types/layout_types';

const initialState = {
    currentMenu: null,
};

export const layout = (state = initialState, action) => {
    switch (action.type) {
        case SET_MENU:
            return {
                ...state,
                currentMenu: action.payload,
            };
        case CLEAR_MENU:
            return {
                ...state,
                currentMenu: null,
            };
        default:
            return state;
    }
};
