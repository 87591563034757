import React from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react/dist/iconify.js';

// Components
import CommonButton from '../../../../../components/common/CommonButton';
import { Text } from '../../../../../components/common/Texts';
import QuestionReview from './questions/QuestionReview';

const PageCorrectAnswers = (props) => {

    const { studentRes, evaluation, handleLastPage } = props;

    const getResponseQuestion = (question) => {
        let result = false;

        studentRes.respuestas.forEach((sQuestion) => {
            // eslint-disable-next-line eqeqeq
            if (question.pregunta_id == sQuestion.pregunta_id) {
                result = sQuestion;
            }
        });

        return result;
    };

    return (
        <WrapperExam>
            <ButtonContainer>
                <CommonButton
                    label="Volver"
                    variant="filled"
                    Icon={() => <Icon icon="iconoir:arrow-left" width="24px" height="18px" />}
                    onClick={handleLastPage}
                />
            </ButtonContainer>
            <TitleContainer>
                <Text fontSize="20px" fontWeight="bold" color="#222" textAlign="start">
                    Respuestas correctas sobre este examen:
                </Text>
                <Text fontSize="16px" color="#616161" textAlign="start">
                    {evaluation?.name}
                </Text>
            </TitleContainer>
            <WrapperQuestions>
                {evaluation?.preguntas?.map((q, index) => {
                    return (
                        <QuestionReview
                            type={q.tipo}
                            question={q}
                            resQuestion={getResponseQuestion(q)}
                            number={index + 1}
                        />
                    );
                })}
            </WrapperQuestions>
        </WrapperExam>
    );
};

export default PageCorrectAnswers;

const WrapperExam = styled.div`
    width: calc(100% - 4rem);
    height: auto;
    padding: 2rem;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    border: 1px solid #A8A8A8;
    border-radius: 30px;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    padding-left: 1rem;
`

const WrapperQuestions = styled.div`
    width: 100%;
    min-height: 450px;
    max-height: 600px;
    overflow-y: auto;
`;