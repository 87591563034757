import * as base64 from "nodejs-base64-encode";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";

// Components
import CardNoteCourse from "../../../course/components/notes/CardNoteCourse";
import SkeletonLoading from "../../../../components/common/SkeletonLoading";
import NoResultsFound from "../../../../components/common/NoResultsFound";
import Text from "../../../../components/common/Text";
import SubjectAccordion from "../SubjectAccordion";
import { Icon } from "@iconify/react";

// Material UI
import { IconButton, Modal } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { subjectsActions } from "../../../../redux/actions";

const SubjectNotes = () => {
  const { id: subject_id } = useParams();
  const subjectId = base64.decode(subject_id, "base64");

  const BLOCKED_NOTES_CASES = ["Bloque integrador"];

  const dispatch = useDispatch();

  const fakeArray = Array.from(Array(4).keys());

  const { notesData } = useSelector((state) => state.enrollment.notes);
  const { repo_id: repoId } = useSelector((state) => state.auth.user);

  const payload = { repoId, subjectId };

  useEffect(() => {
    !notesData && dispatch(subjectsActions.getSubjectNotesRequest(payload));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesData]);

  if (!notesData) {
    return (
      <Container>
        <LoaderWrapper>
          <SkeletonLoading
            variant="rectangular"
            animation="wave"
            height={550}
          ></SkeletonLoading>
          <SkeletonChildren>
            {fakeArray.map((j) => (
              <SkeletonLoading
                key={j}
                variant="rectangular"
                animation="wave"
                height={100}
                width="95%"
              />
            ))}
          </SkeletonChildren>
        </LoaderWrapper>
      </Container>
    );
  }

  if (notesData.length === 0) {
    return (
      <Wrapper>
        <SubjectNotesBoard height="500px">
          <NoResultsFound message="No se encontraron apuntes." />
        </SubjectNotesBoard>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {notesData?.map(
        ({ learning_object, units }, i) =>
          units[i]?.notes?.length !== 0 &&
          !BLOCKED_NOTES_CASES.includes(learning_object) && (
            <SubjectNotesBoard key={learning_object}>
              <TitleWrapper>
                <Text fontSize="14px" fontWeight="500" textColor="#616161">
                  Bloque de contenido
                </Text>
                <Text fontSize="18px" fontWeight="700" textColor="#B31D15">
                  {learning_object}
                </Text>
              </TitleWrapper>
              <NotesWrapper>
                {units?.map(
                  (unit) =>
                    !!unit.notes.length && (
                      <SubjectAccordion
                        key={unit.name}
                        data={unit}
                        description={unit}
                        summary={NoteSummary}
                        details={NoteDetail}
                        decorator={true}
                        decoratorInset={"4.5rem 0 0 2rem"}
                        borderRadius="20px"
                        height="120px"
                        detailsPadding="1rem 0 0 2.3rem"
                      />
                    )
                )}
              </NotesWrapper>
            </SubjectNotesBoard>
          )
      )}
    </Wrapper>
  );
};

export default SubjectNotes;

const NoteSummary = (props) => {
  const {
    data: { name },
  } = props;

  return (
    <SummaryWrapper>
      <CheckCircle />
      <Text fontSize="18px" fontWeight="600" textColor="#B31D15">
        {name}
      </Text>
    </SummaryWrapper>
  );
};

const NoteDetail = (props) => {
  const {
    data: { notes },
  } = props;

  const [notesState, setNotesState] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState(null);

  useEffect(() => {
    !notesState && rearrangeNotes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes, notesState]);

  const handleToggleModal = (value = null) => {
    setSelectedModal(value);
    setOpen(!open);
  };

  function rearrangeNotes() {
    const valuesSeen = {};
    const notesValues = [];

    /**
     * Establece la cantidad de apuntes que tiene cada recurso
     * y filtra los recursos repetidos
     */
    const rearragnedNotes = notes.reduce((acc, curr) => {
      const amount = notes.filter((item) => item.resource === curr.resource);
      const resourceValue = curr.resource;

      /**
       * Se añaden al acumulador los recursos que no se repiten
       * y ejecuta la validación del filtro
       */
      if (!valuesSeen[resourceValue]) {
        valuesSeen[resourceValue] = true;
        acc.push({
          ...curr,
          notes_amount: amount.length,
        });
      }

      /**
       * Capturo en un nuevo array los valores necesarios
       * para reestructurar los datos
       */
      notesValues.push({
        note_text: curr.note,
        note_resource: curr.resource,
        note_time: curr.time,
        id: curr.note_id,
      });

      return acc;
    }, []);

    /**
     * Asocia y añade los múltiples apuntes a un recurso
     */
    const reestructuredNotes = rearragnedNotes.map((note) => ({
      ...note,
      notes: notesValues.filter((item) => note.resource === item.note_resource),
    }));

    setNotesState(reestructuredNotes);
  }

  return (
    <SubjectContainer>
      {notesState?.map((note, i) => (
        <DetailsWrapper key={note.number}>
          <NoteCard>
            <IconContainer>
              <CheckCircle />
            </IconContainer>
            <ContentWrapper>
              <NameWrapper>
                <Text fontSize="14px" fontWeight="700" textColor="#222222">
                  {note.resource}
                </Text>
              </NameWrapper>
              <NoteInfo>
                <NoteButton onClick={() => handleToggleModal(i)}>
                  {i === 0 && (
                    <ColumnName>
                      <Text
                        fontSize="14px"
                        fontWeight="700"
                        textColor="#616161"
                      >
                        Apuntes
                      </Text>
                    </ColumnName>
                  )}
                  <Icon icon="mdi:post-it-note-add" />
                </NoteButton>
                <NotesAmount>
                  {i === 0 && (
                    <ColumnName>
                      <Text
                        fontSize="14px"
                        fontWeight="700"
                        textColor="#616161"
                      >
                        Cantidad de apuntes
                      </Text>
                    </ColumnName>
                  )}
                  <Text fontSize="14px" fontWeight="700" textColor="#222222">
                    {note.notes_amount}
                  </Text>
                </NotesAmount>
              </NoteInfo>
            </ContentWrapper>
          </NoteCard>
          <NotesModal
            open={open && i === selectedModal}
            onClose={() => handleToggleModal(i)}
          >
            <NotesCard>
              <CardHeader>
                <Text fontSize="14px" fontWeight="700" textColor="#ffffff">
                  {note.resource}
                </Text>
              </CardHeader>
              <NotesListWrapper>
                {note.notes.map((noteItem) => (
                  <CardNoteCourse
                    notes={{
                      id: noteItem?.id,
                      video_time: noteItem?.note_time,
                      content: noteItem?.note_text,
                    }}
                  />
                ))}
              </NotesListWrapper>
              <CardFooter />
            </NotesCard>
          </NotesModal>
        </DetailsWrapper>
      ))}
    </SubjectContainer>
  );
};

const Wrapper = styled.div`
  display: grid;
  padding: 0rem 4rem 2rem 0rem;
  gap: 1rem;
`;

const TitleWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

const SubjectNotesBoard = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${(p) => p.height};
  padding: 2.2rem;
  border-radius: 20px;
  background-color: #ffffff;
`;

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// *********************** SKELETON ***********************
const Container = styled.div`
  height: 100%;
`;

const LoaderWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  height: auto;
`;

const SkeletonChildren = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  padding: 1rem 0;
  height: auto;
  width: 100%;
  margin: auto;
  display: grid;
  gap: 0px;
`;

// *********************** SUMMARY ***********************
const SummaryWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;

  svg {
    color: #b31d15;
    width: 36px;
    height: 36px;
    z-index: 5;
    padding: 1px 0;
    background-color: #ffffff;
  }
`;

// *********************** DETAILS ***********************
const SubjectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
`;

const DetailsWrapper = styled.div`
  height: auto;
  padding: 0 5rem 0 2.5rem;
  z-index: 5;
`;

const NoteCard = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  background-color: #f1f1f1;
  padding: 1rem;
  border-radius: 5px;
`;

const IconContainer = styled.div`
  position: absolute;
  left: -3.6rem;
  top: 0.5rem;
  margin: auto 0;
  svg {
    padding: 0;
    margin: 0;
    color: #b31d15;
    background-color: #ffffff;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NameWrapper = styled.div``;

const NoteInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 2.5rem;
  align-items: center;
  width: 170px;
`;

const NoteButton = styled(IconButton)`
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;

  svg {
    width: 27px;
    height: 27px;
    color: #ffffff;
    background-color: #b31d15;
    border-radius: 50%;
    padding: 5px;
  }
`;

const NotesAmount = styled.div`
  position: relative;
`;

const ColumnName = styled.div`
  position: absolute;
  margin: auto;
  inset: -7.5rem 0 0 -4rem;
  width: 160px;
  max-height: fit-content;
`;

// *********************** Modal ***********************
const NotesModal = styled(Modal)`
  display: grid;
  place-content: center;
`;

const NotesCard = styled.div`
  position: relative;
  width: 570px;
  margin: 0 auto;
  height: 590px;
  border-radius: 20px;
  background-color: #ffffff;
`;

const CardHeader = styled.div`
  position: sticky;
  top: 0;
  padding: 1.8rem 1rem;
  text-align: center;
  border-radius: 20px 20px 0 0;
  background-color: #b31d15;
`;

const CardFooter = styled.div`
  width: 100%;
  height: 65px;
  position: sticky;
  bottom: 0;
  border-radius: 0 0 20px 20px;
  background-color: #f1f1f1;
`;

const NotesListWrapper = styled.div`
  height: calc(100% - 8.5rem);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0.7rem 2rem;
  gap: 1rem;
`;