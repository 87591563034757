function useFormatedOneStudentAA(generalData, laboralData) {
    const generalDataFormated = [
        { title: "sis id", content: generalData?.sis_id },
        { title: "Nombre Completo", content: generalData?.nombre_completo },
        {
            title: "Sexo",
            content:
                generalData?.sexo === "M"
                    ? "Masculino"
                    : generalData?.sexo === "F"
                    ? "Femenino"
                    : "-",
        },
        { title: "Teléfono Particular", content: generalData?.tel_particular },
        { title: "Teléfono Celular", content: generalData?.tel_celular },
        {
            title: "Correo electrónico / Login Fusion Auth",
            content: generalData?.email,
        },
        {
            title: "Documento",
            content: generalData?.documento,
        },
        {
            title: "Pasaporte",
            content: generalData?.pasaporte,
        },
        {
            title: "Fecha de nacimiento",
            content: generalData?.fecha_de_nac,
        },
        {
            title: "País",
            content: generalData?.pais,
        },
        {
            title: "Localidad - Ciudad",
            content: generalData?.ciudad,
        },
        {
            title: "Domicilio",
            content: generalData?.domicilio,
        },
    ];

    const laboralDataFormated = [
        { title: "Empresa", content: laboralData?.empresa },
        { title: "Área Laboral", content: laboralData?.area_laboral },
        {
            title: "Cargo Actual",
            content: laboralData?.cargo_actual,
        },
        { title: "Teléfono Laboral", content: generalData?.telefono_laboral },
        {
            title: "Experiencia Laboral",
            content: generalData?.experiencia_laboral,
        },
    ];

    return {
        generalDataFormated,
        laboralDataFormated,
    };
}

export default useFormatedOneStudentAA;
