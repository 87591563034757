import React from 'react';
import { Button } from '@mui/material/';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const WorkshopCard = (props) => {
    const { workshop } = props;

    const history = useHistory();

    return (
        <WorkshopCardWrapper>
            <CardHead>
                <Img src={workshop.image_url} alt="workshop" />
            </CardHead>
            <CardBody>
                <Title>{workshop.publication_name}</Title>
                <Badges>
                    {workshop.tags.slice(0, 4).map((tag, index) => (
                        <Badge key={index}>{tag.toUpperCase()}</Badge>
                    ))}
                </Badges>
                <Description>
                    {workshop.description
                        ? workshop.description
                        : 'Descripción del workshop vacía'}
                </Description>
            </CardBody>
            <CardFooter>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => history.push('/auth/registration')}
                >
                    ¡Accede ahora!
                </Button>
            </CardFooter>
        </WorkshopCardWrapper>
    );
};

export default WorkshopCard;

const WorkshopCardWrapper = styled.div`
    min-height: 400px;
    box-shadow: 0px 0px 15px -7px rgba(46, 46, 46, 0.75);
    -webkit-box-shadow: 0px 0px 15px -7px rgba(46, 46, 46, 0.75);
    -moz-box-shadow: 0px 0px 15px -7px rgba(46, 46, 46, 0.75);
`;

const CardBody = styled.div`
    padding: 1rem;
`;

const CardHead = styled.div`
    width: 100%;
`;

const CardFooter = styled.div`
    padding: 1rem;
`;

const Img = styled.img`
    width: 100%;
`;

const Title = styled.div`
    font-size: 1.2rem;
    font-weight: 700;
    color: #b31d15;
    height: 60px;
`;

const Description = styled.div`
    height: 100px;
    overflow-y: auto;
`;

const Badges = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
    /* height: 100px; */
    max-height: 100px;
    overflow-y: auto;
`;

const Badge = styled.div`
    padding: 0.3rem 0.6rem;
    background-color: #b31d15;
    color: white;
    border-radius: 1rem;
    font-size: 0.8rem;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;
