import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SimpleLoading from '../../common/SimpleLoading';
import { Text } from '../../common/Texts';
import ButtonChat from '../../ui/molecules/advisor/ButtonChat';

const ContentAcademicAdvisorData = (props) => {
    const { advisor } = props;

    const { user } = useSelector((state) => state.auth);

    if (advisor === null) {
        return false;
    }

    return (
        <> 
            <Text fontSize="18px" fontWeight="bold" color="#b31d15">Mi Academic Advisor</Text>
            <Text fontSize="12px" fontWeight="bold" color="#888">{advisor.program}</Text>
            <Grid>
                <Photo>
                    <Img src={advisor.image} />
                </Photo>
                <Data>
                    <div>
                        <H1>{advisor.name}</H1>
                        <Mail href={`mailto:${advisor.email}`}>
                            {advisor.email}
                        </Mail>
                    </div>
                </Data>
                <Chat>
                    <ButtonChat user={user} advisor={advisor} />
                </Chat>
            </Grid>
        </>
    );
};

export default ContentAcademicAdvisorData;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 60px auto 1fr;
    margin: 18px 0;
`;

const Photo = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Img = styled.img`
    border-radius: 50%;
    width: 60px;
    height: 60px;
`;

const Data = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 2rem;
`;

const H1 = styled.h3`
    font-weight: 600;
`;

const Mail = styled.a`
    font-weight: 600;
    color: #b31d15;
`;

const Description = styled.div`
    padding: 0.5rem;
    background-color: #f7f7f7;
    border-radius: 2px;
    color: #858585;
    font-weight: 600;
    margin-bottom: 1.2rem;
`;

const Chat = styled.div`
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
