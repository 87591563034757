import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from '../../common/AlertDialog';
import { useAlertDialog } from '../../../hooks/useAlertDialog';
import EmptyGridResource from '../../common/EmptyGridResource';
import CourseCardSkeleton from '../../common/CourseCardSkeleton';
import { ondemandActions } from '../../../redux/actions';
import BusinessCard from '../../../app/landing/components/Business/BusinessCard';
import GridBusiness from '../../common/GridBusiness';
import useTheme from '../../../hooks/useTheme';

const CoursesBusiness = (props) => {
    const { thematic, handleTab } = props;
    const { primary } = useTheme();
    // REDUX
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const ondemandAcademic = useSelector((state) => state.ondemand.academic);

    const [alertDialog, closeAlertDialog] = useAlertDialog();
    const [themeState, setTheme] = useState(null);
    const [filterData, setfilterData] = useState([]);

    const emptyItems = new Array(3).fill(null);

    // EFFECTS
    useEffect(() => {
        if (user.ondemand_id !== null) {
            if (ondemandAcademic.business === null) {
                dispatch(
                    ondemandActions.getOndemandByCompetitorRequest({
                        id: user.ondemand_id,
                        email: user.personal_email
                    })
                );
            }
        }
    }, [ondemandAcademic]);

    useEffect(() => {
        if (ondemandAcademic.business !== null) {
            filterCourses(themeState);
        }
    }, [themeState]);

    const filterCourses = (theme) => {
        let result = [];
        ondemandAcademic.business.map((course) => {
            course.eje_tematico_ids.filter((th) =>
                th.id == theme ? result.push(course) : null
            );
        });
        setfilterData(result);
    };

    return (
        <CoursesContainer>
            {ondemandAcademic.business === null ? (
                <EmptyGridResource
                    propsValues={emptyItems}
                    Card={CourseCardSkeleton}
                />
            ) : ondemandAcademic.business.length > 0 ? (
                themeState === null ? (
                    <GridBusiness
                        menuFilter={thematic}
                        propsValues={ondemandAcademic.business}
                        allCourses={ondemandAcademic.business}
                        Card={BusinessCard}
                    />
                ) : (
                    <GridBusiness
                        menuFilter={thematic}
                        propsValues={filterData}
                        allCourses={filterData}
                        Card={BusinessCard}
                    />
                )
            ) : (
                <MessageEmpty color={primary}>
                    No tienen cursos asignados por Aden
                </MessageEmpty>
            )}

            <AlertDialog
                titulo={alertDialog.titulo}
                mensaje={alertDialog.mensaje}
                accion={alertDialog.accion}
                open={alertDialog.open}
                onClose={closeAlertDialog}
            ></AlertDialog>
        </CoursesContainer>
    );
};

export default CoursesBusiness;

const CoursesContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const MessageEmpty = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.color};
    font-size: 1.2rem;
    font-weight: 600;
`;
