import React from 'react'
import ErrorResponse from '../../../acropolisCommon/components/ErrorResponse'
import ViewEvaluation from './components/ViewEvaluation'

const RenderEvaluation = (props) => {

    const { resource, onlyView = false } = props

    if (onlyView) {
        return <ViewEvaluation resource={resource} />
    }

    return (
        <ErrorResponse message="Las actividades de presentacion aún no se pueden visualizar en esta página" />
    )
}

export default RenderEvaluation