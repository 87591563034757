import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LaunchIcon from '@mui/icons-material/Launch';
import DialogTask from './DialogTask';
import ModalTaskRender from './ModalTaskRender';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';

const CardTask = (props) => {
    const { values } = props;

    const [open, setOpen] = useState(false);
    const [openTask, setOpenTask] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenTask = () => {
        setOpenTask(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenTask(false);
    };

    const capitalizeFirstLetter = (string) => {
        return string === 'pendiente'
            ? string.charAt(0).toUpperCase() + string.slice(1) + ' corrección'
            : string.charAt(0).toUpperCase() + string.slice(1);
    };
    return (
        <>
            {open && (
                <DialogTask
                    open={open}
                    handleClose={handleClose}
                    values={values}
                />
            )}
            {openTask && (
                <ModalTaskRender
                    open={openTask}
                    handleClose={handleClose}
                    values={values}
                />
            )}
            <DialogTask
                open={open}
                handleClose={handleClose}
                values={values}
                capitalizeFirstLetter={capitalizeFirstLetter}
                openTask={openTask}
            />
            <Card>
                <HeaderRow>
                    <TitleSubject>{values.oa.name.toUpperCase()}</TitleSubject>
                    <SocialWrapper>
                        <SocialDiv>
                            <FavoriteBorderOutlinedIcon />
                            <Count>
                                {values?.comments?.total_public_likes ?? 0}
                            </Count>
                        </SocialDiv>
                        <SocialDiv>
                            <ChatRoundedIcon />
                            <Count>
                                {values?.comments?.total_comments ?? 0}
                            </Count>
                        </SocialDiv>
                    </SocialWrapper>
                </HeaderRow>

                <TitleResource>
                    {values.activity.name.split('-')[0] || values.activity.name}
                </TitleResource>
                <Body>
                    <StateContent>
                        <StateWrapper>
                            <p>Estado:</p>
                            <Chip
                                state={!!values.redo ? 'rehacer' : values.state}
                            >
                                {!!values.redo
                                    ? 'Rehacer'
                                    : capitalizeFirstLetter(values.state)}
                            </Chip>
                        </StateWrapper>
                        <GradeInfo>
                            <Icon icon="mdi:file-edit-outline" />
                            <Grade>
                                {values.grade} <span>/ 100</span>
                            </Grade>
                            <Date>
                                Fecha de entrega:{' '}
                                <span>{values.create_date}</span>
                            </Date>
                        </GradeInfo>
                    </StateContent>
                    <CustomButton onClick={handleClickOpenTask}>
                        <AttachFileIcon />
                        Ver mi tarea
                    </CustomButton>
                </Body>
                <Footer>
                    <IconButton onClick={handleClickOpen}>
                        <LaunchIcon />
                        Ver consigna
                    </IconButton>
                </Footer>
            </Card>
        </>
    );
};

export default CardTask;

const Card = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 24px 34px;
    background-color: #f9f9f9;
    border-radius: 30px;
    border: solid 1px #a8a8a8;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
`;

const TitleSubject = styled.h2`
    width: 100%;
    font-size: 0.9rem;
    color: #b31d15;
    text-transform: uppercase;
`;

const TitleResource = styled.h2`
    width: 100%;
    font-size: 1rem;
    color: #616161;
`;

const Body = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
    align-items: center;
`;

const StateContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
`;

const StateWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    p {
        color: #222222;
        font-size: 0.9rem;
        font-weight: bold;
    }
`;

const Chip = styled.div`
    min-width: fit-content;
    width: 175px;
    height: 32px;
    padding: 8px 16px;
    box-sizing: border-box;
    border-radius: 50px;
    border: ${({ state }) =>
        state === 'pendiente'
            ? 'solid 1px #5E80DB'
            : state === 'corregido'
            ? 'solid 1px #1E8065'
            : 'solid 1px #B31D15'};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ state }) =>
        state === 'pendiente'
            ? '#E6EBF9'
            : state === 'corregido'
            ? '#EAFAF6'
            : '#FCE9E8'};
    color: ${({ state }) =>
        state === 'pendiente'
            ? '#5E80DB'
            : state === 'corregido'
            ? '#1E8065'
            : '#B31D15'};
`;

const GradeInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
        color: #616161;
        font-size: 1.4rem;
    }
`;

const Grade = styled.div`
    color: #222;
    font-size: 1rem;
    font-weight: bold;

    span {
        color: #616161;
    }
`;

const Date = styled.div`
    color: #222;
    font-size: 0.85rem;

    span {
        font-weight: bold;
    }
`;

const CustomButton = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 12px 16px;
    border-radius: 50px;
    background-color: #b31d15;
    color: #fff;
    font-size: 1rem;
    color: #fff;

    svg {
        font-size: 1.3rem;
    }
`;

const Footer = styled.div`
    width: 100%;
`;

const IconButton = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    color: #616161;
    cursor: pointer;
    font-weight: bold;
    svg {
        color: #616161;
        font-size: 1.4rem;
    }
`;

const SocialWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 50px;
    justify-content: flex-end;
    gap: 1rem;
`;

const SocialDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 0 0.25rem;
    align-items: center;
    svg {
        color: #b31d15;
    }
`;

const Count = styled.p`
    font-size: 1rem;
`;

const HeaderRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 1fr;
`;