import React from 'react';

import ReactPlayer from 'react-player';
import styled from 'styled-components';
import '../../css/style.css';
import Portada from '../../img/bg/portada-juntos-lideres.jpg';

const Institucional = () => {
    return (
        <div className="institucionalBg">
            <div>
                <FlexContainer>
                    <div>
                        {/* <Player
                            poster={require("../../img/bg/portada-juntos-lideres.jpg")}
                            src={require("../../img/video/juntos-lideres-2021.mp4")}
                        /> */}
                        <VideoContent>
                            <ReactPlayerFake
                                url="https://youtu.be/BaQ_kgF-0OU?rel=0"
                                controls={true}
                                width="100%"
                                height="100%"
                                playsinline
                                config={{
                                    file: {
                                        attributes: {
                                            poster: `${Portada}`,
                                            style: {
                                                width: '100%',
                                                height: '100%'
                                            }
                                        }
                                    }
                                }}
                            />
                        </VideoContent>
                    </div>
                    <DivFake>
                        <VideoTextContainer className="p-5 institucionalText">
                            <Title>Somos ADEN. Juntos, líderes.</Title>
                            <Description>
                                No puedes elegir dónde naciste. No puedes elegir
                                quiénes son tus padres, tus hermanos, tu familia
                                o tu apellido. Pero sí puedes elegir a quién
                                amas. Puedes elegir tus intereses, tus pasiones.
                                Puedes elegir quién quieres ser. Soñar con un
                                futuro y trazar un camino para llegar a él.{' '}
                                <br /> <br /> Cuando eliges tu educación, estás
                                designando un sello que será por siempre parte
                                de tu identidad. <br /> <br /> Nos gusta pensar
                                que somos los soñadores de América Latina.
                                Porque nos inspira transformar realidades,
                                porque nos potenciamos entre todos para dar lo
                                mejor como individuo, como equipo y como
                                comunidad. <br /> <br />
                            </Description>
                            <div>
                                <a
                                    href="https://www.aden.org/acerca-de-aden/"
                                    target="_blank"
                                >
                                    <button className="button-red">
                                        Conoce más
                                    </button>
                                </a>
                            </div>
                        </VideoTextContainer>
                    </DivFake>
                </FlexContainer>
            </div>
        </div>
    );
};

export default Institucional;

const VideoContent = styled.div`
    position: relative;
    padding-top: 56.25%;
`;

const ReactPlayerFake = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const FlexContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 2rem;
    padding: 7.5%;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        padding: 2rem;
    }
`;

const Title = styled.div`
    font-size: 2rem;
    color: #b31d15;
    margin: 0 0 0.8rem 0;
    font-weight: 900;
`;

const Description = styled.div`
    margin-bottom: 0.8rem;
    width: 100%;
    line-height: 140%;
`;

const VideoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
`;

const DivFake = styled.div`
    justify-content: center;
`;
