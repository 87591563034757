import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Button, Modal } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import Text from '../../../common/Text';

const TicketSuccess = (props) => {
    const { nroTicket, handleReset } = props;

    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTIONS
    function handleClose() {
        setIsOpen(false);
        handleReset();
    }

    // EFFECTS
    useEffect(() => {
        if (nroTicket) {
            setIsOpen(true);
        }
    }, [nroTicket]);

    // RETURN
    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Container>
                {/** Boton cerrar */}
                <CustomCloseRounded color="secondary" onClick={handleClose} />
                <CustomImg
                    src="/assets/ticket-success.svg"
                    alt="ticket-success"
                    loading="lazy"
                />
                <Text fontSize="1.1rem" component="span">
                    Tu número de ticket es:
                </Text>
                <Text
                    fontSize="2.25rem"
                    fontWeight="1000"
                    color="primary"
                    component="h2"
                    style={{ letterSpacing: '0.5rem' }}
                >
                    {nroTicket}
                </Text>
                <Text fontSize="1.1rem" component="p">
                    El número será enviado a tu correo electrónico, por favor
                    guárdalo y no lo pierdas.
                </Text>
                {/** Botones para ir a nuevo ticket o consultar el estado */}
                <ButtonWrapper>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={handleClose}
                    >
                        Nuevo ticket
                    </Button>
                    <Link
                        to={{
                            pathname: '/peticiones-reclamos',
                            state: { consult: true }
                        }}
                        style={{ width: '100%' }}
                    >
                        <Button variant="contained" color="primary" fullWidth>
                            Consultar estado
                        </Button>
                    </Link>
                </ButtonWrapper>
            </Container>
        </Modal>
    );
};

export default TicketSuccess;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
    padding: 2rem 4rem;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    border: none;
    outline: none;

    @media (max-width: 1024px) {
        width: 500px;
    }

    @media (max-width: 768px) {
        width: 450px;
    }

    @media (max-width: 576px) {
        padding: 2rem;
        width: 100%;
        height: max-content;
    }
`;

const CustomCloseRounded = styled(CloseRounded)`
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
`;

const CustomImg = styled.img`
    width: 215px;
    height: auto;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    width: 100%;
    margin-top: 1rem;
`;
