import React, { useState } from "react";
import TabsComponent from "../../components/common/TabsComponent";
import styled from "styled-components";
import ActivitiesRepo from "../../components/templates/academic/oneStudent/ActivitiesRepo";

const AcademicIndividualSubjects = () => {
    const tabs = [
        { label: `Entregas por módulo`, id: 0 },
        // { label: `Promedio de la asignatura`, id: 1 },
    ];
    const [tab, setTab] = useState(0);

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
    };

    function ToggleMain(props) {
        const { tab } = props;

        const cases = {
            0: <ActivitiesRepo />,
            1: <h1>1</h1>,
        };

        return cases[String(tab)] || <ActivitiesRepo />;
    }

    return (
        <>
            <TabsComponent
                fullWidth
                tab={tab}
                handleTab={handleTab}
                tabs={tabs}
            />
            <Container>
                <ToggleMain tab={tab} />
            </Container>
        </>
    );
};

export default AcademicIndividualSubjects;

const Container = styled.div`
    width: 100%;
`;
