import styled from 'styled-components';
import React from 'react';

const GridCard = (props) => {
    const { cards = [], component: Card, width = '300px', display = "flex" } = props;

    return (
        <GridCardWrapper width={width} display={display}>
            {cards.map((card, i) => (
                <Card key={i} values={card} />
            ))}
        </GridCardWrapper>
    );
};

export default GridCard;

const GridCardWrapper = styled.div`
    ${(props) => props.display === "flex" 
    ? `width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-items: center;` 
    : `margin: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;`}
`;
