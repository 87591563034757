import axios from "axios";

export const getOrdersService = async (initailDate, finishDate) => {
    const URL = `${process.env.REACT_APP_SISAPI}/ordenes`;
    const data = { fechai: initailDate, fechaf: finishDate };
    try {
        const result = await axios.post(URL, data);
        return result.data;
    } catch (error) {
        return false;
    }
};

export const getPurchaseOrders = async () => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/purchase_order`;

    try {
        const result = await axios.get(URL);
        return result.data?.response_data;
    } catch (error) {
        return { error };
    }
};

export const getPayOrders = async () => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/pay_order`;

    try {
        const result = await axios.get(URL);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getOrders = async (name) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/purchase_order_modal?order=${name}`;

    try {
        const result = await axios.get(URL);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postCancelOrders = async (id) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/cancel_budget_request/${id}`;

    try {
        const result = await axios.post(URL);
        return result.data;
    } catch (error) {
        return { error };
    }
};

export const postContactService = async (data) => {
    const URL = `${process.env.REACT_APP_SISAPI}/contacto_oc`;
    const body = {
        mensaje: data.text,
        tipo: data.typeId,
        asunto: data.type,
        contacto: data.contact,
    };

    try {
        const result = await axios.post(URL, body);
        return result.data;
    } catch (error) {
        return {
            status: true,
            error,
        };
    }
};

export const getStudentsSisService = async (oc) => {
    const URL = `${process.env.REACT_APP_SISAPI}/ordensis`;
    const data = { oc };

    try {
        const result = await axios.post(URL, data);
        return result.data;
    } catch (error) {
        return { error };
    }
};

export const sendSettlements = async (body) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/budget_request/`;

    try {
        const result = await axios.post(URL, body);
        return result.data;
    } catch (error) {
        return { error };
    }
};
