import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { programsActions } from "../../redux/actions";

const usePrograms = () => {

    const dispatch = useDispatch();
    const programs = useSelector((state) => state.programs.programs);
    const [list, setList] = useState(null)
  
    // EFFECTS
    useEffect(() => {
      if (programs === null) {
        dispatch(programsActions.getProgramsRequest());
      }
      else{
        setList(programs.filter(item => item?.has_study_plan || item.from === 'sis'))
      }
    }, [programs]);
    

    return { programs: list }
}

export default usePrograms