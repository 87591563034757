import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { changeChannel } from '../../../../redux/chat/chatActions';

const UserConversation = (props) => {
    // Props
    const { channel, selectChannel, handleSetSelectChannel } = props;
    // State Redux
    const user = useSelector((state) => state.auth.user);
    // Dispach Redux
    const dispatch = useDispatch();

    // State Component
    const [userC, setUserC] = useState(false);
    const [lastMessage, setLastMessage] = useState(false);

    useEffect(() => {
        getUserChat(user, channel.participantes);
        getLastMessage(user.partner_id, channel.mensajes);
        return () => {};
    }, [channel]);

    const getUserChat = (user, participants) => {
        const result = participants.find(
            (userPart) => userPart.partner_id !== user.partner_id
        );
        setUserC(result);
    };

    const getLastMessage = (id, messages) => {
        if (messages.length > 0) {
            const result = messages[messages.length - 1];
            const lastMessage = {
                ...result,
                isSend: result.partner_id === id ? true : false,
            };
            setLastMessage(lastMessage);
        }
        return;
    };

    return (
        <Container
            onClick={() => {
                dispatch(changeChannel(channel.nombre));
                handleSetSelectChannel(userC?.partner_id);
            }}
            partnerId={selectChannel}
            userC={userC}
        >
            {userC && (
                <>
                    {channel.estado || channel.estado === undefined ? (
                        <>
                            <Image src={userC?.foto} />
                            <Data>
                                <div>
                                    <Title>{`${userC?.nombre}`}</Title>
                                    {lastMessage ? (
                                        <Message>
                                            {' '}
                                            {lastMessage.isSend
                                                ? `Tu: ${lastMessage.mensaje}`
                                                : lastMessage.mensaje}{' '}
                                        </Message>
                                    ) : (
                                        false
                                    )}
                                </div>
                            </Data>
                        </>
                    ) : (
                        <>
                            <ImageBlocked src={userC?.foto} />
                            <Data>
                                <div>
                                    <TitleBlocked>{`${userC?.nombre}`}</TitleBlocked>
                                    {/* {console.log(lastMessage.mensaje.length)} */}
                                    {lastMessage ? (
                                        <Message>Usuario bloqueado</Message>
                                    ) : (
                                        false
                                    )}
                                </div>
                            </Data>
                        </>
                    )}
                </>
            )}
        </Container>
    );
};

export default UserConversation;

const Container = styled.div`
    border-bottom: 1px solid #c4c4c4;
    background-color: ${(props) =>
        props.partnerId !== null &&
        props.userC &&
        props.partnerId.partner_id === props.userC.partner_id
            ? '#f1f1f1'
            : '#ffffff'};
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0.8rem 0.5rem;
    transition: 0.2s ease;
`;
const Image = styled.img`
    background-color: white;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
`;

const ImageBlocked = styled.img`
    background-color: white;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.5;
`;

const Data = styled.div`
    padding-left: 1rem;
    display: flex;
    height: 3.5rem;
    max-height: 100%;
    align-items: center;
    max-width: 100%;
    width: 100%;
`;

const Title = styled.h1`
    font-size: 1rem;
    font-weight: 600;
`;

const TitleBlocked = styled.h1`
    font-size: 1rem;
    font-weight: 600;
    color: #808080;
`;

const Message = styled.p`
    font-size: 0.8rem;
    color: #808080;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* height: 3rem; */
    width: 12rem;
    @media (max-width: 1700px) {
        width: 9rem;
    }
`;
