import React, { useState } from "react";
import styled from "styled-components";
import Courses from "./Courses";
import Programs from "./Programs";
import FinancialStatement from "./FinancialStatement";
import TabsComponent from "../../../common/TabsComponent";
import StudentPersonalData from "../../../common/teacher-academic/StudentPersonalData";
import Qualifications from "./Qualifications";
import DocumentationTab from "./DocumentationTab";
import useOneStudent from "../../../../hooks/useOneStudent";
import SimpleLoading from "../../../common/SimpleLoading";
import ProgramsOneStudent from "./ProgramsOneStudent";

const StudentTabs = (props) => {
    const { student } = props;
    const tabs = [
        { label: `Cursos`, id: 0 },
        { label: `Programas`, id: 1 },
        { label: "Documentación", id: 2 },
        // { label: `Estado financiero`, id: 3 },
        // { label: `Calificaciones`, id: 4 },
        { label: `Datos personales`, id: 5 },
    ];

    const [tab, setTab] = useState(0);

    // REDUX
    const { generalInfo, coursesStudent, docsStudent, programStudent } = useOneStudent(tab);

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
    };

    function ToggleMain(props) {
        const { tab } = props;

        const cases = {
            0: <Courses coursesStudent={coursesStudent} />,
            1: <ProgramsOneStudent programStudent={programStudent} />,
            2: <DocumentationTab docsStudent={docsStudent} />,
            3: <FinancialStatement handleSetStateFinance={() => {}} />,
            4: <Qualifications />,
            5: (
                <StudentPersonalData
                    student={student}
                    generalInfo={generalInfo}
                />
            ),
        };

        return cases[String(tab)] || <Courses />;
    }

    if (coursesStudent === null) {
        return <SimpleLoading />;
    }

    return (
        <Container>
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            <BodyWrapper>
                <ToggleMain tab={tab} />
            </BodyWrapper>
        </Container>
    );
};
export default StudentTabs;

const Container = styled.div`
    border-radius: 20px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* border: solid 1px red; */
`;

const BodyWrapper = styled.div`
    padding: 1rem;
    width: calc(100% - 2.099rem);
    max-width: 100%;
`;
