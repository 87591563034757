import axios from "axios";
import { useState } from "react";
import { formatDate } from "../modules/acropolisCommon/utils/formatDate";

const useTicketForm = (initialValues, inputArea, imageAttachment) => {

    const [openModal, setOpenModal] = useState(false)
    const [openModalError, setOpenModalError] = useState(false)
    const [ticketNumber, setTicketNumber] = useState(0)
    const [loading, setLoading] = useState(false)

    const URL_API = "https://pmapi.internal.aden.org"

    const handleSubmit = async (e) => {
        if (inputArea !== "") {
            let deadLine = ""
            if (!e.dateDeadline) {
                let fechaActual = new Date();
                // Se coloca por defecto la fecha 5 días después del día actual
                fechaActual.setDate(fechaActual.getDate() + 5)
                deadLine = formatDate(fechaActual, "YYYY-MM-DD");
            } else {
                deadLine = e.dateDeadline;
            }
            const emptyFile = new File([], 'empty_file.txt', { type: 'text/plain' });
            try {
                setLoading(true)
                const formData = new FormData();
                formData.append("ticket_name", e.ticketName);
                formData.append("external_client_name", initialValues.externalClientName);
                formData.append("external_client_email", initialValues.externalClientEmail);
                formData.append("description", initialValues.description);
                formData.append("image_attachment", imageAttachment ?? emptyFile);
                formData.append("project_id", parseInt(e.projectId));
                formData.append("internal_user_email", initialValues.internalUserEmail);
                formData.append("date_deadline", deadLine + " 12:00");
                const response = await axios.post(`${URL_API}/api/pm/v1/create_ticket`, formData)
                setTicketNumber(response.data.response_data.id)
                setLoading(false)
                setOpenModal(true)
                return response.data;
            } catch (e) {
                setLoading(false)
                setOpenModalError(true);
            }
            return;
        }
        
        
    };

    return { handleSubmit, openModal, setOpenModal, openModalError, setOpenModalError, ticketNumber, loading };
}

export default useTicketForm;