import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import UserCard from "../../ui/molecules/directory/UserCard";
import { directoriesActions, subjectsActions } from "../../../redux/actions";
// import SimpleLoading from '../../common/SimpleLoading';
import Pagination from "../../common/Pagination";
import UserCardSkeleton from "../../common/UserCardSkeleton";
import NoResultsFound from "../../common/NoResultsFound";
import { checkRoles } from "../../../helpers/roles.helper";

const ContactsGrid = (props) => {
    const { order, onOrdersChange, filters, pageSize, type, subject } = props;

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.auth.user);
    const pagination = useSelector((state) =>
        type !== "subjects"
            ? state.directories.pagination
            : state.enrollment.pagination
    );
    const currentPage = useSelector((state) =>
        type !== "subjects"
            ? state.directories.currentPage
            : state.enrollment.currentPage
    );
    const paginationLoading = useSelector((state) =>
        type !== "subjects"
            ? state.directories.paginationLoading
            : state.enrollment.paginationLoading
    );
    const listLoading = useSelector((state) =>
        type !== "subjects"
            ? state.directories.listLoading
            : state.enrollment.listLoading
    );
    //const pageSize = 12;
    const contacts = useSelector((state) =>
        type !== "subjects"
            ? state.directories.directories
            : state.enrollment.directories
    );
    const emptyItems = new Array(10).fill(null);

    ////////// Effects
    useEffect(() => {
        const query = {
            page_size: pageSize,
            filters: {
                name: filters.name || "",
                country_id: filters.country_id || 0,
                programa_id: filters.programa_id || 0,
                contacto_empresa: filters.empresa || "",
                contacto_cargo_actual: filters.cargo || "",
                intereses_ids: filters.intereses_ids || [],
                id: currentUser?.partner_id || 0,
            },
        };
        if (type === "subjects") {
            dispatch(
                subjectsActions.getSubjectPaginateRequest({ query, subject })
            );
        } else if (checkRoles("alumno")) {
            dispatch(directoriesActions.getPaginateRequest(query));
        } else if (checkRoles("ondemand") /* !!currentUser.ondemand_id */) {
            // Directorio ALab
            dispatch(
                directoriesActions.getPaginateAlabRequest({
                    query,
                    id: currentUser.ondemand_id,
                })
            );
        } else {
            dispatch(directoriesActions.getPaginateRequest(query));
        }
    }, [filters]);

    useEffect(() => {
        if (pagination !== null && currentPage > 0) {
            const newOrder = {};
            switch (order) {
                case "id-desc":
                    newOrder.field = "id";
                    newOrder.sense = "desc";
                    break;
                case "name-asc":
                    newOrder.field = "name";
                    newOrder.sense = "asc";
                    break;
                case "name-desc":
                    newOrder.field = "name";
                    newOrder.sense = "desc";
                    break;

                default:
                    newOrder.field = "id";
                    newOrder.sense = "desc";
                    break;
            }
            const query = {
                page_size: pageSize,
                page_offset: pagination.pages[currentPage - 1].page_offset,
                order: newOrder,
                filters: {
                    name: filters.name,
                    country_id: filters.country_id,
                    programa_id: filters.programa_id,
                    contacto_empresa: filters.empresa,
                    contacto_cargo_actual: filters.cargo,
                    intereses_ids: filters.intereses_ids,
                    id: currentUser?.partner_id || 0,
                },
            };
            if (type === "subjects") {
                dispatch(
                    subjectsActions.getSubjectDirectoriesRequest({
                        query,
                        subject,
                    })
                );
            } else if (checkRoles("alumno")) {
                dispatch(directoriesActions.getDirectoriesRequest(query));
            } else if (checkRoles("ondemand") /* !!currentUser.ondemand_id */) {
                // Directorio ALab
                dispatch(
                    directoriesActions.getDirectoriesAlabRequest({
                        query,
                        id: currentUser.ondemand_id,
                    })
                );
            } else {
                dispatch(directoriesActions.getDirectoriesRequest(query));
            }
        }
    }, [currentPage, order, filters]);

    // Functions
    function nextPage() {
        dispatch(
            type !== "subjects"
                ? directoriesActions.nextPage()
                : subjectsActions.nextSubjectPage()
        );
    }
    function previousPage() {
        dispatch(
            type !== "subjects"
                ? directoriesActions.previousPage()
                : subjectsActions.previousSubjectPage()
        );
    }

    function handleOrderChange(event) {
        event.preventDefault();
        onOrdersChange({ order: event.target.value });
    }
    // Checks
    if (pagination === null || paginationLoading) {
        return (
            <MainContainer>
                <Header>
                    <FormControl
                        className="order"
                        style={{ minWidth: "10rem" }}
                    >
                        <InputLabel>Ordenar Por</InputLabel>
                        <Select value={order} onChange={handleOrderChange}>
                            <MenuItem value={"id-desc"}>Recientes</MenuItem>
                            <MenuItem value={"name-asc"}>Nombre A-Z</MenuItem>
                            <MenuItem value={"name-desc"}>Nombre Z-A</MenuItem>
                        </Select>
                    </FormControl>
                </Header>
                <Data>
                    {emptyItems.map((items, index) => {
                        return <UserCardSkeleton key={index} />;
                    })}
                </Data>
            </MainContainer>
        );
    }
    const { totalPages } = pagination;

    if (contacts != null && contacts.length > 0) {
        contacts.sort((a, b) => {
            return a.sis_id === "" && b.sis_id != null && b.sis_id !== ""
                ? 1
                : b.sis_id === "" && a.sis_id != null && a.sis_id !== ""
                ? -1
                : 0;
        });
    }
    return (
        <MainContainer>
            <Header>
                <FormControl className="order" style={{ minWidth: "10rem" }}>
                    <InputLabel>Ordenar Por</InputLabel>
                    <Select value={order} onChange={handleOrderChange}>
                        <MenuItem value={"id-desc"}>Recientes</MenuItem>
                        <MenuItem value={"name-asc"}>Nombre A-Z</MenuItem>
                        <MenuItem value={"name-desc"}>Nombre Z-A</MenuItem>
                    </Select>
                </FormControl>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    nextPage={nextPage}
                    previousPage={previousPage}
                />
            </Header>

            {listLoading || contacts === null ? (
                <Data>
                    {emptyItems.map((items, index) => {
                        return <UserCardSkeleton key={index} />;
                    })}
                </Data>
            ) : (
                <Data>
                    {contacts.map((contact) => {
                        const key =
                            contact.partner_id || contact.foto.split("=")[3];
                        return (
                            <UserCard
                                key={key}
                                contact={{
                                    ...contact,
                                    partner_id: contact.partner_id,
                                }}
                            />
                        );
                    })}
                </Data>
            )}

            {contacts != null && contacts.length === 0 && (
                <NoResultsFound></NoResultsFound>
            )}

            <Footer>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    nextPage={nextPage}
                    previousPage={previousPage}
                />
            </Footer>
        </MainContainer>
    );
};

ContactsGrid.propTypes = {
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    contacts: PropTypes.array,
    loaded: PropTypes.bool,
    changePage: PropTypes.func,
};

export default ContactsGrid;

const MainContainer = styled.div`
    background-color: #ffffff;
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 20px; */
    color: #bfbfbf;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    row-gap: 2rem;
    border-radius: 5px;
`;

const Box = styled.div``;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .order {
            width: 100%;
            margin-bottom: 1.5rem;
        }
    }
`;

const Footer = styled.div`
    /* grid-area: pagination; */
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
        justify-content: center;
    }
`;

const Data = styled.div`
    display: grid;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 1050px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1rem;
        column-gap: 1rem;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 0.7fr);
        row-gap: 1rem;
        column-gap: 1rem;
    }

    @media screen and (max-width: 675px) {
        grid-template-columns: repeat(2, 0.8fr);
        row-gap: 0.5rem;
        column-gap: 0.5rem;
    }

    @media screen and (max-width: 405px) {
        grid-template-columns: repeat(2, 148px);
    }

    @media screen and (max-width: 360px) {
        grid-template-columns: repeat(2, 140px);
    }

    @media screen and (max-width: 320px) {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        column-gap: 1rem;
    }
`;

const NoContacts = styled.div`
    padding: 1rem;
    width: 100;
    background-color: #f8d7da;
    color: #842029;
`;
