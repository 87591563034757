import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Text } from '../../../common/Texts'
import { Formik, Form } from 'formik';
import { settlementsScheme } from '../../../../utils/teacherSchemas';
import DefaultFile from '../../../common/DefaultFile';
import { Button, Chip, TextField } from '@mui/material';
import useCoursesTeacher from '../../../../hooks/useCoursesTeacher';
import useStudentsTeacher from '../../../../hooks/useStudentsTeacher';
import useCoursesCanvas from '../../../../hooks/useCoursesCanvas';
import DefaultField from '../../../common/DefaultField';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useStudentsCanvas from '../../../../hooks/teacher/useStudentsCanvas';
/* import { assignDracma } from '../../../../redux/api/dracma.api'; */
import { sendSettlements } from '../../../../redux/api/faculty';
import SelectInput from './SelectInput';
/* 
const MULTI_STUDENTS = 2
const UNBOARDING_ONE = 3 */
const TYPES = [
    { id: 1, name: 'Consulta sincrónica' },
    { id: 2, name: 'Sesión OnBoarding grupal' },
    { id: 3, name: 'Sesión OnBoarding individial' },
    { id: 4, name: 'Consulta sincrónica TFM/TFAE' }
]

const FormSettlements = (props) => {

    const { handleRefresh, onClose } = props

    // Fecha de hoy
    const [isoDate] = new Date().toISOString().split('T')
    const minifyData = true
    // STATE
    const initialValues = {
        type: '',
        student: '',
        assigment: '',
        date: isoDate,
    };

    const [type, setType] = useState(null)
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [subject, setSubject] = useState(null)
    const [student, setStudent] = useState(null)
    const [studentsList, setStudentsList] = useState([])

    const { courses } = useCoursesTeacher()
    const { coursesCanvas } = useCoursesCanvas();
    const subjectsArray = !courses ? coursesCanvas : !coursesCanvas ? courses : [...courses, ...coursesCanvas]
    const { students } = useStudentsTeacher(minifyData, subject ? subjectsArray?.find(s => s.name === subject) : null)
    const { studentsCanvas } = useStudentsCanvas()

    const { user } = useSelector(state => state.auth)
    const { repo_id } = user

    useEffect(() => {
        setStudent(null)
    }, [subject])

    const handleSubmit = async (values) => {
        // eslint-disable-next-line no-unused-vars
        const [mimeType, hashB64] = !!file && file.split("base64,")
        const body = {
            ...values,
            file: hashB64,
            student: (values.type === "Sesión OnBoarding grupal" ? studentsList.map(u => u.name || u).join(' - ') : values.student || values.student.name ),
            type: values.type,
            assignment: values.assigment,
            partner_id: repo_id
        }
        delete body.assigment
        setLoading(true)
        setStudentsList([])
        const req = await sendSettlements(body)
        // eslint-disable-next-line no-mixed-operators
        /* values.type.id !== UNBOARDING_ONE || values.type.id !== MULTI_STUDENTS && removeDragmaPoint(values) */
        setLoading(false)
        if (req.error) {
            alert("Error")
        }
        else {
            onClose()
            handleRefresh()
        }
    }

    /* const removeDragmaPoint = async (values) => {
        const body = {
            description: values.type.name + ' | ' + values.assigment.name,
            category: "Consulta",
            repositorio_partner_id: values?.student?.sis_id ? false : values.student.id,
            sis_id: values?.student?.sis_id || false,
            points: -100,
            must_be_unique: false,
            // date: new Date(),
            is_acropolis_lab: false,
            acropolis_lab_id: "",
        };

        // eslint-disable-next-line no-unused-vars
        const request = await assignDracma(body);
        // if (request.error) {
        //     console.log("Error al asignar dracmas");
        // }
    } */


    const handleB64File = file => {
        setFile(file)
    }

    /*     const handleType = (_, values, setFieldValue) => {
            if (values) {
                const { id } = values
                setType(id)
                if (type !== MULTI_STUDENTS) {
                    setStudentsList([])
                }
            }
            setFieldValue('student', "")
            setFieldValue('assigment', "")
        } */

    const handleStudent = (stud) => {
        const isExist = studentsList.some(student => student === stud)
        !isExist && setStudentsList([...studentsList, stud])
    }

    const handleDelete = (student) => {
        setStudentsList(studentsList.filter(s => s !== student))
    }

    return (
        <FormContainer>
            <Header>
                <Text color="#333" fontSize="16px" fontWeight="bold">Cargar nueva liquidación</Text>
            </Header>
            <Container>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={settlementsScheme}
                >
                    {({ values, setFieldValue }) => (
                        <FormFK>
                            {/* TIPO */}
                            {/* <DefaultComplete
                                name="type"
                                label="Tipo"
                                value={values.id}
                                setFieldValue={setFieldValue}
                                variant="outlined"
                                options={TYPES}
                                fullWidth
                                required
                                onChange={(e, value) => {
                                    handleType(e, value, setFieldValue)
                                    setFieldValue('type', value);
                                }}
                            /> */}
                            <SelectInput
                                options={TYPES}
                                setFieldValue={setFieldValue}
                                name="type"
                                placeholder="Tipo"
                                value={type}
                                setValue={setType}
                            />
                            {/* AUTOCOMPLETE ESTUDIANTE */}
                            {values?.type === "Sesión OnBoarding grupal" || values?.type === "Sesión OnBoarding individial" ?
                                (<>
                                    {values?.type === "Sesión OnBoarding grupal" ? <><Col>
                                        <DefaultField
                                            name="student"
                                            label="Estudiante"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                        <Button onClick={() => handleStudent(values.student)}>Agregar</Button>
                                    </Col>
                                        {type === "Sesión OnBoarding grupal" && <Badge>{studentsList.map((student) => <Chip variant="outlined" color="primary" label={student} onDelete={() => handleDelete(student)} />)} </Badge>}
                                    </>
                                        :
                                        <DefaultField
                                            name="student"
                                            label="Estudiante"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />}
                                    <DefaultField
                                        name="assigment"
                                        label="Programa"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </>) :
                                (<>
                                    {courses || coursesCanvas ?
                                        <SelectInput
                                            options={subjectsArray}
                                            setFieldValue={setFieldValue}
                                            name="assigment"
                                            placeholder="Asignatura"
                                            value={subject}
                                            setValue={setSubject}
                                        />
                                        : <TextField
                                            label="Cargando asignaturas"
                                            disabled
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />}
                                    {
                                        !subject ?
                                            <TextField
                                                label="Selecciona una asignatura"
                                                disabled
                                                variant="outlined"
                                                fullWidth
                                            /> : (students || studentsCanvas) ?
                                                <SelectInput
                                                    options={students && studentsCanvas ? [...students, ...studentsCanvas] : !students ? studentsCanvas : !studentsCanvas ? students : []}
                                                    setFieldValue={setFieldValue}
                                                    name="student"
                                                    placeholder="Estudiante"
                                                    value={student}
                                                    setValue={setStudent}
                                                />
                                                : <TextField
                                                    // name="student"
                                                    label="Cargando estudiantes"
                                                    disabled
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                />}
                                    {/* BADGE */}
                                    {/* {type === MULTI_STUDENTS && <Badge>{studentsList.map(({ id, name }) => <Chip variant="outlined" color="primary" label={name} onDelete={() => handleDelete(id)} />)} </Badge>} */}
                                    {/* CURSOS */}
                                </>
                                )}
                            {/* FECHA DE CONSULTA */}
                            <DefaultField label="Fecha de consulta" name="date" variant='outlined' type='date' />
                            {/* ARCHIVO */}
                            <DefaultFile handleB64File={handleB64File} />
                            <Button disabled={!file || loading || (!values?.assigment && !subject) || (!values?.student && !student) || !type} type='submit' fullWidth variant='contained' color='primary'>{loading ? 'Cargando' : 'Guardar'}</Button>
                        </FormFK>
                    )}
                </Formik>
            </Container>
        </FormContainer>
    )
}

export default FormSettlements

const FormContainer = styled.div`
    width: 400px;
    height: auto;
    border-radius: 20px;
    padding: 18px;
`

const Header = styled.div`
    width: 100%;
    /* height: 50px; */
    margin-bottom: 12px;
    display: flex;
    align-items: center;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    flex-direction: column;
`

const FormFK = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;

    @media screen and (max-width: 425px) {
        padding: 0 10%;
        width: 80%;
    }
`;

const Badge = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;
    gap: 8px;
`
const Col = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
`
