import React from 'react';
import styled from 'styled-components';
import { Img } from '../../../common/Image';
import LogoDesktop from '../../../../assets/media/aden/logo-aden-simple-red.png';
import LogoMobile from '../../../../assets/media/aden/aden-red.png';
import { Button } from '@mui/material';

const Navbar = () => {

    const isMobile = window.innerWidth < 768;

    return (
        <NavbarWrapper>
            <LogoWrapper>
                {isMobile ? <Img w="auto" h="24px" src={LogoMobile} /> : <Img w="auto" h="40px" src={LogoDesktop} />}
            </LogoWrapper>
            <div />
        </NavbarWrapper>
    );
};

export default Navbar;

const NavbarWrapper = styled.div`
    width: calc(100% - 10rem);
    padding: 0 5rem;
    height: 64px;
    box-shadow: 0px 5px 20px 0px #0000000F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

    @media (width < 580px) {
        width:calc(100% - 2rem);
        padding: 0 1rem;
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    h2 {
        font-size: 18px;
        color: #b31d15;
    }

    @media (max-width: 650px) {
        img {
            height: 70px;
        }
    }

    @media (max-width: 420px) {
        img {
            height: 60px;
        }
    }

    @media (max-width: 380px) {
        img {
            height: 50px;
        }
    }
`

const ButtonFake = styled(Button)`
    height: 60%;
    margin: 0.5rem;
    padding: 0.5rem;
    @media (max-width: 770px) {
        width: fit-content;
        height: 70%;
    }

    span {
        font-size: 14px;
        @media (max-width: 770px) {
            font-size: 10px;
            font-weight: bold;
        }
    }
`;
