import { useState } from "react";
import {
    postComment,
    postContribution,
} from "../../redux/api/commentsCourse/commentsCourseApi";

const useCommentsCourse = () => {
    // STATES
    const [LoadingPostCont, setLoadingPostCont] = useState(false);
    const [errorPostCont, setErrorPost] = useState(null);
    const [upDateComments, setUpdateComments] = useState(false);

    // FUNCTIONS
    // RESETEAR ESTADO DEL ERROR A NULL
    const resetError = () => {
        setTimeout(() => {
            setErrorPost(null);
        }, 2000);
    };

    const resetUpdateComments = () => {
        setUpdateComments(false);
    };

    // CREAR UNA CONTRIBUCIÓN
    const createContribution = async (body) => {
        setLoadingPostCont(true);
        try {
            await postContribution(body);
            setErrorPost(false);
            setLoadingPostCont(false);
            resetError();
            setUpdateComments(true);
        } catch (error) {
            setLoadingPostCont(false);
            setErrorPost(true);
            resetError();
        }
    };

    // CREAR UNA COMENTARIO
    const createComment = async (body) => {
        setLoadingPostCont(true);
        try {
            await postComment(body);
            setErrorPost(false);
            setLoadingPostCont(false);
            resetError();
            setUpdateComments(true);
        } catch (error) {
            setLoadingPostCont(false);
            setErrorPost(true);
            resetError();
        }
    };

    return {
        LoadingPostCont,
        errorPostCont,
        upDateComments,
        createContribution,
        createComment,
        resetUpdateComments,
    };
};

export default useCommentsCourse;
