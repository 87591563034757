import axios from 'axios';

export function getOptions() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/pqrs/options`
    );
}

export async function getTicket(id) {
    return await axios
        .get(`${process.env.REACT_APP_SISAPI}/v1/acropolis/pqrs/${id}`)
        .then((response) => {
            return {
                status: true,
                data: response.data.response_data
            };
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 500) {
                    return {
                        status: false,
                        data: 'Hubo un error con el servidor'
                    };
                } else {
                    return {
                        status: false,
                        data: error.response.data.error
                            ? error.response.data.error
                            : 'Hubo un error'
                    };
                }
            }
            return {
                status: false,
                data: 'Hubo un error con el servidor'
            };
        });
}

export async function createTicket(values) {
    return await axios
        .post(`${process.env.REACT_APP_SISAPI}/v1/acropolis/pqrs`, values)
        .then((response) => {
            return {
                status: true,
                data: response.data.response_data
            };
        })
        .catch((error) => {
            if (error.response) {
                if (
                    error.response.status === 404 ||
                    error.response.status === 500
                ) {
                    return {
                        status: false,
                        data: 'Hubo un error con el servidor'
                    };
                } else {
                    return {
                        status: false,
                        data: error.response.data.error
                            ? error.response.data.error
                            : 'Hubo un error'
                    };
                }
            }

            return {
                status: false,
                data: 'Hubo un error con el servidor'
            };
        });
}
