import { useContext, useState } from "react";
import styled from "styled-components";

// Components
// Mover ModalCourseConditions mas cerca si es que es el único lugar donde se usa
import ModalCourseConditions from "../../../../../components/ui/organisms/subject/ModalCourseConditions";
import { Icon } from "@iconify/react";

// Context
import CourseSubjectContext from "../../../context/CourseSubjectContext";
import CommonButton from "../../../../../components/common/CommonButton";

const SubjectSecondaryInfo = (props) => {

    const {
        data: {
            courseConditions
        }
    } = useContext(CourseSubjectContext);

    const [open, setOpen] = useState(false);

    const pathname = window.location.href;

    const handleClickCourseConditions = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleClickConsultation = () => {
        if (pathname.includes('tab=0')) {
            window.location.href = pathname.replace(
                /(tab=)0/,
                '$14'
            );
        } else {
            window.location.href = `${pathname}&tab=4`;
        }
    }

    return (
        <>
            <ModalCourseConditions
                courseConditions={courseConditions}
                open={open}
                handleClose={handleClose}
            />
            <ButtonWrapper>
                <CommonButton 
                    label="Agendar consulta personalizada"
                    Icon={() => <Icon icon="mdi:calendar-today-outline" width="24px" height="24px" />}
                    variant="filled"
                    onClick={handleClickConsultation}
                />
                <CommonButton
                    label="Ver condiciones de cursado"
                    Icon={() => <Icon icon="fluent:document-table-checkmark-20-regular" width="24px" height="24px" />}
                    variant="outlined"
                    onClick={handleClickCourseConditions}
                    disabled={!courseConditions ? true : false}
                />
            </ButtonWrapper>
        </>

    )
}

export default SubjectSecondaryInfo;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    width: calc(100% - 56px);
    gap: 24px;
    padding: 24px 32px 24px 24px;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #A8A8A8;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;

    @media (width < 1068px) {
        justify-content: center;
        gap: 16px;
    }

    @media (width < 768px) {
        flex-direction: column;
        align-items: start;

        button {
            width: 100%;
        }
    }
`;