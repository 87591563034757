import React, { useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";

import { connect, useSelector } from "react-redux";
import ChatLayout from "../components/shared/chat-layout/ChatLayout";
import useLayout from "../hooks/useLayout";

const ErrorRoute = (props) => {
    const {
        layout = "common",
        isAuthenticated,
        blockedRoles,
        permissionRoles,
        user,
        blockedStatus,
        title = false,
        component: Component,
        ...rest
    } = props;

    const { pathname } = useLocation();
    const urlRedirect = pathname.slice(1);
    const { setMenu, clearMenu } = useLayout()

    // FUNCTION
    const renderLayout = (layout, Component) => {
        const cases = {
            common: <ChatLayout>{Component}</ChatLayout>,
        };

        return cases[layout] || cases["common"];
    };

    // RETURN
    return (
        <Route
            {...rest}
            component={(props) =>
                isAuthenticated ? (
                    renderLayout(layout, <Component {...props} />)
                ) : (
                    <Redirect to={`/auth`} />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.user,
    user: state.auth.user,
    blockedStatus: state.userStatus.blockedStatus,
});

export default connect(mapStateToProps)(ErrorRoute);
