import { Modal } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import CardAddress from "../../molecules/academic/CardAddress";
import FormSede from "../../molecules/academic/FormSede";
import FormAddress from "../../molecules/academic/FormAddress";
import { Form, Formik } from "formik";
import { upDateTitulation } from "../../../../redux/api/titulation/titulation";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../../../utils/snackStyles";
import { useDispatch } from "react-redux";
import { documentsActions } from "../../../../redux/actions";

const ModalAddress = (props) => {
  const {
    modalIsOpen,
    setModalIsOpen,
    setAddressComplete,
    addressComplete,
    setTypeDelivery,
    setData,
    documents,
    data,
    setExpanded,
    setIsShowCard,
  } = props;

  const [openSnackbar] = useSnackbar(optionsStyle);

  const dispatch = useDispatch();

  // State
  const [checkSede, setCheckSede] = useState(false);
  const [checkAddress, setCheckAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  // STATE INICIAL
  const sedeValues = {
    sede: "",
  };

  const addressValues = {
    country: "",
    city: "",
    zip: "",
    address: "",
  };

  // Functions
  const handleSede = () => {
    setCheckSede(!checkSede);
    setCheckAddress(false);
    setTypeDelivery("sede");
  };

  const handleAddress = () => {
    setCheckAddress(!checkAddress);
    setCheckSede(false);
    setTypeDelivery("domicilio");
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const req = await upDateTitulation(data);
    setLoading(false);

    if (!req.error) {
      documents.forEach((document) => {
        if (document.id === data.id_diploma) {
          document.destino_envio_titulo = data.destino;
          document.id_sede_envio_titulo = data.id_sede;
          document.direccion_envio_titulo = data.direccion;
          document.ciudad_envio_titulo = data.ciudad;
          document.id_pais_envio_titulo = data.id_pais;
          document.cod_postal_envio_titulo = data.cod_postal;
        }
      });
      setModalIsOpen(false);
      setIsShowCard(true);
      setTimeout(() => {
        setModalIsOpen(true);
      }, 1000);

      openSnackbar("¡Domicilio confirmado!");
      dispatch(documentsActions.getDocumentsRequest());
    }
    if (req.error) {
      openSnackbar("¡No se pudo actualizar la información!");
    }
  };

  const handleClose = () => {
    setExpanded(false);
  };

  return (
    <ModalFake onClose={handleClose} open={modalIsOpen}>
      <Wrapper>
        <WrapperCancel>
          <CancelIcon color="primary" onClick={handleClose} />
        </WrapperCancel>
        <Header>
          <span>MBA - Euncet - UPC</span>
          <h1>¿Dónde deseas recibir tu título?</h1>
        </Header>
        <Cards>
          <CardAddress
            icon="sede"
            title="Sede más cercana"
            statu={checkSede}
            handleStatus={handleSede}
          />
          <CardAddress
            icon="address"
            title="Mi domicilio"
            statu={checkAddress}
            handleStatus={handleAddress}
          />
        </Cards>
        <WrapperForm>
          <Formik
            initialValues={checkSede ? sedeValues : addressValues}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Container>
                {/** Formularios */}
                {checkSede && (
                  <FormSede
                    setFieldValue={setFieldValue}
                    values={values}
                    setAddressComplete={setAddressComplete}
                    addressComplete={addressComplete}
                    setData={setData}
                    data={data}
                    loading={loading}
                  />
                )}
                {checkAddress && (
                  <FormAddress
                    setFieldValue={setFieldValue}
                    values={values}
                    setAddressComplete={setAddressComplete}
                    addressComplete={addressComplete}
                    setData={setData}
                    data={data}
                    loading={loading}
                  />
                )}
              </Container>
            )}
          </Formik>
        </WrapperForm>
      </Wrapper>
    </ModalFake>
  );
};

export default ModalAddress;

const ModalFake = styled(Modal)`
  /* position: relative; */
`;

const Wrapper = styled.div`
  width: 500px;
  position: absolute;
  top: 50%;
  padding: 2rem;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 320px;
  height: auto;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
    0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
  background: white;
  outline: none;
`;

const Header = styled.div`
  text-align: center;
  h1 {
    color: #b31d15;
    font-size: 1.4rem;
    margin-top: 0.5rem;
  }
  span {
    color: #616161;
    font-size: 1rem;
  }
`;

const Cards = styled.div`
  display: flex;
  width: 100%;
  gap: 3rem;
  justify-content: center;
`;

const WrapperCancel = styled.div`
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
`;

const WrapperForm = styled.div``;

const Container = styled(Form)``;
