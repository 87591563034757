import { Button, Modal } from '@mui/material';
import styled from 'styled-components';
import usePauseAssignment from '../..//ui/molecules/studyPlan/hooks/usePauseAssignment';
import SimpleLoading from '../SimpleLoading';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const ModalProblem = (props) => {
    const {
        isOpen,
        handleCloseModal,
        data,
        idProgram,
        hash,
        id,
        isDiplomat,
        imageModal = false ,
    } = props;

    const { name } = data;

    const CONFIRM_IMG_ROUTE = imageModal ? `url("${imageModal}")` : 'url("/assets/aden/pregunta-electiva.png")';

    const { loading, error, postEnrollmentPause } = usePauseAssignment();

    const repoId = useSelector((state) => state?.auth?.user?.repo_id);
    const history = useHistory();

    const handleClick = () => {
        const body = {
            partnerId: repoId,
            programId: idProgram,
            assignment: data?.id,
            state: 'confirmado',
        };

        postEnrollmentPause(body);
    };

    useEffect(() => {
        if (error === false) {
            history.push(
                `../asignatura/${hash}?program=${id}&origin=${
                    isDiplomat ? 'diplomado' : 'plan'
                }`
            );
        }
        //eslint-disable-next-line
    }, [error]);

    return (
        <>
            <Modal
                onClose={handleCloseModal}
                open={isOpen}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.001)' }}
            >
                <Container>
                    <Image img={CONFIRM_IMG_ROUTE} />
                    <Title>
                        {!error ? 'Reanudación del cursado' : 'Hubo un error'}
                    </Title>
                    <Description>
                        ¿Deseas continuar tu cursado de la asignatura
                        <span> {name}</span>?
                    </Description>
                    {loading ? (
                        <SimpleLoading />
                    ) : (
                        <Row>
                            <CustomButton
                                onClick={handleCloseModal}
                                variant="outlined"
                                color="primary"
                            >
                                Lo haré luego
                            </CustomButton>

                            <CustomButton
                                onClick={handleClick}
                                variant="contained"
                                color="primary"
                            >
                                Si, retomar
                            </CustomButton>
                        </Row>
                    )}
                </Container>
            </Modal>
        </>
    );
};

export default ModalProblem;

const Container = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 20px;
    width: 405px;
    max-width: 90vw;
    height: fit-content;
    max-height: 90vh;
    padding: 2rem 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    overflow-y: auto;
`;

const Image = styled.div`
    width: 100%;
    background-image: ${(p) => p.img};
    background-position: center;
    height: 350px;
    background-size: contain;
`;

const Title = styled.h2`
    color: #b31d15;
    font-size: 1.2rem;
`;

const Description = styled.p`
    color: #222;
    font-size: 1rem;
    text-align: center;
    span {
        font-weight: bold;
    }
`;

const CustomButton = styled(Button)`
    border-radius: 100px;
`;

const Row = styled.div`
    display: flex;
    width: 90%;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-around;
`;
