import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { Icon } from '@iconify/react';
import { CustomLinearProgress } from '../../../../styled-components';
import LazyImg from '../../../../components/common/LazyImg';
import { Text } from '../../../../components/common/Texts';
import { base64Encode } from '../../../../utils/encrypt';
import noResource from '../../../../assets/media/campus/no-last_resource_found.png';
import tutorial from '../../../../assets/media/aden/tutorial.png';

const LastResourceSeen = ({ lastOpenedOAData, subjectId, resourceId }) => {
    const { name, image, lastOpenedResource, totalProgress, id } =
        lastOpenedOAData;

    const history = useHistory();
    const courseHash = base64Encode(id);
    const subjectHash = base64Encode(subjectId);
    const resourceHash = base64Encode(resourceId);

    const handleRedirect = () => {
        history.push(
            `/curso/${courseHash}?id=${subjectHash}&activity=${resourceHash}`
        );
    };

    const isDefaultImage = image?.includes('4328');

    const validatedImage = isDefaultImage ? tutorial : image;

    if (typeof id === 'string') {
        return (
            <Wrapper>
                <NoResourceWrapper>
                    <Text color="#222222" fontSize="14px" fontWeight="700">
                        Cuando comiences con tu cursado,{' '}
                        <b>podrás continuarlo desde aquí.</b>
                    </Text>
                    <LazyImg
                        src={noResource}
                        backgroundColor="#ffffff"
                        width="fit-content"
                        height="160px"
                    />
                </NoResourceWrapper>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Header>
                <RedirectButton
                    onClick={handleRedirect}
                    variant="text"
                    color="primary"
                >
                    Seguir cursando
                </RedirectButton>
                <LazyImg
                    src={validatedImage}
                    height="100%"
                    width="100%"
                    borderRadius="20px 20px 0 0"
                    isDefault={image?.includes('4328')}
                />
            </Header>
            <Body>
                <CustomLinearProgress
                    variant="determinate"
                    value={totalProgress}
                />
                <CurrentResourceWrapper>
                    <Icon icon="ph:play-circle" />
                    <Text color="#222222" fontSize="13px" fontWeight="400">
                        {lastOpenedResource.publicationName}
                    </Text>
                </CurrentResourceWrapper>
                <NameWrapper>
                    <Text color="#616161" fontSize="14px" fontWeight="700">
                        <span>Módulo: </span> {name}
                    </Text>
                </NameWrapper>
            </Body>
        </Wrapper>
    );
};

export default LastResourceSeen;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 295px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);

    @media (max-width: 1440px) {
        width: 100%;
        height: 305px;
    }

    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;

const Header = styled.section`
    position: relative;
    width: 100%;
    height: 158px;

    &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 158px;
        margin: auto;
        top: 0;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 20px 20px 0 0;
        transition: all 0.2s ease-in;
    }

    &:hover::after {
        background: rgba(0, 0, 0, 0.5);
        transition: all 0.2s ease-in;
    }

    svg {
        background-color: #ffffff;
    }
`;

const Body = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 137px;
    padding: 1rem;
`;

const CurrentResourceWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        min-width: 20px;
        min-height: 20px;
        padding: 4px;
        border-radius: 50%;
        color: #ffffff;
        background: #000000;
    }
`;

const NameWrapper = styled.div`
    display: flex;
    justify-content: flex-start;

    p {
        margin-left: 0;
    }

    span {
        font-weight: 400;
    }
`;

const RedirectButton = styled(Button)`
    position: absolute;
    box-shadow: none;
    border-radius: 50px;
    z-index: 10;
    background-color: #ffffff;
    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    height: 40px;
    width: 160px;

    &:hover {
        background-color: #ffffff;
    }
`;

const NoResourceWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    p {
        padding: 0 1.5rem;
        line-height: 20px;
    }

    b {
        font-weight: 900;
    }
`;
