import React from 'react';
import * as encode from 'nodejs-base64-encode';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Helpers
import { titleHTML } from '../../helpers/title.helper';

// Context
import { CourseSubjectContextProvider } from './context/CourseSubjectContext';

// Hooks
import Subject from './Subject';

export default function CourseSubject() {
    titleHTML('Asignatura');

    const { id } = useParams();
    const repo_id = useSelector((state) => state.auth.user.repo_id);

    const hash = encode.decode(id, 'base64');

    return (
        <CourseSubjectContextProvider hash={hash} repoId={repo_id}>
            <Subject />
        </CourseSubjectContextProvider>
    );
}