import React from "react";
import useInstructors from "../../../../hooks/teacher/useInstructors";
import SimpleLoading from "../../../common/SimpleLoading";
import SubjectInstructors from "../../../../pages/subject/components/subjectTabs/SubjectInstructors";

const Instructors = (props) => {
  const { idCourse, isAcademic = false } = props;

  const { instructors } = useInstructors(idCourse);

  if (!instructors) {
    return <SimpleLoading />;
  }

  return (
    <div>
      <SubjectInstructors descr={{ instructors }} isAcademic={isAcademic} />
    </div>
  );
};

export default Instructors;
