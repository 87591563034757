import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { Span, Text } from '../../../common/Texts'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useTheme from '../../../../hooks/useTheme'

const SliderMicrolearning = (props) => {

    const { resources } = props

    const { primary } = useTheme()

    const [currenntPosition, setCurrenntPosition] = useState(0)

    const handleNext = () => {
        setCurrenntPosition(currenntPosition === resources.length - 1 ? 0 : currenntPosition + 1)
    }

    const handleBack = () => {
        setCurrenntPosition(currenntPosition === 0 ? resources.length - 1 : currenntPosition - 1)
    }

    console.log(currenntPosition);

    if (!resources) {
        return false
    }

    return (
        <div>
            <VideoWrapper>
                <ReactPlayer
                    url={resources[currenntPosition]?.video_url}
                    controls={true}
                    width="100%"
                    height="100%"
                    playsinline
                />
            </VideoWrapper>
            <Body>
                <ButtonsWrapper>
                    <IconButton onClick={handleBack} size="large"><ArrowBackIcon/></IconButton>
                    <IconButton disabled size="large"><ShareIcon/></IconButton>
                    <IconButton onClick={handleNext} size="large"><ArrowForwardIcon/></IconButton>
                </ButtonsWrapper>
                <Text fontSize="18px" color={primary}>{resources[currenntPosition]?.publication_name}</Text>
                <Text>Por <Span fontWeight="bold">{resources[currenntPosition]?.acropolis_author || 'No registrado'}</Span></Text>
            </Body>
        </div>
    );
}

export default SliderMicrolearning

const VideoWrapper = styled.div`
    height: 300px;
    background-color: #333333;
`

const Body = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 7px;
`

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`