import React, { useState } from 'react';
import styled from 'styled-components';
import { School, ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { toTitleCase } from '../../../../../helpers/titleCase.helper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Tooltip } from '@mui/material';
import GraduateStudentModal from './modals/graduate/GraduateStudentModal';

const StudentCard = (props) => {
    const {
        name,
        photo,
        status,
        type,
        info,
        idA,
        viewAcademic,
        id,
        handleSelectedStudent,
        grades,
        setSuccessfullGraduation
    } = props;

    const history = useHistory();

    // STATE
    const [arrowStatus, setArrowStatus] = useState(false);
    const [open, setOpen] = useState(false);

    // FUNCTIONS
    const handleArrow = () => {
        setArrowStatus(!arrowStatus);
    };

    const handleModal = () => {
        setOpen(!open);
    };

    const handleProfileRedirection = () => {
        history.push(`/profesor/alumno/${info?.crm_id}`);
    };

    return (
        <Wrapper id={id}>
            <InfoWrapper>
                <UserInfo onClick={handleProfileRedirection}>
                    <FakeTitle title={toTitleCase(name)}>
                        {toTitleCase(name)}
                    </FakeTitle>
                </UserInfo>
                <Tooltip
                    title="Egresar alumno"
                    arrow
                    placement='top'
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -12],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <IconWrapper onClick={handleModal}>
                        <School />
                    </IconWrapper>
                </Tooltip>
            </InfoWrapper>
            {type === 'details' ? (
                <ButtonFake onClick={handleArrow}>
                    {arrowStatus ? (
                        <ArrowForwardIcon color="primary" />
                    ) : (
                        <ArrowBack color="primary" />
                    )}
                </ButtonFake>
            ) : null}
            {open && (
                <GraduateStudentModal
                    open={open}
                    onClose={handleModal}
                    student={info}
                    setSuccessfullGraduation={setSuccessfullGraduation}
                />
            )}
        </Wrapper>
    );
};

export default StudentCard;

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: 100%;
`;

const FakeTitle = styled.h2`
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
    color: #222222;

    &:hover {
        text-decoration: underline #222222;
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 25px;
    align-items: center;
`;

const UserInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    cursor: pointer;

    svg {
        color: #999999;
    }
`;

const ButtonFake = styled.button`
    padding: 0.5rem;
    border-radius: 50%;
    &:hover {
        cursor: pointer;
        background-color: #fce9e8;
    }
    svg {
        font-size: 2rem;
    }
`;
