import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { financesActions } from "../../../../redux/actions";
import SimpleLoading from "../../../common/SimpleLoading";
import FinanceItem from "../../molecules/academic/FinanceItem";
import ErrorResponse from "../../../common/ErrorResponse";
import { Button } from "@mui/material";

const Finance = (props) => {
  const { handleSetStateFinance } = props;
  // REDUX
  const dispatch = useDispatch();
  const { finances, errorList } = useSelector((state) => state.finances);

  // EFFECTS
  useEffect(() => {
    if (finances === null) {
      dispatch(financesActions.getFinancesRequest());
    }
  }, []);

  const restartPay = () => {
    dispatch(financesActions.getFinancesRequest());
  };

  // RETURN
  if (finances === null && errorList === null) {
    return <SimpleLoading padding="4rem" />;
  }

  if (finances === null || finances?.error) {
    return (
      <ErrorResponse
        message={"No se pudieron cargar los pagos"}
        button={
          <Button onClick={restartPay} color="primary" variant="contained">
            Reintentar
          </Button>
        }
      />
    );
  }

  return (
    <>
      {finances?.productos_academicos?.length > 0 &&
        finances?.productos_academicos?.map((finance, index) => {
          return <FinanceItem finance={finance} key={index} />;
        })}
      {finances?.productos_comerciales?.length > 0 &&
        finances?.productos_comerciales?.map((finance, index) => {
          return <FinanceItem finance={finance} key={index} />;
        })}
      {finances?.productos_academicos?.length === 0 &&
        finances?.productos_comerciales?.length === 0 && (
          <NoData>No se encuentran datos</NoData>
        )}
    </>
  );
};

export default Finance;

const NoData = styled.div`
  font-size: 1.3rem;
  color: #b31d15;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
`;