export const coursesAdapter = (courseEnrollments) => {
    const enrollments = courseEnrollments.enrollments;
    const generalTags = courseEnrollments.general_tags;

    return {
        enrollments: enrollments.map((enrollment) => ({
            type: enrollment.type,
            state: enrollment.state,
            description: enrollment.description,
            id: enrollment.detail.id,
            publicationName: enrollment.detail.publication_name,
            courseDescription: enrollment.detail.description,
            tags: enrollment.detail.tags.map((tag) => ({
                id: tag.id,
                name: tag.name,
            })),
            project100: enrollment.detail.project_100,
            image: enrollment.detail.image_url,
            progress: enrollment.detail.progress,
        })),
        generalTags: generalTags.map((tag) => ({
            id: tag.id,
            name: tag.name,
        })),
    };
};
