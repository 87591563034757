import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFinancesOfStudentServices } from '../../../../redux/api/academic/getConditionByStudent';
import ErrorResponse from '../../../common/ErrorResponse';
import FinanceItem from '../../../ui/molecules/academic/FinanceItem';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../../../../utils/snackStyles';

const FinancialStatement = (props) => {

    const { handleSetStateFinance } = props;

    const [finances, setFinances] = useState(null);
    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!finances) getFinancesOfStudent();
    }, [finances]);

    useEffect(() => {
        if (finances !== null) {
            countFinances(finances);
        }
    }, [finances]);

    //Get finances for Id
    const getFinancesOfStudent = async () => {
        const request = await getFinancesOfStudentServices();
        if (request.error) {
            openSnackbar("¡No se pudieron cargar los datos de la Analítica!");
            setFinances(false);
            return;
        }
        setFinances(request);
    };

    //count Finances
    const countFinances = (finances) => {
        finances.map((fin) => {
            if (fin.estado === 'pendiente') {
                handleSetStateFinance(false);
                return false;
            }
            handleSetStateFinance(false);
            return false;
        });
    };

    const normalizeStatus = (status) => {
        if (status === 'pagado_total') {
            return 'Pagado total';
        } else if (status === 'pendiente') {
            return 'Pendiente';
        } else if (status === 'Pendiente') {
            return 'Pendiente';
        } else if (status === 'cancelado') {
            return 'Cancelado';
        } else if (status === 'procesando_pago') {
            return 'Procesando pago';
        } else if (status === 'reembolsado') {
            return 'Reembolsado';
        } else if (status === 'reprogramado') {
            return 'Reprogramado';
        } else if (status === 'pagado_parcial') {
            return 'Pagado parcial';
        } else if (status === 'rechazado') {
            return 'Rechazado';
        }
    };

    // RETURN
    if (finances === null) {
        return (
            <ErrorResponse message={'No se pudieron cargar los pagos'} />
        );
    }

    return (
        <>
            {finances.length > 0 ? (
                finances.map((finance) => {
                    return (
                        <FinanceItem
                            finance={finance}
                            key={finance.id}
                            normalizeStatus={normalizeStatus}
                        />
                    );
                })
            ) : (
                <NoData>No se encuentran datos</NoData>
            )}
        </>
    );

};

export default FinancialStatement;

const NoData = styled.div`
    font-size: 1.3rem;
    color: #b31d15;
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
`;
