import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useCoursesTeacher from "../../../../hooks/useCoursesTeacher";
import CourseCardRounded from "../../../common/CourseCardRounded";
import SimpleLoading from "../../../common/SimpleLoading";
import SplitButton from "../../../common/SplitButton";
import ErrorResponse from "../../../common/ErrorResponse";
import { useSelector } from "react-redux";
import CourseCardCanvas from "./CourseCardCanvas";
import useCoursesCanvas from "../../../../hooks/useCoursesCanvas";

const CoursesTeacher = () => {
    const options = [
        "Por defecto",
        "Más antiguo",
        "Más nuevo",
        "rol profesor",
        "rol director",
    ];

    const [currentFilter, setCurrentFilter] = useState({
        index: 0,
        name: "Por defecto",
    });

    const { courses } = useCoursesTeacher();
    const { coursesCanvas, handleCoursesCanvas } = useCoursesCanvas();

    const [customCourses, setCustomCourses] = useState(courses);

    const professorSisId = useSelector(
        (state) => state?.auth?.user?.repositorio_data?.professor_sis_id
    );

    useEffect(() => {
        courses !== null && setCustomCourses(courses);
    }, [courses]);

    // STATES
    const [activeTab, setActiveTab] = useState(1);

    const tabs = [
        {
            id: 1,
            name: "Acrópolis",
        },
        {
            id: 3,
            name: "Acropolis LAB"
        },
        {
            id: 2,
            name: "Canvas",
        },
    ];

    const acropolisCourses = !!customCourses ? customCourses.filter((course) => course?.ic === false) : null;

    const acropolisLABCourses = !!customCourses ? customCourses.filter((course) => course?.ic === true) : null;

    // FUNCTIONS
    const handleOption = (args) => {
        setCurrentFilter(args);
        args.index === 0 &&
            setCustomCourses(courses.sort((a, b) => a.id > b.id));
        args.index === 1 &&
            setCustomCourses(
                courses.sort((a, b) => a.fecha_deadline > b.fecha_deadline)
            );
        args.index === 2 &&
            setCustomCourses(
                courses.sort((a, b) => a.fecha_deadline < b.fecha_deadline)
            );

        args.index === 3 &&
            setCustomCourses(
                courses.filter((course) => course.rol[0] === "PROFESOR")
            );
        args.index === 4 &&
            setCustomCourses(
                courses.filter((course) => course.rol[0] === "DIRECTOR")
            );
    };

    const handleOptionCanvas = (args) => {
        setCurrentFilter(args);
        args.index === 0 &&
            handleCoursesCanvas(
                coursesCanvas.sort(
                    (a, b) => a.canvas_course_id > b.canvas_course_id
                )
            );
        args.index === 1 &&
            handleCoursesCanvas(coursesCanvas.sort((a, b) => a.date > b.date));
        args.index === 2 &&
            handleCoursesCanvas(coursesCanvas.sort((a, b) => a.date < b.date));
    };

    if (customCourses === false || coursesCanvas === false) {
        return (
            <CoursesWrapper>
                <ErrorResponse />
            </CoursesWrapper>
        );
    }

    if (customCourses === null) {
        return (
            <CoursesWrapper>
                <SimpleLoading />
            </CoursesWrapper>
        );
    }

    return (
        <CoursesWrapper>
            <Header>
                <WrapperTabs>
                    {tabs.map((tab) => {
                        if ((professorSisId === null || professorSisId === false) && (tab?.id === 2)) {
                            return null;
                        }
                        if (acropolisLABCourses.length === 0 && tab?.id === 3) {
                            return null;
                        }
                        return (
                            <Title
                                key={tab.id}
                                onClick={() => setActiveTab(tab.id)}
                                active={tab.id === activeTab ? true : false}
                            >
                                {tab.name}
                            </Title>)
                    }
                    )}
                </WrapperTabs>

                <SplitButton
                    handleOption={
                        activeTab === 1 ? handleOption : handleOptionCanvas
                    }
                    options={options}
                />
            </Header>
            <Grid>
                {activeTab === 1 ? (
                    <>
                        {currentFilter !== null && (
                            <>
                                {acropolisCourses
                                    .map((course, index) => (
                                        <CourseCardRounded
                                            key={index}
                                            values={course}
                                        />
                                    ))}
                            </>
                        )}
                    </>
                ) : activeTab === 3 ? (
                    <>
                        {currentFilter !== null && (
                            <>
                                {acropolisLABCourses
                                    .map((course, index) => (
                                        <CourseCardRounded
                                            key={index}
                                            values={course}
                                        />
                                    ))}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {coursesCanvas === null ? (
                            <SimpleLoading />
                        ) : (
                            <>
                                {coursesCanvas.map((courses, index) => (
                                    <CourseCardCanvas
                                        key={index}
                                        values={courses}
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}
            </Grid>
        </CoursesWrapper>
    );
};

export default CoursesTeacher;

const CoursesWrapper = styled(Card)`
    display: grid;
    grid-area: courses;
    height: auto;
    border-radius: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 2rem 1.5rem;
`;

const Grid = styled.div`
    margin: 1rem 0 0 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;

    @media (max-width: 768px) {
        justify-items: center;
    }

    @media (max-width: 400px) {
        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const WrapperTabs = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const Title = styled.div`
    cursor: pointer;
    color: ${(p) => (p.active ? "#b31d15" : "#555555")};
    font-weight: 700;
    font-size: 1rem;
    background-color: #fff;
    border: ${(p) => (p.active ? "0.5px solid #f3f3f3" : "none")};
    box-shadow: ${(p) =>
        !!p.active
            ? `0px 3px 5px rgba(0, 0, 0, 0.15), 
        0px 5px 10px rgba(0, 0, 0, 0.06)`
            : "none"};
    border-radius: 5px;
    padding: 8px 14px;
    transition: all 0.3s ease-in-out;

    :hover {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15),
            0px 5px 10px rgba(0, 0, 0, 0.06);
    }
`;
