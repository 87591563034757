import axios from "axios";

export function getDocuments() {
    return axios.get(`${process.env.REACT_APP_SISAPI}/diplomas`);
    // return axios.get(`https://sisapi.aden.org/api/diplomas`);
}

export function saveDocument(pdf) {
    return axios.post(`${process.env.REACT_APP_SISAPI}/documentos`, pdf);
    // return axios.post(`https://sisapi.aden.org/api/documentos`,pdf);
}

export function updateDocument(data) {
    return axios.patch(
        `${process.env.REACT_APP_SISAPI}/documentos/${data.id}`,
        data.pdf
    );
    // return axios.patch(`https://sisapi.aden.org/api/documentos/${data.id}`,data.pdf);
}

export const getDocumentCheck = async (partner_id, sis_id) => {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/documentacion_estudiantes/state_documentation?partner_id=${partner_id}&sis_id=${sis_id}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return null;
    }
};
