import React from "react";
import GridCard from "../../../common/GridCard";
import styled from "styled-components";
import useCrmStudents from "../../../../hooks/academic/useCrmStudents";
import CardWhithSocialMedia from "../../../common/teacher-academic/CardWhithSocialMedia";
import { InputAdornment, TextField } from "@mui/material";
import { SearchRounded } from '@mui/icons-material';
import SimpleLoading from "../../../common/SimpleLoading";

const CrmStudents = () => {
  const { initialStudents, loading, students, handleStudents } =
    useCrmStudents();

  const handleFilter = (e) => {
    handleStudents(
      initialStudents.filter((student) =>
        student.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  if (loading) {
    return <SimpleLoading />;
  }

  return (
    <Wrapper>
      <TextField
        // value={filterField}
        onChange={handleFilter}
        variant="outlined"
        size="small"
        label="Buscar estudiantes..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
      />
      <GridCard
        cards={students || initialStudents}
        component={CardWhithSocialMedia}
      />
    </Wrapper>
  );
};

export default CrmStudents;

const Wrapper = styled.div``;
