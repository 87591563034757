import React from 'react';
import styled from 'styled-components';

const InstructorsAbout = (props) => {
    const { instructors } = props;
    return (
        <>
            <Title>Instructores</Title>
            <BodyWrapper>
                {instructors.map((instructor) => {
                    return (
                        <InfoWrapper key={instructor.name}>
                            <ImgContent>
                                <img src={instructor.photo} alt="" />
                            </ImgContent>
                            <BodyContent>
                                <h3>{instructor.job_instructor}</h3>
                                <div>
                                    <h2>{instructor.name}</h2>
                                    <p>{instructor.job_title}</p>
                                </div>
                            </BodyContent>
                        </InfoWrapper>
                    );
                })}
            </BodyWrapper>
        </>
    );
};

export default InstructorsAbout;

const Title = styled.h2`
    margin-top: 3rem;
    color: #222222;
    font-weight: 900;
    font-size: 1.5rem;
`;
const BodyWrapper = styled.div`
    margin-top: 1.5rem;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
`;
const InfoWrapper = styled.div`
    display: flex;
    flex-basis: calc(50% - 1rem);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;

    @media (max-width: 1170px) {
        flex-basis: calc(100% - 1rem);
    }
`;

const ImgContent = styled.div`
    width: 100%;
    flex-basis: calc(15% - 0.5rem);
    img {
        min-width: 70px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
`;

const BodyContent = styled.div`
    flex-basis: calc(85% - 0.5rem);
    margin-left: 0.5rem;
    h3 {
        color: #616161;
        font-size: 0.7rem;
        text-transform: uppercase;
    }
    div {
        margin-top: 0.5rem;
        h2 {
            font-size: 1.2rem;
        }
        p {
            color: #222222;
            text-transform: uppercase;
            font-size: 0.9rem;
        }
    }
`;
