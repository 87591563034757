import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getAdsByRolAndCompany } from "../../../../redux/api/blog/blogApi";

const AdsBanner = () => {
    const { activeRol } = useSelector((state) => state.auth);
    // POR AHORA SOLO HAY UNA COMPANY QUE ES ADEN Y ES EL NÚMERO 0 EN REPO ID
    const company = activeRol === "ondemand" ? 0 : null;

    //STATES
    const [advertisements, serAdvertisements] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (advertisements === null) {
            handleAds();
        }
    }, []);

    //FUNCTIONS
    const handleAds = async () => {
        const response = await getAdsByRolAndCompany(company, activeRol);
        if (!!response.error) {
            serAdvertisements([]);
        } else {
            serAdvertisements(response.data);
        }
    };

    // console.log("advertisements", advertisements);

    if (!advertisements) {
        return <Skeleton h="485px" w="100%" />;
    }

    return (
        <>
            {advertisements.length > 0 && (
                <CarouselFake
                    transitionMs={500}
                    showArrows={false}
                    autoPlaySpeed={5000}
                    itemsToShow={1}
                    enableAutoPlay
                >
                    {advertisements.map((ad) => (
                        <WrapperAd
                            onClick={() => {
                                if (!!ad.attributes.link) {
                                    window.open(ad.attributes.link);
                                }
                            }}
                            img={
                                !!ad.attributes.bg_image.data.attributes.url
                                    ? `${process.env.REACT_APP_CMS_ADEN}${ad.attributes.bg_image.data.attributes.url}`
                                    : !!ad.attributes.bg_image_url
                                    ? ad.attributes.bg_image_url
                                    : "https://st.depositphotos.com/1137733/2616/i/450/depositphotos_26160981-stock-photo-kid-shouting-through-megaphone.jpg"
                            }
                        >
                            <Tag>{ad.attributes.tag}</Tag>
                            <Body>
                                <Title>{ad.attributes.title}</Title>
                                <Description>
                                    {ad.attributes.description}
                                </Description>
                            </Body>
                        </WrapperAd>
                    ))}
                </CarouselFake>
            )}
        </>
    );
};

export default AdsBanner;

const CarouselFake = styled(Carousel)`
    height: fit-content;
    position: relative;
    border-radius: 20px;
    width: 100%;
    padding: 0;

    .rec-slider-container {
        margin: 0;
    }

    /* .rec.rec-arrow {
        display: none;
        visibility: hidden;
    } */

    .rec.rec-pagination {
        position: absolute;
        bottom: 1rem;
        left: 1.5rem;

        button {
            background: #b5b6b6;
            box-shadow: none;
        }
        .rec-dot_active {
            border: none;
            background-color: #fff;
        }
    }
`;

const WrapperAd = styled.div`
    width: calc(100% - 3rem);
    padding: 1rem;
    background-image: url(${(p) => p.img});
    background-position: center;
    background-size: cover;
    object-fit: content;
    height: fit-content;
    cursor: pointer;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 2rem;
`;

const Tag = styled.div`
    padding: 0.3rem 0.4rem;
    width: fit-content;
    color: #fff;
    background-color: #b31d15;
    border-radius: 5px;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 120px;
`;

const Title = styled.h2`
    color: #fff;
    font-size: 24px;
`;

const Description = styled.p`
    color: #fff;
    font-size: 18px;
`;
