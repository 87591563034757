import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import SimpleLoading from "../../components/common/SimpleLoading";
import Banner from "../../components/templates/ecommerce/Banner";
import BodyLanding from "../../components/ui/organisms/ecommerce/BodyLanding";
import FooterLanding from "../../components/ui/organisms/ecommerce/FooterLanding";
import { titleHTML } from "../../helpers/title.helper";
import { getProductById } from "../../redux/api/ecommerce";

// import Banner from "../../../components/ecommerce/templates/Banner";

const LandingProduct = () => {
    titleHTML("E-commerce");

    const { id } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        if (!product) {
            getProduct();
        }
    }, []);

    const getProduct = async () => {
        const request = await getProductById(parseInt(id, 10));
        if (request.error) {
            return;
        }
        setProduct(request);
    };

    if (!product) {
        return <SimpleLoading />;
    }

    return (
        <Body>
            <BannerWrapper bg={product.image}>
                <Banner
                    name={product.name}
                    categories={product.categories}
                    description={product.description}
                    price={product.price}
                    currency={product.currency}
                    about={product.about}
                />
            </BannerWrapper>
            <BodyWrapper>
                <BodyLanding
                    about={product.about}
                    program={product.program}
                    review={product.review}
                    frequent_questions={product.frequent_questions}
                />
            </BodyWrapper>
            <FooterWrapper>
                <FooterLanding />
            </FooterWrapper>
        </Body>
    );
};

export default LandingProduct;

const Body = styled.div`
    background: #f9f9f9;
`;

const BannerWrapper = styled.div`
    width: 100%;
    min-height: 400px;
    height: 400px;
    background-color: #353535;
    /* background-size: 100%; */
    background-image: url(${(p) => p.bg});
    background-repeat: no-repeat;
    background-position: start;
    background-size: cover;
`;

const BodyWrapper = styled.div`
    width: calc(100% - 20rem);
    padding: 2rem 10rem;

    @media (max-width: 1170px) {
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        padding: 2rem 1rem;
        margin: 0 auto;
    }
`;

const FooterWrapper = styled.div`
    width: 100%;
    background-color: #333333;
    min-height: 300px;
    overflow: hidden;
    @media (max-width: 1775px) {
        background-color: #fff;
        min-height: 200px;
    }
`;
