import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import GenericChips from "./GenericChip";
import { Img } from "./Image";
import { Text } from "./Texts";
import DescriptionIcon from "@mui/icons-material/Description";
import { useHistory } from "react-router-dom";

const CourseCardRounded = (props) => {
    const { values, academic } = props;
    const history = useHistory();

    const getPrograms = (programs) => {
        if (!programs) return;
        return programs.map((program) => (
            <Text fontWeight="600" fontSize=".8rem" color="#888888">
                {" "}
                - {program.split("-")[1]}
            </Text>
        ));
    };

    return (
        <CourseCardWrapper
            //onClick={() => history.push("/speedgrader")}
            onClick={() =>
                history.push(
                    academic === "academic"
                        ? `/academico/curso/${values.id}`
                        : `/profesor/curso/${values.id}`
                )
            }
            title={values.name || "Asignatura sin nombre"}
        >
            <ImageBanner>
                {values?.rol?.length > 0 && (
                    <ChipContainer>
                        {values?.rol.map((rol) => (
                            <Chip key={rol}>{rol}</Chip>
                        ))}
                    </ChipContainer>
                )}
                <ImgFake
                    w="100%"
                    height="100%"
                    loading="lazy"
                    src={values.imagen || values.image}
                />
                {values.pending_correction > 0 && (
                    <Notifications>
                        <WrapperIcon>
                            <DescriptionIcon />
                            <Number>{values.pending_correction}</Number>
                        </WrapperIcon>
                    </Notifications>
                )}
            </ImageBanner>
            <Body>
                <GenericChips
                    title={`AULA ${
                        values?.tipo_aula?.toUpperCase() || "INDEFINIDA"
                    }`}
                    color="#888888"
                    textColor="white"
                    padding="3px 6px"
                    fontSize=".7rem"
                />
                <Title fontWeight="600" fontSize="1.1rem">
                    {values.name || "Asignatura sin nombre"}
                </Title>
                {getPrograms(values.programas) || ""}
            </Body>
        </CourseCardWrapper>
    );
};

export default CourseCardRounded;

const CourseCardWrapper = styled(Card)`
    width: 300px;
    height: 300px;
    border-radius: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    :hover {
        box-shadow: 0px 0px 5px 3px rgba(199, 195, 195, 0.73);
        -webkit-box-shadow: 0px 0px 5px 3px rgba(199, 195, 195, 0.73);
        -moz-box-shadow: 0px 0px 5px 3px rgba(199, 195, 195, 0.73);
        img {
            transform: scale(1.1);
        }
    }

    @media (max-width: 400px) {
        width: 265px;
        height: 265px;
    }
`;

const ImageBanner = styled.div`
    width: 100%;
    height: 160px;
    position: relative;
    border-bottom-left-radius: 30px;
`;

const Body = styled.div`
    height: 160px;
    background-color: white;
    position: relative;
    bottom: 20px;
    border-top-right-radius: 25px;
    padding: 15px;
    :before {
        content: "";
        width: 50px;
        height: 50px;
        position: absolute;
        top: -50px;
        left: 0;
        box-shadow: -30px 0 0px 0 white;
        border-bottom-left-radius: 25px;
    }
`;

const ImgFake = styled(Img)`
    transition: all 0.3s ease-in-out;
    position: relative;
    object-fit: cover;
`;

const Title = styled(Text)`
    margin: 20px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 1rem;
`;

const Notifications = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100px;
    height: 40px;
    position: absolute;
    right: 15px;
    bottom: 25px;
`;

const WrapperIcon = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    background-color: #b31d15;
    padding: 0.2rem 0.5rem;
    justify-content: center;
    border-radius: 16px;

    svg {
        font-size: 1rem;
        color: #fff;
    }
`;

const Number = styled.div`
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
`;

const ChipContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    flex-wrap: wrap;
    z-index: 3;
`;

const Chip = styled.div`
    border-radius: 10px;
    box-sizing: border-box;
    width: fit-content;
    padding: 0.25rem 0.4rem;
    background-color: #222;
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
`;
