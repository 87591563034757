import React from "react";
import styled from "styled-components";
import { Text } from "../../../../common/Texts";

const Commentary = (props) => {
  const { setCommentary } = props;

  const handleChange = (e) => {
    setCommentary(e.target.value);
  };

  return (
    <Wrapper>
      <Text fontWeight="400" fontSize="14px" color="#616161">
        Escribe un comentario sobre tu entrega
      </Text>
      <Textarea name="textarea" onChange={handleChange} />
    </Wrapper>
  );
};

export default Commentary;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 50%;
  height: 400px;
  padding: 26px 40px 26px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #f9f9f9;
  border: 1px solid #A8A8A8;

  @media (max-width: 768px) {
    width: 100%;
    
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  outline: none;
  border-radius: 10px;
  border: none;
  resize: none;
  border: 1px solid #A8A8A8;
`;
