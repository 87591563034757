import styled from 'styled-components';
import DefaultField from '../../../../../components/common/DefaultField';
import DefaultPhone from '../../../../../components/common/DefaultPhone';

const ContactData = (props) => {

    const { values, setFieldValue } = props;

    return (
        <Container>
            <FormContainer>
                <DefaultField
                    name="personal_email"
                    type="email"
                    label="Email"
                    value={values.personal_email}
                />
                <div />
                <DefaultPhone
                    name="phone"
                    label="Teléfono pricipal"
                    value={values.phone}
                    setFieldValue={setFieldValue}
                />
                <DefaultPhone
                    name="work_phone"
                    label="Teléfono secundario"
                    value={values.work_phone}
                    setFieldValue={setFieldValue}
                />
            </FormContainer>
        </Container>
    )
}

export default ContactData;

const Container = styled.div`
    width: 100%;
    max-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    @media (width < 768px) {
        height: 100%;
        max-height: 100%;
    }
`;

const FormContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8rem;
    row-gap: 1rem;
    padding-top: 1rem;

    @media (width < 768px) {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
`;
