import axios from 'axios';

// TRAE UN RECURSO DEL REPOSITORIO POR ID
export async function getResourceById(id, type) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/${id}?type=${type}`
        );
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}

// MARCA UN RECURSO COMO VISTO
export const viewResource = async (body) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/progress/`;

    try {
        const response = await axios.post(URL, body);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
};

// MARCA UN RECURSO COMO VISTO
export const getEvaluation = async (idResource, idEvaluation, idRepo, idOa = 1000) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/test/${idEvaluation}?user=${idRepo}&obj=${idOa}&resource=${idResource}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
};


// TRAE UN OA
export const getCourseById = (payload, origin) => {
    return axios.get(
        `${process.env.REACT_APP_REPO}/v1/repo_aden/objeto_aprendizaje/${payload}?origin=${origin || 'indefinido'}`
    );
}