import styled from "styled-components";
import useMyTasksFilters from "../../../../hooks/students/useMyTasksFilters";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Icon } from '@iconify/react';
import { useEffect } from "react";

const Filter = (props) => {

    const { submissionsByOa, setTasksFiltered, mobile } = props;

    const { handleOption, clearFilter, obtainLabelByValue, toggleActive, taskFilters, value, active, options } = useMyTasksFilters(submissionsByOa);

    useEffect(() => {
        setTasksFiltered(taskFilters)
    }, [taskFilters])

    return (
        <Container mobile={mobile}>
            {
                value !== "" && 
                <SelectedFilter onClick={clearFilter} >
                    {obtainLabelByValue(value)}
                    <Icon icon="material-symbols:close" width="18" height="18" />
                </SelectedFilter>
            }
            <SelectMenu>
                <SelectBox onClick={toggleActive}>
                    <span>Ordenar por</span>
                    {
                        active ? 
                        <ArrowDropUpIcon fontSize='small' /> :
                        <ArrowDropDownIcon fontSize='small' />
                    }
                </SelectBox>
                <OptionsList active={active}>
                    {
                        options.map((option) => (
                            <Option 
                            key={option.value}
                            onClick={() => handleOption(option)}
                            >
                                {option.label}
                            </Option>
                        ))
                    }
                </OptionsList>
            </SelectMenu>
        </Container>
    )
}

export default Filter;

const Container = styled.div`
    width: ${(p) => p.mobile ? "100%" : "50%"};
    height: 40px;
    display: ${(p) => p.mobile ? "none" : "flex"};
    justify-content: end;
    align-items: center;
    gap: 1rem;
    @media (max-width: 768px) {
        display: ${(p) => p.mobile ? "flex" : "none"};
    }
`

const SelectedFilter = styled.button`
    cursor: pointer;
    height: 32px;
    border: 1px solid #B31D15;
    color: #B31D15;
    background-color: #FCE9E8;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
`

const SelectMenu = styled.div`
    width: 120px;
    cursor: pointer;
    position: relative;
`

const SelectBox = styled.div`
    background-color: #F9F9F9;
    padding: 7px 10px 7px 10px;
    color: #a8a8a8;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
`

const OptionsList = styled.div`
    display: ${(p) => p.active ? "flex" : "none"} ;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    right: 0;
    width: 148px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
    border-radius: 5px;
    padding: 8px 0;
    max-height: 136px;
    z-index: 999;
`

const Option = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 2.5rem;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;
    transition: 3s ease-in-out;
`