import React from "react";
import styled from "styled-components";
import { Img } from "../Image";
import { Text } from "../Texts";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
// import AlertStatus from "./AlertStatus";
import * as encrypt from "nodejs-base64-encode";
import { useHistory, useParams } from "react-router-dom";
import SimpleLoading from "../SimpleLoading";

const StudentCardForAcademics = (props) => {
  const { firstInfo } = props;

  let { id } = useParams();

  const hashId = encrypt.encode(id + "", "base64");

  const history = useHistory();

  if (firstInfo === null) {
    return <SimpleLoading />;
  }

  return (
    <CardFake>
      <Wrapper>
        <Student>
          <ImgFake
            w="100%"
            height="100%"
            loading="lazy"
            src={firstInfo?.imagen}
          />
          <ProfileLink>
            <Text fontSize="1.125rem" fontWeight="bold" color="#222222">
              {firstInfo.nombre_completo}
            </Text>
            <TextProfile
              fontSize="0.875rem"
              fontWeight="400"
              color="#A8A8A8"
              onClick={() => {
                history.push(`../../perfil-publico/${hashId}`);
              }}
            >
              {/* link={url} */}
              VER PERFIL PÚBLICO <ArrowForwardRoundedIcon />
            </TextProfile>
          </ProfileLink>
        </Student>
        {firstInfo.fase_academica && (
          <Chip>{firstInfo.fase_academica || ""}</Chip>
        )}
      </Wrapper>
      {/* 
            <AlertStatus
                subtitle="Haz click para ver las acciones recomendadas"
                student={student}
            /> */}
    </CardFake>
  );
};

export default StudentCardForAcademics;

const CardFake = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: calc(100% - 3rem);
  padding: 1.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  gap: 1.5rem;
  align-items: center;
  border-radius: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Student = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ProfileLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    display: flex;
    align-items: center;
    gap: 0.3rem;

    svg {
      margin-top: 2.5px;
      font-size: 1.2rem;
    }
  }
`;

const ImgFake = styled(Img)`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  object-fit: cover;
`;

const Chip = styled.div`
  background: #e6ebf9;
  border-radius: 5px;

  width: fit-content;
  color: #5e80db;
  font-size: 12px;
  font-weight: bold;
  padding: 0.25rem 0.4rem;
`;

const TextProfile = styled(Text)`
  cursor: pointer;
`;
