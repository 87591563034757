import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SimpleLoading from '../../components/common/SimpleLoading';
import CourseInfo from '../../components/templates/academic/CourseInfo';
import CourseBanner from '../../components/templates/teacher/course/CourseBanner';
import { titleHTML } from '../../helpers/title.helper';
import useCourseById from '../../hooks/useCourseById';
import useLayout from '../../hooks/useLayout';

const AcademicCourse = () => {
    titleHTML("Curso Académico");


    const { id } = useParams()
    const { course } = useCourseById(id)
    const { setMenu } = useLayout()

    useEffect(() => {
        if (course?.name) {
            setMenu(course.name)
        }
    }, [course])
    
    if (!course) {
        return <SimpleLoading padding="4" />
    }

    // setMenu(course.name)

    return (
        <CoursePageWrapper>
            <CourseBanner img={course.img} title={course.name} status={course.level || 'no definido'} aula={course.modality || "no registrado"} />
            <CourseInfo course={course} id={id} />
        </CoursePageWrapper>
    );
};

export default AcademicCourse;

const CoursePageWrapper = styled.div`
    display: grid;
    margin: 0;
    padding: 0;
    width: 100%;
    align-items: center;
    overflow: initial;
`;
