import React from 'react';
import styled from 'styled-components';

import Text from '../../../common/Text';

const CardHistory = (props) => {
    const { activity } = props;

    // RETURN
    return (
        <Container>
            <Number isPositive={activity.amount > 0 ? true : false}>
                {activity.amount > 0 && '+'}
                {activity.amount}
                <strong>DP</strong>
            </Number>
            <TextWrapper>
                <Text
                    fontSize="0.75rem"
                    color="secondary"
                    textTransform="uppercase"
                >
                    {activity.category.name}
                </Text>
                <Text fontSize="1rem">{activity.name}</Text>
            </TextWrapper>
        </Container>
    );
};

export default CardHistory;

const Container = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 1rem;
    border-bottom: 1px dashed #bfbfbf;
`;

const Number = styled.span`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 0.1rem;
    width: 50px;
    font-size: 1rem;
    font-weight: 700;
    color: ${(p) => (p.isPositive ? '#3bd39c' : '#f55f4e')};

    strong {
        font-size: 1rem;
        font-weight: 900;
    }
`;

const CustomImg = styled.img`
    width: 1.25rem;
    height: 1.25rem;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;
