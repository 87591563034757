import axios from 'axios';

export function getWorkshops() {
    return axios.get(`${process.env.REACT_APP_REPO}/v1/repo_aden/workshops`);
}

export function getAllWorkshops() {
    try {
        const res = axios.get(`${process.env.REACT_APP_SISAPI}/v1/acropolis/graduation/get_ws_graduation`)
        return res
    } catch (error) {
        return {
            error
        }
    }
}

export function getWorkshopsById(repo_partner_id) {
    try {
        const response = axios.get(`${process.env.REACT_APP_SISAPI}/v1/acropolis/graduation/get_ws_graduation/${repo_partner_id}`)
        return response
    } catch (error) {
        return {
            error
        }
    }
}

export function getWorkshopById(payload, origin, assignmentId) {
    const assignmentQuery = `&id_asignatura=${assignmentId}`
    try {
        if (assignmentId && assignmentId !== "") {
            return axios.get(
                `${process.env.REACT_APP_REPO}/v1/repo_aden/objeto_aprendizaje/${payload}?origin=${origin || 'indefinido'}${assignmentQuery}`
            )
        } else {
            return axios.get(
                `${process.env.REACT_APP_REPO}/v1/repo_aden/objeto_aprendizaje/${payload}?origin=${origin || 'indefinido'}`
            );
        }
    } catch (error) {
        return {
            error
        }
    }
}
 