import React from 'react';
import ExperienciasCards from './ExperienciasCards';
import { Link } from 'react-router-dom';

import callBg from '../../img/bg/bg-experiencia.jpg';
import styled from 'styled-components';

const backgroundImg = {
    backgroundImage: `url(${callBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundAttachment: 'fixed'
};

const Experiencias = () => {
    return (
        <Container style={backgroundImg}>
            <ContainerCard>
                {/** Title */}
                <H1Card>#ExperienciaADEN</H1Card>
                {/** Grid */}
                <ExperienciasCards />
                {/** Info */}
            </ContainerCard>
            <DivExperiencias>
                <H1Title>Comienza hoy tu</H1Title>
                <H1Hashtag>#ExperienciaADEN</H1Hashtag>
                <LinkFake to="auth/registration">¡Regístrate ahora!</LinkFake>
            </DivExperiencias>
        </Container>
    );
};

export default Experiencias;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
`;

const ContainerCard = styled.div`
    width: 100%;
    background: linear-gradient(
            10.67deg,
            #860301 -15.78%,
            #b31d15 23.84%,
            #c0120a 58.8%,
            #f34341 96.08%
        ),
        linear-gradient(0deg, #b31d15, #b31d15);
    height: 500px;
`;

const H1Card = styled.h1`
    font-size: 2.5rem;
    font-weight: 900;
    color: #ffffff;
    margin: 3rem 0em;
    text-align: center;
`;

const DivExperiencias = styled.div`
    color: #ffffff;
    padding-top: 30em;
    margin-bottom: 2em;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const H1Title = styled.h1`
    font-size: 3rem;
`;

const H1Hashtag = styled.h1`
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 2rem;
`;

const LinkFake = styled(Link)`
    text-transform: none;
    background-color: #b31d15;
    color: #ffffff;
    border-radius: 5px;
    padding: 1rem 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;

    :hover {
        background-color: #921009;
        color: #ffffff;
    }
`;
