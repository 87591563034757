import axios from "axios";

export const getReviewQuestionsResource = async (resourceId) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/review_questions/${resourceId}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return error;
    }
}; 

export const postReviewQuestionsModule = async (resourceIds) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/review_questions_area`;

    try {
        const response = await axios.post(URL, { resource_ids: resourceIds });
        return response.data.response_data;
    } catch (error) {
        return error;
    }
}