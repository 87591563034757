import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import MenuUser from "./MenuUser";
import Messages from "./Messages";
import { MenuRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { getNotificationService } from "../../../app/modules/Services/chat.services";
import Menu from "../menu/Menu";
import UserDracmas from "./UserDracmas";
import useTheme from "../../../hooks/useTheme";
import Notifications from "./Notifications";
import { getNotificationFake } from "../../../redux/api/notifications";
/* import useLayout from "../../../hooks/useLayout"; */
import GoBack from "../../../modules/acropolisCommon/components/GoBack";
import AnnouncementsNotification from "./AnnouncementsNotification";
import { getAnnouncementsByRepoId } from "../../../redux/api/announcements";

const Navegation = (props) => {
  const { toggleMenu, menu, closeMenu, title } = props;
  // REDUX
  const { user, activeRol } = useSelector((state) => state.auth);
  const microlearnings = useSelector(
    (state) => state.microlearnings.microlearnings
  );

  const { secondary } = useTheme();

  // STATE
  const [messagesState, setMessagesState] = useState(false);
  const [notificationStateFake, setNotificationFake] = useState([]);
  const [announcements, setAnnouncements] = useState(null)

  useEffect(() => {
    if (microlearnings === null && user) {
      getMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microlearnings]);

  useEffect(() => {
    if (notificationStateFake.length === 0) {
      getNotificacion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (announcements === null) {
      getAnnouncements()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcements])

  // FUNCTIONS
  const getMessages = async () => {
    const notification = await getNotificationService(user.partner_id);
    if (Array.isArray(notification.result)) {
      setMessagesState(notification.result);
    }
  };

  const getNotificacion = async () => {
    const response = await getNotificationFake(activeRol);
    if (!response.error) {
      let result = response.sort((a, b) => b.id - a.id);
      setNotificationFake(result);
    } else {
      console.error(response.error);
    }
  };

  const getAnnouncements = async () => {
    const response = await getAnnouncementsByRepoId(user.repo_id);
    if (!response.error) {
      setAnnouncements(response)
    } else {
      console.error(response.error)
    }
  }

  // Recargar Notificaciones
  const refreshNotification = () => {
    getNotificacion();
  };

  const refreshAnnouncements = () => {
    getAnnouncements();
  }

  // RETURN
  return (
    <>
      <Header color={secondary}>
        <LogoWrapper>
          <FakeIconButton
            onClick={toggleMenu}
            data-tut="reactour__inicio_mobile"
          >
            <MenuRounded color="secondary" />
          </FakeIconButton>
          <Link to="/campus">
            <FakeImg loading="lazy" src="/assets/logo-aden.svg" alt="logo" />
          </Link>
          <MenuWrapper>
            <GoBack title={title} />
          </MenuWrapper>
        </LogoWrapper>
        <Nav>
          {/** Dracmas */}
          <UserDracmas />
          <AnnouncementsNotification
            announcements={announcements?.data}
            refreshAnnouncements={refreshAnnouncements}
          />
          {/* Notificaciones de mensajes */}

          <Messages messages={messagesState} />

          {/* Notificaciones */}
          <Notifications
            notifications={notificationStateFake}
            refreshNotification={refreshNotification}
          />

          {/** Menu del usuario */}
          <MenuUser />
        </Nav>
      </Header>
      <Menu menu={menu} closeMenu={closeMenu}></Menu>
    </>
  );
};

export default Navegation;

const Header = styled.header`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  left: 0;
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: ${(p) => p.color};
  z-index: 999;

  @media (max-width: 768px) {
    padding: 0 12px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-right: 1.5rem;
  width: 100%;

  @media (max-width: 1024px) {
    width: 55%;
  }

  @media (max-width: 768px) {
    width: 45%;
  }

  @media (max-width: 425px) {
    column-gap: 0.5rem;
    margin-right: 0;
  }
`;

const MenuWrapper = styled.div`
  width: 100%;
  overflow: hidden;

  @media (max-width: 425px) {
    column-gap: 0.5rem;
  }
`;

const FakeIconButton = styled(IconButton)`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const FakeImg = styled.img`
  max-height: 46px;

  @media (min-width: 768px) {
    margin-left: 4.5rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  column-gap: 1rem;

  @media (max-width: 425px) {
    column-gap: 0.5rem;
  }
`;

/* const InCompany = styled.img`
  height: 32px;

  @media screen and (max-width: 650px) {
    display: none;
  }
`; */
