import { useState, useEffect } from "react";
import { getAdvanceByAssignment } from "../../redux/api/teacher";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../utils/snackStyles";

const useRankingForAdvance = (id) => {

    const [openSnackbar] = useSnackbar(optionsStyle);

    const [advanceStudents, setAdvanceStudents] = useState(null);

    useEffect(() => {
        !advanceStudents && getStudentAdvance(id);
    }, [advanceStudents]);

    const getStudentAdvance = async () => {
        const request = await getAdvanceByAssignment(id);
        if (request.error) {
            // openSnackbar("¡No se pudieron cargar los estudiantes!");
            setAdvanceStudents(false);
            return;
        }
        setAdvanceStudents(request);
    };

    return {
        advanceStudents,
    };
};

export default useRankingForAdvance;
