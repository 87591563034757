import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import {
    HomeRounded,
    MenuBookRounded,
    PlayArrowRounded,
    SchoolRounded,
    EventRounded,
    CloseRounded,
    ContactsRounded,
} from "@mui/icons-material";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ComputerIcon from "@mui/icons-material/Computer";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import LocalMallIcon from "@mui/icons-material/LocalMall";

import { checkRoles } from "../../../helpers/roles.helper";
import AccordionNavBar from "./AccordionNavBar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const Menu = (props) => {
    const { menu, closeMenu } = props;

    const wrapperRef = useRef();

    const menuEducational = [
        {
            title: "Cursos abiertos",
            to: "/workshops",
            icon: <MenuBookRounded />,
            active: true,
        },
        {
            title: "Biblioteca de contenidos",
            to: "/biblioteca",
            icon: <PlayArrowRounded />,
            active: true,
        },
        {
            title: "Simulador 360",
            to: "/biblioteca?tab=simulador",
            icon: <PlayArrowRounded />,
            active: true,
        },
    ];

    const menuEvents = [
        {
            title: "Talleres presenciales",
            to: "/eventos?tab=physical-events",
            icon: <SchoolRounded />,
            active: true,
        },
        {
            title: "Clases sincrónicas",
            to: "/evento?tab=online-events",
            icon: <ComputerIcon />,
            active: true,
        },
        {
            title: "Consultas con tu profesor",
            to: "/chatear",
            icon: <FreeBreakfastIcon />,
            active: true,
        },
        {
            title: "Workshops internacionales",
            to: "/informacion-academica?tab=international-workshop",
            icon: <PublicRoundedIcon />,
            active: checkRoles("alumno"),
        },
    ];

    // const menuAcropolisLab = [
    //     {
    //         title: "Mis cursos",
    //         to: "/mi-empresa",
    //         icon: <MenuBookRounded />,
    //         active: !!ondemand,
    //     },

    //     {
    //         title: "Desafios",
    //         to: "/desafio",
    //         icon: <StarsIcon />,
    //         active:
    //             (!!ondemand?.challenge_url && !!ondemand?.enable_challenge) ??
    //             true,
    //     },
    // ];

    // EFFECTS
    // useEffect(() => {
    //     if (checkRoles("ondemand")) {
    //         setIsViewEvent(false);
    //     }
    //     if (checkRoles("ondemand") && checkRoles("alumno")) {
    //         setIsViewEvent(true);
    //     }
    // }, []);

    useEffect(() => {
        if (menu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menu]);

    // FUNCTIONS
    function handleClickOutside(e) {
        if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
            return;
        }
        closeMenu();
    }

    // RETURN
    return (
        <Aside menu={menu} ref={wrapperRef}>
            <ButtonMenu>
                <MenuIcon />
            </ButtonMenu>
            <AsideTop id="overflowY">
                <Header menu={menu}>
                    <IconButton onClick={closeMenu} size="large">
                        <CloseRounded fontSize="default" color="secondary" />
                    </IconButton>
                </Header>
                <ListMenu id="menu">
                    {/* Home */}
                    <MenuItem
                        title="Inicio"
                        to="/campus"
                        icon={<HomeRounded color="secondary" />}
                        closeMenu={closeMenu}
                        data_tut="reactour__inicio"
                    />
                    {/* Progreso academico */}
                    {(!checkRoles("ondemand") ||
                        checkRoles(["alumno", "Admin", "visita"])) && (
                        <MenuItem
                            title="Mi progreso"
                            to="/mi-progreso"
                            icon={<SchoolRounded color="secondary" />}
                            closeMenu={closeMenu}
                            data_tut="reactour__mi_progreso"
                        />
                    )}
                    {/* AcropolisLab */}
                    {/* {checkRoles("ondemand") && (
                        <AccordionNavBar
                            closeMenu={closeMenu}
                            listMenu={menuAcropolisLab}
                            title={ondemand?.section ?? "AcrópolisLab"}
                            icon={<BusinessIcon />}
                            data_tut="reactour__acropolis_lab"
                        />
                    )} */}
                    {/* Oferta educativa */}
                    <MenuItem
                        title="Oferta educativa"
                        to="/oferta-educativa"
                        icon={<LocalMallIcon color="secondary" />}
                        closeMenu={closeMenu}
                        data_tut="reactour__oferta_educativa"
                    />
                    {/* Contenido libre */}
                    <AccordionNavBar
                        closeMenu={closeMenu}
                        listMenu={menuEducational}
                        title="Contenido libre"
                        icon={<ReceiptIcon />}
                        data_tut="reactour__contenido_libre"
                    />
                    {/* Directorio */}
                    <MenuItem
                        title="Directorio"
                        to="/networking"
                        icon={<ContactsRounded color="secondary" />}
                        closeMenu={closeMenu}
                        data_tut="reactour__directorio"
                    />
                    {/* Eventos */}
                    <AccordionNavBar
                        closeMenu={closeMenu}
                        listMenu={menuEvents}
                        title="Eventos"
                        icon={<EventRounded />}
                        data_tut="reactour__eventos"
                    />
                </ListMenu>
            </AsideTop>
            {/* Soporte */}
            <MenuItem
                title="Tutoriales de ayuda"
                to="/support"
                icon={<HelpOutlineIcon color="secondary" />}
                closeMenu={closeMenu}
                data_tut="reactour__tutoriales"
            />
        </Aside>
    );
};

export default Menu;

const Aside = styled.aside`
    display: flex;
    flex-direction: column;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    width: 70px;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    transition: all 0.5s;
    z-index: 999999999;

    #overflowY {
        overflow-y: auto;
    }

    div {
        overflow: hidden;
    }

    a {
        position: relative;
        padding: 1rem;
        transition: all 0.5s;
        padding-left: 17px;
        max-height: 55px;
        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        svg {
            font-size: 22px;
        }
    }
    a:hover {
        transition: all 0.5s;
    }

    .EventFake,
    .IconsHearder {
        display: none;
    }

    .accordionFake,
    .SubAccordionFake {
        padding-left: 25px;
    }
    .PFake {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        p {
            margin-left: 1rem;
        }
    }

    :hover {
        transition: all 0.5s;
        width: 280px;
        #menu-accordion {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            position: relative;
            /* padding: 1rem; */
            transition: all 0.5s;
            max-height: 55px;
            #title {
                display: flex;
                justify-content: center;
                align-items: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .EventFake,
        .IconsHearder {
            display: block;
        }
        .accordionFake {
            justify-content: space-between;
        }
        .SubAccordionFake {
            gap: 1rem;
        }

        a {
            gap: 1rem;
            padding-left: 17px;
            span {
                display: flex;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            transition: ease-in-out all 1s;
        }
        a:hover {
            transition: all 0.5s;
            background: #f9f9f9;
            box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
                0px 8px 8px -4px rgba(24, 39, 75, 0.08);
            border-radius: 20px;
        }
    }

    @media (max-width: 768px) {
        visibility: ${(props) => (props.menu ? "visible" : "hidden")};
        opacity: ${(props) => (props.menu ? 1 : 0)};
        transition: all 0.5s ease;
        position: fixed;
        top: 0;
        left: ${(props) => (props.menu ? "0" : "-100%")};
        bottom: 0;
        z-index: 9999;
        width: 70vw;
        overflow: hidden;
        height: 100%;
    }
`;

const Header = styled.div`
    display: none;
    visibility: ${(props) => (props.menu ? "visible" : "hidden")};
    opacity: ${(props) => (props.menu ? 1 : 0)};
    transition: all 0.5s ease;

    @media (max-width: 768px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
`;

const AsideTop = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 2.5rem 0;
    overflow-y: auto;
    #menu {
        overflow-y: auto;
    }
`;

const ButtonMenu = styled.div`
    width: 100%;
    height: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        color: #aaaaaa;
        font-size: 2rem;
    }
`;

const ListMenu = styled.div`
    height: calc(100vh - 140px);
    max-height: calc(100vh - 140px);
    overflow-y: auto;
`;
