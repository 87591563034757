import React from "react";
import styled from "styled-components";
import { Card } from "@mui/material";
import { Text } from "./Texts";

const DefaultCardIcon = (props) => {
    const {
        title = "Default",
        subtitle = false,
        icon = false,
        color = "#b31d15",
        type = "default",
        scale = 1,
        row = false,
    } = props;

    return (
        <CardFake scale={scale} type={type}>
            <Wrapper color={color}>{icon}</Wrapper>
            <Body row={row} type={type} color={color}>
                {subtitle && (
                    <Text
                        id="subtitle"
                        fontSize={row ? "16px" : "12px"}
                        fontWeight="bold"
                        color="#616161"
                    >
                        {" "}
                        {subtitle}
                    </Text>
                )}
                <Text id="title" fontSize="14px" fontWeight="bold" color="#000">
                    {title}
                </Text>
            </Body>
        </CardFake>
    );
};

export default DefaultCardIcon;

const CardFake = styled(Card)`
    display: inline-flex;
    position: relative;
    /* height: 70px; */
    height: ${(p) => (p.type === "tracking" ? "fit-content" : p.type === "pago" ? "95px" : "70px")};
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
        0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    scale: ${(p) => p.scale};
    background-color: #f1f1f1;
`;

const Wrapper = styled.div`
    background-color: ${(p) => p.color};
    display: inline-flex;
    width: 80px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    color: white;
    position: relative;
    /* border-radius: 20px 0 20px 20px; */
    svg {
        font-size: 36px;
        z-index: 2;
    }
    /* :before {
    content: "";
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 100%;
    height: 25px;
    width: 25px;
    border-top-left-radius: 25px;
    box-shadow: -10px -5px 0 8px ${(p) => p.color};
  } */
`;

const Body = styled.div`
    display: inline-flex;
    flex-direction: ${(p) => (p.row ? "row" : "column")};
    padding: 1rem;
    /* border-top-left-radius: 20px; */
    position: relative;
    width: ${(p) =>
        p.type === "tracking" ? "100%" : p.type === "default" && !p.row ? "120px" : p.row ? "100%" : "120px"};
    background-color: #f1f1f1;
    /* align-items: center; */
    align-items: ${(p) => (p.row ? "center" : "flex-start")};

    gap: ${(p) => (p.row ? "1rem" : "0")};
`;
