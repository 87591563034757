import React from "react";
import styled from "styled-components";
import Text from "../../components/common/Text";
import CoursesFree from "../../components/templates/workshops/CoursesFree";
import WorkshopsMain from "../../components/templates/workshops/WorkshopsMain";
import { titleHTML } from "../../helpers/title.helper";

const Workshops = () => {
    titleHTML("Cursos abiertos");

    return (
        <Container>
            <Text
                fontSize="2rem"
                fontWeight="700"
                color="primary"
                component="h1"
            >
                Cursos abiertos
            </Text>
            <CoursesFree />
        </Container>
    );
};

export default Workshops;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
