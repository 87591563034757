import React, { useContext } from "react";
import styled from "styled-components";

// Components
import CommonButton from "../../../../../components/common/CommonButton";
import { Icon } from "@iconify/react/dist/iconify.js";
import ModalIAHeader from "./components/Header";
import Questions from "./components/Questions";
import Messages from "./components/Messages";
import Summary from "./components/Summary";

// Material UI
import { Box, Modal } from "@mui/material";

// Assets
import useIA from "./hooks/useIA";
import Context from "../../../context/CourseSubjectContext";

const ModalIA = (props) => {
    const {
        open,
        handleClose,
        vimeoTranscript,
        publicationName,
        type = "resource",
        unityId = 0,
        resourceId,
        partnerId,
        getNotes,
        resources,
        promptAI,
        OAName
    } = props;

    const {
        loadingResume,
        loadingQuestions,
        questionsError,
        summary,
        questions,
        typeSelected,
        showAnswers,
        LoadingPostNote,
        showSuccessMessage,
        clearResponse,
        generateWithIA,
        setTypeSelected,
        setShowAnswers,
        createNoteHandler
    } = useIA(vimeoTranscript, getNotes, resources, promptAI, publicationName);

    const CourseSubjectContext = useContext(Context);

    const comportamientoInfo = {
        description: typeSelected === 1
            ? 'Botón para obtener el resumen de un video con GPT'
            : typeSelected === 2
                ? 'Botón para generar preguntas de repaso del módulo con GPT'
                : 'Botón para obtener preguntas de repaso de un video con GPT',
        subjectId: CourseSubjectContext?.subjectId,
        OAId: CourseSubjectContext?.OAId,
        OAName: OAName,
        ...(resourceId && { resourceId }), // Solo incluir si resourceId existe
        ...(publicationName && { resourceName: publicationName }) // Solo incluir si publicationName existe
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={() => {
                    handleClose()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <CustomBox>
                    <ModalIAHeader
                        handleClose={handleClose}
                        clearResponse={clearResponse}
                    />
                    <InfoContainer>
                        <Messages
                            unityId={unityId}
                            publicationName={publicationName}
                            type={type}
                            setTypeSelected={setTypeSelected}
                            typeSelected={typeSelected}
                        />
                        <Summary
                            loadingResume={loadingResume}
                            summary={summary}
                        />
                        <Questions
                            loadingQuestions={loadingQuestions}
                            questions={questions}
                            showAnswers={showAnswers}
                            type={type}
                            questionsError={questionsError}
                        />
                    </InfoContainer>
                    <GenerateResume>
                        {
                            (!!summary || questions.length !== 0 || showAnswers) && !questionsError
                                ? <>
                                    {
                                        (type === "resource" && typeSelected === 1) &&
                                        <CommonButton
                                            variant="outlined"
                                            label={LoadingPostNote ? "Guardando resumen..." : showSuccessMessage ? "Apunte creado con éxito!" : "Guardar resumen en Apuntes"}
                                            onClick={() => createNoteHandler(partnerId, resourceId)}
                                            disabled={loadingResume || LoadingPostNote || showSuccessMessage}
                                        />
                                    }
                                    <ClearResponseButton
                                        onClick={clearResponse}
                                        disabled={loadingResume}
                                    >
                                        <Icon icon="heroicons:arrow-turn-up-left" width="24px" height="24px" />
                                        Volver al menú
                                    </ClearResponseButton>
                                    {
                                        questions.length !== 0 && !showAnswers && summary === '' &&
                                        <CommonButton
                                            variant="outlined"
                                            label="Ver respuestas correctas"
                                            onClick={() => setShowAnswers(true)}
                                            disabled={loadingResume}
                                        />
                                    }
                                </>
                                : <CommonButton
                                    variant="filled"
                                    label="Generar"
                                    Icon={() => <Icon icon="mdi:sparkles" width="24px" height="24px" />}
                                    onClick={() => generateWithIA(resourceId)}
                                    disabled={loadingResume || typeSelected === null || loadingQuestions}
                                    // Event action name
                                    data-behaviour-action={
                                        typeSelected === 1
                                            ? "ia_resumen_video"
                                            : typeSelected === 2
                                                ? "ia_preguntas_modulo"
                                                : "ia_preguntas_video"
                                    }
                                    // Event detail
                                    data-behaviour-detail={JSON.stringify(comportamientoInfo)}
                                />
                        }
                    </GenerateResume >
                </CustomBox >
            </Modal >
        </div >
    );
};

export default ModalIA;

const CustomBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
    height: 90vh;
    width: 80vw;
    background-color: #fff;

    p,
    span {
        color: #222;
        font-size: 1rem;
    }
    span {
        font-weight: bold;
    }
`;

const InfoContainer = styled.div`
    width: calc(100% - 2rem);
    height: 100%;
    max-height: calc(80vh - 76px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 5rem;
`

const GenerateResume = styled.div`
    width: calc(100% - 4rem);
    position: absolute;
    bottom: 1rem;
    padding: 1rem;
    background-color: #F9F9F9;
    border-radius: 30px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
`

const ClearResponseButton = styled.button`
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    transition: all 0.3s;
    border-radius: 100px;
    gap: 8px;
    padding: 12px 24px 12px 16px;
    background-color: #CD2118;
    color: #FFF;
    border: none;

    &:hover{
        background-color: #B31D15;
        color: #FFF;
        box-shadow: 0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F;
    }

    &:disabled{
        cursor: not-allowed;
        box-shadow: none;
        background-color: #DCDCDC;
        color: #FFF;
        border: none;
    }

    svg {
        pointer-events: none;
    }
`