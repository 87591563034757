export const courseSubjectAdapter = (courseSubjectData) => {
    return {
        assignmentWithoutInformation: courseSubjectData.assignment_without_information,
        canBePublished: courseSubjectData.can_be_published,
        courseConditions: courseSubjectData.condiciones_cursado,
        coursesAmount: courseSubjectData.courses_amount,
        demoCourse: courseSubjectData.cursado_demo,
        description: courseSubjectData.description,
        duration: courseSubjectData.duration,
        durationInWeeks: courseSubjectData.duration_week,
        enrollmentDiploma: courseSubjectData.enrollment_diploma,
        enrollmentFinalNote: courseSubjectData.enrollment_nota_final,
        enrollmentState: courseSubjectData.enrollment_state,
        date: courseSubjectData.fecha,
        expirationDate: courseSubjectData.fecha_vencimiento,
        id: courseSubjectData.id,
        image: courseSubjectData.img,
        information: courseSubjectData.information,
        level: courseSubjectData.level, // Creo que no se usa
        modality: courseSubjectData.modality, // Creo que no se usa
        name: courseSubjectData.name,
        certificateTemplate: courseSubjectData.plantilla_certificado,
        certificateTemplateAlab: courseSubjectData.plantilla_certificado_alab, // Creo que no se usa
        progress: courseSubjectData.progress,
        reworkActivities: courseSubjectData.rework_activities,
        totalEnrolled: courseSubjectData.total_enrolled,
        type: courseSubjectData.type,
        courses: courseSubjectData.courses.map((course) => ({
            category: course.category,
            courseInPerson: course.course_in_person,
            description: course.description,
            duration: course.duracion,
            durationUnitOfTime: course.duracion_unidad_tiempo,
            id: course.id,
            image: course.img,
            isFree: course.is_free, // No se si se usa
            mainElective: course.main_elective,
            moduleDemo: course.module_demo,
            order: course.order,
            certificateTemplateId: course.plantilla_certificado_id,
            certificateTemplateAlabId: course.plantilla_certificado_alab_id, // Creo que no se usa
            progress: course.progress,
            publicationName: course.publication_name,
            reworkActivities: course.rework_activities,
            pendingCorrections: course.pending_correction,
            selectedFromElective: course.selected_from_elective,
            survey: course.survey,
            tags: course.tags, // No se si se usa
            type: course.type,
            units: course.units, // Ver si se usa este array
            electives: course?.electives ? course?.electives?.map((elective) => ({
                category: elective.category,
                courseInPerson: elective.course_in_person,
                description: elective.description,
                duration: elective.duracion,
                durationUnitOfTime: elective.duracion_unidad_tiempo,
                electives: elective.electives,
                enrolled: elective.enrolled,
                id: elective.id,
                image: elective.img,
                isFree: elective.is_free, // No se si se usa
                mainElective: elective.main_elective,
                module: elective.module,
                moduleDemo: elective.module_demo,
                order: elective.order,
                certificateTemplateId: elective.plantilla_certificado_id, // Creo que no se usa acá
                certificateTemplateAlabId: elective.plantilla_certificado_alab_id, // Creo que no se usa
                progress: elective.progress,
                publicationName: elective.publication_name,
                reworkActivities: elective.rework_activities,
                selectedFromElective: elective.selected_from_elective,
                survey: elective.survey,
                tags: elective.tags, // No se si se usa
                type: elective.type,
                units: elective.units, // Ver si se usa este array
            })) : false
        })),
    }
}
