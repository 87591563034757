import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// import Logo from '../../../../assets/media/ic/porvenir/logoporvenir.png';
import FormLoginPorvenir from './FormLogin';

import { Link } from 'react-router-dom';
import { useServices } from '../../../../hooks/useServices';
import BannerServices from '../../../common/BannerServices';

const LoginEseade = () => {
    const [services] = useServices();
    const [sisApi, setSisApi] = useState(false);

    useEffect(() => {
        if (services !== null) {
            services.forEach(
                (service) =>
                    (service === 'SIS_API' ||
                        service === 'CRM' ||
                        service === 'FA') &&
                    setSisApi(true)
            );
        }
    }, [services, sisApi]);

    return (
        <LoginContainer>
            {!!sisApi && <BannerServices />}
            {/** Logo */}
            <FakeLink margin={!!sisApi ? true : false} to="/">
                <LogoAden src={'https://eseade.aden.org/assets/logo-eseade-40.png'} alt="logo" />
            </FakeLink>
            <FormWrapper>
                <InfoWrapper>
                    <h2>Iniciar sesión</h2>
                    <span>Ingresar usuario y contraseña</span>
                </InfoWrapper>
                <FormLoginPorvenir avaliable={sisApi} />
            </FormWrapper>
            {/** Ir a registro */}
            <ToRegisterWrapper>
                <span>¿Aún no tienes cuenta?</span>
                <FakeLink to="/auth/registration">Registrate</FakeLink>
            </ToRegisterWrapper>
        </LoginContainer>
    );
};

export default LoginEseade;

const LoginContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10% 0;
    background-color: #ffffff;
    overflow-y: scroll;
`;

const FakeLink = styled(Link)`
    display: flex;
    justify-content: center;
    color: #1985fd;
    margin-top: ${(p) => (p.margin ? '80px' : '0')};
    padding: 0.5rem 1rem;
`;

const LogoAden = styled.img`
    max-width: 350px;

    @media (max-width: 1024px) {
        max-width: 300px;
    }

    @media (max-width: 768px) {
        max-width: 250px;
    }
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
`;

const InfoWrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 1.25rem;
        font-weight: 700;
        color: #1985fd;
        margin: 0;
    }

    span {
        font-size: 0.9rem;
        font-weight: 300;
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        margin-top: 5rem;
    }
`;

const ToRegisterWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-size: 1rem;

    a {
        font-weight: 700;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
`;
