import React, { useState, useEffect } from 'react';
import { TextField, IconButton, Button } from '@mui/material';
import {
    AttachFile,
    SentimentSatisfiedAltSharp,
    Close,
} from '@mui/icons-material';
import styled from 'styled-components';
import EmojiPicker from 'emoji-picker-react';
import SendIcon from '@mui/icons-material/Send';
import FileComponent from './FileComponent';

const FooterBody = (props) => {
    const MIMETYPE_CASES = ['image/jpeg', 'image/jpg', 'image/png'];
    const MIMETYPE_DOCS_CASES = [
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/docx',
        'text/csv',
        'text/css',
        'text/html',
        'text/plain',
    ];

    const { statusUser, createMessage, createMessageClick, statusChat } = props;

    const [file, setFile] = useState(null);
    const [renderFile, setRenderFile] = useState(null);
    const [openPicker, setOpenPicker] = useState(false);
    const [message, setMessage] = useState('');

    const handleFiles = (e) => {
        e.preventDefault();
        setFile(e.target.files[0]);
        document.getElementById('msj').focus();
    };

    useEffect(() => {
        setRenderFile(!!file ? URL.createObjectURL(file) : null);
    }, [file]);

    const handlePicker = () => {
        setOpenPicker(!openPicker);
    };

    const handleTextInput = (e) => {
        if (!!e.emoji) {
            setMessage((prev) => prev + e.emoji);
            document.getElementById('msj').focus();
            return;
        }

        const { value } = e?.target;
        setMessage(value);
    };

    const hanleClearFileInput = () => {
        setFile(null);
        document.getElementById('file-input').value = null;
    };

    return (
        <Container fileLoaded={!!file}>
            <FooterWrapper fileLoaded={!!file}>
                <EmojiPickerContainer>
                    <IconButton onClick={handlePicker}>
                        <SentimentSatisfiedAltSharp />
                    </IconButton>
                    {openPicker && (
                        <CustomEmojiPicker onEmojiClick={handleTextInput} />
                    )}
                </EmojiPickerContainer>
                <CustomIconButton>
                    <FileInput
                        id="file-input"
                        type="file"
                        onChange={handleFiles}
                    />
                    <AttachFile />
                </CustomIconButton>
                <TextFieldWrapper>
                    <CustomTextfield
                        id="msj"
                        className="mb-1"
                        style={{ margin: 8 }}
                        placeholder="Escribe tu mensaje aquí"
                        fullWidth
                        margin="normal"
                        value={message}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={
                            statusChat && statusChat !== undefined
                                ? !statusChat.estado.estado
                                : false
                        }
                        onChange={handleTextInput}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                setMessage('');
                                setOpenPicker(false);
                                document.getElementById('file-input').value =
                                    null;
                                setFile(null);
                                createMessage(e, file);
                            }
                        }}
                    />
                    {file && MIMETYPE_CASES.includes(file?.type) && (
                        <FileWrapper>
                            <Image src={renderFile} alt="img" />
                            <CloseImageButton onClick={hanleClearFileInput}>
                                <Close />
                            </CloseImageButton>
                        </FileWrapper>
                    )}
                    {file && MIMETYPE_DOCS_CASES.includes(file?.type) && (
                        <FileWrapper>
                            <FileComponent
                                name={file.name}
                                size={file.size}
                                onClose={hanleClearFileInput}
                            />
                        </FileWrapper>
                    )}
                </TextFieldWrapper>
                <IconMessage
                    variant="contained"
                    onClick={(e) => {
                        createMessageClick(
                            document.getElementById('msj').value,
                            file
                        );
                        document.getElementById('msj').value = '';
                        setOpenPicker(false);
                        setMessage('');
                        setFile(null);
                        document.getElementById('file-input').value = null;
                    }}
                    disabled={!message.length && !file}
                >
                    <SendIcon />
                </IconMessage>
            </FooterWrapper>
        </Container>
    );
};

export default FooterBody;

const Container = styled.div`
    min-height: ${({ fileLoaded }) => (fileLoaded ? '160px' : '80px')};
    width: calc(100% - 2rem);
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 0 0 20px 20px;
    background-color: #ffffff;
`;

const FooterWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: ${({ fileLoaded }) => (fileLoaded ? 'flex-end' : 'center')};
    width: 100%;
    padding: 0 2rem;
`;

const IconMessage = styled(Button)`
    display: flex;
    min-height: 50px;
    min-width: 35px;
    border-radius: 50%;
    justify-content: center;

    svg {
        max-height: 24px;
        max-width: 24px;
    }
`;

const CustomIconButton = styled(IconButton)`
    position: relative;

    input {
        position: absolute;
        opacity: 0;
        height: 20px;
        width: 20px;
    }

    svg {
        font-size: 32px;
    }
`;

const TextFieldWrapper = styled.div`
    width: calc(100% - 280px);
    border-radius: 10px;
    height: auto;
    padding: 0.5rem 0.5rem;
    background-color: #f1f1f1;

    &::before {
        content: '';
        position: absolute;
        margin: auto;
        width: calc(100% - 4rem);
        height: 1px;
        background-color: #a8a8a8;
        top: -20px;
        left: 0;
        right: 0;
    }
`;

const CustomTextfield = styled(TextField)`
    background-color: #f1f1f1;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 10px !important;

    .MuiOutlinedInput-notchedOutline {
        display: none !important;
    }
`;

const FileInput = styled.input`
    cursor: pointer !important;
`;

const CustomEmojiPicker = styled(EmojiPicker)`
    position: absolute !important;
    margin: auto !important;
    top: -29rem !important;
    /* height: 120px !important; */
`;

const EmojiPickerContainer = styled.div`
    position: relative;

    svg {
        font-size: 32px;
    }
`;

const Image = styled.img`
    width: 290px;
    height: 290px;
    object-fit: cover;
    border-radius: 10px;
`;

const FileWrapper = styled.div`
    width: fit-content;
    position: relative;
`;

const CloseImageButton = styled(IconButton)`
    position: absolute;
    margin: auto;
    right: 10px;
    top: 10px;
    background-color: #ffffff;

    &:hover {
        background-color: #ffffff;
    }
`;
