import React from 'react';
import { css, jsx } from '@emotion/react';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { recursos } from './recursosData';
import Slider from 'react-slick';
import styled from 'styled-components';
//import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const WorkshopsCards = () => {
    const widthSize = useWindowWidth();
    const settings = {
        dots: false,
        infinite: false,
        speed: 1500,
        slidesToShow: 4,
        slidesToScroll: 0,
        arrows: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 3,
                    autoplay: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true
                }
            }
        ]
    };

    return (
        <MainContainer>
            <SliderContainer>
                <CustomSlider {...settings}>
                    {recursos.map((slide) => {
                        return (
                            <Afake href={slide.id}>
                                <ImgFake
                                    src={slide.image}
                                    alt={slide.name}
                                ></ImgFake>
                            </Afake>
                        );
                    })}
                </CustomSlider>
            </SliderContainer>
        </MainContainer>
    );
};

export default WorkshopsCards;

function NextArrow(props) {
    const { className, onClick } = props;
    return <DivNextArrow className={className} onClick={onClick} />;
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <DivPrevArrow className={className} onClick={onClick} />;
}

const MainContainer = styled.div`
    width: 100%;
`;

const SliderContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const CustomSlider = styled(Slider)`
    width: 85%;
    .slick-list {
        /* margin: 0 4rem; */
    }
    .slick-slide > div {
        padding: 4rem 4rem;

        @media screen and (max-width: 1500px) {
            padding: 2rem 2rem;
        }

        @media screen and (max-width: 1024px) {
            padding: 4rem 4rem;
        }
    }
    .slick-slide img {
        margin: 0 auto;
    }
`;

const Afake = styled.a`
    margin: 0 !important;
`;

const ImgFake = styled.img`
    width: 200px;
    height: auto;
`;

const DivNextArrow = styled.div`
    display: block;
    border-radius: 50%;
    &::before {
        font-size: 30px !important;
        line-height: 1 !important;
        opacity: 1 !important;
        color: #cd1f17 !important;
    }
`;

const DivPrevArrow = styled.div`
    display: block;
    border-radius: 50%;
    &::before {
        font-size: 30px !important;
        line-height: 1 !important;
        opacity: 1 !important;
        color: #cd1f17 !important;
    }
`;
