import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SearchIcon from '@mui/icons-material/Search';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import Input from '../../atoms/Input';
import { useHistory } from 'react-router-dom';
import useTheme from '../../../../hooks/useTheme';
import { CloseRounded, MenuRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const HeaderAside = (props) => {
    // Props
    const { channels, handleFilterChannel, aside, handleButton } = props;
    // State component
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState('');
    const [values, setValues] = useState([]);
    const { primary } = useTheme();
    // Hooks
    const history = useHistory();
    //   Function
    const showInput = () => {
        show ? setShow(false) : setShow(true);
    };
    const searchChannel = (e) => {
        setSearch(e.target.value);
    };

    const getResultSearch = () => {
        let result = [];
        for (let i = 0; i < channels.length; i++) {
            let resultItem = channels[i].participantes.filter((user) => {
                return user?.nombre
                    ?.toLowerCase()
                    .includes(search.toLowerCase());
            });
            if (resultItem[0] != undefined) {
                result.push(channels[i]);
            }
        }
        handleFilterChannel(result);
    };

    const hrefDirectory = () => {
        history.push('/networking');
    };
    // Effects
    useEffect(() => {
        getResultSearch();
    }, [search, channels]);
    return (
        <HeaderContent aside={aside}>
            {!show ? (
                <Title color={primary} aside={aside}>
                    Chat
                </Title>
            ) : (
                <ContainerInput>
                    <Input
                        onChange={(e) => searchChannel(e)}
                        label={''}
                        placeholder={'Buscar...'}
                        name={'Search'}
                    />
                </ContainerInput>
            )}
            <Buttons aside={aside}>
                <div onClick={() => showInput()}>
                    <SearchComponent />
                    {/* <SearchIcon htmlColor="#bfbfbf" /> */}
                </div>
                <DirectoryButton onClick={() => hrefDirectory()} />
            </Buttons>
            <FakeIconButton onClick={handleButton}>
                {aside ? (
                    <CloseRounded fontSize="default" color="secondary" />
                ) : (
                    <MenuRounded color="secondary" />
                )}
            </FakeIconButton>
        </HeaderContent>
    );
};

export default HeaderAside;

const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 25px;
    height: 10%;
    min-height: 60px;
    transition: 0.5s ease;

    @media (max-width: 768px) {
        justify-content: ${(p) => (p.aside ? 'space-between' : 'center')};
    }
`;

const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: 600;
    color: ${(p) => p.color};
    transition: 1s ease;
    display: flex;

    @media (max-width: 768px) {
        display: ${(p) => (p.aside ? 'flex' : 'none')};
    }
`;
const Buttons = styled.div`
    width: 20%;
    justify-content: space-between;
    display: flex;

    @media (max-width: 768px) {
        display: ${(p) => (p.aside ? 'flex' : 'none')};
    }
`;

const ContainerInput = styled.div`
    width: 70%;
`;

const SearchComponent = styled(SearchIcon)`
    cursor: pointer;
    color: #bfbfbf;
    transition: 0.2s ease;
    :hover {
        color: #969696;
        transition: 0.2s ease;
    }
`;

const DirectoryButton = styled(AddSharpIcon)`
    color: #bfbfbf;
    cursor: pointer;
`;
const FakeIconButton = styled(IconButton)`
    display: none;
    @media (max-width: 768px) {
        display: flex;
    }
`;
