import React from 'react'
import styled from 'styled-components'
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import CampaignIcon from '@mui/icons-material/Campaign';
import CloseIcon from '@mui/icons-material/Close';
import { Text } from "../acropolisCommon/components/Texts";
import useDialog from './hooks/useDialog';
import useNews from './hooks/useNews';
import parse from 'html-react-parser';

const DialogInfo = () => {

    const {
        open,
        anchorEl,
        id,
        handleClick,
        handleClose
    } = useDialog()
    const [newsModal] = useNews()

    if (!newsModal || newsModal.length === 0) {
        return false
    }

    return (
        <>
            <ContentWrapper onClick={handleClick} aria-describedby={id}>
                <IconButtonFake open={open}>
                    {open ? <CloseIcon /> : <CampaignIcon />}
                </IconButtonFake>
            </ContentWrapper>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <WrapperPop>
                    <Header>
                        <Text color='#fff' fontWeight="bold">¿Qué hay de nuevo?</Text>
                        <IconButtonClose onClick={handleClose}>
                            <CloseIcon />
                        </IconButtonClose>
                    </Header>
                    <Body>
                        {/* Ciclar cuando haya una lista de noticias */}
                        {newsModal.map(({ title, preview, content, publishedAt }) => (<ItemNew>
                            <ItemHeader>
                                <div>
                                    <Text fontWeight="bold" fontSize="18px">{title}</Text>
                                    <Text fontWeight="bold" fontSize="14px" color="#a1a1a1">{preview}</Text>
                                </div>
                                <Text fontWeight="bold">{new Date(publishedAt).toLocaleString()}</Text>
                            </ItemHeader>
                            <HtmlRender>{parse(content)}</HtmlRender>
                            <Divider />
                        </ItemNew>))}
                    </Body>
                </WrapperPop>
            </Popover>
        </>
    )
}

export default DialogInfo

const IconButtonFake = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #f9f9f9;
    cursor: pointer;
    display: flex;
    justify-content: center;
    box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
    align-items: center;
    transition: ease all .3s;
    transform: rotate(${p => p.open ? '-90deg' : '0'});
    :after {
        ${p => !p.open && `content: ''`};
        width: 30px; height: 30px;
        border-radius: 100%;
        border: 3px solid #b31d15;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        ${p => !p.open && `animation: ring 1.5s infinite`};
    }
    svg{
        fill: #bfbfbf;
        font-size: 32px;
    }
    @keyframes ring {
        0% {
            width: 60px;
            height: 60px;
            opacity: 1;
        }
        100% {
            width: 100px;
            height: 100px;
            opacity: 0;
        }
    }
`

const WrapperPop = styled.div`
    /* margin: 24px; */
    width: 700px;
    height: 500px;
    background-color: white;
`

const Header = styled.div`
    height: 50px;
    width: calc(100% - 2rem);
    background-color: #b31d15;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
`

const Body = styled.div`
    height: calc(100% - 50px - 2rem);
    width: calc(100% - 2rem);
    padding: 1rem;
    `

const IconButtonClose = styled(IconButton)`
    svg{
        fill: #fff;
    }
`

const ItemNew = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding-top: 1rem;
`

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
`

const ContentWrapper = styled.div`
    margin: 16px 0 0 0;
    position: fixed;
    right: 105px;
    bottom: 20px;
    z-index: 9999;
    height: 75px;
    display: flex;
    align-items: flex-end;
`

const HtmlRender = styled.div`
    padding: 1rem;
`

const ItemHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`