import React from "react";
import EventsCard from "./EventsCard";
import useCountryName from "../../../../hooks/common/useCountryName";

const EventItem = (props) => {
    const { event, countries, getDynamicEvent, tabsFake } = props;

    const { findCountry } = useCountryName();

    let country = "";
    let countryName = "";

    if (countries !== null)
        if (event?.location?.contry)
            countryName = findCountry(
                countries,
                country,
                event.location.contry
            );

    // RETURN
    return (
        <EventsCard
            tabsFake={tabsFake}
            getDynamicEvent={getDynamicEvent}
            event={event}
            countryName={countryName}
        />
    );
};

export default EventItem;
