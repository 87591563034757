import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import TabsComponent from "../../components/common/TabsComponent";
import { programsActions } from "../../redux/actions";
import CoursesBusiness from "../../components/templates/business/CoursesBusiness";
import Analytics from "../../components/templates/business/Analytics";
import CoursesAden from "../../components/templates/business/CoursesAden";
import axios from "axios";
import CoursesAddiotional from "../../components/templates/business/CoursesAddiotional";
import useTheme from "../../hooks/useTheme";
import { titleHTML } from "../../helpers/title.helper";
import DefaultNavegation from "../../components/shared/navegation/DefaultNavegation";

const Business = () => {
    titleHTML("Mi empresa");

    // THEME
    const { primary } = useTheme();

    // REDUX
    const { ondemand, academic } = useSelector((state) => state.ondemand);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const programs = useSelector((state) => state.programs.programs);
    // const loading = useSelector((state) => state.programs.listLoading);

    // STATE
    const [tab, setTab] = useState(1);
    const [tabs, setTabs] = useState([
        { label: `Mis cursos ADEN`, id: 0 },
        { label: `Mis cursos EMPRESA`, id: 1 },
        { label: "Mi analítica", id: 3 },
    ]);
    const [thematic, setThematic] = useState(null);
    const [allThematic, setAllThematic] = useState(null);
    const [courses, setCourses] = useState(null);
    const [title, setTitle] = useState("Mi empresa");

    //   USEEFFECT
    useEffect(() => {
        if (!!ondemand) {
            if (!!ondemand?.enable_additional_course) {
                setTabs([
                    { label: `Mis cursos ADEN`, id: 0 },
                    {
                        label: `Mis cursos ${ondemand.section || "EMPRESA"}`,
                        id: 1,
                    },
                    { label: "Cursos adicionales", id: 2 },
                    { label: "Mi analítica", id: 3 },
                ]);
            } else {
                setTabs([
                    { label: `Mis cursos ADEN`, id: 0 },
                    {
                        label: `Mis cursos ${ondemand.section || "EMPRESA"}`,
                        id: 1,
                    },
                    { label: "Mi analítica", id: 3 },
                ]);
            }
            setTitle(ondemand.acropolis_title || "Mi empresa");
        }
    }, [ondemand]);

    useEffect(() => {
        if (thematic === null) {
            getMainThematic();
            getAllThematic();
        }
    }, [thematic]);

    useEffect(() => {
        if(!!academic.aden || !!academic.business){ validateTab(academic) }
    }, [academic])
    

    useEffect(() => {
        if (programs === null) {
            dispatch(programsActions.getProgramsRequest());
        }
    }, [programs]);

    useEffect(() => {
        if (courses === null) {
            getCourses();
        }
    });

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
    };

    const getMainThematic = async () => {
        const response = await getMainThematicService(user.od_participant_id.id);
        if (!response.error) {
            setThematic(response);
        }
    };

    const getAllThematic = async () => {
        const response = await getMainThematicService(0);
        if (!response.error) {
            setAllThematic(response);
        }
    };

    const getCourses = async () => {
        const response = await getAllCourses(
            user.ondemand_id,
            user.od_participant_id.id
        );
        if (!response.error) {
            setCourses(response);
        }
    };

    const validateTab = data => {
        const aden = data.aden.length > 0
        const business = data.business.length > 0
        handleTab(null, business ? 1 : aden ? 0 : 1)
    }

    return (
        <BusinessContainer>
            <DefaultNavegation title="Mi Empresa" />
            <Title color={primary}>{title}</Title>
            <BusinessMain>
                <TabsComponent
                    tab={tab}
                    handleTab={handleTab}
                    tabs={tabs}
                    programs={programs}
                />
                <BodyWrapper>
                    <ToggleMain
                        allThematic={allThematic}
                        thematic={thematic}
                        tab={tab}
                        courses={courses}
                        handleTab={handleTab}
                    />
                </BodyWrapper>
            </BusinessMain>
        </BusinessContainer>
    );
};

export default Business;

const getMainThematicService = async (id) => {
    const REPO_URL = process.env.REACT_APP_REPO;

    try {
        const res = await axios.get(
            `${REPO_URL}/v1/repo_aden/ondemand/acropolis/ejes_tematicos/${id}`
        );
        return res.data.response_data;
    } catch (error) {
        return {
            error,
        };
    }
};

const getAllCourses = async (id, user) => {
    const REPO_URL = process.env.REACT_APP_REPO;
    try {
        const res = await axios.get(
            `${REPO_URL}/v1/repo_aden/analytics/students/${id}/progress?participant_id=${user}`
        );
        return res.data.response_data;
    } catch (error) {
        return {
            error,
        };
    }
};

const BusinessContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 4rem;
`;

const Title = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    color: ${(p) => p.color};
`;

const BusinessMain = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const BodyWrapper = styled.div`
    padding: 2rem;
`;

function ToggleMain(props) {
    const { tab, thematic, allThematic, courses, handleTab } = props;
    const cases = {
        0: <CoursesAden thematic={thematic} />,
        1: <CoursesBusiness handleTab={handleTab} thematic={thematic} />,
        2: <CoursesAddiotional thematic={allThematic} />,
        3: <Analytics courses={courses} />,
    };

    return cases[String(tab)] || <CoursesAden thematic={thematic} />;
}
