import { useState } from "react";
import styled from "styled-components";

const FilterStatusSubject = (props) => {
  const { handleStateFilter } = props;

  const STATES = ["todos", "egresado", "confirmado", "preinscripto", "baja"];

  const [state, setState] = useState("todos");

  const handleSelect = (value) => {
    setState(value);
    handleStateFilter(value);
  };

  return (
    <FilterStatusWrapper>
      {STATES.map((item) => (
        <StateItem
          active={state === item ? true : false}
          onClick={() => handleSelect(item)}
        >
          {item.toUpperCase()}
        </StateItem>
      ))}
    </FilterStatusWrapper>
  );
};

export default FilterStatusSubject;

const FilterStatusWrapper = styled.div`
  display: flex;
  gap: 1.2rem;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  @media (max-width: 700px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 1rem 0;
    gap: 6px;
    justify-content: center;
  }
`;

const StateItem = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  color: ${(props) => (props.active ? "#b31d15" : "#bfbfbf")};
  background-color: ${(props) => (props.active ? "#ffffff" : "transparent")};
  cursor: pointer;
  box-shadow: ${(props) =>
    props.active
      ? "0px 3px 5px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.06)"
      : "none"};

  :hover {
    color: #b31d15;
    background-color: ${(props) => (props.active ? "#fff" : "#FFF7F6")};
    font-weight: 700;
  }
`;
