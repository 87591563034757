import styled from "styled-components";

// Components
import SimpleLoading from "../../../../components/common/SimpleLoading";
import AttachmentsFile from "./commentsSection/AttachmentsFile";
import { Text } from "../../../../components/common/Texts";
import PickRecAudio from "./commentsSection/PickRecAudio";
import PickRecVideo from "./commentsSection/PickRecVideo";

// Material UI
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';

const CommentsSection = (props) => {

    const { 
        correctedSubmission,
        isEdit,
        editSuccess,
        valuesCorrection,
        comment,
        activeRol,
        handleComment,
        handleSendAudio,
        handleSendVideo,
        handleSendFile,
        loading,
        check,
        handleEdit,
        redo,
        correctionWithCheck,
        note,
        handleSubmit,
        teacherFiles,
        idTask
    } = props

    return (
        <>
            <Text
                fontSize="16px"
                color="#222222"
                fontWeight="bold"
            >
                Comentario
            </Text>
            <Text
                fontSize="14px"
                color="#666"
                fontWeight="400"
            >
                Escribe un comentario sobre la entrega del alumno
            </Text>
            {
                correctedSubmission ?
                    <CommentTextArea
                        label="Escribe un comentario sobre la entrega del alumno."
                        title={
                            !isEdit && !editSuccess
                                ? valuesCorrection.comment_teacher
                                : comment
                        }
                        value={
                            !isEdit && !editSuccess
                                ? valuesCorrection.comment_teacher
                                : comment
                        }
                        disabled={
                            (activeRol === 'docente' ||
                                activeRol === 'academico') &&
                                isEdit
                                ? false
                                : true
                        }
                        type="text"
                        onChange={handleComment}
                    />
                    :
                    <CommentTextArea
                        label="Escribe un comentario sobre la entrega del alumno."
                        value={comment}
                        variant="outlined"
                        type="text"
                        onChange={handleComment}
                    />
            }
            <Footer>
                <ContentAttachments>
                    <PickRecAudio
                        handleSendAudio={handleSendAudio}
                        audioFile={teacherFiles?.audio_correccion?.url}
                        idTask={idTask}
                    />
                    <PickRecVideo
                        handleSendVideo={handleSendVideo}
                        videoFile={teacherFiles?.video_correccion?.url}
                        idTask={idTask}
                    />
                    <AttachmentsFile
                        handleSendFile={handleSendFile}
                        file={teacherFiles?.archivo_correccion?.url}
                        idTask={idTask}
                    />
                </ContentAttachments>
                {
                    correctedSubmission ?
                        <EditButtons>
                            {!isEdit && !loading && (
                                <Button
                                    endIcon={<SendIcon />}
                                    color="primary"
                                    variant="contained"
                                    disabled={isEdit || check}
                                    onClick={handleEdit}
                                >
                                    Editar
                                </Button>
                            )}
                            {isEdit && !loading && (
                                <Button
                                    disabled={!isEdit}
                                    onClick={handleEdit}
                                >
                                    Cancelar
                                </Button>
                            )}
                            {isEdit && !loading && (
                                <Button
                                    disabled={
                                        redo
                                            ? false
                                            : correctionWithCheck
                                                ? false
                                                : !note
                                                    ? true
                                                    : false
                                    }
                                    onClick={handleSubmit}
                                    endIcon={<SendIcon />}
                                    color="primary"
                                    variant="contained"
                                >
                                    Enviar corrección
                                </Button>
                            )}
                            {loading && <SimpleLoading />}
                        </EditButtons>
                        :
                        <>
                            {!loading ? (
                                <Button
                                    disabled={
                                        redo
                                            ? false
                                            : check
                                                ? false
                                                : !note
                                                    ? true
                                                    : false
                                    }
                                    onClick={handleSubmit}
                                    endIcon={<SendIcon />}
                                    color="primary"
                                    variant="contained"
                                >
                                    Enviar corrección
                                </Button>
                            ) : (
                                <SimpleLoading />
                            )}
                        </>
                }
            </Footer>
        </>
    )
}

export default CommentsSection;

const CommentTextArea = styled.textarea`
    width: 100%;
    min-height: 135px;
    height: 100%;
    resize: vertical;
    box-sizing: border-box;
    padding: 8px;
    font-size: 16px;
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ContentAttachments = styled.div`
    display: flex;
    gap: 0.1rem;
    align-items: flex-start;
`;

const EditButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
