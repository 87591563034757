import { useEffect, useState } from 'react';
import { getNotificationService } from '../../redux/api/chat';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../../utils/snackStyles';

const useMessages = (id) => {
    const [messages, setMessages] = useState(null);
    // const [initialCourses, setInitialCourses] = useState(null);

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!messages) getMessages();
    }, [messages]);

    const getMessages = async () => {
        const request = await getNotificationService(id);
        if (request.error) {
            openSnackbar('¡No se pudieron cargar los mensajes!');
            setMessages(false);
            return;
        }
        setMessages(request);
        // setInitialCourses(request);
    };

    const handleMessages = (messages) => {
        setMessages(messages);
    };

    return { messages, handleMessages };
};

export default useMessages;
