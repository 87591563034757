import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../../components/common/Texts';
import { CustomChip } from '../../../../styled-components';
import { Icon } from '@iconify/react';

const SubjectInfoCard = ({ lastOpenedSubjectData }) => {
    const {
        subjectState: { toStart, inProgress, finished },
        name,
    } = lastOpenedSubjectData;

    return (
        <Wrapper>
            <Header>
                <Text color="#ffffff" fontSize="16px" fontWeight="500">
                    Asignatura <span>{name}</span> y avance de los módulos:
                </Text>
            </Header>
            <Body>
                <CustomChip
                    icon={<Icon icon="pajamas:check" fontSize={22} />}
                    color="current"
                    bgColor="#F7F8FD"
                    label={`${inProgress ?? 0} En curso`}
                    width="190px"
                    variant="outlined"
                />
                <CustomChip
                    icon={<Icon icon="wi:time-4" fontSize={22} />}
                    color="toStart"
                    bgColor="#F9F5EC"
                    label={`${toStart ?? 0} Por iniciar`}
                    width="190px"
                    variant="outlined"
                />
                <CustomChip
                    icon={
                        <Icon
                            icon="fluent:hat-graduation-12-regular"
                            fontSize={22}
                        />
                    }
                    color="ended"
                    bgColor="#F7FDFB"
                    label={`${finished ?? 0} Finalizados`}
                    width="190px"
                    variant="outlined"
                />
            </Body>
        </Wrapper>
    );
};

export default SubjectInfoCard;

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 295px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    padding: 0.5rem;

    @media (max-width: 1440px) {
        width: 100%;
        height: 305px;
    }

    @media (max-width: 1024px) {
        max-width: 100%;
    }

    @media (max-width: 768px) {
        border: none;
        padding: 0.5rem 0.5rem 0 0.5rem;
    }
`;

const Header = styled.section`
    box-sizing: border-box;
    margin-top: 0.3rem;
    padding: 0 0.8rem;
    height: 60px;

    span {
        font-weight: 900;
        line-height: 20px;
    }

    p {
        line-height: 20px;
        letter-spacing: 0.1px;
        margin: 0;
    }

    @media (max-width: 1440px) {
        margin-top: 0.3rem;
        padding: 0 0.4rem;
        height: 60px;
        font-size: 14px !important;

        span {
            font-weight: 900;
            line-height: 20px;
            font-size: 14px !important;
        }

        p {
            font-size: 14px !important;
            line-height: 20px;
            letter-spacing: 0.1px;
            margin: 0;
        }
    }
`;

const Body = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 220px;
`;
