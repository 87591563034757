import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Components
import { Text } from "../../../../../../components/common/Texts";
import { Icon } from "@iconify/react/dist/iconify.js";
import Attachments from "../Attachments";

const CorrectionDetails = (props) => {
  const {
    description: { professor_comment, student_comment },
    values
  } = props;

  const parseString = (message) => {
    const string = "\\n";
    const regex = new RegExp(string, "g");
    return message.replace(regex, "<br/>");
  };

  return (
    <Wrapper>
      <ProfessorWrapper>
        <Text fontSize="13px" fontWeight="600" color="#616161">
          Comentarios del profesor tutor
        </Text>
        <CommentBox>
          <Text fontSize="14px" fontWeight="400" color="#616161">
            {parse(
              parseString(
                professor_comment || "<b>Aún no hay comentarios sobre esta tarea.</b>"
              )
            )}
          </Text>
        </CommentBox>
      </ProfessorWrapper>
      {
        values &&
        <>
          <Text fontSize="13px" fontWeight="600" color="#616161">
            Adjuntos del profesor
          </Text>
          {
            values?.file_correction || values?.audio_correction || values?.video_correction ?
              <Attachments
                values={values}
              />
              :
              <CommentBox>
                <Text fontSize="14px" fontWeight="400" color="#616161">
                  <b>No hay adjuntos sobre esta tarea.</b>
                </Text>
              </CommentBox>
          }
        </>
      }
      <StudentWrapper>
        <Text fontSize="13px" fontWeight="600" color="#616161">
          Comentarios del alumno
        </Text>
        <CommentBox>
          <Text fontSize="14px" fontWeight="400" color="#616161">
            {parse(
              parseString(
                student_comment || "<b>No se encontraron comentarios del alumno.</b>"
              )
            )}
          </Text>
        </CommentBox>
      </StudentWrapper>
      <MyActivityButton href={values?.file?.file} target="_blank">
        <Icon icon="ic:round-attach-file" width="24px" height="24px" />
        <Text fontSize="15px" fontWeight="600" color="#FFFFFF">
          Ver mi actividad
        </Text>
      </MyActivityButton>
    </Wrapper>
  );
};

export default CorrectionDetails;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 1rem 1rem 0rem;
`;

const ProfessorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StudentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.6rem;
  border-radius: 20px;
  background-color: #f9f9f9;
  border: 1px solid #C4C4C4;
`;

const MyActivityButton = styled.a`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #cd2118;
  border-radius: 100px;
  padding: 12px 24px;

  svg {
    color: #fff;
  }
`