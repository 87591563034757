import { Button, Dialog } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { encodeData } from '../../../../helpers/encrypt.helper'
import { postAssignmentPrograms } from '../../../../redux/api/studyPlan'
import { Text } from '../../../common/Texts'

const CardFreeGraduates = (props) => {

  const { values } = props
  const { id, scorm_name, image, description, price, currency, enrrollment = false } = values

  // HOOKS
  const { push } = useHistory()
  const [open, setOpen] = useState(false)
  const { user } = useSelector(state => state.auth)
  const [loading, setLoading] = useState(false)

  // FUNCTIONS
  const handleOpen = () => push(!enrrollment ? `../producto/${id}` : `../mi-progreso/${encodeData(String(id))}`)
  const handleDialog = () => setOpen(!open)
  const handleEnrrol = async () => {
    setLoading(true)
    const body = {
      type: 'programa',
      state: "DEMO",
      assignment: id,
    };
    console.log('body', body)
    const response = await postAssignmentPrograms(user.repo_id, body);
    if (response.error) {
      alert("¡Ocurrió un error!")
      return
    }
    setLoading(false)
    handleDialog()
    push(`../mi-progreso/${encodeData(String(id))}`)
  }

  return (
    <CardContainer>
      <ImageBanner bg={image}>
        {enrrollment && <ChipState>Registrado</ChipState>}
        {!enrrollment && <Price>{currency} {price}</Price>}
      </ImageBanner>
      <Body>
        <Text color="#333" fontSize="16px" fontWeight="bold">{scorm_name}</Text>
        <Description>{description}</Description>
      </Body>
      <Footer>
        <Buttons>
          {/* {!enrrollment && <Button onClick={handleDialog} color="primary">Prueba gratis</Button>} */}
          <Button onClick={handleOpen} color="primary" variant='contained'>{!enrrollment ? 'Obtener' : 'Ir a cursar'}</Button>
        </Buttons>
      </Footer>

      {/* Matriculacion */}
      <Dialog open={open} onClose={handleDialog}>
        {!loading ? <Container>
          <Text textAlign="start" color="#333" fontSize="18px" fontWeight="bold">¿Desea matricularse en {scorm_name}?</Text>
          <Wrapper>
            <Button onClick={handleDialog} color="primary">Cancelar</Button>
            <Button onClick={handleEnrrol} color="primary" variant='contained'>Confirmar</Button>
          </Wrapper>
        </Container> : <Container>
          <Text>Cargando</Text>
        </Container>}
      </Dialog>
    </CardContainer>
  )
}

export default CardFreeGraduates

const CardContainer = styled.div`
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 10px;
  width: 100%;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 20px;
  height: 420px;
`

const ImageBanner = styled.div`
  width: 100%;
  height: 150px;
  object-fit: contain;
  background-image: url(${p => p.bg});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px 20px 0 20px;
  position: relative;
`

const Body = styled.div`
  padding: 1rem;
  height: calc(100% - 150px - 2rem - 80px);
`

const Description = styled.div`
  font-size: 12px;
  color: #999;
  max-height: 100px;
  margin-top: 16px;
  overflow-y: auto;
`

const Price = styled.div`
  background-color: white;
  width: fit-content;
  color: #333;
  font-weight: 700;
  font-size: 15px;
  padding: 3px;
  border-radius: 3px 0 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 2px solid #aaa;
  border-left: 2px solid #aaa;
`

const Footer = styled.div`
  background-color: #fafafa;
  height: 80px;
  border-radius: 0 0 20px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 100%;
  padding: 0 16px;
`

const Container = styled.div`
  width: 450px;
  padding: 1rem;
`

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 36px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`

const ChipState = styled.div`
  width: fit-content;
  padding: 3px 10px;
  background-color: #44d5a0;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  font-weight: bold;
  position: absolute;
  top: 15px;
  left: 15px;
`