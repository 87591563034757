import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Text from '../../../common/Text';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { ImportContactsRounded } from '@mui/icons-material';
import Wrapper from '../../shared/Wrapper';

const COLORS = ['#e04f69', '#f2c060', '#63c2a8'];

const CourseStatus = (props) => {
    const { courses } = props;
    const AllCourses = courses ? courses[0].courses : null;

    //STATES
    const [array, setArray] = useState([]);
    const [totalValue, setTotalValue] = useState(null);

    //EFECTS
    useEffect(() => {
        if (AllCourses !== null) {
            sumValues(AllCourses);
        }
    }, [courses]);

    //FUNCTION
    const sumValues = async (array) => {
        const totalV = array.length;
        let finishedValue = 0;
        let toStartValue = 0;
        let onGoing = 0;

        await array.forEach((value) => {
            if (value.progress === 0) {
                return toStartValue++;
            }
            if (value.total !== 0 && value.progress >= 100) {
                return finishedValue++;
            } else {
                return onGoing++;
            }
        });
        setTotalValue(totalV);
        setArray([
            { key: 'to_start', value: toStartValue },
            { key: 'ongoing', value: onGoing },
            { key: 'finished', value: finishedValue }
        ]);
    };

    const percentage = (partialValue, totalValue) => {
        return (100 * partialValue) / totalValue;
    };

    //RETURN
    return (
        <Wrapper columns="span 6">
            <Graphs>
                <Text fontSize="1.5rem" fontWeight="700" color="secondary">
                    Estado de mis cursos
                </Text>
                <PieChartFake width={200} height={200}>
                    <Pie
                        data={array}
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {array.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                    {/* <Tooltip/> */}
                </PieChartFake>
                <RowWrapper columnGap="1rem">
                    {array.map((i, index) => (
                        <ColumnWrapper key={index}>
                            <RowWrapper>
                                <Circle color={COLORS[index]} />
                                <Text
                                    fontSize="0.7rem"
                                    fontWeight="700"
                                    fontcolor={COLORS[index % COLORS.length]}
                                >
                                    {i.key === 'to_start'
                                        ? 'Por iniciar'
                                        : i.key === 'ongoing'
                                        ? 'En curso'
                                        : 'Completo'}
                                </Text>
                            </RowWrapper>
                            <RowWrapper>
                                <Text fontSize="1rem" fontWeight="700">
                                    {i.value}
                                </Text>
                                <ImportContactsRounded
                                    fontSize="small"
                                    color="secondary"
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Text fontSize="1rem" fontWeight="700">
                                    {totalValue !== 0
                                        ? `${percentage(
                                              i.value,
                                              totalValue
                                          ).toFixed(0)}`
                                        : ''}
                                </Text>
                                <Text fontSize="1rem" color="secondary">
                                    {totalValue !== 0 ? '%' : ''}
                                </Text>
                            </RowWrapper>
                        </ColumnWrapper>
                    ))}
                </RowWrapper>
            </Graphs>
        </Wrapper>
    );
};

export default CourseStatus;

const Graphs = styled.div`
    text-align: center;
    vertical-align: middle;
    padding: 2rem;

    justify-content: space-around;
    row-gap: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    flex-direction: column;
    flex-wrap: nowrap;
`;

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${(p) => p.columnGap};
    margin: auto;
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

const Circle = styled.div`
    width: 12px;
    height: 12px;
    background-color: ${(p) => p.color};
`;
const PieChartFake = styled(PieChart)`
    margin: auto;
`;
