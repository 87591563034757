import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SimpleLoading from '../../../common/SimpleLoading';
import { socket } from '../../../../helpers/connectionSocket.helper';

import { ChatMensaje } from './ChatMensaje';

const Body = (props) => {
    const { allMessages, userLogin } = props;

    useEffect(() => {
        if (!!allMessages) {
            gotoBottom('scrollToBottom');
        }
    }, [allMessages]);

    const gotoBottom = (id) => {
        const chatBody = document.getElementById(id);
        chatBody.scrollTop = chatBody.scrollHeight - chatBody.clientHeight;
    };

    if (!allMessages) {
        return <SimpleLoading />;
    }

    return (
        <Container>
            <Scroll id="scrollToBottom">
                {allMessages && (
                    <>
                        {allMessages.map((message, i) => {
                            return (
                                <ChatMensaje
                                    key={i}
                                    // color={message.color}
                                    //   user={message.nombre}
                                    date={message.fecha}
                                    msg={message.mensaje}
                                    adjunto={message.archivo_adjunto}
                                    fileName={message.nombre_archivo}
                                    mimeType={message.mime_type}
                                    fileSize={message.file_size}
                                    esEnviado={
                                        message.partner_id ==
                                        userLogin.partner_id
                                            ? true
                                            : false
                                    }
                                />
                            );
                        })}
                    </>
                )}
            </Scroll>
        </Container>
    );
};

export default Body;

const Container = styled.div`
    height: calc(100% - 190px);
    width: 100%;
    position: relative;
`;

const Scroll = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    padding: 1.3rem;
    scroll-behavior: auto !important;
`;
