import * as types from '../types/status_types';

const actions = {};

// GET BLOCKED STATUS
actions.getBlockedStatusRequest = () => {
    return {
        type: types.GET_BLOCKED_STATUS_REQUEST
    };
};

actions.getBlockedStatusSuccess = (payload) => {
    return {
        type: types.GET_BLOCKED_STATUS_SUCCESS,
        payload: payload
    };
};

actions.getBlockedStatusFail = (error) => {
    return {
        type: types.GET_BLOCKED_STATUS_FAIL,
        payload: error
    };
};

export { actions };
