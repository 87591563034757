import React from "react";
import styled from "styled-components";
import CardCourseStudent from "../../../common/teacher-academic/CardCourseStudent";
import SkeletonTabsGridAA from "../../../common/teacher-academic/SkeletonTabsGridAA";

const Courses = (props) => {
    const { coursesStudent } = props;

    //RETURN
    if (!coursesStudent) {
        return (
            <Container>
                <SkeletonTabsGridAA />
            </Container>
        );
    }
    return (
        <Container>
            <AutoScroll>
                {!!coursesStudent &&
                    coursesStudent.map((value, index) => (
                        <CardCourseStudent key={index} values={value} />
                    ))}
            </AutoScroll>
        </Container>
    );
};

export default Courses;

const Container = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const AutoScroll = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 2rem;
    padding-top: 1rem;
    padding-right: 0.5rem;
    max-height: 600px;
    overflow: auto;

    @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
    }

    ::-webkit-scrollbar {
        background-color: #f9f9f9;
        border-radius: 6px;
        width: 4px;
    }
`;
