import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LogoRed from '../../img/logo/logo-aden-simple-red.png';
import LogoWhite from '../../img/logo/logo-aden-simple-white.png';

const Logo = () => {
    const [logo, setLogo] = useState(false);

    const changeLogo = () => {
        if (window.scrollY >= 80) {
            setLogo(true);
        } else {
            setLogo(false);
        }
    };

    window.addEventListener('scroll', changeLogo);

    return (
        <Link to="/">
            <Img src={logo ? LogoRed : LogoWhite} alt="logo" />
        </Link>
    );
};

export default Logo;

const Img = styled.img`
    width: 180px;
    transition: all 0.4s;
`;
