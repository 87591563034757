import React from "react";
import styled from "styled-components";

// Components
import { Text } from "../../../components/common/Texts";

// Material UI
import { Card } from "@mui/material";

const BannerTask = (props) => {
    const { student, displayMode } = props;

        return (
            <BannerTaskWrapper displayMode={displayMode}>
                <Body>
                    {
                        displayMode === "desktop" && <Avatar src={student.image_url} />
                    }
                    <Text fontWeight="bold" fontSize="20px">
                        {student.name || "Undefined"}
                    </Text>
                </Body>
            </BannerTaskWrapper>
        );
    
    
};

export default BannerTask;

const BannerTaskWrapper = styled(Card)`
    display: ${(p) => p.displayMode === "mobile" ? "none" : "flex" };
    flex-direction: column;
    height: auto;
    width: ${(p) => p.displayMode === "mobile" ? "100vw" : "auto" };
    margin: ${(p) => p.displayMode === "mobile" && "-26px 0px 16px -13px" };
    box-shadow: none;
    padding: 5.5px 0;
    border-bottom: ${(p) => p.displayMode === "desktop" && "solid 1px #c4c4c4" };
    border-left: solid 1px #c4c4c4;
    background-color: #f1f1f1;
    box-shadow: ${(p) => p.displayMode === "mobile" && "0px 4px 4px -2px rgba(24,39,75,0.3)"};
    -webkit-box-shadow: ${(p) => p.displayMode === "mobile" && "0px 4px 4px -2px rgba(24,39,75,0.3)"};
    -moz-box-shadow: ${(p) => p.displayMode === "mobile" && "0px 4px 4px -2px rgba(24,39,75,0.3)"};
    border-radius: 0px;

    @media (max-width: 1200px) {
        border-left: 0px;
        border-radius: ${(p) => p.displayMode === "desktop" && "20px 20px 0px 0px" };
    }

    @media (max-width: 758px) {
        display: ${(p) => p.displayMode === "mobile" ? "flex" : "none" };
    }
`;

const Body = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;

const Avatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: url(${(p) => p.src});
    background-size: 100%;
    background-position: center;
    border: 1px solid #f1f1f1;
`;
