// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
import "./index.css";

import "react-phone-input-2/lib/material.css";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { SplashScreenProvider } from "./components/common/SplashScreen";
import { toAbsoluteUrl } from "./helpers/common.helper";
import { polyfill } from "./polyfill/index";

const { PUBLIC_URL } = process.env;
// Limpio los logs
// process.env.REACT_APP_NAME === "PRODUCCION" &&
//   (console.log = console.warn = console.error = () => {});

// Ejecuto todos los polyfill creados
polyfill();

_redux.setupAxios(axios, store);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register(toAbsoluteUrl("/sw.js"))
      .then((reg) => {
        console.log("Service Worker: Registrado!");
      })
      .catch((err) => {
        console.error("Service Worker :", err);
      });
  });
} else {
  console.error(
    "¡Por favor utilizar un Navegador mas moderno o salir del modo incognico, algunas funciones podrian fallar!"
  );
}

ReactDOM.render(
  <StrictMode>
    <SplashScreenProvider>
      <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </SplashScreenProvider>
  </StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
