import { Button, Card } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getArticles } from "../../redux/api/blog/blogApi";
import SimpleLoading from "../common/SimpleLoading";

const AsideBlog = (props) => {
  const { titleHeader = "También puede interesarte" } = props;
  const history = useHistory();

  // STATES
  const [valueArticles, setvalueArticles] = useState(null);

  // EFFECTS
  useEffect(() => {
    if (valueArticles === null) {
      handleArticles();
    }
  }, []);

  //FUNCTIONS
  const handleArticles = async () => {
    const response = await getArticles();
    if (!!response.error) {
      setvalueArticles([]);
    } else {
      setvalueArticles(response);
    }
  };

  const handleClick = (link) => {
    window.open(link);
  };

  return (
    <CardFake>
      <Header>
        <h2>{titleHeader}</h2>
        <ButtonFake
          onClick={() => {
            history.push(`../blog`);
          }}
          color="primary"
        >
          Ver más
        </ButtonFake>
      </Header>
      <WrapperCards>
        {!valueArticles ? (
          <SimpleLoading />
        ) : (
          <>
            {valueArticles.length > 0 &&
              valueArticles.map((card, index) => (
                <MiniCard
                  key={index}
                  onClick={() => {
                    handleClick(card.link);
                  }}
                >
                  <WrapperTag>
                    {card.tags.length > 0 &&
                      card.tags.map((tag, index) => (
                        <Chip key={index}>{tag}</Chip>
                      ))}
                  </WrapperTag>
                  <h2>{card.title}</h2>
                  <MiniDescription>{card.preview}</MiniDescription>
                  <DateWrapper>
                    <span>{new Date(card.publishedAt).toLocaleString()}</span>
                  </DateWrapper>
                </MiniCard>
              ))}
          </>
        )}
      </WrapperCards>
    </CardFake>
  );
};

export default AsideBlog;

const CardFake = styled(Card)`
  border-radius: 20px;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  width: calc(100% - 2rem);
  height: fit-content;
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 1.15rem;
    color: #222222;
  }
`;

const WrapperCards = styled.div`
  height: 569px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  &::-webkit-scrollbar {
    width: 1px;
  }
`;

const MiniCard = styled(Card)`
  width: calc(100% - 2.5rem);
  min-height: 200px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 10px;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #f1f1f1;
  cursor: pointer;
  box-shadow: none;
  position: relative;
  transition: ease-in-out 0.3s all;

  :hover {
    background: linear-gradient(270deg, #ffe2e1 0%, #fff8f7 100%);
  }

  h2 {
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    height: 2.5rem;
  }

  :before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 8px;
    background-color: #b31d15;
  }
`;

const WrapperTag = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Chip = styled.div`
  width: calc(min-content - 0.6rem);
  height: calc(auto - 0.3rem);
  font-weight: bold;

  padding: 0.15rem 0.3rem;
  background-color: #b31d15;
  color: #fff;
  font-size: 0.8rem;
  border-radius: 5px;
`;

const MiniDescription = styled.div`
  color: #949494;
  height: 90px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 1px;
  }
`;

const ButtonFake = styled(Button)`
  background: none !important;
`;

const DateWrapper = styled.div`
  width: 100%;

  span {
    color: #a8a8a8;
    font-size: 14px;
  }
`;
