import styled from 'styled-components';

// Components
import CommonButton from '../../../../../../components/common/CommonButton';
import LazyImg from '../../../../../../components/common/LazyImg';
import { Icon } from '@iconify/react/dist/iconify.js';

// Material UI
import { Modal } from '@mui/material';

// Assets
import DoubtPerdon from '../../../../../../assets/media/aden/doubt-person.png'

const ModalCorrectAnswers = (props) => {

    const { isOpen, handleCloseModal, handleClick } = props;

    return (
        <>
            <Modal
                onClose={handleCloseModal}
                open={isOpen}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.001)' }}
            >
                <Container>
                    <CloseButton onClick={handleCloseModal}>
                        <Icon icon="material-symbols-light:close" width="32px" height="32px" />
                    </CloseButton>
                    <LazyImg
                        src={DoubtPerdon}
                        width="100%"
                        height="100%"
                        backgroundColor="transparent"
                    />
                    <Title>
                        ¿Quieres ver las respuestas
                        <br />
                        correctas ahora?
                    </Title>
                    <Description>
                        Ten en cuenta que una vez que las visualices
                        <br />
                        <b>no podrás volver a realizar la actividad</b>
                    </Description>
                    <ButtonsContainer>
                        <CommonButton
                            label={"Ver respuestas"}
                            onClick={handleClick}
                            variant="filled"
                        />
                        <CommonButton
                            label={"Volver"}
                            onClick={handleCloseModal}
                            variant="outlined"
                        />
                    </ButtonsContainer>
                </Container>
            </Modal>
        </>
    );
};

export default ModalCorrectAnswers;

const Container = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    border-radius: 20px;
    width: 405px;
    max-width: 90vw;
    height: fit-content;
    max-height: 90vh;
    padding: 2rem 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    overflow-y: auto;
`;

const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    top: 2rem;
    right: 2rem;
`

const Title = styled.h2`
    color: #b31d15;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
`;

const Description = styled.p`
    color: #222;
    font-size: 1rem;
    text-align: center;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`