import React from 'react';
import styled from 'styled-components';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';

const FilterButton = (props) => {
    const { title } = props;

    return (
        <FilterB>
            <FilterListRoundedIcon />
            {title}
        </FilterB>
    );
};

export default FilterButton;

const FilterB = styled.div`
    color: white;
    font-size: 16px;
    line-height: 14px;
    font-weight: bold;

    background-color: #b31d15;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    box-sizing: border-box;

    .MuiSvgIcon-root {
        font-size: 20px;
        font-weight: bold;
    }
`;
