import React from "react";
import CardSubjectRepo from "../../../ui/molecules/academic/CardSubjectRepo";
import styled from "styled-components";
import SimpleLoading from "../../../common/SimpleLoading";
import { ReactComponent as NotFoundSvg } from "../../../../assets/media/svg/404-Error-pana.svg";

const SubjectsRepo = (props) => {
    const { subjectsRepo, loadingSubjects, repoId } = props;

    if (loadingSubjects) {
        return (
            <Center>
                <SimpleLoading />
            </Center>
        );
    }

    return (
        <Wrapper>
            <Container>
                {subjectsRepo ? (
                    <>
                        {subjectsRepo?.enrollments?.length > 0 ? (
                            <>
                                {subjectsRepo?.enrollments?.map(
                                    (subject, index) => (
                                        <CardSubjectRepo
                                            subject={subject}
                                            repoId={repoId}
                                            key={index}
                                        />
                                    )
                                )}
                            </>
                        ) : (
                            <>
                                <NotFoundSvg width="50%" />
                                <p>no se encontraron asignaturas</p>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <NotFoundSvg width="50%" />
                        <p>Hubo un problema al mostrar las asignaturas</p>
                    </>
                )}
            </Container>
        </Wrapper>
    );
};

export default SubjectsRepo;

const Wrapper = styled.div`
    width: calc(100% - 4rem);
    padding: 0 2rem 2rem 2rem;
    min-height: 400px;
`;

const Container = styled.div`
    display: flex;
    width: calc(100% - 2rem);
    padding: 1rem;
    gap: 1.5rem;

    flex-direction: column;
    height: 100%;
    /* overflow-y: auto; */
    align-items: center;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const Center = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
`;
