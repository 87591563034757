import React from "react";
import styled from "styled-components";

// Formik
import { ErrorMessage, Field } from "formik";

const CustomError = ({ children }) => (
    <div style={{ color: '#b31d15' }}>
        {children}
    </div>
);

const CustomTextField = ({ label, helperText, ...props }) => (
    <InputWrapper>
        {label && <Label>{label}</Label>}
        <Input {...props} />
        {helperText && <HelperText>{helperText}</HelperText>}
    </InputWrapper>
);

const DefaultField = (props) => {
    const { name, type, label, placeholder, variant = 'standard' } = props;

    return (
        <Field
            as={CustomTextField}
            name={name}
            helperText={<ErrorMessage name={name} component={CustomError} />}
            type={type}
            label={label}
            variant={variant}
            placeholder={placeholder}
            color="primary"
            size="small"
            fullWidth
            {...props}
        />
    );
};

export default DefaultField;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60px;
`;

const Label = styled.label`
    margin-bottom: 5px;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.54);
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
`;

const HelperText = styled.span`
    margin-top: 3px;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.54);
`;