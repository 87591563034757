import styled from "styled-components";
import ContactData from "./contactInfo/ContactData";
import SocialNetworks from "./contactInfo/SocialNetworks";

const ContactInfo = (props) => {

    const { values, setFieldValue } = props;

    return (
        <ContactInfoContainer>
            <h2>Datos de contacto</h2>
            <ContactData 
                values={values} 
                setFieldValue={setFieldValue}
            />
            <h2>Redes sociales</h2>
            <SocialNetworks
                values={values}
                setFieldValue={setFieldValue}
            />
        </ContactInfoContainer>
    )
}

export default ContactInfo;

const ContactInfoContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;

    h2 {
        color: #b31d15;
        font-size: 24px;
        font-weight: 700;
    }

    @media (width < 768px) {
        height: fit-content;
        overflow-y: scroll;
    }
`