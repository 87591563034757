export const downloadableAdapter = (microlearnings) => {
    return microlearnings.map((microlearning) => ({
      id: microlearning.repo_id,
      publication_name: microlearning.publication_name,
      link_material: microlearning.link_material,
      type: microlearning.tipo,
      subtype: microlearning.subtipo,
      description: microlearning.descripcion,
      image: microlearning.image_url,
    }));
  };
  