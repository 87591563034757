import { Card } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import usePrograms from '../../../../hooks/common/usePrograms';
import GenericSlider from '../../../../components/common/GenericSlider';
import { Text } from '../../../../components/common/Texts';
import SchoolIcon from '@mui/icons-material/School';
import CheckIcon from '@mui/icons-material/Check';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { checkRoles } from '../../../../helpers/roles.helper';
import { useHistory } from 'react-router-dom';
import { LargeLinearProgress, CustomChip } from '../../../../styled-components';
import SkeletonLoading from '../../../../components/common/SkeletonLoading';
import LazyImg from '../../../../components/common/LazyImg';
import NoCoursesFound from '../../../../assets/media/campus/no-course_found.png';
import useWindowWidth from '../../../../hooks/useWindowWidth';

const ProgramsSlider = () => {
    const { programs } = usePrograms();

    const windowWidth = useWindowWidth();

    if (!programs) {
        return <SkeletonLoading height="319px" width="100%" />;
    }

    if (programs.length === 0) {
        return (
            <CardFake>
                <NotFoundWrapper>
                    <Text color="#616161" fontSize="15px" fontWeight="500">
                        Aún no te encuentras matriculado en ningun programa.
                    </Text>
                    <LazyImg
                        src={NoCoursesFound}
                        width="300px"
                        height="fit-content"
                        backgroundColor="#ffffff"
                    />
                </NotFoundWrapper>
            </CardFake>
        );
    }

    return (
        <>
            {checkRoles(['alumno', 'Admin', 'visita']) && (
                <CardFake data-tut="reactour__ProgramsSlider">
                    <GenericSlider
                        array={programs}
                        card={CardP}
                        to={'/mi-progreso'}
                        title={'Mis programas'}
                        type={'programs'}
                    />
                </CardFake>
            )}
        </>
    );
};

export default ProgramsSlider;

const CardFake = styled(Card)`
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
    border-radius: 30px;
    background-color: #fff;
    padding: 32px 24px;
    height: 319px;

    @media (max-width: 1136px) {
        width: calc(100% - 275px);
        height: 365px;
        margin-top: 30px;
        padding: 0;
    }

    @media (max-width: 1024px) {
        width: 100%;
        height: 380px;
        /* padding: 32px 24px; */
        margin-top: 0;
        padding: 1rem 0 0 1rem;
    }
`;

const CardP = (props) => {
    const { value } = props;

    const windowWidth = useWindowWidth();

    /* const hash = encode.encode(String(value.id), "base64"); */
    const history = useHistory();

    const handleLink = () => {
        let uri = './mi-progreso';
        history.push(uri);
    };

    return (
        <CardWrapp onClick={handleLink}>
            <ProgressContent>
                <Text color="#A8A8A8" fontSize="10px" fontWeight="700">
                    PROGRAMA
                </Text>
                <Text
                    color="#222"
                    fontSize={windowWidth >= 1260 ? '18px' : '14px'}
                    fontWeight="400"
                >
                    {value.program}
                </Text>
                <Progress>
                    <IconWrapper>
                        <Icon icon="clarity:user-solid" />
                    </IconWrapper>
                    <LinearProgressWrapper>
                        <LargeLinearProgress
                            variant="determinate"
                            value={
                                // Esta validación se realiza por manqueada del back
                                Math.trunc(value.total_progress) > 100
                                    ? 100
                                    : Math.trunc(value.total_progress)
                            }
                        />
                    </LinearProgressWrapper>
                    <Text color="#616161" fontSize="15px" fontWeight="700">
                        {
                            // Esta validación se realiza por manqueada del back
                            Math.trunc(value.total_progress) > 100
                                ? 100
                                : Math.trunc(value.total_progress)
                        }
                        %
                    </Text>
                    <IconWrapper>
                        <Icon icon="fa6-solid:user-graduate" />
                    </IconWrapper>
                </Progress>
            </ProgressContent>
            <StateWrapper>
                <Text
                    color="#616161"
                    fontSize={windowWidth >= 1260 ? '18px' : '14px'}
                    fontWeight="400"
                >
                    Avance de las asignaturas que componen este programa:
                </Text>
                <StateContent>
                    <StateChip
                        icon={<Icon icon="pajamas:check" fontSize={22} />}
                        color="current"
                        bgColor="#F7F8FD"
                        label={`${value.in_progress} En curso`}
                        width="190px"
                        variant="outlined"
                    />
                    <StateChip
                        icon={<Icon icon="wi:time-4" fontSize={22} />}
                        color="toStart"
                        bgColor="#F9F5EC"
                        label={`${value.pending} Por iniciar`}
                        width="190px"
                        variant="outlined"
                    />
                    <StateChip
                        icon={
                            <Icon
                                icon="fluent:hat-graduation-12-regular"
                                fontSize={22}
                            />
                        }
                        color="ended"
                        bgColor="#F7FDFB"
                        label={`${value.finished} Finalizados`}
                        width="190px"
                        variant="outlined"
                    />
                </StateContent>
            </StateWrapper>
        </CardWrapp>
    );
};

const CardWrapp = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    /* grid-template-columns: repeat(2, 1fr); */

    @media (max-width: 1600px) {
        /* grid-template-columns: 1fr; */
        height: auto;
    }

    @media (max-width: 1336px) {
        gap: 1rem;
    }
`;

const ProgressContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.3rem;
    min-width: 400px;
    height: fit-content;
    overflow: hidden;
    white-space: nowrap;
`;

const StateContent = styled.div`
    display: flex;
    gap: 2rem;
    max-width: 600px;

    @media (max-width: 1136px) {
        flex-direction: column;
        align-items: center;
        gap: 7px;
    }
`;

const Skeleton = styled.div`
    background-color: #ededed;
    border-radius: 30px;
    height: ${(p) => p.h};
    width: ${(p) => p.w};
`;

const Progress = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media (max-width: 1260px) {
        justify-content: flex-start;
        width: calc(100% - 100px);
    }
`;

const LinearProgressWrapper = styled.div`
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 16px;
    background-color: #eaeaea;

    @media (max-width: 1336px) {
        justify-content: flex-start;
    }
`;

const IconWrapper = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: flex-end;
    border-radius: 50%;
    padding: 0.3rem;
    border: 2px solid #616161;
    background-color: #e5e5e5;

    svg {
        width: 20px;
        height: 19px;
        color: #616161;
    }

    @media screen and (max-width: 768px) {
        svg {
            width: 16px;
            height: 15px;
        }
    }
`;

const StateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const NotFoundWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    p {
        text-align: center;
        width: 250px;
    }
`;

const StateChip = styled(CustomChip)`
    @media (max-width: 1260px) {
        font-size: 12px;
        width: 150px;

        svg {
            font-size: 16px;
        }
    }
`;
