import { InputAdornment, TextField } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import React, { useState } from "react";
import styled from "styled-components";
import useCoursesCanvas from "../../../../hooks/useCoursesCanvas";
import ErrorResponse from "../../../common/ErrorResponse";
import NoResultsFound from "../../../common/NoResultsFound";
import SimpleLoading from "../../../common/SimpleLoading";
import SplitButton from "../../../common/SplitButton";
import CourseCardCanvas from "../../teacher/courses/CourseCardCanvas";

const GridCoursesCanvas = () => {
    const options = ["Por defecto", "Más antiguo", "Más nuevo"];
    const [currentFilter, setCurrentFilter] = useState({
        index: 0,
        name: "Por defecto",
    });
    const [filterField, setFilterField] = useState("");

    const { initialCoursesCanvas, coursesCanvas, handleCoursesCanvas } =
        useCoursesCanvas();

    const handleOption = (args) => {
        setCurrentFilter(args);
        args.index === 0 &&
            handleCoursesCanvas(
                coursesCanvas.sort(
                    (a, b) => a.canvas_course_id > b.canvas_course_id
                )
            );
        args.index === 1 &&
            handleCoursesCanvas(coursesCanvas.sort((a, b) => a.date > b.date));
        args.index === 2 &&
            handleCoursesCanvas(coursesCanvas.sort((a, b) => a.date < b.date));
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterField(value);
        handleCoursesCanvas(
            initialCoursesCanvas.filter((course) =>
                course.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
    };

    if (coursesCanvas === false) {
        return (
            <GridTeacherWrapper>
                <ErrorResponse />
            </GridTeacherWrapper>
        );
    }

    if (coursesCanvas === null) {
        return (
            <GridTeacherWrapper>
                <SimpleLoading />
            </GridTeacherWrapper>
        );
    }

    return (
        <GridTeacherWrapper>
            <Header>
                <TextField
                    value={filterField}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    label="Buscar curso..."
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                />
                <SplitButton handleOption={handleOption} options={options} />
            </Header>
            {coursesCanvas.length === 0 && <NoResultsFound />}
            <Grid>
                {coursesCanvas.map((courses, index) => (
                    <CourseCardCanvas key={index} values={courses} />
                ))}
            </Grid>
        </GridTeacherWrapper>
    );
};

export default GridCoursesCanvas;

const GridTeacherWrapper = styled.div``;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`;

const Grid = styled.div`
    margin: 1rem 0 0 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
    justify-items: center;

    @media (max-width: 400px) {
        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    }
`;
