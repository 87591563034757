import React from "react";
import TablePagination from "@mui/material/TablePagination";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

export default function GenericTable(props) {
    const {
        rows,
        footer = true,
        pageSize = false,
        loading,
        handleOpenModal,
    } = props;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize || 5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return (
            <SkeletonWrapper>
                <CircularProgress />
            </SkeletonWrapper>
        );
    }

    return (
        <div>
            <Table>
                <thead>
                    <tr>
                        <Th>ORDEN</Th>
                        <Th>ORDEN DE PAGO</Th>
                        <Th>TIPO DE ORDEN</Th>
                        <Th>REFERENCIA</Th>
                        <Th>FECHA DE CARGA</Th>
                        <Th>FECHA DE ACTIVIDAD</Th>
                        <Th>RESPONSABLE</Th>
                        <Th>ESTADO</Th>
                        <Th>MONTO</Th>
                        <Th>CANCELAR</Th>
                    </tr>
                </thead>
                <tbody>
                    {(rowsPerPage > 0
                        ? rows.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        : rows
                    ).map((row, index) => (
                        <Tr key={index} onClick={() => {
                            handleOpenModal(row.name);
                        }}>
                            <Td>{row?.name}</Td>
                            <Td>{row?.pay_order || "-"}</Td>
                            <Td>{row?.type_id_name || "-"}</Td>
                            <Td align="start">{row?.ref}</Td>
                            <Td>{row?.date}</Td>
                            <Td>{row?.date_order}</Td>
                            <Td>{row?.responsible}</Td>
                            <Td>{row?.state}</Td>
                            <Td>{row?.amountNew}</Td>
                            <Td>{row?.cancel}</Td>
                        </Tr>
                    ))}
                </tbody>
            </Table>
            {!!footer && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={"Filas por página"}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
}

const Table = styled.table`
    width: 100%;
    border-radius: 10px;
    border-spacing: 0;
    border: 1px solid #b31d15;
    margin-bottom: 1rem;

    thead {
        tr:first-child {
            th:first-child {
                border-radius: 10px 0 0 0;
            }
            th:last-child {
                border-radius: 0 10px 0 0;
            }
        }
    }
`;

const Th = styled.th`
    padding: 8px;
    text-align: left;
    text-align: center;
    border-bottom: 1px solid #b31d15;
    color: #b31d15;
    background-color: #F1F1F1;

    &:first-child {
        th:first-child {
            border-radius: 0 0 0 10px;
        }
        th:last-child {
            border-radius: 0 0 10px 0;
        }
    }
`;

const Td = styled.td`
    padding: 8px;
    text-align: ${(props) => props.align === "start" ? "start" : "center"};
`;

const Tr = styled.tr`
    cursor: pointer;
    &:nth-child(even) {
        background-color: #f9f9f9;
    }

    &:last-child {
        border-radius: 0 0 10px 10px;
        td:first-child {
            border-radius: 0 0 0 10px;
        }
        td:last-child {
            border-radius: 0 0 10px 0;
        }
    }
`;

const SkeletonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 300px;
    padding-bottom: 150px;
`;
