import React, { useEffect } from 'react';
import styled from 'styled-components';

import SearchIcon from '@mui/icons-material/Search';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import ProfileSummary from '../../organisms/profile-summary/ProfileSummary';
import { useShow } from '../../../../hooks/useShow';
import { useDispatch, useSelector } from 'react-redux';
// import { directoriesActions } from '../../../redux/actions';
import { chatActions } from '../../../../redux/actions';

const DataUser = (props) => {
    const { statusUser, statusChat } = props;

    //HOOKS
    const [show, handleShow, handleClose] = useShow(false);
    const dispatch = useDispatch();
    const currentPartner = useSelector((state) => state.chatPartner.partner);
    const { activeRol } = useSelector((state) => state.auth);
    const teacherRol = activeRol ==='docente';
    const url = `/profesor/alumno/${currentPartner?.partner_id}`;

    useEffect(() => {
        if (statusUser?.partner_id) {
            dispatch(chatActions.getPartnerDataRequest(statusUser.partner_id));
        }
    }, [statusUser]);


    // METHODS
    function handleClick() {
        if(teacherRol){
            window.open(url, '_blank');
            return;
        }
        if (!!currentPartner) {
            handleShow();
            return;
        }
    }

    function handleCloseButton() {
        handleClose();
    }


    return (
        <Content>
            {statusChat.estado.estado ? (
                <ClickeableArea onClick={handleClick}>
                    <Image src={statusUser.image} />
                    <Data>
                        <Title>{statusUser.name}</Title>
                        <Status>
                            {statusUser.status ? 'EN LINEA' : 'DESCONECTADO'}
                        </Status>
                    </Data>
                </ClickeableArea>
            ) : (
                <ClickeableArea onClick={handleClick}>
                    <ImageBlocked src={statusUser.image} />
                    <Data>
                        <TitleBlocked>{statusUser.name}</TitleBlocked>
                        <Status>BLOQUEADO</Status>
                    </Data>
                </ClickeableArea>
            )}

            {/* SUMMARY */}
            {currentPartner && (
                <ProfileSummary
                    open={show}
                    handleClose={handleCloseButton}
                    contact={currentPartner}
                    showChatButton={false}
                    maxWidth="md"
                />
            )}

        </Content>
    );
};

export default DataUser;

const Content = styled.div``;

const ClickeableArea = styled.div`
    display: grid;
    grid-template-columns: 4rem 1fr;
    padding: 0 15px;
    cursor: pointer;
`;

const Image = styled.img`
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
`;

const ImageBlocked = styled.img`
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.5;
`;

const Data = styled.div`
    width: auto;
    height: 3.5rem;
    padding-left: 0.2rem;
    padding-top: 5px;
`;

const Title = styled.h6`
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.1rem;
`;

const TitleBlocked = styled.h6`
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.1rem;
    color: #b3b3b3;
`;

const Status = styled.h6`
    font-weight: 700;
    font-size: 0.8rem;
    color: #b3b3b3;
`;
