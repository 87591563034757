import { useEffect, useState } from "react";
import {
  getStudentsAAServicesRepoById,
  getStudentsAAServicesRepo,
} from "../../redux/api/students";
import { useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../utils/snackStyles";

function useRepoStudents() {
  const { repositorio_id } = useSelector(
    (state) => state?.auth?.user?.repositorio_data
  );

  const [myStudents, setMyStudents] = useState(null);
  const [initialMyStudents, setInitialMyStudents] = useState(null);
  const [allStudents, setAllStudents] = useState(null);
  const [initialAllStudents, setInitialAllStudents] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalMyStudents, setTotalMyStudents] = useState(null);
  const [totalAllStudents, setTotalAllStudents] = useState(null);
  const [pageSize, setPageSize] = useState(100);

  const [openSnackbar] = useSnackbar(optionsStyle);

  // EFFECTS
  useEffect(() => {
    !allStudents && getAllStudents("", 0);
  }, [allStudents]);

  // FUNCTIONS
  const getRepoStudents = async (pageSize = 50, offset = 0) => {
    setLoading(true);
    const request = await getStudentsAAServicesRepoById(repositorio_id, pageSize, offset);
    if (request.error) {
      openSnackbar("¡No se pudieron cargar los estudiantes!");
      setInitialMyStudents(false);
      setMyStudents(false);
      return;
    }
    setInitialMyStudents(request.response_data);
    setTotalMyStudents(request.cantidad_particpantes);
    setLoading(false);
  };
  
  const getAllStudents = async (inputValue, offset = 0) => {
    setLoading(true);
    let body = {
      "partner_id.name": inputValue,
    };
    const request = await getStudentsAAServicesRepo(pageSize, offset, body);
    if (request.error) {
      openSnackbar("¡No se pudieron cargar los estudiantes!");
      setInitialAllStudents(false);
      setAllStudents(false);
      return;
    }
    setAllStudents(request.response_data);
    setInitialAllStudents(request.response_data);
    setTotalAllStudents(request.cantidad_participantes);
    setLoading(false);
  };

  // filtro de busqueda por fase académica, solo para los estudiantes del crm
  const handleStudents = (newStudents) => {
    setMyStudents(newStudents);
  };

  const handleAllStudents = (newStudents) => {
    setAllStudents(newStudents);
  };

  return {
    initialMyStudents,
    myStudents,
    loading,
    handleStudents,
    allStudents,
    getAllStudents,
    handleAllStudents,
    totalAllStudents,
    totalMyStudents,
    pageSize,
    getRepoStudents,
  };
}

export default useRepoStudents;
