import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// Components
import CommonButton from '../../../../../../components/common/CommonButton';
import { Text } from '../../../../../../components/common/Texts';
import StateActivities from './customAccordion/StateActivities';
import ModalBlocked from './customAccordion/ModalBlocked';
import { Icon } from '@iconify/react/dist/iconify.js';
import ExtraInfo from './customAccordion/ExtraInfo';

// Material UI
import { LinearProgress } from '@mui/material';

const CustomAccordion = (props) => {

    const {
        module,
        disabled,
        blocked,
        reasonBlocked,
        disableLast,
        handleClick,
        infoEnabled,
        hasElectiveSubject
    } = props;

    const history = useHistory();

    const [expanded, setExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const contentRef = useRef(null);

    const expandedAvailable = module?.description;

    const handleToggle = () => {
        if (!expandedAvailable || disabled || blocked) return;
        setExpanded(!expanded);
    };

    const extraInfo = () => {
        if (blocked) {
            return disableLast ? "5rem" : "2.5rem";
        }
        if (disableLast) {
            return "2.5rem";
        }
        return "";
    };

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [expanded]);

    return (
        <>
            <ModalBlocked
                isOpen={modalIsOpen}
                title="Este bloque se encuentra bloqueado"
                description={`Lo sentimos, no puedes acceder a este bloque por ${reasonBlocked}`}
                handleCloseModal={() => setModalIsOpen(false)}
                handleClick={() => history.push('../mi-academic-advisor')}
            />
            <Accordion
                blocked={blocked}
            >
                <ExtraInfo
                    blocked={blocked}
                    disableLast={disableLast}
                    setModalIsOpen={setModalIsOpen}
                    hasElectiveSubject={hasElectiveSubject}
                />
                <AccordionSummary
                    disabled={disabled || blocked || disableLast}
                    cursorPointer={expandedAvailable && !disabled && !blocked && !disableLast}
                    onClick={handleToggle}
                    infoEnabled={infoEnabled}
                    paddingBottom={() => extraInfo()}
                >
                    {
                        (module?.pendingCorrections || module?.reworkActivities) &&
                        <StateActivities
                            pendingCorrections={module?.pendingCorrections}
                            reworkActivities={module?.reworkActivities}
                        />
                    }
                    <InfoContainer>
                        <Title>
                            <h5>{module?.type}</h5>
                            <h4>{module?.publicationName}</h4>
                        </Title>
                        <ProgressWrapper>
                            <ModuleLinearProgress
                                color="secondary"
                                variant="determinate"
                                value={Math.trunc(module?.progress)}
                            />
                            <Text
                                fontSize={'14px'}
                                fontWeight="400"
                                color="#222222"
                            >
                                {module?.progress}%
                            </Text>
                        </ProgressWrapper>
                        <CommonButton
                            label={module?.progress === 100 ? "Volver a ver" : "Cursar"}
                            variant="filled"
                            onClick={handleClick}
                            disabled={disableLast || disabled || blocked}
                            width="180px"
                        />
                        {expandedAvailable
                            ? <SeeMoreButton
                                expanded={expanded}
                            >
                                {
                                    expanded ? <label>Ver menos</label> : <label>Ver más</label>
                                }
                                <Icon
                                    icon="fluent:chevron-down-16-regular"
                                    width="24px"
                                    height="24px"
                                />
                            </SeeMoreButton>
                            : <div style={{ width: "160px" }} />}
                    </InfoContainer>
                </AccordionSummary>
                <AccordionDetails
                    expanded={expanded}
                    contentHeight={contentHeight}
                    ref={contentRef}
                >
                    <p>
                        {module?.description}
                    </p>
                    <label>
                        <Icon icon="uit:clock" width="24px" height="24px" />
                        <b>
                            {module.duration}{" "}
                            {module.durationUnitOfTime}
                        </b>
                        <p>
                            de cursado
                        </p>
                    </label>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default CustomAccordion;

const Accordion = styled.div`
    border: 1px solid #ddd;
    border-radius: 30px;
    margin: 10px 0;
    padding: 24px 24px 24px 24px;
    min-width: calc(72vw - 48px);
    width: 100%;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    background-color: #fff;

    :hover {
        label {
            display: ${({ blocked }) => blocked && "block"};
        }
    }

    @media (width < 1068px) {
        padding: 2rem;
        height: fit-content;
    }
`;

const AccordionSummary = styled.div`
    cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'default')};
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    min-height: 48px;
    width: 100%;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    padding-bottom: ${({ paddingBottom }) => paddingBottom};

    @media (width < 1068px) {
        flex-direction: column;
        gap: 2rem;
        padding-right: 1rem;
        height: 100%;

        button {
            width: 100%; 
            max-width: 240px;
        }
    }
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    width: 100%;
    height: 100%;
    max-height: calc(96px - 48px);
    position: relative;

    @media (width < 1068px) {
        flex-direction: column;
        max-height: 100%;
    }
`

const Title = styled.span`
    width: 400px;
    display: flex;
    flex-direction: column;

    h5 {
        font-size: 16px;
        font-weight: 500;
        color: #000;
    }

    h4 {
        font-size: 20px;
        font-weight: 700;
        color: #b31d15;
    }

    @media (width < 1068px) {
        width: 100%;
    }
`

const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    width: fit-content;

    @media (width < 1068px) {
        width: 100%;
    }
`;

const ModuleLinearProgress = styled(LinearProgress)`
    width: 350px;
    height: 7px;
    border-radius: 8px;
    background-color: #eaeaea;

    @media (width < 1500px) {
        width: 250px;
    }

    @media (width < 1068px) {
        width: 100%;
    }

    @media (width < 768px) {
        width: 100%;
    }
`;

const SeeMoreButton = styled.span`
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px 12px 12px 24px;
    border-radius: 100px;

    svg {
        transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
        transition: transform 0.3s ease;
    }

    label {
        cursor: pointer;
    }

    &:hover {
        box-shadow: 0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F;
        background-color: #F9F9F9;
    }
    
    @media (width < 1068px) {
        position: absolute;
        top: 2.5rem;
        right: -1rem;

        label {
            display: none;
        }
    }
`

const AccordionDetails = styled.div`
    margin-top: ${({ expanded }) => (expanded ? '24px' : '0')};
    height: ${({ expanded, contentHeight }) => (expanded ? `${contentHeight}px` : '0')};
    overflow: hidden;
    transition: height 0.3s ease, margin-top 0.3s ease;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    label {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4px;

        svg {
            color: #b31d15;
        }
    }

    @media (width < 1068px) {
        font-size: 14px;
    }
`;