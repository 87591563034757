import { authActions } from './actions';

export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        (config) => {
            const {
                auth: { authToken, token }
            } = store.getState();
            if (authToken || token) {
                config.headers.Authorization = `Bearer ${authToken || token}`;
                // config.headers.Authorization = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InhJLWdwRGxnSkh2R1Fyd0xHLVVYR1MzZUdUWSJ9.eyJhdWQiOiIyYzU0ZmMyMS00M2I3LTRjOTYtYmEyOS1hNGE2NmZkNTQzNDIiLCJleHAiOjE2OTgyNTUxMjEsImlhdCI6MTY5NzY1MDMyMSwiaXNzIjoiYWRlbi5vcmciLCJzdWIiOiI2ODg0NWZkMS0zZTNjLTQ1ZmMtODdjOC1lYmViNWY0NjlmZmUiLCJqdGkiOiI2NmVjNDU1Mi01ODgzLTRhNDYtODdhYy0wYTVhZmQ1YzE5OTMiLCJhdXRoZW50aWNhdGlvblR5cGUiOiJQQVNTV09SRCIsImVtYWlsIjoic2ViYW1haWRhbmFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImFwcGxpY2F0aW9uSWQiOiIyYzU0ZmMyMS00M2I3LTRjOTYtYmEyOS1hNGE2NmZkNTQzNDIiLCJyb2xlcyI6WyJhbHVtbm8iLCJkb2NlbnRlIl0sInNjb3BlIjoib2ZmbGluZV9hY2Nlc3MiLCJsYXN0TmFtZSI6Ik1BSURBTkEgQ0lWSVQiLCJvbmRlbWFuZF9pZCI6MCwicmVwb3NpdG9yaW9fcGFydG5lcl9pZCI6NjUsImZpcnN0TmFtZSI6IlNFQkFTVElBTiBBTEJFUlRPIiwiY3JtX3BhcnRuZXJfaWQiOjgwMzcxLCJzaXNJRCI6IjQyMDU5In0.bT5BLLWH9FmLXpi8ssVLGQEK4xvX-xx5NWQehcktQxSeee3Pf_-VT-fUUpFDSL6F8jOt6XDIuUcjYZW9OBn4azmsTv9BZLifEz3VtSieancos7P5Pe4dVjtacXCX4EpyZH7UQkIfXFKQNOFCmW4eM35RhIDHFYZd16zX2P5yz2vZBARaYygNw_h__MExfDECf4-s07xKfLBJTwGlxtNdM1RkgK0aD1TTfWaPI6O1hCbw1_63ozv3cG8N5V739Z6FtbH6u1P4LjVi0Xc16YWvC8vbzySIQ-kjiGUiW2Ej5959T5JlPAuZxQkXZFzU7-8GSXdDD9jhzDhTThU_4zb-qw`;
            }

            return config;
        },
        (err) => Promise.reject(err)
    );
    axios.interceptors.response.use(
        (res) => {
            if (res.data.token) {
                //console.log("RES: ", res);
                store.dispatch(authActions.setRefreshToken(res.data.token));
            }
            return res;
        },
        (error) => {
            const { response } = error;
            if (response) {
                console.log("ERROR: ", error);
                if (
                    response.data?.code === 'expired_token' ||
                    response.data?.detail === 'Token vencido'
                ) {
                    store.dispatch(authActions.logoutUser());
                }
            }

            return Promise.reject(error);
        }
    );
}
