import React from 'react';
import styled from 'styled-components';
import CourseStatus from '../../ui/organisms/business/CourseStatus';
import NextCourses from '../../ui/organisms/business/NextCourses';
import UserProgress from '../../ui/organisms/business/UserProgress';

const Analytics = (props) => {
    const { courses } = props;

    return (
        <ContainerAnalytics>
            <CourseStatus courses={courses} />
            {/* <NextCourses/> */}
            <UserProgress userProgress={courses} />
        </ContainerAnalytics>
    );
};

export default Analytics;

const ContainerAnalytics = styled.div`
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    width: 100%;
    @media (max-width: 1300px) {
        grid-template-columns: 1fr;
    }
`;
