import { Modal } from '@mui/material';
import styled from 'styled-components';
import { Text } from '../Texts';
import { Icon } from '@iconify/react';
import moment from 'moment';

export function formatDate(date, format) {
    return moment.utc(date).format(format);
}

const ModalProblem = (props) => {
    const {
        isOpen,
        title,
        description,
        handleCloseModal,
        handleClick,
        isExpired,
        isToExpire,
        data,
        imageModal,
        duration,
        start,
        expiration,
    } = props;
    const { start_date, expected_end_date, end_date } =
        data?.enrollment_dates || {};

    return (
        <>
            <Modal
                onClose={handleCloseModal}
                open={isOpen}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.001)' }}
            >
                <Wrapper>
                    <CloseButton onClick={handleCloseModal}>
                        <Icon
                            icon="iconoir:cancel"
                            width="24px"
                            height="24px"
                        />
                    </CloseButton>
                    <img src={imageModal} alt="personas" />
                    <Text fontSize="24px" fontWeight={700} color="#B31D15">
                        {title}
                    </Text>
                    <Text
                        fontSize="16px"
                        fontWeight={500}
                        color="#222222"
                        textAlign="center"
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    {isExpired && (
                        <ExpiredWrapper>
                            <Row>
                                <Seccion>
                                    <Icon icon="tdesign:calendar-2" />
                                    <TextDiv>
                                        <p>Inicio</p>
                                        <span>
                                            {formatDate(
                                                start_date ? start_date : start,
                                                'DD/MM/YYYY'
                                            )}
                                        </span>
                                    </TextDiv>
                                </Seccion>{' '}
                                <Seccion>
                                    <Icon icon="ph:calendar-x" />
                                    <TextDiv>
                                        <p>Vencimiento</p>
                                        <span>
                                            {formatDate(
                                                end_date
                                                    ? end_date
                                                    : expiration,
                                                'DD/MM/YYYY'
                                            )}
                                        </span>
                                    </TextDiv>
                                </Seccion>
                            </Row>
                            <Row>
                                <Seccion>
                                    <Icon icon="carbon:time" />
                                    <span>
                                        {duration
                                            ? duration
                                            : data?.duration_week === 1
                                            ? data?.duration_week + ' semana'
                                            : data?.duration_week + ' semanas'}
                                    </span>
                                    <p>de cursado </p>
                                </Seccion>
                            </Row>
                        </ExpiredWrapper>
                    )}
                    {isToExpire && (
                        <ExpiredWrapper>
                            <Row>
                                <Seccion>
                                    <Icon icon="tdesign:calendar-2" />
                                    <TextDiv>
                                        <p>Inicio</p>
                                        <span>
                                            {formatDate(
                                                start_date,
                                                'DD/MM/YYYY'
                                            )}
                                        </span>
                                    </TextDiv>
                                </Seccion>{' '}
                                <Seccion>
                                    <Icon icon="ph:calendar-x" />
                                    <TextDiv>
                                        <p>Vencimiento</p>
                                        <span>
                                            {formatDate(
                                                expected_end_date,
                                                'DD/MM/YYYY'
                                            )}
                                        </span>
                                    </TextDiv>
                                </Seccion>
                            </Row>
                            <Row>
                                <SeccionToExpire>
                                <Icon icon="fluent:calendar-error-24-regular" />
                                    <TextDiv>
                                        <p>Fecha de expiración</p>
                                        <p>
                                            {formatDate(end_date, 'DD/MM/YYYY')}
                                        </p>
                                    </TextDiv>
                                </SeccionToExpire>
                            </Row>
                        </ExpiredWrapper>
                    )}
                    <ButtonGoTo onClick={handleClick}>
                        Contactar a mi Academic Advisor
                    </ButtonGoTo>
                </Wrapper>
            </Modal>
        </>
    );
};

export default ModalProblem;

const Wrapper = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    border-radius: 20px;
    width: 450px;
    padding: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    overflow-y: auto;

    @media (max-width: 768px) {
        width: 320px;
        height: auto;
        padding: 2rem 1rem;
    }

    img {
        height: 200px;
    }
`;

const ButtonGoTo = styled.button`
    cursor: pointer;
    min-height: 40px;
    padding: 0 24px;
    border-radius: 100px;
    background-color: #b31d15;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
`;

const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #000;
`;

const ExpiredWrapper = styled.div`
    background-color: #f1f1f1;
    width: calc(100% - 6rem);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    gap: 0.5rem;
    border-radius: 20px;
`;

const Seccion = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: center;
    p {
        color: #616161;
        font-weight: 500;
        font-size: 13px;
    }
    span {
        color: #222222;
        font-size: 14px;
        font-weight: 700;
    }
    svg {
        color: #b31d15;
        font-size: 20px;
        font-weight: 700;
    }

    @media screen and (max-width: 768px) {
        p {
            font-size: 0.8rem;
        }
        span {
            font-size: 0.9rem;
        }
    }
`;

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
`;

const SeccionToExpire = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    padding: 0.5rem 1rem;
    gap: 4px;
    align-items: center;
    justify-content: center;
    background: #fff5f5;
    border: 1px solid #e94c44;
    border-radius: 10px;

    p {
        color: #e94c44;
        font-weight: 700;
        font-size: 13px;
    }
    svg {
        color: #e94c44;
        font-size: 20px;
        font-weight: 700;
    }

    @media screen and (max-width: 768px) {
        p {
            font-size: 0.8rem;
        }
    }
`;
