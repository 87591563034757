import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import {
    IconButton,
    InputAdornment,
    Button,
    CircularProgress
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { registerStudentSchema } from '../../../../utils/schemas';
import DefaultField from '../../../common/DefaultField';
import { authActions } from '../../../../redux/actions';
import { getPublicInfoContact } from '../../../../redux/api/auth';
import SimpleLoading from '../../../common/SimpleLoading';
import InfoSignUp from '../../molecules/auth/InfoSignUp';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../../../../utils/snackStyles';

const RegisterFormStudent = (props) => {
    const {
        loadingRegister,
        errorRegister,
        signUp,
        registerStudentRequest,
        clearErrorRegister,
        id
    } = props;

    const [openSnackbar] = useSnackbar(optionsStyle);

    // STATE
    const initialValues = {
        first_name: '',
        email: '',
        password: ''
    };
    const [showPassword, setShowPassword] = useState(false);
    const [showRecoPassword, setShowRecoPassword] = useState(false);
    const [form, setForm] = useState(null);

    // EFFECTS
    useEffect(() => {
        if (signUp) {
            openSnackbar(`¡${form?.name} has sido dado de alta correctamente!`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errorRegister) {
            openSnackbar(`Ocurrió un error al dar de alta al alumno`);
            setTimeout(() => {
                clearErrorRegister();
            }, 1000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!form) {
            getInfo(id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // FUNCTIONS
    const getInfo = async (partnerId) => {
        const request = await getPublicInfoContact(partnerId);
        if (request.error) {
            alert('Error');
            return;
        }
        setForm(request);
    };

    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }

    function toggleShowRecoPassword() {
        setShowRecoPassword(!showRecoPassword);
    }

    function handleSubmit(values) {
        registerStudentRequest({
            password: values.password,
            // fa_id: form?.fa_id
            email: form.personal_email
        });
    }

    if (form === null) {
        return <SimpleLoading />;
    }

    if (!!signUp) {
        return <InfoSignUp name={form?.name} mail={signUp} />;
    }

    // RETURN
    return <>
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={registerStudentSchema}
        >
            {() => (
                <Container>
                    {/** Nombre */}
                    <DefaultField
                        name="first_name"
                        type="text"
                        label="Nombre"
                        disabled
                        value={form.name}
                    />
                    {/** Email */}
                    <DefaultField
                        name="email"
                        type="email"
                        label="Email"
                        disabled
                        value={form.personal_email}
                    />
                    {/** Contraseña */}
                    <DefaultField
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        label="Contraseña"
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={toggleShowPassword} size="large">
                                        {showPassword ? (
                                            <Visibility fontSize="small" />
                                        ) : (
                                            <VisibilityOff fontSize="small" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {/** Repetir contraseña */}
                    <DefaultField
                        name="changepassword"
                        type={showRecoPassword ? 'text' : 'password'}
                        label="Repetir contraseña"
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={toggleShowRecoPassword} size="large">
                                        {showRecoPassword ? (
                                            <Visibility fontSize="small" />
                                        ) : (
                                            <VisibilityOff fontSize="small" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {/** Boton registrarse */}
                    {loadingRegister ? (
                        <CircularProgress color="primary" size={32} />
                    ) : (
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            fullWidth
                        >
                            Registrarme
                        </Button>
                    )}
                </Container>
            )}
        </Formik>
    </>;
};

const mapStateToProps = (state) => ({
    loadingRegister: state.auth.loadingSignUp,
    errorRegister: state.auth.signUpError,
    signUp: state.auth.signUp
});

const mapDispatchToProps = (dispatch) => {
    return {
        registerStudentRequest: (values) =>
            dispatch(authActions.registerStudentRequest(values)),
        loginUserRequest: (values) =>
            dispatch(authActions.loginUserRequest(values)),
        clearErrorRegister: () => dispatch(authActions.clearErrorRegister())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterFormStudent);

const Container = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding: 0;
        width: 100%;
    }
`;
