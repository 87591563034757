import React from 'react';
import styled from 'styled-components';

import Text from '../../../common/Text';
import NextCourseCard from '../../molecules/business/NextCourseCard';

const NextCourses = () => {
    return (
        <Container>
            <Text
                fontSize="1.5rem"
                fontWeight="700"
                color="secondary"
                alaign="center"
            >
                ¡Completa estos cursos antes de que terminen!
            </Text>
            <WrapperOverflow>
                <NextCourseCard />
                <NextCourseCard />
                <NextCourseCard />
                <NextCourseCard />
                <NextCourseCard />
                <NextCourseCard />
            </WrapperOverflow>
        </Container>
    );
};

export default NextCourses;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 2rem;
    padding: 2rem;
    width: 100%;
    overflow-x: hidden;
`;

const WrapperOverflow = styled.div`
    display: flex;
    column-gap: 1rem;
    overflow-x: scroll;
`;
const NextCourseCardFake = styled(NextCourseCard)`
    grid-area: nextcoursecard;
`;
