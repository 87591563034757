import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { courseSubjectAdapter } from '../adapters/courseSubject.adapter';
import useLayout from '../../../hooks/useLayout';

const Context = React.createContext();

export function CourseSubjectContextProvider({ children, hash, repoId }) {
    const { setMenu } = useLayout();

    const [data, setData] = useState([]);

    const getData = async () => {
        const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/${hash}?repo_id=${repoId}`;
        try {
            const res = await axios.get(URL);
            const data = res.data;
            const postData = data.response_data.assignment;
            setMenu(postData.name);
            const URL_POST = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/update_latest_entry_acropolis/${repoId}?asignatura_id=${postData?.id}`;
            try {
                await axios.post(URL_POST);
            } catch (error) {
                console.error(error)
            }
            return postData;
        } catch (error) {
            return error.request.status;
            /* return {
                error,
            }; */
        }
    };

    const {
        data: subjectData,
        loading,
        error,
        isError,
        refetch,
    } = useFetch({
        asyncFn: () => getData(),
        adapter: courseSubjectAdapter
    });

    useEffect(() => {
        !!subjectData && setData(subjectData);
    }, [subjectData]);

    const resetData = () => {
        setData([]);
        refetch();
    };

    const contextValues = {
        data,
        dataLoading: loading,
        dataError: error,
        dataisError: isError,
        setData,
        resetData,
    };

    return (
        <Context.Provider value={contextValues}>{children}</Context.Provider>
    );
}

export default Context;
