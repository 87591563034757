import { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { ProfileContext } from "../../../../../contexts/profile/ProfileProvider";

const TextToEdit = (props) => {

    const { property } = props;

    const { user, userLoading, handleUpdateProfile, currentProperty, setCurrentProperty } = useContext(ProfileContext);

    const [editingAcademicTraining, setEditingAcademicTraining] = useState(false);
    const [value, setValue] = useState(user[property?.formatted] || "No hay información disponible");

    const textareaRef = useRef(null);

    const loadingCurrentProperty = currentProperty === property.formatted && userLoading;

    const handleOnClick = () => {
        if (!userLoading) {
            setEditingAcademicTraining(true)
            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.focus();
                }
            }, 10)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setCurrentProperty(property.formatted);
            handleUpdateProfile({ [property.notFormatted]: value });
            setEditingAcademicTraining(false);
        }
        if (event.key === 'Escape') {
            event.preventDefault();
            setValue(user[property?.formatted]);
            setEditingAcademicTraining(false);
        }
    };

    const handleBlur = () => {
        setEditingAcademicTraining(false);
        setValue(user[property?.formatted]);
    }

    return (
        <Container userLoading={userLoading} onClick={handleOnClick}>
            {
                !editingAcademicTraining
                    ? value
                    : <textarea
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        ref={textareaRef}
                    />
            }
            {
                loadingCurrentProperty && <p>Guardando...</p>
            }
        </Container>
    )
}

export default TextToEdit;

const Container = styled.p`
    width: 100%;
    padding: 1rem 0;
    cursor: ${({ userLoading }) => userLoading ? "not-allowed" : "pointer"};
    color: #7F7F7F;

    :hover {
        color: ${({ userLoading }) => !userLoading && "#000"};
    }

    textarea {
        width: calc(100% - 2rem);
        resize: vertical;
        color: #7F7F7F;
        border: none;
        background-color: #f1f1f1;
        padding: 1rem;
    }

    p {
        margin-top: 1rem;
        color: #000;
        font-size: 16px;
    }
`