import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import GridCardWhithSocialMedia from '../../components/templates/academic/allStudents/GridCardWhithSocialMedia';
import TabsComponent from '../../components/common/TabsComponent';
import { titleHTML } from '../../helpers/title.helper';
import useLayout from "../../hooks/useLayout";

const ProgramAcademic = () => {
    titleHTML('Programa');

    const tabs = [
        { label: `Plan de Estudio`, id: 0 },
        { label: `Alumnos`, id: 1 },
        { label: `Cuerpo Académico`, id: 2 }
    ];

    const [tab, setTab] = useState(0);

    const { setMenu, clearMenu } = useLayout()

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
    };

    function ToggleMain(props) {
        const { tab } = props;
        const cases = {
            0: (
                <GridCardWhithSocialMedia
                    type="students"
                    url={`${window.location.origin}/mock/students.json`}
                />
            ),
            1: (
                <GridCardWhithSocialMedia
                    type="students"
                    url={`${window.location.origin}/mock/students.json`}
                />
            ),
            2: (
                <GridCardWhithSocialMedia
                    type="students"
                    url={`${window.location.origin}/mock/students.json`}
                />
            )
        };

        return cases[String(tab)] || <GridCardWhithSocialMedia />;
    }

    useEffect(() => {
        setMenu("Doctorado en Administración de Empresas")
    }, [])

    return (
        <Container>
            {/*<DefaultNavegation program={true} title="Doctorado en Administración de Empresas" />*/}
            <WrapperTop>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            </WrapperTop>
            <BodyWrapper>
                <ToggleMain tab={tab} />
            </BodyWrapper>
        </Container>
    );
};

export default ProgramAcademic;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const WrapperTop = styled.div`
    position: absolute;
    top: 70px;
    left: 70px;
    height: 53px;
    width: calc(100% - 70px);
    display: flex;
    background: #ffffff;
    box-shadow: 0px 1px 0px #e5e5e5;
    z-index: 6;

    @media (max-width: 768px) {
        left: 0;
        width: 100%;
    }
`;

const BodyWrapper = styled.div`
    padding: 2rem;
    width: calc(100% - 4rem);
    border-radius: 20px;
    height: 800px;
    overflow: auto;
`;
