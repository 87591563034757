import { Button, Card } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { Text } from '../../common/Texts';
import { CustomChip } from '../../../styled-components/mui/chip';

const CardHome = () => {
    return (
        <CardWrapper>
            <BodyCard>
                <ChipWrapper>
                    <WorkshopChip
                        bgColor="#ffffff"
                        color="primary"
                        label={<b>Mejora continua</b>}
                        // width="60px"
                        variant="outlined"
                    />
                </ChipWrapper>
                <TextWrapper>
                    <Text fontSize="24px" color="#ffffff" fontWeight="900">
                        Plan de evaluación de Resultados y Efectividad
                    </Text>
                    <Text fontSize="16px" color="#ffffff" fontWeight="400">
                        Estamos trabajando para asegurar la evaluación y mejora
                        continua de los programas ofrecidos en ADEN. Conoce más
                        en el informe
                    </Text>
                </TextWrapper>
                <RedirectButton
                    color="primary"
                    variant="outlined"
                    onClick={() =>
                        window.open(
                            `${window.location.origin}/assets/pdf/file.pdf`
                        )
                    }
                    fullWidth
                >
                    Conoce más
                </RedirectButton>
            </BodyCard>
        </CardWrapper>
    );
};

export default CardHome;

const CardWrapper = styled(Card)`
    height: 100%;
    position: relative;
`;

const BodyCard = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: space-around;
    gap: 3rem;
    flex-direction: column;
    background: linear-gradient(#e51a1a, #b31d15);
`;

const RedirectButton = styled(Button)`
    width: fit-content;
    border-radius: 50px;
    background-color: #ffffff;
    box-shadow: none;

    &:hover {
        background-color: #f1f1f1;
        box-shadow: none;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const ChipWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const WorkshopChip = styled(CustomChip)`
    width: fit-content;

    svg {
        border: none;
    }
`;
