import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAdditionalData } from "../../../../../redux/api/teacher";
import { Text } from "../../../../common/Texts";

const TrainingTeacher = (props) => {
  // REDUX
  const { user } = props;
  const { repositorio_data } = user;
  const { info_personal } = repositorio_data;

  const [typeDni, setTypeDni] = useState(null);
  const [cv, setCv] = useState(null);

  useEffect(() => {
    !typeDni && getInfoData();
  }, [typeDni]);

  const getInfoData = async () => {
    const request = await getAdditionalData(user.repo_id);
    if (request.error) {
      return;
    } else {
      const result = request.type_document.find(
        (type) => type.id === info_personal.l10n_latam_identification_type_id
      );
      setTypeDni(result.nombre);
      setCv(`data:${request.cv.mimetype};base64,${request.cv.base64}`);
    }
  };

  return (
    <Container>
      <>
        <Text fontSize="1.25rem" fontWeight="700">
          Curriculum Vitae
        </Text>
        <PaddingText fontSize="1rem">
          <Button
            download={info_personal.email || "cv"}
            color="primary"
            disabled={!cv}
            href={cv}
            variant="contained"
          >
            Descargar CV
          </Button>
        </PaddingText>
      </>
      <>
        <Text fontSize="1.25rem" fontWeight="700">
          Información personal
        </Text>
        <PaddingText fontSize="1rem">
          <Grid>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                DIRECCIÓN
              </Text>
              <Text>{info_personal.street || "-"}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                NACIONALIDAD
              </Text>
              <Text>{info_personal.nacionalidad || "-"}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                TÉLEFONO
              </Text>
              <Text>{info_personal.phone || "-"}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                MÓVIL
              </Text>
              <Text>{info_personal.mobile || "-"}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                CORREO PERSONAL
              </Text>
              <Text>{info_personal.email || "-"}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                CORREO LABORAL
              </Text>
              <Text>{info_personal.email_laboral || "-"}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                TIPO DOCUMENTO
              </Text>
              <Text>{typeDni || ""}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                DOCUMENTO
              </Text>
              <Text>{info_personal.vat}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                FECHA NACIMIENTO
              </Text>
              <Text>{info_personal.fecha_nacimiento || "-"}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                VTO. PASAPORTE
              </Text>
              <Text>{info_personal.vencimiento_pasaporte || "-"}</Text>
            </div>
            <div>
              <Text color="#aaaaaa" fontWeight="bold" fontSize=".8rem">
                SEXO ( según documento )
              </Text>
              <Text>{info_personal?.genero || "-"}</Text>
            </div>
          </Grid>
        </PaddingText>
      </>
    </Container>
  );
};

export default TrainingTeacher;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const PaddingText = styled(Text)`
  padding: 0 2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
`;
