import * as encode from "nodejs-base64-encode";
import { useParams } from "react-router-dom";
import React from "react";

// Components
import CourseModule from "./CourseTemplate";

// Helpers
import { titleHTML } from "../../helpers/title.helper";

// Context
import { CourseSubjectContextProvider } from "./context/CourseSubjectContext";

// Redux
import { useSelector } from "react-redux";

export default function Course() {
  titleHTML("Curso");

  const { id } = useParams();
  const { repo_id } = useSelector((state) => state.auth.user) || {};

  const hash = encode.decode(id + "", "base64");

  return (
    <CourseSubjectContextProvider partnerId={repo_id} OAId={hash}>
      <CourseModule id={id} hash={hash} />
    </CourseSubjectContextProvider>
  );
}
