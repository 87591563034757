import { Icon } from '@iconify/react/dist/iconify.js';
import styled from 'styled-components';

// Components
import LazyImg from '../../../../../../components/common/LazyImg';
import CommonButton from '../../../../../../components/common/CommonButton';


// Assets
import SurveyImg from '../../../../../../assets/media/aden/survey-img.png'

const Survey = (props) => {

    const { handleClick, surveyName } = props;

    return (
        <Container>
            <InfoWrapper>
                <LazyImg
                    width={236}
                    height={"100%"}
                    src={SurveyImg}
                    backgroundColor={"#fff"}
                />
                <SurveyInfo>
                    <h3><b>{surveyName}</b></h3>
                    <h4>Tu opinión es muy importante <br /> para seguir mejorando</h4>
                    <p>¿Nos ayudas completando una breve encuesta?</p>
                </SurveyInfo>
                <CommonButton
                    label="Responder encuesta"
                    width="184px"
                    variant="filled"
                    onClick={handleClick}
                    disabled={false}
                />
            </InfoWrapper>
            <SuggestionMessage>
                <IconContainer>
                    <Icon
                        icon="fa6-regular:lightbulb"
                        width="24px"
                        height="24px"
                    />
                </IconContainer>
                <label>
                    Te sugerimos <b>realizar la encuesta</b> cuando ya tengas
                    una <b>visión integral</b> sobre el{' '}
                    <b>profesor tutor y el contenido de la asignatura.</b>
                </label>
            </SuggestionMessage>
        </Container>
    )
}

export default Survey;

const Container = styled.div`
    height: 215px;
    width: 100%;
    padding: 32px 40px 24px 68px;
    min-width: calc(72vw - 132px);
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    border: 1px solid #A8A8A8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 8px;

    @media (width < 1068px) {
        height: 100%;
        padding: 2rem;
        margin-top: 2rem;
    }
`

const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 6rem);
    height: 80%;
    padding-right: 6rem;

    @media (width < 1068px) {
        height: 100%;
        width: 100%;
        flex-direction: column;   
        gap: 2rem;
        padding: 0;
    }
`

const SurveyInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 24px;
    
    h3 {
        font-size: 22px;
        font-weight: 400;
        color: #B31D15;
        max-width: 380px;
    }
   

    h4 {
        font-size: 16px;
        font-weight: 400;
        max-width: 380px;
        color: #222;
    }

    p {
        font-size: 16px;
        font-weight: 700;
        max-width: 380px;
        color: #222;
    }

    @media (width < 1068px) {
        width: 100%;
    }
`

const SuggestionMessage = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

    @media (width < 1068px) {
        margin-top: 2rem;
    }
`

const IconContainer = styled.div`
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #f9f5ec;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c29f43;
`;