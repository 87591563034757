import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useRankingByCourse from "../../../../hooks/teacher/useRankingByCourse";
import useRankingForAdvance from "../../../../hooks/teacher/useRankingForAdvance";
// import QuickAnnouncement from '../../../ui/molecules/teacher/QuickAnnouncement';
import RankingDashboardCourse from "../../../ui/organisms/teacher/rankings/RankingDashboardCourse";
// import Calendar from '../calendar/Calendar';
// import LatestForumEntries from '../forum/LatestForumEntries';
import Qualification from "../qualification/Qualification";

const CourseDashboard = (props) => {
    const { id } = props;

    return (
        <GridCourseDashboard>
            <Qualification type="course" idCourse={id} />
            {/* <RankingDashboardCourse
                title={"Ranking de entregas"}
                type={"progress"}
                ranking={students}
                advanceStudents={advanceStudents}
            /> */}
        </GridCourseDashboard>
    );
};

export default CourseDashboard;

const GridCourseDashboard = styled.div`
    /* display: grid;
    grid-template-columns: 6fr 2.5fr;
    grid-template-areas: "qualification ranking";
    grid-template-rows: 600px;
    gap: 16px; */

    @media (max-width: 1440px) {
        display: flex;
        justify-content: space-space-around;
        flex-direction: column;
    }
`;
