import React, { useEffect, useState } from 'react';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import styled from 'styled-components';

const StarsComment = (props) => {
    const { value } = props;
    const [arrayStar, setArrayStar] = useState([]);
    const [arrayOutlineStar, setArrayOutlineStar] = useState([]);
    const outlineStar = 5 - value;

    useEffect(() => {
        selectStar(value);
        selectOutlineStar(outlineStar);
    }, []);

    const selectStar = (stars) => {
        const star = {
            1: [''],
            2: ['', ''],
            3: ['', '', ''],
            4: ['', '', '', ''],
            5: ['', '', '', '', '']
        };
        setArrayStar(star[stars]);
    };
    const selectOutlineStar = (outlinesStar) => {
        const outlineStar = {
            0: [],
            1: [''],
            2: ['', ''],
            3: ['', '', ''],
            4: ['', '', '', ''],
            5: ['', '', '', '', '']
        };
        setArrayOutlineStar(outlineStar[outlinesStar]);
    };
    return (
        <div>
            {arrayStar.map(() => {
                return <StarFake />;
            })}
            {arrayOutlineStar.map(() => {
                return <StarBorderFake />;
            })}
        </div>
    );
};

export default StarsComment;

const StarFake = styled(Star)`
    color: #f7bb56;
    font-size: 1.2rem;
`;

const StarBorderFake = styled(StarBorder)`
    color: #f7bb56;
    font-size: 1.2rem;
`;
