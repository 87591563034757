import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCoursesCanvas } from "../redux/api/teacher";

const useCoursesCanvas = () => {
    const [coursesCanvas, setCoursesCanvas] = useState(null);
    const [initialCoursesCanvas, setInitialCoursesCanvas] = useState(null);

    const professorSisId = useSelector(
        (state) => state?.auth?.user?.repositorio_data?.professor_sis_id
    );

    useEffect(() => {
        if (!coursesCanvas) getCourseCanvas(professorSisId);
    }, [coursesCanvas]);

    const getCourseCanvas = async (id) => {
        if (coursesCanvas === null) {
            const response = await getCoursesCanvas(id);

            if (!response.error) {
                setCoursesCanvas(response.response_data);
                setInitialCoursesCanvas(response.response_data);
            }
        }
    };

    const handleCoursesCanvas = (newCourses) => {
        setCoursesCanvas(newCourses);
    };

    return { initialCoursesCanvas, coursesCanvas, handleCoursesCanvas };
};

export default useCoursesCanvas;
