/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import {
  getAssignmentRelated,
  getProgram,
  getRelatedPrograms,
  getStudyPlanFake,
  postAssignmentPrograms,
} from "../../redux/api/studyPlan";
import NotFound from "../error/NotFound";
import ProgramsCourses from "../../components/templates/studyPlan/ProgramsCourses";
// import SimpleLoading from "../../components/common/SimpleLoading";
import TabsComponent from "../../components/common/TabsComponent";
import useGetStudyPlan from "../../hooks/students/useGetStudyPlan";
import { useSelector } from "react-redux";
import * as encode from "nodejs-base64-encode";
import SkeletonPlan from "./SkeletonPlan";
import useLayout from "../../hooks/useLayout";

const StudyPlan = () => {
  const { id } = useParams();
  const history = useHistory();

  // STATES
  const [programRelated, setProgramRelated] = useState([]);
  const [data, setData] = useState(null);
  const [dataProgram, setDataProgram] = useState(null);
  const [isRelated, setIsRelated] = useState(null);

  const hash = encode.decode(id, "base64");

  const programId = hash;

  const repo_id = useSelector((state) => state.auth.user.repo_id);

  const tabs = [
    { label: "Plan de estudio", id: 0 },
    // { label: "Mis Calificaciones", id: 1 },
    // { label: "Alumnos", id: 2 },
    // { label: "Cuerpo Académico", id: 3 },
  ];

  const { tab, handleTab } = useGetStudyPlan();
  const { setMenu } = useLayout();

  // EFFECT
  useEffect(() => {
    if (dataProgram === null) {
      getProgramByIds();
    }
  }, []);

  useEffect(() => {
    if (programRelated?.length > 0) {
      getDataAssignmentRelated(programRelated[0].id);
    }
  }, [programRelated]);

  useEffect(() => {
    if (dataProgram !== null) {
      setIsRelated(dataProgram.has_related_programs);
    }
  }, [dataProgram]);

  useEffect(() => {
    dataProgram && setMenu(dataProgram?.name);
  }, [dataProgram]);

  useEffect(() => {
    if (isRelated !== null) {
      getProgramRelated();
    }
  }, [isRelated]);

  useEffect(() => {
    if (data === null) {
      getDataStudyPlan(programId);
    }
  }, [data]);

  // FUNCTIONS

  // trae la info del programa actual
  const getProgramByIds = async () => {
    const response = await getProgram(repo_id, programId);
    if (!response.error) {
      setDataProgram(response);
    } else {
      history.push("../not-found");
    }
  };

  // trae los programas relacionados
  const getProgramRelated = async () => {
    const response = await getRelatedPrograms(dataProgram.id, repo_id);
    if (!response.error) {
      setProgramRelated(response);
    }
  };

  // trae las asignaturas
  const getDataStudyPlan = async (id) => {
    const response = await getStudyPlanFake(id, repo_id);
    if (!response.error) {
      const dataCleaning = response.assignments.filter(
        (subject) => subject.elective_subject !== false
      );

      const modifiedResponse = { ...response, assignments: dataCleaning };

      setData(modifiedResponse);
    } else {
      history.push("../not-found");
    }
  };

  async function handleEnrollment(repoId, subjectId) {
    const body = {
      type: "elective_subject",
      assignment: subjectId,
      program_id: programId,
    };

    const response = await postAssignmentPrograms(repoId, body);
    if (response?.error) {
      return response?.error;
    }
    setData(null);
    return response;
  }

  // trae las asignaturas de planes de estudios relacionados
  const getDataAssignmentRelated = async (id) => {
    const response = await getAssignmentRelated(id, repo_id);
    if (!response.error) {
      setData(response);
    } else {
      history.push("../not-found");
    }
  };

  if (data?.error) {
    history.push("../not-found");
    return <NotFound />;
  }

  if (data === null || dataProgram === null) {
    return <SkeletonPlan />;
  }

  return (
    <Wrapper>
      <TabsWrapper>
        <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
      </TabsWrapper>
      <ContentWrapper>
        <ToggleMain
          id={hash}
          programState={data?.state_program}
          data={data?.assignments}
          programRelated={programRelated}
          dataProgram={dataProgram}
          tab={tab}
          getDataStudyPlan={getDataAssignmentRelated}
          handleEnrollment={handleEnrollment}
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default StudyPlan;

const Wrapper = styled.div`
  margin-top: 3rem;
  background-color: #f1f1f1;
  padding: 1rem 2rem;
  border-radius: 20px;
  box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
`;

const TabsWrapper = styled.div`
  width: calc(100%);
  position: fixed;
  left: 0px;
  top: 70px;
  z-index: 10;
  border-top: 1px solid #f7f7f7;

  header {
    padding-left: 6rem;
  }

  @media (max-width: 768px) {
    left: 0px;
    width: calc(100%);

    header {
      padding-left: 3rem;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  /* margin-top: 30px; */
`;

function ToggleMain(props) {
  const {
    tab,
    data,
    programRelated,
    getDataStudyPlan,
    id,
    dataProgram,
    programState,
    handleEnrollment,
  } = props;

  const isDiplomat = dataProgram?.name?.includes("Diplomado");

  switch (tab) {
    case 0:
      return (
        <ProgramsCourses
          dataProgram={dataProgram}
          data={data}
          id={id}
          programRelated={programRelated}
          getDataStudyPlan={getDataStudyPlan}
          handleEnrollment={handleEnrollment}
          isDiplomat={isDiplomat}
          programState={programState}
        />
      );
    default:
      return null;
  }
}
