export const optionsStyle = {
    style: {
        backgroundColor: "#fff",
        fontSize: "16px",
        textAlign: "center",
        marginBottom: "20px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        border: "1px solid #B31D15",
        color: "#000",
        fontWeight: "bold",
    },
    closeStyle: {
        color: "#000",
        fontSize: "16px",
    },
};