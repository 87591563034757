import { useEffect, useState } from 'react';
import { getStatusServices } from '../redux/api/services';

export const useServices = () => {
    const [status, setStatus] = useState(null);
    const [services, setServices] = useState(null);

    useEffect(() => {
        if (status === null) checkServices();
    }, []);

    useEffect(() => {
        if (status !== null) verifyServices(status);
    }, [status]);

    useEffect(() => {
        services !== null && services.length > 0 && console.info(services);
    }, [services]);

    const checkServices = async () => {
        const request = await getStatusServices();
        if (request.error) {
            setStatus({ sis_api: false });
        } else {
            setStatus(request);
        }
    };

    const verifyServices = (object) => {
        let result = [];
        for (const key in object) {
            if (object[key] === false) {
                result = [...result, key.toUpperCase()];
            }
        }
        setServices(result);
    };

    return [services];
};
