import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useLocation } from 'react-router-dom';
import TabsComponent from '../../common/TabsComponent';
import OptionWrapper from './OptionWrapper';

const NAVEGATION_TABS = [
    { id: 0, label: 'Nuevo ticket' },
    { id: 1, label: 'Consultar estado ticket' }
];

const TicketsMain = () => {
    // QUERY PARAMS
    const { state } = useLocation();

    // STATE
    const [currentTab, setCurrentTab] = useState(0);

    // EFFECTS
    useEffect(() => {
        if (state) {
            setCurrentTab(1);
        }
    }, []);

    // FUNCTION
    function changeCurrentTab(e, value) {
        setCurrentTab(value);
    }

    // RETURN
    return (
        <Container>
            {/** Opciones */}
            <TabsComponent
                tab={currentTab}
                handleTab={changeCurrentTab}
                tabs={NAVEGATION_TABS}
            />
            {/** Contenedor principal */}
            <OptionWrapper currentTab={currentTab} />
        </Container>
    );
};

export default TicketsMain;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;
