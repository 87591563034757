import { useHistory, useParams } from "react-router-dom";
import * as encode from "nodejs-base64-encode";
import { useState, useEffect } from "react";
import styled from "styled-components";

// Components
import DeliveriesDescription from "./myDeliveries/DeliveriesDescription";
import SimpleLoading from "../../../../components/common/SimpleLoading";
import Text from "../../../../components/common/Text";
import SubjectAccordion from "../SubjectAccordion";

// Material UI
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import CheckCircle from "@mui/icons-material/CheckCircle";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonChecked";

// Redux
import { getCalifications } from "../../../../redux/api/califications/califications";
import { useSelector } from "react-redux";

const MyDeliveries = () => {
	const { id } = useParams();

	const idCourse = encode.decode(id, "base64");

	const history = useHistory();

	// REDUX
	const { repo_id } = useSelector((state) => state?.auth?.user);

	// REACT-STATE
	const [califications, setCalifications] = useState(null);

	useEffect(() => {
		!califications && getUserCalifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [califications]);

	// FUNCTIONS
	const getUserCalifications = async () => {
		const request = await getCalifications(repo_id, idCourse);
		if (request.error) {
			setCalifications([]);
			return;
		}
		setCalifications(request);
	};

	if (!califications) {
		return (
			<LoaderWrapper>
				<SimpleLoading />
			</LoaderWrapper>
		);
	}

	if (!califications?.length) {
		return (
			<Wrapper>
				<MessageWrapper>
					<ErrorOutline /> No se encontraron entregas
				</MessageWrapper>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<Text fontSize="20px" fontWeight="600" textColor="#222222">
				Entrega por módulo
			</Text>
			<ModulesWrapper>
				{califications?.map((calification, i) => {
					const moduleId = encode.encode(calification.id + "", "base64");
					return (
						<SubjectAccordion
							key={i}
							data={calification}
							summary={CalificationSummary}
							details={DeliveriesDescription}
							handleClick={(activityId) => {
								history.push(
									`../curso/${moduleId}==?id=${id}&resource=${calification.id}&origin=plan&activity=${encode.encode(activityId + "", "base64")}`
								)
							}}
							description={calification?.activities}
							decorator={true}
							decoratorInset={"4.7rem 0 0 2.91rem"}
							borderRadius="20px"
							height="120px"
							detailsPadding="1rem 0 0 2.3rem"
						/>
					);
				})}
			</ModulesWrapper>
		</Wrapper>
	);
};

export default MyDeliveries;

function CalificationSummary(props) {
	const {
		data: { name, activities },
	} = props;

	const [checked, setChecked] = useState(false);

	useEffect(() => {
		!checked && handleCheckedModule(activities);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked]);

	const handleCheckedModule = (modules) => {
		const modulesCounter = modules?.reduce((acc, curr) => {
			return (acc = curr?.submissions?.length > 0);
		}, false);
		setChecked(modulesCounter);
	};

	return (
		<Container>
			{checked ? <FakeCheckCircle /> : <RadioButtonUnchecked />}
			<TextWrapper>
				<Text fontSize="14px" fontWeight="400" textColor="#616161">
					MÓDULO:
				</Text>
				<Text fontSize="18px" fontWeight="600" textColor="#b31d15">
					{name}
				</Text>
			</TextWrapper>
		</Container>
	);
}

const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 2rem 5rem;
  border-radius: 20px;
  height: auto;
`;

const MessageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 500px;
  font-size: 24px;
  font-weight: 700;
  color: #c8c8c8;
`;

const ModulesWrapper = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 5;

  svg {
    color: #b31d15;
    width: 32px;
    height: 32px;
  }
`;

const FakeCheckCircle = styled(CheckCircle)`
  position: relative;
  z-index: 7;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LoaderWrapper = styled.div`
  margin-top: 2.6rem;
  height: 12.5rem;
`;
