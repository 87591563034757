import React from "react";
import styled from "styled-components";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { IconButton } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Text } from "../Texts";

const SocialMediaToCardTeacher = (props) => {
    const { value } = props;

    return (
        <SocialMedia>

            <BtnSocial
                disabled={value.whatsapp === ""}
                href={`https://wa.me/?phone=${value.whatsapp}`}
                target="_blank"
                rel="noreferrer"
            >
                    <WhatsappIcon />
                    {' '}
                    <Text
                        fontSize="0.8rem"
                        fontWeight="900"
                        color="#B31D15"
                    >Whatsapp</Text>
            </BtnSocial>

            <BtnSocial
                disabled={value.personal_email === ""}
                href={`mailto:${value.personal_email}`}
                target="_blank"
                rel="noreferrer"
            >
                <AlternateEmailRoundedIcon />
                {' '}
                <Text
                    fontSize="0.8rem"
                    fontWeight="900"
                    color="#B31D15"
                >E-mail</Text>
            </BtnSocial>

            <BtnSocial
                disabled={value.mobile === ""}
                href={`tel:${value.mobile}`}
                target="_blank"
                rel="noreferrer"
            >
                <LocalPhoneRoundedIcon />
                {' '}
                <Text
                    fontSize="0.8rem"
                    fontWeight="900"
                    color="#B31D15"
                >Llamar</Text>
            </BtnSocial>

            <BtnSocial
                disabled={value.linkedin === ""}
                href={value.linkedin}
                target="_blank"
                rel="noopener noreferrer"
            >
                <LinkedInIcon />
                {' '}
                <Text
                    fontSize="0.8rem"
                    fontWeight="900"
                    color="#B31D15"
                >LinkedIn</Text>
            </BtnSocial>
            
        </SocialMedia>
    );
};

export default SocialMediaToCardTeacher;

const SocialMedia = styled.div`
    height: 100%;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BtnSocial = styled(IconButton)`
    cursor: pointer;
    transition: width 2s ease-in-out;

    div{ 
        width: 0;
        visibility: collapse;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }

    :hover {
        width: auto;
        background-color: transparent;
        svg{
            color: #B31D15;
        }
        div{
            width: auto;
            animation: 2s linear;
            visibility: visible;
            opacity: 1;
            width: auto;
            margin-left: 0.5rem;
            justify-content: space-evenly;
        }
    }
`;
