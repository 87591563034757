import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPublicProfile } from "../../redux/api/profiles";
import {
    getCoursesStudentAA,
    getProgramsStudentAA,
    getGeneralInfo,
    getStudiesInfo,
    getProfessionalInfo,
    getMoreInfo
} from "../../redux/api/students";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../utils/snackStyles";


function useOneStudent360() {

    let { id } = useParams();
    const [openSnackbar] = useSnackbar(optionsStyle);

    // STATES
    const [publicProfileData, setPublicProfileData] = useState(null)
    const [sisId, setSisId] = useState(null)
    const [generalInfo, setGeneralInfo] = useState(null);
    const [coursesStudent, setCoursesStudent] = useState(null);
    const [programStudent, setProgramStudent] = useState(null);
    const [academicTraining, setAcademicTraining] = useState(null)
    const [professionalInfo, setProfessionalInfo] = useState(null)
    const [moreInfo, setMoreInfo] = useState(null)

    // EFFECTS
    useEffect(() => {
        if (publicProfileData === null) {
            getProfile();
        }
        if (generalInfo === null) {
            getGeneralInfoStudent();
        }
        if (programStudent === null) {
            getProgram();
        }
        if (academicTraining === null) {
            getAcademicTraining();
        }
        if (professionalInfo === null) {
            getProfessionalInfoStudent();
        }
        if (moreInfo === null) {
            getMoreInfoStudent();
        }
    }, []);

    useEffect(() => {
        if (!!sisId & coursesStudent === null) {
            getCourses()
        }
    }, [sisId])

    // FUNCTIONS
    const getProfile = async () => {
        const request = await getPublicProfile(id);
        if (!!request.error) {
            openSnackbar('¡No se pudieron cargar los datos del estudiante!');
            setPublicProfileData(false);
            setSisId(false);

            return;
        }
        setSisId(request.sis_id);
        setPublicProfileData(request);
    };

    const getGeneralInfoStudent = async () => {
        const request = await getGeneralInfo(id);
        if (!!request.error) {
            setGeneralInfo(false);
        } else {
            setGeneralInfo(request);
        }
    };

    const getCourses = async () => {
        const request = await getCoursesStudentAA(sisId);
        if (!!request.error) {
            setCoursesStudent(false);
        } else {
            setCoursesStudent(request);
        }
    };

    const getProgram = async () => {
        const response = await getProgramsStudentAA(id);
        if (!!response.error) {
            setProgramStudent(false);
        } else {
            setProgramStudent(response);
        }
    };

    const getAcademicTraining = async () => {
        const response = await getStudiesInfo(id);
        if (!!response.error) {
            setAcademicTraining(false);
        } else {
            setAcademicTraining(response);
        }
    };

    const getProfessionalInfoStudent = async () => {
        const response = await getProfessionalInfo(id);
        if (!!response.error) {
            setProfessionalInfo(false);
        } else {
            setProfessionalInfo(response);
        }
    };

    const getMoreInfoStudent = async () => {
        const response = await getMoreInfo(id);
        if (!!response.error) {
            setMoreInfo(false);
        } else {
            setMoreInfo(response);
        }
    };

    return {
        publicProfileData,
        generalInfo,
        coursesStudent,
        programStudent,
        academicTraining,
        professionalInfo,
        moreInfo
    };
}

export default useOneStudent360;
