import InteresesSlider from './InteresesSlider';
import styled from 'styled-components';

const Intereses = () => {
    return (
        <Section>
            <Div>
                <H1>Especialízate en las siguientes áreas</H1>
            </Div>
            <InteresesSlider></InteresesSlider>
        </Section>
    );
};

export default Intereses;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
`;

const Div = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    padding: 3rem 0;
`;

const H1 = styled.h1`
    font-size: 2.5rem;
    font-weight: 900;
    color: #b31d15;
    text-align: center;
    padding: 0 2em;
`;
