import React, { useEffect, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DefaultSnackbar = (props) => {
    const { error } = props;
    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (error) {
            setIsOpen(true);
        }
    }, [error]);

    // FUNCTIONS
    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setIsOpen(false);
    }

    // RETURN
    return (
        <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="warning">
                {error}
            </Alert>
        </Snackbar>
    );
};

export default DefaultSnackbar;
