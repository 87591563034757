import React from 'react';
import ModuleContent from '../modulesContents/ModuleContent';

const Modules = (props) => {

    const { courses } = props

    return <ModuleContent courses={courses}/>;
};

export default Modules;
