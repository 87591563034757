import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { titulationActions } from "../../redux/actions";
import { getDocumentCheck } from "../../redux/api/documents";

const useDocumentState = () => {
    // REDUX
    const dispatch = useDispatch();
    const titulation = useSelector((state) => state?.titulation?.titulation);
    const { partner_id, sis_id } = useSelector((state) => state?.auth?.user);

    const sisId = !!sis_id ? sis_id : false;

    // STATES
    const [titulationState, setTitulationState] = useState(titulation);

    // EFFECTS
    useEffect(() => {
        handleGetDocumentation();
    }, []);

    useEffect(() => {
        if (titulationState !== null) {
            if (titulation !== titulationState) {
                dispatch(titulationActions.titulation(titulationState));
            }
        }
    }, [titulationState]);

    // FUNCTIONS
    const handleGetDocumentation = async () => {
        // ACA LLAMARÍA AL ENFPOINT, Y CAMBIARIA EL STATE DE TITULATIONSTATE
        const response = await getDocumentCheck(partner_id, sisId);
        setTitulationState(response);
        // setTitulationState(response);
    };

    return { titulationState };
};

export default useDocumentState;