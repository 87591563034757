import React, { useEffect } from 'react';
import styled from 'styled-components';

import WhatsappBtn from '../../components/common/WhatsappBtn';
import LoginEseade from '../../components/templates/incompany/eseade/LoginEseade';
import PublicityWithoutEseade from '../../components/templates/incompany/eseade/PublicityWithout';
// import PublicityWithoutPorvenir from '../../components/templates/incompany/porvenir/PublicityWithout';

const EseadeIC = () => {
    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <LoginPageContainer>
            <PublicityWithoutEseade />
            <LoginEseade />
            <WhatsappBtn x="right: 7rem" y="bottom: 1.5rem" />
        </LoginPageContainer>
    );
};

export default EseadeIC;

const LoginPageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100vh;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        div {
            height: 100%;
        }
    }
`;
