import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import styled from 'styled-components';

// Material UI
import EventAvailableRounded from '@mui/icons-material/EventAvailableRounded';
import EventBusyRounded from '@mui/icons-material/EventBusyRounded';
import CloseRounded from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const AchievementCardModal = (props) => {
    const { isOpen, handleCloseModal, achievement } = props;
    const { user } = useSelector((state) => state.auth);

    // STATE
    const [open, setOpen] = useState(false);

    // FUNCTIONS
    const handleTooltipOpen = () => {
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };

    // RETURN
    return (
        <Modal open={isOpen} onClose={handleCloseModal}>
            <AchievementCardModalContainer>
                <Header>
                    <TitleHeader>Certificado Académico</TitleHeader>
                    <IconButton onClick={handleCloseModal} size="large">
                        <CloseRounded fontSize="small" />
                    </IconButton>
                </Header>
                <Body>
                    <FakeImg src={achievement.imagen} />
                    <AchievementWrapper>
                        <Name>{achievement.nombre}</Name>
                        <Description>{achievement.descripcion}</Description>
                        <DatesWrapper>
                            <DateWrapper>
                                <EventAvailableRounded fontSize="small" />
                                <DateText>
                                    <b>Fecha expedición: </b>
                                    {achievement.fechaExpedicion
                                        ? achievement.fechaExpedicion
                                        : 'Sin expedición'}
                                </DateText>
                            </DateWrapper>
                            <DateWrapper>
                                <EventBusyRounded fontSize="small" />
                                <DateText>
                                    <b>Fecha caducidad: </b>
                                    {achievement.fechaCaducidad
                                        ? achievement.fechaCaducidad
                                        : 'Sin caducidad'}
                                </DateText>
                            </DateWrapper>
                        </DatesWrapper>
                    </AchievementWrapper>
                </Body>
                <ButtonWrapper>
                    <FakeButton
                        variant="contained"
                        color="primary"
                        href={`https://logros.aden.org/insigniaSola/${user.sis_id}/${achievement.id_partner_insignia}`}
                        target="_blank"
                    >
                        Ver certificado
                    </FakeButton>
                    <CopyToClipboard
                        text={`https://logros.aden.org/insigniaSola/${user.sis_id}/${achievement.id_partner_insignia}`}
                        onCopy={handleTooltipOpen}
                    >
                        <Tooltip
                            PopperProps={{
                                disablePortal: true
                            }}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copiado"
                            placement="top"
                        >
                            <FakeButton variant="contained" color="primary">
                                Copiar link de acceso público
                            </FakeButton>
                        </Tooltip>
                    </CopyToClipboard>
                    <FakeButtonClose
                        variant="outlined"
                        color="primary"
                        onClick={handleCloseModal}
                    >
                        Cerrar
                    </FakeButtonClose>
                </ButtonWrapper>
            </AchievementCardModalContainer>
        </Modal>
    );
};

export default AchievementCardModal;

const AchievementCardModalContainer = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: max-content;
    max-width: 90vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (max-width: 768px) {
        max-width: 100vw;
        width: 100%;
        height: 100%;
        border-radius: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    color: #b7b7b7;
    border-bottom: 1px solid #efefef;

    @media (max-width: 768px) {
        background-color: #b31d15;
        color: #ffffff;

        svg {
            color: #ffffff;
        }
    }
`;

const TitleHeader = styled.h1`
    font-size: 1.25rem;
    font-weight: 700;
`;

const Body = styled.div`
    display: flex;
    grid-gap: 2rem;
    padding: 2rem 2rem 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

const FakeImg = styled.img`
    width: 150px;
    height: auto;
`;

const AchievementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    padding-right: 2rem;
`;

const Name = styled.h1`
    font-size: 1.25rem;
    font-weight: 700;
    color: #222222;
`;

const Description = styled.p`
    font-size: 1rem;
    color: #616161;
`;

const DatesWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 2rem;
    color: #616161;

    @media (max-width: 768px) {
        flex-direction: column;
        row-gap: 1rem;
    }
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;

const DateText = styled.span`
    font-size: 1rem;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    grid-gap: 1rem;
    padding: 1rem 2rem 2rem;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: auto;
    }
`;

const FakeButton = styled(Button)`
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const FakeButtonClose = styled(Button)`
    @media (max-width: 768px) {
        width: 100%;
        display: none;
    }
`;
