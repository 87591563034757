import { useState } from "react";
import { useEffect } from "react";
import { getPayOrders } from "../../redux/api/faculty";

const usePayOrders = () => {
    //STATES
    const [payOrders, setPayOrders] = useState(null);
    const [loadingPayOrder, setLoadingPayOrder] = useState(true);

    //EFFECTS
    useEffect(() => {
        payOrders === null && handlePayOrders();
    }, []);

    //FUNCTIONS
    const handlePayOrders = async () => {
        setLoadingPayOrder(true);
        try {
            const res = await getPayOrders();
            setPayOrders(res);
            setLoadingPayOrder(false);
        } catch (error) {
            setPayOrders(false);
            setLoadingPayOrder(false);
        }
    };

    return { payOrders, loadingPayOrder };
};

export default usePayOrders;
