import React from 'react';
import styled from 'styled-components';
import { fakeArrayItems } from '../../utils/arrays/array.utils';

import { Skeleton } from '@mui/material';

const array = fakeArrayItems(4);

const AdenEventsSkt = () => {
    return (
        <Container>
            {array.map(() => (
                <Card>
                    <SkeletonFake
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height={142}
                    />
                </Card>
            ))}
        </Container>
    );
};

export default AdenEventsSkt;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 5px;
    gap: 0.5rem;
    background-color: #ffffff;
    width: 100%;

    @media (max-width: 768px) {
        padding: 1rem;
        width: 100%;
    }
`;

const Card = styled.div`
    border-radius: 20px;
`;

const SkeletonFake = styled(Skeleton)`
    border-radius: var(--border-radius_medium);
`;
