import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Text from "../../common/Text";
import RegisterFormStudent from "../../ui/organisms/auth/RegisterFormStudent";

const RegisterStudent = (props) => {
    const { id } = props;
    return (
        <RegisterContainer>
            {/** Logo */}
            <Link to='/'>
                <LogoAden loading='lazy' src='/assets/aden/logo-aden-simple-red.png' alt='logo' />
            </Link>
            <FormWrapper>
                <InfoWrapper>
                    <Text fontSize='2rem' fontWeight='700' color='primary' component='h2'>
                        Registrarme
                    </Text>
                    <Text fontSize='1.2rem' fontWeight='400' component='span'>
                        Ingrese sus datos para crear su cuenta
                    </Text>
                </InfoWrapper>
                <RegisterFormStudent id={id} />
            </FormWrapper>
            {/** Ir a login */}
            <ToLoginWrapper>
                <span>¿Ya tienes cuenta?</span>
                <Link to='/auth'>Ingresar</Link>
            </ToLoginWrapper>
        </RegisterContainer>
    );
};

export default RegisterStudent;

const RegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5% 0;
    background-color: #ffffff;
`;

const LogoAden = styled.img`
    max-width: 200px;

    @media (max-width: 1024px) {
        max-width: 200px;
    }
    @media (max-width: 768px) {
        max-width: 230px;
        margin-top: 2rem;
    }
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 40%;
    @media (max-width: 1400px) {
        width: 60%
    }
    @media (max-width: 768px) {
        width: 100%
    }
`;

const InfoWrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 768px) {
        margin-top: 5rem;
    }
`;

const ToLoginWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-size: 1rem;

    a {
        font-weight: 700;
    }

    @media (width < 768px) {
        span, a {
            font-size: 1.3rem;
        }
    }
`;
