import { useContext, useRef } from "react";
import styled from "styled-components";

// Components
import AchievementCard from "./achievementSlider/AchievementCard";

// Material UI
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { IconButton } from "@mui/material";

// React Elastic Carousel
import Carousel, { consts } from 'react-elastic-carousel';

// Context
import { ProfileContext } from "../../../../../contexts/profile/ProfileProvider";

const AchievementsSlider = (props) => {

    const { width } = props;

    const { userAchievements } = useContext(ProfileContext);

    const carouselRef = useRef(null);

    const handleNextArrow = () => {
        carouselRef.current.slideNext();
    };

    const handlePrevArrow = () => {
        carouselRef.current.slidePrev();
    };

    if (!userAchievements.achievements || userAchievements.achievements?.length === 0 || userAchievements.loading) {
        return (
            <Skeleton />
        )
    }

    return (
        <SliderCampusContainer>
            <OptionsWrapper>
                <ArrowsWrapper>
                    <ButtonsWrapper>
                        <FakeIconButton
                            variant="text"
                            size="small"
                            color="tertiary"
                            onClick={handlePrevArrow}
                        >
                            <ArrowBack />
                        </FakeIconButton>
                        <FakeIconButton
                            variant="text"
                            size="small"
                            color="tertiary"
                            onClick={handleNextArrow}
                        >
                            <ArrowForward />
                        </FakeIconButton>
                    </ButtonsWrapper>
                </ArrowsWrapper>
            </OptionsWrapper>
            <FakeCarousel
                length={userAchievements.achievements.length}
                ref={carouselRef}
                itemPadding={[8, 0]}
                itemPosition={consts.START}
                itemsToShow={!!width ? (width / 380) : 2}
                itemsToScroll={1}
            >
                {
                    userAchievements.achievements.map((item, index) => {
                        return (
                            <AchievementCard
                                value={item}
                                key={index}
                                index={index}
                            />
                        )
                    })
                }
            </FakeCarousel>
        </SliderCampusContainer>
    )
}

export default AchievementsSlider;

const SliderCampusContainer = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: 1260px) {
        row-gap: 0rem;
    }
`;

const OptionsWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const ArrowsWrapper = styled.div`
    display: flex;
    column-gap: 1.2rem;

    @media (max-width: 1260px) {
        align-items: center;
        column-gap: 0.5rem;
        margin-right: 1rem;
        margin-top: 1rem;
    }

    @media (max-width: 1336px) {
        display: none;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
`;

const FakeIconButton = styled(IconButton)`
    background-color: ${(p) => p.color};
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    width: 35px;
    height: 35px;
    border-radius: 50%;

    :hover {
        background-color: ${(p) => p.color};
        filter: brightness(1.2);
    }

    svg {
        width: 20px;
        height: 20px;
        color: #616161;
    }

    @media (max-width: 1260px) {
        width: 16px;
        height: 16px;
    }
`;


const FakeCarousel = styled(Carousel)`
    padding-bottom: 8px;

    .rec.rec-slider-container {
        margin: 0;
    }
    .rec.rec-arrow {
        display: none;
        visibility: hidden;
    }

    .rec.rec-pagination {
        display: none;
        visibility: hidden;
    }
`;

const Skeleton = styled.div`
    display: flex;
    background-color: #ededed;
    border-radius: 20px;
    height: 160px;
    width: 100%;
    box-sizing: border-box;
`;