import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getWorkshops, getWorkshopById } from '../api/workshops';
import { workshopsActions } from '../actions';

function* workshopsRequest() {
    try {
        const res = yield call(getWorkshops);
        yield put(workshopsActions.getWorkshopsSuccess(res.data.response_data));
    } catch (error) {
        yield put(workshopsActions.getWorkshopsFail(error));
    }
}

function* workshopByIdRequest(action) {
    try {
        const res = yield call(getWorkshopById, action.payload);
        yield put(workshopsActions.getWorkshopByIdSuccess(res.data));
    } catch (error) {
        yield put(workshopsActions.getWorkshopByIdFail(error));
    }
}

function* workshopsWatcher() {
    yield takeEvery(types.GET_WORKSHOPS_REQUEST, workshopsRequest);
    yield takeEvery(types.GET_WORKSHOPBYID_REQUEST, workshopByIdRequest);
}

export { workshopsWatcher };
