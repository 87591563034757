import styled from "styled-components";
import * as React from "react";

// Components
import { Text } from "../../../common/Texts";
import SelectRol from "../../atoms/SelectRol";

// Material UI
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

// Assets
import StudentCircle from '../../../../assets/media/aden/student-circle.png'
import ProfesorCircle from '../../../../assets/media/aden/profesor-circle.png'
import AlabCircle from '../../../../assets/media/aden/alab-circle.png'
import campusOrnament from '../../../../assets/media/campus-ornament-2.png';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectRolModal(props) {
    const {
        open,
        handleLogin,
        loadingGetUser,
        rolOpen,
        handleClose = false,
        active = false,
    } = props;

    const handleRoles = (rol) => {
        const cases = {
            alumno: {
                name: "Alumno",
                subtitle: "ACRÓPOLIS",
                icon: StudentCircle,
            },
            docente: {
                name: "Profesor",
                subtitle: "ACRÓPOLIS",
                icon: ProfesorCircle,
            },
            academico: {
                name: "Académico",
                subtitle: "ACRÓPOLIS",
                icon: StudentCircle, // ¿Desafectar el rol académico?
            },
            ondemand: {
                name: "Estudiante",
                subtitle: "ACRÓPOLIS-LAB",
                icon: AlabCircle,
            },
            visita: {
                name: "Visita",
                subtitle: "ACRÓPOLIS",
                icon: StudentCircle, // Cambiar por el de visita
            },
        };

        return cases[rol] || cases["visita"];
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                TransitionComponent={Transition}
            >
                <Container id="1">
                    <Image src={campusOrnament} />
                    <Image2 src={campusOrnament} />
                    {!!handleClose && (
                        <Close onClick={handleClose}>
                            <CloseIcon color="primary" />
                        </Close>
                    )}
                    {!loadingGetUser ? (
                        <CardRol>
                            <Text
                                color="#B31D15"
                                fontWeight="700"
                                fontSize="28px"
                                textAlign="center"
                            >
                                ¿Con qué rol deseas ingresar?
                            </Text>
                            <Text
                                color="#222"
                                fontWeight="600"
                                fontSize="16px"
                                textAlign
                            >
                                Selecciona una opción para poder acceder.
                            </Text>
                            <SelectWrapper>
                                {rolOpen.map((rol) => {
                                    const { name, icon, subtitle } =
                                        handleRoles(rol);
                                    if (active === rol) {
                                        return (
                                            <SelectRol
                                                disabled={true}
                                                value={rol || "visita"}
                                                icon={icon}
                                                name={name}
                                                subtitle={subtitle}
                                            />
                                        );
                                    }
                                    return (
                                        <SelectRol
                                            value={rol || "visita"}
                                            handleLogin={handleLogin}
                                            icon={icon}
                                            name={name}
                                            subtitle={subtitle}
                                        />
                                    );
                                })}
                            </SelectWrapper>
                        </CardRol>
                    ) : (
                        <CardRol progress>
                            <CircularProgress />
                        </CardRol>
                    )}
                </Container>
            </Dialog>
        </div>
    );
}

const Container = styled.div`
    max-width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    overflow: hidden;
`;

const Image = styled.img`
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    width: 80vw;
    height: 100vh;
    object-fit: center;
    object-position: center;
    mask-image: linear-gradient(#f9f9f9 80%);
    z-index: 0;
`;

const Image2 = styled.img`
    position: absolute;
    margin: auto;
    bottom: 0;
    left: 0;
    width: 80vw;
    height: 100vh;
    object-fit: center;
    object-position: center;
    mask-image: linear-gradient(#f9f9f9 80%);
    z-index: 0;
    rotate: 180deg;
`;

const Close = styled(IconButton)`
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 15px;
`;

const CardRol = styled.div`
    min-width: 516px;
    width: fit-content;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: ${({ progress }) => progress ? "center" : "space-between"};
    align-items: center;
    background-color: #fff;
    border-radius: 30px;
    gap: 1rem;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    padding: 2rem;
    z-index: 1;

    @media (max-width: 768px) {
        min-width: auto;
        width: calc(100% - 30px);
        margin: 0 15px;
    }
`;

const SelectWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    @media (max-width: 768px) {
        width: 100%;
        margin: 2rem 0;
        flex-direction: column;
    }
`;
