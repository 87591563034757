import React from 'react';
import styled from 'styled-components';
import { Img } from '../../common/Image';
import Logo from '../../../assets/media/aden/logo-aden-with-online-learning.png';
import Text from '../../common/Text';
import AuthComponent from '../../ui/organisms/auth/AuthComponent';

const AuthForm = () => {
    return (
        <AuthFormWrapper>
            <AuthFormContainer>
                <Img w="290px" src={Logo} />
                <h1>CAMPUS <b>ACRÓPOLIS</b></h1>
                <AuthComponent />
                <TextFake fontWeight="600" textColor="#222" fontSize="12px">
                    © {new Date().getFullYear()} ADEN. Todos los derechos reservados.
                </TextFake>
            </AuthFormContainer>
        </AuthFormWrapper>
    );
};

export default AuthForm;

const AuthFormWrapper = styled.div`
    padding: 4rem 16rem 4rem 0;
    text-align: center;
    width: 50vw;
    height: calc(100vh - 8rem);
    display: flex;
    justify-content: end;
    align-items: center;
    overflow-y: hidden;

    @media (width < 1540px) {
        padding: 2rem 8rem 2rem 0;
        height: calc(100vh - 4rem);
        overflow-x: hidden;
    }

    @media (max-width: 1068px) {
        padding: 3px;
        width: calc(100% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }
`;

const AuthFormContainer = styled.div`
    background-color: #fff;
    height: 100%;
    width: 30vw;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    h1 {
        font-size: 24px;
        font-weight: 400;
        color: #222;

        b {
            font-size: 24px;
        }
    }

    @media (width < 1330px) {
        min-width: 400px;
    }

    @media (width <= 1068px) {
        width: 90vw;
        height: calc(95vh - 2rem);
        justify-content: space-between;
        padding: 1rem 0;
    }
`

const TextFake = styled(Text)`

`;
