import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import CourseCardRounded from "../../components/common/CourseCardRounded";
import ErrorResponse from "../../components/common/ErrorResponse";
import SimpleLoading from "../../components/common/SimpleLoading";
import SplitButton from "../../components/common/SplitButton";
import { titleHTML } from "../../helpers/title.helper";
import useCoursesAA from "../../hooks/useCoursesAA";

const CoursesAA = () => {
    titleHTML("Asignaturas");

    // OPTIONS
    const tabs = [
        { label: "En curso", id: 0 },
        { label: "Finalizados", id: 1 },
    ];
    const options = ["Por defecto", "Más antiguo", "Más nuevo"];

    // REDUX
    const AARepositorioPartnerId = useSelector(
        (state) => state?.auth?.user?.repositorio_data?.repositorio_id
    );

    // HOOK
    const { courses } = useCoursesAA();

    // STATES
    const [coursesFake, setCoursesFake] = useState(courses);
    const [activeTab, setActiveTab] = useState(0);
    const [currentFilter, setCurrentFilter] = useState({
        index: 0,
        name: "Por defecto",
    });
    const [tab, setTab] = useState(0);

    // EFFECTS
    useEffect(() => {
        if (courses !== null) {
            setCoursesFake(courses);
        }
    }, [courses]);

    // COMENTADO HASTA VER BIEN LAS FECHAS DE END

    // useEffect(() => {
    //     if (courses !== null) {
    //         if (tab === 0) {
    //             setCoursesFake(
    //                 courses.filter((course) => {
    //                     const courseDate = new Date(course.date_end).getTime();
    //                     return courseDate > new Date().getTime();
    //                 })
    //             );
    //         }
    //         if (tab === 1) {
    //             const date = new Date();

    //             setCoursesFake(
    //                 courses.filter(
    //                     (course) =>
    //                         new Date(course.date_end).getTime() < date.getTime()
    //                 )
    //             );
    //         }
    //     }
    // }, [tab]);

    useEffect(() => {
        if (courses !== null) {
            currentFilter.index === 0 &&
                setCoursesFake(coursesFake.sort((a, b) => a.id - b.id));
            if (currentFilter.index === 1) {
                setCoursesFake(
                    coursesFake.sort((a, b) => {
                        const dateA = new Date(a.date_begin).getTime();
                        const dateB = new Date(b.date_begin).getTime();
                        return dateA - dateB;
                    })
                );
            }

            if (currentFilter.index === 2) {
                setCoursesFake(
                    coursesFake.sort((a, b) => {
                        const dateA = new Date(a.date_begin).getTime();
                        const dateB = new Date(b.date_begin).getTime();
                        return dateB - dateA;
                    })
                );
            }
        }
    }, [currentFilter.index]);

    // FUNCTIONS
    const handleOption = (args) => {
        setCurrentFilter(args);
    };

    const handleTab = (value) => {
        setTab(value);
        setActiveTab(value);
    };

    //RETURN
    if (coursesFake === false) {
        return (
            <CoursesAAWrapper>
                <ErrorResponse />
            </CoursesAAWrapper>
        );
    }

    if (coursesFake === null) {
        return (
            <CoursesAAWrapper>
                <SimpleLoading />
            </CoursesAAWrapper>
        );
    }

    if (AARepositorioPartnerId === null || AARepositorioPartnerId === false) {
        return (
            <CoursesAAWrapper>
                <ErrorResponse />
            </CoursesAAWrapper>
        );
    }

    return (
        <>
            <CoursesAAWrapper>
                <Header>
                    <WrapperTabs>
                        {tabs.map((tab) => (
                            <Title
                                key={tab.id}
                                onClick={() => handleTab(tab.id)}
                                active={tab.id === activeTab ? true : false}
                            >
                                {tab.label}
                            </Title>
                        ))}
                    </WrapperTabs>
                    <SplitButton
                        handleOption={handleOption}
                        options={options}
                    />
                </Header>

                <Grid>
                    {!!courses &&
                        coursesFake.map((course, index) => (
                            <CourseCardRounded
                                key={index}
                                values={course}
                                academic={"academic"}
                            />
                        ))}
                </Grid>
            </CoursesAAWrapper>
        </>
    );
};

export default CoursesAA;

const CoursesAAWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 1rem;
    border-radius: 2rem;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1.5rem;

    @media (max-width: 768px) {
        margin-top: 5rem;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    align-items: center;
`;

const Grid = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
    padding: 1rem;
    overflow-y: auto;

    @media (max-width: 922px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    ::-webkit-scrollbar {
        background-color: #f9f9f9;
        border-radius: 6px;
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f9f9f9;
        width: 6px;
        border-radius: 6px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: #eaeaea;
        width: 6px;
    }
`;

const WrapperTabs = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 1.5rem;
`;

const Title = styled.div`
    cursor: pointer;
    color: ${(p) => (p.active ? "#b31d15" : "#555555")};
    font-weight: 700;
    font-size: 1rem;
    background-color: #fff;
    border: ${(p) => (p.active ? "0.5px solid #f3f3f3" : "none")};
    box-shadow: ${(p) =>
        !!p.active
            ? `0px 3px 5px rgba(0, 0, 0, 0.15), 
        0px 5px 10px rgba(0, 0, 0, 0.06)`
            : "none"};
    border-radius: 5px;
    padding: 8px 14px;
    transition: all 0.3s ease-in-out;

    :hover {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15),
            0px 5px 10px rgba(0, 0, 0, 0.06);
    }
`;
