import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text } from "../../../../common/Texts";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import RankingCard from "../../../molecules/teacher/RankingCard";
import SimpleLoading from "../../../../common/SimpleLoading";

const RankingDashboardCourse = (props) => {
    const { title, type, ranking, advanceStudents } = props;

    const [order, setOrder] = useState("desc");
    const [values, setValues] = useState([]);
    const [flag, setFlag] = useState(false);

    // EFFECTS
    useEffect(() => {
        !!ranking && !!advanceStudents && arrayCombined();
    }, [ranking, advanceStudents]);

    const arrayCombined = () => {
        for (let index = 0; index < ranking.length; index++) {
            for (let j = 0; j < advanceStudents.length; j++) {
                if (ranking[index].id === advanceStudents[j].id) {
                    values.push({
                        ...ranking[index],
                        progressAdvance: advanceStudents[j].progress,
                    });
                }
            }
            if (index === ranking.length - 1) {
                setFlag(true);
            }
        }
    };

    function handleAscOrderChange() {
        setOrder("asc");
        sortAsc();
    }

    function handleDescOrderChange() {
        setOrder("desc");
        sortDesc();
    }

    function sortAsc() {
        setValues(values.sort((a, b) => a.progress - b.progress));
        setFlag(false);

        setTimeout(() => {
            setFlag(true);
        }, 10);
    }
    function sortDesc() {
        setValues(values.sort((a, b) => b.progress - a.progress));
        setFlag(false);
        setTimeout(() => {
            setFlag(true);
        }, 10);
    }

    if (!ranking || !advanceStudents || !flag) {
        return <SimpleLoading />;
    }

    return (
        <RankingWrapper>
            <TitleWrapper>
                <Text color="#555555" fontWeight="700" fontSize="1.2rem">
                    {title}
                </Text>
                {order === "asc" ? (
                    <ArrowDownwardRoundedIconFake
                        //onClick={handleAscOrderChange}
                        onClick={handleDescOrderChange}
                        color="primary"
                    />
                ) : (
                    <ArrowUpwardRoundedIconFake
                        //onClick={handleDescOrderChange}
                        onClick={handleAscOrderChange}
                        color="primary"
                    />
                )}
            </TitleWrapper>
            <Scroll>
                <DivFake>
                    {values.map((user, index) => (
                        <RankingCard
                            values={user}
                            type={type}
                            number={index}
                            key={index}
                        />
                    ))}
                </DivFake>
            </Scroll>
        </RankingWrapper>
    );
};

export default RankingDashboardCourse;

const RankingWrapper = styled.div`
    height: calc(100% - 3rem);
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 25px;
    padding: 1.5rem;
`;

const TitleWrapper = styled.div`
    display: inline-flex;
    width: 90%;
    justify-content: space-between;
    gap: 1rem;
`;

const ArrowDownwardRoundedIconFake = styled(ArrowDownwardRoundedIcon)`
    &:hover {
        cursor: pointer;
    }
`;

const ArrowUpwardRoundedIconFake = styled(ArrowUpwardRoundedIcon)`
    &:hover {
        cursor: pointer;
    }
`;

const Scroll = styled.div`
    max-height: 520px;
    width: 100%;
    overflow-y: auto;
    margin: 0.2rem;

    @media (max-width: 1000px) {
        max-height: 320px;
    }
`;

const DivFake = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
