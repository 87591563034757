import * as React from 'react';
import Chip from '@mui/material/Chip';
import styled from 'styled-components';
// import Stack from '@mui/material/Stack';

const DefaultChip = (props) => {
    const {
        title = 'Chip genérico',
        color = '#fff',
        padding = '.5rem',
        fontSize = '1rem',
        radius = '5px',
        textColor = '#000',
        fontWeight = false,
        textTransform = 'lowercase'
    } = props;

    return (
        <ChipFake
            textTransform={textTransform}
            fontWeight={fontWeight}
            radius={radius}
            fontSize={fontSize}
            bg={color}
            padding={padding}
            label={title}
            textColor={textColor}
        />
    );
};

export default DefaultChip;

const ChipFake = styled(Chip)`
    background-color: ${(p) => p.bg};
    height: 20px;
    border-radius: ${(p) => p.radius};
    text-transform: lowercase;
    text-transform: ${p => p.textTransform};
    width: min-content;
    span {
        font-size: ${(p) => p.fontSize};
        padding: ${(p) => p.padding};
        color: ${(p) => p.textColor};
        font-weight: ${(p) => p.fontWeight};
    }
`;
