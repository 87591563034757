import { Icon } from '@iconify/react/dist/iconify.js';
import styled from 'styled-components';
import { Text } from '../../../../../../components/common/Texts';

const SubjectDates = (props) => {

    const { date } = props;

    const COUNTRY_LOCALE = 'es-AR';
    const options = { timeZone: 'UTC' };

    const formattedBeginDate = new Date(date).toLocaleDateString(
        COUNTRY_LOCALE,
        options
    );
    return (
        <DateContainer>
            <DateWrapper>
                <Icon
                    icon="ph:calendar-check-thin"
                    width="24px"
                    height="24px"
                />
                <TextWrapper>
                    <Text
                        color="#222"
                        fontSize='14px'
                        fontWeight="400"
                    >
                        Inicio
                    </Text>
                    {date ? (
                        <DateText>
                            {formattedBeginDate}
                        </DateText>
                    ) : (
                        <DateText>-</DateText>
                    )}
                </TextWrapper>
            </DateWrapper>
        </DateContainer>
    )
}

export default SubjectDates;

const DateContainer = styled.div`
    display: flex;
    gap: 16px;
`

const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;

    svg {
        color: #b31d15;
        width: 20px;
        height: 20px;

        @media (max-width: 1024px) {
            width: 17px;
            height: 17px;
        }
    }

    @media (max-width: 1024px) {
        gap: 4px;
    }

    @media (max-width: 768px) {
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

const DateText = styled.span`
    color: #222;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;

    @media screen and (max-width: 1024px) {
        font-size: 12px;
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
`;