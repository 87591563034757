import axios from "axios";

export const postNotesCourse = async (body) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/notes`;

    try {
        const result = await axios.post(URL, body);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getNotesCourse = async (idResource, partnerId) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/notes/${idResource}?partner_id=${partnerId}`;

    try {
        const result = await axios.get(URL);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};
