import styled from 'styled-components';

export const Text = styled.p`
    font-size: ${(p) => p.fontSize};
    font-weight: ${(p) => p.fontWeight};
    color: ${(p) => p.color} !important;
    text-align: ${(p) => p.textAlign};
    letter-spacing: ${(p) => p.letterSpacing};
    text-overflow: ${(p) => p.ellipsis};
    overflow: ${(p) => p.ellipsis && 'hidden'};
    white-space: ${(p) => p.ellipsis && 'nowrap'};
    line-height: ${(p) => p.lineHeight};
    &::first-letter {
        text-transform: uppercase;
    }
`;

export const Span = styled.span`
    font-size: ${(p) => p.fontSize};
    font-weight: ${(p) => p.fontWeight};
    color: ${(p) => p.color};
    text-align: ${(p) => p.textAlign};
`;

export const Href = styled.a`
    font-size: ${(p) => p.fontSize};
    font-weight: ${(p) => p.fontWeight};
    color: ${(p) => p.color};
    text-align: ${(p) => p.textAlign};
    text-decoration: none;
    :hover {
        filter: brightness(0.8);
    }
`;
