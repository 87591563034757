import React from 'react';
import styled from 'styled-components';

import { ErrorRounded } from '@mui/icons-material';
import { Button } from '@mui/material';

const TryAgain = (props) => {
    const { padding, handleTryAgain } = props;

    return (
        <TryAgainContainer padding={padding}>
            <ErrorRounded fontSize="large" />
            <Text>Ocurrió un error</Text>
            <Button
                variant="contained"
                color="primary"
                onClick={handleTryAgain}
            >
                Reintentar
            </Button>
        </TryAgainContainer>
    );
};

export default TryAgain;

const TryAgainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    text-align: center;
    color: #bfbfbf;
    padding: ${(props) => props.padding} 0;
`;

const Text = styled.span`
    font-size: 1.5rem;
    font-weight: 700;
`;
