import React, { useEffect } from 'react';
import styled from "styled-components";

// Components
import DefaultComplete from '../../../../../components/common/DefaultComplete';
import DefaultSelect from "../../../../../components/common/DefaultSelect";
import DefaultField from "../../../../../components/common/DefaultField";

// Material UI
import { FormControl, FormLabel, FormHelperText, Radio, RadioGroup, FormControlLabel } from "@mui/material";

// Formik
import { Field } from "formik";

// Redux
import { utilsActions } from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const PersonalData = (props) => {

    const { values, setFieldValue } = props;

    const docTypes = useSelector((state) => state.utils.docTypes);
    const countries = useSelector((state) => state.utils.countries);
    const jobs = useSelector((state) => state.utils.jobsOptions);
    const professions = useSelector((state) => state.utils.professions);
    const areas = useSelector((state) => state.utils.areasOptions);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!docTypes) dispatch(utilsActions.getDocTypesRequest());
        if (!countries) dispatch(utilsActions.getCountriesRequest());
        if (!jobs) dispatch(utilsActions.getJobsOptionsRequest());
        if (!professions) dispatch(utilsActions.getProfessionsRequest());
        if (!areas) dispatch(utilsActions.getAreasOptionsRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, docTypes, countries]);

    return (
        <Container>
            <FormContainer>
                <DefaultField name="name" type="text" value={values.name} label="Nombre completo" required />
                <DefaultField name="birthday" type="date" label="Fecha de nacimiento" InputLabelProps={{ shrink: true }} />
                <FormControl component="fieldset">
                    <FormLabel style={{ color: "#000" }} component="legend">Género *</FormLabel>
                    <FormHelperText>Selecciona tu género.</FormHelperText>
                    <Field name="gender">
                        {({ field }) => (
                            <RadioGroup {...field}>
                                <FormControlLabel value="F" control={<Radio />} label="Femenino" />
                                <FormControlLabel value="M" control={<Radio />} label="Masculino" />
                                <FormControlLabel value="O" control={<Radio />} label="Otro" />
                            </RadioGroup>
                        )}
                    </Field>
                    
                </FormControl>
                <div />
                <DefaultSelect name="document_type" label="Tipo de documento" options={docTypes || []} />
                <DefaultField name="document_number" type="text" label="Número de documento" />
                <DefaultComplete
                    name="country"
                    label="País de residencia"
                    value={values.country}
                    setFieldValue={setFieldValue}
                    options={!!countries ? countries : []}
                />
                <DefaultField name="city" type="text" label="Ciudad de residencia" required />
                <DefaultField name="zip" type="number" label="Código postal" required />
                <DefaultField name="street" type="text" label="Dirección" />
                <DefaultComplete
                    name="profession"
                    label="Título de grado"
                    value={values.profession}
                    setFieldValue={setFieldValue}
                    options={!!professions ? professions : []}
                />
                <DefaultComplete
                    name="current_job"
                    label="Cargo actual"
                    value={values.current_job}
                    setFieldValue={setFieldValue}
                    options={!!jobs ? jobs : []}
                />
                <DefaultField name="company" type="text" label="Empresa" required />
            </FormContainer>
        </Container>
    );
};

export default PersonalData;

const Container = styled.div`
    width: 100%;
    max-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    @media (width < 768px) {
        height: 100%;
        max-height: 100%;
    }
`;

const FormContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8rem;
    row-gap: 3rem;

    @media (width < 768px) {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
`;
