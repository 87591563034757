import axios from 'axios';

export function getDracma() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/dracmacoins`
    );
}

export function getMovements() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/dracmacoins/movements`
    );
}

export function getRanking() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/dracmacoins/ranking?top=100`
    );
}

export function getRankingOndemand() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/dracmacoins/ranking?top=100&ondemand=true`
    );
}

export function getBenefits() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/dracmacoins/benefits`
    );
}

export async function getDracmaChallenge(body, crm_id, repo_id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/dracma_challenge/${repo_id}/${crm_id}`;
    try {
        const response = await axios.post(URL, body);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}

export async function saveDracmaChallenge(body) {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/response_dracma_challenge`;
    try {
        const response = await axios.post(URL, body);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}

export async function assignDracma(body) {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/dracmapoints`;
    try {
        const response = await axios.post(URL, body);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}