import React from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { Text } from '../../../common/Texts';
import { Button, Chip, Tooltip } from '@mui/material';

const SingleElectiveSelection = (props) => {
    const { elective, handleCheck, optionCheck, multiSelectedItems } = props;

    const disableItem = multiSelectedItems.some(
        (item) => item.id === elective?.id
    );

    const handleRedirect = () => {
        window.open('https://app.aden.org/eventos', '_blank');
    };

    const presencial = elective?.courseInPerson;
    

    return (
        <ItemContainer
            onClick={() =>
                disableItem
                    ? null
                    : handleCheck(
                          elective?.id,
                          elective?.publicationName,
                          elective?.courseInPerson
                      )
            }
        >
            <ContainerInfo>
                <ContainerLabel>
                    <IconInfo icon="ic:round-info" />
                    <label for={elective?.id}>
                        <span>{elective?.publicationName}</span>
                        <p>{elective?.description}</p>
                    </label>
                </ContainerLabel>

                {!disableItem && (
                    <CheckColumn>
                        <ChipMode
                            label={
                                presencial === true ? 'Presencial' : 'Virtual'
                            }
                            textColor={
                                presencial === true ? '#B31D15' : '#1E8065'
                            }
                            bgcolor={
                                presencial === true ? '#FFF5F5' : '#F7FDFB'
                            }
                        />
                        {!!presencial ? (
                            <Tooltip
                                title={
                                    <>
                                        <EventButton onClick={handleRedirect}>
                                            <Text
                                                fontSize="14px"
                                                fontWeight="600"
                                                textTransform="none"
                                            >
                                                Antes de confirmar tu inscripción a esta electiva presencial, te sugerimos que verifiques la disponibilidad de la clase mediante el siguiente link:
                                            </Text>
                                            <Text
                                                fontSize="14px"
                                                fontWeight="900"
                                                color={'#b31d15'}
                                                textTransform="none"
                                            >
                                                Ver apartado Eventos
                                            </Text>

                                        </EventButton>
                                    </>
                                }
                                arrow
                                placement="top"
                            >
                                <Checkbox
                                    optionCheck={optionCheck === elective?.id}
                                    type="checkbox"
                                    id={elective?.id}
                                    name={elective?.id}
                                    checked={optionCheck === elective?.id}
                                />
                            </Tooltip>
                        ) : (
                            <Checkbox
                                optionCheck={optionCheck === elective?.id}
                                type="checkbox"
                                id={elective?.id}
                                name={elective?.id}
                                checked={optionCheck === elective?.id}
                            />
                        )}
                    </CheckColumn>
                )}
                {disableItem && (
                    <Text color="#b31d15" fontSize="12px" fontWeight="600">
                        Seleccionada como complementaria
                    </Text>
                )}
            </ContainerInfo>
            {!!presencial && (
                <InfoContainer>
                    <Icon icon="octicon:info-24" width="24px" height="24px" />
                    <label>
                        Esta opción sólo está disponible para aquellos que{' '}
                        <b>asistieron a la clase presencial.</b>
                    </label>
                </InfoContainer>
            )}
        </ItemContainer>
    );
};

export default SingleElectiveSelection;

const ItemContainer = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    width: calc(100% - 2rem);
    justify-content: space-between;
    gap: 0.5rem;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    cursor: pointer;
    padding: 1rem;
    height: auto;
    :hover {
        background-color: #dcdcdc;
        transition: all 0.3s ease-in-out;
    }
`;

const ContainerInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 2rem);
    gap: 0.5rem;
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 2rem;

    svg {
        color: #c29f43;
    }
`;

const IconInfo = styled(Icon)`
    color: #b31d15;
    min-height: 27px;
    min-width: 27px;
    font-size: 24px;
`;

const ContainerLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: calc(100% - 30px);

    label {
        color: #222222;
        font-size: 1.2rem;
        padding: 0;
        text-align: start;
    }

    @media (width < 1068px) {
        label {
            p {
                margin-top: 1rem;
            }
        }
    }
`;

const Checkbox = styled.input`
    appearance: none;
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    border-radius: 50%;
    border: ${(p) =>
        p.optionCheck ? 'solid 2px #b31d15' : 'solid 2px #a8a8a8'};
    background-color: ${(p) => (p.optionCheck ? '#b31d15' : 'transparent')};
    transition: all 0.2s ease-in-out;
    position: relative;

    ${(p) =>
        p.optionCheck &&
        `:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: 0px;
        border: solid 2px #fff;
        
        @media (min-width: 1536px) and (max-width: 1536px) {
            top: 1px;
            left: 1px;
            
        }
    }`}
`;

const CheckColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
`;
const ChipMode = styled(Chip)`
    width: 100px;
    height: 30px;
    padding: 1rem 0;
    gap: 10px;
    border-radius: 100px;
    background-color: ${(p) => p.bgcolor};
    border: ${(p) => `1px solid ${p.textColor}`};

    .MuiChip-label {
        color: ${(p) => p.textColor};
        font-size: 0.8rem;
    }
    /* span { */
    /* } */
`;

const EventButton = styled(Button)`
    width: 100%;
    padding: 1rem;
    background-color: #fafafa;
    color: black;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;

    :hover {
        background-color: #fafafa;
    }
`;
