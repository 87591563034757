import { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import ReactCalendar from "react-calendar";
import SkeletonLoading from "../../../../components/common/SkeletonLoading";
import "../../styles/calendar.css";

const Calendar = (props) => {
  const { dates } = props;

  /**
   * Injecta una clase, creada previamente en css,
   * a las fechas que se incluyan en el array que
   * se pasa como prop (dates)
   */
  const displayEventDates = ({ date, view }) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");

    if (dates.includes(formattedDate)) {
      return "highlight-date";
    }
  };

  if (!dates) {
    return <SkeletonLoading height={420} width="720px" />;
  }

  return (
    <CustomCalendar
      currentDate={dates?.currentDate}
      showNeighboringMonth={false}
      prev2Label={null}
      next2Label={null}
      tileClassName={displayEventDates}
      view="month"
      locale="es-AR"
    />
  );
};

export default Calendar;

const CustomCalendar = styled(ReactCalendar)`
  width: 720px !important;
  border-radius: 20px !important;
  border: 1px solid #616161 !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25),
    0px 12px 24px -15px rgba(0, 0, 0, 0.1);

  &.react-calendar {
    height: 420px !important;

    .react-calendar__tile {
      width: 50px;
    }
  }
`;
