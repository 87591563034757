import React from 'react';

import { Field } from 'formik';
import { MenuItem, Select } from '@mui/material';

const DefaultSelect = (props) => {
    const { name, label, options } = props;

    // RETURN
    return (
        <Field
            as={Select}
            name={name}
            label={label}
            variant="standard"
            color="primary"
            size="small"
            fullWidth
            {...props}
        >
            {options.map((value) => (
                <MenuItem value={value.id} key={value.id}>
                    {value.name || value.nombre}
                </MenuItem>
            ))}
        </Field>
    );
};

export default DefaultSelect;
