import React from 'react';
import styled from 'styled-components';

import { Skeleton } from '@mui/material';

function Card() {
    return (
        <CardContainer>
            <Skeleton
                variant="circular"
                animation="wave"
                width={100}
                height={100}
            />
            <Skeleton animation="wave" width="50%" />
            <Skeleton animation="wave" width="60%" />
            <Skeleton animation="wave" width="70%" />
        </CardContainer>
    );
}

const AchievementsSkt = () => {
    return (
        <Container>
            <Skeleton animation="wave" width={230} height={70} />
            <GridContainer>
                <Card />
                <Card />
                <Card />
                <Card />
                <Card />
            </GridContainer>
        </Container>
    );
};

export default AchievementsSkt;

const Container = styled.div`
        background: #ffffff;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    border-radius: 20px;
    padding: 2rem;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 2rem;
    padding: 18px;

    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
    }
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 260px;
    row-gap: 1rem;
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    border-radius: 20px;
    padding: 2rem;
`;
