import React, { /* useEffect, useState */ } from 'react';
import styled from 'styled-components';
import DownloadIcon from '@mui/icons-material/Download';
// import SuccesDownloadFile from './SuccesDownloadFile';

const DownloadButton = (props) => {
    const { link, fileType, name, id } = props;

    // const [succesDownload, setSuccesDownload] = useState(false);
    // const [succesModal, setSuccesModal] = useState(false);

    // useEffect(() => {
    //     if (!!succesDownload) {
    //         setSuccesModal(true);
    //     }
    // }, [succesDownload, id]);

    // const handlecloseSuccesModal = () => {
    //     setSuccesModal(false);
    // };

    // function getDirectDownloadLink(sharedLink) {
    //     const fileId = sharedLink.match(/\/d\/(.*?)\//)[1];
    //     const directDownloadLink = `https://drive.google.com/uc?export=download&id=${fileId}`;
    //     return directDownloadLink;
    // }

    // const handleDownload = async (fileType, fileurl) => {
    //     // let fileURL = await getDirectDownloadLink(fileurl);
    //     let fileURL = fileurl;
    //     let fileName = `${name}.${fileType}`;

    //     console.log('fileURL', fileURL);
    //     console.log('fileName', fileName);
    //     const xhr = new XMLHttpRequest();
    //     console.log('xhr', xhr);
    //     xhr.open('GET', fileURL, true);
    //     xhr.responseType = 'blob';
    // xhr.onprogress = function (e) {
    //     if (e.lengthComputable) {
    //         const porcentaje = (e.loaded / e.total) * 100;
    //         console.log(`Descarga en progreso: ${porcentaje.toFixed(2)}%`);
    //     }
    // };
    //     xhr.onload = function () {
    //         if (xhr.status === 200) {
    //             const link = document.createElement('a');
    //             console.log('link', link);
    //             link.href = window.URL.createObjectURL(xhr.response);
    //             link.download = fileName;
    //             link.click();
    //             setSuccesDownload(true);
    //             console.log('Descarga finalizada');
    //         }
    //     };
    //     xhr.send();
    // };

    return (
        <>
            <Container
                key={id}
                // onClick={() => {handleDownload(fileType, link);}}
                target="_blank"
                href={link}
                download
            >
                <DownloadIcon />
                Descargar
            </Container>

            {/* {succesModal && (
                <SuccesDownloadFile
                    openModal={succesModal}
                    handleClose={handlecloseSuccesModal}
                    name={name}
                    id={id}
                />
            )} */}
        </>
    );
};

export default DownloadButton;

const Container = styled.a`
    background-color: #b31d15;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 130px;
    border-radius: 100px;
    padding: 0.8rem;
    gap: 8px;
    color: white;
    svg {
        font-size: 1.5rem;
    }
`;
