import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCoursesAAServices } from "../redux/api/courses";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../utils/snackStyles";

const useCoursesAA = () => {
    const [courses, setCourses] = useState(null);
    const [initialCourses, setInitialCourses] = useState(null);
    const AARepositorioPartnerId = useSelector(
        (state) => state?.auth?.user?.repositorio_data?.repositorio_id
    );

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!courses) getCourses(AARepositorioPartnerId);
    }, [courses]);

    const getCourses = async (id) => {
        const request = await getCoursesAAServices(id);
        if (request.error) {
            openSnackbar("¡No se pudieron cargar los cursos!");
            setCourses(false);
            return;
        }
        setCourses(request);
        setInitialCourses(request);
    };

    const handleCourses = (newCourses) => {
        setCourses(newCourses);
    };

    return { initialCourses, courses, handleCourses };
};

export default useCoursesAA;
