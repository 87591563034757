import { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { Skeleton } from '@mui/material';
import AnnouncementsSection from './AnnouncementsSection';

const Announcements = (props) => {
    const { initialValues, general = false, setOpenEditModal, rol } = props;
    const [newslettersData, setNewslettersData] = useState(initialValues);

    const handleChange = (e) => {
        e.preventDefault();

        if (!e.target.value !== '' || !general) {
            setNewslettersData(
                initialValues.filter((item) =>
                    item.title
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            return;
        }

        if (e.target.value.length === 0) {
            setNewslettersData(initialValues);
            return;
        }

        const filteredData = initialValues.map((item) => ({
            ...item,
            newsletters: item.newsletters.filter((newsletter) =>
                newsletter.title
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            ),
        }));
        setNewslettersData(filteredData);
    };

    if (!rol) {
        <Wrapper>
            <Skeleton variant="rectangular" width={300} height={200} />
        </Wrapper>;
    }
    return (
        <Wrapper>
            <SearchWrapper>
                <SearchInput
                    onChange={handleChange}
                    placeholder="Buscar anuncio..."
                />
                <SearchIcon
                    icon="ic:twotone-search"
                    width="1.2em"
                    height="1.2em"
                />
            </SearchWrapper>
            <Container>
                {newslettersData.length !== 0 ? (
                    <>
                        {newslettersData.map((item) => {
                            if (general) {
                                return (
                                    <>
                                        {item.newsletters.length !== 0 ? (
                                            <>
                                                {item.newsletters.map(
                                                    (newsletter) => {
                                                        return (
                                                            <AnnouncementsSection
                                                                subjectName={
                                                                    item?.asignatura_name
                                                                }
                                                                general={true}
                                                                card={
                                                                    newsletter
                                                                }
                                                                setOpenEditModal={
                                                                    setOpenEditModal
                                                                }
                                                                rol={rol}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <h1>
                                                    No hay anuncios con este
                                                    nombre
                                                </h1>
                                            </>
                                        )}
                                    </>
                                );
                            } else {
                                return (
                                    <AnnouncementsSection
                                        card={item}
                                        setOpenEditModal={setOpenEditModal}
                                        rol={rol}
                                    />
                                );
                            }
                        })}
                    </>
                ) : (
                    <>
                        <h1>No hay anuncios con este nombre</h1>
                    </>
                )}
            </Container>
        </Wrapper>
    );
};

export default Announcements;

const Wrapper = styled.div`
    width: calc(100% - 4rem);
    height: fit-content;
    min-height: 856px;
    background-color: #fff;
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
`;

const SearchWrapper = styled.div`
    position: relative;
    width: 250px;
    height: 48px;
    background-color: #f9f9f9;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 1rem;
`;

const SearchInput = styled.input`
    width: 100%;
    height: 24px;
    color: black;
    background-color: transparent;
    border: 0px;
    padding: 5px;
    border-radius: 5px;
    width: 200px;
    outline: none;

    &::placeholder {
        color: #a8a8a8;
    }
`;

const SearchIcon = styled(Icon)`
    color: #a8a8a8;
    position: absolute;
    left: 12px;
`;

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
