import React from 'react';
import styled from 'styled-components';

// Components
import LazyImg from '../../../components/common/LazyImg';
import parse from 'html-react-parser';
import CommonButton from '../../../components/common/CommonButton';

const CardNote = ({ value }) => {

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return html.replace(regex, 'src="');
    };

    return (
        <BusinessCardContainer /* href={value?.noteURL} */ target="blank" rel="noreferred">
            <ImageWrapper>
                <LazyImg
                    src={value?.noteImage ?? 'https://www.aden.org/files/sites/9/2018/09/Workshop_Miami_magazine.jpg'}
                    width="100%"
                    height="136px"
                    borderRadius="20px 20px 0 0"
                    alt="objeto de aprendizaje"
                />
            </ImageWrapper>
            {/* <Chip>
                One Year MBA
            </Chip> */}
            <Body>
                {/* <label>24/08/2023 18:30</label> */}
                <NombrePublicacion>
                    {value?.noteTitle ?? "No posee un título"}
                </NombrePublicacion>
                <DescriptionWrapper>
                    <Description>
                        {!!value?.noteDescription
                            ? parse(sanitizerHtml(value?.noteDescription))
                            : 'No posee una descripción'}
                    </Description>
                </DescriptionWrapper>
                <ButtonWrapper>
                    <CommonButton
                        variant="filled"
                        label="Leer más"
                        onClick={() => window.open(value?.noteURL)}
                        disabled={!value?.noteURL || value?.noteURL === '' || !value?.noteURL.includes('http')}
                    />
                </ButtonWrapper>
            </Body>
        </BusinessCardContainer>
    );
};

export default CardNote;

const BusinessCardContainer = styled.a`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16), 0px 3px 6px 0px rgba(0, 0, 0, 0.23);
    width: 340px;
    height: 380px;
    border-radius: 20px;
    overflow: hidden;

    ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 8px;
        background-color: #DCDCDC;
    }

    :hover {
        ::after {
            background-color: #b31d15;
        }
    }
`;

const ImageWrapper = styled.div`
    position: relative;
`;

/* const Chip = styled.div`
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: .5rem 1rem;
    box-shadow: 0px 8px 24px -4px #18274B14, 0px 6px 12px -6px #18274B1F;
    border: 1px solid #B31D15;
    border-radius: 100px;
    background-color: #ffffff;
    color: var(--primary-one, #B31D15);
    font-size: 600;
` */

const Body = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    height: 80%;

    label {
        font-size: 10px;
        color: #535353;
    }
`;

const NombrePublicacion = styled.h3`
    font-size: 1rem;
    font-weight: 700;
    text-align: start;
    color: #222222;
    height: fit-content;
    margin-left: 0 !important;

    @media screen and (max-width: 996px) {
        font-size: 1.2rem;
    }
`;

const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
    overflow-y: scroll;
`;

const Description = styled.p`
    display: inline-block;
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
    margin-bottom: 0.5rem;
    color: #535353;

    /* @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    } */
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: start;
    margin-bottom: 4rem;
`;
