import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { toTitleCase } from "../../../../helpers/titleCase.helper";
import SimpleLoading from "../../../common/SimpleLoading";

const FilterTags = (props) => {
  const { handleTags, courses } = props;

  const { tags } = useSelector((state) => state.enrollment);

  const [currentTags, setCurrentTags] = useState([]);

  useEffect(() => {
    currentTags.length > 0 && filteredCourses();
    currentTags.length === 0 && handleTags(false);
  }, [currentTags]);

  const selectedTags = (item) => {
    if (!!item) {
      const isExist = currentTags.find((itemTag) => itemTag === item);
      if (!!isExist)
        setCurrentTags(currentTags.filter((itemTag) => itemTag !== item));
      else setCurrentTags([...currentTags, item]);
    } else {
      setCurrentTags([]);
    }
  };

  const isActive = (tag) => {
    return !!currentTags.find((itemTag) => itemTag === tag);
  };

  const leftScroll = () => {
    const scroll = document.getElementById("scroll-tags");
    scroll.scrollLeft = scroll.scrollLeft + -200;
  };

  const rightScroll = () => {
    const scroll = document.getElementById("scroll-tags");
    scroll.scrollLeft = scroll.scrollLeft + 200;
  };

  const filteredCourses = () => {
    let newCourses = [];
    // Ciclo los cursos
    courses.forEach((course) => {
      let state = [];
      currentTags.forEach((tag) => {
        let newArray = course.detail
          ? course.detail.tags.map((item) => item.id)
          : course.tags.map((item) => item.id);
        let result = newArray.includes(tag);
        !!result ? state.push("si") : state.push("no");
      });
      let isAvaliable = state.find((item) => item === "no");
      !isAvaliable && newCourses.push(course);
    });
    handleTags(newCourses);
  };

  if (tags === null) {
    return (
      <FilterTagsWrapper>
        <SimpleLoading />
      </FilterTagsWrapper>
    );
  }
  return (
    <FilterTagsWrapper>
      <Arrow>
        <IconButton color="primary" onClick={leftScroll} size="large">
          <ArrowBackIcon />
        </IconButton>
      </Arrow>
      <Tags id="scroll-tags">
        <CardItem
          active={currentTags.length === 0 ? true : false}
          onClick={() => selectedTags(null)}
        >
          Todos
        </CardItem>
        {tags &&
          tags.map((tag) => (
            <CardItem
              title={tag.name}
              key={`index_${tag.id}`}
              onClick={() => selectedTags(tag.id)}
              active={isActive(tag.id)}
            >
              <ItemName>{tag.name}</ItemName>
            </CardItem>
          ))}
      </Tags>
      <Arrow>
        <IconButton color="primary" onClick={rightScroll} size="large">
          <ArrowForwardIcon />
        </IconButton>
      </Arrow>
    </FilterTagsWrapper>
  );
};

export default FilterTags;

const FilterTagsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  /* background-color: #eeeeee; */
`;

const Tags = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem;
  max-width: 100%;
  overflow-x: hidden;
  align-items: center;
  scroll-behavior: smooth;
`;

const Arrow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardItem = styled.div`
  /* position: relative; */
  padding: 0 1rem;
  min-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #777777;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  ${(p) => p.active && "color: #b31d15"};
  ${(p) =>
    p.active &&
    `box-shadow: 2px 5px 9px 2px rgba(203,203,203,0.75);
                        -webkit-box-shadow: 2px 5px 9px 2px rgba(203,203,203,0.75);
                        -moz-box-shadow: 2px 5px 9px 2px rgba(203,203,203,0.75);`};
  :hover {
    filter: brightness(0.8);
    box-shadow: 2px 5px 17px -2px rgba(216, 216, 216, 0.75);
    -webkit-box-shadow: 2px 5px 17px -2px rgba(216, 216, 216, 0.75);
    -moz-box-shadow: 2px 5px 17px -2px rgba(216, 216, 216, 0.75);
  }
`;

const ItemName = styled.div`
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
`;
