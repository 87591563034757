import React from 'react';
import styled from 'styled-components';

const PublicityWithoutStudent = () => {
    const video =
        'https://player.vimeo.com/external/541694141.sd.mp4?s=7d1425b1889c2ca8ee91988a63c78dc9fcea53cd&profile_id=164';
    return (
        <Container>
            <CustomVideo autoPlay muted loop>
                <source src={video} type="video/mp4" />
            </CustomVideo>
            <Overlay />
            <TextContainer>
                <DivText>
                    <H1>
                        N° 1 en formación online de negocios de América Latina
                    </H1>
                    <H3>
                        Nos gusta pensar que somos los soñadores de América
                        Latina. Porque nos inspira transformar realidades,
                        porque nos potenciamos entre todos para dar lo mejor
                        como individuo, como equipo y como comunidad.
                    </H3>
                    <H2>Somos ADEN. Juntos, líderes.</H2>
                </DivText>
            </TextContainer>
        </Container>
    );
};

export default PublicityWithoutStudent;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 1rem;
    text-align: left;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    color: #ffffff;

    @media (max-width: 768px) {
        visibility: hidden !important;
        display: none !important;
    }
`;

const CustomVideo = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

const Overlay = styled.div`
    background: rgb(179, 29, 21);
    background: linear-gradient(
        45deg,
        rgba(179, 29, 21, 0.6) 0%,
        rgba(179, 29, 21, 0.3) 50%,
        rgba(179, 29, 21, 0.6) 100%
    );
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
`;

const TextContainer = styled.div`
    padding: 0 10%;
    z-index: 99999;
    text-align: left;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 1024) {
        padding: 0 5%;
    }
`;

const DivText = styled.div`
    z-index: 99;
    color: #ffffff;
    text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const H2 = styled.h2`
    font-size: 2rem;
    @media (max-width: 1024px) {
        font-size: 1.5rem;
    }
`;

const H3 = styled.h3`
    font-size: 1.5rem;
    line-height: 140%;
    font-weight: 400;
    @media (max-width: 1024px) {
        font-size: 1rem;
    }
`;

const H1 = styled.h1`
    font-size: 3.5rem;
    font-weight: 900;
    @media (max-width: 1024px) {
        font-size: 2.5rem;
    }
`;
