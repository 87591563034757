import { useContext } from "react";
import styled from "styled-components";
import { ProfileContext } from "../../../../contexts/profile/ProfileProvider";
import { CircularProgress, Switch } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const PublicProfile = () => {

    const { user, userLoading, handleUpdateProfile } = useContext(ProfileContext);

    const [loading, setLoading] = useState(false);
    // Si no utilizo una flag, cuando haga update de los datos del usuario desde otro componente, también se vería afectado este loading.
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        flag && setLoading(userLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoading]);

    const handleChange = async () => {
        setLoading(true);
        setFlag(true);
        handleUpdateProfile({ profile_is_public: !user.profileIsPublic });

        setTimeout(() => {
            setFlag(false);
        }, 10000);
    };

    return (
        <PublicProfileContainer>
            <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={handleChange}
                checked={user.profileIsPublic}
                disabled={loading}
            />
            <label>Perfil público</label>
            {
                loading && <CircularProgress color="primary" size={16} />
            }
        </PublicProfileContainer>
    )
}

export default PublicProfile;

const PublicProfileContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;

    label {
        font-size: 16px;
        color: #000;
        font-weight: 500;
        margin-right: 1rem;
    }
`