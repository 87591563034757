import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

// Components
import Navbar from "../../components/ui/organisms/certificate/Navbar";
import Footer from "./components/Footer";
import FormRefered from "./components/FormRefered";
import { Text } from "../../components/common/Texts";

// Services
import { getPublicInfoForEmail } from "./services/auth";

// Redux
import { getCountries } from "../../redux/api/utils";

// Assets
import LeftImage from "./assets/left-image.png"
import RightImage from "./assets/right-image.png"
import AdenTeAcompaña from "../../assets/media/aden/aden-te-acompaña.png"
import { useSelector } from "react-redux";
import SimpleLoading from "../../components/common/SimpleLoading";
import ModalForm from "./components/ModalForm";
import ModalError from "./components/ModalError";
import ModalConfirm from "./components/ModalConfirm";
import InfoSection from "./components/InfoSection";

const Referred = () => {

    // User desde params
    const { id } = useParams();

    // User desde redux
    const user = useSelector((state) => state?.auth?.user);
    const contacto_email_alumni = user ? user.contacto_email_alumni : null;

    const [student, setStudent] = useState(null);
    const [countries, setCountries] = useState(null);
    const [modalForm, setModalForm] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);

    useEffect(() => {
        if (!student && (id !== undefined || contacto_email_alumni !== null)) {
            getData(id ?? contacto_email_alumni);
            setModalForm(false);
        } else {
            setModalForm(true);
        }
        !countries && getCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [student, countries]);

    const getData = async (email, fromForm = false) => {
        setModalLoading(true);
        setModalForm(false);
        const request = await getPublicInfoForEmail(email);
        if (request.error) {
            setStudent(false);
            setModalLoading(false);
            setModalError(true)
        } else {
            setStudent(request);
            setModalLoading(false);
            setModalForm(false);
            if (fromForm) {
                setModalConfirm(true);
            }
        }
    };

    const getCountry = async () => {
        try {
            const request = await getCountries();
            setCountries(request.data.response_data);
        } catch (error) { }
    };

    return (
        <Page>
            {
                modalForm &&
                <ModalContainer>
                    <Modal>
                        <ModalForm
                            getData={getData}
                        />
                    </Modal>
                </ModalContainer>
            }
            {
                modalError &&
                <ModalContainer>
                    <Modal>
                        <ModalError
                            setModalError={setModalError}
                            setModalForm={setModalForm}
                        />
                    </Modal>
                </ModalContainer>
            }
            {
                modalLoading &&
                <ModalContainer>
                    <Modal loading={true}>
                        <SimpleLoading />
                    </Modal>
                </ModalContainer>
            }
            {
                modalConfirm &&
                <ModalContainer>
                    <Modal>
                        <ModalConfirm
                            student={student}
                            setModalConfirm={setModalConfirm}
                            setModalForm={setModalForm}
                        />
                    </Modal>
                </ModalContainer>
            }
            <Navbar />
            <Container>
                <NavInfo>
                    <Icon icon="fluent:home-16-filled" width="20" height="20" color="#b31d15" />
                    <Icon icon="material-symbols:chevron-right-rounded" width="30" height="30" color="#535353" />
                    <Text fontSize="16px" fontWeight="500" color="#535353" ellipsis={true}>Referidos</Text>
                </NavInfo>
                <Title>
                    <Line />
                    <h1 fontSize="32px" fontWeight="900" color="#b31d15">Comparte, expande y crece con el "Programa de Referidos"</h1>
                    <Line />
                </Title>
                <SubTitle>
                    <b>¡Sigamos impulsando el talento y la prosperidad de la comunidad latinoamericana!</b>
                    <br />
                    <i>Queremos que más ejecutivos vivan la experiencia ADEN.</i>
                </SubTitle>
                <h3>Invita a un colega, amigo o familiar y obtén increíbles beneficios.</h3>
                <InfoCard>
                    <p><b>¿Cuáles son los beneficios que tú recibirás?</b></p>
                    <span>
                        <label>
                            <Icon icon="iconamoon:gift" width="24" height="24" color="#fff" />
                            200 USD
                        </label>
                        <Icon icon="mi:arrow-right" width="1.5rem" height="1.5rem" color="#fff" />
                        <p>Si la persona que refieres se matricula en un MBA o en un GLOBAL MBA.</p>
                    </span>
                    <span>
                        <label>
                            <Icon icon="iconamoon:gift" width="24" height="24" color="#fff" />
                            100 USD
                        </label>
                        <Icon icon="mi:arrow-right" width="1.5rem" height="1.5rem" color="#fff" />
                        <p>Si la persona que refieres se matricula en una MAESTRIA ESPECIALIZADA.</p>
                    </span>
                    <span>
                        <label>
                            <Icon icon="iconamoon:gift" width="24" height="24" color="#fff" />
                            75 USD
                        </label>
                        <Icon icon="mi:arrow-right" width="1.5rem" height="1.5rem" color="#fff" />
                        <p>Si la persona que refieres se matricula en un MAJOR o PROGRAMA ESPECIALIZADO.</p>
                    </span>
                    <hr />
                    <p><b>¿Y qué beneficio obtiene el referido?</b></p>
                    <span>
                        <Icon icon="iconamoon:gift" width="24" height="24" color="#fff" />
                        <p>15% de bonificación en TODA NUESTRA PROPUESTA ACADÉMICA.</p>
                    </span>
                </InfoCard>
                <InfoSection type="landing" />
                <FormCard>
                    <img src={LeftImage} alt="left" style={{ position: "absolute", top: "50%", left: "0%", transform: "translate(0%, -50%)", width: "40vw", height: "auto", maxHeight: "670px", objectFit: "cover" }} />
                    <img src={RightImage} alt="right" style={{ position: "absolute", top: "50%", right: "0%", transform: "translate(0%, -50%)", width: "40vw", height: "auto", maxHeight: "670px", objectFit: "cover" }} />
                    <FormRefered student={student} countries={countries} />
                    <img src={AdenTeAcompaña} alt="right" style={{ position: "absolute", top: "93%", left: "50%", transform: "translate(-50%, -50%)", width: "auto", height: "90px", objectFit: "cover" }} />
                </FormCard>
            </Container>
            <Footer />
        </Page>
    );
};

export default Referred;

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000040;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;        
`;

const Modal = styled.div`
    width: 500px;
    height: 320px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.loading ? "center" : "space-between"};
    align-items: center;
    gap: 2rem;

    h2 {
        color: #b31d15;
        text-align: center;
        font-size: 24px;
    }

    p {
        color: #616161;
        font-size: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        font-size: 16px;
    }

    @media (width < 768px) {
        width: 74vw;
    }
`


const Page = styled.div`
    width: 100%;
    height: auto;
    background-color: #f9f9f9;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    h3 {
        font-weight: 400;
        font-size: 24px;

        @media (width < 768px) {
            font-size: 14px;
            max-width: 70vw;
            text-align: center;
        }
    }
`;

const NavInfo = styled.div`
    width: calc(100% - 4rem);
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 3rem 0 1rem 3rem;
`

const Title = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    h1 {
        width: fit-content;
        font-size: 32px;
        font-weight: 900;
        color: #b31d15;
        white-space: nowrap;

        @media (width < 768px) {
            width: 80vw;
            font-size: 20px;
            white-space: normal;
            text-align: center;
        }
    }
`

const SubTitle = styled.h2`
    text-align: center;
    font-size: 24px;
    color: #000;
`

const Line = styled.div`
    width: 100%;
    height: 3px;
    background-color: #b31d15;
    border-radius: 5px;
    
    @media (width < 768px) {
        width: 10%;
    }
`

const InfoCard = styled.div`
    width: 50%;
    height: 540px;
    border-radius: 20px;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    background: rgb(229,26,26);
    background: linear-gradient(180deg, rgba(229,26,26,1) 0%, rgba(179,29,21,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    padding: 0 3rem;
    z-index: 99;

    @media (width < 768px) {
        width: calc(100% - 8rem);
    }

    p {
        font-size: 20px;
        font-weight: 500;
        color: #fff;

        @media (width < 768px) {
            font-size: 13px;
        }
    }

    span {
        display: flex;
        gap: 1rem;

        @media (width < 768px) {
            svg {
                min-height: 2rem;
                min-width: 2rem;
            }
        }

        label {
            height: fit-content;
            min-width: 120px;
            max-width: 120px;
            display: flex;
            gap: 1rem;
            font-size: 20px;
            font-weight: 500;
            color: #fff;

            @media (width < 768px) {
                font-size: 13px;
                min-width: 90px;
                max-width: 90px;
            }
        }
    }

    hr {
        width: 100%;
        height: .5px;
        background-color: #fff;
    }
`

const FormCard = styled.div`
    position: relative;
    width: 100%;
    height: 1100px;
    margin-top: -7rem;
    
    @media (max-width: 768px) {
        height: 1000px;

        img {
            display: none;
        }
    }
`;