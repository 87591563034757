import styled from "styled-components";
import { useState } from "react";

// Components
import { Icon } from "@iconify/react/dist/iconify.js";

const CopyButton = ({ text }) => {

    const [statusCopyText, setStatusCopyText] = useState(null);
    const [toastCopyText, setToastCopyText] = useState(false);

    const copyText = () => {
        // Se limpia el summary (HTML) para que quede un texto limpio al copiar
        const textToCopy = text.replace(/<\/?[^>]+(>|$)/g, '').replace(/\n\s*\n/g, '\n\n');
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setStatusCopyText(true);
                setToastCopyText(true);
            })
            .catch(() => {
                setStatusCopyText(false);
                setToastCopyText(true);
            });
        setTimeout(() => {
            setStatusCopyText(null);
            setToastCopyText(false);
        }, [3000]);
    }

    return (
        <CopyButtonContainer onClick={copyText}>
            {
                toastCopyText && <ToastCopy>
                    {
                        statusCopyText
                            ? "Copiado correctamente"
                            : "Error al copiar"
                    }
                </ToastCopy>
            }
            {
                statusCopyText === null ?
                    <Icon icon="lets-icons:copy-light" width="24px" height="24px" />
                    : statusCopyText === true ?
                        <Icon icon="ph:check-light" width="24px" height="24px" />
                        : <Icon icon="akar-icons:close" width="24px" height="24px" />
            }
        </CopyButtonContainer>
    );
}

export default CopyButton;

const CopyButtonContainer = styled.button`
    position: relative;
    cursor: pointer;
`

const ToastCopy = styled.div`
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: -1.8rem;
    left: 100%; /* Centra el triángulo horizontalmente */
    transform: translateX(-40%); /* Ajuste para centrar */
    padding: 8px;
    background-color: #616161;
    border-radius: 4px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 10px;
    color: #fff;
    white-space: nowrap;

    ::after {
        content: '';
        position: absolute;
        bottom: -10px; /* Mueve el triángulo hacia abajo del cuadrado */
        left: 30%; /* Centra el triángulo horizontalmente */
        transform: translateX(-50%); /* Ajuste para centrar */
        border-width: 6px;
        border-style: solid;
        border-color: #616161 transparent transparent transparent; /* Color superior, luego lados transparentes */
    }
`