import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Text from "../../common/Text";
import OndemandForm from "./OndemandForm";

const Register = (props) => {
  const { ondemand } = props;

  return (
    <Container>
      {/** Logo */}
      <Link to="/">
        <LogoAden
          loading="lazy"
          src="/assets/aden/logo-aden-simple-red.png"
          alt="logo"
        />
      </Link>
      <FormWrapper>
        <InfoWrapper>
          <Image src={ondemand.logo_url} w="200px" alt="logo" />
          <br />
          <Text
            fontSize="1.25rem"
            fontWeight="700"
            color="primary"
            component="h2"
          >
            {ondemand.detail}
          </Text>
          {/* <Text fontSize="0.9rem" fontWeight="300" component="span">
						Descripcion
					</Text> */}
        </InfoWrapper>
        <OndemandForm />
      </FormWrapper>
      {/** Ir a login */}
      <ToLoginWrapper>
        <span>¿Ya tienes cuenta?</span>
        <Link to="/auth">Ingresar</Link>
      </ToLoginWrapper>
    </Container>
  );
};

export default Register;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5% 0;
  background-color: #ffffff;
`;

const LogoAden = styled.img`
  max-width: 200px;

  @media (max-width: 1024px) {
    max-width: 180px;
  }
  @media (max-width: 768px) {
    max-width: 150px;
    margin-top: 2rem;
  }
`;

const Image = styled.img`
  max-width: ${(p) => p.w};

  @media (max-width: 1024px) {
    max-width: 300px;
  }
  @media (max-width: 768px) {
    max-width: 250px;
    margin-top: 2rem;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
`;

const InfoWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin-top: 5rem;
  }
`;

const ToLoginWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 1rem;

  a {
    font-weight: 700;
  }
`;
