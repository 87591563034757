import styled from '@emotion/styled';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = (props) => {

  const { inputArea, setInputArea, error, required } = props;

  const handleChange = (value) => {
    if (value.includes("img")) {
      const valueSplited = value.split("img");
      const imgWithStyle = valueSplited[0] + "img style='max-width: 100%; height: auto;'" + valueSplited[1];
      setInputArea(imgWithStyle)
    }
    setInputArea(value);
  };

  return (
    <Container>
      <Label>
        <h1>Descripción {required && " *"}</h1>
        {
          error && <h4>Campo requerido</h4>
        }
      </Label>
      <ReactQuillContainer
        value={inputArea}
        onChange={handleChange} 
        modules={{  
          toolbar: {  
            container: [  
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
              ['bold', 'italic', 'underline'],  
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],  
              [{ 'align': [] }],  
              ['link', 'image'],  
              ['clean'],  
              [{ 'color': [] }]  
            ] 
          }
        }}  
      />
    </Container>
  );
};

export default RichTextEditor;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  span {
    color: #000;
  }

  @media (width < 768px) {
    margin-bottom: 6rem;
  }
`

const Label = styled.label`
  display: flex;
  width: 100%;
  justify-content: space-between;

  h1 {
    color: #616161;
    font-size: 1rem;
    font-weight: normal;
  }

  h4 {
    color: #b31d15
  }
`

const ReactQuillContainer = styled(ReactQuill)`
  height: 30vh;
`