import React from 'react';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import { MenuItem, ListItemIcon } from '@mui/material';
import useTheme from '../../../hooks/useTheme';

const DracmaItem = (props) => {
    const { handleClose, dracma_coin_name } = props;
    const { primary } = useTheme();
    // RETURN
    return (
        <Container
            to="/competencia"
            activeClassName="active"
            onClick={handleClose}
        >
            <FakeMenuItem color={primary}>
                <ListItemIcon>
                    <CustomDracmaCoin id="dracma-coin" />
                </ListItemIcon>
                {dracma_coin_name !== '' && dracma_coin_name !== undefined ? (
                    <p>Competencia {dracma_coin_name} </p>
                ) : (
                    <p>Competencia Dracma</p>
                )}
            </FakeMenuItem>
        </Container>
    );
};

export default DracmaItem;

const Container = styled(NavLink)`
    &.active {
        p {
            color: #222222;
        }
    }
`;

const FakeMenuItem = styled(MenuItem)`
    padding: 0.5rem 2rem;
    border-top: 1px solid #eeeeee;

    .MuiListItemIcon-root {
        min-width: 2rem;
        @media (max-width: 768px) {
            font-size: 1.2rem;
        }
    }

    p {
        font-size: 0.9rem;
        margin: 0;
        padding: 0.9rem;

        @media (max-width: 768px) {
            font-size: 1.2rem;
        }
    }

    p {
        color: #a8a8a8;
    }

    :hover{
        #dracma-coin{
            filter: grayscale(0%) opacity(100%);
        }
        p {
            color: #b31d15;
        }
    }
`;

const CustomDracmaCoin = styled.div`
    filter: grayscale(100%) opacity(43%);
    background-image: url(${process.env.PUBLIC_URL + '/assets/aden/dracma-point.svg'});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 22px;
    height: 22px;
`;
