import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    getCoursesStudentAA,
    getDocsStudentAA,
    getGeneralInfoOneStudent,
    getInfoOneStudentAA,
    getProgramsStudentAA,
} from "../redux/api/students";
import { useQueryParams } from "./useQueryParams";

function useOneStudent(activeTab) {
    let { id } = useParams();
    const params = useQueryParams();
    const inscriptionId = params.get("inscription");
    const sisId = params.get("sis");

    // STATES
    const [generalInfo, setGeneralInfo] = useState(null);
    const [firstInfo, setFirstInfo] = useState(null);
    const [coursesStudent, setCoursesStudent] = useState(null);
    const [docsStudent, setDocsStudent] = useState(null);
    const [programStudent, setProgramStudent] = useState(null);

    // EFFECTS
    useEffect(() => {
        if (firstInfo === null) {
            getFirstData();
        }
    }, []);

    useEffect(() => {
        if (coursesStudent === null && activeTab === 0) {
            getCourses();
        }
        if (programStudent === null && activeTab === 1) {
            getProgram();
        }
        if (docsStudent === null && activeTab === 2) {
            getDocs();
        }
        if (generalInfo === null && activeTab === 5) {
            getGeneralInfo();
        }
    }, [activeTab]);

    // FUNCTIONS
    // TRAE PESTAÑA DATOS PERSONALES
    const getGeneralInfo = async () => {
        const response = await getGeneralInfoOneStudent(id);

        if (!!response.error) {
            setGeneralInfo(false);
        } else {
            setGeneralInfo(response);
        }
    };

    // TRAER INFO PRINCIPAL
    const getFirstData = async () => {
        const response = await getInfoOneStudentAA(id, inscriptionId);

        if (!!response.error) {
            setFirstInfo(false);
        } else {
            setFirstInfo(response);
        }
    };

    // TRAE CURSOS DEL ALUMNO
    const getCourses = async () => {
        const response = await getCoursesStudentAA(sisId);

        if (!!response.error) {
            setCoursesStudent(false);
        } else {
            setCoursesStudent(response);
        }
    };

    // TRAE DOCUMENTOS DEL ALUMNO
    const getDocs = async () => {
        const response = await getDocsStudentAA(id);

        if (!!response.error) {
            setDocsStudent(false);
        } else {
            setDocsStudent(response);
        }
    };

    // TRAE PROGRAMAS DEL ALUMNO
    const getProgram = async () => {
        const response = await getProgramsStudentAA(id);

        if (!!response.error) {
            setProgramStudent(false);
        } else {
            setProgramStudent(response);
        }
    };

    return {
        generalInfo,
        firstInfo,
        coursesStudent,
        docsStudent,
        programStudent
    };
}

export default useOneStudent;
