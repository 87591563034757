import styled from "styled-components";
import { useContext } from "react";
import { ProfileContext } from "../../../contexts/profile/ProfileProvider";
import { Icon } from "@iconify/react/dist/iconify.js";
import CommonButton from "../../../components/common/CommonButton";
import PublicProfile from "./sideInfo/PublicProfile";
import FontAwesomeIcon from "../../../components/common/FontAwesomeIcon";
import TextToEdit from "./mainInfo/formationAndExperience/TextToEdit";
import { Modal } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import ModalInfo from "./ModalInfo";
import SharedProfile from "../../../components/ui/molecules/profile/SharedProfile";
import * as encrypt from 'nodejs-base64-encode';

const SideInfo = () => {

    const { user } = useContext(ProfileContext);

    // use local storage to save the open modal state
    const [openModal, setOpenModal] = useState(false);
    const [openModalShare, setOpenModalShare] = useState(false);

    const hash = encrypt.encode(user.partnerId + '', 'base64');
    const text = `${window.location.host}/perfil-publico/${hash}`;

    useEffect(() => {
        const savedModalState = localStorage.getItem('openModal');
        if (savedModalState) {
            setOpenModal(JSON.parse(savedModalState));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('openModal', JSON.stringify(openModal));
    }, [openModal]);


    const socialNetworks = [
        {
            id: 1,
            name: 'LinkedIn',
            icon: 'fa-brands fa-linkedin',
            url: user?.linkedIn
        },
        {
            id: 2,
            name: 'Facebook',
            icon: 'fa-brands fa-square-facebook',
            url: user?.facebook
        },
        {
            id: 3,
            name: 'Instagram',
            icon: 'fa-brands fa-square-instagram',
            url: user?.instagram
        },
        {
            id: 4,
            name: 'X',
            icon: 'fa-brands fa-square-x-twitter',
            url: user?.x
        }
    ]

    const personalData = [
        {
            id: 1,
            icon: 'fa-light fa-location-dot',
            data: user?.direction
        },
        {
            id: 2,
            icon: 'fa-light fa-cake-candles',
            data: user?.birthdayDate
        },
        {
            id: 3,
            icon: 'fa-light fa-graduation-cap',
            data: user?.profession
        },
        {
            id: 4,
            icon: 'fa-light fa-briefcase',
            data: user?.currentJob
        },
        {
            id: 5,
            icon: 'fa-light fa-building',
            data: user?.company
        }
    ]

    return (
        <SideInfoContainer>
            <Modal 
                open={openModal}
                onClose={() => setOpenModal(false)}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <ModalInfo onClose={() => setOpenModal(false)}/>
            </Modal>
            <SharedProfile
                modalIsOpen={openModalShare}
                handleCloseModal={() => setOpenModalShare(false)}
                link={text}
            />
            <PrincipalInfo>
                <img src={user?.photo} alt="Foto de perfil" />
                <h3>{user?.name}</h3>
                <h4>#{user?.fusionAuthID}</h4>
            </PrincipalInfo>
            <Divider />
            <PublicProfile />
            <Divider />
            <AboutMe>
                <label>Sobre ti</label>
                <TextToEdit property={{ formatted: "biography", notFormatted: "biography" }} />
            </AboutMe>
            <Divider />
            <ContactData>
                <label>Mis datos</label>
                {
                    personalData.map((data) => (
                        <h5 key={data.id}>
                            <FontAwesomeIcon icon={data.icon} color="#B31D15" size="24px" />
                            {data.data}
                        </h5>
                    ))
                }
            </ContactData>
            <Divider />
            <ContactData>
                <label>Contacto</label>
                <h5>
                    <FontAwesomeIcon icon="fa-light fa-envelope" color="#B31D15" size="24px" />
                    {user?.email}
                </h5>
                <h5>
                    <FontAwesomeIcon icon="fa-light fa-phone" color="#B31D15" size="24px" />
                    +{user?.phoneNumber}
                    {
                        !!user?.workPhoneNumber &&
                        <>
                            <Pipe />
                            +{user?.workPhoneNumber}
                        </>
                    }
                </h5>
            </ContactData>
            <Divider />
            <SocialNetworks>
                <label>Mis redes sociales </label>
                <div>
                    {
                        socialNetworks.map((socialNetwork) => (
                            <button key={socialNetwork.id} disabled={!socialNetwork.url || socialNetwork.url === "-"}>
                                <a href={socialNetwork.url} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={socialNetwork.icon} color="#B31D15" size="24px" />
                                </a>
                            </button>
                        ))
                    }
                </div>
            </SocialNetworks>
            <Divider />
            <ButtonsContainer>
                <CommonButton
                    variant="filled"
                    label="Editar perfil"
                    Icon={() => <Icon icon="bi:pencil-square" width="24px" height="24px" />}
                    onClick={() => setOpenModal(true)}
                />
                <CommonButton
                    variant="black&white"
                    label="Compartir perfil"
                    Icon={() => <Icon icon="bi:share" width="24px" height="24px" />}
                    onClick={() => setOpenModalShare(true)}
                />
            </ButtonsContainer>
        </SideInfoContainer>
    );
}

export default SideInfo;

const SideInfoContainer = styled.div` 
    width: calc(25% - 24px);
    min-height: 70vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    border: 1px solid #A8A8A8;
    border-radius: 20px;
    background-color: #f9f9f9;
    padding: 24px;

    @media (width < 1560px) {
        width: calc(25% - 24px);
    }

    @media (width < 1150px) {
        width: calc(100% - 4rem);
    }
`

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #BFBFBF;
`


const PrincipalInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;

    img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 1px solid #E5E5E5;
        object-fit: cover;
    }

    h3 {
        font-size: 20px;
        font-weight: 700;
        color: #B31D15;
    }

    h4 {
        font-size: 14px;
        font-weight: 400;
        color: #A8A8A8; 
    }

    p {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        svg {
            margin-right: 8px;
            color: #b31d15;
        }
    }
`

const AboutMe = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;
    
    label {
        font-size: 16px;
        color: #000;
        font-weight: 500;
    }

    p {
        width: 100%;
    }
`

const ContactData = styled.div`
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: start;
    align-items: start;
    gap: 1rem;

    h5 {
        display: flex;
        align-items: center;
        color: #666;
        font-weight: 400;

        svg {
            margin-right: 8px;
            color: #b31d15;
            width: 24px;
        }
    }
`

const Pipe = styled.div`
    width: 2px; 
    height: 24px;
    background-color: #b31d15;
    margin: 0 1rem;
`

const SocialNetworks = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;

    div {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    button {
        svg {
            color: #b31d15;
        }

        :disabled {
            cursor: not-allowed;

            a {
                pointer-events: none;
            }
            svg {
                color: #a8a8a8 !important;
            }
        }
    }
`

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;

    @media (width < 1650px) {
        flex-direction: column;
        align-items: start;
    }
`