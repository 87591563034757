import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const InfoSignUp = (props) => {
    const { name } = props;

    const history = useHistory();

    return (
        <InfoSignUpWrapper>
            <WelcomeButton onClick={() => history.push(`/auth`)}>
                ¡Ingresar con {name}!
            </WelcomeButton>
        </InfoSignUpWrapper>
    );
};

export default InfoSignUp;

const InfoSignUpWrapper = styled.div`
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WelcomeButton = styled.div`
    width: 350px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fadede;
    border-radius: 0.3rem;
    cursor: pointer;
    color: #b31d15;
    font-weight: 700;
    transition: all ease-in-out 0.2s;
    box-shadow: 0px 0px 8px -2px rgba(168, 166, 166, 0.75);
    -webkit-box-shadow: 0px 0px 8px -2px rgba(168, 166, 166, 0.75);
    -moz-box-shadow: 0px 0px 8px -2px rgba(168, 166, 166, 0.75);
    :hover {
        filter: opacity(0.8);
    }
`;
