import styled from 'styled-components';
import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Tooltip from '@mui/material/Tooltip';

const WhatsappBtn = (props) => {
    const { x = false, y = false } = props;

    const openWhatsapp = () =>
        window.open(
            'https://wa.me/5078339232?text=¡Hola,%20me%20comunico%20desde%20la%20plataforma%20de%20Acrópolis!'
        );

    return (
        <Tooltip
            arrow
            title="Contactar whatsapp de soporte"
            placement="left-start"
        >
            <WhatsappBtnWrapper onClick={openWhatsapp} x={x} y={y}>
                <WhatsAppIcon />
            </WhatsappBtnWrapper>
        </Tooltip>
    );
};

export default WhatsappBtn;

const WhatsappBtnWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    width: 45px;
    height: 45px;
    max-height: 45px;
    border-radius: 50%;
    background-color: #b31d15;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out all;
    box-shadow: 0px 0px 7px -1px rgba(148, 140, 140, 0.75);
    -webkit-box-shadow: 0px 0px 7px -1px rgba(148, 140, 140, 0.75);
    -moz-box-shadow: 0px 0px 7px -1px rgba(148, 140, 140, 0.75);
    ${(p) => p.x};
    ${(p) => p.y};
    :hover {
        filter: brightness(0.8);
    }
    @media (max-width: 768px) {
        right: 1rem;
    }
`;
