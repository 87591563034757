import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import CardSubmissionRepo from "./CardSubmissionRepo";

const CardActivitiesRepo = (props) => {
    const { module, repoId, id } = props;

    // STATES
    const [moreInfo, setMoreInfo] = useState(false);

    // FUNCTIONS
    const handleMoreInfo = () => {
        setMoreInfo(!moreInfo);
    };

    return (
        <Wrapper moreInfo={moreInfo}>
            <Header onClick={handleMoreInfo}>
                <IconCheck icon="icon-park-solid:check-one" />
                <Container>
                    <Title>
                        <span>MÓDULO</span>
                        <h3>{module?.name}</h3>
                    </Title>
                    <ArrowWrapper>
                        <IconArrow
                            moreInfo={moreInfo}
                            icon="ep:arrow-up-bold"
                        />
                    </ArrowWrapper>
                </Container>
            </Header>
            <Body moreInfo={moreInfo}>
                <HeaderBody>
                    <Divider></Divider>
                    <TableTitle>Fecha de entrega</TableTitle>
                    <TableTitle>Estado</TableTitle>
                    <TableTitle>Tipo de actividad</TableTitle>
                    <TableTitle>Intentos</TableTitle>
                    <TableTitle>Calificación</TableTitle>
                </HeaderBody>
                {module.activities.map((activity, index) => (
                    <CardSubmissionRepo
                        activity={activity}
                        key={index}
                        repoId={repoId}
                        id={id}
                    />
                ))}
            </Body>
        </Wrapper>
    );
};

export default CardActivitiesRepo;

const Wrapper = styled.div`
    width: calc(100% - 2rem);
    background-color: #fff;
    box-shadow: 0px 12px 24px -15px rgba(0, 0, 0, 0.1),
        0px 0px 10px -6px rgba(0, 0, 0, 0.25);
    min-height: ${(p) =>
        !p.moreInfo ? "calc(104px - 2rem)" : "calc(264px - 2rem)"};
    padding: 1rem;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const Header = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;
    height: calc(104px - 2rem);
    cursor: pointer;
`;

const IconCheck = styled(Icon)`
    font-size: 2.5rem;
    color: #b31d15;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    span {
        color: #616161;
        font-size: 0.9rem;
    }

    h3 {
        color: #b31d15;
        font-size: 1.1rem;
    }
`;

const ArrowWrapper = styled.div`
    padding: 0.3rem;
`;

const IconArrow = styled(Icon)`
    font-size: 1.4rem;
    transition: all 0.3s ease-in-out;
    transform: ${(p) => (!p.moreInfo ? "rotate(180deg)" : "rotate(0deg)")};
    color: #000;
`;

const Body = styled.div`
    height: 100%;
    flex-grow: 1;
    display: ${(p) => (p.moreInfo ? "flex" : "none")};
    width: 100%;
    gap: 0.8rem;
    flex-direction: column;
`;

const HeaderBody = styled.div`
    display: flex;
    margin-left: 55px;
    width: calc(100% - 77px);
    padding: 0 11px;
`;

const TableTitle = styled.div`
    flex-basis: calc(12.5% - 0.5rem);
    text-align: center;

    color: #616161;
    font-weight: 700;
    font-size: 14px;
`;

const Divider = styled.div`
    flex-basis: calc(37.5% - 0.5rem);
`;
