import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { titleHTML } from '../../helpers/title.helper';
import useRefreshTheme from '../../hooks/useRefreshTheme';
import { authActions } from '../../redux/actions';

const Logout = () => {
    titleHTML("Cerraste sesión");

    // REDUX
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.auth.authToken);
    const theme = useRefreshTheme();
    const history = useHistory();

    // EFFECTS
    useEffect(() => {
        if (authToken !== null) {
            // theme.deleteTheme();
            dispatch(authActions.logoutUser());
        } else {
            history.push('/auth');
        }
    }, [authToken, dispatch, theme, history]);

    return null;
};

export default Logout;
