import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    FormControl,
    Slider,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import ChipGeneric from '../../../common/teacher-academic/ChipGeneric';
import SearchBar from '../../../common/teacher-academic/SearchBar';
import { useSelector } from 'react-redux';
import NoResultsFound from '../../../common/NoResultsFound';
import axios from 'axios';
import SimpleLoading from '../../../common/SimpleLoading';

const FilterStudentsStatus = (props) => {
    const { sentFilters, handleClickOpen } = props;

    const [filterlist, setFilterlist] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState(null);

    const countries = useSelector((state) => state.utils.countries);

    //EFFECTS
    useEffect(() => {
        getFilters();
    }, []);

    useEffect(() => {
        if (selectedFilters !== []) {
            sentFilters(selectedFilters);
            handleClickOpen();
            getFilters();
        }
    }, [selectedFilters]);

    // useEffect(() => {
    //   !handleClickOpen && setSelectedFilters([]);
    // }, [handleClickOpen])

    const getFilters = async (body) => {
        const URL = `${window.location.origin}/mock/filterList.json`;
        try {
            const response = await axios.get(URL, body);
            return setFilterlist(response.data.response_data.filters);
        } catch (error) {
            return { error };
        }
    };

    // FUNCTION
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearchValue = (args) => {
        setSearchValue(args);
    };

    const handleClickFilter = (e) => {
        const id_select = e.target.parentElement.id;
        filterlist.forEach((element) => {
            if (element.id === Number(id_select)) {
                setFilterlist([
                    ...filterlist,
                    (element.selected = !element.selected)
                ]);
            }
        });
    };

    const handleSubmit = () => {
        let filtersTrue = filterlist.filter(
            (element) => element.selected === true
        );
        setSelectedFilters(filtersTrue);
        // sentFilters( selectedFilters );
        // handleClickOpen(false);
    };

    if (!filterlist) {
        return <SimpleLoading />;
    }
    return (
        <Wrapper>
            <FormControl>
                <AccordionFake
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6h-content"
                        id="panel6h-header"
                    >
                        <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                            Estado estudiantes
                        </TypographyFake>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TypographyChips>
                            {filterlist
                                .filter((f) => f.type === 'status')
                                .map((status) => {
                                    return (
                                        <ChipGeneric
                                            label={status.name}
                                            id={status.id}
                                            checked={status.selected}
                                            handleClick={handleClickFilter}
                                        />
                                    );
                                })}
                        </TypographyChips>
                    </AccordionDetails>
                </AccordionFake>

                <AccordionFake
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                            Condición estudiante
                        </TypographyFake>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TypographyChips>
                            {filterlist
                                .filter((f) => f.type === 'condition')
                                .map((status) => {
                                    return (
                                        <ChipGeneric
                                            label={status.name}
                                            id={status.id}
                                            checked={status.selected}
                                            handleClick={handleClickFilter}
                                        />
                                    );
                                })}
                        </TypographyChips>
                    </AccordionDetails>
                </AccordionFake>

                <AccordionFake
                    expanded={expanded === 'panel4'}
                    onChange={handleChange('panel4')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                            País
                        </TypographyFake>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* <SearchBar
                            propsValues={countries}
                            handleSearchValue={handleSearchValue}
                            title="Buscar paises" 
                            filterNames={['name']} 
                        />
                        {searchValue.length === 0 ? (
                            <NoResultsFound />
                        ) : (
                            searchValue.map((info, index) => <ChipGeneric
                                                    label={info.name}
                                                    id={index + 1}
                                                    checked={info.state}
                                                    handleClick={handleClickCondition}
                                                />
                            )
                        )} */}
                    </AccordionDetails>
                </AccordionFake>

                <AccordionFake
                    expanded={expanded === 'panel5'}
                    onChange={handleChange('panel5')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                    >
                        <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                            Último contacto
                        </TypographyFake>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TypographyChips>
                            {filterlist
                                .filter((f) => f.type === 'lastContact')
                                .map((status) => {
                                    return (
                                        <ChipGeneric
                                            label={status.name}
                                            id={status.id}
                                            checked={status.selected}
                                            handleClick={handleClickFilter}
                                        />
                                    );
                                })}
                        </TypographyChips>
                    </AccordionDetails>
                </AccordionFake>

                <FilterButton>
                    <ButtonFake
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        APLICAR FILTRO
                    </ButtonFake>
                </FilterButton>
            </FormControl>
        </Wrapper>
    );
};

export default FilterStudentsStatus;

const Wrapper = styled.div`
    height: 80%;
    overflow-y: auto;
    row-gap: 1rem;
`;

const AccordionFake = styled(Accordion)`
    border: none !important;
    box-shadow: none;
    margin-bottom: 2rem;

    &::before {
        display: none;
    }
`;
const TypographyFake = styled(Typography)`
    color: #616161;
    font-weight: 700;
    font-size: 1.1rem;
`;
const FilterButton = styled.div`
    width: 90%;
    position: absolute;
    top: 90%;
`;
const ButtonFake = styled(Button)`
    border-radius: 5px;
    width: 100%;
    height: 3rem;
`;
const TypographyChips = styled(Typography)`
    display: flex;
    width: 100%;
    gap: 0.3rem;
    flex-wrap: wrap;
`;
