import * as types from '../types';

const actions = {};

// GET EVENT
actions.getEventsRequest = () => {
    return {
        type: types.GET_EVENTS_REQUEST
    };
};

actions.getEventsSuccess = (event) => {
    return {
        type: types.GET_EVENTS_SUCCESS,
        payload: event
    };
};

actions.getEventsFail = (error) => {
    return {
        type: types.GET_EVENTS_FAIL,
        payload: error
    };
};

export { actions };
