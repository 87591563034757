import styled from "styled-components";
import EnrollSuccess from "../../assets/media/aden/high-five.png"
import { Icon } from "@iconify/react";

const EnrollSuccessPopUp = (props) => {

    const { handleClick, setOpenModal } = props;

    return (
        <Wrapper>
            <ModalContainer>
                <CloseButton onClick={() => setOpenModal(false)}>
                    <Icon icon="iconoir:cancel" width={25} />
                </CloseButton>
                <img src={EnrollSuccess} alt="question" />
                <h2>¡Felicitaciones!</h2>
                <p>Ya puedes comenzar a cursar <br/> la asignatura seleccionada.</p>
                <ActionButton onClick={handleClick}>Entendido</ActionButton>
            </ModalContainer>
        </Wrapper>
    )
}

export default EnrollSuccessPopUp;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000030;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ModalContainer = styled.div`
    position: relative;
    width: 450px;
    height: 600px;
    max-height: 600px;
    background-color: #fff;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    h2 {
        font-weight: 700;
        font-size: 24px;
        color: #b31d15;
    }

    p {
        width: 70%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
    }

    b {
        font-weight: 700;
        font-size: 16px;
    }

    @media (width <= 768px) {
        width: 90%;
    }
`

const ActionButton = styled.button`
    height: 40px;
    padding: 0 1rem;
    background-color: #CD2118;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;

    :hover {
        background-color: #b31d15;
    }
`

const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
`