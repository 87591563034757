import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Footer from './components/Footer';
import SimpleNav from './components/Navegation/SimpleNav';
import CustomBreadcrumbs from '../../components/common/CustomBreadcrumbs';
import Link from '@mui/material/Link';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
}));

function ScrollTop(props) {
    const { children } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor'
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div
                onClick={handleClick}
                role="presentation"
                className={classes.root}
            >
                {children}
            </div>
        </Zoom>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired
};

const PrivacyPolicy = () => {
    return (
        <div style={{ backgroundColor: '#f9f9f9' }}>
            <SimpleNav />
            <MainContainer>
                <CustomBreadcrumbs title="Política de Privacidad"></CustomBreadcrumbs>
                <Title id="back-to-top-anchor">Política de Privacidad</Title>
                <GridContainer>
                    <ContentContainer>
                        <LinkContainer>
                            <CustomLink href="#infoRecogida">
                                Información que es recogida
                            </CustomLink>
                            <CustomLink href="#usoInfoRecogida">
                                Uso de la información recogida
                            </CustomLink>
                            <CustomLink href="#cookies">Cookies</CustomLink>
                            <CustomLink href="#enlaceTerceros">
                                Enlace a terceros
                            </CustomLink>
                            <CustomLink href="#controlInfoPersonal">
                                Control de su información personal
                            </CustomLink>
                            <CustomLink href="#politicaReembolso">
                                Política de reembolso
                            </CustomLink>
                        </LinkContainer>
                    </ContentContainer>
                    <ContentContainer>
                        <TextHighlight>
                            La presente Política de Privacidad establece los
                            términos en que ADEN usa y protege la información
                            que es proporcionada por sus usuarios al momento de
                            utilizar su sitio web. Esta compañía está
                            comprometida con la seguridad de los datos de sus
                            usuarios. Cuando le pedimos llenar los campos de
                            información personal con la cual usted pueda ser
                            identificado, lo hacemos asegurando que sólo se
                            empleará de acuerdo con los términos de este
                            documento. Sin embargo esta Política de Privacidad
                            puede cambiar con el tiempo o ser actualizada por lo
                            que le recomendamos y enfatizamos revisar
                            continuamente esta página para asegurarse que está
                            de acuerdo con dichos cambios.
                        </TextHighlight>
                        <Subtitle id="infoRecogida">
                            Información que es recogida
                        </Subtitle>
                        <Text>
                            Nuestro sitio web podrá recoger información personal
                            por ejemplo: Nombre, información de contacto como su
                            dirección de correo electrónica e información
                            demográfica. Así mismo cuando sea necesario podrá
                            ser requerida información específica para procesar
                            algún pedido o realizar una entrega o facturación.
                        </Text>
                        <Subtitle id="usoInfoRecogida">
                            Uso de la información recogida
                        </Subtitle>
                        <Text>
                            Nuestro sitio web emplea la información con el fin
                            de proporcionar el mejor servicio posible,
                            particularmente para mantener un registro de
                            usuarios, de pedidos en caso que aplique, y mejorar
                            nuestros productos y servicios. Es posible que sean
                            enviados correos electrónicos periódicamente a
                            través de nuestro sitio con ofertas especiales,
                            nuevos productos y otra información publicitaria que
                            consideremos relevante para usted o que pueda
                            brindarle algún beneficio, estos correos
                            electrónicos serán enviados a la dirección que usted
                            proporcione y podrán ser cancelados en cualquier
                            momento. <br />
                            <br /> ADEN está altamente comprometido para cumplir
                            con el compromiso de mantener su información segura.
                            Usamos los sistemas más avanzados y los actualizamos
                            constantemente para asegurarnos que no exista ningún
                            acceso no autorizado.
                        </Text>
                        <Subtitle id="cookies">Cookies</Subtitle>
                        <Text>
                            Una cookie se refiere a un fichero que es enviado
                            con la finalidad de solicitar permiso para
                            almacenarse en su ordenador, al aceptar dicho
                            fichero se crea y la cookie sirve entonces para
                            tener información respecto al tráfico web, y también
                            facilita las futuras visitas a una web recurrente.
                            Otra función que tienen las cookies es que con ellas
                            las web pueden reconocerte individualmente y por
                            tanto brindarte el mejor servicio personalizado de
                            su web. <br />
                            <br /> Nuestro sitio web emplea las cookies para
                            poder identificar las páginas que son visitadas y su
                            frecuencia. Esta información es empleada únicamente
                            para análisis estadístico y después la información
                            se elimina de forma permanente. Usted puede eliminar
                            las cookies en cualquier momento desde su ordenador.
                            Sin embargo las cookies ayudan a proporcionar un
                            mejor servicio de los sitios web, estas no dan
                            acceso a información de su ordenador ni de usted, a
                            menos de que usted así lo quiera y la proporcione
                            directamente, visitas a una web. Usted puede aceptar
                            o negar el uso de cookies, sin embargo la mayoría de
                            navegadores aceptan cookies automáticamente pues
                            sirve para tener un mejor servicio web. También
                            usted puede cambiar la configuración de su ordenador
                            para declinar las cookies. Si se declinan es posible
                            que no pueda utilizar algunos de nuestros servicios.
                        </Text>
                        <Subtitle id="enlaceTerceros">
                            Enlaces a terceros
                        </Subtitle>
                        <Text>
                            Este sitio web pudiera contener enlaces a otros
                            sitios que pudieran ser de su interés. Una vez que
                            usted dé clic en estos enlaces y abandone nuestra
                            página, ya no tenemos control sobre el sitio al que
                            es redirigido y por lo tanto no somos responsables
                            de los términos o privacidad ni de la protección de
                            sus datos en esos otros sitios terceros. Dichos
                            sitios están sujetos a sus propias políticas de
                            privacidad por lo cual es recomendable que los
                            consulte para confirmar que usted está de acuerdo
                            con estas.
                        </Text>
                        <Subtitle id="controlInfoPersonal">
                            Control de su información personal
                        </Subtitle>
                        <Text>
                            En cualquier momento usted puede restringir la
                            recopilación o el uso de la información personal que
                            es proporcionada a nuestro sitio web. Cada vez que
                            se le solicite rellenar un formulario, como el de
                            alta de usuario, puede marcar o desmarcar la opción
                            de recibir información por correo electrónico. En
                            caso de que haya marcado la opción de recibir
                            nuestro boletín o publicidad usted puede cancelarla
                            en cualquier momento. <br /> <br /> Esta compañía no
                            venderá, cederá ni distribuirá la información
                            personal que es recopilada sin su consentimiento,
                            salvo que sea requerido por un juez con un orden
                            judicial. <br /> <br /> ADEN se reserva el derecho
                            de cambiar los términos de la presente Política de
                            Privacidad en cualquier momento. <br /> <br /> Si
                            tiene alguna pregunta con respecto a esta política
                            de privacidad, puede comunicarse con nosotros
                            utilizando la información a continuación. <br />
                            <br />
                            <a href="mailto:contacto@aden.org">
                                contacto@aden.org
                            </a>
                        </Text>
                        <Subtitle id="politicaReembolso">
                            Política de reembolso (sin reembolso)
                        </Subtitle>
                        <Text>
                            Todos los servicios se venden “tal como están”.
                            Luego del cursado de la carrera a la que corresponda
                            el pago, no se emitirán reembolsos.
                        </Text>
                    </ContentContainer>
                </GridContainer>
                <ScrollTop>
                    <CustomFab
                        color="primary"
                        size="small"
                        aria-label="scroll back to top"
                    >
                        <KeyboardArrowUpIcon />
                    </CustomFab>
                </ScrollTop>
            </MainContainer>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;

const MainContainer = styled.div`
    margin-top: 100px;
    padding: 2rem 5%;
`;

const Title = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    color: #b31d15;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
`;

const Subtitle = styled.h2`
    font-size: 1.3rem;
    font-weight: 700;
    color: #222222;
    margin: 2rem 0;

    &::before {
        display: block;
        content: ' ';
        margin-top: -130px;
        height: 130px;
        visibility: hidden;
        pointer-events: none;
    }
`;

const ContentContainer = styled.div`
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    height: auto;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 5px;
    display: block;
`;

const LinkContainer = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0;

    &::before {
        display: block;
        content: ' ';
        margin-top: -130px;
        height: 130px;
        visibility: hidden;
        pointer-events: none;
    }
`;

const Text = styled.p`
    font-size: 1rem;
    color: #222222;
`;

const TextHighlight = styled.p`
    font-size: 1.2rem;
    color: #b31d15;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 8fr;
    grid-gap: 1rem;

    @media screen and (max-width: 1440px) {
        grid-template-columns: 3fr 6fr;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const CustomLink = styled(Link)`
    width: 100%;
    display: block;
    padding: 1rem 0;
    color: #222222;

    &.active {
        color: #b31d15;
        font-weight: 700;
        border-right: 2px #b31d15 solid;
    }
`;

const ActiveLink = {
    fontWeight: '700',
    color: '#b31d15',
    borderRight: '2px #b31d15 solid'
};

const CustomFab = styled(Fab)`
    margin-bottom: 5em;
    margin-right: 1em;

    @media screen and (max-width: 768px) {
        margin-bottom: 6em;
    }
`;
