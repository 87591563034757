import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { programsActions } from "../../../../../redux/actions";

const usePauseProgram = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const postEnrollmentPause = async (body) => {
        setLoading(true);
        setError(null);
        try {
            const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/change_enrollment_status?partner_id=${body.partnerId}&programa_id=${body.programId}&estado=${body.state}`;
            const response = await axios.post(URL);
            setLoading(false);
            setError(false);
            dispatch(programsActions.getProgramsRequest());
            return response.data;
        } catch (error) {
            setLoading(false);
            setError(true);
            throw new Error(error);
        }
    };
    return { loading, error, postEnrollmentPause };
};

export default usePauseProgram;
