import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CardActivitiesRepo from "./CardActivitiesRepo";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { useParams } from "react-router-dom";
import { getActivitiesRepo } from "../../../../redux/api/academic/studentsRepo";
import SimpleLoading from "../../../common/SimpleLoading";
import { ReactComponent as NotFoundSvg } from "../../../../assets/media/svg/404-Error-pana.svg";

const ActivitiesRepo = () => {
    const params = useQueryParams();
    const repoId = params.get("repo");
    const { id } = useParams();

    // STATES
    const [modules, setModules] = useState(null);
    const [loading, setLoading] = useState(false);
    // EFFECTS
    useEffect(() => {
        modules === null && handleModules();
    }, [modules]);

    // FUNCTIONS
    const handleModules = async () => {
        setLoading(true);

        try {
            const res = await getActivitiesRepo(id, repoId);
            setModules(res?.courses);
            setLoading(false);
        } catch (error) {
            setModules(false);
            setLoading(false);
        }
    };

    return (
        <Wrapper>
            {loading ? (
                <SimpleLoading />
            ) : (
                <>
                    {modules?.length > 0 ? (
                        <>
                            {modules.map((module, index) => (
                                <CardActivitiesRepo
                                    key={index}
                                    module={module}
                                    repoId={repoId}
                                    id={id}
                                />
                            ))}
                        </>
                    ) : (
                        <ErrorWrapper>
                            <NotFoundSvg width="50%" />
                            <p>No se encontraron módulos con entregas</p>
                        </ErrorWrapper>
                    )}
                </>
            )}
        </Wrapper>
    );
};

export default ActivitiesRepo;

const Wrapper = styled.div`
    width: calc(100% - 5rem);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 12px 24px -15px rgba(0, 0, 0, 0.1),
        0px 0px 10px -6px rgba(0, 0, 0, 0.25);
    padding: 2.5rem;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const ErrorWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
