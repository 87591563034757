import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as encode from 'nodejs-base64-encode';

// Components
import DialogCertificate from '../../components/ui/molecules/studyPlan/DialogCertificate';
import { useQueryParams } from '../../modules/acropolisCommon/hooks/useQueryParams';
import CourseSkeleton from '../../components/common/CourseSkeleton';
import AsideCourse from './components/aside/AsideCourse';
import MainCourse from './components/main/MainCourse';

// Material UI
import { MenuRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';

// Custom hooks
import useGetNotesCourse from '../../hooks/notesCourse/useGetNotesCourse';
import useStudentStatus from '../campus/hooks/useStundetStatus';
import useLayout from '../../hooks/useLayout';
import useCourse from './hooks/useCourse';

// Assets
import { ReactComponent as NotFoundSvg } from '../../assets/media/svg/404-Error-pana.svg';

// Api
import { postLastEntry } from './services/api';

const CourseTemplate = (props) => {
    const { hash } = props;

    const params = useQueryParams();
    let idResource = parseInt(params.get('resource'));
    const idCourse = params.get('id');
    const courseId = encode.decode(idCourse + '', 'base64');

    // STATES
    const [isChangeMinute, setIsChangeMinute] = useState(false);
    const [currentMinute, setCurrentMinute] = useState(0);
    /* const [changeStatus, setChangeStatus] = useState(false); */
    const [viewDelivery, setViewDelivery] = useState(null);
    const [currentSecondVideo, setCurrentSecondVideo] = useState(0);
    const [showAside, setShowAside] = useState(true);
    const [tabClose, setTabClose] = useState(4);

    //CUSTOM-HOOKS
    const {
        repoId,
        workshop,
        grade,
        progress,
        setProgress,
        lastResourceOfCourse,
        currentMedia,
        setCurrentMedia,
        currentPosition,
        setCurrentPosition,
        changeCurrentMedia,
        toggleAside,
        handleCloseAside,
        unitsEnabled,
        openAside,
        blockResourceChange,
        blockResocurce,
        setMenuItems,
        menuItems,
        unitCurrentNow,
        handleNext,
        backItem,
        nextItem,
        handleCheckResource,
        currentUnit,
        nextResource,
        certificateEnabled,
        changeStatus,
        setChangeStatus,
        promptAI
    } = useCourse(hash);

    const { valuesNotes, errorGetNotes, LoadingGetNotes, getNotes } =
        useGetNotesCourse(currentMedia?.id);

    const { statusStudentBlock } = useStudentStatus();

    const notesContent = {
        valuesNotes,
        errorGetNotes,
        LoadingGetNotes,
        getNotes,
    };

    const { setMenu } = useLayout();

    // FUNCTIONS
    const handleCurrentSecond = (second) => {
        setCurrentSecondVideo(second);
    };

    const handleCurrentMinute = (minute) => {
        setCurrentMinute(minute);
    };

    const handleIsChangeMinute = (value) => {
        setIsChangeMinute(value);
    };

    // EFFECT
    useEffect(() => {
        workshop && setMenu(workshop?.nombre_publicacion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workshop]);

    useEffect(() => {
        if (currentMedia === null && workshop !== null) {
            // Selecciona la actividad no vista
            let status = true;
            if (idResource) {
                if (workshop !== null) {
                    workshop.unidades.forEach((unidad) => {
                        unidad.clases.forEach((clase) => {
                            if (clase?.id_evaluacion === idResource) {
                                // eslint-disable-next-line react-hooks/exhaustive-deps
                                idResource = null;
                                setCurrentMedia(clase || false);
                                setCurrentPosition(clase || false);
                                unitCurrentNow(clase || null);
                                status = false;
                            }
                        });
                    });
                }
            }
            workshop.unidades.forEach((unidad, u) => {
                if (status === true) {
                    unidad.clases.forEach((clase, i) => {
                        if (!clase.visto && status === true) {
                            setCurrentMedia(clase || false);
                            setCurrentPosition(clase || false);
                            unitCurrentNow(clase || null);
                            status = false;
                        }
                    });
                }
            });
            if (status === true) {
                setCurrentMedia(
                    workshop.unidades[0] !== undefined
                        ? workshop.unidades[0].clases[0]
                        : false
                );
                setCurrentPosition(
                    workshop.unidades[0] !== undefined
                        ? workshop.unidades[0].clases[0]
                        : false
                );
                unitCurrentNow(
                    workshop.unidades[0] !== undefined
                        ? workshop.unidades[0].clases[0]
                        : null
                );
            }
        }
        // Selecciona los items
        if (workshop !== null && menuItems.length === 0) {
            workshop.unidades.forEach((unidad) => {
                unidad.clases.forEach((clase) => {
                    setMenuItems((state) => [...state, clase]);
                });
            });
        }
        // Cálculo del progreso según "visto"
        let allView = 0;
        if (workshop !== null && menuItems.length !== 0) {
            workshop.unidades.forEach((unidad) => {
                unidad.clases.forEach((clase) => {
                    if (clase.visto) {
                        ++allView;
                    }
                    setProgress(
                        (allView * 100) /
                            (menuItems.length === 0 ? 1 : menuItems.length)
                    );
                });
            });
        }
    }, [workshop, currentMedia]);

    useEffect(() => {
        postLastEntry(repoId, courseId, hash);
        const id = localStorage.getItem('src_id');
        const type = localStorage.getItem('src_type');
        if (id || type) {
            localStorage.removeItem('src_id');
            localStorage.removeItem('src_type');
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (currentMedia === null) {
            const timer = setTimeout(() => {
                setCurrentMedia(false);
            }, 26000);

            return () => clearTimeout(timer);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMedia]);

    /** RETURN ----------------------------------------------------------------------- */
    if (currentMedia === null) {
        return <CourseSkeleton />;
    }

    if (currentMedia === false) {
        return (
            <DataEmpty>
                <Container>
                    <NotFoundSvg width="50%" />
                    <Title>{workshop?.nombre_publicacion}</Title>
                    <Description>
                        No hay recursos en el objeto de aprendizaje
                    </Description>
                </Container>
            </DataEmpty>
        );
    }

    return (
        <>
            <CoursePageContainer>
                <MainCourseWrapper>
                    <MainCourse
                        currentPosition={currentPosition}
                        notesContent={notesContent}
                        setChangeStatus={setChangeStatus}
                        handleCurrentSecond={handleCurrentSecond}
                        openAside={openAside}
                        nextItem={nextItem}
                        backItem={backItem}
                        statusStudentBlock={
                            statusStudentBlock?.studentStatus === 'Bloqueado'
                        }
                        reasonBlock={statusStudentBlock?.blockDetail?.reason}
                        menuItems={menuItems}
                        currentMedia={currentMedia}
                        nombre={workshop?.nombre_publicacion}
                        blockResourceChange={blockResourceChange}
                        blockResocurce={blockResocurce}
                        objId={hash}
                        handleCheckResource={handleCheckResource}
                        handleNext={handleNext}
                        nextResource={nextResource}
                        viewDelivery={viewDelivery}
                        lastResourceOfCourse={lastResourceOfCourse}
                        handleIsChangeMinute={handleIsChangeMinute}
                        isChangeMinute={isChangeMinute}
                        currentMinute={currentMinute}
                        showAside={showAside}
                        setShowAside={setShowAside}
                        tabClose={tabClose}
                        setTabClose={setTabClose}
                        promptAI={promptAI}
                    >
                        <IconButton onClick={toggleAside} size="large">
                            <MenuRounded htmlColor="#ffffff" />
                        </IconButton>
                    </MainCourse>
                </MainCourseWrapper>
                <AsideWrapper showAside={showAside}>
                    <AsideCourse
                        isWs={!!workshop.es_workshop}
                        nombre={workshop?.nombre_publicacion}
                        notesContent={notesContent}
                        certificado={workshop.certificado}
                        openAside={openAside}
                        handleCloseAside={handleCloseAside}
                        toggleAside={toggleAside}
                        unidades={workshop.unidades}
                        currentMedia={currentMedia}
                        grade={grade}
                        changeCurrentMedia={changeCurrentMedia}
                        currentUnit={currentUnit}
                        objId={hash}
                        menuItems={menuItems}
                        workshop={workshop}
                        unitsEnabled={unitsEnabled}
                        changeStatus={changeStatus}
                        setChangeStatus={setChangeStatus}
                        setViewDelivery={setViewDelivery}
                        currentSecondVideo={currentSecondVideo}
                        lastResourceOfCourse={lastResourceOfCourse}
                        certificateEnabled={certificateEnabled}
                        handleIsChangeMinute={handleIsChangeMinute}
                        handleCurrentMinute={handleCurrentMinute}
                        showAside={showAside}
                        tabClose={tabClose}
                        setTabClose={setTabClose}
                    />
                </AsideWrapper>
                {workshop.certificado === true &&
                    progress === 100 &&
                    !!certificateEnabled && (
                        <DialogCertificate
                            setOpenTest={false}
                            course={workshop}
                            type={'workshop'}
                        />
                    )}
            </CoursePageContainer>
        </>
    );
};

export default CourseTemplate;

const CoursePageContainer = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 1150px) {
        flex-direction: column;
        max-width: 95vw;
    }
`;

const MainCourseWrapper = styled.div`
    flex-basis: 75%;

    @media (width < 1150px) {
        max-width: 95vw;
    }

    @media (max-width: 900px) {
        flex-basis: 100%;
    }
`;

const AsideWrapper = styled.div`
    flex-basis: 25%;

    @media (max-width: 900px) {
        flex-basis: 100%;
    }
`;

const DataEmpty = styled.div`
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Title = styled.div`
    font-size: 1.2rem;
    color: #b31d15;
    font-weight: 600;
`;

const Description = styled.div`
    font-size: 1rem;
    color: #555555;
    font-weight: 600;
`;
