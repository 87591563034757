import styled from 'styled-components';

// Material UI
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Error from '@mui/icons-material/Error';

// Components
import { Text } from '../../../../../../components/common/Texts';

const State = (props) => {

    const { module } = props;

    return (
        <StateWrapper>
            {module?.survey ? (
                module?.survey?.done ? (
                    <>
                        <CheckCircleOutline />
                        <Text
                            color="#616161"
                            fontSize={'16px'}
                            fontWeight="500"
                        >
                            Completaste la encuesta
                        </Text>
                    </>
                ) : (
                    <>
                        <Error />
                        <Text
                            color="#616161"
                            fontSize={'16px'}
                            fontWeight="500"
                        >
                            Recuerda completar la
                            encuesta para continuar con
                            tu cursado.
                        </Text>
                    </>
                )
            ) : (
                <>
                    {module.category !== 'electiva' &&
                        !module.selectedFromElective && (
                            <>
                                <Text
                                    color="#616161"
                                    fontSize={'16px'}
                                    fontWeight="700"
                                >
                                    Estado:{' '}
                                </Text>
                                <StateChip
                                    state={
                                        module?.progress ===
                                            100
                                            ? 'finished'
                                            : module?.progress ===
                                                0
                                                ? 'to_begin'
                                                : 'on_study'
                                    }
                                >
                                    {module?.progress ===
                                        100
                                        ? 'Finalizado'
                                        : module?.progress ===
                                            0
                                            ? 'Por iniciar'
                                            : 'En curso'}
                                </StateChip>
                            </>
                        )}
                </>
            )}
        </StateWrapper>
    )
}

export default State;

const StateWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    gap: 12px;
    width: fit-content;
    top: -3.4rem;
    right: 0;

    svg {
        color: #b31d15;
    }
`;

const StateChip = styled.div`
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06px;
    padding: 8px 1rem;
    border-radius: 100px;
    border: ${({ state }) =>
        state === 'finished'
            ? '1px solid #1E8065'
            : state === 'to_begin'
                ? '1px solid #C29F43'
                : '1px solid #5E80DB'
    };

    color: ${({ state }) =>
        state === 'finished'
            ? '#1E8065'
            : state === 'to_begin'
                ? '#C29F43'
                : '#5E80DB'
    };

    background-color: ${({ state }) =>
        state === 'finished'
            ? '#F7FDFB'
            : state === 'to_begin'
                ? '#F9F5EC'
                : '#F7F8FD'
    };
`