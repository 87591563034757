import { useEffect, useState } from "react"
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../../utils/snackStyles";
import { getResourceById } from "../services/repositorio";

const useResource = (id, type) => {

    const [resource, setResource] = useState(null)
    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        fetchData()
    }, [id, type])

    const fetchData = async () => {
        const request = await getResourceById(id, type)
        if (request.error) {
            openSnackbar('¡No se pudo obtener la información del recurso!');
            setResource('error')
        }
        else {
            setResource(request)
        }
    }

    return { resource }
}

export default useResource