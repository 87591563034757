import { Button, Card } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import GenericPagination from '../../../common/GenericPagination';
import TableGradeBook from './table/TableGradeBook';
import FilterFor from '../../../common/teacher-academic/FilterFor';
import useGradebook from '../../../../hooks/teacher/useGradebook';
import ErrorResponse from '../../../common/ErrorResponse';
import { Tune } from '@mui/icons-material';

const GradebookSection = (props) => {
    const { id, viewAcademic = false } = props;
    const [successfullGraduation, setSuccessfullGraduation] = useState(null);

    // CUSTOM HOOKS
    const {
        students,
        handleCurrentPage,
        totalStudents,
        isLoading,
        currentPage,
        pageSize,
        inputText,
        getInputFilterStudents,
        handleChange,
        handleClearFilters,
    } = useGradebook(id, successfullGraduation);
    return (
        <CardFake>
            <Container>
                <FiltersWrapper>
                    <FilterSearch>
                        <FilterFor
                            handleChange={handleChange}
                            handleSearch={getInputFilterStudents}
                            filterField={inputText}
                            label="Buscar por estudiante"
                            disabled={isLoading}
                            searchButton={true}
                        />
                    </FilterSearch>
                </FiltersWrapper>
                {!students && !isLoading && (
                    <ErrorWrapper>
                        <ErrorResponse message="No se encontraron estudiantes" />
                        <ClearButton
                            onClick={handleClearFilters}
                            variant="contained"
                            startIcon={<Tune />}
                        >
                            Limpiar filtro
                        </ClearButton>
                    </ErrorWrapper>
                )}

                {students && (
                    <TableContainer>
                        <TableGradeBook
                            idA={id}
                            viewAcademic={viewAcademic}
                            students={students}
                            isLoading={isLoading}
                            setSuccessfullGraduation={setSuccessfullGraduation}
                        />
                        <PaginationWrapper>
                            <GenericPagination
                                handleCurrentPage={handleCurrentPage}
                                totalItems={totalStudents}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                loading={isLoading}
                            />
                        </PaginationWrapper>
                    </TableContainer>
                )}
            </Container>
        </CardFake>
    );
};

export default GradebookSection;

const CardFake = styled(Card)`
    grid-area: container;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
`;

const Container = styled.div`
    min-height: auto;
    width: 98%;
    margin: 0 auto;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const PaginationWrapper = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
`;

const FiltersWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    align-items: center;
    width: 100%;
    align-items: flex-end;
    margin: 2rem 0;

    @media (max-width: 943px) {
        flex-direction: column;
        height: 200px;
    }
`;

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
`;

const FilterSearch = styled.div`
    width: 100%;
    flex-basis: calc(30% - 1rem);
    @media (max-width: 1024px) {
        flex-basis: calc(55% - 1rem);
    }
`;

const ClearButton = styled(Button)`
    border-radius: 50px;
`;

