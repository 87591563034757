import styled from "styled-components";
import { useState } from "react";

// Components
import DefaultField from "../../../../components/common/DefaultField";
import CommonButton from "../../../../components/common/CommonButton";

// Formik
import { Form } from "formik";

// Redux
import { changePasswordLogged } from "../../../../redux/api/auth";

const PasswordManagement = (props) => {

    const { values, setFieldValue } = props;

    const [sending, setSending] = useState(false)

    const handleChangePassword = async () => {
        if (values.new_password_modal === values.confirm_new_password) {
            if (values.confirm_new_password.length > 7) {
                try {
                    setSending(true)
                    await changePasswordLogged(values.personal_email, values.confirm_new_password);
                    setSending(false)
                } catch (e) {
                    console.log('Error: ', e);
                    setSending(false)
                }
            } 
        } 
    }

    return (
        <PasswordManagementContainer>
            <h2>Cambiar contraseña</h2>
            <Container>
                <FormContainer>
                    <DefaultField
                        name="new_password_modal"
                        type="password"
                        label="Nueva contraseña"
                        value={values.new_password_modal}
                        setFieldValue={setFieldValue}
                    />
                    <DefaultField
                        name="confirm_new_password"
                        type="password"
                        label="Repetir nueva contraseña"
                        value={values.confirm_new_password}
                        setFieldValue={setFieldValue}
                    />
                    <CommonButton
                        variant="outlined"
                        label={
                            sending ? "Enviando..." : "Confirmar"
                        }
                        onClick={handleChangePassword}
                    />
                </FormContainer>
            </Container>
        </PasswordManagementContainer>
    )
}

export default PasswordManagement;

const PasswordManagementContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;

    h2 {
        color: #b31d15;
        font-size: 24px;
        font-weight: 700;
    }
`

const Container = styled.div`
    width: 100%;
    max-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
`

const FormContainer = styled(Form)`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8rem;
    row-gap: 1rem;

    @media (width < 768px) {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
`;