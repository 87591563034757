import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SimpleLoading from '../../components/common/SimpleLoading';

import Publicity from '../../components/templates/ondemand/Publicity';
import Register from '../../components/templates/ondemand/Register';
import { titleHTML } from '../../helpers/title.helper';

const RegisterOnDemand = () => {
    titleHTML("Registro");

    let { ondemand_id } = useParams();
    const ondemand = useSelector((state) => state.ondemand.ondemand);

    if (ondemand === null) {
        return <SimpleLoading />;
    }

    return (
        <Container>
            <Publicity />
            <Register ondemand={ondemand} />
        </Container>
    );
};

export default RegisterOnDemand;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    height: 100vh;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        div {
            height: 100%;
        }
    }
`;
