import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Components
import DocumentationTemplate from '../../../components/templates/academic/allStudents/DocumentationTemplate';
import BannerInternationalWorkshop from './workshops/BannerInternationalWorkshop';
import Finance from '../../../components/ui/organisms/academic/Finance';
import Graduation from './workshops/Graduation';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { financesActions } from '../../../redux/actions';

const Main = (props) => {
    const { handleSetStateFinance, handleSetStateDocumentation, tab } = props;

    // State
    const [wsGraduation, setwsGraduation] = useState(null);
    const [myWsGraduations, setmyWsGraduations] = useState(null);

    // REDUX
    const dispatch = useDispatch();
    const finances = useSelector((state) => state.finances.finances);
    const user = useSelector((state) => state.auth.user);

    // EFFECTS
    useEffect(() => {
        if (finances === null) {
            dispatch(financesActions.getFinancesRequest());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (myWsGraduations === null) {
            getWsGraduationById(user.partner_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myWsGraduations]);

    useEffect(() => {
        if (wsGraduation === null) {
            getWsGraduation();
        }
    }, [wsGraduation]);

    // Api Functions
    const getWsGraduationById = async (id) => {
        const result = await getWsGraduationByIdService(id);
        if (result !== false) {
            setmyWsGraduations(result);
        }
        return;
    };

    const getWsGraduation = async () => {
        const result = await getWsGradiationService();
        if (result !== false) {
            setwsGraduation(result);
        }
    };

    // funcion para comparar si una fecha ya pasó o no
    const compareDates = (date) => {
        const dateParts = date.split('-');
        const dateObject = new Date(
            dateParts[0],
            dateParts[1] - 1,
            dateParts[2]
        );
        dateObject.setHours(0, 0, 0, 0);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return dateObject < today;
    };

    const workshops = [
        {
            title: 'IA aplicada a los Negocios',
            description: '',
            country: 'Barcelona',
            date: '17 al 20 de Septiembre, 2024',
            id: 6,
            img: './assets/internationalWorkshop/innovacion.png',
            description_complete: '',
            link: 'https://cursos.aden.org/product/712?type=Workshops&name=Workshop-IA-aplicada-a-los-Negocios-Barcelona',
            untilDate: 'Viernes 30 de agosto',
            briefing: '14 de agosto',
            disabled: compareDates('2024-09-01'),
            location: 'C/ de Rosselló i Pòrcel, 7-11'
        },
        {
            title: 'Innovación, Tecnología y Tendencias',
            description: '',
            country: 'Miami',
            date: '2 al 4 de Octubre, 2024',
            id: 3,
            img: './assets/internationalWorkshop/nuevas-tecnologias.png',
            description_complete: '',
            link: 'https://cursos.aden.org/product/713?type=Workshops&name=Workshop-Innovaci%C3%B3n,-Tecnolog%C3%ADa-y-Tendencias-Miami',
            untilDate: 'Viernes 13 de Septiembre',
            briefing: '28 de agosto',
            disabled: compareDates('2024-09-13'),
            location: 'Hotel Pullman Miami Airport'
        },
    ];

    return (
        <MainContainer>
            {tab === 0 && (
                <DocumentationTemplate
                    handleSetStateDocumentation={handleSetStateDocumentation}
                />
            )}
            {tab === 1 && (
                <Container>
                    <Finance handleSetStateFinance={handleSetStateFinance} />
                </Container>
            )}
            {tab === 2 && (
                <WrapperWorkshop>
                    <WrapperCard>
                        <BannerInternationalWorkshop />
                        <Container>
                            <Graduation workshops={workshops} />
                        </Container>
                    </WrapperCard>
                </WrapperWorkshop>
            )}
        </MainContainer>
    );
};

export default Main;

const URL = process.env.REACT_APP_SISAPI + '/v1/acropolis/graduation';

const getWsGraduationByIdService = async (id) => {
    const body = {
        partner_id: id,
    };
    try {
        const response = await axios.post(
            `${URL}/workshops_graduation_by_user`,
            body
        );
        return response.data.response_data;
    } catch (error) {
        console.error(error);
        return false;
    }
};

const getWsGradiationService = async () => {
    try {
        const response = await axios.get(`${URL}/get_ws_graduation`);
        return response.data.response_data;
    } catch (error) {
        console.error(error);
        return false;
    }
};

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 5px;
    margin-top: 1rem;
`;

const Container = styled.div`
    background-color: #ffffff;
`;

const WrapperWorkshop = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 3rem;
    height: 100%;
`;

const WrapperCard = styled.div`
    width: calc(100% - 48px);
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    border-radius: 20px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 48px;
`;
