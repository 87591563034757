import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import NoResultsFound from "../NoResultsFound";

const GridContent = (props) => {
    const { contents, Card, widthCard, type, student, activeCard = 1 } = props;

    return (
        <Container>
            {contents.length === 0 ? (
                <NoResultsFound />
            ) : (
                <>
                    <Wrapper>
                        {contents.map((p) => (
                            <Card
                                key={p.id}
                                values={p}
                                data={p}
                                activeCard={activeCard}
                                type={type}
                                student={student}
                                program={p}
                                quantity={contents.length}
                            />
                        ))}
                    </Wrapper>
                </>
            )}
        </Container>
    );
};

export default GridContent;

const Container = styled.div`
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    padding: 1rem;
`;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 1rem;
    height: 500px;
    /* @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
    } */
`;
