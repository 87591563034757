import React from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '../../../common/Texts';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';

const Certification = (props) => {

    const { id, avaliable, type, name = "ADEN" } = props

    const { user } = useSelector(state => state.auth)

    const handleOpen = () => {
        if (!!avaliable) {
            window.open(`https://constancias.aden.org/?idcod=${type}-${id}-${user.repo_id}-ADEN2022/`, '_blank');
        }
    }

    return (
        <ContainerWrapper onClick={handleOpen} avaliable={avaliable}>
            <IconWrapper avaliable={avaliable}>
                <Icon icon="ph:medal-fill" width="24px" height="24px" />
            </IconWrapper>
            <DataWrapper>
                <span>
                    <Text fontSize="16px" fontWeight="bold" color={!avaliable ? '#aaa' : "#b31d15"}>Certificado {name}</Text>
                </span>
            </DataWrapper>
        </ContainerWrapper>
    )
}

export default Certification

Certification.propTypes = {
    avaliable: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['OA', 'PROGRAM'].isRequired),
};

const ContainerWrapper = styled.div`
    height: 40px;
    border-radius: 10px;
    width: 180px;
    box-shadow: 0px 2px 4px -2px rgba(24,39,75,0.12), 0px 4px 4px -2px rgba(24,39,75,0.08);
    cursor: ${p => p.avaliable ? 'pointer' : 'block'};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background-color: #f1f1f1;
    border: 1px solid ${(props) => props.avaliable ? "#b31d15" : "#f1f1f1"};

    :hover {
        box-shadow: ${(props) => props.avaliable && "none"};
        background-color: ${(props) => props.avaliable && "transparent"};
    }
`

const IconWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        font-size: 32px;
        color: ${p => p.avaliable ? "#b31d15" : "#aaa"};
    }
`

const DataWrapper = styled.div`
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
    height: 100%;
    span{
        width: 100%;
    }
`