export function getMonth(date) {
    let month = date.slice(5, 7);
    switch (month) {
        case '01':
            return 'Enero';
        case '02':
            return 'Febrero';
        case '03':
            return 'Marzo';
        case '04':
            return 'Abril';
        case '05':
            return 'Mayo';
        case '06':
            return 'Junio';
        case '07':
            return 'Julio';
        case '08':
            return 'Agosto';
        case '09':
            return 'Septiembre';
        case '10':
            return 'Octubre';
        case '11':
            return 'Noviembre';
        case '12':
            return 'Diciembre';
        default:
            break;
    }
}

export function getDay(date) {
    return date.slice(8, 10);
}

export function getYear(date) {
    return date.slice(0, 4);
}

export function getHour(date) {
    return date.slice(11, 16);
}

export function getScheduleDay(date) {
    let d = new Date(date);

    let day = '';
    let month = '';

    switch (d.getUTCDay()) {
        case 0:
            day = 'Domingo';
            break;
        case 1:
            day = 'Lunes';
            break;
        case 2:
            day = 'Martes';
            break;
        case 3:
            day = 'Miércoles';
            break;
        case 4:
            day = 'Jueves';
            break;
        case 5:
            day = 'Viernes';
            break;
        case 6:
            day = 'Sábado';
            break;
        default:
            break;
    }

    switch (d.getUTCMonth()) {
        case 0:
            month = 'Enero';
            break;
        case 1:
            month = 'Febrero';
            break;
        case 2:
            month = 'Marzo';
            break;
        case 3:
            month = 'Abril';
            break;
        case 4:
            month = 'Mayo';
            break;
        case 5:
            month = 'Junio';
            break;
        case 6:
            month = 'Julio';
            break;
        case 7:
            month = 'Agosto';
            break;
        case 8:
            month = 'Septiembre';
            break;
        case 9:
            month = 'Octubre';
            break;
        case 10:
            month = 'Noviembre';
            break;
        case 11:
            month = 'Diciembre';
            break;
        default:
            break;
    }

    return day + ', ' + date.slice(8, 10) + ' de ' + month;
}

export function parseMinutes(date) {
    let decimalTimeStr = date.toString();
    let d = new Date(0, 0);
    d.setMinutes(+decimalTimeStr * 60);

    if (d.getMinutes() === 59) {
        return new Date(d.getTime() + 1 * 60000).toTimeString().slice(0, 5);
    }

    return d.toTimeString().slice(0, 5);
}

export const diffDate = (dateInitial, dateFinish) => {
    const date_1 = new Date(dateInitial);
    const date_2 = new Date(dateFinish);

    const dayAsMilliseconds = 86400000;
    const diffInMillisenconds = date_2 - date_1;
    const diffDay = Math.round(diffInMillisenconds / dayAsMilliseconds);
    const diffHour = Math.round(diffDay / 24)
    const difMin = Math.round(diffHour / 60)

    return { diffDay, diffHour, difMin }
}