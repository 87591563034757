import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSnackbar } from "react-simple-snackbar"
import { optionsStyle } from "../../../utils/snackStyles"
import { getNewServices } from "../services/api"

const useNews = () => {
    const [news, setNews] = useState(null)
    const { activeRol } = useSelector(state => state.auth);
    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        !news && getNews()
    }, [])

    const getNews = async () => {
        try {
            const req = await getNewServices(activeRol)
            const sanitizerData = req.map(item => ({ ...item?.attributes })).reverse()
            setNews(sanitizerData)
        } catch (error) {
            openSnackbar("¡No se pudieron obtener las noticias!")
        }
    }

    return [news]

}

export default useNews