import React, { useState } from 'react'
import styled from 'styled-components'
import GridCard from '../../../common/GridCard'
import CardTask from '../../molecules/progress/CardTask'
import Filter from '../../molecules/progress/Filter'


const GridTasks = (props) => {

    const { tasks } = props

    const [tasksFilter, setTasksFilter] = useState(null)

    return (
        <Container>
            <Filter submissionsByOa={tasks} setTasksFiltered={setTasksFilter} mobile={true}/>
            <GridCard cards={tasksFilter || tasks || []} component={CardTask} width={'150px'} />
        </Container>
    )
}

export default GridTasks

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    @media (max-width: 768px) {
        margin-top: 1rem;
        gap: 2rem;
        width: 100%;
    }
`
