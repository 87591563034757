import { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Text } from "../../../../components/common/Texts";
import { AccessTime, FmdGood } from "@mui/icons-material";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { getMonthName } from "../../../../utils/dates/dates.utils";
import SkeletonLoading from "../../../../components/common/SkeletonLoading";
import { removeDuplicateObjects } from "../../../../utils/arrays";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";

const EventsDateList = (props) => {
  const { conversations, getAllSummitEvents, loading } = props;

  const SUMMIT_COUNTRIES = [
    "Panama",
    "Ecuador",
    "Honduras",
    "Argentina",
    "Colombia",
    "Costa Rica",
    "Rep. Dominicana",
    "Guatemala",
    "El Salvador",
    "Mexico",
  ];

  const { name: userCountry } = useSelector(
    (state) => state?.auth?.user?.country
  );

  const [events, setEvents] = useState(null);
  const [country, setCountry] = useState(userCountry);
  const [currentView, setCurrentView] = useState(0);

  useEffect(() => {
    !!conversations && handleConversationEvents(conversations);
  }, [conversations]);

  const filterDatesByMonth = (events, month) => {
    const filteredEvents = events.filter(
      (event) => getMonthName(event.date) === month
    );

    return filteredEvents;
  };

  const handleConversationEvents = (events) => {
    const formattedEvents = events.map((event) => {
      return {
        month: getMonthName(event.date),
        events: filterDatesByMonth([...events], getMonthName(event.date)),
      };
    });

    setEvents(removeDuplicateObjects(formattedEvents, "month"));
  };

  const handleSelectChange = (event) => {
    getAllSummitEvents(event.target.value);
    setCountry(event.target.value);
  };

  const handleToggleView = () => {
    setCurrentView(0);
    setCountry("Sin seleccionar");
    getAllSummitEvents("");
  };

  if (!events || loading) {
    return <SkeletonLoading height={420} width="550px" />;
  }

  if (events.length === 0 && currentView === 0) {
    return (
      <Wrapper>
        <Text color="#222222" fontSize="20px" fontWeight="400">
          Eventos cumbre | <b>País: {country}</b>
        </Text>
        <Body>
          <NotFoundWrapper>
            {country === userCountry && (
              <>
                <Text color="#B31D15" fontSize="20px" fontWeight="700">
                  ¡Ups! No encontramos eventos en tu país.
                </Text>
                <Text color="#000000" fontSize="16px" fontWeight="400">
                  No te preocupes, hay eventos que puedes disfrutar en otros
                  países de Latinoamérica:
                </Text>
              </>
            )}

            {country !== userCountry && (
              <>
                <Text color="#B31D15" fontSize="20px" fontWeight="700">
                  ¡Escoge el país desde el cual quieres participar de la cumbre.
                </Text>
              </>
            )}
            <FormControl>
              <InputLabel>Selecciona el país</InputLabel>
              <CountrySelect
                label="Selecciona el país"
                name="Selecciona el país"
                onChange={handleSelectChange}
              >
                {SUMMIT_COUNTRIES.map((country) => (
                  <CountryItem key={country} value={country}>
                    {country}
                  </CountryItem>
                ))}
              </CountrySelect>
            </FormControl>
          </NotFoundWrapper>
        </Body>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>
        <Text color="#222222" fontSize="20px" fontWeight="400">
          Eventos cumbre | <b>País: {country}</b>
        </Text>
        {country !== userCountry && (
          <GoBackButton onClick={handleToggleView}>
            <Icon icon="akar-icons:arrow-back" />
            Volver al selector
          </GoBackButton>
        )}
      </Header>
      <Body>
        {events?.map((event) => (
          <>
            <MonthWrapper key={event.month}>
              <Text color="#616161" fontSize="16px" fontWeight="400">
                <b>{event.month}</b>
              </Text>
            </MonthWrapper>
            <EventsWrapper>
              {event?.events?.map((event) => (
                <EventWrapper key={event?.id}>
                  <DateRounded>
                    <Text color="#ffffff" fontSize="16px" fontWeight="400">
                      {moment(event?.date).format("DD")}
                    </Text>
                  </DateRounded>
                  <EventInfo>
                    <Text color="#222222" fontSize="12px" fontWeight="500">
                      <b>
                        {event?.eventLocation.includes("Online")
                          ? "ONLINE"
                          : "ETAPA PRE-CUMBRE"}
                      </b>
                      {/* <b>ETAPA PRE-CUMBRE</b> | INSCRIPTO */}
                    </Text>
                    <Text color="#616161" fontSize="12px" fontWeight="500">
                      <b>{event?.title}</b>
                    </Text>
                    <LocationInfoWrapper>
                      <Section>
                        <AccessTime />
                        <TimeWrapper>
                          <Text
                            color="#a8a8a8"
                            fontSize="12px"
                            fontWeight="500"
                          >
                            {event?.schedule}
                          </Text>
                        </TimeWrapper>
                      </Section>
                      <Section>
                        <FmdGood />
                        <LocationTextWrapper>
                          <Text
                            color="#a8a8a8"
                            fontSize="12px"
                            fontWeight="500"
                          >
                            {event?.eventLocation}
                          </Text>
                        </LocationTextWrapper>
                      </Section>
                    </LocationInfoWrapper>
                  </EventInfo>
                </EventWrapper>
              ))}
            </EventsWrapper>
          </>
        ))}
      </Body>
    </Wrapper>
  );
};

export default EventsDateList;

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 370px;
  width: 530px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Body = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

const MonthWrapper = styled.div`
  position: relative;
  &::after {
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #616161;
    bottom: -0.6rem;
  }
`;

const EventsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
  gap: 2rem;
`;

const EventWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;

  &::after {
    position: absolute;
    content: " ";
    width: calc(100% - 3.6rem);
    height: 1px;
    margin: auto;
    right: 0;
    bottom: -11px;
    background-color: #a8a8a8;
  }
`;

const DateRounded = styled.div`
  min-width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #35d0a5;
`;

const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.45rem;
`;

const LocationInfoWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 0.4rem;

  svg {
    width: 18px;
    height: 18px;
    color: #a8a8a8;
  }
`;

const TimeWrapper = styled.div`
  min-width: 75px;
`;

const Section = styled.section`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  width: auto;
`;

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4rem;
  height: 100%;
  margin-top: 3rem;
  text-align: center;
`;

const CountrySelect = styled(Select)`
  width: 250px !important;
`;

const LocationTextWrapper = styled.div`
  width: 350px;
`;

const CountryItem = styled(MenuItem)``;

const GoBackButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  border-radius: 50px;

  svg {
    width: 22px;
    height: 20px;
    padding-bottom: 4px;
  }
`;
