import React from 'react'
import styled from 'styled-components'

const DefaultCard = (props) => {
    return (
        <CardFake {...props}/>
    )
}

export default DefaultCard

const CardFake = styled.div`
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.06);
    border-radius: 20px;
    padding: 20px;
`