import { IconButton } from '@mui/material';
import { ForumRounded } from '@mui/icons-material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { createChannel } from '../../../../helpers/createChannelChat.helper';
import {
    changeChannel,
    setParticipants
} from '../../../../redux/chat/chatActions';

const ButtonChat = (props) => {
    const { user, advisor } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    //Chat
    const chatGeneration = () => {
        const hash = createChannel(user.partner_id, advisor.partner_id);
        const contantFormated = {
            nombre: advisor.name,
            apellido: '',
            partner_id: parseInt(advisor.partner_id, 10),
            foto: advisor.image,
            contactData: advisor
        };
        dispatch(changeChannel(hash));
        dispatch(
            setParticipants([contantFormated, { ...user, nombre: user.name }])
        );
        setTimeout(() => {
            history.push('networking/chat');
        }, 200);
    };

    return (
        <IconButton onClick={() => chatGeneration()} size="large">
            <ForumRounded color='primary' />
        </IconButton>
    );
};

export default ButtonChat;