import styled from 'styled-components';
import AnnouncementCard from './AnnouncementCard';
import { useState } from 'react';
import { Skeleton } from '@mui/material';

const AnnouncementsSection = (props) => {
    const { subjectName, card, general = false, setOpenEditModal, rol } = props;
    const [openModal, setOpenModal] = useState(false);

    const today = new Date();
    const publishedDate = new Date(card?.published_date);

    if (!rol) {
        <Skeleton variant="rectangular" width={300} height={200} />;
    }
    return (
        <>
            {rol === 'docente' ? ( //el docente previsualiza todos los anuncios vigentes
                <AnnouncementCard
                    subjectName={subjectName}
                    general={general}
                    card={card}
                    type="editable"
                    rol={rol}
                    setOpenModal={setOpenModal} //modal de despliegue por visualizacion de contenido
                    setOpenEditModal={setOpenEditModal} //modal de edicion, formulario ModalCreateAnnouncement
                />
            ) : (
                today >= publishedDate && ( //el alumno visualiza todos los anuncios vigentes y publicados (SIN los que aún no se publican)
                    <AnnouncementCard
                        subjectName={subjectName}
                        general={general}
                        card={card}
                        rol={rol}
                        setOpenModal={setOpenModal} //modal de despliegue por visualizacion de contenido
                        setOpenEditModal={setOpenEditModal} //modal de edicion, formulario ModalCreateAnnouncement
                    />
                )
            )}

            {openModal && (
                <ModalContainer>
                    <ModalWindow>
                        <AnnouncementCard
                            subjectName={subjectName}
                            general={general}
                            card={card}
                            type="editable"
                            rol={rol}
                            isOpen={openModal}
                            setOpenModal={setOpenModal} //modal de despliegue por visualizacion de contenido
                            setOpenEditModal={setOpenEditModal} //modal de edicion, formulario ModalCreateAnnouncement
                        />
                        <CloseModalButton onClick={() => setOpenModal(false)}>
                            Volver
                        </CloseModalButton>
                    </ModalWindow>
                </ModalContainer>
            )}
        </>
    );
};

export default AnnouncementsSection;

const ModalContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100vw - 6rem);
    height: 100vh;
    background-color: #00000030;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
`;

const ModalWindow = styled.div`
    width: 100%;
    max-width: 70vw;
    height: fit-content;
    padding: 2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    gap: 2rem;
    border-radius: 20px;
`;

const CloseModalButton = styled.button`
    cursor: pointer;
    width: 88px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: #b31d15;
`;
