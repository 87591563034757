import React from "react";
import styled from "styled-components";
import CardNewNote from "./CardNewNote";
import useNotesCourse from "../../../../hooks/notesCourse/useNotesCourse";
import CardNoteCourse from "./CardNoteCourse";
import useGetNotesCourse from "../../../../hooks/notesCourse/useGetNotesCourse";
import SimpleLoading from "../../../../components/common/SimpleLoading";

const MainNotes = (props) => {
    const {
        currentMedia,
        currentSecondVideo,
        handleCurrentMinute,
        handleIsChangeMinute,
        valuesNotes,
        errorGetNotes,
        LoadingGetNotes,
        getNotes,
        showAside
    } = props;

    const {
        LoadingPostNote,
        errorPost,
        upDateNotes,
        createNote,
        resetUpdateNotes,
    } = useNotesCourse();

    if (showAside) {
        return (
            <>
                {LoadingGetNotes || errorGetNotes ? (
                    <SimpleLoading />
                ) : (
                    <>
                        {Array.isArray(valuesNotes) &&
                            valuesNotes.length > 0 ? (
                            <>
                                {valuesNotes.map((notes, index) => (
                                    <CardNoteCourse
                                        handleCurrentMinute={
                                            handleCurrentMinute
                                        }
                                        handleIsChangeMinute={
                                            handleIsChangeMinute
                                        }
                                        notes={notes}
                                        key={index}
                                        getNotes={getNotes}
                                        resetUpdateNotes={resetUpdateNotes}
                                        showAside={showAside}
                                    />
                                ))}
                            </>
                        ) : (
                            <NotCommentShow>
                                <p>No se encontraron apuntes.</p>
                            </NotCommentShow>
                        )}
                    </>
                )}
            </>
        )
    }

    return (
        <Container>
            <CardNewNote
                LoadingPostNote={LoadingPostNote}
                errorPost={errorPost}
                createNote={createNote}
                upDateNotes={upDateNotes}
                resetUpdateNotes={resetUpdateNotes}
                idResource={currentMedia?.id}
                currentSecondVideo={currentSecondVideo}
                getNotes={getNotes}
            />
            <>
                {LoadingGetNotes || errorGetNotes ? (
                    <SimpleLoading />
                ) : (
                    <>
                        {Array.isArray(valuesNotes) &&
                            valuesNotes.length > 0 ? (
                            <>
                                {valuesNotes.map((notes, index) => (
                                    <CardNoteCourse
                                        handleCurrentMinute={
                                            handleCurrentMinute
                                        }
                                        handleIsChangeMinute={
                                            handleIsChangeMinute
                                        }
                                        notes={notes}
                                        key={index}
                                        getNotes={getNotes}
                                        resetUpdateNotes={resetUpdateNotes}
                                    />
                                ))}
                            </>
                        ) : (
                            <NotComment>
                                <p>No se encontraron apuntes.</p>
                                <p>¡Sé el primero en dejar uno!</p>
                            </NotComment>
                        )}
                    </>
                )}
            </>
        </Container>
    );
};

export default MainNotes;

const Container = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    height: calc(100% - 200px);
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1rem;
    overflow-y: auto;
    overflow-x: hidden;
`;

const NotComment = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    p {
        font-size: 1.2rem;
    }
`;


const NotCommentShow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    p {
        font-size: 14px;
        color: var(--secondary-three);
        text-align: center;
    }
`;