import React from 'react';
import styled from 'styled-components';
import GridResource from '../../../common/GridResource';
import MicrolearningsCard from '../microlearnings/MicrolearningsCard';

const ResourcesFavs = (props) => {
    const { favs } = props;

    const mutateResource = (array) =>
        array.map((item) => ({ ...item, favorito: true }));

    return (
        <GridMain>
            <GridResource
                propsValues={mutateResource(favs || [])}
                type={'micro'}
                Card={MicrolearningsCard}
                handleOpenModal={() => {}}
            />
        </GridMain>
    );
};

export default ResourcesFavs;

const GridMain = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 5px;
`;
