import styled from "styled-components";
import parse from "html-react-parser";
import React from "react";

// Components
import { Text } from "../../../components/common/Texts";
import { Img } from "../../../components/common/Image";

// Material UI
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

// Assets
import img from "../../../assets/media/svg/exam.png";

const TaskRender = (props) => {
    const { name, file, text, isFile, isText } = props;

    const validateMimeType = (mimetype) => {
        const cases = {
            "image/bmp": "bmp",
            "application/msword": "doc",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                "doc",
            "text/htm": "htm",
            "text/html": "html",
            "image/jpg": "jpg",
            "image/jpeg": "jpeg",
            "application/pdf": "pdf",
            "image/png": "png",
            "application/vnd.ms-powerpoint": "ppt",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                "pptx",
            "image/tiff": "tiff",
            "text/plain": "txt",
            "application/vnd.ms-excel": "xls",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                "xlsx",
        };
        return cases[mimetype] || false;
    };

    const sanitizerOfficeUrl = (url) => {
        const bar = "%2F";
        const same = "%3D";
        const question = "%3F";
        const ampersand = "%26";
        const twoDots = "%3A";
        const urlNew = url
            .replace(/\//g, bar)
            .replace(/:/g, twoDots)
            .replace(/&/g, ampersand)
            .replace(/\?/g, question)
            .replace(/=/g, same);
        return urlNew;
    };

    const renderType = (isFile, isText, text, file) => {
        if (isFile) {
            // eslint-disable-next-line no-unused-vars
            const docs = [
                { uri: file?.file, fileType: validateMimeType(file?.mimetype) },
            ];
            if (file.mimetype === "application/pdf") {
                return <Iframe src={file.file} />;
            }

            if (file.mimetype === "application/octet-stream") {
                return (
                    <video width="100%" height="90%" controls autoPlay>
                        <source src={file.file} type="video/mp4" />
                        Tu navegador no soporta el elemento de video.
                    </video>
                );
            }

            if (
                file.mimetype === "image/png" ||
                file.mimetype === "image/jpeg" ||
                file.mimetype === "image/jpg"
            ) {
                return (
                    <WrapperImage>
                        <ImgFake src={file.file} />
                    </WrapperImage>
                );
            }

            if (file.mimetype === "text/plain") {
                return (
                    <iframe
                        src={file?.file}
                        width="100%"
                        height="100%"
                        title="txt"
                    />
                );
            }

            if (isText) {
                return <Scroll>{parse(text)}</Scroll>;
            }

            if (validateMimeType(file.mimetype)) {
                console.log("file.file", file.file);
                return (
                    <Iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${sanitizerOfficeUrl(
                            file.file
                        )}`}
                    />
                );
            } else {
                return (
                    <Doc>
                        <Text
                            fontSize="1.5rem"
                            fontWeight="700"
                            color="#b31d15"
                        >
                            {name}
                        </Text>
                        <Img loading="lazy" w="200px" height="auto" src={img} />
                        <Button
                            onClick={() => window.open(file.file)}
                            variant="contained"
                            color="primary"
                        >
                            Descargar tarea
                        </Button>
                    </Doc>
                );
            }
        }
    };

    return (
        <TaskRenderWrapper>
            {renderType(isFile, isText, text, file)}
        </TaskRenderWrapper>
    );
};

export default TaskRender;

const TaskRenderWrapper = styled(Card)`
    display: grid;
    grid-area: task;
    height: calc(100% - 61px);
    width: 100%;
    border-right: solid 1px #c4c4c4;
    
    @media (max-width: 1200px) {
        border: 0px;
        border-radius: 0px 0px 20px 20px;
    }    
`;

const Iframe = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
`;

const Doc = styled.div`
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
`;

const Scroll = styled.div`
    margin: 16px;
    overflow-y: auto;
`;

const WrapperImage = styled.div`
    display: flex;
    padding: 1rem;
    width: calc(100% - 2rem);
    justify-content: center;
    align-items: center;
    height: calc(100% - 2rem);
`;

const ImgFake = styled.img`
    width: 100%;
    object-fit: cover;
`;
