import React, { useState } from 'react';
import styled from 'styled-components';
import TabsComponent from '../../components/common/TabsComponent';
// import OutstandingStudents from '../../components/templates/students/OutstandingStudents';
import StudentsNow from '../../components/templates/students/StudentsNow';
import { titleHTML } from '../../helpers/title.helper';

const StudentsPage = () => {
    titleHTML("Alumnos");
    
    const tabs = [
        { label: 'Alumnos actuales', id: 0 },
        { label: 'Alumnos destacados', id: 2 },
        { label: 'Alumnos pasados', id: 1 },
    ];

    const [tab, setTab] = useState(0);

    function handleTab(e, value) {
        setTab(value);
    }

    return (
        <StudentsWrapper>
            <TabsWrapper>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            </TabsWrapper>
            <ContentWrapper>{
                tab === 0 ? <StudentsNow />
                // : tab === 2 ? <OutstandingStudents />
                : null            
            }
            </ContentWrapper>
        </StudentsWrapper>
    );
};

export default StudentsPage;

const StudentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const TabsWrapper = styled.div`
    width: calc(100% - 6rem - 70px);
    position: fixed;
    left: 70px;
    top: 70px;
    z-index: 10;
    border-top: 1px solid #f7f7f7;
    padding: 0 3rem;
    background-color: #ffffff;
    @media (max-width: 768px) {
        left: 0;
        width: calc(100% - 6rem);
    }
`;

const ContentWrapper = styled.div`
    width: 100%;
    margin-top: 50px;
`;
