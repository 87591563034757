import { useEffect, useState } from "react";
import { getStudentsAAServicesSis } from "../../redux/api/students";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../utils/snackStyles";

function useCrmStudents() {
  const [students, setStudents] = useState(null);
  const [initialStudents, setInitialStudents] = useState(null);
  const [loading, setLoading] = useState(true);

  const [openSnackbar] = useSnackbar(optionsStyle);

  // EFFECTS
  useEffect(() => {
    !initialStudents && getCrmStudents();
  }, [initialStudents, students]);

  // FUNCTIONS
  const getCrmStudents = async () => {
    const request = await getStudentsAAServicesSis();
    if (request.error) {
      openSnackbar("¡No se pudieron cargar los estudiantes!");
      setInitialStudents(false);
      setStudents(false);
      setLoading(true);
      return;
    }
    console.log("request", request);
    setInitialStudents(request.response_data)
    // setStudents(request.response_data)
    setLoading(false)
  };

  const handleStudents = (newStudents) => {
    setStudents(newStudents);
  };

  return {
    initialStudents,
    students,
    loading,
    handleStudents,
  };
}

export default useCrmStudents;
