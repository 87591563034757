import * as encrypt from 'nodejs-base64-encode';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import React from 'react';

// Components
import UserData from '../../../molecules/teacher/UserData';
import GenericChips from '../../../../common/GenericChip';
import { Text } from '../../../../common/Texts';

// Material UI
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';

// Helpers
import { diffDate } from '../../../../../helpers/getDate.helper';

const Task = (props) => {
    const { values } = props;

    const dateSubmission = new Date(values.submission.create_date);

    const { diffDay } = diffDate(dateSubmission, new Date());

    const data = {
        id_task: values?.submission?.id || 0,
        id_assignment: values?.assignment?.id || 0,
    };
    const hash = encrypt.encode(JSON.stringify(data), 'base64');

    const convertToLocalTime = (date, timezone) => {
        // Convierte la fecha y hora del evento a la zona horaria especificada
        const eventTime = moment.tz(date, timezone);

        // Convierte la hora del evento a la hora local
        const localTime = eventTime.clone().tz(moment.tz.guess());

        return localTime;
    };

    const formatDate = convertToLocalTime(values.submission.create_date, "UTC");

    const localTimeFormatted = formatDate.format('YYYY-MM-DD HH:mm:ss');

    return (
        <TaskWrapper delay={diffDay} component={Link} to={`/profesor/calificador/${hash}`}>
            <Header>
                <UserData
                    img={values?.student?.image_url}
                    name={values?.student?.name}
                />
                <Gap>
                    <GenericChips
                        textColor={'black'}
                        color={
                            diffDay <= 2
                                ? '#c7e89b'
                                : diffDay > 2 && diffDay <= 5
                                    ? '#f5f3a6'
                                    : '#edc1b4'
                        }
                        title={localTimeFormatted}
                        radius="5px"
                        fontSize=".8rem"
                        padding="2px 4px"
                    />
                </Gap>
            </Header>
            <BodyWrapper>
                <Body>
                    <Points
                        type={true}
                    >
                        Actividad: {values?.activity?.name}
                    </Points>
                    <IconButtonFake variant="contained" color="primary">
                        <Link to={`/profesor/calificador/${hash}`}>
                            <RemoveRedEyeIcon htmlColor="#bfbfbf" />
                        </Link>
                    </IconButtonFake>
                </Body>
                <Text color="#777777" fontWeight="600" fontSize=".8rem">
                    {values?.oa?.name}
                </Text>
            </BodyWrapper>
            <Footer>
                <Text color="#777777" fontWeight="600" fontSize=".8rem">
                    {values?.assignment?.name || 'Asignatura indefinida'}
                </Text>
            </Footer>
        </TaskWrapper>
    );
};

export default Task;

const TaskWrapper = styled(Card)`
    display: inline-block;
    width: calc(100% - 20px);
    max-height: 132px;
    margin: 10px;
    height: 129px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 2px rgba(222, 222, 222, 0.75);
    -webkit-box-shadow: 0px 0px 6px 2px rgba(222, 222, 222, 0.75);
    -moz-box-shadow: 0px 0px 6px 2px rgba(222, 222, 222, 0.75);
    background-color: ${(p) =>
        p.delay <= 2
            ? '#e9f7e4'
            : p.delay > 2 && p.delay <= 5
                ? '#f9fae8'
                : '#f7e1e1'};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    padding-bottom: 5px;
`;
const BodyWrapper = styled.a`
    display: flex;
    flex-direction: column;
    padding: 0 15px;
`;
const Body = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
`;
const Footer = styled.div`
    height: 30px;
    padding: 0px 15px;
    margin-top: 14px;
    display: flex;
    align-items: center;
`;

const IconButtonFake = styled(IconButton)`
    padding: 7px;
    width: 40px;
    height: 40px;
    z-index: 0;
    span {
        a {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

const Points = styled(Text)`
    width: calc(100% - 60px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${(p) => (p.type ? '#222222' : 'black')};
    font-weight: ${(p) => (p.type ? 'bold' : '400')};
    cursor: pointer;
`;

const Gap = styled.div`
    display: flex;
    gap: 6px;
`;
