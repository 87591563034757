import styled, { keyframes } from "styled-components";

// Components
import { Icon } from "@iconify/react/dist/iconify.js";

const LoaderQuestions = (props) => {

    const { type } = props;

    return (
        <LoadingContainer>
            <DotsContainer>
                <Dot delay="0s" />
                <Dot delay="0.495s" />
                <Dot delay="0.99s" />
            </DotsContainer>
            <LoadingText>
                <Icon icon="mdi:sparkles" width="24px" height="24px" />
                {
                    type === "resource"
                        ? <h2>Generando preguntas sobre el recurso</h2>
                        : <h2>Generando preguntas sobre el módulo</h2>
                }

            </LoadingText>
        </LoadingContainer>
    )
}

export default LoaderQuestions;

const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const bounce = keyframes`
    0% {
        transform: translateY(0);
        background-color: #B31D15;
    }
    15% {
        transform: translateY(-10px);
    }
    33% {
        transform: translateY(0);
        background-color: #B31D15;
    }
    34% {
        background-color: #DCDCDC;
    }
    100% {
        transform: translateY(0);
        background-color: #DCDCDC;
    }
`;

const DotsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
    height: 50px;
`;

const Dot = styled.div`
    width: 8px;
    height: 8px;
    margin: 0 3px;
    border-radius: 50%;
    animation: ${bounce} 1.5s infinite;
    animation-delay: ${({ delay }) => delay};
    background-color: #DCDCDC;
`;

const LoadingText = styled.div`
    display: flex;
    gap: 1rem;
    color: #B31D15;

    h2 {
        font-size: 20px;
        font-weight: 600;
    }
`