import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { coursesActions } from '../actions';
import {
    getCourses,
    getStudentsByCourse,
    getTeachersByCourse,
    enrollCourseById
} from '../api/courses';

function* coursesRequest(action) {
    try {
        const res = yield call(getCourses, action.payload);
        yield put(coursesActions.getCoursesSuccess(res.data.response_data));
    } catch (error) {
        yield put(coursesActions.getCoursesFail(error));
    }
}

function* moreCoursesRequest(action) {
    try {
        const res = yield call(getCourses, action.payload);
        yield put(coursesActions.getMoreCoursesSuccess(res.data.response_data));
    } catch (error) {
        yield put(coursesActions.getMoreCoursesFail(error));
    }
}

function* studentsRequest(action) {
    try {
        const res = yield call(getStudentsByCourse, action.payload);
        yield put(coursesActions.getStudentsSuccess(res.data.response_data));
    } catch (error) {
        yield put(coursesActions.getStudentsFail(error));
    }
}

function* teachersRequest(action) {
    try {
        const res = yield call(getTeachersByCourse, action.payload);
        yield put(coursesActions.getTeachersSuccess(res.data.response_data));
    } catch (error) {
        yield put(coursesActions.getTeachersFail(error));
    }
}

function* enrollCourseRequest(action) {
    try {
        yield call(enrollCourseById, action.payload);
        yield put(coursesActions.enrollCourseSuccess(action.payload.curso_id));
    } catch (error) {
        yield put(coursesActions.enrollCourseFail(error));
    }
}

function* coursesWatcher() {
    yield takeEvery(types.GET_COURSES_REQUEST, coursesRequest);
    yield takeEvery(types.GET_MORECOURSES_REQUEST, moreCoursesRequest);
    yield takeEvery(types.GET_STUDENTS_REQUEST, studentsRequest);
    yield takeEvery(types.GET_TEACHERS_REQUEST, teachersRequest);
    yield takeEvery(types.ENROLL_COURSE_REQUEST, enrollCourseRequest);
}

export { coursesWatcher };
