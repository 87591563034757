import axios from "axios";

export async function getUserSummitValidation(eventId, partnerId, email) {
  const URL = `${process.env.REACT_APP_SISAPI}/v2/acropolis/events/validate_event_inscription?event_id=${eventId}&email=${email}&partner_id=${partnerId}`;

  try {
    const response = await fetch(URL, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_SISAPI_AUTH}`,
      },
    });
    return response.json();
  } catch (error) {
    return new Error(error);
  }
}

export async function getUserSummitInscription(eventId) {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/events/check-registration/${eventId}`;

  try {
    const request = axios.get(URL);
    return request;
  } catch (error) {
    return new Error(error);
  }
}

export async function getSummitBanner() {
  const URL = `${process.env.REACT_APP_CMS_ADENAPI}/cumbre-banners?populate=*`;

  try {
    const request = await fetch(URL, {
      method: "GET",
      cache: "no-cache",
    });
    return request.json();
  } catch (error) {
    console.log("error", error);
  }
}

export async function getSummitEvents(country) {
  const URL = `${process.env.REACT_APP_CMS_ADENAPI}/cumbre-element?populate[Eventos][filters][country][$eq]=${country}`;

  try {
    const request = await fetch(URL, {
      method: "GET",
      cache: "no-cache",
    });
    return request.json();
  } catch (error) {
    console.log("error", error);
  }
}

export async function getSummitOA() {
  const URL = `${process.env.REACT_APP_CMS_ADENAPI}/cumbre-element?populate=ObjetosDeAprendizaje`;

  try {
    const request = await fetch(URL, {
      method: "GET",
      cache: "no-cache",
    });
    return request.json();
  } catch (error) {
    console.log("error", error);
  }
}
