import { Card } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Span, Text } from '../../../common/Texts';

const CardPay = (props) => {
    const {
        color = 'black',
        backgroundColor = 'white',
        icon: Icon,
        title = 'Titulo',
        data = 'Value',
        money = false
    } = props;

    return (
        <CardPayWrapper color={color} backgroundColor={backgroundColor}>
            <Text color="#616161" fontWeight="700" fontSize="16px">
                {!!money && (
                    <Span color="#616161" fontWeight="700" fontSize="16px">
                        {money}{' '}
                    </Span>
                )}
                {data}
            </Text>
            <Text fontSize="14px" color="#616161" fontWeight="400">
                {title}
            </Text>
            <span></span>
            <IconWrapper>
                <Icon />
            </IconWrapper>

        </CardPayWrapper>
    );
};

export default CardPay;

const CardPayWrapper = styled(Card)`
    position: relative;
    width: fit-content;
    height: 58px;
    border-radius: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
    border: 1px solid ${(props) => props.color};
    background-color: ${(props) => props.backgroundColor};

    svg {
        width: 42px;
        height: 42px;
        color: ${(props) => props.color};
    }

    // Es para poder usar absolute en el Icon sin romper el flex
    span {
        width: 42px;

        @media (width < 768px) {
            width: 0;
        }
    }

    @media (width < 768px) {
        width: 90%;
        justify-content: center;
    }
`;


const IconWrapper = styled.div`
    position: absolute;
    right: 10px;
`