import React from "react";
import styled from "styled-components";
import { Text } from "../../../common/Texts";
import NoResultsFound from "../../../common/NoResultsFound";
import Document from "../../../ui/molecules/academic/Document";
import SkeletonTabsAcordeonAA from "../../../common/teacher-academic/SkeletonTabsAcordeonAA";

const DocumentationTab = (props) => {
    const { docsStudent } = props;

    //return
    if (docsStudent === null) {
        return (
            <Page>
                <SkeletonTabsAcordeonAA />
            </Page>
        );
    } else {
        // setDocumentCharger(true);
        if (docsStudent.length === 0 || docsStudent === false) {
            return (
                <Page>
                    <NoResultsFound message="¡Ups! No se encontró ninguna documentación." />
                </Page>
            );
        }
    }

    return (
        <Container>
            {docsStudent.length === 0 ? (
                <Center>
                    <Text fontWeight="bold" fontSize={"1.1rem"} color="#777777">
                        No requiere ninguna documentación
                    </Text>
                </Center>
            ) : (
                <GridDocument>
                    {docsStudent.map((req, index) => {
                        return (
                            <Document
                                key={index}
                                data={{ ...req, id: req.id_documentacion }}
                            />
                        );
                    })}
                </GridDocument>
            )}
        </Container>
    );
};

export default DocumentationTab;

const Container = styled.div`
    background: #ffffff;
    border-radius: 20px;
`;

const Center = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const GridDocument = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Page = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
