import { createContext, useEffect, useState } from 'react';
import { getSubjectId } from '../services/api';

const Context = createContext();

export function CourseSubjectContextProvider({ children, partnerId, OAId}) {

    const [subjectId, setSubjectId] = useState(null);

    useEffect(() => {
        getSubjectIdByPartnerIdAndOAId();
    })

    // Pedir de modificar este EP para que traiga el nombre además del ID
    const getSubjectIdByPartnerIdAndOAId = async () => {

        const res = await getSubjectId(partnerId, OAId);
        setSubjectId(res?.subject_id)
    }

    const contextValues = {
        subjectId: subjectId,
        OAId: OAId,
        partnerId: partnerId
    }

    return (
        <Context.Provider value={contextValues}>
            {children}
        </Context.Provider>
    );
}

export default Context;