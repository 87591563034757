import React, { useState } from "react";
import styled from "styled-components";

import { LocationOnRounded } from "@mui/icons-material";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import { Button } from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createChannel } from "../../../helpers/createChannelChat.helper";
import {
  changeChannel,
  setParticipants,
} from "../../../redux/chat/chatActions";
import { useEffect } from "react";
import { postFav, postTakeOutFav } from "../../../redux/api/utils";
import StarIcon from "@mui/icons-material/Star";

const PublicHeaderInfo = (props) => {
  const {
    foto,
    name,
    city,
    country,
    biography,
    street,
    zip,
    id,
    repo_id,
    value = { favorito: false },
  } = props;

  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  const [fav, setFav] = useState(false);

  useEffect(() => {
    setFav(value.favorito);
  }, [value.favorito]);

  const chatGeneration = () => {
    const hash = createChannel(user.partner_id, id);
    const contantFormated = {
      nombre: name,
      apellido: "",
      partner_id: parseInt(id, 10),
      foto: foto,
    };
    dispatch(changeChannel(hash));
    dispatch(
      setParticipants([contantFormated, { ...user, nombre: user.name }])
    );

    const uri = history.location.pathname;
    const newUri = uri.split("/");
    if (newUri[2] !== undefined) {
      if (newUri[2] == "escenario") {
        history.push("../../networking/chat");
      }
    }
    setTimeout(() => {
      history.push("/networking/chat");
    }, 200);
  };

  const locationData = (street, city, country, zip) => {
    if (!!street && !!city && !!country && !!zip) {
      return (
        <LocationWrapper>
          <LocationOnRounded fontSize="small" />
          <Location>
            {street + ", " + city + ", " + country + "(CP " + zip + ")"}
          </Location>
        </LocationWrapper>
      );
    } else {
      return (
        <LocationWrapper>
          <LocationOffIcon fontSize="small" />
          <Location>No está completa toda la dirección</Location>
        </LocationWrapper>
      );
    }
  };

  // FUNCIONES QUE CAMBIAN EL ESTADO DE FAVORITO
  const handleFav = () => (!fav ? setFavorite() : setTakeOut());

  const setFavorite = async () => {
    const req = await postFav({
      resource: repo_id,
      repo_id: user.repo_id,
      // recurso | workshop | contacto
      type: "contacto",
    });
    if (!req.error) {
      setFav(true);
    }
  };

  const setTakeOut = async () => {
    const req = await postTakeOutFav({
      resource: repo_id,
      repo_id: user.repo_id,
      // recurso | workshop | contacto
      type: "contacto",
    });
    if (!req.error) setFav(false);
  };

  return (
    <PublicHeaderInfoContainer>
      <PhotoWrapper>
        <Photo src={foto} alt="user-photo" />
      </PhotoWrapper>
      <InfoWrapper>
        <Name>{name}</Name>
        {locationData(street, city, country, zip)}
        <Biography>{biography}</Biography>
      </InfoWrapper>

      <ButtonFake
        disabled={user.partner_id === id ? true : false}
        startIcon={<ForumIcon />}
        color="primary"
        variant="contained"
        onClick={chatGeneration}
      >
        Chatear
      </ButtonFake>
      {id && (
        <CustomButton
          size="small"
          onClick={handleFav}
          variant={"outlined"}
          disabled={user.partner_id === id ? true : false}
        >
          {/* <StarIcon style={{ marginRight: "1rem" }} />{" "} */}
          {!fav ? "Contacto favorito" : "Quitar contacto favorito"}
        </CustomButton>
      )}
    </PublicHeaderInfoContainer>
  );
};

export default PublicHeaderInfo;

const PublicHeaderInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 6fr 1fr;
  grid-gap: 2rem;
  padding: 2rem;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Photo = styled.img`
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  max-width: 160px;
  max-height: 160px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const Name = styled.h1`
  font-size: 1.75rem;
  font-weight: 900;
`;

const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  color: #a3a3a3;
`;

const Location = styled.span`
  font-size: 1rem;
  font-weight: 700;
`;

const Biography = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #a3a3a3;
`;

const ButtonFake = styled(Button)`
  max-height: 35px;
  margin: auto 0;
  display: flex;
`;

const CustomButton = styled(Button)`
  font-weight: bold;
  border-color: #b31d15;
  color: #b31d15;
  width: fit-content;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
