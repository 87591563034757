import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SimpleLoading from '../../../common/SimpleLoading';
import Body from '../../molecules/chat/Body';
import FooterBody from '../../molecules/chat/FooterBody';
import HeaderBody from '../../molecules/chat/HeaderBody';

const BodyChat = (props) => {
    const {
        userLocal,
        users,
        messages,
        createMessage,
        createMessageClick,
        newMessage,
        sendMessage,
        changeStatusChat,
        aside,
        loading,
    } = props;

    // debugger
    const hash = useSelector((state) => state.chat.channel);
    const userLogin = useSelector((state) => state.auth.user);

    const [allMessages, setAllMessages] = useState(false);
    const [statusUser, setStatusUser] = useState(false);

    useEffect(() => {
        setAllMessages((state) => [...messages]);
    }, [messages]);

    useEffect(() => {
        if (!!users) {
            const isOnline = formatStatusUsers(userLocal, users);
            setStatusUser(isOnline);
        }
    }, [users]);

    useEffect(() => {
        setAllMessages(messages);
    }, [hash]);

    useEffect(() => {
        if (newMessage.sala === hash) {
            setAllMessages((state) => [...state, newMessage]);
        }
    }, [newMessage]);

    useEffect(() => {
        if (sendMessage.sala == hash) {
            setAllMessages((state) => [...state, sendMessage]);
        }
    }, [sendMessage]);

    if (loading) {
        return (
            <Content>
                <SimpleLoading />
            </Content>
        );
    }

    return (
        <GridLayout aside={aside}>
            <HeaderBody
                changeStatusChat={changeStatusChat}
                statusChat={users}
                statusUser={statusUser}
            />
            <Body allMessages={allMessages} userLogin={userLogin}></Body>
            <FooterBody
                createMessageClick={createMessageClick}
                statusChat={users}
                createMessage={createMessage}
            />
        </GridLayout>
    );
};

export default BodyChat;

const GridLayout = styled.div`
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    position: relative;
    border-radius: 20px;

    box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.25),
        0px 12px 24px -15px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        display: ${(p) => (p.aside ? 'none' : 'flex')};
    }
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const formatStatusUsers = (userLocal, users) => {
    let connected = users.usuarios.filter(
        (user) => user.partner_id != userLocal.partner_id
    );
    let disconected = users.desconectados.filter(
        (user) => user.partner_id != userLocal.partner_id
    );
    let participants = users.participantes.filter(
        (user) => user.partner_id != userLocal.partner_id
    );

    if (connected.length === participants.length) {
        return {
            status: true,
            name: `${participants[0]?.nombre}`,
            image: participants[0]?.foto,
            partner_id: participants[0]?.partner_id,
        };
    } else {
        return {
            status: false,
            name: `${participants[0].nombre}`,
            image: participants[0].foto,
            partner_id: participants[0].partner_id,
        };
    }
};
