import React from 'react';
import styled from 'styled-components';

import Text from '../../../common/Text';
import { IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

const HeaderModalEditProfile = (props) => {
    const { closeModal } = props;

    // RETURN
    return (
        <Container>
            <Text fontSize="1.25rem" fontWeight="700">
                Editar perfil
            </Text>
            <IconButton size="small" onClick={closeModal}>
                <CloseRounded color="secondary" fontSize="small" />
            </IconButton>
        </Container>
    );
};

export default HeaderModalEditProfile;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    color: #b7b7b7;
    border-bottom: 1px solid #eeeeee;

    @media (max-width: 768px) {
        background-color: #b31d15;
        color: #ffffff;

        svg {
            color: #ffffff;
        }
    }
`;
