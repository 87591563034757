import { Button, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCountries } from "../../../../redux/api/titulation/titulation";
import SimpleLoading from "../../../common/SimpleLoading";

const FormAddress = (props) => {
  const {
    values,
    setFieldValue,
    setAddressComplete,
    addressComplete,
    setData,
    data,
    loading,
  } = props;

  // STATES
  const [fieldsComplete, setFieldsComplete] = useState(false);
  const [countries, setCountries] = useState([]);

  // TRAER SEDES
  const getCountry = async () => {
    try {
      const response = await getCountries();
      setCountries(response);
    } catch (error) {
      return { error };
    }
  };

  // EFFECTS
  useEffect(() => {
    const handleValues = (value) => {
      if (countries) {
        countries.forEach((country) => {
          if (country.id === value) {
            setAddressComplete(`
            ${values.address}, ${values.city}, ${country.name}, ${values.zip}. 
          `);
          }
        });
      }
    };

    if (!values.country || !values.city || !values.zip || !values.address) {
      setFieldsComplete(false);
    } else {
      handleValues(values.country);
      setFieldsComplete(true);
    }
  }, [values]);

  useEffect(() => {
    if (countries.length === 0) {
      getCountry();
    }
  }, []);

  return (
    <Wrapper>
      <Header>
        <h2>Confirma tu domicilio</h2>
        <p>
          Una vez confirmado no podrás hacer modificaciones, ¡asegúrate de
          ingresar los datos correctos!
        </p>
      </Header>
      <Fields>
        <TextField
          id="outlined-select-currency"
          select
          label="País"
          name="country"
          required
          value={values.country}
          onChange={(e) => {
            setFieldValue("country", e.target.value);
            setData({ ...data, id_pais: e.target.value });
          }}
        >
          {countries.map((country) => (
            <MenuItem value={country.id}>{country.name}</MenuItem>
          ))}
        </TextField>
        <TextField
          id="standard-basic"
          label="Ciudad"
          variant="standard"
          name="city"
          required
          value={values.city}
          onChange={(e) => {
            setFieldValue("city", e.target.value);
            setData({ ...data, ciudad: e.target.value });
          }}
        />
        <TextField
          id="standard-basic"
          label="Código Postal"
          variant="standard"
          name="zip"
          required
          value={values.zip}
          onChange={(e) => {
            setFieldValue("zip", e.target.value);
            setData({
              ...data,
              cod_postal: e.target.value,
              destino: "domicilio",
            });
          }}
        />
        <TextFieldFake
          id="standard-basic"
          label="Dirección"
          variant="standard"
          name="address"
          required
          value={values.address}
          onChange={(e) => {
            setFieldValue("address", e.target.value);
            setData({ ...data, direccion: e.target.value });
          }}
        />
      </Fields>
      {fieldsComplete && (
        <Resume>
          <p>
            Tu título será enviado a <span>{addressComplete}</span>
          </p>
        </Resume>
      )}
      {loading ? (
        <SimpleLoading padding="2rem" />
      ) : (
        <ButtonFake
          color="primary"
          disabled={fieldsComplete ? false : true}
          variant="contained"
          type="submit"
        >
          Confirmar
        </ButtonFake>
      )}
    </Wrapper>
  );
};

export default FormAddress;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  margin-top: 2rem;
  h2 {
    color: #616161;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  p {
    color: #a8a8a8;
  }
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
`;
const TextFieldFake = styled(TextField)`
  grid-row: 2 / 2;
  grid-column: 1 / 4;
`;

const Resume = styled.div`
  border: 1px solid #1e8065;
  border-radius: 10px;
  width: 100%;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f7fdfb;

  p {
    color: #1e8065;
    font-size: 1rem;
    margin: 1rem;
  }
  span {
    color: #1e8065;
    font-size: 1rem;
    font-weight: bold;
  }
`;

const ButtonFake = styled(Button)`
  margin: 0 auto;
`;
