import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getPrograms } from '../api/programs';
import { programsActions } from '../actions';

function* programsRequest() {
    try {
        const res = yield call(getPrograms);
        yield put(programsActions.getProgramsSuccess(res.data.response_data));
    } catch (error) {
        yield put(programsActions.getProgramsFail(error));
    }
}

function* programsWatcher() {
    yield takeEvery(types.GET_PROGRAMS_REQUEST, programsRequest);
}

export { programsWatcher };
