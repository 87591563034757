import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Text } from "../../../../common/Texts";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Img } from "../../../../common/Image";
import { useShow } from "../../../../../hooks/useShow";
import ProfileSummary from "../../../../ui/organisms/profile-summary/ProfileSummary";

function CircularProgressWithLabel(props) {
    const customStyle = useProgressStyles();
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                className={customStyle.bottom}
                size={70}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                className={customStyle.top}
                size={70}
                variant="determinate"
                {...props}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Text
                    color="#3BD39C"
                    fontWeight="700"
                    fontSize="1rem"
                >{`${Math.round(props.value)}%`}</Text>
            </Box>
        </Box>
    );
}
CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const useProgressStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    bottom: {
        color: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
    },
    top: {
        color: "#3BD39C",
        animationDuration: "550ms",
        position: "absolute",
        left: 0,
    },
    circle: {
        strokeLinecap: "round",
    },
}));

const UsersCard = (props) => {
    const { user } = props;
    const { foto, name, status, country, progress = 0, profession } = user;
    const [show, handleShow, handleClose] = useShow(false);

    const { activeRol } = useSelector(state => state.auth)
    const history = useHistory();

    const [data, setData] = useState([]);
    const [rol, setRol] = useState("");
   
    useEffect(() => {
        if (!!data) {
            if (!!data.sis_id) {
                setRol("Alumno");
            } else {
                setRol("Invitado");
            }
        }
    }, [data]);

      //FUNCTIONS
      const getData = async () => {
        const URL =
            process.env.REACT_APP_SISAPI +
            `/v1/acropolis/users/profile-summary/${user.partner_id || 0}`;
        try {
            const res = await axios.get(URL);
            const data = res.data;
            const postData = data.response_data;
            setData(postData);
        } catch (error) {
            return {
                error,
            };
        }
    };
    
    const handleShowProfile = () => {
        getData();
        handleShow();
    };
    
    const to360Student = async () => {
        history.push(`/profesor/alumno/${user?.partner_id}`)
      }

    return (
        <>
            <WrapperCard
                //onClick={handleShowProfile}
                onClick={ activeRol === 'docente'
                ? to360Student
                : activeRol === 'academico' && handleShowProfile
              }
            >
                <StatusFake status={status}>{status}</StatusFake>
                <Container>
                    <Photo>
                        <Img h="64px" w="64px" src={foto} />
                    </Photo>
                    <Info>
                        <h2>{name}</h2>
                        <span>{profession[1] || "Sin profesión"}</span>
                        <div>
                            <LocationOnIcon />
                            <p>{country[1] || "Sin país"}</p>
                        </div>
                    </Info>
                    <Progress>
                        <CircularProgressWithLabel value={progress} />
                    </Progress>
                </Container>
                {/* SUMMARY*/}
            </WrapperCard>
            <ProfileSummary
                open={show}
                handleClose={handleClose}
                userRol={rol}
                contact={data}
                maxWidth="md"
                showChatButton={true}
            />
        </>
    );
};

export default UsersCard;

const WrapperCard = styled(Card)`
    border-radius: 20px;
    width: 100%;
    max-width: 380px;
    height: 120px;
    display: flex;
    cursor: pointer;
    align-items: center;
    position: relative;
    /* overflow: visible; */
    justify-self: center;
`;
const StatusFake = styled.div`
    border-radius: 5px;
    padding: 4px 10px;
    height: 22px;
    color: ${(p) =>
        p.status === "cursando"
            ? "#5e80db"
            : p.status === "preinscripto"
            ? "#C29F43"
            : p.status === "egresado"
            ? "#1E8065"
            : p.status === "baja"
            ? "#FC2958"
            : ""};
    background-color: ${(p) =>
        p.status === "cursando"
            ? "#e6ebf9"
            : p.status === "preinscripto"
            ? "#f9f5ec"
            : p.status === "egresado"
            ? "#eafaf6"
            : p.status === "baja"
            ? "#ffe6eb"
            : ""};
    position: absolute;
    width: fit-content;
    right: 28px;
    top: -9.48%;
    bottom: 90.52%;
`;
const Container = styled.div`
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
`;
const Photo = styled.div`
    height: 69px;
    width: 69px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        border-radius: 50%;
    }
    @media (max-width: 800px) {
        height: 50px;
        width: 50px;
        /* flex-basis: calc(30% - 1rem); */
    }
`;
const Info = styled.div`
    flex-basis: calc(70% - 1rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.15rem;
    overflow: hidden;
    h2 {
        color: #222222;
        font-size: 1rem;
    }
    span {
        color: #616161;
        font-size: 0.9rem;
    }

    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #a8a8a8;
        font-size: 0.8rem;

        svg {
            font-size: 1rem;
        }
    }
`;
const Progress = styled.div``;
