import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LinearProgressBar from '../../../../../common/LinearProgressBar';

const StatisticsSummary = (props) => {
    const { review } = props;

    const RatingFunction = (review, value) => {
        const [starsGeneric, setStarsGeneric] = useState(null);

        useEffect(() => {
            const FindStars = (review) => {
                let starGeneric = 0;
                review.map((review) => {
                    if (review.rating == value) {
                        starGeneric = starGeneric + 1;
                    }
                });
                setStarsGeneric(starGeneric);
            };
            FindStars(review);
        }, []);
        let total = String((starsGeneric * 100) / review.length);

        if (starsGeneric === null) {
            return null;
        }
        return total;
    };

    let totalFive = RatingFunction(review, 5);
    let totalFour = RatingFunction(review, 4);
    let totalThree = RatingFunction(review, 3);
    let totalTwo = RatingFunction(review, 2);
    let totalOne = RatingFunction(review, 1);

    if (totalFive === null) {
        return null;
    }
    if (totalFour === null) {
        return null;
    }
    if (totalThree === null) {
        return null;
    }
    if (totalTwo === null) {
        return null;
    }
    if (totalOne === null) {
        return null;
    }

    return (
        <Container>
            <LinearProgressBar stars="5" value={totalFive} />
            <LinearProgressBar stars="4" value={totalFour} />
            <LinearProgressBar stars="3" value={totalThree} />
            <LinearProgressBar stars="2" value={totalTwo} />
            <LinearProgressBar stars="1" value={totalOne} />
        </Container>
    );
};
export default StatisticsSummary;

const Container = styled.div`
    flex-basis: calc(80% - 0.5rem);
    width: 100%;
`;
