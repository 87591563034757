import React, { useState } from 'react';
import styled from 'styled-components';

import Popover from '@mui/material/Popover';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import { useSelector } from 'react-redux';

const MenuPopover = (props) => {
    const { changeStatusChat, statusChat } = props;

    const [chat, setChat] = useState(statusChat);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = useSelector((state) => state.auth.user);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleStatusChat = () => {
        handleClose();
        if (statusChat.estado.estado) {
            changeStatusChat(false);
        } else {
            changeStatusChat(true);
        }
    };

    return (
        <div>
            <div aria-describedby={id} onClick={handleClick}>
                <MoreVertSharpIcon htmlColor="#bfbfbf" />
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Menu>
                    {user.partner_id ==
                        parseInt(statusChat.estado.responsableBloqueo, 10) ||
                    statusChat.estado.responsableBloqueo == '' ? (
                        statusChat.estado.estado ? (
                            <Option onClick={() => handleStatusChat()}>
                                Bloquear chat
                            </Option>
                        ) : (
                            <Option onClick={() => handleStatusChat()}>
                                Desbloquear chat
                            </Option>
                        )
                    ) : (
                        <Option>Has sido bloqueado</Option>
                    )}
                </Menu>
            </Popover>
        </div>
    );
};

export default MenuPopover;

const Menu = styled.div`
    padding: 0.1rem 0rem;
    width: 180px;
`;

const Option = styled.div`
    width: 100%;
    height: 2.2rem;
    padding: 0.3rem;
    padding-left: 0.5rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.8rem;
    color: #757575;
    :hover {
        background-color: #ededed;
        color: #b31d15;
    }
`;
