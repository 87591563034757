import React from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import Text from '../../../common/Text';

const CardUserPosition = () => {
    // REDUX
    const { foto } = useSelector((state) => state.auth.user);
    const { balance } = useSelector((state) => state.dracma.dracmas);

    // RETURN
    return (
        <Container>
            {/** Informacion del usuario */}
            <Wrapper>
                <Text fontSize="1.25rem" fontWeight="700" color="secondary">
                    ...
                </Text>
                <UserPhoto src={foto} alt="user" />
                <Text fontSize="1rem">Tú</Text>
            </Wrapper>
            {/** Dracma del usuario */}
            <Wrapper>
                {/* <CustomImage src="/assets/aden/dracma-point.svg" alt="dracma-point" /> */}
                <PointsText>{balance}</PointsText>
                <PointsTextStrong>DP</PointsTextStrong>
            </Wrapper>
        </Container>
    );
};

export default CardUserPosition;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    background: #fafafa;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.1rem;
`;

const UserPhoto = styled.img`
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
`;

const CustomImage = styled.img`
    width: 20px;
    height: auto;
`;

const PointsText = styled.span`
    font-size: 1rem;
    color: #616161;
    font-weight: 400;

    b {
        font-size: 1rem;
        color: #616161;
        font-weight: 700;
    }
`;

const PointsTextStrong = styled(PointsText)`
    font-weight: 700;
`;
