import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import SimpleLoading from "../../components/common/SimpleLoading";
import CourseBanner from "../../components/templates/teacher/course/CourseBanner";
import CourseTabs from "../../components/templates/teacher/course/CourseTabs";
import { titleHTML } from "../../helpers/title.helper";
import useCourseById from "../../hooks/useCourseById";
import useLayout from "../../hooks/useLayout";

const OneCourseTeacher = () => {
    titleHTML("Curso");

    const { id } = useParams();
    const { course } = useCourseById(id);
    // const { setMenu, clearMenu } = useLayout()

    // useEffect(() => {
    //     setMenu(course.name)
    // }, [])
    

    if (!course) {
        return <SimpleLoading padding="4" />;
    }
    
    

    return (
        <CoursePageWrapper>
            <CourseBanner
                img={course.img}
                title={course.name}
                status={course.level || "no definido"}
                aula={course.modality || "no registrado"}
            />
            <CourseTabs course={course} id={id} />
        </CoursePageWrapper>
    );
};

export default OneCourseTeacher;

const CoursePageWrapper = styled.div`
    display: grid;
    margin: 0;
    padding: 0;
    width: 100%;
    align-items: center;
    overflow: initial;
`;
