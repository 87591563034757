import React, { useState } from "react";
import styled from "styled-components";
import CardNewComment from "./CardNewComment";
import OrderComments from "./OrderComments";
import ThreadComments from "./thread/ThreadComments";
import useCommentsCourse from "../../../../hooks/commentsCourse/useCommentsCourse";
import useGetContributions from "../../../../hooks/commentsCourse/useGetContributions";
import SimpleLoading from "../../../acropolisCommon/components/SimpleLoading";
import { CircularProgress } from "@mui/material";

const MainComments = (props) => {
    const { currentMedia, showAside } = props;

    const idContribution = currentMedia?.id || currentMedia?.id_recurso;

    const [currentFilter, setCurrentFilter] = useState("new");

    const handleFilter = (newFilter) => {
        setCurrentFilter(newFilter);
    };

    const {
        LoadingPostCont,
        errorPostCont,
        upDateComments,
        createContribution,
        createComment,
        resetUpdateComments,
    } = useCommentsCourse();

    const {
        errorGetCont,
        LoadingGetCont,
        valuesContributions,
        getContributions,
    } = useGetContributions(idContribution);

    if (showAside) {
        return (
            <>
                {
                    (valuesContributions !== null) ? (
                        <>
                            {
                                valuesContributions.length > 0 ? <>
                                    {
                                        valuesContributions.map((comment) => {
                                            return (
                                                <CommentContainer>
                                                    <UserContainer>
                                                        <UserImg src={comment.partner_id.image} />
                                                        <UserInfo>
                                                            <UserName>
                                                                {comment.partner_id.name}
                                                            </UserName>
                                                            <PublicationDate>
                                                                {comment.create_date}
                                                            </PublicationDate>
                                                        </UserInfo>
                                                    </UserContainer>
                                                    <Comment>
                                                        {comment.contribution.replace(/<\/?p>/g, '')}
                                                    </Comment>
                                                    <SeeMoreButton>
                                                        Ver más
                                                    </SeeMoreButton>
                                                </CommentContainer>
                                            )
                                        })
                                    }
                                </> : 
                                <NotCommentShow>
                                    <p>No se encontraron comentarios.</p>
                                </NotCommentShow>
                            }

                        </>
                    )
                        :
                        <CircularProgress style={{ color: "var(--primary-one)" }} />
                }
            </>
        )

    }

    return (
        <Container>
            <CardNewComment
                LoadingPostCont={LoadingPostCont}
                idResource={currentMedia.id_recurso || currentMedia.id}
                errorPostCont={errorPostCont}
                createContribution={createContribution}
                type="contribution"
                getContributions={getContributions}
                resetUpdateComments={resetUpdateComments}
                upDateComments={upDateComments}
            />
            <OrderComments
                currentFilter={currentFilter}
                handleFilter={handleFilter}
            />

            {LoadingGetCont || errorGetCont ? (
                <SimpleLoading />
            ) : (
                <>
                    {Array.isArray(valuesContributions) &&
                        valuesContributions?.length > 0 ? (
                        <>
                            {currentFilter === "new" && (
                                <>
                                    {valuesContributions
                                        .sort(
                                            (a, b) =>
                                                new Date(
                                                    b?.create_date
                                                )?.getTime() -
                                                new Date(
                                                    a?.create_date
                                                )?.getTime()
                                        )
                                        .map((comments, index) => (
                                            <ThreadComments
                                                valuesContributions={comments}
                                                LoadingPostCont={
                                                    LoadingPostCont
                                                }
                                                errorPostCont={errorPostCont}
                                                key={index}
                                                createComment={createComment}
                                                getContributions={
                                                    getContributions
                                                }
                                                upDateComments={upDateComments}
                                                currentFilter={currentFilter}
                                                resetUpdateComments={
                                                    resetUpdateComments
                                                }
                                            />
                                        ))}
                                </>
                            )}
                            {currentFilter === "votes" && (
                                <>
                                    {valuesContributions
                                        .sort((a, b) => b?.likes - a?.likes)
                                        .map((comments, index) => (
                                            <ThreadComments
                                                valuesContributions={comments}
                                                LoadingPostCont={
                                                    LoadingPostCont
                                                }
                                                errorPostCont={errorPostCont}
                                                key={index}
                                                createComment={createComment}
                                                getContributions={
                                                    getContributions
                                                }
                                                upDateComments={upDateComments}
                                                currentFilter={currentFilter}
                                                resetUpdateComments={
                                                    resetUpdateComments
                                                }
                                            />
                                        ))}
                                </>
                            )}
                        </>
                    ) : (
                        <NotComment>
                            <p>No se encontraron comentarios.</p>
                            <p>¡Sé el primero en dejar uno!</p>
                        </NotComment>
                    )}
                </>
            )}
        </Container>
    );
};

export default MainComments;

const CommentContainer = styled.div`
    width: 152px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--secondary-one);
    border-left: 10px solid var(--secondary-three); 
    border-radius: 10px;
    padding-left: 8px;

    :hover {
        border-left: 10px solid var(--primary-one);
        box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
        button {
            color: var(--primary-one);
        }
    }
`

const UserContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

const UserImg = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-image: url(${(props) => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
`

const UserName = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: #222;
    
`

const PublicationDate = styled.div`
    font-size: 10px;
    font-weight: 700;
    color: var(--secondary-four);
`

const Comment = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-four);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const SeeMoreButton = styled.button`
    cursor: pointer;
    width: 100%;
    text-align: start;
    font-size: 10px;
    font-weight: 700;
    color: var(--secondary-four);
`

const Container = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    height: calc(100% - 200px);
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1rem;
    overflow-y: auto;
    overflow-x: hidden;
`;

const NotComment = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    p {
        font-size: 1.2rem;
    }
`;

const NotCommentShow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;

    p {
        font-size: 14px;
        color: var(--secondary-three);
        text-align: center;
    }
`;