import * as types from '../types/dracma_types';

const actions = {};

// GET DRACMA
actions.getDracmaRequest = () => {
    return {
        type: types.GET_DRACMA_REQUEST
    };
};

actions.getDracmaSuccess = (payload) => {
    return {
        type: types.GET_DRACMA_SUCCESS,
        payload: payload
    };
};

actions.getDracmaFail = (error) => {
    return {
        type: types.GET_DRACMA_FAIL,
        payload: error
    };
};

// GET MOVEMENTS
actions.getMovementsRequest = () => {
    return {
        type: types.GET_MOVEMENTS_REQUEST
    };
};

actions.getMovementsSuccess = (payload) => {
    return {
        type: types.GET_MOVEMENTS_SUCCESS,
        payload: payload
    };
};

actions.getMovementsFail = (error) => {
    return {
        type: types.GET_MOVEMENTS_FAIL,
        payload: error
    };
};

// GET RANKING
actions.getRankingRequest = () => {
    return {
        type: types.GET_RANKING_REQUEST
    };
};

actions.getRankingSuccess = (payload) => {
    return {
        type: types.GET_RANKING_SUCCESS,
        payload: payload
    };
};

actions.getRankingFail = (error) => {
    return {
        type: types.GET_RANKING_FAIL,
        payload: error
    };
};

// GET RANKING
actions.getRankingOndemandRequest = () => {
    return {
        type: types.GET_RANKINGONDEMAND_REQUEST
    };
};

actions.getRankingOndemandSuccess = (payload) => {
    return {
        type: types.GET_RANKINGONDEMAND_SUCCESS,
        payload: payload
    };
};

actions.getRankingOndemandFail = (error) => {
    return {
        type: types.GET_RANKINGONDEMAND_FAIL,
        payload: error
    };
};

// GET BENEFITS
actions.getBenefitsRequest = () => {
    return {
        type: types.GET_BENEFITS_REQUEST
    };
};

actions.getBenefitsSuccess = (payload) => {
    return {
        type: types.GET_BENEFITS_SUCCESS,
        payload: payload
    };
};

actions.getBenefitsFail = (error) => {
    return {
        type: types.GET_BENEFITS_FAIL,
        payload: error
    };
};

export { actions };
