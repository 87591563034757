import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { programsActions } from "../redux/actions";
import { getProducts } from "../redux/api/ecommerce";


const useGraduates = (isScorm = false) => {
    const [product, setProduct] = useState(null);
    const [productsFilter, setProductsFilter] = useState(null);
    const programs = useSelector((state) => state.programs.programs);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(programsActions.getProgramsRequest());
    }, []);

    useEffect(() => {
        if (!product && programs) {
            getProduct();
        }
    }, [programs]);

    

    const getProduct = async () => {
        const request = await getProducts();
        if (request.error) {
            return;
        }
        const data = isScorm ? sanitizerData(request.filter(c => !!c.upselling).random(), programs) : request
        setProduct(data);
    };

    // Unifico los dos array para ver si esta matriculado
    const sanitizerData = (request, programs) => {
        const result = request.map(course => {
            const isRegister = programs.find(pro => pro?.from === 'repo' && pro?.id === course.id)
            if (!!isRegister) {
                return { ...course, enrrollment: isRegister }
            }
            return course
        })
        return result
    }

    const handleProducts = (e) => {
        const { value } = e.target
        setProductsFilter(isScorm ? product.filter(c => c?.scorm_name.toLowerCase().includes(value) && !!c.upselling) : product.filter(c => c?.scorm_name.toLowerCase().includes(value)))
    }

    const handleGraduates = (e) => {
        const { value } = e.target
        setProductsFilter(product.filter(c => c?.name.toLowerCase().includes(value)))
    }

    return { product, productsFilter, handleProducts, handleGraduates }
}

export default useGraduates