import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import GridMyProgress from '../../common/GridMyProgress';
import { authActions, coursesActions } from '../../../redux/actions';
import { checkRoles } from '../../../helpers/roles.helper';
import AlertDialog from '../../common/AlertDialog';
import { useAlertDialog } from '../../../hooks/useAlertDialog';
import CampusSubjectCard from '../../ui/molecules/campus/CampusSubjectCard';
import TabsComponent from '../../common/TabsComponent';
import { Card } from '@mui/material';
import Registrations from '../subject/Registrations';
import useDocumentState from '../../../hooks/students/useDocumentState';
import CardCourse from '../../ui/molecules/campus/CardCourse';
import useStudentStatus from '../../../pages/campus/hooks/useStundetStatus';

const Courses = (props) => {
    const { tabsFake } = props;

    // HOOKS
    const { titulationState } = useDocumentState();
    const { statusStudentBlock } = useStudentStatus();

    // REDUX
    const dispatch = useDispatch();
    const courses = useSelector((state) => state.courses.courses);
    const listLoading = useSelector((state) => state.courses.listLoading);
    const errorList = useSelector((state) => state.courses.errorList);
    const errorEnroll = useSelector((state) => state.courses.errorEnroll);
    const faActionUrl =
        `${process.env.REACT_APP_FA_ACTION}` ||
        'https://fa.aden.org/oauth2/authorize';
    const userStore = useSelector((state) => state.auth.user);
    const { canvas_login } = useSelector((state) => state.auth);

    const tabs = [
        { label: 'Asignaturas Canvas', id: 0 },
        // { label: "Asignaturas Acrópolis", id: 1 },
    ];

    //STATE
    const [currentState, setCurrentState] = useState('Todos');

    const [alertDialog, closeAlertDialog, openAlertDialog] = useAlertDialog();
    const [tab, setTab] = useState(0);

    useEffect(() => {
        if (courses === null && !listLoading) {
            dispatch(
                coursesActions.getCoursesRequest({
                    size: '100',
                    offset: '0',
                })
            );
        }
        if (errorEnroll === 'flag') {
            dispatch(
                coursesActions.getCoursesRequest({
                    size: '100',
                    offset: '0',
                })
            );
        }

        if (errorEnroll !== null && errorEnroll !== 'flag') {
            if (errorEnroll.message.includes('418')) {
                openAlertDialog(
                    '¡Ups!',
                    'Has alcanzado el límite permitido de cursos en progreso. Por favor, toma contacto con tu Asesor Académico.',
                    'Ok'
                );
            } else if (errorEnroll.message.includes('400')) {
                openAlertDialog(
                    '¡Ups!',
                    'No has entregado la documentación correspondiente. Por favor, toma contacto con tu Asesor Académico.',
                    'Ok'
                );
            } else {
                openAlertDialog(
                    '¡Ups!',
                    'Ocurrió un error al inscribirte en este curso. Por favor intenta nuevamente más tarde o refresca la página.',
                    'Ok'
                );
            }
        }
    }, [errorEnroll]);

    // FUNCTIONS
    const renderCase = (type) => {
        const caseType = {
            0: (
                <GridMyProgress
                    propsValues={courses}
                    Card={CardCourse}
                    titulationState={titulationState}
                    currentState={currentState}
                    handleCurrentState={handleCurrentState}
                    gridFor="courses"
                    errorList={errorList}
                    handleTryAgain={handleTryAgain}
                    statusStudentBlock={
                        statusStudentBlock?.studentStatus === 'Bloqueado'
                    }
                />
            ),
            1: <Registrations tabsFake={tabsFake} />,
        };

        return caseType[type];
    };

    const handleTab = (_, value) => {
        setTab(value);
    };

    function handleCurrentState(newValue) {
        setCurrentState(newValue);
    }

    function handleTryAgain() {
        dispatch(
            coursesActions.getCoursesRequest({
                size: '40',
                offset: '0',
            })
        );
    }

    function loginFirstInsructure() {
        const form = document.getElementById('formlogin');

        if (!canvas_login && !!form) {
            dispatch(authActions.canvasLoginSuccess());
            setTimeout(() => {
                form.submit();
            }, 2000);
        }
    }

    // RETURN
    if (checkRoles('visita')) {
        return (
            <VisitaWrapper>
                <TextVisita
                    target="_blank"
                    href="https://www.aden.org/nuestros-programas/"
                    rel="noopener noreferrer"
                >
                    Ir a Oferta educativa
                </TextVisita>
            </VisitaWrapper>
        );
    }

    return (
        <CoursesContainer>
            <Container>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
                <TabCase>{renderCase(tab)}</TabCase>
            </Container>

            <AlertDialog
                titulo={alertDialog.titulo}
                mensaje={alertDialog.mensaje}
                accion={alertDialog.accion}
                open={alertDialog.open}
                onClose={closeAlertDialog}
            ></AlertDialog>
        </CoursesContainer>
    );
};

export default Courses;

const CoursesContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const H2 = styled.h2`
    font-size: 1.5rem;
    font-weight: 700;
`;

const VisitaWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6rem 0;
`;

const TextVisita = styled.a`
    color: #b31d15;
    text-decoration: underline;
    font-size: 1.5rem;
    font-weight: 700;
`;

const Container = styled(Card)`
    border-radius: 20px;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
`;

const TabCase = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
