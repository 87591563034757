import React from 'react';
import styled from 'styled-components';
import { Img } from '../../../common/Image';
import whiteLogo from '../../../../assets/media/aden/logo-aden-simple-white.png';
import { Text } from '../../../common/Texts';

export default function FooterLanding() {
    return (
        <FooterLandingWrapper>
            <LogoWrapper>
                <Img w="200px" src={whiteLogo} />
            </LogoWrapper>
            <Navbar></Navbar>
            <Copyright>
                <Text color="#fff">
                    Copyright © ADEN International Business School
                </Text>
                <Text fontSize=".9rem" fontWeight="bold" color="#fff">
                    UNIVERSITE DE MANAGEMENT DE SUISSE, S.A
                </Text>
            </Copyright>
        </FooterLandingWrapper>
    );
}

const FooterLandingWrapper = styled.div`
    padding: 3rem 10rem;
    @media (max-width: 1775px) {
        display: none;
    }
`;

const LogoWrapper = styled.div`
    width: 100%;
`;

const Navbar = styled.div`
    height: 100px;
`;

const Copyright = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
