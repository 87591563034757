import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const CustomLinearProgress = styled(LinearProgress)`
    width: 100%;
    height: 7px;
    border-radius: 8px;
    background-color: #eaeaea;

    .MuiLinearProgress-bar {
        border-radius: 20px;
        background-color: #35d0a5;
        transition: ease-out 1s;
    }
`;


export const LargeLinearProgress = styled(LinearProgress)`
    box-sizing: border-box !important;
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 20px;
    overflow: visible !important;
    background-color: transparent;

    /* el progreso de la barra se controla por width y se deshabilita transform-translate */
    .MuiLinearProgress-bar {
        border-radius: 20px !important;
        width: ${(p) => p.value}%;
        background-color: #35d0a5 !important;
        transition: ease-out 1s !important;
    }

    .MuiLinearProgress-bar1Determinate {
        transform: none !important;
        transition: all 1s !important;
        z-index: 20 !important;
    }

    .MuiLinearProgress-bar1Determinate::before {
        /* content: "${(p) => p.value}%"; */
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: -1.7rem;
        width: 100%;
        color: #28a986;
        font-weight: 700;
        font-size: 16px;
    }

    @media screen and (max-width: 768px) {
        height: 20px;
        .MuiLinearProgress-bar {
            width: ${(p) => p.value ?? 100}%;
        }
    }

    @media screen and (max-width: 425px) {
        width: 100%;
        .MuiLinearProgress-bar {
            width: ${(p) => p.value ?? 100}%;
        }
    }
`;