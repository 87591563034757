import React from 'react'
import styled from 'styled-components'
import ErrorResponse from '../../../../acropolisCommon/components/ErrorResponse'
import { Text } from '../../../../acropolisCommon/components/Texts'
import useActivity from '../../../hooks/useActivity'
import parse from 'html-react-parser';
import DefaultChip from '../../../../acropolisCommon/components/DegaultChip'

const ViewPresentation = (props) => {

    const { resource } = props


    const [presentation] = useActivity(resource?.id_evaluacion)

    if (!presentation) {
        return <ErrorResponse />
    }

    const { name, descripcion, tipo_entrega, opciones_entrega, consigna } = presentation

    return (
        <DisplayWrapper>
            <ContentChip>
                <DefaultChip padding="15px" radius="15px" textColor="#fff" color="#b31d15" title={tipo_entrega} />
                <DefaultChip radius="15px" textColor="#fff" color="#b31d15" title={opciones_entrega} />
            </ContentChip>
            <Text color="#616161" fontWeight="bold" fontSize="18px">{name?.toUpperCase()}</Text>
            <Description>
                <Text color="#616161" fontWeight="bold">Descripcion de actividad:</Text>
                <RenderHTML>{parse(descripcion)}</RenderHTML>
                <Text color="#616161" fontWeight="bold">Consigna:</Text>
                <RenderHTML>{parse(consigna)}</RenderHTML>
            </Description>
        </DisplayWrapper>
    )
}

export default ViewPresentation

const DisplayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const Description = styled.div`
    display: flex;
    flex-direction: column;
`

const RenderHTML = styled.div`
    margin: 16px 0;
`

const ContentChip = styled.div`
    display: flex;
    gap: 12px;
`