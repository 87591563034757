import { Form } from 'formik';
import styled from 'styled-components';
import DefaultField from '../../../../../components/common/DefaultField';

const SocialNetworks = (props) => {

    const { values } = props;

    return (
        <Container>
            <FormContainer>
                {/** LinkedIn */}
                <DefaultField
                    name="linkedin"
                    type="text"
                    label="LinkedIn"
                    value={values.linkedin}
                />
                {/** Facebook */}
                <DefaultField
                    name="facebook"
                    type="text"
                    label="Facebook"
                    value={values.facebook}
                />
                {/** Instagram */}
                <DefaultField
                    name="instagram"
                    type="text"
                    label="Instagram"
                    value={values.instagram}
                />
                <DefaultField
                    name="twitter"
                    type="text"
                    label="Twitter"
                    value={values.twitter}
                />
            </FormContainer>
        </Container>
    )
}

export default SocialNetworks;

const Container = styled.div`
    width: 100%;
    max-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    @media (width < 768px) {
        height: 100%;
        max-height: 100%;
    }
`;

const FormContainer = styled(Form)`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8rem;
    row-gap: 1rem;
    padding-top: 1rem;

    @media (width < 768px) {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
`;
