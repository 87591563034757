import * as types from '../types';

const initialState = {
    documents: null,
    listLoading: false,
    errorList: null
};

export const documents = (state = initialState, action) => {
    switch (action.type) {
        // GET DOCUMENTS
        case types.GET_DOCUMENTS_REQUEST:
            return {
                ...state,
                listLoading: true,
                errorList: null
            };
        case types.GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                documents: action.payload,
                errorList: null
            };
        case types.GET_DOCUMENTS_FAIL:
            return {
                ...state,
                listLoading: false,
                errorList: action.payload
            };
        // POST DOCUMENTS
        case types.POST_DOCUMENT_REQUEST:
            return {
                ...state,
                listLoading: true,
                errorList: null
            };
        case types.POST_DOCUMENT_SUCCESS:
            const document = action.payload.documento;
            let newState = [...state.documents];
            for (let i = 0; i < newState.length; i++) {
                for (let j = 0; j < newState[i].requerimientos.length; j++) {
                    if (
                        document.display_name ==
                        newState[i].requerimientos[j].nombre
                    ) {
                        newState[i].requerimientos[j].estado =
                            'Pendiente de aprobación';
                        newState[i].requerimientos[j].id_documentacion =
                            document.id;
                        newState[i].requerimientos[j].nombre =
                            document.display_name;
                        newState[i].requerimientos[j].nombre_documento =
                            document.attachement_nombre;
                    }
                }
            }
            return {
                ...state,
                listLoading: false,
                documents: [...newState],
                errorList: null
            };
        case types.POST_DOCUMENT_FAIL:
            return state;

        // PUT DOCUMENTS
        case types.PUT_DOCUMENT_REQUEST:
            return {
                ...state,
                listLoading: true,
                errorList: null
            };
        case types.PUT_DOCUMENT_SUCCESS:
            const document2 = action.payload.documento;
            let newState2 = [...state.documents];
            for (let i = 0; i < newState2.length; i++) {
                for (let j = 0; j < newState2[i].requerimientos.length; j++) {
                    if (
                        document2.name == newState2[i].requerimientos[j].nombre
                    ) {
                        newState2[i].requerimientos[j].estado =
                            'Pendiente de aprobación';
                        newState2[i].requerimientos[j].id_documentacion =
                            document2.id;
                        newState2[i].requerimientos[j].nombre = document2.name;
                        newState2[i].requerimientos[j].nombre_documento =
                            document2.attachement_nombre;
                    }
                }
            }
            return {
                ...state,
                listLoading: false,
                documents: [...newState2],
                errorList: null
            };
        case types.PUT_DOCUMENT_FAIL:
            return state;

        default:
            return state;
    }
};
