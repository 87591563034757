import { useState } from "react";
import styled from "styled-components";
import { Autocomplete } from "@mui/material";
import CrmStudents from "../../../ui/organisms/academic/CrmStudents";
import RepoStudents from "../../../ui/organisms/academic/RepoStudents";

const StudentsNowAA = () => {
  const options = [
    "Todos",
    "Alta/Bienvenida",
    "Cursando",
    "Cursado suspendido",
    "Cursado condicional",
    "Cursado completo",
    "Investigación doctoral",
    "Gestión de título",
    "Egresado",
    "Baja",
    "Inactivo",
  ];

  // STATES
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    { label: "Mis alumnos CRM", id: 0 },
    { label: "Mis alumnos REPO", id: 1 },
  ];

  const handleTab = (id) => {
    setActiveTab(id);
  };

  return (
    <StudentsWrapper>
      <WrapperTabs>
        {tabs.map((tab) => (
          <Title
            key={tab.id}
            onClick={() => handleTab(tab.id)}
            active={tab.id === activeTab ? true : false}
          >
            {tab.label}
          </Title>
        ))}
      </WrapperTabs>
      {activeTab === 0 && <CrmStudents />}
      {activeTab === 1 && <RepoStudents />}
    </StudentsWrapper>
  );
};

export default StudentsNowAA;

const StudentsWrapper = styled.div`
  display: grid;
  width: calc(100% - 3rem);
  height: auto;
  border-radius: 2rem;
  padding: 2rem 1.5rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const WrapperTabs = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
  padding: 1rem;
`;

const Title = styled.div`
  cursor: pointer;
  color: ${(p) => (p.active ? "#b31d15" : "#555555")};
  font-weight: 700;
  font-size: 1rem;
  background-color: #fff;
  border: ${(p) => (p.active ? "0.5px solid #f3f3f3" : "none")};
  box-shadow: ${(p) =>
    !!p.active
      ? `0px 3px 5px rgba(0, 0, 0, 0.15), 
        0px 5px 10px rgba(0, 0, 0, 0.06)`
      : "none"};
  border-radius: 5px;
  padding: 8px 14px;
  transition: all 0.3s ease-in-out;

  :hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15),
      0px 5px 10px rgba(0, 0, 0, 0.06);
  }
`;
