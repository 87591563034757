import styled from 'styled-components';
import React from 'react';
import { Text } from './Texts';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorResponse = (props) => {
    const { message = 'Ocurrió un error', button = false } = props;

    return (
        <ErrorResponseWrapp>
            <TextFake color="#aaaaaa" fontWeight="700" fontSize="1.5rem">
                <ErrorOutlineIcon />
                {` ${message}`}
            </TextFake>
            {!!button && button}
        </ErrorResponseWrapp>
    );
};

export default ErrorResponse;

const ErrorResponseWrapp = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
`;

const TextFake = styled(Text)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    svg {
        font-weight: 800;
        font-size: 2rem;
    }
`;
