import { Form, Formik } from "formik";
import styled from "styled-components";
import DefaultField from "./DefaultField";
import { emailSchema } from "../../../utils/schemas";

const ModalForm = (props) => {

    const { getData } = props;

    const handleSubmit = async (values, resetForm) => {
        getData(values.email, true);
        resetForm();
    };

    return (
        <>
            <h2>
                Por favor, ingresa el correo
                <br />
                con el que estás registrado en el campus
                <br />
                electrónico para continuar
            </h2>
            <FormContainer>
                <Formik
                    initialValues={{
                        email: ""
                    }}
                    // onSubmit={handleSubmit}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, resetForm);
                    }}
                    validationSchema={emailSchema}
                >
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <DefaultField
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    label="Correo electrónico"
                                    required
                                />
                                <PrimaryButton>
                                    Continuar
                                </PrimaryButton>
                            </Form>
                        );
                    }}
                </Formik>
            </FormContainer>
        </>
    )
};

export default ModalForm;

const FormContainer = styled.div`
    width: 100%;
    height: 60%;
    position: relative;

    button {
        position: absolute;
        bottom: 0;
        right: 0;
    }
`

const PrimaryButton = styled.button`
    cursor: pointer;
    background-color: #cd2118;
    color: #fff;
    border-radius: 10px;
    height: 40px;
    padding: 0 1rem;
    text-align: center;
    font-size: 16px;
    font-weight: 600;

    :hover {
        background-color: #b31d15;
    }
`