import React, { useState } from "react";
import styled from "styled-components";
import SendIcon from "@mui/icons-material/Send";
import { FilledInput, IconButton, InputAdornment } from "@mui/material";

const InputMessage = (props) => {
    const { handleSend, userPhoto } = props;

    // STATES
    const [message, setMessage] = useState("");

    // FUNCTIONS
    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    return (
        <WrapperComment>
            <Image>
                <img src={userPhoto} />
            </Image>
            <Comment>
                <FilledInputFake
                    type="text"
                    value={message}
                    placeholder="Escribe un comentario"
                    onChange={handleChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButtonFake
                                disabled={!message ? true : false}
                                onClick={() => {
                                    handleSend(message);
                                    setMessage("");
                                }}
                                edge="end"
                            >
                                <SendIcon />
                            </IconButtonFake>
                        </InputAdornment>
                    }
                />
            </Comment>
        </WrapperComment>
    );
};

export default InputMessage;

const WrapperComment = styled.div`
    display: grid;
    grid-template-columns: 48px 4fr;
    gap: 1rem;
    align-items: center;
`;

const Image = styled.div`
    border-radius: 50%;
    overflow: hidden;
    height: 48px;
    width: 48px;
    img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
`;

const Comment = styled.div`
    height: 50px;
    position: relative;
`;

const FilledInputFake = styled(FilledInput)`
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #f1f1f1;
    border-radius: 5px;

    input {
        padding-bottom: 30px;
    }
    span {
        /* margin-top: 1rem; */
    }
    svg {
        color: #a8a8a8;
    }

    :before {
        display: none;
    }
    :after {
        display: none;
    }
`;

const IconButtonFake = styled(IconButton)`
    /* background: none!important; */
`;