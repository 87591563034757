import React from "react";
import styled from "styled-components";
import { useState } from "react";
import TabsComponent from "../../components/common/TabsComponent";
import GridCardWhithSocialMedia from "../../components/templates/academic/allStudents/GridCardWhithSocialMedia";
import { titleHTML } from "../../helpers/title.helper";
import FilterTeacherStatus from "../../components/templates/academic/allStudents/FilterTeacherStatus";
import AnalyticsTeachers from "../../components/templates/academic/allStudents/AnalyticsTeachers";
import TeachersNowAA from "../../components/templates/academic/TeachersNowAA";

const AcademicTeachers = () => {
    titleHTML("Profesores");

    const tabs = [
        { label: `Profesores`, id: 0 },
        //{ label: `Analítica`, id: 1 }
    ];

    const [tab, setTab] = useState(0);

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
    };

    function ToggleMain(props) {
        const { tab } = props;

        const cases = {
            0: (
                <TeachersNowAA />
                // <GridCardWhithSocialMedia
                //     type="teachers"
                //     url={`${window.location.origin}/mock/teachersGrid.json`}
                //     filterComponent={FilterTeacherStatus}
                // />
            ),
            //1: <AnalyticsTeachers />
        };

        return cases[String(tab)] || <TeachersNowAA />;
    }

    return (
        <Container>
            <WrapperTop>
                <TabsComponent
                    tab={tab}
                    handleTab={handleTab}
                    tabs={tabs}
                />
            </WrapperTop>
            <BodyWrapper>
                <ToggleMain tab={tab} />
            </BodyWrapper>
        </Container>
    );
};

export default AcademicTeachers;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const WrapperTop = styled.div`
    position: absolute;
    top: 70px;
    left: 70px;
    width: calc(100% - 70px);
    background: #ffffff;
    z-index: 6;

    @media (max-width: 768px) {
        left: 0;
    }
`;

const BodyWrapper = styled.div`
    padding: 2rem;
    width: calc(100% - 4rem);
    border-radius: 20px;
    height: 800px;
    overflow: auto;
`;
