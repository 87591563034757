import * as types from '../types/tickets_types';

const actions = {};

// GET OPTIONS
actions.getOptionsRequest = () => {
    return {
        type: types.GET_OPTIONS_REQUEST
    };
};

actions.getOptionsSuccess = (payload) => {
    return {
        type: types.GET_OPTIONS_SUCCESS,
        payload: payload
    };
};

actions.getOptionsFail = (error) => {
    return {
        type: types.GET_OPTIONS_FAIL,
        payload: error
    };
};

// GET TICKET
actions.getTicketRequest = (id) => {
    return {
        type: types.GET_TICKET_REQUEST,
        payload: id
    };
};

actions.getTicketSuccess = (payload) => {
    return {
        type: types.GET_TICKET_SUCCESS,
        payload: payload
    };
};

actions.getTicketFail = (error) => {
    return {
        type: types.GET_TICKET_FAIL,
        payload: error
    };
};

export { actions };
