import React from "react";
import styled from "styled-components";
import { Card } from "@mui/material";
import { Text } from "../Texts";
import BarProgress from "../BarProgress";

import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
// import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import { Img } from "../Image";
import { useState } from "react";
// import CourseDetailForAcademic from "../../templates/academic/oneStudent/CourseDetailForAcademic";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";

const CardCourseStudent = (props) => {
    const { values } = props;

    // const [isOpen, setIsOpen] = useState(false);
    // function openModal() {
    //     setIsOpen(true);
    // }
    // function closeModal() {
    //     setIsOpen(false);
    // }

    const FilterColor =
        values.estado === "Confirmado"
            ? "#5e80db"
            : values.estado === "Preinscripto"
            ? "#C29F43"
            : values.estado === "Egresado"
            ? "#1E8065"
            : values.estado === "Baja"
            ? "#FC2958"
            : "";

    return (
        <>
            <Container
                // onClick={openModal}
                filter={FilterColor}
            >
                <CardFake>
                    <Wrapper>
                        <ImgFake
                            w="100%"
                            height="100%"
                            loading="lazy"
                            src={values?.image || "../../assets/logo-aden.svg"}
                        />
                    </Wrapper>

                    <Body>
                        <Text fontSize="16px" fontWeight="bold" color="#222222">
                            {" "}
                            {values.curso}
                        </Text>
                        <BarProgress value={values.progreso} />
                        <Footer state={values?.estado}>
                            {values.estado === "confirmado" ? (
                                <>
                                    <VisibleCalendar>
                                        <TodayRoundedIcon />
                                        <div>
                                            <Text
                                                fontSize="8px"
                                                fontWeight="700"
                                                color="#A8A8A8"
                                            >
                                                INICIO
                                            </Text>
                                            <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                color="#616161"
                                            >
                                                {values.curso_fecha_inicio ||
                                                    "-"}
                                            </Text>
                                        </div>
                                    </VisibleCalendar>
                                    <VisibleCalendar>
                                        <EventBusyRoundedIcon />
                                        <div>
                                            <Text
                                                fontSize="8px"
                                                fontWeight="700"
                                                color="#A8A8A8"
                                            >
                                                VENCIMIENTO
                                            </Text>
                                            <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                color="#616161"
                                            >
                                                {values?.curso_fecha_fin || "-"}
                                            </Text>
                                        </div>
                                    </VisibleCalendar>
                                </>
                            ) : values.estado === "preinscripto" ? (
                                <>
                                    <VisibleCalendar>
                                        <TodayRoundedIcon />
                                        <div>
                                            <Text
                                                fontSize="0.5rem"
                                                fontWeight="700"
                                                color="#A8A8A8"
                                            >
                                                INICIO
                                            </Text>
                                            <Text
                                                fontSize="0.75rem"
                                                fontWeight="400"
                                                color="#616161"
                                            >
                                                -
                                            </Text>
                                        </div>
                                    </VisibleCalendar>
                                    <VisibleCalendar>
                                        <EventBusyRoundedIcon />
                                        <div>
                                            <Text
                                                fontSize="0.5rem"
                                                fontWeight="700"
                                                color="#A8A8A8"
                                            >
                                                VENCIMIENTO
                                            </Text>
                                            <Text
                                                fontSize="0.75rem"
                                                fontWeight="400"
                                                color="#616161"
                                            >
                                                -
                                            </Text>
                                        </div>
                                    </VisibleCalendar>
                                </>
                            ) : values.state === "egresado" ? (
                                <>
                                    <VisibleCalendar>
                                        <EventBusyRoundedIcon />
                                        <div>
                                            <Text
                                                fontSize="8px"
                                                fontWeight="700"
                                                color="#A8A8A8"
                                            >
                                                FINALIZADO EN
                                            </Text>
                                            <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                color="#616161"
                                            >
                                                {values?.curso_fecha_fin || "-"}
                                            </Text>
                                        </div>
                                    </VisibleCalendar>

                                    <VisibleCalendar>
                                        <StarsRoundedIcon />
                                        <div>
                                            <Text
                                                fontSize="8px"
                                                fontWeight="700"
                                                color="#616161"
                                            >
                                                NOTA
                                            </Text>
                                            <Text
                                                fontSize="12px"
                                                fontWeight="700"
                                                color="#616161"
                                            >
                                                {values?.nota || "-"}
                                            </Text>
                                        </div>
                                    </VisibleCalendar>
                                </>
                            ) : values.state === "baja" ? (
                                <>
                                    <VisibleCalendar>
                                        <TodayRoundedIcon />
                                        <div>
                                            <Text
                                                fontSize="8px"
                                                fontWeight="700"
                                                color="#EAEAEA"
                                            >
                                                INICIO
                                            </Text>
                                            <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                color="#EAEAEA"
                                            >
                                                {values?.curso_fecha_inicio ||
                                                    "-"}
                                            </Text>
                                        </div>
                                    </VisibleCalendar>
                                    <VisibleCalendar>
                                        <EventBusyRoundedIcon />
                                        <div>
                                            <Text
                                                fontSize="8px"
                                                fontWeight="700"
                                                color="#EAEAEA"
                                            >
                                                VENCIMIENTO
                                            </Text>
                                            <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                color="#EAEAEA"
                                            >
                                                {values?.curso_fecha_fin || "-"}
                                            </Text>
                                        </div>
                                    </VisibleCalendar>
                                </>
                            ) : (
                                ""
                            )}
                        </Footer>
                    </Body>
                </CardFake>

                <State state={values.estado}>{values.estado}</State>
            </Container>
            {/* <CourseDetailForAcademic
                open={isOpen}
                onClose={closeModal}
                data={values}
                student={student}
            /> */}
        </>
    );
};

export default CardCourseStudent;

const Container = styled.div`
    position: relative;
    /* cursor: pointer; */
    /* width: 100%; */
    :hover {
        img {
            filter: opacity(0.5) contrast(175%) brightness(100%)
                drop-shadow(0 0 0 ${(props) => props.filter});
        }
    }
    @media (max-width: 500px) {
        width: 90%;
    }
`;

const CardFake = styled(Card)`
    display: grid;
    grid-template-columns: 110px auto;
    height: 180px;
    border-radius: 20px;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);

    @media (min-width: 769px) and (max-width: 980px) {
        display: flex;
        flex-direction: column;
        font-size: 0.5rem;
        padding: 1.5rem;
        max-width: 240px;
    }

    @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        font-size: 0.5rem;
        padding: 1.5rem;
        width: 200px;
    }
`;

// const BarProgressFake = styled(BarProgress)`
//     & div.MuiLinearProgress-colorPrimary {
//         background-color: #eaeaea;
//     }
//     & div.MuiLinearProgress-barColorPrimary {
//         background-color: #35d0a5;
//     }
//     &&
//         .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate {
//         background-color: #35d0a5;
//     }
// `;

const Wrapper = styled.div`
    background-color: white;
    display: inline-flex;
    border-radius: 20px 0 20px 20px;

    @media (min-width: 769px) and (max-width: 980px) {
        display: none;
    }

    @media (max-width: 500px) {
        display: none;
    }
`;

const ImgFake = styled(Img)`
    transition: all 0.3s ease-in-out;
    position: relative;
    border-radius: 20px 0 20px 20px;
    object-fit: cover;
    object-position: center;
`;

const Body = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;

    padding: 1.875rem 0px 1rem 1rem;
    border-top-left-radius: 20px;
    position: relative;
    left: -20px;
    background-color: white;
    align-items: flex-start;

    :before {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: 0px;
        left: -50px;
        height: 50px;
        width: 50px;
        border-bottom-right-radius: 20px;
        box-shadow: 20px 5px 0 0px white;
    }

    @media (min-width: 769px) and (max-width: 980px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

const Footer = styled.div`
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    justify-content: space-between;
    color: #a8a8a8;
`;

const VisibleCalendar = styled.div`
    display: inline-flex;
    flex-direction: row;
    padding: 0 0.5rem;
    .MuiSvgIcon-root {
        font-size: 1.375rem;
        align-self: center;
    }
    @media (min-width: 769px) and (max-width: 980px) {
        .MuiSvgIcon-root {
            display: none;
        }
    }
    @media (max-width: 500px) {
        .MuiSvgIcon-root {
            display: none;
        }
    }
`;

const State = styled.div`
    height: 15px;
    width: 80px;
    border-radius: 5px;
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 800;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    color: ${(props) =>
        props.state === "Confirmado"
            ? "#5e80db"
            : props.state === "Preinscripto"
            ? "#C29F43"
            : props.state === "Egresado"
            ? "#1E8065"
            : props.state === "Baja"
            ? "#FC2958"
            : ""};

    background-color: ${(props) =>
        props.state === "Confirmado"
            ? "#E6EBF9"
            : props.state === "Preinscripto"
            ? "#F9F5EC"
            : props.state === "Egresado"
            ? "#EAFAF6"
            : props.state === "Baja"
            ? "#FFE6EB"
            : ""};

    @media (min-width: 769px) and (max-width: 980px) {
        right: 40%;
    }

    @media (max-width: 500px) {
        right: 40%;
    }
`;
