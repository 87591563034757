import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAdditionalData } from '../../../../redux/api/teacher';
import DefaultSelect from '../../../common/DefaultSelect';
import DefaultField from '../../../common/DefaultField';
import { useSelector } from 'react-redux';
import DefaultPhone from '../../../common/DefaultPhone';
import DefaultFile from '../../../common/DefaultFile';
import OptionPhoto from '../../../ui/organisms/profile/OptionPhoto';

const AboutInformation = (props) => {

    const { values, setFieldValue, handleCV } = props

    // State
    const [typesDocuments, setTypesDocuments] = useState(null)
    const gender = [{ id: "femenino", name: "Femenino" }, { id: "masculino", name: "Masculino" }]

    const { user } = useSelector(state => state.auth)

    // Effects
    useEffect(() => {
        if (typesDocuments === null) {
            getTypesDocuments()
        }
    }, [typesDocuments])

    // Functions
    const getTypesDocuments = async () => {
        const response = await getAdditionalData(user.repo_id)
        setTypesDocuments(response.type_document)
    }

    return <DocumentationWrapper>
        <div>
            <OptionPhoto/>
        </div>
        <Form>
            <DefaultPhone
                name="repo_phone"
                label="Telefono"
                value={values.phone}
                setFieldValue={setFieldValue}
            />
            <DefaultPhone
                name="repo_mobile"
                label="Movil"
                value={values.phone}
                setFieldValue={setFieldValue}
            />
            <DefaultField
                name="repo_street"
                type="text"
                label="Dirección"
            />
            <DefaultField
                name="repo_country"
                type="text"
                label="Nacionalidad"
            />
            {typesDocuments && (
                <DefaultSelect
                    name="repo_type_document"
                    label="Tipo de documento"
                    options={typesDocuments}
                />
            )}
            <DefaultField
                name="repo_document"
                type="text"
                label="Documento"
            />
            <DefaultField
                name="repo_personal_email"
                type="text"
                label="Correo electrónico (personal)"
            />
            <DefaultField
                name="repo_email"
                type="text"
                label="Correo electrónico (empresarial)"
            />
            <DefaultField
                name="repo_birthday"
                type="date"
                label="Fecha de nacimiento"
            />
            <DefaultField
                name="repo_vto_passport"
                type="date"
                label="Vencimiento de pasaporte"
            />
            <DefaultSelectFake
                name="repo_gender"
                label="Tipo de documento"
                options={gender}
            />
            <DefaultFile
                handleB64File={handleCV}
            />
        </Form>
    </DocumentationWrapper>
};

export default AboutInformation;

const DocumentationWrapper = styled.div`
    min-width: 350px;
    padding: 1rem;
    padding-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 3fr;
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        max-height: 72vh;
        overflow-y: auto;
        margin: 0 0 30px 0;
    }
`

const Form = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
        width: calc(100% - 2rem);
        gap: 10px;
    }
`

const DefaultSelectFake = styled(DefaultSelect)`
    max-height: 50px;
`