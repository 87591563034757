import styled from 'styled-components';
import parse from 'html-react-parser';
import CopyButton from './summary/CopyButton';

const Summary = (props) => {

    const { loadingResume, summary } = props;

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return html.replace(regex, 'src="');
    };

    return (
        <>
            {
                !!summary
                && <ResumeDescription>{parse(sanitizerHtml(summary.replace(/```|html/g, "")))}</ResumeDescription>
            }
            {
                !loadingResume && !!summary && (
                    <ButtonsContainer>
                        <CopyButton text={summary} />
                    </ButtonsContainer>
                )
            }
        </>
    )
}

export default Summary;

const ResumeDescription = styled.div`
    text-align: start;
    padding-left: 1rem;
    h1 {
        margin-bottom: 1rem;
        font-size: 18px;
        color: #b31d15;
    }
    h2 {
        margin-bottom: 1rem;
        font-size: 16px;
        color: #b31d15;
    }
    p {
        margin-bottom: 1rem;
        font-size: 14px;
    }
    ul {
        margin-left: 1rem;
        margin-bottom: 1rem;
        font-size: 14px;
    }
`

const ButtonsContainer = styled.div`
    width: calc(100% - 2rem);
    margin-left: 1rem;
    padding-top: 1rem;
    display: flex;
    gap: 8px;
    border-top: 1px solid #DCDCDC;
`