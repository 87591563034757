import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { changeAttempt } from "../../../../redux/api/academic/studentsRepo";
import SimpleLoading from "../../../common/SimpleLoading";

const CardSubmissionRepo = (props) => {
    const { activity, repoId, id } = props;

    // STATES
    const [values, setValues] = useState(activity?.intentos_actividad || 0);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    // EFFECTS
    useEffect(() => {
        !!show && handleAttempt();
    }, [show]);

    // FUNCTIONS
    const handleBlur = (e) => {
        setValues(e.target.value);
        setShow(true);
    };

    const handleKeyUp = (e) => {
        if (e.key === "Enter") {
            handleBlur(e);
        }
    };

    const handleValue = (e) => {
        setValues(e.target.value);
    };

    const handleAttempt = async () => {
        setLoading(true);
        try {
            await changeAttempt(activity?.id, repoId, values);

            setShow(false);
            setLoading(false);
        } catch (error) {
            setShow(false);
            setLoading(false);
        }
    };

    console.info("activity", activity);

    return (
        <Wrapper>
            <Title>{activity?.name}</Title>
            <Date className="basis">
                {
                    activity.submissions.sort((a, b) => {
                        return b.grade - a.grade;
                    })[0]?.delivery_date
                }
            </Date>
            <WrapperChip className="basis">
                {/* <ChipState>Rehacer</ChipState> */}

                {activity.submissions.length > 0 ? (
                    activity.submissions.sort((a, b) => {
                        return b.grade - a.grade;
                    })[0]?.state === "corregido" &&
                    !activity.submissions.sort((a, b) => {
                        return b.grade - a.grade;
                    })[0]?.redo ? (
                        <ChipState type="corregido">Calificado</ChipState>
                    ) : activity.submissions.sort((a, b) => {
                          return b.grade - a.grade;
                      })[0]?.state === "corregido" &&
                      activity.submissions.sort((a, b) => {
                          return b.grade - a.grade;
                      })[0]?.redo ? (
                        <ChipState type="rehacer">Rehacer</ChipState>
                    ) : activity.submissions.sort((a, b) => {
                          return b.grade - a.grade;
                      })[0]?.state === "auto_corregido" ? (
                        <ChipState type="auto_corregido">
                            Auto Corregido
                        </ChipState>
                    ) : (
                        <ChipState type="nocalificado">
                            Pendiente calificar
                        </ChipState>
                    )
                ) : (
                    <ChipState type="pendiente">Pendiente</ChipState>
                )}
            </WrapperChip>
            <WrapperChip className="basis">
                {activity.submissions.sort((a, b) => {
                    return b.grade - a.grade;
                })[0]?.state === "auto_corregido" ? (
                    <ChipType state="auto_corregido">Auto corregible</ChipType>
                ) : (
                    <ChipType state="Presentacion">Presentación</ChipType>
                )}
            </WrapperChip>
            {loading ? (
                <SimpleLoading />
            ) : (
                <>
                    {activity.submissions.length > 0 ? (
                        <InputFake
                            onBlur={handleBlur}
                            onKeyUp={handleKeyUp}
                            value={values}
                            max={100}
                            min={0}
                            className="basis"
                            maxLength={3}
                            type="number"
                            onChange={handleValue}
                        />
                    ) : (
                        <div className="basis">-</div>
                    )}
                </>
            )}
            {activity.submissions.length > 0 ? (
                <Calification className="basis">
                    {
                        activity.submissions.sort((a, b) => {
                            return b.grade - a.grade;
                        })[0]?.grade
                    }
                </Calification>
            ) : (
                <Calification className="basis">-</Calification>
            )}
        </Wrapper>
    );
};

export default CardSubmissionRepo;

const Wrapper = styled.div`
    width: calc(100% - 77px);
    padding: 22px 11px;
    height: calc(64px - 44px);
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 10px;

    margin-left: 55px;

    .basis {
        flex-basis: calc(12.5% - 0.5rem);
        text-align: center;
    }
`;

const Title = styled.h4`
    flex-basis: calc(37.5% - 0.5rem);
    font-size: 0.9rem;
    color: #222;
`;
const Date = styled.p`
    color: #616161;
    font-size: 0.9rem;
    letter-spacing: 0.15px;
    line-height: 20px;
`;

const WrapperChip = styled.div`
    display: flex;
    justify-content: center;
`;

const ChipState = styled.div`
    width: fit-content;
    padding: 7px 14px;
    color: ${(p) =>
        p.type === "corregido"
            ? "#35d0a5"
            : p.type === "pendiente"
            ? "#C29F43"
            : p.type === "auto_corregido"
            ? "#86A0E4"
            : p.type === "nocalificado"
            ? "#d67539"
            : "#b31d15"};
    background-color: ${(p) =>
        p.type === "corregido"
            ? "#EAFAF6"
            : p.type === "pendiente"
            ? "#F9F5EC"
            : p.type === "auto_corregido"
            ? "#E6EBF9"
            : p.type === "nocalificado"
            ? "#d67539"
            : "#FCE9E8"};
    border-radius: 8px;
    font-weight: bold;

    @media (max-width: 1600px) {
        font-size: 0.9rem;
    }
    @media (max-width: 1500px) {
        font-size: 0.75rem;
        padding: 7px 10px;
    }
`;
const ChipType = styled.div`
    background-color: ${(p) =>
        p.state === "auto_corregido" ? "#222222" : "#c4c4c4"};
    color: ${(p) => (p.state === "auto_corregido" ? "#fff" : "#222222")};
    width: fit-content;
    padding: 7px 14px;
    font-weight: bold;
    border-radius: 8px;

    @media (max-width: 1600px) {
        font-size: 0.9rem;
    }
    @media (max-width: 1500px) {
        font-size: 0.75rem;
        padding: 7px 10px;
    }
`;
const Calification = styled.div`
    color: #616161;
    font-size: 0.9rem;
`;

const InputFake = styled.input`
    font-size: 1rem;
    display: block;
    margin-right: 0.5rem;
    background: none;
    height: 20px;
    color: #b31d15;
    cursor: pointer;
    font-weight: 700;
    text-align: center;
    border: none;

    &:hover {
        box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
            0px 8px 8px -4px rgba(24, 39, 75, 0.08);
        border-radius: 5px;
        padding: 0.5rem;
        /* border: 0.5px solid #616161; */
        border: ${(p) =>
            p.activity_status === "pendiente" ? "none" : "0.5px solid #b31d15"};
    }
    &:focus {
        box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
            0px 8px 8px -4px rgba(24, 39, 75, 0.08);
        border-radius: 5px;
        padding: 0.5rem;
        outline: none;
        border: 0.5px solid #b31d15;
        caret-color: #b31d15;
    }
`;
