import React from "react";
import styled from "styled-components";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
const CardStudentRepo = (props) => {
  const { id, studentInfo } = props;

  const history = useHistory();
  const params = useQueryParams();
  const repoId = params.get("repo");

  return (
    <>
      <Wrapper>
        <Image>
          <img src={studentInfo?.imagen} alt="Foto alumno" />
        </Image>
        <State>{studentInfo?.student_state || "Cursando"}</State>

        <Header>
          <Left></Left>
          <Student>
            <span>Alumno</span>
            <p>{studentInfo?.name?.toLowerCase()}</p>
            <DataStudent>
              <p>{studentInfo?.country}</p>
              <p>
                {" "}
                Legajo:{" "}
                <span>
                  #C{id}-R{repoId}
                  {studentInfo?.sisId && `-S${studentInfo?.sisId}`}
                </span>
              </p>
            </DataStudent>
          </Student>
        </Header>

        <Footer>
          <Left></Left>
          <Contacts>
            <Buttons>
              <Button
                onClick={() => {
                  history.push(`../../profesor/alumno/${id}`);
                }}
              >
                Ver perfil público
              </Button>
              {/* <Divider></Divider>
                            <Button>Datos personales</Button> */}
            </Buttons>
            <SocialsMedia>
              <Link target="_blank" rel="noreferrer" isDisabled={true}>
                <QuestionAnswerIcon />
              </Link>
              <Link target="_blank" rel="noreferrer" isDisabled={true}>
                <WhatsAppIcon />
              </Link>
              <Link
                href={`mailto:${studentInfo?.email}`}
                target="_blank"
                rel="noreferrer"
              >
                <AlternateEmailIcon />
              </Link>
              <Link
                href={`tel:${studentInfo?.phone}`}
                target="_blank"
                rel="noreferrer"
              >
                <LocalPhoneIcon />
              </Link>
            </SocialsMedia>
          </Contacts>
        </Footer>
      </Wrapper>
    </>
  );
};

export default CardStudentRepo;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 248px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 12px 24px -15px rgba(0, 0, 0, 0.1),
    0px 0px 10px -6px rgba(0, 0, 0, 0.25);
`;

const State = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: fit-content;
  z-index: 2;
  padding: 7px 16px;
  border-radius: 5px;
  color: #5e80db;
  background-color: #e6ebf9;
`;

const Image = styled.div`
  position: absolute;
  top: 43.5px;
  left: 1.5rem;
  flex-basis: calc(171px - 1rem);
  width: 100%;
  z-index: 2;

  img {
    width: 161px;
    height: 161px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: content;
    border-radius: 50%;
  }
`;

const Header = styled.div`
  height: 152px;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  display: flex;
`;
const Student = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1.8rem;

  span {
    text-transform: uppercase;
    color: #616161;
    font-size: 0.9rem;
  }

  p {
    font-size: 1.1rem;
    font-weight: bold;
    color: #b31d15;
  }
`;
const Footer = styled.div`
  z-index: 1;
  display: flex;
  width: calc(100% - 3rem);
  height: calc(96px - 3rem);
  padding: 1.5rem;
  background-color: #f1f1f1;
`;

const Contacts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const SocialsMedia = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-align: center;
  justify-content: center;
  gap: 0.5rem;
`;

const Button = styled.button`
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b31d15;
`;

const Left = styled.div`
  flex-basis: calc(171px + 2rem);
  width: 100%;
`;

const DataStudent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.3rem;

  p {
    color: #616161;
    font-weight: 400;
    font-size: 14px;

    span {
      font-weight: 600;
    }
  }
`;

const Link = styled.div`
  svg {
    color: ${(p) => (p.isDisabled ? "#696969" : "#b31d15")};
  }
`;
