import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import Text from '../../../common/Text';
import { dracmaActions } from '../../../../redux/actions';
import OverflowRanking from '../../molecules/dracma/OverflowRanking';

const UsersRatingOndemand = (props) => {
    const { withTitle, limit, minHeight } = props;

    // console.log(props.minHeight);

    // REDUX
    const dispatch = useDispatch();
    const ranking = useSelector((state) => state.dracma.rankingOndemand);

    // EFFECTS
    useEffect(() => {
        if (!ranking) {
            dispatch(dracmaActions.getRankingOndemandRequest());
        }
    }, []);

    // RETURN
    if (!ranking) {
        return null;
    }

    return (
        <Container>
            {withTitle && (
                <Text fontSize="1rem" fontWeight="700">
                    Tabla de clasificación
                </Text>
            )}
            <OverflowWrapper minHeight={minHeight} withTitle={withTitle}>
                <OverflowRanking limit={limit} ranking={ranking} />
            </OverflowWrapper>
        </Container>
    );
};

export default UsersRatingOndemand;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    scrollbar-color: transparent transparent;
`;

const OverflowWrapper = styled.div`
    position: relative;
    min-height: ${(props) => (props.minHeight === 'min' ? '250px' : '600px')};

    @media (max-width: 768px) {
        min-height: 400px;
    }
`;
