export function capitalizeFirstLetter(string) {

    if (!string || string.length === 0) {
        return "";
    }

    return (string.charAt(0).toUpperCase() + string.slice(1))?.replace(/_/g, ' ');
}

export function capitalizeAllLetters(string) {

    if (!string || string.length === 0) {
        return "";
    }

    return string.toUpperCase();
}