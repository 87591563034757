import { Card, InputAdornment, TextField } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import React, { useState } from "react";
import styled from "styled-components";
import CardWhithSocialMedia from "../../common/teacher-academic/CardWhithSocialMedia";
import GridContent from "../../common/teacher-academic/GridContent";
import ErrorResponse from "../../common/ErrorResponse";
import NoResultsFound from "../../common/NoResultsFound";
import SimpleLoading from "../../common/SimpleLoading";
import SplitButton from "../../common/SplitButton";
import useTeachersAA from "../../../hooks/useTeachersAA";

const TeachersNowAA = () => {
    const options = ["Por defecto", "Más antiguo", "Más nuevo"];
    const [currentFilter, setCurrentFilter] = useState({
        index: 0,
        name: "Por defecto",
    });
    const [filterText, setFilterText] = useState("");

    const { initialTeachers, teachers, handleTeachers } = useTeachersAA();

    const handleOption = (args) => {
        setCurrentFilter(args);
        args.index === 0 &&
            handleTeachers(teachers.sort((a, b) => a.id > b.id));
        args.index === 1 &&
            handleTeachers(
                teachers.sort((a, b) => a.lastContact > b.lastContact)
            );
        args.index === 2 &&
            handleTeachers(
                teachers.sort((a, b) => a.lastContact < b.lastContact)
            );
    };

    const handleChange = (e) => {
        setFilterText(e.target.value);
        handleTeachers(
            initialTeachers.filter((student) =>
                student.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
    };

    if (teachers === false) {
        return (
            <TeachersWrapper>
                <ErrorResponse />
            </TeachersWrapper>
        );
    }

    if (teachers === null) {
        return (
            <TeachersWrapper>
                <SimpleLoading />
            </TeachersWrapper>
        );
    }

    return (
        <TeachersWrapper>
            <Header>
                <TextField
                    value={filterText}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    label="Buscar profesor..."
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchRounded />
                            </InputAdornment>
                        ),
                    }}
                />
                <SplitButton handleOption={handleOption} options={options} />
            </Header>
            <GridContent
                currentFilter={currentFilter}
                //moreFilters={moreFilters}
                contents={teachers}
                Card={CardWhithSocialMedia}
                contentPerPage={5}
                urlPagination={""}
                widthCard="260px"
                type={"teachers"}
            />
            {/* <GridCard
                width="220px"
                cards={students}
                component={CardWhithSocialMedia}
            /> */}
            {teachers.length === 0 && <NoResultsFound />}
        </TeachersWrapper>
    );
};

export default TeachersNowAA;

const TeachersWrapper = styled.div`
    display: grid;
    width: 100%;
    height: auto;
    padding: 2rem 1.5rem;

    @media (max-width: 768px) {
        padding: 0.5rem;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
    }
`;
