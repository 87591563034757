import React, { useState } from 'react';
import TabsComponent from '../../common/TabsComponent';
import styled from 'styled-components';
import { Card } from '@mui/material';
import CourseDashboard from './course/CourseDashboard';
import Qualifications from './course/Qualifications';
import Persons from './course/Persons';
// import Forum from './Forum';
import Modules from './course/Modules';
import Instructors from './course/Instructors';
import SubjectAnnouncements from '../../ui/organisms/subject/SubjectAnnouncements';

const CourseInfo = (props) => {

    const { course } = props

    const tabs = [
        // { label: `Tablero`, id: 0 },
        { label: `Participantes`, id: 3 },
        { label: `Módulos y contenido`, id: 1 },
        { label: 'Calificaciones', id: 2 },
        { label: `Instructores`, id: 4 },
        // { label: `Foro`, id: 4 },
        { label: 'Anuncios', id: 5 }
    ];

    const [tab, setTab] = useState(3);

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
    };

    function ToggleMain(props) {
        const { tab } = props;

        const cases = {
            1: <Modules courses={course.courses}/>,
            2: <Qualifications id={course.id}/>,
            3: <Persons id={course.id}/>,
            4: <Instructors idCourse={course.id} isAcademic={true}/>,
            5: <SubjectAnnouncements id={course.id} professor={true} />
        };

        return cases[String(tab)] || <CourseDashboard />;
    }


    return (
        <Card>
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            <BodyWrapper>
                <ToggleMain tab={tab} />
            </BodyWrapper>
        </Card>
    );
};
export default CourseInfo;

const BodyWrapper = styled.div`
    padding: 1rem;
`;
