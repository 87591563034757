import workshop from '../../img/recursos/Workshops.png';
import Webinars from '../../img/recursos/Webinars.png';
import MasterSessions from '../../img/recursos/MasterSessions.png';
import Microlearnings from '../../img/recursos/Microlearnings.png';

export const recursos = [
    {
        image: workshop,
        name: '12+ Workshops Online',
        id: '#workshops'
    },
    {
        image: Webinars,
        name: '15+ Webinars Mensuales',
        id: '#webinars'
    },
    {
        image: MasterSessions,
        name: '12 Master Sessions Anuales',
        id: '#mastersessions'
    },
    {
        image: Microlearnings,
        name: '1000+ Microlearnings',
        id: '#microlearnings'
    }
    /* {
        image: require("../../img/recursos/Cumbre.png"),
        name: "Cumbre Latinoamericana de Management con 50+ Expositores",
    }, */
];
