import React from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ViewResource from './components/view_resource/ViewResource';
import ViewSurvey from './components/view_survey/ViewSurvey';
import useLayout from '../../hooks/useLayout';

const Visualizer = () => {

    const { type, id } = useParams();
    const { setMenu } = useLayout()


    const cases = {
        survey: 'encuesta',
        resource: 'recurso'
    }

    setMenu(`Visor de ${cases[type] || 'contenido'}`)

    return (
        <ResourceWrapper>
            {cases[type] === 'recurso' && <ViewResource type={cases[type] || 'contenido'} id={id} />}
            {cases[type] === 'encuesta' && <ViewSurvey hash={id} />}
        </ResourceWrapper>
    )
}

export default Visualizer

const ResourceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

