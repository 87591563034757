import { Form, Formik } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";

// Components
import DefaultField from "./DefaultField";
import DefaultPhone from "../../../components/common/DefaultPhone";
import DefaultSelect from "../../../components/common/DefaultSelect";
import { Text } from "../../../components/common/Texts";

// Hooks
import { useQueryParams } from "../../../hooks/useQueryParams";

// Redux
import { createRefered } from "../../../redux/api/refered";

// Utils
import { referedSchema } from "../../../utils/schemas";
import { optionsStyle } from "../../../utils/snackStyles";

const FormRefered = (props) => {
    const { countries, student, handleDragma = false, misReferidos = false } = props;

    const [openSnackbar] = useSnackbar(optionsStyle);
    const [loading, setLoading] = useState(false);
    const params = useQueryParams()

    const utm_campaign = params.get('utm_campaign') ?? "mkt"
    const utm_content = params.get('utm_content') ?? "mkt"
    const medium = params.get('utm_medium') ?? "Referidos Bono 2024"
    const source = params.get('utm_source') ?? "Referido"

    // FORMIK
    const initialValues = {
        name: "",
        lastName: "",
        country: 10,
        state: "",
        programs: "1",
        email: "",
        phone: "",
    };

    const handleSubmit = async (values, resetForm) => {
        const country = countries.find((item) => item.id === values.country);
        const program = programs.find((item) => item.id === values.programs);
        const body = {
            partner_id: student.partner_id,
            name: values.name,
            last_name: values.lastName,
            email: values.email,
            phone: values.phone,
            opp_pais: country.name,
            opp_ciudad: values.state.toLowerCase(),
            opp_id_prod_mkt: program.id,
            program_name: program.name,
            medium: medium, // suborigen
            utm_campaign: utm_campaign,
            utm_content: utm_content,
            source: source // origen
        };
        setLoading(true);
        const request = await createRefered(body);
        setLoading(false);
        if (request.error) {
            openSnackbar("¡Ocurrió un error!");
        } else {
            handleDragma && handleDragma()
            openSnackbar("¡Referido exitoso!");
            resetForm({ values: "" });
        }
        // /api/v1/acropolis/referidos
    };

    return (
        <FormWrapper misReferidos={misReferidos}>
            <div>
                <Text color="#b31d15" fontSize="32px" fontWeight="700" textAlign="center">
                    Ingresa aquí <br /> los datos de tu referido
                </Text>
                <Formik
                    initialValues={initialValues}
                    // onSubmit={handleSubmit}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, resetForm);
                    }}
                    validationSchema={referedSchema}
                >
                    {({ values, setFieldValue }) => {
                        return (
                            <FormContainer>
                                <Content>
                                    <DefaultField
                                        name="name"
                                        type="text"
                                        label="Nombre"
                                        required
                                        variant="outlined"
                                    />
                                    <DefaultField
                                        name="lastName"
                                        type="text"
                                        variant="outlined"
                                        label="Apellido"
                                        required
                                    />
                                    <DefaultField
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        label="Correo electrónico"
                                        required
                                    />
                                    <DefaultPhone
                                        styled={styledInput}
                                        name="phone"
                                        label="Teléfono *"
                                        value={values.phone}
                                        setFieldValue={setFieldValue}
                                    />
                                    <DefaultSelectFake
                                        name="country"
                                        label="País de residencia"
                                        variant="outlined"
                                        options={countries || []}
                                        disabled={!countries ? true : false}
                                    />
                                    <DefaultField
                                        name="state"
                                        type="text"
                                        variant="outlined"
                                        label="Ciudad"
                                        required
                                    />
                                    <DefaultSelectFake
                                        name="programs"
                                        label="Programas"
                                        variant="outlined"
                                        options={programs}
                                    />
                                </Content>

                                <ButtonWrapper>
                                    {!loading ?
                                        student?.partner_id ?
                                            (
                                                <SubmitButton
                                                    type="submit"
                                                    value="Enviar"
                                                    disabled={
                                                        student === false ? true : false
                                                    }
                                                />
                                            )
                                            :
                                            (
                                                <SubmitButton
                                                    type="submit"
                                                    value="Identificate"
                                                    disabled={true}
                                                />
                                            )
                                        : (
                                            <SubmitButton
                                                type="submit"
                                                value="Cargando..."
                                                disabled
                                            />
                                        )}
                                </ButtonWrapper>
                            </FormContainer>
                        )
                    }}
                </Formik>
            </div>
        </FormWrapper>
    );
};

export default FormRefered;

const FormWrapper = styled.div`
    width: fit-content;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    background-color: #fff;
    ${(props) => {
        return props.misReferidos
            ? ``
            : `position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);`;
    }}
    border-radius: 20px;

    li {
        margin-left: 1rem;
        margin-bottom: 12px;
    }

    @media (max-width: 768px) {
        width: calc(100% - 6rem);
        padding: 2rem 2rem;
        overflow-x: hidden;
    }
`;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 25px;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    justify-content: end;
    gap: 1rem;
`;

const SubmitButton = styled.input`
    cursor: pointer;
    height: 40px;
    border-radius: 10px;
    padding: 0px 1rem;
    background-color: #CD2118;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border: 0px;

    :disabled {
        background-color: #a2a2a2;
        color: #f1f1f1;
        opacity: 0.5;
        cursor: not-allowed;

        :hover {
            background-color: #a2a2a2;
        }
    }

    :hover {
        background-color: #b31d15;
    }
`

const Content = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    /* width: "100%"; */
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`;

const styledInput = {
    width: "100%",
    padding: "10px 60px",
    borderRadius: "10px",
};

const DefaultSelectFake = styled(DefaultSelect)`
    height: 40px;
    border-radius: 10px;
`;

const programs = [
    {
        id: "1",
        name: "Selecciona el programa a referir"
    },
    {
        id: "MBAeo1",
        name: "Online Executive MBA",
    },
    {
        id: "MBAfu1",
        name: "MBA Online",
    },
    {
        id: "OYhum1",
        name:
            "Online One Year MBA en Gestión Estratégica de los Recursos Humanos",
    },
    {
        id: "OYmkd1",
        name: "Online One Year MBA en Marketing Digital",
    },
    {
        id: "OYdib1",
        name: "Online One Year MBA en Digital Business",
    },
    {
        id: "OYfzc1",
        name: "Online One Year MBA en Finanzas Corporativas",
    },
    {
        id: "OYpjm1",
        name: "Online One Year MBA en Dirección de Proyectos",
    },
    {
        id: "OYdcm1",
        name: "Online One Year MBA en Dirección Comercial",
    },
    {
        id: "OYcoa1",
        name: "Online One Year MBA en Business Coaching",
    },
    {
        id: "OYdop1",
        name: "Online One Year MBA en Dirección de Operaciones",
    },
    {
        id: "OYgen1",
        name: "Online One Year MBA en Gestión Estratégica de los Negocios",
    },
    {
        id: "OYinn1",
        name: "Online One Year MBA en Gestión Estratégica de la Innovación",
    },
    {
        id: "OYblo1",
        name: "Online One Year MBA en Blockchain",
    },
    {
        id: "OYdit1",
        name: "Online One Year MBA en Digital Transformation",
    },
    {
        id: "OYsal1",
        name: "Online One Year MBA en Salud Digital",
    },
    {
        id: "OYent1",
        name: "Online One Year MBA en Entrepreneurship",
    },
    {
        id: "OYbig1",
        name: "Online One Year MBA en Big Data & Business Analytics",
    },
    {
        id: "OYecm1",
        name: "Online One Year MBA en E-Commerce",
    },
    {
        id: "OYcic1",
        name: "Online One Year MBA en Comunicación e Imagen Corporativa",
    },
    {
        id: "OYmdn1",
        name: "Online Marketing Estratégico",
    },
    {
        id: "OYint1",
        name: "Online One Year MBA en International Business",
    },
    {
        id: "OYfbm1",
        name: "Online One Year MBA en Banking and Insurance Management",
    },
    {
        id: "OYcmp1",
        name: "Online One Year MBA en Compliance",
    },
    {
        id: "MOcoa1",
        name: "Master Online en  Business Coaching",
    },
    {
        id: "MOpro1",
        name: "Master Online en  Dirección de Proyectos",
    },
    {
        id: "MOmkd1",
        name: "Master Online en  Marketing Digital",
    },
    {
        id: "MOdib1",
        name: "Master Online en  Digital Business",
    },
    {
        id: "MOgen1",
        name: "Master Online en  Gestión estratégica de los negocios",
    },
    {
        id: "MOfzc1",
        name: "Master Online en  Dirección de Finanzas",
    },
    {
        id: "MOcmp1",
        name: "Master Online en  Compliance",
    },
    {
        id: "MOsal1",
        name: "Master Online en  Gestión de Salud Digital",
    },
    {
        id: "MOlaw1",
        name: "Master Online en  Derecho de los Negocios (LLM)",
    },
    {
        id: "MOgpp1",
        name: "Master Online en  Gestión de Políticas Públicas",
    },
    {
        id: "MBlaw1",
        name: "Master Blend Derecho de los Negocios (LLM)",
    },
    {
        id: "MBsal1",
        name: "Master Blend Gestión de Salud Digital",
    },
    {
        id: "MBgpp1",
        name: "Master Blend Políticas Públicas",
    },
    {
        id: "MOecm1",
        name: "Master Online en E-Commerce",
    },
    {
        id: "MObig1",
        name: "Master Online en Big Data & Business Analytics",
    },
    {
        id: "MJiag1", // Cambiar los ID
        name: "Major in AI for Business",
    },
    {
        id: "MJbig1",
        name: "Major in Big Data and Business Analytics",
    },
    {
        id: "MJmkd1",
        name: "Major in Marketing Digital",
    },
    {
        id: "MJecm1",
        name: "Major in E-commerce",
    },
    {
        id: "MJdib1",
        name: "Major in Digital Business",
    },
    {
        id: "POblo1",
        name: "Programa Especializado Online en  Blockchain",
    },
    {
        id: "POdit1",
        name: "Programa Especializado Online en  Digital Transformation",
    },
    {
        id: "POcoa1",
        name: "Programa Especializado Online en  Executive Coaching",
    },
    {
        id: "POpjm1",
        name: "Programa Especializado Online en  Dirección de Proyectos",
    },
    {
        id: "POtpm1",
        name: "Taller de Certificación en Project Management",
    },
    {
        id: "POgen1",
        name:
            "Programa Especializado Online en  Gestión estratégica de los negocios",
    },
    {
        id: "POfzc1",
        name: "Programa Especializado Online en  Finanzas Corporativas",
    },
    {
        id: "POdib1",
        name: "Programa Especializado Online en  Negocios Digitales",
    },
    {
        id: "POmkd1",
        name: "Programa Especializado Online en  Marketing Digital",
    },
    {
        id: "POdop1",
        name: "Programa Especializado Online en  Dirección de Operaciones",
    },
    {
        id: "POhum1",
        name:
            "Programa Especializado Online en  Gestión Estratégica de los Recursos Humanos",
    },
    {
        id: "POcmp1",
        name: "Programa Especializado Online en  Compliance",
    },
    {
        id: "POven1",
        name: "Programa Especializado Online en  Dirección Comercial",
    },
    {
        id: "POhab1",
        name: "Programa Especializado Online en  Habilidades Gerenciales",
    },
    {
        id: "POgsa1",
        name: "Programa Especializado Online en  Gestión Estratégica en Salud",
    },
    {
        id: "POsal1",
        name: "Programa Especializado Online en  Salud Digital",
    },
    {
        id: "POfav1",
        name: "Programa Especializado Online en  Finanzas Internacionales",
    },
    {
        id: "POfam1",
        name: "Programa Especializado Online en  Family Business",
    },
    {
        id: "POent1",
        name: "Programa Especializado Online en  Entrepreneurship",
    },
    {
        id: "POlaw1",
        name: "Programa Especializado Online en  Legal Tech and Innovation",
    },
    {
        id: "POinn1",
        name:
            "Programa Especializado Online en  Gestión Estratégica de la Innovación",
    },
    {
        id: "POgpu1",
        name: "Programa Especializado Online en  Gestión Pública",
    },
    {
        id: "POgpy1",
        name: "Programa Especializado Online en  Gestión de Proyectos Públicos",
    },
    {
        id: "POgpp1",
        name: "Programa Especializado Online en  Políticas Públicas",
    },
    {
        id: "POecm1",
        name: "Programa Especializado Online en E-Commerce",
    },
    {
        id: "POphd1",
        name: "Programa Especializado en Habilidades Directivas",
    },
    {
        id: "POpdg1",
        name: "Programa Especializado Online en Desarrollo Gerencial",
    },
    {
        id: "POmid1",
        name:
            "Programa Especializado Online en Marketing, Innovación y Negocios Digitales",
    },
    {
        id: "POgco1",
        name: "Programa Online de Gerencia Comercial",
    },
    {
        id: "POjpr1",
        name: "Online Programa de Innovación Jóvenes Profesionales",
    },
    {
        id: "POegc1",
        name: "Online Programa de Eficacia en la Gestión Comercial",
    },
    {
        id: "POcic1",
        name:
            "Programa Especializado Online en Comunicación e Imagen Corporativa",
    },
    {
        id: "POfbm1",
        name:
            "Programa Especializado Online en Banking and Insurance Management",
    },
    {
        id: "POitb1",
        name: "Programa Especializado Online en IT Busines",
    },
    {
        id: "POlog1",
        name: "Programa Especializado Online en Logística",
    },
    {
        id: "POfar1",
        name: "Programa Especializado Online en Management Farmacéutico",
    },
    {
        id: "POint1",
        name: "Programa Especializado Online en International Business",
    },
    {
        id: "POnec1",
        name: "Programa Especializado Online en Nuevas Economías",
    },
    {
        id: "POmdn1",
        name: "Programa Especializado Online en Marketing Estratégico",
    },
    {
        id: "POcex1",
        name: "Programa Especializado Online en Customer Experience",
    },
    {
        id: "PObig1",
        name: "Programa Especializado Online en Big Data & Business Analytics",
    },
    {
        id: "POsos1",
        name: "Programa Especializado Online en  Negocios Sostenibles",
    }
];
