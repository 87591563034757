import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckIcon from "@mui/icons-material/CheckCircle";
import { Button } from "@mui/material";
import CopyIcon from "@mui/icons-material/FilterNone";
import DownloadIcon from "@mui/icons-material/GetApp";
import SharedEvents from "./SharedEvents";
import BookmarkAddIcon from "@mui/icons-material/Bookmarks";
import useCountDown from "../../../../hooks/common/useCountDown";
import * as encode from "nodejs-base64-encode";
import { useSelector } from "react-redux";
import AddEventToCalendar from "../../shared/AddEventToCalendar";
import { getLocalUTC } from "../../../../helpers/time.helper";
import moment from "moment-timezone";

const InfoRegisterModal = (props) => {
    const { event } = props;
    // STATES
    const [timer, setTimer] = useState(null);
    const [isCopy, setIsCopy] = useState(false);
    const { countdown, timers } = useCountDown();

    const partner_id = useSelector((state) => state.auth.user.partner_id);

    const hash = encode.encode(partner_id + "#" + event.crm_id, "base64");

    const utc = getLocalUTC();
    const dateFormat = new Date(
        moment(event.date_begin.replace(/-/g, "/") + `${utc} UTC`).format()
    );

    const formatDate = `${dateFormat.getFullYear()}/${
        dateFormat.getMonth() + 1
    }/${dateFormat.getDate()} ${dateFormat.getHours()}:${dateFormat.getMinutes()}:${dateFormat.getSeconds()}`;

    useEffect(() => {
        countdown(formatDate);
    }, []);

    useEffect(() => {
        if (timers !== null) {
            setTimer(timers);
        }
    }, [timers]);

    const goToEvent = () => {
        if (event.type === "ONLINE") {
            window.open(event.webinar_url);
        }
    };

    const handleCopy = () => {
        setIsCopy(false);
        if (hash !== "") {
            navigator.clipboard.writeText(`${hash}`);
            setIsCopy(true);
        }

        setTimeout(() => {
            setIsCopy(false);
        }, 1500);
    };

    return (
        <Wrapper>
            <WrapperCheck className="flex">
                <CheckIconFake />
                <h3>¡Ya estás inscripto!</h3>
            </WrapperCheck>
            <WrapperBody className="flex">
                <h4>Tu código de inscripción</h4>
                <p>El día del evento podrás marcar tu asistencia con el</p>
                <SpanFake>{hash}</SpanFake>
                <WrapperButtons>
                    <Button
                        onClick={handleCopy}
                        color="primary"
                        variant="contained"
                        startIcon={<CopyIcon />}
                    >
                        {isCopy ? "Copiado" : "Copiar"}
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<DownloadIcon />}
                        disabled
                    >
                        Descargar mi entrada
                    </Button>
                </WrapperButtons>
            </WrapperBody>
            <WrapperShared className="flex">
                <h4>¡Compártelo con tus colegas!</h4>
                <SharedEvents sharedItem={event} links={event.webinar_url} />
            </WrapperShared>
            <WrapperFooter className="flex">
                <WrapperCalendar>
                    <AddEventToCalendar oneEvent={event} />
                    {/* <BookmarkAddIcon /> Añadir a mi calendario */}
                </WrapperCalendar>
                <ButtonFake
                    onClick={goToEvent}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                        timer === null ? true : timer !== 0 ? true : false
                    }
                >
                    {timer === null ? (
                        "Calculando..."
                    ) : timer === 0 ? (
                        event.type === "ONLINE" ? (
                            "Ir al evento"
                        ) : (
                            "Ver ubicación"
                        )
                    ) : (
                        <>{timer}</>
                    )}
                </ButtonFake>
            </WrapperFooter>
        </Wrapper>
    );
};

export default InfoRegisterModal;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    height: 100%;
    justify-content: center;
    padding: 0 1rem;

    .flex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const WrapperCheck = styled.div`
    gap: 0.5rem;
    margin-top: 0.5rem;
    width: 100%;
    h3 {
        color: #222222;
        font-size: 1.2rem;
    }
`;

const CheckIconFake = styled(CheckIcon)`
    color: #35d0a5;
    font-size: 6rem;
`;

const WrapperBody = styled.div`
    gap: 0.5rem;
    width: 100%;

    h4 {
        font-weight: 400;
        color: #616161;
        text-transform: uppercase;
        font-size: 0.7rem;
        letter-spacing: 1.5px;
    }
    p {
        color: #222222;
        font-size: 0.9rem;
        font-weight: bold;
    }
`;

const WrapperButtons = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
`;

const SpanFake = styled.span`
    color: #b31d15;
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0.8rem auto;
`;

const WrapperShared = styled.div`
    gap: 1rem;
    width: 100%;
    h4 {
        color: #222222;
        font-weight: 400;
    }
`;

const WrapperFooter = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
`;

const WrapperCalendar = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: #b31d15;
    font-weight: bold;

    svg {
        font-size: 1.4rem;
    }
`;

const ButtonFake = styled(Button)`
    margin-bottom: 1rem;
`;
