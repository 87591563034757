import styled from 'styled-components';

// Components
import { Icon } from '@iconify/react';
import { Text } from '../../../../../../components/common/Texts';

const BottomContent = (props) => {

    const { coursesLength, duration } = props;

    return (
        <BottomContentContainer>
            <ModulesAmount>
                <Icon
                    color="#B31D15"
                    width={25}
                    height={25}
                    icon="ph:book-bookmark-light"
                />
                <Text
                    fontSize={'16px'}
                >
                    <b>{coursesLength}</b> Módulos
                </Text>
            </ModulesAmount>
            <Divider />
            <AssignmentDuration>
                <Icon
                    color="#B31D15"
                    width={25}
                    height={25}
                    icon="mdi-light:clock"
                />
                <Text
                    fontSize={'16px'}
                >
                    <b>
                        {duration === '0 False'
                            ? '_'
                            : `${duration} `}
                    </b>
                    de cursado
                </Text>
            </AssignmentDuration>
        </BottomContentContainer>
    )
}

export default BottomContent;

const BottomContentContainer = styled.div`
    display: flex;
    gap: 24px;

    @media (width < 1024px) {
        gap: 32px;
    }
    @media (max-width: 768px) {
        padding: 0;
        display: none;
    }
`

const ModulesAmount = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    @media (max-width: 1024px) {
        gap: 7px;
    }
`;

const AssignmentDuration = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    @media (max-width: 1024px) {
        gap: 7px;
    }
`;

const Divider = styled.div`
    width: 1px;
    height: 35px;
    background-color: #222;
`;