import React from 'react';
import styled from 'styled-components';

const PublicityWithoutPorvenir = () => {
    const videoUrl =
        'https://player.vimeo.com/external/295669767.hd.mp4?s=63c2c3ee6ac076e92f4031038d931ca43ca1db36&profile_id=174';

    return (
        <Container>
            <CustomVideo autoPlay muted loop>
                <source src={videoUrl} type="video/mp4" />
            </CustomVideo>
            <Overlay />
            <TextContainer>
                <DivText>
                    <H1>Porvenir</H1>
                    <H3>
                        Que puedas estudiar sin ir a un salón de clases, eso es{' '}
                        <b>Avancemos Justos</b>
                    </H3>
                    <H2>Sólo hay uno.</H2>
                </DivText>
            </TextContainer>
        </Container>
    );
};

export default PublicityWithoutPorvenir;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 1rem;
    text-align: left;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    color: #ffffff;

    @media (max-width: 768px) {
        visibility: hidden !important;
        display: none !important;
    }
`;

const CustomVideo = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

const Overlay = styled.div`
    background: rgb(252, 157, 47);
    background: linear-gradient(
        45deg,
        rgba(252, 157, 47, 0.6) 0%,
        rgba(252, 157, 47, 0.3) 50%,
        rgba(252, 157, 47, 0.6) 100%
    );
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
`;

const TextContainer = styled.div`
    padding: 0 10%;
    z-index: 99999;
    text-align: left;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 1024) {
        padding: 0 5%;
    }
`;

const DivText = styled.div`
    z-index: 99;
    color: #ffffff;
    text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const H2 = styled.h2`
    font-size: 2rem;
    @media (max-width: 1024px) {
        font-size: 1.5rem;
    }
`;

const H3 = styled.h3`
    font-size: 1.5rem;
    line-height: 140%;
    font-weight: 400;
    @media (max-width: 1024px) {
        font-size: 1rem;
    }
`;

const H1 = styled.h1`
    font-size: 3.5rem;
    font-weight: 900;
    @media (max-width: 1024px) {
        font-size: 2.5rem;
    }
`;
