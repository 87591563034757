import { CloseRounded } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

import Text from './Text';

const HeadModal = (props) => {
    const { title, closeModal } = props;

    // RETURN
    return (
        <Container>
            <Text
                fontSize="1.5rem"
                fontWeight="700"
                color="secondary"
                component="h1"
            >
                {title}
            </Text>
            <CloseRounded
                onClick={closeModal}
                color="secondary"
                style={{ cursor: 'pointer' }}
            />
        </Container>
    );
};

export default HeadModal;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
`;
