import { InputAdornment, TextField } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import React, { useState } from 'react';
import styled from 'styled-components';
import useCoursesTeacher from '../../../../hooks/useCoursesTeacher';
import CourseCardRounded from '../../../common/CourseCardRounded';
import ErrorResponse from '../../../common/ErrorResponse';
import GridCard from '../../../common/GridCard';
import NoResultsFound from '../../../common/NoResultsFound';
import SimpleLoading from '../../../common/SimpleLoading';
import SplitButton from '../../../common/SplitButton';

const GridCoursesTeacher = () => {
    const options = ['Por defecto', 'Más antiguo', 'Más nuevo'];
    // eslint-disable-next-line no-unused-vars
    const [currentFilter, setCurrentFilter] = useState({
        index: 0,
        name: 'Por defecto'
    });
    const [filterField, setFilterField] = useState('');

    const { initialCourses, courses, handleCourses } = useCoursesTeacher();

    const handleOption = (args) => {
        setCurrentFilter(args);
        args.index === 0 && handleCourses(courses.sort((a, b) => a.id > b.id));
        args.index === 1 &&
            handleCourses(
                courses.sort((a, b) => a.fecha_deadline > b.fecha_deadline)
            );
        args.index === 2 &&
            handleCourses(
                courses.sort((a, b) => a.fecha_deadline < b.fecha_deadline)
            );
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterField(value);
        handleCourses(
            initialCourses.filter((course) =>
                course.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
    };

    if (courses === false) {
        return (
            <GridTeacherWrapper>
                <ErrorResponse />
            </GridTeacherWrapper>
        );
    }

    if (courses === null) {
        return (
            <GridTeacherWrapper>
                <SimpleLoading />
            </GridTeacherWrapper>
        );
    }

    return (
        <GridTeacherWrapper>
            <Header>
                <TextField
                    value={filterField}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    label="Buscar curso..."
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchRounded />
                            </InputAdornment>
                        )
                    }}
                />
                <SplitButton handleOption={handleOption} options={options} />
            </Header>
            <GridCard cards={courses} component={CourseCardRounded} display="grid"/>
            {courses.length === 0 && <NoResultsFound />}
        </GridTeacherWrapper>
    );
};

export default GridCoursesTeacher;

const GridTeacherWrapper = styled.div``;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`;
