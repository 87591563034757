import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Modal, Tooltip } from '@mui/material';
import HeadModal from '../../../common/HeadModal';
import Text from '../../../common/Text';
import MoreInformation from './MoreInformation';
import CardBenefit from '../../molecules/dracma/CardBenefit';
import SimpleLoading from '../../../common/SimpleLoading';
import axios from 'axios';
import { useSelector } from 'react-redux';

const HowToGetMorePoints = (props) => {
    const { dracma_coin_name } = props;

    // STATE
    const [isOpen, setIsOpen] = useState(false);
    const [achievements, setAchievements] = useState(null);
    const ondemand = useSelector((state) => state.ondemand.ondemand);

    // FUNCTIONS
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const getData = async () => {
        const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/dracmacoins/achievements`;
        try {
            const res = await axios.get(URL);
            const data = res.data;
            const postData = data.response_data;
            setAchievements(Object.values(postData));
        } catch (error) {
            return {
                error
            };
        }
    };

    useEffect(() => {
        getData();
    }, []);

    // RETURN
    if (achievements === null) {
        return <SimpleLoading />;
    }
    return (
        <>
            <ButtonDracma variant="contained" size="small" onClick={openModal}>
                ¿Cómo obtengo más puntos?
            </ButtonDracma>
            <Modal open={isOpen} onClose={closeModal}>
                <Container>
                    <HeadModal
                        title={
                            dracma_coin_name !== '' &&
                            dracma_coin_name !== undefined
                                ? `¿Cómo se obtienen ${dracma_coin_name} Points?`
                                : '¿Cómo se obtienen Dracma Points?'
                        }
                        closeModal={closeModal}
                    />
                    <CustomHr />
                    {/** Lista de actividades */}
                    <ColumnWrapper>
                        <Text fontSize="1rem" component="p">
                            ¡Tu actividad dentro del campus ahora te da puntos!
                            Chequea en esta lista cuáles son las actividades que
                            te dan
                            {dracma_coin_name !== '' &&
                            dracma_coin_name !== undefined
                                ? ` ${dracma_coin_name} Points:`
                                : ' Dracma Points:'}
                        </Text>
                        <OverflowWrapper>
                            <Overflow>
                                {ondemand === null ||
                                ondemand.enable_event === true
                                    ? achievements[0].map((more, index) => {
                                          return (
                                              <CardBenefit
                                                  benefit={more}
                                                  key={index}
                                                  customImg={
                                                      '/assets/aden/dracma-point.svg'
                                                  }
                                                  alt={'dracma-point'}
                                              />
                                          );
                                      })
                                    : achievements[0]
                                          .filter(
                                              (more) =>
                                                  more.crm_id !== 3 &&
                                                  more.crm_id !== 6
                                          )
                                          .map((more, index) => {
                                              return (
                                                  <CardBenefit
                                                      benefit={more}
                                                      key={index}
                                                      customImg={
                                                          '/assets/aden/dracma-point.svg'
                                                      }
                                                      alt={'dracma-point'}
                                                  />
                                              );
                                          })}
                            </Overflow>
                        </OverflowWrapper>
                    </ColumnWrapper>
                    <CustomHr />
                    {/** Botones */}
                    <ButtonWrapper>
                        <MoreInformation
                            dracma_coin_name={
                                dracma_coin_name !== '' &&
                                dracma_coin_name !== undefined
                                    ? dracma_coin_name
                                    : undefined
                            }
                            withText={true}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={closeModal}
                        >
                            Cerrar
                        </Button>
                    </ButtonWrapper>
                </Container>
            </Modal>
        </>
    );
};

export default HowToGetMorePoints;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 900px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem 2rem;
`;

const CustomHr = styled.hr`
    width: 100%;
    margin: 0;
`;

const OverflowWrapper = styled.div`
    position: relative;
    /* height: 500px; */
`;

const Overflow = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
`;

const ButtonDracma = styled(Button)`
    color: white;
    font-size: 14px;
    background-color: #999999;
`;
