import React from "react";
import styled from "styled-components";

// Components
import { Span, Text } from "../../../../../../components/common/Texts";
import { Icon } from "@iconify/react/dist/iconify.js";

// Custom hooks
import useStatus from "../../../../../../hooks/common/useStatus";

// Utils
import { convertToLocalTime } from "../../../../../../utils/convertToLocalTime";

const CorrectionSummary = (props) => {
	const {
		data: { create_date, submission_number, state, grade, redo, date_of_correction },
	} = props;

	const { setLightStateColor, setStrongStateColor, setStateText } =
		useStatus();

	const formatDateCreation = convertToLocalTime(create_date, "UTC");

	const formatDateCorrection = convertToLocalTime(date_of_correction, "UTC");

	return (
		<Wrapper>
			<Container>
				<Chip>
					{submission_number || 1}° Entrega
				</Chip>
				<Text fontWeight="500" fontSize="12px" color="#616161">
					Entregado el {formatDateCreation}
				</Text>
			</Container>
			<Container>
				<Text fontWeight="700" color="#222222" fontSize="14px">
					Estado:
				</Text>
				<State
					color={setStrongStateColor(redo ? "rehacer" : state)}
					backgroundColor={setLightStateColor(redo ? "rehacer" : state)}
				>
					{setStateText(redo ? "rehacer" : state)}
				</State>
			</Container>
			<Container>
				<IconWrapper>
					<Icon icon="lucide:file-pen-line" width="20px" height="20px" />
				</IconWrapper>
				<Text fontWeight="900" fontSize="20px">
					{grade}{" "}
					<Span fontWeight="700" color="#616161">
						/ 100
					</Span>
				</Text>
				<Text fontWeight="500" fontSize="12px" color="#616161">
					{date_of_correction ? `Corregido el ${formatDateCorrection} hs.` : null}
				</Text>
			</Container>
		</Wrapper>
	);
};

export default CorrectionSummary;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 15px;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
`;

const Chip = styled.label`
	padding: 8px 16px;
	font-size: 12px;
	font-weight: 600;
	border-radius: 100px;
	border: 1px solid #DCDCDC;
	background-color: #f9f9f9;
	color: #222222;
`

const State = styled.span`
	width: 175px;
	font-size: 12px;
	font-weight: 800;
	padding: 8px 0px;
	text-align: center;
	border-radius: 100px;
	&::first-letter {
		text-transform: capitalize !important;
	}
	color: ${(p) => p.color};
	border: 1px solid ${(p) => p.color};
	background-color: ${(p) => p.backgroundColor};
`;

const IconWrapper = styled.div`
	svg {
		color: #616161;
		font-size: 32px;
	}
`;
