import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useBlogArticle from "../../hooks/blog/useBlogArticle";
import NoResultsFound from "../common/NoResultsFound";
import SimpleLoading from "../common/SimpleLoading";
import BlogFilter from "../ui/molecules/blog/BlogFilter";
import CardArticles from "./CardArticles";

const MainBlog = () => {
    // STATES
    const [currentState, setCurrentState] = useState("Todos");
    const [values, setValues] = useState(null);

    const { articles } = useBlogArticle();

    // EFFECTS
    useEffect(() => {
        if (articles !== null) {
            setValues(articles);
        }
    }, [articles]);

    useEffect(() => {
        if (currentState === "Todos") {
            setValues(articles);
        }
        if (currentState === "Nuevos") {
            setValues(articles.filter((value) => value.type === "new"));
        }
        if (currentState === "Vistos") {
            setValues(articles.filter((value) => value.type === "viewed"));
        }
    }, [currentState]);

    // FUNCTION

    function handleCurrentState(newValue) {
        setCurrentState(newValue);
    }

    if (articles === null || values === null) {
        return <SimpleLoading />;
    }

    return (
        <CardFake>
            <BlogFilter
                currentState={currentState}
                handleCurrentState={handleCurrentState}
            />
            <GridWrapper>
                {articles.length > 0 ? (
                    <>
                        {values.map((article, index) => (
                            <CardArticles key={index} value={article} />
                        ))}
                    </>
                ) : (
                    <>
                        <NoResultsFound message="¡Ups! No se encontró nuevo contenido del blog." />
                    </>
                )}
            </GridWrapper>
        </CardFake>
    );
};

export default MainBlog;

const CardFake = styled(Card)`
    width: calc(100% - 4rem);
    border: none;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 20px;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(323px, 1fr));
    align-items: center;
    gap: 1rem;
`;
