import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import GetAppIcon from "@mui/icons-material/GetApp";
import RestoreIcon from "@mui/icons-material/Restore";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { URL_SIS_API } from "../../../../app/modules/Services/environments";
import { documentsActions } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "../../../common/Texts";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../../../utils/snackStyles";
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const Document = (props) => {
    // Props
    const { data } = props;

    // Inputs
    const hiddenFileInputSave = useRef(null);
    const hiddenFileInputUpdate = useRef(null);

    //Rol
    const activeRol = useSelector((state) => state.auth.activeRol);

    const { listLoading, error } = useSelector((state) => state.documents);

    useEffect(() => {
        if (flag) {
            if (!listLoading) {
                if (error) {
                    openSnackbar(`Ocurrió un error al guardar documento`);
                    setFlag(false);
                } else {
                    openSnackbar(`Guardado con éxito`);
                    dispatch(documentsActions.getDocumentsRequest());
                    setFlag(false);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listLoading]);

    // State
    const [loading, setLoading] = useState(false)
    const [flag, setFlag] = useState(false);
    const [openSnackbar] = useSnackbar(optionsStyle);

    // Redux
    const dispatch = useDispatch();

    const handleClickSave = () => {
        hiddenFileInputSave.current.click();
    };

    const handleClickUpdate = () => {
        hiddenFileInputUpdate.current.click();
    };

    // Functions
    const savePdf = async (e, typeDocument) => {
        if (e.target.files[0].type === 'application/pdf') {
            const file = new FormData();
            file.append("documento", e.target.files[0]);
            file.append("tipo_documentacion", typeDocument);
            dispatch(documentsActions.postDocumentRequest(file));
            openSnackbar(`Guardando archivo`);
            setFlag(true);
        }
        else {
            openSnackbar(`El formato debe ser PDF`);
        }
    };

    const updatePdf = async (e, typeDocument, id) => {
        if (e.target.files[0].type === 'application/pdf') {
            const file = new FormData();
            file.append("documento", e.target.files[0]);
            file.append("tipo_documentacion", typeDocument);
            dispatch(documentsActions.putDocumentRequest({ id, pdf: file }));
            openSnackbar(`Actualizando archivo`);
            setFlag(true);
        }
        else {
            openSnackbar(`El formato debe ser PDF`);
        }
    };

    const downloadFile = async (id, name) => {
        setLoading(true)
        const getDocumentById = async (id) => {
            try {
                const response = await axios.get(`${URL_SIS_API}/documento/${id}`);
                return response.data.link;
            } catch (error) {
                return error;
            }
        };
        const result = await getDocumentById(id);
        try {
            generatePdf(result, name);
        } catch (error) {
            openSnackbar(`No se pudo descargar el documento`);
        } finally {
            setLoading(false)
        }
    };

    const generatePdf = (file, name) => {
        const link = document.createElement("a");
        link.href = file;
        link.download = name;
        link.click();
    };

    if (loading) {
        return <Container>
            <Header>
                <Text fontWeight="700" color="#555555">
                    {data.nombre}
                </Text>
            </Header>
            <Body>
                <Badge status={'loading'}>
                    <>
                        <Name>Generando</Name>
                        <WatchLaterIcon />
                    </>
                </Badge>
                <ButtonsWrapper>

                </ButtonsWrapper>
            </Body>
        </Container>
    }

    return (
        <>
            {data.nombre !== "" && (
                <Container>
                    <Header>
                        <Text fontWeight="700" color="#555555">
                            {data.nombre}
                        </Text>
                    </Header>
                    <Body>
                        <Badge status={data.estado}>
                            {(data.estado === "Pendiente aprobación" || data.estado === "pendiente") && (
                                <>
                                    <Name>En revisión</Name>
                                    <WatchLaterIcon />
                                </>
                            )}
                            {(data.estado === "Pendiente carga") && (
                                <>
                                    <Name>Pendiente</Name>
                                    <WatchLaterIcon />
                                </>
                            )}
                            {(data.estado === "Rechazado" ||
                                data.estado === "Rechazada") && (
                                    <>
                                        <Name>Rechazado</Name>
                                        <CancelIcon />
                                    </>
                                )}
                            {(data.estado === "Aceptado" ||
                                data.estado === "Aceptada") && (
                                    <>
                                        <Name>Aceptado</Name>
                                        <OfflinePinIcon />
                                    </>
                                )}
                        </Badge>
                        <ButtonsWrapper>
                            {data.estado !== "Pendiente carga" && <DonwloadIcon onClick={() => downloadFile(data.id_documentacion, data.nombre_documento)} />}
                            {(data.estado === "Aceptado" ||
                                data.estado === "Aceptada") && (
                                    <AdjuntIcon1Disabled />
                                )}
                            {((data.estado === "Pendiente aprobación" || data.estado === "pendiente") && data.documento_cargado) && (
                                <AdjuntIcon1Disabled />
                            )}
                            {data.estado === "Pendiente carga" &&
                                activeRol === "alumno" && (
                                    <Tooltip title="Subir archivo">
                                        <AdjuntIcon1
                                            type="file"
                                            onClick={handleClickSave}
                                        />
                                    </Tooltip>
                                )}
                            {(data.estado === "Rechazado" ||
                                data.estado === "Rechazada") &&
                                activeRol === "alumno" && (
                                    <Tooltip title="Actualizar archivo">
                                        <AdjuntIcon2
                                            type="file"
                                            onClick={handleClickUpdate}
                                        />
                                    </Tooltip>
                                )}
                            <InputFile
                                ref={hiddenFileInputSave}
                                onChange={(e) =>
                                    savePdf(
                                        e,
                                        data.tipo_documentacion,
                                        data.id_documentacion
                                    )
                                }
                                type="file"
                            />
                            <InputFile
                                ref={hiddenFileInputUpdate}
                                onChange={(e) =>
                                    updatePdf(
                                        e,
                                        data.tipo_documentacion,
                                        data.id_documentacion
                                    )
                                }
                                type="file"
                            />
                        </ButtonsWrapper>
                    </Body>
                </Container>
            )}
        </>
    );
};

export default Document;

const Container = styled.div`
    height: 120px;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 19px 0px;
    border-radius: 10px;
    margin-bottom: 1rem;
`;

const Header = styled.div`
    height: 60%;
    width: calc(100% - 20px);
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const Body = styled.div`
    height: 40%;
    width: calc(100% - 20px);
    padding: 0 10px;
    background-color: #f7f7f7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Badge = styled.div`
    font-size: 0.9rem;
    /* font-weight: 600; */
    color: white;
    padding: 0.1rem;
    max-width: 140px;
    width: fit-content;
    background-color: ${(props) => {
        if (props.status === "Pendiente aprobación") {
            return "#5E80DB";
        } else if (props.status === "Rechazado") {
            return "#B31D15";
        } else if (props.status === "Aceptado") {
            return "#1E8065";
        } else if (props.status === "Cargando") {
            return "#b56b2f";
        } else if (props.status === "loading") {
            return "#b148b5";
        } else {
            return "#C29F43";
        }
    }};
    border-radius: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
`;

const Name = styled.div`
    padding-left: 0.5rem;
    width: fit-content;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 12px;
`;

const DonwloadIcon = styled(GetAppIcon)`
    color: #a3a3a3;
    cursor: pointer;
`;

const AdjuntIcon1 = styled(AttachFileIcon)`
    color: #616161;
    cursor: pointer;
`;

const AdjuntIcon2 = styled(RestoreIcon)`
    color: #616161;
    cursor: pointer;
`;

const AdjuntIcon1Disabled = styled(RestoreIcon)`
    color: #a3a3a3;
`;

const InputFile = styled.input`
    display: none;
`;
