import React from 'react';
import styled from 'styled-components';

import UsersRating from './UsersRating';
import UserPosition from './UserPosition';

const RatingOption = () => {
    return (
        <Container>
            {/** Tabla de clasificacion */}
            <UsersRating minHeight={'max'} limit={100} withTitle={true} />
            {/** Posicion del usuario con recomendaciones para ganar */}
            <UserPosition />
        </Container>
    );
};

export default RatingOption;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    @media (max-width: 425px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;
