import styled from "@emotion/styled";
import React, { useState } from "react";
import { Icon } from "@iconify/react";
import ChatIcon from "@mui/icons-material/Chat";
import SubReplyCard from "./SubReplyCard";
import CardNewComment from "../CardNewComment";
import parse from "html-react-parser";
import { patchLikeComment } from "../../../../../redux/api/commentsCourse/commentsCourseApi";
import { useSelector } from "react-redux";

const ThreadComments = (props) => {
    const {
        valuesContributions,
        createComment,
        LoadingPostCont,
        errorPostCont,
        resetUpdateComments,
        upDateComments,
        getContributions,
    } = props;

    // REDUX
    const id = useSelector((state) => state?.auth?.user?.repo_id);

    // STATES
    const [showReplies, setShowReplies] = useState(false);
    const [expand, setExpand] = useState(false);
    const [currentValues, setCurrentValues] = useState(valuesContributions);
    const [isLiked, setIsLiked] = useState(currentValues?.liked);
    const [likes, setLikes] = useState(currentValues?.likes);

    // FUNCTIONS
    const handleShowReplies = () => {
        setShowReplies(!showReplies);
    };

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, "g");
        return html.replace(regex, 'src="');
    };

    const handleNewComment = () => {
        setExpand(!expand);
    };

    const handleLike = async () => {
        try {
            await patchLikeComment(currentValues?.id, "contribution", id);

            setIsLiked(!isLiked);

            // handleSetLike(!isLiked);
            setLikes(!isLiked ? likes + 1 : likes - 1);
        } catch (error) {
            console.info("error", error);
        }
    };

    const censoredCategory = [
        "Sesgo de Género",
        "Insulto Personal",
        "Discriminación varia",
        "Spam",
    ];

    // RETURN
    return (
        <Container>
            <Header>
                <Image>
                    <img
                        src={currentValues?.partner_id?.image}
                        alt="imagen usuario"
                    />
                </Image>
                <NameContainer>
                    <h2>{currentValues?.partner_id?.name}</h2>
                    <span>{currentValues?.create_date}</span>
                </NameContainer>
            </Header>
            <Body>
                {censoredCategory.some((x) => x === currentValues?.category) ? (
                    <Comment category={true}>
                        <p>
                            Este comentario fue moderado, por ser considerado "
                            {currentValues?.category}"
                        </p>
                    </Comment>
                ) : (
                    <Comment>
                        <p>
                            {parse(sanitizerHtml(currentValues?.contribution))}
                        </p>
                    </Comment>
                )}
                <Interactions>
                    <Likes>
                        {isLiked ? (
                            <Icon
                                onClick={handleLike}
                                icon="icon-park-solid:like"
                            />
                        ) : (
                            <Icon
                                onClick={handleLike}
                                icon="icon-park-outline:like"
                            />
                        )}
                        <span>{likes}</span>
                    </Likes>
                    <Reply onClick={handleNewComment}>
                        <ChatIcon />
                        <span>Responder</span>
                    </Reply>
                </Interactions>
                {expand ? (
                    <FullWidth>
                        <CardNewComment
                            expand={expand}
                            idContribution={currentValues?.id}
                            handleNewComment={handleNewComment}
                            createComment={createComment}
                            LoadingPostCont={LoadingPostCont}
                            errorPostCont={errorPostCont}
                            type="comment"
                            getContributions={getContributions}
                            upDateComments={upDateComments}
                            resetUpdateComments={resetUpdateComments}
                        />
                    </FullWidth>
                ) : (
                    <>
                        {currentValues?.comments?.length > 0 && (
                            <MoreReplies
                                showReplies={showReplies}
                                onClick={handleShowReplies}
                            >
                                <p>
                                    {!showReplies ? "Mostrar" : "Ocultar"}{" "}
                                    {currentValues?.comments?.length}
                                    {currentValues?.comments?.length === 1
                                        ? " respuesta"
                                        : " respuestas"}
                                </p>
                                <Icon icon="ep:arrow-up-bold" />
                            </MoreReplies>
                        )}
                    </>
                )}
                <ThreadReplies showReplies={showReplies}>
                    {currentValues?.comments?.length > 0 && (
                        <>
                            {currentValues.comments.map((subComment, index) => (
                                <SubReplyCard
                                    subComment={subComment}
                                    key={index}
                                />
                            ))}
                        </>
                    )}
                </ThreadReplies>
            </Body>
        </Container>
    );
};

export default ThreadComments;

const Container = styled.div`
    width: calc(100% - 2rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: #f9f9f9;
    border-radius: 10px;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-start;
`;

const Image = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: flex-start;

    h2 {
        color: #222222;
        font-weight: bold;
        font-size: 0.9rem;
    }

    span {
        font-size: 0.8rem;
        font-weight: bold;
        color: #a8a8a8;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-left: calc(35px + 0.5rem);
`;

const Comment = styled.div`
    width: 100%;

    p {
        color: #616161;
        font-size: 1rem;
        text-align: start;
        font-style: ${(p) => (p.category ? "italic" : "none")};
        font-weight: ${(p) => (p.category ? "bold" : "normal")};
    }
`;

const Interactions = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
`;

const Likes = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        color: #b31d15;
        cursor: pointer;
        font-size: 1.3rem;
    }

    span {
        color: #616161;
        font-size: 0.9rem;
        font-weight: bold;
    }
`;

const Reply = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    svg {
        color: #b31d15;
        font-size: 1.3rem;
    }

    span {
        color: #616161;
        font-size: 0.9rem;
        font-weight: bold;
    }
`;

const MoreReplies = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    p {
        color: #b31d15;
        font-size: 1rem;
    }

    svg {
        transition: all 0.3s ease-in-out;

        color: #b31d15;
        transform: ${(p) =>
            p.showReplies ? "rotate(0deg)" : "rotate(180deg)"};
    }
`;

const ThreadReplies = styled.div`
    display: ${(p) => (p.showReplies ? "flex" : "none")};
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
`;

const FullWidth = styled.div`
    margin-left: calc(-35px - 0.5rem);
`;
