import React from 'react';
import styled from 'styled-components';

const ContentReadings = (props) => {
    const { readings } = props;
    return (
        <Container>
            {readings.map((reading, index) => {
                return (
                    <Body key={index}>
                        <span>{index + 1}.</span> {reading}
                    </Body>
                );
            })}
        </Container>
    );
};

export default ContentReadings;
const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Body = styled.p`
    color: #616161;
    font-size: 0.95rem;
    span {
        font-weight: bold;
    }
    margin-top: 1rem;
`;
