import React, { useRef, useState } from "react";
import styled from "styled-components";
import * as encrypt from "nodejs-base64-encode";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import BannerTask from "./components/BannerTask";
import StudentTask from "./components/StudentTask";
import TaskRender from "./components/TaskRender";
import SimpleLoading from "../../components/common/SimpleLoading";

// Material UI
import { Card } from "@mui/material";

// Hooks
import useSubmission from "../../hooks/teacher/useSubmission";

// Helpers
import { titleHTML } from "../../helpers/title.helper";

const SpeedGrader = () => {
    titleHTML("Calificador");
    const { hash } = useParams();

    const decode = encrypt.decode(hash, "base64");
    const data = JSON.parse(decode);

    const { activeRol } = useSelector((state) => state.auth);

    const { submission } = useSubmission(data.id_task, data.id_assignment);

    // Resizable
    const [leftWidthPercentage, setLeftWidthPercentage] = useState(70); // Ancho inicial del panel izquierdo
    const [backgroundColor, setBackgroundColor] = useState("#BBB");
    const [pointerEventsNone, setPointerEventsNone] = useState(false);
    const containerRef = useRef(null);
    const animationFrameRef = useRef(null);

    if (!submission) {
        return <SimpleLoading />;
    }

    const valuesCorrection = {
        comment_teacher: submission.professor_comment,
        grade: submission.grade,
        date: submission.date_of_correction,
    };

    const handleMouseDown = (e) => {
        const startX = e.clientX;
        const containerWidth = containerRef.current.getBoundingClientRect().width;
        const startLeftWidth = (leftWidthPercentage / 100) * containerWidth;

        const handleMouseMove = (e) => {
            setBackgroundColor("#c4c4c4")
            setPointerEventsNone(true)
            if (animationFrameRef.current) return; // Evitar múltiples llamadas en el mismo frame

            animationFrameRef.current = requestAnimationFrame(() => {
                const newLeftWidth = startLeftWidth + (e.clientX - startX);
                const newLeftWidthPercentage = (newLeftWidth / containerWidth) * 100;

                if (newLeftWidthPercentage > 100) {
                    setLeftWidthPercentage(100);
                } else if (newLeftWidthPercentage < 0) {
                    setLeftWidthPercentage(0);
                } else {
                    setLeftWidthPercentage(newLeftWidthPercentage);
                }

                animationFrameRef.current = null; // Liberar la referencia después de que se complete la animación
            });
        };

        const stopResizing = () => {
            setBackgroundColor("#BBB")
            setPointerEventsNone(false)
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', stopResizing);

            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
                animationFrameRef.current = null;
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', stopResizing);
    };

    return (
        <>
            <BannerTask student={submission.student} displayMode="mobile" />
            <SpeedGraderWrapper ref={containerRef} open={true}>
                <ViewActivity width={`${leftWidthPercentage}%`} pointerEventsNone={pointerEventsNone}>
                    <TitleActivity>{submission.resource.name}</TitleActivity>
                    <TaskRender
                        isText={!!submission.response}
                        isFile={!!submission.file}
                        name={submission?.resource?.name}
                        text={submission.response}
                        file={submission.file || null}
                    />
                </ViewActivity>
                <ResizableLine backgroundColor={backgroundColor} onMouseDown={handleMouseDown}>
                    <SimbolControl />
                </ResizableLine>
                <AsideContainer width={`${100 - leftWidthPercentage}%`}>
                    <BannerTask
                        student={submission.student}
                        displayMode="desktop"
                    />
                    <StudentTask
                        valuesCorrection={valuesCorrection}
                        state={submission?.state}
                        idTask={data?.id_task}
                        idAssignment={data?.id_assignment}
                        student={submission?.student}
                        activeRol={activeRol}
                        isRedo={!!submission?.redo}
                        history={submission?.other_submissions}
                        isFile={submission?.file || null}
                        createDate={submission?.create_date}
                        correctionDate={submission?.date_of_correction}
                        studentComment={submission?.student_comment}
                        correctionType={submission?.activity?.correction_type}
                        order={submission?.order}
                        teacherFiles={submission?.teacher_files}
                        grade={submission?.grade}
                    />
                </AsideContainer>
            </SpeedGraderWrapper>
        </>
    );
};

export default SpeedGrader;

const SpeedGraderWrapper = styled(Card)`
    border: solid 1px #c4c4c4;
    display: flex;
    height: 85vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    position: relative;

    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 24px;
        border: 0px;
        box-shadow: none;
    }
`;

const ViewActivity = styled.div`
    height: 100%;
    width: ${(props) => props.width};
    pointer-events: ${(props) => props.pointerEventsNone && "none"};

    @media (max-width: 1200px) {
        width: calc(100% - 2px);
        border: 1px solid #c4c4c4;
        border-radius: 0px 0px 20px 20px;
        div {
            box-shadow: none;
        }
    }
`;

const ResizableLine = styled.button`
    height: 100%;
    width: 6px;
    min-width: 6px;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 0px;
    cursor: col-resize;
    display: flex;
    align-items: center;
    justify-content: center;
`

const SimbolControl = styled.div`
    width: 100%;
    height: 2rem;
    background-color: #999;
`

const TitleActivity = styled.div`
    color: #fff;
    background-color: #b31d15;
    border-bottom: solid 1px #c4c4c4;
    width: calc(100% - 72px);
    display: flex;
    justify-content: center;
    padding: 19px 36px;
    font-size: 1.1rem;
`;

const AsideContainer = styled.div`
    height: 100%;
    width: ${(props) => props.width};

    @media (max-width: 1200px) {
        width: calc(100% - 2px);
        border: 1px solid #c4c4c4;
        border-radius: 20px;
        margin-bottom: 1rem;
    }
`;
