import React, { useState } from 'react';
import styled from 'styled-components';

import Navegation from '../navegation/Navegation';
import Menu from '../menu/Menu';

const LayoutCourse = (props) => {
    // STATE
    const [menu, setMenu] = useState(false);

    // FUNCTIONS
    function toggleMenu() {
        setMenu(!menu);
    }

    // RETURN
    return (
        <>
            <NavCointainer>
                <Navegation toggleMenu={toggleMenu} />
            </NavCointainer>
            <Overlay menu={menu}></Overlay>
            <Main menu={menu}>{props.children}</Main>
        </>
    );
};

export default LayoutCourse;

const NavCointainer = styled.div`
    position: fixed;
    z-index: 999;
    height: 100vh;
    top: 0;

    @media screen and (max-width: 768px) {
        height: 100vh;
    }
`;

const Main = styled.main`
    padding: 70px 0rem 0rem calc(0rem + 70px);
    overflow: ${(props) => props.menu && 'hidden'};

    @media (max-width: 1440px) {
        /* padding: 70px 0rem 0rem calc(0rem + 70px); */
    }

    @media (max-width: 768px) {
        /* padding: calc(2rem + 70px) 1rem 2rem 1rem; */
        padding: 70px 0px;
    }
`;

const Overlay = styled.div`
    display: ${(props) => (props.menu ? 'flex' : 'none')};
    visibility: ${(props) => (props.menu ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.menu ? 1 : 0)};
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000050;
    z-index: 9;
`;
