import React from 'react';
import { useHistory, Link } from 'react-router-dom';

const useRedirect = () => {
    const history = useHistory();

    function handleRedirection(path) {
        history.push(path);
    }

    return { handleRedirection };
};

export default useRedirect;
