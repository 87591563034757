import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import useTheme from '../../../hooks/useTheme';

const MenuItem = (props) => {
    const { to, icon, title, closeMenu, target, data_tut, link = false  } = props;

    const { primary } = useTheme();


    if (!!link) {
        return <Hiper
            href={to}
            color={primary}
            target={'_blank'}
        >
            <IconFake>
                {/* {isBeta && <NewSpan>BETA</NewSpan>} */}
                {icon}
            </IconFake>
            <Title>{title}</Title>
        </Hiper>
    }

    return (
        <MenuItemContainer
            exact
            to={to}
            color={primary}
            activeClassName="active"
            target={target}
            onClick={closeMenu}
            data-tut={data_tut}
        >
            <IconFake>
                {/* {isBeta && <NewSpan>BETA</NewSpan>} */}
                {icon}
            </IconFake>
            <Title>{title}</Title>
        </MenuItemContainer>
    );
};

export default MenuItem;

const MenuItemContainer = styled(NavLink)`
    display: flex;
    align-items: center;
    position: relative;
    transition: 0.1s ease-in-out all;
    padding: 1rem 0;
    margin: 4px 7px;
    svg,
    span {
        color: #999999;
    }

    &.active {
        background: #F9F9F9;
        box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
        border-radius: 20px;
        svg,
        span {
            color: ${(props) => props.color};
        }
        :hover {
            border-right: 0px solid ${(props) => props.color};
        }
    }

    @media (max-width: 768px) {
        justify-content: flex-start;
        column-gap: 1rem;
        padding: 1rem 0rem 1rem 2rem;
        width: 100%;
    }

    @media (max-width: 1400px) {
        svg {
            font-size: 1.2rem;
        }
        padding: 0.7rem 0;
    }
`;

const Hiper = styled.a`
    display: flex;
    align-items: center;
    position: relative;
    transition: 0.1s ease-in-out all;
    padding: 1rem 0;
    margin: 4px 7px;
    cursor: pointer;
    svg,
    span {
        color: #999999;
    }

    &.active {
        background: #F9F9F9;
        box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
        border-radius: 20px;
        svg,
        span {
            color: ${(props) => props.color};
        }
        :hover {
            border-right: 0px solid ${(props) => props.color};
        }
    }

    @media (max-width: 768px) {
        justify-content: flex-start;
        column-gap: 1rem;
        padding: 1rem 0rem 1rem 2rem;
        width: 100%;
    }

    @media (max-width: 1400px) {
        svg {
            font-size: 1.2rem;
        }
        padding: 0.7rem 0;
    }
`;

const Title = styled.span`
    display: none;

    @media (max-width: 768px) {
        font-size: 1.3rem;
        display: block;
    }
`;

const IconFake = styled.div`
    max-width: 45px;
`;
