import React, { useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import Text from "../../components/common/Text";
import DracmaHistory from "../../components/templates/dracma/DracmaHistory";
import MainDracma from "../../components/templates/dracma/MainDracma";
import UserDracma from "../../components/templates/dracma/UserDracma";
import { dracmaActions } from "../../redux/actions";
import MoreInformation from "../../components/ui/organisms/dracma/MoreInformation";
import HowToGetMorePoints from "../../components/ui/organisms/dracma/HowToGetMorePoints";
import { titleHTML } from "../../helpers/title.helper";
import useLayout from "../../hooks/useLayout";

const Dracma = () => {
    titleHTML("Competencia Dracma");

    // REDUX
    const dispatch = useDispatch();
    const dracmas = useSelector((state) => state.dracma.dracmas);
    const ondemand = useSelector((state) => state.ondemand.ondemand);


    const { setMenu, clearMenu } = useLayout()


    // EFFECTS
    useEffect(() => {
        if (!dracmas) {
            dispatch(dracmaActions.getDracmaRequest());
        }
    }, []);

    useEffect(() => {
        ondemand && ondemand.dracma_coin_name !== setMenu("")
                        ? setMenu(ondemand.dracma_coin_name)
                        : setMenu("Competencia Dracma")
    }, [ondemand])

    // RETURN
    if (!dracmas) {
        return null;
    }

    return (
        <Container>
            <Text
                fontSize="2rem"
                fontWeight="700"
                color="primary"
                component="h1"
            >
                {ondemand && ondemand.dracma_coin_name !== ""
                    ? `Competencia ${ondemand.dracma_coin_name}`
                    : "Competencia Dracma"}
            </Text>
            <MainWrapper>
                <ColumnWrapper>
                    {/** Informacion de usuario */}
                    <UserContainer>
                        <Header>
                            <Text
                                fontSize="1.25rem"
                                fontWeight="700"
                                color="secondary"
                            >
                                Mis puntos
                            </Text>
                            <MoreInformation
                                dracma_coin_name={
                                    ondemand
                                        ? ondemand.dracma_coin_name
                                        : undefined
                                }
                                withText={false}
                            />
                        </Header>
                        <UserDracma
                            dracma_coin_name={
                                ondemand ? ondemand.dracma_coin_name : undefined
                            }
                            dracmas={dracmas}
                            withBalance={false}
                        />
                        <HowToGetMorePoints
                            dracma_coin_name={
                                ondemand ? ondemand.dracma_coin_name : undefined
                            }
                            dracmas={dracmas}
                        />
                    </UserContainer>
                    {/** Historial de dracmas */}
                    <DracmaHistory />
                </ColumnWrapper>
                {/** Contenedor principal con ranking y beneficios  */}
                <MainDracma />
            </MainWrapper>
        </Container>
    );
};

export default Dracma;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const MainWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 7fr;
    grid-gap: 2rem;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;

    @media (max-width: 768px) {
        flex-direction: row;
        width: 100%;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;

const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (max-width: 768px) {
        width: calc(100% - 4rem);
    }
`;
