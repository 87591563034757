import styled from 'styled-components';
import Announcements from './announcements/Announcements';
import Pagination from './announcements/Pagination';
import { useEffect, useState } from 'react';
import AnnouncementSkeleton from './announcements/AnnouncementSkeleton';
import { Icon } from '@iconify/react';
import {
    getAnnouncementsByAssignmentId,
    patchAnnouncements,
    postAnnouncements,
} from '../../../../redux/api/announcements';
import NotFoundImg from '../../../../assets/media/aden/not_found.png';
import { announcementSchema } from '../../../../utils/schemas';
import { useSelector } from 'react-redux';
import PopUpSuccess from './announcements/PopUpSuccess';
import ModalCreateAnnouncement from './announcements/ModalCreateAnnouncement';

const SubjectAnnouncements = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { id, professor } = props;

    const [announcementsData, setAnnouncementsData] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [succesAction, setSuccesAction] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const pageMax = Math.ceil(announcementsData.length / 4);

    const { repo_id } = useSelector((state) => state.auth.user);
    const { activeRol } = useSelector((state) => state.auth);

    const initialValues = {
        title: '',
        message: '',
        published_date: '',
        expiration_date: '',
        asignatura_id: id,
    };

    useEffect(() => {
        if (!announcementsData) {
            getAnnouncements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcementsData]);

    const getAnnouncements = async () => {
        const response = await getAnnouncementsByAssignmentId(id);
        if (response.error) {
            console.error(response.error);
            return;
        }
        setAnnouncementsData(response.data);
    };

    const validateDates = (published_date, expiration_date) => {
        const today = new Date();
        const publishedDate = new Date(published_date + ' 23:59:00');
        publishedDate.setDate(publishedDate.getDate());
        const expirationDate = new Date(expiration_date + ' 23:59:00');
        expirationDate.setDate(expirationDate.getDate());

        if (today > publishedDate) {
            return {
                isValid: false,
                error: 'La fecha de publicación debe ser igual o posterior a la fecha actual',
            };
        }

        if (publishedDate > expirationDate) {
            return {
                isValid: false,
                error: 'La fecha de caducidad debe ser posterior a la fecha de publicación',
            };
        }

        return { isValid: true };
    };

    const handleSubmitAnnouncement = async (values) => {
        const { isValid, error } = validateDates(
            values.published_date,
            values.expiration_date
        );
        if (isValid && !loading) {
            setLoading(true);
            try {
                await postAnnouncements(repo_id, values);
                setLoading(false);
                setOpenModal(false);
                setSuccesAction('publicó');
                setOpenModalSuccess(true);
            } catch (error) {
                setLoading(false);
                setError('Error, por favor intente de nuevo');
            }
        } else {
            setError(error);
        }
    };

    const handleSubmitAnnouncementEdited = async (values) => {
        const { isValid, error } = validateDates(
            values.published_date,
            values.expiration_date
        );
        const newsletter_id = values.id;
        const data = {
            active: true,
            title: values.title,
            message: values.message,
            expiration_date: values.expiration_date,
            published_date: values.published_date,
        };
        if (!isValid) {
            setError(error);
            return;
        }
        try {
            await patchAnnouncements(newsletter_id, data);
            setOpenEditModal(false);
            setSuccesAction('editó');
            setOpenModalSuccess(true);
        } catch (error) {
            setError('Error, por favor intente de nuevo');
        }
    };

    //unicamente se despublica, luego de ello el EP solo envia anuncios publicados por el profesor para su edición.
    const handleAnnouncementUnpublishing = async () => {
        const newsletter_id = Number(openEditModal.id);
        const data = {
            active: false,
        };
        setLoading(true);
        try {
            await patchAnnouncements(newsletter_id, data);
            setLoading(false);
            setOpenEditModal(false);
            setSuccesAction('despublicó');
            setOpenModalSuccess(true);
        } catch (error) {
            setLoading(false);
            setError('Error, por favor intente de nuevo');
        }
    };

    if (!announcementsData) {
        return (
            <Wrapper>
                <AnnouncementSkeleton />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {professor ? (
                <>
                    <h1>Crea un anuncio y mantén a tus alumnos informados</h1>
                    <Button onClick={() => setOpenModal(true)}>
                        <Icon
                            icon="foundation:megaphone"
                            width="1.5em"
                            height="1.5em"
                            style={{ color: '#fff' }}
                        />
                        Crear nuevo anuncio
                    </Button>
                </>
            ) : (
                <h1>Anuncios correspondientes a esta asignatura:</h1>
            )}
            {openModalSuccess && !!succesAction && (
                <PopUpSuccess
                    setOpenModal={setOpenModalSuccess}
                    actionSucces={succesAction}
                    setAnnouncementsData={setAnnouncementsData}
                />
            )}
            {/* Creación de anuncio */}
            {openModal && (
                <ModalCreateAnnouncement
                    initialValues={initialValues}
                    handleSubmitAnnouncement={handleSubmitAnnouncement}
                    announcementSchema={announcementSchema}
                    setOpenModal={setOpenModal}
                    loading={loading}
                    error={error}
                    type="create"
                />
            )}
            {/* Edición de anuncio */}
            {openEditModal && (
                <ModalCreateAnnouncement
                    initialValues={openEditModal}
                    handleSubmitAnnouncementEdited={
                        handleSubmitAnnouncementEdited
                    }
                    handleAnnouncementUnpublishing={
                        handleAnnouncementUnpublishing
                    }
                    announcementSchema={announcementSchema}
                    setOpenModal={setOpenEditModal}
                    loading={loading}
                    error={error}
                    type="edit"
                />
            )}
            {announcementsData && announcementsData.length > 0 ? (
                <>
                    <Announcements
                        initialValues={announcementsData}
                        announcementsData={
                            Array.isArray(announcementsData)
                                ? announcementsData.slice(
                                      page * 4 - 4,
                                      page * 4
                                  )
                                : []
                        }
                        setAnnouncementsData={setAnnouncementsData}
                        professor={professor}
                        setOpenEditModal={setOpenEditModal}
                        rol={activeRol}
                    />
                    <Pagination
                        page={page}
                        setPage={setPage}
                        pageMax={pageMax}
                    />
                </>
            ) : (
                <WrapperNotFound>
                    <img src={NotFoundImg} alt="not-found" />
                    <p>
                        Aún no tienes anuncios en <br /> tu bandeja de entrada
                    </p>
                </WrapperNotFound>
            )}
        </Wrapper>
    );
};

export default SubjectAnnouncements;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;

    h1 {
        color: #616161;
        font-size: 20px;
        font-weight: 500;
    }
`;

const WrapperNotFound = styled.div`
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 80vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
        text-align: center;
    }
`;

const Button = styled.button`
    cursor: pointer;
    border-radius: 5px;
    margin-top: 2rem;
    margin-left: 2rem;
    background-color: #b31d15;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    :hover {
        background-color: #cd2118;
    }
`;
