import React, { useState, useEffect } from "react";
import { getHighlight, postHighlight } from "../../../redux/api/students"
import styled from "styled-components";
import { Img } from "../Image";
import { Text } from "../Texts";
import SimpleLoading from "../SimpleLoading";
import AccordionLeft from "../../templates/teacher/persons/student/AccordionLeft";
import HighlightStudentModal from "../../ui/molecules/profile/teacher/HighlightStudentModal";
import ChatToTeacher from "./ChatToTeacher";
import SocialMediaToCardTeacher from "./SocialMediaToCardTeacher";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../../utils/snackStyles";


const StudentCardForTeacher = (props) => {

    const { generalInfo, publicProfileData, academicTraining, professionalInfo } = props;

    const [highlight, setHighlight] = useState(null);
    const [show, setShow] = useState(false);
    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        !highlight && isStudentHighlight();
    }, [highlight]);

    const isStudentHighlight = async () => {
        const request = await getHighlight(
            publicProfileData?.repo_id || 0,
            parseInt(publicProfileData?.partner_id, 10),
            publicProfileData?.personal_email
        );
        if (request === true || request === false) {
            setHighlight(request);
        } else {
            console.log('error');
        }
    };

    const handleHighlight = async (reason) => {
        const body = {
            comment: `${reason}`,
            saved_by: `${publicProfileData?.repo_id}`,
            repo_id: `${publicProfileData?.repo_id || 0}`,
            crm_id: `${parseInt(publicProfileData?.partner_id, 10)}`,
            email: `${publicProfileData?.personal_email}`
        };
        const request = await postHighlight(body);

        if (request.error) {
            openSnackbar('¡No se pudo destacar el alumno!');
        } else {
            openSnackbar('¡Alumno destacado exitosamente!');
        }
        setShow(true);
        isStudentHighlight();
    };

    //RETURNS
    if (
        generalInfo === null ||
        publicProfileData === null
    ) {
        return <SimpleLoading />;
    }
    return (
        <CardFake>
            <Wrapper>
                <Student>

                    <Header>
                        <ImgFake
                            w="100%"
                            height="100%"
                            loading="lazy"
                            src={publicProfileData?.foto}
                        />
                        <DetailsText>
                            <Text
                                fontSize="1.25rem"
                                fontWeight="bold"
                                color="#222222"
                            >
                                {publicProfileData.name}
                            </Text>
                            <Text
                                fontSize="0.8rem"
                                fontWeight="bold"
                                color="#616161"
                            >
                                #C{publicProfileData.partner_id || 'XXXX'}-R{publicProfileData.repo_id || 'XXXX'}-S{publicProfileData.sis_id || 'XXXX'}
                            </Text>
                        </DetailsText>
                    </Header>

                    <Footer>
                        <DetailsText>
                            <Text
                                fontSize="0.8rem"
                                fontWeight="bold"
                                color="#616161"
                            >
                                BIOGRAFIA
                            </Text>
                            <Text
                                fontSize="0.8rem"
                                fontWeight="400"
                                color="#222222"
                            >
                                {publicProfileData.biography}
                            </Text>
                        </DetailsText>
                    </Footer>

                    <Buttons>
                        <ChatToTeacher value={publicProfileData} />
                        <HighlightStudentModalStyle
                            title={
                                !!highlight
                                    ? 'Alumno destacado'
                                    : 'Destacar alumno'}
                            student={publicProfileData.name}
                            show={show}
                            handleHighlight={handleHighlight}
                            backgroundColor={!!highlight
                                ? "#FFBC00"
                                : "#ffff"}
                            textColor={!!highlight
                                ? "#ffff"
                                : "#B31D15"}
                            border={!!highlight
                                ? "#FFBC00"
                                : "#A8A8A8"}
                        />
                    </Buttons>

                    <WrapperSocial>
                        <SocialMediaToCardTeacher value={publicProfileData} />
                    </WrapperSocial>

                </Student>

                <AccordionLeft
                    generalInfo={generalInfo}
                    publicProfileData={publicProfileData}
                    academicTraining={academicTraining}
                    professionalInfo={professionalInfo}
                />

            </Wrapper>
        </CardFake>
    );
};

export default StudentCardForTeacher;

const CardFake = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: calc(100% - 3rem);
    padding: 1.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    gap: 1.5rem;
    align-items: center;
    border-radius: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
`;

const Student = styled.div`
    display: grid;
    align-items: start;
    gap: 0.5rem;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;
`;

const Footer = styled.div`
    gap: 1.5rem;
    line-height: 140%;
`;

const HighlightStudentModalStyle = styled(HighlightStudentModal)`
    text-align: center;
    margin: auto;
    width: 100%;
`;

const DetailsText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div {
        display: flex;
        align-items: center;
        gap: 0.3rem;

        svg {
            margin-top: 2.5px;
            font-size: 1.2rem;
        }
    }
`;

const ImgFake = styled(Img)`
    border-radius: 50%;
    width: 64px;
    height: 64px;
    object-fit: cover;
`;


const WrapperSocial = styled.div`
    height: 50px;
    width: calc(100% - 2rem);
    display: flex;
    justify-content: space-between;
    padding: 4px 1rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    
    svg{
        color: #A8A8A8;
    }
`;

const Buttons = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    width: 100%;
    gap: 0.5rem;
`;