import React from "react";
import styled from "styled-components";
import { useState } from "react";
//import AnalyticsAA from '../../components/templates/academic/allStudents/AnalyticsAA';
import TabsComponent from "../../components/common/TabsComponent";
//import GridCardWhithSocialMedia from '../../components/templates/academic/allStudents/GridCardWhithSocialMedia';
import { titleHTML } from "../../helpers/title.helper";
//import FilterStudentsStatus from '../../components/templates/academic/allStudents/FilterStudentsStatus';
import StudentsNowAA from "../../components/templates/academic/allStudents/StudentsNowAA";

const AcademicStudents = () => {
    titleHTML("Alumnos");

    const tabs = [
        { label: `Alumnos`, id: 0 },
        // { label: `Analítica`, id: 1 }
    ];

    const [tab, setTab] = useState(0);

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
    };

    function ToggleMain(props) {
        const { tab } = props;
        const cases = {
            0: <StudentsNowAA />,
            // 1: <AnalyticsAA />
        };

        return cases[String(tab)] || <StudentsNowAA />;
    }

    return (
        <Container>
            <WrapperTop>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            </WrapperTop>
            <BodyWrapper>
                <ToggleMain tab={tab} />
            </BodyWrapper>
        </Container>
    );
};

export default AcademicStudents;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const WrapperTop = styled.div`
    position: absolute;
    top: 70px;
    left: 70px;
    width: calc(100% - 70px);
    background: #ffffff;
    z-index: 6;

    @media (max-width: 768px) {
        left: 0;
    }
`;

const BodyWrapper = styled.div`
    padding: 2rem;
    width: calc(100% - 4rem);
    border-radius: 20px;
    height: 800px;
    overflow: auto;
`;
