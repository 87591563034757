import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import DefaultField from "../../../common/DefaultField";
import SimpleLoading from "../../../common/SimpleLoading";
import { utilsActions } from "../../../../redux/actions";
import DefaultComplete from "../../../common/DefaultComplete";
import Select from "@mui/material/Select";
import {
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
} from "@mui/material";
import AcademicOptionPhoto from "./AcademicOptionPhoto";
import DefaultPhone from "../../../common/DefaultPhone";
const RADIOOPTIONS = [
    { value: "F", label: "Femenino" },
    { value: "M", label: "Masculino" },
    { value: "O", label: "Otro" },
];

const InputEditProfile = (props) => {
    const {
        values,
        setFieldValue,
        setInfoComplete,
        handleChange,
        ws,
        setWsSelect,
        setCompanions,
        wsSelect,
        companions,
        id,
    } = props;
    // REDUX
    const dispatch = useDispatch();

    const docTypes = useSelector((state) => state.utils.docTypes);
    const nationalities = useSelector((state) => state.utils.nationalities);
    const countries = useSelector((state) => state.utils.countries);
    const jobs = useSelector((state) => state.utils.jobsOptions);
    const user = useSelector((state) => state.auth.user);

    // FUNCTION
    useEffect(() => {
        const handleChangeSelect = () => {
            ws.forEach((w) => {
                if (w.id === id) {
                    setWorkshop(w);
                }
            });
            setWsSelect(id);
        };
        handleChangeSelect();
    }, []);
    const handleChangeInput = (event) => {
        let number = parseInt(event.target.value, 10);
        if (number > 2) {
            seterr(true);
            setCompanions(0);
        } else {
            seterr(false);
            setCompanions(number);
        }
    };

    // STATES
    const [workshop, setWorkshop] = useState(null);
    const [err, seterr] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (docTypes === null) {
            dispatch(utilsActions.getDocTypesRequest());
        }
        if (nationalities === null) {
            dispatch(utilsActions.getNationalitiesRequest());
        }
        if (countries === null) {
            dispatch(utilsActions.getCountriesRequest());
        }
        if (jobs === null) {
            dispatch(utilsActions.getJobsOptionsRequest());
        }
    }, []);

    // RETURN
    if (!docTypes || !nationalities || !countries || !jobs) {
        return <SimpleLoading padding="8rem" />;
    }
    // STATE LOGICA CAMPOS COMPLETOS
    if (
        !values.work_email ||
        !values.company ||
        !values.street ||
        !values.current_job.name ||
        !values.zip ||
        !values.nationality.name ||
        !values.city ||
        !values.country.name ||
        values.phone.length <= 5
    ) {
        setInfoComplete(false);
    } else {
        setInfoComplete(true);
    }
    if (wsSelect === null || companions === null) {
        setInfoComplete(false);
    }
    if (wsSelect === null) {
        setInfoComplete(false);
    }
    return (
        <OverflowWrappper>
            <Container>
                {/** Photo */}
                <AcademicOptionPhoto />
                {/** User Information */}
                <InputWrapper>
                    {/** Email laboral */}
                    <DefaultField
                        name="work_email"
                        type="email"
                        label="Email laboral"
                        onChange={handleChange}
                        value={values.work_email}
                        setFieldValue={setFieldValue}
                    />
                    {/** Empresa actual */}
                    <DefaultField
                        name="company"
                        type="text"
                        label="Empresa actual"
                        value={values.company}
                    />
                    {/** Cargo actual */}
                    <DefaultComplete
                        name="current_job"
                        label="Cargo actual"
                        value={values.current_job}
                        setFieldValue={setFieldValue}
                        options={jobs}
                    />
                    {/** Direccion */}
                    <DefaultField
                        name="street"
                        type="text"
                        label="Dirección"
                        value={values.street}
                    />
                    {/** Codigo Postal */}
                    <DefaultField
                        name="zip"
                        type="number"
                        label="Código Postal"
                        value={values.zip}
                    />
                    {/** Ciudad de residencia */}
                    <DefaultField
                        name="city"
                        type="text"
                        label="Ciudad de residencia"
                        value={values.city}
                    />
                    {/** Pais de residencia */}
                    <DefaultComplete
                        name="country"
                        label="País de residencia"
                        value={values.country}
                        setFieldValue={setFieldValue}
                        options={countries}
                    />
                    {/** Nacionalidad */}
                    <DefaultComplete
                        name="nationality"
                        label="Nacionalidad"
                        value={values.nationality}
                        setFieldValue={setFieldValue}
                        options={nationalities}
                    />

                    {/** Celular */}
                    <DefaultPhone
                        name="phone"
                        label="Whatsapp"
                        value={values.phone}
                        setFieldValue={setFieldValue}
                    />
                    <div></div>
                    <FakeFormControl>
                        <InputLabel id="demo-simple-select-label">
                            Workshops disponibles
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={id}
                            //   onChange={handleChangeSelect}
                        >
                            {ws.map((w, index) => (
                                <MenuItem
                                    disabled={w.id === id ? false : true}
                                    key={index}
                                    value={w.id}
                                >{`${
                                    w.name
                                } | Modalidad ${w.modalidad.toUpperCase()}`}</MenuItem>
                            ))}
                        </Select>
                    </FakeFormControl>
                    {workshop && workshop.modalidad === "presencial" && (
                        <FakeFormControl>
                            <TextField
                                error={err}
                                type="number"
                                onChange={handleChangeInput}
                                value={companions}
                                placeholder="N° acompañantes min(0) max(2)"
                                id="standard-basic"
                                label="Cantidad de acompañantes"
                                helperText={err ? "*valores entre 0 y 2" : ""}
                                color="secondary"
                            />
                        </FakeFormControl>
                    )}
                </InputWrapper>
            </Container>
        </OverflowWrappper>
    );
};

export default InputEditProfile;

const FakeFormControl = styled(FormControl)`
    width: 100%;
    margin: 1rem 0;
`;
const OverflowWrappper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const Container = styled.div`
    display: flex;
    grid-gap: 2rem;
    padding: 2rem 1rem 2rem 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 2rem 1rem;
    }

    @media (max-width: 425px) {
        padding: 2rem 0;
    }
`;

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    width: 100%;
    height: max-content;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
    }
`;

// const FullField = styled(DefaultField)`
//   grid-column: span 2;
// `;

// const CustomFormControl = styled(FormControl)`
//   grid-column: span 2;
// `;
