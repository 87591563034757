import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';

const ModalCreateAnnouncement = (props) => {
    const {
        initialValues,
        handleSubmitAnnouncement,
        handleSubmitAnnouncementEdited,
        handleAnnouncementUnpublishing,
        announcementSchema,
        setOpenModal,
        loading,
        error,
        type,
    } = props;

    const formatDate = (date) => {
        const [year, month, day] = date.split('-');
        return [year, month, day].join('-');
    };

    const handleSubmit = (values) => {
        if (type === 'create') {
            handleSubmitAnnouncement(values);
        }
        if (type === 'edit') {
            handleSubmitAnnouncementEdited(values);
        }
    };

    return (
        <Modal>
            <Header>
                Nuevo anuncio
                {type === 'edit' && (
                    <UbpublishButton onClick={handleAnnouncementUnpublishing}>
                        Despublicar
                    </UbpublishButton>
                )}
            </Header>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={announcementSchema}
            >
                {({ values, setFieldValue }) => (
                    <FormData>
                        <label>Asunto</label>
                        <Input
                            name="title"
                            type="text"
                            label="Asunto"
                            placeholder="Introduce un título"
                            value={values.title}
                            setFieldValue={setFieldValue}
                            required
                        />
                        <label>Anuncio</label>
                        <Field
                            name="message"
                            as={InputTextArea}
                            label="Anuncio"
                            placeholder="Redacta tu anuncio"
                            value={values.message}
                            setFieldValue={setFieldValue}
                            required
                        />
                        <label>Fecha de publicación y caducidad</label>
                        <p>
                            Si deseas puedes programar cuándo quieres que se
                            publique y despublique el anuncio.
                        </p>
                        <section>
                            <div>
                                <label>Fecha de publicación:</label>
                                <Input
                                    name="published_date"
                                    type="date"
                                    label="Asunto"
                                    placeholder="Introduce un título"
                                    value={formatDate(values.published_date)}
                                    setFieldValue={setFieldValue}
                                    required
                                />
                            </div>
                            <div>
                                <label>Fecha de caducidad:</label>
                                <Input
                                    name="expiration_date"
                                    type="date"
                                    label="Asunto"
                                    placeholder="Introduce un título"
                                    value={formatDate(values.expiration_date)}
                                    setFieldValue={setFieldValue}
                                    required
                                />
                            </div>
                        </section>
                        <ButtonsWrapper>
                            <ModalButton
                                variant="secondary"
                                onClick={() => setOpenModal(false)}
                            >
                                Cancelar
                            </ModalButton>
                            <ModalButton
                                type="submit"
                                onClick={() => handleSubmit(values)}
                            >
                                {loading
                                    ? 'Cargando...'
                                    : type === 'create'
                                    ? 'Publicar anuncio'
                                    : 'Editar anuncio'}
                            </ModalButton>
                        </ButtonsWrapper>
                    </FormData>
                )}
            </Formik>
            {!!error && <ErrorMsg>{error}</ErrorMsg>}
        </Modal>
    );
};

export default ModalCreateAnnouncement;

const Modal = styled.div`
    position: fixed;
    width: calc(50vw - 4rem);
    min-height: calc(70vh - 4rem);
    max-height: 1000px;
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 9999;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding: 2rem;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    label {
        font-size: 18px;
        font-weight: 700;
        color: #222;
    }

    section {
        display: flex;
        flex-direction: row;
        gap: 2rem;

        div {
            display: flex;
            flex-direction: column;

            label {
                font-family: Lato;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.005em;
                text-align: left;
                color: #616161;
            }

            input {
                width: 312px;
            }
        }

        /* @media (max-width: 1400px) {
            flex-direction: column;
        } */
    }

    @media (width < 1540px) {
        top: 22rem;
        /* overflow: scroll; */
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    font-size: 24px;
    font-weight: 700;
    color: #b31d15;
    margin-left: 0;
`;

const FormData = styled(Form)`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const InputTextArea = styled.textarea`
    width: calc(50vw - 8rem);
    max-height: 300px !important;
    height: 300px !important;
    border: 1px solid #a2a2a2;
    border-radius: 10px;
    padding: 1rem 1rem;
    font-size: 16px;
    box-sizing: border-box;
    resize: none;
    scrollbar-color: transparent transparent;
    line-height: 24px;

    @media (width <= 1540px) {
        height: 130px !important;
    }
`;

const Input = styled(Field)`
    width: calc(50vw - 8rem);
    max-height: 700px;
    height: auto;
    border: 1px solid #a2a2a2;
    border-radius: 10px;
    padding: 1rem 1rem;
    font-size: 16px;
    box-sizing: border-box;
    resize: none;
    scrollbar-color: transparent transparent;
    line-height: 24px;
`;

const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 1rem;
`;

const ModalButton = styled.button`
    cursor: pointer;
    border-radius: 8px;
    background-color: ${(props) =>
        props.variant === 'secondary' ? '#fff' : '#b31d15'};
    color: ${(props) => (props.variant === 'secondary' ? '#b31d15' : '#fff')};
    font-size: 14px;
    font-weight: 700;
    min-width: 150px;
    height: 40px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: ${(props) => props.variant === 'secondary' && '1px solid #b31d15'};

    :hover {
        background-color: ${(props) =>
            props.variant !== 'secondary' && '#CD2118'};
    }
`;

const ErrorMsg = styled.div`
    color: #cd2118;
    font-weight: 700;

    @media (width <= 1540px) {
        font-size: 14px;
    }
`;

const UbpublishButton = styled.button`
    cursor: pointer;
    border-radius: 8px;
    background-color: #b31d15;
    color: #fafafa;
    font-size: 14px;
    font-weight: 700;
    min-width: 150px;
    height: 40px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: 1px solid #b31d15;
    :hover {
        background-color: #cd2118;
    }
`;
