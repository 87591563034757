import React, { useEffect } from "react";

import { IconButton } from "@mui/material";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import ReloadIcon from "@mui/icons-material/Cached";
import Popover from "@mui/material/Popover";
import styled from "styled-components";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import ContactsRounded from "@mui/icons-material/ContactsRounded";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Button } from "@mui/material";
import useTheme from "../../../hooks/useTheme";
import { postNotification } from "../../../redux/api/notifications";

const Notifications = (props) => {
    const { notifications, refreshNotification } = props;

    // STATES
    const [noti, setNoti] = useState(false);
    const [unReadMessage, setUnReadMessage] = useState([]);
    const [alert, setAlert] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [allRead, setAllRead] = useState(false);
    const { primary } = useTheme();

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    // EFFECTS
    useEffect(() => {
        if (notifications) {
            sortChannel(notifications);
            CountFilter();
        }
        // eslint-disable-next-line eqeqeq
        if (notifications.length == 0) {
            setAlert("No encontramos notificaciones");
        }
        return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    // FUNCTIONS
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const sortChannel = (notification) => {
        let result = notification.sort((a, b) => a.is_open - b.is_open);
        setNoti(result);
    };

    const CountFilter = () => {
        setUnReadMessage(
            notifications.filter(
                (notification) => notification.is_open === false
            )
        );
    };

    const handleGoTo = async (launch_url, id) => {
        const req = await postNotification(id);

        if (req.error) {
            console.error(req.error);
        }

        noti.forEach((notification) => {
            if (notification.id === id) {
                notification.is_open = true;
                refreshNotification();
            }
        });

        if (launch_url) {
            window.location.href = launch_url;
        }
    };

    const handleAllRead = () => {
        noti.forEach((notification) => {
            if(notification.is_open === false) {
                handleGoTo(false, notification.id);
            }
            setAllRead(true);
            setUnReadMessage([]);
        });
    };

    // console.log("notificaciones", notifications);
    return (
        <div>
            <IconButton
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleClick}
                size="large">
                <NotificationsRoundedIcon htmlColor="#bfbfbf" />
                {anchorEl == null && noti && noti.length > 0 ? (
                    unReadMessage.length > 0 ? (
                        <Count color={primary}>{unReadMessage.length}</Count>
                    ) : null
                ) : null}
            </IconButton>
            <PopoverFake
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <HeaderNotifications color={primary}>
                    <Title>Notificaciones</Title>
                    <Buttons>
                        <ButtonReload onClick={refreshNotification}>
                            <RefreshIcon color="white" />
                        </ButtonReload>
                        <Link onClick={handleClose} to="/notificaciones">
                            <OpenNotifications />
                        </Link>
                    </Buttons>
                </HeaderNotifications>
                <BodyNotification>
                    {noti &&
                        noti.map((notification, i) => {
                            return (
                                <WrapperNotification
                                    read={notification.is_open}
                                    allRead={allRead}
                                    launch_url={notification.launch_url}
                                    onClick={() =>
                                        handleGoTo(
                                            notification.launch_url,
                                            notification.id
                                        )
                                    }
                                    key={i}
                                >
                                    <Header>
                                        <TitleMsg
                                            read={notification.is_open}
                                            allRead={allRead}
                                        >
                                            {notification.title}
                                        </TitleMsg>
                                        <Hour>{notification.date}</Hour>
                                    </Header>
                                    <Descriptions>
                                        <Msg>{notification.message}</Msg>
                                    </Descriptions>
                                </WrapperNotification>
                            );
                        })}

                    {/* Si no trae ninguna notifiación */}
                    {!noti ||
                        noti === undefined ||
                        // eslint-disable-next-line eqeqeq
                        (noti.length == 0 && (
                            <NoNotiication>
                                <ReloadIconFake />
                                <Alert>{alert}</Alert>
                                <CustomButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<ContactsRounded />}
                                    onClick={refreshNotification}
                                >
                                    Actualizar
                                </CustomButton>
                            </NoNotiication>
                        ))}
                </BodyNotification>
                <FooterNotifications>
                    {unReadMessage.length > 0 && (
                        <ViewNotification
                            color={primary}
                            onClick={() => {
                                handleAllRead();
                            }}
                        >
                            Marcar todo como leído
                        </ViewNotification>
                    )}
                </FooterNotifications>
            </PopoverFake>
        </div>
    );
};

export default Notifications;

const PopoverFake = styled(Popover)`
    .MuiPaper-root {
        border-radius: 20px;
        background-color: #f9f9f9;
        @media (max-width: 768px) {
            width: 100vw;
        }
    }
`;

const Count = styled.h1`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1rem;
    height: 1rem;
    font-size: 0.7rem;
    color: white;
    background-color: ${(p) => p.color};
    position: absolute;
    border-radius: 500px;
    top: 0.3rem;
    right: 0.3rem;
`;

const HeaderNotifications = styled.div`
    height: 2.8rem;
    width: 20rem;
    background-color: ${(p) => p.color};
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: calc(100% - 24px);
    }
`;

const FooterNotifications = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.06);
    height: 2.5rem;
    background-color: #ffffff;

    @media (max-width: 768px) {
        height: 3.5rem;
    }
`;

const Title = styled.h6`
    color: white;
    font-size: 1.2rem;
`;

const OpenNotifications = styled(OpenInNewSharpIcon)`
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
`;

const RefreshIcon = styled(ReloadIcon)`
    cursor: pointer;
    color: white;
    font-size: 1.35rem;
    display: flex;
    align-items: center;
`;

const ViewNotification = styled.button`
    color: ${(p) => p.color};
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const BodyNotification = styled.div`
    width: 100%;
    height: 25rem;
    overflow-y: auto;
    padding: 0.3rem 0.6rem;
    box-sizing: border-box;
    scrollbar-color: transparent transparent;
`;

const WrapperNotification = styled.button`
    width: 100%;
    min-height: 66px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #f1f1f1;
    box-shadow: ${(p) =>
        p.allRead
            ? "none"
            : p.read
            ? "none"
            : "0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)"};
    border-radius: 20px;
    background-color: ${(p) =>
        p.allRead ? "#F9F9F9" : p.read ? "#F9F9F9" : "#fff"};
    margin: 0.5rem auto;
    padding: 0.8rem;
    cursor: ${(p) => (p.launch_url ? "pointer" : "default")};
    position: relative;
    overflow: hidden;

    ::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        height: 100%;
        width: 9px;
        background-color: ${(p) => (p.read ? "#d9d9d9" : "#35D0A5")};
    }
`;

const Header = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0.7rem;
`;

const TitleMsg = styled.h6`
    color: ${(p) => (p.allRead ? "#616161" : p.read ? "#616161" : "#222222")};
    font-size: 0.8rem;
    font-weight: bold;
    max-width: 150px;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Msg = styled.h6`
    color: #616161;
    font-size: 0.8rem;
    text-align: start;
    white-space: wrap;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const Descriptions = styled.div`
    height: 100%;
    width: 18rem;
`;

const Hour = styled.div`
    color: #a8a8a8;
    font-size: 0.8rem;
    font-weight: bold;
`;

const NoNotiication = styled.div`
    color: #222222;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
    gap: 1rem;
    height: 100%;
`;

const ReloadIconFake = styled(ReloadIcon)`
    color: #a8a8a8;
    margin: 0 auto;
    width: 100%;
    font-size: 3rem;
    text-align: center;
`;

const ButtonReload = styled.button`
    border: none;
    outline: none;
    padding: 0;
    background: none;
`;

const CustomButton = styled(Button)`
    color: #ffffff;
    background-color: ${(p) => p.color};
    margin-top: 1rem;
    width: fit-content;
    margin: 1rem auto 0 auto;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const Alert = styled.p`
    color: #616161;
    font-size: 1.2rem;
    font-weight: bold;
`;
