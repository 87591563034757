import styled from "styled-components";

// Components
import GenericChips from "../../../../components/common/GenericChip";

const StudentTaskHeader = (props) => {

    const { correctedSubmission, isRedo, redo, handleNavigate, note, old, history, idAssignment, grade, correctionWithCheck } = props;

    const link = `{"id_task":${history[history.length - 1]?.id.toString()},"id_assignment":${idAssignment.toString()}}`

    const base64Link = Buffer.from(link).toString('base64');

    return (
        <Header>
            {
                old &&
                <FirstSection>
                    <LastSubmissionButton href={`/profesor/calificador/${base64Link}`}>Volver a la última entrega</LastSubmissionButton>
                </FirstSection>
            }
            <SecondSection>
                {
                    correctedSubmission ?
                        <GenericChips
                            radius="5px"
                            textColor={
                                !!isRedo || !!redo || ((grade < 80) && (note < 80) && !correctionWithCheck) ? '#B31D15' : '#1E8065'
                            }
                            fontSize="15px"
                            fontWeight="bold"
                            color={!!isRedo || !!redo || ((grade < 80) && (note < 80) && !correctionWithCheck) ? '#FCE9E8' : '#EAFAF6'}
                            padding="1rem 10px"
                            title={
                                !!isRedo || !!redo || ((grade < 80) && (note < 80) && !correctionWithCheck)
                                    ? 'Rehacer actividad'
                                    : 'Actividad aprobada'
                            }
                        />
                        :
                        <GenericChips
                            radius="5px"
                            textColor="#C29F43"
                            fontSize="15px"
                            fontWeight="bold"
                            color="#F9F5EC"
                            padding="1rem 10px"
                            title="Pendiente de corrección"
                        />
                }
                <CustomButton onClick={handleNavigate}>
                    Ver perfil
                </CustomButton>
            </SecondSection>
        </Header>
    )
}

export default StudentTaskHeader;

const Header = styled.header`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;

    @media (max-width: 1200px) {
        border-bottom: 0px;
    }
`;

const FirstSection = styled.div`
    width: calc(100% - 12px);
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 12px 12px 0 0;
`

const LastSubmissionButton = styled.a`
    cursor: pointer;
    height: 40px;
    background-color: #CD2118;
    border-radius: 8px;
    padding: 0 16px 0 24px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        background-color: #B31D15;
    }
`

const SecondSection = styled.div`
    display: flex;
    align-items: center;
    padding: 1.5rem;
    justify-content: space-between;
    gap: 10px;
`

const CustomButton = styled.button`
    color: #616161;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    :hover {
        color: #b31d15;
    }
`;
