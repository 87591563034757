import React, { useEffect } from 'react';
import styled from 'styled-components';

import WorkshopsCards from './WorkshopsCards';
import { useDispatch, useSelector } from 'react-redux';
import { workshopsActions } from '../../../../redux/actions';
import SimpleLoading from '../../../../components/common/SimpleLoading';

const NextWorkshops = () => {
    // REDUX
    const dispatch = useDispatch();
    const workshops = useSelector((state) => state.workshops.workshops);
    const listLoading = useSelector((state) => state.workshops.listLoading);

    // EFFECTS
    useEffect(() => {
        if (workshops === null) {
            dispatch(workshopsActions.getWorkshopsRequest());
        }
    }, []);

    // RETURN
    if (workshops === null || listLoading) {
        return <SimpleLoading padding="2rem" />;
    }

    return (
        <Section id="workshops">
            <WrapperTitle>
                <Title>Cursos abiertos</Title>
            </WrapperTitle>
            <WorkshopsCards workshops={workshops} />
        </Section>
    );
};

export default NextWorkshops;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding-bottom: 2rem;
`;

const WrapperTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    padding: 3rem 0;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 900;
    color: #b31d15;
    text-align: center;
    padding: 0 2em;
`;
