import { Card, InputAdornment, TextField } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import React, { useState } from 'react';
import styled from 'styled-components';
import useStudentsTeacher from '../../../hooks/useStudentsTeacher';
import ErrorResponse from '../../common/ErrorResponse';
import GridCard from '../../common/GridCard';
import NoResultsFound from '../../common/NoResultsFound';
import SimpleLoading from '../../common/SimpleLoading';
import SplitButton from '../../common/SplitButton';
// import CardStudentTeacher from '../../common/teacher-academic/CardStudentTeacher';
import CardWhithSocialMedia from '../../common/teacher-academic/CardWhithSocialMedia';

const StudentsNow = () => {
    const options = ['Por defecto', 'Más antiguo', 'Más nuevo'];
    const [currentFilter, setCurrentFilter] = useState({
        index: 0,
        name: 'Por defecto'
    });
    const [filterText, setFilterText] = useState('');

    const { initialStudents, students, handleStudents } = useStudentsTeacher();

    const handleOption = (args) => {
        setCurrentFilter(args);
        args.index === 0 &&
            handleStudents(students.sort((a, b) => a.id > b.id));
        args.index === 1 &&
            handleStudents(
                students.sort((a, b) => a.lastContact > b.lastContact)
            );
        args.index === 2 &&
            handleStudents(
                students.sort((a, b) => a.lastContact < b.lastContact)
            );
    };

    const handleChange = (e) => {
        setFilterText(e.target.value);
        handleStudents(
            initialStudents.filter((student) =>
                student.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
    };

    if (students === false) {
        return (
            <StudentsWrapper>
                <ErrorResponse />
            </StudentsWrapper>
        );
    }

    if (students === null) {
        return (
            <StudentsWrapper>
                <SimpleLoading />
            </StudentsWrapper>
        );
    }

    return (
        <StudentsWrapper>
            <Header>
                <TextField
                    value={filterText}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    label="Buscar alumno..."
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchRounded />
                            </InputAdornment>
                        )
                    }}
                />
                <SplitButton handleOption={handleOption} options={options} />
            </Header>
            <GridCard
                width="300px"
                cards={students}
                //component={CardStudentTeacher}
                component={CardWhithSocialMedia}
                display="grid"
            />
            {students.length === 0 && <NoResultsFound />}
        </StudentsWrapper>
    );
};

export default StudentsNow;

const StudentsWrapper = styled(Card)`
    display: grid;
    grid-area: courses;
    height: auto;
    border-radius: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 2rem 1.5rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`;
