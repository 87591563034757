import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Text } from "../../../common/Texts";
import LinealProgress from "../../../common/LinealProgress";
import LinearProgressFG from "../../../common/LinearProgressFG";

function CircularProgressWithLabel(props) {
    const customStyle = useProgressStyles();
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                className={customStyle.bottom}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                className={customStyle.top}
                variant="determinate"
                {...props}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Text
                    color="#3BD39C"
                    fontWeight="700"
                    fontSize="0.5rem"
                >{`${Math.round(props.value)}%`}</Text>
            </Box>
        </Box>
    );
}
CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const useProgressStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    bottom: {
        color: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
    },
    top: {
        color: "#3BD39C",
        animationDuration: "550ms",
        position: "absolute",
        left: 0,
    },
    circle: {
        strokeLinecap: "round",
    },
}));

const RankingCard = (props) => {
    const { number, type, values } = props;

    // STATES
    const [viewAdvance, setViewAdvance] = useState(false);

    // FUNCTIONS
    const handleInfo = () => {
        setViewAdvance(!viewAdvance);
    };

    return (
        <CardFake viewAdvance={viewAdvance}>
            <Container>
                <PositionNumber
                    color="#222222"
                    fontWeight="900"
                    fontSize="1rem"
                >
                    {number + 1}
                </PositionNumber>
                <WrapperPhoto>
                    <Photo url={values?.image} />
                </WrapperPhoto>
                <WrapperName color="#222222" fontWeight="700" fontSize="1rem">
                    {values?.name}
                </WrapperName>
                {type === "progress" ? (
                    <CircularProgressWithLabel value={values?.progress} />
                ) : (
                    <DivMsg>
                        <Text color="#616161" fontWeight="700" fontSize="6px">
                            <ForumRoundedIcon fontSize="small" />
                        </Text>
                        <Text
                            color="#616161"
                            fontWeight="700"
                            fontSize="0.875rem"
                        >
                            28
                        </Text>
                    </DivMsg>
                )}
                {type === "progress" && (
                    <ArrowWrapper
                        onClick={handleInfo}
                        viewAdvance={viewAdvance}
                    >
                        <KeyboardArrowDownIcon />
                    </ArrowWrapper>
                )}
            </Container>
            <AdvanceWrapper viewAdvance={viewAdvance}>
                <h3>Avance</h3>
                <LinearProgressFG value={values?.progressAdvance} />
            </AdvanceWrapper>
        </CardFake>
    );
};

export default RankingCard;

const CardFake = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    width: calc(100% - 2rem);
    background: #ffffff;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 10px;
    height: ${(p) => (!!p.viewAdvance ? "80px" : "100%")};
`;

const Container = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
`;

const AdvanceWrapper = styled.div`
    display: ${(p) => (!!p.viewAdvance ? "flex" : "none")};
    gap: 1rem;
    justify-content: space-between;

    h3 {
        font-size: 1rem;
    }
`;

const PositionNumber = styled.div`
    color: #222222;
    font-weight: 900;
    width: 30px;
    text-align: center;
    font-size: 1rem;
`;

const WrapperPhoto = styled.div`
    width: 50px;
`;

const Photo = styled.div`
    border-radius: 50%;
    margin: 0 auto;
    background-image: url(${(p) => p.url});
    width: 32px;
    height: 32px;
    background-position: center center;
    background-size: cover;
`;

const WrapperName = styled.p`
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #222222;
    font-weight: 700;
    font-size: 1rem;
`;

const DivMsg = styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

const ArrowWrapper = styled.div`
    cursor: pointer;

    svg {
        transition: all 0.3s ease-in-out;

        ${(p) =>
            !!p.viewAdvance
                ? `
        transform: rotate(180deg);
        `
                : `
        transform: rotate(0deg);
        `}
    }
`;
