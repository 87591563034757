import { useSnackbar } from "react-simple-snackbar";
import styled from "styled-components";
import React, { useEffect, useState } from "react";

// Components
import Text from "../../../common/Text";
import SelectRolModal from "./SelectRolModal";

// Material UI
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../redux/actions";

// Utils
import { checkRolesLogin } from "../../../../helpers/roles.helper";
import { loginSchema } from "../../../../utils/schemas";
import { optionsStyle } from "../../../../utils/snackStyles";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { Icon } from "@iconify/react/dist/iconify.js";
import CommonButton from "../../../common/CommonButton";

const ALAB_URL = process.env.REACT_APP_ALAB;

const LoginForm = (props) => {
	// PROPS
	const { handleTab } = props;

	// FORMIK
	const initialValues = {
		user: "",
		password: "",
	};

	// STATE
	const [showPassword, setShowPassword] = useState(false);
	const [modal, setModal] = useState(false);

	// REDUX
	const dispatch = useDispatch();
	const [openSnackbar] = useSnackbar(optionsStyle);
	const { loadingLogin, errorRoles, token, roles, error, loadingGetUser } =
		useSelector((state) => state.auth);

	useEffect(() => {
		if (!!errorRoles && !loadingLogin) {
			openSnackbar("Usuario y/o contraseña incorrectos");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorRoles, dispatch, loadingLogin]);

	useEffect(() => {
		if (error) {
			openSnackbar("¡Ocurrió un error al acceder con este usuario!");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	useEffect(() => {
		if (!!token) {
			handleCheckRol(roles);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, roles]);

	// FUNCTIONS
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleSubmit = (values) => {
		dispatch(authActions.loginTokenRequest(values));
	};

	const handleCheckRol = (roles) => {
		const rol = checkRolesLogin(roles);
		if (!!rol) setModal(rol);
		else handleDirectLogin(roles[0]);
	};

	const handleLogin = (rol) => {
		if (rol === "ondemand") {
			window.open(
				`${ALAB_URL}/autologin?rol=ondemand&token=${token}&redirecturi=/campus`,
				"_self"
			);
		} else {
			dispatch(authActions.getDataRequest(rol));
		}
	};

	const handleDirectLogin = (rol) => {
		if (rol === "ondemand") {
			window.open(
				`${ALAB_URL}/autologin?rol=ondemand&token=${token}&redirecturi=/campus`,
				"_self"
			);
		} else {
			dispatch(authActions.getDataRequest(rol));
		}
	};

	return (
		<Wrapper>
			<Content>
				<Text fontWeight="800" fontSize="24px" color="#B31D15">
					¡Hola de nuevo!
				</Text>
				<Text fontWeight="400" textColor="#222" fontSize="16px">
					Que alegría verte de nuevo por aquí
				</Text>
			</Content>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={loginSchema}
			>
				{({ values }) => {
					return (
						<FormContainer>
							<FormField>
								<FieldFake
									name="user"
									type="email"
									value={values.user}
									placeholder="Ingresa tu correo electrónico"
									fullWidth
								/>
								{
									values.user !== ''
										? <FieldLabel>Correo electrónico</FieldLabel>
										: <ErrorMessage name="user" render={msg => (
											<Error>
												<Icon
													icon="mage:exclamation-circle"
													width="16px"
													height="16px"
												/>
												<span>Es necesario que ingreses tu correo eletrónico</span>
											</Error>
										)} />
								}
							</FormField>
							{/** Password */}
							<PassWrapper>
								<FieldFake
									name="password"
									type={showPassword ? "text" : "password"}
									value={values.password}
									placeholder="Contraseña"
									fullWidth
								/>
								{
									values.password !== ""
										? <FieldLabel>Contraseña</FieldLabel>
										: <ErrorMessage name="password" render={msg => (
											<Error>
												<Icon
													icon="mage:exclamation-circle"
													width="16px"
													height="16px"
												/>
												<span>Es necesario que ingreses tu contraseña</span>
											</Error>
										)} />
								}
								<InputAdornmentFake position="end">
									<IconButton
										size="small"
										onClick={toggleShowPassword}
									>
										{showPassword ? (
											<Visibility fontSize="small" />
										) : (
											<VisibilityOff fontSize="small" />
										)}
									</IconButton>
								</InputAdornmentFake>
							</PassWrapper>
							<ButtonWrapper>
								{!loadingLogin && !loadingGetUser && (
									<CommonButton
										label="Ingresar al campus"
										variant="filled"
										type="submit"
									/>
								)}
								{loadingLogin && !loadingGetUser && (
									<CommonButton
										label="Ingresando"
										variant="filled"
										type="submit"
										Icon={() => <CircularProgress size="1.3rem" color="primary" />}
										disabled
									/>
								)}
								{loadingGetUser && loadingLogin && (
									<CommonButton
										label="Obteniendo información"
										variant="filled"
										type="submit"
										Icon={() => <CircularProgress size="1.3rem" color="primary" />}
										disabled
									/>
								)}
								<CommonButton
									label="Olvidé mi contraseña"
									variant="text"
									onClick={() => handleTab(2)}
								/>
							</ButtonWrapper>
						</FormContainer>
					)
				}}
			</Formik>
			<Subtext>
				<Text fontWeight="500" textColor="#222" fontSize="16px">
					¿Aún no tienes cuenta?
				</Text>
				<CommonButton
					label="Crear cuenta"
					variant="text"
					onClick={() => handleTab(1)}
				/>
			</Subtext>
			{!!modal && (
				<SelectRolModal
					rolOpen={modal}
					loadingGetUser={loadingGetUser}
					open={!!modal}
					handleLogin={handleLogin}
				/>
			)}
		</Wrapper>
	);
};

export default LoginForm;

const ButtonWrapper = styled.div`
	margin: 1rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
`;

const PassWrapper = styled.div`
	width: 60%;
    position: relative;
    color: #B31D15;

	@media (width <= 768px) {
		width: 80%;
	}
`;

const FormContainer = styled(Form)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 1.5rem;
`;

const FormField = styled.div`
	width: 60%;
    position: relative;
    color: #B31D15;

	@media (width <= 768px) {
		width: 80%;
	}
`;

const FieldFake = styled(Field)`
    font-size: 14px;
    outline: none;
	border: 0px;
    border-bottom: 1px solid #B31D15;
    padding: 0.5rem 0 0.5rem 0;
    height: 20px;
    width: calc(100% - 5rem);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        box-shadow: 0 0 0px 1000px #fff inset;
        -webkit-text-fill-color: #000;
    }

	@media (width <= 768px) {
		width: 100%;
	}
`;

const FieldLabel = styled.label`
    position: absolute;
    bottom: -1.2rem;
    left: 36px;
    padding: 0px 5px;
    font-weight: 600;
    font-size: 12px;
    @media (max-width: 768px) {
        left: 0px;
		padding: 0;
        font-size: 10px;
    }
`

const Error = styled.div`
	position: absolute;
    bottom: -1.2rem;
    left: 36px;
    padding: 0px 5px;
    font-weight: 600;
    font-size: 12px;
	display: flex;
	align-items: center;
	gap: 4px;
	color: #E94C44;

	span {
		white-space: nowrap;
	}

	@media (width < 768px) {
		left: 0px;
		bottom: -1.5rem;
		padding: 0;
		font-size: 10px;
	}
`

const InputAdornmentFake = styled(InputAdornment)`
    position: absolute;
    top: 50%;
    right: 2.2rem;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin: 16px 0 26px 0;

	@media (max-width: 768px) {
		margin: 10px;
		max-height: 100px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: calc(100% + 7rem);
	@media (max-width: 768px) {
		width: 100%;
	}
`;

const Subtext = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	border-top: 1px solid #C4C4C4;
	padding-top: 16px;
`