import * as types from '../types/status_types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getBlockedStatus } from '../api/status.api';
import { statusActions } from '../actions';

function* getBlockedStatusRequest() {
    try {
        const res = yield call(getBlockedStatus);
        yield put(
            statusActions.getBlockedStatusSuccess(res.data.response_data)
        );
    } catch (error) {
        yield put(statusActions.getBlockedStatusFail(error));
    }
}

function* statusWatcher() {
    yield takeEvery(types.GET_BLOCKED_STATUS_REQUEST, getBlockedStatusRequest);
}

export { statusWatcher };
