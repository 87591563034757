import { useContext, useState } from "react";
import styled from "styled-components";

// Components
import BlockSubmission from "./BlockSubmission";
import InputFile from "../../../../../../components/ui/molecules/course/presentation/InputFile";
import usePresentationSubmission from './hooks/usePresentationSubmission';
import CourseContext from '../../../../context/CourseSubjectContext';
import { Text } from "../../../../../../components/common/Texts";
import { Icon } from "@iconify/react/dist/iconify.js";
import TextQuillInput from "./TextQuillInput";

const PresentationSubmission = (props) => {

    const { 
        subjectId
    } = useContext(CourseContext)

    const { 
        presentation: {
            submissions,
            id: presentationId,
            tipo: presentationType,
            opciones_entrega
        }, 
        resourceId, 
        objId, 
        setLoading, 
        submitViewResource, 
        setPage, 
        setChangeStatus, 
        statusStudentBlock,
        loading
    } = props;

    const [commentary, setCommentary] = useState('');
    const [editSubmission, setEditSubmission] = useState(false);
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(false);
    const [text, setText] = useState(submissions[0]?.response || "");

    const handleChange = (e) => {
        setText(e);
    };

    const validateInput = () => {
        if (!submissions?.length) {
            return false;
        }

        const firstSubmission = submissions[0];

        if (editSubmission) {
            return false;
        }

        if (firstSubmission?.redo) {
            return false;
        }

        return !firstSubmission?.grade;
    };

    const { handleSubmit } = usePresentationSubmission(submitViewResource,
        setLoading,
        setPage,
        setChangeStatus,
        submissions,
        presentationId,
        presentationType,
        resourceId,
        objId,
        subjectId)

    const showSendActivityButton = !(!editSubmission && (submissions[0]?.state === 'corregido' && !submissions[0]?.redo));

    const validateDisabledSendActivity = ((file !== null || (text !== "" && text !== '<p><br></p>') || commentary !== "") && !fileError && !loading && !validateInput() ? false : true) || statusStudentBlock;

    return (
        <Submission>
            <SubmissionHeader>
                <Text
                    color="#b31d15"
                    fontSize="18px"
                    fontWeight="700"
                    style={{ borderBottom: '1px solid #A8A8A8', paddingBottom: '1rem', width: showSendActivityButton ? 'calc(100% - 12rem)' : '100%' }}
                >
                    Entrega de la actividad
                </Text>
                {
                    !showSendActivityButton ? null :
                        <SendButton
                            disabled={validateDisabledSendActivity}
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmit(file !== null ? false : text, file, commentary)}
                        >
                            <Icon icon="system-uicons:paper-plane" width="24px" height="24px" />
                            <label>{loading ? "Enviando actividad..." : "Enviar actividad"}</label>
                        </SendButton>
                }
            </SubmissionHeader>
            {
                !!submissions.sort((a, b) => b.submission_number - a.submission_number)[0]?.redo
                    || submissions.length === 0
                    || editSubmission ? (
                    <>
                        {opciones_entrega === 'carga_archivo' && (
                            <InputFile
                                setCommentary={setCommentary}
                                file={file}
                                setFile={setFile}
                                fileError={fileError}
                                setFileError={setFileError}
                            />
                        )}
                        {opciones_entrega === 'texto' && (
                            <TextQuillInput
                                setCommentary={setCommentary}
                                text={text}
                                handleChange={handleChange}
                            />
                        )}
                    </>
                ) : (
                    <BlockSubmission
                        submission={submissions[0]}
                    />
                )}
            {submissions.length > 0 && !submissions[0].redo && (
                <EditSubmission>
                    <SendButton
                        variant="contained"
                        color="primary"
                        onClick={() => setEditSubmission(!editSubmission)}
                    >
                        {!editSubmission
                            ? submissions[0].state === 'corregido'
                                ?
                                <>
                                    <Icon icon="pajamas:retry" width="24px" height="24px" />
                                    <label>
                                        Realizar un nuevo intento
                                    </label>
                                </>
                                :
                                <>
                                    <Icon icon="fluent:edit-48-regular" width="24px" height="24px" />
                                    <label>
                                        Editar entrega
                                    </label>
                                </>
                            :
                            <>
                                <Icon icon="fluent:edit-off-48-regular" width="24px" height="24px" />
                                <label>
                                    Cancelar
                                </label>
                            </>
                        }
                    </SendButton>
                </EditSubmission>
            )}
        </Submission>
    )
}

export default PresentationSubmission;

const Submission = styled.div`
    width: 100%;
    height: auto;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 30px;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 768px) {
        height: fit-content;
    }
`;

const SubmissionHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
`

const SendButton = styled.button`
    cursor: pointer;
    width: fit-content;
    padding: 8px 24px 8px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #CD2118;
    border-radius: 100px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;

    label {
        cursor: pointer;
        white-space: nowrap;
    }

    :disabled {
        cursor: not-allowed;
        background-color: #DCDCDC;

        label {
            cursor: not-allowed;
        }
    }
`

const EditSubmission = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;