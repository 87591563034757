import React from "react";
import styled from "styled-components";

// Components
import SubjectAccordion from "../../../../../../pages/subject/components/SubjectAccordion";
import CorrectionSummary from "./CorrectionSummary";
import CorrectionDetails from "./CorrectionDetails";

// Custom hooks
import useStatus from "../../../../../../hooks/common/useStatus";

const Correction = (props) => {
  const { deliveries, showHistorial } = props;

  const { setLightStateColor } = useStatus();

  return (
    <CorrectionWrapper>
      {deliveries.map((deliver) => {
        return (
          <SubjectAccordion
            summary={CorrectionSummary}
            details={CorrectionDetails}
            defaultExpanded={true}
            key={deliver.id}
            data={deliver}
            description={
              deliver.professor_comment || deliver.student_comment
                ? {
                    professor_comment: deliver.professor_comment,
                    student_comment: deliver.student_comment,
                    professor_name: deliver.professor_name,
                  }
                : false
            }
            values={deliver}
            borderRadius="20px"
            height="180px"
            decoratorColor={setLightStateColor(
              deliver.redo ? "rehacer" : deliver.state
            )}
            decorator={false}
            decoratorHeight="0px"
            decoratorWidth="0px"
            detailsPadding="0 0 0 1.1rem"
            boxShadow="box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;"
            border="1px solid #C4C4C4"
            showInfo={!showHistorial}
          />
        );
      })}
    </CorrectionWrapper>
  );
};

export default Correction;

const CorrectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;