import React from 'react';
import styled from 'styled-components';
import Experiencias from './components/Experiencias/Experiencias';
import Footer from './components/Footer';
import Intereses from './components/Intereses/Intereses';
import Navegation from './components/Navegation/Navegation';
import NextWorkshops from './components/NextWorkshops/NextWorkshops';
import Recursos from './components/Recursos/Recursos';
import Welcome from './components/Welcome';
import Institucional from './components/Institucional/Institucional';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import WhatsappChat from '../../components/shared/chat-layout/WhatsappChat';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

function ScrollTop(props) {
    const { children } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor'
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div
                onClick={handleClick}
                role="presentation"
                className={classes.root}
            >
                {children}
            </div>
        </Zoom>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
};

const Index = () => {
    const history = useHistory();

    // Se redirige al login, por ende nunca se va a ver la landing
    history.push('/auth');

    return (
        <div style={{ backgroundColor: '#ffffff' }}>
            <Navegation />
            <span id="back-to-top-anchor"></span>
            <main>
                <Welcome />
                <Recursos />
                <Intereses />
                <NextWorkshops />
                <Institucional />
                <Experiencias />
                <ScrollTop>
                    <CustomFab
                        color="primary"
                        size="small"
                        aria-label="scroll back to top"
                    >
                        <KeyboardArrowUpIcon />
                    </CustomFab>
                </ScrollTop>
                <WhatsappChat />
            </main>
            <Footer />
        </div>
    );
};

export default Index;

const CustomFab = styled(Fab)`
    margin-bottom: 5em;
    margin-right: 1em;

    @media screen and (max-width: 768px) {
        margin-bottom: 6em;
    }
`;
