import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    getArticles,
    getArticlesByCompany,
} from "../../redux/api/blog/blogApi";

const useBlogArticle = () => {
    const ondemandId = useSelector((state) => state.auth.user.ondemand_id);

    const [companyArticles, setCompanyArticles] = useState(null);
    const [articles, setArticles] = useState(null);

    // EFFECTS
    useEffect(() => {
        if (ondemandId && companyArticles === null) {
            getArticleCompany();
        }

        if (articles === null) {
            getArticlesBlog();
        }
    }, []);

    // FUNCTIONS
    const getArticleCompany = async () => {
        const res = await getArticlesByCompany(ondemandId);

        if (!res.error) {
            setCompanyArticles(res);
        } else {
            setCompanyArticles([]);
        }
    };

    const getArticlesBlog = async () => {
        const response = await getArticles();

        if (!response.error) {
            setArticles(response);
        } else {
            setArticles([]);
        }
    };

    return { articles };
};

export default useBlogArticle;
