import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getFinances } from '../api/finances';
import { financesActions } from '../actions';

function* financesRequest() {
    try {
        const res = yield call(getFinances);
        yield put(financesActions.getFinancesSuccess(res.data.pagos));
    } catch (error) {
        yield put(financesActions.getFinancesFail(error));
    }
}

function* financesWatcher() {
    yield takeEvery(types.GET_FINANCES_REQUEST, financesRequest);
}

export { financesWatcher };
