import React, { useState } from 'react';
import styled from 'styled-components';
import NavegationAcademic from './NavigationAcademic';
import Footer from '../footer/Footer';
import ErrorBoundary from '../../../utils/ErrorBoundary';
import ErrorBoundaryAlert from '../../common/ErrorBoundaryAlert';

const AcademicLayout = (props) => {
    // STATE
    const [menu, setMenu] = useState(false);

    // FUNCTIONS
    function toggleMenu() {
        setMenu(!menu);
    }

    function closeMenu() {
        setMenu(false);
    }

    return (
        <>
            <NavCointainer
            >
                <NavegationAcademic
                    menu={menu}
                    closeMenu={closeMenu}
                    toggleMenu={toggleMenu}
                />
            </NavCointainer>
            <Overlay menu={menu}></Overlay>
            <Main menu={menu}
            >
                <ErrorBoundary fallback={<ErrorBoundaryAlert />}>
                    <div>{props.children}</div>
                </ErrorBoundary>
                <Footer />
            </Main>
        </>
    );
};

export default AcademicLayout;

const NavCointainer = styled.div`
    position: fixed;
    z-index: 999;

    height: ${(props) => (props.bannerExists ? 'calc(100vh - 70px)' : '100vh')};
    top: ${(props) => (props.bannerExists ? '70px' : '0')};

    @media screen and (max-width: 768px) {
        height: ${(props) =>
            props.bannerExists ? 'calc(100vh - 110px)' : '100vh'};
        top: ${(props) => (props.bannerExists ? '110px' : '0')};

        height: ${(props) => (props.services ? 'calc(100vh - 50px)' : '100vh')};
        top: ${(props) => (props.services ? '50px' : '0')};
    }

    header {
        top: 0;
    }
`;

const Overlay = styled.div`
    display: ${(props) => (props.menu ? 'flex' : 'none')};
    visibility: ${(props) => (props.menu ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.menu ? 1 : 0)};
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: #00000050;
    z-index: 9;
`;

const Main = styled.main`
    position: relative;
    padding: ${(props) =>
        props.bannerExists
            ? 'calc(2rem + 140px) 4rem 9rem calc(4rem + 70px)'
            : 'calc(2rem + 70px) 4rem 9rem calc(4rem + 70px)'};
    overflow: ${(props) => props.menu && 'hidden'};
    height: 100%;
    min-height: 85vh;
    background-color: #f9f9f9;
    @media (max-width: 1440px) {
        padding: ${(props) =>
            props.bannerExists
                ? 'calc(2rem + 140px) 4rem 9rem calc(4rem + 70px)'
                : 'calc(2rem + 70px) 4rem 9rem calc(4rem + 70px)'};
    }

    @media (max-width: 768px) {
        padding: ${(props) =>
            props.bannerExists
                ? 'calc(2rem + 180px) 1rem 18rem 1rem'
                : 'calc(2rem + 70px) 1rem 18rem 1rem'};
    }
`;
