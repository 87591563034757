export const studentStatusAdapter = (studentStatus) => {
    const status = studentStatus;
    const detailBlock = status?.status_block_detail;

    return {
        studentStatus: status?.status,
        id: status?.status_id,
        blockDetail: {
            idStatusBlock: detailBlock?.reason_id,
            reason: detailBlock?.reason,
            date: detailBlock?.blocked_date,
        },
    };
};
