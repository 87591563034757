import React, { useState } from 'react';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import styled from 'styled-components';
// import { SearchRounded } from '@mui/icons-material';

const AutocompleteSelector = (props) => {
    const { options, label, filterSelected, value } = props;
    const [inputValue, setInputValue] = useState(0);

    return (
        <AutocompleteStyled
            value={options.find((option) => option.id === value) || null}
            isOptionEqualToValue={(option, value) => option.id === value}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
                filterSelected(newValue);
            }}
            options={options}
            sx={{ width: 350 }}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option.name}` || ''}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={label}
                    // InputProps={{
                    //     startAdornment: (
                    //         <InputAdornment position="start">
                    //             <SearchRounded />
                    //         </InputAdornment>
                    //     ),
                    // }}
                />
            )}
        />
    );
};

export default AutocompleteSelector;

const AutocompleteStyled = styled(Autocomplete)`
    border: none !important;
    :hover {
        border: none !important;
    }
    div &&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root {
        border-radius: 100px;
        background-color: transparent;
        border: none !important;
        gap:0.5rem;
        :hover {
            border: none !important;
        }
    }

    input & .MuiOutlinedInput-root {
        width: 100%;
        border-radius: 100px !important;
        outline: none !important;
        display: flex;
        gap: 1rem;
        align-items: center;
    padding-left: 0.5rem;
    background-color: green;
    }

`;
