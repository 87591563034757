import Axios from 'axios';

const URL_CHAT = `${process.env.REACT_APP_CHAT}/api`;
// const URL_CHAT = "http://localhost:3045/api"

export const pushNotificationService = async (data) => {
    const URL = `${URL_CHAT}/notification`;
    try {
        const response = await Axios.post(URL, data); //Error 500 - el código original también devuelve error 500
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getNotificationService = async (data) => {
    const URL = `${URL_CHAT}/getNotifications`;
    try {
        const response = await Axios.post(URL, { user_id: data }); //Error 500 - el código original también devuelve error 500
        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteNotificationService = async (data) => {
    const URL = `${URL_CHAT}/deleteNotifications`;
    try {
        const response = await Axios.post(URL, { user_id: data }); //Error 500 - el código original también devuelve error 500
        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteNotificationByIdService = async (data) => {
    const URL = `${URL_CHAT}/deleteNotificationById`;
    try {
        const response = await Axios.post(URL, {
            id_to: data.to,
            id_from: data.from
        }); //Error 500 - el código original también devuelve error 500
        return response.data;
    } catch (error) {
        return error;
    }
};
