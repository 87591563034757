import { Box, Button, Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import SimpleLoading from "../../../../components/common/SimpleLoading";
import { Text } from "../../../../components/common/Texts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const InscriptionModal = (props) => {
  const {
    open,
    handleClose,
    handlePost,
    loading,
    campType = "camp",
    campEvent = "finanzas",
    eventId,
    message,
  } = props;

  const CONFIRM_IMG_ROUTE = 'url("/assets/aden/pregunta-electiva.png")';

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBox sx={style}>
          <SnackbarWrapper></SnackbarWrapper>
          <Header>
            <CloseIcon onClick={handleClose} />
          </Header>
          <Image img={CONFIRM_IMG_ROUTE} />
          <h2>Inscripción a evento</h2>
          <p>
            ¿Deseas inscribirte al{" "}
            <b>
              {campType} de {campEvent}
            </b>
            ?
          </p>

          {loading ? (
            <SimpleLoading />
          ) : !message ? (
            <CustomButton
              onClick={(e) => handlePost(e, eventId)}
              variant="contained"
            >
              Realizar inscripción
            </CustomButton>
          ) : (
            <Text color="#B31D15" fontSize="12px" fontWeight="700">
              {message}
            </Text>
          )}
        </CustomBox>
      </Modal>
    </div>
  );
};

export default InscriptionModal;

const Image = styled.div`
  width: 100%;
  height: 300px;
  background-image: ${(p) => p.img};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const CustomBox = styled(Box)`
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;

  h2 {
    color: #b31d15;
  }

  p,
  span {
    color: #222;
    font-size: 1rem;
  }
  span {
    font-weight: bold;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`;

const CustomButton = styled(Button)`
  border-radius: 8px;
`;

const SnackbarWrapper = styled.div`
  position: absolute;
`;
