import { useEffect, useState } from "react";
import { getPurchaseOrders, postCancelOrders } from "../../redux/api/faculty";
import CancelIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../utils/snackStyles";

const useSettlements = () => {
    const [settlements, setSettlements] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [faculty, setFaculty] = useState(null);
    const [states, setStates] = useState([]);
    const [open, setOpen] = useState(false);
    const [idDelete, setIdDelete] = useState();

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        !settlements && getData();
    // eslint-disable-next-line
    }, []);

    const getData = async () => {
        const req = await getPurchaseOrders();
        if (req.error) {
            openSnackbar("¡No se pudieron cargar los datos!");
            setSettlements([]);
            return;
        } else {
            setSettlements(renderTable(req));
            setInitialValues(renderTable(req));
            handleInformation(req);
            getStatusPay(req);
        }
    };

    const handleModal = (id) => {
        setOpen(true);
        setIdDelete(id);
    };

    const handleCancel = async () => {
        const req = await postCancelOrders(idDelete);

        if (!req.error) {
            handleRefresh();
            openSnackbar("¡Orden cancelada correctamente!");
        } else {
            openSnackbar("¡No se pudo cancelar la orden!");
        }
        setTimeout(() => {
            setOpen(false);
        }, 800);
    };

    const handleClose = () => {
        setOpen(false);
        setIdDelete(null);
    };

    const handleInformation = (values) => {
        // Monto en dolar
        const dollar = values
            .filter(
                (item) => item.currency === "USD" && item.state === "purchase"
            )
            .reduce((acc, current) => acc + current.amount, 0);
        // Monto en moneda local
        const localCurrency = values
            .filter(
                (item) => item.currency !== "USD" && item.state === "purchase"
            )
            .reduce((acc, current) => acc + current.amount, 0);
        // Pagos a autorizar
        const draftForPay = values.filter(
            (item) => item.state === "draft"
        ).length;
        // Pagos autorizados
        const draftPay = values.filter(
            (item) => item.state === "purchase"
        ).length;

        setFaculty({ dollar, localCurrency, draftForPay, draftPay });
    };

    const getStatusPay = (values) => {
        const states = values.map((item) => item.state);
        const stateClean = Array.from(new Set(states));
        const sanitizerStates = stateClean.map((item) => {
            const cases = {
                done: { id: item, name: "Bloqueado" },
                cancel: { id: item, name: "Cancelado" },
                draft: { id: item, name: "A autorizar" },
                "paid out": { id: item, name: "Pagado" },
                "to approve": { id: item, name: "Aprobado" },
                sent: { id: item, name: "Cotización enviada" },
                check: { id: item, name: "Revisado" },
                purchase: { id: item, name: "Petición de compra" },
            };
            return cases[item];
        });
        setStates(sanitizerStates);
    };

    const renderTable = (array) => {
        return array.map((item) => {
            return {
                ...item,
                initialState: item.state,
                cancel: (
                    <IconButtonFake
                        disabled={item.state !== "draft"}
                        onClick={() => handleModal(item.id)}
                    >
                        <CancelIconFake colors={item.state !== "draft"} />
                    </IconButtonFake>
                ),
                state: (
                    <StateChip
                        color={
                            (item.state === "done" && "#F0E3CE") ||
                            (item.state === "cancel" && "#F5D0C6") ||
                            (item.state === "draft" && "#FBF0EA") ||
                            (item.state === "paid out" && "#C1F0E3") ||
                            (item.state === "sent" && "#E4EBF5") ||
                            (item.state === "check" && "#E9C0F0") ||
                            (item.state === "purchase" && "#F9F5EC")
                        }
                        textColor={
                            (item.state === "done" && "#DE9621") ||
                            (item.state === "cancel" && "#DE4921") ||
                            (item.state === "draft" && "#B75E26") ||
                            (item.state === "paid out" && "#28A986") ||
                            (item.state === "sent" && "#5A8FDB") ||
                            (item.state === "check" && "#A43EB5") ||
                            (item.state === "purchase" && "#DBC68F")
                        }
                        fontSize="12px"
                        fontWeight="bold"
                        title={
                            (item.state === "done" && "Bloqueado") ||
                            (item.state === "cancel" && "Cancelado") ||
                            (item.state === "draft" && "A autorizar") ||
                            (item.state === "paid out" && "Pagado") ||
                            (item.state === "to approve" && "A autorizar") ||
                            (item.state === "sent" && "Cotización enviada") ||
                            (item.state === "check" && "Revisado") ||
                            (item.state === "purchase" && "Petición de compra")
                        }
                        padding="10px"
                    >
                        {
                            (item.state === "done" && "Bloqueado") ||
                            (item.state === "cancel" && "Cancelado") ||
                            (item.state === "draft" && "A autorizar") ||
                            (item.state === "paid out" && "Pagado") ||
                            (item.state === "to approve" && "A autorizar") ||
                            (item.state === "sent" && "Cotización enviada") ||
                            (item.state === "check" && "Revisado") ||
                            (item.state === "purchase" && "Petición de compra")
                        }
                    </StateChip>
                ),
                amountNew: (
                    <b>
                        {item.amount > 0
                            ? `${item.currency} ${item.amount}`
                            : `${item.currency} ${item.amount}`}
                    </b>
                ),
                date: new Date(item?.date_order).toLocaleString() + "hs" || "-",
            };
        });
    };

    const handleSettlements = (newData) => {
        setSettlements(newData);
    };

    const handleRefresh = () => {
        getData();
    };

    return {
        settlements,
        initialValues,
        open,
        handleClose,
        handleSettlements,
        renderTable,
        handleRefresh,
        handleCancel,
        faculty,
        states,
    };
};

export default useSettlements;

const CancelIconFake = styled(CancelIcon)`
    color: ${(p) => (p.colors ? "#e1e1e1" : "#b31d15")};
    font-size: 1.5rem;
`;

const IconButtonFake = styled(IconButton)`
    width: 2rem;
    height: 2rem;
`;

const StateChip = styled.div`
    width: 150px;
    height: 40px;
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid ${(props) => props.textColor};
    font-size: 14px;
`