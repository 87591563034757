import * as types from '../types';

const initialState = {
    tipos_inconvenientes: null,
    loading: false,
    error: null
};

export const inconvenientes = (state = initialState, action) => {
    switch (action.type) {
        case types.TIPO_INCONVENIENTE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case types.TIPO_INCONVENIENTE_SUCCESS:
            return {
                ...state,
                loading: false,
                tipos_inconvenientes: action.payload,
                error: null
            };
        case types.TIPO_INCONVENIENTE_FAIL:
            return {
                ...state,
                loading: false,
                tipos_inconvenientes: null,
                error: action.payload
            };
        case types.REPORT_INCONVENIENTE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case types.REPORT_INCONVENIENTE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case types.REPORT_INCONVENIENTE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
