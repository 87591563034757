import React from 'react';
import styled from 'styled-components';

const Publicity = () => {
    return (
        <Container>
            <Img
                loading="lazy"
                src="https://contactodecomercio.com/wp-content/uploads/2020/08/business-desktop-hd-wallpaper-61250-63064-hd-wallpapers.jpg"
            />
        </Container>
    );
};

export default Publicity;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    filter: grayscale(100%);
`;
