import React from 'react';
import styled from 'styled-components';

const CircularProgress = ({ size = 217, strokeWidth = 12, progress = 0 }) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;

    return (
        <CircleContainer style={{ width: size, height: size }}>
            <svg width={size} height={size}>
                <defs>
                    <linearGradient id="gradient" x1="1" y1="1" x2="0" y2="0">
                        <stop offset="0%" stopColor="#B31D15" />
                        <stop offset="100%" stopColor="#E01F14" />
                    </linearGradient>
                </defs>
                <CircleBackground
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={strokeWidth}
                />
                <CircleProgress
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
            </svg>
            <ProgressText>
                <label>
                    <b>{progress}</b>%
                </label>
                <span>Mi progreso</span>
            </ProgressText>
        </CircleContainer>
    );
};

export default CircularProgress;

const CircleContainer = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

const CircleBackground = styled.circle`
    fill: none;
    stroke: #fff;
`;

const CircleProgress = styled.circle`
    fill: none;
    stroke: url(#gradient);
    stroke-linecap: butt;
    transition: stroke-dasharray 0.5s ease;
    transform: rotate(-90deg); /* Rotar el círculo para comenzar en 90° */
    transform-origin: 50% 50%; /* Ajustar el origen de la rotación */
`;

const ProgressText = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #333;

    label {
        font-size: 40px;
        font-weight: 500;
        color: #b31d15;
        b {
            font-size: 64px;
            font-weight: 700;
        }
    }

    span {
        color: #222;
        font-size: 16px;
        font-weight: 600;
    }
`;
