import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from '../../common/AlertDialog';
import { useAlertDialog } from '../../../hooks/useAlertDialog';
import EmptyGridResource from '../../common/EmptyGridResource';
import CourseCardSkeleton from '../../common/CourseCardSkeleton';
import { ondemandActions } from '../../../redux/actions';
import BusinessCard from '../../../app/landing/components/Business/BusinessCard';
import GridBusiness from '../../common/GridBusiness';
import { Button } from '@mui/material';
import useTheme from '../../../hooks/useTheme';

const CoursesAddiotional = (props) => {
    const { thematic } = props;
    const { primary } = useTheme();
    // REDUX
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const ondemandAcademic = useSelector((state) => state.ondemand.academic);

    const [alertDialog, closeAlertDialog] = useAlertDialog();
    const [themeState, setTheme] = useState(null);
    const [filterData, setfilterData] = useState([]);
    const [courses, setCourses] = useState(null);
    const [sliceArray, setsliceArray] = useState(0);

    const emptyItems = new Array(3).fill(null);

    // EFFECTS
    useEffect(() => {
        if (user.ondemand_id !== null) {
            if (ondemandAcademic.additional === null) {
                dispatch(
                    ondemandActions.getAddiotionalCoursesRequest(
                        user.ondemand_id
                    )
                );
            }
        }
    }, [ondemandAcademic]);

    useEffect(() => {
        if (ondemandAcademic.additional !== null) {
            filterCourses(themeState);
        }
        // console.log(themeState);
    }, [themeState]);

    useEffect(() => {
        if (ondemandAcademic.additional !== null) {
            cutArray(ondemandAcademic.additional);
        }
    }, [ondemandAcademic]);

    const cutArray = (array) => {
        const result = array.slice(sliceArray, sliceArray + 9);
        if (courses !== null) {
            setCourses([...courses, ...result]);
        } else {
            setCourses(result);
        }
        setsliceArray(sliceArray + 9);
    };

    const filterCourses = (theme) => {
        let result = [];
        ondemandAcademic.additional.map((course) => {
            course.eje_tematico_ids.filter((th) =>
                th.id == theme ? result.push(course) : null
            );
        });
        setfilterData(result);
    };

    return (
        <CoursesContainer>
            {courses === null ? (
                <EmptyGridResource
                    propsValues={emptyItems}
                    Card={CourseCardSkeleton}
                />
            ) : ondemandAcademic.additional.length > 0 ? (
                themeState === null ? (
                    <>
                        <GridBusiness
                            menuFilter={thematic}
                            propsValues={courses}
                            Card={BusinessCard}
                            allCourses={ondemandAcademic.additional}
                        />
                        {ondemandAcademic.additional.length > sliceArray && (
                            <Load>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        cutArray(ondemandAcademic.additional)
                                    }
                                >
                                    Mostrar mas
                                </Button>
                            </Load>
                        )}
                    </>
                ) : (
                    <>
                        <GridBusiness
                            menuFilter={thematic}
                            propsValues={filterData}
                            allCourses={filterData}
                            Card={BusinessCard}
                        />
                        {filterData.length > sliceArray && (
                            <Load>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => cutArray(filterData)}
                                >
                                    Mostrar mas
                                </Button>
                            </Load>
                        )}
                    </>
                )
            ) : (
                <MessageEmpty color={primary}>
                    No hay cursos adicionales
                </MessageEmpty>
            )}

            <AlertDialog
                titulo={alertDialog.titulo}
                mensaje={alertDialog.mensaje}
                accion={alertDialog.accion}
                open={alertDialog.open}
                onClose={closeAlertDialog}
            ></AlertDialog>
        </CoursesContainer>
    );
};

export default CoursesAddiotional;

const CoursesContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const MessageEmpty = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.color};
    font-size: 1.2rem;
    font-weight: 600;
`;

const Load = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
