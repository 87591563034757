import axios from 'axios';
import { CLOSE_SNACK } from '../types/alerts_types';

export function getMicrolearnings() {
    return axios.get(
        `${process.env.REACT_APP_REPO}/v1/repo_aden/microcontents`
    );
}

export const getCountLikes = async (id) => {
    try {
        const req = await axios.get(
            `${process.env.REACT_APP_SISAPI}/v1/acropolis/resources/recurso/${id}/count_likes`
        );
        return req.data.response_data;
    } catch (error) {
        return { error };
    }
};

export const postLikes = async (id, type) => {
    try {
        const req = await axios.post(
            `${process.env.REACT_APP_SISAPI}/v1/acropolis/resources/recurso/${id}/${type}`
        );
        return req.data.response_data;
    } catch (error) {
        return { error };
    }
};

export const isMyLike = async (id) => {
    try {
        const req = await axios.get(
            `${process.env.REACT_APP_SISAPI}/get_calificacion/recurso/${id}`
        );
        return req.data;
    } catch (error) {
        return { error };
    }
};


export async function getDownloadableFiles() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_REPO}/v1/repo_aden/microcontents?descargable=true`
        );
        return response.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
}