import React from 'react';
import CommonChip from '../../CommonChip';

const StateSubjectsChip = (props) => {
    const { enrollmentState,  } = props;
   
    const baseChipProps = {
        variant: "subjectState",
    };


    const getChipProps = (state) => {
        switch (state) {
            case 'Vencido':
                return { label: state , strongColor: "#B31D15", lightColor: "#FFF" };
            case 'Sin matricular':
                return { label: state , strongColor: "#B75E26", lightColor: "#FBF0EA" };
            case 'Por iniciar':
                return { label: state , strongColor: "#C29F43", lightColor: "#F9F5EC" };
            case 'En curso':
                return { label: state , strongColor: "#5E80DB", lightColor: "#F7F8FD" };
            case 'Finalizado':
                return { label: state , strongColor: "#1E8065", lightColor: "#F7FDFB" };
            case 'Egresado':
                return { label: 'Finalizado' , strongColor: "#1E8065", lightColor: "#F7FDFB" };
            case 'En pausa':
                return { label: state , strongColor: "#B31D15", lightColor: "#FFF5F5" };
            case 'Bloqueado':
                return { label: state , strongColor: "#616161", lightColor: "#FFFFFF" };
            case 'Próximo a vencer':
                return { label: state , strongColor: "#B31D15", lightColor: "#FFF" };
            default:
                return { label: state , strongColor: "#000", lightColor: "#FFF" };
        }
    };

    const chipProps = getChipProps(enrollmentState);

    return (
        <div>
            <CommonChip {...baseChipProps} {...chipProps} />
        </div>
    );

};

export default StateSubjectsChip;
