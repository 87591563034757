import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getTypesDocumentsService } from '../../../../redux/api/teacher';
import DefaultSelect from '../../../common/DefaultSelect';
import DefaultField from '../../../common/DefaultField';
import DefaultFile from '../../../common/DefaultFile';

const Documentation = (props) => {

    const { handleB64File } = props;

    // State
    const [typesDocuments, setTypesDocuments] = useState(null)

    // Effects
    useEffect(() => {
        if (typesDocuments === null) {
            getTypesDocuments()
        }
    }, [typesDocuments])

    // Functions
    const getTypesDocuments = async () => {
        const response = await getTypesDocumentsService()
        setTypesDocuments(response)
    }

    return <DocumentationWrapper>
        {typesDocuments && (
            <DefaultSelect
                name="repo_document_type"
                label="Tipo de documento"
                options={typesDocuments}
            />
        )}
        <Grid>
            <DefaultField
                name="repo_institution"
                type="text"
                label="Institución"
            />
            <DefaultField
                name="repo_year"
                type="number"
                label="Año de realizacion"
            />
        </Grid>
        <DefaultField
            name="repo_description"
            type="text"
            label="Descripcion"
            multiline
            rows={4}
        />
        <DefaultFile
            handleB64File={handleB64File}
        />
    </DocumentationWrapper>
};

export default Documentation;

const DocumentationWrapper = styled.div`
    padding: 1rem;
    padding-top: 1.5rem;
    display: grid;
    gap: 1.5rem;
    @media(max-width: 768px){
        width: calc(100% - 2rem);
        display: flex;
        flex-direction: column;
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
`