import React from "react";
import styled from "styled-components";

import { Text } from "../../common/Texts";

const StudentDataList = (props) => {
    const { value } = props;

    return (
        <Container>
            {value.map((element, i) => (
                <Data>
                    <Text
                        fontSize="0.75rem"
                        fontWeight="bold"
                        color="#616161"
                        textAlign="start"
                    >
                        {element.title}
                    </Text>
                    {!!element.content ? (
                        <Text
                            fontSize="1rem"
                            fontWeight="normal"
                            color="#222222"
                            textAlign="start"
                        >
                            {element.content}
                        </Text>
                    ) : (
                        <Text
                            fontSize="1rem"
                            fontWeight="normal"
                            color="##A8A8A8"
                            textAlign="start"
                        >
                            Sin asignar
                        </Text>
                    )}
                </Data>
            ))}
        </Container>
    );
};

export default StudentDataList;

const Container = styled.div`
    /* display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */

    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
`;

const Data = styled.div`
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    border-radius: 5px;
    padding: 0.5rem;
`;
