import React from 'react';

import RecursosSlider from './RecursosSlider';
import styled from 'styled-components';

const Intereses = () => {
    return (
        <RecursosContainer>
            <Header>
                <Title>¡Accede a miles de recursos sin costo!</Title>
            </Header>
            <RecursosSlider></RecursosSlider>
        </RecursosContainer>
    );
};

export default Intereses;

const RecursosContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    padding: 3rem 0;
`;

const Title = styled.div`
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
    color: #b31d15;
    padding: 0 2em;
`;
