import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Calification from './Calification';
import StudentCard from './StudentCard';
import SkeletonLoading from '../../../../common/SkeletonLoading';
import { Text } from '../../../../common/Texts';

const TableGradeBook = (props) => {
    const { idA, viewAcademic, students, isLoading, setSuccessfullGraduation} = props;

    const GRADES_INFO = [
        {
            gradeType: 'actividades de desarrollo',
            description: '70% de la calificación final',
        },
        {
            gradeType: 'actividades de correción automática',
            description: '20% de la calificación final',
        },
        {
            gradeType: 'bloque integrador',
            description: '10% de la calificación final',
        },
        { gradeType: 'nota final', description: '' },
    ];

    const [allStudentActivities, setAllStudentActivities] = useState(null);
    const [allActivities, setAllActivities] = useState(null);

    useEffect(() => {
        !!students && getUserActivities();
        !!students && getActivities();
    }, [students]);

    // FUNCTION

    const getUserActivities = () => {
        const activities = students?.map((student) => {
            return {
                allStudentActivities: student?.activities,
                grades: {
                    finalGrade: Math.trunc(student?.grade?.final_grade),
                    developActivitiesGrade:
                        Math.trunc(student?.grade?.columns[0]?.grade) || '-',
                    autoTestGrade:
                        Math.trunc(student?.grade?.columns[1]?.grade) || '-',
                    blockGrade:
                        Math.trunc(student?.grade?.columns[2]?.grade) || '-',
                },
            };
        });
        setAllStudentActivities(activities);
    };

    const getActivities = () => {
        const activities = students[0]?.activities?.map((activity) => {
            return activity?.name;
        });
        setAllActivities(activities);
    };

    if (isLoading) {
        return (
            <SkeletonLoading
                variant="rounded"
                animation="wave"
                height={910}
                radius="25px"
            />
        );
    }

    return (
        <Wrapper>
            <StudentsColumn>
                <RowHead>
                    <Text fontSize="16px" fontWeight="700">
                        Estudiante
                    </Text>
                </RowHead>
                {students?.map((student, i) => (
                    <RowsWrapper key={student.name}>
                        <StudentCard
                            id={i}
                            key={student.name}
                            name={student.name}
                            status={student.status || 'cursando'}
                            type="table"
                            info={student}
                            idA={idA}
                            viewAcademic={viewAcademic}
                            grades={student?.grade}
                            setSuccessfullGraduation={setSuccessfullGraduation}
                        />
                    </RowsWrapper>
                ))}
            </StudentsColumn>
            <InfoRowsWrapper>
                <ActivitiesWrapper>
                    {allActivities?.map((activity, i) => (
                        <ActivityRow key={i}>
                            <Text fontSize="15px" fontWeight="600">
                                {activity}
                            </Text>
                        </ActivityRow>
                    ))}
                    {GRADES_INFO.map((gradeTitle) => (
                        <ActivityRow key={gradeTitle.gradeType}>
                            <Text fontSize="15px" fontWeight="600">
                                {gradeTitle?.gradeType}
                            </Text>
                            <Text
                                color="#b31d15"
                                fontSize="12px"
                                fontWeight="600"
                            >
                                {gradeTitle?.description}
                            </Text>
                        </ActivityRow>
                    ))}
                </ActivitiesWrapper>
                <StudentsInfoWrapper>
                    {allStudentActivities?.map((student, i) => (
                        <ActivitiesRowWrapper key={i}>
                            {student?.allStudentActivities?.map(
                                (activity, j) => (
                                    <>
                                        <RowItemWrapper key={j}>
                                            <Row>
                                                <Calification
                                                    idA={idA}
                                                    key={
                                                        activity?.activity_number
                                                    }
                                                    id={
                                                        activity?.submission?.id
                                                    }
                                                    activity_status={
                                                        activity?.submission
                                                            ?.state
                                                    }
                                                    undelivered={
                                                        activity?.submission
                                                    }
                                                    note={
                                                        activity?.submission
                                                            ?.grade || 0
                                                    }
                                                    pending={
                                                        activity?.submission
                                                    }
                                                    redo={
                                                        activity?.submission
                                                            ?.redo || false
                                                    }
                                                    outstanding={
                                                        activity?.outstanding
                                                    }
                                                    viewAcademic={viewAcademic}
                                                    type={activity?.type}
                                                    submission={
                                                        activity?.submission
                                                    }
                                                />
                                            </Row>
                                        </RowItemWrapper>
                                    </>
                                )
                            )}
                            <RowItemWrapper
                                key={student?.grades?.developActivitiesGrade}
                            >
                                <Row>
                                    <b>
                                        {
                                            student?.grades
                                                ?.developActivitiesGrade
                                        }
                                    </b>
                                </Row>
                            </RowItemWrapper>
                            <RowItemWrapper
                                key={student?.grades?.autoTestGrade}
                            >
                                <Row>
                                    <b>{student?.grades?.autoTestGrade}</b>
                                </Row>
                            </RowItemWrapper>
                            <RowItemWrapper key={student?.grades?.blockGrade}>
                                <Row>
                                    <b>{student?.grades?.blockGrade}</b>
                                </Row>
                            </RowItemWrapper>
                            <RowItemWrapper key={student?.grades?.finalGrade}>
                                <Row>
                                    <b>{student?.grades?.finalGrade}</b>
                                </Row>
                            </RowItemWrapper>
                        </ActivitiesRowWrapper>
                    ))}
                </StudentsInfoWrapper>
            </InfoRowsWrapper>
        </Wrapper>
    );
};

export default TableGradeBook;

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    padding: 1rem 0;
`;

const StudentsColumn = styled.div`
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    border-radius: 20px 0 0 20px;
    min-width: 268px;
    height: calc(100% + 5px);
    border: 1px solid #a8a8a8;
`;

const RowHead = styled.div`
    box-sizing: border-box;
    border-bottom: 1px solid #a8a8a8;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 0 0 1rem;
    background-color: #f1f1f1;
    border-radius: 20px 0 0 0;
`;

const RowsWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 0 0 1rem;
    width: 268px;
    height: 35px;
    border-bottom: 1px solid #a8a8a8;

    &:last-child {
        border-bottom: none;
    }

    &:nth-child(odd) {
        background-color: #f1f1f1;
    }

    &:nth-child(odd):last-child {
        border-radius: 0 0 0 20px;
    }
`;

const InfoRowsWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    overflow-y: hidden;
    overflow-x: auto;

    &:last-child {
        border-bottom: none;
    }

    &:first-child {
        border-bottom: none;
    }
`;

const ActivitiesWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 268px;
    height: 61px;
`;

const ActivityRow = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    min-width: 268px;
    height: 61px;
    white-space: wrap;
    border: 1px solid #a8a8a8;
    border-left: none;
    text-align: center;
    background-color: #f1f1f1;
    overflow-y: hidden;

    &:last-child {
        border-radius: 0 20px 0 0;
    }
`;

const StudentsInfoWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

const ActivitiesRowWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    width: max-content;
    max-height: 35px;
    border-bottom: 1px solid #a8a8a8;
    border-right: 1px solid #a8a8a8;

    &:nth-child(even) {
        background-color: #f1f1f1;
    }

    &:last-child {
        border-radius: 0 0 20px 0;
        max-height: 36px;
    }
`;

const RowItemWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 268px;
    height: 100%;

    border-right: 1px solid #a8a8a8;

    &:last-child {
        border-right: none;
        width: 267px;
    }
`;

const Row = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 268px;
    height: 100%;
`;
