import axios from "axios";

export async function getTutorials(rol) {
    const URL = `${window.location.origin}/mock/tutorials.json`;
    try {
        const response = await axios.get(URL);
        const result = response.data.response_data.filter(item => item.rol === rol);
        return result
    } catch (error) {
        return { error };
    }
}