import blockchain from '../../img/intereses/img/blockchain-title.jpg';
import derecho from '../../img/intereses/img/derecho-title.jpg';
import Habilidades from '../../img/intereses/img/hab-direct-title.jpg';
import Marketing from '../../img/intereses/img/marketing-title.jpg';
import Negocios from '../../img/intereses/img/negocios-title.jpg';
import Proyectos from '../../img/intereses/img/proyectos-title.jpg';
import Rrhh from '../../img/intereses/img/rrhh-title.jpg';
import Salud from '../../img/intereses/img/salud-title.jpg';

export const intereses = [
    {
        image: blockchain,
        name: 'Blockchain'
    },
    {
        image: derecho,
        name: 'Derecho / Business Law'
    },
    {
        image: Habilidades,
        name: 'Habilidades Directivas'
    },
    {
        image: Marketing,
        name: 'Marketing'
    },
    {
        image: Negocios,
        name: 'Negocios'
    },
    {
        image: Proyectos,
        name: 'Proyectos'
    },
    {
        image: Rrhh,
        name: 'Recursos Humanos'
    },
    {
        image: Salud,
        name: 'Salud'
    }
];
