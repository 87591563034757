import { Card, IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/AlternateEmail";

const CardJourney = (props) => {
    const { data } = props;
    const {
        last_income,
        instagram,
        facebook,
        whatsapp,
        email,
        name,
        photo,
        last_assignment,
    } = data;

    const socialArray = [
        { name: instagram, icon: <InstagramIcon /> },
        { name: facebook, icon: <FacebookIcon /> },
        { name: whatsapp, icon: <WhatsappIcon /> },
        { name: email, icon: <EmailIcon /> },
    ];

    return (
        <CardFake>
            <Header>
                <Tag>Riesgo de abandono</Tag>
                <Date>
                    <span>Último ingreso: </span>
                    <p>{last_income}</p>
                </Date>
            </Header>
            <Body>
                <h3>{last_assignment}</h3>
            </Body>
            <Footer>
                <StudentWrapper>
                    <Image>
                        <img src={photo} />
                    </Image>
                    <div>
                        <span>Estudiante</span>
                        <p>{name}</p>
                    </div>
                </StudentWrapper>
                <SocialMedia>
                    {socialArray.map((social, index) => (
                        <IconButtonFake
                            key={index}
                            disabled={!!social.name ? false : true}
                            color="primary"
                            onClick={() => {
                                window.open(social.name);
                            }}
                        >
                            {social.icon}
                        </IconButtonFake>
                    ))}
                </SocialMedia>
            </Footer>
        </CardFake>
    );
};

export default CardJourney;

const CardFake = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 160px;
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 10px;
    padding: 0;
`;

const Header = styled.div`
    display: flex;
    width: calc(100% - 1rem);
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
`;
const Tag = styled.p`
    width: fit-content;
    padding: 0.2rem;
    background-color: #28a986;
    color: #fff;
    border-radius: 5px;
    font-size: .9rem;
`;

const Date = styled.div`
    color: #616161;
    font-size: .85rem;
    display: flex;
    gap: 0.3rem;
    align-items: center;

    @media (max-width: 1475px) {
        flex-direction: column;
        gap: 0;
    }

    span {
        color: #222222;
    }
`;

const Body = styled.div`
    width: calc(100% - 1rem);
    display: flex;
    padding: 0 0.5rem;
    justify-content: flex-start;
    align-items: center;

    h3 {
        color: #222222;
        font-weight: bold;
        font-size: 1rem;
    }
`;

const Footer = styled.div`
    widt: calc(100% - 16px);
    padding: 8px;
    height: calc(51px - 16px);
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-between;
`;

const StudentWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;

    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        span {
            color: #616161;
            font-weight: bold;
            font-size: 0.75rem;
        }

        p {
            font-size: 0.9rem;
            color: #222222;
            font-weight: bold;
        }
    }
`;

const Image = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    width: 35px;
    height: 35px;

    img {
        width: 100%;
    }
`;

const SocialMedia = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    button {
        padding: 0;
    }
`;

const IconButtonFake = styled(IconButton)``;
