import React from 'react';
import styled from 'styled-components';

const AnalyticsTeachers = () => {
   
    return (
        <IframeWrapper>
            <Iframe 
                src={`https://datastudio.google.com/embed/reporting/147b28a4-fc8c-47e3-a127-e95c6c9744e2/page/9BWhC`}
                frameBorder="0" 
                allowFullScreen 
            />
        </IframeWrapper>    
    );
};

export default AnalyticsTeachers;

const IframeWrapper = styled.div`
    position: relative;
    padding-bottom: 50%;
    padding-top: 8%;
    height: 0;
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
`;
