import styled from "styled-components";

// Components
import { Icon } from "@iconify/react/dist/iconify.js";

// Assets
import AdenBotHead from "../../../../../../assets/media/aden/AdenBotCabeza.png";

const ModalIAHeader = (props) => {

    const { handleClose, clearResponse } = props;

    return (
        <Header>
            <AdenBotInfo>
                <AdenBotImg>
                    <img src={AdenBotHead} alt="Aden-Bot-Cabeza" width={51} />
                </AdenBotImg>
                <AdenBotName>
                    <h2>ADI</h2>
                    <label>Asistente virtual con inteligencia artificial</label>
                </AdenBotName>
            </AdenBotInfo>
            <CloseButton onClick={() => {
                clearResponse()
                handleClose()
            }}>
                <Icon icon="iconoir:cancel" width="24px" height="24px" />
            </CloseButton>
        </Header>
    )
}

export default ModalIAHeader;

const Header = styled.div`
    width: calc(100% - 2rem);
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    background: linear-gradient(270.23deg, #B31D15 0.14%, #E01F14 99.79%);
    border-radius: 30px 30px 0 0;
`;

const AdenBotInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const AdenBotImg = styled.div`
    width: 44px;
    height: 44px;
    border-radius: 100px;
    background-color: #B31D15;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AdenBotName = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    color: #fff;
    font-weight: 700;

    h2 {
        font-size: 20px;
    }

    label {
        font-size: 13px;
    }
`;

const CloseButton = styled.button`
    cursor: pointer;
    color: #fff;
`
