import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Socials from '../../components/ui/organisms/profile/Socials';
import PublicHeaderInfo from '../../components/templates/profile/PublicHeaderInfo';
import PublicAboutMe from '../../components/ui/organisms/profile/PublicAboutMe';
import { useParams } from 'react-router-dom';
import { getPublicProfile } from '../../redux/api/profiles';
import SimpleLoading from '../../components/common/SimpleLoading';
import * as encrypt from 'nodejs-base64-encode';
import MainProfilePublic from '../../components/templates/profile/MainProfilePublic';
import { titleHTML } from '../../helpers/title.helper';

const PublicProfile = () => {
    titleHTML("Mi perfil público");

    const { id } = useParams();
    const decodeId = encrypt.decode(id + '', 'base64');
    // REDUX
    // const user = useSelector((state) => state.auth.user);
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Get user
        getProfile(decodeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProfile = async (id) => {
        const request = await getPublicProfile(id);
        if (request.error) {
            console.log(request.error);
        } else {
            setUser(request);
        }
    };

    if (user === null) {
        return <SimpleLoading />;
    }

    return (
        <ProfileContainer>
            <PublicHeaderInfo
                id={user.partner_id}
                foto={user.foto}
                name={user.name}
                city={user.city}
                country={user.country.name}
                biography={user.biography}
                street={user.street === null ? false : user.street}
                zip={user.zip === null ? false : user.zip}
                repo_id={user.repo_id}
            />
            <Body>
                <BodyInfo>
                    <PublicAboutMe
                        birthday={user.birthday}
                        profession={user.profession.name}
                        current_job={user.current_job.name}
                        job_area={user.job_area.name}
                        company={user.company}
                    />
                    {/* <Contact
                        phone={user.phone}
                        mobile={user.mobile}
                        work_email={user.work_email}
                        personal_email={user.personal_email}
                    /> */}
                    <Socials
                        facebook={user.facebook}
                        instagram={user.instagram}
                        skype={user.skype}
                        linkedin={user.linkedin}
                        twitter={user.twitter}
                        whatsapp={user.whatsapp}
                    />
                </BodyInfo>
                <MainProfilePublic
                    user={user}
                    interests={user.intereses}
                    motivations={user.motivaciones}
                />
            </Body>
        </ProfileContainer>
    );
};

export default PublicProfile;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

const Body = styled.div`
    display: grid;
    grid-template-columns: 3fr minmax(0, 8fr);
    grid-gap: 1.5rem;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
    }
`;

const BodyInfo = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;