import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { Add, Arrow } from '@mui/icons-material';
import styled, { keyframes } from 'styled-components';

import * as encode from 'nodejs-base64-encode';
import BarProgress from '../../../../components/common/BarProgress';
import LazyImg from '../../../../modules/acropolisCommon/components/LazyImg';
import { Text } from '../../../../components/common/Texts';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Alarm } from '@fortawesome/fontawesome-pro';
// import {} from '@awesome.me/kit-81876cd84f/';

const CourseCard = ({ value }) => {
    const hash = encode.encode(value?.id + '', 'base64');
    const courseDirection = `/curso/${hash}?origin=p100`;

    const [openInfo, setOpenInfo] = useState(false);

    const handleShowInfo = () => {
        setOpenInfo(!openInfo);
    };

    return (
        <BusinessCardContainer>
            {!openInfo && (
                <>
                    <ImageWrapper>
                        <LazyImg
                            src={
                                !!value?.image.length
                                    ? value?.image
                                    : 'https://www.aden.org/files/sites/9/2018/09/Workshop_Miami_magazine.jpg'
                            }
                            width="100%"
                            height="136px"
                            borderRadius="20px 20px 0 0"
                            alt="objeto de aprendizaje"
                        />
                    </ImageWrapper>
                    <Body>
                        <BarProgress value={value?.progress} />
                        <NombrePublicacion>
                            {value?.publicationName}
                        </NombrePublicacion>
                        <DescriptionWrapper>
                            <Description>
                                {!!value?.courseDescription
                                    ? value?.courseDescription
                                    : 'No posee una descripción'}
                            </Description>
                            <div>
                                <InfoButton onClick={handleShowInfo}>
                                    {/* <FontAwesomeIcon icon="fa-solid fa-house" /> */}
                                    Ver más información
                                </InfoButton>
                            </div>
                        </DescriptionWrapper>
                    </Body>
                </>
            )}

            <Footer>
                <Link to={courseDirection}>
                    <RedirectButton variant="contained">
                        {value?.progress === 0
                            ? 'Iniciar'
                            : value?.progress === 100
                            ? 'Volver a ver'
                            : 'Continuar'}
                    </RedirectButton>
                </Link>
            </Footer>

            {!!openInfo && (
                <MoreInfoWrapper>
                    <Text
                        color="#4F4F4F"
                        fontSize="11px"
                        lineHeight="140%"
                        fontWeight="600"
                    >
                        INFORMACIÓN DEL CURSO
                    </Text>
                    <Text
                        color="#B31D15"
                        fontSize="16px"
                        lineHeight="140%"
                        fontWeight="700"
                    >
                        {value?.publicationName}
                    </Text>
                    <DesciptionWrapper>
                        <Text
                            color="#000000"
                            fontSize="13px"
                            lineHeight="19px"
                            fontWeight="400"
                        >
                            {value?.courseDescription}
                        </Text>
                    </DesciptionWrapper>
                    <InfoButton onClick={handleShowInfo}>Volver</InfoButton>
                </MoreInfoWrapper>
            )}
        </BusinessCardContainer>
    );
};

export default CourseCard;

const BusinessCardContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
    width: 340px;
    height: 380px;
    border-radius: 20px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        max-width: 300px;
    }

    @media screen and (max-width: 320px) {
        width: 250px;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
`;

const Body = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    height: 100%;
`;

const NombrePublicacion = styled.h1`
    font-size: 1rem;
    font-weight: 700;
    text-align: start;
    color: #222222;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 100%;

    @media screen and (max-width: 996px) {
        font-size: 1.2rem;
    }
`;

const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
`;

const Description = styled.p`
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    max-height: 2.2rem;
    text-overflow: ellipsis;
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
    color: #999999;

    @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 1rem 1rem 1rem;
    border-top: 1px solid #fafafa;
    margin-top: auto;
    background-color: #fafafa;
    height: 100%;
`;

const RedirectButton = styled(Button)`
    border-radius: var(--border-radius_rounded);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    min-width: 80px;
    box-shadow: none !important;
`;

const InfoButton = styled.span`
    cursor: pointer;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #616161;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
`;

const MoreInfoWrapper = styled.section`
    box-sizing: border-box;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100% !important;
    padding: 1rem;
    height: calc(100% - 74px);
    border-radius: 20px 20px 0 0;
    animation: ${fadeIn} 0.3s ease-in-out;
    background-color: #ffffff;
`;

const DesciptionWrapper = styled.div`
    height: 156px;
    overflow-y: auto;
`;
