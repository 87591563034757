import React from "react";
import Skeleton from "@mui/material/Skeleton";
import styled, { keyframes } from 'styled-components'

const CourseSkeleton = () => {
    return (
        <CourseCardContainer>
            <Body>
                <FakeHeaderB variant="rect" animation="pulse" width="100%" />
                <FakeBodyB>
                    <FakeResource
                        variant="rect"
                        animation="pulse"
                        width="100%"
                        height="100%"
                    />
                </FakeBodyB>
            </Body>
            <Aside>
                <FakeHeader>
                    <span>
                        Módulos
                    </span>
                    <span>
                        Comentarios
                    </span>
                    <span>
                        Apuntes
                    </span>
                </FakeHeader>
                <FakeBody>
                    <StageSkeleton
                        variant="rounded"
                        animation="wave"
                        width="100%"
                        height="60px"
                    />
                    <StageSkeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height="60px"
                    />
                    <StageSkeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height="60px"
                    />
                    <StageSkeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height="60px"
                    />
                    <StageSkeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height="60px"
                    />
                </FakeBody>
            </Aside>
        </CourseCardContainer>
    );
};

export default CourseSkeleton;

const twinkle = keyframes`
    0%{
        background-color: #f1f1f1;
    }
    50%{
        background-color: #e1e1e1;
    }
    100%{
        background-color: #f1f1f1;
    }
`;

const CourseCardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid #f2f2f2;
    height: calc(100vh - 70px);
`;

const Body = styled.div`
    width: calc(75% - 4.5rem - 18px);
    height: calc(100%);
    padding: 2rem 2.5rem 0 2.5rem;
`;

const Aside = styled.div`
    width: 25%;
    background-color: #ffffff;
    padding-top: 2rem;
`;

const FakeHeader = styled.div`
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-right: 8px;

    span {
        height: 80%;
        padding: 0 12px;
        font-size: 1rem;
        color: transparent;
        animation: ${twinkle} 2s linear infinite;
        border-radius: 10px;
    }
`;

const FakeBody = styled.div`
    height: 100%;
    width: calc(100% - 1rem);
    padding-top: calc(2rem - 8px);
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const FakeBodyB = styled.div`
    height: calc(75vh + 10px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FakeHeaderB = styled(Skeleton)`
    height: 74px;
    background-color: #f1f1f1;
    border-radius: 20px 20px 0 0;
`;

const FakeResource = styled(Skeleton)`
    background-color: #cfcfcf;
`;

const StageSkeleton = styled(Skeleton)`
    background-color: #f1f1f1;
    border-radius: 20px;
    margin-left: -8px;
`;