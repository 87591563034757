import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import ContentGenAccordion from "./ContentGenAccordion";

const AccordionBlock = ({ id, title, name, children }) => (
    <AccordionContainer>
        <AccordionSumm
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls={`panel${id}a-content`}
            id={`panel${id}a-header`}
        >
            <BodyAccordionSummary>
                <Unit>{title}</Unit>
                <Title>{name}</Title>
            </BodyAccordionSummary>
        </AccordionSumm>
        <AccordionDetails>
            <Typography>
                {children}
            </Typography>
        </AccordionDetails>
    </AccordionContainer>
);

const GenericAccordion = ({ units, id, publication_name, type }) => {
    const renderUnits = (units) => units?.map((unit, index) => (
        <AccordionBlock id={id} title={unit?.type} name={unit?.publication_name}>
            {unit?.units?.length > 0 && unit?.units?.map((unit) => (
                <>
                    <BoxTitle>
                        <TitleContent color="primary">
                            {unit?.name}
                        </TitleContent>
                    </BoxTitle>
                    <ContentGenAccordion resource={unit?.resources} />
                </>
            ))}
        </AccordionBlock>
    ));

    return (
        <AccordionContainer>
            <AccordionSumm
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls={`panel${id}a-content`}
                id={`panel${id}a-header`}
            >
                <BodyAccordionSummary>
                    <Unit>{type === "electiva" ? publication_name : type}</Unit>
                    <Title>{publication_name}</Title>
                </BodyAccordionSummary>
            </AccordionSumm>
            <AccordionDetails>
                <Typography>
                    {type === "electiva" ? renderUnits(units) : units?.map((unit) => (
                        <>
                            <BoxTitle>
                                <TitleContent color="primary">
                                    {unit?.name}
                                </TitleContent>
                            </BoxTitle>
                            <ContentGenAccordion resource={unit?.resources} />
                        </>
                    ))}
                </Typography>
            </AccordionDetails>
        </AccordionContainer>
    );
};

export default GenericAccordion;

const Title = styled(Typography)`
    color: #222222;
    font-weight: bold;
`;
const AccordionContainer = styled(Accordion)`
    margin-bottom: 1rem;
    align-items: center;
    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12),
        0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    border-radius: 5px;
    &::before {
        content: "";
        width: 0px;
        height: 0px;
    }
`;
const AccordionSumm = styled(AccordionSummary)`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #fff;
    box-shadow: none;
    border-radius: 6px;
`;
const BodyAccordionSummary = styled.div`
    width: 100%;
`;
const Unit = styled(Typography)`
    color: #b31d15;
    text-transform: uppercase;
    font-size: 0.8rem;
`;
/* const Description = styled(Typography)`
    font-size: 0.7rem;
`; */
const TitleContent = styled(Typography)`
    text-transform: uppercase;
    font-weight: bold;
`;
const BoxTitle = styled.div`
    /* margin-top: 2.5rem; */
    display: flex;
    align-items: center;
    column-gap: 1rem;
    align-content: center;
`;
