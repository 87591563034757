import React from "react";
import styled from "styled-components";

import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { ForumRounded, LocationOnRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { createChannel } from "../../../../helpers/createChannelChat.helper";
import {
  changeChannel,
  setParticipants,
} from "../../../../redux/chat/chatActions";
import ProfileSummary from "../../organisms/profile-summary/ProfileSummary";
import { useShow } from "../../../../hooks/useShow";

const UserCard = (props) => {
  const { user } = props;
  const history = useHistory();
  // STATE
  const [show, handleShow, handleClose] = useShow(false);
  // REDUX
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.auth.user);

  // FUNCTIONS
  function chatGeneration() {
    const hash = createChannel(userLogin.partner_id, user.partner_id);
    const contantFormated = {
      nombre: user.name,
      apellido: "",
      partner_id: parseInt(user.partner_id, 10),
      foto: user.foto,
    };
    dispatch(changeChannel(hash));
    dispatch(
      setParticipants([
        contantFormated,
        { ...userLogin, nombre: userLogin.name },
      ])
    );
    setTimeout(() => {
      history.push("networking/chat");
    }, 200);
  }

  // RETURN
  return (
    <UserCardContianer>
      <LeftWrapper onClick={handleShow}>
        <PhotoWrapper>
          <Photo src={user.foto} alt="user" />
        </PhotoWrapper>
        <InfoWrapper>
          <Name>{user.name.toLowerCase()}</Name>
          <Work>{user.profession.name || "Sin asignar"}</Work>
          <CountryWrapper>
            <LocationOnRounded />
            <span>{user.country.name || "Sin asignar"}</span>
          </CountryWrapper>
        </InfoWrapper>
      </LeftWrapper>
      <RightWrapper>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ForumRounded fontSize="small" />}
          onClick={chatGeneration}
        >
          Chat
        </Button>
      </RightWrapper>
      {/* PROFILE SUMMARY */}
      <ProfileSummary
        open={show}
        handleClose={handleClose}
        contact={user}
        maxWidth="md"
      />
    </UserCardContianer>
  );
};

export default UserCard;

const UserCardContianer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  cursor: pointer;
`;

const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Photo = styled.img`
  width: 85px;
  height: 85px;
  object-fit: cover;
  object-position: center;
  max-width: 85px;
  max-height: 85px;
  border-radius: 50px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;

const Name = styled.h2`
  font-size: 1.1rem;
  font-weight: 700;
  color: #222222;
`;

const Work = styled.span`
  font-size: 0.9rem;
  color: #616161;
`;

const CountryWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: #a3a3a3;
  font-size: 0.9rem;

  svg {
    font-size: 0.9rem;
  }
`;

const RightWrapper = styled.div``;
