import styled from "styled-components";
import LazyImg from "../../modules/acropolisCommon/components/LazyImg";
import ErrorImg from "../../assets/media/aden/error.png"

const ErrorBoundaryAlert = (props) => {
    return (
        <Container>
            <LazyImg
                src={ErrorImg}
                width="350px"
                height="350px"
                backgroundColor="#fff"
            />
            <h1>¡Ups! Ocurrió un error</h1>
            <p>Prueba refrescando la página o contacta a soporte.</p>
        </Container>
    )
}

export default ErrorBoundaryAlert;

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #fff;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    border-radius: 20px;

    h1 {
        font-size: 20px;
        color: #616161;
    }
    
    p {
        font-size: 16px;
        color: #616161;
    }
`