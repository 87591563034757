import React, { useState } from 'react';
import styled from 'styled-components';
import useMyTasks from '../../../hooks/students/useMyTasks';
import GridTasks from '../../ui/organisms/progress/GridTasks';
import OAList from '../../ui/organisms/progress/OAList';
import { Text } from '../../common/Texts';
import Filter from '../../ui/molecules/progress/Filter';

const MyTasks = () => {
    const { assignment, submissionsByOa, assignmentSelected, handleSelectA } =
        useMyTasks();

    const [tasksFiltered, setTasksFiltered] = useState(null);
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    return (
        <Container>
            <Header>
                <Text color="#000" fontWeight="bold" fontSize="16px">
                    Asignaturas
                </Text>
                <Filter
                    submissionsByOa={submissionsByOa}
                    setTasksFiltered={setTasksFiltered}
                    mobile={false}
                />
            </Header>
            <TasksWrapper>
                <SectionWrapper type="oa">
                    <OAList
                        assignment={assignment}
                        assignmentSelected={assignmentSelected}
                        handleClick={handleSelectA}
                        toggleShow={toggleShow}
                        show={show}
                    />
                </SectionWrapper>
                <SectionWrapperTasks type={show}>
                    <GridTasks tasks={tasksFiltered || submissionsByOa || []} />
                </SectionWrapperTasks>
            </TasksWrapper>
        </Container>
    );
};

export default MyTasks;

const Container = styled.div`
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 2rem 1.5rem;
    box-sizing: border-box;
    gap: 1.5rem;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TasksWrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    /* background-color: #fff; */
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
    scrollbar-color: transparent transparent;
`;

const SectionWrapper = styled.div`
    padding: 0 1.25rem 0 1.25rem;
    max-height: 80vh;
    overflow: scroll;
    border-right: 2px solid #c4c4c4;
    @media (max-width: 768px) {
        padding: 1.25rem 1.25rem 1rem 1.25rem;
        border-right: 0px;
    } ;
`;

const SectionWrapperTasks = styled.div`
    height: 100%;
    border-radius: 1.25rem;
    padding: 0 0 1.25rem 1.25rem;
    max-height: 80vh;
    overflow: scroll;
    @media (max-width: 768px) {
        padding: 0px 1.25rem 1.25rem 1.25rem;
        display: ${(p) => (p.type === false ? 'none' : 'flex')};
    } ;
`;
