import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import SimpleLoading from '../../../common/SimpleLoading';
import SplitButton from '../../../common/SplitButton';
import CardWhithSocialMedia from '../../../common/teacher-academic/CardWhithSocialMedia';
import FilterButton from '../../../common/teacher-academic/FilterButton';
import GridContent from '../../../common/teacher-academic/GridContent';
import MorFilter from '../../../common/teacher-academic/MorFilter';
import SearchBar from '../../../common/teacher-academic/SearchBar';
import FilterStudentsStatus from './FilterStudentsStatus';

const GridCardWhithSocialMedia = (props) => {
    const { type, url , filterComponent, getDatass } = props;
    const options = ['Más antiguo', 'Más nuevo'];
console.log('getDatass', getDatass)
    //STATES
    const [currentFilter, setCurrentFilter] = useState({
        index: 1,
        name: 'Más nuevo'
    });

    const [data, setData] = useState([]);
    const [values, setValues] = useState(null);
    const [moreFilters, setMoreFilters] = useState([]);

    //EFFETCS
    useEffect(() => {
        !values && getData();
    }, [values]);

    useEffect(() => {
        if (moreFilters.length > 0) {
            handleFiltersValue(data, moreFilters);
        } else {
            setValues(data);
        }
    }, [moreFilters]);

    //FUNCTIONS
    const handleSearchValue = (args) => {
        setValues(args);
    };

    const handleFiltersValue = (data, moreFilters) => {
        // let statusFilter = moreFilters.filter((item) => item.type === "status"); //name del status
        // let conditionFilter = moreFilters.filter((item) => item.type === "condition"); // id del alert
        //let lastContactFilter = moreFilters.filter((item) => item.type === "lastContact" ) //cálculo de plazo

        let result = [];

        data.filter((d) => {
            moreFilters.forEach((filter) => {
                if (d.id_state === filter.id) {
                    result.push(d);
                    return console.log('idem state', d.id_state);
                }
                if (d.id_condition === filter.id) {
                    result.push(d);
                    return console.log('idem condition', d.id_condition);
                }
                if (d.id_lastContact === filter.id) {
                    result.push(d);
                    return console.log('idem id_lastContact', d.id_lastContact);
                }
                if (d.alert === filter.id) {
                    result.push(d);
                    return console.log('idem alert teacher', d.alert);
                }
            });

            setValues(result);
        });
    };

    //Order
    const handleOption = (args) => {
        setCurrentFilter(args);
    };

    const getData = async () => {
        try {
            const response = await axios.get(url);
            setData(response.data.response_data[`${type}`]);
            setValues(response.data.response_data[`${type}`]);
        } catch (error) {
            return { error };
        }
    };

    const sentFilters = (array) => {
        setMoreFilters(array);
    };

    //RETURN
    if (!values || !data) {
        return (
            <Container>
                <SimpleLoading />;
            </Container>
        );
    }
    return (
        <Container>
            <Header>
                <SearchBar
                    propsValues={data}
                    handleSearchValue={handleSearchValue}
                    title="Buscar alumnos"
                    filterNames={[
                        'name',
                        'description',
                        'title',
                        'file',
                        'lastContact',
                        'country',
                        'state'
                    ]}
                />
                <RigthButtons>
                    <SplitButton
                        handleOption={handleOption}
                        options={options}
                    />
                    {/* <MorFilter
                        title="Filtros"
                        color="#b31d15"
                        Component={filterComponent}
                        sentFilters={sentFilters}
                    /> */}
                    {/* <FilterButton title="Filtrar" /> */}
                </RigthButtons>
            </Header>
            <GridContent
                currentFilter={currentFilter}
                moreFilters={moreFilters}
                contents={values}
                Card={CardWhithSocialMedia}
                contentPerPage={5}
                urlPagination={''}
                widthCard="260px"
                type={type}
            />
        </Container>
    );
};

export default GridCardWhithSocialMedia;

const Container = styled.div`
    background: #ffffff;
    border-radius: 20px;
    box-sizing: border-box;
    align-content: space-between;
    padding-top: 0.5rem;
    margin: 2rem 0;
`;

const Header = styled.div`
    width: 95%;
    margin-top: 2rem;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 400px auto;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        width: 80%;
    }
`;

const RigthButtons = styled.div`
    gap: 1rem;
    vertical-align: middle;
    display: inline-flex;

    .MuiButtonGroup-root {
        background-color: #f9f9f9;
    }
`;
