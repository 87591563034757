import * as types from '../types';

const actions = {};

// GET DOCUMENTS
actions.getDocumentsRequest = () => {
    return {
        type: types.GET_DOCUMENTS_REQUEST
    };
};

actions.getDocumentsSuccess = (documents) => {
    return {
        type: types.GET_DOCUMENTS_SUCCESS,
        payload: documents
    };
};

actions.getDocumentsFail = (error) => {
    return {
        type: types.GET_DOCUMENTS_FAIL,
        payload: error
    };
};

// POST DOCUMENT
actions.postDocumentRequest = (document) => {
    // console.log(document);
    return {
        type: types.POST_DOCUMENT_REQUEST,
        payload: document
    };
};

actions.postDocumentSuccess = (document) => {
    return {
        type: types.POST_DOCUMENT_SUCCESS,
        payload: document
    };
};

actions.postDocumentFail = (error) => {
    return {
        type: types.POST_DOCUMENT_FAIL,
        payload: error
    };
};

// PUT DOCUMENT
actions.putDocumentRequest = (data) => {
    return {
        type: types.PUT_DOCUMENT_REQUEST,
        payload: data
    };
};

actions.putDocumentSuccess = (data) => {
    return {
        type: types.PUT_DOCUMENT_SUCCESS,
        payload: data
    };
};

actions.putDocumentFail = (error) => {
    return {
        type: types.PUT_DOCUMENT_FAIL,
        payload: error
    };
};

export { actions };
