import { createContext } from 'react'

export const SubjectContext = createContext()

export const SubjectProvider = ({ children }) => {
    
    
  return (
    <SubjectContext.Provider value={{}}>
        {children}
    </SubjectContext.Provider>
  )
}

export default SubjectProvider