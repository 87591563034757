import styled from "styled-components";
import parse from 'html-react-parser';

// Components
import { Text } from "../../../../../../components/common/Texts";
import DefaultCardIcon from "../../../../../../components/common/DefaultCardIcon";

// Material UI
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const PresentationContent = (props) => {

    const { presentation } = props;

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return html.replace(regex, 'src="');
    };

    const handleDonwload = () => {
        window.open(
            presentation?.archivo,
            presentation?.name,
            'toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=10,width=800,height=480'
        );
    };

    return (
        <Content>
            <Text
                color="#b31d15"
                fontSize="18px"
                fontWeight="700"
                style={{ borderBottom: '1px solid #A8A8A8', paddingBottom: '1rem' }}
            >
                Consignas de la actividad
            </Text>
            <DescriptionContainerTitle>
                <div>
                    {parse(sanitizerHtml(presentation?.descripcion || ''))}
                </div>
            </DescriptionContainerTitle>
            {!!presentation.archivo && (
                <DescriptionContainer>
                    <Text
                        color="#b31d15"
                        fontSize="18px"
                        fontWeight="700"
                    >
                        Material complementario
                    </Text>
                    <Click onClick={handleDonwload}>
                        <DefaultCardIcon
                            icon={<CloudDownloadIcon />}
                            color={'#616161'}
                            subtitle={'Libro de ayuda'}
                            title={'Descargar'}
                        />
                    </Click>
                </DescriptionContainer>
            )}
            <DescriptionContainer>
                <ParseHtml>
                    {parse(sanitizerHtml(presentation?.consigna))}
                </ParseHtml>
            </DescriptionContainer>
        </Content>
    )
}

export default PresentationContent;

const Content = styled.div`
    width: calc(100% - 4rem);
    min-height: 80px;
    padding: 2rem;
    border-radius: 30px;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
`;

const DescriptionContainerTitle = styled.div`
    width: auto;
    max-width: 100%;
    height: auto;
    position: relative;
    display: grid;
    gap: 15px;
`;

const DescriptionContainer = styled.div`
    width: auto;
    max-width: 100%;
    height: auto;
    padding-top: 1rem;
    position: relative;
    display: grid;
    gap: 15px;
`;

const Click = styled.div`
    cursor: pointer;
    max-width: 230px;
    transition: all 0.3s ease-in-out;
    :hover {
        filter: brightness(0.9);
    }
`;

const ParseHtml = styled.div`
    width: 100%;
    max-width: auto;

    .btn {
        padding: 0.5rem 1rem;
        border-radius: 0.375rem;
        color: #fff;
    }

    .btn-danger {
        background-color: #dc3545;
        :hover {
            background-color: #c82333;
        }
    }

    .btn-warning {
        background-color: #ffc107;
        color: #000;
        :hover {
            background-color: #e0a800;
        }
    }

    .btn-info {
        background-color: #117a8b;
        :hover {
            background-color: #138496;
        }
    }

    .btn-success {
        background-color: #28a745;
        :hover {
            background-color: #218838;
        }
    }

    .btn-secondary {
        background-color: #f8f9fa;
        color: #000;
        :hover {
            background-color: #e2e6ea;
        }
    }

    .btn-primary {
        background-color: #007bff;
        :hover {
            background-color: #0069d9;
        }
    }

    .btn-epsilon {
        background-color: #e46f78;
        :hover {
            background-color: #de4f5a;
        }
    }

    .btn-delta {
        background-color: #5b899e;
        :hover {
            background-color: #4d7486;
        }
    }

    .btn-gamma {
        background-color: #5c5b80;
        :hover {
            background-color: #4c4b6a;
        }
    }

    .btn-beta {
        background-color: #875a7b;
        :hover {
            background-color: #704b66;
        }
    }

    .btn-alpha {
        background-color: #00a09d;
        :hover {
            background-color: #007a77;
        }
    }
`;
