import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Fade,
  Grow,
  Collapse,
  Button,
  Zoom,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import styled from "styled-components";

let typeTransition = "";

const Transition = React.forwardRef(function Transition(props, ref) {
  if (typeTransition === "slide") {
    return <Slide direction="up" ref={ref} {...props} />;
  }
  if (typeTransition === "fade") {
    return <Fade direction="up" ref={ref} {...props} />;
  }
  if (typeTransition === "grow") {
    return <Grow direction="up" ref={ref} {...props} />;
  }
  if (typeTransition === "collapse") {
    return <Collapse direction="up" ref={ref} {...props} />;
  }
  if (typeTransition === "zoom") {
    return <Zoom direction="up" ref={ref} {...props} />;
  }
});

const DialogGenericFG = (props) => {
  const {
    customColor = "#b31d15",
    message,
    open,
    handleClose,
    title,
    titleColor = "#222222",
    transitionType = "zoom",
  } = props;

  typeTransition = transitionType;
  return (
    <div>
      <DialogFake
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      // aria-describedby="alert-dialog-slide-description"
      >
        <WrapperHeader>
          <DialogTitleFake titleColor={titleColor}>{title}</DialogTitleFake>
          <Button onClick={handleClose}>
            <CancelIconFake customColor={customColor} />
          </Button>
        </WrapperHeader>
        <DialogContent>
          <DialogContentTextFake id="alert-dialog-slide-description">
            {message}
          </DialogContentTextFake>
        </DialogContent>
      </DialogFake>
    </div>
  );
};

export default DialogGenericFG;

const DialogFake = styled(Dialog)`
  /* position: relative; */
`;

const DialogTitleFake = styled(DialogTitle)`
  color: ${(p) => p.titleColor};
`;

const DialogContentTextFake = styled(DialogContentText)`
  color: #222222;
  height: fit-content;
`;

const WrapperHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const CancelIconFake = styled(CancelIcon)`
  color: ${(p) => p.customColor};
  font-size: 2rem;
`;
