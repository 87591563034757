import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import CachedIcon from "@mui/icons-material/Cached";

const BannerReferred = (props) => {
    return (
        <CardFake>
            <h1>Refiere a un amigo y obtén beneficios</h1>
            <p>
                Refiere a un amigo y obtén beneficios¡Tu recomendación multiplica la comunidad ADEN! Queremos que más ejecutivos vivan la experiencia ADEN. Invita a un colega-amigo o familiar y obtiene increíbles beneficios.
            </p>
            <CachedIconFake />
        </CardFake>
    );
};

export default BannerReferred;

const CardFake = styled(Card)`
    grid-area: banner;
    position: relative;
    border-radius: 20px;
    width: calc(100% - 4rem);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: linear-gradient(269.94deg, #fef6f6 0.05%, #ffffff 99.95%),
        #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;

    h2 {
        color: #222222;
        font-size: 1.4rem;
    }

    p {
        max-width: 75%;
        color: #222222;
        line-height: 140%;
        font-size: 1.1rem;
    }
    z-index: 1;
`;

const CachedIconFake = styled(CachedIcon)`
    position: absolute;
    right: 2rem;
    top: 0;
    bottom: 0;
    color: #fceeee;
    font-size: 13.2rem;
    transform: rotateZ(90deg);
    z-index: -1;
`;
