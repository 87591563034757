import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Button from '../../atoms/Button';
import { Chip } from '@mui/material';
import styled from 'styled-components';
import { Image } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ForumIcon from '@mui/icons-material/Forum';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { createChannel } from '../../../../helpers/createChannelChat.helper';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeChannel,
    setParticipants
} from '../../../../redux/chat/chatActions';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { postFav, postTakeOutFav } from '../../../../redux/api/utils';
import StarIcon from '@mui/icons-material/Star';
import { getHighlight, postHighlight } from '../../../../redux/api/students';
import HighlightStudentModal from '../../molecules/profile/teacher/HighlightStudentModal';
import SimpleLoading from '../../../common/SimpleLoading';
import { Favorite } from "@mui/icons-material";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../../../../utils/snackStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function defaultHandleClose() {
    console.warn('handleClose not implemented');
}

const ProfileSummary = (props) => {
    const {
        open,
        fullWidth,
        maxWidth,
        handleClose,
        contact,
        showChatButton,
        userRol,
        value = { favorito: false }
    } = props;
    const {
        partner_id,
        name,
        mobile,
        phone,
        foto,
        personal_email,
        country,
        twitter,
        profession,
        skype,
        linkedin,
        instagram,
        facebook,
        biography,
        city,
        whatsapp,
        job,
        company,
        sis_id,
        profile_is_public,
        repo_id
    } = contact;

    // debugger
    const location = city ? `${city}, ${country?.name}` : country?.name;
    const userLogin = useSelector((state) => state.auth.user);
    const activeRol = useSelector((state) => state.auth.activeRol);

    const history = useHistory();
    const dispatch = useDispatch();
    const [openSnackbar] = useSnackbar(optionsStyle);

    const [fav, setFav] = useState(false);
    const [highlight, setHighlight] = useState(null);
    const [show, setShow] = useState(false);

    //Chat students of subject
    const partnerId = contact.partner_id ? contact.partner_id : contact.id;

    useEffect(() => {
        setFav(value.favorito);
    }, [value.favorito]);

    useEffect(() => {
        !highlight &&
            open &&
            activeRol === 'docente' &&
            userRol === 'Alumno' &&
            isStudentHighlight();
    }, [highlight, open]);

    const chatGeneration = () => {
        const hash = createChannel(userLogin.partner_id, partnerId);
        const contantFormated = {
            nombre: name,
            apellido: '',
            partner_id: parseInt(partner_id, 10),
            foto: foto
        };
        dispatch(changeChannel(hash));
        dispatch(
            setParticipants([
                contantFormated,
                { ...userLogin, nombre: userLogin.name }
            ])
        );

        const uri = history.location.pathname;
        const newUri = uri.split('/');
        if (newUri[2] !== undefined) {
            if (newUri[2] === 'escenario') {
                history.push('../../networking/chat');
            }
        }
        setTimeout(() => {
            history.push('/networking/chat');
        }, 200);
    };

    const handleFav = () => (!fav ? setFavorite() : setTakeOut());

    const setFavorite = async () => {
        const req = await postFav({
            resource: repo_id,
            repo_id: userLogin.repo_id,
            // recurso | workshop | contacto
            type: 'contacto'
        });
        if (!req.error) {
            setFav(true);
        }
    };

    const setTakeOut = async () => {
        const req = await postTakeOutFav({
            resource: repo_id,
            repo_id: userLogin.repo_id,
            // recurso | workshop | contacto
            type: 'contacto'
        });
        if (!req.error) setFav(false);
    };

    const isStudentHighlight = async () => {
        const request = await getHighlight(
            repo_id || 0,
            parseInt(partner_id, 10),
            personal_email
        );
        if (request === true || request === false) {
            setHighlight(request);
        } else {
            console.log('error');
        }
    };

    const handleHighlight = async (reason) => {
        const body = {
            comment: `${reason}`,
            saved_by: `${userLogin.repo_id}`,
            repo_id: `${repo_id || 0}`,
            crm_id: `${parseInt(partner_id, 10)}`,
            email: `${personal_email}`
        };
        const request = await postHighlight(body);

        if (request.error) {
            openSnackbar('¡No se pudo destacar el alumno!');
        } else {
            openSnackbar('¡Alumno destacado exitosamente!');
        }
        setShow(true);
        isStudentHighlight();
    };


    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <MainContainer userRol={userRol}>
                <DataContainer>
                    <CloseButton color="primary" onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>
                    <ImageContainer>
                        <Image src={foto} roundedCircle width="100%" />
                        {
                            sis_id === undefined
                                ? <SimpleLoading />
                                : <CustomChip
                                    size="small"
                                    label={
                                        !!sis_id ? 'ALUMNO'
                                            : sis_id === "" && 'INVITADO'
                                    }
                                    style={{
                                        padding: '0 0.5rem',
                                        marginTop: '-1.5rem'
                                    }}
                                    userRol={userRol}
                                />


                        }
                    </ImageContainer>

                    <h1>{name}</h1>
                    <Subtitle>{profession?.name}</Subtitle>

                    {company && (
                        <Subtitle style={{ fontWeight: 'bold' }}>
                            {company}
                        </Subtitle>
                    )}
                    {job && <Subtitle>{job}</Subtitle>}
                    <Location>
                        <LocationOnIcon color="scondary" /> {location}
                    </Location>
                </DataContainer>
                <BioContainer>
                    {!!profile_is_public ? (
                        <>
                            <Title>Biografía</Title>
                            <p>{biography}</p>
                            {userLogin.is_student && (
                                <>
                                    <Title>Contactar</Title>
                                    <IconsContainer>
                                        <CustomIconButton
                                            userRol={userRol}
                                            disabled={mobile === ''}
                                            href={`tel:${mobile}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <SmartphoneIcon />
                                        </CustomIconButton>
                                        <CustomIconButton
                                            userRol={userRol}
                                            disabled={phone === ''}
                                            href={`tel:${phone}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <PhoneIcon />
                                        </CustomIconButton>
                                        <CustomIconButton
                                            userRol={userRol}
                                            disabled={personal_email === ''}
                                            href={`mailto:${personal_email}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <AlternateEmailIcon />
                                        </CustomIconButton>
                                        <CustomIconButton
                                            userRol={userRol}
                                            disabled={!facebook}
                                            href={facebook}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FacebookIcon />
                                        </CustomIconButton>
                                        <CustomIconButton
                                            userRol={userRol}
                                            disabled={!instagram}
                                            href={instagram}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <InstagramIcon />
                                        </CustomIconButton>
                                        <CustomIconButton
                                            userRol={userRol}
                                            disabled={!linkedin}
                                            href={linkedin}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <LinkedInIcon />
                                        </CustomIconButton>
                                        <CustomIconButton
                                            userRol={userRol}
                                            disabled={!twitter}
                                            href={twitter}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <TwitterIcon />
                                        </CustomIconButton>
                                        <CustomIconButton
                                            userRol={userRol}
                                            disabled={!whatsapp}
                                            href={`https://wa.me/?phone=${whatsapp}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <WhatsAppIcon />
                                        </CustomIconButton>
                                        <CustomIconButton
                                            userRol={userRol}
                                            disabled={!skype}
                                        >
                                            <i className="fab fa-skype social-network" />
                                        </CustomIconButton>
                                    </IconsContainer>
                                </>
                            )}
                            <ButtonsContainer>
                                {
                                    userRol === 'Alumno' &&
                                    activeRol === 'docente' &&
                                    highlight !== undefined && (
                                        <HighlightStudentModal
                                            title={
                                                !!highlight
                                                    ? 'Alumno destacado'
                                                    : 'Destacar alumno'}
                                            student={name}
                                            show={show}
                                            handleHighlight={handleHighlight}
                                            backgroundColor={!!highlight
                                                ? "#FFBC00"
                                                : "#ffff"}
                                            textColor={!!highlight
                                                ? "#ffffff"
                                                : "#B31D15"}
                                            border={!!highlight
                                                ? "#FFBC00"
                                                : "#A8A8A8"}
                                        />
                                    )}
                                {showChatButton && (
                                    <CustomButton
                                        handleClick={chatGeneration}
                                        variant="outlined"
                                        userRol={userRol}
                                    >
                                        <ForumIcon
                                            style={{ marginRight: '1rem' }}
                                        />{' '}
                                        Chat
                                    </CustomButton>
                                )}
                                {!!repo_id && (
                                    <CustomButton
                                        handleClick={handleFav}
                                        variant={'outlined'}
                                        userRol={userRol}
                                        disabled={!repo_id}
                                    >
                                        <Favorite
                                            style={{ marginRight: '1rem' }}
                                        />{' '}
                                        {!fav
                                            ? 'Contacto favorito'
                                            : 'Quitar contacto favorito'}
                                    </CustomButton>
                                )}
                            </ButtonsContainer>
                        </>
                    ) : profile_is_public === false ? (
                        <PrivateProfileContainer>
                            <PrivateProfileTitle>¡Vaya!</PrivateProfileTitle>
                            <PrivateProfileMsg>
                                Este usuario no ha compartido su información de
                                perfil públicamente, intenta contactarlo a
                                través del chat.
                            </PrivateProfileMsg>
                            <ButtonsContainer>
                                {
                                    userRol === 'Alumno' &&
                                    activeRol === 'docente' &&
                                    highlight !== undefined && (
                                        <HighlightStudentModal
                                            title={
                                                !!highlight
                                                    ? 'Alumno destacado'
                                                    : 'Destacar alumno'}
                                            student={name}
                                            show={show}
                                            handleHighlight={handleHighlight}
                                            backgroundColor={!!highlight
                                                ? "#FFBC00"
                                                : "#ffff"}
                                            textColor={!!highlight
                                                ? "#ffffff"
                                                : "#B31D15"}
                                            border={!!highlight
                                                ? "#FFBC00"
                                                : "#A8A8A8"}
                                        />
                                    )}
                                {showChatButton && (
                                    <CustomButton
                                        handleClick={chatGeneration}
                                        variant="outlined"
                                        userRol={userRol}
                                    >
                                        <ForumIcon
                                            style={{ marginRight: '1rem' }}
                                        />{' '}
                                        Chat
                                    </CustomButton>
                                )}
                                {!!repo_id && (
                                    <CustomButton
                                        handleClick={handleFav}
                                        variant={'outlined'}
                                        userRol={userRol}
                                    >
                                        <StarIcon
                                            style={{ marginRight: '1rem' }}
                                        />{' '}
                                        {!fav
                                            ? 'Añadir contacto'
                                            : 'Quitar contacto'}
                                    </CustomButton>
                                )}
                            </ButtonsContainer>
                        </PrivateProfileContainer>
                    )
                        : profile_is_public === undefined && <SimpleLoading />
                    }
                </BioContainer>
            </MainContainer>
        </Dialog>
    );
};

ProfileSummary.defaultProps = {
    open: false,
    fullWidth: false,
    maxWidth: 'md',
    handleClose: defaultHandleClose,
    showChatButton: true
};

ProfileSummary.propTypes = {
    open: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    handleClickOpen: PropTypes.func,
    handleClose: PropTypes.func
};

export default ProfileSummary;

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1rem;
    padding: 2rem;
    background: ${(p) =>
        p.userRol === 'Invitado'
            ? 'linear-gradient(to bottom, rgba(94, 128, 219, 0.15) 0.52%, white 40%)'
            : 'linear-gradient(to bottom, rgba(179, 29, 21, 0.15) 0.52%, white 40%)'};

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
    }
`;

const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    text-align: center;
    h1 {
        font-size: 1.5rem;
        font-weight: bold;
    }
    h2 {
        font-size: 1.2rem;
    }
`;
const BioContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 5rem;
`;

const IconsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
        justify-content: center;
        align-items: center;
    }
`;
const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;

const CustomButton = styled(Button)`
    font-weight: bold;
    border-color: ${(p) =>
        p.userRol === 'Invitado' ? '#5E80DB !important' : '#B31D15 !important'};
    color: ${(p) =>
        p.userRol === 'Invitado' ? '#5E80DB !important' : '#B31D15 !important'};

    :disabled {
        border-color: 1px solid #999999;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const CloseButton = styled.div`
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: #a3a3a3;
    cursor: pointer;
`;

const Location = styled.div`
    color: #a3a3a3;
`;

const Title = styled.div`
    font-size: 1.3rem;
    font-weight: 700;
    color: #222222;
`;

const Subtitle = styled.h2`
    color: #a3a3a3;
    font-size: 1rem;
    text-align: center;
    align-self: center;
`;

const PrivateProfileContainer = styled.div`
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
`;
const PrivateProfileTitle = styled.h2`
    font-size: 1.6rem;
    font-weight: bolder;
`;
const PrivateProfileMsg = styled.p`
    font-size: 1rem;
    color: #616161;
    margin: 1.5rem 2rem;
    text-align: center;
`;

const CustomChip = styled(Chip)`
    background-color: ${(p) =>
        p.userRol === 'Invitado' ? '#5E80DB' : '#B31D15'};
    color: #ffffff;
    font-weight: 700;
`;

const CustomIconButton = styled(IconButton)`
    color: ${(p) => (p.userRol === 'Invitado' ? '#5E80DB' : '#B31D15')};

    :hover {
        color: ${(p) => (p.userRol === 'Invitado' ? '#5E80DB' : '#B31D15')};
    }
`;
