import React, { useState } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Button, Link, Menu, MenuItem } from '@mui/material';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';

const AddEventToCalendar = (props) => {
    const { oneEvent } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        publication_name,
        category,
        // description,
        date_begin,
        date_end,
        webinar_url,
        timezone,
    } = oneEvent;

    // Convierte la hora del evento a la hora local
    const convertToLocalTime = (date, timezone) => {
        // Convierte la fecha y hora del evento a la zona horaria especificada
        const eventTime = moment.tz(date, timezone);

        // Convierte la hora del evento a la hora local
        const localTime = eventTime.clone().tz(moment.tz.guess());

        return localTime;
    };

    const date_begin_local = convertToLocalTime(date_begin, timezone);
    const date_end_local = convertToLocalTime(date_end, timezone);

    moment.locale('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
            '_'
        ),
        monthsShort:
            'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
                '_'
            ),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split(
            '_'
        ),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    });

    const calendarEvent = {
        title: publication_name,
        description: webinar_url,
        start: date_begin_local.toISOString(),
        end: date_end_local.toISOString(),
        location: category.name,
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // RETURN
    return (
        <>
            <CustomButton
                variant="contained"
                color="primary"
                aria-controls="calendars"
                aria-haspopup="true"
                onClick={handleClick}
            >
                Añadir a mi calendario
            </CustomButton>

            <Menu
                id="calendars"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItemFake onClick={handleClose}>
                    <Link href={google(calendarEvent)} target="_blank">
                        {' '}
                        Google Calendar{' '}
                    </Link>
                </MenuItemFake>
                <MenuItemFake onClick={handleClose}>
                    <Link href={outlook(calendarEvent)} target="_blank">
                        {' '}
                        Outlook{' '}
                    </Link>
                </MenuItemFake>
                <MenuItemFake onClick={handleClose}>
                    <Link href={office365(calendarEvent)} target="_blank">
                        {' '}
                        Office365{' '}
                    </Link>
                </MenuItemFake>
                <MenuItemFake onClick={handleClose}>
                    <Link href={yahoo(calendarEvent)} target="_blank">
                        {' '}
                        Yahoo{' '}
                    </Link>
                </MenuItemFake>
                <MenuItemFake onClick={handleClose}>
                    <Link href={ics(calendarEvent)} target="_blank">
                        {' '}
                        Ics{' '}
                    </Link>
                </MenuItemFake>
            </Menu>
        </>
    );
};

export default AddEventToCalendar;

const CustomButton = styled(Button)`
    width: max-content;
    font-size: 0.7rem;
    span {
        color: #ffffff;
    }
    :hover {
        color: #ffffff;
    }
`;

const MenuItemFake = styled(MenuItem)`
    .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary {
        color: #616161;
    }
    .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary:hover {
        color: #b31d15;
    }
    .MuiLink-underlineHover:hover {
        text-decoration: none;
    }
`;
