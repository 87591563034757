import styled from "styled-components";
import React from "react";
// import { Img } from "../../../common/Image";
// import { Span, Text } from "../../../common/Texts";
// import BarProgress from "../../../common/BarProgress";
// import CheckIcon from "@mui/icons-material/Check";
import { useHistory } from "react-router-dom";
import { /*Avatar*/ Button, Card } from "@mui/material";
// import { AvatarGroup } from "@mui/lab";
import * as encode from "nodejs-base64-encode";

const CardSubject = (props) => {
  const { values } = props;

  const history = useHistory();

  const hash = encode.encode(values.detail.id + "", "base64");

  const handleClick = () => {
    history.push(`/asignatura/${hash}`);
  };
  return (
    <>
      <CourseCardContainer>
        <ImgWrapper>
          <FakeImg src={values.detail.image} alt="course" />
        </ImgWrapper>
        <Body>
          <Estado estado={values.state}>
            {values.state}
          </Estado>
          {/* {values.state !== "Preinscripto" && (
            <DateGroup>
              <>
                <ItemWrapper>
                  <CalendarToday style={{ fontSize: 30 }} />
                  <DateWrapper>
                    <TextSpan>FECHA INICIO</TextSpan>
                    <DateSpan>
                      <Moment format="DD/MM/YYYY">{}</Moment>
                    </DateSpan>
                  </DateWrapper>
                </ItemWrapper>
                <ItemWrapper>
                  <EventAvailable style={{ fontSize: 30 }} />
                  <DateWrapper>
                    <TextSpan>FECHA FIN</TextSpan>
                    <DateSpan>
                      <Moment format="DD/MM/YYYY">{fecha_fin}</Moment>
                    </DateSpan>
                  </DateWrapper>
                </ItemWrapper>
              </>
            </DateGroup>
          )} */}
          <Title>{values.detail.title}</Title>
          <ProgressWrapper>
            <LinearProgressWrapper>
              <LinearProgress porcentaje={values.detail.progress} />
            </LinearProgressWrapper>
            <Percentage>{parseInt(values.detail.progress) > 100 ? 100 : parseInt(values.detail.progress)}%</Percentage>
          </ProgressWrapper>
        </Body>
        <Footer>
          {/* <UsersWrapper>
            <AvatarsWrapper>
              <UsersTitle>Alumnos</UsersTitle>
              <FakeAvatarGroup max={10} onClick={handleOpenModalAlumno}>
                {value.alumnos.map((alumno, index) => (
                  <FakeAvatar key={index} src={alumno} />
                ))}
              </FakeAvatarGroup>
            </AvatarsWrapper>
            <AvatarsWrapper>
              <UsersTitle>Profesores</UsersTitle>
              <FakeAvatarGroup onClick={handleOpenModalProfesor}>
                <Avatar src={value.profesor} />
                <Avatar src={value.academic} />
              </FakeAvatarGroup>
            </AvatarsWrapper>
          </UsersWrapper> */}
          <ButtonsWrapper>
            <Buttons state={values.state} onClick={handleClick}>
              Ver asignatura
            </Buttons>
          </ButtonsWrapper>
        </Footer>
      </CourseCardContainer>
    </>
  );
};

export default CardSubject;

const CourseCardContainer = styled(Card)`
  display: flex;
  width: 100%;
  height: 442px;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  min-height: 380px;
  border-radius: 20px;
  border: 1px solid #f2f2f2;
`;

const Body = styled.div`
  height: 160px;
  background-color: white;
  position: relative;
  bottom: 20px;
  border-top-right-radius: 20px;
  padding: 15px;
  :before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    top: -50px;
    left: 0;
    box-shadow: -30px 0 0px 0 white;
    border-bottom-left-radius: 20px;
  }
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  height: 50%;
  /* background: red; */
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: 160px;
  position: relative;
  border-bottom-left-radius: 20px;
`;

const FakeImg = styled.img`
  width: 100%;
  height: 130px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  object-fit: cover;
  object-position: center;
`;

const Estado = styled.span`
  font-size: 8px;
  font-weight: 900;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.estado === "confirmado"
      ? "#5e80db"
      : props.estado === "preinscripto"
      ? "#C29F43"
      : props.estado === "egresado"
      ? "#1E8065"
      : ""};
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 8px;
  width: fit-content;
  position: absolute;
  top: -15px;
  left: 25px;
  margin: 0 auto;
  /* transform: translate(1rem, 1rem); */
`;

// const StatusNivelation = styled.div`
//   font-size: 0.75rem;
//   text-transform: uppercase;
//   background-color: #a8a8a8;
//   color: #ffffff;
//   border-radius: 5px;
//   padding: 0.5rem 1rem;
//   width: fit-content;
//   position: absolute;
//   top: -15px;
//   right: 25px;
//   margin: 0 auto;
// `;

// const DateWrapper = styled.div`
//   display: flex;
//   align-items: left;
//   flex-direction: column;
//   column-gap: 0.5rem;
//   color: #a8a8a8;
// `;

// const ItemWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   column-gap: 0.5rem;
//   margin-top: 1rem;
//   color: #a8a8a8;
// `;

// const DateGroup = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   column-gap: 0.5rem;
//   color: #a8a8a8;

//   svg {
//     font-size: 0.9rem;
//   }
// `;

// const TextSpan = styled.span`
//   font-size: 0.5rem;
//   font-weight: 400;
// `;

// const DateSpan = styled.span`
//   font-size: 0.75rem;
//   font-weight: 400;
// `;

const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  /* height: 4rem; */
  align-items: center;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
  flex-grow: 1;
  text-align: start;
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  margin-top: auto;
`;

const LinearProgressWrapper = styled.div`
  border-radius: 50px;
  width: 100%;
  height: 7px;
  background-color: #eaeaea;
`;

const LinearProgress = styled.div`
  border-radius: 50px;
  width: ${(props) => `${props.porcentaje}%`};
  height: 7px;
  background-color: #5e80db;
`;

const Percentage = styled.span`
  font-size: 0.9rem;
  color: #5e80db;
  flex-wrap: wrap;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1.5rem;
  margin-top: auto;
  border-top: 1px solid #e8e8e8;
`;

// const UsersWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   .MuiAvatarGroup-avatar {
//     width: 35px;
//     height: 35px;
//     background-color: #ececec;
//     color: #b7b7b7;
//     font-size: 10px;
//     font-weight: 600;
//   }
// `;

// const AvatarsWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   row-gap: 0.5rem;
// `;

// const UsersTitle = styled.h2`
//   font-size: 0.75rem;
//   font-weight: 700;
//   color: #b7b7b7;
// `;

// const FakeAvatarGroup = styled(AvatarGroup)`
//   cursor: pointer;
// `;

// const FakeAvatar = styled(Avatar)`
//   width: 35px;
//   height: 35px;
// `;

// const BottomFooterWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

// const NoteWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   column-gap: 0.5rem;
//   color: #a3a3a3;
// `;

// const NoteSpan = styled.span`
//   font-size: 0.75rem;
// `;

// const NoteText = styled.span`
//   font-size: 1.2rem;
//   color: #222222;
//   font-weight: bold;
// `;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Buttons = styled(Button)`
  background-color: #5e80db;
  color: #ffffff;
  font-weight: 700;

  :hover {
    background-color: #7e9df0;
    color: #ffffff;
  }
`;
