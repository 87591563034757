import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { randomGreeting } from '../../helpers/randomGreeting';
import { Text } from './Texts';

const Greeting = () => {
    const { user } = useSelector((state) => state.auth);

    const [first, setfirst] = useState();

    const greetingRandom = useCallback(() => {
        setfirst(randomGreeting());
    }, []);

    useEffect(() => {
        greetingRandom();
    }, []);

    return (
        <Greetings>
            <Text fontSize="1.5rem" fontWeight="bold" color="#222222">
                {first} {user.name}
            </Text>
            <Text fontSize="1rem" fontWeight="bold" color="#222222">
                ¡Que alegría verte de nuevo por aquí! Échale un vistazo a tus
                pendientes y ten un excelente día.
            </Text>
        </Greetings>
    );
};

export default Greeting;

const Greetings = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
