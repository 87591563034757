import { Icon } from "@iconify/react/dist/iconify.js";
import styled from "styled-components";

// Material UI
import { Modal } from "@mui/material";

// Components
import { Text } from "../../../../../components/common/Texts";

// Assets
import StartImage from "../../../../../assets/media/aden/start.png";

const ModalStartExam = (props) => {

    const { isOpen, handleCloseModal, handleClick } = props;

    return (
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
        >
            <Wrapper>
                <CloseButton onClick={handleCloseModal}>
                    <Icon
                        icon="iconoir:cancel"
                        width="24px"
                        height="24px"
                    />
                </CloseButton>
                <img src={StartImage} alt="personas" />
                <Text
                    fontSize="24px"
                    fontWeight={700}
                    color="#b31d15"
                    textAlign="center"
                >
                    ¿Quieres comenzar <br />
                    el examen ahora?
                </Text>
                <Text
                    fontSize="16px"
                    fontWeight={500}
                    color="#222222"
                    textAlign="center"
                >
                    Ten en cuenta que una vez que empieces, <br /><b>el tiempo correrá y no podrás retroceder.</b>
                </Text>
                <span>
                    <ButtonGoTo variant="primary" onClick={handleClick}>
                        Realizar examen
                    </ButtonGoTo>
                    <ButtonGoTo variant="secondary" onClick={handleCloseModal}>
                        Lo haré luego
                    </ButtonGoTo>
                </span>
            </Wrapper>
        </Modal>
    )
}

export default ModalStartExam;

const Wrapper = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 20px;
    width: 445px;
    max-width: 90vw;
    height: fit-content;
    max-height: 90vh;
    padding: 2rem 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    overflow-y: auto;

    span {
        display: flex;
        gap: 2rem;
    }

    @media (max-width: 768px) {
        width: 320px;
        height: auto;
        padding: 2rem 1rem;
    }

    img {
        width: 300px;
        height: 300px;
    }
`;

const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #000;
`;

const ButtonGoTo = styled.button`
    cursor: pointer;
    height: 40px;
    padding: 0 24px;
    border-radius: 100px;
    background-color: ${(props) => props.variant === "primary" ? "#CD2118" : "FFF"} ;
    color: ${(props) => props.variant === "primary" ? "#FFF" : "#B31D15"};
    font-size: 15px;
    font-weight: 500;
    border: ${(props) => props.variant === "primary" ? "none" : "1px solid #B31D15"};
    transition: all 0.3s ease;

    :hover {
        background-color: #B31D15;
        color: #fff;
    }
`;