import React from 'react';
import styled from 'styled-components';

import Link from '@mui/material/Link';
import Logo from '../img/logo/logo-aden-simple-white.png';
import facebook from '../img/footer/facebook.svg';
import twitter from '../img/footer/twitter.svg';
import instagram from '../img/footer/instagram.svg';
import linkedin from '../img/footer/linkedin.svg';
import youtube from '../img/footer/youtube.svg';

const Footer = () => {
    return (
        <Container>
            <FooterBrand>
                <CustomLogo src={Logo} alt="logo" />
                <div>
                    <SocialMedia>
                        <a
                            href="https://www.facebook.com/ADENBusinessSchool"
                            target="_blank"
                        >
                            <img loading="lazy" src={facebook} />
                        </a>
                        <a href="https://twitter.com/ADENBS" target="_blank">
                            <img loading="lazy" src={twitter} />
                        </a>
                        <a
                            href="https://www.instagram.com/adenbs/"
                            target="_blank"
                        >
                            <img loading="lazy" src={instagram} />
                        </a>
                        <a
                            href="https://www.linkedin.com/school/adenbs/"
                            target="_blank"
                        >
                            <img loading="lazy" src={linkedin} />
                        </a>
                        <a
                            href="https://www.youtube.com/user/ADENBusinessSchool"
                            target="_blank"
                        >
                            <img loading="lazy" src={youtube} />
                        </a>
                    </SocialMedia>
                </div>
            </FooterBrand>
            <Divisor />
            <LinkContainer>
                <CustomLink
                    href="https://www.aden.org/acerca-de-aden/"
                    target="_blank"
                >
                    Sobre ADEN
                </CustomLink>
                <CustomLink
                    href="https://www.aden.org/nuestros-programas/"
                    target="_blank"
                >
                    Oferta Educativa
                </CustomLink>
                <CustomLink
                    href="https://www.aden.org/business-magazine/"
                    target="_blank"
                >
                    ADEN Business Magazine
                </CustomLink>
                <CustomLink href="/privacy-policy">
                    Política de privacidad
                </CustomLink>
            </LinkContainer>
            <Divisor />
            <Copyright>
                Copyright © ADEN University Campus Panamá
                <br /> UNIVERSITE DE MANAGEMENT DE SUISSE, S.A.
            </Copyright>
        </Container>
    );
};

export default Footer;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #3e3f3f;
    color: white;
    padding: 4rem 4rem;
    row-gap: 1rem;
`;

const FooterBrand = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media screen and (max-width: 700px) {
        justify-content: center;
        flex-direction: column;
    }
`;

const CustomLogo = styled.img`
    width: 200px;
    height: auto;
`;

const SocialMedia = styled.div`
    display: flex;
    column-gap: 2rem;

    img {
        width: 35px;
        height: auto;
    }

    a:hover {
        opacity: 0.8;
    }

    @media screen and (max-width: 700px) {
        padding: 2em;
    }
`;

const LinkContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

const CustomLink = styled(Link)`
    color: #666666;
    font-size: 0.75rem;
    padding: 5px 10px;
    cursor: pointer !important;

    :hover {
        color: #ffffff;
        opacity: 0.5;
        text-decoration: none;
    }
`;

const Divisor = styled.hr`
    border-top: 1px solid #616161;
    width: 100%;
    margin: 0;
    opacity: 0.3;
`;

const Copyright = styled.div`
    font-size: 0.75rem;
    text-align: center;
`;
