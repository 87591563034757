import React from 'react';
import styled from 'styled-components';

import IconInfoLeft from '../../../molecules/profile/IconInfoLeft';
import {
    School,
    LocationCity,
    Stars,
    QueryBuilder
} from '@mui/icons-material';

const AcademicInfo = (props) => {
    const { year = "-", maxTitle = "-", title = '-', university = '-'  } = props;

    return (
        <AboutMeTeacherContainer>
            <Title>Info académica</Title>
            <IconInfoLeft
                icon={<School />}
                title="Título universitario"
                data={title}
            />
            <IconInfoLeft
                icon={<LocationCity />}
                title="Universidad"
                data={university}
            />
            <IconInfoLeft
                icon={<Stars />}
                title="Título máximo"
                data={maxTitle}
            />
            <IconInfoLeft
                icon={<QueryBuilder />}
                title="Años en la industria"
                data={year}
            />
        </AboutMeTeacherContainer>
    );
};

export default AcademicInfo;

const AboutMeTeacherContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 2rem;
    border-radius: 5px;
    color: #bfbfbf;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: 700;
`;
