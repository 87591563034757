import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
    putLikes,
    getLikes,
    getCalificacion,
    getCountries,
    getNationalities,
    getDocTypes,
    getProfessions,
    getProgramsOptions,
    getJobsOptions,
    getAreasOptions
} from '../api/utils';
import { utilsActions } from '../actions';

function* sendLike(action) {
    try {
        const res = yield call(putLikes, action.payload);
        yield put(utilsActions.resourceSuccessLikes(res));
    } catch (error) {
        yield put(utilsActions.resourceFailLikes(error));
    }
}

function* likesRequest(action) {
    try {
        const res = yield call(getLikes, action.payload);
        yield put(
            utilsActions.getLikesSuccess({
                likes: res.data.response_data.likes,
                dislikes: res.data.response_data.dislikes
            })
        );
    } catch (error) {
        yield put(utilsActions.getLikesFail(error));
    }
}

function* calificacionRequest(action) {
    try {
        const res = yield call(getCalificacion, action.payload);
        yield put(utilsActions.getCalificacionSuccess(res.data.calificacion));
    } catch (error) {
        yield put(utilsActions.getCalificacionFail(error));
    }
}

function* countriesRequest() {
    try {
        const res = yield call(getCountries);
        yield put(utilsActions.getCountriesSuccess(res.data.response_data));
    } catch (error) {
        yield put(utilsActions.getCountriesFail(error));
    }
}

function* nationalitiesRequest() {
    try {
        const res = yield call(getNationalities);
        yield put(utilsActions.getNationalitiesSuccess(res.data.response_data));
    } catch (error) {
        yield put(utilsActions.getNationalitiesFail(error));
    }
}

function* docTypesRequest() {
    try {
        const res = yield call(getDocTypes);
        yield put(utilsActions.getDocTypesSuccess(res.data.response_data));
    } catch (error) {
        yield put(utilsActions.getDocTypesFail(error));
    }
}

function* professionsRequest() {
    try {
        const res = yield call(getProfessions);
        yield put(utilsActions.getProfessionsSuccess(res.data.response_data));
    } catch (error) {
        yield put(utilsActions.getProfessionsFail(error));
    }
}

function* programsOptionsRequest() {
    try {
        const res = yield call(getProgramsOptions);
        yield put(
            utilsActions.getProgramsOptionsSuccess(res.data.response_data)
        );
    } catch (error) {
        yield put(utilsActions.getProgramsOptionsFail(error));
    }
}

function* jobsOptionsRequest() {
    try {
        const res = yield call(getJobsOptions);
        yield put(utilsActions.getJobsOptionsSuccess(res.data.response_data));
    } catch (error) {
        yield put(utilsActions.getJobsOptionsFail(error));
    }
}

function* areasOptionsRequest() {
    try {
        const res = yield call(getAreasOptions);
        yield put(utilsActions.getAreasOptionsSuccess(res.data.response_data));
    } catch (error) {
        yield put(utilsActions.getAreasOptionsFail(error));
    }
}

function* utilsWatcher() {
    yield takeEvery(types.SET_LIKE_REQUEST, sendLike);
    yield takeEvery(types.GET_LIKES_REQUEST, likesRequest);
    yield takeEvery(types.GET_CALIFICACION_REQUEST, calificacionRequest);
    yield takeEvery(types.GET_COUNTRIES_REQUEST, countriesRequest);
    yield takeEvery(types.GET_NATIONALITIES_REQUEST, nationalitiesRequest);
    yield takeEvery(types.GET_DOCTYPES_REQUEST, docTypesRequest);
    yield takeEvery(types.GET_PROFESSIONS_REQUEST, professionsRequest);
    yield takeEvery(types.GET_PROGRAMS_OPTIONS_REQUEST, programsOptionsRequest);
    yield takeEvery(types.GET_JOBS_OPTIONS_REQUEST, jobsOptionsRequest);
    yield takeEvery(types.GET_AREAS_OPTIONS_REQUEST, areasOptionsRequest);
}

export { utilsWatcher };
