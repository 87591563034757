import React from "react";
import styled from "styled-components";
import { Card } from "@mui/material";
import { Text } from "../Texts";
import BarProgress from "../BarProgress";

const CardCourseStudent360Teacher = (props) => {
    const { values } = props;

    return (
        <>
            <CardFake>
                <Body>
                    <State state={values.estado}>{values.estado}</State>
                    <Text fontSize="1rem" fontWeight="bold" color="#222222">
                        {" "}
                        {values.curso}
                    </Text>
                    <Text fontSize="0.8rem" fontWeight="bold" color="#616161">
                        {" "}
                        ID_Curso: {values.curso_id}
                    </Text>
                    <BarProgress value={values.progreso} />
                  
                </Body>
            </CardFake>
        </>
    );
};

export default CardCourseStudent360Teacher;

const CardFake = styled(Card)`
    display: flex;
    flex-direction: column;
    min-height: 180px;
    border-radius: 20px;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    gap: 1rem;
`;

const Body = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem;
    position: relative;
    background-color: white;
    align-items: flex-start;
    gap: 1rem;
    width: 90%;
`;

const State = styled.div`
    height: 15px;
    width: 80px;
    border-radius: 5px;
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 800;
  
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    color: ${(props) =>
        props.state === "Confirmado"
            ? "#5e80db"
            : props.state === "Preinscripto"
            ? "#C29F43"
            : props.state === "Egresado"
            ? "#1E8065"
            : props.state === "Baja"
            ? "#FC2958"
            : ""};

    background-color: ${(props) =>
        props.state === "Confirmado"
            ? "#E6EBF9"
            : props.state === "Preinscripto"
            ? "#F9F5EC"
            : props.state === "Egresado"
            ? "#EAFAF6"
            : props.state === "Baja"
            ? "#FFE6EB"
            : ""};

    @media (min-width: 769px) and (max-width: 980px) {
        right: 40%;
    }

    @media (max-width: 500px) {
        right: 40%;
    }
`;
