import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import MenuUser from "../navegation/MenuUser";
import Messages from "../navegation/Messages";
import { MenuRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { checkRoles } from "../../../helpers/roles.helper";
import { getNotificationService } from "../../../app/modules/Services/chat.services";
import useTheme from "../../../hooks/useTheme";
import MenuAcademic from "./MenuAcademic";
import Notifications from "../navegation/Notifications";
import { getNotificationFake } from "../../../redux/api/notifications";
import GoBack from "../../../modules/acropolisCommon/components/GoBack";
import useLayout from "../../../hooks/useLayout";
import AnnouncementsNotification from "../navegation/AnnouncementsNotification";
import { getAnnouncementsByRepoId } from "../../../redux/api/announcements";

const NavegationAcademic = (props) => {
    const { toggleMenu, menu, closeMenu } = props;
    // REDUX
    const user = useSelector((state) => state.auth.user);
    const microlearnings = useSelector(
        (state) => state.microlearnings.microlearnings
    );

    const { secondary } = useTheme();

    // STATE
    const [messagesState, setMessagesState] = useState(false);
    const [notificationStateFake, setNotificationFake] = useState([]);
    const [announcements, setAnnouncements] = useState(null)
    const { menu: currentMenu } = useLayout()

    // EFFECTS
    useEffect(() => {
        if (microlearnings === null) {
            getNotifications();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [microlearnings]);

    useEffect(() => {
        if (notificationStateFake.length === 0) {
            getNotificacion();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (announcements === null) {
            getAnnouncements()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcements])

    // FUNCTIONS
    const getNotifications = async () => {
        const notification = await getNotificationService(user.partner_id);
        if (Array.isArray(notification.result)) {
            setMessagesState(notification.result);
        }
    };

    const getNotificacion = async () => {
        const response = await getNotificationFake('academico');
        if (!response.error) {
            let result = response.sort((a, b) => b.id - a.id);
            setNotificationFake(result);
        } else {
            console.error(response.error);
        }
    };

    const getAnnouncements = async () => {
        const response = await getAnnouncementsByRepoId(user.repo_id);
        if (!response.error) {
            setAnnouncements(response)
        } else {
            console.error(response.error)
        }
    }

    // Recargar Notificaciones
    const refreshNotification = () => {
        getNotificacion();
    };

    const refreshAnnouncements = () => {
        getAnnouncements();
    }

    // RETURN
    return (
        <>
            <Header color={secondary}>
                <LogoWrapper>
                    <FakeIconButton onClick={toggleMenu}>
                        <MenuRounded color="secondary" />
                    </FakeIconButton>
                    <Link to="/academico">
                        <FakeImg
                            loading="lazy"
                            src="/assets/logo-aden.svg"
                            alt="logo"
                        />
                    </Link>
                </LogoWrapper>
                <MenuWrapper>
                    <GoBack title={currentMenu} />
                </MenuWrapper>
                <Nav>
                    <AnnouncementsNotification
                        announcements={announcements?.data}
                        refreshAnnouncements={refreshAnnouncements}
                    />
                    {/** Dracmas */}
                    {/* <UserDracmas /> */}

                    {/* Mensajes */}
                    {checkRoles(["Admin", "alumno", "visita"]) && (
                        <Messages messages={messagesState} />
                    )}

                    {/* Notificaciones */}
                    <Notifications
                        notifications={notificationStateFake}
                        refreshNotification={refreshNotification}
                    />

                    {/** Menu del usuario */}
                    <MenuUser />
                </Nav>
            </Header>
            <MenuAcademic menu={menu} closeMenu={closeMenu}></MenuAcademic>
        </>
    );
};

export default NavegationAcademic;

const Header = styled.header`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  left: 0;
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: ${(p) => p.color};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 999;

  @media (max-width: 768px) {
    padding: 0 12px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-right: 1.5rem;

  @media (max-width: 425px) {
    column-gap: 0.5rem;
    margin-right: 0;
  }
`;

const MenuWrapper = styled.div`
width: 100%;
overflow: hidden;

@media (max-width: 425px) {
  column-gap: 0.5rem;
}
`;

const FakeIconButton = styled(IconButton)`
    display: none;

    @media (max-width: 768px) {
        display: flex;
    }
`;

const FakeImg = styled.img`
    max-height: 46px;
`;

const Nav = styled.nav`
    display: flex;
    align-items: center;
    column-gap: 1rem;

    @media (max-width: 425px) {
        column-gap: 0.5rem;
    }
`;
