import React, { useState } from 'react';
import styled from 'styled-components';

import {
    EventAvailable,
    EventBusy,
    CalendarToday,
    Stars,
} from '@mui/icons-material';
import Moment from 'react-moment';
import { Avatar, Button, Card } from '@mui/material';
import { AvatarGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import UsersModal from '../../organisms/my-progress/UsersModal';
import { coursesActions } from '../../../../redux/actions';
import ConfirmDialog from './ConfirmDialog';
import DialogBlockDoc from './DialogBlockDoc';
import * as encode from 'nodejs-base64-encode';
import { useHistory } from 'react-router-dom';
import LazyImg from '../../../../components/common/LazyImg';

const CampusSubjectCard = (props) => {
    const { value, index, titulationState, slider } = props;

    const fecha_inicio = value.curso_fecha_inicio;
    const fecha_vencimiento = value.curso_fecha_fin;
    const daysDateEnd = value.curso_duracion_semanas * 7;

    function addDaysToDate(date, days) {
        var res = new Date(date);
        res.setDate(res.getDate() + days);
        return res;
    }

    const userStatus = useSelector((state) => state.userStatus);
    const { push } = useHistory();

    const isBlock = !!userStatus?.blockedStatus?.blocked;

    // REDUX
    const dispatch = useDispatch();

    const baseURL = window.location.origin;
    const hashCourseId = encode.encode(value?.repo_course_id + '', 'base64');

    // STATE
    const [isOpen, setIsOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openBlock, setOpenBlock] = useState(false);
    const [user, setuser] = useState(null);
    const { canvas_login } = useSelector((state) => state.auth);

    // FUNCTIONS
    function handleOpenModalProfesor() {
        setIsOpen(true);
        setuser('Profesores');
    }

    function handleOpenModalAlumno() {
        setIsOpen(true);
        setuser('Alumnos');
    }

    function handleCloseModal() {
        setIsOpen(false);
    }

    function handleEnrollClick(curso_id, es_nivelatoria, repo_course_id) {
        let body = { curso_id, es_nivelatoria };
        body = !!repo_course_id ? { ...body, repo_course_id } : { ...body };

        dispatch(coursesActions.enrollCourseRequest(body));
    }

    const handleOpenConfirm = () => {
        !titulationState ? setOpen(true) : setOpenBlock(true);
    };

    const handleConfirm = (value, selectedCourse) => {
        setOpen(false);
        if (value) {
            handleEnrollClick(
                selectedCourse.curso_id,
                selectedCourse.es_nivelatoria,
                selectedCourse.repo_course_id
            );
        }
    };

    const handleOpenCourse = () => {
        if (value.repo_enrollment_id && value.repo_course_id) {
            // SE ABRE EN ACROPOLIS CON PUSH
            push(`../asignatura/${hashCourseId}?origin=plan`);
        }
    };

    const handleCloseBlock = () => {
        setOpenBlock(false);
    };

    // RETURN
    return (
        <>
            <CourseCardContainer slider={slider}>
                <ImgWrapper>
                    <LazyImg
                        src={`/assets/transient/courses/${index}.jpg`}
                        width="100%"
                        height="100%"
                        borderRadius="20px 20px 0 0"
                        alt="course"
                    />
                </ImgWrapper>
                <Body>
                    <Estado estado={value.estado}>
                        {value.estado == 'Confirmado'
                            ? 'En curso'
                            : value.estado}
                    </Estado>
                    {value.es_nivelatoria && (
                        <StatusNivelation>
                            Nivelatorio opcional
                        </StatusNivelation>
                    )}

                    <Title>{value.curso}</Title>

                    {value.estado !== 'Preinscripto' && (
                        <DateGroup>
                            <>
                                {!!fecha_inicio && (
                                    <ItemWrapper>
                                        <CalendarToday
                                            style={{ fontSize: 30 }}
                                        />
                                        <DateWrapper>
                                            <TextSpan>FECHA INICIO</TextSpan>
                                            <DateSpan>
                                                <Moment format="DD/MM/YYYY">
                                                    {fecha_inicio}
                                                </Moment>
                                            </DateSpan>
                                        </DateWrapper>
                                    </ItemWrapper>
                                )}

                                {!!value.curso_fecha_fin && (
                                    // <ItemWrapper>
                                    //     <EventAvailable
                                    //         style={{ fontSize: 30 }}
                                    //     />
                                    //     <DateWrapper>
                                    //         <TextSpan>FECHA FIN</TextSpan>
                                    //         <DateSpan>
                                    //             <Moment format="DD/MM/YYYY">
                                    //                 {addDaysToDate(
                                    //                     fecha_inicio,
                                    //                     daysDateEnd
                                    //                 )}
                                    //             </Moment>
                                    //         </DateSpan>
                                    //     </DateWrapper>
                                    // </ItemWrapper>
                                    <ItemWrapper>
                                        <EventBusy style={{ fontSize: 30 }} />
                                        <DateWrapper>
                                            <TextSpan>VENCIMIENTO</TextSpan>
                                            <DateSpan>
                                                <Moment format="DD/MM/YYYY">
                                                    {fecha_vencimiento}
                                                </Moment>
                                            </DateSpan>
                                        </DateWrapper>
                                    </ItemWrapper>
                                )}
                            </>
                        </DateGroup>
                    )}
                </Body>
                <Footer>
                    {value.estado == 'Confirmado' ? (
                        <BottomFooterWrapper>
                            {/* {value.estado == 'Egresado' ? (
                                <NoteWrapper>
                                    <NoteSpan>Tu nota:</NoteSpan>
                                    <NoteText>
                                        {value.nota % 1 === 0
                                            ? value.nota
                                            : value.nota.toFixed(2)}
                                    </NoteText>
                                </NoteWrapper>
                            ) : (
                                value.es_aulaunica && (
                                    <ItemWrapper>
                                        <EventBusy style={{ fontSize: 30 }} />
                                        <DateWrapper>
                                            <TextSpan>VENCIMIENTO</TextSpan>
                                            <DateSpan>
                                                <Moment format="DD/MM/YYYY">
                                                    {fecha_vencimiento}
                                                </Moment>
                                            </DateSpan>
                                        </DateWrapper>
                                    </ItemWrapper>
                                )
                            )} */}
                            {value.repo_enrollment_id &&
                            value.repo_course_id ? (
                                <FakeButton
                                    state={value.estado}
                                    variant="contained"
                                    onClick={handleOpenCourse}
                                    rel="noopener noreferrer"
                                    target={
                                        !!value.repo_enrollment_id
                                            ? '_self'
                                            : '_blank'
                                    }
                                    disabled={isBlock}
                                >
                                    {!!value.repo_enrollment_id
                                        ? 'Cursar'
                                        : 'Ir a cursar'}
                                </FakeButton>
                            ) : (
                                <FakeButton
                                    state={value.estado}
                                    variant="contained"
                                    href="https://aden.instructure.com/login/openid_connect"
                                    rel="noopener noreferrer"
                                    target={
                                        !!value.repo_enrollment_id
                                            ? '_self'
                                            : '_blank'
                                    }
                                    disabled={isBlock}
                                >
                                    {!!value.repo_enrollment_id
                                        ? 'Cursar'
                                        : 'Ir a cursar'}
                                </FakeButton>
                            )}
                        </BottomFooterWrapper>
                    ) : value.estado == 'Preinscripto' ? (
                        <BottomFooterWrapper>
                            <NoteWrapper>
                                <NoteSpan>¡Confirma para iniciar!</NoteSpan>
                            </NoteWrapper>
                            <FakeButton
                                variant="contained"
                                state={value.estado}
                                disabled={isBlock}
                                onClick={handleOpenConfirm}
                            >
                                Iniciar curso
                            </FakeButton>

                            {!titulationState ? (
                                <ConfirmDialog
                                    selectedCourse={value}
                                    open={open}
                                    onClose={handleConfirm}
                                />
                            ) : (
                                <DialogBlockDoc
                                    openBlock={openBlock}
                                    handleCloseBlock={handleCloseBlock}
                                />
                            )}
                        </BottomFooterWrapper>
                    ) : value.estado == 'Egresado' ? (
                        <BottomFooterWrapper>
                            <NoteWrapper>
                                <Stars />
                                <NoteText>
                                    {value.nota % 1 === 0
                                        ? value.nota
                                        : value.nota.toFixed(2)}
                                </NoteText>
                            </NoteWrapper>
                            <FakeButton
                                variant="contained"
                                state={value.estado}
                                href={
                                    !!value.repo_enrollment_id &&
                                    !!value.repo_course_id
                                        ? `${baseURL}/asignatura/${hashCourseId}?origin=plan`
                                        : canvas_login
                                        ? `https://aden.instructure.com/courses/${value.canvas_course_id}`
                                        : 'https://aden.instructure.com/login/openid_connect'
                                    // : "https://aden.instructure.com"
                                }
                                rel="noopener noreferrer"
                                target={
                                    !!value.repo_enrollment_id
                                        ? '_self'
                                        : '_blank'
                                }
                                disabled={isBlock}
                            >
                                Ver curso
                            </FakeButton>
                        </BottomFooterWrapper>
                    ) : (
                        <BottomFooterWrapper>
                            <NoteWrapper>
                                <NoteSpan>Tu nota:</NoteSpan>
                                <NoteText>{value.nota}</NoteText>
                            </NoteWrapper>
                            <FakeButton
                                variant="contained"
                                state={value.estado}
                                href={
                                    !!value.repo_enrollment_id &&
                                    !!value.repo_course_id
                                        ? `${baseURL}/asignatura/${hashCourseId}?origin=plan`
                                        : canvas_login
                                        ? `https://aden.instructure.com/courses/${value.canvas_course_id}`
                                        : 'https://aden.instructure.com/login/openid_connect'
                                    // : "https://aden.instructure.com"
                                }
                                rel="noopener noreferrer"
                                target={
                                    !!value.repo_enrollment_id
                                        ? '_self'
                                        : '_blank'
                                }
                                disabled={isBlock}
                            >
                                {!!value.repo_enrollment_id
                                    ? 'Cursar'
                                    : 'Ir a cursar'}
                            </FakeButton>
                        </BottomFooterWrapper>
                    )}
                </Footer>
            </CourseCardContainer>
            <UsersModal
                isOpen={isOpen}
                handleCloseModal={handleCloseModal}
                title={user}
                id={value.curso_id}
            />
        </>
    );
};

export default CampusSubjectCard;

const CourseCardContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    width: ${({ slider }) => (slider ? '100%' : '320px')};
    height: 380px;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: var(--shadow-strong);
    border-radius: 20px;
    border: 1px solid #f2f2f2;

    @media screen and (max-width: 768px) {
        width: ${({ slider }) => (slider ? '100%' : '300px')};
    }

    @media screen and (max-width: 320px) {
        width: ${({ slider }) => (slider ? '100%' : '250px')};
    }
`;

const Body = styled.div`
    height: 129px;
    background-color: white;
    position: relative;
    bottom: 20px;
    padding: 15px;
    :before {
        content: '';
        width: 50px;
        height: 50px;
        position: absolute;
        top: -50px;
        left: 0;
        box-shadow: -30px 0 0px 0 white;
    }
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    height: 50%;
`;

const ImgWrapper = styled.div`
    width: 100%;
    height: 136px;
    position: relative;
`;

const Estado = styled.span`
    font-size: 0.6rem;
    font-weight: 900;
    text-transform: uppercase;
    background-color: ${(props) =>
        props.estado === 'Confirmado'
            ? '#5e80db'
            : props.estado === 'Preinscripto'
            ? '#C29F43'
            : props.estado === 'Egresado'
            ? '#1E8065'
            : ''};
    color: #ffffff;
    border-radius: 5px;
    padding: 0.4rem 0.8rem;
    width: fit-content;
    position: absolute;
    top: -15px;
    left: 25px;
    margin: 0 auto;
`;

const StatusNivelation = styled.div`
    font-size: 0.6rem;
    text-transform: uppercase;
    background-color: #a8a8a8;
    color: #ffffff;
    border-radius: 5px;
    padding: 0.4rem 0.8rem;
    width: fit-content;
    position: absolute;
    top: -15px;
    right: 25px;
    margin: 0 auto;
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
    column-gap: 0.5rem;
    color: #a8a8a8;
`;

const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-top: 1rem;
    color: #a8a8a8;
`;

const DateGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
    color: #a8a8a8;

    svg {
        font-size: 0.9rem;
    }
`;

const TextSpan = styled.span`
    font-size: 0.5rem;
    font-weight: 400;
`;

const DateSpan = styled.span`
    font-size: 0.75rem;
    font-weight: 400;
`;

const Title = styled.h1`
    font-size: 1rem;
    font-weight: 700;
    text-align: start;
    color: #222222;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media screen and (max-width: 996px) {
        font-size: 1.2rem;
    }
`;

// COMENTADO HASTA QUE FUNCIONE BIEN EL PROGRESO
// const ProgressWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   column-gap: 1rem;
//   margin-top: auto;
// `;

// const LinearProgressWrapper = styled.div`
//   border-radius: 50px;
//   width: 100%;
//   height: 7px;
//   background-color: #eaeaea;
// `;

// const LinearProgress = styled.div`
//   border-radius: 50px;
//   width: ${(props) => `${props.porcentaje}%`};
//   height: 7px;
//   background-color: #5e80db;
// `;

// const Percentage = styled.span`
//   font-size: 0.9rem;
//   color: #5e80db;
//   flex-wrap: wrap;
// `;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1rem 1.5rem;
    margin-top: auto;
    border-top: 1px solid #e8e8e8;
`;

const BottomFooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const NoteWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: #a3a3a3;
`;

const NoteSpan = styled.span`
    font-size: 0.75rem;
`;

const NoteText = styled.span`
    font-size: 1.2rem;
    color: #222222;
    font-weight: bold;
`;

const FakeButton = styled(Button)`
    background-color: ${(p) =>
        p.state === 'Preinscripto'
            ? '#c29f43'
            : p.state === 'Confirmado'
            ? '#5e80db'
            : '#1e8065'};
    color: #ffffff;

    :hover {
        background-color: ${(p) =>
            p.state === 'Preinscripto'
                ? '#dcb54d'
                : p.state === 'Confirmado'
                ? '#7e9df0'
                : '#35ae8d'};
    }
`;
