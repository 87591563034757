import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getProducts } from '../../../../redux/api/ecommerce';
import { getTutorials } from '../../../../redux/api/support';
import ErrorResponse from '../../../common/ErrorResponse';
import GridCard from '../../../common/GridCard';
import SimpleLoading from '../../../common/SimpleLoading';
import GraduatesCard from '../../molecules/educativeOffer/GraduatesCard';
import CardTutorial from '../../molecules/support/CardTutorial';

const VideosSupport = () => {

    const [videos, setVideos] = useState(null);
    const { activeRol } = useSelector(state => state.auth)

    useEffect(() => {
        if (!videos) {
            getProduct();
        }
    }, []);

    const getProduct = async () => {
        const request = await getTutorials(activeRol);
        if (request.error) {
            return;
        }
        setVideos(request);
    };

    if (!videos) {
        return <PageLoading>
            <SimpleLoading />
        </PageLoading>
    }

    if (videos.length === 0) {
        return <PageLoading>
            <ErrorResponse message="¡No hay ningún tutorial disponible!" />
        </PageLoading>
    }

    return (
        <TutorialWrapper>
            <GridCard cards={videos} component={CardTutorial} />
        </TutorialWrapper>
    )
}

export default VideosSupport

const TutorialWrapper = styled.div`
    padding: 0 24px;
`

const PageLoading = styled.div`
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`