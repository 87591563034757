import React, { useState } from 'react'
import styled from 'styled-components'

import ErrorResponse from '../../../acropolisCommon/components/ErrorResponse';
import SimpleLoading from '../../../acropolisCommon/components/SimpleLoading';
import { formatUrlFrame } from '../../helpers/googleURL.helper';


const RenderPlaneMaterial = (props) => {

    const { resource } = props

    const [isLoad, setIsLoad] = useState(false)

    if (!resource?.link_storage) {
        return <ErrorResponse message="El material plano no esta cargado | link_storage" />
    }

    return (
        <Wrapper>
            <Iframe onLoad={() => setIsLoad(true)} src={formatUrlFrame(resource.link_storage)} />
            <Loading isLoad={isLoad}>
                <SimpleLoading />
            </Loading>
        </Wrapper>
    )
}

export default RenderPlaneMaterial

const Wrapper = styled.div`
    position: relative;
`

const Loading = styled.div`
    width: 100%;
    min-height: 500px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    display: ${p => p.isLoad && 'none'};
`

const Iframe = styled.iframe`
    border: none;
    width: 100%;
    min-height: 600px;
    height: 100%;
`