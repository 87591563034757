import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getAdvisor } from '../api/advisor';
import { advisorActions } from '../actions';

function* advisorRequest() {
    try {
        const res = yield call(getAdvisor);
        yield put(advisorActions.getAdvisorSuccess(res.data.response_data));
    } catch (error) {
        yield put(advisorActions.getAdvisorFail(error));
    }
}

function* advisorWatcher() {
    yield takeEvery(types.GET_ACADEMIC_ADVISOR_REQUEST, advisorRequest);
}

export { advisorWatcher };
