import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Material UI
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";

const QuestionGeneric = (props) => {

	const { type, question, number, handleValueQuestion, handleDataQuestion } = props;

	const [value, setValue] = useState(false);
	const [valueCheck, setValueCheck] = useState([]);
	const [stateQuestion, setstateQuestion] = useState(false);

	const correctas = question?.opciones?.filter(opcion => opcion.es_opcion_correcta === true)?.length;

	useEffect(() => {
		if (value !== false || valueCheck.length !== 0) {
			handleValueQuestion(number);
			handleDataQuestion(stateQuestion);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, valueCheck]);

	const handleChangeRadio = (event) => {
		setValue(parseInt(event.target.value, 10));
		setstateQuestion({
			pregunta_id: question.pregunta_id,
			respuestas_usuario: [parseInt(event.target.value, 10)],
		});
	};

	const handleChangeCheck = (event) => {
		if (event.target.checked === true) {
			setValueCheck([...valueCheck, parseInt(event.target.value, 10)]);
			setstateQuestion({
				pregunta_id: question.pregunta_id,
				respuestas_usuario: [...valueCheck, parseInt(event.target.value, 10)],
			});
		} else {
			let result = valueCheck.filter((el) => {
				return el !== parseInt(event.target.value, 10);
			});
			setValueCheck(result);
			setstateQuestion({
				pregunta_id: question.pregunta_id,
				respuestas_usuario: result,
			});
		}
	};

	const sanitizerHtml = (html) => {
		const string = 'src="/';
		const regex = new RegExp(string, "g");
		return html.replace(regex, 'src="');
	};

	return (
		<Container id={`${number}element`}>
			<Header>
				<Title>
					<BookmarkBorderIcon /> Pregunta {number}
				</Title>
				<Value>
					{question?.puntaje_pregunta}
					{question?.puntaje_pregunta > 1 ? " pts" : " pt"}
				</Value>
			</Header>
			<Body>
				<Question>{parse(sanitizerHtml(question?.pregunta))}</Question>
				<Answers>
					{type === "unica_opcion" && (
						<RadioGroup
							aria-label="gender"
							name="gender1"
							value={value}
							onChange={handleChangeRadio}
						>
							{question?.opciones?.map((opt) => {
								return (
									<FakeLabelRadio
										value={opt.opcion_id}
										control={<FakeRadio color={"primary"} />}
										label={opt.opcion}
									/>
								);
							})}
						</RadioGroup>
					)}
					{type === "verdadero_falso" && (
						<RadioGroup
							aria-label="gender"
							name="gender1"
							value={value}
							onChange={handleChangeRadio}
						>
							{question?.opciones?.map((opt) => {
								return (
									<FakeLabelRadio
										value={opt?.opcion_id}
										control={<FakeRadio color={"primary"} />}
										label={opt?.opcion}
									/>
								);
							})}
						</RadioGroup>
					)}
					{type === "multi_opcion" && (
						<CheckContainer onChange={handleChangeCheck}>
							{question?.opciones?.map((opt) => {
								return (
									<FakeLabelCheck
										control={
											<FakeCheckbox
												name={opt?.opcion_id}
												value={opt?.opcion_id}
												color={"primary"}
											/>
										}
										label={opt?.opcion}
									/>
								);
							})}
						</CheckContainer>
					)}
				</Answers>
				{
					correctas > 1 && (
						<Info>
							<Icon icon="octicon:info-24" width="24px" height="24px" />
							<label>En esta pregunta debes marcar más de una respuesta correcta.</label>
						</Info>
					)
				}
			</Body>
		</Container>
	);
};

export default QuestionGeneric;

const Container = styled.div`
	box-sizing: border-box;
	width: 100%;
	min-height: 200px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 30px);
	padding: 0 15px;
	height: 50px;
	background-color: #f1f1f1;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
`;

const Title = styled.div`
	font-size: 1.2rem;
	color: #6b6b6b;
	font-weight: 700;
	padding: 0 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

const Value = styled.div`
	font-size: 1.2rem;
	color: #6b6b6b;
	font-weight: 700;
	padding: 0 0.5rem;
`;

const Question = styled.div`
	max-width: 100%;
	overflow-wrap: break-word;

	span {
		white-space: pre-wrap !important;
	}
`;

const Answers = styled.div`
	width: 100%;
	padding-left: 1rem;
`;

const Info = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 8px;
	margin-bottom: 1rem;

	svg {
		color: #b31d15;
	}
`

const Body = styled.div`
	margin: 0 0 2rem 0;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	padding: 2rem;
	padding-bottom: 0;
	box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const FakeRadio = styled(Radio)`
	transform: scale(0.7);
`;

const FakeLabelRadio = styled(FormControlLabel)`
	span {
		font-size: 0.8rem;
		padding-bottom: 1rem;
	}
`;

const FakeLabelCheck = styled(FormControlLabel)`
	span {
		font-size: 0.8rem;
	}
`;

const FakeCheckbox = styled(Checkbox)`
	transform: scale(0.7);
`;

const CheckContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;