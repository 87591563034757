import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import EventItem from '../../../../pages/campus/components/common/EventItem';
import { useDispatch, useSelector } from 'react-redux';
import { utilsActions } from '../../../../redux/actions';
import { getDynamicEvents } from '../../../../redux/api/events/events';
import AdenEventsSkt from '../../../skeletons/AdenEventsSkt';
import { checkRoles } from '../../../../helpers/roles.helper';
import NoEventsFound from '../../../../assets/media/campus/no-events.png';
import LazyImg from '../../../common/LazyImg';
import { Text } from '../../../common/Texts';

const OptionWrapper = (props) => {
    const { currentTab, setLoading } = props;

    // REDUX
    const dispatch = useDispatch();
    const countries = useSelector((state) => state.utils.countries);
    const { partner_id } = useSelector((state) => state.auth.user);

    //EFFECTS
    const [event, setEvent] = useState(null);
    const [eventFake, setEventFake] = useState([]);

    // EFFECTS
    useEffect(() => {
        setEvent(null);
        if (currentTab === 0) {
            getDynamicEvent('online', 10, 'next', partner_id);
        }
        if (currentTab === 1) {
            getDynamicEvent('physical', 10, 'next', partner_id);
        }
    }, [currentTab]);

    // comentado hasta que suban el back a prod

    useEffect(() => {
        if (event !== null) {
            if (event.length > 0) {
                if (!checkRoles('alumno')) {
                    setEventFake(
                        event.filter((value) => !value.only_for_students)
                    );
                } else {
                    setEventFake(event);
                }
            }
        }
    }, [event]);

    // FUNCTIONS
    const getDynamicEvent = async (modality, limit, timelapse, partnerId) => {
        setLoading(true);
        const response = await getDynamicEvents(
            modality,
            limit,
            timelapse,
            partnerId
        );
        if (response.error) {
            setEvent([]);
        } else {
            setEvent(response);
        }
        setLoading(false);
    };

    // EFFECTS traer countries
    useEffect(() => {
        if (countries === null) {
            dispatch(utilsActions.getCountriesRequest());
        }
    }, []);

    const NotFoundComponent = () => {
        return (
            <NotFoundWrapper>
                <TextWrapper>
                    <Text color="#222222" fontSize="16px" fontWeight="700">
                        Aún no hay eventos online disponibles
                    </Text>
                    <Text color="#222222" fontSize="14px" fontWeight="400">
                        Una vez que hayan, los encontrarás aquí.
                    </Text>
                </TextWrapper>
                <LazyImg
                    src={NoEventsFound}
                    backgroundColor="#ffffff"
                    height="fit-content"
                    width="fit-content"
                />
            </NotFoundWrapper>
        );
    };

    // RETURN
    if (event === null) {
        return <AdenEventsSkt />;
    }

    return (
        <OverflowWrapper>
            <Overflow>
                {event.length > 0 ? (
                    eventFake.length > 0 ? (
                        <>
                            {eventFake.map((item) => (
                                <EventItem
                                    key={item}
                                    tabsFake={currentTab}
                                    getDynamicEvent={getDynamicEvent}
                                    countries={countries}
                                    event={item}
                                />
                            ))}
                        </>
                    ) : (
                        <NotFoundComponent />
                    )
                ) : (
                    <NotFoundComponent />
                )}
            </Overflow>
        </OverflowWrapper>
    );
};

export default OptionWrapper;

const OverflowWrapper = styled.div`
    position: relative;
    height: 100%;
    margin-bottom: auto;
`;

const Overflow = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    row-gap: 15px;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
`;

const NotFoundWrapper = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
`;
