import React from "react";
import styled, { keyframes } from "styled-components";
import GiantPericles from "../../../assets/media/aden/Pericles-Login.png"

const PublicityWithout = () => {

    return (
        <Container>
            <img src={GiantPericles} alt="ADEN" height="100%" />
            <TextContainer>
                <h1>
                    Somos la red de universidades <br />
                    y escuelas de negocios <br />
                    <b> con mayor presencia <br />
                    en América Latina. </b>
                </h1>
                <h3>
                    Formamos a los líderes de la región.
                </h3>
                <h2>
                    ADEN <i>te acompaña</i>.
                </h2>
            </TextContainer>
        </Container>
    );
};

export default PublicityWithout;

const fadeInOut = keyframes`
    0% {opacity: 1;}
    45%{opacity: 1;}
    50%{opacity: 0;}
    95%{opacity: 0;}
    100%{opacity: 1;}
`;

const fadeOutIn = keyframes`
    0% {opacity: 0;}
    45%{opacity: 0;}
    50%{opacity: 1;}
    95%{opacity: 1;}
    100%{opacity: 0;}
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    row-gap: 1rem;
    text-align: left;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50vw;
    height: 100%;
    color: #ffffff;

    img {
        position: absolute;
        top: 0;
        left: 0;
        animation: ${fadeInOut} 10s linear infinite;
    }

    @media (max-width: 1068px) {
        visibility: hidden !important;
        display: none !important;
    }
`;

const TextContainer = styled.div`
    padding-left: 4rem;
    z-index: 99;
    text-align: left;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    animation: ${fadeOutIn} 10s linear infinite;

    h1 {
        font-size: 40px;
        font-weight: 400;

        @media (max-width: 1024px) {
            font-size: 2.5rem;
        }
    }

    h2 {
        font-size: 32px;
        font-weight: 700;

        @media (max-width: 1024px) {
            font-size: 1.5rem;
        }
    }

    h3 {
        font-size: 32px;
        line-height: 140%;
        font-weight: 400;

        @media (max-width: 1024px) {
            font-size: 1rem;
        }
    }

    @media screen and (max-width: 1024) {
        padding: 0 5%;
    }
`;