import React, { useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";

import { connect, useSelector } from "react-redux";
// import { useHelpChat } from "../hooks/useHelpChat";
import { checkRoles } from "../helpers/roles.helper";
import useHotjar from "../hooks/useHotjar";
import LayoutOndemand from "../components/shared/ondemand-layout/LayoutOndemand";
import useLayout from "../hooks/useLayout";

const OndemandRoute = (props) => {
    const {
        layout = "common",
        isAuthenticated,
        blockedRoles,
        permissionRoles,
        user,
        blockedStatus,
        title = false,
        component: Component,
        ...rest
    } = props;
    // const finantial_status = blockedStatus;

    // Hooks
    // const { hash } = useHelpChat(user, isAuthenticated, "private");
    const { setMenu, clearMenu } = useLayout()
    useHotjar()
    const history = useHistory();
    const activeRol = useSelector((state) => state.auth.activeRol);

    const { pathname } = useLocation();
    const urlRedirect = pathname.slice(1);

    // FUNCTION
    const renderLayout = (layout, Component) => {
        const cases = {
            common: (
                <LayoutOndemand minPadding={false} layout={layout}>
                    {Component}
                </LayoutOndemand>
            ),
        };

        return cases[layout] || cases["common"];
    };

    if (!checkRoles(permissionRoles) || blockedRoles.includes(activeRol)) {
        history.push("./not-found");
    }

    // RETURN
    return (
        <Route
            {...rest}
            component={(props) =>
                isAuthenticated ? (
                    renderLayout(layout, <Component {...props} />)
                ) : (
                    <Redirect to={urlRedirect === 'not-found' || urlRedirect === 'logout' || urlRedirect === 'cookies' ? '/auth' : `/auth?redirect=${urlRedirect}`} />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.user,
    user: state.auth.user,
    blockedStatus: state.userStatus.blockedStatus,
});

export default connect(mapStateToProps)(OndemandRoute);
