import axios from 'axios';

export const getStudentStatus = async () => {
    try {
        const req = await axios.get(
            `${process.env.REACT_APP_REPO}/v1/repo_aden/students/status`
        );
        return req.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};
