import axios from 'axios';

export const getConditionByStudent = async (student) => {
    const URL = `${window.location.origin}/mock/coursesByStudent.json`;

    try {
        const response = await axios.get(URL);
        const coursesByStudent = response.data.response_data;
        return coursesByStudent;
    } catch (error) {
        return { error };
    }
};

export const getDocumentationStudentServices = async (body) => {
    const URL = `${window.location.origin}/mock/titulaciones.json`;

    try {
        const response = await axios.get(URL, body);
        return response.data.response_data.titulaciones;
    } catch (error) {
        return { error };
    }
};

export const getFinancesOfStudentServices = async (body) => {
    const URL = `${window.location.origin}/mock/getFinances.json`;

    try {
        const response = await axios.get(URL, body);
        return response.data.response_data.pagos;
    } catch (error) {
        return { error };
    }
};
