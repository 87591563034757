import React, { useEffect } from "react";
import styled from "styled-components";

import { IconButton } from "@mui/material";
import { ShareOutlined } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import { useHistory } from "react-router-dom";
import useTheme from "../../../../hooks/useTheme";
import moment from "moment";
import { useState } from "react";
import { postFav, postTakeOutFav } from "../../../../redux/api/utils";
import { useSelector, useDispatch } from "react-redux";
import { microlearningsActions } from "../../../../redux/actions";
import LazyImg from "../../../../modules/acropolisCommon/components/LazyImg";

const MicrolearningsCard = (props) => {
  const { value, handleOpenModal } = props;
  const { primary } = useTheme();

  const history = useHistory();
  const now = moment();
  const fecha_desde = moment(value?.fecha_desde) || "";
  const isNew = now.isoWeek() === fecha_desde.isoWeek();
  const [fav, setFav] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setFav(value.favorito);
  }, [value.favorito]);

  const handleFav = () => (!fav ? setFavorite() : setTakeOut());

  const setFavorite = async () => {
    const req = await postFav({
      resource: value.repo_id,
      repo_id: user.repo_id,
      // recurso | workshop | contacto
      type: "recurso",
    });
    if (!req.error) {
      setFav(true);
      handleStateRedux(value.repo_id, "like");
    }
  };

  const setTakeOut = async () => {
    const req = await postTakeOutFav({
      resource: value.repo_id,
      repo_id: user.repo_id,
      // recurso | workshop | contacto
      type: "recurso",
    });
    if (!req.error) {
      setFav(false);
      handleStateRedux(value.repo_id, "no-like");
    }
  };

  const handleStateRedux = (id, type) => {
    dispatch(
      type === "like"
        ? microlearningsActions.setLikes(id)
        : microlearningsActions.setNoLikes(id)
    );
  };

  const handleClick = () => {
    history.push(`./biblioteca/${value.repo_id}`);
  };

  return (
    <MicrolearningsCardContainer>
      <ImageWrapper view={value.viewed} new={isNew}>
        {/* <FakeImg
          // src={
          //     value.image
          //         ? `data:image/png;base64, ${value.image}`
          //         : "https://www.aden.org/files/sites/9/2018/09/Workshop_Miami_magazine.jpg"
          // }
          src={value.image_url}
          alt="microlearning"
        /> */}
        <LazyImg
          src={value.image_url}
          width="100%"
          height="150"
          borderRadius="20px"
        />
        {value.viewed ? (
          <>
            <BadgeView>VISTO</BadgeView>
          </>
        ) : isNew ? (
          <BadgeNew>NUEVO</BadgeNew>
        ) : null}
        <WrapperRec onClick={handleClick}>
          <PlayIcon />
        </WrapperRec>
      </ImageWrapper>
      <Body>
        {value.tags.length > 0 && (
          <TagsGrid>
            {value.tags.slice(0, 3).map((tag, index) => (
              <Tag key={index}>#{tag.toLowerCase()}</Tag>
            ))}
          </TagsGrid>
        )}
        <NombrePublicacion>{value.publication_name}</NombrePublicacion>

        <AuthorWrapper>
          <Por>Por</Por>
          <Author color={primary}>{value.acropolis_author}</Author>
        </AuthorWrapper>
      </Body>
      <Footer>
        <IconWrapper>
          <IconButton
            onClick={() =>
              handleOpenModal(value.repo_id, value.publication_name)
            }
            size="large"
          >
            <ShareOutlinedFake />
          </IconButton>
          <IconButtonFav
            isFav={fav}
            title="Marcar como favorito"
            onClick={handleFav}
          >
            <FavoriteIcon color="primary" />
          </IconButtonFav>
        </IconWrapper>
        <TimeWrapper>
          <AccessTimeIcon /> {value.duration}
        </TimeWrapper>
      </Footer>
    </MicrolearningsCardContainer>
  );
};

export default MicrolearningsCard;

const MicrolearningsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
  border: 1px solid #f2f2f2;
  transition: all linear;
  border-radius: 20px;
  max-height: 358.5px;
  max-width: auto;
  min-width: 287px;
  height: 358.5px;
`;

const BadgeNew = styled.div`
  position: absolute;
  background-color: #b31d15;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 0.8rem;
  font-weight: 900;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.06));
`;

const BadgeView = styled.div`
  position: absolute;
  background-color: #a8a8a8;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 0.8rem;
  font-weight: 900;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.06));
`;

const ImageWrapper = styled.div`
  position: relative;

  :hover {
    div:nth-child(0n + 2) {
      opacity: ${(p) => (p.view ? "0" : p.new ? "0" : "1")};
    }
  }
`;

const WrapperRec = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 2px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  border-radius: 20px;
  transition: 0.4s all ease-in-out;
  svg {
    color: #fff;
    font-size: 3.5rem;
  }

  :hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  height: 100%;
`;

const NombrePublicacion = styled.h1`
  font-size: 0.95rem;
  font-weight: 700;
  text-align: start;
  color: #222222;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: 996px) {
    font-size: 1.2rem;
  }
`;

const AuthorWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  margin-top: auto;
  width: 100%;
`;

const Por = styled.span`
  font-size: 0.85rem;
  color: #222222;
`;

const Author = styled.span`
  font-size: 0.85rem;
  color: ${(p) => p.color};
  width: 100%;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #fafafa;
  margin-top: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Tag = styled.span`
  font-size: 0.75rem;
  font-weight: 400;

  color: #a8a8a8;
  padding: 0.2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const IconButtonFav = styled(IconButton)`
  svg {
    color: ${(p) => !p.isFav && "#cccccc"};
    transform: scale(1.2);
  }
`;

const TagsGrid = styled.div`
  display: flex;
  width: 100%;
  gap: 0.3rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ShareOutlinedFake = styled(ShareOutlined)`
  color: #a8a8a8;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #a8a8a8;
`;
