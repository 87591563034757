import React from 'react';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import DownloadButton from './DownloadButton';
import { Icon } from '@iconify/react';

const DownloadableModal = (props) => {
    const { openModal, handleClose, link, name, fileType, id, description } =
        props;

    const renderContent = (content) => {
        return content.split('\n\n').map((paragraph, index) => (
            <p key={index}>
                <Icon icon="tabler:point" />
                {paragraph}
            </p>
        ));
    };

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container>
                <TitleStyle>{name}</TitleStyle>
                <SubTitle>¿Qué encontrarás en este {name} ?</SubTitle>
                <Description>{renderContent(description)}</Description>
                <DownloadButton
                    link={link}
                    name={name}
                    fileType={fileType}
                    id={id}
                />
            </Container>
        </Modal>
    );
};

export default DownloadableModal;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-width: 800px;
    max-height: 500px;
    background-color: #ffffff;
    box-shadow: 0px 12px 24px -15px #0000001a;
    box-shadow: 0px 0px 10px -6px #00000040;
    padding: 32px 40px 32px 40px;
    border-radius: 20px;
    opacity: 0px;

    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const TitleStyle = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    color: #b31d15;
`;

const SubTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background: #f9f9f9;
    width: 90%;
    padding: 2rem;
    gap: 10px;
    border-radius: 20px;
    opacity: 0px;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    svg {
        vertical-align: middle;
        margin-right: 10px;
        font-weight: 700;
        }
`;
