import React, { useState } from 'react';
import styled from 'styled-components';

import { LocationOnRounded } from '@mui/icons-material';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import * as encrypt from 'nodejs-base64-encode';
// import ShareIcon from '@mui/icons-material/Share';
import SharedProfile from '../../../ui/molecules/profile/SharedProfile';
import EditProfileTeacher from '../../../ui/organisms/profile/teacher/EditProfileTeacher';

const HeaderTeacherInfo = (props) => {
    const { foto, name, city, country, biography, street, zip } = props;

    const { user } = useSelector((state) => state.auth);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const locationData = (street, city, country, zip) => {
        if (!!street && !!city && !!country && !!zip) {
            return (
                <LocationWrapper>
                    <LocationOnRounded fontSize="small" />
                    <Location>
                        {street +
                            ', ' +
                            city +
                            ', ' +
                            country +
                            '(CP ' +
                            zip +
                            ')'}
                    </Location>
                </LocationWrapper>
            );
        } else {
            return (
                <LocationWrapper>
                    <LocationOffIcon fontSize="small" />
                    <Location>No está completa toda la dirección</Location>
                </LocationWrapper>
            );
        }
    };

    const hash = encrypt.encode(user.partner_id + '', 'base64');
    const text = `${window.location.host}/perfil-publico/${hash}`;

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    // const handleOpenModal = () => {
    //     setModalIsOpen(true);
    // };

    return (
        <HeaderTeacherInfoContainer>
            <PhotoWrapper>
                <Photo src={foto} alt="user-photo" />
            </PhotoWrapper>
            <InfoWrapper>
                <Name>{name}</Name>
                {locationData(street, city, country, zip)}
                <Biography>{biography}</Biography>
            </InfoWrapper>
            <ButtonsWrapper>
                <EditProfileTeacher />
                {/* <Button
                    onClick={handleOpenModal}
                    color="primary"
                    variant="outlined"
                    startIcon={<ShareIcon />}
                >
                    Perfil
                </Button> */}
            </ButtonsWrapper>
            
            <SharedProfile
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                link={text}
            />
        </HeaderTeacherInfoContainer>
    );
};

export default HeaderTeacherInfo;

const HeaderTeacherInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 6fr 1fr;
    grid-gap: 2rem;
    padding: 2rem;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const PhotoWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Photo = styled.img`
    width: 160px;
    height: 160px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    max-width: 160px;
    max-height: 160px;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    @media (max-width: 768px) {
        align-items: center;
    }
`;

const Name = styled.h1`
    font-size: 1.75rem;
    font-weight: 900;
`;

const LocationWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: #a3a3a3;
`;

const Location = styled.span`
    font-size: 1rem;
    font-weight: 700;
`;

const Biography = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: #a3a3a3;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ButtonFake = styled(Button)`
    width: 100%;
`