import React from 'react';
import ModuleContent from '../../teacher/modulesContents/ModuleContent';
// import ModuleContent from '../modulesContents/ModuleContent';

const Modules = (props) => {

    const { courses } = props

    // return <h1>Modules</h1>

    return <ModuleContent courses={courses}/>;
};

export default Modules;
