import React from 'react';
import CommonChip from '../../CommonChip';
import { Icon } from '@iconify/react';
import styled from 'styled-components';

const NotificationChip = (props) => {
    const { content } = props;

    const baseChipProps = {
        variant: 'status',
    };

    const getChipProps = (state) => {
        switch (state) {
            case 'rework_activities':
                return {
                    label: 'Tienes actividades por rehacer',
                    strongColor: '#B31D15',
                    lightColor: '#FFF',
                    icon: 'mingcute:alert-line',
                };
            case 'pending_correction':
                return {
                    label: 'Tienes actividades sin corregir',
                    strongColor: '#C29F43',
                    lightColor: '#F9F5EC',
                    icon: 'fa6-solid:file-circle-exclamation',
                };
            // case 'level':
            //     return {
            //         label: 'Nivelatorio opcional',
            //         strongColor: '#616161',
            //         lightColor: '#E5E5E5',
            //         icon: 'fa6-solid:chart-simple',
            //     };
            default:
                return null;
        }
    };

    const chipProps = Array.isArray(content)
        ? content.reduce((acc, item) => ({ ...acc, ...getChipProps(item) }), {})
        : {};

    if (!chipProps) return null;
    return (
        <RedoActivity>
            <CommonChip
                {...baseChipProps}
                {...chipProps}
                Icon={() => (
                    <Icon icon={chipProps.icon} width="16px" height="16px" />
                )}
            />
        </RedoActivity>
    );
};

export default NotificationChip;

const RedoActivity = styled.div`
    min-height: 32px;
    position: absolute;
    z-index: 2;
    top: 90px; //img de la card es de higth:130px
    left: 10px;
`;
