import React from "react";
import useLike from "../../../../hooks/common/useLike";

import styled from "styled-components";
import { IconButton } from "@mui/material";
import { Favorite, ShareOutlined, AccessTime } from "@mui/icons-material";

const SocialsFooter = (props) => {
  const {
    isFav,
    title = "Marcar como favorito",
    duration = false,
    resourceId,
    onClickModal,
  } = props;

  const { handleFavorite, favorite } = useLike();

  return (
    <Wrapper>
      <SocialsWrapper>
        <CustomIconButton onClick={onClickModal}>
          <ShareOutlined />
        </CustomIconButton>
        <CustomIconButton
          title={title}
          onClick={() => handleFavorite(resourceId)}
          isFav={favorite}
        >
          <Favorite />
        </CustomIconButton>
      </SocialsWrapper>
      {!!duration && (
        <TimeWrapper>
          <DurationWrapper>
            <AccessTime /> {duration}
          </DurationWrapper>
        </TimeWrapper>
      )}
    </Wrapper>
  );
};

export default SocialsFooter;

const Wrapper = styled.footer`
  background-color: #ffffff;
  padding: 0.7rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 20px 20px;
`;

const SocialsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  svg {
    color: #cccccc;
  }
`;

const CustomIconButton = styled(IconButton)``;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${(p) => (p.isFav ? "#b31d15" : "#cccccc")};
  }
`;

const DurationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
