import { useContext } from "react";
import styled from "styled-components";
import { Text } from "../../../../components/common/Texts";
import Calendar from "./Calendar";
import EventsDateList from "./EventsDateList";
import { SummitContext } from "../../../../contexts/summit/SummitProvider";

const Schedule = () => {
  const { conversationDates, conversations, getAllSummitEvents, loading } =
    useContext(SummitContext);

  return (
    <ScheduleContainer>
      <Header>
        <Text color="#ffffff" fontSize="24px" fontWeight="400">
          Agenda Cumbre Latinoamericana 2024 | <b>Los juegos del Management</b>
        </Text>
      </Header>
      <Body>
        <Calendar dates={conversationDates} />
        <EventsDateList
          conversations={conversations}
          getAllSummitEvents={getAllSummitEvents}
          loading={loading}
        />
      </Body>
    </ScheduleContainer>
  );
};

export default Schedule;

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: auto;
  border-radius: 20px;
  box-shadow: 0px 0px 10px -0px rgba(0, 0, 0, 0.25),
    0px 12px 24px -15px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  border-radius: 20px 20px 0 0;
  display: grid;
  place-items: center;
  padding: 1.3rem;
  background: linear-gradient(#b31d15, #841510);
`;

const Body = styled.section`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: 2rem;
  height: auto;
  padding: 1.5rem;
`;
