import axios from "axios";

export const getInfoEvent = async (id) => {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/events/${id}`;

    try {
        const req = await axios.get(URL);

        return req.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export async function handleAttendance(idEvent, email) {
    try {
        const req = await axios.get(
            `${process.env.REACT_APP_SISAPI}/v1/acropolis/events/attended_email/${idEvent}/${email}`
        );

        return req.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
}
