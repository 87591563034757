
import styled from "styled-components";
import Copyright from "../../common/Copyright";

const Footer = () => {
    return (
        <FooterContainer>
            <Copyright />
        </FooterContainer>
    )
}

export default Footer;

const FooterContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 120px;
    background-color: #B31D15;

    @media (width < 768px) {
        height: 218px;
    }
`