import React from "react";
import styled from "styled-components";
import SimpleLoading from "../../../../common/SimpleLoading";

const CardProgramsOneStudent360 = (props) => {
    const { program } = props;

    // RETURN
    if (!program) {
        return <SimpleLoading />;
    }

    return (
        <Card>
            <Name>
                {program?.programa?.nombre_programa ||
                    "Nombre del programa no definido"}
            </Name>
            <TypeProgram>
                {program?.programa?.tipo_programa || "No definido"}
            </TypeProgram>
            <Modality>
                {program?.programa?.modalidad_programa || "No definida"}
            </Modality>
        </Card>
    );
};

export default CardProgramsOneStudent360;

const Card = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    background: linear-gradient(269.94deg, #fef6f6 0.05%, #ffffff 99.95%),
        #ffffff;
    position: relative;
`;

const Name = styled.h2`
    font-size: 1rem;
    color: #222222;
   
`;

const TypeProgram = styled.div`
    padding-bottom: 2rem;
`;

const Modality = styled.div`
    position: absolute;
    background-color: #fff;
    color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 16px;
    right: 16px;
    padding: 5px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: .6rem;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
`;