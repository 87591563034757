import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    }
}));

const Input = (props) => {
    //const { label, variant, type, required, disabled, helperTex, error, name, defaultValue, value, onChange, color, size, placeholder } = props;

    const classes = useStyles();
    return (
        <FormControl style={{ width: '100%' }}>
            <TextField margin="normal" style={{ width: '100%' }} {...props} />
        </FormControl>
    );
};

Input.defaultProps = {
    disabled: false,
    error: false,
    helperText: '',
    label: 'text input',
    multiline: false,
    required: false,
    rows: 1,
    rowsMax: 5,
    type: 'text',
    variant: 'standard',
    color: 'primary',
    size: 'medium'
};
Input.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    label: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
        'text',
        'password',
        'number',
        'search',
        'email',
        'url'
    ]),
    required: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
    color: PropTypes.oneOf(['primary', 'secondary']),
    size: PropTypes.oneOf(['medium', 'small']),
    placeholder: PropTypes.string
};

export default Input;
