import * as types from '../types';

const actions = {};

actions.getInterestsRequest = () => {
    return {
        type: types.GET_INTERESTS_REQUEST
    };
};

actions.getInterestsSuccess = (interests) => {
    return {
        type: types.GET_INTERESTS_SUCCESS,
        payload: interests
    };
};

actions.getInterestsFail = (error) => {
    return {
        type: types.GET_INTERESTS_FAIL,
        payload: error
    };
};

export { actions };
