import React from "react";
import styled from "styled-components";
import Footer from "../../app/landing/components/Footer";
import SimpleNav from "../../app/landing/components/Navegation/SimpleNav";
import { Text } from "../../components/common/Texts";
import AfterSalesForm from "../../components/ui/molecules/afterSales/AfterSalesForm";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';


const AfterSales = () => {

    return (
        <>
        <MainContainer>
            <SimpleNav />
            <Container>
                <Text fontSize="1.05rem" fontWeight="700" component="h1" textAlign="start">
                    A través de este Formulario de Postventa podrás enviarnos 
                    <Text fontSize="1rem" fontWeight="400"> <DoneRoundedIconFake color="primary" /> tipo de solicitud </Text>
                    <Text fontSize="1rem" fontWeight="400"> <DoneRoundedIconFake color="primary" /> datos del alumno</Text>
                    <Text fontSize="1rem" fontWeight="400"> <DoneRoundedIconFake color="primary" /> departamento y referente derivador</Text>
                    <Text fontSize="1rem" fontWeight="400"> <DoneRoundedIconFake color="primary" /> descripción </Text>
                    <Text fontSize="1rem" fontWeight="400"> <DoneRoundedIconFake color="primary" /> archivo adjunto.</Text>
                </Text>
               
                <AfterSalesForm />       
            </Container>
        </MainContainer>
        <Footer />
        </>
    );
};

export default AfterSales;

const MainContainer = styled.div`
    margin-top: 100px;
    padding: 2rem 5%;
`;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding: 1rem;
    border-radius: 20px;
    width: 100%;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    background-color: #fff;
    text-align: center;
`;

const DoneRoundedIconFake = styled(DoneRoundedIcon)`
    font-size: 1rem;
`;
