import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import DefaultComplete from '../../../common/DefaultComplete';
import { utilsActions } from '../../../../redux/actions';
import SimpleLoading from '../../../common/SimpleLoading';
import DefaultField from '../../../common/DefaultField';
import DefaultPhone from '../../../common/DefaultPhone';

const OptionFour = (props) => {
    const { values, setFieldValue } = props;

    // REDUX
    const dispatch = useDispatch();
    const professions = useSelector((state) => state.utils.professions);
    const jobs = useSelector((state) => state.utils.jobsOptions);
    const areas = useSelector((state) => state.utils.areasOptions);

    // EFFECTS
    useEffect(() => {
        if (professions === null) {
            dispatch(utilsActions.getProfessionsRequest());
        }
        if (jobs === null) {
            dispatch(utilsActions.getJobsOptionsRequest());
        }
        if (areas === null) {
            dispatch(utilsActions.getAreasOptionsRequest());
        }
    }, []);

    // RETURN
    if (!professions || !jobs || !areas) {
        return <SimpleLoading padding="8rem" />;
    }

    return (
        <OverflowWrappper>
            <Container>
                <InputWraqpper>
                    {/** Titulo de grado */}
                    <DefaultComplete
                        name="profession"
                        label="Título de grado"
                        value={values.profession}
                        setFieldValue={setFieldValue}
                        options={professions}
                    />
                    {/** Empresa actual */}
                    <DefaultField
                        name="company"
                        type="text"
                        label="Empresa actual"
                    />
                    {/** Cargo actual */}
                    <DefaultComplete
                        name="current_job"
                        label="Cargo actual"
                        value={values.current_job}
                        setFieldValue={setFieldValue}
                        options={jobs}
                    />
                    {/** Área laboral */}
                    <DefaultComplete
                        name="job_area"
                        label="Área laboral"
                        value={values.job_area}
                        setFieldValue={setFieldValue}
                        options={areas}
                    />
                    {/** Email laboral */}
                    <DefaultField
                        name="work_email"
                        type="email"
                        label="Email laboral"
                    />
                    {/** Telefono laboral */}
                    <div>
                        <DefaultPhone
                            name="work_phone"
                            label="Teléfono laboral"
                            value={values.work_phone}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                    {/** Experiencia laboral */}
                    <FullField
                        name="work_experience"
                        type="text"
                        label="Experiecia Laboral"
                        multiline
                        rows={4}
                    />
                    {/** Formación Académica */}
                    <FullField
                        name="academic_training"
                        type="text"
                        label="Formación Académica"
                        multiline
                        rows={4}
                    />
                </InputWraqpper>
            </Container>
        </OverflowWrappper>
    );
};

export default OptionFour;

const OverflowWrappper = styled.div`
    height: 100%;
    position: relative;
`;

const Container = styled.div`
    padding: 2rem 1rem 2rem 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;

const InputWraqpper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    width: 100%;
    height: max-content;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem;
    }

    @media (max-width: 425px) {
        padding: 2rem 0;
    }
`;

const FullField = styled(DefaultField)`
    grid-column: span 2;
`;
