import React from 'react';
import styled from 'styled-components';

import { Skeleton } from '@mui/material';

const FilterSubjectSkeleton = () => {
    return (
        <Container>
            <main>
                <Fake animation="wave" />
                <Fake animation="wave" />
            </main>
            <div>
                <Fake animation="wave" />
            </div>
        </Container>
    );
};

export default FilterSubjectSkeleton;

const Container = styled.div`
    position: relative;
    main {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
    }
    div {
        position: relative;
        top: -20px;
    }
`;

const Fake = styled(Skeleton)`
    width: calc(100% - 2rem);
    height: 100px;
    color: black;
    margin: 0;
`;
