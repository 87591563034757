import { Snackbar, Alert } from "@mui/material";

const GenericSnackbar = (props) => {
  const {
    open,
    vertical = "bottom",
    horizontal = "center",
    severity = "success",
    message = "No hay mensaje",
    handleClose = () => ""
  } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
    > 
      <Alert sx={{ width: "100%" }} severity={severity} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default GenericSnackbar;
