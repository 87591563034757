import React from 'react';
import styled from 'styled-components';
import GridResource from '../../../common/GridResource';
import WorkshopCard from '../campus/WorkshopCard';

const WorkshopsFavs = (props) => {
    const { workshops } = props;

    const mutateResource = (array) =>
        array.map((item) => ({ ...item, favorito: true }));

    return (
        <GridMain>
            <GridResource
                propsValues={mutateResource(workshops || [])}
                Card={WorkshopCard}
                handleOpenModal={() => {}}
                type={'workshop'}
            />
        </GridMain>
    );
};

export default WorkshopsFavs;

const GridMain = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 5px;
`;
