import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import SubjectAnnouncements from '../../../ui/organisms/subject/SubjectAnnouncements';
import TabsComponent from '../../../common/TabsComponent';
import StatisticsDashboard from './StatisticsDashboard';
import CourseDashboard from './CourseDashboard';
import Qualifications from './Qualifications';
import Instructors from './Instructors';
import Persons from './Persons';
import Modules from './Modules';

// Material UI
import { Card } from '@mui/material';

// Hooks
import useTabHistory from '../../../../hooks/useTabHistory';

const CourseTabs = (props) => {
    const { course } = props;

    const { handleTabHistory, initialTab } = useTabHistory()

    const tabs = [
        { label: `Tablero`, id: 0, href:`/profesor/curso/${course.id}?tab=0`},
        { label: `Módulos y contenido`, id: 1, href:`/profesor/curso/${course.id}?tab=1` },
        { label: 'Calificaciones', id: 2, href:`/profesor/curso/${course.id}?tab=2` },
        { label: `Participantes`, id: 3, href:`/profesor/curso/${course.id}?tab=3` },
        { label: `Instructores`, id: 4, href:`/profesor/curso/${course.id}?tab=4` },
        { label: `Estadísticas`, id: 5, href:`/profesor/curso/${course.id}?tab=5` },
        { label: 'Anuncios', id: 6, href:`/profesor/curso/${course.id}?tab=6` },
    ];

    const [tab, setTab] = useState(initialTab);

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
        handleTabHistory(`?tab=${value}`);
    };

    function ToggleMain(props) {
        const { tab } = props;

        const cases = {
            0: <CourseDashboard id={course.id} />,
            1: <Modules courses={course.courses} />,
            2: <Qualifications id={course.id} />,
            3: <Persons id={course.id} />,
            4: <Instructors idCourse={course.id} />,
            5: <StatisticsDashboard id={course.id} />,
            6: <SubjectAnnouncements id={course.id} professor={true} />,
        };

        return cases[String(tab)] || <CourseDashboard />;
    }

    return (
        <Card>
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            <BodyWrapper>
                <ToggleMain tab={tab} />
            </BodyWrapper>
        </Card>
    );
};
export default CourseTabs;

const BodyWrapper = styled.div`
    padding: 1rem;
`;
