import axios from 'axios';

export function getPartnerData(payload) {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/users/profile-summary/${payload}`;
    const partner_data = axios.get(URL);

    return partner_data;
}

export const getNotificationService = async (id) => {
    const URL = `${process.env.REACT_APP_CHAT}/api/getNotifications`;
    try {
        const response = await axios.post(URL, { user_id: id }); //Error 500 - el código original también devuelve error 500
        
        return response.data.result;
    } catch (error) {
        return { error };
    }
};