import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Img } from '../../common/Image'
import year from "../../../assets/media/aden/30.png";
import Questionnaire from './Questionnaire';
import axios from 'axios';

// const BASEURL = 'https://sheet.best/api/sheets/dc121530-ac30-4455-813b-d72eab378376'
const BASEURL = ''
const TAB_QUESTION = '129727291'

const Anniversary = () => {

    const [open, setOpen] = useState(false)
    const [questions, setQuestions] = useState(null)

    useEffect(() => {
        getQuestions()
    }, [])
    

    const handleToggle = () => {
        setOpen(!open)
    }

    const getQuestions = async () => {
        try {
            const req = await axios.get(`${BASEURL}`)
            const response = sanitizerExcel(req?.data)
            setQuestions(response)
        } catch (error) {
            return error
        }
    }

    const sanitizerExcel = data => {

        

        return data.map(item => {
            let response = []

            for (const key in item) {
                if (key !== 'pregunta' && !!item[key]) {
                    const split = !!item[key] && item[key].split('-')
                    response = [...response, {
                        text: split[1],
                        isCorrect: split[0] === 't' ? true : false
                    }]
                }
            }
            return {
                title: item.pregunta,
                response: response
            }
        })
    }

    if (!questions) {
        return ''
    }

    return (<>
        <AnniversaryButton open={false} onClick={handleToggle}>
            <Img w="130px" src={year} />
        </AnniversaryButton>
        <Question open={open}>
            <Questionnaire questions={questions}/>
        </Question>
    </>
    )
}

export default Anniversary

const AnniversaryButton = styled.div`
    width: ${p => p.open ? '160px' : '140px'};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: #b31d15;
    position: fixed;
    right: 0;
    top: 100px;
    border-bottom-left-radius: ${p => p.open ? '0' : '5px'};
    border-top-left-radius: 5px;
    z-index: 999;
    cursor: pointer;
    transition: ease-in-out .3s all;
    :hover{
        filter: brightness(1.2);
        width: 160px;
    }
    @media(max-width: 768px){
        top: 70px;
        border-top-left-radius: 0px;
    }
`

const Question = styled.div`
    width: 400px;
    height: auto;
    padding: 15px 0;
    background-color: white;
    position: fixed;
    top: 160px;
    z-index: 999;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    right: ${p => p.open ? '0' : '-400px'};
    box-shadow:${p => p.open && '-4px 5px 20px -3px rgba(110,110,110,0.75)'};
    transition: ease-in-out .3s all;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
        top: 130px;
        border-radius: 0px;
    }
`