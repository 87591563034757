import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import * as base64 from "nodejs-base64-encode";

// FETCH AND STATE
import { setNewPassword, sendRecoverEmail } from "../../../../redux/api/auth";

// COMPONENTS
import styled from "styled-components";
import Text from "../../../common/Text";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
	recoverEmailSchema,
	recoverPasswordSchema,
} from "../../../../utils/schemas";
import { TaskAlt } from "@mui/icons-material";
import SimpleLoading from "../../../common/SimpleLoading";
import GenericSnackbar from "../../../common/GenericSnackbar";
import { Icon } from "@iconify/react/dist/iconify.js";
import CommonButton from "../../../common/CommonButton";

const RecoverForm = (props) => {
	const { handleTab } = props;

	const initialValues = {
		email: "",
		password: "",
		confirmedPassword: "",
	};

	// REDUX
	const history = useHistory();
	const { recover_id } = useParams();
	const params = useQueryParams();
	const loginId = base64?.decode(
		params.size !== 0 ? params?.get("user") : "",
		"base64"
	);

	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [snackbarContent, setSnackbarContent] = useState({
		message: "",
		severity: "success",
	});

	const handleOpenSnackbar = (message, severity) => {
		setSnackbarContent({
			message: message,
			severity: severity,
		});
		setOpenSnackbar(true);
	};

	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	const handleRedirectionToLogin = () => {
		handleTab(0);
		history.push("/auth");
	};

	const handleSubmitEmail = async (values) => {
		setIsLoading(true);
		try {
			const response = await sendRecoverEmail(values.email);
			if (response.data.status_code === 200) {
				handleOpenSnackbar("¡Correo enviado!", "success");
				setIsLoading(false);
				setStatus(true);
				return;
			}
		} catch (error) {
			handleOpenSnackbar("¡No se encontró el usuario!", "warning");
			setIsLoading(false);
		}
	};

	const handleSubmitNewPassword = async (values) => {
		setIsLoading(true);
		if (values.password !== values.confirmedPassword) {
			handleOpenSnackbar("¡Las contraseñas no coinciden!", "warning");
			return;
		}
		const request = await setNewPassword(loginId, values.password, recover_id);

		if (request.status === 200) {
			handleOpenSnackbar("¡La contraseña se cambió con éxito!", "success");
			setIsLoading(false);
			setStatus(true);
			setTimeout(() => {
				handleRedirectionToLogin();
			}, 1000);
			return;
		}
	};

	return (
		<Wrapper>
			<Content>
				<Text fontWeight="800" fontSize="24px" color="#B31D15">
					{
						!status ? "Recuperación de contraseña" : "¡Correo enviado!"
					}
				</Text>
				{
					!status &&
					<Text fontWeight="400" textColor="#222" fontSize="1rem">
						{!!recover_id
							? "Ingresa tu nueva contraseña"
							: "Ingresa tu correo electrónico y recibirás un enlace para reestablecer tu clave."}
					</Text>
				}
			</Content>
			{!recover_id ? (
				isLoading ? (
					<SimpleLoading />
				) : !status ? (
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmitEmail}
						validationSchema={recoverEmailSchema}
					>
						{({ values }) => (
							<FormContainer>
								{/** EMAIL */}
								<FormField>
									<FieldFake
										name="email"
										type="email"
										value={values.email}
										placeholder="Correo electrónico"
										fullWidth
									/>
									{
										values.email !== ''
											? <FieldLabel>Correo electrónico</FieldLabel>
											: <ErrorMessage name="email" render={msg => (
												<Error>
													<Icon
														icon="mage:exclamation-circle"
														width="16px"
														height="16px"
													/>
													<span>Es necesario que ingreses tu correo eletrónico</span>
												</Error>
											)} />
									}
								</FormField>
								<ButtonsWrapper>
									<CommonButton
										label="Enviar"
										type="submit"
										variant="filled"
									/>
									<CommonButton
										label="Volver al inicio"
										onClick={() => handleTab(0)}
										variant="text"
									/>
								</ButtonsWrapper>
							</FormContainer>
						)}
					</Formik>
				) : (
					<>
						<Text fontWeight="500" textColor="#222" fontSize="1rem">
							Te hemos enviado un correo electrónico, sigue <br />
							las instrucciones para cambiar tu <br />
							contraseña.
						</Text>
						<ButtonsWrapper>
							<CommonButton
								label="Volver al inicio"
								onClick={() => handleTab(0)}
								variant="text"
							/>
						</ButtonsWrapper>
					</>
				)
			) : (
				<>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmitNewPassword}
						validationSchema={recoverPasswordSchema}
					>
						{({ values }) =>
							isLoading ? (
								<SimpleLoading />
							) : !status ? (
								<FormContainer>
									<PassWrapper>
										<FieldFake
											name="password"
											type="password"
											value={values.password}
											placeholder="Contraseña"
											fullWidth
										/>
										{
											values.password !== ""
												? <FieldLabel>Contraseña</FieldLabel>
												: <ErrorMessage name="password" render={msg => (
													<Error>
														<Icon
															icon="mage:exclamation-circle"
															width="16px"
															height="16px"
														/>
														<span>Es necesario que ingreses tu contraseña</span>
													</Error>
												)} />
										}
									</PassWrapper>
									<PassWrapper>
										<FieldFake
											name="confirmedPassword"
											type="password"
											value={values.confirmedPassword}
											placeholder="Confirmar contraseña"
											fullWidth
										/>
										{
											values.password !== ""
												? <FieldLabel>Confirmar contraseña</FieldLabel>
												: <ErrorMessage name="confirmedPassword" render={msg => (
													<Error>
														<Icon
															icon="mage:exclamation-circle"
															width="16px"
															height="16px"
														/>
														<span>Es necesario que ingreses tu contraseña confirmada</span>
													</Error>
												)} />
										}
									</PassWrapper>
									<ButtonsWrapper>
										<CommonButton
											variant="filled"
											type="submit"
											label="Confirmar"
										/>
										<CommonButton
											variant="text"
											label="Volver al inicio"
											onClick={() => handleRedirectionToLogin()}
										/>
									</ButtonsWrapper>
								</FormContainer>
							) : (
								<FakeTaskAlt />
							)
						}
					</Formik>
				</>
			)}
			<GenericSnackbar
				open={openSnackbar}
				message={snackbarContent.message}
				severity={snackbarContent.severity}
				handleClose={handleCloseSnackbar}
			/>
		</Wrapper>
	);
};

export default RecoverForm;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 2.3rem;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;

	@media (max-width: 768px) {
        width: 90%;
    }
`;

const FormContainer = styled(Form)`
	margin-top: 16rem 0 0 0;
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const PassWrapper = styled.div`
	width: 60%;
    position: relative;
    color: #B31D15;

	@media (width <= 1500px) {
		width: 100%;
	}

	@media (width <= 768px) {
		width: 80%;
	}
`;

const FormField = styled.div`
	width: 60%;
    position: relative;
    color: #B31D15;

	@media (width <= 768px) {
		width: 80%;
	}
`;

const FieldFake = styled(Field)`
    font-size: 14px;
    outline: none;
	border: 0px;
    border-bottom: 1px solid #B31D15;
    padding: 0.5rem 0 0.5rem 0;
    height: 20px;
    width: calc(100% - 5rem);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        box-shadow: 0 0 0px 1000px #fff inset;
        -webkit-text-fill-color: #000;
    }

	@media (width <= 1600px) {
		width: 100%;
	}
`;

const FieldLabel = styled.label`
    position: absolute;
    bottom: -1.2rem;
    left: 36px;
    padding: 0px 5px;
    font-weight: 600;
    font-size: 12px;
    @media (max-width: 768px) {
        left: 0px;
		padding: 0;
        font-size: 10px;
    }
`

const Error = styled.div`
	position: absolute;
    bottom: -1.2rem;
    left: 36px;
    padding: 0px 5px;
    font-weight: 600;
    font-size: 12px;
	display: flex;
	align-items: center;
	gap: 4px;
	color: #E94C44;

	span {
		white-space: nowrap;
	}

	@media (max-width: 1600px) {
		bottom: -1.5rem;
        left: 0px;
		padding: 0;
    }
`

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	margin-top: 1rem;
`;

const FakeTaskAlt = styled(TaskAlt)`
  	color: #2ce43b;
`;
