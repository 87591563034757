import { useEffect, useState } from "react";
import useVimeo from "../../acropolisCommon/hooks/useVimeo";

import styled from "styled-components";
import AutoPlay from "../../coursesModule/components/ui/molecules/course/player/AutoPlay";
import SimpleLoading from "../../../components/common/SimpleLoading";

const Vimeo = (props) => {
    const {
        videoLink,
        nextResource,
        handleNext,
        handleCurrentSecond,
        onProgress,
        CASES_TO_EXCLUDE,
        dispatchState,
        nextVideo,
        valuesNotes,
        nombre_publicacion,
        isChangeMinute,
        currentMinute,
        handleIsChangeMinute,
    } = props;

    const { player, vimeoVideoRef, video, loading, handleStopVideo, failed } =
        useVimeo(videoLink);

    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(null);
    const [cancelModal, setCancelModal] = useState(true);

    const progressBar = document.getElementById("{vimeo_player}");

    // const hola = progressBar.document.getElementById("player");

    // console.log("progressBar", progressBar );

    useEffect(() => {
        (!!player || failed) && videoProgressController();
    }, [player]);

    useEffect(() => {
        isChangeMinute && handleCurrentMinute(currentMinute);
    }, [isChangeMinute]);

    const handleCancelModal = () => {
        setCancelModal(false);
    };

    const getCurrentVideoTime = async () => {
        try {
            const seconds = await player.getCurrentTime();
            const duration = await player.getDuration();

            setCurrentTime(seconds);
            handleCurrentSecond(seconds);
            setTotalTime(duration);
            onProgress(seconds, duration);
        } catch (error) {
            console.log("Current-Time", error);
        }
    };

    const onEnded = () => {
        if (currentTime === totalTime) {
            // handleNext();
            setCurrentTime(0);
            setTotalTime(null);
        }
    };

    const handleCurrentMinute = (second) => {
        player.setCurrentTime(second);
        handleStopVideo();
        handleIsChangeMinute(false);
    };

    const videoProgressController = () => {
        try {
            player.on("timeupdate", getCurrentVideoTime);
            onEnded();
        } catch (error) {
            console.log("Progress Controller", error);
        }
    };

    return (
        <PlayerWrapper>
            <VimeoPLayer
                id={"{vimeo_player}"}
                ref={vimeoVideoRef}
                data-vimeo-url={video}
                isLoading={loading}
            />
            {nextVideo &&
                nextResource &&
                !CASES_TO_EXCLUDE.includes(nombre_publicacion) &&
                Math.trunc(totalTime - currentTime) === 0 &&
                cancelModal && (
                    <AutoPlayWrapper>
                        <AutoPlay
                            handleStop={handleStopVideo}
                            nextResource={nextResource}
                            handleNext={handleNext}
                            handleCancelModal={handleCancelModal}
                            player={player}
                            dispatchState={dispatchState}
                            nombre_publicacion={nombre_publicacion}
                        />
                    </AutoPlayWrapper>
                )}
        </PlayerWrapper>
    );
};

export default Vimeo;

const PlayerWrapper = styled.div`
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
`;

const VimeoPLayer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
`;

const LoadingWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
`;

const AutoPlayWrapper = styled.div`
    width: 380px;
    height: 180px;
    background-color: rgba(179, 29, 21, 0.65);
    position: absolute;
    margin-bottom: 2rem;
    right: 1rem;
    bottom: 1rem;
    border-radius: 20px;
    z-index: 8;
    transition: all 0.3s ease-in-out;

    :hover {
        background-color: rgba(179, 29, 21, 0.8);
    }
`;

const StopVideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #333333dd;
    position: absolute;
    z-index: 9;
    right: 0;
    top: 0;
    border-radius: 0.3rem;
`;
