import React from "react";
import styled from "styled-components";

import Countdown, { zeroPad } from "react-countdown";
import moment from "moment";
import { useSelector } from "react-redux";

const BannerLayout = (props) => {
    const { evento } = props;
    const authToken = useSelector((state) => state.auth.authToken);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Text>¡ACCEDE AHORA!</Text>;
        } else if (days >= 1) {
            return (
                <Text>
                    {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m{" "}
                    {zeroPad(seconds)}s
                </Text>
            );
        } else if (days < 1 && hours > 1) {
            return (
                <Text>
                    {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
                </Text>
            );
        } else if (days < 1 && hours < 1) {
            return (
                <Text>
                    {zeroPad(minutes)}m {zeroPad(seconds)}s
                </Text>
            );
        } else {
            return null;
        }
    };

    const getLocalUTC = () => {
        const hours = new Date().getTimezoneOffset();
        if (hours > 0) {
            return `-${hours / 60}`;
        } else {
            return `+${(hours * -1) / 60}`;
        }
    };

    const utc = getLocalUTC();
    const date_from_local = new Date(evento.date_from + `${utc} UTC`);

    moment.locale("es", {
        months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
            "_"
        ),
        monthsShort: "Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.".split(
            "_"
        ),
        weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split(
            "_"
        ),
        weekdaysShort: "Dom._Lun._Mar._Mier._Jue._Vier._Sab.".split("_"),
        weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
    });

    const date_string = moment(date_from_local).locale("es").format("LL");

    const time_string = moment(date_from_local).format("HH:mm");

    // RETURN
    return (
        <Banner>
            <BannerSpan>
                Reviví los mejores momentos que nos dejó la Cumbre
                Latinoamericana de Management 2022
            </BannerSpan>

            <BannerActions>
                {/* <Countdown
                    date={date_from_local.getTime()}
                    renderer={renderer}
                /> */}
                <CustomButton
                    href={`https://live.aden.org/redirect?authToken=${authToken}`}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Volver a ver
                </CustomButton>
            </BannerActions>
        </Banner>
    );
};

export default BannerLayout;

const Banner = styled.header`
    height: 70px;
    position: fixed;
    z-index: 999;
    /* background-color: #b31d15; */
    width: calc(100% - 8rem);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
    color: #ffffff;
    font-size: 1.2rem;
    padding: 0px 4rem;

    background: linear-gradient(-45deg, #b31d15, #ba2137, #d10623, #b31d15);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;

    @media screen and (max-width: 1300px) {
        padding: 0px 3rem;
        width: calc(100% - 6rem);
        font-size: 1rem;
    }

    @media screen and (max-width: 768px) {
        height: 110px;
        padding: 1rem;
        width: calc(100% - 2rem);
        flex-direction: column;
        row-gap: 1rem;
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
`;

const BannerSpan = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 1rem;
    @media screen and (max-width: 768px) {
        padding: 0 2rem;
    }
`;

const BannerActions = styled.div`
    display: flex;
    column-gap: 2rem;
    align-items: center;
    justify-content: center;
`;

const CustomButton = styled.a`
    /* color: #ffffff; */
    background-color: #ffffff;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 5px;
    font-weight: 700;
    text-decoration: none;

    :hover {
        background-color: rgba(255, 255, 255, 0.8);
        color: #b31d15;
        text-decoration: none;
    }

    :visited {
        color: #b31d15;
    }
`;

const Text = styled.span`
    font-size: 1.2rem;
    font-weight: 800;
    color: #ffffff;
`;
