import styled from "styled-components";

// Components
import InterestsAndMotivations from "./mainInfo/InterestsAndMotivations";
import FormationAndExperience from "./mainInfo/FormationAndExperience";

const MainInfo = () => {

    return (
        <MainInfoContainer>
            <h1>Formación y experiencia</h1>
            <FormationAndExperience />
            <h1>Intereses y motivaciones</h1>
            <InterestsAndMotivations />
        </MainInfoContainer>
    )
}

export default MainInfo;

const MainInfoContainer = styled.div`
    width: calc(66% - 24px);
    min-height: 70vh;
    height: 100%;
    border: 1px solid #A8A8A8;
    border-radius: 20px;
    background-color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 24px;

    h1 {
        color: #b31d15;
        font-size: 20px;
        font-weight: 700;
    }

    @media (width < 1560px) {
        width: calc(66% - 24px);
    }

    @media (width < 1150px) {
        width: calc(100% - 4rem);
    }
`