import { useState } from 'react';
import styled from 'styled-components';

// Components
import { Text } from '../../../../../components/common/Texts';

// Material UI
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useStatus from '../../../../../hooks/common/useStatus';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ExpandMore from '@mui/icons-material/ExpandMore';
import OpenInNew from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';

const DeliveriesDescription = (props) => {
    const { description: activities, handleClick } = props;

    const { setLightStateColor, setStrongStateColor } = useStatus();

    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleAccordion = (value, len) => {
        value === len - 1 && setOpen(!open);
        setIsOpen(!isOpen);
    };

    return (
        <DescriptionContainer>
            {activities?.map((activity, i) => {
                /* asignación de estado y rehacer */
                const submissionsArray = [...activity?.submissions].reverse();
                const redo = submissionsArray[0]?.redo;
                const state = submissionsArray[0]?.state;
                const grade = submissionsArray[0]?.grade;

                return (
                    <>
                        <DescriptionWrapper key={activity?.name}>
                            <ActivityCard>
                                <IconContainer>
                                    {!!state || !!redo ? (
                                        <CheckCircle />
                                    ) : (
                                        <RadioButtonUnchecked />
                                    )}
                                </IconContainer>
                                <InfoWrapper>
                                    <DescriptionText title={activity?.name}>
                                        <FakeIconButton
                                            onClick={() =>
                                                handleClick(activity?.id)
                                            }
                                        >
                                            <OpenInNew />
                                        </FakeIconButton>
                                        <ActivityNameWrapper>
                                            <Text
                                                ellipsis={true}
                                                fontSize="14px"
                                                fontWeight="700"
                                                textColor="#616161"
                                            >
                                                {activity?.name}
                                            </Text>
                                        </ActivityNameWrapper>
                                    </DescriptionText>
                                    <ActivityInfo>
                                        <ActivityInfoWrapper>
                                            {i === 0 && (
                                                <DescriptionHead>
                                                    <Text
                                                        fontSize="14px"
                                                        fontWeight="700"
                                                        textColor="#616161"
                                                    >
                                                        Fecha de entrega
                                                    </Text>
                                                </DescriptionHead>
                                            )}
                                            {activity?.submissions?.length >
                                            0 ? (
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="400"
                                                    textColor="#616161"
                                                >
                                                    {
                                                        submissionsArray[0]?.delivery_date
                                                    }
                                                </Text>
                                            ) : (
                                                <Text
                                                    fontSize="16px"
                                                    fontWeight="600"
                                                >
                                                    -
                                                </Text>
                                            )}
                                        </ActivityInfoWrapper>
                                        <ActivityInfoWrapper>
                                            {i === 0 && (
                                                <DescriptionHead>
                                                    <Text
                                                        fontSize="14px"
                                                        fontWeight="700"
                                                        textColor="#616161"
                                                    >
                                                        Estado
                                                    </Text>
                                                </DescriptionHead>
                                            )}
                                            <Chip
                                                setLightStateColor={setLightStateColor(
                                                    redo
                                                        ? 'rehacer'
                                                        : state ===
                                                          'auto_corregido'
                                                        ? 'corregido'
                                                        : state
                                                )}
                                                setStrongStateColor={setStrongStateColor(
                                                    redo
                                                        ? 'rehacer'
                                                        : state ===
                                                          'auto_corregido'
                                                        ? 'corregido'
                                                        : state
                                                )}
                                            >
                                                {!!state && !redo
                                                    ? state === 'pendiente'
                                                        ? 'Pendiente corrección'
                                                        : state
                                                              ?.charAt(0)
                                                              .toUpperCase() +
                                                          state
                                                              .slice(1)
                                                              .replaceAll(
                                                                  '_',
                                                                  ''
                                                              )
                                                    : !!redo
                                                    ? 'Rehacer'
                                                    : 'Sin entregas'}
                                            </Chip>
                                        </ActivityInfoWrapper>
                                        <ActivityInfoWrapper>
                                            {i === 0 && (
                                                <DescriptionHead>
                                                    <Text
                                                        fontSize="14px"
                                                        fontWeight="700"
                                                        textColor="#616161"
                                                    >
                                                        Tipo de actividad
                                                    </Text>
                                                </DescriptionHead>
                                            )}
                                            <TypeChip type={state}>
                                                {state === 'auto_corregido'
                                                    ? 'Autocorregible'
                                                    : 'Presentación'}
                                            </TypeChip>
                                        </ActivityInfoWrapper>
                                        <ActivityInfoWrapper>
                                            {i === 0 && (
                                                <DescriptionHead>
                                                    <Text
                                                        fontSize="14px"
                                                        fontWeight="700"
                                                        textColor="#616161"
                                                    >
                                                        Calificación
                                                    </Text>
                                                </DescriptionHead>
                                            )}
                                            {activity?.submissions?.length &&
                                            !redo ? (
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="400"
                                                    textColor="#616161"
                                                >
                                                    <b>{grade}</b> / 100
                                                </Text>
                                            ) : (
                                                <Text
                                                    fontSize="16px"
                                                    fontWeight="600"
                                                >
                                                    -
                                                </Text>
                                            )}
                                        </ActivityInfoWrapper>
                                    </ActivityInfo>
                                </InfoWrapper>
                            </ActivityCard>
                            {activity.submissions.length > 1 && (
                                <HistoryAccordion
                                    onClick={() =>
                                        handleAccordion(i, activities?.length)
                                    }
                                >
                                    <FakeAccordionSummary
                                        expandIcon={
                                            <ExpandMore fontSize="medium" />
                                        }
                                    >
                                        <LineHider
                                            index={i}
                                            activitiesAmount={
                                                activities?.length
                                            }
                                            submissionsAmount={
                                                activity?.submissions?.length
                                            }
                                            height={open ? 6 : 2}
                                            top="-1.5rem"
                                            left="-2.25rem"
                                        />
                                        {/* <Text fontSize="14px" fontWeight="700" textColor="#B31D15">
                      {current === i  && isOpen ? "Ocultar" : "Mostrar"} historial de entregas
                    </Text> */}
                                        <SumaryHeader>
                                            <Text
                                                fontSize="14px"
                                                fontWeight="700"
                                                textColor="#B31D15"
                                            >
                                                Historial de entregas
                                            </Text>
                                        </SumaryHeader>
                                    </FakeAccordionSummary>
                                    <FakeAccordionDetails>
                                        {submissionsArray.map(
                                            (submission, j) => {
                                                return (
                                                    j !== 0 && (
                                                        <>
                                                            <DeliveryCard>
                                                                <DescriptionText
                                                                    title={
                                                                        activity?.name
                                                                    }
                                                                >
                                                                    <FakeIconButton
                                                                        onClick={() =>
                                                                            handleClick(
                                                                                activity?.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <OpenInNew />
                                                                    </FakeIconButton>
                                                                    <Text
                                                                        ellipsis={
                                                                            true
                                                                        }
                                                                        fontSize="14px"
                                                                        fontWeight="700"
                                                                        textColor="#616161"
                                                                    >
                                                                        {
                                                                            activity?.name
                                                                        }
                                                                    </Text>
                                                                </DescriptionText>
                                                                <ActivityInfo>
                                                                    <ActivityInfoWrapper>
                                                                        <Text
                                                                            fontSize="14px"
                                                                            fontWeight="400"
                                                                            textColor="#616161"
                                                                        >
                                                                            {
                                                                                submission?.delivery_date
                                                                            }
                                                                        </Text>
                                                                    </ActivityInfoWrapper>
                                                                    <ActivityInfoWrapper>
                                                                        <Chip
                                                                            state={
                                                                                submission?.state
                                                                            }
                                                                            redo={
                                                                                submission?.redo
                                                                            }
                                                                        >
                                                                            {!!submission?.state &&
                                                                            !submission?.redo
                                                                                ? submission?.state?.replaceAll(
                                                                                      '_',
                                                                                      ''
                                                                                  )
                                                                                : !!submission?.redo
                                                                                ? 'Rehacer'
                                                                                : 'Pendiente'}
                                                                        </Chip>
                                                                    </ActivityInfoWrapper>
                                                                    <ActivityInfoWrapper>
                                                                        <TypeChip
                                                                            type={
                                                                                submission?.state
                                                                            }
                                                                        >
                                                                            {submission?.state ===
                                                                            'auto_corregido'
                                                                                ? 'Autocorregible'
                                                                                : 'Presentación'}
                                                                        </TypeChip>
                                                                    </ActivityInfoWrapper>
                                                                    <ActivityInfoWrapper>
                                                                        {submission?.grade &&
                                                                        !submission?.redo ? (
                                                                            <Text
                                                                                fontSize="14px"
                                                                                fontWeight="400"
                                                                                textColor="#616161"
                                                                            >
                                                                                <b>
                                                                                    {
                                                                                        submission?.grade
                                                                                    }
                                                                                </b>{' '}
                                                                                /
                                                                                100
                                                                            </Text>
                                                                        ) : (
                                                                            <Text
                                                                                fontSize="16px"
                                                                                fontWeight="600"
                                                                            >
                                                                                -
                                                                            </Text>
                                                                        )}
                                                                    </ActivityInfoWrapper>
                                                                </ActivityInfo>
                                                            </DeliveryCard>
                                                        </>
                                                    )
                                                );
                                            }
                                        )}
                                    </FakeAccordionDetails>
                                </HistoryAccordion>
                            )}
                        </DescriptionWrapper>
                    </>
                );
            })}
        </DescriptionContainer>
    );
};

export default DeliveriesDescription;

const DescriptionContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
`;

const DescriptionWrapper = styled.div`
    height: auto;
    padding: 0 5rem 0 2.5rem;
    z-index: 5;
`;

const DescriptionHead = styled.div`
    position: absolute;
    top: -7.5rem;
    bottom: 0;
    margin: auto;
    min-width: 120px;
    max-height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ActivityCard = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background-color: #f1f1f1;
    padding: 1rem;
    border-radius: 5px;
`;

const IconContainer = styled.div`
    position: absolute;
    left: -2.71rem;
    svg {
        padding: 0;
        margin: 0;
        color: #b31d15;
        background-color: #ffffff;
    }
`;

const InfoWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
`;

const FakeIconButton = styled(IconButton)`
    z-index: 5;
    svg {
        width: 18px;
        height: 18px;
        color: #616161;
    }
`;

const DescriptionText = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 420px;
`;

const ActivityInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    align-items: center;
`;

const ActivityInfoWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
`;

const Chip = styled.div`
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.6rem;
    font-weight: bold;
    letter-spacing: 0.07px;
    text-align: center;

    color: ${(p) => p.setStrongStateColor};

    background-color: ${(p) => p.setLightStateColor};
`;

const TypeChip = styled.span`
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.6rem;
    font-weight: bold;
    letter-spacing: 0.07px;
    color: ${(p) => (p.type !== 'auto_corregido' ? '#222222' : '#FFFFFF')};
    background-color: ${(p) =>
        p.type !== 'auto_corregido' ? '#C4C4C4' : '#222222'};
`;

const HistoryAccordion = styled(Accordion)`
    position: relative;
    box-shadow: none !important;
    margin: 0 !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    svg {
        color: #b31d15;
    }

    .MuiCollapse-vertical {
        width: 100% !important;
    }

    &.MuiAccordion-root::before {
        content: none;
    }
`;

const FakeAccordionSummary = styled(AccordionSummary)`
    position: relative;
    padding: 0 !important;
    width: 100% !important;
`;

const SumaryHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const FakeAccordionDetails = styled(AccordionDetails)`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 0 1.5rem 0 !important;
`;

const DeliveryCard = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 35% 65%;
    width: 100% !important;
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 5px;
`;

const LineHider = styled.div`
    position: absolute;
    height: calc(
        100% +
            ${(p) =>
                p.submissionsAmount > 2
                    ? p.height * (p.submissionsAmount - 1)
                    : p.height}rem
    );
    width: 12px;
    top: ${(p) => (p.top ? p.top : '0')};
    margin: auto;
    left: ${(p) => (p.left ? p.left : '0')};
    background-color: #ffffff;
    z-index: 0;
    visibility: ${(p) =>
        p.index === p.activitiesAmount - 1 && p.submissionsAmount > 1
            ? 'visibible'
            : 'hidden'};
    transition: all 0.3s;
`;

const ActivityNameWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
