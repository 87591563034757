import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
    getAdditionalCourses,
    getOndemandByCompetitor,
    getOndemandById
} from '../api/ondemand';
import { ondemandActions } from '../actions';

function* ondemandRequest(action) {
    try {
        const res = yield call(getOndemandById, action.payload);
        if (res.data.error === undefined) {
            yield put(
                ondemandActions.getOndemandSuccess(res.data.response_data)
            );
        } else {
            yield put(ondemandActions.getOndemandFail(res.data.error));
        }
    } catch (error) {
        yield put(ondemandActions.getOndemandFail(error));
    }
}

function* ondemandCompetitorRequest(action) {
    try {
        const res = yield call(getOndemandByCompetitor, action.payload);
        if (res.data.error === undefined) {
            yield put(
                ondemandActions.getOndemandByCompetitorSuccess(
                    res.data.response_data
                )
            );
        } else {
            yield put(
                ondemandActions.getOndemandByCompetitorFail(res.data.error)
            );
        }
    } catch (error) {
        yield put(ondemandActions.getOndemandByCompetitorFail(error));
    }
}

function* ondemandAdditionalCoursesRequest(action) {
    try {
        const res = yield call(getAdditionalCourses, action.payload);
        if (res.data.error === undefined) {
            yield put(ondemandActions.getAddiotionalCoursesSuccess(res.data));
        } else {
            yield put(
                ondemandActions.getOndemandByCompetitorFail(res.data.error)
            );
        }
    } catch (error) {
        yield put(ondemandActions.getOndemandByCompetitorFail(error));
    }
}

function* ondemandWatcher() {
    yield takeEvery(types.GET_ONDEMAND_REQUEST, ondemandRequest);
    yield takeEvery(
        types.GET_ONDEMANDCOMPETITOR_REQUEST,
        ondemandCompetitorRequest
    );
    yield takeEvery(
        types.GET_COURSES_ADDITIONAL_REQUEST,
        ondemandAdditionalCoursesRequest
    );
}

export { ondemandWatcher };
