import React, { useEffect } from "react";
import styled from "styled-components";
import CardStudentRepo from "../../components/ui/molecules/academic/CardStudentRepo";
import StudentTabsRepo from "../../components/ui/molecules/academic/StudentTabsRepo";
import { useParams } from "react-router-dom";
import useOneStudentRepo from "../../hooks/academic/useOneStudentRepo";
import SimpleLoading from "../../components/common/SimpleLoading";
import { useQueryParams } from "../../hooks/useQueryParams";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import useLayout from "../../hooks/useLayout";

const AcademicOneStudentRepo = () => {
    const { id } = useParams();

    const params = useQueryParams();
    const repoId = params.get("repo");

    const {
        studentInfo,
        loadingInfo,
        subjectsRepo,
        loadingSubjects,
        programsRepo,
        loadingPrograms,
    } = useOneStudentRepo(id, repoId);

    const { setMenu, clearMenu } = useLayout()

    // FUNCTIONS
    const handleGoUp = () => {
        window.scroll(0, 0);
    };

    useEffect(() => {
        if(studentInfo) {
            setMenu(studentInfo?.name || "Alumno 360")
        }
    }, [studentInfo])
    


    return (
        <Container
            onScroll={() => {
                console.info("hola estoy en scroll");
            }}
        >
            <Wrapper>
                {loadingInfo ? (
                    <Center>
                        <SimpleLoading />
                    </Center>
                ) : (
                    <Header>
                        <CardStudentRepo id={id} studentInfo={studentInfo} />
                        <LastAccess>
                            <span>último acceso a la plataforma</span>
                            <p>{studentInfo?.app_last_login || "-"}</p>
                        </LastAccess>
                    </Header>
                )}
                <StudentTabsRepo
                    subjectsRepo={subjectsRepo}
                    loadingSubjects={loadingSubjects}
                    programsRepo={programsRepo}
                    loadingPrograms={loadingPrograms}
                    repoId={repoId}
                />
            </Wrapper>
            <GoUp title="Ir arriba" onClick={handleGoUp}>
                <ArrowUpwardIcon />
            </GoUp>
        </Container>
    );
};

export default AcademicOneStudentRepo;

const Container = styled.div``;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`;

const Header = styled.div`
    display: grid;
    width: 100%;
    gap: 1rem;
    grid-template-columns: 2fr 2fr;
    align-items: flex-start;
`;

const LastAccess = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    gap: 0.3rem;

    span {
        color: #616161;
        text-transform: uppercase;
        font-size: 12px;
    }

    p {
        color: #222222;
        font-size: 1rem;
        font-weight: bold;
    }
`;

const Center = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
`;

const GoUp = styled.div`
    position: fixed;
    bottom: 15px;
    left: 80px;
    background-color: #b31d15;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 12px 24px -15px rgba(0, 0, 0, 0.1),
        0px 0px 10px -6px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    svg {
        color: #fff;
        font-size: 2.5rem;
    }
`;
