import React from 'react';
import Star from '@mui/icons-material/Star';
import StarHalf from '@mui/icons-material/StarHalf';
import styled from 'styled-components';
import StarsResume from './StarsResume';

const RatingSummary = (props) => {
    const { review } = props;
    let percentage = 0;

    const accountantRating = (review) => {
        review.map((review) => {
            percentage = percentage + parseInt(review.rating, 10);
        });
        percentage = percentage / review.length;
    };
    accountantRating(review);

    return (
        <Container>
            <Score>{percentage.toFixed(1)}</Score>
            <StarsResume percentage={percentage} />
            <p>{`${review.length} reseñas`}</p>
        </Container>
    );
};

export default RatingSummary;

const Container = styled.div`
    flex-basis: calc(25% - 1rem);

    p {
        margin-top: 1rem;
        text-align: center;
        margin: 0 auto;
        color: #a8a8a8;
    }
`;

const Stars = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        color: #f7bb56;
        font-size: 1.5rem;
    }
`;

const Score = styled.span`
    display: block;
    font-weight: bold;
    font-size: 3.4rem;
    text-align: center;
    margin: 0 auto;
`;
