import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { authActions, dracmaActions } from "../../../../redux/actions";
import {
    assignDracma,
    saveDracmaChallenge,
} from "../../../../redux/api/dracma.api";
import { Text } from "../../../common/Texts";
import parse from "html-react-parser";

const QuestionChallengeDracma = (props) => {
    const { handleClose, values } = props;

    const [seconds, setSeconds] = useState(0);
    const [status, setStatus] = useState(null);
    const [radio, setRadio] = useState(null);
    const totalTime = 60;

    const { user } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    useEffect(() => {
        if (seconds < totalTime) {
            setTimeout(() => {
                if (typeof seconds === "number") {
                    setSeconds(seconds + 1);
                }
            }, 1000);
        }
        if (seconds === totalTime && status === null) {
            setSeconds(null);
            setStatus("time");
            sendResult("time");
        }
    }, [seconds]);

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, "g");
        return html.replace(regex, 'src="');
    };

    const handleClick = async () => {
        const body = {
            description: "Respuesta correcta - Dracma Challenge",
            category: "Reacciones en los recursos",
            repositorio_partner_id: user.repo_id,
            points: 20,
            must_be_unique: false,
            // date: new Date(),
            is_acropolis_lab: !!user.ondemand_id,
            acropolis_lab_id: user.ondemand_id || "",
        };
        setSeconds(null);
        const radioResult = getIsCorrect(radio);
        if (radioResult === "correct") {
            // Asignar Dragma
            const request = await assignDracma(body);
            if (request.error) {
                console.log("Error al asignar dracmas");
            }
        }
        sendResult(radioResult);
        setStatus(radioResult);
    };

    const sendResult = async (type) => {
        const body = {
            repo_id: user.repo_id,
            crm_partner_id: user.partner_id,
            type: type,
            res_id: values.question.id,
        };
        const request = await saveDracmaChallenge(body);
        return request;
    };

    const getIsCorrect = (value) => {
        const result = values.question.options.find(
            (item) => item.option === value
        );
        return result.is_correct ? "correct" : "incorrect";
    };

    const handleLater = () => {
        dispatch(
            authActions.setChallengeDracma({
                state: false,
                date: new Date().toLocaleDateString(),
            })
        );
        handleClose();
    };

    const handleRadio = (e) => {
        setRadio(e.target.value);
    };

    if (status === "correct") {
        //ACTUALIZAR DRACMAS EN HOME
        dispatch(dracmaActions.getDracmaRequest());

        return (
            <div>
                <HeaderCorrect>
                    <Text color="white" fontSize="18px" fontWeight="400">
                        ¡Correcto!
                    </Text>
                    <Title color="white" fontSize="48px" fontWeight="900">
                        +20 Dracma Points
                    </Title>
                </HeaderCorrect>
                <Body>
                    <Text
                        color="#222222"
                        textAlign="center"
                        fontSize="24px"
                        fontWeight="700"
                    >
                        ¡Muy bien {user.name}, sumas Dracmas!
                    </Text>
                    <Text
                        color="#222222"
                        textAlign="center"
                        fontSize="16px"
                        fontWeight="700"
                    >
                        Nos veremos mañana con un nuevo desafío
                    </Text>
                    <Buttons>
                        <Button
                            onClick={handleLater}
                            color="primary"
                            variant="contained"
                        >
                            Ok
                        </Button>
                    </Buttons>
                </Body>
            </div>
        );
    }

    if (status === "incorrect") {
        return (
            <div>
                <HeaderInCorrect>
                    <Text color="white" fontSize="18px" fontWeight="400">
                        ¡Incorrecto!
                    </Text>
                    <Title color="white" fontSize="48px" fontWeight="900">
                        No sumas puntos
                    </Title>
                </HeaderInCorrect>
                <Body>
                    <Text
                        color="#222222"
                        textAlign="center"
                        fontSize="24px"
                        fontWeight="700"
                    >
                        ¡Que no decaiga, la próxima será!
                    </Text>
                    <Text
                        color="#222222"
                        textAlign="center"
                        fontSize="16px"
                        fontWeight="700"
                    >
                        Nos vememos mañana con un nuevo desafío
                    </Text>
                    <Buttons>
                        <Button
                            onClick={handleLater}
                            color="primary"
                            variant="contained"
                        >
                            Ok, lo intentaré mañana
                        </Button>
                    </Buttons>
                </Body>
            </div>
        );
    }

    if (status === "time") {
        return (
            <div>
                <Header>
                    <Text color="white" fontSize="18px" fontWeight="400">
                        Ups
                    </Text>
                    <Title color="white" fontSize="48px" fontWeight="900">
                        ¡Se acabó el tiempo!
                    </Title>
                </Header>
                <Body>
                    <Text
                        color="#222222"
                        textAlign="center"
                        fontSize="18px"
                        fontWeight="700"
                    >
                        Intenta de nuevo mañana
                    </Text>
                    <Buttons>
                        <Button
                            onClick={handleLater}
                            color="primary"
                            variant="contained"
                        >
                            Ok, lo intentaré mañana
                        </Button>
                    </Buttons>
                </Body>
            </div>
        );
    }

    return (
        <div>
            <Header>
                <Text color="white" fontSize="18px" fontWeight="400">
                    El tema de hoy es:
                </Text>
                <Title
                    color="white"
                    fontSize="24px"
                    fontWeight="900"
                    textAlign="center"
                >
                    {values.challenge_name}
                </Title>
            </Header>
            <Body>
                <WrapperQuestion>
                    <Text
                        color="#222222"
                        textAlign="center"
                        fontSize="18px"
                        fontWeight="700"
                    >
                        {/* {values.question.name} */}
                        {parse(sanitizerHtml(values.question.description))}
                    </Text>
                </WrapperQuestion>

                <Progress value={seconds} />
                <FormQuestion value={radio} onChange={handleRadio}>
                    {values.question.options.map((item) => (
                        <FormControlLabelFake
                            value={item.option}
                            control={<Radio />}
                            label={item.option}
                        />
                    ))}
                </FormQuestion>
                <Buttons>
                    <Button
                        onClick={handleClick}
                        color="primary"
                        variant="contained"
                    >
                        Confirmar
                    </Button>
                </Buttons>
            </Body>
        </div>
    );
};

export default QuestionChallengeDracma;

const Header = styled.div`
    width: 100%;
    height: 146px;
    background: linear-gradient(
        96.54deg,
        #990800 0%,
        #b31d15 49.19%,
        #e5271c 99.04%
    );
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
`;

const HeaderCorrect = styled.div`
    width: 100%;
    height: 146px;
    background: linear-gradient(96.54deg, #1ebb90 0%, #53e7be 99.04%);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
`;

const HeaderInCorrect = styled.div`
    width: 100%;
    height: 146px;
    background: linear-gradient(96.54deg, #f62225 0%, #fb4d4f 99.04%);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
`;

const Title = styled(Text)`
    font-style: italic;
    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const Body = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 25px;
`;

const Buttons = styled.div`
    display: flex;
    gap: 30px;
`;

const Progress = styled.div`
    height: 7px;
    width: 100%;
    background-color: #cccccc;
    border-radius: 3px;
    position: relative;
    :before {
        content: "";
        transition: ease-in-out 0.8s all;
        position: absolute;
        width: ${(p) => `${(p.value * 100) / 60}%`};
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 3px;
        background-color: #35d0a5;
    }
`;

const FormQuestion = styled(RadioGroup)`
    width: 90%;
    margin: auto;
`;

const FormControlLabelFake = styled(FormControlLabel)`
    span {
        font-size: 13px;
        font-weight: 500;
    }
    svg {
        font-size: 16px;
    }
`;

const WrapperQuestion = styled.div`
    max-width: 100%;
    * {
        white-space: normal !important;
    }
`;
