import { useEffect, useState } from "react";
import {
    getStudentInfo,
    getSubjectsRepo,
} from "../../redux/api/academic/studentsRepo";

const useOneStudentRepo = (partnetId, repoId) => {
    // STATES
    const [studentInfo, setStudentInfo] = useState(null);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [subjectsRepo, setSubjectsRepo] = useState(null);
    const [loadingSubjects, setLoadingSubjects] = useState(false);
    const [programsRepo, setProgramsRepo] = useState(null);
    const [loadingPrograms, setLoadingPrograms] = useState(false);

    // EFFECTS
    useEffect(() => {
        studentInfo === null && handleStudentInfo();
    }, [studentInfo]);

    useEffect(() => {
        subjectsRepo === null && handleSubjectsRepo();
    }, [subjectsRepo]);

    useEffect(() => {
        programsRepo === null && handleProgramsRepo();
    }, [programsRepo]);

    // FUNCTIONS
    const handleStudentInfo = async () => {
        setLoadingInfo(true);
        try {
            const res = await getStudentInfo(partnetId);

            setStudentInfo(res);
            setLoadingInfo(false);
        } catch (error) {
            setStudentInfo(false);
            setLoadingInfo(false);
        }
    };

    const handleSubjectsRepo = async () => {
        setLoadingSubjects(true);
        try {
            const res = await getSubjectsRepo(repoId, "asignatura");

            setSubjectsRepo(res);
            setLoadingSubjects(false);
        } catch (error) {
            setSubjectsRepo(false);
            setLoadingSubjects(false);
        }
    };

    const handleProgramsRepo = async () => {
        setLoadingPrograms(true);
        try {
            const res = await getSubjectsRepo(repoId, "programa");

            setProgramsRepo(res);
            setLoadingPrograms(false);
        } catch (error) {
            setProgramsRepo(false);
            setLoadingPrograms(false);
        }
    };

    return {
        studentInfo,
        loadingInfo,
        subjectsRepo,
        loadingSubjects,
        programsRepo,
        loadingPrograms,
    };
};

export default useOneStudentRepo;
