import { useEffect, useState } from 'react';
import { getCoursesServices } from '../redux/api/courses';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../utils/snackStyles';

const useCoursesTeacher = () => {
    const [courses, setCourses] = useState(null);
    const [initialCourses, setInitialCourses] = useState(null);

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!courses) getCourses();
    }, [courses]);

    const getCourses = async () => {
        const request = await getCoursesServices();
        if (request.error) {
            openSnackbar('¡No se pudieron cargar los cursos!');
            setCourses(false);
            return;
        }
        setCourses(request);
        setInitialCourses(request);
    };

    const handleCourses = (newCourses) => {
        setCourses(newCourses);
    };

    return { initialCourses, courses, handleCourses };
};

export default useCoursesTeacher;
