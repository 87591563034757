import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Button, Fab, IconButton, Typography } from '@mui/material';

const Pagination = (props) => {
    const {
        currentPage,
        totalPages,
        changePage,
        nextPage,
        previousPage
    } = props;
    return (
        <MainContainer>
            <CurrentPage>
                Página <b>{currentPage}</b> de <b>{totalPages}</b>
            </CurrentPage>
            <Pages>
                <IconButton
                    color="secondary"
                    disabled={currentPage <= 1}
                    onClick={previousPage}
                    size="large">
                    <KeyboardArrowLeftIcon fontSize="small" />
                </IconButton>
                <PageNumber>{currentPage}</PageNumber>
                <IconButton
                    color="secondary"
                    disabled={currentPage >= totalPages}
                    onClick={nextPage}
                    size="large">
                    <KeyboardArrowRightIcon fontSize="small" />
                </IconButton>
            </Pages>
        </MainContainer>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    changePage: PropTypes.func,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func
};

export default Pagination;

const MainContainer = styled.div`
    display: flex;
    column-gap: 2rem;
    align-items: center;
`;

const CurrentPage = styled.div``;

const Pages = styled.div`
    display: flex;
    align-items: center;
`;

const PageNumber = styled.div`
    color: #a3a3a3;
`;
