import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import styled from "styled-components";

export const StyledAccordion = styled(Accordion)`
  position: relative;
  border: none;
  box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.25),
    0px 12px 24px -15px rgba(0, 0, 0, 0.1);
  outline: none;
  background-color: ${(p) => (p.bg ? p.bg : "#ffffff")};
  width: 100%;
  border-radius: 20px !important;
  z-index: 1;

  &.css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
    border-radius: 20px;
  }

  &.MuiAccordion-root:before {
    display: none;
  }

  @media (max-width: 1024px) {
    min-width: 250px;
  }

  @media (max-width: 768px) {
    height: 210px;
    min-width: 250px;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  position: relative;
  border: none;
  outline: none;
  background-color: ${(p) => (p.bg ? p.bg : "#ffffff")};
  height: auto !important;
  border-radius: 20px;
  padding-right: 3rem;

  @media (max-width: 1024px) {
    padding-right: 1rem;
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  position: relative;
  border: none;
  outline: none;
  background-color: ${(p) => (p.bg ? p.bg : "#ffffff")};
  height: auto;
  border-radius: 20px;
  padding: 1rem;
`;
