import { useSelector } from "react-redux";
import captureException from "../../../../../../../utils/captureException";
import { sendPresentationFormData, updatePresentation } from "../api";

const usePresentationSubmission = (submitViewResource, setLoading, setPage, setChangeStatus, submissions, presentationId, presentationType, resourceId, objId, hashId) => {

    const { repo_id } = useSelector((state) => state?.auth?.user);

    const sendPresentation = async (data) => {
        setLoading(true);
        let response = '';
        if (
            submissions.length === 0 ||
            (submissions.length > 0 &&
                submissions[0].state === 'corregido')
        ) {
            response = await sendPresentationFormData(data);
        } else {
            response = await updatePresentation(data);
        }
        if (response.error) {
            captureException(response.error, data);
            alert('Error en la peticion');
            setLoading(false);
        } else {
            submitViewResource();
            setPage(1);
            setLoading(false);
            setChangeStatus(true);
        }
        
    };

    const handleSubmit = (html, file, commentary) => {
        let data = new FormData();

        data.append('comentario_alumno', `${commentary}`);
        data.append('student_comment', `${commentary}`);

        if (
            submissions.length === 0 ||
            (submissions.length > 0 &&
                submissions[0].state === 'corregido')
        ) {
            data.append('alumno', `${repo_id}`);
            data.append('evaluacion', `${presentationId}`);
            data.append('recurso', `${parseInt(resourceId, 10)}`);
            data.append('objeto_aprendizaje', `${parseInt(objId, 10)}`);
            data.append('tipo', `${presentationType}`);

            data.append('asignatura', `${hashId}`);
            if (!!html) {
                data.append('sub_tipo', 'texto');
                data.append('entrega_alumno', html);
            } else if (!!file) {
                data.append('sub_tipo', 'carga_archivo');
                data.append('file', file);
            } else if (!!commentary) {
                data.append('sub_tipo', 'texto');
                data.append('entrega_alumno', "");
            }
        } else {
            data.append('task_id', submissions[0]?.id);

            if (!!html) {
                data.append('sub_type', 'texto');
                data.append('text_answer', html);
            } else if (!!file) {
                data.append('sub_type', 'carga_archivo');
                data.append('file', file);
            } else if (!!commentary) {
                data.append('sub_tipo', 'texto');
                data.append('entrega_alumno', "");
            }
        }

        sendPresentation(data);
    };

    return { handleSubmit };
}

export default usePresentationSubmission;