import { Button, Card } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import PhotoLibraryRoundedIcon from '@mui/icons-material/PhotoLibraryRounded';
import { Text } from '../../../common/Texts';
import SharedProfile from '../profile/SharedProfile';
import ShareIcon from '@mui/icons-material/Share';

const AsideButtons = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const generateImage = () => {
        window.html2canvas = html2canvas;
        let content = document.getElementById('toPdf');

        const options = {
            background: 'white',
            scrollY: -window.scrollY,
            scrollX: -window.scrollX
        };

        html2canvas(content, options).then((canvas) => {
            var a = document.createElement('a');
            a.href = canvas
                .toDataURL('image/PNG')
                .replace('image/PNG', 'image/octet-stream');
            a.download = 'Constancia.png';
            a.click();
        });
    };

    const generatePDF = () => {
        window.html2canvas = html2canvas;

        let content = document.getElementById('toPdf');

        const options = {
            background: 'white',
            scrollY: -window.scrollY,
            scrollX: -window.scrollX
        };

        html2canvas(content, options)
            .then((canvas) => {
                const img = canvas.toDataURL('image/PNG');
                let doc = new jsPDF('l', 'cm', 'a4');

                const pageWidth = doc.internal.pageSize.getWidth();
                const pageHeight = doc.internal.pageSize.getHeight();

                const widthRatio = pageWidth / canvas.width;
                const heightRatio = pageHeight / canvas.height;
                const ratio =
                    widthRatio > heightRatio ? heightRatio : widthRatio;

                const canvasWidth = canvas.width * ratio;
                const canvasHeight = canvas.height * ratio;

                const marginX = (pageWidth - canvasWidth) / 2;
                const marginY = (pageHeight - canvasHeight) / 2;

                doc.addImage(
                    img,
                    'PNG',
                    marginX,
                    marginY,
                    canvasWidth,
                    canvasHeight,
                    undefined,
                    'FAST'
                );

                return doc;
            })
            .then((doc) => {
                doc.save('Constancia.pdf');
            });
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const text = `${window.location}`;

    return (
        <CardFake>
            <Info>
                <Text fontSize="1.2rem" fontWeight="bold" color="#b31d15">
                    ¡Ahora podés descargar tus certificados!
                </Text>
                <Text fontSize="1rem" fontWeight="bold" color="#555555">
                    Click en el boton para generar tu certificado
                </Text>
            </Info>
            <Button
                endIcon={<PictureAsPdfRoundedIcon />}
                variant="contained"
                color="primary"
                onClick={generatePDF}
                fullWidth
            >
                Descargar como PDF
            </Button>
            <Button
                endIcon={<PhotoLibraryRoundedIcon />}
                variant="contained"
                color="primary"
                onClick={generateImage}
                fullWidth
            >
                Descargar como Imagen
            </Button>
            <Button
                onClick={handleOpenModal}
                color="primary"
                variant="contained"
                endIcon={<ShareIcon />}
                fullWidth
            >
                Compartir
            </Button>
            <SharedProfile
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                link={text}
            />
        </CardFake>
    );
};

export default AsideButtons;

const CardFake = styled(Card)`
    display: flex;
    height: 250px;
    max-width: 320px;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
`;

const Info = styled.div`
    text-align: center;
    display: grid;
    gap: 1rem;
`;
