import React, { useState } from 'react';
import styled from 'styled-components';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Text from './Text';


const DefaultFile = props => {

    const { handleB64File, toPdf = true } = props

    const [fileState, setFileState] = useState(null)

    const handleChange = async e => {
        const file = e.target.files[0]
        if (file.size >= 5048576) {
            alert('Tamaño máxido 5mb')
            return
        }
        const size = formatSizeUnits(file.size)
        if (toPdf) {
            if (file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/png") {
                setFileState({
                    name: file.name,
                    size: size
                })
            }
            else {
                alert('Formato no válido')
                setFileState(null)
                return
            }
        }
        else{
            if (file.type === "image/jpeg" || file.type === "image/png") {
                setFileState({
                    name: file.name,
                    size: size
                })
            }
            else {
                alert('Formato no válido')
                setFileState(null)
                return
            }
        }
        const fileTransform = await toBase64(file)
        handleB64File(fileTransform)
    }

    const formatSizeUnits = (bytes) => {
        if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1) { bytes = bytes + " bytes"; }
        else if (bytes === 1) { bytes = bytes + " byte"; }
        else { bytes = "0 bytes"; }
        return bytes;
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    return <DefaultFileWrapper>
        <LabelInput for="file" color={!!fileState}>
            {/* Si el input esta vacio */}
            {!fileState && (
                <>
                    <ButtonBlueFake>Seleccionar archivo</ButtonBlueFake>
                    <Text fontSize=".8rem" color="#444444">{toPdf && 'PDF,'} JPG, JPEG, PNG</Text>
                </>
            )}
            {/* Si contiene algo */}
            {!!fileState && (
                <>
                    <ButtonBlueFake><InsertDriveFileIcon/> {fileState.name}</ButtonBlueFake>
                    <Text fontSize=".8rem" color="#444444">{fileState.size}</Text>
                </>
            )}
        </LabelInput>
        <File
            onChange={handleChange}
            id="file"
            type="file"
        />
    </DefaultFileWrapper>
}

export default DefaultFile;

const DefaultFileWrapper = styled.div`
    width: 100%;
`

const File = styled.input`
    display: none;
`

const LabelInput = styled.label`
    display: flex;
    width: calc(100% - 2rem);
    height: 100px;
    border: 1px ${p => p.color ? '#b31d15' : '#999'} dashed;
    padding: .5rem 1rem;
    color: #b31d15;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .5s ease-in-out all;
    border-radius: 3px;
    flex-direction: column;
    :hover{
        background-color: #f0f0f0;
    }
`
const ButtonBlueFake = styled.div`
    padding: .7rem 1rem;
    background-color: #b31d15;
    border-radius: 5px;
    color: #fff;
    margin-bottom: .2rem;
    display: flex;
    max-width: 150px;
    justify-content: center;
    align-items: center;
    gap: .2rem;
`