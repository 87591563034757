import React from 'react';
import styled from 'styled-components';
import GenericTable from "../../../../common/GenericTable";
import { Text } from '../../../../common/Texts';

const DocumentationTeacher = (props) => {
    const { user } = props
    const { repositorio_data } = user

    const columns = [
        { id: 'id_tipo_documentacion', label: 'Cod. ID' },
        { id: 'institucion', label: 'Institución' },
        { id: 'tipo', label: 'Tipo' },
        { id: 'titulo', label: 'Título' },
    ]

    // console.log(user);

    return <DocumentationWrapper>
        <Text fontWeight="bold" id="title" color="#bfbfbf" fontSize="1.5rem">Documentación</Text>
        {/* <Content>
            <GenericTable columns={columns} rows={repositorio_data?.documentacion} />
        </Content> */}
    </DocumentationWrapper>;
};

export default DocumentationTeacher;

const DocumentationWrapper = styled.div`
    padding: 2rem;
    display: grid;
    gap: 18px;
    flex-direction: column;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

const Content = styled.div`
    @media(max-width: 768px){
        padding: 0;
        width: 100%;
        overflow-X: auto;
    }
`