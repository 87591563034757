import React from 'react';
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import useTheme from '../../hooks/useTheme';

export default function MaterialThemeProvider(props) {
    const { primary } = useTheme();
    const theme = createTheme(
        adaptV4Theme({
            typography: {
                fontFamily: ['Lato'].join(','),
                fontSize: 16,
                // '@media (max-width: 1400px)': {
                // 	fontSize: 10
                // },
                // '@media (max-width: 1280px)': {
                // 	fontSize: 12
                // },
                // '@media (max-width: 1024px)': {
                // 	fontSize: 12
                // },
                button: {
                    textTransform: 'none',
                    fontWeight: 'bold',
                },
            },
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: primary,
                    // dark: will be calculated from palette.primary.main,
                    contrastText: '#fff', //will be calculated to contrast with palette.primary.main
                },
                background: {
                    default: '#f9f9f9 !important',
                },
                secondary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#35D0A5',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
                },
                tertiary: {
                    main: '#616161',
                    contrastText: '#fff', //will be calculated to contrast with palette.primary.main
                },
                cuaternary: {
                    main: '#ffffff',
                    contrastText: '#000000',
                },
                current: {
                    main: '#5E80DB',
                    contrastText: '#616161',
                },
                toStart: {
                    main: '#C29F43',
                    contrastText: '#616161',
                },
                ended: {
                    main: '#28A986',
                    contrastText: '#616161',
                },
                muted: {
                    main: '#c4c4c4',
                },
                error: {
                    // light: will be calculated from palette.primary.main,
                    main: '#f8423f',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
                },
            },
            props: {
                // Name of the component ⚛️
                MuiButtonBase: {
                    // The properties to apply
                    disableRipple: false, // No more ripple, on the whole application 💣!
                },

                // Set default elevation to 1 for popovers.
                MuiPopover: {
                    elevation: 1,
                },
            },
        })
    );
    const { children } = props;

    return (
        <StylesProvider injectFirst>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </StyledEngineProvider>
        </StylesProvider>
    );
}
