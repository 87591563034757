import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getEvents } from '../api/events';
import { eventsActions } from '../actions';

function* eventsRequest() {
    try {
        const res = yield call(getEvents);
        yield put(eventsActions.getEventsSuccess(res.data.response_data));
    } catch (error) {
        yield put(eventsActions.getEventsFail(error));
    }
}

function* eventsWatcher() {
    yield takeEvery(types.GET_EVENTS_REQUEST, eventsRequest);
}

export { eventsWatcher };
