// REACT
import { useState, useEffect, useReducer } from "react";

// STYLES
import styled from "styled-components";

// COMPONENTS
import {
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Radio,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Text } from "../../../components/common/Texts";
import { Add, Remove, Close } from "@mui/icons-material";

const ElectiveSubjectModal = (props) => {
  const { open, onClose, subjects, handleEnrollment, repo_id } = props;

  const CONFIRM_IMG_ROUTE = 'url("/assets/aden/pregunta-electiva.png")';
  const IS_CONFIRMED_IMG_ROUTE = 'url("/assets/aden/confirm-elective.png")';

  const [contentStepper, setContentStepper] = useState(1);

  const [state, dispatchState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      openAccordion: false,
      currentAccordion: null,
      checked: false,
      currentChecked: null,
      checkedId: null,
      isLoading: false,
    }
  );

  useEffect(() => {
    return () => {
      setContentStepper(1);
    };
  }, [onClose]);

  const handleAccordion = (value) => {
    if (value !== state?.currentAccordion) {
      dispatchState({ currentAccordion: value });
      dispatchState({ openAccordion: true });
      return;
    }
    dispatchState({ openAccordion: !state.openAccordion });
  };

  const handleRadio = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (value !== state?.currentChecked) {
      dispatchState({ currentChecked: name });
      dispatchState({ checkedId: value });
      dispatchState({ checked: true });
      return;
    }
    dispatchState({ checked: !state.checked });
  };

  const handleSteps = () => {
    setContentStepper((prev) => prev + 1);
  };

  const handleApplyInscription = () => {
    dispatchState({ isLoading: true });
    const response = handleEnrollment(repo_id, state?.checkedId);
    if (!response.error) {
      console.log("Ocurrió un error al matricular");
      return;
    }
    handleSteps();
    dispatchState({ isLoading: false });
  };

  function handleModalContent(step) {
    const cases = {
      1: (
        <ContentWrapper width="calc(100% - 300px)" gap="1rem">
          <HeaderWrapper>
            <Text fontSize="24px" fontWeight="900" color="#B31D15">
              Selección de electiva
            </Text>
            <Text fontSize="16px" fontWeight="500" color="#222222">
              Escoge la asignatura que deseas cursar:
            </Text>
          </HeaderWrapper>
          <ModulesWrapper>
            {subjects?.map((subject, i) => (
              <Container key={i}>
                <RadioSelector
                  checked={
                    state?.checked && state?.currentChecked === subject.nombre
                  }
                  value={subject?.id}
                  name={subject?.nombre}
                  onChange={handleRadio}
                  disabled={subject?.enrolled}
                />
                <ModuleAccordion
                  onClick={() => handleAccordion(i)}
                  expanded={
                    state?.openAccordion && state?.currentAccordion === i
                  }
                >
                  <ModuleAccordionSummary>
                    <SummaryInfo>
                      {state?.openAccordion && state?.currentAccordion === i ? (
                        <Remove />
                      ) : (
                        <Add />
                      )}
                      <Text fontSize="16px" fontWeight="400" color="#222222">
                        {subject?.nombre}
                      </Text>
                    </SummaryInfo>
                  </ModuleAccordionSummary>
                  <ModuleAccordionDetails>
                    <Text fontSize="14px" fontWeight="400" color="#616161">
                      {subject?.descripcion || "No contiene descripción"}
                    </Text>
                  </ModuleAccordionDetails>
                </ModuleAccordion>
              </Container>
            ))}
          </ModulesWrapper>
          <FooterWrapper>
            <ElectionButton
              variant="contained"
              disabled={!state?.currentChecked}
              onClick={handleSteps}
            >
              Seleccionar electiva
            </ElectionButton>
          </FooterWrapper>
        </ContentWrapper>
      ),
      2: (
        <ContentWrapper width="445px" alignItems="center">
          <CloseIconButton onClick={onClose}>
            <Close />
          </CloseIconButton>
          <Image image={CONFIRM_IMG_ROUTE} />
          <TextWrapper>
            <Text fontSize="24px" fontWeight="900" color="#B31D15">
              Selección de electiva
            </Text>
            <Text fontSize="16px" fontWeight="500" color="#222222">
              ¿Deseas inscribirte a la asignatura{" "}
              <b>{state?.currentChecked ?? "Nombre asignatura"}</b>?
            </Text>
            <ElectionButton
              variant="contained"
              width="200px"
              onClick={state.isLoading ? "" : handleApplyInscription}
            >
              {state.isLoading ? (
                <CustomCircularProgress size={26} />
              ) : (
                "Realizar inscripción"
              )}
            </ElectionButton>
          </TextWrapper>
        </ContentWrapper>
      ),
      3: (
        <ContentWrapper width="445px" alignItems="center">
          <Image image={IS_CONFIRMED_IMG_ROUTE} />
          <TextWrapper>
            <Text fontSize="24px" fontWeight="900" color="#B31D15">
              ¡Inscripción correcta!
            </Text>
            <Text fontSize="16px" fontWeight="500" color="#222222">
              Ya puedes comenzar a cursar la electiva seleccionada.
            </Text>
            <ElectionButton variant="contained" width="200px">
              Comenzar
            </ElectionButton>
          </TextWrapper>
        </ContentWrapper>
      ),
    };

    return cases[step] || cases[1];
  }

  return (
    <ElectiveModal open={open} onClose={onClose}>
      {handleModalContent(contentStepper)}
    </ElectiveModal>
  );
};

export default ElectiveSubjectModal;

const ElectiveModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.alignItems ? p.alignItems : "none")};
  gap: ${(p) => (p.gap ? p.gap : "none")};
  padding: 2rem;
  border-radius: 20px;
  width: ${(p) => (p.width ? p.width : "auto")};
  background-color: #ffffff;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
`;

const ModulesWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Container = styled.div`
  position: relative;
`;

const ModuleAccordion = styled(Accordion)`
  box-shadow: none;
  border: 1px solid #dcdcdc;
  border-radius: 10px !important;
  transition: 0.3s ease-in;

  &:hover {
    background-color: #f6f6f6;
    transition: 0.3s ease-in;
  }

  &.MuiAccordion-root {
    margin: 0;
    padding: 0.2rem 0.8rem;
  }

  &.MuiAccordion-root:before {
    display: none;
  }
`;

const ModuleAccordionSummary = styled(AccordionSummary)`
  border: none;

  .MuiAccordionSummary-contentGutters {
    display: flex;
    justify-content: space-between;
  }

  svg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #b31d15 !important;
    color: #ffffff;
  }
`;

const SummaryInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem !important;
`;

const RadioSelector = styled(Radio)`
  position: absolute;
  margin: 0;
  height: fit-content;
  right: 1rem;
  top: 0.4rem;
  bottom: 0;
  z-index: 6;
`;

const ModuleAccordionDetails = styled(AccordionDetails)`
  padding: 0 3.36rem 1.36rem 3.36rem;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.4rem;
`;

const ElectionButton = styled(Button)`
  border-radius: 10px;
  width: ${(p) => (p.width ? p.width : "auto")};
`;

const Image = styled.div`
  width: 350px;
  height: 400px;
  background-image: ${(p) => p.image};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const CloseIconButton = styled(IconButton)`
  position: absolute;
  margin: 0;
  top: 15px;
  right: 15px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  width: 350px;
`;

const CustomCircularProgress = styled(CircularProgress)`
  color: #ffffff;
`;
