import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import TabsComponent from '../../../common/TabsComponent';
import InterestsMotivations from '../../../ui/organisms/profile/InterestsMotivations';
import TrainingTeacher from '../../../ui/organisms/profile/teacher/TrainingTeacher';


const tabsTeacher = [
    { label: 'Información personal', id: 1 },
    { label: 'Intereses y motivaciones', id: 0 },
];

const MainProfileTeacher = () => {
    // STATE
    const [tab, setTab] = useState(1);
    const { user } = useSelector((state) => state.auth);

    // FUNCTIONS
    function handleTab(e, value) {
        setTab(value);
    }

    // RETURN
    return (
        <MainProfileTeacherContainer>
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabsTeacher}/>
            <BodyWrapper>
                <ToggleMain tab={tab} user={user} />
            </BodyWrapper>
        </MainProfileTeacherContainer>
    );
};

export default MainProfileTeacher;

const MainProfileTeacherContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const BodyWrapper = styled.div`
    padding: 2rem;
`;

function ToggleMain(props) {
    const { tab, user } = props;
    switch (tab) {
        case 0:
            return <InterestsMotivations />;
        case 1:
            return <InterestsMotivations />;
        default:
            return null;
    }
}
