import {
    Box,
    Button,
    FormControlLabel,
    Paper,
    Slide,
    Switch
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import FilterListIcon from '@mui/icons-material/FilterList';
import CancelIcon from '@mui/icons-material/Cancel';

const FilterSlider = (props) => {
    const { Component, checked, handleClickOpen, sentFilters } = props;

    return (
        <Wrapper>
            <Box>
                <Box sx={{ width: 200 }}>
                    <BoxFake direction="left" in={checked}>
                        <Paper
                            sx={{ m: 1, width: 100, height: 100 }}
                            elevation={4}
                        >
                            <BoxSize sx={{ height: '85vh' }}>
                                <BoxContent>
                                    <HeaderFilter>
                                        <FilterWrapper>
                                            <FilterListIcon />
                                            <h2>Filtrar</h2>
                                        </FilterWrapper>
                                        <ButtonFake onClick={handleClickOpen}>
                                            <CancelIcon />
                                        </ButtonFake>
                                    </HeaderFilter>
                                    <Component
                                        sentFilters={sentFilters}
                                        handleClickOpen={handleClickOpen}
                                    />
                                </BoxContent>
                            </BoxSize>
                        </Paper>
                    </BoxFake>
                </Box>
            </Box>
        </Wrapper>
    );
};

export default FilterSlider;

const Wrapper = styled.div``;

const BoxFake = styled(Slide)`
    position: fixed;
    bottom: 0;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
        0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    right: 0;
    z-index: 9999;
    flex-direction: column;
`;
const BoxSize = styled(Box)`
    width: 444px;
    display: flex;
    // flex-direction: column;

    @media (max-width: 468px) {
        width: 100%;
    }
`;
const BoxContent = styled.div`
    width: 90%;
    margin: 0 auto;
`;
const HeaderFilter = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    //flex-direction: column;

    h2 {
        color: #b31d15 !important;
    }
    svg {
        margin-right: 1rem;
        color: #b31d15;
    }
`;
const FilterWrapper = styled.div`
    display: flex;
    /* flex-direction: column; */
    align-items: center;
`;
const ButtonFake = styled.button`
    cursor: pointer;
    &:hover {
        background-color: transparent;
    }
`;
