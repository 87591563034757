import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ContactsGrid from "../../components/templates/directory/ContactsGrid";

import Filters from "../../components/templates/directory/Filters";
import FiltersMobile from "../../components/templates/directory/FiltersMobile";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { titleHTML } from "../../helpers/title.helper";
import { Card } from "@mui/material";
import TabsComponent from "../../components/common/TabsComponent";
import useFavorites from "../../hooks/useFavorites";
import ContactsFavs from "../../components/ui/molecules/favorites/ContactsFavs";
import { useHistory } from "react-router-dom";

const Directory = () => {
    titleHTML("Directorio");

    const tabs = [
        { label: "Comunidad", id: 0 },
        { label: "Mis Contactos", id: 1 },
        { label: "Chat", id: 2 },
    ];

    const history = useHistory();

    // STATES
    const [order, setOrder] = useState({ order: "name-asc" });
    const [filters, setFilters] = useState({});
    const [tab, setTab] = useState(0);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    // HOOK
    const { favs } = useFavorites();

    // FUNCTIONS
    const handleTab = (_, value) => {
        setTab(value);
    };

    // METHODS
    function onFiltersChange(newFilters) {
        setFilters(newFilters);
    }
    function onOrdersChange(newOrders) {
        setOrder(newOrders);
    }

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }
        window.addEventListener("resize", handleResize);

        return (_) => {
            window.removeEventListener("resize", handleResize);
        };
    });

    useEffect(() => {
        if (tab === 2) {
            history.push("../networking/chat");
        }
    }, [tab]);

    return (
        <DirectoryContainer>
            <Title>Directorio</Title>
            <CardFake>
                <TabsComponent tab={tab} tabs={tabs} handleTab={handleTab} />
                {tab === 0 && (
                    <DirectoryMain>
                        {dimensions.width <= 768 ? (
                            <FiltersMobile sendFilters={onFiltersChange} />
                        ) : (
                            <Filters sendFilters={onFiltersChange} />
                        )}
                        <ContactsGrid
                            filters={filters}
                            {...order}
                            onOrdersChange={onOrdersChange}
                            pageSize={16}
                        />
                    </DirectoryMain>
                )}
                {tab === 1 && <ContactsFavs contacts={favs.contacto} />}
            </CardFake>
        </DirectoryContainer>
    );
};

export default Directory;

const DirectoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const Title = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    color: #b31d15;
`;

const CardFake = styled(Card)`
    border-radius: 20px;
`;

const DirectoryMain = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
    padding: 1rem;

    @media screen and (max-width: 1440px) {
        grid-template-columns: 3fr 6fr;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;
