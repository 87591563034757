import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Card } from '@mui/material';

import CardHome from '../../../../components/templates/campus/CardHome';
import InternationalWorkshop from '../../../../components/templates/campus/InternationalWorkshop';

const DiverseBanners = () => {
    const { ondemand, resources } = useSelector((state) => state.ondemand);
    const { activeRol } = useSelector((state) => state.auth);

    return (
        <Wrapper>
            {activeRol === 'alumno' && (
                <CardFake h="416px">
                    <InternationalWorkshop />
                </CardFake>
            )}
            {/* Plan de evaluacion */}
            {activeRol === 'alumno' && (
                <CardFake h="416px">
                    <CardHome />
                </CardFake>
            )}
        </Wrapper>
    );
};

export default DiverseBanners;

const Wrapper = styled.section`
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const CardFake = styled(Card)`
    max-height: ${(p) => p.h};
    height: ${(p) => p.h};
    width: 100%;
    border-radius: 20px;
    box-shadow: var(--shadow-strong);
    border-radius: var(--border-radius_medium);

    &.MuiCard-root {
        z-index: 10;
    }
`;
