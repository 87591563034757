import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getComments } from "../../redux/api/blog/blogApi";
import CommentCard from "../ui/molecules/blog/CommentCard";
import SimpleLoading from "../common/SimpleLoading";
import InputMessage from "../ui/molecules/blog/InputMessage";
import { useSelector } from "react-redux";

const CommentsBlog = () => {
    // STATES
    const [comments, setComments] = useState(null);
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state?.auth?.user);
    const userPhoto = user?.foto;

    // EFFECTS
    useEffect(() => {
        if (comments === null) {
            getCommentsBlog();
        }
    }, []);

    // FUNCTION
    const getCommentsBlog = async () => {
        const response = await getComments();

        if (!response.error) {
            setComments(response);
        } else {
            setComments([]);
        }
    };

    if (comments === null) {
        return <SimpleLoading />;
    }

    const handleSend = (message) => {
        const date = new Date();

        if (message !== "") {
            comments.unshift({
                id: comments.length + 1,
                person: {
                    name: user?.name,
                    photo: userPhoto,
                },
                message: message,
                message_date: date.toLocaleDateString(),
                likes: 0,
                dislake: 0,
            });
        }
        console.log("envies =>", comments);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 300);
    };

    return (
        <CardFake>
            <Header>
                <h2>¡Deja tu comentario!</h2>
                {/* INPUT PARA ENVIAR COMENTARIOS */}
                <InputMessage userPhoto={userPhoto} handleSend={handleSend} />
            </Header>
            <AmountComments>{comments?.length} comentarios</AmountComments>
            
            {/* LISTA DE TODOS LOS  COMENTARIOS */}
            <CommentCard comments={comments} />
            {/* )} */}
        </CardFake>
    );
};

export default CommentsBlog;

const CardFake = styled(Card)`
    border-radius: 20px;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    padding: 1rem;
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
        font-size: 1.5rem;
    }
`;

const AmountComments = styled.p`
    color: #616161;
    font-size: 1rem;
    font-weight: bold;
`;