import React from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";

import parse from 'html-react-parser';
import CommonButton from "../../../components/common/CommonButton";

const PublicHeaderInfo = (props) => {
    const {
        photo,
        socialNetworks,
        professionalExperience
    } = props;

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return html.replace(regex, 'src="');
    };

    return (
        <PublicHeaderInfoContainer>
            <PhotoWrapper>
                <Photo src={photo} alt="user-photo" />
            </PhotoWrapper>
            <InfoWrapper>
                <Name>Experiencia profesional</Name>
                <Biography>
                    {
                        !!professionalExperience &&
                        <>
                            {parse(sanitizerHtml(professionalExperience))}
                        </>
                    }
                </Biography>
                <ButtonsContainer>
                    {
                        socialNetworks?.map((social, index) => (
                            <CommonButton
                                Icon={() => <Icon icon={`bi:${social.name}`} width="1.2em" height="1.2em" />}
                                variant="outlined"
                                onClick={() => { window.open(social.url) }}
                            />
                        ))
                    }
                </ButtonsContainer>
            </InfoWrapper>
        </PublicHeaderInfoContainer>
    );
};

export default PublicHeaderInfo;

const PublicHeaderInfoContainer = styled.div`
    width: 25%;
    min-height: 620px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem;
    border-radius: 30px;
    background-color: #f1f1f1;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    border: 1px solid #A8A8A8;

    @media (max-width: 1000px) {
        width: calc(100% - 4rem);
        justify-content: space-evenly;
    }
`;

const PhotoWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Photo = styled.img`
    width: 196px;
    height: 196px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    max-width: 196px;
    max-height: 196px;
`;

const InfoWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    row-gap: 1rem;

    @media (max-width: 1000px) {
        align-items: center;
    }
`;

const Name = styled.h1`
    font-size: 1.75rem;
    font-weight: 900;
`;

const Biography = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #222222;

    li {
        padding-top: 8px;
    }

    ul, ol {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100% !important;
    }
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;  
    gap: 1rem;
`