import React, { useEffect, useState, useReducer } from "react";
import styled from "styled-components";
import ProgramInfoCard from "../../ui/molecules/studyPlan/ProgramInfoCard";
import { Card } from "@mui/material";
import GridStudyPlan from "../../common/GridStudyPlan";
import GridPaginationBox from "../../common/GridPaginationBox";
const ProgramsCourses = (props) => {
    const {
        id,
        data,
        getDataStudyPlan,
        dataProgram,
        isDiplomat,
        programState,
        handleEnrollment,
        getProgramStudyPlan,
        setDataProgram,
        setData,
    } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        { programProgress: null }
    );

    // STATES
    // eslint-disable-next-line no-unused-vars
    const [currentIndex, setCurrentIndex] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [tab, setTab] = useState(0);
    const [isFlag, setIsFlag] = useState(false);
    const [dataAssigment, setDataAssigment] = useState(data);
    const [isChange, setIsChange] = useState(false);
    const [onInscription, setOnInscription] = useState(false);

    const [page, setPage] = useState(1);

    const totalPages = Math.ceil(dataAssigment.length / 16);

    useEffect(() => {
        setDataAssigment(data);
        setTimeout(() => {
            setIsChange(false);
        }, 300);
        handleOnInscription(data);
        // setIsFlag(false);
    }, [data, isFlag]);

    useEffect(() => {
        !state.programProgress && programProgressCalc();
        //eslint-disable-next-line
    }, [state.programProgress]);

    // FUNCTIONS
    const renderCase = (type) => {
        const caseType = {
            0: (
                <GridStudyPlan
                    id={id}
                    // Barajar la posibilidad de no paginar, ya que no creo que ningún programa tenga más de 16 asignaturas
                    course={dataAssigment.slice(
                        page === 1 ? 0 : (page - 1) * 16,
                        page * 16
                    )}
                    setDataAssigment={setDataAssigment}
                    currentIndex={currentIndex}
                    dataAssigment={dataAssigment}
                    setIsChange={setIsChange}
                    isChange={isChange}
                    dataProgram={dataProgram}
                    setDataProgram={setDataProgram}
                    setData={setData}
                    onInscription={onInscription}
                    isDiplomat={isDiplomat}
                    setIsFlag={setIsFlag}
                    isFlag={isFlag}
                    programState={programState}
                    handleEnrollment={handleEnrollment}
                    getProgramStudyPlan={getProgramStudyPlan}
                    getDataStudyPlan={getDataStudyPlan}
                />
            ),
        };

        return caseType[type];
    };

    const handleOnInscription = (data) => {
        let courseConfirmed = data.filter(
            (value) => value.state === "confirmado"
        );

        if (courseConfirmed.length >= 2) {
            setOnInscription(false);
        } else {
            setOnInscription(true);
        }
    };

    const programProgressCalc = () => {
        const coursesProgress = data?.reduce(
            (acc, curr) => acc + curr?.progress?.progress,
            0
        );
        const totalProgress = Math.trunc(
            (coursesProgress * 100) / (data?.length * 100)
        );
        setTimeout(() => {
            updateState({ programProgress: totalProgress });
        }, 1000);
    };

    return (
        <Container>
            <ProgramInfoCard
                id={id}
                programData={dataProgram}
                programState={programState}
                subjectsData={data}
                programProgress={
                    !state?.programProgress ? 0 : state?.programProgress
                }
                dataAssigment={dataAssigment}
            />

            <CardFake>
                <CardsWrapper>
                    <TabCase>{renderCase(tab)}</TabCase>
                </CardsWrapper>
            </CardFake>
            <GridPaginationBox
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </Container>
    );
};

export default ProgramsCourses;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;

const CardFake = styled(Card)`
    position: relative;
    background-color: transparent;
    box-shadow: none;
`;

const CardsWrapper = styled.div`
    /* padding: 2rem; */

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const TabCase = styled.div`
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
