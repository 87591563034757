import React from 'react';
import styled from 'styled-components';
import { Text } from '../../common/Texts';

const SelectRol = (props) => {
    const { icon, name, subtitle, handleLogin, value, disabled = false } = props;

    const formatSubtitle = (subtitle) => {
        const parts = subtitle.split(/-LAB/);
        return (
            <>
                {parts.map((part, index) => (
                    <React.Fragment key={index}>
                        {part}
                        {index < parts.length - 1 && <b>LAB</b>}
                    </React.Fragment>
                ))}
            </>
        );
    };

    if (disabled) {
        return (
            <Card disabled>
                <Circle>
                    <img src={icon} alt={name} />
                </Circle>
                <SubtitleContainer>
                    <Text fontSize="20px" fontWeight="700" color="#222">
                        {name}
                    </Text>
                    <Text fontSize="20px" fontWeight="400" color="#222">
                        {formatSubtitle(subtitle)}
                    </Text>
                </SubtitleContainer>
            </Card>
        );
    }

    return (
        <Card onClick={() => handleLogin(value)}>
            <Circle>
                <img src={icon} alt={name} />
            </Circle>
            <SubtitleContainer>
                <Text fontSize="20px" fontWeight="700" color="#222">
                    {name}
                </Text>
                <Text fontSize="20px" fontWeight="400" color="#222">
                    {formatSubtitle(subtitle)}
                </Text>
            </SubtitleContainer>
        </Card>
    );
};

export default SelectRol;

const Card = styled.div`
    width: 240px;
    height: 240px;
    border-radius: 20px;
    border: 1px solid #A8A8A8;
    transition: all 0.3s ease-in-out;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background-color: ${({ disabled }) => (disabled && "#f1f1f1")};
    padding: 2rem 0;
    img {
        // efecto de black and white
        filter: ${({ disabled }) => (disabled && "grayscale(100%)")};
    }
    :hover {
        background-color: #f1f1f1;
        box-shadow: 0px 8px 8px -4px #18274B0A, 0px 4px 6px -4px #18274B0A;
    }
    @media(max-width: 768px){
        width: 100%;
        height: 100px;
        margin: 0;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
`;

const Circle = styled.div`
    width: 140px;
    height: 140px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    border: 1px solid #616161;
    overflow: hidden;
    box-shadow: 0px 8px 8px -4px #2222221A, 0px 4px 6px -4px #18274B0A;

    img {
        width: 168px;
        height: 168px;
    }
    svg {
        color: #fff;
        font-size: 3rem;
    }
    @media(max-width: 768px){
        width: 60px;
        height: 60px;
        img {
            width: 60px;
            height: 60px;
        }
        svg {
            font-size: 2rem;
        }
    }
`;

const SubtitleContainer = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`