import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../../components/common/Texts';
import { Img } from '../../../../components/common/Image';
import giftAnimation from '../../../../assets/media/gif/animacion-referidos.gif';
import { Button } from '@mui/material';
import useRedirect from '../../../../hooks/useRedirect';

const MyReferralsCard = () => {
    //HOOKS
    const { handleRedirection } = useRedirect();

    return (
        <Wrapper>
            <TitleWrapper>
                <Text color="#B31D15" fontSize="20px" fontWeight="700">
                    Mis referidos
                </Text>
            </TitleWrapper>
            <CardWrapper>
                <Image src={giftAnimation} w="30" />
                <TextWrapper>
                    <Text color="#B31D15" fontSize="16px" fontWeight="700">
                        ¡Refiere {" "}
                        <Text color="#1D1D18" fontSize="16px" fontWeight="400">
                            a un amigo y obtén
                        </Text>
                        {" "}beneficios exclusivos!
                    </Text>
                </TextWrapper>
                <ReferralsButton
                    onClick={() => handleRedirection('/mis-referidos')}
                    variant="contained"
                >
                    Ver más
                </ReferralsButton>
            </CardWrapper>
        </Wrapper>
    );
};

export default MyReferralsCard;

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const TitleWrapper = styled.div`
    margin-left: 20px;

    @media (max-width: 1024px) {
        p {
            font-size: 15px;
        }
    }
`;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 365px;
    border-radius: 30px;
    background-color: #FBFBFB;
    z-index: 1;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);

    @media (max-width: 1440px) {
        width: 300px;
    }

    @media (max-width: 1136px) {
        width: 250px;
    }

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const TextWrapper = styled.div`
    width: calc(100% - 8rem);
    padding: 1.2rem;
    text-align: center;
    display: flex;
    justify-content: center;

    p {
        display: inline-block;
    }
`;

const Image = styled(Img)`
    width: 121px;
    height: 121px;
    background-color: #fff;
`;

const ReferralsButton = styled(Button)`
    border-radius: 50px;
    padding: 0.5rem 1.5rem;
`;
