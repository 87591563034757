const useStatus = () => {
  function setLightStateColor(state = "sin_entrega") {
    const cases = {
      pendiente: "#E6EBF9",
      corregido: "#EAFAF6",
      rehacer: "#FCE9E8",
      sin_entrega: "#F9F5EC",
    };

    return cases[state];
  }

  function setStrongStateColor(state = "sin_entrega") {
    const cases = {
      pendiente: "#5E80DB",
      corregido: "#1E8065",
      rehacer: "#B31D15",
      sin_entrega: "#C29F43",
    };

    return cases[state];
  }

  function setStateText(state = "sin_entrega") {
    const cases = {
      pendiente: "Pendiente correción",
      corregido: "Aprobada",
      rehacer: "Rehacer",
      sin_entrega: "No entregado",
    };

    return cases[state];
  }

  return { setLightStateColor, setStrongStateColor, setStateText };
};

export default useStatus;
