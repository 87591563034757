import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import Text from '../../../common/Text';

const MoreInformation = (props) => {
    const { withText, dracma_coin_name } = props;
    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTIONS
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    // RETURN
    return (
        <>
            {withText ? (
                <ButtonTextWrapper onClick={openModal}>
                    <InfoRounded color="secondary" />
                    <Text
                        fontSize="1rem"
                        fontWeight="700"
                        color="secondary"
                        component="h2"
                    >
                        Más info
                    </Text>
                </ButtonTextWrapper>
            ) : (
                <InfoRounded
                    color="secondary"
                    onClick={openModal}
                    style={{ cursor: 'pointer' }}
                />
            )}
            <Modal open={isOpen} onClose={closeModal}>
                <Container>
                    {/** Titulo */}
                    <ColumnWrapper padding="2rem" textAlign="center">
                        <Text
                            fontSize="1.5rem"
                            fontWeight="700"
                            color="primary"
                            component="h1"
                        >
                            {dracma_coin_name !== undefined &&
                            dracma_coin_name !== ''
                                ? `Competencia ${dracma_coin_name}`
                                : ' Competencia Dracma'}
                        </Text>
                        <Text fontSize="1rem" component="p">
                            Tus acciones dentro del Campus y durante el cursado
                            te permitirán ganar puntos y subir de nivel.
                        </Text>
                        <Text fontSize="1rem" component="p">
                            ¡Compite con tus colegas y se el mejor!
                        </Text>
                    </ColumnWrapper>
                    <CustomHr />
                    {/** Contenido */}
                    <RowWrapper padding="2rem" gridGap="2rem">
                        <CustomImg
                            src="/assets/aden/dracma-point.svg"
                            alt="dracma-point"
                            loading="lazy"
                        />
                        <ColumnWrapper>
                            <Text
                                fontSize="1.1rem"
                                fontWeight="700"
                                component="h2"
                            >
                                {dracma_coin_name !== undefined &&
                                dracma_coin_name !== ''
                                    ? ` ${dracma_coin_name} Points`
                                    : 'Dracma Points'}
                            </Text>
                            <Text fontSize="0.9rem" component="p">
                                Ciertas acciones y actividades durante tu
                                cursado te darán
                                {dracma_coin_name !== undefined &&
                                dracma_coin_name !== ''
                                    ? ` ${dracma_coin_name} `
                                    : ' Dracma '}
                                Points, estos puntos te ayudarán a subir de
                                nivel y así subir en la tabla de clasificación.
                            </Text>
                        </ColumnWrapper>
                    </RowWrapper>
                    <CustomHr />
                    {/** Botones */}
                    <RowWrapper padding="1rem" gridGap="1rem">
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={closeModal}
                        >
                            Cerrar
                        </Button>
                    </RowWrapper>
                </Container>
            </Modal>
        </>
    );
};

export default MoreInformation;

const ButtonTextWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    cursor: pointer;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 450px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;

    @media (max-width: 576px) {
        width: 100%;
    }
`;

const RowWrapper = styled.div`
    display: flex;
    grid-gap: ${(p) => p.gridGap};
    padding: ${(p) => p.padding};
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: ${(p) => p.padding};
    text-align: ${(p) => p.textAlign};
`;

const CustomImg = styled.img`
    width: 75px;
    height: 75px;
    object-fit: cover;
    object-position: center;
`;

const CustomHr = styled.hr`
    width: 100%;
    margin: 0;
`;
