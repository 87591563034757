import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import styled from 'styled-components';
import overlay from 'videojs-overlay';

require('@silvermine/videojs-quality-selector')(videojs);
require('@silvermine/videojs-quality-selector/dist/css/quality-selector.css');

export const VideoJS = (props) => {
    const {
        options,
        videos = [],
        controls = true,
        autoplay = false,
        muted = false,
        onProgress  = false,
        // onFinish,
        onPlay  = false,
        onPause = false,
        // setTag,
        // tag,
        videoRef,
        playerRef,
        handleNext,
        onError = false
        // played = true,
    } = props;

    const srcRef = useRef(videos);

    videojs.registerPlugin('overlay', overlay);

    const videoRender = () => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;
            const player = (playerRef.current = videojs(videoElement, {
                autoplay: autoplay,
                controls: controls,
                responsive: true,
                fluid: true,
                sources: videos
            }));

            // Events
            const progress = () => {
                onProgress(player.currentTime(), player.duration());
            };

            const ended = () => {
                handleNext();
            };

            const play = () => {
                onPlay({
                    status: 'play',
                    played: true
                });
            };

            const pause = () => {
                onPause({
                    status: 'pause',
                    played: false
                });
            };

            !!onProgress && player.on('timeupdate', progress);
            player.on('ended', ended);
            !!onPause && player.on('pause', pause);
            !!onPlay && player.on('play', play);
            !!onError && player.on('error', (e) => onError(e))
            player.muted(muted);
            player.autoplay(autoplay);
            player.controls(controls);
            player.controlBar.addChild('QualitySelector');

            // Ocultar src
            blockSrc();
        }
    };
    useEffect(() => {
        videoRender();
    }, [options]);

    useEffect(() => {
        blockContext();
        if (srcRef.current[0]?.src !== videos[0]?.src) {
            srcRef.current = videos;
            playerRef.current?.src(srcRef.current);
            videoRef.current = null;
        }
    }, [videos[0]]);

    const blockContext = () => {
        const videoJS = document.getElementById('video-aden');
        if (videoJS) {
            videoJS.oncontextmenu = () => false;
        }
    };

    const blockSrc = () => {
        setTimeout(() => {
            const videoHTML = document.getElementById('video-aden_html5_api');
            if (videoHTML) {
                videoHTML.removeAttribute('src');
            }
        }, 100);
    };

    return (
        <>
            <div data-vjs-player>
                <VideoComponent
                    id="video-aden"
                    ref={videoRef}
                    className="video-js vjs-theme-fantasy"
                    data-setup='{ "playbackRates": [0.5, 1, 1.5, 2]}'
                />
            </div>
        </>
    );
};

export default VideoJS;

const VideoComponent = styled.video`
    position: relative;
    .vjs-play-progress {
        background-color: #b31d15;
    }
    .vjs-control-bar {
        background: rgba(255, 255, 255, 0.1);
    }

    .vjs-playback-rate-value {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }

    .vjs-play-control {
        font-size: 1rem;
        margin: auto;
    }

    .vjs-play-progress::before {
        background-color: #b31d15;
    }
    .vjs-overlay {
        position: fixed;
        top: 50%;
        left: 50%;
        font-size: 2rem;
        color: #fff;
        border-radius: 0.5rem;
        padding: 1rem;

        background-color: rgba(0, 0, 0, 0.5);
    }
    .vjs-quality-selector {
    }
    .vjs-quality-selector span::before {
        display: none;
    }
    .vjs-quality-selector span::after {
        content: ' res';
        font-size: 1rem;
        color: #fff;
    }
    .vjs-big-play-button {
        color: rgba(255, 255, 255, 0.5);
        border: solid 2px rgba(255, 255, 255, 0.5);
        border-radius: 50%;
    }
    .vjs-big-play-button:hover {
        border: solid 2px rgba(255, 255, 255, 1);
    }
    .vjs-current-time {
        display: block !important;
    }
    .vjs-time-divider {
        display: block !important;
    }

    .vjs-duration,
    .vjs-no-flex .vjs-duration {
        display: block !important;
    }
    .vjs-menu-item-text {
        &::after {
            display: none;
        }
    }
`;
// const Tag = styled.div`
//   display: flex;
//   justify-content: center;
// `;
// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// `;
// const TagBody = styled.div`
//   border: solid 1px #b31d15;
//   padding: 1rem;
//   border-radius: 2px;
//   margin-top: 2rem;
//   text-align: center;

//   h3,
//   p,
//   span {
//     margin-bottom: 1rem;
//   }
// `;
