import { createContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userProfileAdapter } from "../../adapters/profile/userProfile.adapter";
import { achievementsActions, authActions, programsActions } from "../../redux/actions";
import { useState } from "react";

export const ProfileContext = createContext(null);

export const ProfileProvider = ({ children }) => {

    const dispatch = useDispatch();

    const { user, loading } = useSelector((state) => state.auth);

    const { programs, listLoading} = useSelector((state) => state.programs);

    const { achievements, listLoading:listLoadingAchievements } = useSelector((state) => state.achievements);

    const [currentProperty, setCurrentProperty] = useState(null);

    const userProfile = userProfileAdapter(user);

    const userPrograms = {
        programs: programs,
        loading: listLoading
    }

    const userAchievements = {
        achievements: achievements,
        loading: listLoadingAchievements
    }

    useEffect(() => {
        if (!programs) {
            dispatch(programsActions.getProgramsRequest());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programs]);

    useEffect(() => {
        if (!achievements) {
            dispatch(achievementsActions.getAchievementsRequest(user.sis_id));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achievements])

    // Data es un objeto de las propiedades que vamos a actualizar en "raw format", sin adaptar, como se van a enviar al EP
    function handleUpdateProfile(data) {
        dispatch(
            authActions.updateUserRequest({
                ...user,
                ...data
            })
        );
    }


    return (
        <ProfileContext.Provider
            value={{
                user: userProfile,
                userPrograms,
                userAchievements,
                userLoading: loading,
                handleUpdateProfile,
                currentProperty,
                setCurrentProperty
            }}
        >
            {children}
        </ProfileContext.Provider>
    )
}

export default ProfileProvider;