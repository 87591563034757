import React, { useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Videos from '../img/video/fondo-web-04.mp4';
import Link from '@mui/material/Link';
const Welcome = (props) => {
    const { setMailLanding } = props;

    const videoRef = useRef();
    const setPlayBack = () => {
        videoRef.current.playbackRate = 0.8;
        //onCanPlay={() => setPlayBack()}
    };

    return (
        <Section>
            <Video autoPlay muted loop>
                <source src={Videos} type="video/mp4" />
            </Video>
            <DivBackgroud />
            <DivContent>
                <DivText>
                    <H1>
                        N° 1 en formación online de negocios de América Latina
                    </H1>
                    <H3>
                        Nos gusta pensar que somos los soñadores de América
                        Latina. Porque nos inspira transformar realidades,
                        porque nos potenciamos entre todos para dar lo mejor
                        como individuo, como equipo y como comunidad.
                    </H3>
                    <H2>Somos ADEN. Juntos, líderes.</H2>
                </DivText>
                <DivInfo>
                    <H4>Regístrate y accede a miles de recursos sin costo</H4>
                    <div>
                        <Input
                            type="email"
                            placeholder="Email"
                            id="mail-landing"
                        ></Input>
                    </div>
                    <LinkFake
                        onClick={() =>
                            setMailLanding(
                                document.getElementById('mail-landing').value
                            )
                        }
                        to="auth/registration"
                    >
                        ¡Quiero ser parte!
                    </LinkFake>
                </DivInfo>
            </DivContent>
        </Section>
    );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    setMailLanding(mail) {
        dispatch({
            type: '[Set Mail] Action',
            mail
        });
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);

const Section = styled.section`
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

const DivBackgroud = styled.div`
    background: rgb(0, 0, 0);
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
`;

const DivContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 1rem;
    @media screen and (max-width: 1500px) {
        width: 80%;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const DivText = styled.div`
    z-index: 99;
    margin: 0 5rem;
    color: #ffffff;
    text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media (max-width: 768px) {
        margin: 0 2rem;
    }
`;

const H2 = styled.h2`
    font-size: 2rem;
    @media (max-width: 1024px) {
        font-size: 1.5rem;
    }
`;

const H3 = styled.h3`
    font-size: 1.5rem;
    line-height: 140%;
    font-weight: 400;
    @media (max-width: 1024px) {
        font-size: 1rem;
    }

    @media (max-width: 1024px) {
        font-size: 1.2rem;
        line-height: 150%;
    }
`;

const H1 = styled.h1`
    font-size: 3.5rem;
    font-weight: 900;
    @media (max-width: 1024px) {
        font-size: 2.5rem;
    }
`;

const DivInfo = styled.div`
    z-index: 99;
    margin: 0 5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (max-width: 768px) {
        margin: 0 2rem;
    }
`;

const H4 = styled.h4`
    font-size: 1rem;
    color: #ffffff;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
`;

const Input = styled.input`
    width: 60%;
    box-sizing: border-box;
    padding: 1rem 1rem;
    border-radius: 5px;
    border: none;
    :focus {
        outline: none;
        box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    }
    @media screen and (max-width: 480px) {
        width: 100%;
    }
`;

const LinkFake = styled(Link)`
    background-color: #b31d15;
    color: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 1rem 2.5rem;
    font-weight: 700;
    display: flex;
    width: max-content;
    align-items: center;
    text-align: center !important;

    :hover {
        background-color: #921009;
        color: #ffffff;
        text-decoration: none;
    }
`;
