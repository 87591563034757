import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import GenericAccordion from "../../../common/GenericAccordion";
// import AdditionalContent from './AdditionalContent';
// import Calendar from '../calendar/Calendar';

// import PlusIcon from '@mui/icons-material/Close';

const ModuleContent = (props) => {
    const { courses } = props;
    console.log("🚀 ~ ModuleContent ~ courses:", courses)

    if (!courses) return <></>;
    
    return (
        <Wrapper>
            <CardFake>
                <Container>
                    <Header>
                        <h2>Módulos</h2>
                    </Header>
                    {courses?.sort((a, b) => new Date(a.order - b.order))?.map((module, index) => {
                        if (module?.category === "electiva") {
                            return (
                                <GenericAccordion
                                    key={index}
                                    id={index}
                                    publication_name={module?.publication_name}
                                    units={module?.electives || Array()} // Array() para evitar undefined, parche temporal
                                    type={"electiva"}
                                />
                            )
                        }
                        return (
                            <GenericAccordion
                                key={index}
                                id={index}
                                publication_name={module.publication_name}
                                units={module.units}
                                type={module.type}
                            />
                        );
                    })}
                </Container>
            </CardFake>

            {/* <Calendar />

            <CardAdditional>
                <Container>
                    <Header>
                        <h2>Contenido adicional</h2>
                        <ButtonFake color="primary" variant="contained">
                            <PlusIcon /> Añadir
                        </ButtonFake>
                    </Header>
                    <AdditionalContent />
                </Container>
            </CardAdditional> */}
        </Wrapper>
    );
};

export default ModuleContent;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 420px auto;
    gap: 2.2rem;
    grid-template-areas:
        "container container container calendar"
        "container container container calendar"
        "additional additional additional .";

    @media (max-width: 1200px) {
        grid-template-areas:
            "container container container container"
            "container container container container"
            "additional additional calendar calendar"
            "additional additional calendar calendar";
    }

    @media (max-width: 650px) {
        grid-template-areas:
            "container container container container"
            "container container container container"
            "additional additional additional additional"
            "calendar calendar calendar calendar";
    }
`;
const CardFake = styled(Card)`
    grid-area: container;
    padding: 1rem;
    border-radius: 20px;
`;
const Container = styled.div`
    width: 98%;
    margin: 0 auto;
`;
const Header = styled.header`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    h2 {
        margin: 1rem 0;
        color: #222222;
        font-size: 1.2rem;
    }
`;
// const CardAdditional = styled(Card)`
//     grid-area: additional;
//     padding: 1rem;
//     border-radius: 20px;
//     overflow-y: scroll;

//     @media (max-width: 1460px) {
//         padding: 2rem 1.5rem;
//         height: 600px;
//     }
// `;
// const ButtonFake = styled(Button)`
//     height: 2.2rem;

//     svg {
//         transform: rotateZ(45deg);
//         font-size: 1.1rem;
//         margin-right: 0.3rem;
//     }
// `;
