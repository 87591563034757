import React from 'react'
import ErrorResponse from '../../../acropolisCommon/components/ErrorResponse'
import ViewPresentation from './components/ViewPresentation'

const RenderPresentation = (props) => {

    const { resource, onlyView = false } = props

    if (onlyView) {
        return <ViewPresentation resource={resource} />
    }

    return (
        <ErrorResponse message="Las actividades de presentacion aún no se pueden visualizar en esta página" />
    )
}

export default RenderPresentation