import React from 'react';
import styled from 'styled-components';
import useTheme from '../../../hooks/useTheme';
import ListCard from '../../ui/organisms/microlearnings/ListCard';

const ScrollMicrolearnings = (props) => {
    const { primary } = useTheme();

    const {
        microlearnings,
        currentMicrolearningId,
        handleCurrentMicrolearning
    } = props;

    return (
        <ScrollMicrolearningsContainer>
            <Title>También puede interesarte...</Title>
            <ListWrapper>
                <List color={primary}>
                    {microlearnings.map((microlearning) => (
                        <ListCard
                            microlearning={microlearning}
                            currentMicrolearningId={currentMicrolearningId}
                            key={microlearning?.repo_id}
                            handleCurrentMicrolearning={
                                handleCurrentMicrolearning
                            }
                        />
                    ))}
                </List>
            </ListWrapper>
        </ScrollMicrolearningsContainer>
    );
};

export default ScrollMicrolearnings;

const ScrollMicrolearningsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.5rem 1rem;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Title = styled.h2`
    font-size: 1rem;
    font-weight: 700;

    @media (max-width: 768px) {
        color: #616161;
    }
`;

const ListWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const List = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    overflow-x: hidden;
    overflow-y: scroll;

    @media screen and (max-width: 768px) {
        position: relative;
        max-height: 300px;
        width: 100%;
    }

    ::-webkit-scrollbar {
        background-color: #f1f1f1;
        border-radius: 6px;
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: ${(p) => p.color};
        width: 4px;
    }
`;
