import React, { useState } from 'react';
import styled from 'styled-components';
import { getTicket } from '../../../../redux/api/tickets.api';
import DefaultSnackbar from '../../../common/DefaultSnackbar';

import ControlTicket from '../../molecules/tickets/ControlTicket';
import TicketInfo from '../../molecules/tickets/TicketInfo';

const TicketState = () => {
    // STATE
    const [ticket, setTicket] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // FUNCTIONS
    async function handleSubmit({ ticket }) {
        setTicket(null);
        setLoading(true);
        const res = await getTicket(ticket);
        if (res.status) {
            setTicket(res.data);
        } else {
            setError(res.data);
        }
        setLoading(false);
    }

    // RETURN
    return (
        <Container>
            {/** Formulario */}
            <ControlTicket handleSubmit={handleSubmit} loading={loading} />
            {/** Informacion del ticket */}
            {ticket && !loading && <TicketInfo ticket={ticket} />}
            {/** Snackbar error */}
            <DefaultSnackbar error={error} />
        </Container>
    );
};

export default TicketState;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
`;
