import React, { useEffect } from "react";
// import styled from "styled-components";
import * as encode from "nodejs-base64-encode";
import { useSelector } from "react-redux";
// import Button from "@mui/material/Button";
import DefaultCard from "../../../acropolisCommon/components/DefaultCard";
// import { Text } from "../../../acropolisCommon/components/Texts";
import { viewResource } from "../../services/repositorio";
import { useHistory } from "react-router-dom";

const MainViewSurvey = (props) => {
    const { hash } = props;

    const { user } = useSelector((state) => state.auth);

    // console.log("Aca");
    // window.QPROSurvey = { };
    // window.QPROSurvey.settings = {inID : "fSSgBZw3", segmentCode : "PS", appURL : "https://www.questionpro.com"}
    // console.log('window', window)

    const { goBack } = useHistory();

    const decodeData = encode.decode(hash, "base64");
    const surveyObject = JSON.parse(decodeData);

    const handleProgress = async () => {
        if (!surveyObject.done) {
            const body = {
                user: user.repo_id,
                survey: surveyObject.id,
            };
            const request = await viewResource(body);
            if (request.error) {
                alert("Ocurrió un error al cargar el progreso");
            }
        }
        window.open(surveyObject?.link);
        goBack();
    };

    useEffect(() => {
        console.info("me ejecute jejerejre");
        handleProgress();
    }, []);

    return (
        <DefaultCard>
            {/* <Header>
                <div></div>
                <Text fontWeight="bold">En caso de no poder finalizar la encuesta has click en el siguiente botón</Text>
                <Button onClick={() => window.open(surveyObject?.link)} color='primary' variant='contained'>Ir a la encuesta</Button>
            </Header>
            <Iframe onLoad={handleProgress} src={surveyObject?.link} /> */}
        </DefaultCard>
    );
};

export default MainViewSurvey;

// const Iframe = styled.iframe`
//     border: none;
//     width: 100%;
//     min-height: 670px;
//     height: 100%;
// `;

// const Header = styled.div`
//     display: flex;
//     justify-content: space-between;
//     height: 80px;
//     align-items: center;
// `;
