import React, { useState } from "react";
import styled from "styled-components";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import AcademicModalEditProfile from "./AcademicModalEditProfile";
// import { profileSchema } from "../../../../utils/schemas"; Agregar cuando se haya finalizado el completado de perfil
import { authActions } from "../../../../redux/actions";
import TabsComponent from "../../../common/TabsComponent";
import axios from "axios";
import { SnackBarGenerico } from "../../../../app/campus/components/SnackBarGenerico";
import { useSnackBar } from "../../../../app/campus/hooks/useSnackBar";

const tabs = [{ label: "Información General", id: 0 }];

const FormEditProfile = (props) => {
    const { ws, handleNewGetWs, id } = props;
    // REDUX
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const loading = useSelector((state) => state.auth.loading);
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    // STATE
    const initialValues = {
        // OptionOne
        name: user.name,
        biography: user.biography,
        document_type: user.document_type,
        document_number: user.document_number,
        birthday: user.birthday,
        nationality: user.nationality,
        country: user.country,
        city: user.city,
        gender: user.gender,
        profile_is_public: user.profile_is_public,
        zip: user.zip,
        street: user.street,
        // OptionTwo
        profession: user.profession,
        company: user.company,
        current_job: user.current_job,
        job_area: user.job_area,
        work_email: user.work_email,
        work_phone: user.work_phone,
        work_experience: user.work_experience,
        academic_training: user.academic_training,
        // OptionThree
        // personal_email: user.personal_email,
        phone: user.phone,
        mobile: user.mobile,
        whatsapp: user.whatsapp,
        facebook: user.facebook,
        instagram: user.instagram,
        twitter: user.twitter,
        skype: user.skype,
        linkedin: user.linkedin,
        // OptionFour
        motivaciones: user.motivaciones,
        intereses: user.intereses,
    };

    // STATE
    const [currentTab, setCurrentTab] = useState(0);
    const [infoComplete, setInfoComplete] = useState(false);
    const [buttonState, setbuttonState] = useState(false);
    const [wsSelect, setWsSelect] = useState(null);
    const [companions, setCompanions] = useState(0);

    // FUNCTIONS
    function changeTab(e, value) {
        setCurrentTab(value);
    }

    const registerWsGraduation = async () => {
        setbuttonState(true);
        const data = {
            partner_id: user.partner_id,
            id_ws: wsSelect,
            companions: parseInt(companions, 10),
        };
        const result = await registerWsGraduationService(data);
        // handleClose();
        if (result.status === 200) {
            openSnackBar("success", `¡El formulario se envió exitosamente!`);
            handleNewGetWs();
        }
        setbuttonState(false);
        setWsSelect(null);
        setCompanions(null);
    };

    function handleSubmit(values) {
        dispatch(
            authActions.updateUserRequest({
                ...values,
                phone: values.phone.length > 4 ? values.phone : "",
                mobile: values.mobile.length > 4 ? values.mobile : "",
            })
        );
    }

    // RETURN
    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ values, setFieldValue, handleChange }) => (
                    <Container>
                        {/** Seleccionar opcion */}
                        <TabsComponent
                            tab={currentTab}
                            handleTab={changeTab}
                            tabs={tabs}
                        />
                        {/** Opciones */}
                        <AcademicModalEditProfile
                            currentTab={currentTab}
                            values={values}
                            id={id}
                            setFieldValue={setFieldValue}
                            setInfoComplete={setInfoComplete}
                            handleChange={handleChange}
                            ws={ws}
                            handleNewGetWs={handleNewGetWs}
                            setWsSelect={setWsSelect}
                            setCompanions={setCompanions}
                            setbuttonState={setbuttonState}
                            wsSelect={wsSelect}
                            companions={companions}
                        />

                        {/** Boton submit */}
                        <ButtonWrapper>
                            {/* Cuando los campos no están completos  */}
                            <div></div>
                            <LegendWrapper>
                                {!infoComplete &&
                                    "Termina de completar tus datos para poder registrarte."}
                            </LegendWrapper>

                            {loading ? (
                                <ProgressWrapper>
                                    <CircularProgress
                                        color="primary"
                                        size={32}
                                    />
                                </ProgressWrapper>
                            ) : (
                                <ButtonFake
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={() => registerWsGraduation()}
                                    disabled={infoComplete ? false : true}
                                >
                                    Registrarme
                                </ButtonFake>
                            )}
                        </ButtonWrapper>
                    </Container>
                )}
            </Formik>
            <SnackBarGenerico
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </>
    );
};

export default FormEditProfile;

// API
const URL = process.env.REACT_APP_SISAPI + "/v1/acropolis/graduation";

const registerWsGraduationService = async (data) => {
    try {
        const response = await axios.post(
            `${URL}/register_ws_graduation `,
            data
        );
        return response;
    } catch (error) {
        console.error(error);
        return error.response;
    }
};

const Container = styled(Form)`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 2rem;
    height: 100%;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: max-content;
    width: 100%;
    align-items: center;
    margin-top: auto;
    margin-left: auto;
    gap: 0.5rem;
    @media (max-width: 768px) {
        margin-top: 4rem;

        width: 100%;
        flex-direction: column;
    }
`;

const ProgressWrapper = styled.div`
    padding: 0.25rem 6rem;
`;

const LegendWrapper = styled.p`
    color: #b31d15;
    font-weight: bold;
    text-align: center;
    font-size: 1.1rem;
`;

const ButtonFake = styled(Button)`
    width: auto;

    @media (max-width: 768px) {
        width: 100%;
    }
`;
