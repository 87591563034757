import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import * as encode from 'nodejs-base64-encode';
import styled from 'styled-components';
import { Icon } from '@iconify/react';

// Components
import ElectiveSubject from './subjectFormativeModules/ElectiveSubject';
import CustomAccordion from './subjectFormativeModules/CustomAccordion';
/* import SubjectAccordion from '../../SubjectAccordion'; */
import Text from '../../../../../components/common/Text';
import State from "./subjectFormativeModules/State"

// Assets
import Survey from './subjectFormativeModules/Survey';

// Hooks
import useStudentStatus from '../../../../campus/hooks/useStundetStatus';

const SubjectFormativeModules = (props) => {
    const { modules, origin, subjectType } = props;

    const params = useParams();
    const history = useHistory();
    const { statusStudentBlock } = useStudentStatus();

    const [electivesFormated, setElectivesFormated] = useState([]);
    const [hasElectiveSubject, setHasElectiveSubject] = useState(false);
    const [disableLast, setDisableLast] = useState(false);

    useEffect(() => {
        modulesHandler(modules);
    }, [modules]);

    useEffect(() => {
        setDisableLast(
            hasElectiveSubject
                ? !checkAllDoneBefore(modules) || electivesFormated.length === 0
                : !checkAllDoneBefore(modules)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasElectiveSubject]);

    const handleClickSurvey = (module) => {
        const survey = {
            link: module?.survey?.link || false,
            id: module?.survey?.id || false,
            done: module?.survey?.done || false,
        };

        // A veces al encodear en base 64 el hash queda con un "/" que no es permitido en la URL, entonces lo reemplazamos por "*"
        const hashSurvey = encode.encode(JSON.stringify(survey), 'base64').replace(/\//g, '*');

        if (!!module?.onStudy) {
            history.push(`/view/survey/${hashSurvey}`);
        }
    };

    const handleClick = (hash) => {
        history.push(`/curso/${hash}?id=${params.id}&origin=${origin}`);
    };

    // Verifica que todo lo anterior al último curso
    const checkAllDoneBefore = (array) => {
        let allDone = true;
        array.forEach((module) => {
            // Si no es encuesta y tiene progreso diferente a 100 = false
            // Si es encuesta y no está hecha = false
            // Y no tiene que ser tipo "Bloque Integrador"
            // si es main_elective y tiene progreso diferente a 100 = false

            if (
                (!module?.survey &&
                    module?.type !== 'Bloque Integrador' &&
                    module?.progress !== 100 &&
                    !Array.isArray(module.electives) &&
                    module.category === 'obligatoria' &&
                    !module.selected_from_elective) ||
                (module?.survey && !module?.survey?.done) ||
                (!!module.main_elective &&
                    module.main_elective !== 'not_electiva' &&
                    module.progress !== 100)
            ) {
                allDone = false;
                return allDone;
            }
        });
        return allDone;
    };

    const modulesHandler = (modules) => {
        const fileteredModules = modules.filter((module) => {
            return !!module.selectedFromElective;
        });
        setElectivesFormated(fileteredModules);

        for (const module of modules) {
            if (module.electives.length >= 1) {
                setHasElectiveSubject(true);
            }
        }

        // Ordenar los módulos por la propiedad order
        modules.sort((a, b) => a.order - b.order);
    };

    const moduleTypeHandler = (module) => {
        const moduleMap = {
            survey: (
                <Survey
                    surveyName={module?.survey?.name}
                    handleClick={() => handleClickSurvey(module)}
                />
            ),
            elective: (
                <ElectiveSubject
                    electives={module?.electives}
                    handleClick={handleClick}
                    electivesFormated={electivesFormated}
                    disabled={subjectType === 'DEMO' ? !module?.moduleDemo : false}
                    blocked={statusStudentBlock?.studentStatus === 'Bloqueado'}
                    reasonBlocked={statusStudentBlock?.blockDetail?.reason}
                />
            ),
            default: (
                <CustomAccordion
                    module={module}
                    disabled={(subjectType === 'DEMO' ? !module?.moduleDemo : false)}
                    blocked={module.progress === 0 && statusStudentBlock?.studentStatus === 'Bloqueado'}
                    reasonBlocked={statusStudentBlock?.blockDetail?.reason}
                    disableLast={module?.type === 'Bloque Integrador' && disableLast}
                    handleClick={() => {
                        const hash = encode.encode(module?.id + '', 'base64');
                        handleClick(hash)
                    }}
                    hasElectiveSubject={hasElectiveSubject}
                    infoEnabled={module?.type === 'Bloque Integrador' && disableLast}
                />
            )
        };

        if (module?.survey) {
            return moduleMap.survey;
        }
        if (!!module?.electives) {
            return moduleMap.elective;
        }
        if (module?.category === 'electiva') {
            return null
        }
        return moduleMap.default;
    };

    return (
        <Wrapper>
            <TitleWrapper>
                <Text fontSize="20px" fontWeight="500">
                    Bloques que componen esta asignatura:
                </Text>
            </TitleWrapper>
            <Container>
                <OrnamentLine />
                {modules?.map((module, i) => (
                    <>
                        {/* Si no es categoría 'electiva' o una electiva principal, no se muestra */}
                        {(module?.mainElective) && (
                            <ModulesWrapper key={i}>
                                <ModuleCheck>
                                    {/* Si no es categoría 'elective' y el módulo no es seleccionado de una electiva, se muestra el icono */}
                                    {(!module?.category === 'electiva' ||
                                        !module.selectedFromElective) && (
                                            <IconWrapper>
                                                {module?.progress !== 0 ||
                                                    module?.survey?.done ||
                                                    (Array.isArray(module.electives) && module.electives.some(elective => elective?.progress !== 0)) ? (
                                                    <Icon icon="heroicons:check-circle-16-solid" width="40px" height="40px" />
                                                ) : module.category !== 'electiva' ? (
                                                    <CircleCommon />
                                                ) : (
                                                    <CircleElective>
                                                        <Icon icon="tdesign:gesture-click" width="25px" height="25px" />
                                                    </CircleElective>
                                                )}
                                            </IconWrapper>
                                        )}
                                </ModuleCheck>
                                <State
                                    module={module}
                                />
                                {
                                    moduleTypeHandler(module)
                                }
                            </ModulesWrapper>
                        )}
                    </>
                ))}
            </Container>
        </Wrapper>
    );
};

export default SubjectFormativeModules;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 120px;
    height: 100%;

    @media screen and (max-width: 768px) {
        gap: 8rem;
    }
`;

const Container = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6rem;

    @media (width < 768px) {
        gap: 6rem;
    }
`;

const TitleWrapper = styled.div`

    @media screen and (max-width: 768px) {
        display: flex;
        margin-left: 0;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 3rem;
    }
`;

const ModulesWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    grid-template-columns: 1fr 18fr;
    align-items: center;

    @media screen and (max-width: 1440px) {
        grid-template-columns: 1fr 14fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const ModuleCheck = styled.div`
    position: absolute;
    left: -50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 50%;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    color: #b31d15;
    background-color: #f9f9f9;
    padding: 3px 0;

    svg {
        color: #b31d15;
        @media screen and (max-width: 768px) {
            font-size: 2.5rem;
        }
    }
`;

const CircleCommon = styled.div`
    width: 32px;
    height: 32px; 
    border-radius: 100px; 
    border: 2px solid #B31D15; 
    margin-left: 2px; 
    display: flex; 
    align-items: center; 
    justify-content: center;
`

const CircleElective = styled.div`
    width: 42px;
    height: 42px; 
    border-radius: 100px; 
    border: 2px solid #B31D15; 
    margin-left: -2px; 
    display: flex; 
    align-items: center; 
    justify-content: center;
`

const OrnamentLine = styled.span`
    width: 2px;
    position: absolute;
    min-height: calc(100% - 7.7rem);
    margin: auto;
    left: -30px;
    top: 50%;
    visibility: visible;
    transform: translate(-50%, -50%);
    background-color: #b31d15;

    @media screen and (max-width: 768px) {
        left: 16px;
        min-height: calc(100% - 19rem);
        display: none;
    }
`;