import { useEffect, useState } from 'react';
import { getStudentsServices } from '../redux/api/students';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../utils/snackStyles';

const useStudentsTeacher = (minify = false, subject) => {
    const [students, setStudents] = useState(null);
    const [initialStudents, setInitialStudents] = useState(null);

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (subject) {
            /* setStudents(null);
            console.info("students", students);
            if (!students)  */getStudents(subject?.id);
        } else {
            if (!students) getStudents(subject)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subject]);

    const getStudents = async (subjectID) => {
        const request = await getStudentsServices(minify, subjectID);
        if (request.error) {
            openSnackbar('¡No se pudieron cargar los estudiantes!');
            setStudents(false);
            return;
        }
        setStudents(request);
        setInitialStudents(request);
    };

    const handleStudents = (newStudents) => {
        setStudents(newStudents);
    };

    return { initialStudents, students, handleStudents };
};

export default useStudentsTeacher;
