import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import DialogGenericFG from "../../../common/DialogGenericFG";

const Pay = (props) => {
  const { pay, activeRol, type } = props;
  // FUNCTIONS

  //STATES
  const [open, setOpen] = React.useState(false);

  //FUNCTIONS
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const open2Checkout = (link) => {
    window.open(
      link,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=10,left=10,width=800,height=480"
    );
    // Agregar actividad en CRM
  };

  // RETURN
  return (
    <PayContainer>
      <Body>
        <Product>
          {type === "slope" && <SubTitle>Valor cuota</SubTitle>}
          {type === "paid" && <SubTitle>Monto abonado</SubTitle>}
          {type === "campus" && <SubTitle>importe</SubTitle>}
          {type === "slope" && (
            <Money>
              {pay.moneda}{" "}
              {pay.valor_cuota !== undefined
                ? Math.round(pay.valor_cuota)
                : "0"}
            </Money>
          )}
          {type === "paid" && (
            <Money>
              {pay.moneda}{" "}
              {pay.monto_abonado !== undefined
                ? Math.round(pay.monto_abonado)
                : "0"}
            </Money>
          )}
          {type === "campus" && (
            <Money>
              {pay.moneda}{" "}
              {pay.importe_total !== undefined
                ? Math.round(pay.importe_total)
                : "0"}
            </Money>
          )}
        </Product>

        <Product>
          <SubTitle>Forma de pago</SubTitle>
          <Title>{pay.forma_pago}</Title>
        </Product>
        {type === "campus" ? (
          <>
            <Product>
              <SubTitle>Cantidad de cuotas</SubTitle>
              <div>
                <Title>{pay.cantidad_cuotas}</Title>
              </div>
            </Product>
            <Product>
              <FakeButton onClick={handleClickOpen} type="warning">
                Estado de pago
              </FakeButton>
              <DialogGenericFG
                message={`Para conocer el estado del pago en sede, comunicarse con ${pay.email_controller}`}
                open={open}
                title="Estado de pago"
                handleClose={handleClose}
                customColor="#b31d15"
                titleColor="#b31d15"
                transitionType="grow"
              />
            </Product>
          </>
        ) : type === "paid" ? (
          <Product>
            <SubTitle>Fecha de pago</SubTitle>
            <div>
              {!!pay.fecha_operacion ? (
                <Title>{pay.fecha_operacion}</Title>
              ) : (
                <Title>-</Title>
              )}
            </div>
          </Product>
        ) : (
          <Product>
            <SubTitle>Cantidad de cuotas</SubTitle>
            <div>
              <Title>{pay.cantidad_cuotas}</Title>
            </div>
          </Product>
        )}

        {type === "slope" && activeRol === "alumno" ? (
          <Product>
            <FakeButton
              disabled={false}
              variant="contained"
              color="primary"
              onClick={() => open2Checkout(pay.link_pago)}
            >
              Pagar
            </FakeButton>
          </Product>
        ) : null}
      </Body>
    </PayContainer>
  );
};

export default Pay;

const PayContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1.5rem;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
`;
const Money = styled.h2`
  font-size: 1rem;
  font-weight: 700;
`;
// const Header = styled.div`
//   display: flex;
//   align-items: center;
//   column-gap: 2rem;

//   @media (max-width: 768px) {
//     justify-content: space-between;
//   }
// `;

// const PayId = styled.div`
//   font-size: 0.8rem;
//   font-weight: 600;
//   color: #616161;
// `;

const StatusButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.6rem;
  border-radius: 50px;
  font-size: 0.75rem;
  font-weight: 700;
  background-color: ${(props) => {
    if (props.status === "pagado") {
      return "#1E8065";
    } else if (props.status === "pendiente") {
      return "#C29F43";
    } else if (props.status === "cancelado") {
      return "#ad2d21";
    } else if (props.status === "reembolsado") {
      return "#3e56b0";
    }
  }};
  color: #ffffff;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

const Product = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.span`
  font-size: 0.6rem;
  text-transform: uppercase;
  color: #616161;
`;

const Title = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: #222222;
`;

const FakeButton = styled(Button)`
  /* z-index: 10; */
  background-color: ${(p) => p.type === "warning" && "#DF9363"};
  color: ${(p) => p.type === "warning" && "#fff"};
  @media (max-width: 768px) {
    width: 100%;
  }
  transition: all 0.4s ease-in-out;
  :hover {
    background-color: ${(p) => p.type === "warning" && "#CB7945"};
  }
`;