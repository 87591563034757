import * as types from '../types';

const actions = {};

actions.titulation = (state) => {
    return {
        type: types.SET_TITULACION_STATE,
        payload: state
    };
};


export { actions };
