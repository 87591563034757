import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Card, Button, CircularProgress } from '@mui/material';
import { Text } from '../../../../../../../components/common/Texts';
import LazyImg from '../../../../../../../components/common/LazyImg';
import SimpleLoading from '../../../../../../../components/common/SimpleLoading';
import GraduateStudent from '../../../../../../../assets/media/teacher/graduate-student.png';
import GraduateSuccess from '../../../../../../../assets/media/teacher/graduate-success.png';
import GraduateError from '../../../../../../../assets/media/teacher/graduate-error.png';
import useGraduateStudent from '../../../../../../../hooks/teacher/useGraduateStudent';
import { toTitleCase } from '../../../../../../../helpers/titleCase.helper';

const GraduateStudentModal = (props) => {
    const { open, onClose, student, setSuccessfullGraduation } = props;

    const {
        grade: { final_grade: finalGrade },
        id: repoId,
        enrollment_id: enrollmentId,
        name,
    } = student;

    const defaultTitles = {
        image: GraduateStudent,
        mainTitle: `¿Quieres egresar al estudiante
        ${toTitleCase(name)}?`,
        subTitle:
            'Recuerda que al confirmar el egreso del alumno, no podrás deshacer la acción.',
    };

    const successTitles = {
        image: GraduateSuccess,
        mainTitle: '¡Egreso realizado con éxito!',
        subTitle: '',
    };

    const errorTitles = {
        image: GraduateError,
        mainTitle: 'Error en el egreso del estudiante',
        subTitle:
            'Ocurrió un problema al procesar tu solicitud de egreso. Por favor, intenta nuevamente. Si el problema persiste, contacta a nuestro soporte.',
    };

    const { handleGraduate } = useGraduateStudent();

    //STATES
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isGraduated, setIsGraduated] = useState(false);
    const [information, setInformation] = useState(defaultTitles);
    const [isConfirmed, setIsConfirmed] = useState(false);

    //EFFETCS
    useEffect(() => {
        isGraduated &&
            isConfirmed &&
            setSuccessfullGraduation(repoId) &&
            onClose();
    }, [isGraduated, isConfirmed]);

    //FUNCTIONS
    const handleIsGraduate = useCallback(async (repoId) => {
        setIsLoading(true);
        const response = await handleGraduate(repoId, finalGrade, enrollmentId);
        if (response.error) {
            setInformation(errorTitles);
            setIsLoading(false);
            setIsError(true);
        }
        setInformation(successTitles);
        setIsLoading(false);
        setIsGraduated(true);
    }, []);

    const handleGraduateButtonClick = () => {
        setIsConfirmed(true);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <GraduateModal open={open} onClose={onClose}>
            <CardWrapper>
                <LazyImg
                    width="300px"
                    height="300px"
                    src={information.image}
                    backgroundColor="#ffffff"
                />
                <Body>
                    <Text fontSize="24px" fontWeight="700" color="#b31d15">
                        {information.mainTitle}
                    </Text>
                    <Text fontSize="16px" fontWeight="400" color="#222222">
                        {information.subTitle}
                    </Text>
                </Body>
                <ButtonsWrapper>
                    {(!isGraduated || isError) && (
                        <GraduateButton
                            onClick={handleIsGraduate}
                            variant="contained"
                        >
                            {isLoading ? (
                                <Spinner size={24} padding="0" />
                            ) : (
                                `Egresar alumno`
                            )}
                        </GraduateButton>
                    )}
                    <GraduateButton
                        onClick={
                            isGraduated
                                ? handleGraduateButtonClick
                                : handleClose
                        }
                        variant={isGraduated ? 'contained' : 'outlined'}
                    >
                        {!isGraduated ? 'Volver' : 'Actualizar'}
                    </GraduateButton>
                </ButtonsWrapper>
            </CardWrapper>
        </GraduateModal>
    );
};

export default GraduateStudentModal;

const GraduateModal = styled(Modal)`
    display: grid;
    place-items: center;
`;

const CardWrapper = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    height: 576px;
    width: 460px;
    border-radius: 30px;
    outline: none;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 0 3rem;
    text-align: center;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    column-gap: 1rem;
`;

const GraduateButton = styled(Button)`
    min-width: 150px;
    border-radius: 50px;
    box-shadow: none;
`;

const Spinner = styled(CircularProgress)`
    color: #ffffff;
`;
