import React, { useState } from "react";
import styled from "styled-components";
import CachedIcon from "@mui/icons-material/Cached";
import { Text } from "../../common/Texts";
import SelectRolModal from "../../ui/molecules/auth/SelectRolModal";
import { useDispatch, useSelector } from "react-redux";
import { checkRolesLogin } from "../../../helpers/roles.helper";
import { useHistory } from "react-router-dom";

const ALAB_URL = process.env.REACT_APP_ALAB;

const ChangeRol = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { loadingGetUser, user, activeRol, token, authToken} = useSelector(
        (state) => state.auth
    );

    const handleOpen = (e) => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleLogin = async (rol) => {
        if (rol === "ondemand") {
            window.open(
                `${ALAB_URL}/autologin?rol=ondemand&token=${token || authToken}&redirecturi=/campus`,
                "_self"
            );
        } else {
            history.push("../loading/" + rol);
        }
    };

    if (!checkRolesLogin(user.roles)) {
        return false;
    }

    return (
        <>
            <Wrapper onClick={handleOpen}>
                <CachedIcon />
                <Text>Cambiar de rol</Text>
            </Wrapper>
            <SelectRolModal
                rolOpen={checkRolesLogin(user.roles)}
                loadingGetUser={loadingGetUser}
                open={open}
                active={activeRol}
                handleLogin={handleLogin}
                handleClose={handleClose}
            />
        </>
    );
};

export default ChangeRol;

const Wrapper = styled.div`
    height: 58px;
    color: #a8a8a8;
    cursor: pointer;
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    padding: 0 28px;
    align-items: center;
    transition: ease-in-out 0.4s all;
    :hover {
        background-color: #f5f5f5;
        color: #b31d15;
    }
`;
