import React from "react";
import styled from "styled-components";

// Components
import { Text } from "../../../../../../components/common/Texts";

// Material UI
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import moment from "moment";

const Attempts = (props) => {
    const { note, submissions, setSubmission, handleSimpleReview } = props;

    const handleClickOpen = (sub) => {
        setSubmission(sub);
        handleSimpleReview();
    };

    const convertToLocalTime = (date, timezone) => {
        // Convierte la fecha y hora del evento a la zona horaria especificada
        const eventTime = moment.tz(date, timezone);

        // Convierte la hora del evento a la hora local
        const localTime = eventTime.clone().tz(moment.tz.guess());

        return localTime;
    };

    return <>
        <AttemptsWrapper>
            <Header>
                <Col color="#616161" fontWeight="700" fontSize="14px">
                    Intentos
                </Col>
                <Col color="#616161" fontWeight="700" fontSize="14px">
                    Fecha de realización
                </Col>
                <Col color="#616161" fontWeight="700" fontSize="14px">
                    Calificación
                </Col>
                <Col color="#616161" fontWeight="700" fontSize="14px">
                    Estado
                </Col>
                <Col color="#616161" fontWeight="700" fontSize="14px">
                    Revisión
                </Col>
            </Header>
            <Body>
                {submissions
                    .sort((a, b) => {
                        return b.submission_number - a.submission_number;
                    })
                    .map((sub, index) => {
                        const formatDate = convertToLocalTime(sub.create_date, "UTC");
                        const localTimeFormatted = formatDate.format('YYYY-MM-DD HH:mm:ss');
                        return (
                            <>
                                <Col
                                    color="#616161"
                                    fontWeight="700"
                                    fontSize="14px"
                                >
                                    {sub?.submission_number}°
                                </Col>
                                <Col
                                    color="#616161"
                                    fontWeight="700"
                                    fontSize="14px"
                                >
                                    {localTimeFormatted}
                                </Col>
                                <Col
                                    color="#616161"
                                    fontWeight="700"
                                    fontSize="14px"
                                >
                                    {((sub?.grade * 100) / note).toFixed(2)}%
                                </Col>
                                <Col
                                    color="#616161"
                                    fontWeight="700"
                                    fontSize="14px"
                                >
                                    {
                                        <StateChip
                                            color={((sub?.grade * 100) / note).toFixed(2) >= 80 ? "#1E8065" : "#B31D15"}
                                            backgroundColor={((sub?.grade * 100) / note).toFixed(2) >= 80 ? "#F7FDFB" : "#FFF5F5"}
                                        >
                                            {
                                                ((sub?.grade * 100) / note).toFixed(2) >= 80 ? "Aprobado" : "Realizado"
                                            }
                                        </StateChip>
                                    }
                                </Col>
                                <Col
                                    color="#616161"
                                    fontWeight="700"
                                    fontSize="14px"
                                >
                                    <IconButton
                                        onClick={() => handleClickOpen(sub)}
                                        size="small"
                                    >
                                        <VisibilityIconFake width={"24px"} height={"24px"}/>
                                    </IconButton>
                                </Col>
                            </>
                        );
                    })}
            </Body>
        </AttemptsWrapper>
    </>;
};

export default Attempts;

const AttemptsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
`;

const Header = styled.div`
    width: 100%;
    height: 50px;
    background-color: #f1f1f1;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`;

const Body = styled.div`
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: #ffffff;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    ::-webkit-scrollbar {
        width: 1px;
    }
`;

const Col = styled(Text)`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const VisibilityIconFake = styled(VisibilityIcon)`
    font-size: 22px;
    margin: 2px;
`;

const StateChip = styled.div`
    width: 100%;
    height: fit-content;
    padding: .5rem 1rem;
    text-align: center;
    color: ${(props) => props.color};
    background-color: ${(props) => props.backgroundColor};
    border: 1px solid ${(props) => props.color};
    border-radius: 100px;
    font-size: 12px;
    font-weight: 700;
`;
