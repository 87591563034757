import styled from "styled-components";
import KeyboardTabRoundedIcon from '@mui/icons-material/KeyboardTabRounded';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

const GridPaginationBox = (props) => {

    const { page = 1, setPage = null, totalPages = 1 } = props;

    const handlePageToStart = () => {
        setPage(1)
      }
    
      const handlePageLeft = () => {
        page !== 1 && setPage(page - 1)
      }
    
      const handlePageRight = () => {
        page !== totalPages && setPage(page + 1)
      }
    
      const handlePageToEnd = () => {
        setPage(totalPages)
      }

    return (
        <PaginationWrapper>
            <PaginationBox>
                <PaginationButton onClick={handlePageToStart}>
                    <LeftKeyboardTabRoundedIcon sx={{ fontSize: 20 }} />
                </PaginationButton>
                <PaginationButton onClick={handlePageLeft}>
                    <WestRoundedIcon sx={{ fontSize: 18 }} />
                </PaginationButton>
                <p>Página <b>{page}</b> de {totalPages}</p>
                <PaginationButton onClick={handlePageRight}>
                    <EastRoundedIcon sx={{ fontSize: 18 }} />
                </PaginationButton>
                <PaginationButton onClick={handlePageToEnd}>
                    <KeyboardTabRoundedIcon sx={{ fontSize: 20 }} />
                </PaginationButton>
            </PaginationBox>
        </PaginationWrapper>
    )
}

export default GridPaginationBox;

const PaginationWrapper = styled.div`
  position: relative;
  height: 48px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`

const PaginationBox = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 500;
    color: #535353;
    b {
      color: #B31D15;
    }
  }
`

const PaginationButton = styled.button`
  cursor: pointer;
  color: #B31D15; 
  display: flex;
  align-items: center;
`

const LeftKeyboardTabRoundedIcon = styled(KeyboardTabRoundedIcon)`
  transform: rotate(180deg);
`