import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser';
import { Text } from '../../../../../../components/common/Texts';
import { Icon } from '@iconify/react/dist/iconify.js';

const BlockSubmission = (props) => {

    const { submission } = props

    const handleDonwload = () => {
        window.open(submission.file.file);
    };

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return html.replace(regex, 'src="');
    };

    if (!!submission.response) {
        return <div style={{ padding: "1rem 0" }}>{parse(sanitizerHtml(submission.response))}</div>
    }

    return (
        <BlockSubmissionWrapper>
            <Click onClick={handleDonwload}>
                <MyActivityButton>
                    <Icon icon="ic:round-attach-file" width="24px" height="24px" />
                    <Text fontSize="15px" fontWeight="600" color="#FFFFFF">
                        Descargar mi actividad
                    </Text>
                </MyActivityButton>
            </Click>
        </BlockSubmissionWrapper>
    )
}

export default BlockSubmission

const BlockSubmissionWrapper = styled.div`
    border-radius: 20px;
`

const Click = styled.div`
    cursor: pointer;
    max-width: 250px;
    transition: all .3s ease-in-out;
    :hover{
        filter: brightness(.9);
    }
`

const MyActivityButton = styled.div`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #cd2118;
    border-radius: 100px;
    padding: 12px 24px;

    svg {
        color: #fff;
    }
`