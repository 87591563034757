import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { inconvenientesActions } from '../actions';
import { getTipoInconveniente, postInconveniente } from '../api/inconvenientes';

function* tipoInconvenienteRequest() {
    try {
        const res = yield call(getTipoInconveniente);
        yield put(
            inconvenientesActions.tipoInconvenienteSuccess(
                res.data.response_data.tipos_inconventiente
            )
        );
    } catch (error) {
        yield put(inconvenientesActions.tipoInconvenienteFail(error));
    }
}

function* reportInconveniente(action) {
    try {
        const res = yield call(postInconveniente, action.payload);
        yield put(inconvenientesActions.reportInconvenienteSuccess(res));
    } catch (error) {
        yield put(inconvenientesActions.tipoInconvenienteFail(error));
    }
}

function* inconvenientesWatcher() {
    yield takeEvery(types.TIPO_INCONVENIENTE_REQUEST, tipoInconvenienteRequest);
    yield takeEvery(types.REPORT_INCONVENIENTE_REQUEST, reportInconveniente);
}

export { inconvenientesWatcher };
