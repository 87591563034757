import { useState } from "react";
import GridCard from "../../../common/GridCard";
import styled from "styled-components";
import useRepoStudents from "../../../../hooks/academic/useRepoStudents";
import CardWhithSocialMedia from "../../../common/teacher-academic/CardWhithSocialMedia";
import {
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Switch,
} from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import SimpleLoading from "../../../common/SimpleLoading";
import ErrorResponse from "../../../common/ErrorResponse";
import GenericPagination from "../../../common/GenericPagination";

const RepoStudents = () => {
  const {
    initialMyStudents,
    loading,
    myStudents,
    handleStudents,
    allStudents,
    initialAllStudents,
    getAllStudents,
    getRepoStudents,
    handleAllStudents,
    totalMyStudents,
    totalAllStudents,
    pageSize,
  } = useRepoStudents();

  const [isChecked, setIsChecked] = useState(false);
  const [inputText, setInputText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleFilter = (e) => {
    setInputText(e.target.value);
    // if (!isChecked) {
    //   handleStudents(
    //     initialMyStudents.filter((student) =>
    //       student.name.toLowerCase().includes(e.target.value.toLowerCase())
    //     )
    //   );
    // } else {
    // }
    handleFilterInput(e);
  };

  const handleCurrentPage = (e, value) => {
    setCurrentPage(value);
    // isChecked
    getAllStudents(inputText, (value - 1) * pageSize);
    // : getRepoStudents(pageSize, (value - 1) * pageSize);
  };

  const handleFilterInput = (e) => {
    setInputText(e.target.value);
  };

  const handleCheck = (e) => {
    // setIsChecked(e.target.checked);
    setInputText("");
    handleStudents(initialMyStudents);
    handleAllStudents(null);
    setCurrentPage(1);
  };

  if (loading) {
    return <SimpleLoading />;
  }

  return (
    <Wrapper>
      <FunctionalityWrapper>
        <FiltersWrapper>
          <TextField
            value={inputText}
            onChange={handleFilter}
            variant="outlined"
            size="small"
            label="Buscar estudiantes..."
            InputProps={{
              endAdornment: (
                <Button onClick={() => getAllStudents(inputText)}>
                  Buscar
                </Button>
              ),
            }}
          />
          {/* <FormControlLabel
            onClick={handleCheck}
            control={<Switch />}
            label="Buscar en todos los estudiantes"
          /> */}
        </FiltersWrapper>
        <PaginationWrapper>
          <GenericPagination
            totalItems={totalAllStudents}
            pageSize={pageSize}
            handleCurrentPage={handleCurrentPage}
            currentPage={currentPage}
          />
        </PaginationWrapper>
      </FunctionalityWrapper>
      <GridCard cards={allStudents} component={CardWhithSocialMedia} />
      {/* {isChecked && !allStudents ? (
        <ErrorResponse message="Ingresa el estudiante a buscar" />
      ) : (
        <GridCard
          cards={!isChecked ? myStudents || initialMyStudents : allStudents}
          component={CardWhithSocialMedia}
        />
      )} */}
    </Wrapper>
  );
};

export default RepoStudents;

const Wrapper = styled.div``;

const FunctionalityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
