import * as types from '../types';

const actions = {};

actions.getAchievementsRequest = (sis_id) => {
    return {
        type: types.GET_ACHIEVEMENTS_REQUEST,
        payload: sis_id
    };
};

actions.getAchievementsSuccess = (achievements) => {
    return {
        type: types.GET_ACHIEVEMENTS_SUCCESS,
        payload: achievements
    };
};

actions.getAchievementsFail = (error) => {
    return {
        type: types.GET_ACHIEVEMENTS_FAIL,
        payload: error
    };
};

export { actions };
