import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { Text } from '../../common/Texts';
import { useHistory } from 'react-router-dom';
import PublicIcon from '@mui/icons-material/Public';
import { CustomChip } from '../../../styled-components/mui/chip';

const InternationalWorkshop = () => {
    const history = useHistory();

    return (
        <CardWrapper>
            <BodyCard>
                <ChipWrapper>
                    <WorkshopChip
                        bgColor="#ffffff"
                        icon={<PublicIcon />}
                        color="primary"
                        label={<b>Workshops internacionales</b>}
                        width="fit-content"
                        variant="outlined"
                    />
                </ChipWrapper>
                <TextWrapper>
                    <Text
                        className="z"
                        fontSize="24px"
                        color="#ffffff"
                        fontWeight="900"
                    >
                        ¡Reserva tus tickets ahora!
                    </Text>
                    <Text
                        className="z"
                        fontSize="16px"
                        color="#ffffff"
                        fontWeight="400"
                    >
                        Los Workshops tienen como objetivo sumergir a los
                        futuros egresados en un ambiente propicio para generar
                        una visión global y vivenciar una experiencia
                        intercultural.
                    </Text>
                </TextWrapper>
                <RedirectButton
                    className="z"
                    color="primary"
                    variant="outlined"
                    onClick={() =>
                        history.push(
                            '/informacion-academica?tab=international-workshop'
                        )
                    }
                    // fullWidth
                >
                    Ver workshops disponibles
                </RedirectButton>
            </BodyCard>
        </CardWrapper>
    );
};

export default InternationalWorkshop;

const CardWrapper = styled.div`
    height: 100%;
    position: relative;
`;

const BodyCard = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: space-around;
    gap: 3rem;
    flex-direction: column;
    background: linear-gradient(#e51a1a, #b31d15);
`;

const RedirectButton = styled(Button)`
    width: fit-content;
    border-radius: 50px;
    background-color: #ffffff;
    box-shadow: none;

    &:hover {
        background-color: #f1f1f1;
        box-shadow: none;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const ChipWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const WorkshopChip = styled(CustomChip)`
    width: fit-content;

    svg {
        border: none;
    }
`;
