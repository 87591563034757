import { useState, useEffect } from 'react'

const useSortSettlements = (orders) => {

   const [open, setOpen] = useState(false)
   const [typeSorted, setTypeSorted] = useState(orders)
   const [columnType, setColumnType] = useState("Orden")
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      if (orders) {
         if (
            columnType === 'Orden' ||
            columnType === 'Fecha de carga' ||
            columnType === 'Fecha de actividad' ||
            columnType === 'Fecha de pago' ||
            columnType === 'Fecha de efectiva de pago'
         ) {
            sortByOrderAndDates(orders)
         }
         if (columnType === 'Tipo') {
            sortByType(orders)
         }
         if (columnType === 'Referencia') {
            sortByReference(orders)
         }
         if (columnType === 'Responsable') {
            sortByResponsible(orders)
         }
         if (columnType === 'Estado') {
            sortByState(orders)
         }
         if (columnType === 'Monto') {
            sortByAmount(orders)
         }
      }
      // eslint-disable-next-line
   }, [orders, columnType])

   const handleOpenList = () => {
      setOpen(!open)
   }

   const handleCloseList = () => {
      setOpen(false)
   }

   // ORDENA LOS STRINGS DE Z-A
   const sortStringsBackwards = (a, b) => {
      if (a < b) {
         return 1;
      }
      if (a > b) {
         return -1;
      }
      return 0;
   }

   // ORDENA LAS FILAS POR EL ORDEN (TAMBIÉN POR LAS DISTINTAS FECHAS)
   function sortByOrderAndDates(data) {
      setLoading(true)
      const sortedData = data.sort((a, b) => (
         b.name.split(/[a-zA-Z]/g).pop() - a.name.split(/[a-zA-Z]/g).pop()
      )
      )
      setTypeSorted(sortedData)
      setTimeout(() => {
         setLoading(false)
      }, 700)
   }

   // ORDENA LAS FILAS POR REFERENCIAS
   // ! ======= AJUSTAR CASOS - NO ORDENA CORRECTAMENTE =======
   function sortByReference(data) {
      setLoading(true)

      const newItem = data.map((item, i) => (
         // eslint-disable-next-line no-useless-escape
        {...item, reference: item.ref.split(/[0-9\/]+[A-Z0-9- ]+-/g).pop() }
      ))

      const sortedData = newItem.sort((a, b) => {
         if (a.reference < b.reference) {
            return -1;
         }
         if (a.reference > b.reference) {
            return 1;
         }
         return 0;
      })
      setTypeSorted(sortedData)
      setTimeout(() => {
         setLoading(false)
      }, 700)
   }

   function sortByType(data) {
      setLoading(true)

      const newItem = data.map((item, i) => (
         // eslint-disable-next-line no-useless-escape
        {...item, type_id_name: item.type_id_name.split(/[0-9\/]+[A-Z0-9- ]+-/g).pop() }
      ))

      const sortedData = newItem.sort((a, b) => {
         if (a.type_id_name < b.type_id_name) {
            return -1;
         }
         if (a.type_id_name > b.type_id_name) {
            return 1;
         }
         return 0;
      })
      setTypeSorted(sortedData)
      setTimeout(() => {
         setLoading(false)
      }, 700)
   }

   // ORDENA POR RESPONSABLE
   function sortByResponsible(data) {
      setLoading(true)

      const sortedData = data.sort((a, b) => (
         sortStringsBackwards(a.responsible, b.responsible)
      ))
      
      setTypeSorted(sortedData)
      setTimeout(() => {
         setLoading(false)
      }, 700)
   }

   // ORDENA POR ESTADO
   function sortByState(data) {
      setLoading(true)

      const sortedData = data.sort((a, b) => (
         sortStringsBackwards(a.initialState, b.initialState)
      ))

      setTypeSorted(sortedData)
      setTimeout(() => {
         setLoading(false)
      }, 700)
   }

   // ORDENA POR MONTO (SIN IMPORTAR LA DIVISA)
   function sortByAmount(data) {
      setLoading(true)

      const sortedData = data.sort((a, b) => a.amount - b.amount)

      setTypeSorted(sortedData)
      setTimeout(() => {
         setLoading(false)
      }, 700)
   }

   return {
      open,
      setOpen,
      columnType,
      setColumnType,
      typeSorted,
      handleOpenList,
      handleCloseList,
      loading
   }
}

export default useSortSettlements