import styled from "styled-components";

const InfoSection = (props) => {

    const { type = "acropolis" } = props;

    return (
        <Container type={type}>
            <h2>¿Cómo acceder al beneficio?</h2>
            <p>
                Se dará un bono en cash por PayPal a quienes den <b>referidos matriculados. </b> 
                Para recibir el pago, el referido <b>debe abonar la segunda cuota de su programa.</b>
            </p>
            <h2>Términos y condiciones</h2>
            <p>
                Para ser considerado apto en este programa de referidos, <b>la persona recomendada debe ser nueva en el Grupo Educativo ADEN</b>,
                es decir, no debe estar registrada previamente ni haber tenido algún contacto o relación, como ser alumno, empleado o haber iniciado
                un proceso de inscripción en cualquier programa ofrecido por ADEN. <b>No aplica a quienes se matriculen por medio de la Beca Globo Común.</b>
            </p>
            <p>
                El programa está abierto únicamente para aquellos <b>referentes que sean alumnos activos o que hayan egresado de ADEN. </b>
                Las recompensas monetarias se procesarán <b>exclusivamente a través de PayPal,</b> y para que el referente sea elegible para recibir su recompensa,
                <b> la persona recomendada debe haber realizado el pago de su segunda cuota académica.</b>
            </p>
            <p>
                Además, <b>esta última obtendrá un descuento porcentual (%) en el costo de su programa académico.</b> Cabe destacar que ADEN se reserva el derecho
                a cualquier momento de modificar o terminar el programa sin previo aviso.
            </p>
        </Container>
    )
}

export default InfoSection;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: ${(props) => props.type === "landing" && "0 14rem"};

    h2 {
        font-size: 24px;
        color: #b31d15;
    }

    p {
        font-size: 20px;
        color: #222222;
    }
`;