import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

// Material UI
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';

const QuestionReview = (props) => {

    const { type, question, resQuestion, number } = props;

    // States
    const [value, setValue] = useState(null);

    // Effects
    useEffect(() => {
        if (resQuestion && value === null && type !== 'multi_opcion') {
            setValue(resQuestion?.respuestas_usuario[0]);
        }
        if (resQuestion && value === null && type === 'multi_opcion') {
            setValue(resQuestion?.respuestas_usuario);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // Functions
    const getCheckedStudent = (id) => {
        let result = false;
        resQuestion?.respuestas_usuario?.forEach((question_id) => {
            if (question_id === id) {
                result = true;
            }
        });
        return result;
    };

    // Functions material hover

    const [anchorEl, setAnchorEl] = useState(null);
    const [valueContent, setValueContent] = useState('');

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setValueContent(event.currentTarget.textContent);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return String(html).replace(regex, 'src="');
    };

    const open = Boolean(anchorEl);

    return (
        <Container>
            <Header>
                <Title>Pregunta {number}</Title>
            </Header>
            <Body>
                <Question>{parse(sanitizerHtml(question?.pregunta))}</Question>
                <Answers>
                    {type === 'unica_opcion' && (
                        <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={value}
                        >
                            {question?.opciones?.map((opt) => {
                                return (
                                    <AnswerContainer>
                                        <TypographyFake
                                            isCorrect={opt?.es_opcion_correcta}
                                            aria-owns={
                                                open
                                                    ? 'mouse-over-popover'
                                                    : undefined
                                            }
                                            aria-haspopup="true"
                                        >
                                            <div>
                                                <Response>
                                                    <FakeLabelRadio
                                                        onClick={handlePopoverOpen}
                                                        value={opt?.opcion_id}
                                                        control={
                                                            <FakeRadio
                                                                color={'primary'}
                                                            />
                                                        }
                                                        htmlFor={opt?.opcion}
                                                        label={opt?.opcion}
                                                    />
                                                </Response>
                                                {
                                                    opt?.es_opcion_correcta &&
                                                    <Chip isCorrect={opt?.es_opcion_correcta}>
                                                        <Icon icon="streamline:check" width="15px" height="15px" />
                                                        <label>
                                                            Correcta
                                                        </label>
                                                    </Chip>
                                                }
                                            </div>
                                            {
                                                ((opt?.opcion_id !== value) && opt?.es_opcion_correcta && opt?.comentario) &&
                                                <AnswerClarification>
                                                    <p>
                                                        {parse(sanitizerHtml(opt?.comentario))}
                                                    </p>
                                                </AnswerClarification>
                                            }
                                        </TypographyFake>
                                    </AnswerContainer>
                                );
                            })}
                            <Popover
                                id="mouse-over-popover"
                                sx={{
                                    pointerEvents: 'none'
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                                onClose={handlePopoverClose}
                            >
                                <TypographyHover sx={{ p: 1 }}>
                                    {valueContent}
                                </TypographyHover>
                            </Popover>
                        </RadioGroup>
                    )}
                    {type === 'verdadero_falso' && (
                        <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={value}
                        >
                            {question?.opciones?.map((opt) => {
                                return (
                                    <>
                                        <AnswerContainer>
                                            <TypographyFake
                                                isCorrect={opt?.es_opcion_correcta}
                                                aria-owns={
                                                    open
                                                        ? 'mouse-over-popover'
                                                        : undefined
                                                }
                                                aria-haspopup="false"
                                                onClick={handlePopoverOpen}
                                            >
                                                <div>
                                                    <Response>
                                                        <FakeLabelRadio
                                                            value={opt?.opcion_id}
                                                            control={
                                                                <FakeRadio
                                                                    color={
                                                                        'primary'
                                                                    }
                                                                />
                                                            }
                                                            label={opt?.opcion}
                                                        />
                                                    </Response>
                                                    {
                                                        opt?.es_opcion_correcta &&
                                                        <Chip isCorrect={opt?.es_opcion_correcta}>
                                                            <Icon icon="streamline:check" width="15px" height="15px" />
                                                            <label>
                                                                Correcta
                                                            </label>
                                                        </Chip>
                                                    }
                                                </div>
                                                {
                                                    ((opt?.opcion_id !== value) && opt?.es_opcion_correcta && opt?.comentario) &&
                                                    <AnswerClarification>
                                                        <p>
                                                            {parse(sanitizerHtml(opt?.comentario))}
                                                        </p>
                                                    </AnswerClarification>
                                                }
                                            </TypographyFake>
                                            <Popover
                                                id="mouse-over-popover"
                                                sx={{
                                                    pointerEvents: 'none'
                                                }}
                                                open={open}
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                            >
                                                <TypographyHover
                                                    colors={opt?.es_opcion_correcta}
                                                    sx={{ p: 1 }}
                                                >
                                                    {opt?.opcion}
                                                </TypographyHover>
                                            </Popover>
                                        </AnswerContainer>
                                    </>
                                );
                            })}
                        </RadioGroup>
                    )}
                    {type === 'multi_opcion' && (
                        <CheckContainer>
                            {question?.opciones?.map((opt) => {
                                return (
                                    <AnswerContainer>
                                        <TypographyFake
                                            isCorrect={opt?.es_opcion_correcta}
                                            aria-owns={
                                                open
                                                    ? 'mouse-over-popover'
                                                    : undefined
                                            }
                                            aria-haspopup="false"
                                            onClick={handlePopoverOpen}
                                        >
                                            <div>
                                                <Response>
                                                    <FakeLabelCheck
                                                        checked={getCheckedStudent(
                                                            opt?.opcion_id
                                                        )}
                                                        control={
                                                            <FakeCheckbox
                                                                name={opt?.opcion_id}
                                                                value={opt?.opcion_id}
                                                                color={'primary'}
                                                            />
                                                        }
                                                        label={opt?.opcion}
                                                    />
                                                    <Popover
                                                        id="mouse-over-popover"
                                                        sx={{
                                                            pointerEvents: 'none'
                                                        }}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left'
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left'
                                                        }}
                                                        onClose={handlePopoverClose}
                                                        disableRestoreFocus
                                                    >
                                                        <TypographyHover
                                                            colors={opt?.es_opcion_correcta}
                                                            sx={{ p: 1 }}
                                                        >
                                                            {opt?.opcion}
                                                        </TypographyHover>
                                                    </Popover>
                                                </Response>
                                                {
                                                    opt?.es_opcion_correcta &&
                                                    <Chip isCorrect={opt?.es_opcion_correcta}>
                                                        <Icon icon="streamline:check" width="15px" height="15px" />
                                                        <label>
                                                            Correcta
                                                        </label>
                                                    </Chip>
                                                }
                                            </div>
                                            {
                                                ((opt?.opcion_id !== value) && opt?.es_opcion_correcta && opt?.comentario) &&
                                                <AnswerClarification>
                                                    <p>
                                                        {parse(sanitizerHtml(opt?.comentario))}
                                                    </p>
                                                </AnswerClarification>
                                            }
                                        </TypographyFake>
                                    </AnswerContainer>
                                );
                            })}
                        </CheckContainer>
                    )}
                </Answers>
            </Body>
        </Container>
    );
};

export default QuestionReview;

const Container = styled.div`
    width: 100%;
    min-height: 200px;
    margin: 1rem 0;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 30px);
    padding: 0 15px;
    height: 50px;
    background-color: #F1F1F1;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`;

const Title = styled.div`
    font-size: 1.2rem;
    color: #6b6b6b;
    font-weight: 700;
    padding: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const AnswerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TypographyFake = styled(Typography)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
    border-radius: 30px;
    padding: 2px 7px;
    border: 1px solid ${p => p.isCorrect ? '#35D0A5' : 'none'};

    div {
        display: flex;
        flex-direction: space-between;
        align-items: center;   
    }
`;

const TypographyHover = styled(Typography)`
    background-color: #222222;
    color: #fff;
    padding: 0.5rem;
    font-size: 1rem;
`;
const Question = styled.h4`
    margin-bottom: 15px;
`;

const Answers = styled.div`
    width: 100%;
`;

const Body = styled.div`
    width: calc(100% - 4rem);
    margin: 0 0 1rem 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 2rem;
    padding-bottom: 0;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
`;

const FakeRadio = styled(Radio)`
    transform: scale(0.7);
`;

const FakeLabelRadio = styled(FormControlLabel)`
    span {
        font-size: 0.8rem;
        cursor: pointer;
    }
`;

const FakeLabelCheck = styled(FormControlLabel)`
    span {
        font-size: 0.8rem;
        cursor: pointer;
    }
`;

const FakeCheckbox = styled(Checkbox)`
    transform: scale(0.7);
`;

const CheckContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Response = styled.div`
    width: 100%;
    display: flex;
`;

const Chip = styled.span`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 8px 16px 8px 8px;
    color: #28A986;
    border: 1px solid #28a986;
    border-radius: 100px;
    background-color: #F7FDFB;

    label {
        font-size: 12px;
        font-weight: 700;
    }
`

const AnswerClarification = styled.div`
    max-width: 100%;

    p {
        max-width: 50vw;
        padding: 0 1rem;
        overflow-wrap: break-word;
    }
`