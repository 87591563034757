import axios from 'axios';

export const getStudentAtRisk = async (student) => {
    const URL = `${window.location.origin}/mock/students.json`;

    try {
        const response = await axios.get(URL);
        const studentLog = response.data.response_data.students;
        return studentLog;
    } catch (error) {
        return { error };
    }
};
