import * as encode from "nodejs-base64-encode";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import SubjectAnnouncements from "../../../components/ui/organisms/subject/SubjectAnnouncements";
import SimpleLoading from "../../../components/common/SimpleLoading";
import SubjectCalifications from "./subjectTabs/SubjectCalifications";
import SubjectInstructors from "./subjectTabs/SubjectInstructors";
import MyDeliveries from "./subjectTabs/MyDeliveries";
import SubjectBoard from "./subjectTabs/SubjectBoard";
import SubjectNotes from "./subjectTabs/SubjectNotes";

// Hooks
import useTabHistory from "../../../hooks/useTabHistory";

const SubjectTabs = (props) => {
    const { subject, origin, programId, totalProgress, resetData } =
        props;

    const { handleTabHistory, initialTab } = useTabHistory();

    const descr = subject.information;

    const tabs = [
        { label: `Progreso asignatura`, id: 0 },
        { label: `Entregas`, id: 1 },
        { label: "Calificaciones", id: 2 },
        { label: "Apuntes", id: 3 },
        { label: "Cuerpo académico", id: 4 },
        { label: "Anuncios", id: 5 },
    ];

    const [tab, setTab] = useState(initialTab);

    const { id } = useParams();

    useEffect(() => {
        handleOnStudy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // FUNCTIONS
    const handleTab = (value) => {
        setTab(value);
        // Acá en vez de volver a armar todo la URL se puede recuperar la URL actual y cambiar solo el tab
        handleTabHistory(
            `${id}?program=${encode.encode(
                programId,
                "base64"
            )}&origin=${origin}&tab=${value}`
        );
    };    

    const mod = subject.courses;

    const handleOnStudy = () => {
        let arrayCourses = mod.sort((a, b) => a.order - b.order);

        for (let index = 0; index < arrayCourses.length; index++) {
            if (index === 0) {
                arrayCourses[index].onStudy = true;
            } else {
                if (
                    arrayCourses[index - 1].progress >= 100 ||
                    !!arrayCourses[index - 1]?.survey.done
                ) {
                    arrayCourses[index].onStudy = true;
                } else {
                    // COMENTADO PORQUE SEBA PIDIÓ QUE SE DESBLOQUEEN LOS BLOQUES EN LAS ASIGNATURAS PARA NO CAMBIAR TODA LA LÓGICA COMENTAMOS SOLO ESTA PARTE
                    // arrayCourses[index].onStudy = false;
                    arrayCourses[index].onStudy = true;
                }
            }
        }
    };

    function ToggleMain(props) {
        const { tab } = props;

        const cases = {
            0: <SubjectBoard
                subject={subject}
                totalProgress={totalProgress}
                origin={origin}
                resetData={resetData}
                programHash={encode.encode(programId,"base64")}
            />,
            1: <MyDeliveries />,
            2: <SubjectCalifications />,
            3: <SubjectNotes />,
            4: <SubjectInstructors descr={descr} />,
            5: <SubjectAnnouncements id={subject?.id} />,
        };
        return cases[String(tab)] || <SubjectBoard />;
    }

    //RETURN
    if (subject.length === 0) {
        return (
            <CardFake>
                <SimpleLoading />
            </CardFake>
        );
    }

    const Tabs = (props) => {
        const { tabValue, handleTabValue, tabOptions } = props;
    
        // RETURN
        return (
            <Container>
                {tabOptions.map((option) => (
                    <Tab
                        onClick={() => handleTabValue(option.id)}
                        tabValue={tabValue}
                        key={option.id}
                        active={tabValue === option.id}
                    >
                        {option.label}
                    </Tab>
                ))}
            </Container>
        );
    };

    return (
        <>
            <TabPosition>
                <Tabs tabValue={tab} handleTabValue={handleTab} tabOptions={tabs} />
            </TabPosition>
            <CardFake>
                <BodyWrapper>
                    <ToggleMain tab={tab} />
                </BodyWrapper>
            </CardFake>
        </>
    );
};
export default SubjectTabs;

const CardFake = styled.div`
    margin-top: 44px;

    @media (max-width: 768px) {
        margin-top: 70px;
    }
`;

const BodyWrapper = styled.div`
    padding: 0.5rem;
`;

const TabPosition = styled.div`
    position: absolute;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 2.5rem;
    left: -2rem;
    top: -32px;
    background-color: white;
    box-shadow: 0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F;

    @media (max-width: 1440px) {
        width: calc(100% - 1rem);
    }

    @media (max-width: 768px) {
        width: calc(100% - 3rem);
        padding-left: 2.5rem;
        left: -1rem;
        top: 5px;
    }
`;

const Container = styled.div`
	width:100%;
    display: flex;
    justify-content: start;
    align-items: center;
`;

const Tab = styled.button`
    position: relative;
    padding: 1rem;
    cursor: pointer;
    color: #222;
    font-size: 16px;
    font-weight: ${props => (props.active ? '700' : '500')};
    transition: color 0.3s;
    background-color: #fff;

    &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 2rem);
        border-radius: 100px 100px 0 0;
        height: 3px;
        background-color: ${props => (props.active ? '#b3261e' : 'transparent')};
        
    }
`;