import axios from "axios";

export async function updateComments(submissionId) {
    const URL = `https://repositorio-api.aden.org/api/v1/repo_aden/comment/entrega/${submissionId}/all`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
}
