import { Card } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { checkRoles } from '../../../../helpers/roles.helper';
import AdsBanner from '../common/AdsBanner';
import SliderMicrolearning from '../../../../components/ui/organisms/microlearnings/SliderMicrolearning';
import AdenEvents from '../../../../components/templates/campus/AdenEvents';
// import CardCumbre from "./CardCumbre";
import CardHome from '../../../../components/templates/campus/CardHome';
import InternationalWorkshop from '../../../../components/templates/campus/InternationalWorkshop';

const AsideCampus = () => {
    const { ondemand, resources } = useSelector((state) => state.ondemand);
    const { activeRol } = useSelector((state) => state.auth);
    const enable_event = !!ondemand ? ondemand?.enable_event : true;

    return (
        <Wrapper>
            {activeRol === 'ondemand' && <AdsBanner />}
            {/* Slider para ondemand */}
            {!!resources && activeRol === 'ondemand' && (
                <CardFake h="440px">
                    <SliderMicrolearning resources={resources} />
                </CardFake>
            )}
            {/* Evento Cumbre */}
            {/* <CardFake>
                <CardCumbre />
            </CardFake> */}
            {/* Eventos */}
            {enable_event && (
                <CardFake h="440px">
                    <AdenEvents />
                </CardFake>
            )}
            {/* Los workshops internacionales son solo para alumnos */}
            {activeRol === 'alumno' && (
                <CardFake h="440px">
                    <InternationalWorkshop />
                </CardFake>
            )}
            {/* Plan de evaluacion */}
            {activeRol === 'alumno' && (
                <CardFake h="440px">
                    <CardHome />
                </CardFake>
            )}
        </Wrapper>
    );
};

export default AsideCampus;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
`;

const CardFake = styled(Card)`
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
        0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    max-height: ${(p) => p.h};
    height: ${(p) => p.h};

    &.MuiCard-root {
        z-index: 10;
    }
`;
