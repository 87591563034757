import * as types from '../types';

const initialState = {
    directories: null,
    currentPage: 0,
    pagination: null,
    paginationLoading: false,
    errorPagination: null,
    listLoading: false,
    errorList: null
};

export const directories = (state = initialState, action) => {
    switch (action.type) {
        //Pagination
        case types.GET_PAGINATION_REQUEST:
            return {
                ...state,
                paginationLoading: true,
                errorPagination: null
            };
        case types.GET_PAGINATION_SUCCESS:
            const data = action.payload;
            const currentPage = data.contactos > 0 ? 1 : 0;
            const response = {
                totalPages: data.total_paginas,
                totalContacts: data.contactos,
                pages: data.paginas
            };
            return {
                ...state,
                currentPage: currentPage,
                paginationLoading: false,
                pagination: response,
                errorPagination: null
            };
        case types.GET_PAGINATION_FAIL:
            return {
                ...state,
                paginationLoading: false,
                errorPagination: action.payload
            };
        //Pagination Alab
        case types.GET_PAGINATIONALAB_REQUEST:
            return {
                ...state,
                paginationLoading: true,
                errorPagination: null
            };
        case types.GET_PAGINATIONALAB_SUCCESS:
            const dataAlab = action.payload;
            const currentPageAlab = dataAlab.contactos > 0 ? 1 : 0;
            const responseAlab = {
                totalPages: dataAlab.total_paginas,
                totalContacts: dataAlab.contactos,
                pages: dataAlab.paginas
            };
            return {
                ...state,
                currentPage: currentPageAlab,
                paginationLoading: false,
                pagination: responseAlab,
                errorPagination: null
            };
        case types.GET_PAGINATIONALAB_FAIL:
            return {
                ...state,
                paginationLoading: false,
                errorPagination: action.payload
            };
        //CurrentPage
        case types.NEXT_PAGE:
            return {
                ...state,
                currentPage: state.currentPage + 1
            };
        case types.PREVIOUS_PAGE:
            return {
                ...state,
                currentPage: state.currentPage - 1
            };
        case types.CHANGE_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        //Directories
        case types.GET_DIRECTORIES_REQUEST:
            return {
                ...state,
                directories: [],
                listLoading: true,
                errorList: null
            };
        case types.GET_DIRECTORIES_SUCCESS:
            const directoryData = action.payload.directorio;
            return {
                ...state,
                listLoading: false,
                directories: directoryData,
                errorList: null
            };
        case types.GET_DIRECTORIES_FAIL:
            return {
                ...state,
                listLoading: false,
                errorList: action.payload
            };
        //Directories Alab
        case types.GET_DIRECTORIESALAB_REQUEST:
            return {
                ...state,
                directories: [],
                listLoading: true,
                errorList: null
            };
        case types.GET_DIRECTORIESALAB_SUCCESS:
            const directoryAlabData = action.payload;
            return {
                ...state,
                listLoading: false,
                directories: directoryAlabData,
                errorList: null
            };
        case types.GET_DIRECTORIESALAB_FAIL:
            return {
                ...state,
                listLoading: false,
                errorList: action.payload
            };
        default:
            return state;
    }
};
