import React, { useEffect } from 'react';
import styled from 'styled-components';
import GenericChips from '../../../common/GenericChip';
import { Text } from '../../../common/Texts';

const CourseBanner = (props) => {
    const { status, aula, title, img = "https://random.imagecdn.app/500/160" } = props;

    return (
        <Banner img={img}>
            <DivChips>
                <GenericChips
                    title={aula}
                    color="#FFE6EB"
                    textColor="#FC2958"
                    padding="3px 6px"
                    fontSize=".7rem"
                />
                <GenericChips
                    title={status}
                    color="#5e80db"
                    textColor="#ffffff"
                    padding="3px 6px"
                    fontSize=".7rem"
                />
            </DivChips>
            <Text fontWeight="700" fontSize="36PX" color="#FFFFFF">
                {title}
            </Text>
        </Banner>
    );
};

export default CourseBanner;

const Banner = styled.div`
    background-image: url(${p => p.img});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 180px;
    padding: 1.5rem;
    display: grid;
    align-content: center;
    align-items: center;
`;
const DivChips = styled.div`
    position: relative;
    width: 8vw;
    z-index: 2;
    display: flex;
    gap: 10px;
    height: 30px;
`;
