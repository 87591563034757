import { Box, Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import SingleElectiveSelection from "./SingleElectiveSelection";
import MultiElectivesSelection from "./MultiElectivesSelection";
import CommonButton from "../../../common/CommonButton";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
};

const ElectiveSelectionModal = (props) => {
    const {
        open,
        handleClose,
        type,
        electives,
        setNameSelected,
        optionCheck,
        setOptionCheck,
        multiSelectedItems,
        handleMultiSelectionChange,
        electivesFormated,
        setHasCourseInPerson
    } = props;

    const handleCheck = (option, name, hasInCoursePerson) => {
        setOptionCheck(option);
        setNameSelected(name);
        setHasCourseInPerson(hasInCoursePerson);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <CustomBox sx={style}>
                    <Header>
                        <div></div>
                        <p>Biblioteca de electivas</p>
                        <CloseIcon onClick={handleClose} />
                    </Header>
                    <Container>
                        {type === "main" ? (
                            <Wrapper>
                                {electives.map((elective) => (
                                    <SingleElectiveSelection
                                        key={elective.id}
                                        elective={elective}
                                        setNameSelected={setNameSelected}
                                        optionCheck={optionCheck}
                                        handleCheck={handleCheck}
                                        multiSelectedItems={multiSelectedItems}
                                    />
                                ))}
                                <CommonButton
                                    variant="filled"
                                    onClick={handleClose}
                                    label="Seleccionar electiva principal"
                                />
                            </Wrapper>
                        ) : (
                            <Wrapper>
                                <MultiElectivesSelection
                                    electives={electives}
                                    multiSelectedItems={multiSelectedItems}
                                    electivesFormated={electivesFormated}
                                    optionCheck={optionCheck}
                                    handleMultiSelectionChange={
                                        handleMultiSelectionChange
                                    }
                                />
                                <CommonButton
                                    variant="filled"
                                    onClick={handleClose}
                                    label="Seleccionar electivas complementarias"
                                />
                            </Wrapper>
                        )}
                    </Container>
                </CustomBox>
            </Modal>
        </div>
    );
};

export default ElectiveSelectionModal;

const CustomBox = styled(Box)`
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
    outline: none;
    overflow: hidden;

    h2 {
        color: #b31d15;
    }

    p,
    span {
        color: #222;
        font-size: 1rem;
    }
    span {
        font-weight: bold;
    }

    @media (width < 1068px) {
        max-width: 90vw;
    }
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #b31d15;
    box-sizing: border-box;
    padding: 1rem;

    p {
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
    }

    svg {
        cursor: pointer;
        color: #fff;
    }
`;

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-sizing: border-box;
    padding: 0 4px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    align-items: flex-end;
`;