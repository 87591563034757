import React from 'react';

import InputEditProfile from './InputEditProfile';

const AcademicModalEditProfile = (props) => {
    const {
        currentTab,
        values,
        setFieldValue,
        setInfoComplete,
        handleChange,
        ws,
        setWsSelect,
        setCompanions,
        wsSelect,
        companions,
        id
    } = props;

    // RETURN
    if (currentTab === 0) {
        return (
            <InputEditProfile
                values={values}
                setFieldValue={setFieldValue}
                setInfoComplete={setInfoComplete}
                handleChange={handleChange}
                ws={ws}
                id={id}
                setWsSelect={setWsSelect}
                wsSelect={wsSelect}
                setCompanions={setCompanions}
                companions={companions}
            />
        );
    }

    return null;
};

export default AcademicModalEditProfile;
