import * as types from '../types';

const initialState = {
    workshop: null,
    workshops: null,
    loading: false,
    listLoading: false,
    error: null,
    errorList: null
};

export const workshops = (state = initialState, action) => {
    switch (action.type) {
        // GET WORKSHOPS
        case types.GET_WORKSHOPS_REQUEST:
            return {
                ...state,
                listLoading: true,
                errorList: null
            };
        case types.GET_WORKSHOPS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                workshops: action.payload,
                errorList: null
            };
        case types.GET_WORKSHOPS_FAIL:
            return {
                ...state,
                listLoading: false,
                errorList: action.payload
            };
        // GET WORKSHOP BY ID
        case types.GET_WORKSHOPBYID_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case types.GET_WORKSHOPBYID_SUCCESS:
            return {
                ...state,
                loading: false,
                workshop: action.payload,
                error: null
            };
        case types.GET_WORKSHOPBYID_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case types.SET_LIKE_WS:
            const workshopsLike = state.workshops.map((workshop) =>
                workshop.repo_id !== action.payload
                    ? workshop
                    : { ...workshop, favorito: true }
            );
            return {
                ...state,
                workshops: workshopsLike
            };
        case types.SET_NOLIKE_WS:
            const workshopsNoLike = state.workshops.map((workshop) =>
                workshop.repo_id !== action.payload
                    ? workshop
                    : { ...workshop, favorito: false }
            );
            return {
                ...state,
                workshops: workshopsNoLike
            };
        default:
            return state;
    }
};
