import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";

// Components
import CommonButton from '../../../../../components/common/CommonButton';
import MediaCapturer from 'react-multimedia-capture';

const VideoRecorder = (props) => {

	const { handleSendVideo, setOpenModal, videoBlob, setVideoBlob, videoUrl, setVideoUrl } = props;

	const [recording, setRecording] = useState(false);

	const [saving, setSaving] = useState(false);
	const videoRef = useRef(null);
	const videoRefPreview = useRef(null);

	useEffect(() => {
		handlePreview();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoBlob, videoUrl]);

	const handleRequest = () => {
		console.log('Request Recording...');
	};

	const handleGranted = () => {
		console.log('Permission Granted!');
	};

	const handleDenied = (err) => {
		console.log('Permission Denied!', err);
	};

	const handleStart = (stream) => {
		setRecording(true);
		setVideoUrl("");
		setStreamToVideo(stream);
		console.log('Recording Started.');
	};

	const handleStop = (blob) => {
		setSaving(false);
		setVideoBlob(blob);
		releaseStreamFromVideo();
	};

	const handlePause = () => {
		releaseStreamFromVideo();
	};

	const handleResume = (stream) => {
		setStreamToVideo(stream);
	};

	const handleError = (err) => {
		console.log(err);
	};

	const handleStreamClose = () => {
	};

	const setStreamToVideo = (stream) => {
		const video = videoRef.current;
		if (window.URL) {
			video.srcObject = window.URL.createObjectURL(stream);
		} else {
			video.src = stream;
		}
	};

	const setStreamPreview = (stream) => {
		const video = videoRefPreview.current;
		video.srcObject = stream;
	};

	const releaseStreamFromVideo = () => {
		const video = videoRef.current;
		video.src = '';
	};

	const handlePreview = () => {
		navigator.mediaDevices.getUserMedia({ video: true })
			.then(stream => {
				setStreamPreview(stream);
			})
			.catch(err => {
				console.log('Error accessing media devices.', err);
			});
	};

	const saveVideo = () => {
		handleSendVideo(videoBlob);
		setOpenModal(false)
	}

	return (
		<Container>
		{
				videoBlob !== "" ?
					<>
						<video src={URL.createObjectURL(videoBlob)} controls></video>
						<CommonButton style={{ margin: "8px" }} variant="outlined" label="Borrar video" onClick={() => {
							setVideoBlob("")
							releaseStreamFromVideo();
						}} />
					</>
					:
					(videoUrl !== "") ?
						<>
							<video src={videoUrl} controls></video>
							<CommonButton style={{ margin: "8px" }} variant="outlined" label="Borrar video" onClick={() => {
								setVideoUrl("")
							}} />
						</>
						:
						<video ref={videoRefPreview} autoPlay></video>
			}
			<MediaCapturer
				constraints={{ audio: true, video: true }}
				timeSlice={10}
				onRequestPermission={handleRequest}
				onGranted={handleGranted}
				onDenied={handleDenied}
				onStart={handleStart}
				onStop={handleStop}
				onPause={handlePause}
				onResume={handleResume}
				onError={handleError}
				onStreamClosed={handleStreamClose}
				render={({ request, start, stop, pause, resume }) => (
					<>
						{
							saving
								? <CommonButton variant="filled" label="Guardando..." />
								: <ActionsButtons>
									{
										recording ?
											<RecordingContainer>
												<CommonButton variant="outlined" onClick={() => {
													setRecording(false);
													setSaving(true);
													setTimeout(() => {
														stop()
													}, 500)
												}}
													label="Detener" />
											</RecordingContainer>
											:
											<>
												{
													videoBlob === "" && <CommonButton disabled={videoUrl !== ""} variant="filled" label="Empezar grabación" onClick={start} />
												}
												<CommonButton variant="outlined" label="Cancelar" onClick={() => setOpenModal(false)} />
												{
													!!videoBlob && <>
														<CommonButton variant="filled" label="Guardar" onClick={saveVideo} />
													</>
												}
											</>
									}
									<GhostVideo ref={videoRef} autoPlay></GhostVideo>
								</ActionsButtons>
						}
					</>
				)}
			/>
		</Container>
	);
};

export default VideoRecorder;

const Container = styled.div`
	width: fit-content;
	height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	padding: 1rem;

	video {
		min-width: 640px;
		max-width: 640px;
		min-height: 480px;
	}
`

const GhostVideo = styled.video`
	display: none;	
`

const ActionsButtons = styled.div`
	display: flex;
	gap: 8px;
`

const RecordingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.25em;
    border-radius: 0.25em;
    width: 85px;
`
