export const lastResourceAdapter = (data) => {
    const lastOpenSubjectData = data?.last_open_subject_data;
    const lastOpenedOaData = data?.last_open_oa_data;

    return {
        lastOpenedSubjectData: {
            subjectId: lastOpenSubjectData?.id,
            name: lastOpenSubjectData?.name,
            totalProgress: lastOpenSubjectData?.total_progress,
            image: lastOpenSubjectData?.image,
            subjectState: {
                toStart: lastOpenSubjectData?.state_course_oa?.to_be_started,
                inProgress: lastOpenSubjectData?.state_course_oa?.in_progress,
                finished: lastOpenSubjectData?.state_course_oa?.finished,
            },
        },
        lastOpenedOAData: {
            id: lastOpenedOaData?.id,
            name: lastOpenedOaData?.name,
            totalProgress: lastOpenedOaData?.total_progress,
            image: lastOpenedOaData?.image,
            lastOpenedResource: {
                id: lastOpenedOaData?.last_open_resource?.id,
                name: lastOpenedOaData?.last_open_resource?.name,
                type: lastOpenedOaData?.last_open_resource?.type,
                unityId: lastOpenedOaData?.last_open_resource?.unity_id,
                unityName: lastOpenedOaData?.last_open_resource?.unity_name,
                publicationName:
                    lastOpenedOaData?.last_open_resource?.publication_name,
            },
        },
    };
};
