import React from 'react';
import styled from 'styled-components';

import Text from '../../../common/Text';
import TicketForm from '../../molecules/tickets/TicketForm';

const NewTicket = () => {
    return (
        <Container>
            <Text fontSize="1.25rem" fontWeight="700" component="h1">
                ¡Bienvenido a nuestro sistema PQRS!
            </Text>
            <Text fontSize="1rem" component="p">
                A través de este sistema podrás dejarnos tus peticiones, quejas,
                reclamos o felicitaciones. Completa el formulario, envíalo y
                recibe tu ticket de seguimiento.
            </Text>
            {/** Formulario */}
            <TicketForm />
            {/** Modal exito */}
        </Container>
    );
};

export default NewTicket;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 2rem;
`;
