import React from "react";
import styled from "styled-components";
import { Img } from "../../common/Image";
// import Text from "../../common/Text";
import AuthComponent from "../../ui/organisms/auth/AuthComponent";
// import bds from "../../../assets/media/ic/bds.png";
// import lacthosa from "../../../assets/media/ic/lacthosa.png";
// import uber from "../../../assets/media/ic/uber.png";
import { ReactComponent as ReactLogo } from '../../../assets/media/aden/acropolis_lab_horizontal_color.svg'

const AuthFormLab = (props) => {

    const { primaryColor, secondaryColor, logoAlab, logoAlabColor } = props

    return (
        <AuthFormWrapper color={secondaryColor}>
            {/* {window.location.origin.includes("uber") ? (
                <WrapperLogos>
                    <ImgFake src={uber} />
                    <ImgFake src="/assets/aden/acropolis_lab_horizontal_color.svg" />
                </WrapperLogos>
            ) :
            window.location.origin.includes("lacthosa") ? (
                <WrapperLogos>
                    <ImgFake src={lacthosa} />
                    <ImgFake src="/assets/aden/acropolis_lab_horizontal_color.svg" />
                </WrapperLogos>
            ) :
                window.location.origin.includes("bds") ? (
                <WrapperLogos>
                    <ImgFake src={bds} />
                    <ImgFake src="/assets/aden/acropolis_lab_horizontal_color.svg" />
                </WrapperLogos>
            ) :
            window.location.origin.includes("repretel") ? (
                <WrapperLogos>
                    <ImgFake src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Repretel.png/640px-Repretel.png" />
                    <ImgFake src="/assets/aden/acropolis_lab_horizontal_color.svg" />
                </WrapperLogos>
            ) :
            window.location.origin.includes("mastercard") ? (
                <WrapperLogos>
                    <ImgFake src="/media/logos/mastercard-logo.svg" />
                    <ImgFake src="/assets/aden/acropolis_lab_horizontal_color.svg" />
                </WrapperLogos>
            ) : (
                <ImgFake src="/assets/aden/acropolis_lab_horizontal_color.svg" />
            )} */}
            <ImgFake src={logoAlab} />
            <ReactLogoStyled color={logoAlabColor}/>
            <AuthComponent isLab={true} primaryColor={primaryColor}/>
            <div></div>
        </AuthFormWrapper>
    );
};

export default AuthFormLab;

const AuthFormWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    background-color: ${p => p.color};
    @media (max-width: 768px) {
        padding: 3px;
        width: calc(100% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }
`;

const ReactLogoStyled = styled(ReactLogo)`
    width: 400px;
    fill: ${p => p.color} !important;
	.st0{
        fill: ${p => p.color} !important;
    }
	.st1{
        fill: ${p => p.color} !important;
    }
    /* filter: invert(1) saturate(0%); */
`

const ImgFake = styled(Img)`
    width: 250px;
    @media (max-width: 768px) {
        width: 200px;
    }
`;

// const WrapperLogos = styled.div`
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     justify-content: center;
//     gap: 1rem;
//     width: 100%;

//     img {
//         width: 250px;
//     }
// `;
