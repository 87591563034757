import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Card } from '@mui/material';
import AdenEvents from '../../../../components/templates/campus/AdenEvents';
import SubjectResume from './SubjectResume';
import MyReferralsCard from './MyReferralsCard';
import ProgramsSlider from '../common/ProgramsSlider';

const DirectAccess = () => {
    const { ondemand, resources } = useSelector((state) => state.ondemand);
    const { activeRol } = useSelector((state) => state.auth);

    const enable_event = !!ondemand ? ondemand?.enable_event : true;

    return (
        <Wrapper>
            <ResponsiveResume>
                <SubjectResume />
            </ResponsiveResume>
            <ContentWrapper eventsEnabled={enable_event}>
                <ProgressWrapper>
                    <ResumeDesktop>
                        <SubjectResume />
                    </ResumeDesktop>
                    <MyReferralsCard />

                    {/* Programas - Solo alumnos */}
                    <ProgramsSlider />
                </ProgressWrapper>

                {enable_event && <AdenEvents />}
            </ContentWrapper>
        </Wrapper>
    );
};

export default DirectAccess;

const Wrapper = styled.div`
    width: 100%;
`;

const ContentWrapper = styled.div`
    box-sizing: border-box;
    ${({ eventsEnabled }) =>
        eventsEnabled
            ? `display: grid;
               grid-template-columns: 3fr 1fr;
               grid-template-rows: auto;
               column-gap: 25px;`
            : 'display: inline-block;'}
    margin-top: 15px;
    z-index: 10;
    height: fit-content;

    @media (max-width: 1024px) {
        height: fit-content;
        ${({ eventsEnabled }) =>
            eventsEnabled
                ? `display: grid;
               grid-template-columns: 1fr 1fr;
               grid-template-rows: auto;
               column-gap: 25px;`
                : 'display: inline-block;'}
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const ProgressWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    column-gap: 36px;
    row-gap: 22px;
    height: fit-content;

    @media (max-width: 1440px) {
        column-gap: 24px;
    }

    @media (max-width: 1260px) {
        align-items: flex-end;
    }
`;

const ResumeDesktop = styled.div`
    flex-grow: 1;
    flex-basis: 300px;
    @media (max-width: 1024px) {
        display: none;
    }
`;

const ResponsiveResume = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: block;
    }
`;
