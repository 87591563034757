import { useContext, useState, useEffect } from "react";
import { SummitContext } from "../../../../contexts";
import styled from "styled-components";
import { Text } from "../../../../components/common/Texts";
import ThemeAccordion from "./ThemeAccordion";
import { fakeArrayItems } from "../../../../utils/arrays";
import SkeletonLoading from "../../../../components/common/SkeletonLoading";

const SummitTheme = () => {
  const { themes } = useContext(SummitContext);

  const STAGES_DESCRIPTIONS = [
    {
      name: "TrainCamp",
      description:
        "Cursos de Impacto Online, como fase de entrenamiento y absorción de conocimiento para las etapas siguientes.",
    },
    {
      name: "ArenaCamp",
      description:
        "Clase Presencial lúdica en sede, en conexión con todos los países de Latam, como fase de competición y puesta en marcha del conocimiento.",
    },
    {
      name: "ImpactCamp",
      description:
        "Jornada Lúdica Presencial en sede, como fase de ejecución e impacto del conocimiento adquirido.",
    },
  ];

  if (!themes) {
    return <SkeletonLoading height={420} width="100%" />;
  }

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Text color="#ffffff" fontSize="24px" fontWeight="500">
            Personaliza tu trayectoria - Ejes temáticos Cumbre
          </Text>
        </TitleWrapper>
      </Header>
      <Body>
        <StagesWrapper>
          <Text color="#ffffff" fontSize="16px" fontWeight="400">
            Te invitamos a que selecciones unos de los siguientes ejes. Cada uno
            estará compuesto por tres etapas:
          </Text>
          <UnorderedList>
            {STAGES_DESCRIPTIONS.map((stage) => (
              <ListItem>
                <Text color="#ffffff" fontSize="16px" fontWeight="300">
                  <b>{stage.name}</b> : {stage.description}
                </Text>
              </ListItem>
            ))}
          </UnorderedList>
        </StagesWrapper>

        <ThemesWrapper>
          {themes.map((theme, i) => (
            <ThemeAccordion key={i} theme={theme} />
          ))}
        </ThemesWrapper>
      </Body>
    </Wrapper>
  );
};

export default SummitTheme;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 2rem;
  border-radius: 20px;
  background-color: #b31d15;
  box-shadow: 0px 0px 10px -0px rgba(0, 0, 0, 0.25),
    0px 12px 24px -15px rgba(0, 0, 0, 0.1);
`;

const Header = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    margin: auto;
    bottom: -20px;
  }
`;

const TitleWrapper = styled.div``;

const Body = styled.section`
  display: grid;
  row-gap: 2.5rem;
  margin-top: 2rem;
`;

const StagesWrapper = styled.div`
  display: grid;
  row-gap: 1rem;
`;

const UnorderedList = styled.ul`
  display: grid;
  row-gap: 0.3rem;
  margin-left: 1.5rem;
`;

const ListItem = styled.li`
  &::marker {
    color: #ffffff;
    font-size: 24px;
  }
`;

const ThemesWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
`;
