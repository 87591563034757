import { useState } from "react";
import styled from "styled-components";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../../../styled-components";
import { Button } from "@mui/material";
import { KeyboardArrowDown, Block, Check } from "@mui/icons-material";
import { Text } from "../../../../components/common/Texts";
import CampCard from "./CampCard";
import useLocalStorage from "use-local-storage";

const ThemeAccordion = (props) => {
  const { theme } = props;

  const [storagedValue, setStoragedValue] = useLocalStorage("theme_id", "");

  const [open, setOpen] = useState(false);

  const handleAccordion = () => {
    setOpen(!open);
  };

  const handleSelectedTheme = (e, id) => {
    e.stopPropagation();
    setStoragedValue(id);
  };

  return (
    <Wrapper>
      <StyledAccordion
        bg={
          !(storagedValue === theme?.id) && !!storagedValue.length
            ? "#f1f1f1"
            : "#ffffff"
        }
        onClick={handleAccordion}
      >
        <StyledAccordionSummary
          bg={
            !(storagedValue === theme?.id) && !!storagedValue.length
              ? "#f1f1f1"
              : "#ffffff"
          }
        >
          <Content>
            <Text color="#ACACAC" fontSize="14px" fontWeight="400">
              EJE TEMÁTICO
            </Text>
            <TitleWrapper>
              <Text
                color="#B31D15"
                fontSize="20px"
                fontWeight="700"
                title={theme.title}
              >
                {theme.title.toUpperCase()}
              </Text>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text color="#616161" fontSize="14px" fontWeight="400">
                {theme.description}
              </Text>
            </DescriptionWrapper>
            <FloatingContent>
              {!storagedValue.length ? (
                <>
                  {/* <SelectButton
                    variant="contained"
                    onClick={(e) => handleSelectedTheme(e, theme?.id)}
                  >
                    Seleccionar eje
                  </SelectButton> */}
                  <ArrowIcon open={open} />
                </>
              ) : storagedValue === theme?.id ? (
                <>
                  <SelectedWrapper>
                    <Check />
                    <Text color="#28A986" fontSize="14px" fontWeight="400">
                      Eje Seleccionado
                    </Text>
                  </SelectedWrapper>
                  <ArrowIcon open={open} />
                </>
              ) : (
                <>
                  <BlockedWrapper>
                    <Block />
                    <Text color="#616161" fontSize="14px" fontWeight="400">
                      Eje bloqueado
                    </Text>
                  </BlockedWrapper>
                  <ArrowIcon open={open} />
                </>
              )}
            </FloatingContent>
          </Content>
        </StyledAccordionSummary>
        <StyledAccordionDetails
          bg={
            !(storagedValue === theme?.id) && !!storagedValue.length
              ? "#f1f1f1"
              : "#ffffff"
          }
        >
          <CardsWrapper>
            {theme?.camps?.map((camp, i) => (
              <CampCard
                blocked={storagedValue === theme?.id}
                blockLearningObject={!storagedValue.length}
                camp={camp}
                key={i}
              />
            ))}
          </CardsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Wrapper>
  );
};

export default ThemeAccordion;

const Wrapper = styled.div``;

const Content = styled.div`
  position: relative;
  padding: 0.4rem;
  display: grid;
  row-gap: 1rem;
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  height: 50px;
  overflow-y: auto;
`;

const FloatingContent = styled.div`
  position: absolute;
  margin: auto;
  right: 0;
  top: 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 2rem;
`;

const SelectButton = styled(Button)`
  border-radius: 30px;
  width: 170px;
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ArrowIcon = styled(KeyboardArrowDown)`
  transform: rotate(${(p) => (p.open ? "180deg" : "0deg")});
  transition: 0.4s all ease;
`;

const SelectedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 24px;
    height: 24px;
    color: #28a986;
  }
`;

const BlockedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 24px;
    height: 24px;
    color: #616161;
  }
`;
