import styled from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";
import parse from 'html-react-parser';

const PublicProfileInfo = (props) => {

    const {
        name,
        academicFormation,
        profession,
        teachingAreas
    } = props;

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return html.replace(regex, 'src="');
    };


    return (
        <Container>
            <Header>
                <h1>{name}</h1>
                <h2>{profession}</h2>
            </Header>
            <Body>
                <h3>Formación académica</h3>
                <AcademicFormation>
                    {
                        !!academicFormation &&
                        <>
                            {parse(sanitizerHtml(academicFormation))}
                        </>
                    }
                </AcademicFormation>
            </Body>
            <Footer>
                <h3>Áreas de enseñanza</h3>
                <GridCards>
                    {
                        teachingAreas.map((program, i) => (
                            <Card disabled={!program.programURL} key={i}>
                                <span>{program.programName}</span>
                                <a href={program.programURL} target="_blank" rel="noreferrer">
                                    <Icon icon="solar:arrow-right-linear" width="24px" height="24px" />
                                </a>
                            </Card>
                        ))
                    }
                </GridCards>
            </Footer>
        </Container>
    );
}

export default PublicProfileInfo;

const Container = styled.div`
    width: 75%;
    min-height: 100%;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    background-color: #fff;
    border-radius: 30px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 2rem;

    @media (width < 1000px) {
        width: calc(100% - 4rem);
    }
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid #A8A8A8;
    padding-bottom: 10px;

    h1 {
        font-size: 28px;
        font-weight: 900;
        color: #b31d15;
    }

    h2 {
        font-size: 20px;
        font-weight: 700;
        color: #222222;
        font-style: italic;
    }
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
    h3 {
        font-size: 20px;
        font-weight: 700;
        color: #222222;
    }

    ul {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
            font-size: 15px;
            font-weight: 400;
            color: #222222;
        }
    }
`

const AcademicFormation = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #222222;

    li {
        padding-top: 8px;
    }

    ul, ol {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100% !important;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h3 {
        font-size: 20px;
        font-weight: 700;
        color: #222222;
    }
`

const GridCards = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @media (width < 1000px) {
        grid-template-columns: 1fr;
    }
`

const Card = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem .5rem .5rem 1rem;
    border: 1px solid #DCDCDC;
    border-radius: 50px;

    span {
        max-width: 90%;
    }

    :disabled {
        a {
            background-color: #A8A8A8;
            color: #fff;
            cursor: not-allowed;
        }
    }

    a {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #CD2118;
        color: #fff;
    }
`