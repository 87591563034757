import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Text } from '../../../../components/common/Texts';
import { Icon } from '@iconify/react';
import useFetch from '../../../../hooks/useFetch';
import { getLastResourceSeen } from '../../../../redux/api/subjects';
import { lastResourceAdapter } from '../../adapters';
import LastResourceSeen from './LastResourceSeen.js';
import SubjectInfoCard from './SubjectInfoCard.js';
import SkeletonLoading from '../../../../components/common/SkeletonLoading';

const SubjectResume = () => {
    const { repo_id: repoId } = useSelector((state) => state.auth.user);

    const { data: lastResource, loading } = useFetch({
        asyncFn: () => getLastResourceSeen(repoId),
        adapter: lastResourceAdapter,
    });

    if (loading || !lastResource) {
        return (
            <Wrapper>
                <TextWrapper>
                    <Icon icon="ri:progress-2-line" />
                    <Text color="#B31D15" fontSize="20px" fontWeight="700">
                        Mi progreso
                    </Text>
                </TextWrapper>
                <CardWrapper>
                    <SkeletonLoading height={26} variant="rounded" />
                    <CardsContainer>
                        <SkeletonLoading height={295} variant="rounded" />
                        <SkeletonLoading height={295} variant="rounded" />
                    </CardsContainer>
                </CardWrapper>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <TextWrapper>
                <Icon icon="ri:progress-2-line" />
                <Text color="#B31D15" fontSize="20px" fontWeight="700">
                    Mi progreso
                </Text>
            </TextWrapper>
            <CardWrapper>
                <Text color="#ffffff" fontSize="20px" fontWeight="400">
                    Continúa tu aprendizaje desde donde lo dejaste:
                </Text>
                <CardsContainer>
                    <LastResourceSeen
                        lastOpenedOAData={lastResource?.lastOpenedOAData}
                        subjectId={
                            lastResource?.lastOpenedSubjectData?.subjectId
                        }
                        resourceId={
                            lastResource?.lastOpenedOAData?.lastOpenedResource
                                ?.id
                        }
                    />
                    <SubjectInfoCard
                        lastOpenedSubjectData={
                            lastResource?.lastOpenedSubjectData
                        }
                    />
                </CardsContainer>
            </CardWrapper>
        </Wrapper>
    );
};

export default SubjectResume;

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 25px;

    /* @media (max-width: 1440px) {
        width: 100%;
    } */

    @media (max-width: 1024px) {
        margin-bottom: 40px;
    }
`;

const TextWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px;

    &:first-child {
        flex-grow: 4;
    }

    svg {
        color: #b31d15;
        font-size: 24px;
    }

    &::after {
        position: absolute;
        content: ' ';
        width: calc(100% - 182px);
        height: 2px;
        margin: auto;
        top: 6px;
        bottom: 0;
        right: 15px;
        border-radius: 1px;
        background-color: #b31d15;
    }

    @media (max-width: 1440px) {
        p {
            font-size: 15px;
        }

        &::after {
            top: 3px;
            bottom: 0;
            right: 50px;
        }
    }

    @media (max-width: 768px) {
        p {
            font-size: 16px;
        }

        &::after {
            content: none;
        }
    }
`;

const CardWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 30px;
    height: 365px;
    padding: 16px;
    z-index: 10;
    background: linear-gradient(#e51a1a, #b31d15);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);

    p {
        margin-left: 10px;
    }

    @media (max-width: 1440px) {
        gap: 8px;
        max-width: 100%;
    }

    @media (max-width: 1024px) {
        max-width: 100%;
    }

    @media (max-width: 768px) {
        height: fit-content;
    }
`;

const CardsContainer = styled.div`
    box-sizing: content-box;
    display: flex;
    gap: 18px;
    width: 100%;
    height: calc(100% - 45px);

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
    }
`;
