import { useState, useEffect } from "react";
import {
    getSubjectDirectories,
    getSubjectPaginate,
} from "../../redux/api/subjects";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../utils/snackStyles";

const useStudentsCourse = (id) => {

    const [openSnackbar] = useSnackbar(optionsStyle);

    const [payload, setPayload] = useState(null);
    const [students, setStudents] = useState(null);
    const [allStudents, setAllStudents] = useState(null);
    const [offset, setOffset] = useState(0);
    const [viewLoading, setViewLoading] = useState(false);
    const [noMore, setNoMore] = useState(false);

    useEffect(() => {
        !payload &&
            setPayload({
                subject: id,
                query: {
                    page_size: 10,
                    page_offset: offset,
                    filters: {
                        name: "",
                        country_id: 0,
                        programa_id: 0,
                        contacto_empresa: "",
                        contacto_cargo_actual: "",
                        intereses_ids: [""],
                        id: 0,
                    },
                },
            });
    }, []);

    useEffect(() => {
        // !payload && getCountUser(id);
        !!payload && getStudents(payload);
    }, [payload]);

    const getStudents = async (payload) => {
        const request = await getSubjectDirectories(payload);
        setViewLoading(false);

        if (request.error) {
            openSnackbar("¡No se pudieron cargar los estudiantes!");
        } else {
            if (students === null) {
                setStudents(request.data.response_data);
            } else {
                request.data.response_data.length === 0
                    ? setNoMore(true)
                    : setNoMore(false);

                request?.data?.response_data?.forEach((element) => {
                    students.push(element);
                });
            }
            setAllStudents(request.data.response_data);
        }
        setOffset(offset + 10);
    };

    // DEJE DE USAR getCountUser PORQUE EL AGUS ROMPIÓ EL ENDPOINT
    const getCountUser = async (id) => {
        const query = {
            page_size: 1,
            filters: {
                name: "",
                country_id: 0,
                programa_id: 0,
                contacto_empresa: "",
                contacto_cargo_actual: "",
                intereses_ids: [],
                id: 0,
            },
        };
        const request = await getSubjectPaginate({ query, subject: id });

        setOffset(
            request.data.response_data.contactos < 10
                ? request.data.response_data.contactos
                : 10
        );

        console.log("llegué aca", request);

        setPayload({
            subject: id,
            query: {
                page_size:
                    request.data.response_data.contactos < 10
                        ? request.data.response_data.contactos
                        : 10,
                page_offset: 0,
                filters: {
                    name: "",
                    country_id: 0,
                    programa_id: 0,
                    contacto_empresa: "",
                    contacto_cargo_actual: "",
                    intereses_ids: [""],
                    id: 0,
                },
            },
        });
    };

    // funcion para cambiar los estudiantes
    const handleStudents = (students) => setStudents(students);

    const handleMoreStudents = () => {
        setViewLoading(true);
        getStudents({
            subject: id,
            query: {
                page_size: 10,
                page_offset: offset,
                filters: {
                    name: "",
                    country_id: 0,
                    programa_id: 0,
                    contacto_empresa: "",
                    contacto_cargo_actual: "",
                    intereses_ids: [""],
                    id: 0,
                },
            },
        });
    };

    return {
        allStudents,
        students,
        viewLoading,
        noMore,
        payload,
        handleStudents,
        handleMoreStudents,
    };
};

export default useStudentsCourse;
