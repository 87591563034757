import { Button, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getSedes } from "../../../../redux/api/titulation/titulation";
import SimpleLoading from "../../../common/SimpleLoading";

const FormSede = (props) => {
  const {
    values,
    setFieldValue,
    setAddressComplete,
    addressComplete,
    setData,
    data,
    loading,
  } = props;

  // STATES
  const [fieldsComplete, setFieldsComplete] = useState(false);
  const [sedes, setSedes] = useState(null);

  // TRAER SEDES
  const getSede = async () => {
    const response = await getSedes();
    if (response.error) {
      // alert("hay un error en la api");
      setSedes([]);
    } else {
      setSedes(response);
    }
  };

  //EFFECTS
  useEffect(() => {
    if (!sedes) {
      getSede();
    }
  }, [sedes]);

  useEffect(() => {
    if (!values.sede) {
      setFieldsComplete(false);
    } else {
      setFieldsComplete(true);
    }

    const handleValues = (value) => {
      if (sedes) {
        sedes.forEach((sede) => {
          if (sede.id === value) {
            setAddressComplete(`${sede.direccion}, ${sede.nombre}`);
          }
        });
      }
    };
    handleValues(values.sede);
  }, [values]);

  return (
    <Wrapper>
      <Header>
        <h2>CONFIRMA LA SEDE DE RECEPCIÓN</h2>
        <p>
          Una vez confirmado no podrás hacer modificaciones, ¡asegúrate de
          ingresar los datos correctos!
        </p>
      </Header>
      <Fields>
        <TextFieldFake
          id="outlined-select-currency"
          select
          label="Selecciona una sede"
          name="sede"
          disabled={!sedes || sedes.length === 0}
          required
          value={values.sede}
          onChange={(e) => {
            setFieldValue("sede", e.target.value);
            setData({
              ...data,
              id_sede: e.target.value,
              destino: "sede",
              direccion: "",
              ciudad: "",
              id_pais: "",
              cod_postal: "",
            });
          }}
        >
          {!!sedes &&
            sedes.map((sede) => (
              <MenuItem value={sede.id}>{sede.nombre}</MenuItem>
            ))}
        </TextFieldFake>
      </Fields>
      {fieldsComplete && (
        <Resume>
          <p>
            Tu título será enviado a <span>{addressComplete}</span>
          </p>
        </Resume>
      )}
      {loading ? (
        <SimpleLoading padding="2rem" />
      ) : (
        <ButtonFake
          color="primary"
          disabled={fieldsComplete ? false : true}
          variant="contained"
          type="submit"
        >
          Confirmar
        </ButtonFake>
      )}
    </Wrapper>
  );
};

export default FormSede;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  margin-top: 2rem;
  h2 {
    color: #616161;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  p {
    color: #a8a8a8;
  }
`;

const Fields = styled.div`
  width: 100%;
`;

const TextFieldFake = styled(TextField)`
  width: 100%;
`;
const Resume = styled.div`
  border: 1px solid #1e8065;
  border-radius: 10px;
  width: 100%;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f7fdfb;

  p {
    color: #1e8065;
    font-size: 1rem;
    margin: 1rem;
  }
  span {
    color: #1e8065;
    font-size: 1rem;
    font-weight: bold;
  }
`;

const ButtonFake = styled(Button)`
  margin: 0 auto;
`;
