import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { InsertDriveFile, Download, Close } from '@mui/icons-material';
import { Text } from '../../../common/Texts';

const FileComponent = (props) => {
    const { name, size, href, onClose } = props;

    const kb = size / 1024;
    const mb = kb / 1024;

    return (
        <Container>
            <InsertDriveFile />
            <FileData>
                <Text
                    ellipsis="ellipsis"
                    fontSize="13px"
                    fontWeight="700"
                    color="#b31d15"
                >
                    {name}
                </Text>
                <Text fontSize="13px" fontWeight="400" color="#222222">
                    {mb.toFixed(2)} MB
                </Text>
            </FileData>
            {!!href && (
                <a download={name} href={href}>
                    <IconButton>
                        <Download />
                    </IconButton>
                </a>
            )}
            {!!onClose && (
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            )}
        </Container>
    );
};

export default FileComponent;

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    min-width: 220px;
    max-width: 260px;
    align-items: center;
    border-radius: 20px;
    padding: 0.8rem 1rem;
    gap: 0.5rem;
    border: 1px solid #e5e5e5;
    background-color: #f9f9f9;

    svg {
        color: #a3a3a3;
    }
`;

const FileData = styled.div`
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
`;
