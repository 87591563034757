import React from "react";
import styled from "styled-components";
import { Card } from "@mui/material";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded";
import { Text } from "../Texts";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";

const MiniIconCards = (props) => {
    const { firstInfo } = props;

    const cards = [
        {
            label: `DEUDA`,
            id: 0,
            content: `-`,
            icon: <AttachMoneyRoundedIcon />,
            color: "#5E80DB",
        },
        {
            label: `INSCRIPCIONES`,
            id: 1,
            content: `${firstInfo.nro_inscripciones || '-'}`,
            icon: <DescriptionRoundedIcon />,
            color: "#C29F43",
        },
        // {
        //     label: 'CLIENTES',
        //     id: 2,
        //     content: `${student.customers}`,
        //     icon: <AccountBoxRoundedIcon />,
        //     color: '#B75E26'
        // },
        {
            label: "DOCUMENTACION",
            id: 3,
            content: `${firstInfo.nro_documentos || '-'}`,
            icon: <FileCopyRoundedIcon />,
            color: "#1E8065",
        },
    ];

    return (
        <Container>
            {cards.map((icon, index) => (
                <CardFake key={index}>
                    <Wrapper color={icon.color}>{icon.icon}</Wrapper>
                    <Body color={icon.color}>
                        <div>
                            <Text
                                fontSize="0.75rem"
                                fontWeight="bold"
                                color="#616161"
                            >
                                {" "}
                                {icon.label}
                            </Text>
                            <Text
                                fontSize="1.5rem"
                                fontWeight="bold"
                                color="#222222"
                            >
                                {icon.content}
                            </Text>
                        </div>
                    </Body>
                </CardFake>
            ))}
        </Container>
    );
};

export default MiniIconCards;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1rem;
    place-content: center;
    justify-items: start;
    justify-content: space-between;
    margin-bottom: 1rem;

    @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const CardFake = styled(Card)`
    display: flex;
    position: relative;
    height: 82px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);

    @media screen and (max-width: 900px) {
        width: 290px;
        align-self: center;
    }
`;

const Wrapper = styled.div`
    background-color: ${(p) => p.color};
    display: inline-flex;
    width: 80px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    color: white;
    position: relative;
    border-radius: 20px 0 20px 20px;
    :before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: 0px;
        left: 80px;
        height: 25px;
        width: 25px;
        border-top-left-radius: 25px;
        box-shadow: -10px -5px 0 8px ${(p) => p.color};
    }
`;

const Body = styled.div`
    display: inline-flex;
    flex-direction: row;
    padding: 1rem 0px 1rem 1rem;
    border-top-left-radius: 20px;
    position: relative;
    background-color: white;
    justify-content: space-between;
    align-items: center;
`;
