import React from 'react';
import styled from 'styled-components';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../redux/actions';

// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

export default function SaveSnackbar() {
    const dispatch = useDispatch();
    const snack = useSelector((state) => state.auth.snack);

    const handleClose = () => {
        dispatch(authActions.closeSnackbar());
    };

    return (
        <FakeSnackbar
            open={snack.snackShow}
            autoHideDuration={2000}
            onClose={handleClose}
        >
            <Alert variant="filled" elevation={6} onClose={handleClose} severity={snack.snackType}>
                {snack.snackMessage}
            </Alert>
        </FakeSnackbar>
    );
}

const FakeSnackbar = styled(Snackbar)`
    width: 100%;
`;
