import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import GridMyProgress from '../../common/GridMyProgress';
import { achievementsActions } from '../../../redux/actions';
import AchievementCard from '../../ui/organisms/my-progress/AchievementCard';
import AchievementsSkt from '../../skeletons/AchievementsSkt';

const Achievements = () => {
    // REDUX
    const dispatch = useDispatch();
    const achievements = useSelector(
        (state) => state.achievements.achievements
    );
    const user = useSelector((state) => state.auth.user);
    const listLoading = useSelector((state) => state.achievements.listLoading);

    // EFFECTS
    useEffect(() => {
        if (achievements === null) {
            dispatch(achievementsActions.getAchievementsRequest(user.sis_id));
        }
    }, []);

    // RETURN
    if (achievements === null || listLoading) {
        return <AchievementsSkt />;
    }

    return (
        <AchievementsContainer>
            <GridMyProgress
                propsValues={achievements}
                Card={AchievementCard}
                name="nombre"
            />
        </AchievementsContainer>
    );
};

export default Achievements;

const AchievementsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
