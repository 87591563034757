import React from 'react';
import styled from 'styled-components';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Logo from '../../../../assets/media/aden/logo-aden-simple-red.png';
import { ArrowDownwardOutlined } from '@mui/icons-material';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { Button } from '@mui/material';
import SimpleLoading from '../../../common/SimpleLoading';
import Text from '../../../common/Text';

const DownloadProgress = (props) => {
    const { print } = props;

    //DATE
    const d = new Date();
    const date = d.toISOString().slice(0, 10);

    //FUNCTIONS
    const headRows = () => {
        return [
            {
                course: 'Nombre del Curso',
                viewed: 'Recursos vistos',
                total: 'Recursos del curso',
                percentage: 'Progreso'
            }
        ];
    };

    function bodyRows(rowCount, data) {
        rowCount = rowCount || 1;
        var body = [];
        for (var j = 1; j <= rowCount; j++) {
            body.push({
                id: j,
                course: data[j - 1] ? data[j - 1].course : '',
                viewed:
                    data[j - 1] && data[j - 1].viewed <= data[j - 1].total
                        ? data[j - 1].viewed
                        : data[j - 1].total,
                total: data[j - 1] ? data[j - 1].total : '',
                percentage:
                    data[j - 1] && data[j - 1].progress <= 100
                        ? `${data[j - 1].progress} %`
                        : '100 %'
            });
        }
        return body;
    }

    const generatePDF = async () => {
        try {
            const doc = new jsPDF('p', 'mm', 'a4');
            // IMG
            let img = new Image();
            img.src = Logo;
            doc.addImage(img, 'png', 20, 10, 50, 10);
            // FONT
            doc.setTextColor('#757575');
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            // TITLE
            doc.text('Avance de cursado', 90, 17);
            //DATE
            doc.text(date, 170, 17);
            //USER
            let finalY = doc.lastAutoTable.finalY || 10;
            doc.text(`${print.name}`, 14, finalY + 20);
            //TABLE
            autoTable(doc, {
                theme: 'grid',
                startY: finalY + 25,
                margin: { top: 20, bottom: 20 },
                horizontalPageBreak: false,
                pageBreak: 'auto',
                tableWidth: '80%',
                head: headRows(),
                headStyles: {
                    fillColor: '#b31d15',
                    fontStyle: 'bold',
                    textColor: '#FFFFFF',
                    cellPadding: 2,
                    halign: 'center',
                    valign: 'middle'
                },
                columnStyles: {
                    0: { halign: 'left' },
                    1: { halign: 'center' },
                    2: { halign: 'center' },
                    3: { halign: 'center' }
                },
                body: await bodyRows(print.courses.length, print.courses),
                styles: {
                    lineWidth: 0.1,
                    cellPadding: 1,
                    fontSize: 10,
                    overflow: 'linebreak',
                    cellWidth: 'auto'
                }
            });

            finalY = doc.lastAutoTable.finalY;
            doc.text(
                `Generado por Acrópolis Lab con fecha ${date}`,
                14,
                finalY + 10
            );

            doc.save(`cursos_${print.name}.pdf`);
        } catch (error) {
            console.log(error);
        }
    };

    const saveAsExcel = async () => {
        try {
            // Create a workbook with a worksheet
            const wb = new ExcelJS.Workbook();
            wb.views = [
                {
                    x: 0,
                    y: 0,
                    width: 10000,
                    height: 20000,
                    firstSheet: 0,
                    activeTab: 1,
                    visibility: 'visible',
                    showGridLines: true
                }
            ];
            const ws = wb.addWorksheet('UsersSheet', {
                pageSetup: {
                    paperSize: 9,
                    orientation: 'portrait',
                    margins: {
                        left: 0.7,
                        right: 0.7,
                        top: 0.75,
                        bottom: 0.75,
                        header: 0.3,
                        footer: 0.3
                    }
                }
            });
            //COLUMNS
            ws.columns = [
                { header: 'Usuario', key: 'name', width: 30 },
                { header: 'Nombre del Curso', key: 'course', width: 30 },
                { header: 'Recursos\n del curso', key: 'total', width: 15 },
                { header: 'Recursos\n cursados', key: 'viewed', width: 15 },
                { header: 'Progreso', key: 'progress', width: 15 }
            ];
            // ws.getCell('A2').value = print.name;
            print.courses.forEach((courseData) => {
                ws.addRow({
                    name: print.name,
                    course: courseData.course,
                    total: courseData.total,
                    viewed:
                        courseData.viewed <= courseData.total
                            ? courseData.viewed
                            : courseData.total,
                    progress:
                        courseData.progress <= 100
                            ? courseData.progress / 100
                            : 1
                });
            });

            let bodyFill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'F8F9F9' }
            };
            let bodyBorder = {
                left: { style: 'thin' },
                right: { style: 'thin' }
            };
            let alignmentTex = {
                vertical: 'middle',
                horizontal: 'left',
                indent: 1
            };
            let alignmentNumber = { vertical: 'middle', horizontal: 'center' };

            ws.eachRow({ includeEmpty: false }, function (row) {
                row.eachCell({ includeEmpty: false }, function () {
                    //FILL BODY
                    row.getCell('A').fill = bodyFill;
                    row.getCell('B').fill = bodyFill;
                    row.getCell('C').fill = bodyFill;
                    row.getCell('D').fill = bodyFill;
                    row.getCell('E').fill = bodyFill;
                    //PERCENTAGE
                    row.getCell('E').numFmt = '0.00%';
                    //BORDERS
                    row.getCell('A').border = bodyBorder;
                    row.getCell('B').border = bodyBorder;
                    row.getCell('C').border = bodyBorder;
                    row.getCell('D').border = bodyBorder;
                    row.getCell('E').border = bodyBorder;
                });
            });
            //styles
            ['A1', 'B1', 'C1', 'D1', 'E1'].map(
                (key) =>
                    (ws.getCell(key).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'b31d15' }
                    })
            );
            ['A1', 'B1', 'C1', 'D1', 'E1'].map(
                (key) =>
                    (ws.getCell(key).font = {
                        name: 'helvetica',
                        size: 12,
                        bold: true,
                        color: { argb: 'FFFFFF' }
                    })
            );
            ['A1', 'B1', 'C1', 'D1', 'E1'].map(
                (key) =>
                    (ws.getCell(key).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    })
            );
            ws.getRow(1).height = 30;
            ws.getRow(1).alignment = { vertical: 'middle' };
            ws.getColumn(1).alignment = alignmentTex;
            ws.getColumn(2).alignment = alignmentTex;
            ws.getColumn(3).alignment = alignmentNumber;
            ws.getColumn(4).alignment = alignmentNumber;
            ws.getColumn(5).alignment = alignmentNumber;

            // Generate & Save Excel File
            const buf = await wb.xlsx.writeBuffer();
            saveAs(new Blob([buf]), 'cursos_por_usuario.xlsx');
        } catch (error) {
            console.log(error);
        }
    };

    if (print === null) {
        return <SimpleLoading />;
    }
    return (
        <Container>
            <Text fontSize="1rem" fontWeight="700" fontcolor="#A8A8A8">
                Avance porcentual de cada cursado
            </Text>
            <ButtonList>
                <PdfButton
                    size="small"
                    endIcon={<PictureAsPdfRoundedIcon />}
                    variant="contained"
                    color="primary"
                    onClick={generatePDF}
                >
                    Exportar PDF
                </PdfButton>
                <ExcelButton
                    size="small"
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowDownwardOutlined />}
                    onClick={saveAsExcel}
                >
                    Exportar XLS
                </ExcelButton>
            </ButtonList>
        </Container>
    );
};

export default DownloadProgress;

// const Container = styled.div`
//     display: contents;
//     width: 40%;
//     margin: 0 auto;
//     gap: 0.5rem;
// `;

// const ButtonList = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: end;
//     flex-wrap: wrap;
//     gap: 1rem;
//     padding: 0.5rem;
//     font-size: 1rem;
// `;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    gap: 0.5rem;
`;

const ButtonList = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0rem;
    font-size: 1rem;
    margin-top: 0;
`;

const PdfButton = styled(Button)`
    background-color: #b31d15;
    color: #f4f4f4; ;
`;
const ExcelButton = styled(Button)`
    background-color: #1d6f42;
    color: #f4f4f4;
`;
