import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Material UI
import VideoFileIcon from "@mui/icons-material/VideoLibrary";
import VideocamIcon from '@mui/icons-material/Videocam';
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";

// Hooks
import VideoRecorder from "./VideoRecorder";

const PickRecVideo = (props) => {

    const { handleSendVideo, videoFile } = props;

    const [openModal, setOpenModal] = useState(false);
    const [videoBlob, setVideoBlob] = useState("");
    const [videoUrl, setVideoUrl] = useState("");

    useEffect(() => {
        if (videoFile) {
            setVideoUrl(videoFile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container>
            <ButtonVideo title="Grabar video" onClick={() => setOpenModal(true)}>
                <VideocamIcon />
            </ButtonVideo>
            {(!!videoUrl || !!videoBlob) && (
                <ButtonFile video={videoUrl !== "" || videoBlob !== ""} title="Ver video grabado" onClick={() => setOpenModal(true)}>
                    <VideoFileIcon />
                </ButtonFile>
            )}
            <FakeDialog
                fullWidth
                open={openModal}>
                <VideoRecorder
                    videoUrl={videoUrl}
                    setVideoUrl={setVideoUrl}
                    videoBlob={videoBlob}
                    setVideoBlob={setVideoBlob}
                    setOpenModal={setOpenModal}
                    handleSendVideo={handleSendVideo}
                />
            </FakeDialog>
        </Container>
    );
};

export default PickRecVideo;

const Container = styled.div`
    display: flex;
    gap: 0rem;
`;

const ButtonVideo = styled(IconButton)`
    width: 38px;
    height: 38px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    svg {
        font-size: 1.5rem;
        fill: #a9a9a9;
    }
`;

const ButtonFile = styled(IconButton)`
    width: 38px;
    height: 38px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${(props) => props.video && "#b31d15"};
    :hover {
        background-color: ${(props) => props.video && "#b31d15"};
    }
    svg {
        font-size: 1.5rem;
        fill: ${(props) => props.video ? "#fff" : "#616161"};
    }
`;
const FakeDialog = styled(Dialog)`
    & > div {
        max-width: 100vw;
    }
    & > div:nth-child(3) {
        width: 100vw;
        max-height: 100%;

        & > div {
            max-width: fit-content;
        }
    }

    .camera {
        width: calc(100% - 4rem);
    }
`