import React from 'react';
import styled from 'styled-components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Skeleton } from '@mui/material';

const UserCardSkeleton = (props) => {
    return (
        <MainContainer>
            <ClickeableArea>
                <ImageContainer>
                    <Skeleton
                        animation="wave"
                        variant="circular"
                        width={120}
                        height={120}
                    />
                </ImageContainer>
                <Skeleton animation="wave" height={15} width="80%" />
                <Skeleton animation="wave" height={10} width="50%" />{' '}
                <Skeleton animation="wave" height={8} width="30%" />{' '}
                {/* <H6>
                    <LocationOnIcon />
                </H6> */}
            </ClickeableArea>
            <FakeButton animation="wave" />
        </MainContainer>
    );
};

UserCardSkeleton.propTypes = {};

export default UserCardSkeleton;

const MainContainer = styled.div`
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.03) 0.52%,
        white 40%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 2rem;
    row-gap: 0.5rem;
    justify-content: space-between;
    min-height: 215px;
    border: 1px solid #f2f2f2;

    @media screen and (max-width: 468px) {
        padding: 1.5rem 0.5rem;
        min-height: 200px;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 3rem;
`;

const H4 = styled.div`
    height: 1rem;
`;
const H5 = styled.div`
    font-size: 0.9rem;
`;
const ClickeableArea = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
`;

const FakeButton = styled(Skeleton)`
    width: 100px;
    height: 50px;
`;
