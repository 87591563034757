import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import EmptyGridResource from "../../common/EmptyGridResource";
import CourseCardSkeleton from "../../common/CourseCardSkeleton";
import useTheme from "../../../hooks/useTheme";
import { getSubjectsP100 } from "../../../redux/api/subjects";
import P100Card from "../../../app/landing/components/Business/P100Card";

const CoursesAden = () => {
    const { primary } = useTheme();

    // REDUX
    const repoId = useSelector((state) => state?.auth?.user?.repo_id);

    // STATE
    const [values, setValues] = useState(null);

    const emptyItems = new Array(3).fill(null);

    // EFFECTS
    useEffect(() => {
        values === null && getCoursesP100();
    }, []);

    // FUNCTIONS
    const getCoursesP100 = async () => {
        try {
            const req = await getSubjectsP100(repoId);
            setValues(req);
        } catch (error) {
            setValues(false);
        }
    };

    return (
        <CoursesContainer>
            <Container isLoading={values}>
                {values === null ? (
                    <EmptyGridResource
                        propsValues={emptyItems}
                        Card={CourseCardSkeleton}
                    />
                ) : !!values && values?.enrollments?.length > 0 ? (
                    <Grid>
                        {values?.enrollments.map((value) => (
                            <P100Card value={value} key={value.detail.id} />
                        ))}
                    </Grid>
                ) : (
                    <MessageEmpty color={primary}>
                        No tienen cursos asignados por Aden International
                        Business School
                    </MessageEmpty>
                )}
            </Container>
        </CoursesContainer>
    );
};

export default CoursesAden;

const CoursesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const MessageEmpty = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(p) => p.color};
    font-size: 1.2rem;
    font-weight: 600;
`;

const Container = styled.div`
    display: ${(p) => (p.isLoading !== null ? "flex" : "block")};
    flex-direction: column;

    gap: 2rem;
    background-color: #fff;
    border-radius: 20px;
    width: calc(100% - 4rem);
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    align-items: center;
`;

// const Header = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
// `;

// const TextFieldFake = styled(TextField)`
//     width: fit-content;
// `;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    width: 100%;
    align-items: center;
    gap: 1.5rem;
`;
