import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as encode from "nodejs-base64-encode";
import useTheme from "../../../../hooks/useTheme";
import BarProgress from "../../../../components/common/BarProgress";
import { useSelector } from "react-redux";

const BusinessCard = (props) => {
    const { value } = props;
    // console.log(value);
    const { primary } = useTheme();
    const { enable_correction_activities } = useSelector(state => state.ondemand.ondemand)
    const hash = encode.encode(value.id + "", "base64");

    return (
        <BusinessCardContainer>
            <ImageWrapper>
                <FakeImg
                    src={
                        value.imagen !== ""
                            ? value.imagen
                            : "https://www.aden.org/files/sites/9/2018/09/Workshop_Miami_magazine.jpg"
                    }
                    alt="objeto de aprendizaje"
                />
                {value.obligatorio === true && (
                    <SpanType color={primary}>Obligatorio</SpanType>
                )}
            </ImageWrapper>
            <Body>
                <BarProgress
                    value={value.progreso > 100 ? 100 : value.progreso}
                />
                <NombrePublicacion>
                    {value.nombre_publicacion}
                </NombrePublicacion>
                <DescriptionWrapper>
                    <Description>
                        {value.descripcion !== false
                            ? value.descripcion
                            : "No posee una descripción"}
                    </Description>
                </DescriptionWrapper>
            </Body>
            <Footer>
                <IconWrapper></IconWrapper>
                <FakeLink color={primary} to={`/curso/${hash}?origin=${!!enable_correction_activities ? 'plan' : 'alab'}`}>
                    Ir a cursar
                </FakeLink>
            </Footer>
        </BusinessCardContainer>
    );
};

export default BusinessCard;

const BusinessCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    margin: 1rem;
    max-width: 380px;
    width: 100%;
    min-height: 350px;
    border-radius: 20px;
`;

const ImageWrapper = styled.div`
    position: relative;
`;

const SpanType = styled.span`
    font-size: 0.7rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: ${(props) => props.color};
    color: #ffffff;
    border-radius: 100px;
    padding: 0.3rem 0.8rem;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(1rem, 1rem);
`;

const FakeImg = styled.img`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-radius: 20px;
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    height: 100%;
`;

const NombrePublicacion = styled.h1`
    font-size: 1rem;
    font-weight: 700;
    text-align: start;
    color: #222222;

    @media screen and (max-width: 996px) {
        font-size: 1.2rem;
    }
`;

const DescriptionWrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;
    max-height: 100px;
    overflow-y: auto;
`;

const Description = styled.span`
    font-size: 0.85rem;
    color: #999999;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #fafafa;
    margin-top: auto;
    background-color: #fafafa;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const FakeLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-weight: 700;
    background-color: ${(props) => props.color};
    color: #ffffff;

    :hover {
        background-color: #616161;
        color: #ffffff;
    }
`;
