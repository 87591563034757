import React from 'react'
import styled from 'styled-components'
import { Text } from '../../../../../components/common/Texts'
import ImagenBloqueo from '../../../../../assets/media/aden/image-bloqueo.png'
import LazyImg from '../../../../../components/common/LazyImg'

const Pending = () => {
    return (
        <PendingWrapper>
            <LazyImg src={ImagenBloqueo} alt="Imagen de bloqueo" backgroundColor="#fff" width={363} height={"auto"} />
            <Text fontWeight="400" fontSize="15px" color="#616161" textAlign="center">
                Aquí encontrarás las calificaciones y comentarios <br /> de tu profesor.
            </Text>
        </PendingWrapper>
    )
}

export default Pending

const PendingWrapper = styled.div`
    border-radius: 20px;
    height: 430px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    border: 1px solid #C4C4C4;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;

    svg{
        font-size: 40px;
        color: #A8A8A8;
    }
`