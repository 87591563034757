import React from 'react';
import styled from 'styled-components';
import AsideButtons from '../../ui/molecules/certificate/AsideButtons';
import CertificateHtml from '../../ui/molecules/certificate/CertificateHtml';
import Navbar from '../../ui/organisms/certificate/Navbar';

const MainPage = (props) => {
    const { html } = props;

    return (
        <Page>
            <Navbar />
            <Container>
                <CertificateHtml html={html} />
                <AsideButtons />
            </Container>
        </Page>
    );
};

export default MainPage;

const Container = styled.div`
    padding: 3rem 10rem;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    @media (max-width: 1500px) {
        grid-template-columns: 1fr;
        padding: 0.5rem;
    }
`;

const Page = styled.div`
    width: 100%;
    overflow: scroll;
`;
