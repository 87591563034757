import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import ModalDescription from './evaluation/ModalDescription';
import QuestionGeneric from './questions/QuestionGeneric';
import WarningMessage from './questions/WarningMessage';

// Material UI
import { Icon } from '@iconify/react/dist/iconify.js';

const PageExam = (props) => {
    const { evaluation, handleDataQuestion, handleSendExam } = props;

    // STATES
    const [cicleSelected, setCicleSelected] = useState([]);
    const [open, setOpen] = React.useState(false);

    // FUNCTIONS
    const handleValueQuestion = (value) => {
        let state = true;
        if (cicleSelected.length > 0) {
            cicleSelected.forEach((val) => {
                if (val === value) {
                    state = false;
                }
            });
            if (state === true) {
                setCicleSelected((state) => [...state, value]);
                return null;
            }
        }
        if (state === true) {
            setCicleSelected((state) => [...state, value]);
        }
    };

    const handleFocus = (index) => {
        const number = index + 1;
        const element = document.getElementById(`${number}element`);

        element.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
        });
    };

    return (
        <WrapperExam>
            <HeaderExam>
                <section>
                    <h1>
                        {evaluation?.name}
                    </h1>
                </section>
                <NavExam>
                    <QuestionsWrapper>
                        <p>Preguntas</p>
                        <QuestionsCount>
                            {evaluation?.preguntas?.map((question, index) => {
                                return (
                                    <CircleQuestion
                                        onClick={() => handleFocus(index)}
                                        status={
                                            cicleSelected.includes(index + 1) === true
                                                ? true
                                                : false
                                        }
                                    >
                                        {index + 1}
                                    </CircleQuestion>
                                );
                            })}
                        </QuestionsCount>
                    </QuestionsWrapper>
                    <ButtonsWrapper>
                        <BtnDescription>
                            <ModalDescription
                                setOpen={setOpen}
                                open={open}
                                description={evaluation?.descripcion}
                            />
                        </BtnDescription>
                        <Time>
                            <Icon icon="circum:timer" width="24px" height="24px" />
                            <SpanExam>
                                {evaluation?.tiempo === false
                                    ? 'SIN LÍMITE'
                                    : `${evaluation?.tiempo} min`}
                            </SpanExam>
                        </Time>
                    </ButtonsWrapper>
                </NavExam>
            </HeaderExam>
            <WrapperQuestions>
                {evaluation?.preguntas?.map((q, index) => {
                    return (
                        <QuestionGeneric
                            handleDataQuestion={handleDataQuestion}
                            type={q.tipo}
                            handleValueQuestion={handleValueQuestion}
                            number={index + 1}
                            question={q}
                        />
                    );
                })}
                <Center>
                    <WarningMessage />
                    <EndTryButton
                        onClick={handleSendExam}
                        disabled={
                            evaluation?.preguntas.length -
                                cicleSelected?.length ===
                                0
                                ? false
                                : true
                        }
                    >
                        <Icon icon="material-symbols-light:check-circle-outline" width="24px" height="24px" />
                        <label>
                            Finalizar intento
                        </label>
                    </EndTryButton>
                </Center>
            </WrapperQuestions>
        </WrapperExam>
    );
};

export default PageExam;

const WrapperExam = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 1rem;
`;

const HeaderExam = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border: 1px solid #A8A8A8;
    border-radius: 30px;

    section {
        width: 100%;
        height: 54px;
        background-color: #B31D15;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px 30px 0 0;

        h1 {
            color: #FFF;
        }
    }
`

const NavExam = styled.nav`
    padding: 2rem 2rem;
    width: calc(100% - 4rem);
    height: calc(60px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    @media (width < 768px) {
        height: fit-content;
        flex-direction: column;
    }
`;

const QuestionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 65%;

    p {
        font-size: 12px;
        color: #616161;
        font-weight: 700;
    }

    @media (width < 1470px) {
        max-width: 50%;
    }

    @media (width < 1260px) {
        max-width: 40%;
    }

    @media (width < 768px) {
        max-width: 100%;
    }
`

const QuestionsCount = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    gap: 8px;
    overflow-x: auto;
`;

const CircleQuestion = styled.div`
    min-width: 34px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: ${(props) => props.status === true ? '#b21f17' : '#E5E5E5'};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.status === true ? '#FFF' : '#616161')};
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`

const BtnDescription = styled.div``;

const Time = styled.div`
    width: fit-content;
    height: fit-content;
    gap: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #F9F9F9;
    border: 1px solid #E5E5E5;
    border-radius: 30px;
    padding: 8px 16px 8px 8px;

    svg {
        background-color: #B31D15;
        border-radius: 100px;
        color: #FFF;
        padding: 6px;
    }
`;

const SpanExam = styled.span`
    color: #222;
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
`;

const WrapperQuestions = styled.div`
    width: calc(100% - 4rem);
    min-height: 450px;
    max-height: 600px;
    overflow-y: auto;
    padding: 2rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    border-radius: 30px;
`;

const Center = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`

const EndTryButton = styled.button`
    cursor: pointer;
    padding: 12px 24px 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background-color: #CD2118;
    color: #FFF;

    label {
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
    }

    :disabled {
        cursor: not-allowed;
        background-color: #DCDCDC;

        label {
            cursor: not-allowed;
        }
    }

    :hover {
        background-color: #B31D15;
        box-shadow: 0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F;

        :disabled {
            background-color: #DCDCDC;
            box-shadow: none;
        }
    }
`