import React from "react";
import styled from "styled-components";
import DomainIcon from "@mui/icons-material/Domain";
import LocationIcon from "@mui/icons-material/LocationOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CardAddress = (props) => {
  const { icon, title, statu, handleStatus } = props;

  return (
    <Card onClick={handleStatus} statu={statu}>
      <CheckCircleFake statu={statu} />
      {icon === "sede" ? <DomainIcon /> : <LocationIcon />}
      <p>{title}</p>
    </Card>
  );
};

export default CardAddress;

const Card = styled.div`
  margin-top: 2rem;
  position: relative;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
  display: flex;
  background-color: ${(p) => (p.statu ? "#F7FDFB" : "white")};
  width: 180px;
  height: 180px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;

  svg {
    color: #a8a8a8;
    font-size: 2rem;
  }
  p {
    color: #222222;
    font-size: 1.1rem;
    font-weight: bold;
  }
  transition: all 0.3s ease;

  :hover {
    margin-top: 1.5rem;
  }
`;

const CheckCircleFake = styled(CheckCircleIcon)`
  position: absolute;
  top: 10px;
  display: ${(p) => (p.statu ? "block" : "none")};
  right: 10px;
  font-size: 1.6rem !important;
  color: #3bd39c !important;
`;
