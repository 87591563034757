import styled from "styled-components";

const ModalError = (props) => {

    const { setModalError, setModalForm } = props;

    return (
        <>
            <h2>
                ¡Ups!
                <br />
                El correo ingresado
                <br />
                no es válido
            </h2>
            <p>Por favor intenta con otro correo electrónico</p>
            <SecondaryButton
                onClick={() => {
                    setModalError(false);
                    setModalForm(true);
                }}
            >
                Usar otro correo
            </SecondaryButton>
        </>
    );
}

export default ModalError;

const SecondaryButton = styled.button`
    cursor: pointer;
    height: 40px;
    border: 1px solid #b31d15;
    border-radius: 10px;
    background-color: #fff;
    color: #b31d15;
    font-size: 16px;
    font-weight: 600;
    padding: 0 1rem;
`