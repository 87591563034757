import React, { useState } from "react";
import styled from "styled-components";

import Pay from "./Pay";
import { Collapse, ListItem } from "@mui/material";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const FinanceItem = (props) => {
    const { finance } = props;

    //Rol
    const activeRol = useSelector((state) => state.auth.activeRol);

    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTIONS
    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    // RETURN
    return (
        <>
            <ListItemCustom button open={isOpen} onClick={toggleIsOpen}>
                <LeftWrapper>
                    <Product>
                        <SubTitle>Producto</SubTitle>
                        <Title>
                            {finance.concepto !== null
                                ? finance.concepto
                                : "Producto no identificado"}
                        </Title>
                    </Product>
                </LeftWrapper>
                <RightWrapper>
                    <ProductWrapper>
                        <Product>
                            <SubTitle>Importe total</SubTitle>
                            <Title>
                                {finance.moneda}{" "}
                                {Math.round(finance.importe_total)}
                            </Title>
                        </Product>
                    </ProductWrapper>
                    {isOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                </RightWrapper>
            </ListItemCustom>
            <CollapseCustom in={isOpen} timeout="auto" unmountOnExit>
                <CollapseItemsWrapper>
                    {finance.cuotas_online_abonadas.length > 0 && (
                        <>
                            {finance.cuotas_online_abonadas.map((paid) => (
                                <Pay
                                    pay={paid}
                                    activeRol={activeRol}
                                    type="paid"
                                />
                            ))}
                        </>
                    )}
                    {finance.pagos_online_pendientes.length > 0 && (
                        <>
                            {finance.pagos_online_pendientes.map((slope) => (
                                <Pay
                                    pay={slope}
                                    activeRol={activeRol}
                                    type="slope"
                                />
                            ))}
                        </>
                    )}
                    {!!finance.pagos_sede && (
                        <>
                            {finance.pagos_sede.length > 0 && (
                                <>
                                    {finance.pagos_sede.map((campus) => (
                                        <Pay
                                            pay={campus}
                                            activeRol={activeRol}
                                            type="campus"
                                        />
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </CollapseItemsWrapper>
            </CollapseCustom>
        </>
    );
};

export default FinanceItem;

const ListItemCustom = styled(ListItem)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #eeeeee;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: unset;
        row-gap: 1rem;
        width: 100%;
    }
`;

const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const RightWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    @media (max-width: 768px) {
        justify-content: space-between;
    }
`;

const StatusButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.5rem;
    border-radius: 50px;
    font-size: 0.7rem;
    font-weight: 700;
    background-color: ${(props) => {
        if (props.status === "pagado_total") {
            return "#1E8065";
        } else if (props.status === "pendiente") {
            return "#C29F43";
        } else if (props.status === "cancelado") {
            return "#ad2d21";
        } else if (props.status === "procesando_pago") {
            return "#1da1ad";
        } else if (props.status === "reembolsado") {
            return "#3e56b0";
        } else if (props.status === "reprogramado") {
            return "#d15817";
        } else if (props.status === "pagado_parcial") {
            return "#C24380";
        } else if (props.status === "rechazado") {
            return "#615955";
        }
    }};
    color: #ffffff;
`;

const ProductWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const Product = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    color: #616161;
`;

const SubTitle = styled.span`
    font-size: 0.6rem;
    text-transform: uppercase;
`;

const Title = styled.h2`
    font-size: 0.9rem;
    font-weight: 700;
`;

const Money = styled.h2`
    font-size: 1rem;
    font-weight: 700;
`;

const CollapseCustom = styled(Collapse)`
    background-color: #f9f9f9;
    padding: 1rem;
`;

const CollapseItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

const FakeButton = styled(Button)`
    z-index: 10;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
