import React from 'react';
import styled from 'styled-components';

import Text from '../../../common/Text';

const NumberTitle = (props) => {
    const { number, title } = props;

    // RETURN
    return (
        <Container>
            <CustomNumber>{number}</CustomNumber>
            <Text fontSize="1.25rem" fontWeight="700" component="h2">
                {title}
            </Text>
        </Container>
    );
};

export default NumberTitle;

const Container = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const CustomNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #b31d15;
    color: #ffffff;
    width: 30px;
    height: 30px;
`;
