import React, { useState } from "react";
import { createChannel } from "../../../helpers/createChannelChat.helper";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../helpers/userActions.helper";
import {
    changeChannel,
    setParticipants,
} from "../../../redux/chat/chatActions";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { IconButton, Tooltip } from "@mui/material";

const SocialMediaOneStudent = (props) => {
    const { firstInfo } = props;
    const history = useHistory();
    const userLogin = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    let { id } = useParams();

    //Chat students of subject
    const partnerId = id;

    // STATES
    const [isCopy, setIsCopy] = useState(false);

    //Chat
    const chatGeneration = () => {
        const hash = createChannel(userLogin.partner_id, partnerId);
        const contantFormated = {
            nombre: firstInfo.nombre_completo,
            apellido: "",
            partner_id: parseInt(partnerId, 10),
            foto: firstInfo.imagen,
            contactData: firstInfo,
        };
        userAction({
            object_id: partnerId,
            object: "Chat acropolis",
            name: `Se inició el chat con ${firstInfo.nombre_completo}`,
            activity: "Iniciar chat",
            medio: "Chat",
        });
        dispatch(changeChannel(hash));
        dispatch(
            setParticipants([
                contantFormated,
                { ...userLogin, nombre: userLogin.name },
            ])
        );
        setTimeout(() => {
            history.push("/networking/chat");
        }, 200);
    };

    const handleCopy = (value) => {
        setIsCopy(false);
        if (value !== "") {
            navigator.clipboard.writeText(`${value}`);
            setIsCopy(true);
        }

        setTimeout(() => {
            setIsCopy(false);
        }, 1000);
    };

    return (
        <SocialMedia>
            <BtnSocial onClick={chatGeneration} variant="outlined">
                <Tooltip title="Chat">
                    <ForumRoundedIcon />
                </Tooltip>
            </BtnSocial>

            <BtnSocial
                disabled={firstInfo.whatsapp === ""}
                href={`https://wa.me/?phone=${firstInfo.whatsapp}`}
                target="_blank"
                rel="noreferrer"
            >
                <Tooltip title="Whatsapp">
                    <WhatsappIcon />
                </Tooltip>
            </BtnSocial>

            <BtnSocial
                disabled={firstInfo.email === ""}
                // href={`mailto:${value.personal_email}`}
                onClick={() => {
                    handleCopy(firstInfo.email);
                }}
                target="_blank"
                rel="noreferrer"
            >
                <Tooltip title={`${firstInfo.email}`}>
                    <AlternateEmailRoundedIcon />
                </Tooltip>
            </BtnSocial>

            <BtnSocial
                disabled={firstInfo.tel_particular === ""}
                // href={`tel:${value.mobile}`}
                onClick={() => {
                    handleCopy(firstInfo.tel_particular);
                }}
                target="_blank"
                rel="noreferrer"
            >
                <Tooltip title={`${firstInfo.tel_particular}`}>
                    <LocalPhoneRoundedIcon />
                </Tooltip>
            </BtnSocial>
            {!!isCopy && <ChipCopy>¡Copiado!</ChipCopy>}
        </SocialMedia>
    );
};

export default SocialMediaOneStudent;

const SocialMedia = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    height: 90px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    color: #a8a8a8;
    position: relative;
`;

const BtnSocial = styled(IconButton)`
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
        background-color: #f3f3f3;
    }
    svg {
        color: #aaaaaa;
        font-size: 1.7rem;
    }
`;

const LogoSkype = styled.img`
    width: 1.7rem;
    height: 1.7rem;
`;

const ChipCopy = styled.div`
    position: absolute;
    background-color: #3bd39c;
    color: #fff;
    padding: 0.3rem 0.3rem;
    width: fit-content;
    border-radius: 5px;
    bottom: -15px;
`;
