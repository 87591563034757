import { useEffect, useState } from 'react';
import { getInstructorsByCourse } from '../../redux/api/courses';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../../utils/snackStyles';

const useInstructors = (courseId) => {
    const [instructors, setInstructors] = useState(null);

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!instructors) getInstructorsById();
    }, [instructors]);

    const getInstructorsById = async () => {
        const request = await getInstructorsByCourse(courseId);
        if (request.error) {
            openSnackbar('¡No se pudo cargar los tutores!');
            setInstructors(false);
            return;
        }
        setInstructors(request);
        // setInitialCourses(request);
    };

    return { instructors };
};

export default useInstructors;
