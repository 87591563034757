import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import styled from 'styled-components';

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Text>{`${Math.round(props.value)}%`}</Text>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired
};

const useStyles = makeStyles({
    root: {
        width: '100%'
    }
});

export default function LinealProgress(props) {
    const { workshop, currentMedia, handleProgress } = props;

    const [progress, setProgress] = useState(0);
    //   const [all, setAll] = useState(0)

    useEffect(() => {
        getProgress();
    }, [workshop, currentMedia]);

    const getProgress = () => {
        let lengthClass = 0;
        let allView = 0;
        workshop.unidades.forEach((unit) => {
            unit.clases.forEach((classe) => {
                ++lengthClass;
                if (classe.visto) {
                    ++allView;
                }
            });
        });
        setProgress((allView * 100) / lengthClass);
        handleProgress((allView * 100) / lengthClass);
    };

    return (
        <Container>
            <LinearProgressWithLabel value={progress} />
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    padding: 0.3rem 3rem;
`;

const Text = styled.div`
    font-size: 1rem;
    color: #b31d15;
    font-weight: 700;
`;
