import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { coursesActions, subjectsActions } from "../../redux/actions"

const useCourses = () => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { courses, errorEnroll, hasMore, loadMoreLoading } = useSelector((state) => state.courses);
    const { enrollment } = useSelector(
        (state) => state.enrollment
    );

    // STATE
    const [allCourses, setAllCourses] = useState({
        list: null,
        errorState: null,
        loadingState: true,
    });

    // EFFECTS
    useEffect(() => {
        if (courses === null) {
            dispatch(
                coursesActions.getCoursesRequest({
                    size: "8",
                    offset: "0",
                })
            );
        }
        if (enrollment === null) {
            dispatch(subjectsActions.getSubjectsRequest(user.repo_id));
        }

    }, [errorEnroll]);

    useEffect(() => {
        if (!!courses || !!enrollment) {
            fusionCourses();
        }
    }, [courses, enrollment]);



    function loadMore() {
        if (loadMoreLoading === false && courses !== null) {
            dispatch(
                coursesActions.getMoreCoursesRequest({
                    size: '8',
                    offset: courses.length
                })
            );
        }
    }

    if (hasMore) {
        loadMore();
    }

    const fusionCourses = () => {
        if (!courses && !enrollment)
            setAllCourses({
                ...allCourses,
                loadingState: false,
                errorState: true,
            });
        else if (!courses)
            setAllCourses({
                ...allCourses,
                loadingState: false,
                list: enrollment,
            });
        else if (!enrollment)
            setAllCourses({
                ...allCourses,
                loadingState: false,
                list: courses,
            });
        else
            setAllCourses({
                ...allCourses,
                loadingState: false,
                list: [...courses, ...enrollment],
            });
        return;
    };


    return { allCourses }
}

export default useCourses