import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

// Reducers
import { chatSlice } from "./chat/chatSlice";
import { utils } from "./reducers/utils";
import { inconvenientes } from "./reducers/inconvenientes";
import { workshops } from "./reducers/workshops";
import { microlearnings } from "./reducers/microlearnings";
import { directories } from "./reducers/directories";
import { motivations } from "./reducers/motivations";
import { interests } from "./reducers/interests";
import { auth } from "./reducers/auth";
import { achievements } from "./reducers/achievements";
import { programs } from "./reducers/programs";
import { courses } from "./reducers/courses";
import { documents } from "./reducers/documents";
import { partner } from "./reducers/chat";
import { finances } from "./reducers/finances";
import { advisor } from "./reducers/advisor";
import { events } from "./reducers/events";
import { ondemand } from "./reducers/ondemand";
import { titulation } from "./reducers/titulation";
import { careers } from "./reducers/careers";
import { layout } from "./reducers/layout";

import { dracma } from "./reducers/dracma.reducer";
import { tickets } from "./reducers/tickets.reducer";
import { userStatus } from "./reducers/status.reducer";
import { alerts } from "./reducers/alerts";
import { subjects } from "./reducers/subjects";
import { summit } from "./reducers/summit";

// Sagas
import { chatWatcher } from "./sagas/chat";
import { utilsWatcher } from "./sagas/utils";
import { inconvenientesWatcher } from "./sagas/inconvenientes";
import { workshopsWatcher } from "./sagas/workshops";
import { microlearningsWatcher } from "./sagas/microlearnings";
import { directoriesWatcher } from "./sagas/directories";
import { motivationsWatcher } from "./sagas/motivations";
import { interestsWatcher } from "./sagas/interests";
import { authWatcher } from "./sagas/auth";
import { achievementsWatcher } from "./sagas/achievements";
import { programsWatcher } from "./sagas/programs";
import { coursesWatcher } from "./sagas/courses";
import { documentsWatcher } from "./sagas/documents";
import { financesWatcher } from "./sagas/finances";
import { advisorWatcher } from "./sagas/advisor";
import { eventsWatcher } from "./sagas/events";
import { ondemandWatcher } from "./sagas/ondemand";
import { dracmaWatcher } from "./sagas/dracma.saga";
import { ticketsWatcher } from "./sagas/tickets.saga";
import { statusWatcher } from "./sagas/status.saga";
import { subjectsWatcher } from "./sagas/subjects";
import { careersWatcher } from "./sagas/careers";
import { summitWatcher } from "./sagas/summit";

const appReducer = combineReducers({
    auth: auth,
    layout: layout,
    alerts: alerts,
    courses: courses,
    workshops: workshops,
    chat: chatSlice.reducer,
    chatPartner: partner,
    utils: utils,
    inconvenientes: inconvenientes,
    microlearnings: microlearnings,
    directories: directories,
    motivations: motivations,
    interests: interests,
    achievements: achievements,
    programs: programs,
    documents: documents,
    finances: finances,
    advisor: advisor,
    events: events,
    ondemand: ondemand,
    dracma: dracma,
    tickets: tickets,
    userStatus: userStatus,
    enrollment: subjects,
    titulation: titulation,
    careers: careers,
    summit: summit,
});

export const rootReducer = (state, action) => {
    if (action.type === "LOGOUT_USER") {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export function* rootSaga() {
    yield all([
        authWatcher(),
        utilsWatcher(),
        inconvenientesWatcher(),
        workshopsWatcher(),
        microlearningsWatcher(),
        directoriesWatcher(),
        motivationsWatcher(),
        interestsWatcher(),
        achievementsWatcher(),
        programsWatcher(),
        coursesWatcher(),
        documentsWatcher(),
        chatWatcher(),
        financesWatcher(),
        advisorWatcher(),
        eventsWatcher(),
        ondemandWatcher(),
        dracmaWatcher(),
        ticketsWatcher(),
        statusWatcher(),
        subjectsWatcher(),
        careersWatcher(),
        summitWatcher(),
    ]);
}
