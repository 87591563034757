import { useHistory } from "react-router-dom";
import styled from "styled-components";
import React from "react";

// Components
import SubjectFormativeModules from "./subjectBoard/SubjectFormativeModules";
import SubjectSecondaryInfo from "./subjectBoard/SubjectSecondaryInfo";
import CommonButton from "../../../../components/common/CommonButton";
import SubjectInfoCard from "./subjectBoard/SubjectInfoCard";
import { Icon } from "@iconify/react/dist/iconify.js";


const SubjectBoard = (props) => {
	const { subject, totalProgress, origin, programHash } = props;

	const { push } = useHistory()

	return (
		<BoardWrapper>
			<CommonButton
				label="Volver al programa"
				Icon={() => <Icon icon="solar:arrow-left-linear" width="24px" height="24px" />}
				variant="outlined"
				onClick={() => push(`/mi-progreso/${programHash}`)}
			/>
			<SubjectInfoCard subject={subject} totalProgress={totalProgress} />
			<SubjectSecondaryInfo />
			<SubjectFormativeModules
				modules={subject?.courses}
				origin={origin}
				subjectType={subject?.type}
			/>
		</BoardWrapper>
	);
};

export default SubjectBoard;

const BoardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	box-sizing: border-box;
	height: 100%;
	margin: 1rem 6rem;
	z-index: 25;

	@media (max-width: 1440px) {
		margin: 1rem 4rem;
	}

	@media (max-width: 768px) {
		margin: 0 0 4rem 0;
	}
`;
