import React, { useEffect, useState } from 'react';
import {
    StyledAccordion,
    StyledAccordionSummary,
    StyledAccordionDetails,
} from '../../../../styled-components';
import PericlesOutlineBlanco from '../../../../assets/media/aden/pericles-outlined-blanco.png';
import styled from 'styled-components';
import {
    capitalizeAllLetters,
    capitalizeFirstLetter,
} from '../../../../utils/stringUtils';
import { Icon } from '@iconify/react';
import { Text } from '../../../../components/common/Texts';
import EnrollPopUp from '../../../../components/pop-ups/EnrollPopUp';
import { useSelector } from 'react-redux';
import EnrollSuccessPopUp from '../../../../components/pop-ups/EnrollSuccessPopUp';
import { useHistory } from 'react-router-dom';
import * as encode from 'nodejs-base64-encode';
import EnrollErrorPopUp from '../../../../components/pop-ups/EnrollErrorPopUp';
import ProgramsModal from '../../../../components/templates/my-progress/ProgramsModal';
import useRelatedPrograms from '../../hooks/useRelatedPrograms';
import SimpleLoading from '../../../../components/common/SimpleLoading';
import ErrorImg from '../../../../assets/media/aden/error.png';
import MajorProgramCard from './MajorProgramCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MajorProgramAccordion = (props) => {
    const { program } = props;

    const history = useHistory();

    const [open, setOpen] = useState(true);
    const [openModalFromSis, setOpenModalFromSis] = useState(false);

    const { repo_id } = useSelector((state) => state?.auth?.user);

    const {
        loadingRelatedPrograms,
        programPlusInfo,
        errorRelatedPrograms,
        programToEnroll,
        openModal,
        openModalSuccess,
        openModalError,
        loadingFetch,
        fetchRelatedPrograms,
        enrollRelatedProgram,
        setProgramToEnroll,
        setOpenModal,
        setOpenModalSuccess,
        setOpenModalError,
    } = useRelatedPrograms();

    useEffect(() => {
        fetchRelatedPrograms(program?.id, repo_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [program]);

    const handleRedirect = (id) => {
        const hash = encode.encode(String(id ?? programToEnroll.id), 'base64');
        history.push(`../mi-progreso/${hash}`);
    };

    if (program?.from === 'sis') {
        return (
            <Wrapper>
                <StateWrapper>
                    <Text fontSize="16px" color="#222222" fontWeight="700">
                        Estado:
                    </Text>
                    <Chip state={program?.academic_phase}>
                        {capitalizeFirstLetter(program?.academic_phase)}
                    </Chip>
                </StateWrapper>
                <MajorProgramStyledAccordion
                    onClick={() => setOpenModalFromSis(true)}
                >
                    <MajorProgramAccordionSummary src={PericlesOutlineBlanco}>
                        <InfoContainer>
                            <label>
                                {capitalizeAllLetters(program?.type_program)}
                            </label>
                            <h2>{program?.program}</h2>
                            <ProgressWrapper>
                                <LinearProgressWrapper>
                                    <LinearProgress
                                        porcentaje={
                                            program?.total_progress > 100
                                                ? 100
                                                : program?.total_progress
                                        }
                                    />
                                </LinearProgressWrapper>
                                <Percentage>
                                    {program?.total_progress > 100
                                        ? 100
                                        : Math.round(program?.total_progress)}
                                    %
                                </Percentage>
                            </ProgressWrapper>
                        </InfoContainer>
                    </MajorProgramAccordionSummary>
                </MajorProgramStyledAccordion>
                <ProgramsModal
                    isOpen={openModalFromSis}
                    handleCloseModal={() => setOpenModalFromSis(false)}
                    program={program}
                />
            </Wrapper>
        );
    }

    return (
        <>
            {openModal && (
                <EnrollPopUp
                    handleClick={enrollRelatedProgram}
                    loadingFetch={loadingFetch}
                    setOpenModal={setOpenModal}
                    subjectName={programToEnroll?.name}
                />
            )}
            {openModalSuccess && (
                <EnrollSuccessPopUp
                    handleClick={() => handleRedirect(programToEnroll?.id)}
                    setOpenModal={setOpenModalSuccess}
                />
            )}
            {openModalError && (
                <EnrollErrorPopUp setOpenModal={setOpenModalError} />
            )}
            <Wrapper>
                <StateWrapper>
                    <Text fontSize="16px" color="#222222" fontWeight="700">
                        Estado:
                    </Text>
                    <Chip state={program?.state}>
                        {capitalizeFirstLetter(program?.state)}
                    </Chip>
                </StateWrapper>
                <MajorProgramStyledAccordion defaultExpanded>
                    <MajorProgramAccordionSummary
                        src={PericlesOutlineBlanco}
                        open={open}
                        onClick={() => setOpen(!open)}
                    >
                        <ArrowContainer open={open}>
                            {!open ? 'Ver más' : 'Ver menos'}{' '}
                            <KeyboardArrowDownIcon />
                        </ArrowContainer>
                        <InfoContainer>
                            <label>
                                {capitalizeAllLetters(program?.type_program)}
                            </label>
                            <h2>{program?.program}</h2>
                            <ProgressWrapper>
                                <LinearProgressWrapper>
                                    <LinearProgress
                                        porcentaje={
                                            program?.total_progress > 100
                                                ? 100
                                                : program?.total_progress
                                        }
                                    />
                                </LinearProgressWrapper>
                                <Percentage>
                                    {program?.total_progress > 100
                                        ? 100
                                        : Math.floor(
                                            program?.total_progress * 100
                                        ) / 100}
                                    %
                                </Percentage>
                            </ProgressWrapper>
                        </InfoContainer>
                        <DataContainer open={open}>
                            <span>
                                <Icon icon="ion:book" width={25} />
                                <p>
                                    {programPlusInfo?.amount_subprograms} Programas
                                </p>
                            </span>
                        </DataContainer>
                    </MajorProgramAccordionSummary>
                    <MajorProgramAccordionDetails open={open}>
                        <h3>
                            Programas que componen esta{' '}
                            {program?.type_program.toLowerCase()}:
                        </h3>
                        {errorRelatedPrograms ||
                            programPlusInfo?.subprograms?.length === 0 ? (
                            <ErrorContainer>
                                <img src={ErrorImg} alt="error" />
                                <h2>¡Ups! Hubo un error</h2>
                                <h4>Por favor intenta nuevamente.</h4>
                            </ErrorContainer>
                        ) : (
                            <>
                                {loadingRelatedPrograms ? (
                                    <SimpleLoading />
                                ) : (
                                    <>
                                        {
                                            programPlusInfo?.subprograms?.length === 0 ? (
                                                <WarningContainer>
                                                    <Icon icon="octicon:info-24" width="24px" height="24px" />
                                                    <label>
                                                        El programa no posee plan de estudio, <b>informar a su academic advisor.</b>
                                                    </label>
                                                </WarningContainer>
                                            ) : (
                                                <>
                                                    {programPlusInfo?.subprograms?.map(
                                                        (program, index) => {
                                                            return (
                                                                <MajorProgramCard
                                                                    program={program}
                                                                    index={index}
                                                                    programPlusInfo={
                                                                        programPlusInfo
                                                                    }
                                                                    setProgramToEnroll={
                                                                        setProgramToEnroll
                                                                    }
                                                                    setOpenModal={
                                                                        setOpenModal
                                                                    }
                                                                    handleRedirect={
                                                                        handleRedirect
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </>
                                            )
                                        }

                                    </>
                                )}
                            </>
                        )}
                    </MajorProgramAccordionDetails>
                </MajorProgramStyledAccordion>
            </Wrapper>
        </>
    );
};

export default MajorProgramAccordion;

const Wrapper = styled.div`
    position: relative;

    .css-1c35hjw-MuiPaper-root-MuiAccordion-root {
        margin-top: 0px;
    }
`;

const MajorProgramStyledAccordion = styled(StyledAccordion)`
    background-color: #f1f1f1;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    position: relative;
    margin: 0 !important;
    :before {
        content: '';
        position: absolute;
        width: 12px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #b31d15;
        border-radius: 20px 0 0 20px;
    }

    @media (width <= 678px) {
        height: 100%;
    }
`;

const ArrowContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    top: 10px;
    right: 10px;
    color: #222222;
    font-weight: bold;
    font-size: 1.05rem;
    svg {
        transition: 0.3s all ease-in-out;
        transform: ${(props) =>
        props.open ? 'rotate(-180deg)' : 'rotate(0deg)'};
        font-size: 2.2rem;
    }
`;

const StateWrapper = styled.div`
    position: absolute;
    /* top: -20px; */
    margin: auto;
    right: 0rem;
    top: -3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media (width <= 768px) {
        top: -3.5rem;

        div {
            display: none;
        }
    }
`;

const Chip = styled.span`
    width: fit-content;
    padding: 7px 16px;
    border-radius: 8px;

    background-color: ${(p) =>
        p.state === 'preinscripto'
            ? '#f9f5ec'
            : p.state === 'confirmado' ||
                p.state === 'cursando' ||
                p.state === 'Cursando'
                ? '#E6EBF9'
                : p.state === 'baja'
                    ? '#FCE9E8'
                    : p.state === 'egresado' || p.state === 'Egresado'
                        ? '#EAFAF6'
                        : '#E9C0F0'};
    font-weight: 600;
    color: ${(p) =>
        p.state === 'preinscripto'
            ? '#C29F43'
            : p.state === 'confirmado' || p.state === 'Cursando'
                ? '#5E80DB'
                : p.state === 'baja'
                    ? '#B31D15'
                    : p.state === 'egresado' || p.state === 'Egresado'
                        ? '#1E8065'
                        : p.state === 'DEMO' && '#A43EB5'};
`;

const MajorProgramAccordionSummary = styled(StyledAccordionSummary)`
    background-color: #f1f1f1;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 360px ${(props) => (props.open ? '100%' : '132%')};
    height: ${(props) => (props.open ? '220px' : '180px')} !important;
    display: flex;
    align-items: start;
    padding-left: 3rem;
    padding-top: 1rem;
    border-bottom: ${(props) => props.open && '1px'} solid #c4c4c4;
    transition: 0.3s all;
    border-radius: ${(props) =>
        props.open ? '20px 20px 0 0' : '20px'} !important;

    .MuiAccordionSummary-content {
        height: 90%;
        flex-direction: column !important;
        justify-content: start !important;
    }

    label {
        background-color: #b31d15;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #fff;
        height: 34px;
        width: fit-content;
        padding: 0 1rem;
    }

    h2 {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
    }

    :before {
        content: '';
        position: absolute;
        width: 12px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #b31d15;
        border-radius: ${(props) =>
        props.open ? '20px 0 0 0' : '20px 0 0 20px'};
    }

    @media (width <= 768px) {
        background-image: none;
        padding: 0 3rem;
        height: 212px !important;

        .MuiAccordionSummary-content {
            margin: 0 !important;
        }

        h2 {
            font-size: 20px;
        }

        label {
            background-color: transparent;
            color: #222;
            font-size: 14px;
            font-weight: 700;
            padding: 0;
            height: fit-content;
        }
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: start;

    @media (width <= 768px) {
        height: 100%;
        justify-content: space-evenly;
    }
`;

const DataContainer = styled.div`
    display: ${(props) => (props.open ? 'flex' : 'none')};
    height: 100%;
    align-items: center;
    justify-content: start;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        svg {
            color: #b31d15;
        }

        p {
            font-size: 16px;
            font-weight: 600;
        }
    }

    @media (width <= 768px) {
        display: none;
    }
`;

const ProgressWrapper = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;

    @media (width <= 768px) {
        width: 100%;
    }
`;

const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 12px;
    background-color: ${(props) => (props.second ? '#f1f1f1' : '#fff')};
    padding: 0 3px;
    display: flex;
    align-items: center;
    justify-content: start;
`;

const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(props) => `${props.porcentaje}%`};
    height: 9px;
    background-color: #35d0a5;
`;

const Percentage = styled.span`
    font-size: 20px;
    color: #222;
    flex-wrap: wrap;
`;

const ErrorContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    h2 {
        color: #b31d15;
    }

    img {
        width: 300px;
        height: 300px;
    }

    @media (width <= 768px) {
        img {
            width: 200px;
            height: 200px;
        }
    }
`;

const WarningContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: .5rem;

    svg {
        color: #C29F43;
    }
`

const MajorProgramAccordionDetails = styled(StyledAccordionDetails)`
    background-color: #f1f1f1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6rem;
    padding: 4rem 2rem;

    h3 {
        color: #616161;
        font-weight: 500;
        font-size: 20px;
    }

    :before {
        content: '';
        position: absolute;
        width: 12px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #b31d15;
        border-radius: 0 0 0 20px;
    }

    @media (width <= 768px) {
        padding: 1rem 2rem 12rem 2rem;
        gap: 15rem;

        h3 {
            font-size: 16px;
            text-align: center;
            margin-bottom: -8rem;
        }
    }
`;
