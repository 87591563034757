export const publicProfileTeacherAdapter = (data) => {
    return {
        photo: data.photo,
        academicFormation: data.academic_formation,
        books: data.books.map((book) => ({
            title: book.title,
            year: book.anio,
            editorial: book.editorial,
            isbn: book.isbn,
            roleWriting: book.role_writing,
        })),
        notes: data.notes.map((note) => ({
            noteDescription: note.note_description,
            noteURL: note.note_url,
            noteImage: note.note_image,
            noteTitle: note.note_title,
        })),
        professionalExperience: data.professional_experience,
        socialNetworks: data.social_networks.map((social) => ({
            name: social.name,
            url: social.url,
        })),
        teachingAreas: data.teaching_areas.map((area) => ({
            programName: area.program_name,
            programURL: area.programa_url,
        })),
    }
}