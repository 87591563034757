import React, { useState } from 'react';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import Contact from '../../components/ui/organisms/profile/Contact';
import Socials from '../../components/ui/organisms/profile/Socials';
import { titleHTML } from '../../helpers/title.helper';
import DocumentationTeacher from '../../components/ui/organisms/profile/teacher/DocumentationTeacher';
import AcademicInfo from '../../components/ui/organisms/profile/teacher/AcademicInfo';
import HeaderTeacherInfo from '../../components/templates/profile/teacher/HeaderTeacherInfo';
import MainProfileTeacher from '../../components/templates/profile/teacher/MainProfileTeacher';

const ProfileTeacher = () => {
    titleHTML("Mi perfil");

    // REDUX
    const user = useSelector((state) => state.auth.user);
    const { repositorio_data } = user
    const { info_personal, info_educativa } = repositorio_data

    return (
        <ProfileContainer>
            {/* <CustomBreadcrumbs /> */}
            <HeaderTeacherInfo
                foto={user.foto}
                name={user.name}
                city={info_personal?.mobile || '-'}
                country={info_personal?.nacionalidad}
                biography={user.biography}
                street={info_personal?.street}
                zip={user.zip === null ? false : user.zip}
            />
            <Body>
                <Aside>
                    <AcademicInfo
                        title={info_educativa?.titulo_universitario || 'Sin registro'}
                        year={info_educativa?.anos_en_industria || '-'}
                        university={info_educativa?.universidad || '-'}
                        maxTitle={info_educativa?.titulo_maximo || '-'}
                    />
                    <Contact
                        phone={info_personal?.phone || 'Sin registro'}
                        mobile={info_personal?.mobile || 'Sin registro'}
                        work_email={info_personal?.email_laboral || 'Sin registro'}
                        personal_email={info_personal?.email || 'Sin registro'}
                    />
                    <Socials
                        facebook={user.facebook}
                        instagram={user.instagram}
                        skype={user.skype}
                        linkedin={user.linkedin}
                        twitter={user.twitter}
                        whatsapp={user.whatsapp}
                    />
                </Aside>
                <BodyContainer>
                    <MainProfileTeacher />
                    <DocumentationTeacher user={user} />
                </BodyContainer>
            </Body>
        </ProfileContainer>
    );
};

export default ProfileTeacher;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

const Body = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 1.5rem;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
    }
`;

const Aside = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;