import { useState } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Icon } from "@iconify/react/dist/iconify.js";

// Material UI
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

const SubjectAccordion = (props) => {
    const {
        summary: SummaryComponent,
        details: DetailsComponent,
        data = [],
        handleClick = () => '',
        isCompletedBg,
        description,
        disabled = false,
        decorator = false,
        defaultExpanded = false,
        decoratorHeight = false,
        decoratorWidth = '2px',
        decoratorRadius = '0',
        decoratorColor = '#b31d15',
        decoratorInset = '0px 0px 0px 0px',
        borderRadius = '0px',
        height = '150px',
        detailsPadding = '0',
        statusBlock = false,
        reasonBlocked = '',
        boxShadow = '0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -4px rgba(24, 39, 75, 0.2)',
        border = 'none',
        showInfo,
        hasDescription = true
        // disabledLast = true
    } = props;

    const [expanded, setExpanded] = useState(defaultExpanded);

    const handleAccordion = () => {
        if (hasDescription) {
            setExpanded(!expanded);
        }
    };

    return (
        <FakeAccordion
            borderRadius={borderRadius}
            expanded={showInfo === undefined ? expanded : !showInfo ? !expanded : expanded}
            title={
                disabled && statusBlock
                    ? `Lamentablemente, no puedes realizar esta actividad en este momento. El acceso está temporalmente bloqueado debido a ${reasonBlocked}`
                    : ''
            }
            isCompletedBg={isCompletedBg}
            disabled={disabled}
            onChange={handleAccordion}
            decorator={decorator}
            decoratorInset={decoratorInset}
            decoratorColor={decoratorColor}
            decoratorHeight={decoratorHeight}
            decoratorWidth={decoratorWidth}
            decoratorRadius={decoratorRadius}
            boxShadow={boxShadow}
            border={border}
        >
            <FakeAccordionSummary
                borderRadius={borderRadius}
                isCompletedBg={isCompletedBg}
                height={height}
            >
                {!!SummaryComponent && (
                    <>
                        {
                            hasDescription &&
                            <ExpandMoreInfoContainer>
                                <label>{expanded ? "Ver menos" : "Ver más"}</label>
                                <ExpandMoreInfo
                                    icon="lets-icons:expand-down-light"
                                    width="1.2em"
                                    height="1.2em"
                                    visibility={true}
                                    expanded={expanded} />
                            </ExpandMoreInfoContainer>
                        }
                        <SummaryComponent
                            data={data}
                            description={description}
                            handleClick={handleClick}
                            // disabledLast={disabledLast}
                            {...props}
                        />
                    </>
                )}
            </FakeAccordionSummary>
            <FakeAccordionDetails
                borderRadius={borderRadius}
                isCompletedBg={isCompletedBg}
                detailsPadding={detailsPadding}
            >
                {!!DetailsComponent && (
                    <DetailsComponent
                        handleClick={handleClick}
                        description={description}
                        {...props}
                    />
                )}
            </FakeAccordionDetails>
        </FakeAccordion>
    );
};

SubjectAccordion.propTypes = {
    SummaryComponent: propTypes.element,
    DetailsComponent: propTypes.element,
    data: propTypes.array,
    handleClick: propTypes.func,
    size: propTypes.bool,
    isCompletedBg: propTypes.bool,
    description: propTypes.bool,
    decorator: propTypes.bool,
};

export default SubjectAccordion;

const FakeAccordion = styled(Accordion)`
    position: relative;
    border: ${(p) => p.border};
    box-shadow: ${(p) => p.boxShadow};
    outline: none;
    background-color: ${(p) =>
        p.isCompletedBg === 100 ? '#f1f1f1' : '#FFFFFF'};
    width: 100%;
    border-radius: ${(p) => p.borderRadius} !important;
    z-index: 1;

    &::after {
        visibility: ${(p) => (p.decorator ? 'visible' : 'hidden')};
        position: absolute;
        inset: ${(p) => p.decoratorInset};
        content: ' ';
        width: ${(p) => p.decoratorWidth};
        height: ${(p) =>
        p.decoratorHeight && p.expanded
            ? p.decoratorHeight
            : p.decoratorHeight
                ? p.decoratorHeight
                : p.expanded && !p.decoratorHeight
                    ? 'calc(100% - 9.7rem)'
                    : '0'};
        background: ${(p) => p.decoratorColor};
        border-radius: ${(p) => p.decoratorRadius};
        transition: all ease 0.3s;
    }

    &.css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
        border-radius: ${(p) => p.borderRadius};
    }
    &.MuiPaper-elevation {
        margin: 0px;
    }
    &.MuiAccordion-root:before {
        display: none;
    }

    @media (max-width: 1024px) {
        min-width: 250px;
        min-height: 150px;
        // Height
    }
`;

const FakeAccordionSummary = styled(AccordionSummary)`
    position: relative;
    border: none;
    outline: none;
    background-color: ${(p) =>
        p.isCompletedBg === 100 ? '#f1f1f1' : '#FFFFFF'};
    height: ${(p) => p.height} !important;
    border-radius: ${(p) => p.borderRadius};
    padding-right: 3rem;

    @media (max-width: 1024px) {
        padding-right: 1rem;
    }

    @media (max-width: 768px) {
        height: 100% !important;
    }
`;

const ExpandMoreInfoContainer = styled.div`
    height: fit-content;
    position: absolute;
    top: 2rem;
    bottom: 0;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        color: #616161;
    }
`

const ExpandMoreInfo = styled(Icon)`
    width: 30px;
    height: 30px;
    color: #000000;
    visibility: ${(p) => (p.visibility ? 'visible' : 'hidden')};
    transform: ${(p) => (p.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: all 0.4s ease;

    @media (max-width: 768px) {
        top: -14rem;
        right: 0.5rem;
    }
`;

const FakeAccordionDetails = styled(AccordionDetails)`
    position: relative;
    border: none;
    outline: none;
    background-color: ${(p) =>
        p.isCompletedBg === 100 ? '#f1f1f1' : '#FFFFFF'};
    height: auto;
    border-radius: ${(p) => p.borderRadius};
    padding: ${(p) => p.detailsPadding};
`;
