import axios from "axios";

export async function getArticles() {
    const URL = `${process.env.REACT_APP_CMS_ADENAPI}/blog-articles/feed/aden`;
    try {
        const request = await fetch(URL);
        return await request.json();
    } catch (error) {
        console.log("error", error);
        return { error };
    }
}
// BLOG ARTICULOS POR EMPRESA
export async function getArticlesByCompany(repoId) {
    const URL = `${process.env.REACT_APP_CMS_ADENAPI}/blog-articles?populate=*&filters[company][repo_id][$eq]=${repoId}`;
    try {
        const request = await fetch(URL);
        return await request.json();
    } catch (error) {
        console.log("error", error);
        return { error };
    }
}

export async function getInfoEvents(id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/events/${id}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}

export async function getComments() {
    const URL = `${window.location.origin}/mock/blog/comments.json`;
    try {
        const response = await axios.get(URL);
        return response.data.response_data.comments;
    } catch (error) {
        return { error };
    }
}

export async function getAdsByRolAndCompany(company, userRol) {
    // COMENTADO PORQUE NO ME ANDA AMBIENTE DE TESTING
    const URL = !!company
        ? `${process.env.REACT_APP_CMS_ADENAPI}/slides?populate=*&filters[company][repo_id][$eq]=${company}&filters[user_roles][name][$eq]=${userRol}`
        : `${process.env.REACT_APP_CMS_ADENAPI}/slides?populate=*&filters[user_roles][name][$eq]=${userRol}`;

    try {
        const request = await fetch(URL);
        return await request.json();
    } catch (error) {
        return { error };
    }
}
