import { useEffect, useState } from 'react';
import { getCourseByIdServices } from '../redux/api/courses';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../utils/snackStyles';

const useCourseById = (id) => {

    const [course, setCourse] = useState(null);

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!course && !!id) getCourseById(id);
    }, [course]);

    const getCourseById = async (id) => {
        const request = await getCourseByIdServices(id);
        if (request.error) {
            openSnackbar('¡No se pudo obtener la información del curso!');
            setCourse(null);
            return;
        }
        setCourse(request);
    };

    return { course };
};

export default useCourseById;
