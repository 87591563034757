import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';

const AnnouncementSkeleton = () => {
    return (
        <SkeletonWrapper>
            <Skeleton
                variant="rounded"
                animation="wave"
                width="25%"
                height={30}
            />
            <SkeletonCardsWrapper>
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    width="20%"
                    height={40}
                />
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    width="100%"
                    height={174}
                />
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    width="100%"
                    height={174}
                />
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    width="100%"
                    height={174}
                />
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    width="100%"
                    height={174}
                />
            </SkeletonCardsWrapper>
        </SkeletonWrapper>
    )
}

export default AnnouncementSkeleton;

const SkeletonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

const SkeletonCardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
`