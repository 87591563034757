import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { CloseRounded, MenuRounded } from '@mui/icons-material';

// COMPONENTS
import Logo from './Logo';
import styled from 'styled-components';

const Navegation = () => {
    const [menu, setMenu] = useState(false);
    const [navbar, setNavbar] = useState(false);

    function handleMenu() {
        setMenu(!menu);
    }

    const width = window.innerWidth > 0 ? window.innerWidth : window.width;

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener('scroll', changeBackground);

    const bgColor = '#ffffff';
    const bgTransparent = 'transparent';

    return (
        <HeaderFake
            bgColor={bgColor}
            bgTransparent={bgTransparent}
            navbar={navbar}
        >
            <Logo />
            <Button onClick={handleMenu}>
                {menu ? (
                    <CloseRounded
                        style={{ color: navbar ? '#b31d15' : '#ffffff' }}
                    />
                ) : (
                    <MenuRounded
                        style={{ color: navbar ? '#b31d15' : '#ffffff' }}
                        fontSize="large"
                    />
                )}
            </Button>
            <NavFake menu={menu}>
                <LinkLogin to="auth">Ingresar</LinkLogin>
                <LinkRegistration to="auth/?registration=true">
                    Registrarme
                </LinkRegistration>
            </NavFake>
        </HeaderFake>
    );
};

export default Navegation;

const HeaderFake = styled.header`
    background: ${(p) => (p.navbar ? p.bgColor : p.bgTransparent)};
    box-shadow: ${(p) =>
        p.navbar ? '0px 5px 20px rgba(0, 0, 0, 0.06)' : 'none'};
    transition: background-color 0.4s ease-out;
    padding: 0 5rem;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    @media screen and (max-width: 768px) {
        padding: 0 2rem;
    }
`;

const NavFake = styled.nav`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    background-color: transparent;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 80%;
        position: absolute;
        top: 100px;
        right: 0;
        left: ${(p) => (p.menu ? 0 : '-100%')};
        background-color: #ffffff;
        height: 90vh;
        opacity: 1;
        transition: all 0.5s ease;
        padding: 10%;
    }
`;

const LinkLogin = styled(Link)`
    border: ${(p) => (p.navbar ? '1px solid #b31d15' : '1px solid #ffffff')};
    color: #b31d15;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0.25rem 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        opacity: 0.8;
    }

    @media screen and (max-width: 768px) {
        border: 1px solid #b31d15;
        padding: 1rem 1.2rem;
        font-size: 1.2rem;
        margin: 1rem;
    }
`;

const LinkRegistration = styled(Link)`
    background-color: #b31d15;
    color: #ffffff;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        background-color: #921009;
        color: #ffffff;
    }

    @media screen and (max-width: 768px) {
        padding: 1rem 1.2rem;
        font-size: 1.2rem;
        margin: 1rem;
    }
`;

const Button = styled.button`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
    }
`;
