import { Card } from "@mui/material";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import useFavorites from "../../../hooks/useFavorites";
import SimpleLoading from "../../common/SimpleLoading";
import TabsComponent from "../../common/TabsComponent";
import MainTabs from "../../ui/organisms/favorites/MainTabs";

const MainFavs = () => {
    const tabs = [
        { label: "Mis recursos", id: 0 },
        { label: "Mis cursos", id: 1 },
        { label: "Mis contactos", id: 2 },
    ];

    const [tab, setTab] = useState(0);

    // HOOK
    const { favs } = useFavorites();

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
    };

    if (favs === null) {
        return <SimpleLoading />;
    }

    // console.log("favoritos", favs);

    return (
        <MainFavsWrapper>
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            <MainTabs favs={favs} tab={tab} />
        </MainFavsWrapper>
    );
};

export default MainFavs;

const MainFavsWrapper = styled(Card)`
    border-radius: 20px;
    margin-top: 1.5rem;
`;
