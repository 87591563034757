import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SimpleLoading from "../../../common/SimpleLoading";
import { ReactComponent as NotFoundSvg } from "../../../../assets/media/svg/404-Error-pana.svg";

const MainPayOrder = (props) => {
    const { payOrders, loadingPayOrder } = props;

    const state = {
        cancel: "Cancelado",
        draft: "Borrador",
        confirmed: "Confirmado",
        paid_out: "Pagado",
    };

    const statePurchase = {
        cancel: "Cancelado",
        draft: "Borrador",
        confirmed: "Confirmado",
        paid_out: "Pagado",
        sent: "Petición enviada",
        to_approve: "Para aprobar",
        purchase: "Pedido de compra",
        done: "Bloqueado",
        check: "Revisado por compras",
    };

    const handleState = (value) => {
        return state[value];
    };

    const handleStatePurchase = (value) => {
        const valueTransform = value.replace(" ", "_");

        return statePurchase[valueTransform];
    };

    // RETURN
    if (loadingPayOrder) {
        return (
            <div>
                <SimpleLoading />
            </div>
        );
    }

    
    return (
        <div>
            {!payOrders ? (
                <MessageErrorRequest>
                    <NotFoundSvg width="37%" />
                    Hubo un problema al mostrar las ordenes de pago
                </MessageErrorRequest>
            ) : payOrders.length > 0 ? (
                <div>
                    {payOrders.map((payOrder) => (
                        <AccordionFake>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Container>
                                    <Header>
                                        <p>Orden</p>
                                        <p>Referencia</p>
                                        <p>Fecha de creación</p>
                                        <p>Fecha de pago</p>
                                        <p>Responsable</p>
                                        <p>Método de pago</p>
                                        <p>Estado</p>
                                        <p>Monto</p>
                                    </Header>
                                    <Body>
                                        <p>{payOrder?.name}</p>
                                        <p>{payOrder?.ref || "-"}</p>
                                        <p>{payOrder?.create_order}</p>
                                        <p>
                                            {payOrder?.estimated_payment_date}
                                        </p>
                                        <p>{payOrder?.responsible}</p>
                                        <p>
                                            {payOrder?.payment_method_id || "-"}
                                        </p>
                                        <p>{handleState(payOrder?.state)}</p>
                                        <p>
                                            {payOrder?.currency}{" "}
                                            {payOrder?.amount}
                                        </p>
                                    </Body>
                                </Container>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Title>Pedidos de compra</Title>
                                <Container>
                                    <HeaderDetails>
                                        <p>Orden</p>
                                        <p>Referencia</p>
                                        <p>Fecha de orden</p>
                                        <p>Fecha de actividad</p>
                                        <p>Responsable</p>
                                        <p>Estado</p>
                                        <p>Monto</p>
                                    </HeaderDetails>
                                    {payOrder?.purchase_orders.map(
                                        (purchaseOrders) => (
                                            <BodyDetails>
                                                <p>{purchaseOrders?.name}</p>
                                                <p>{purchaseOrders?.ref}</p>
                                                <p>
                                                    {purchaseOrders?.date_order}
                                                </p>
                                                <p>
                                                    {
                                                        purchaseOrders?.activity_date
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        purchaseOrders?.responsible
                                                    }
                                                </p>
                                                <p>
                                                    {handleStatePurchase(
                                                        purchaseOrders?.state
                                                    )}
                                                </p>
                                                <p>
                                                    {purchaseOrders?.currency}{" "}
                                                    {purchaseOrders?.amount}
                                                </p>
                                            </BodyDetails>
                                        )
                                    )}
                                </Container>
                            </AccordionDetails>
                        </AccordionFake>
                    ))}
                </div>
            ) : (
                <MessageErrorRequest>
                    <NotFoundSvg width="37%" />
                    No se encontraron ordenes de pago
                </MessageErrorRequest>
            )}
        </div>
    );
};

export default MainPayOrder;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const AccordionFake = styled(Accordion)`
    border-radius: 20px !important;
    box-shadow: none;
    &::before {
        content: "";
        width: 0px;
        height: 0px;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: calc(100% - 1rem);
    padding: 0.5rem;
    background-color: #d9d9d9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    p {
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: uppercase;
        flex-basis: calc(12.5% - 1rem);
    }
`;
const Body = styled.div`
    width: 100%;
    display: flex;
    width: calc(100% - 1rem);
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    background-color: #f9f9f9;
    padding: 0.5rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    p {
        font-size: 0.85rem;
        flex-basis: calc(12.5% - 1rem);
    }
`;

const Title = styled.h3`
    font-size: 1.2rem;
    color: #b31d15;
    text-decoration: underline;
`;

const HeaderDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: calc(100% - 1rem);
    padding: 0.5rem;
    background-color: #e94c4414;
    border: solid 1px #e94c4414;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    p {
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: uppercase;
        flex-basis: calc(14.28% - 1rem);
    }
`;

const BodyDetails = styled.div`
    width: 100%;
    display: flex;
    width: calc(100% - 1rem);
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-left: solid 1px #d9d9d9;
    border-right: solid 1px #d9d9d9;
    border-bottom: solid 1px #d9d9d9;
    p {
        font-size: 0.85rem;
        flex-basis: calc(14.28% - 1rem);
    }
`;

const MessageErrorRequest = styled.div`
    width: 100%;
    height: 100%;
    color: #b31d15;
    font-weight: 600;
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    flex-direction: column;
`;
