import { MenuItem } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'

// Recibe un Array de objectos 
// Formato [ { value: "Value", name: "Name" } ]
const DefaultSelectFilter = (props) => {

    const { options = [], handleFilter, initialOrders } = props;

    const [activeOption, setActiveOption] = useState("all")

    const handleFilterArray = (value, name) => {
        setActiveOption(value)
        const result = value === 'all' ? initialOrders : initialOrders.filter(item => item.initialState === value)
        handleFilter(result)
    }

    return (
        <Wrapper>
            <MenuItemFake 
            active={activeOption === "all"}
            onClick={() => handleFilterArray('all', null)}>TODOS</MenuItemFake>
            {options.map(item => <MenuItemFake active={activeOption === item.id}
            onClick={() => handleFilterArray(item.id, item.name)}>{item.name.toUpperCase()}</MenuItemFake>)}
        </Wrapper>)
}

export default DefaultSelectFilter;

const Wrapper = styled.div`
    display: flex;
    gap: 1rem;
`

const MenuItemFake = styled(MenuItem)` 
    background-color: ${(props) => props.active ? "#fff" : "transparent"};
    box-shadow: ${(props) => props.active ? "0px 5px 10px 0px #0000000F, 0px 3px 5px 0px #00000026" : ""};
    border-radius: 5px;
    color: ${(props) => props.active ? "#b31d15" : "#949494"};
    font-weight: ${(props) => props.active ? "700" : "400"};
`