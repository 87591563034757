import { InputAdornment, TextField } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SearchBar = (props) => {
    const {
        title = 'Buscar...',
        filterNames = [],
        propsValues = [],
        handleSearchValue
    } = props;

    const [search, setSearch] = useState('');

    //limpiar búsqueda para apertura de modales
    useEffect(() => {
        setSearch('');
    }, []);

    // FUNCTIONS
    const handleChange = (e) => {
        const search = e.target.value;
        if (search.length > 0) handleFilter(search, filterNames, propsValues);
        else handleSearchValue(propsValues);
        setSearch(e.target.value);
    };

    const handleFilter = (search, filterNames, propsValues) => {
        let filtered = [];
        for (let i = 0; i < filterNames.length; i++) {
            let array = propsValues;
            const result = array.filter((item) => {
                const valueBar = item[filterNames[i]];
                if (typeof valueBar === 'string') {
                    return valueBar
                        .toLowerCase()
                        .includes(search.toLowerCase());
                } else {
                    return String(valueBar).includes(search);
                }
            });
            if (result.length > 0) {
                filtered = result;
                break;
            }
        }
        handleSearchValue(filtered);
        return filtered;
    };

    return (
        <Container>
            <TextField
                value={search}
                onChange={handleChange}
                size="small"
                label=""
                placeholder={title}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchRounded />
                        </InputAdornment>
                    )
                }}
            />
        </Container>
    );
};

export default SearchBar;

const Container = styled.div`
    display: inline-flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    padding: 9px 15px;
    box-sizing: border-box;
    height: 50px;
    background: #f9f9f9;
    border-radius: 5px;

    .MuiInput-underline:before {
        border-bottom: none;
    }
    .MuiInput-underline:after {
        border-bottom: none;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
    }

    .MuiSvgIcon-root {
        color: #a8a8a8;
    }
`;
