import React from 'react';
import Students from '../../teacher/persons/Students';
// import Students from '../persons/Students';

const Persons = (props) => {

    const { id } = props

    return <Students id={id}/>;
};

export default Persons;
