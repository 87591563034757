import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SimpleLoading from '../../components/common/SimpleLoading';
import { titleHTML } from '../../helpers/title.helper';

const Challege = () => {
    titleHTML("Challenge");

    const ondemand = useSelector((state) => state.ondemand.ondemand);
    const user = useSelector((state) => state.auth.user);

    if (ondemand === null) {
        return <SimpleLoading />;
    }

    return (
        <Container>
            <CustomIframe src={ondemand?.challenge_url} frameborder="0" />
        </Container>
    );
};

export default Challege;

const Container = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 2rem);
`;

const CustomIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
`;
