import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import WhatsappBtn from '../../components/common/WhatsappBtn';
import PublicityWithoutStudent from '../../components/templates/auth/PublicityWitoutStudent';
import RegisterStudent from '../../components/templates/auth/RegisterStudent';
import * as encrypt from 'nodejs-base64-encode';

const RegisterPageStudent = () => {
    const { id } = useParams();

    const decodeId = encrypt.decode(id + '', 'base64');

    return (
        <RegisterPageContainer>
            <PublicityWithoutStudent />
            <RegisterStudent id={decodeId} />
            <WhatsappBtn x="right: 7rem" y="bottom: 1.5rem" />
        </RegisterPageContainer>
    );
};

export default RegisterPageStudent;

const RegisterPageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    height: 100vh;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            height: 100%;
            max-width: 90vw;
        }
    }
`;