import { Card } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../../common/Texts';
import SplitButton from '../../../common/SplitButton';
import Task from '../../../ui/organisms/teacher/qualification/Task';
import useTaskTeacher from '../../../../hooks/teacher/useTaskTeacher';
import SimpleLoading from '../../../common/SimpleLoading';
import ErrorResponse from '../../../common/ErrorResponse';

const Qualification = (props) => {
    const { idCourse = null, type = 'all', idTeacher = null } = props;

    const options = ['Por defecto', 'Más antiguo', 'Más nuevo'];

    const { taskTeacher } = useTaskTeacher(type, idCourse, idTeacher);

    const [currentFilter, setCurrentFilter] = useState({
        index: 1,
        name: 'Más antiguo',
    });

    const handleOption = (args) => {
        setCurrentFilter(args);
    };

    if (taskTeacher === null) {
        return (
            <OtherCard>
                <SimpleLoading />
            </OtherCard>
        );
    }

    if (taskTeacher === false) {
        return (
            <OtherCard>
                <ErrorResponse message="Ocurrio un problema" />
            </OtherCard>
        );
    }

    if (taskTeacher.length === 0) {
        return (
            <OtherCard>
                <ErrorResponse message="No hay ninguna tarea pendiente" />
            </OtherCard>
        );
    }

    return (
        <QualificationWrapper>
            <Header>
                <Text color="#555555" fontWeight="700" fontSize="1.2rem">
                    Por calificar
                </Text>
                <SplitButton
                    handleOption={handleOption}
                    options={options}
                    active={currentFilter.index}
                />
            </Header>
            <List>
                {currentFilter !== null && (
                    <>
                        {currentFilter.index === 0 &&
                            taskTeacher.map((a) => <Task key={a} values={a} />)}
                        {currentFilter.index === 1 &&
                            taskTeacher
                                .sort(
                                    (a, b) =>
                                        new Date(
                                            a.submission.create_date
                                        ).getTime() -
                                        new Date(
                                            b.submission.create_date
                                        ).getTime()
                                )
                                .map((a) => <Task values={a} />)}
                        {currentFilter.index === 2 &&
                            taskTeacher
                                .sort(
                                    (a, b) =>
                                        new Date(
                                            b.submission.create_date
                                        ).getTime() -
                                        new Date(
                                            a.submission.create_date
                                        ).getTime()
                                )
                                .map((a) => <Task values={a} />)}
                    </>
                )}
            </List>
        </QualificationWrapper>
    );
};

export default Qualification;

const QualificationWrapper = styled(Card)`
    grid-area: qualification;
    display: flex;
    row-gap: 1.5rem;
    flex-direction: column;
    height: auto;
    border-radius: 25px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1.5rem;
`;

const OtherCard = styled(Card)`
    grid-area: qualification;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    border-radius: 25px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const List = styled.div`
    height: 500px;
    overflow-y: auto;

    @media (max-width: 1000px) {
        height: 300px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;
