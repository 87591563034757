import { useState, useEffect } from "react";
import { microlearningsActions } from "../../redux/actions";
import { postFav, postTakeOutFav } from "../../redux/api/utils";
import { useDispatch, useSelector } from "react-redux";

const useLike = () => {
  const { repo_id } = useSelector((state) => state.auth.user);
  const { favorito } = useSelector(
    (state) => state.microlearnings.currentMicrolearning
  );

  const dispatch = useDispatch();

  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    setFavorite(favorito);
  }, [favorito]);
  
  const handleStateRedux = (id, type) => {
    dispatch(
      type === "like"
        ? microlearningsActions.setLikes(id)
        : microlearningsActions.setNoLikes(id)
    );
  };

  const setFavoriteItem = async (resourceId) => {
    const req = await postFav({
      resource: resourceId,
      repo_id,
      type: "recurso",
    });
    if (!req.error) {
      setFavorite(true);
      handleStateRedux(resourceId, "like");
    }
  };

  const removeFavoriteItem = async (resourceId) => {
    const req = await postTakeOutFav({
      resource: resourceId,
      repo_id,
      type: "recurso",
    });
    if (!req.error) {
      setFavorite(false);
      handleStateRedux(resourceId, "no-like");
    }
  };

  const handleFavorite = (resourceId) =>
    !favorite ? setFavoriteItem(resourceId) : removeFavoriteItem(resourceId);

  return { handleFavorite, favorite, favorito };
};

export default useLike;
