import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from '@mui/material';
import Text from '../Text';
import { postAssignmentPrograms } from '../../../redux/api/studyPlan';
import { useSnackbar } from 'react-simple-snackbar';
import { optionsStyle } from '../../../utils/snackStyles';
import { useHistory, useParams } from 'react-router-dom';
import SimpleLoading from '../../common/SimpleLoading';
import * as encode from 'nodejs-base64-encode';

const ModalAssignment = (props) => {
    const {
        handleClick,
        data,
        repo_id,
        setDataAssigment,
        dataAssigment,
        setIsChange,
        idProgram,
        setIsFlag,
        isFlag,
        isDiplomat,
        // setIsSelected
    } = props;

    const history = useHistory();

    const hash = encode.encode(data.id + '', 'base64');
    const { id } = useParams();

    // REDUX
    const [openSnackbar] = useSnackbar(optionsStyle);

    // STATE
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    // FUNCTIONS
    function handleModal() {
        handleClick();
        setIsOpen(false);
        // setIsSelected(true)
    }

    const handlePost = () => {
        postAssignment('assignment', data.id);
    };

    const postAssignment = async (type, assignment_id) => {
        const body = {
            type: type,
            assignment: assignment_id,
            program_id: idProgram,
        };

        setIsLoading(true);
        const response = await postAssignmentPrograms(repo_id, body);

        if (!response.error) {
            const result = dataAssigment;

            result.forEach((element) => {
                if (element.id === data.id) {
                    element.state = 'confirmado';
                    setIsChange(true);
                    setIsFlag(!isFlag);
                }
            });

            setTimeout(() => {
                setIsChange(false);
            }, 300);

            setDataAssigment(result);
            openSnackbar('¡Matriculación correcta!');
            history.push(
                `../asignatura/${hash}?program=${id}&origin=${
                    isDiplomat ? 'diplomado' : 'plan'
                }`
            );

            setIsLoading(false);
            return;
        }
        setIsLoading(false);
        openSnackbar('¡No se pudo matricular a la asignatura!');
    };

    // RETURN
    return (
        <>
            <Modal onClose={handleModal} open={isOpen}>
                <Container>
                    <TextWrapper>
                        <Text
                            fontSize="1.5rem"
                            fontWeight="700"
                            color="primary"
                        >
                            Estás a punto de matricularte en {data.name}
                        </Text>
                        <TextFake fontSize="1.2rem" fontWeight="400">
                            ¡Confirma tu elección!
                        </TextFake>
                    </TextWrapper>
                    {/* Botones */}
                    <WrapperButtons>
                        {isLoading && <SimpleLoading />}

                        {!isLoading && (
                            <>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => {
                                        handleModal();
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handlePost}
                                >
                                    Confirmar
                                </Button>
                            </>
                        )}
                    </WrapperButtons>
                </Container>
            </Modal>
        </>
    );
};

export default ModalAssignment;

const Container = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
    padding: 2rem;
    border-radius: 20px;
    width: 500px;
    max-width: 90vw;
    height: max-content;
    max-height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow-y: auto;
    @media (max-width: 768px) {
        width: 320px;
        height: auto;
        padding: 2rem 1rem;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 1.5rem;
    width: 90%;
`;
const TextFake = styled(Text)`
    color: #616161;
    margin-top: 1rem;
    span {
        font-weight: bold;
    }
`;
const WrapperButtons = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 468px) {
        flex-direction: row;
        gap: 2rem;
    }
`;
