import { useState, useEffect } from 'react';
import { getStudents } from '../../redux/api/teacher';

const useGradebook = (id, successfullGraduation) => {
    const [students, setStudents] = useState(null);
    const [totalStudents, setTotalStudents] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    // useFilter states
    const [inputText, setInputText] = useState('');
    const [checkedState, setCheckedState] = useState({});
    const [checkedDelivery, setCheckedDelivery] = useState({});
    const [chips, setChips] = useState([]);

    useEffect(() => {
        handleStudents(id, pageSize, 0);
    }, []);

    useEffect(() => {
        if (successfullGraduation) {
            handleStudents(id, pageSize, 0);
        }
    }, [successfullGraduation]);

    // SETEAMOS LOS ALUMNOS Y LAS NOTAS
    const handleStudents = async (
        id,
        size = 20,
        offset = 0,
        studentState,
        deliveryState,
        textValue
    ) => {
        setIsLoading(true);
        const body = {
            enrollment: {
                'partner_id.name': textValue,
            },
        };
        const { data, error } = await getStudents(id, body, { offset, size });
        if (error) {
            console.error('error', error);
            return;
        }
        setTotalStudents(data.cantidad_matriculaciones_asignatura);
        setStudents(
            data.response_data.students.length > 0
                ? data.response_data.students
                : null
        );
        setIsLoading(false);
    };

    const handleCurrentPage = (e, value) => {
        setCurrentPage(value);
        handleStudents(
            id,
            pageSize,
            (value - 1) * pageSize,
            checkedState.value,
            checkedDelivery.value,
            inputText
        );
    };

    const getFilteredStudents = (studentState, deliveryState, textValue) => {
        handleStudents(id, 20, 0, studentState, deliveryState, textValue);
    };

    const handleClearFilters = () => {
        setInputText('');
        setCurrentPage(1);
        handleStudents(id, 20, 0);
    };

    const getInputFilterStudents = () => {
        getFilteredStudents('', '', inputText);
    };

    const handleChange = (e) => {
        setInputText(e.target.value);
    };

    return {
        students,
        handleCurrentPage,
        totalStudents,
        isLoading,
        currentPage,
        pageSize,
        chips,
        handleClearFilters,
        checkedDelivery,
        checkedState,
        handleChange,
        inputText,
        getInputFilterStudents,
    };
};

export default useGradebook;
