import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '@mui/material';
import HeadModal from '../../../common/HeadModal';
import Text from '../../../common/Text';
import MoreInformation from './MoreInformation';
import { dracmaActions } from '../../../../redux/actions';
import CardBenefit from '../../molecules/dracma/CardBenefit';

const HowToGetMore = () => {
    // REDUX
    const dispatch = useDispatch();
    const benefits = useSelector((state) => state.dracma.benefits);

    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (!benefits) {
            dispatch(dracmaActions.getBenefitsRequest());
        }
    }, []);

    // FUNCTIONS
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    // RETURN
    if (!benefits) {
        return null;
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={openModal}
            >
                ¿Cómo obtengo más monedas?
            </Button>
            <Modal open={isOpen} onClose={closeModal}>
                <Container>
                    <HeadModal
                        title="¿Cómo se obtienen Puntos de Experiencia y Dracma Coins?"
                        closeModal={closeModal}
                    />
                    <CustomHr />
                    {/** Lista de actividades */}
                    <ColumnWrapper>
                        <Text fontSize="1rem" component="p">
                            ¡Tu actividad dentro y fuera del campus ahora te da
                            beneficios! Chequea en esta lista cuáles son las
                            actividades que te dan Puntos de Experiencia y
                            Dracmas:
                        </Text>
                        <OverflowWrapper>
                            <Overflow>
                                {benefits.map((benefit, index) => (
                                    <CardBenefit
                                        benefit={benefit}
                                        key={index}
                                        customImg={
                                            '/assets/aden/dracma-coin.svg'
                                        }
                                        alt={'dracma-coin'}
                                    />
                                ))}
                            </Overflow>
                        </OverflowWrapper>
                    </ColumnWrapper>
                    <CustomHr />
                    {/** Botones */}
                    <ButtonWrapper>
                        <MoreInformation withText={true} />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={closeModal}
                        >
                            Cerrar
                        </Button>
                    </ButtonWrapper>
                </Container>
            </Modal>
        </>
    );
};

export default HowToGetMore;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 900px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem 2rem;
`;

const CustomHr = styled.hr`
    width: 100%;
    margin: 0;
`;

const OverflowWrapper = styled.div`
    position: relative;
    height: 500px;
`;

const Overflow = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
`;
