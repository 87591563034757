import axios from 'axios';

export async function getStatusServices() {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_SISAPI}/v1/acropolis/test/info`
        );
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}
