import { useEffect, useState } from "react"

const useMyTasksFilters = (tasks) => {

    const [taskFilters, setTaskFilters] = useState(null)
    const [value, setValue] = useState("")
    const [active, setActive] = useState(false)

    const options = [
        {
            label: "Fecha de entrega",
            value: "date"
        },
        {
            label: "Estado de entrega",
            value: "state"
        },
        {
            label: "Nombre",
            value: "name"
        }
    ] 

    useEffect(() => {
        clearFilter()
        setActive(false)
    }, [tasks])

    const toggleActive = () => {
        setActive(!active)
    }

    const obtainLabelByValue = (value) => {
        const option = options.find(option => option.value === value);
        return option ? option.label : "";
    }

    const clearFilter = () => {
        setValue("")
        setTaskFilters(tasks)
    }

    // Orden de prioridad de los estados
    const statesOrder = {
        "pendiente": 1,
        "corregido": 2,
        "rehacer": 3,
    };
      
    function obtainStateWithRedo(state, redo) {
        if (state === "corregido" && redo) {
          return "rehacer";
        }
        return state;
    }
      
    function orderByState(array) {
        setTaskFilters(array.sort((a, b) => statesOrder[obtainStateWithRedo(a.state, a.redo)] - statesOrder[obtainStateWithRedo(b.state, b.redo)]));
    }

    function orderByDate(array) {
        setTaskFilters(array.sort((a, b) => a.create_date.localeCompare(b.create_date)));
    }

    function orderByName(array) {
        setTaskFilters(array.sort((a, b) => a.oa.name.localeCompare(b.oa.name)));
    }

    const handleOption = (option) => {
        setValue(option.value)
        const sortedArray = [...tasks];
        option.value === "date" ? orderByDate(sortedArray) : option.value === "state" ? orderByState(sortedArray) : orderByName(sortedArray)
        toggleActive()
    }

    return { handleOption, clearFilter, obtainLabelByValue, toggleActive, taskFilters, value, active, options }

}

export default useMyTasksFilters;