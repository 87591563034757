import * as types from '../types';

const actions = {};

// GET OndemandById
actions.getOndemandRequest = (id) => {
    return {
        type: types.GET_ONDEMAND_REQUEST,
        payload: id
    };
};

actions.getOndemandSuccess = (ondemand) => {
    return {
        type: types.GET_ONDEMAND_SUCCESS,
        payload: ondemand
    };
};

actions.getOndemandFail = (error) => {
    return {
        type: types.GET_ONDEMAND_FAIL,
        payload: error
    };
};

actions.setOndemand = () => {
    return {
        type: types.SET_ONDEMAND,
    };
};

// GET OndemandByCompetitor
actions.getOndemandByCompetitorRequest = (competitor) => {
    return {
        type: types.GET_ONDEMANDCOMPETITOR_REQUEST,
        payload: competitor
    };
};

actions.getOndemandByCompetitorSuccess = (ondemand) => {
    return {
        type: types.GET_ONDEMANDCOMPETITOR_SUCCESS,
        payload: ondemand
    };
};

actions.getOndemandByCompetitorFail = (error) => {
    return {
        type: types.GET_ONDEMANDCOMPETITOR_FAIL,
        payload: error
    };
};

// GET Cursos adicionales
actions.getAddiotionalCoursesRequest = (payload) => {
    return {
        type: types.GET_COURSES_ADDITIONAL_REQUEST,
        payload: payload
    };
};

actions.getAddiotionalCoursesSuccess = (courses) => {
    return {
        type: types.GET_COURSES_ADDITIONAL_SUCCESS,
        payload: courses
    };
};

actions.getAddiotionalCoursesFail = (error) => {
    return {
        type: types.GET_COURSES_ADDITIONAL_FAIL,
        payload: error
    };
};

export { actions };
