import React from 'react';
import styled from 'styled-components';
import { Img } from '../../../common/Image';
import { Text } from '../../../common/Texts';

const UserData = (props) => {
    const { img, name, justifyContent, padding } = props;

    return (
        <UserDataWrapper justifyContent={justifyContent} padding={padding}>
            <Photo>
                <Img radius="50%" w="25px" h="25px" loading="lazy" src={img} />
            </Photo>
            <Text fontWeight="700" fontSize=".85rem">
                {name}
            </Text>
        </UserDataWrapper>
    );
};

export default UserData;

const UserDataWrapper = styled.div`
    display: flex;
    justify-content: ${(p) =>
        p.justifyContent ? p.justifyContent : 'space-between'};
    align-items: center;
    gap: 5px;
    padding: ${(p) => (p.padding ? p.padding : 0)};
`;

const Photo = styled.div`
    max-width: 25px;
    max-height: 25px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    object-fit: cover;
    object-position: top;
`;
