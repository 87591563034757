import React from 'react';
import styled from 'styled-components';

const IconInfoLeft = (props) => {
    const { icon, title, data } = props;

    return (
        <IconInfoLeftContainer>
            {icon}
            <TextWrapper>
                <Title>{title}</Title>
                <Data>{data || '-'}</Data>
            </TextWrapper>
        </IconInfoLeftContainer>
    );
};

export default IconInfoLeft;

const IconInfoLeftContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.span`
    font-size: 0.65rem;
    font-weight: 700;
    text-transform: uppercase;
`;

const Data = styled.span`
    font-size: 1.1rem;
    font-weight: 400;
    color: #222222;
`;
