import * as types from '../types/dracma_types';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
    getDracma,
    getMovements,
    getRanking,
    getRankingOndemand,
    getBenefits
} from '../api/dracma.api';
import { dracmaActions } from '../actions';

function* getDracmaRequest() {
    try {
        const res = yield call(getDracma);
        yield put(dracmaActions.getDracmaSuccess(res.data.response_data));
    } catch (error) {
        yield put(dracmaActions.getDracmaFail(error));
    }
}

function* getMovementsRequest() {
    try {
        const res = yield call(getMovements);
        yield put(
            dracmaActions.getMovementsSuccess(res.data.response_data.movements)
        );
    } catch (error) {
        yield put(dracmaActions.getMovementsFail(error));
    }
}

function* getRankingRequest() {
    try {
        const res = yield call(getRanking);
        yield put(
            dracmaActions.getRankingSuccess(res.data.response_data.ranking)
        );
    } catch (error) {
        yield put(dracmaActions.getRankingFail(error));
    }
}

// FUNCIÓN RANKING ONDEMAND
function* getRankingOndemandRequest() {
    try {
        const res = yield call(getRankingOndemand);
        yield put(
            dracmaActions.getRankingOndemandSuccess(
                res.data.response_data.ranking
            )
        );
    } catch (error) {
        yield put(dracmaActions.getRankingOndemandFail(error));
    }
}

function* getBenefitsRequest() {
    try {
        const res = yield call(getBenefits);
        yield put(
            dracmaActions.getBenefitsSuccess(res.data.response_data.ranking)
        );
    } catch (error) {
        yield put(dracmaActions.getBenefitsFail(error));
    }
}

function* dracmaWatcher() {
    yield takeEvery(types.GET_DRACMA_REQUEST, getDracmaRequest);
    yield takeEvery(types.GET_MOVEMENTS_REQUEST, getMovementsRequest);
    yield takeEvery(types.GET_RANKING_REQUEST, getRankingRequest);
    yield takeEvery(
        types.GET_RANKINGONDEMAND_REQUEST,
        getRankingOndemandRequest
    );
    yield takeEvery(types.GET_BENEFITS_REQUEST, getBenefitsRequest);
}

export { dracmaWatcher };
