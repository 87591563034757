import axios from "axios";

// TRAER DATOS DE NOTIFICACIONES
export async function getNotification() {
    const URL = `${window.location.origin}/mock/notifications.json`;
    try {
        const response = await axios.get(URL);
        return response.data.response_data.notifications;
    } catch (error) {
        return { error };
    }
}

export async function getNotificationFake(role, assignmentId) {
    const URL = assignmentId
        ? `${process.env.REACT_APP_SISAPI}/v1/acropolis/notifications?role=${role}&asignatura_id=${assignmentId}`
        : `${process.env.REACT_APP_SISAPI}/v1/acropolis/notifications?role=${role}`;
    try {
        const response = await axios.get(URL);
        return response.data.notifications;
    } catch (error) {
        return { error };
    }
}

export async function getCategoryNotification() {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/notifications/categories`;
    try {
        const response = await axios.get(URL);
        return response.data.notification_categories;
    } catch (error) {
        return { error };
    }
}

export async function postNotification(id) {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/notifications/${id}`;

    try {
        const response = await axios.post(URL);
        return response.data;
    } catch (error) {
        return { error };
    }
}
