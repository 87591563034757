import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { Form, Formik } from "formik";
import { useSnackBar } from "../../../../app/campus/hooks/useSnackBar";
import { SnackBarGenerico } from "../../../../app/campus/components/SnackBarGenerico";
import {
  IconButton,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { registerSchema } from "../../../../utils/schemas";
import DefaultField from "../../../common/DefaultField";
import { authActions } from "../../../../redux/actions";
import { useHistory } from "react-router-dom";

const lenguaje = () => {
  const lenguaje = window.navigator.language;
  return lenguaje[0] + lenguaje[1];
};

const RegisterForm = (props) => {
  const {
    mailLanding,
    loadingRegister,
    errorRegister,
    authToken,
    registerUserRequest,
    loginUserRequest,
    clearErrorRegister,
  } = props;

  let history = useHistory();

  // STATE
  const initialValues = {
    first_name: "",
    last_name: "",
    email:
      mailLanding != null || mailLanding != undefined || mailLanding != ""
        ? mailLanding
        : "",
    language: "",
    password: "",
  };
  const [auxValues, setAuxValues] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showRecoPassword, setShowRecoPassword] = useState(false);
  const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

  // EFFECTS
  useEffect(() => {
    if (authToken) {
      openSnackBar(
        "success",
        `Te hemos enviado un mail para que verifiques tu cuenta`
      );
      setTimeout(() => {
        history.push("/auth");
        //loginUserRequest(auxValues);
      }, 8000);
    }
  }, [authToken]);

  useEffect(() => {
    if (errorRegister) {
      openSnackBar("warning", "Ocurrió un error");
      setTimeout(() => {
        clearErrorRegister();
      }, 1000);
    }
  }, [errorRegister]);

  // FUNCTIONS
  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  function toggleShowRecoPassword() {
    setShowRecoPassword(!showRecoPassword);
  }

  function handleSubmit(values) {
    registerUserRequest({
      form: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email.toLowerCase(),
        password: values.password,
        language: lenguaje().toLowerCase(),
      },
      extra_data: {},
    });
    setAuxValues({
      user: values.email.toLowerCase(),
      password: values.password,
    });
  }

  // RETURN
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={registerSchema}
      >
        {() => (
          <Container>
            {/** Nombre */}
            <DefaultField
              name="first_name"
              type="text"
              label="Nombre"
              required
            />
            {/** Apellido */}
            <DefaultField
              name="last_name"
              type="text"
              label="Apellido"
              required
            />
            {/** Email */}
            <DefaultField name="email" type="email" label="Email" required />
            {/** Contraseña */}
            <DefaultField
              name="password"
              type={showPassword ? "text" : "password"}
              label="Contraseña"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowPassword} size="large">
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/** Repetir contraseña */}
            <DefaultField
              name="changepassword"
              type={showRecoPassword ? "text" : "password"}
              label="Repetir contraseña"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowRecoPassword} size="large">
                      {showRecoPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/** Boton registrarse */}
            {loadingRegister ? (
              <CircularProgress color="primary" size={32} />
            ) : (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
              >
                Registrarme
              </Button>
            )}
            {/** Snackbar de error */}
            <SnackBarGenerico
              show={snackBar.show}
              handleClose={closeSnackBar}
              message={snackBar.message}
              variant={snackBar.variant}
            />
          </Container>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  mailLanding: state.auth.mailLanding,
  loadingRegister: state.auth.loadingRegister,
  errorRegister: state.auth.errorRegister,
  authToken: state.auth.authToken,
});

const mapDispatchToProps = (dispatch) => {
  return {
    registerUserRequest: (values) =>
      dispatch(authActions.registerUserRequest(values)),
    loginUserRequest: (values) =>
      dispatch(authActions.loginUserRequest(values)),
    clearErrorRegister: () => dispatch(authActions.clearErrorRegister()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);

const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  width: 50%;
  padding: 0 25%;

  @media screen and (max-width: 425px) {
    padding: 0 10%;
    width: 80%;
  }
`;
