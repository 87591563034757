import * as types from "../types/summit_types";

const actions = {};

actions.getSummitInscriptionRequest = (payload) => {
  return {
    type: types.GET_SUMMIT_REGISTRATION_REQUEST,
    payload: payload,
  };
};
actions.getSummitInscriptionSucess = (registration) => {
  return {
    type: types.GET_SUMMIT_REGISTRATION_SUCCESS,
    payload: registration,
  };
};
actions.getSummitInscriptionFail = (error) => {
  return {
    type: types.GET_SUMMIT_REGISTRATION_FAIL,
    payload: error,
  };
};

export { actions };
