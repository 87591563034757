import * as encrypt from 'nodejs-base64-encode';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import React from "react";

// Components
import PublicProfileInfo from "./components/PublicProfileInfo";
import PublicHeaderInfo from "./components/PublicHeaderInfo";
import PublicBooksInfo from "./components/PublicBooksInfo";
import ProfileSkeleton from "./components/ProfileSkeleton";
import Slider from "../../components/common/Slider";
import CardNote from "./components/CardNote";

// Redux
import { getPublicProfileTeacher } from '../../redux/api/profiles';

// Hooks
import useFetch from "../../hooks/useFetch";

// Helpers
import { titleHTML } from '../../helpers/title.helper';

// Adapters
import { publicProfileTeacherAdapter } from "./adapters/publicProfileTeacherAdapter";
import { useQueryParams } from '../../hooks/useQueryParams';

const PublicProfileTeacher = () => {
    titleHTML("Perfil profesor");

    const { id } = useParams();
    const decodeId = encrypt.decode(id + '', 'base64');

    const params = useQueryParams();
    const name = params.get("name");

    const { data: profileData, loading } = useFetch({
        asyncFn: () => getPublicProfileTeacher(decodeId),
        adapter: publicProfileTeacherAdapter,
    });

    if (profileData === null || loading) {
        return <ProfileSkeleton />;
    }

    return (
        <ProfileContainer>
            <ProfileWrapper>
                <PublicHeaderInfo
                    photo={profileData?.photo}
                    socialNetworks={profileData?.socialNetworks}
                    professionalExperience={profileData?.professionalExperience}
                />
                <PublicProfileInfo
                    name={name}
                    academicFormation={profileData?.academicFormation}
                    profession={profileData?.profession ?? ""}
                    teachingAreas={profileData?.teachingAreas}
                />
            </ProfileWrapper>
            <Body>
                <Wrapper>
                    <CardFake>
                        <h3>Notas vinculadas al experto</h3>
                        {
                            profileData?.notes?.length <= 4 ? (
                                <NotSliderWrapper>
                                    <ButtonWrapper>
                                    </ButtonWrapper>
                                    <Content>
                                        <CardsWrapper>
                                            {profileData?.notes?.map((note) => (
                                                <CardNote
                                                    key={note?.id}
                                                    value={note}
                                                />
                                            ))}
                                        </CardsWrapper>
                                    </Content>
                                </NotSliderWrapper>
                            ) : (
                                <Slider
                                    array={profileData?.notes}
                                    card={CardNote}
                                    to={''}
                                />
                            )
                        }
                    </CardFake>
                </Wrapper>
                <PublicBooksInfo
                    books={profileData?.books}
                />
            </Body>
        </ProfileContainer>
    );
};

export default PublicProfileTeacher;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

const ProfileWrapper = styled.div`
    display: flex;
    gap: 2rem;

    @media (width < 1000px) {
        flex-direction: column;
    }
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
    }
`;

const Wrapper = styled.div``

const CardFake = styled.div`
    position: relative;
    width: calc(100% - 48px);
    box-shadow: var(--shadow-strong);
    border-radius: var(--border-radius_sharp);
    background-color: #fff;
    padding: 32px 24px;

    h3 {
        font-size: 20px;
        font-weight: 700;
        color: #222;
        margin-left: 1rem;
    }
`;

const NotSliderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const CardsWrapper = styled.div`
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Content = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;
