import {
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SnackBarGenerico } from '../../../app/campus/components/SnackBarGenerico';
import { useSnackBar } from '../../../app/campus/hooks/useSnackBar';
import { URL_SIS_API } from '../../../app/modules/Services/environments';
import SimpleLoading from '../../common/SimpleLoading';
import Button from '../../ui/atoms/Button';

const initialForm = {
    subject: '',
    type: '',
    message: ``
}

const FormContact = (props) => {
    const { advisor } = props;

    const [values, setValues] = useState(initialForm);
    const [loading, setLoading] = useState(false)
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();
    const { user } = useSelector(state => state.auth)
    const { name, personal_email, phone, mobile } = user

    useEffect(() => {
        setValues({
            subject: '',
            type: '',
            message: `Hola ${advisor.name}, `
        })
    }, [advisor])


    // FUNCTION
    function handleChange(e) {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const onSubmit = async () => {
        const URL = `${URL_SIS_API}/v1/acropolis/academics/contact`;
        setLoading(true)
        const data = {
            message: values.message,
            subject: values.subject,
            type: values.type,
            program: advisor?.program,
            academic_email: advisor?.email || 'tecnologia@aden.org',
            student_phone: (phone || mobile) + '',
            student_email: personal_email,
            student_name: name
        };


        try {
            const response = await axios.post(URL, data); //Error 500 - el código original también devuelve error 500
            openSnackBar(
                'success',
                `Se envió la ${values.type.toLowerCase()} a ${advisor.name}`
            );
            setLoading(false)
            setValues(initialForm)
            return response;
        } catch (error) {
            setLoading(false)
            openSnackBar(
                'error',
                `Error al enviar ${values.type.toLowerCase()} a ${advisor.name}`
            );
            setValues(initialForm)
            return error;
        }
    };

    const onReset = () => {
        setValues((state) => ({
            ...state,
            subject: '',
            message: ''
        }));
    };

    return (
        <>
            <H2>Formulario de contacto rápido</H2>
            <Form>
                <Input
                    name="subject"
                    label="Asunto del mensaje"
                    variant='outlined'
                    size="small"
                    value={values.subject}
                    onChange={handleChange}
                />
                <RadioGroupStyled
                    aria-label="Tipo de consulta"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value="Sugerencia"
                        control={<Radio size="small" />}
                        label="Sugerencia"
                    />
                    <FormControlLabel
                        value="Queja / Reclamo"
                        control={<Radio size="small" />}
                        label="Queja/Reclamo"
                    />
                </RadioGroupStyled>
                <Input
                    name="message"
                    variant='outlined'
                    label="Mensaje"
                    size="small"
                    value={values.message}
                    multiline
                    rows={4}
                    onChange={handleChange}
                />
                {!loading ? <Buttons>
                    <Button
                        variant={'default'}
                        label="Resetear"
                        handleClick={() => onReset()}
                    />
                    <Button
                        id="send"
                        disabled={
                            values.message !== '' &&
                                values.type !== '' &&
                                values.subject !== ''
                                ? false
                                : true
                        }
                        label={'Enviar'}
                        handleClick={() => onSubmit()}
                    />
                </Buttons> : <SimpleLoading />}
            </Form>
            <SnackBarGenerico
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </>
    );
};

export default FormContact;

const H2 = styled.a`
    font-weight: 600;
    font-size: 1.3rem;
    color: #b31d15;
`;

const Form = styled.div`
    margin: 1rem 0;
`;

const Input = styled(TextField)`
    width: 100%;
    margin: 1rem 0;
`;

const RadioGroupStyled = styled(RadioGroup)`
    flex-direction: unset;
`;

const Buttons = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 12px;
`;
