import React from 'react';
import styled from 'styled-components';

import moment from 'moment-timezone';
import NumberTitle from '../../atoms/tickets/NumberTitle';
import TicketCard from '../../atoms/tickets/TicketCard';
import TicketResolution from '../../atoms/tickets/TicketResolution';
import TicketText from '../../atoms/tickets/TicketText';

const TicketInfo = (props) => {
    const { ticket } = props;

    // FUNCTIONS
    function parseStatus(status) {
        const FINAL_STATUS = {
            open: 'Abierto'
        };
        return FINAL_STATUS[status.toLowerCase()] || status;
    }

    // RETURN
    return (
        <>
            <NumberTitle number="2" title="Revisa el estado de tu ticket" />
            <Container>
                {/** Tarjetas */}
                <ColumnWrapper>
                    <TicketCard title="Nº TICKET" text={ticket.ticket_number} />
                    <TicketCard
                        title="ESTADO"
                        text={parseStatus(ticket.status)}
                    />
                    <TicketCard
                        title="FECHA EMISIÓN"
                        text={moment(ticket.create_date).format('DD/MM/YYYY')}
                    />
                    {ticket.closing_date !== '' && (
                        <TicketCard
                            title="FECHA DE CIERRE"
                            text={moment(ticket.closing_date).format(
                                'DD/MM/YYYY'
                            )}
                        />
                    )}
                </ColumnWrapper>
                {/** Informacion del ticket */}
                <InfoWrapper>
                    <RowWrapper>
                        <TicketText
                            title="Área responsable"
                            text={ticket.area}
                        />
                        <TicketText title="Tipo de caso" text={ticket.case} />
                        <TicketText title="Categoría" text={ticket.category} />
                    </RowWrapper>
                    <TicketText title="Asunto" text={ticket.issue} />
                    <TicketText title="Descripción" text={ticket.description} />
                    {ticket.resolution_detail !== '' && (
                        <TicketResolution
                            title="Resolución"
                            text={ticket.resolution_detail}
                        />
                    )}
                </InfoWrapper>
            </Container>
        </>
    );
};

export default TicketInfo;

const Container = styled.div`
    display: flex;
    grid-gap: 2rem 4rem;
    padding-left: calc(30px + 1rem);

    @media (max-width: 768px) {
        flex-direction: column;
        padding-left: 0;
    }
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    border-radius: 5px;
    background-color: #fcfcfc;
    width: 100%;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 576px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
        grid-gap: 2rem;
    }
`;
