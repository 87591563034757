import React from "react";
import styled from "styled-components";
import { Text } from "../Texts";
import { Card } from "@mui/material";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import SocialMediaOneStudent from "./SocialMediaOneStudent";

const MediumIconCards = (props) => {
    const { firstInfo } = props;

    return (
        <Container>
            {/* <CardFake>
                <Text color="#A8A8A8">
                    <AccessTimeRoundedIcon />
                </Text>
                <div>
                    <Text fontSize="0.75rem" fontWeight="bold" color="#616161">
                        {' '}
                        ÚLTIMO CONTACTO
                    </Text>
                    <Text
                        className="lastContact"
                        fontSize="1rem"
                        fontWeight="900"
                        color="#222222"
                    >
                        {student.lastContact}
                        <Text fontWeight="400">
                            {' '}
                            - vía {student.mediumOfContact}
                        </Text>
                    </Text>
                </div>
            </CardFake>

            <CardFake>
                <Text color="#A8A8A8">
                    <AssistantPhotoRoundedIcon />
                </Text>
                <div>
                    <Text fontSize="0.75rem" fontWeight="bold" color="#616161">
                        {' '}
                        ESTADO GESTIÓN ACADÉMICA
                    </Text>
                    <TextFake fontSize="1rem" fontWeight="900" color="#222222">
                        {' '}
                        {student.statusAcademic}
                    </TextFake>
                </div>
            </CardFake> */}

            <CardFake>
                <Text color="#A8A8A8">
                    <TouchAppRoundedIcon />
                </Text>
                <div>
                    <Text fontSize="0.75rem" fontWeight="bold" color="#616161">
                        {" "}
                        ULTIMO ACCESO A LA PLATAFORMA
                    </Text>
                    <Text fontSize="1rem" fontWeight="900" color="#222222">
                        {" "}
                        {firstInfo?.ultimo_acceso_plataforma || "Sin Registros"}
                    </Text>
                </div>
            </CardFake>
            <SocialMediaOneStudent firstInfo={firstInfo} />
        </Container>
    );
};

export default MediumIconCards;

const Container = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 1rem;
    place-content: center;
    justify-items: start;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const CardFake = styled(Card)`
    display: grid;
    grid-template-columns: 30px auto;
    gap: 1.5rem;
    place-content: center;
    justify-items: center;
    align-items: center;
    height: 90px;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    padding: 0 2rem 0 0.5rem;
    box-sizing: border-box;

    .lastContact {
        display: inline-flex;
        flex-direction: row;
        gap: 0.15rem;
    }

    @media screen and (max-width: 900px) {
        width: 290px;
        box-sizing: border-box;
        align-self: center;
    }
`;

const TextFake = styled(Text)`
    text-transform: uppercase;
`;
