import axios from "axios";

// EGRESAR A ALUMNOS AUTOMATICAMENTE
export const postGraduate = async (body) => {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/faculty/graduate`;

    try {
        const response = await axios.post(URL, body);
        return response.data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};
