import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import { Text } from "../../../../../components/common/Texts";

// Material UI
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CalificationPercentage = (props) => {
	const { values } = props;

	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState(null);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		values && getData(values);
	}, [values]);

	const getData = (courses) => {
		const percentages = {}; // Objeto para almacenar los porcentajes únicos y sus datos correspondientes

		// Recopilar datos de las actividades y sus porcentajes únicos
		courses.forEach((item) => {
			item.activities.forEach((act) => {
				const percentage_grade = act?.percentage_grade.toString();
				if (!percentages[percentage_grade]) {
					percentages[percentage_grade] = {
						activities: [],
						prom: 0,
					};
				}

				// Obtener la calificación máxima de la actividad
				const [max] =
					act.submissions
						.sort((a, b) => b.grade - a.grade)
						.filter((item) => !item.redo) || [];

				// Agregar actividad al porcentaje correspondiente
				percentages[percentage_grade].activities.push({
					...act,
					grade: max,
				});
			});
		});

		// Calcular el promedio para cada porcentaje
		Object.keys(percentages).forEach((percentage) => {
			const totalGrade = percentages[percentage].activities.reduce(
				(acc, act) => acc + (act.grade?.grade || 0),
				0
			);
			percentages[percentage].prom = (
				((totalGrade / percentages[percentage].activities.length) *
					parseInt(percentage)) /
				100
			).toFixed(1);
		});

		// Calcular el promedio total
		const total = Object.values(percentages)
			.reduce((acc, percentage) => acc + parseFloat(percentage.prom), 0)
			.toFixed(1);

		setTotal(total);
		setData(percentages);
	};

	const handleOpen = (value) => {
		setIsOpen(value === isOpen ? false : value);
	};

	return (
		<Wrapper>
			<Head>
				<Text color="#777" fontSize="18px">
					Notas de la asignatura
				</Text>
				<Text fontSize="18px">Promedio general</Text>
			</Head>
			<br />
			{data &&
				Object.keys(data).map((item) => {
					return (
						<>
							<Header open={isOpen === item} onClick={() => handleOpen(item)}>
								{isOpen !== item ? (
									<KeyboardArrowDownIcon />
								) : (
									<KeyboardArrowUpIcon />
								)}
								<Text fontSize="22px">
									{(parseFloat(data[item].prom) || 0.0).toFixed(1)}/
									<b>{item}%</b>
								</Text>
							</Header>
							<Body open={isOpen === item}>
								<Activity>
									<Text fontWeight="bold" color="#555">
										Actividad
									</Text>
									<Text fontWeight="bold" color="#555">
										Estado
									</Text>
									<Text textAlign="center" fontWeight="bold" color="#555">
										Nota obtenida
									</Text>
								</Activity>
								{data[item].activities.map((act) => (
									<Activity>
										<Text color="#555">{act.name}</Text>
										<Text fontWeight="bold" color="#777">
											{(act.grade?.state || "pendiente").toUpperCase()}
										</Text>
										<Text textAlign="center" fontWeight="bold" color="#555">
											{act.grade?.grade || "-"}/100
										</Text>
									</Activity>
								))}
							</Body>
						</>
					);
				})}
			<Footer>
				<Text color="#777" fontSize="18px">
					Promedio total de la asignatura
				</Text>
				<Text fontSize="22px">
					{total}/<b>{100}%</b>
				</Text>
			</Footer>
		</Wrapper>
	);
};

export default CalificationPercentage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Header = styled.div`
  width: calc(100% - 4rem);
  padding: 0 2rem;
  height: 55px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: ease-in-out 0.3s all;
  :hover {
    background-color: #ddd;
  }
`;

const Body = styled.div`
  border: ${(p) => (p.open ? "1px solid #eee" : "0px solid #eee")};
  transition: ease-in-out 0.3s all;
  height: ${(p) => (p.open ? "auto" : "0")};
  div {
    display: ${(p) => (p.open ? "grid" : "none")};
  }
`;

const Activity = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 0.5fr;
  border-bottom: 1px solid #eee;
  padding: 1rem;
`;

const Footer = styled.div`
  width: calc(100% - 4rem);
  padding: 0 2rem;
  height: 55px;
  background-color: #eee;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Head = styled.div`
  width: calc(100% - 4rem);
  padding: 0 2rem;
  height: 55px;
  background-color: #eee;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
