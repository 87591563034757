import React from 'react';
import styled from 'styled-components';

const SkillsAbout = (props) => {
    const { skills } = props;
    return (
        <>
            <Title>Habilidades que obtendrás</Title>
            <BodyWrapper>
                {skills.map((skill) => {
                    return (
                        <div key={skill}>
                            <p>{skill}</p>
                        </div>
                    );
                })}
            </BodyWrapper>
        </>
    );
};

export default SkillsAbout;

const Title = styled.h2`
    margin-top: 3rem;
    color: #222222;
    font-weight: 900;
    font-size: 1.5rem;
`;

const BodyWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1.5rem auto;
    flex-wrap: wrap;
    div {
        padding: 0.5rem 1rem;
        background-color: #b31d15;
        margin-right: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        p {
            padding: 0;
            margin: 0;
            color: #fff;
            text-align: center;
            font-size: 1rem;

            @media (max-width: 880px) {
                font-size: 0.8rem;
            }
        }
    }
`;
