import * as yup from 'yup';

export const settlementsScheme = yup.object().shape({
    student: yup
        .string()
        // .shape(),
        .required('Estudiante requerido'),
    type: yup
        .string()
        .required('Tipo requerido'),
    date: yup
        .string()
        .required('Fecha requerida'),
    assigment: yup
        .string()
        // .shape()
        .required('Asignatura requerida'),
});