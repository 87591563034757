import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Button } from "@mui/material";
import { WorkspacePremium } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as encode from "nodejs-base64-encode";
import { sendEmailCertificate } from "../../../redux/api/ondemand";

const CertificateButtonProgress = (props) => {
    const { certificate, objId, disabled = false, display, userOrigin } = props;

    const { id } = useParams();
    const oaId = encode.decode(id, "base64");

    const user = useSelector((state) => state.auth.user);

    const ondemandSendCertificate = useSelector(
        (state) => state?.ondemand?.ondemand?.enable_auto_send_certificate
    );

    const { repo_id, ondemand_id } = user;

    const handleOpenCertificate = () => {
        if (ondemandSendCertificate) {
            sendEmailCertificate(oaId, repo_id, ondemand_id);
        }

        if (!!certificate) {
            window.open(
                `https://constancias.aden.org/?idcod=OA-${objId}-${user.repo_id}-ADEN2022/`,
                "_blank"
            );
        } else {
            alert("No posee certificado");
        }
    };

    return (
        <Container>
            <AsideButton
                onClick={() => handleOpenCertificate()}
                // active={!!certificate ? true : false}
                color="primary"
                variant="contained"
                disabled={disabled}
                style={{ display: `${display}` }}
                isAlone={userOrigin === "demo" ? false : true}
            >
                <CertificateIcon />
                Descarga tu certificado
            </AsideButton>
        </Container>
    );
};

export default CertificateButtonProgress;

const Container = styled.div`
    width: ${(p) => (p.isAlone ? "auto" : "100%")};
`;

const AsideButton = styled(Button)`
    line-height: 20px;
    text-align: start;
    font-size: 14px;
    padding: 15px 15px 15px 8px;
    border-radius: 20px;
    /* width: ${(p) => (p.isAlone ? "auto" : "100%")}; */
    width: 100%;
    @media (max-width: 1024px) {
        margin-top: 15px;
        width: 100%;
    }
`;

const CertificateIcon = styled(WorkspacePremium)`
    margin-right: 5px;
`;
