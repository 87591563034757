import React, { useEffect } from "react";
import styled from "styled-components";
import AsideBlog from "../../components/blog/AsideBlog";
import CardArticleMain from "../../components/blog/CardArticleMain";
import CommentsBlog from "../../components/blog/CommentsBlog";
import useLayout from "../../hooks/useLayout";

const Article = () => {

    const { setMenu, clearMenu } = useLayout()

    useEffect(() => {
        setMenu("¿Cómo ser asertivo en tu puesto de trabajo?")
    }, [])




    return (
        <>
            <Container>
                <Wrapper>
                    <CardArticleMain />
                    <CommentsBlog />
                </Wrapper>
                <AsideBlog />
            </Container>
        </>
    );
};

export default Article;

const Container = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;

    gap: 1rem;

    @media (max-width: 1100px) {
        display: flex;
        flex-direction: column;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`;