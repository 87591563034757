import * as types from '../types';

const initialState = {
    interests: null,
    listLoading: false,
    errorList: null
};

export const interests = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_INTERESTS_REQUEST:
            return {
                ...state,
                listLoading: true
            };
        case types.GET_INTERESTS_SUCCESS:
            return {
                ...state,
                interests: action.payload,
                listLoading: false
            };
        case types.GET_INTERESTS_FAIL:
            return {
                ...state,
                errorList: action.payload,
                listLoading: false
            };

        default:
            return state;
    }
};
