import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import InterestsMotivationsPublic from '../../../../ui/organisms/profile/InterestsMotivationsPublic';
import CardCourseStudent360Teacher from '../../../../common/teacher-academic/CardCourseStudent360Teacher';
import CardProgramsOneStudent360 from './CardProgramsOneStudent360';


const AccordionRight = (props) => {

    const {coursesStudent, publicProfileData, programStudent,  academicTraining, professionalInfo, moreInfo} = props; 
    const [expanded, setExpanded] = useState(false);

    // FUNCTION
     const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
   
    return (
        <Wrapper>
            <AccordionFake
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6h-content"
                    id="panel6h-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Motivaciones e intereses
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetailsFake>
                    <InterestsMotivationsPublic
                            interests={publicProfileData.intereses}
                            motivations={publicProfileData.motivaciones}
                            rol="teacher"
                    />
                    <TypographyChips>
                            <Content>
                                {moreInfo?.competencia_valorada && <p> <Tiltle>Competencia Valorada:</Tiltle> {" "}{moreInfo.competencia_valorada } </p>}
                                {moreInfo?.habilidades_blandas && <p> <Tiltle>Habilidades blandas:</Tiltle>{" "}{moreInfo?.habilidades_blandas } </p>}
                                {moreInfo?.conoce_aden && <p> <Tiltle>Conoce ADEN:</Tiltle>{" "}{moreInfo.conoce_aden } </p>}
                                {moreInfo?.motivacion && <p> <Tiltle>Motivación principal:</Tiltle>{" "}{moreInfo.motivacion} </p>}
                                {moreInfo?.contenido_especifico && <p> <Tiltle>Contenido Especifico:</Tiltle>{moreInfo?.contenido_especifico } </p>}
                                {moreInfo?.inversion && <p> <Tiltle>Inversión:</Tiltle>{" "}{moreInfo.inversion } </p>}
                                {moreInfo?.metodologia_preferida && <p> <Tiltle>Metodología Preferida:</Tiltle>{" "}{moreInfo?.metodologia_preferida } </p>}
                                {moreInfo?.areas_transversales && <p> <Tiltle>Areas Transversales:</Tiltle>{" "}{moreInfo.areas_transversales } </p>}
                                {moreInfo?.presencial_complementario && <p><Tiltle>Presencial Complementario:</Tiltle>{" "}{moreInfo.presencial_complementario} </p>}
                                {moreInfo?.presupuesto && <p> <Tiltle>Presupuesto:</Tiltle>{" "}{moreInfo?.presupuesto } </p>}
                                {moreInfo?.urgencia_de_inicio && <p> <Tiltle>Urgencia de inicio:</Tiltle>{" "}{moreInfo?.urgencia_de_inicio } </p>}
                            </Content>
                        </TypographyChips>
                </AccordionDetailsFake>
            </AccordionFake>
            
            <AccordionFake
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Formación y experiencia 
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetails>
                    <TypographyChips>
                      
                        <Tiltle>Experiencia laboral</Tiltle>
                        <Content>
                            {professionalInfo?.cargo?.experiencia && <p> {professionalInfo.cargo.experiencia } </p>}
                            {professionalInfo?.cargo?.años_experiencia && <p> Años de Experiencia:{" "}{professionalInfo.cargo.años_experiencia } </p>}
                            {professionalInfo?.cargo?.nivel_seniority && <p> Nivel de seniority:{" "}{professionalInfo.cargo.nivel_seniority } </p>}
                            {professionalInfo?.cargo?.es_docente && <p> Es docente:{" "}{professionalInfo.cargo.es_docente } </p>}
                            {professionalInfo?.cargo?.cv && <p> CV:{" "}{professionalInfo.cargo.cv } </p>}
                        </Content>
                                           
                        <Tiltle>Formación académica</Tiltle>
                        <Content>
                            {academicTraining?.diplomados?.diplomado && <p> Diplomado 1: {" "}{academicTraining.diplomados.diplomado } </p>}
                            {academicTraining?.diplomados?.diplomado2 && <p> Diplomado 2: {" "}{academicTraining.diplomados.diplomado2 } </p>}

                            {academicTraining?.grado?.titulo_de_grado && <p> Título de grado:{" "}{academicTraining.grado.titulo_de_grado } </p>}
                            {academicTraining?.grado?.universidad && <p> Título de grado:{" "}{academicTraining.grado.universidad } </p>}

                            {academicTraining?.maestrias?.maestria && <p> Maestria 1:{" "}{academicTraining.maestrias.maestria } </p>}
                            {academicTraining?.maestrias?.maestria2 && <p> Maestria 2:{" "}{academicTraining.maestrias.maestria2 } </p>}

                            {academicTraining?.nivel?.nivel_academico && <p> Nivel academico:{" "}{academicTraining.nivel.nivel_academico } </p>}
                            {academicTraining?.nivel?.nivel_de_estudio && <p> Nivel de estudio:{" "}{academicTraining.nivel.nivel_de_estudio } </p>}
                        </Content>

                    </TypographyChips>
                </AccordionDetails>
            </AccordionFake>

            <AccordionFake
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Programas
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetails>
                    <AutoScroll>
                        {!!programStudent &&
                            programStudent.map((program, index) => (
                                <CardProgramsOneStudent360
                                    program={program}
                                    key={index} 
                                />
                        ))}
                    </AutoScroll>
                </AccordionDetails>
            </AccordionFake>

            <AccordionFake
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Cursos 
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetails>
                    <AutoScroll>
                        {!!coursesStudent &&
                            coursesStudent.map((value, index) => (
                                <CardCourseStudent360Teacher 
                                key={index} 
                                values={value} 
                                />
                            ))}
                    </AutoScroll>
                </AccordionDetails>
            </AccordionFake>


            {/* <AccordionFake
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Logros en ADEN 
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetails>
                    <TypographyChips>
                        <p>chipsss</p>
                    </TypographyChips>
                </AccordionDetails>
            </AccordionFake> */}
        </Wrapper>
    );
};

export default AccordionRight;

const Wrapper = styled.div`
    height: 90%;
    overflow-y: auto;
    row-gap: 1rem;
    margin: 1rem;
`;

const AccordionFake = styled(Accordion)`
    border: none !important;
    box-shadow: none;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border-radius: 1rem;
    align-items: flex-start;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded:first-child {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .MuiAccordionSummary-root{
        background: #ffffff;
        border-radius: 1rem ;
        padding: 0.5rem 2rem;
        height: 3rem;
        p { color: #B31D15;}
        svg {color: #B31D15;}
    }

    .MuiAccordionSummary-root.Mui-expanded{
        background: #B31D15;
        border-radius: 1rem;
        padding: 0.5rem 2rem;
        height: 3rem;
        p{color: #ffffff;}
        svg{color: #ffffff;}
    }

    svg{
        color: #ffffff;
    }

    &::before {
        display: none;
    }
`;

const AccordionDetailsFake = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const TypographyFake = styled(Typography)`
    color: #ffffff;
    font-weight: 700;
    font-size: 1.125rem;
`;

const TypographyChips = styled(Typography)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.3rem;
    flex-wrap: wrap;
`;

const Tiltle = styled.text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 0.8rem;
    line-height:0.5rem;
    align-items: center;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #616161;
`;

const Content = styled.text`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #222222;
    margin-bottom: 1.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;

const AutoScroll = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 2rem;
    padding: 1rem 0.5rem;
    max-height: 600px;
    overflow: auto;

    @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
    }

    ::-webkit-scrollbar {
        background-color: #f9f9f9;
        border-radius: 6px;
        width: 4px;
    }

    img{
        display: none;
    }
`;
