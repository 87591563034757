import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

const CardProgramsRepo = (props) => {
  const { program } = props;

  const { name } = program.detail;

  const [moreInfo, setMoreInfo] = useState(false);

  const handleMoreInfo = () => {
    setMoreInfo(!moreInfo);
  };

  return (
    <>
      <Container moreInfo={moreInfo}>
        <Wrapper>
          <LeftContent>
            <IconCheck icon="icon-park-solid:check-one" />
            <InfoNames>
              <p>PROGRAMA</p>
              <h2>{name}</h2>
            </InfoNames>
          </LeftContent>
          <ProgressWrapper>
            <Progress progress={program?.progress?.progress}></Progress>
            <p>{program?.progress?.progress}%</p>
          </ProgressWrapper>
          <GradeStatusWrapper>
            <State>
              <p>Estado:</p>

              <Chip state={program.state}>{program.state || "confirmado"}</Chip>
            </State>
          </GradeStatusWrapper>
          <ArrowWrapper onClick={handleMoreInfo}>
            <IconArrow moreInfo={moreInfo} icon="ep:arrow-up-bold" />
          </ArrowWrapper>
        </Wrapper>
        <Body moreInfo={moreInfo}>
          <p>
            Nota final: <span>No disponible</span>
          </p>
        </Body>
      </Container>
    </>
  );
};

export default CardProgramsRepo;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 4rem);
  background: #fff;
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.3s ease-in-out;
  min-height: ${(p) => (!p.moreInfo ? "100px" : "150px")};
  max-height: ${(p) => (!p.moreInfo ? "100px" : "150px")};
  box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1),
    0px 6px 14px -6px rgba(24, 39, 75, 0.12);
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2rem;
  width: 100%;
`;

const LeftContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-basis: calc(30% - 1rem);
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
`;

const InfoNames = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 100%;

  h2 {
    color: #b31d15;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  p {
    font-size: 14px;
    color: #616161;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-basis: calc(35% - 1rem);
  align-items: center;
  width: 100%;
`;

const Progress = styled.div`
  width: 100%;
  height: 7px;
  border-radius: 50px;
  background-color: #eaeaea;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    border-radius: 50px;
    width: ${(p) => `${p.progress}%`};
    background-color: #35d0a5;
    z-index: 2;
  }
`;

const GradeStatusWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-basis: calc(25% - 1rem);
`;

const State = styled.div`
  display: flex;
  flex-basis: 50%;
  gap: 1rem;
  align-items: center;
  p {
    color: #616161;
    font-weight: 600;
  }
`;

const Chip = styled.div`
  width: fit-content;
  padding: 7px 16px;
  border-radius: 8px;

  background-color: ${(p) =>
    p.state === "preinscripto"
      ? "#f9f5ec"
      : p.state === "confirmado"
      ? "#E6EBF9"
      : p.state === "baja"
      ? "#FCE9E8"
      : p.state === "egresado"
      ? "#EAFAF6"
      : "#e6ebf9"};
  font-weight: 600;
  color: ${(p) =>
    p.state === "preinscripto"
      ? "#C29F43"
      : p.state === "confirmado"
      ? "#5E80DB"
      : p.state === "baja"
      ? "#B31D15"
      : p.state === "egresado"
      ? "#1E8065"
      : "#5E80DB"};
`;

const IconCheck = styled(Icon)`
  font-size: 2.5rem;
  color: #b31d15;
  min-width: 3rem;
  max-width: 3rem;
`;

const ArrowWrapper = styled.div`
  flex-basis: 6%;
  text-align: end;
`;

const IconArrow = styled(Icon)`
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: ${(p) => (!p.moreInfo ? "rotate(180deg)" : "rotate(0deg)")};
  color: #000;
`;

const Body = styled.div`
  width: 100%;
  opacity: ${(p) => (!p.moreInfo ? "0" : "1")};
  display: flex;
  align-items: center;
  transition: all 0.3 ease-in-out;
  gap: 1rem;
  margin-top: 1rem;
  p {
    color: #616161;
    font-weight: bold;
    font-size: 1.1rem;
    span {
      font-weight: normal;
    }
  }
`;
