import React, { useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, Button } from '@mui/material';
import { SearchRounded, Search } from '@mui/icons-material';

const FilterFor = (props) => {
    const {
        handleChange,
        label,
        filterField,
        disabled = false,
        searchButton = false,
        handleSearch,
    } = props;

    return (
        <TextInput
            value={filterField}
            onChange={handleChange}
            variant="outlined"
            size="small"
            placeholder={label}
            disabled={disabled}
            InputProps={{
                endAdornment: (
                    <Button disabled={disabled} onClick={handleSearch}>
                        Buscar
                    </Button>
                ),
                startAdornment: (
                    <InputAdornment position="end">
                        <Search />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default FilterFor;

const TextInput = styled(TextField)`
    .MuiOutlinedInput-root {
        height: 56px;
        min-width: 420px;
        border-radius: 50px !important;
        outline: none !important;
        display: flex;
        gap: 1rem;
    }
`;
