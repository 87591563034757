import React from 'react';
import styled from 'styled-components';
import DefaultWrapper from '../../shared/DefaultWrapper';
import Text from '../../../common/Text';
import SimpleLoading from '../../../common/SimpleLoading';
import DownloadProgress from './DownloadProgress';
import Wrapper from '../../shared/Wrapper';
import CircularProgressBox from './CircularProgress';
import { Card } from '@mui/material';

const UserProgress = (props) => {
    const { userProgress } = props;

    //RETURN
    if (userProgress === null) {
        return <SimpleLoading />;
    }
    if (userProgress.length === 0) {
        return (
            <DefaultWrapper
                title={
                    userProgress
                        ? `El usuario "${userProgress.name}" no tiene cursos asignados`
                        : 'Avance por curso'
                }
                columns="span 18"
            />
        );
    }
    return (
        <DefaultWrapper columns="span 18">
            <DownloadProgress print={userProgress[0]} />
            <Container>
                {userProgress[0].courses.map((item, index) => (
                    <ProgressCard key={index}>
                        <div>
                            <Text
                                fontSize="0.875rem"
                                fontWeight="700"
                                lineheight="14px"
                            >
                                {item.course ? item.course : ''}
                            </Text>
                            <br />
                            <Text
                                fontSize="11px"
                                fontWeight="400"
                                lineheight="20px"
                                fontcolor="#616161"
                            >
                                {item &&
                                item.total !== 0 &&
                                item.viewed <= item.total
                                    ? `${item.viewed}/${item.total} Recursos cursados`
                                    : `${item.total}/${item.total} Recursos cursados`}
                            </Text>
                        </div>
                        <CircularProgressBox
                            percentage={
                                item.progress > 100 ? 100 : item.progress
                            }
                        />
                    </ProgressCard>
                ))}
            </Container>
        </DefaultWrapper>
    );
};

export default UserProgress;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 0.5rem;
    column-gap: 1.5rem;
    padding: 1.5rem;
    background-color: #f2f2f2;
    @media (max-width: 1600px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 1300px) and (min-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 650px) {
        grid-template-columns: 1fr;
    }
`;

const ProgressCard = styled(Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    width: calc(100% - 2rem);
    height: auto;
`;
