import React, { useState } from "react";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import StudentDataList from "./StudentDataList";
import Tab from "@mui/material/Tab";
import SimpleLoading from "../SimpleLoading";
import useFormatedOneStudentAA from "../../../hooks/useFormatedOneStudentAA";

const StudentPersonalData = (props) => {
    const { student, generalInfo } = props;
    const breakPoints = [{ width: 1, itemsToShow: 1 }];

    const { generalDataFormated, laboralDataFormated } =
        useFormatedOneStudentAA(
            generalInfo?.datos_generales,
            generalInfo?.datos_laborales
        );

    const tabs = [
        { label: `Datos generales`, id: 0 },
        { label: `Datos laborales`, id: 1 },
        // { label: "Datos académicos", id: 2 },
    ];

    function ToggleMain(props) {
        const { tab } = props;
        const cases = {
            0: <StudentDataList value={generalDataFormated} />,
            1: <StudentDataList value={laboralDataFormated} />,
            // 2: <StudentDataList value={student.academicData} />,
        };

        return (
            cases[String(tab)] || (
                <StudentDataList value={generalDataFormated} />
            )
        );
    }

    if (generalInfo === null) {
        return <SimpleLoading />;
    }

    return (
        <Container>
            <FakeCarousel
                length={tabs.length}
                //ref={carouselRef}
                breakPoints={breakPoints}
                itemPadding={[8]}
                showArrows={true}
            >
                {tabs.map((item) => (
                    <Card>
                        <FakeTab
                            label={item.label}
                            key={item.id}
                            value={item.id}
                        />
                        <ToggleMain tab={item.id} />
                    </Card>
                ))}
            </FakeCarousel>
        </Container>
    );
};

export default StudentPersonalData;
const Container = styled.div`
    background: #ffffff;
    padding: 1rem;
    width: calc(100% - 2rem);
`;

const FakeCarousel = styled(Carousel)`
    width: 100%;
    position: relative;

    .rec.rec-pagination {
        visibility: hidden;
        display: none;
    }

    .rec.rec-arrow {
        font-size: 12px;
        color: #b31d15;
        background-color: #ffffff;
        border: none;
        box-shadow: none;
        align-items: center;
        z-index: 1;
    }

    .rec.rec-arrow:hover {
        border-radius: 50%;
    }

    .rec.rec-arrow:disabled {
        visibility: hidden;
    }

    .rec-carousel-item:focus {
    }

    .rec-arrow.rec.rec-arrow-left {
        position: absolute;
        left: 0;
        top: 15px;
        background: transparent;
    }

    .rec-arrow.rec.rec-arrow-left button {
        background: #b31d15;
        transform: rotate(-180deg);
    }
    button .rec-arrow.rec.rec-arrow-left {
        color: #b31d15;
        width: 50px;
    }
    .rec-arrow.rec.rec-arrow-right {
        position: absolute;
        right: 0;
        top: 15px;
        background: transparent;
    }
    button .rec-arrow-right {
        background: #b31d15;
        transform: rotate(-180deg);
    }
`;

const FakeTab = styled(Tab)`
    font-weight: 700;
    text-transform: none;
    .MuiTab-wrapper {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        z-index: 2;
    }
`;

const Card = styled.div`
    padding: 0.5rem;
    width: 100%;
`;
