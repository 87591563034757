import { useDispatch, useSelector } from "react-redux";
import { layoutActions } from "../redux/actions";
import { useEffect } from "react";

const useLayout = () => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.layout?.currentMenu);

  const handleMenu = (title) => {
    if (menu !== title) {
      dispatch(layoutActions.setMenu(title));
    }
    localStorage.setItem("title", title);
  };

  const clearMenu = () => {
    if (menu) {
      dispatch(layoutActions.clearMenu());
    }
  };

  useEffect(() => {
    return () => {
      clearMenu();
    };
  }, []);

  return {
    menu,
    setMenu: handleMenu,
    clearMenu: clearMenu,
  };
};

export default useLayout;
