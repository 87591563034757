import React, { useState } from "react";
import styled from "styled-components";

// Components
import CardHistorySubmission from "./historySubmission/CardHistorySubmission";

// Material UI
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const HistorySubmissionStudent = (props) => {
    const isSmallerScreen = useMediaQuery("(max-width:580px)");

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isSmallerScreen ? "90%" : 524,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "20px",
        overflow: "hidden",
    };

    const { history, open, handleClose, idAssignment } = props;

    const [idSubmission, setIdSubmission] = useState(null);

    const handleExpand = (expand, id) => {
        expand ? setIdSubmission(id) : setIdSubmission(null);
    };  

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Header>
                    <p>Historial de entregas</p>
                    <CustomCloseIcon onClick={handleClose} />
                </Header>
                <Body>
                    {history.length > 0 ? (
                        <CardsWrapper>
                            {history.map((currentTask, index) => (
                                <CardContainer key={currentTask.id}>
                                    <Row>
                                        <CustomCheckCircleIcon />
                                        <CardHistorySubmission
                                            submission={currentTask}
                                            handleExpand={handleExpand}
                                            idAssignment={idAssignment}
                                            last={currentTask?.order === history.length + 1}
                                        />
                                    </Row>
                                    {currentTask?.professor_comment && (
                                        <Comment
                                            isShow={
                                                idSubmission === currentTask.id
                                            }
                                        >
                                            <p>
                                                {currentTask?.professor_comment}
                                            </p>
                                        </Comment>
                                    )}
                                </CardContainer>
                            ))}
                        </CardsWrapper>
                    ) : (
                        <p>No se encontraron entregas</p>
                    )}
                </Body>
            </Box>
        </Modal>
    );
};

export default HistorySubmissionStudent;

const Header = styled.div`
    position: relative;
    width: 100%;
    height: 70px;
    display: flex;
    background-color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        color: #fff;
        font-size: 1.2rem;
    }
`;

const CustomCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #fff;
    font-size: 1.5rem;
    right: 1rem;
    cursor: pointer;
`;

const Body = styled.div`
    height: 400px;
    width: calc(100% - 2rem);
    padding: 1rem;
    overflow-y: auto;
    position: relative;

    :after {
        position: absolute;
        content: "";
        bottom: 0;
        width: calc(100% + 1rem);
        left: -1rem;
        height: 8px;
        background-color: #b31d15;
    }
`;

const CardsWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    overflow: auto;
    transition: all 0.3s ease-in-out;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const CardContainer = styled.div`
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
    transition: all 0.3s ease-in-out;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
`;

const CustomCheckCircleIcon = styled(CheckCircleIcon)`
    color: #b31d15;
    font-size: 2.3rem;
`;

const Comment = styled.div`
    height: ${(p) => (p.isShow ? "calc(100% - 3rem)" : "0px")};
    overflow: hidden;
    width: calc(70% - 3rem);
    padding: ${(p) => (p.isShow ? "1.5rem" : "0")};
    color: #616161;
    background-color: #f1f1f1;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
`;
