import * as types from '../types';
import * as authTypes from '../types/auth_types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { authActions, ondemandActions } from '../actions';
import {
    loginSisApi,
    registerSisApi,
    updateUserByToken,
    updateUserPhoto,
    getUserByToken,
    registerOndemandSisApi,
    registerStudent,
    loginRoles,
    userData,
    getRecoverPasswordId
} from '../api/auth';
import { getOndemandById } from '../api/ondemand';

function* loginRequest(action) {
    try {
        const res = yield call(loginSisApi, action.payload);
        yield put(
            authActions.loginUserSuccess({
                user: { ...res.data.response_data, data_login: action.payload },
                origin: action.payload.origin
            })
        );
    } catch (error) {
        yield put(authActions.loginUserFail(error));
    }
}

function* registerRequest(action) {
    try {
        const res = yield call(registerSisApi, action.payload);
        yield put(
            authActions.registerUserSuccess(res.data.response_data.token)
        );
    } catch (error) {
        yield put(authActions.registerUserFail(error));
    }
}

function* registerStudentRequest(action) {
    try {
        const res = yield call(registerStudent, action.payload);
        yield put(
            authActions.registerStudentSuccess(
                res.data.response_data?.user?.email
            )
        );
    } catch (error) {
        yield put(authActions.registerStudentFail(error));
    }
}

function* registerOndemandRequest(action) {
    try {
        const res = yield call(registerOndemandSisApi, action.payload);
        yield put(
            authActions.registerUserOndemandSuccess(
                res.data.response_data.token
            )
        );
    } catch (error) {
        yield put(authActions.registerUserOndemandFail(error));
    }
}

function* updateRequest(action) {
    try {
        const res = yield call(updateUserByToken, action.payload);
        yield put(
            authActions.updateUserSuccess(
                res.data.response_data.cambios_realizados
            )
        );
    } catch (error) {
        yield put(authActions.updateUserFail(error));
    }
}

function* updatePhotoRequest(action) {
    try {
        yield call(updateUserPhoto, action.payload.form);
        yield put(
            authActions.updatePhotoSuccess({ foto: action.payload.photo })
        );
    } catch (error) {
        yield put(authActions.updatePhotoFail(error));
    }
}

function* instructureFirstClickRequest() {
    try {
        yield put(authActions.instructureFirstClickSuccess());
    } catch (error) {
        yield put(authActions.instructureFirstClickFail(error));
    }
}

function* userRequest(action) {
    try {
        const res = yield call(getUserByToken, action.payload);
        yield put(authActions.getUserSuccess(res.data));
    } catch (error) {
        yield put(authActions.getUserFail(error));
    }
}

function* loginTRequest(action) {
    try {
        const res = yield call(loginRoles, action.payload);
        yield put(authActions.loginTokenSuccess(res.data.response_data));
    } catch (error) {
        yield put(authActions.loginTokenFail(error.response.status));
    }
}

function* dataRequest(action) {
    try {
        const res = yield call(userData, action.payload);
        const ondemandId = res?.data?.response_data?.profile_data?.ondemand_id
        if (action.payload === 'ondemand' && !!ondemandId) {
            try {
                const resOndemand = yield call(getOndemandById, ondemandId);
                if (!res.data.error) {
                    yield put(
                        ondemandActions.getOndemandSuccess(resOndemand.data.response_data)
                    );
                }
                else {
                    yield put(ondemandActions.getOndemandFail(resOndemand.data.error));
                }
            } catch (error) {
                yield put(ondemandActions.getOndemandFail(error));
            }
        }
        yield put(
            authActions.getDataSuccess({
                data: res.data.response_data.profile_data,
                rol: action.payload
            })
        );
    } catch (error) {
        yield put(authActions.getDataFail(error));
    }
}

function* recoverRequest(action) {
    try {
        const response = yield call(getRecoverPasswordId, action.email)
        yield put(authActions.getRecoverPasswordIdSuccess(response.data.changePasswordId))
    } catch (error) {
        yield put(authActions.getRecoverPasswordIdFail(error))
    }
}

function* authWatcher() {
    yield takeEvery(types.LOGIN_USER_REQUEST, loginRequest);
    yield takeEvery(authTypes.LOGIN_TOKEN_REQUEST, loginTRequest);
    yield takeEvery(authTypes.GET_DATA_REQUEST, dataRequest);
    yield takeEvery(types.GET_USER_REQUEST, userRequest);
    yield takeEvery(types.REGISTER_USER_REQUEST, registerRequest);
    yield takeEvery(types.REGISTER_STUDENT_REQUEST, registerStudentRequest);
    yield takeEvery(
        types.REGISTER_USER_ONDEMAND_REQUEST,
        registerOndemandRequest
    );
    yield takeEvery(types.UPDATE_USER_REQUEST, updateRequest);
    yield takeEvery(types.UPDATE_PHOTO_REQUEST, updatePhotoRequest);
    yield takeEvery(
        types.INSTRUCTURE_FIRST_CLICK_REQUEST,
        instructureFirstClickRequest
    );
    yield takeEvery(types.GET_USER_REQUEST, userRequest);
    yield takeEvery(authTypes.GET_RECOVER_PASSWORD_ID_REQUEST, recoverRequest);
}

export { authWatcher };
