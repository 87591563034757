import React from 'react'
import MainSupport from '../../components/templates/support/MainSupport'

const Support = () => {
  return (
    <>
      <MainSupport/>
    </>
  )
}

export default Support