import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "styled-components";

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgressFake variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <TypographyFake
                    variant="body2"
                    sx={{ color: "red" }}
                >{`${Math.round(props.value)}%`}</TypographyFake>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const LinearProgressFG = (props) => {
    const { value } = props;

    const [progress, setProgress] = React.useState(value);

    return (
        <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
        </Box>
    );
};

export default LinearProgressFG;

const LinearProgressFake = styled(LinearProgress)`
    border-radius: 20px;
    height: 6.5px;
    background-color: #EEEEEE;
    div {
        background-color: #3bd39c;
    }
`;

const TypographyFake = styled(Typography)`
    color: #3bd39c;
`;
