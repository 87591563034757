import React from "react";
import { useQueryParams } from "./useQueryParams";

const useTabHistory = () => {
  const queryTab = useQueryParams();
  const initialTab = Number(queryTab.get("tab"));

  const handleTabHistory = (URL) => {
    window.history.pushState(false, false, URL);
  };

  return { handleTabHistory, initialTab };
};

export default useTabHistory;
