import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    beforeSend(event) {
        // console.log(event)
        // Aquí puedes filtrar errores específicos. Por ejemplo:
        if (event?.exception) {
            const error = event?.exception?.values[0];
            if (error?.type === 'TypeError' || error?.value?.includes('normalize')) {
                // Filtra errores no manejados (unhandled rejections)
                // console.log(error)
                return null;  // No enviar este evento a Sentry
            }
        }
        return event;  // Enviar el evento a Sentry
    },
    // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

export default Sentry;