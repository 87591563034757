import * as types from '../types/tickets_types';

const initialState = {
    // AREAS
    options: null,
    errorOptions: null,
    // TICKET
    ticket: null,
    errorTicket: null
};

export const tickets = (state = initialState, action) => {
    switch (action.type) {
        // GET AREAS
        case types.GET_OPTIONS_REQUEST:
            return {
                ...state,
                errorOptions: null
            };
        case types.GET_OPTIONS_SUCCESS:
            return {
                ...state,
                options: action.payload
            };
        case types.GET_OPTIONS_FAIL:
            return {
                ...state,
                errorOptions: action.payload
            };
        // GET TICKET
        case types.GET_TICKET_REQUEST:
            return {
                ...state,
                errorTicket: null
            };
        case types.GET_TICKET_SUCCESS:
            return {
                ...state,
                ticket: action.payload
            };
        case types.GET_TICKET_FAIL:
            return {
                ...state,
                errorTicket: action.payload
            };
        default:
            return state;
    }
};
