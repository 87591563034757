import { useState } from "react";


const useDialog = () => {

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const id = open ? 'popover' : undefined;

    const handleClick = (e) => {
        setOpen(!open)
        setAnchorEl(!anchorEl ? e.currentTarget : null);
    }

    const handleClose = () => {
        setOpen(false)
        setAnchorEl(null);
    }

    return {
        open,
        anchorEl,
        id,
        handleClick,
        handleClose
    }
}

export default useDialog