import React from 'react';
import { intereses } from './interesesSlides';
import Slider from 'react-slick';
import styled from 'styled-components';

const WorkshopsCards = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <DivContent>
            <DivSlider>
                <SliderFake {...settings}>
                    {intereses.map((slide, index) => {
                        return (
                            <Img
                                loading="lazy"
                                src={slide.image}
                                alt={slide.name}
                                key={index}
                            ></Img>
                        );
                    })}
                </SliderFake>
            </DivSlider>
        </DivContent>
    );
};

export default WorkshopsCards;

function NextArrow(props) {
    const { className, onClick } = props;
    return <DivNextArrow className={className} onClick={onClick} />;
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <DivPrevArrow className={className} onClick={onClick} />;
}

const DivContent = styled.div`
    width: 95%;
`;

const DivSlider = styled.div`
    display: flex;
    justify-content: center;
`;

const SliderFake = styled(Slider)`
    width: 80%;
    .slick-list {
        /* margin: 0 4rem; */
    }
    .slick-slide > div {
        padding: 2rem 1rem;
    }
`;

const Img = styled.img`
    width: 300px;
    height: auto;
`;

const DivNextArrow = styled.div`
    display: block;
    border-radius: 50%;
    &::before {
        font-size: 30px !important;
        line-height: 1 !important;
        opacity: 1 !important;
        color: #cd1f17 !important;
    }
`;

const DivPrevArrow = styled.div`
    display: block;
    border-radius: 50%;
    &::before {
        font-size: 30px !important;
        line-height: 1 !important;
        opacity: 1 !important;
        color: #cd1f17 !important;
    }
`;
