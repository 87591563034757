import React from "react";
import styled from "styled-components";
import VideoIcon from "@mui/icons-material/Videocam";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import BorderColorICon from "@mui/icons-material/BorderColor";
import { useHistory } from "react-router-dom";

const ContentGenAccordion = (props) => {
    
    const { resource } = props;

    const { push } = useHistory()

    return (
        <Container>
            <>
                {resource.sort((a,b) => a.order - b.order).map((contents, index) => {
                    return (
                        <Body onClick={() => push(`../../view/resource/${contents.id}`)} title={`Ir a ${contents.name}`} key={index}>
                            {contents.type === "material_plano" ? (
                                <InsertDriveFile />
                            ) : contents.type === "evaluacion" ? (
                                <BorderColorICon />
                            ) : contents.type === "audiovisual" ? (
                                <VideoIcon />
                            ) : null}
                            {contents.name}
                        </Body>
                    );
                })}
            </>
        </Container>
    );
};

export default ContentGenAccordion;
const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Body = styled.p`
    color: #616161;
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    margin: 15px;
    svg {
        font-size: 18px;
    }
    transition: ease-in-out .3s all;
    :hover{
        cursor: pointer;
        color: #b31d15;
    }
`;
