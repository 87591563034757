import React from "react";
import styled from "styled-components";
import Schedule from "./components/schedule/Schedule";
import Conversation from "./components/conversation/Conversation";
import SummitTheme from "./components/themes/SummitTheme";

const Summit = () => {
  return (
    <Wrapper>
      <ScheduleWrapper>
        <Schedule />
        <Conversation />
        <SummitTheme />
      </ScheduleWrapper>
    </Wrapper>
  );
};

export default Summit;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ScheduleWrapper = styled.div`
  display: grid;
  row-gap: 2rem;
  padding: 1rem 2rem;
`;
