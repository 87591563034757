import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SimpleLoading from '../../components/common/SimpleLoading';
import { microlearningsActions } from '../../redux/actions';
import ScrollMicrolearnings from '../../components/templates/microlearnings/ScrollMicrolearnings';
import useTheme from '../../hooks/useTheme';
import { titleHTML } from '../../helpers/title.helper';
import VimeoPlayer from '../../modules/acropolisCommon/components/VimeoPlayer';
import SocialsFooter from '../../components/ui/molecules/microlearnings/SocialsFooter';
import SharedResource from '../../components/common/SharedResource';

const Microlearnings = () => {
    titleHTML('Microlearnings');

    const { primary } = useTheme();
    const id = useParams();

    // REDUX
    const dispatch = useDispatch();
    const microlearnings = useSelector(
        (state) => state.microlearnings.microlearnings
    );
    const currentMicrolearning = useSelector(
        (state) => state.microlearnings.currentMicrolearning
    );


    const [openModal, setOpenModal] = useState(false);
    const [sharedItem, setSharedItem] = useState(null);
    const [typeVideo, setTypeVideo] = useState(null);

    // EFFECTS
    useEffect(() => {
        if (microlearnings === null) {
            dispatch(microlearningsActions.getMicrolearningsRequest());
        }
        if (microlearnings !== null) {
            dispatch(microlearningsActions.loadMicrolearning(id));
        }
    }, [microlearnings]);

    useEffect(() => {
        if (currentMicrolearning !== null) {
            const analysis = analyzeVideoUrl(currentMicrolearning.video_url);
            if (analysis.isVimeo) {
                setTypeVideo('vimeo');
            } else if (analysis.isGoogle) {
                setTypeVideo('google');
            } else if (analysis.isAden) {
                setTypeVideo('aden');
            } else {
                setTypeVideo('other');
            }
        }
    }, [currentMicrolearning]);

    // FUNCTIONS
    function handleCurrentMicrolearning(newMicrolearning) {
        dispatch(microlearningsActions.setMicrolearning(newMicrolearning));
    }

    const handleShareModal = () => {
        setSharedItem({
            lp_type: 'microcontent',
            type: 'microlearning',
            id: id.id,
            nombre_publicacion: currentMicrolearning?.publication_name,
        });
        setOpenModal(!openModal);
    };

    function analyzeVideoUrl(url) {
        return {
            isVimeo: url.includes('vimeo'),
            isGoogle: url.includes('google'),
            isAden: url.includes('aden'),
        };
    }

    const convertGoogleDriveUrl = (url) => {
        const regex = /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/view/;
        const match = url.match(regex);
        if (match && match[1]) {
            const fileId = match[1];
            return `https://drive.google.com/file/d/${fileId}/preview`;
        }
        return url;
    };

    // RETURN
    if (currentMicrolearning === null) {
        return <SimpleLoading padding="4rem" />;
    }

    return (
        <MicrolearningsContainer>
            <Title color={primary}>
                {currentMicrolearning.publication_name}
            </Title>
            <MicrolearningsMain
                isSimulator={
                    currentMicrolearning.categoria === 45 ? true : false
                }
            >
                <FirstColumn>
                    {typeVideo === 'vimeo' ? (
                        <VimeoPlayer
                            videoLink={currentMicrolearning.video_url}
                        />
                    ) : typeVideo === 'google' ? (
                        <iframe
                            src={convertGoogleDriveUrl(
                                currentMicrolearning.video_url
                            )}
                            allowFullScreen
                            width="100%"
                            height="480"
                            title="Google Drive Video"
                        ></iframe>
                    ) : (
                        <iframe
                            src={currentMicrolearning.video_url}
                            allowFullScreen
                            width="100%"
                            height="480"
                            title="Other Video"
                        ></iframe>
                    )}

                    <SocialsFooter
                        isFav={currentMicrolearning.favorito}
                        resourceId={currentMicrolearning.repo_id}
                        onClickModal={handleShareModal}
                    />
                </FirstColumn>
                {currentMicrolearning.categoria !== 45 && (
                    <ScrollMicrolearnings
                        microlearnings={microlearnings}
                        currentMicrolearningId={currentMicrolearning.repo_id}
                        handleCurrentMicrolearning={handleCurrentMicrolearning}
                    />
                )}
            </MicrolearningsMain>
            <SharedResource
                modalIsOpen={openModal}
                handleCloseModal={handleShareModal}
                sharedItem={sharedItem}
            />
        </MicrolearningsContainer>
    );
};

export default Microlearnings;

const MicrolearningsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const Title = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    color: ${(p) => p.color};
`;

const MicrolearningsMain = styled.div`
    display: ${(p) => (p.isSimulator ? 'flex' : 'grid')};
    grid-template-columns: 3fr 2fr;
    flex-direction: ${(p) => (p.isSimulator ? 'column' : 'row')};
    grid-gap: 2rem;

    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const FirstColumn = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 0 0 20px 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;
