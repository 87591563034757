import React from "react";
import styled from "styled-components";

// Components
import SideInfo from "./components/SideInfo";
import MainInfo from "./components/MainInfo";

// Redux
import { useSelector } from "react-redux";

// Helpers
import { titleHTML } from "../../helpers/title.helper";

// Context
import ProfileProvider from "../../contexts/profile/ProfileProvider";

const Profile = () => {
    titleHTML("Mi perfil");

    // REDUX
    const user = useSelector((state) => state.auth.user);

    return (
        <ProfileProvider>
            <ProfileContainer>
                <SideInfo />
                <MainInfo />
            </ProfileContainer>
        </ProfileProvider>
    );
};

export default Profile;

const ProfileContainer = styled.div`
    display: flex;
    gap: 2rem;

    @media (width < 1150px) {
        flex-direction: column;
    }
`;

const Body = styled.div`
    display: grid;
    grid-template-columns: 3fr minmax(0, 8fr);
    grid-gap: 1.5rem;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
    }
`;

const BodyInfo = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;
