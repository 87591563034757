import React, { useEffect } from "react";
import {
    Redirect,
    Route,
    useHistory,
    useLocation,
} from "react-router-dom";

import { connect, useSelector } from "react-redux";
import Layout from "../components/shared/layout/Layout";
import ChatLayout from "../components/shared/chat-layout/ChatLayout";
import LayoutCourse from "../components/shared/course-layout/LayoutCourse";
import LayoutTeacher from "../components/shared/teacher-layout/LayoutTeacher";
// import { useHelpChat } from "../hooks/useHelpChat";
import { checkRoles } from "../helpers/roles.helper";
/* import useHotjar from "../hooks/useHotjar"; */
import LayoutOndemand from "../components/shared/ondemand-layout/LayoutOndemand";
import AcademicLayout from "../components/shared/academic-layout/AcademicLayout";
import useLayout from "../hooks/useLayout";

const CommonRoute = (props) => {
    const {
        layout = "common",
        isAuthenticated,
        blockedRoles,
        permissionRoles,
        user,
        blockedStatus,
        title = false,
        component: Component,
        ...rest
    } = props;
    const finantial_status = blockedStatus;

    const { pathname } = useLocation();
    const urlRedirect = pathname.slice(1);

    // Redux
    const activeRol = useSelector((state) => state.auth.activeRol);

    // Hooks
    // const { setMenu, clearMenu } = useLayout()
    // const { hash } = useHelpChat(user, isAuthenticated, "private");
    /* useHotjar() */
    const history = useHistory();
    const redirect = () => history.push("not-found");

    // FUNCTION
    const renderLayout = (layout, Component) => {
        const cases = {
            common: renderByRol(activeRol, Component),
            chat: renderByRol(activeRol, Component),
            course: <LayoutCourse>{Component}</LayoutCourse>,
            iframe: <ChatLayout>{Component}</ChatLayout>,
        };

        return cases[layout] || cases["common"];
    };

    const renderByRol = (rol, Component) => {
        const cases = {
            docente: <LayoutTeacher minPadding={false}>
                {Component}
            </LayoutTeacher>,
            academico: <AcademicLayout >
                {Component}
            </AcademicLayout>,
            ondemand: <LayoutOndemand minPadding={false} layout={layout}>
                {Component}
            </LayoutOndemand>,
            alumno: <Layout
                finantial_status={finantial_status}
                layout={layout}
                title={title}
            >
                {Component}
            </Layout>,
        }

        return cases[rol] || cases['alumno']
    }

    if (
        (checkRoles(blockedRoles) && !checkRoles(permissionRoles)) ||
        blockedRoles.includes(activeRol)
    ) {
        redirect();
    }

    // RETURN
    return (
        <Route
            {...rest}
            component={(props) =>
                isAuthenticated ? (
                    renderLayout(layout, <Component {...props} />)
                ) : (
                    <Redirect to={urlRedirect === 'not-found' || urlRedirect === 'logout' || urlRedirect === 'cookies' ? '/auth' : `/auth?redirect=${urlRedirect}`} />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.user,
    user: state.auth.user,
    blockedStatus: state.userStatus.blockedStatus,
});

export default connect(mapStateToProps)(CommonRoute);
