import React from 'react';
import styled from 'styled-components';

// Components
import { Icon } from '@iconify/react';
import CommonChip from '../../../../../../components/common/CommonChip';


const StateChip = (props) => {

    const { 
        enrollmentState,
        isExpired, 
        setOpenModalBloqued, 
        setOpenModalExpired 
    } = props;

    const stateKey = isExpired ? 'vencida' : enrollmentState?.toLowerCase();

    const chipContent = {
        egresado: (
            <CommonChip
                label="Aprobada"
                variant="status"
                strongColor="#1E8065"
                lightColor="#EAFAF6"
                Icon={() => <Icon icon="ic:round-check" width="16px" height="16px" />}
            />
        ),
        baja: (
            <ErrorWrapper>
                <CommonChip 
                    label="Bloqueada"
                    variant="status"
                    strongColor="#B31D15"
                    lightColor="#FFF"
                    // Revisar este Icon
                    Icon={() => <Icon icon="solar:lock-outline" width="16px" height="16px" />}
                />
                <button onClick={() => setOpenModalBloqued(true)}>
                    ¿Por qué está bloqueada?
                </button>
            </ErrorWrapper>
        ),
        vencida: (
            <ErrorWrapper>
                <CommonChip
                    label="Vencida"
                    variant="status"
                    strongColor="#B31D15"
                    lightColor="#FFF"
                    // Revisar este Icon
                    Icon={() => <Icon icon="ph:calendar-x-light" width="16px" height="16px" />}
                />
                <button onClick={() => setOpenModalExpired(true)}>
                    ¿Por qué está vencida?
                </button>
            </ErrorWrapper>
        )
    };

    return (
        <div>
            {chipContent[stateKey] || null}
        </div>
    );
};

export default StateChip;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1rem;

    button {
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        color: #cd2118;

        :hover {
            font-weight: 700;
        }
    }
`;