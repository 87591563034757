import { useState } from "react";

const useCountDown = () => {
    const [timers, setTimer] = useState(null);

    const getTime = (dateTo) => {
        let now = new Date(),
            time = (new Date(dateTo) - now + 1000) / 1000,
            seconds = ("0" + Math.floor(time % 60)).slice(-2),
            minutes = ("0" + Math.floor((time / 60) % 60)).slice(-2),
            hours = ("0" + Math.floor((time / 3600) % 24)).slice(-2),
            days = Math.floor(time / (3600 * 24));

        return {
            seconds,
            minutes,
            hours,
            days,
            time,
        };
    };

    const countdown = (dateTo) => {
        const timerUpdate = setInterval(() => {
            let currenTime = getTime(dateTo);

            setTimer(
                `${currenTime.days}d ${currenTime.hours}h ${currenTime.minutes}m ${currenTime.seconds}s`
            );

            if (currenTime.time <= 1) {
                setTimer(0);
                clearInterval(timerUpdate);
            }
        }, 1000);
    };

    return {
        countdown,
        timers,
    };
};

export default useCountDown;
