import * as types from '../types';
import * as authTypes from '../types/auth_types';

const actions = {};

// LOGIN
actions.loginUserRequest = (data) => {
    return {
        type: types.LOGIN_USER_REQUEST,
        payload: data
    };
};

actions.loginUserSuccess = (payload) => {
    return {
        type: types.LOGIN_USER_SUCCESS,
        payload: payload.user
    };
};

actions.loginUserFail = (error) => {
    return {
        type: types.LOGIN_USER_FAIL,
        payload: error
    };
};

actions.clearErrorLogin = () => {
    return {
        type: types.CLEAR_ERROR_LOGIN
    };
};

// LOGOUT
actions.logoutUser = () => {
    return {
        type: types.LOGOUT_USER
    };
};

// EDIT USER
actions.updateUserRequest = (data) => {
    return {
        type: types.UPDATE_USER_REQUEST,
        payload: data
    };
};

actions.updateUserSuccess = (user) => {
    return {
        type: types.UPDATE_USER_SUCCESS,
        payload: user
    };
};

actions.updateUserFail = (error) => {
    return {
        type: types.UPDATE_USER_FAIL,
        payload: error
    };
};

actions.updateUser = (data) => {
    return {
        type: types.SET_UPDATE_USER,
        payload: data
    };
};

actions.closeSnackbar = () => {
    return {
        type: types.CLOSE_SNACKBAR
    };
};

// UPDATE PHOTO
actions.updatePhotoRequest = (payload) => {
    return {
        type: types.UPDATE_PHOTO_REQUEST,
        payload: payload
    };
};

actions.updatePhotoSuccess = (photo) => {
    return {
        type: types.UPDATE_PHOTO_SUCCESS,
        payload: photo
    };
};

actions.updatePhotoFail = (error) => {
    return {
        type: types.UPDATE_PHOTO_FAIL,
        payload: error
    };
};

// INSTRUCTURE FIRST CLICK
actions.canvasLoginSuccess = () => {
    return {
        type: types.LOGIN_CANVAS
    };
};

actions.instructureFirstClickRequest = () => {
    return {
        type: types.INSTRUCTURE_FIRST_CLICK_REQUEST
    };
};

actions.instructureFirstClickSuccess = () => {
    return {
        type: types.INSTRUCTURE_FIRST_CLICK_SUCCESS
    };
};

actions.instructureFirstClickFail = (error) => {
    return {
        type: types.INSTRUCTURE_FIRST_CLICK_FAIL,
        payload: error
    };
};

// REGISTER USER VISITA
actions.registerUserRequest = (user) => {
    return {
        type: types.REGISTER_USER_REQUEST,
        payload: user
    };
};

actions.registerUserSuccess = (payload) => {
    return {
        type: types.REGISTER_USER_SUCCESS,
        payload: payload
    };
};

actions.registerUserFail = (error) => {
    return {
        type: types.REGISTER_USER_FAIL,
        payload: error
    };
};

actions.clearErrorRegister = () => {
    return {
        type: types.CLEAR_ERROR_REGISTER
    };
};

// REGISTER USER ONDEMAND
actions.registerUserOndemandRequest = (data) => {
    // console.log("action", data);
    return {
        type: types.REGISTER_USER_ONDEMAND_REQUEST,
        payload: data
    };
};

actions.registerUserOndemandSuccess = (payload) => {
    return {
        type: types.REGISTER_USER_ONDEMAND_SUCCESS,
        payload: payload
    };
};

actions.registerUserOndemandFail = (error) => {
    return {
        type: types.REGISTER_USER_ONDEMAND_FAIL,
        payload: error
    };
};

actions.clearErrorOndemandRegister = () => {
    return {
        type: types.CLEAR_ERROR_ONDEMAND_REGISTER
    };
};

// GET USER REQUEST
actions.getUserRequest = (authToken) => {
    return {
        type: types.GET_USER_REQUEST,
        payload: authToken
    };
};

actions.getUserSuccess = (payload) => {
    return {
        type: types.GET_USER_SUCCESS,
        payload: payload
    };
};

actions.getUserFail = (error) => {
    return {
        type: types.GET_USER_FAIL,
        payload: error
    };
};

// REGISTER USER ESTUDIANTE, ALTA COMERCIAL
actions.registerStudentRequest = (user) => {
    return {
        type: types.REGISTER_STUDENT_REQUEST,
        payload: user
    };
};

actions.registerStudentSuccess = (payload) => {
    return {
        type: types.REGISTER_STUDENT_SUCCESS,
        payload: payload
    };
};

actions.registerStudentFail = (error) => {
    return {
        type: types.REGISTER_STUDENT_FAIL,
        payload: error
    };
};

actions.setRefreshToken = (token) => {
    return {
        type: types.SET_REFRESH_TOKEN,
        payload: token
    };
};

// Nuevo Login /////////////////////////////////////////
actions.loginTokenRequest = (data) => {
    return {
        type: authTypes.LOGIN_TOKEN_REQUEST,
        payload: data
    };
};

actions.loginTokenSuccess = (payload) => {
    return {
        type: authTypes.LOGIN_TOKEN_SUCCESS,
        payload
    };
};

actions.loginTokenFail = (error) => {
    return {
        type: authTypes.LOGIN_TOKEN_FAIL,
        payload: error
    };
};

// GET DATA
actions.getDataRequest = (data) => {
    return {
        type: authTypes.GET_DATA_REQUEST,
        payload: data
    };
};

actions.getDataSuccess = (payload) => {
    return {
        type: authTypes.GET_DATA_SUCCESS,
        payload
    };
};

actions.setChallengeDracma = (payload) => {
    return {
        type: authTypes.SET_CHALLENGE_DRACMA,
        payload
    };
};

actions.getDataFail = (error) => {
    return {
        type: authTypes.GET_DATA_FAIL,
        payload: error
    };
};

actions.setTokenAuth = (payload) => {
    return {
        type: authTypes.SET_TOKEN_AUTH,
        payload: payload
    };
};

// RECOVER PASSWORD
actions.getRecoverPasswordIdRequest = (email) => {
    return {
        type: authTypes.GET_RECOVER_PASSWORD_ID_REQUEST,
        email
    }
}

actions.getRecoverPasswordIdSuccess = (payload) => {
    return {
        type: authTypes.GET_RECOVER_PASSWORD_ID_SUCCESS,
        payload
    }
}

actions.getRecoverPasswordIdFail = (error) => {
    return {
        type: authTypes.GET_RECOVER_PASSWORD_ID_FAIL,
        payload: error
    }
}

export { actions };
