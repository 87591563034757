import { Card } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import CourseCardRounded from "../../common/CourseCardRounded";
import SplitButton from "../../common/SplitButton";
import { Text } from "../../common/Texts";
import ErrorResponse from "../../common/ErrorResponse";
import SimpleLoading from "../../common/SimpleLoading";
import useCoursesAA from "../../../hooks/useCoursesAA";
import { useSelector } from "react-redux";

const CoursesAcademic = () => {
    //STATE
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        { label: "En curso", id: 0 },
        { label: "Finalizados", id: 1 },
    ];
    const options = ["Por defecto", "Más antiguo", "Más nuevo"];

    const AARepositorioPartnerId = useSelector(
        (state) => state?.auth?.user?.repositorio_data?.repositorio_id
    );

    const { courses, handleCourses } = useCoursesAA();
    const [currentFilter, setCurrentFilter] = useState({
        index: 0,
        name: "Por defecto",
    });

    const [tab, setTab] = useState(0);

    const handleOption = (args) => {
        setCurrentFilter(args);
        args.index === 0 && handleCourses(courses.sort((a, b) => a.id > b.id));
        args.index === 1 &&
            handleCourses(courses.sort((a, b) => a.date_end > b.date_end));
        args.index === 2 &&
            handleCourses(courses.sort((a, b) => a.date_end < b.date_end));
    };

    const handleTab = (_, value) => {
        setTab(value);
    };

    //RETURN
    if (courses === false) {
        return (
            <CoursesWrapper>
                <ErrorResponse message="¡Ocurrió un error al cargar las asignaturas!" />
            </CoursesWrapper>
        );
    }
    if (courses === null) {
        return (
            <CoursesWrapper>
                <SimpleLoading />
            </CoursesWrapper>
        );
    }
    if (AARepositorioPartnerId === null || AARepositorioPartnerId === false) {
        return (
            <CoursesWrapper>
                <ErrorResponse message="¡Ocurrió un error al cargar las asignaturas!" />
            </CoursesWrapper>
        );
    }
    return (
        <CoursesWrapper>
            <Header>
                <Text color="#555555" fontWeight="700" fontSize="1.2rem">
                    Mis asignaturas
                </Text>
                <RightButtons>
                    <SplitButton
                        handleOption={handleOption}
                        options={options}
                    />
                </RightButtons>
            </Header>

            <WrapperTabs>
                {tabs.map((tab) => (
                    <Title
                        key={tab.id}
                        onClick={() => setActiveTab(tab.id)}
                        active={tab.id === activeTab ? true : false}
                    >
                        {tab.label}
                    </Title>
                ))}
            </WrapperTabs>

            <Grid>
                {tab === 0
                    ? currentFilter !== null && (
                          <>
                              {courses.map((course, index) => (
                                  <CourseCardRounded
                                      key={index}
                                      values={course}
                                      academic={"academic"}
                                  />
                              ))}
                          </>
                      )
                    : currentFilter !== null && (
                          <>
                              {courses.map((course, index) => (
                                  <CourseCardRounded
                                      key={index}
                                      values={course}
                                      academic={"academic"}
                                  />
                              ))}
                          </>
                      )}
            </Grid>
        </CoursesWrapper>
    );
};

export default CoursesAcademic;

const CoursesWrapper = styled(Card)`
    grid-area: courses;
    display: flex;
    flex-direction: column;
    border-radius: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 2rem 1.5rem;
`;

const Header = styled.div`
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const RightButtons = styled.div`
    align-self: end;
`;

const Grid = styled.div`
    margin: 1rem 0 0 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;

    @media (max-width: 768px) {
        justify-items: center;
    }

    @media (max-width: 400px) {
        grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
    }
`;

const WrapperTabs = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
`;

const Title = styled.div`
    cursor: pointer;
    color: ${(p) => (p.active ? "#b31d15" : "#555555")};
    font-weight: 700;
    font-size: 1rem;
    background-color: #fff;
    border: ${(p) => (p.active ? "0.5px solid #f3f3f3" : "none")};
    box-shadow: ${(p) =>
        !!p.active
            ? `0px 3px 5px rgba(0, 0, 0, 0.15), 
        0px 5px 10px rgba(0, 0, 0, 0.06)`
            : "none"};
    border-radius: 5px;
    padding: 8px 14px;
    transition: all 0.3s ease-in-out;

    :hover {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15),
            0px 5px 10px rgba(0, 0, 0, 0.06);
    }
`;
