export const historicalGradesAdapter = (grades) => {
    const total = grades?.total || 0;
    const mappedGrades =
        grades?.data.map((grade) => ({
            id: grade?.id,
            student: grade?.student.name,
            activity: grade?.activity?.name,
            teacher: grade?.submission?.grading_professor?.name,
            type: grade?.type,
            qualificationDate: grade?.submission?.date || grade?.submission?.correction_date,
            presentationDate: grade?.submission?.send_date, //solo para tipo "presentacion", las "auto_corregible" solo tienen fecha "date"
            qualification: grade?.score,
            maxScore: grade?.max_score,
            idSubmission: grade?.submission?.id,
            numberSubmission: grade?.submission?.number,
            stateSubmission: grade?.submission?.state,
        })) || [];
    return {
        total,
        grades: mappedGrades,
    };
};

export const historicalStudentsAdapter = (students) => {
    const mappedStudents =
        students?.map((student) => ({
            id: student?.id,
            name: student?.name,
            email: student?.email,
        })) || [];
    return {
        studentsList: mappedStudents,
    };
};

export const historicalActivitiesAdapter = (activities) => {
    const mappedActivities =
        activities?.map((activity) => ({
            id: activity?.id,
            name: activity?.name,
            type: activity?.type,
        })) || [];
    return {
        activitiesList: mappedActivities,
    };
};
