import React, { useState } from 'react'
import styled from 'styled-components'
import { Span, Text } from '../../common/Texts'
import Button from "@mui/material/Button";
import { Img } from '../../common/Image';
import logo from "../../../assets/media/aden/30-red.png";

const Questionnaire = (props) => {

    const { questions } = props

    const [correct, setCorrect] = useState(null)
    const [currentQuestion, setCurrentQuestion] = useState(questions[0])
    const [indexQuestion, setIndexQuestion] = useState(0)


    const handleQuestion = item => {
        setCorrect(item.isCorrect)
    }

    const handleNext = () => {
        const newIndex = indexQuestion + 1
        setCurrentQuestion(questions[newIndex])
        setIndexQuestion(newIndex)
        setCorrect(null)
    }

    console.log(currentQuestion);

    return <Wrapper>
        <Number><Span color="#b31d15" fontWeight="600" fontSize="16px">{indexQuestion + 1}</Span> <Span color="#999999" fontWeight="600" fontSize="14px"> / 5</Span></Number>
        <Img src={logo} w="150px"/>
        {correct === null && <Text textAlign="center" color="#b31d15" fontWeight="600" fontSize="18px">{currentQuestion.title}</Text>}
        {correct === null && (<Options>
            {currentQuestion.response.map(item => <Option onClick={() => handleQuestion(item)}>
                {item.text}
            </Option>)}
        </Options>)}
        {correct === true && <h1>Correcto</h1>}
        {correct === false && <h1>Incorrecto</h1>}
        <Footer>
            <Button disabled={correct !== null && questions.length !== (indexQuestion + 1) ? false : true} size='small' fullWidth color='primary' onClick={handleNext}>Siguiente</Button>
        </Footer>
    </Wrapper>
}

export default Questionnaire

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Options = styled.div`
    margin: 15px 0;
    width: calc(100% - 20px);
    padding: 0 10px;
    display: grid;
    row-gap: 10px;
`

const Option = styled.div`
    cursor: pointer;
    color: #b31d15;
    font-weight: 600;
    width: calc(100% - 10px);
    background-color: white;
    text-align: center;
    padding: 5px;
    box-shadow: 0px 0px 10px 0px rgba(214,214,214,0.75);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(214,214,214,0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(214,214,214,0.75);
    border-radius: 5px;
`

const Footer = styled.div`
    margin-top: 15px;
    padding: 0 10px;
    width: calc(100% - 20px);
`

const Number = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
`