import React, { useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { connect, useDispatch } from 'react-redux';
import { encrypt } from '../utils/encrypt';
import { authActions } from '../redux/actions';

const RedirectTo = ({ isAuthenticated }) => {
    // QUERIES
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let history = useHistory();
    let query = useQuery();

    let user = query.get('user');
    let password = query.get('password');
    let redirect = query.get('redirect');

    // REDUX
    const dispatch = useDispatch();

    // EFFECTS
    useEffect(() => {
        if (user && password) {
            dispatch(
                authActions.loginUserRequest({
                    user: encrypt(user),
                    password: encrypt(password),
                    origin: 'redirect'
                })
            );
        } else {
            history.push('/auth');
        }
    }, []);

    // RETURN
    if (isAuthenticated) {
        return <Redirect to={`/${redirect}`} />;
    }

    return (
        <>
            <div id="splash-screen" className="kt-splash-screen">
                <img
                    src="/assets/aden/logo-aden-simple-red.png"
                    alt="Aden Business School"
                    width="200"
                    loading="lazy"
                />
                <svg className="splash-spinner" viewBox="0 0 50 50">
                    <circle
                        className="path"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        strokeWidth="2"
                    ></circle>
                </svg>
            </div>
            {/** Formulario canvas */}
            <form
                id="formlogin-redirect"
                onSubmit={(e) => {
                    e.preventDefault();
                    // console.log(e);
                }}
                action={
                    `${process.env.REACT_APP_FA_ACTION}` ||
                    'https://fa.aden.org/oauth2/authorize'
                }
                method="post"
            >
                <input
                    type="hidden"
                    name="client_id"
                    value="2c54fc21-43b7-4c96-ba29-a4a66fd54342"
                />
                <input
                    type="hidden"
                    name="redirect_uri"
                    value={`${window.location.origin}/${
                        redirect !== undefined && redirect
                    }`}
                />
                <input type="hidden" name="response_type" value="code" />
                <input
                    type="hidden"
                    name="state"
                    value="iY3A4ZPnWt_MMtEtMIaKDbpjQ5BQFm0M2mDevG3UrVI"
                />
                <input
                    type="hidden"
                    name="timezone"
                    value="America/Argentina/Buenos_Aires"
                />
                <input
                    type="hidden"
                    name="loginId"
                    value={user !== null ? encrypt(user) : ''}
                />
                <input
                    type="hidden"
                    name="password"
                    value={password !== null ? encrypt(password) : ''}
                />
            </form>
        </>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.user
});

export default connect(mapStateToProps)(RedirectTo);
