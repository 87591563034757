import * as types from '../types';

const initialState = {
    motivations: null,
    listLoading: false,
    errorList: null
};

export const motivations = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_MOTIVATIONS_REQUEST:
            return {
                ...state,
                listLoading: true
            };
        case types.GET_MOTIVATIONS_SUCCESS:
            return {
                ...state,
                motivations: action.payload,
                listLoading: false
            };
        case types.GET_MOTIVATIONS_FAIL:
            return {
                ...state,
                errorList: action.payload,
                listLoading: false
            };
        default:
            return state;
    }
};
