import * as encode from 'nodejs-base64-encode';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useEffect } from 'react';

// Components
import SimpleLoading from '../../../../components/common/SimpleLoading';

// Material UI
import { Button, Modal } from '@mui/material';

// Hooks
import usePauseProgram from './hooks/usePauseProgram';

const ModalProblem = (props) => {
    const { isOpen, handleCloseModal, programName, idProgram } =
        props;

    const CONFIRM_IMG_ROUTE = 'url("/assets/aden/pregunta-electiva.png")';

    const hash = encode.encode(String(idProgram), 'base64')

    const { loading, error, postEnrollmentPause } = usePauseProgram();

    const repoId = useSelector((state) => state?.auth?.user?.repo_id);
    const history = useHistory();

    const handleClick = () => {
        const body = {
            partnerId: repoId,
            programId: idProgram,
            state: 'cursando',
        };

        postEnrollmentPause(body);
    };

    useEffect(() => {
        if (error === false) {
            history.push(
                `../mi-progreso/${hash}`
            );
        }
        //eslint-disable-next-line
    }, [error]);

    return (
        <>
            <Modal
                onClose={handleCloseModal}
                open={isOpen}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.001)' }}
            >
                <Container>
                    <Image img={CONFIRM_IMG_ROUTE} />
                    <Title>
                        {!error ? 'Retomar cursado' : 'Hubo un error'}
                    </Title>
                    <Description>
                        ¿Deseas reanudar el cursado del
                        <span> {programName}</span>?
                    </Description>
                    {loading ? (
                        <SimpleLoading />
                    ) : (
                        <CustomButton
                            onClick={handleClick}
                            variant="contained"
                            color="primary"
                        >
                            Retomar cursado
                        </CustomButton>
                    )}
                </Container>
            </Modal>
        </>
    );
};

export default ModalProblem;

const Container = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 20px;
    width: 405px;
    max-width: 90vw;
    height: fit-content;
    max-height: 90vh;
    padding: 2rem 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    overflow-y: auto;
`;

const Image = styled.div`
    width: 100%;
    background-image: ${(p) => p.img};
    background-position: center;
    height: 350px;
    background-size: contain;
`;

const Title = styled.h2`
    color: #b31d15;
    font-size: 1.2rem;
`;

const Description = styled.p`
    color: #222;
    font-size: 1rem;
    text-align: center;
    span {
        font-weight: bold;
    }
`;

const CustomButton = styled(Button)`
    border-radius: 8px;
`;
