import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import TabsComponent from "../../components/common/TabsComponent";
import GridCoursesCanvas from "../../components/templates/course/teacher/GridCoursesCanvas";
import GridCoursesTeacher from "../../components/templates/course/teacher/GridCoursesTeacher";
import { titleHTML } from "../../helpers/title.helper";

const CourseTeacher = () => {
    titleHTML("Cursos");

    const professorSisId = useSelector(
        (state) => state?.auth?.user?.repositorio_data?.professor_sis_id
    );

    const tabs = !!professorSisId ? [{ label: "Acrópolis", id: 0 }, { label: "Canvas", id: 1 }] : [{ label: "Acrópolis", id: 0 }];

    const [tab, setTab] = useState(0);

    const handleTab = (_, value) => {
        setTab(value);
    };

    const renderCase = (type) => {
        const caseType = {
            0: <GridCoursesTeacher />,
            1: <GridCoursesCanvas />,
        };

        return caseType[type];
    };

    return (
        <>
            <CourseTeacherWrapper>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
                <TabCase>{renderCase(tab)}</TabCase>
            </CourseTeacherWrapper>
        </>
    );
};

export default CourseTeacher;

const CourseTeacherWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: banner;
    height: auto;
    border-radius: 2rem;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    header {
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
    }
`;

const TabCase = styled.div`
    padding: 1rem;
`;
