import axios from 'axios';

export function getTipoInconveniente() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/resources/tipo_inconveniente`
    );
}

export function postInconveniente(payload) {
    return axios.post(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/resources/recursos/${payload.recurso_id}/inconvenientes/${payload.tipo_inconveniente_id}`,
        { detalle: payload.detalle }
    );
}
