import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CardArticles = (props) => {
    const { value } = props;

    // FUNCTIONS
    const handleClick = () => {
        window.open(value.link);
    };

    return (
        <CardFake onClick={handleClick} image={value?.image || ""}>
            <Header img={value.cover_link}></Header>
            <Body>
                <TagsWrapper>
                    <>
                        {value?.tags?.length > 0 &&
                            value?.tags
                                .slice(0, 2)
                                .map((tag, index) => (
                                    <Chip key={index}>{tag}</Chip>
                                ))}
                    </>
                </TagsWrapper>
                <h2>{value.title}</h2>
                <p>{value.preview}</p>
                <Span>{new Date(value.publishedAt).toLocaleString()}</Span>
            </Body>
        </CardFake>
    );
};

export default CardArticles;

const CardFake = styled(Card)`
    width: 100%;
    cursor: pointer;
    /* min-width: 323px;
    max-width: 350px; */
    border-radius: 20px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
`;

const Header = styled.div`
    height: 100px;
    padding: 1.5rem;
    border-radius: 20px;
    width: calc(100% - 3rem);
    background-image: ${(p) => `url('${p.img}')`};
    background-size: cover;
    background-position: center center;
`;

const TagsWrapper = styled.div`
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;
`;

const Chip = styled.div`
    width: calc(min-content - 0.6rem);
    height: calc(auto - 0.6rem);
    padding: 0.2rem;
    background-color: #b31d15;
    color: #fff;
    font-size: 13px;
    border-radius: 5px;
`;

const Body = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 14rem;
    gap: 0.8rem;

    h2 {
        color: #222222;
        font-size: 1.2rem;
    }

    p {
        overflow: auto;
        color: #616161;
        font-size: 0.93rem;

        &::-webkit-scrollbar {
            width: 1px;
        }
    }
`;

const Span = styled.span`
    color: #a8a8a8;
    font-size: 0.9rem;

    span {
        font-weight: bold;
    }
`;
