import styled from "styled-components";

const ModalConfirm = (props) => {

    const { student, setModalConfirm, setModalForm } = props;

    return (
        <>
            <h2>
                Por favor, comprueba que esta
                <br />
                información se corresponsa con tu
                <br />
                correo eletrónico
            </h2>
            <ul>
                <li>
                    Nombre: <b>{student?.name}</b>
                </li>
                <li>
                    Correo eletrónico: <b>{student?.personal_email}</b>
                </li>
                <li>
                    Legajo: <b>{student?.partner_id}</b>
                </li>
            </ul>
            <ButtonsContainer>
                <SecondaryButton
                    onClick={() => {
                        setModalConfirm(false);
                        setModalForm(true);
                    }}
                >
                    Usar otro correo
                </SecondaryButton>
                <PrimaryButton
                    onClick={() => {
                        setModalConfirm(false);
                    }}
                >
                    Confirmar correo
                </PrimaryButton>
            </ButtonsContainer>
        </>
    )
}

export default ModalConfirm;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`;

const PrimaryButton = styled.button`
    cursor: pointer;
    background-color: #cd2118;
    color: #fff;
    border-radius: 10px;
    height: 40px;
    padding: 0 1rem;
    text-align: center;
    font-size: 16px;
    font-weight: 600;

    :hover {
        background-color: #b31d15;
    }
`

const SecondaryButton = styled.button`
    cursor: pointer;
    height: 40px;
    border: 1px solid #b31d15;
    border-radius: 10px;
    background-color: #fff;
    color: #b31d15;
    font-size: 16px;
    font-weight: 600;
    padding: 0 1rem;
`