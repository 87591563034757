import store from '../redux/store';

/*
    Resource_id: Id del recurso
    Activity: 'Login', 'Ingreso Workshop', 'Visualizar recurso' , 'Compartir recurso' , 'Actualizar perfil'
    Name: Nombre de actividad
*/
export const userAction = (props) => {
    const { object_id, object, activity, name, medio } = props;
    const token = store.getState().auth.authToken;
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/activities`;

    if (token) {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const body = JSON.stringify({
            object_id, // debe ser un entero
            object, // debe ser 'recurso', 'objeto_aprendizaje' o 'asignatura'
            name, // Nombre descriptivo de la actividad
            activity, // Nombre de la actividad a trackear
            medio // En caso de ser una actividad de 'comartir', el medio por el cual se compartió
        });

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: body
        };

        fetch(URL, requestOptions).then((response) => response.text());
    } else {
        console.error('Token no encontrado');
    }
};

// const getToken = () => {
//   return store.getState().auth.authToken
//     ? store.getState().auth.authToken
//     : false;
// };
