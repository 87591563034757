import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Material UI}
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

const AttachmentsFile = (props) => {
    const { handleSendFile, file } = props;

    const FILE_SIZE_LIMIT_IN_BYTES = 10485760; // ====> 10MB

    const [attachFile, setAttachFile] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (file) {
            setAttachFile(file);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFile = (e) => {
        const [file] = e?.target?.files;

        if (file.size >= FILE_SIZE_LIMIT_IN_BYTES) {
            alert('Tamaño máxido 10MB');
            return;
        }

        if (
            file.type === 'application/pdf' ||
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'application/msword' ||
            file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            file.type === 'application/vnd.ms-excel' || // Excel (XLS)
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || // Excel (XLSX)
            file.type === 'application/vnd.ms-powerpoint' || // PowerPoint (PPT)
            file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' // PowerPoint (PPTX)
        ) {
            handleSendFile(file);
            setAttachFile(file?.name);
        } else {
            alert('Formatos de archivo inválido');
            return;
        }
    };

    const handleCleanFile = () => {
        setAttachFile(null);
        setOpen(false)
    }

    return (
        <Container>
            <Label htmlFor="tucu">
                <AttachFileIcon />
            </Label>
            <Input
                multiple={false}
                onChange={handleFile}
                id="tucu"
                type="file"
            />
            {attachFile && (
                <LabelFileSaved onClick={() => setOpen(true)} title={attachFile}>
                    <InsertDriveFileIcon />
                </LabelFileSaved>
            )}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContainer>
                    <Button variant="contained" onClick={() => window.open(attachFile)}>
                        Ver archivo
                    </Button>
                    <Button variant="outlined" onClick={handleCleanFile}>
                        <DeleteForeverIcon />
                        Borrar archivo
                    </Button>
                    <Button variant="contained" onClick={() => setOpen(false)}>
                        Cerrar
                    </Button>
                </DialogContainer>
            </Dialog>
        </Container>
    );
};

export default AttachmentsFile;

const Container = styled.div`
    display: flex;
    /* flex-direction: column; */
    gap: 0rem;
`;

const Label = styled.label`
    width: 38px;
    height: 38px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 1.5rem;
        fill: ${(p) => (p.disabled ? '#e1e1e1' : '#a9a9a9')};
    }
    :hover {
        background-color: #efefef;
    }
`;

const LabelFileSaved = styled.button`
    cursor: pointer;
    width: 38px;
    height: 38px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b31d15;
    :hover {
        background-color: #b31d15;
    }
    svg {
        font-size: 1.5rem;
        fill: #fff;
    }
`;

const Input = styled.input`
    display: none;
`;

const DialogContainer = styled.div`
    display: flex;
    gap: 1rem;
    background-color: #fff;
    padding: 1rem;
    border-radius: 5px;
`