import { useState } from 'react';
import { postAssignmentPrograms } from '../redux/api/studyPlan';
import { useSnackbar } from 'react-simple-snackbar';
import { optionsStyle } from '../utils/snackStyles';

const useElectives = () => {
    const [openSnackbar] = useSnackbar(optionsStyle);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // FUNCTIONS
    const postSelectElective = async (repoId, body) => {
        setLoading(true);
        setError(null);
        try {
            const data = await postAssignmentPrograms(repoId, body);
            if (data.error) {
                setLoading(false);
                setError(true);
                openSnackbar('¡Error al matricular en el curso!');
                return;
            }

            setLoading(false);
            setError(false);
        } catch (error) {
            setLoading(false);
            setError(true);
            openSnackbar('¡Error al matricular en el curso!');
        }
    };

    return { postSelectElective, error, loading };
};

export default useElectives;
