import { useSnackbar } from "react-simple-snackbar";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import CommonButton from "../../../common/CommonButton";
import { Icon } from "@iconify/react/dist/iconify.js";
import Text from "../../../common/Text";

// Material UI
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";

// Formik
import { ErrorMessage, Field, Form, Formik } from "formik";

// Utils
import { registerNewSchema } from "../../../../utils/schemas";
import { optionsStyle } from "../../../../utils/snackStyles";

// Redux
import { authActions } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const RegisterForm = (props) => {
	// PROPS
	const { handleTab } = props;

	const initialValues = {
		full_name: "",
		email: "",
		password: "",
	};

	// STATE
	const [showPassword, setShowPassword] = useState(false);
	const [openSnackbar] = useSnackbar(optionsStyle);

	// REDUX
	const dispatch = useDispatch();
	const { loadingRegister, errorRegister, authToken } = useSelector(
		(state) => state.auth
	);

	// EFFECTS
	useEffect(() => {
		if (!!errorRegister) {
			const error = errorRegister.response.data.error.detail;
			if (error === "El mail ya existe en FusionAuth") {
				openSnackbar("Este correo electrónico ya esta en uso");
			} else {
				openSnackbar("¡Ocurrió un error! Volver a intentar");
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorRegister, dispatch]);

	useEffect(() => {
		if (!!authToken) {
			openSnackbar("Cuenta creada correctamente. ¡Revisar correo electrónico!");
			dispatch(authActions.clearErrorRegister());
			setTimeout(() => {
				handleTab(0);
			}, 5000);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken, dispatch, handleTab]);

	// FUNCTIONS
	const handleSubmit = (values) => {
		const body = sanitizarData(values);
		const bodyRequest = {
			form: body,
			extra_data: {},
		};
		dispatch(authActions.registerUserRequest(bodyRequest));
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const sanitizarData = (object) => {
		const names = object.full_name.split(" ");
		return {
			first_name: names[0],
			last_name: names[names.length - 1],
			email: object.email.toLowerCase(),
			password: object.password,
			language: lenguaje().toLowerCase(),
		};
	};

	const lenguaje = () => {
		const lenguaje = window.navigator.language;
		return lenguaje[0] + lenguaje[1];
	};

	return (
		<Wrapper>
			<Content>
				<Text fontWeight="800" fontSize="24px" color="#B31D15">
					¡Comienza tu camino <br /> de aprendizaje!
				</Text>
				<Text fontWeight="400" textColor="#222" fontSize="16px">
					Crea tu cuenta y empieza hoy a construir <br /> tu futuro.
				</Text>
			</Content>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={registerNewSchema}
			>
				{({ values }) => (
					<FormContainer>
						{/** FULL NAME */}
						<FormField>
							<FieldFake
								name="full_name"
								type="text"
								value={values.full_name}
								placeholder="Nombre completo"
								fullWidth
							/>
							{
								values.full_name !== ''
									? <FieldLabel>Nombre completo</FieldLabel>
									: <ErrorMessage name="full_name" render={msg => (
										<Error>
											<Icon
												icon="mage:exclamation-circle"
												width="16px"
												height="16px"
											/>
											<span>Es necesario que ingreses tu nombre completo</span>
										</Error>
									)} />
							}
						</FormField>
						{/** EMAIL */}
						<FormField>
							<FieldFake
								name="email"
								type="email"
								value={values.email}
								placeholder="Correo electrónico"
								fullWidth
							/>
							{
								values.email !== ''
									? <FieldLabel>Correo electrónico</FieldLabel>
									: <ErrorMessage name="email" render={msg => (
										<Error>
											<Icon
												icon="mage:exclamation-circle"
												width="16px"
												height="16px"
											/>
											<span>Es necesario que ingreses tu correo eletrónico</span>
										</Error>
									)} />
							}
						</FormField>
						{/** PASSWORD */}
						<PassWrapper>
							<FieldFake
								name="password"
								type={showPassword ? "text" : "password"}
								value={values.password}
								placeholder="Contraseña"
								fullWidth
							/>
							{
								values.password !== ""
									? <FieldLabel>Contraseña</FieldLabel>
									: <ErrorMessage name="password" render={msg => (
										<Error>
											<Icon
												icon="mage:exclamation-circle"
												width="16px"
												height="16px"
											/>
											<span>Es necesario que ingreses tu contraseña</span>
										</Error>
									)} />
							}
							<InputAdornmentFake position="end">
								<IconButton
									size="small"
									onClick={toggleShowPassword}
								>
									{showPassword ? (
										<Visibility fontSize="small" />
									) : (
										<VisibilityOff fontSize="small" />
									)}
								</IconButton>
							</InputAdornmentFake>
						</PassWrapper>
						<CommonButton
							variant="filled"
							type="submit"
							label={!loadingRegister ? "Crear cuenta" : "Creando cuenta"}
							disabled={loadingRegister}
						/>
					</FormContainer>
				)}
			</Formik>
			<CommonButton
				variant="text"
				label="Volver"
				onClick={() => handleTab(0)}
			/>
		</Wrapper>
	);
};

export default RegisterForm;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	width: calc(100% + 7rem);

	@media (width < 1540px) {
		gap: 1rem;
	}

	@media (max-width: 768px) {
		
		width: 100%;
	}
`;

const FormContainer = styled(Form)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
	width: 100%;
`;

const FormField = styled.div`
	width: 60%;
    position: relative;
    color: #B31D15;

	@media (width <= 768px) {
		width: 80%;
	}
`;

const PassWrapper = styled.div`
	width: 60%;
    position: relative;
    color: #B31D15;
	margin-bottom: 1rem;

	@media (width < 1540px) {
		margin-bottom: 0rem;
	}

	@media (width <= 768px) {
		width: 80%;
		margin-bottom: 1rem;
	}
`;

const FieldFake = styled(Field)`
    font-size: 14px;
    outline: none;
	border: 0px;
    border-bottom: 1px solid #B31D15;
    padding: 0.5rem 0 0.5rem 0;
    height: 20px;
    width: calc(100% - 5rem);

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        box-shadow: 0 0 0px 1000px #fff inset;
        -webkit-text-fill-color: #000;
    }

	@media (width <= 768px) {
		width: 100%;
	}
`;

const FieldLabel = styled.label`
    position: absolute;
    bottom: -1.2rem;
    left: 36px;
    padding: 0px 5px;
    font-weight: 600;
    font-size: 12px;
    @media (max-width: 768px) {
        left: 0px;
		padding: 0;
        font-size: 10px;
    }
`

const Error = styled.div`
	position: absolute;
    bottom: -1.2rem;
    left: 36px;
    padding: 0px 5px;
    font-weight: 600;
    font-size: 12px;
	display: flex;
	align-items: center;
	gap: 4px;
	color: #E94C44;

	span {
		white-space: nowrap;
	}

	@media (width < 768px) {
		bottom: -1.5rem;
		left: 0px;
	}
`

const InputAdornmentFake = styled(InputAdornment)`
    position: absolute;
    top: 50%;
    right: 2.2rem;
`;

const Content = styled.div`
	margin: 16px 0 0 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	@media (max-width: 768px) {
		margin: 10px;
		max-height: 100px;
	}
`;
