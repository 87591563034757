import { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import LoaderQuestions from "./questions/LoaderQuestions";
import { Icon } from '@iconify/react';

const Questions = (props) => {

    const { loadingQuestions, questions, showAnswers, type, questionsError } = props;

    const [selectedOptions, setSelectedOptions] = useState({});
    const [checkedOptions, setCheckedOptions] = useState([]);

    useEffect(() => {
        // Se usa esta validación para limpiar bien las elecciones del usuario
        if (!showAnswers || questions.length === 0) {
            setSelectedOptions({});
            setCheckedOptions([]);
        }
    }, [showAnswers, questions])

    const handleRadioChange = (questionId, option) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [questionId]: option,
        }));
    };

    const handleCheckboxChange = (option) => {
        setCheckedOptions((prev) =>
            prev.includes(option)
                ? prev.filter((item) => item !== option)
                : [...prev, option]
        );
    };

    return (
        <>
            {
                questionsError && <ErrorMessage>Ocurrió un error al cargar las preguntas, por favor, intente de nuevo</ErrorMessage>
            }
            {
                loadingQuestions && <LoaderQuestions type={type} />
            }
            {
                (!!questions && !loadingQuestions && Array.isArray(questions)) && questions?.map((question, index) => {
                    const questionOrder = parseInt(question.order);
                    return (
                        <QuestionContainer key={question.order}>
                            <h3>Pregunta {question.order}</h3>
                            <h5>{question.question}</h5>
                            {(question.type === 'single_choice' || question.type === 'true_false') && (
                                question.options.map((option, index) => (
                                    <Label key={option?.option} checked={selectedOptions[questionOrder] === option?.option && showAnswers}>
                                        {
                                            !showAnswers
                                                ? <RelativeDiv>
                                                    <HiddenRadio
                                                        type="radio"
                                                        value={option?.option}
                                                        checked={selectedOptions[questionOrder] === option?.option}
                                                        onChange={() => handleRadioChange(questionOrder, option?.option)}
                                                    />
                                                    <BorderDiv checked={selectedOptions[questionOrder] === option?.option} />
                                                    <AbsoluteDiv checked={selectedOptions[questionOrder] === option?.option} />
                                                </RelativeDiv>
                                                : <Chip isCorrect={option?.is_correct}>
                                                    {
                                                        option?.is_correct
                                                            ? <Icon icon="streamline:check-solid" width="15px" height="15px" />
                                                            : <Icon icon="streamline:delete-1-solid" width="15px" height="15px" />
                                                    }
                                                </Chip>
                                        }
                                        <OptionText>{option?.option}</OptionText>
                                        {
                                            showAnswers && selectedOptions[questionOrder] === option?.option && <LabelCorrectAnswer>Mi respuesta</LabelCorrectAnswer>
                                        }
                                    </Label>
                                ))
                            )}
                            {
                                question.type === 'multiple_choice' && question.options.map((option, index) => (
                                    <Label key={index} checked={checkedOptions.includes(option.option) && showAnswers}>
                                        {
                                            !showAnswers
                                                ? <RelativeDiv>
                                                    <HiddenCheckbox
                                                        type="checkbox"
                                                        value={option.option}
                                                        checked={checkedOptions.includes(option.option)}
                                                        onChange={() => handleCheckboxChange(option.option)}
                                                    />
                                                    <StyledCheckbox checked={checkedOptions.includes(option.option)}>
                                                        {checkedOptions.includes(option.option) && (
                                                            <CheckIcon icon="material-symbols:check" width="18px" height="18px" />
                                                        )}
                                                    </StyledCheckbox>
                                                </RelativeDiv>
                                                : <Chip isCorrect={option?.is_correct}>
                                                    {
                                                        option?.is_correct
                                                            ? <Icon icon="streamline:check-solid" width="15px" height="15px" />
                                                            : <Icon icon="streamline:delete-1-solid" width="15px" height="15px" />
                                                    }
                                                </Chip>
                                        }
                                        <OptionText>{option.option}</OptionText>
                                        {
                                            showAnswers && checkedOptions.includes(option.option) && <LabelCorrectAnswer>Mi respuesta</LabelCorrectAnswer>
                                        }
                                    </Label>
                                ))
                            }
                        </QuestionContainer>
                    )
                }
                )
            }
        </>
    )
}

export default Questions;

const ErrorMessage = styled.p`
    font-size: 16px;
    font-weight: 700;
    margin-left: 1rem;
`

const QuestionContainer = styled.div`
    width: calc(100% - 3rem);
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 1rem;
    margin-bottom: 1rem;

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #b31d15;
        margin-bottom: 1rem;
    }

    h5 {
        font-size: 14px;
        font-weight: 400;
        color: #222;
        margin-bottom: 1rem;
    }
`

const Label = styled.label`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    border: ${({ checked }) => checked && "1px solid #616161"};
    padding: 8px;
    border-radius: 100px;
    margin-bottom: 8px;
`;

const RelativeDiv = styled.div`
    position: relative;
    padding: 8px;
`;

const Chip = styled.span`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 8px;
    border-radius: 100px;
    background-color: ${({ isCorrect }) => isCorrect ? "#35D0A5" : "#E94C44"};
    
    svg {
        color: #FFF;
    }

    label {
        font-size: 12px;
        font-weight: 700;
    }
`

const HiddenRadio = styled.input`
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
`;

const BorderDiv = styled.div`
    width: 14px;
    height: 14px;
    border: 2px solid ${({ checked }) => checked ? "#B31D15" : "#616161"};
    border-radius: 50%;
`;

const AbsoluteDiv = styled.div`
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background-color: ${(props) => (props.checked ? '#B31D15' : '#ffffff')};
    transform: ${(props) => (props.checked ? 'scale(0.3)' : 'scale(0)')};
    transition: transform 0.2s ease-in-out;
`;

const OptionText = styled.p`
    font-size: 14px;
    margin-left: 0.5rem;
    color: #000;
    font-weight: 400;
`;

const LabelCorrectAnswer = styled.label`
    position: absolute;
    right: 8px;
    font-size: 12px;
    font-weight: 700;
    color: #222;
    border: 1px solid #616161;
    padding: 8px 16px;
    border-radius: 100px;
    background-color: #f9f9f9;
`;

const HiddenCheckbox = styled.input`
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
`;

const StyledCheckbox = styled.div`
    width: 14px;
    height: 14px;
    border: 2px solid ${({ checked }) => (checked ? "#B31D15" : "#616161")};
    border-radius: 3px;
    background-color: ${(props) => (props.checked ? '#B31D15' : '#ffffff')};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
`;

const CheckIcon = styled(Icon)`
    color: white;
`;