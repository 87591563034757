import { Icon } from '@iconify/react/dist/iconify.js';
import styled from 'styled-components';

const StateActivities = (props) => {

    const { reworkActivities, pendingCorrections } = props;

    return (
        <StateActivitiesWrapper>
            {
                reworkActivities &&
                <ReworkActivitiesChip>
                    <Icon
                        icon="mage:exclamation-circle"
                        width="24px"
                        height="24px"
                    />
                    Tienes actividades por rehacer
                </ReworkActivitiesChip>
            }
            {
                pendingCorrections &&
                <PendingCorrectionsChip>
                    <Icon
                        icon="uil:file-exclamation"
                        width="24px"
                        height="24px"
                    />
                    Tienes actividades sin corregir
                </PendingCorrectionsChip>
            }
        </StateActivitiesWrapper>
    )
}

export default StateActivities;

const StateActivitiesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
`

const ReworkActivitiesChip = styled.div`
    font-size: 13px;
    font-weight: 700;
    padding: 8px 16px 8px 8px;
    border-radius: 100px;
    background-color: #fff;
    color: #b31d15;
    border: 1px solid #b31d15;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 4px;
    z-index: 10;

    svg {
        color: #b31d15;
    }
`;

const PendingCorrectionsChip = styled.div`
    font-size: 13px;
    font-weight: 700;
    padding: 8px 16px 8px 8px;
    border-radius: 100px;
    background-color: #fff;
    color: #C29F43;
    border: 1px solid #C29F43;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 4px;
    z-index: 10;

    svg {
        color: #C29F43;
    }
`;