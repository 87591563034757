import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCalificationsService } from '../../../../redux/api/teacher';
import DefaultSelect from '../../../common/DefaultSelect';
import DefaultField from '../../../common/DefaultField';

const EducationalInformation = (props) => {

    // State
    const [califications, setCalifications] = useState(null)

    // Effects
    useEffect(() => {
        if (califications === null) {
            getCalifications()
        }
    }, [califications])

    // Functions
    const getCalifications = async () => {
        const response = await getCalificationsService()
        setCalifications(response)
    }

    return <DocumentationWrapper>
        <Grid>
            <DefaultField
                name="repo_title"
                type="text"
                label="Título universitario"
            />
            <DefaultField
                name="repo_max_title"
                type="text"
                label="Título máximo"
            />
        </Grid>
        <Grid>
            {califications && (
                <DefaultSelect
                    name="repo_calification"
                    label="Máxima calificación académica"
                    options={califications}
                />
            )}
            <DefaultField
                name="repo_university"
                type="text"
                label="Universidad"
            />
        </Grid>
        <Grid>
            <DefaultField
                name="repo_year"
                type="number"
                label="Años en la industria"
            />
            
        </Grid>
    </DocumentationWrapper>
};

export default EducationalInformation;

const DocumentationWrapper = styled.div`
    /* min-width: 350px; */
    padding: 1rem;
    padding-top: 1.5rem;
    display: grid;
    gap: 1.5rem;
    @media(max-width: 768px){
        width: calc(100% - 2rem);
        display: flex;
        flex-direction: column;
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
    }
`