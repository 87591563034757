import * as base64 from 'nodejs-base64-encode';

export function encrypt(x) {
    var s = [];
    for (var i = 0; i < x.length; i++) {
        var j = x.charCodeAt(i);
        if (j >= 33 && j <= 126) {
            s[i] = String.fromCharCode(33 + ((j + 14) % 94));
        } else {
            s[i] = String.fromCharCode(j);
        }
    }
    return s.join('');
}

export function base64Encode(value) {
    return base64.encode(value + '', 'base64');
}

export function base64Decode(value) {
    return base64.decode(value, 'base64');
}
