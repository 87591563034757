import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    getAnnouncementsByRepoId,
    patchAnnouncements,
} from '../../redux/api/announcements';
import AnnouncementSkeleton from '../../components/ui/organisms/subject/announcements/AnnouncementSkeleton';
import NotFoundImg from '../../assets/media/aden/not_found.png';
import Announcements from '../../components/ui/organisms/subject/announcements/Announcements';
import Pagination from '../../components/ui/organisms/subject/announcements/Pagination';
import useLayout from '../../hooks/useLayout';
import PopUpSuccess from '../../components/ui/organisms/subject/announcements/PopUpSuccess';
import ModalCreateAnnouncement from '../../components/ui/organisms/subject/announcements/ModalCreateAnnouncement';
import { announcementSchema } from '../../utils/schemas';

const GeneralAnnouncements = (props) => {
    const { professor } = props;

    const [announcementsData, setAnnouncementsData] = useState(false);
    const [initialValues, setInitialValues] = useState(false);
    const [page, setPage] = useState(1);
    const pageMax = Math.ceil(announcementsData?.length / 4);

    const [succesAction, setSuccesAction] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    //REDUX
    const { repo_id } = useSelector((state) => state.auth.user);
    const { activeRol } = useSelector((state) => state.auth);

    const { setMenu } = useLayout();

    useEffect(() => {
        if (!announcementsData) {
            getAnnouncements();
        }
        if (!professor) {
            setMenu('Anuncios');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcementsData]);

    const getAnnouncements = async () => {
        const response = await getAnnouncementsByRepoId(repo_id);
        if (!response.error) {
            setAnnouncementsData(response.data);
            setInitialValues(response.data);
        } else {
            console.error(response.error);
        }
    };

    const validateDates = (published_date, expiration_date) => {
        const today = new Date();
        const publishedDate = new Date(published_date + ' 23:59:00');
        publishedDate.setDate(publishedDate.getDate());
        const expirationDate = new Date(expiration_date + ' 23:59:00');
        expirationDate.setDate(expirationDate.getDate());

        if (today > publishedDate) {
            return {
                isValid: false,
                error: 'La fecha de publicación debe ser igual o posterior a la fecha actual',
            };
        }

        if (publishedDate > expirationDate) {
            return {
                isValid: false,
                error: 'La fecha de caducidad debe ser posterior a la fecha de publicación',
            };
        }

        return { isValid: true };
    };

    const handleSubmitAnnouncementEdited = async (values) => {
        const { isValid, error } = validateDates(
            values.published_date,
            values.expiration_date
        );
        const newsletter_id = values.id;
        const data = {
            active: true,
            title: values.title,
            message: values.message,
            expiration_date: values.expiration_date,
            published_date: values.published_date,
        };
        if (!isValid) {
            setError(error);
            return;
        }
        try {
            await patchAnnouncements(newsletter_id, data);
            setOpenEditModal(false);
            setSuccesAction('editó');
            setOpenModalSuccess(true);
        } catch (error) {
            setError('Error, por favor intente de nuevo');
        }
    };

    //unicamente se despublica, luego de ello el EP solo envia anuncios publicados por el profesor para su edición.
    const handleAnnouncementUnpublishing = async () => {
        const newsletter_id = Number(openEditModal.id);
        const data = {
            active: false,
        };
        setLoading(true);
        try {
            await patchAnnouncements(newsletter_id, data);
            setLoading(false);
            setOpenEditModal(false);
            setSuccesAction('despublicó');
            setOpenModalSuccess(true);
        } catch (error) {
            setLoading(false);
            setError('Error, por favor intente de nuevo');
        }
    };

    if (!initialValues) {
        return (
            <Wrapper>
                <AnnouncementSkeleton />
            </Wrapper>
        );
    }

    if (initialValues.length === 0 || initialValues === undefined) {
        return (
            <WrapperNotFound>
                <img src={NotFoundImg} alt="not-found" />
                <p>
                    Aún no tienes anuncios en <br /> tu bandeja de entrada
                </p>
            </WrapperNotFound>
        );
    }

    return (
        <Wrapper>
            <>
                <h1>Anuncios generales por asignaturas:</h1>
                <Announcements
                    initialValues={initialValues}
                    announcementsData={
                        Array.isArray(announcementsData)
                            ? announcementsData.slice(page * 4 - 4, page * 4)
                            : []
                    }
                    setAnnouncementsData={setAnnouncementsData}
                    setOpenEditModal={setOpenEditModal}
                    professor={professor}
                    general
                    rol={activeRol}
                />
                <Pagination page={page} setPage={setPage} pageMax={pageMax} />
            </>

            {openEditModal && (
                <ModalCreateAnnouncement
                    initialValues={openEditModal}
                    handleSubmitAnnouncementEdited={
                        handleSubmitAnnouncementEdited
                    }
                    handleAnnouncementUnpublishing={
                        handleAnnouncementUnpublishing
                    }
                    announcementSchema={announcementSchema}
                    setOpenModal={setOpenEditModal}
                    loading={loading}
                    error={error}
                    type="edit"
                />
            )}
            {openModalSuccess && !!succesAction && (
                <PopUpSuccess
                    setOpenModal={setOpenModalSuccess}
                    actionSucces={succesAction}
                    setAnnouncementsData={setAnnouncementsData}
                />
            )}
        </Wrapper>
    );
};

export default GeneralAnnouncements;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;

    h1 {
        color: #616161;
        font-size: 20px;
        font-weight: 500;
    }
`;

const WrapperNotFound = styled.div`
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 80vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
        text-align: center;
    }
`;
