import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Text } from "../../components/common/Texts";
import ContentAcademicAdvisorData from "../../components/templates/advisor/ContentAcademicAdvisorData";
import FormContact from "../../components/templates/advisor/FormContact";
import { titleHTML } from "../../helpers/title.helper";
import { advisorActions } from "../../redux/actions";
import InputIcon from '@mui/icons-material/Input';
import PersonIcon from '@mui/icons-material/Person';
import ErrorResponse from "../../components/common/ErrorResponse";
import SimpleLoading from "../../components/common/SimpleLoading";

const AcademicAdvisor = () => {
    titleHTML("Mi Academic Advisor");

    // REDUX
    const dispatch = useDispatch();
    let { advisor, errorList } = useSelector((state) => state.advisor);
    const [currentAdvisor, setCurrentAdvisor] = useState(null)

    // EFFECTS
    useEffect(() => {
        if (advisor === null) {
            dispatch(advisorActions.getAdvisorRequest());
        }
    }, []);

    const handleAdviser = (advisor, program) => {
        setCurrentAdvisor({...advisor, program })
    }

    if (!!errorList) {
        return <AcademicAdvisorContainer>
            <AcademicAdvisorMain>
                <ErrorResponse message="¡Ocurrió un error al buscar tus inscripciones!" />
            </AcademicAdvisorMain>
        </AcademicAdvisorContainer>
    }

    if (!advisor) {
        return <AcademicAdvisorContainer>
            <AcademicAdvisorMain>
                <SimpleLoading />
            </AcademicAdvisorMain>
        </AcademicAdvisorContainer>
    }

    return (
        <AcademicAdvisorContainer>
            <Grid>
                <AcademicAdvisorMain>
                    <Header>
                        <Title>Información</Title>
                        <Description>
                            Tu Academic Advisor te ayudará en tu cursado para no perder el
                            ritmo, canalizar tus dudas, y ayudarte en el desarrollo de tu
                            perfil profesional. Cuenta con ella como tu aliada para tu
                            crecimiento en esta aventura de aprendizaje personal.
                        </Description>
                    </Header>
                    <Header>
                        <Title>Mis inscripciones</Title>
                        <Description>
                            Selecciona tu programa para contactar con tu Academic Advisor
                        </Description>
                    </Header>
                    <GridAdvisor>
                        {advisor.map(ad => (<ItemCard active={ad.program === currentAdvisor?.program ? true : false}>
                            <InfoData>
                                <Text color="#999" fontWeight="bold">{ad.state}</Text>
                                <Text fontWeight="bold">{ad.program}</Text>
                                <Text color="#999" fontSize="12px" fontWeight="bold">PROGRAMA</Text>
                            </InfoData>
                            <IconWrapper>
                                <IconButton
                                    disabled={!ad.academic_advisor}
                                    onClick={() => handleAdviser(ad.academic_advisor, ad.program)}
                                    size="large">
                                    <InputIcon />
                                </IconButton>
                            </IconWrapper>
                        </ItemCard>))}
                    </GridAdvisor>
                </AcademicAdvisorMain>
                <AcademicAdvisorMain>
                    {!!currentAdvisor ? <>
                        <ContentAcademicAdvisorData advisor={currentAdvisor} />
                        <FormContact advisor={currentAdvisor} />
                    </> : (<Center>
                        <ErrorResponse message="¡Elige una inscripción para continuar!" />
                    </Center>)}
                </AcademicAdvisorMain>
            </Grid>
        </AcademicAdvisorContainer>
    );
};

export default AcademicAdvisor;

const AcademicAdvisorContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const Title = styled.h1`
    font-size: 20px;
    font-weight: 700;
    color: #b31d15;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 16px;
`;

const AcademicAdvisorMain = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
`;

const ItemCard = styled.div`
    background-color: #f7f7f7;
    height: auto;
    width: calc(100% - 32px);
    border-radius: 20px;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 50px;
    transition: ease-in-out .3s all;
    ${p => p.active && 'background: linear-gradient(270deg, #ffe2e1 0%, #fff8f7 100%)'};
    ${p => p.active && 'box-shadow: 0px 0px 20px rgba(0,0,0,0.06)'};
    :hover{
        background: linear-gradient(270deg, #ffe2e1 0%, #fff8f7 100%);
        transition: ease-in-out .3s all;
    }
`

const Description = styled.div`
    padding: 1rem;
    background-color: #f7f7f7;
    border-radius: 20px;
    color: #858585;
    font-weight: 600;
    margin-bottom: 1.2rem;
`;

const GridAdvisor = styled.div`
    display: grid;
    margin-top: 16px;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const InfoData = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const IconWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Center = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`