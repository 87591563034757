import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TabsComponent from "../../../common/TabsComponent";
import DashboardReferred from "./DashboardReferred";

const PanelReferred = (props) => {
    const { myReferred } = props;

    const [tab, setTab] = useState(0);

    const tabs = [
        { label: `Mis referidos`, id: 0 },
        // { label: `Beneficios disponibles`, id: 1 },
    ];

    const handleTab = (e, value) => {
        setTab(value);
    };

    function ToggleMain(props) {
        const { tab } = props;
        const cases = {
            0: <DashboardReferred myReferred={myReferred}/>,
        };
        return cases[String(tab)] || <DashboardReferred />;
    }

    return (
        <CardFake>
            <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            <Body>
                <ToggleMain tab={tab} />
            </Body>
        </CardFake>
    );
};

export default PanelReferred;

const CardFake = styled(Card)`
    grid-area: panel;
    border-radius: 20px;
    width: 100%;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    background-color: #fff;
`;

const Body = styled.div`
    width: calc(100% - 4rem);
    padding: 2rem;
`;
