import React from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Button, Chip, Tooltip } from '@mui/material';
import { Text } from '../../../common/Texts';

const MultiElectivesSelection = (props) => {
    const {
        electives,
        multiSelectedItems,
        optionCheck,
        handleMultiSelectionChange,
        electivesFormated,
    } = props;

    const handleRedirect = () => {
        window.open('https://app.aden.org/eventos', '_blank');
    };

    return (
        <CustomFormGroup>
            {electives.map((elective) => (
                <CustomFormControlLabel key={elective.id}>
                    <ContainerInfo>
                        <span>
                            <label>{elective.publicationName}</label>
                            <p>{elective?.description}</p>
                        </span>
                        <CheckColumn>
                            <ChipMode
                                label={
                                    elective?.courseInPerson === true
                                        ? 'Presencial'
                                        : 'Virtual'
                                }
                                textColor={
                                    elective?.courseInPerson === true
                                        ? '#B31D15'
                                        : '#1E8065'
                                }
                                bgcolor={
                                    elective?.courseInPerson === true
                                        ? '#FFF5F5'
                                        : '#F7FDFB'
                                }
                            />
                            {!!elective?.courseInPerson ? (
                                <Tooltip
                                    title={
                                        <>
                                            <EventButton
                                                onClick={handleRedirect}
                                            >
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="600"
                                                    textTransform="none"
                                                >
                                                    Antes de confirmar tu
                                                    inscripción a esta electiva
                                                    presencial , te sugerimos
                                                    que verifiques la
                                                    disponibilidad de la clase
                                                    mediante el siguiente link:
                                                </Text>
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="900"
                                                    color={'#b31d15'}
                                                    textTransform="none"
                                                >
                                                    Ver apartado Eventos
                                                </Text>
                                            </EventButton>
                                        </>
                                    }
                                    arrow
                                    placement="top"
                                >
                                    <Checkbox
                                        type="checkbox"
                                        disabled={
                                            electivesFormated.length > 0
                                                ? electivesFormated.some(
                                                      (id) =>
                                                          id.id === elective.id
                                                  )
                                                : optionCheck === elective.id
                                        }
                                        checked={multiSelectedItems.some(
                                            (selectedItem) =>
                                                selectedItem.id === elective.id
                                        )}
                                        onChange={() =>
                                            handleMultiSelectionChange(
                                                elective.id,
                                                elective.publicationName,
                                                elective?.courseInPerson
                                            )
                                        }
                                    />
                                </Tooltip>
                            ) : (
                                <Checkbox
                                    type="checkbox"
                                    disabled={
                                        electivesFormated.length > 0
                                            ? electivesFormated.some(
                                                  (id) => id.id === elective.id
                                              )
                                            : optionCheck === elective.id
                                    }
                                    checked={multiSelectedItems.some(
                                        (selectedItem) =>
                                            selectedItem.id === elective.id
                                    )}
                                    onChange={() =>
                                        handleMultiSelectionChange(
                                            elective.id,
                                            elective.publicationName,
                                            elective?.courseInPerson
                                        )
                                    }
                                />
                            )}
                        </CheckColumn>
                    </ContainerInfo>
                    {elective?.courseInPerson && (
                        <InfoContainer>
                            <Icon
                                icon="octicon:info-24"
                                width="24px"
                                height="24px"
                            />
                            <label>
                                Esta opción sólo está disponible para aquellos
                                que{' '}
                                <b>asistieron a la clase presencial.</b>
                            </label>
                        </InfoContainer>
                    )}
                </CustomFormControlLabel>
            ))}
        </CustomFormGroup>
    );
};

export default MultiElectivesSelection;

const CustomFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`;

const CustomFormControlLabel = styled.div`
    border: solid 1px #dcdcdc;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 10px;

    input {
        margin-right: 0.5rem;
    }

    span {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    p {
        font-size: 0.9rem;
        font-weight: 500;
        text-align: start;
        padding-right: 8px;
    }

    label {
        font-size: 0.9rem;
        text-align: start;
        font-weight: 700;
    }
`;

const ContainerInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 2rem);
    gap: 0.5rem;

    @media (width < 1068px) {
        span {
            p {
                margin-top: 1rem;
            }
        }
    }
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 0;

    svg {
        color: #c29f43;
    }
`;

const CheckColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
`;
const ChipMode = styled(Chip)`
    width: 100px;
    height: 30px;
    padding: 1rem 0;
    gap: 10px;
    border-radius: 100px;
    background-color: ${(p) => p.bgcolor};
    border: ${(p) => `1px solid ${p.textColor}`};

    .MuiChip-label {
        color: ${(p) => p.textColor};
        font-size: 0.8rem;
    }
    /* span { */
    /* } */
`;

const EventButton = styled(Button)`
    width: 100%;
    padding: 1rem;
    background-color: #fafafa;
    color: black;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;

    :hover {
        background-color: #fafafa;
    }
`;
