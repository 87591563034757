import { useEffect, useState } from 'react';
import { getSubmissionById } from '../../redux/api/teacher';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../../utils/snackStyles';

const useSubmission = (idSub, idAs) => {
    const [submission, setSubmission] = useState(null);

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!submission) getSubmission();
    }, [submission]);

    const getSubmission = async () => {
        const request = await getSubmissionById(idSub, idAs);
        if (request.error) {
            openSnackbar('¡No se pudo cargar la tarea!');
            setSubmission(false);
            return;
        }
        setSubmission(request);
        // setInitialCourses(request);
    };

    return { submission };
};

export default useSubmission;
