import React from "react";
import styled from "styled-components";
import SimpleLoading from "../../../common/SimpleLoading";

const ProgramsOneStudent = (props) => {
    const { programStudent } = props;

    // RETURN
    if (!programStudent) {
        return <SimpleLoading />;
    }

    return (
        <Wrapper>
            {programStudent.map((program) => (
                <Card count={programStudent.length}>
                    <Name>
                        {program?.programa?.nombre_programa ||
                            "Nombre del programa no definido"}
                    </Name>
                    <TypeProgram>
                        {program?.programa?.tipo_programa || "No definido"}
                    </TypeProgram>
                    <Modality>
                        {program?.programa?.modalidad_programa || "No definida"}
                    </Modality>
                </Card>
            ))}
        </Wrapper>
    );
};

export default ProgramsOneStudent;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1rem;
`;

const Card = styled.div`
    padding: 1rem;
    width: ${(p) => (p.count > 2 ? "calc(100% - 2rem)" : "calc(320px - 2rem)")};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
    background: linear-gradient(269.94deg, #fef6f6 0.05%, #ffffff 99.95%),
        #ffffff;
    position: relative;
`;

const Name = styled.h2`
    font-size: 1rem;
    color: #222222;
    height: 2.5rem;
`;

const TypeProgram = styled.div`
    height: 2.5rem;
`;

const Modality = styled.div`
    position: absolute;
    background-color: #fff;
    color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 16px;
    right: 16px;
    padding: 5px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: .6rem;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
`;
