import { useEffect } from 'react'
import { useSelector } from 'react-redux';

const useHotjar = () => {

    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        window.hj("identify", user?.sis_id || 0, {
            "Signed up": new Date().toISOString(), // Signup date in ISO-8601 format.
            "Email": user?.personal_email || '', // User's email address.
            "Name": user?.name || '', // User's full name.
            "Is a student": user?.is_student || '', // Boolean value for whether the user is a student.
            "Ondemand Id": user?.ondemand_id || '',
            "Ondemand Participant Id": user?.od_participant_id.id || ''
        });
        // window
    }, [user])

    return user
}

export default useHotjar