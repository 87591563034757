import React from 'react';
import styled from 'styled-components';

import Text from '../../common/Text';
import MoreInformation from '../../ui/organisms/dracma/MoreInformation';
import HowToGetMore from '../../ui/organisms/dracma/HowToGetMore';

const UserDracma = (props) => {
    const { dracmas, withBalance, dracma_coin_name } = props;
    // RETURN
    return (
        <>
            <DracmaWrapper>
                {/** Cantidad de monedas */}
                <CoinWrapper>
                    <CustomImage
                        src="\assets\aden\dracma-point.svg"
                        alt="dracma-points"
                        width="36px"
                        loading="lazy"
                    />
                    <Text fontSize="2rem" fontWeight="700" component="span">
                        {dracmas.balance}{' '}
                        <Text
                            fontSize="1rem"
                            fontWeight="700"
                            color="secondary"
                            component="span"
                        >
                            {dracma_coin_name !== '' &&
                            dracma_coin_name !== undefined
                                ? `${dracma_coin_name.charAt(0)}P`
                                : 'DP'}
                        </Text>
                    </Text>
                </CoinWrapper>
                {/** Mas informacion */}
            </DracmaWrapper>
            {withBalance && (
                <BalanceWrapper>
                    {/** Total Obtenido */}
                    <BalanceCard>
                        <BackgroundWrapper background="#616161" color="#ffffff">
                            HISTÓRICO
                        </BackgroundWrapper>
                        <BackgroundWrapper>
                            <CustomImage
                                src="\assets\aden\dracma-point.svg"
                                alt="dracma-point"
                                width="18px"
                                loading="lazy"
                            />
                            <Text
                                fontSize="0.75rem"
                                fontWeight="700"
                                component="span"
                            >
                                {dracmas.total_earned}
                            </Text>
                        </BackgroundWrapper>
                    </BalanceCard>
                    {/** Gastado */}
                    <BalanceCard>
                        <BackgroundWrapper background="#616161" color="#ffffff">
                            GASTADO
                        </BackgroundWrapper>
                        <BackgroundWrapper>
                            <CustomImage
                                src="\assets\aden\dracma-point.svg"
                                alt="dracma-coin"
                                width="18px"
                                loading="lazy"
                            />
                            <Text
                                fontSize="0.75rem"
                                fontWeight="700"
                                component="span"
                            >
                                {dracmas.total_spent}
                            </Text>
                        </BackgroundWrapper>
                    </BalanceCard>
                </BalanceWrapper>
            )}
            {/** Obtener mas dracma */}
            <HowToGetMore />
        </>
    );
};

export default UserDracma;

const DracmaWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CoinWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const CustomImage = styled.img`
    width: ${(p) => p.width};
    height: auto;
`;

const BalanceWrapper = styled.div`
    display: flex;
    column-gap: 1rem;
`;

const BalanceCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #616161;
    width: 100%;
`;

const BackgroundWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.25rem 1rem;
    background-color: ${(p) => p.background};
    color: ${(p) => p.color};
    font-size: 0.75rem;
`;
