// Likes
export const SET_LIKE_REQUEST = "SET_LIKE_REQUEST";
export const SET_LIKE_SUCCESS = "SET_LIKE_SUCCESS";
export const SET_LIKE_FAIL = "SET_LIKE_FAIL";

export const GET_LIKES_REQUEST = "GET_LIKES_REQUEST";
export const GET_LIKES_SUCCESS = "GET_LIKES_SUCCESS";
export const GET_LIKES_FAIL = "GET_LIKES_FAIL";

export const GET_CALIFICACION_REQUEST = "GET_CALIFICACION_REQUEST";
export const GET_CALIFICACION_SUCCESS = "GET_CALIFICACION_SUCCESS";
export const GET_CALIFICACION_FAIL = "GET_CALIFICACION_FAIL";

export const SET_LIKE = "SET_LIKE";
export const SET_DISLIKE = "SET_DISLIKE";

// Countries
export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

// Nationalities
export const GET_NATIONALITIES_REQUEST = "GET_NATIONALITIES_REQUEST";
export const GET_NATIONALITIES_SUCCESS = "GET_NATIONALITIES_SUCCESS";
export const GET_NATIONALITIES_FAIL = "GET_NATIONALITIES_FAIL";

// Inconvenientes
export const TIPO_INCONVENIENTE_REQUEST = "TIPO_INCONVENIENTE_REQUEST";
export const TIPO_INCONVENIENTE_SUCCESS = "TIPO_INCONVENIENTE_SUCCESS";
export const TIPO_INCONVENIENTE_FAIL = "TIPO_INCONVENIENTE_FAIL";

export const REPORT_INCONVENIENTE_REQUEST = "REPORT_INCONVENIENTE_REQUEST";
export const REPORT_INCONVENIENTE_SUCCESS = "REPORT_INCONVENIENTE_SUCCESS";
export const REPORT_INCONVENIENTE_FAIL = "REPORT_INCONVENIENTE_FAIL";

// Courses
export const FETCH_STATE = "FETCH_STATE";

export const GET_COURSES_REQUEST = "GET_COURSES_REQUEST";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAIL = "GET_COURSES_FAIL";

export const GET_MORECOURSES_REQUEST = "GET_MORECOURSES_REQUEST";
export const GET_MORECOURSES_SUCCESS = "GET_MORECOURSES_SUCCESS";
export const GET_MORECOURSES_FAIL = "GET_MORECOURSES_FAIL";

export const GET_STUDENTS_REQUEST = "GET_STUDENTS_REQUEST";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL";

export const GET_TEACHERS_REQUEST = "GET_TEACHERS_REQUEST";
export const GET_TEACHERS_SUCCESS = "GET_TEACHERS_SUCCESS";
export const GET_TEACHERS_FAIL = "GET_TEACHERS_FAIL";

export const ENROLL_COURSE_REQUEST = "ENROLL_COURSE_REQUEST";
export const ENROLL_COURSE_SUCCESS = "ENROLL_COURSE_SUCCESS";
export const ENROLL_COURSE_FAIL = "ENROLL_COURSE_FAIL";

// Workshops
export const GET_WORKSHOPS_REQUEST = "GET_WORKSHOPS_REQUEST";
export const GET_WORKSHOPS_SUCCESS = "GET_WORKSHOPS_SUCCESS";
export const GET_WORKSHOPS_FAIL = "GET_WORKSHOPS_FAIL";

export const GET_WORKSHOPBYID_REQUEST = "GET_WORKSHOPBYID_REQUEST";
export const GET_WORKSHOPBYID_SUCCESS = "GET_WORKSHOPBYID_SUCCESS";
export const GET_WORKSHOPBYID_FAIL = "GET_WORKSHOPBYID_FAIL";

export const SET_LIKE_WS = "SET_LIKE_WS";
export const SET_NOLIKE_WS = "SET_NOLIKE_WS";

// Microlearnings
export const GET_MICROLEARNINGS_REQUEST = "GET_MICROLEARNINGS_REQUEST";
export const GET_MICROLEARNINGS_SUCCESS = "GET_MICROLEARNINGS_SUCCESS";
export const GET_MICROLEARNINGS_FAIL = "GET_MICROLEARNINGS_FAIL";

export const SET_CURRENT_MICROLEARNING = "SET_CURRENT_MICROLEARNING";
export const LOAD_FIRST_MICROLEARNING = "LOAD_FIRST_MICROLEARNING";

export const SET_LIKE_MICROLEARNING = "SET_LIKE_MICROLEARNING";
export const SET_NOLIKE_MICROLEARNING = "SET_NOLIKE_MICROLEARNING";

// Directories
export const GET_PAGINATION_REQUEST = "GET_PAGINATION_REQUEST";
export const GET_PAGINATION_SUCCESS = "GET_PAGINATION_SUCCESS";
export const GET_PAGINATION_FAIL = "GET_PAGINATION_FAIL";

export const GET_PAGINATIONALAB_REQUEST = "GET_PAGINATIONALAB_REQUEST";
export const GET_PAGINATIONALAB_SUCCESS = "GET_PAGINATIONALAB_SUCCESS";
export const GET_PAGINATIONALAB_FAIL = "GET_PAGINATIONALAB_FAIL";

export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";
export const CHANGE_PAGE = "CHANGE_PAGE";

export const GET_DIRECTORIES_REQUEST = "GET_DIRECTORIES_REQUEST";
export const GET_DIRECTORIES_SUCCESS = "GET_DIRECTORIES_SUCCESS";
export const GET_DIRECTORIES_FAIL = "GET_DIRECTORIES_FAIL";

export const GET_DIRECTORIESALAB_REQUEST = "GET_DIRECTORIESALAB_REQUEST";
export const GET_DIRECTORIESALAB_SUCCESS = "GET_DIRECTORIESALAB_SUCCESS";
export const GET_DIRECTORIESALAB_FAIL = "GET_DIRECTORIESALAB_FAIL";

// Motivations
export const GET_MOTIVATIONS_REQUEST = "GET_MOTIVATIONS_REQUEST";
export const GET_MOTIVATIONS_SUCCESS = "GET_MOTIVATIONS_SUCCESS";
export const GET_MOTIVATIONS_FAIL = "GET_MOTIVATIONS_FAIL";

// Interests
export const GET_INTERESTS_REQUEST = "GET_INTERESTS_REQUEST";
export const GET_INTERESTS_SUCCESS = "GET_INTERESTS_SUCCESS";
export const GET_INTERESTS_FAIL = "GET_INTERESTS_FAIL";

// Auth
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const SET_CHALLENGE_DRACMA = "SET_CHALLENGE_DRACMA";

export const CLEAR_ERROR_LOGIN = "CLEAR_ERROR_LOGIN";
export const LOGIN_CANVAS = "LOGIN_CANVAS";

// VISITA
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
// ESTUDIANTE
export const REGISTER_STUDENT_REQUEST = "REGISTER_STUDENT_REQUEST";
export const REGISTER_STUDENT_SUCCESS = "REGISTER_STUDENT_SUCCESS";
export const REGISTER_STUDENT_FAIL = "REGISTER_STUDENT_FAIL";

export const CLEAR_ERROR_REGISTER = "CLEAR_ERROR_REGISTER";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const LOGOUT_USER = "LOGOUT_USER";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export const UPDATE_PHOTO_REQUEST = "UPDATE_PHOTO_REQUEST";
export const UPDATE_PHOTO_SUCCESS = "UPDATE_PHOTO_SUCCESS";
export const UPDATE_PHOTO_FAIL = "UPDATE_PHOTO_FAIL";

export const SET_UPDATE_USER = "SET_UPDATE_USER";

export const INSTRUCTURE_FIRST_CLICK_REQUEST =
  "INSTRUCTURE_FIRST_CLICK_REQUEST";
export const INSTRUCTURE_FIRST_CLICK_SUCCESS =
  "INSTRUCTURE_FIRST_CLICK_SUCCESS";
export const INSTRUCTURE_FIRST_CLICK_FAIL = "INSTRUCTURE_FIRST_CLICK_FAIL";

export const REGISTER_USER_ONDEMAND_REQUEST = "REGISTER_USER_ONDEMAND_REQUEST";
export const REGISTER_USER_ONDEMAND_SUCCESS = "REGISTER_USER_ONDEMAND_SUCCESS";
export const REGISTER_USER_ONDEMAND_FAIL = "REGISTER_USER_ONDEMAND_FAIL";
export const SET_ONDEMAND = "SET_ONDEMAND";

export const CLEAR_ERROR_ONDEMAND_REGISTER = "CLEAR_ERROR_ONDEMAND_REGISTER";

// Achievements
export const GET_ACHIEVEMENTS_REQUEST = "GET_ACHIEVEMENTS_REQUEST";
export const GET_ACHIEVEMENTS_SUCCESS = "GET_ACHIEVEMENTS_SUCCESS";
export const GET_ACHIEVEMENTS_FAIL = "GET_ACHIEVEMENTS_FAIL";

// Programs
export const GET_PROGRAMS_REQUEST = "GET_PROGRAMS_REQUEST";
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS";
export const GET_PROGRAMS_FAIL = "GET_PROGRAMS_FAIL";

export const GET_PROGRAMS_OPTIONS_REQUEST = "GET_PROGRAMS_OPTIONS_REQUEST";
export const GET_PROGRAMS_OPTIONS_SUCCESS = "GET_PROGRAMS_OPTIONS_SUCCESS";
export const GET_PROGRAMS_OPTIONS_FAIL = "GET_PROGRAMS_OPTIONS_FAIL";

// Documents
export const GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAIL = "GET_DOCUMENTS_FAIL";

export const POST_DOCUMENT_REQUEST = "POST_DOCUMENT_REQUEST";
export const POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
export const POST_DOCUMENT_FAIL = "POST_DOCUMENT_FAIL";

export const PUT_DOCUMENT_REQUEST = "PUT_DOCUMENT_REQUEST";
export const PUT_DOCUMENT_SUCCESS = "PUT_DOCUMENT_SUCCESS";
export const PUT_DOCUMENT_FAIL = "PUT_DOCUMENT_FAIL";

// Finances
export const GET_FINANCES_REQUEST = "GET_FINANCES_REQUEST";
export const GET_FINANCES_SUCCESS = "GET_FINANCES_SUCCESS";
export const GET_FINANCES_FAIL = "GET_FINANCES_FAIL";

// Document types
export const GET_DOCTYPES_REQUEST = "GET_DOCTYPES_REQUEST";
export const GET_DOCTYPES_SUCCESS = "GET_DOCTYPES_SUCCESS";
export const GET_DOCTYPES_FAIL = "GET_DOCTYPES_FAIL";

// Professions
export const GET_PROFESSIONS_REQUEST = "GET_PROFESSIONS_REQUEST";
export const GET_PROFESSIONS_SUCCESS = "GET_PROFESSIONS_SUCCESS";
export const GET_PROFESSIONS_FAIL = "GET_PROFESSIONS_FAIL";
export const SET_PARTNER_NULL = "SET_PARTNER_NULL";

// Areas
export const GET_AREAS_OPTIONS_REQUEST = "GET_AREAS_OPTIONS_REQUEST";
export const GET_AREAS_OPTIONS_SUCCESS = "GET_AREAS_OPTIONS_SUCCESS";
export const GET_AREAS_OPTIONS_FAIL = "GET_AREAS_OPTIONS_FAIL";

// Job
export const GET_JOBS_OPTIONS_REQUEST = "GET_JOBS_OPTIONS_REQUEST";
export const GET_JOBS_OPTIONS_SUCCESS = "GET_JOBS_OPTIONS_SUCCESS";
export const GET_JOBS_OPTIONS_FAIL = "GET_JOBS_OPTIONS_FAIL";

// Chat partner
export const GET_PARTNER_CHAT_REQUEST = "GET_PARTNER_CHAT_REQUEST";
export const GET_PARTNER_CHAT_SUCCESS = "GET_PARTNER_CHAT_SUCCESS";
export const GET_PARTNER_CHAT_FAIL = "GET_PARTNER_CHAT_FAIL";

// Academic Advisor
export const GET_ACADEMIC_ADVISOR_REQUEST = "GET_ACADEMIC_ADVISOR_REQUEST";
export const GET_ACADEMIC_ADVISOR_SUCCESS = "GET_ACADEMIC_ADVISOR_SUCCESS";
export const GET_ACADEMIC_ADVISOR_FAIL = "GET_ACADEMIC_ADVISOR_FAIL";

// Events
export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

// Ondemand
export const GET_ONDEMAND_REQUEST = "GET_ONDEMAND_REQUEST";
export const GET_ONDEMAND_SUCCESS = "GET_ONDEMAND_SUCCESS";
export const GET_ONDEMAND_FAIL = "GET_ONDEMAND_FAIL";

export const GET_ONDEMANDCOMPETITOR_REQUEST = "GET_ONDEMANDCOMPETITOR_REQUEST";
export const GET_ONDEMANDCOMPETITOR_SUCCESS = "GET_ONDEMANDCOMPETITOR_SUCCESS";
export const GET_ONDEMANDCOMPETITOR_FAIL = "GET_ONDEMANDCOMPETITOR_FAIL";

export const GET_COURSES_ADDITIONAL_REQUEST = "GET_COURSES_ADDITIONAL_REQUEST";
export const GET_COURSES_ADDITIONAL_SUCCESS = "GET_COURSES_ADDITIONAL_SUCCESS";
export const GET_COURSES_ADDITIONAL_FAIL = "GET_COURSES_ADDITIONAL_FAIL";

// REFRESH TOKEN
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";

// TITULACIÓN
export const SET_TITULACION_STATE = "SET_TITULACION_STATE";

// CARRERAS
export const GET_CAREERS_REQUEST = "GET_CAREERS_REQUEST";
export const GET_CAREERS_SUCCESS = "GET_CAREERS_SUCCESS";
export const GET_CAREERS_FAIL = "GET_CAREERS_FAIL";

//