import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MiniIconCards from "../../components/common/teacher-academic/MiniIconCards";
import StudentTabs from "../../components/templates/academic/oneStudent/StudentTabs";
import MediumIconCards from "../../components/common/teacher-academic/MediumIconCards";
import StudentCardForAcademics from "../../components/common/teacher-academic/StudentCardForAcademics";
import { titleHTML } from "../../helpers/title.helper";
import DefaultNavegation from "../../components/shared/navegation/DefaultNavegation";
import { getConditionByStudent } from "../../redux/api/academic/getConditionByStudent";
import SimpleLoading from "../../components/common/SimpleLoading";
import useOneStudent from "../../hooks/useOneStudent";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../utils/snackStyles";
import useLayout from "../../hooks/useLayout";

const AcademicOneStudent = (id_student) => {
    titleHTML("Alumno 360°");

    const [student, setStudent] = useState(null);
    const [openSnackbar] = useSnackbar(optionsStyle);

    // REDUX
    const { firstInfo } = useOneStudent();

    const { setMenu, clearMenu } = useLayout()

    useEffect(() => {
        !student && getStudentById(id_student);
    }, [student]);

    useEffect(() => {
        firstInfo && setMenu(firstInfo.nombre_completo)
    }, [firstInfo])

    const getStudentById = async (id) => {
        const request = await getConditionByStudent(id);
        if (request.error) {
            openSnackbar("¡No se pudo obtener la información del alumno!");
            setStudent(false);
            return;
        }
        setStudent(request.coursesByStudent[0]);
    };

    //RETURN
    if (!student || !firstInfo) {
        return (
            <Container>
                <SimpleLoading padding="6" />
            </Container>
        );
    }



    return (
        <>
            <Container>
                <StudentInfo>
                    <StudentCardForAcademics firstInfo={firstInfo} />
                    <WrapperRight>
                        <MiniIconCards firstInfo={firstInfo} />
                        <MediumIconCards firstInfo={firstInfo} />
                    </WrapperRight>
                </StudentInfo>
                <Journey>
                    <StudentTabs student={student} />
                </Journey>
            </Container>
        </>
    );
};

export default AcademicOneStudent;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    align-items: center;

    @media (max-width: 1200px) {
        gap: 1rem;
    }
`;

const StudentInfo = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    /* overflow: hidden; */

    @media (max-width: 1300px) {
        grid-template-columns: 2fr 2fr;
    }
    @media (max-width: 1230px) {
        display: flex;
        flex-direction: column;
    }
`;

const WrapperRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Journey = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    @media (max-width: 768px) {
        max-width: 98%;
    }
`;
