import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Popover } from '@mui/material';
import Text from '../../common/Text';
import { dracmaActions } from '../../../redux/actions';
import DracmaResume from '../../ui/organisms/dracma/DracmaResume';

const UserDracmas = () => {
    // REDUX
    const dispatch = useDispatch();
    const dracmas = useSelector((state) => state.dracma.dracmas);
    const ondemand = useSelector((state) => state.ondemand.ondemand);
    // STATE
    const [anchorEl, setAnchorEl] = useState(null);

    // EFFECTS
    useEffect(() => {
        if (!dracmas) dispatch(dracmaActions.getDracmaRequest());
    }, []);

    // FUNCTIONS
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // RETURN
    if (!dracmas) {
        return null;
    }

    if (ondemand && ondemand.enable_dracma === false) {
        return null;
    }

    return (
        <>
            <CoinsWrapper data-tut="reactour__user_dracmas">
                <CustomIconButton
                    aria-describedby={id}
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                >
                    <CustomImg
                        src="/assets/aden/dracma-point.svg"
                        alt="dracma-point"
                        loading="lazy"
                    />
                    <Points>{dracmas.balance}</Points>
                </CustomIconButton>
            </CoinsWrapper>
            {/** Popover de Dracmas */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Container>
                    {/** Resumen de Dracma */}
                    <DracmaResume />
                </Container>
            </Popover>
        </>
    );
};

export default UserDracmas;

const CoinsWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;

const CustomImg = styled.img`
    width: 1.75rem;
    height: auto;
`;

const CustomIconButton = styled(IconButton)`
    border-radius: 100px;
    display: flex;
    column-gap: 1rem;
`;

const Points = styled.div`
    font-size: 1.1rem;
    color: #a8a8a8;
    font-weight: 700;
    padding-left: 0.5rem;
`;

const Container = styled.div`
    width: 400px;

    @media (max-width: 425px) {
        width: 90vw;
    }
`;
