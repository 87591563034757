import React from 'react';
import styled from 'styled-components';
import ErrorImg from "../../../assets/media/aden/error.png"
import LazyImg from '../../../modules/acropolisCommon/components/LazyImg';

const NoProgramsFound = (props) => {
    const {
        message = '¡Ups! No encontramos lo que buscabas.',
    } = props;

    return (
        <NoResultsFoundContainer>
            <LazyImg 
                src={ErrorImg} 
                backgroundColor={"#fff"}
                width="300px"
                height="300px"
            />
            <Text>{message}</Text>
        </NoResultsFoundContainer>
    );
};

export default NoProgramsFound;

const NoResultsFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    padding: 2rem;
    text-align: center;
    background-color: #ffffff;
    color: #bfbfbf;
`;

const Text = styled.span`
    font-size: 1.5rem;
    font-weight: 700;
`;
