import styled from "styled-components";

const CardSubmissionState = (props) => {

    const { Icon, strongColor, lightColor, state } = props;

    return (
        <Container strongColor={strongColor} lightColor={lightColor}>
            <span>
                {Icon}
            </span>
            <label>Estado de la entrega:</label>
            <p>{state}</p>
        </Container>
    )
};

export default CardSubmissionState;

const Container = styled.div`
    height: 32px;
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
    border: 1px solid #A8A8A8;
    border-radius: 50px;

    span {
        width: 32px;
        height: 32px;
        border-radius: 50px;
        background-color: ${(props) => props?.strongColor};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: var(--secondary-one, #ffffff);
        }
    }

    label {
        font-size: 15px;
    }

    p {
        width: 155px;
        text-align: center;
        padding: .5rem;
        border: 1px solid ${(props) => props?.strongColor};
        border-radius: 50px;
        background-color: ${(props) => props?.lightColor};
        color: ${(props) => props?.strongColor};
        font-size: 12px;
        font-weight: 700;

        @media (width < 1540px) {
            width: 150px;
        }
    }

    @media (width < 1400px) {
        width: 100%;
    }
`