import React, { useState } from "react";
import { createChannel } from "../../../helpers/createChannelChat.helper";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../helpers/userActions.helper";
import {
    changeChannel,
    setParticipants,
} from "../../../redux/chat/chatActions";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import { IconButton, Tooltip } from "@mui/material";

const SocialMediaToCard = (props) => {
    const { value, type } = props;
    const history = useHistory();
    const userLogin = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    //Chat students of subject
    const partnerId = value.partner_id ? value.partner_id : value.id;

    //Chat
    const chatGeneration = () => {
        const hash = createChannel(userLogin.partner_id, partnerId);
        const contantFormated = {
            nombre: value.name,
            apellido: "",
            partner_id: parseInt(partnerId, 10),
            foto: value.image,
            contactData: value,
        };
        userAction({
            object_id: partnerId,
            object: "Chat acropolis",
            name: `Se inició el chat con ${value.name}`,
            activity: "Iniciar chat",
            medio: "Chat",
        });
        dispatch(changeChannel(hash));
        dispatch(
            setParticipants([
                contantFormated,
                { ...userLogin, nombre: userLogin.name },
            ])
        );
        setTimeout(() => {
            history.push("/networking/chat");
        }, 200);
    };

    return (
        <SocialMedia>
            <BtnSocial onClick={chatGeneration} variant="outlined">
                <Tooltip title="Chat">
                    <ForumRoundedIcon />
                </Tooltip>
            </BtnSocial>

            <BtnSocial
                disabled={value.whatsapp === ""}
                href={`https://wa.me/?phone=${value.whatsapp}`}
                target="_blank"
                rel="noreferrer"
            >
                <Tooltip title="Whatsapp">
                    <WhatsappIcon />
                </Tooltip>
            </BtnSocial>

            <BtnSocial
                disabled={value.personal_email === ""}
                href={`mailto:${value.personal_email}`}
                target="_blank"
                rel="noreferrer"
            >
                <Tooltip title="E-mail">
                    <AlternateEmailRoundedIcon />
                </Tooltip>
            </BtnSocial>

            <BtnSocial
                disabled={value.mobile === ""}
                href={`tel:${value.mobile}`}
                target="_blank"
                rel="noreferrer"
            >
                <Tooltip title="Llamado telefónico">
                    <LocalPhoneRoundedIcon />
                </Tooltip>
            </BtnSocial>
        </SocialMedia>
    );
};

export default SocialMediaToCard;

const SocialMedia = styled.div`
    height: 100%;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BtnSocial = styled(IconButton)`
    cursor: pointer;
    /* width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; */
    svg {
        color: #B31D15;
        font-size: 22px;
    }
`;
