import styled from "styled-components";
import SkeletonLoading from "../../../components/common/SkeletonLoading";

const ProfileSkeleton = () => {
    return (
        <Container>
            <Wrapper>
                <SkeletonLoading height="calc(620px + 4rem)" width="calc(25% + 4rem)" />
                <SkeletonLoading height="calc(620px + 4rem)" width="calc(75% + 48px)" />
            </Wrapper>
        </Container>
    )
}

export default ProfileSkeleton;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`

const Wrapper = styled.div`
    display: flex;
    gap: 2rem;

    @media (width < 1000px) {
        flex-direction: column;
    }
`