import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CommentCard = (props) => {
    const { comments } = props;

    return (
        <Wrapper>
            <>
                {comments.map((comment, index) => (
                    <CardFake key={index}>
                        <Header>
                            <Image>
                                <img src={comment.person.photo} />
                            </Image>
                            <NameWrapper>
                                <h2>{comment.person.name}</h2>
                                <span>{comment.message_date}</span>
                            </NameWrapper>
                        </Header>
                        <Body>
                            <p>{comment.message}</p>
                        </Body>
                    </CardFake>
                ))}
            </>
        </Wrapper>
    );
};

export default CommentCard;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 435px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 1px;
    }
`;

const CardFake = styled(Card)`
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    width: calc(100% - 2.4rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 100px;
`;

const Header = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const Image = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        /* objet-fit: cover; */
    }
`;

const NameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    h2 {
        font-size: 1rem;
        color: #222222;
    }

    span {
        color: #a8a8a8;
        font-size: 0.65rem;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 4.5rem;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 1px;
    }

    p {
        color: #616161;
        font-size: 0.9rem;
    }
`;