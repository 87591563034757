import styled from 'styled-components';
import React from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';

const BannerInternationalWorkshop = () => {
    return (
        <Container>
            <h1>
                <Icon icon="mdi:world" width={20} height={20} />
                ¿Listo para descubrir el mundo, mientras aprendes?
            </h1>
            <p>
                Los workshops son experiencias de networking internacionales donde alumnos y
                egresados de todo Latinoamérica vivencian el aprendizaje continuo de la mano de
                expertos, visitas a empresas de renombre que ha utilizado las mejores herramientas para su gestión y actividades de networking que potencian sus habilidades gerenciales.
            </p>
            <Label>
                <Icon icon="ph:ticket" width={24} height={24} />
                <span>Requiere la adquisición de tickets pagos</span>
            </Label>
        </Container>
    );
};

export default BannerInternationalWorkshop;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 20px;
    /* background: linear-gradient(269.94deg, #fef6f6 0.05%, #ffffff 99.95%), #ffffff; */
    z-index: 0;
    align-items: flex-start;
    h1 {
        width: 100%;
        color: #B31D15;
        font-size: 20px;
        font-size: 700;
        z-index: 2;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 8px;
        padding-bottom: 1rem;
        border-bottom: 1px solid #B31D15;
    }
    p {
        z-index: 2;
        color: #616161;
    }
`;

const Label = styled.div`
    background-color: #fff;
    border-radius: 30px;
    z-index: 2;
    color: #b31d15;
    border: 1px solid #B31D15;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 4px 1rem;
    span {
        font-size: 14px;
        width: fit-content;
        font-weight: 600;
    }
`;
