import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LazyImg from '../../../../components/common/LazyImg';
import documentationWarning from '../../../../assets/media/documentation-warning.png';

const AlertForDocumentation = (props) => {
    const { statusStudentBlock } = props;

    const {
        blockDetail: { idStatusBlock, reason },
    } = statusStudentBlock;

    const history = useHistory();

    return (
        <Wrapper>
            <ImageWrapper>
                <LazyImg
                    backgroundColor="#ffffff"
                    src={documentationWarning}
                    width="150px"
                    height="140px"
                />
            </ImageWrapper>
            <Info>
                <h3>Estimado/a estudiante:</h3>
                {idStatusBlock === 3 ? (
                    <p>
                        El sistema ha detectado que su legajo{' '}
                        <b>
                            presenta inconvenientes con la documentación oficial
                            de su Maestría
                        </b>
                        . Por favor, <b>actualice su documentación</b> o{' '}
                        <b>
                            contacte a su asesor de documentación 
                        </b>{' '}
                        para regularizar su situación.
                    </p>
                ) : (
                    <p>
                        Lamentablemente, algunas actividades y asignaturas
                        dentro de tu cursado se encuentran temporalmente
                        bloqueadas debido a <b>{reason}</b>
                    </p>
                )}
                <Buttons>
                    {idStatusBlock === 3 ? (
                        <ButtonFake
                            color="primary"
                            onClick={() =>
                                history.push('./informacion-academica')
                            }
                            variant="contained"
                        >
                            Actualizar documentación
                        </ButtonFake>
                    ) : (
                        <ButtonFake
                            onClick={() =>
                                history.push('./mi-academic-advisor')
                            }
                            variant="outlined"
                        >
                            Contactar Academic Advisor
                        </ButtonFake>
                    )}
                </Buttons>
            </Info>
        </Wrapper>
    );
};

export default AlertForDocumentation;

const Wrapper = styled.div`
    padding: 2rem;
    align-items: flex-end;
    border: solid 1px #b31d15;
    border-radius: 30px;
    background-color: #ffffff;
    display: flex;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
    gap: 2rem;

    @media (max-width: 768px) {
        p,
        b,
        h3 {
            font-size: 14px;
        }
    }
`;

const ImageWrapper = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`;

const Info = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    color: #222222;
    text-align: start;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;

const ButtonFake = styled(Button)`
    border-radius: 50px;
    padding: 0.5rem 1rem;

    @media (max-width: 768px) {
        width: 100%;
        font-size: 14px;
    }
`;
