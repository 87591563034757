import styled from "styled-components";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";

const PublicBooksInfo = (props) => {

    const { books } = props;

    // Clasificar el array de books por roleWriting
    // Crear un objeto con las keys de roleWriting y los valores de los libros
    // Mostrar los libros en el componente


    const booksByRole = books.reduce((acc, book) => {
        const { roleWriting } = book;
        if (!acc[roleWriting]) {
            acc[roleWriting] = [];
        }
        acc[roleWriting].push(book);
        return acc;
    }, {});

    return (
        <Container>
            <h3>Libros</h3>
            {Object.keys(booksByRole).map((role) => (
                <section key={role}>
                    <label>{capitalizeFirstLetter(role)} de:</label>
                    <ul>
                        {booksByRole[role].map((book, index) => (
                            <li key={index}>"{book.title}", {book.year}, editorial {book.editorial}.</li>
                        ))}
                    </ul>
                </section>
            ))}
        </Container>
    )
};

export default PublicBooksInfo;

const Container = styled.div`
    width: calc(100% - 4rem);
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    border-radius: 30px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h3 {
        font-size: 20px;
        color: #222222;
    }

    section {
        display: flex;
        flex-direction: column;
        gap: 8px;

        label {
            font-size: 20px;
            font-weight: 700;
            color: #b31d15;
        }

        p {
            color: #222222;
            font-size: 14px;
            margin-left: 1rem;
        }

        ul {
            margin-left: 1rem;
            display: flex;
            flex-direction: column;
            gap: 8px;

            li {
                color: #222222;
                font-size: 14px;
            }
        }
    }
`