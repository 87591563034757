import React from "react";
import styled from "styled-components";
import { Chip } from "@mui/material";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ForumIcon from "@mui/icons-material/Forum";

import Button from "../../atoms/Button";
import { useShow } from "../../../../hooks/useShow";
import ProfileSummary from "../../organisms/profile-summary/ProfileSummary";
import { createChannel } from "../../../../helpers/createChannelChat.helper";
import { useDispatch, useSelector } from "react-redux";
import {
  changeChannel,
  setParticipants,
} from "../../../../redux/chat/chatActions";
import { useHistory } from "react-router-dom";
import { userAction } from "../../../../helpers/userActions.helper";

const UserCard = (props) => {
  const { contact, showChatButton } = props;
  const { foto: image, name, profession, country, sis_id } = contact;
  const rol = sis_id ? "Alumno" : "Invitado";

  // Hooks
  const [show, handleShow, handleClose] = useShow(false);
  const userLogin = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  //Chat students of subject
  const partnerId = contact.partner_id ? contact.partner_id : contact.id;

  //Chat
  const chatGeneration = () => {
    const hash = createChannel(userLogin.partner_id, partnerId);
    const contantFormated = {
      nombre: name,
      apellido: "",
      partner_id: parseInt(partnerId, 10),
      foto: image,
      contactData: contact,
    };
    userAction({
      object_id: partnerId,
      object: "Chat acropolis",
      name: `Se inició el chat con ${name}`,
      activity: "Iniciar chat",
      medio: "Chat",
    });
    dispatch(changeChannel(hash));
    dispatch(
      setParticipants([
        contantFormated,
        { ...userLogin, nombre: userLogin.name },
      ])
    );
    setTimeout(() => {
      history.push("/networking/chat");
    }, 200);
  };

  return (
    <MainContainer userRol={rol}>
      <ClickeableArea onClick={handleShow}>
        <ImageContainer>
          <Avatar>
            <img src={image} />
          </Avatar>
          <CustomChip
            size="small"
            label={rol}
            userrol={rol}
            style={{ padding: "0 0.5rem", marginTop: "-1.5rem" }}
            color="primary"
          />
        </ImageContainer>
        <H4> {name.toLowerCase()} </H4>
        <H5> {profession.name} </H5>
        <H6>
          <LocationOnRoundedIcon style={{ fontSize: "0.8rem" }} />{" "}
          {country.name}
        </H6>
      </ClickeableArea>
      <CustomButton
        onClick={showChatButton === false ? null : chatGeneration}
        variant="outlined"
        userRol={rol}
      >
        <ForumIcon />
        <ButtonText>Chat</ButtonText>
      </CustomButton>

      {/* SUMMARY */}
      <ProfileSummary
        open={show}
        handleClose={handleClose}
        userRol={rol}
        contact={contact}
        maxWidth="md"
        showChatButton={showChatButton}
      />
    </MainContainer>
  );
};

UserCard.propTypes = {};

export default UserCard;

const MainContainer = styled.div`
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
  background: ${(p) =>
    p.userRol === "Invitado"
      ? "linear-gradient(to bottom, rgba(94, 128, 219, 0.15) 0.52%, white 40%)"
      : "linear-gradient(to bottom, rgba(179, 29, 21, 0.15) 0.52%, white 40%)"};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  row-gap: 0.5rem;
  justify-content: space-between;
  border-radius: 20px;
  @media screen and (max-width: 468px) {
    padding: 1.5rem 0.5rem;
    min-height: 200px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  position: relative;
`;

const Avatar = styled.div`
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const H4 = styled.h4`
  font-weight: 900;
  font-size: 1rem;
  margin-top: 0.5rem;

  text-align: center;
  align-self: center;
  color: #222222;
  padding: 0 10px;
  max-width: 200px;
`;
const H5 = styled.h5`
  font-size: 0.9rem;
  font-weight: 400;
  color: #616161;
  align-self: center;
  padding: 0 10px;
  text-align: center;
`;

const H6 = styled.h5`
  font-weight: 300;
  font-size: 0.9rem;
  align-self: center;
  color: #a3a3a3;
  padding: 0 10px;
  text-align: center;
`;
const ClickeableArea = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
`;

const ButtonText = styled.p`
  font-weight: 700;
  margin-left: 5px !important;
`;

const CustomChip = styled(Chip)`
  font-size: 0.8rem;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  background-color: ${(p) =>
    p.userrol === "Invitado" ? "#5E80DB" : "#B31D15"};
`;

const CustomButton = styled(Button)`
  border-color: ${(p) =>
    p.userRol === "Invitado" ? "#5E80DB !important" : "#B31D15 !important"};
  color: ${(p) =>
    p.userRol === "Invitado" ? "#5E80DB !important" : "#B31D15 !important"};
`;
