import { useState, forwardRef } from 'react';
import styled from 'styled-components';
import { OpenInNew, Close } from '@mui/icons-material';
import {
    AppBar,
    Dialog,
    IconButton,
    Slide,
    Toolbar,
    Tooltip,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import * as encrypt from 'nodejs-base64-encode';
import SimpleReview from '../../../../ui/molecules/course/evaluation/SimpleReview';

const Calification = (props) => {
    const {
        activity_status,
        note,
        id,
        type,
        redo,
        idA,
        viewAcademic = false,
        submission,
        undelivered,
        rowId,
        activeRow,
        columns,
        columnId,
    } = props;

    const Transition = forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const data = {
        id_task: id || 0,
        id_assignment: idA || 0,
    };
    const [openModal, setOpenModal] = useState(false);

    const hash = encrypt.encode(JSON.stringify(data), 'base64');

    const history = useHistory();

    const handleClickOpen = () => {
        if (
            type === 'auto_corregible' &&
            activity_status !== 'pendiente' &&
            submission !== 'sin_entrega'
        ) {
            setOpenModal(true);
            return;
        }
        history.push(`../calificador/${hash}`);
        return;
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Wrapper
                isSelected={activeRow}
                columns={columns}
                rowId={rowId}
                columnId={columnId}
                redo={redo}
                state={activity_status}
            >
                <CalificationContent>
                    <InputNoteWrapper>
                        {!!redo ? (
                            <>Rehacer</>
                        ) : undelivered === 'sin_entrega' ? (
                            <Bar>-</Bar>
                        ) : activity_status === 'pendiente' ? (
                            <Tooltip
                                title="Pendiente de correción"
                                arrow
                                slotProps={{
                                    popper: {
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -12],
                                                },
                                            },
                                        ],
                                    },
                                }}
                            >
                                <NoteAlertIcon icon="mdi:note-alert-outline" />
                            </Tooltip>
                        ) : (
                            <Note state={activity_status}>{note}</Note>
                        )}
                    </InputNoteWrapper>
                </CalificationContent>
                {undelivered !== 'sin_entrega' && (
                    <Tooltip
                        title="Ver entrega"
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -12],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <IconFake
                            onClick={handleClickOpen}
                            activity_status={activity_status ?? undelivered}
                        >
                            <OpenInNew />
                        </IconFake>
                    </Tooltip>
                )}
            </Wrapper>
            {!!openModal && (
                <Dialog
                    fullScreen
                    open={openModal}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                size="large"
                            >
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <SimpleReview submission={submission} />
                </Dialog>
            )}
        </>
    );
};

export default Calification;

const Wrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    width: 267.5px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: none;
`;

const CalificationContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
`;
const InputNoteWrapper = styled.div``;

const NoteAlertIcon = styled(Icon)`
    width: 20px;
    height: 20px;
`;

const IconFake = styled(IconButton)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: 0.5rem;
    visibility: ${({ display }) => (display ? 'hidden' : 'visible')};

    svg {
        font-size: 1.6rem;
        color: ${({ activity_status }) =>
            activity_status === 'sin_entrega' ? '#d8d8d8' : '#a8a8a8'};
        &:hover {
            cursor: ${({ activity_status }) =>
                activity_status === 'sin_entrega' ? 'default' : 'pointer'};
        }
    }
`;

const Note = styled.span`
    font-weight: bold;
    font-size: 1rem;
`;

const Bar = styled.span`
    font-weight: 900;
    display: flex;
    justify-content: center;
`;
