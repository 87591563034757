import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/react';
import styled from 'styled-components';

import { CloseRounded, MenuRounded } from '@mui/icons-material';

// COMPONENTS
import Logo from './Logo';

const SimpleNav = () => {
    const [menu, setMenu] = useState(false);
    const [navbar, setNavbar] = useState(false);

    function handleMenu() {
        setMenu(!menu);
    }

    return (
        <NavBarContainer>
            <Link to="/">
                <LogoImg
                    // src={require('../../img/logo/logo-aden-simple-red.png')}
                    src= {"/assets/aden/logo-aden-simple-red.png"}
                    alt="logo"
                />
            </Link>
            <MobileToggle onClick={handleMenu}>
                {menu ? (
                    <CloseRounded style={{ color: '#b31d15' }} />
                ) : (
                    <MenuRounded
                        style={{ color: '#b31d15' }}
                        fontSize="large"
                    />
                )}
            </MobileToggle>
            <NavBar
                css={css`
                    @media screen and (max-width: 768px) {
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 100%;
                        position: absolute;
                        top: 100px;
                        right: 0;
                        left: ${menu ? 0 : '-100%'};
                        background-color: #ffffff;
                        height: 90vh;
                        opacity: 1;
                        transition: all 0.5s ease;
                        padding: 10%;
                    }
                `}
            >
                <WhiteButton to="auth">Ingresar</WhiteButton>
                <RedButton to="auth/?registration=true">Registrarme</RedButton>
            </NavBar>
        </NavBarContainer>
    );
};

export default SimpleNav;

const NavBarContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    padding: 0 5rem;
    height: 100px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    @media screen and (max-width: 768px) {
        padding: 0 2rem;
    }
`;

const NavBar = styled.nav`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    background-color: transparent;
`;

const LogoImg = styled.img`
    width: 250px;
    transition: all 0.4s;
`;

const MobileToggle = styled.button`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
    }
`;

const WhiteButton = styled(Link)`
    border: 1px solid #b31d15;
    color: #b31d15;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0.25rem 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        opacity: 0.8;
    }

    @media screen and (max-width: 768px) {
        border: 1px solid #b31d15;
        padding: 1rem 1.2rem;
        font-size: 1.2rem;
        margin: 1rem;
    }
`;

const RedButton = styled(Link)`
    background-color: #b31d15;
    color: #ffffff;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        background-color: #921009;
        color: #ffffff;
    }

    @media screen and (max-width: 768px) {
        padding: 1rem 1.2rem;
        font-size: 1.2rem;
        margin: 1rem;
    }
`;
