import React from 'react';
import styled from 'styled-components';
import { OpenInNew } from '@mui/icons-material';

const LinkToCalifications = (props) => {
    const { id_activity, id_assignment } = props;

    const link = `{"id_task":${id_activity.toString()},"id_assignment":${id_assignment.toString()}}`;
    const base64Link = Buffer.from(link).toString('base64');

    return (
        <CustomButton
            href={`/profesor/calificador/${base64Link}`}
            target="_blank"
        >
            <OpenInNew />{' '}
        </CustomButton>
    );
};

export default LinkToCalifications;

const CustomButton = styled.a`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 0.3rem;
    font-weight: bold;
    color: #616161;
    font-size: 14px;
    cursor: pointer;
    svg {
        font-size: 1rem;
        color: #d8d8d8;
        &:hover {
        color: #a8a8a8;
    }
    }

`;
