import Mariano from '../../img/experiencias/experiencia-mariano-arnal.jpg';
import Karina from '../../img/experiencias/experiencia-karina-paez.jpeg';
import Matias from '../../img/experiencias/experiencia-matias-molina.jpeg';
import Edgar from '../../img/experiencias/experiencia-edgar-leonel-de-leon-rodriguez.jpg';
import Eduardo from '../../img/experiencias/experiencia-eduardo-otero.jpeg';

export const experiencias = [
    {
        name: 'Mariano Arnal',
        text:
            'El MBA de ADEN, me permitió articular conocimientos que traía desde la experiencia; así logre formar una visión más integradora para poder afrontar las problemáticas desde un punto de vista más estratégico.',
        image: Mariano,
        pais: 'argentina'
    },
    {
        name: 'Karina Paez',
        text:
            'A partir de el fin del MBA con ADEN, debía realizar el proyecto de graduación que fue dirigido por el profesor Fabiar Perez, quien me inspiró y aportó en mi proceso de crecimiento profesional. Cuando inicie el proceso de dar vida a lo que hoy es mi programa “Yo Protagonista” vigente desde hace poquito más de 7 años sabía que sería un programa relacionado al empoderamiento personal, fui dándole forma acompañada de un equipo de profesionales variados quienes aportaron en cada parte del proceso, hoy es un programa que mueve la cultura de las empresas ,parte de que cada persona tiene todo para brillar, lograr y crear resultados desde sus creencias, pensamientos, acciones y hábitos.',
        image: Karina,
        pais: 'ecuador'
    },
    {
        name: 'Matias Molina',
        text:
            'En mi vida fue un antes y un después poder mirar los negocios y darles perspectiva de futuro. Resolver con herramientas que muchas veces están ahí dispersas, pero que ADEN se encarga de encauzar, contener y procesar el cúmulo de información y experiencia a través de su valor agregado que son las personas que trabajan desde el servicio de limpieza y Administración hasta los profes que contienen al alumno con dudas, propuestas y sugerencias.',
        image: Matias,
        pais: 'argentina'
    },
    {
        name: 'Edgar Leonel de Leon Rodriguez',
        text:
            'ADEN, no solo llenó mis expectativas, sino que las superó en gran medida, ya que vincularon perfectamente la academia, los negocios y la sociedad. ADEN cambió mi manera de pensar, analizar, ver y abordar las situaciones del dia a dia en los negocios.',
        image: Edgar,
        pais: 'panama'
    },
    {
        name: 'Eduardo Otero',
        text:
            'Cuando inicié el cursado, la necesidad era  conseguir herramientas que me permitieran dar soluciones diferentes a las que ya se habían vuelto habituales. Gracias a ADEN puedo decir que los proyectos han crecido no sólo internamente sino que han iniciado procesos para ser llevados a otros países.',
        image: Eduardo,
        pais: 'colombia'
    }
];
