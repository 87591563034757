import React from 'react'
import styled from 'styled-components'
import useRankingByCourse from "../../../../hooks/teacher/useRankingByCourse";
import useRankingForAdvance from "../../../../hooks/teacher/useRankingForAdvance";
import RankingDashboardCourse from '../../../ui/organisms/teacher/rankings/RankingDashboardCourse'

const StatisticsDashboard = (props) => {

    const { id } = props;

    const { students } = useRankingByCourse(id)
    const { advanceStudents } = useRankingForAdvance(id)
    
  return (
    <Wrapper>
        <RankingDashboardCourse
            title={"Ranking de entregas"}
            type={"progress"}
            ranking={students}
            advanceStudents={advanceStudents}
        />
    </Wrapper>
  )
}

export default StatisticsDashboard

const Wrapper = styled.div`

`