import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CertificateButtonProgress from "../../../../components/ui/atoms/CertificateButtonProgress";

import styled from "styled-components";
import { Button } from "@mui/material";
import { Route } from "@mui/icons-material";
import * as encode from "nodejs-base64-encode";
import { useQueryParams } from "../../../../modules/acropolisCommon/hooks/useQueryParams";

const AsideProgress = (props) => {
  const { workshop, currentMedia, handleProgress, certificate, objId, activeRol } =
    props;

  // const [avaliableCertificate, setAvaliableCertificate] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openTest, setOpenTest] = useState(progress >= 100 ? true : false);

  const DISABLE_CERTIFICATE_ORIGIN_CASES = ["plan", "diplomado"];

  useEffect(() => {
    getProgress();
  }, [workshop, currentMedia]);

  useEffect(() => {
    if (progress >= 100) {
      setOpenTest(true);
    }
  }, [progress]);

  const { id } = useParams();

  const params = useQueryParams();
  const userOrigin = params.get("origin");
  // const programId = encode.decode(program, "base64");

  const getProgress = () => {
    let lengthClass = 0;
    let allView = 0;
    workshop.unidades.forEach((unit) => {
      unit.clases.forEach((clase) => {
        ++lengthClass;
        if (clase.visto) {
          ++allView;
        }
      });
    });
    setProgress((allView * 100) / lengthClass);
    handleProgress((allView * 100) / lengthClass);
  };

  // ============= FUNCIONES =============
  const handleCloseTest = () => setOpenTest(false);
  const handleOpenTest = () => setOpenTest(true);

  return (
    <Container>
      <ProgressContainer>
        <ProgressBarContainer>
          <BarWrapper>
            {/* <LinearBarProgress color="secondary" progress={progress} /> */}
          </BarWrapper>
        </ProgressBarContainer>
        <ButtonWrapper>
          {userOrigin === "demo" && (
            <AsideButton
              variant="outlined"
              color="secondary"
              onClick={handleOpenTest}
              isAlone={!certificate ? true : false}
            >
              <DownloadIcon />
              <ButtonText>Conocer mi avance</ButtonText>
            </AsideButton>
          )}
          {
            activeRol === "ondemand" ?
              (
                <>
                  {
                    workshop?.certificado_alab &&
                    <CertificateButtonProgress
                      objId={objId}
                      certificate={certificate}
                      userOrigin={userOrigin}
                      disabled={progress !== 100 ? true : false}
                      workshop={workshop}
                    />
                  }
                </>
              ) :
              (
                <>
                  {
                    certificate &&
                    !DISABLE_CERTIFICATE_ORIGIN_CASES.includes(
                      userOrigin
                    ) && (
                      <CertificateButtonProgress
                        objId={objId}
                        certificate={certificate}
                        userOrigin={userOrigin}
                        disabled={progress !== 100 ? true : false}
                        workshop={workshop}
                      />
                    )
                  }
                </>
              )
          }
        </ButtonWrapper>
      </ProgressContainer>
    </Container>
  );
};

export default AsideProgress;

const Container = styled.div``;

const ProgressContainer = styled.div`
    width: calc(100% - 1rem);
    padding: 0 0.5rem;
    margin: 0 auto 0 auto;
`;

const ProgressBarContainer = styled.div`
    display: flex;
    align-items: center;
`;

const BarWrapper = styled.div`
    margin: 0;
    align-items: center;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    justify-content: space-between;
    gap: 8%;
    @media (max-width: 1024px) {
        display: block;
    }
`;

const AsideButton = styled(Button)`
    line-height: 20px;
    text-align: start;
    font-size: 14px;
    padding: 15px 15px 15px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const DownloadIcon = styled(Route)`
    margin-right: 10px;
    color: #b31d15;
`;

const ButtonText = styled.div`
    overflow: hidden;
    word-wrap: normal;
    text-overflow: ellipsis;
    color: black;
`;
