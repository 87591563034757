import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import GenericErrorImage from '../../assets/media/genric-error.png';
import LazyImg from './LazyImg';
import { Text } from './Texts';

const GenericError = ({ reloadFn = () => {} }) => {
    return (
        <Wrapper>
            <LazyImg
                backgroundColor="#ffffff"
                src={GenericErrorImage}
                width="fit-content"
                height="fit-content"
            />

            <Text fontSize="24px" fontWeight="700" color="#b31d15">
                ¡Ups!
                <p>No pudimos cargar este contenido</p>
            </Text>
            <Text fontSize="16px" fontWeight="400" color="#222222">
                Por favor inténtalo nuevamente.
            </Text>
            <RefreshButton
                onClick={reloadFn}
                variant="contained"
                startIcon={<Refresh />}
            >
                Volver a cargar
            </RefreshButton>
        </Wrapper>
    );
};

export default GenericError;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 610px;
    text-align: center;
    gap: 2rem;
    background-color: #ffffff;
    border-radius: 30px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
`;

const RefreshButton = styled(Button)`
    border-radius: 50px;
`;
