import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';


const AccordionLeft = (props) => {
    
    const {generalInfo, publicProfileData, academicTraining, professionalInfo } = props; 

    const [expanded, setExpanded] = useState(false);

     // FUNCTION
     const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function calcularEdad(fecha) {
        var hoy = new Date();
        var cumpleanos = new Date(fecha);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();
    
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        return edad;
    }
    
    return (
        <Wrapper>
            <AccordionFake
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6h-content"
                    id="panel6h-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Datos personales
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetails>
                    <TypographyChips>
                        {publicProfileData?.gender &&     
                            <>
                                <Tiltle>Sexo</Tiltle>
                                <Content>{  
                                    publicProfileData.gender === "M"
                                    ? "Masculino"
                                    : publicProfileData.gender === "F"
                                    ? "Femenino"
                                    : "-"
                                    }
                                </Content>
                            </>                      
                        }

                        {generalInfo?.info?.estado_civil &&     
                            <>
                                <Tiltle>Estado civil</Tiltle>
                                <Content>{generalInfo.info.estado_civil}</Content>
                            </>                      
                        }
                       
                        {generalInfo?.info?.fecha_de_nacimiento &&     
                            <>
                                <Tiltle>Fecha de nacimiento</Tiltle>
                                <Content>{generalInfo.info.fecha_de_nacimiento}</Content>
                            </>                      
                        }

                        {generalInfo?.info?.fecha_de_nacimiento &&     
                            <>
                                <Tiltle>Edad</Tiltle>
                                <Content>{calcularEdad(generalInfo.info.fecha_de_nacimiento)}</Content>
                            </>                      
                        }

                        {publicProfileData?.country?.name &&
                            <>
                                <Tiltle>País</Tiltle>
                                <Content>{publicProfileData.country.name}</Content>
                            </> 
                        }

                        {publicProfileData?.city &&
                            <>
                                <Tiltle>Localidad - CIUDAD</Tiltle>
                                <Content>{publicProfileData.city}</Content>
                            </> 
                        }

                    </TypographyChips>
                </AccordionDetails>
            </AccordionFake>

            <AccordionFake
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Contacto
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetails>
                    <TypographyChips>

                        {publicProfileData?.phone &&
                            <>
                                <Tiltle>TELÉFONO PARTICULAR</Tiltle>
                                <Content>{publicProfileData.phone}</Content>
                            </> 
                        }
                        
                        {publicProfileData?.mobile &&
                            <>
                                <Tiltle>TELÉFONO CELULAR</Tiltle>
                                <Content>{publicProfileData.mobile}</Content>
                            </> 
                        }

                        {publicProfileData?.personal_email &&
                            <>
                                <Tiltle>EMAIL</Tiltle>
                                <Content>{publicProfileData.personal_email}</Content>
                            </> 
                        }

                        {publicProfileData?.contacto_email_alumni &&
                            <>
                                <Tiltle>EMAIL 2</Tiltle>
                                <Content>{publicProfileData.contacto_email_alumni}</Content>
                            </> 
                        }

                        {publicProfileData?.work_email &&
                            <>
                                <Tiltle>EMAIL INSTITUCIONAL</Tiltle>
                                <Content>{publicProfileData.work_email}</Content>
                            </> 
                        }

                        {publicProfileData?.street &&
                            <>
                                <Tiltle>Dirección</Tiltle>
                                <Content>
                                    {publicProfileData.street}
                                    {" - "}
                                    {
                                        publicProfileData?.city
                                        ? publicProfileData.city
                                        : "-"
                                    }
                                    { " - "}
                                    {
                                        publicProfileData?.country?.name 
                                        ? publicProfileData.country.name 
                                        : "-"
                                    }
                                </Content>
                            </> 
                        }
                
                    </TypographyChips>
                </AccordionDetails>
            </AccordionFake>

            <AccordionFake
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Redes sociales
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetails>
                    <TypographyChips>

                        {generalInfo?.redes?.facebook &&
                            <>
                                <Tiltle>facebook</Tiltle>
                                <Content>{generalInfo.redes.facebook}</Content>
                            </> 
                        }
                        {generalInfo?.redes?.instagram &&
                            <>
                                <Tiltle>instagram</Tiltle>
                                <Content>{generalInfo.redes.instagram}</Content>
                            </> 
                        }
                        {generalInfo?.redes?.linkedin &&
                            <>
                                <Tiltle>linkedin</Tiltle>
                                <Content>{generalInfo.redes.linkedin}</Content>
                            </> 
                        }
                        {generalInfo?.redes?.skype &&
                            <>
                                <Tiltle>skype</Tiltle>
                                <Content>{generalInfo.redes.skype}</Content>
                            </> 
                        }
                        {generalInfo?.redes?.tiktok &&
                            <>
                                <Tiltle>tiktok</Tiltle>
                                <Content>{generalInfo.redes.tiktok}</Content>
                            </> 
                        }
                        {generalInfo?.redes?.twitter &&
                            <>
                                <Tiltle>twitter</Tiltle>
                                <Content>{generalInfo.redes.twitter}</Content>
                            </> 
                        }

                    </TypographyChips>
                </AccordionDetails>
            </AccordionFake>

            <AccordionFake
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Información Profesional
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetails>
                    <TypographyChips>

                        {professionalInfo?.empresa?.empresa_nombre &&     
                            <>
                                <Tiltle>EMPRESA</Tiltle>
                                <Content>{professionalInfo.empresa.empresa_nombre}</Content>
                            </>                      
                        }

                        {professionalInfo?.empresa?.rubro_industria &&     
                            <>
                                <Tiltle>rubro</Tiltle>
                                <Content>{professionalInfo.empresa.rubro_industria}</Content>
                            </>                      
                        }

                        {professionalInfo?.cargo?.area &&     
                            <>
                                <Tiltle>ÁREA LABORAL</Tiltle>
                                <Content>{professionalInfo.cargo.area}</Content>
                            </>                      
                        }
                                                             
                        {professionalInfo?.cargo?.cargo_actual &&     
                            <>
                                <Tiltle>CARGO ACTUAL</Tiltle>
                                <Content>{professionalInfo.cargo.cargo_actual}</Content>
                            </>                      
                        }

                        {professionalInfo?.cargo?.personal_a_cargo &&     
                            <>
                                <Tiltle>Personal a cargo</Tiltle>
                                <Content>{professionalInfo.cargo.personal_a_cargo}</Content>
                            </>                      
                        }

                        {professionalInfo?.empresa?.telefono &&     
                            <>
                                <Tiltle>TELÉFONO LABORAL</Tiltle>
                                <Content>{professionalInfo.empresa?.telefono}</Content>
                            </>                      
                        }

                    </TypographyChips>
                </AccordionDetails>
            </AccordionFake>

            <AccordionFake
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                >
                    <TypographyFake sx={{ width: '33%', flexShrink: 0 }}>
                        Información Académica
                    </TypographyFake>
                </AccordionSummary>
                <AccordionDetails>
                    <TypographyChips>
                        
                        {academicTraining?.nivel?.nivel_de_estudio &&     
                            <>
                                <Tiltle>NIVEL DE ESTUDIOS</Tiltle>
                                <Content>{academicTraining.nivel.nivel_de_estudio}</Content>
                            </>                      
                        }
                        {academicTraining?.grado?.titulo_de_grado &&     
                            <>
                                <Tiltle>TÍTULO DE GRADO</Tiltle>
                                <Content>{academicTraining.grado.titulo_de_grado}</Content>
                            </>                      
                        }
                        {academicTraining?.grado?.universidad &&     
                            <>
                                <Tiltle>universidad</Tiltle>
                                <Content>{academicTraining.grado.universidad}</Content>
                            </>                      
                        }
                        {publicProfileData?.academic_training &&
                            <>
                                <Tiltle>formación académica</Tiltle>
                                <Content>{publicProfileData?.academic_training}</Content>
                            </> 
                        }
                       
                    </TypographyChips>
                </AccordionDetails>
            </AccordionFake>
        </Wrapper>
    );
};

export default AccordionLeft;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    row-gap: 1rem;
`;

const AccordionFake = styled(Accordion)`
    border: none !important;
    box-shadow: none;
    margin-bottom: 2rem;
    p{
        color: #4F4F4F;
    }

    &::before {
        display: none;
    }
`;
const TypographyFake = styled(Typography)`
    color: #4F4F4F;
    font-weight: 700;
    font-size: 1.1rem;
`;

const TypographyChips = styled(Typography)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.3rem;
    flex-wrap: wrap;
`;


const Tiltle = styled.text`
font-family: 'Lato';
font-style: normal;
font-weight: 900;
font-size: 0.8rem;
line-height:0.5rem;
align-items: center;
text-transform: uppercase;
font-feature-settings: 'tnum' on, 'lnum' on;
color: #616161;
`;

const Content = styled.text`
font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 1rem;
align-items: center;
font-feature-settings: 'tnum' on, 'lnum' on;
color: #222222;
margin-bottom: 1.5rem;
`;

