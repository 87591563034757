import React from "react";
import styled from "styled-components";

import useResource from "../../hooks/useResource";
import DefaultCard from "../../../acropolisCommon/components/DefaultCard";
import DefaultChip from "../../../acropolisCommon/components/DegaultChip";
import { Text } from "../../../acropolisCommon/components/Texts";
import ErrorResponse from "../../../acropolisCommon/components/ErrorResponse";
import SimpleLoading from "../../../acropolisCommon/components/SimpleLoading";
import RenderPlaneMaterial from "./RenderPlaneMaterial";
import RenderVideo from "./RenderVideo";
import RenderPresentation from "./RenderPresentation";
import RenderEvaluation from "./RenderEvaluation";

// import RenderActivity from "../../ui/organisms/resources/RenderActivity";
// import RenderPresentation from "../../ui/organisms/resources/RenderPresentation";

const ViewResource = (props) => {
    // PROPS
    const { type, id } = props;

    // HOOKS
    const { resource } = useResource(id, type);

    // FUNCTIONS
    const renderType = (resource) => {
        const cases = {
            audiovisual: <RenderVideo resource={resource} />,
            material_plano: <RenderPlaneMaterial resource={resource} />,
            evaluacion: <RenderEvaluation resource={resource} onlyView={true} />,
            presentacion: <RenderPresentation resource={resource} onlyView={true} />,
            error: <ErrorResponse message="Ocurrió un error al obtener el recurso" />,
        };
        return cases[resource.format] || cases["error"];
    };

    if (!resource) {
        return (
            <DefaultCard>
                <SimpleLoading />
            </DefaultCard>
        );
    }

    if (resource === "error") {
        return (
            <DefaultCard>
                <ErrorResponse message="Ocurrió un error al obtener el recurso" />
            </DefaultCard>
        );
    }

    return (
        <MainWrapper>
            <ResourceWrapper>
                {/* Informacion del recurso */}
                <DefaultCard>
                    <Container>
                        <Text color="#b31d15" fontSize="18px" fontWeight="bold">
                            {resource?.publication_name ||
                                "El recurso no posee un nombre cargado."}
                        </Text>
                        <Description>
                            {resource?.description ||
                                "El recurso seleccionado no posee una descripción."}
                        </Description>
                        {resource?.tags && (
                            <ChipsWrapper>
                                {resource?.tags.map((item) => (
                                    <DefaultChip
                                        padding="5px 10px"
                                        radius="20px"
                                        textColor="white"
                                        color="#b31d15"
                                        title={item.toLowerCase()}
                                    />
                                ))}
                            </ChipsWrapper>
                        )}
                    </Container>
                </DefaultCard>
                {/* Recurso */}
                <DefaultCard>
                    {!resource.can_be_published && (
                        <Description>
                            ¡El recurso no esta apto para publicar en Acropolis!
                        </Description>
                    )}
                    <Container>{renderType(resource)}</Container>
                </DefaultCard>
            </ResourceWrapper>
            {/* Padlet */}
            {resource.url_padlet && (
                <DefaultCard>
                    <Iframe src={resource.url_padlet} />
                </DefaultCard>
            )}
        </MainWrapper>
    );
};

export default ViewResource;

const MainWrapper = styled.div`
    display: grid;
    gap: 16px;
`;

const ResourceWrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr 4fr;
    gap: 16px;
`;

const Description = styled.div`
    padding: 1rem;
    background-color: #f7f7f7;
    border-radius: 20px;
    color: #858585;
    font-weight: 600;
    margin-bottom: 1.2rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ChipsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* align-items: center; */
    gap: 8px;
    width: 100%;
    height: 80px;
    max-height: 80px;
    overflow-y: auto;
`;

const Iframe = styled.iframe`
    border: none;
    width: 100%;
    min-height: 600px;
    height: 100%;
`;
