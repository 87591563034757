import useFetch from '../../../hooks/useFetch';
import { studentStatusAdapter } from '../adapters/studentStatus.adapter';
import { getStudentStatus } from '../api';

const useStudentStatus = () => {
    const {
        data: statusStudentBlock,
        loading: loadingStatusStudent,
        error: errorStatusStudent,
        isError: isErrorStatusStudent,
    } = useFetch({
        asyncFn: () => getStudentStatus(),
        adapter: studentStatusAdapter,
    });
    return {
        statusStudentBlock,
        loadingStatusStudent,
        errorStatusStudent,
        isErrorStatusStudent,
    };
};

export default useStudentStatus;
