import React from 'react';
import styled from 'styled-components';
import WhatsappBtn from '../../components/common/WhatsappBtn';

import PublicityWithout from '../../components/templates/auth/PublicityWithout';
import Register from '../../components/templates/auth/Register';

const RegisterPage = () => {
    return (
        <RegisterPageContainer>
            <PublicityWithout />
            <Register />
            <WhatsappBtn x="right: 7rem" y="bottom: 1.5rem" />
        </RegisterPageContainer>
    );
};

export default RegisterPage;

const RegisterPageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    height: 100vh;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        div {
            height: 100%;
        }
    }
`;
