import styled from 'styled-components';
import parse from 'html-react-parser';
import { Icon } from '@iconify/react';
import React from 'react';

// Components
import MultipleChoiceHeader from './header/MultipleChoiceHeader';
import LazyImg from '../../../../../components/common/LazyImg';
import { Text } from '../../../../../components/common/Texts';
import Attempts from './evaluation/Attempts';

// Assets
import ImagenBloqueo from '../../../../../assets/media/aden/image-bloqueo.png';

const PageInitial = (props) => {
    const {
        description,
        time,
        attempts,
        delivery,
        note,
        isReview,
        setSubmission,
        submissions,
        statusStudentBlock,
        reasonBlock,
        publicationName,
        handleOpenModal,
        handleSimpleReview
    } = props;

    const dateAttempt = !!delivery[0]?.create_date
        ? new Date(delivery[0]?.create_date).toLocaleDateString()
        : '-';

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, 'g');
        return html.replace(regex, 'src="');
    };

    return (
        <Container
            title={
                reasonBlock
                    ? `Lamentablemente, no puedes realizar esta actividad en este momento. El acceso está temporalmente bloqueado debido a ${reasonBlock}`
                    : ''
            }
        >
            <MultipleChoiceHeader
                publicationName={publicationName}
                dateAttempt={dateAttempt}
                attempts={attempts}
                time={time}
                submissions={submissions}
                note={note}
            />
            {
                submissions?.length > 0 &&
                <>
                    <Wrapper>
                        <Text
                            color="#B31D15"
                            fontSize="18px"
                            fontWeight="700"
                            style={{ borderBottom: '1px solid #A8A8A8', paddingBottom: '1rem' }}
                        >
                            Tus intentos
                        </Text>
                        <AttemptsWrapper>
                            {delivery?.length === 0 ? (
                                <PendingWrapper>
                                    <LazyImg src={ImagenBloqueo} alt="Imagen de bloqueo" backgroundColor="#fff" width={363} height={"auto"} />
                                    <Text fontWeight="400" fontSize="15px" color="#616161" textAlign="center">
                                        Aquí podrás ver los detalles de tus intentos
                                    </Text>
                                </PendingWrapper>
                            ) : (
                                <Attempts
                                    setSubmission={setSubmission}
                                    handleSimpleReview={() => handleSimpleReview(0)}
                                    submissions={submissions}
                                    note={note}
                                />
                            )}
                        </AttemptsWrapper>
                    </Wrapper>
                    <InfoContainer>
                        <Icon icon="octicon:info-24" width="24px" height="24px" />
                        <label>
                            Ten en cuenta que la plataforma registrará este examen como completo, <b>una vez que lo hayas realizado.</b>
                        </label>
                    </InfoContainer>
                </>
            }
            <ContainerInitial>
                <Text
                    color="#B31D15"
                    fontSize="18px"
                    fontWeight="700"
                    style={{ borderBottom: '1px solid #A8A8A8', paddingBottom: '1rem' }}
                >
                    Consignas del examen:
                </Text>
                <DescriptionInitial>
                    <ParseHtml>
                        {parse(sanitizerHtml(description))}
                    </ParseHtml>
                </DescriptionInitial>
            </ContainerInitial>
            {
                submissions.length <= 0 &&
                <InfoContainer>
                    <Icon icon="octicon:info-24" width="24px" height="24px" />
                    <label>
                        Ten en cuenta que la plataforma registrará este examen como completo, <b>una vez que lo hayas realizado.</b>
                    </label>
                </InfoContainer>
            }
            <TakeTestWrapper>
                <ButtonTakeTest
                    disabled={
                        (attempts <= 0 ||
                            (delivery.length > 0 && !!delivery[0]?.review) ||
                            !!isReview
                            ? true
                            : false) || statusStudentBlock
                    }
                    onClick={handleOpenModal}
                >
                    <Icon icon="cil:pencil" width="24px" height="24px" />
                    <label>Realizar examen</label>
                </ButtonTakeTest>
                {delivery.length > 0 && !!delivery[0]?.review ? (
                    <Text color="#222" fontSize="15px" fontWeight="600">
                        Ya no puedes volver a realizar este examen porque visualizaste las respuestas correctas.
                    </Text>
                ) : (
                    <TakeTestText>
                        <b>¡Importante!</b> tienes <span>{attempts === 100 ? 'ilimitado' : attempts < 0 ? 0 : attempts}</span> intentos restantes para realizar este examen.
                    </TakeTestText>
                )}
            </TakeTestWrapper>
            {
                submissions.length <= 0 &&
                <Wrapper>
                    <Text
                        color="#B31D15"
                        fontSize="18px"
                        fontWeight="700"
                        style={{ borderBottom: '1px solid #A8A8A8', paddingBottom: '1rem' }}
                    >
                        Tus intentos
                    </Text>
                    <AttemptsWrapper>
                        {delivery.length === 0 ? (
                            <PendingWrapper>
                                <LazyImg src={ImagenBloqueo} alt="Imagen de bloqueo" backgroundColor="#fff" width={363} height={"auto"} />
                                <Text fontWeight="400" fontSize="15px" color="#616161" textAlign="center">
                                    Aquí podrás ver los detalles de tus intentos
                                </Text>
                            </PendingWrapper>
                        ) : (
                            <Attempts submissions={submissions} note={note} />
                        )}
                    </AttemptsWrapper>
                </Wrapper>
            }
        </Container>
    );
};

export default PageInitial;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    margin-bottom: 1rem;
`;

const Wrapper = styled.div`
    width: calc(100% - 4rem);
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    border: 1px solid #A8A8A8;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const ContainerInitial = styled.div`
    width: calc(100% - 4rem);
    height: 100%;
    padding: 2rem;
    display: flex;
    gap: 15px;
    flex-direction: column;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    border: 1px solid #A8A8A8;
    border-radius: 30px;
`;

const DescriptionInitial = styled.span`
    height: fit-content;
    max-height: 380px;
    overflow-y: auto;
    height: auto;
`;

const AttemptsWrapper = styled.div`
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    min-height: 70px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ParseHtml = styled.div`
    width: 100%;
    max-width: auto;
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: .5rem;

    svg {
        color: #C29F43;
    }
`

const TakeTestWrapper = styled.div`
    width: calc(100% - 48px);
    height: fit-content;
    padding: 2rem 24px;
    border: 1px solid #A8A8A8;
    border-radius: 30px;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 24px;
`

const ButtonTakeTest = styled.button`
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 24px 12px 16px;
    border-radius: 100px;
    background-color: #cd2118;
    color: #fff;

    label {
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
    }

    :hover {
        background-color: #b31d15;
    }

    :disabled {
        background-color: #ddd;
        color: #aaa;
        cursor: not-allowed;

        label {
            cursor: not-allowed;
        }
    }
`

const TakeTestText = styled.p`
    color: #222;
    font-size: 15px;
    font-weight: 400;

    span {
        color: #b31d15;
        font-weight: 700;
    }
`

const PendingWrapper = styled.div`
    border-radius: 20px;
    height: 430px;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    border: 1px solid #A8A8A8;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;

    svg{
        font-size: 40px;
        color: #A8A8A8;
    }
`