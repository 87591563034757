import * as types from '../types';

const initialState = {
    programs: null,
    listLoading: false,
    errorList: null
};

export const programs = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PROGRAMS_REQUEST:
            return {
                ...state,
                listLoading: true,
                errorList: null
            };
        case types.GET_PROGRAMS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                programs: action.payload
            };
        case types.GET_PROGRAMS_FAIL:
            return {
                ...state,
                listLoading: false,
                programs: [],
                errorList: action.payload
            };
        default:
            return state;
    }
};
