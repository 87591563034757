import React, { useState } from "react";
import { Formik, Form } from "formik";
import styled from "styled-components";
import DefaultField from "../../../common/DefaultField";
import { Button, Checkbox } from "@mui/material";
import DefaultPhone from "../../../common/DefaultPhone";
import DefaultSelect from "../../../common/DefaultSelect";
import { eventRegisterSchema } from "../../../../utils/schemas";
import DefaultCheck from "../../../common/DefaultCheck";
import Text from "../../../common/Text";
import { useSelector } from "react-redux";
import { postEvets } from "../../../../redux/api/events/events";
import SimpleLoading from "../../../common/SimpleLoading";

const FormEventModal = (props) => {
  const { countries, crm_id, tabsFake } = props;

  const user = useSelector((state) => state.auth.user);
  const partner_id = useSelector((state) => state.auth.user.partner_id);

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState({
    view: false,
    msg: "",
  });

  const initialValues = {
    name: user.name,
    email: user.personal_email,
    phone: user.phone,
    country: user.country.id,
    city: user.city,
    terms: false,
  };

  const handleSubmit = async (values) => {
    const data = {
      partner_id: partner_id,
      event_id: crm_id,
      type: null,
    };
    setLoading(true);

    const req = await postEvets(data);
    setLoading(false);

    if (!req.error) {
      setMsg({
        view: true,
        msg: "¡Se registró correctamente!",
        type: true,
      });
    }

    setTimeout(() => {
      setMsg({
        view: false,
        msg: "",
        type: null,
      });
    }, 2000);

    if (req.error) {
      if (
        req.error.response.data.error.includes(
          "El contacto ya se encuentra registrado al evento"
        )
      ) {
        setMsg({
          view: true,
          msg: "¡Ya se encuentra registrado en el evento!",
          type: false,
        });
        return;
      }

      if (
        req.error.response.data.error.includes(
          "No quedan plazas para este evento."
        )
      ) {
        setMsg({
          view: true,
          msg: req.error.response.data.error,
          type: false,
        });
        return;
      }

      setMsg({
        view: true,
        msg: "¡No se pudo registrar al evento!",
        type: false,
      });

      setTimeout(() => {
        setMsg({
          view: false,
          msg: "",
          type: null,
        });
      }, 2000);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={eventRegisterSchema}
    >
      {({ values, setFieldValue }) => (
        <FormContainer>
          <Content>
            <DefaultField
              name="name"
              type="text"
              variant="outlined"
              label="Nombre Completo"
              required
            />

            <DefaultField
              name="email"
              type="email"
              variant="outlined"
              label="E-mail"
              required
            />
            <DefaultPhone
              styled={styledInput}
              name="phone"
              label="Teléfono"
              value={values.phone}
              setFieldValue={setFieldValue}
            />
            <DefaultSelectFake
              name="country"
              label="País de residencia"
              variant="outlined"
              options={countries || []}
              disabled={!countries ? true : false}
            />
            <DefaultField
              name="city"
              type="text"
              label="Región de residencia"
              variant="outlined"
              required
            />
            <WrapperCheck>
              <Checkbox name="terms" color="primary" required />
              <Text fontSize="0.85rem" component="span">
                Consiento el uso de mis datos para los fines indicados en la
                política de privacidad, así como para recibir publicidad de su
                entidad.
              </Text>
            </WrapperCheck>
          </Content>

          <ButtonWrapper>
            {loading ? (
              <SimpleLoading />
            ) : (
              <Button type="submit" color="primary" variant="contained">
                ¡Inscribirme ahora!
              </Button>
            )}
          </ButtonWrapper>
          {msg.view && (
            <WrapperMsg type={msg.type}>
              <p>{msg.msg}</p>
            </WrapperMsg>
          )}
        </FormContainer>
      )}
    </Formik>
  );
};

export default FormEventModal;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  position: relative;
`;
const DefaultSelectFake = styled(DefaultSelect)`
  height: 40px;
`;

const ButtonWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 90%;
`;

const styledInput = {
  width: "100%",
  padding: "10px 60px",
};

const WrapperCheck = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 90%;
  align-items: flex-start;
`;

const WrapperMsg = styled.div`
  position: absolute;
  bottom: 5px;
  left: calc(50% - 100px - 0.3rem);
  width: 200px;
  border-radius: 5px;
  height: 40px;
  padding: 0.3rem;
  background-color: ${(p) => (p.type ? "#35D0A5" : "#FC2958")};
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #fff;
    font-weight: bold;
  }
`;
