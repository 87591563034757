import { Button } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Img } from '../../../common/Image'
import { Text } from '../../../common/Texts'

const GraduatesCard = (props) => {

    const { values } = props

    const history = useHistory()

    return (
        <CardWrapper title={values.name}>
            <Header image={values.image}/>
            <Body>
                <Title fontSize="18px" fontWeight="700">{values.name}</Title>
                <BodyDescription>
                    <Text color="#949494">{values?.description}</Text>
                </BodyDescription>
            </Body>
            <Footer>
                <Text fontSize="18px" fontWeight="900">US$ {values.price}</Text>
                <Button onClick={() => history.push('/producto/' + values.id)} variant='contained' color='primary'>Ver más</Button>
            </Footer>
        </CardWrapper>
    )
}

export default GraduatesCard

const CardWrapper = styled.div`
    display: grid;
    grid-template-rows: 102px auto 78px;
    width: 323px;
    height: 407px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`

const Header = styled.div`
    height: 102px;
    background-image: url(${p => p.image});
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-size: cover;
`

const Body = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
`

const BodyDescription = styled.div`
    max-height: 143px;
    overflow-y: auto;
`

const Footer = styled.div`
    height: 78px;
    background-color: #FAFAFA;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
`

const Title = styled(Text)`
    width: 280px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`