import { Button, InputAdornment, TextField } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import React, { useState } from 'react';
import styled from 'styled-components';
import useStudentsCourse from '../../../../hooks/teacher/useStudentsCourse';
import ErrorResponse from '../../../common/ErrorResponse';
import SimpleLoading from '../../../common/SimpleLoading';
import UsersCard from './student/UsersCard';

const Students = (props) => {
    const { id } = props;

    const [filterField, setFilterField] = useState('');

    const {
        allStudents,
        students,
        viewLoading,
        noMore,
        handleStudents,
        handleMoreStudents,
    } = useStudentsCourse(id);

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterField(value);
        handleStudents(
            allStudents.filter((student) =>
                student.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
    };

    if (!students) {
        return <SimpleLoading />;
    }

    // if (students.length === 0) {
    //     return (
    //         <ErrorResponse message="No hay estudiantes en esta asignatura" />
    //     );
    // }

    return (
        <>
            <Container>
                {/* <Text color="#333333" fontSize="1.5rem" fontWeight="bold">Alumnos</Text> */}
                <Header>
                    <TextField
                        value={filterField}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        label="Buscar estudiante..."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchRounded />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* <SplitButton handleOption={handleOption} options={options} /> */}
                </Header>

                {students.length === 0 ? (
                    <ErrorResponse message="No hay estudiantes en esta asignatura" />
                ) : (
                    <>
                        <UsersWrapper>
                            {students?.map((user) => {
                                return <UsersCard user={user} />;
                            })}
                        </UsersWrapper>
                    </>
                )}
            </Container>
            {students.length !== 0 && (
                <DivButton>
                    {!!viewLoading ? (
                        <SimpleLoading />
                    ) : !noMore ? (
                        <Button
                            onClick={handleMoreStudents}
                            color="primary"
                            variant="outlined"
                        >
                            Ver más
                        </Button>
                    ) : (
                        <NoMore>Llegaste al final</NoMore>
                    )}
                </DivButton>
            )}
        </>
    );
};

export default Students;

const Container = styled.div`
    width: calc(100% - 32px);
    margin: 0 auto;
    padding: 0 16px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`;

const UsersWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;
    /* padding: 0 16px; */

    @media (max-width: 1300px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const DivButton = styled.div`
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NoMore = styled.p`
    color: #b31d15;
`;
