import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { InputAdornment, TextField } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import NoResultsFound from "./NoResultsFound";
import MenuFilter from "./Menu";

const GridBusiness = (props) => {
  const { Card, propsValues, menuFilter, allCourses } = props;
  // STATE
  const [values, setValues] = useState([]);
  const [search, setSearch] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);

  // EFFECTS
  useEffect(() => {
    setValues(propsValues);
  }, [propsValues]);

  useEffect(() => {
    if (search !== null) {
      if (search === "") {
        setValues(propsValues);
      } else {
        setValues(
          allCourses.filter((course) => {
            return course.nombre_publicacion
              .toLowerCase()
              .includes(search.toLowerCase()); //setea "values" desde "allCourses" filtrando por SEARCH "course.nombre_publicacion"
          })
        );
      }
    }
  }, [search, allCourses]);

  useEffect(() => {
    if (currentMenu !== null) filterData(); //setea "values" desde "allCourses" filtrando por MENU "eje_tematico_ids"
    if (currentMenu === null) setValues(propsValues);
  }, [currentMenu]);

  // FUNCTIONS
  const filterData = () => {
    let result = [];
    allCourses.forEach((value) => {
      value.eje_tematico_ids.forEach((theme) => {
        if (theme.nombre === currentMenu || theme === currentMenu) {
          result.push(value);
        }
      });
    });
    setValues(result);
  };

  function handleChange(e) {
    setSearch(e.target.value);
  }

  const handleSelectMenu = (menu) => {
    setCurrentMenu(menu);
  };

  // RETURN
  return (
    <>
      <Filters>
        <MenuFilter
          menuFilter={menuFilter}
          handleSelectMenu={handleSelectMenu}
          currentMenu={currentMenu}
        />
        <TextFieldFake
          value={search}
          onChange={handleChange}
          size="small"
          label=""
          placeholder="Buscar..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
      </Filters>
      {values.length === 0 ? (
        <NoResultsFound />
      ) : (
        <GridValues>
          {values
            .sort(function (a) {
              if (a.id === 6619) {
                return -1;
              }
            })
            .map((value, i) => (
              <Card value={value} key={i} />
            ))}
        </GridValues>
      )}
    </>
  );
};

export default GridBusiness;

const Filters = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
`;

const GridValues = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  /* grid-gap: 1rem; */

  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
  }
`;

const TextFieldFake = styled(TextField)`
  box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
  -webkit-box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
  -moz-box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
  padding: 0.5rem 2rem;
`;
