import React from 'react';
import { Chip } from '@mui/material';
import styled from 'styled-components';
const ChipGeneric = (props) => {
    const { id, label, checked, handleClick } = props;

    return (
        <ChipFake
            label={label}
            variant
            id={id}
            checked={checked}
            onClick={handleClick}
        />
    );
};

export default ChipGeneric;

const ChipFake = styled(Chip)`
    font-weight: 700;
    background-color: ${(p) => (p.checked ? '#FFF2F1' : '#D6D6D6')}!important;
    border: solid 1px ${(p) => (p.checked ? '#b31d15' : '#BDBDBD')};
    color: ${(p) => (p.checked ? '#b31d15' : '#222222')};
`;
