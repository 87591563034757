import React from 'react'
import styled from 'styled-components'
import ErrorResponse from '../../../../acropolisCommon/components/ErrorResponse'
import { Text } from '../../../../acropolisCommon/components/Texts'
import parse from 'html-react-parser';
import DefaultChip from '../../../../acropolisCommon/components/DegaultChip'
import useActivity from '../../../hooks/useActivity'

const ViewEvaluation = (props) => {

    const { resource } = props


    const [evaluation] = useActivity(resource?.id_evaluacion)

    console.log('evaluation', evaluation)

    if (!evaluation) {
        return <ErrorResponse />
    }

    const { name, descripcion, tipo, preguntas, cantidad_intentos } = evaluation

    return (
        <DisplayWrapper>
            <ContentChip>
                <DefaultChip padding="15px" radius="15px" textColor="#fff" color="#b31d15" title={tipo} />
            </ContentChip>
            <Text color="#616161" fontWeight="bold" fontSize="18px">{name?.toUpperCase()}</Text>
            <Description>
                <Text color="#616161" fontWeight="bold">Descripcion de actividad:</Text>
                <RenderHTML>{parse(descripcion)}</RenderHTML>
                <Text color="#616161" fontWeight="bold">Preguntas:</Text>
                <RenderHTML>{preguntas.map(item => {
                    return <Question>
                        <Text color="#333" fontWeight="bold">{parse(item.pregunta)}</Text>
                        <Options>{item.opciones.map(op => <Option>{op.es_opcion_correcta ? "🟢" : "🔴"} {parse(op.opcion)}</Option>)}</Options>
                    </Question>
                })}</RenderHTML>
            </Description>
        </DisplayWrapper>
    )
}

export default ViewEvaluation

const DisplayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const Description = styled.div`
    display: flex;
    flex-direction: column;
`

const RenderHTML = styled.div`
    margin: 16px 0;
`

const ContentChip = styled.div`
    display: flex;
    gap: 12px;
`

const Options = styled.div`
    margin: .8rem 0;
    padding-left: 1rem;

`

const Question = styled.div`
    background-color: #fafafa;
    border-radius: 20px;
    margin: .5rem 0;
    padding: 0.5rem;
`

const Option = styled.div`
    margin: 3px 0;
`