import React, { useState } from 'react';
import styled from 'styled-components';
import TabsComponent from '../../components/common/TabsComponent';
import Main from './components/Main';
import { titleHTML } from '../../helpers/title.helper';
import { useQueryParams } from '../../hooks/useQueryParams';

const Academic = () => {
    titleHTML("Información académica");

    const tabs = [
        { label: 'Documentación', id: 0 },
        { label: 'Estado financiero', id: 1 },
        { label: 'Workshop Internacional', id: 2 }
    ];

    const queryTab = useQueryParams();
    const initialTab = queryTab.get('tab') === 'finantial-state' ? 1 
    : queryTab.get('tab') === 'international-workshop' ? 2
    : 0;
    // STATE
    const [tab, setTab] = useState(initialTab);

    // FUNCTIONS
    function handleTab(e, value) {
        setTab(value);
    }

    // RETURN
    return (
        <AcademicContainer>
            <TabsWrapper>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            </TabsWrapper>
            <ContentWrapper>
                <Main
                    handleSetStateFinance={() => {}}
                    handleSetStateDocumentation={() => {}}
                    tab={tab}
                />
            </ContentWrapper>
        </AcademicContainer>
    );
};

export default Academic;

const AcademicContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const TabsWrapper = styled.div`
    width: calc(100% - 6rem - 70px);
    position: fixed;
    left: 70px;
    top: 70px;
    z-index: 3;
    border-top: 1px solid #f7f7f7;
    padding: 0 3rem;
    background-color: #ffffff;
    @media (max-width: 768px) {
        left: 0;
        width: calc(100% - 6rem);
    }
`;

const ContentWrapper = styled.div`
    width: 100%;
`;
