// REACT
import React, { useState, useEffect, useReducer } from 'react';

// ROUTER
import { useHistory, useParams } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';

// BASE64
import * as encode from 'nodejs-base64-encode';

// STYLES
import styled from 'styled-components';

// COMPONENTS
import { Card } from '@mui/material';
import ModalAssignment from '../../../components/common/modals/ModalAssignment';
import DialogGenericFG from '../../../components/common/DialogGenericFG';
import Text from '../../../components/common/Text';
import electiveImage from '../../../assets/media/aden/asignatura-electiva.png';
import ElectiveSubjectModal from '../../../pages/progress/components/ElectiveSubjectModal';
import electiveIcon from '../../../assets/media/svg/elective-icon.svg';
import ModalProblem from '../../../components/common/modals/ModalProblem';
import ModalPause from '../../../components/common/modals/ModalPause';
import ConfirmDialog from '../../ui/molecules/campus/DialogBlockDoc';
import NotificationChip from './chips/NotificationChip';
import StateSubjectContent from './wrappers/StateSubjectContent';
import StateSubjectsChip from './chips/StateChip';
import LazyImg from '../LazyImg';
import useSubjects from '../../../hooks/subject/useSubject';
import SubjectFooterButton from './buttons/SubjectFooterButton';
import reanudar_cursado from '../../../../src/assets/media/aden/reanudar_cursado.svg';
import curiosity_people from '../../../../src/assets/media/aden/curiosity-people.png';

const SubjectsCard = (props) => {
    const {
        data,
        setDataAssigment,
        dataAssigment,
        setIsChange,
        dataProgramName,
        isDiplomat,
        setIsFlag,
        isFlag,
        programState,
        handleEnrollment,
        titulationState,
        statusStudentBlock,
    } = props;

    // STATES Modales
    const [openModal, setOpenModal] = useState(false);
    const [openModalExpired, setOpenModalExpired] = useState(false);
    const [openModalBloqued, setOpenModalBloqued] = useState(false);
    const [openElectiveModal, setOpenElectiveModal] = useState(false);
    const [openModalToExpire, setOpenModalToExpire] = useState(false);

    const [open, setOpen] = useState(false);
    const [openModalPause, setOpenModalPause] = useState(false);
    const [openModalTitulation, setOpenModalTitulation] = useState(false);

    const [state, subjectState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        { subjectType: null, is_demo: false, is_elective: false }
    );

    // VALIDATIONS
    const STATE_CASES = ['DEMO', 'confirmado', 'egresado'];
    const isNotCurrent = data?.assignment_without_information;
    const isDiplomado = dataProgramName.toLowerCase().includes('diplomado');

    // ROUTER
    const history = useHistory();
    const { id } = useParams();

    // REDUX
    const repo_id = useSelector((state) => state.auth.user.repo_id);

    //EFFECTS
    useEffect(() => {
        subjectTypeController();
        // eslint-disable-next-line
    }, [data]);

    // STATIC-DATA
    const idProgram = encode.decode(id, 'base64');
    const hash = encode.encode(data.id + '', 'base64');

    //hook
    const {
        handleEnrollmentDates,
        enrollmentDates,
        progress,
        subjectCondition,
        imgGrey,
        PopulateNotificationChip,
    } = useSubjects(data, statusStudentBlock);
    //FUNCTIONS
    /* Establece el tipo de asignatura dependiendo el estado que se le haya asignado en el repositorio*/
    const subjectTypeController = () => {
        if (programState === 'DEMO')
            subjectState({
                subjectType: 'DEMO',
                is_demo: !data?.cursado_demo,
                is_elective: false,
            });

        if (data?.elective_subject)
            subjectState({
                subjectType: 'ELECTIVE',
                is_demo: false,
                is_elective: true,
            });

        if (!data?.elective_subject && programState !== 'DEMO')
            subjectState({
                subjectType: 'ACTIVE',
                is_demo: false,
                is_elective: false,
            });
        handleEnrollmentDates(data?.enrollment_dates);
    };

    //modales
    const handleClose = () => {
        setOpen(false);
    };

    const handleElectiveModal = () => {
        setOpenElectiveModal(!openElectiveModal);
    };

    const handleClick = () => {
        if (
            titulationState &&
            (data?.state === 'preinscripto' || data?.state === 'pendiente')
        ) {
            setOpenModalTitulation(true);
            return;
        }
        if (data.state === 'preinscripto' || data.state === 'en_pausa') {
            setOpenModalPause(true);
            return;
        }

        if (STATE_CASES.includes(data?.state)) {
            history.push(
                `../asignatura/${hash}?program=${id}&origin=${
                    isDiplomat ? 'diplomado' : 'plan'
                }`
            );
            return;
        }
        if (data.elective_subject) {
            setOpenModal(!openModal);
            return;
        }
        if (data.state === 'pendiente') {
            setOpenModal(!openModal);
            return;
        }
        //estado bloquedo pero progreso al 100%, ya sea con nota final o sin ella
        if (
            subjectCondition === 'Finalizado' ||
            subjectCondition === 'Egresado'
        ) {
            history.push(`../asignatura/${hash}?program=${id}&origin=plan`);
            return;
        }
    };

    const handleCloseModalPause = () => {
        setOpenModalPause(false);
    };

    const handleClickModal = () => {
        // history.push('/mi-academic-advisor');
        history.push('/support');
    };

    const ToggleButtons = ({ type, disabled }) => {
        const cases = {
            elective: (
                <SubjectFooterButton
                    variant="contained"
                    onClick={handleElectiveModal}
                    disabled={disabled}
                    title="Seleccionar"
                />
            ),
            demo: (
                <SubjectFooterButton
                    variant="contained"
                    disabled={
                        state.is_demo ||
                        (data.progress === 0 &&
                            statusStudentBlock?.studentStatus === 'Bloqueado')
                    }
                    onClick={state.is_demo ? () => {} : handleClick}
                    title={
                        state.is_demo
                            ? 'Bloqueado'
                            : data?.progress === 0
                            ? 'Comenzar'
                            : data?.progress === 100
                            ? 'Volver a ver'
                            : 'Continuar'
                    }
                />
            ),
            active: (
                <SubjectFooterButton
                    variant="contained"
                    disabled={
                        ((isNotCurrent ||
                            subjectCondition === 'Bloqueado' ||
                            subjectCondition === 'Vencido') &&
                            progress !== 100) ||
                        (progress === 0 && subjectCondition === 'Bloqueado')
                    }
                    onClick={isNotCurrent ? () => {} : handleClick}
                    title={
                        subjectCondition === 'Sin matricular'
                            ? 'Matricular'
                            : subjectCondition === 'Por iniciar'
                            ? 'Iniciar'
                            : subjectCondition === 'En curso'
                            ? 'Continuar'
                            : subjectCondition === 'Finalizado' ||
                              subjectCondition === 'Egresado'
                            ? 'Volver a ver'
                            : subjectCondition === 'En pausa'
                            ? 'Retomar cursado'
                            : subjectCondition === 'Próximo a vencer' &&
                              progress !== 0
                            ? 'Continuar'
                            : subjectCondition === 'Próximo a vencer' &&
                              progress === 0
                            ? 'Iniciar'
                            : `${subjectCondition}`
                    }
                />
            ),
        };

        return cases[type?.toLowerCase()] || cases['active'];
    };

    if (!!open) {
        return (
            <DialogGenericFG
                message={`No puede cursar más de dos materias en simultáneo`}
                open={open}
                title="Error al Inscribirse"
                handleClose={handleClose}
                customColor="#b31d15"
                titleColor="#b31d15"
                transitionType="fade"
            />
        );
    }

    return (
        <>
            <SubjectCardContainer
                notAvailable={data.assignment_without_information}
                title={
                    data.progress === 0 &&
                    statusStudentBlock?.studentStatus === 'Bloqueado'
                        ? `Lamentablemente, no puedes realizar esta actividad en este momento. El acceso está temporalmente bloqueado debido a ${statusStudentBlock?.blockDetail?.reason}`
                        : data.name
                }
                demoUnlocker={data?.cursado_demo}
                isDemo={state.subjectType}
            >
                {/* chip de estado si no es electiva */}
                {!data?.elective_subject && (
                    <CourseStateChip>
                        <StateSubjectsChip enrollmentState={subjectCondition} />
                    </CourseStateChip>
                )}

                {!data?.cursado_demo && programState === 'DEMO' && (
                    <DisablerCover />
                )}

                <LazyImg
                    src={
                        data?.elective_subject
                            ? electiveImage
                            : !data.assignment_without_information
                            ? data.img
                            : '../../assets/aden/NotImageFound.png'
                    }
                    width="100%"
                    height="130px"
                    disabledState={imgGrey}
                />
                {(data?.level ||
                    data?.rework_activities ||
                    data?.pending_correction) &&
                    PopulateNotificationChip?.length > 0 && (
                        <NotificationChip
                            content={PopulateNotificationChip(
                                data?.level,
                                data?.rework_activities,
                                data?.pending_correction
                            )}
                        />
                    )}

                {!data?.elective_subject ? (
                    <Body>
                        {!data.assignment_without_information && (
                            <ProgressWrapper>
                                <LinearProgressWrapper>
                                    <LinearProgress
                                        porcentaje={progress}
                                        disabledState={imgGrey}
                                    />
                                </LinearProgressWrapper>
                                <Percentage disabledState={imgGrey}>
                                    {progress}%
                                </Percentage>
                            </ProgressWrapper>
                        )}
                        <Plan
                            notAvailable={
                                data.assignment_without_information ||
                                subjectCondition === 'Bloqueado' ||
                                subjectCondition === 'Vencido' ||
                                subjectCondition === 'En pausa'
                            }
                        >
                            <h2>{data.name || 'sin nombre'}</h2>
                        </Plan>
                        <StateSubjectContent
                            enrollmentState={subjectCondition}
                            data={data}
                            setOpenModalExpired={setOpenModalExpired}
                            setOpenModalBloqued={setOpenModalBloqued}
                            setOpenModalToExpire={setOpenModalToExpire}
                            isDiplomado={isDiplomado}
                            enrollmentDates={enrollmentDates}
                        />
                    </Body>
                ) : (
                    // card electiva
                    <Body>
                        <ElectiveWrapper>
                            <Text
                                fontSize="1rem"
                                fontWeight="700"
                                color="#B31D15"
                            >
                                {data?.name}
                            </Text>

                            <ElectiveIndications>
                                <ElectiveIcon src={electiveIcon} />
                                <TextWrapper>
                                    {data?.subjects?.length ? (
                                        <>
                                            <Text
                                                fontSize="1rem"
                                                fontWeight="500"
                                                color="#B31D15"
                                            >
                                                Selección de electiva
                                            </Text>
                                            <Text
                                                fontSize="0.8rem"
                                                fontWeight="500"
                                                color="#616161"
                                            >
                                                Escoge la asignatura que deseas
                                                cursar.
                                            </Text>
                                        </>
                                    ) : (
                                        <Text
                                            fontSize="16px"
                                            fontWeight="500"
                                            color="#616161"
                                        >
                                            Esperando la confirmación de
                                            asignaturas vigentes
                                        </Text>
                                    )}
                                </TextWrapper>
                            </ElectiveIndications>
                        </ElectiveWrapper>
                    </Body>
                )}

                <Footer>
                    <ToggleButtons
                        type={state?.subjectType}
                        disabled={!data?.subjects?.length}
                    />
                </Footer>
            </SubjectCardContainer>

            {/* Modales ----------------------------------------------------------------  */}
            {openElectiveModal && (
                <ElectiveSubjectModal
                    open={openElectiveModal}
                    onClose={handleElectiveModal}
                    subjects={data?.subjects}
                    handleEnrollment={handleEnrollment}
                    repo_id={repo_id}
                />
            )}
            {openModal && (
                <ModalAssignment
                    repo_id={repo_id}
                    handleClick={handleClick}
                    data={data}
                    idProgram={idProgram}
                    setDataAssigment={setDataAssigment}
                    dataAssigment={dataAssigment}
                    setIsChange={setIsChange}
                    setIsFlag={setIsFlag}
                    isFlag={isFlag}
                    isDiplomat={isDiplomat}
                />
            )}
            {openModalPause && (
                <ModalPause
                    isOpen={openModalPause}
                    data={data}
                    idProgram={idProgram}
                    hash={hash}
                    id={id}
                    isDiplomat={isDiplomat}
                    handleCloseModal={handleCloseModalPause}
                    imageModal={reanudar_cursado}
                />
            )}
            {/* Vencido */}
            {openModalExpired && (
                <ModalProblem
                    isOpen={openModalExpired}
                    title={'Asignatura vencida'}
                    description={
                        '<p>Lo sentimos, ya expiró el plazo para cursar esta asignatura. Para poder continuar con tu cursado, deberás comunicarte con tu <strong>Academic Advisor</strong>.</p>'
                    }
                    handleCloseModal={() => setOpenModalExpired(false)}
                    handleClick={handleClickModal}
                    isExpired={true}
                    data={data}
                    imageModal={curiosity_people}
                />
            )}
            {/* proximo a vencer  */}
            {openModalToExpire && (
                <ModalProblem
                    isOpen={openModalToExpire}
                    title={'Asignatura próxima a expirar'}
                    description={
                        '<p><strong>Esta asignatura expirará en las próximas semanas.</strong></p><p>Te sugerimos avanzar con los contenidos pendientes para asegurar la continuidad de tu cursado sin interrupciones.</p>'
                    }
                    handleCloseModal={() => setOpenModalToExpire(false)}
                    handleClick={handleClickModal}
                    isToExpire={true}
                    data={data}
                    imageModal={curiosity_people}
                />
            )}
            {/* bloqueado */}
            {openModalBloqued && (
                <ModalProblem
                    isOpen={openModalBloqued}
                    title={'Asignatura bloqueada'}
                    description={
                        'Lo sentimos, esta asignatura se encuentra bloqueada.' /* por falta de pago.*/
                    }
                    handleCloseModal={() => setOpenModalBloqued(false)}
                    handleClick={handleClickModal}
                    imageModal={curiosity_people}
                />
            )}
            <ConfirmDialog
                handleCloseBlock={() => setOpenModalTitulation(false)}
                openBlock={openModalTitulation}
            />
        </>
    );
};

export default SubjectsCard;

const SubjectCardContainer = styled(Card)`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 350px !important;
    border-radius: 20px;
    background-color: #ffffff;
    transition: 0.3s all ease;
    cursor: ${(p) => (p.notAvailable ? 'default' : 'pointer')};
    cursor: ${(p) =>
        p.isDemo === 'DEMO'
            ? p.demoUnlocker
                ? 'pointer'
                : 'default'
            : 'pointer'};
    box-shadow: 0px 3px 6px 0px #0000003b, 0px 3px 6px 0px #00000029;
    :hover {
        box-shadow: ${(p) =>
            p.isDemo === 'DEMO'
                ? p.demoUnlocker
                    ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    : 'none'
                : 'rgba(0, 0, 0, 0.24) 0px 3px 8px'};
        :before {
            opacity: ${(p) =>
                p.isDemo === 'DEMO' ? (p.demoUnlocker ? '1' : 'none') : '1'};
        }
    }
`;

const Body = styled.div`
    display: flex;
    background-color: white;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    padding: 0 1rem 1rem 1rem;
    padding-top: 1rem;
    z-index: 5;
    max-width: 100%;
    height: 250px !important;
    overflow: hidden;
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    height: 100px !important;
    width: 100%;
    background-color: #f1f1f1;
    padding: 0.3rem;
    border-radius: 0px 0px 20px 20px;
`;

const Plan = styled.div`
    display: flex;
    height: 250px;
    align-items: center;
    h2 {
        font-size: 1rem;
        font-weight: 900;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: normal;
        line-height: 120%;
        letter-spacing: 0.3px;
        color: ${(p) => (p.notAvailable ? '#a8a8a8' : '#222222')};
        @media screen and (max-width: 768px) {
            font-size: 1.2rem;
        }
    }
`;

const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
`;

const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 7px;
    background-color: #eaeaea;
`;

const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(props) => `${props.porcentaje}%`};
    height: 7px;
    background-color: ${(props) =>
        props.disabledState ? '#A8A8A8' : '#3bd39c'};
`;

const Percentage = styled.span`
    font-size: 0.9rem;
    color: ${(props) => (props.disabledState ? '#A8A8A8' : '#222222')};
    flex-wrap: wrap;
`;

const DisablerCover = styled.div`
    position: absolute;
    height: 100%;
    min-width: 100%;
    z-index: 6;
    background-color: #eaeaea;
    opacity: 0.45;
`;

const CourseStateChip = styled.span`
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: 0.4rem 0.7rem;
    z-index: 7;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06px;
    border-radius: 8px;
`;

//ELECTIVAS
const ElectiveWrapper = styled.div`
    height: 100%;
    width: calc(100 - 2rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
`;

const ElectiveIndications = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

const ElectiveIcon = styled.img`
    width: 24px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
`;
