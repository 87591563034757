import React from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";
import Commentary from "../../../../../../components/ui/molecules/course/presentation/Commentary";

const TextQuillInput = (props) => {
  // https://www.npmjs.com/package/react-quill
  const { setCommentary, text, handleChange } = props;

  return (
    <Container>
      <ReactQuillContainer
        style={{ height: "400px", width: "110%" }}
        value={text}
        onChange={handleChange}
        modules={{
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ align: [] }],
              ["link" /*, 'image'*/],
              [{ color: [] }],
            ],
          },
        }}
      />
      <Commentary setCommentary={setCommentary} />
    </Container>
  );
};

export default TextQuillInput;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;

  .ql-toolbar {
    border: none;
    /* box-shadow: 1px 0px 39px -6px rgba(174, 174, 174, 0.42); */
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: 1px solid #A8A8A8;
  }
  .ql-container {
    border: none;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top: 1px solid #A8A8A8;
  }

  @media (max-width: 768px) {
    height: calc(100% - 8rem);
    gap: 5rem;
  }
`;

const ReactQuillContainer = styled(ReactQuill)`
  border-radius: 20px;
  border: 1px solid #A8A8A8;
`