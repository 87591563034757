import { Icon } from '@iconify/react/dist/iconify.js';
import styled from 'styled-components';

const ExtraInfo = (props) => {

    const { blocked, disableLast, setModalIsOpen, hasElectiveSubject } = props;

    return (
        <ExtraInfoContainer>
            {
                blocked &&
                <TextBlocked onClick={() => setModalIsOpen(true)}>
                    <Icon icon="solar:lock-outline" width="18px" height="18px" />
                    <p>¿Por qué está bloqueado?</p>
                </TextBlocked>
            }
            {
                disableLast &&
                <InfoDisabled>
                    <Icon
                        icon="octicon:info-24"
                        width="24px"
                        height="24px"
                    />
                    <label>
                        Para habilitar este bloque{' '}
                        <b>
                            {
                                hasElectiveSubject
                                    ? "deberás realizar la encuesta de satisfacción y finalizar el cursado de la electiva principal."
                                    : "deberás finalizar los bloques anteriores y responder la encuesta de satisfacción."
                            }
                        </b>
                    </label>
                </InfoDisabled>
            }
        </ExtraInfoContainer>
    )
}

export default ExtraInfo;

const ExtraInfoContainer = styled.div`
    position: absolute;
    bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const TextBlocked = styled.button`
    z-index: 100;
    color: #b31d15;
    cursor: pointer;
    opacity: 1;
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        border-bottom: 1px solid #b31d15;
        font-size: 16px;
    }
`

const InfoDisabled = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    opacity: 1 !important;

    svg {
        color: #c29f43;
    }

    label {
        color: #616161;
        font-size: 14px;
        font-weight: 400;

        b {
            font-weight: 700;
        }
    }

    @media (width < 768px) {
        bottom: -2.5rem;
    }
`;