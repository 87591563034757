import React from 'react';
import styled from 'styled-components';

import { SearchRounded } from '@mui/icons-material';

const NoResultsFound = (props) => {
    const {
        message = '¡Ups! No encontramos lo que buscabas, intenta una búsqueda diferente.'
    } = props;

    return (
        <NoResultsFoundContainer>
            <SearchRounded fontSize="large" />
            <Text>{message}</Text>
        </NoResultsFoundContainer>
    );
};

export default NoResultsFound;

const NoResultsFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    padding: 2rem;
    text-align: center;
    background-color: #ffffff;
    color: #bfbfbf;
    border-radius: 1rem;
`;

const Text = styled.span`
    font-size: 1.5rem;
    font-weight: 700;
`;
