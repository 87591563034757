import React from "react";
import styled from "styled-components";
import SimpleLoading from "../SimpleLoading";
import { useHistory } from "react-router-dom";
import SocialMediaToCard from "./SocialMediaToCard";
import { useSelector } from "react-redux";

const CardWhithSocialMedia = (props) => {
    const { values, type = "students", quantity, activeCard } = props;
    const history = useHistory();
    const { activeRol } = useSelector((state) => state.auth);

    const docente_to360Student = async () => {
        history.push(`/profesor/alumno/${values?.partner_id || values?.id}`);
    };

    const academico_to360Student = async () => {
        if (activeCard === 0) {
            history.push(
                `/academico/alumno/${values?.partner_id}?inscription=${
                    values?.inscripcion_id || 0
                }&sis=${values?.sis_id || 0}`
            );
        } else {
            history.push(
                `/academico/alumno-repo/${values?.crm_partner_id}?repo=${values?.repo_id}`
            );
        }
    };
    

    //RETURNS
    if (!values) {
        <CardWrapper>
            <SimpleLoading />
        </CardWrapper>;
    }
    return (
        <CardWrapper
            activeCard={activeCard}
            academicPhase={values?.fase_academica}
            quantity={quantity}
        >
            <WrapperBody
                onClick={
                    activeRol === "docente"
                        ? docente_to360Student
                        : activeRol === "academico" && academico_to360Student
                }
                activeCard={activeCard}
            >
                <WrapperPhoto>
                    <img src={values.image} />
                </WrapperPhoto>
                <WrapperInfo>
                    {/* {type === "students" && <Tag>{values.profession[1]}</Tag>}
                    {type === "teachers" && (
                        <Tag>{values.assignment.assignment_name}</Tag>
                    )} */}
                    <h2>{values.name || "Desconocido"}</h2>

                    {type === "students" && (
                        <WrapperMoreInfo>
                            <span>{values.city}</span>
                            <span>
                                Legajo: {values.sis_id || values.partner_id}
                            </span>
                        </WrapperMoreInfo>
                    )}
                </WrapperInfo>
            </WrapperBody>
            {activeCard == 0 && (
                <WrapperProgram>
                    <p>{values?.programa?.nombre_programa}</p>
                </WrapperProgram>
            )}
            <WrapperSocial activeCard={activeCard}>
                <SocialMediaToCard value={values} />
            </WrapperSocial>
        </CardWrapper>
    );
};

export default CardWhithSocialMedia;

const CardWrapper = styled.div`
    width: ${(p) => (p.quantity > 2 ? "100%" : "320px")};
    ${(p) =>
        p.activeCard === 0
            ? `
            height: 246px;
            `
            : `
            height: 216px;
    `};
    display: flex;
    border: solid 1px #f1f1f1;
    flex-direction: column;
    background-color: #fff;
    border-radius: 20px;
    justify-content: space-between;
    /* overflow: hidden; */
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
        0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    position: relative;

    ${(p) =>
        !!p.academicPhase &&
        `
        ::before {
        position: absolute;
        
            content: '${p.academicPhase}';
        
        padding: 0.2rem;
        width: fit-content;
        top: 5px;
        right: 10px;
        font-size: .8rem;
        font-weight: bold;
        border-radius: 5px;
        background-color: #FDF2F1;
        color: #b31d15;
    }    
        `}
`;

const WrapperBody = styled.div`
    /* border: solid 1px red; */
    ${(p) =>
        p.activeCard === 0
            ? `
            height:  calc(136px - 1rem);
            `
            : `
            height:  calc(166px - 1rem);
    `};
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.5rem 1rem;
    width: calc(100% - 2rem);
    position: relative;
    z-index: 3;
    cursor: pointer;
`;

const WrapperPhoto = styled.div`
    width: 110px;
    height: 110px;
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: center;

    img {
        border-radius: 50%;
        width: 100%;
        object-fit: cover;
        border: solid 2px #f1f1f1;
    }
`;

const WrapperInfo = styled.div`
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: flex-start;

    h2 {
        font-size: 1.05rem;
        color: #222;
    }
`;

// const Tag = styled.p`
//     color: #a9a9a9;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     // Addition lines for 2 line or multiline ellipsis
//     display: -webkit-box !important;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     white-space: normal;
//     font-size: 0.8rem;
// `;

const WrapperMoreInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    justify-content: flex-start;

    span {
        font-weight: bold;
        color: #616161;
        font-size: 0.8rem;
    }
`;

const WrapperSocial = styled.div`
    height: 50px;
    width: calc(100% - 2rem);
    display: flex;
    justify-content: space-between;
    padding: 4px 1rem;
    background-color: #b31d1509;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`;

const WrapperProgram = styled.div`
    width: calc(100% - 2rem);
    padding: 0.5rem 1rem;

    p {
        color: #696969;
        font-size: 0.8rem;
    }
`;
