import { useState, useEffect } from 'react';
import { getTaskPending, getTaskPendingByCourse } from '../../redux/api/teacher';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../../utils/snackStyles';

const useTaskTeacher = (type, id_course = 0, id_teacher = 0) => {

    const [openSnackbar] = useSnackbar(optionsStyle);

    const [taskTeacher, setTaskTeacher] = useState(null);

    useEffect(() => {
        !taskTeacher && type === "course" && getTaskByCourse(id_course)
        !taskTeacher && type === "all" && getTaskByTeacher(id_teacher)
    }, [taskTeacher]);


    const getTaskByCourse = async id => {
        const request = await getTaskPendingByCourse(id)
        if (request.error) {
            setTaskTeacher(false)
            // openSnackbar('¡No se pudieron cargar las tareas pendientes!');
        }
        else {
            setTaskTeacher(request)
        }
    }

    const getTaskByTeacher = async id => {
        const request = await getTaskPending(id)
        if (request.error) {
            openSnackbar('¡No se pudieron cargar las tareas pendientes!');
            setTaskTeacher(false)
        }
        else {
            setTaskTeacher(request)
        }
    }



    return {
        taskTeacher,
    };
};

export default useTaskTeacher;
