import { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import propTypes from 'prop-types';

const LazyImg = (props) => {
    const {
        src = '',
        width = '100px',
        height = '100px',
        borderRadius = '0',
        backgroundColor = '#f1f1f1',
        position = 'relative',
        disabledState
    } = props;

    const imageRef = useRef();
    const parentRef = useRef();

    useEffect(() => {
        const isHidden = parentRef?.current?.classList?.contains('hide');

        if (!isHidden) {
            imageRef?.current?.addEventListener('load', loaded);
            hideParent();
        }

        return () => {
            imageRef?.current?.removeEventListener('load', loaded);
        };
    }, [imageRef?.current, parentRef?.current]);

    function loaded() {
        imageRef?.current?.classList?.add('loaded');
    }

    function hideParent() {
        parentRef?.current?.classList?.add('hide');
    }

    return (
        <ImageWrapper
            borderRadius={borderRadius}
            height={height}
            width={width}
            backgroundColor={backgroundColor}
            ref={parentRef}
            position={position}
        >
            <Image
                src={src}
                width={width}
                height={height}
                borderRadius={borderRadius}
                ref={imageRef}
                loaded={imageRef?.current?.complete}
                loading="lazy"
                disabledState={disabledState}
            />
        </ImageWrapper>
    );
};

LazyImg.propTypes = {
    src: propTypes.string,
    width: propTypes.string,
    height: propTypes.string,
    borderRadius: propTypes.string,
    backgroundColor: propTypes.string,
};

export default LazyImg;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
`;

const pulse = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`;

const ImageWrapper = styled.div`
    position: ${(p) => p.position};
    border-radius: ${(p) => p.borderRadius};
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    background-color: ${(p) => p.backgroundColor};
    animation: ${pulse} 1.5s infinite;

    &.hide {
        animation: none;
    }
`;

const Image = styled.img`
    height: ${(p) => p.height};
    width: ${(p) => p.width};
    object-fit: cover;
    object-position: center;
    border-radius: ${(p) => p.borderRadius};
    filter: ${({ disabledState }) => disabledState ? 'grayscale(100%)' : 'none'};

    &.loaded {
        animation: ${fadeIn} 0.5s ease-in;
    }
`;
