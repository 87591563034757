import styled from "styled-components";

const CommonChip = (props) => {

    const {
        label,
        Icon,
        variant, // outlined, solid, status
        strongColor,
        lightColor
    } = props;
    return (
        <Button
            variant={variant}
            hasIcon={!!Icon}
            strongColor={strongColor}
            lightColor={lightColor}
        >
            {!!Icon && <Icon />}
            {label}
        </Button>
    )
}

export default CommonChip;

const Button = styled.span`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 100px;
    gap: ${({ hasIcon }) => hasIcon && "8px"};
    padding: ${({ hasIcon }) => hasIcon ? "8px 16px 8px 10px" : "8px 16px"};
    background-color: ${({ variant, lightColor, strongColor }) => variant === "solid" ? strongColor :  lightColor};
    color: ${({ variant, lightColor, strongColor }) => variant === "solid" ? lightColor : strongColor};
    box-shadow: ${({ variant }) => variant === "outlined" && "0px 8px 24px -4px #18274B14, 0px 6px 12px -6px #18274B1F"};
    border: ${({ variant, strongColor }) => variant === "subjectState" ? `1px solid ${strongColor}` : 'none'};

`