import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";

// Components
import SimpleLoading from "../../../../../components/common/SimpleLoading";
import MultipleChoiceCardHeader from "./header/MultipleChoiceCardHeader";
import CommonButton from "../../../../../components/common/CommonButton";
import { Span, Text } from "../../../../../components/common/Texts";

// Redux
import { getReviewById } from "../../../../../redux/api/courses";

const PageReviewAnswers = (props) => {

    const { submission, handleGoBack } = props;

    const [info, setInfo] = useState(null);

    useEffect(() => {
        !info && getReview(submission);
        //eslint-disable-next-line
    }, [submission]);

    const sanitizerHtml = (html) => {
        const string = 'src="/';
        const regex = new RegExp(string, "g");
        return html.replace(regex, 'src="');
    };

    const getReview = async (sub) => {
        const request = await getReviewById(sub.id);
        if (request) {
            setInfo(request);
        }
    };

    if (!info) {
        return (
            <LoadingWrapper>
                <SimpleLoading />
            </LoadingWrapper>
        );
    }

    return (
        <Wrapper>
            <Container>
                <ButtonContainer>
                    <CommonButton
                        label="Volver"
                        variant="filled"
                        Icon={() => <Icon icon="iconoir:arrow-left" width="24px" height="18px" />}
                        onClick={handleGoBack}
                    />
                </ButtonContainer>
                <TitleContainer>
                    <Text fontSize="20px" fontWeight="bold" color="#222" textAlign="start">
                        Revisión de mis respuestas sobre este examen:
                    </Text>
                    <Text fontSize="16px" color="#616161" textAlign="start">
                        {info?.activity?.name}
                    </Text>
                </TitleContainer>
                <Information>
                    <MultipleChoiceCardHeader
                        Icon={<Icon icon="ph:calendar-x-light" width="20px" height="1.2em" />}
                        title="Fecha"
                        subtitle={new Date(
                            info?.create_date
                        )?.toLocaleDateString()}
                    />
                    <MultipleChoiceCardHeader
                        Icon={<Icon icon="circum:circle-list" width="20px" height="1.2em" />}
                        title="Puntaje"
                        subtitle={info?.grade + "/100"}
                    />
                </Information>
                <Col>
                    {info?.answers?.map((item, index) => {
                        return (
                            <Contain isCorrect={item?.correct}>
                                <Chip isCorrect={item?.correct}>
                                    {item?.correct ? (
                                        <Icon icon="streamline:check" width="15px" height="15px" />
                                    ) : (
                                        <Icon icon="uiw:close" width="15px" height="15px" />
                                    )}
                                    <label>
                                        {item?.correct ? "Correcta" : "Incorrecta"}
                                    </label>
                                </Chip>
                                <Question>
                                    <Span color="#222">{index + 1}.</Span>{" "}
                                    {parse(sanitizerHtml(item?.question))}
                                </Question>
                                <Text fontSize="16px" fontWeight="700" color="#616161">
                                    {item?.answer?.length > 1
                                        ? "Mis respuestas:"
                                        : "Mi respuesta:"}
                                </Text>
                                {item?.answer?.map((answer) => (
                                    <Answer key={answer?.election}>
                                        <IsCorrect
                                            isCorrect={answer?.correct_election}
                                        >
                                            {answer?.correct_election ? (
                                                <Icon icon="ic:baseline-check-circle" width="24px" height="24px" />
                                            ) : (
                                                <Icon icon="ion:close-circle-sharp" width="24px" height="24px" />
                                            )}
                                        </IsCorrect>
                                        {answer?.election}
                                    </Answer>
                                ))}
                            </Contain>
                        );
                    })}
                </Col>
            </Container>
        </Wrapper>
    );
};

export default PageReviewAnswers;

const Wrapper = styled.div`
    width: calc(100% - 4rem);
    margin-bottom: 2rem;
    padding: 2rem;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    border: 1px solid #A8A8A8;
    border-radius: 30px;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 15px;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
`

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    padding-left: 1rem;
`

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Contain = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    position: relative;
    padding: 15px;
    position: relative;
    overflow: hidden;
    border: 1px solid #A8A8A8;
`;

const Chip = styled.span`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 8px 16px 8px 8px;
    color: ${(p) => (p.isCorrect ? "#28A986" : "#E94C44")};
    border: 1px solid ${(p) => (p.isCorrect ? "#28A986" : "#E94C44")};
    border-radius: 100px;
    background-color: ${(p) => (p.isCorrect ? "#F7FDFB" : "#FFF5F7")};

    label {
        font-size: 12px;
        font-weight: 700;
    }
`

const IsCorrect = styled.div`
    svg {
        color: ${(p) => (p.isCorrect ? "#35D0A5" : "#F8423F")};
        font-size: 1.8rem;
    }
`;
const Question = styled.div`
    display: flex;
    gap: 8px;
    color: #222;
`;

const Information = styled.div`
    width: 100%;
    margin: 24px auto;
    display: flex;
    justify-content: start;
    gap: 40px;
    padding-left: 16px;
`;

const Answer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
