import axios from "axios";

export const sendPresentationFormData = async (presentation) => {
    // ACÁ CAMBIE LA URL
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/test_presentacion2`;

    try {
        const response = await axios.post(URL, presentation);
        return response.data;
    } catch (error) {
        return { error };
    }
};

export const updatePresentation = async (presentation) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/update_presentation`;

    try {
        const response = await axios.patch(URL, presentation);
        return response.data;
    } catch (error) {
        return { error };
    }
};