import { Icon } from "@iconify/react";
import styled from "styled-components";

const Pagination = (props) => {

    const { page, setPage, pageMax } = props;

    const goFirstPage = () => {
        setPage(1)
    }

    const goLastPage = () => {
        setPage(pageMax)
    }

    const goPageBack = () => {
        if (page !== 1) {
            setPage(page - 1);
        }
    }

    const goPageForward = () => {
        if (page !== pageMax) {
            setPage(page + 1)
        }
    }

    return (
        <Container>
            <button onClick={goFirstPage}>
                <Icon icon="mingcute:arrow-to-left-line" width="1.6em" height="1.6em" />
            </button>
            <button onClick={goPageBack}>
                <Icon icon="mingcute:arrow-left-line" width="1.4em" height="1.4em" />
            </button>
            <span>
                Página <b>{page}</b> de {pageMax}
            </span>
            <button onClick={goPageForward}>
                <Icon icon="mingcute:arrow-right-line" width="1.4em" height="1.4em" />
            </button>
            <button onClick={goLastPage}>
                <Icon icon="mingcute:arrow-to-right-line" width="1.6em" height="1.6em" />
            </button>
        </Container>
    )
}

export default Pagination;

const Container = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    button {
        cursor: pointer;
        color: #b31d15;
    }

    span {
        font-size: 16px;
    }

    b {
        color: #b31d15;
    }
`