/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { List } from "@mui/material";

import { useParams } from "react-router-dom";
import ModalTutor from "./ModalTutor";
// import { userAction } from "../helpers/userActions.helper";
import { userAction } from "../../../helpers/userActions.helper";
import { useSelector } from "react-redux";
import { postProgressOA } from "../../../redux/api/courses";
import { useQueryParams } from "../../acropolisCommon/hooks/useQueryParams";
import * as encode from "nodejs-base64-encode";
import AsideProgress from "./AsideProgress";
import MainComments from "./commentsCourse/MainComments";
import MainNotes from "./notesCourse/MainNotes";
import BlockTabCourse from "../../../components/common/BlockTabCourse";
import { handleMouseScrollX } from "../../../helpers/eventMouse.helper";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import AsideListItem from "../../../pages/course/components/aside/asideList/AsideListItem";
import Tabs from "../../../components/common/Tabs";

const AsideCourse = (props) => {
  const {
    workshop,
    unidades,
    currentMedia,
    changeCurrentMedia,
    currentUnit,
    objId,
    certificado: certificate,
    unitsEnabled,
    changeStatus,
    setChangeStatus,
    setViewDelivery,
    certificateEnabled,
    menuItems,
    currentSecondVideo,
    handleIsChangeMinute,
    handleCurrentMinute,
    notesContent,
    showAside,
    tabClose,
    setTabClose,
  } = props;

  const { valuesNotes, errorGetNotes, LoadingGetNotes, getNotes } =
    notesContent;

  const [officeHour, setOfficeHour] = useState(false);
  const [avaliableCertificate, setAvaliableCertificate] = useState(false);
  const [tab, setTab] = useState(0);

  const params = useQueryParams();

  const { activeRol } = useSelector((state) => state.auth);
  const { repo_id } = useSelector((state) => state.auth.user) || {};

  const repoId = params.get("repo_id");

  let { id } = useParams();

  const idAssigmentHash = params.get("id");
  const idOAAdenupa = params.get("id_oa");

  const idAssignment = idAssigmentHash
    ? encode.decode(idAssigmentHash + "", "base64")
    : false;

  const idOA = encode.decode(id + "", "base64");
  const AdenupaOAId = encode.decode(idOAAdenupa + "", "base64");

  useEffect(() => {
    getOfficeHour(unidades);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOfficeHour = (unidades) => {
    const officeHour = unidades.filter(
      (unidad) => unidad.nombre == "Office Hour"
    );
    setOfficeHour(officeHour);
  };

  const handleSetProgress = async (progress) => {
    const body = {
      partner_id: repo_id ?? parseInt(repoId),
      oa_id: !idOA ? parseInt(idOA) : parseInt(AdenupaOAId),
      assignment_id: !!idAssignment ? parseInt(idAssignment) : false,
      progress: progress,
    };
    await postProgressOA(body);
  };

  const handleProgress = (progress) => {
    if (progress === 100) {
      setAvaliableCertificate(true);
      userAction({
        object_id: workshop.id,
        object: workshop.nombre_publicacion,
        name: workshop.nombre_publicacion,
        activity: !!workshop.es_workshop
          ? "Finalizar Workshop"
          : "Finalizar OA",
        medio: !!workshop.es_workshop ? "Finalizar Workshop" : "Finalizar OA",
      });
    }
    handleSetProgress(progress);
  };

  const tabs = [
    { label: "Módulos", id: 0 },
    { label: "Comentarios", id: 1 },
    { label: "Apuntes", id: 2 },
  ];

  function handleTab(value) {
    setTab(value);
  }

  const handleChip = (id) => {
    setTab(id);
  };

  /** RETURN ----------------------------------------------------------------------- */
  if (!showAside) {
    return (
      <FakeDrawer activeRol={activeRol} showAside={showAside}>
        <TabsCloseWrapper>
          {tabs.map((item, index) => {
            return (
              <TabClose isOpen={tabClose === item.id}>
                <HeadTab
                  onClick={() =>
                    tabClose === item.id ? setTabClose(4) : setTabClose(item.id)
                  }
                >
                  {item.label}
                  {tabClose !== item.id ? (
                    <ExpandMoreRoundedIcon fontSize="large" />
                  ) : (
                    <ExpandLessRoundedIcon fontSize="large" />
                  )}
                </HeadTab>
                {tabClose === 0 && tabClose === item.id && (
                  <>
                    {unidades.map((unidad) => {
                      const isEnabled = !!unitsEnabled.find(
                        (item) => item === unidad.id
                      );
                      return (
                        <Unity isEnabled={isEnabled}>
                          <UnityIndex isEnabled={isEnabled}>
                            {unidad.id}
                            {unidad.id !== 1 && <Line isEnabled={isEnabled} />}
                          </UnityIndex>
                          <UnityName isEnabled={isEnabled}>
                            {unidad.nombre}
                          </UnityName>
                        </Unity>
                      );
                    })}
                  </>
                )}
                {tabClose === 1 && tabClose === item.id && (
                  <>
                    {
                      <>
                        <SubLabel>Últimos comentarios</SubLabel>
                        <MainComments
                          currentMedia={currentMedia}
                          showAside={!showAside}
                        />
                      </>
                    }
                  </>
                )}
                {tabClose === 2 && tabClose === item.id && (
                  <>
                    {
                      <>
                        <SubLabel>Últimos apuntes</SubLabel>
                        <MainNotes
                          currentMedia={currentMedia}
                          currentSecondVideo={currentSecondVideo}
                          handleCurrentMinute={handleCurrentMinute}
                          handleIsChangeMinute={handleIsChangeMinute}
                          valuesNotes={valuesNotes}
                          errorGetNotes={errorGetNotes}
                          LoadingGetNotes={LoadingGetNotes}
                          getNotes={getNotes}
                          showAside={!showAside}
                        />
                      </>
                    }
                  </>
                )}
              </TabClose>
            );
          })}
        </TabsCloseWrapper>
        {/* <CloseMobile>
                <FakeIconButton onClick={toggleAside}>
                    <MenuRounded htmlColor="#ffffff" />
                </FakeIconButton>
            </CloseMobile> */}
      </FakeDrawer>
    );
  }
  return (
    <FakeDrawer activeRol={activeRol === "ondemand"} showAside={showAside}>
      <SliderTabs id="tabs" onClick={() => handleMouseScrollX("tabs")}>
        {tabs.map((element, index) => (
          <ChipTab
            key={index}
            onClick={() => handleChip(element.id)}
            currentId={element.id}
            tabSelected={tab}
          >
            {element.label} !
          </ChipTab>
        ))}
      </SliderTabs>
      <TabsWrapper>
        <Tabs
          tabValue={tab}
          tabOptions={tabs}
          handleTabValue={handleTab}
        />
      </TabsWrapper>
      {/* <CloseMobile>
                <FakeIconButton onClick={toggleAside}>
                    <MenuRounded htmlColor="#ffffff" />
                </FakeIconButton>
            </CloseMobile> */}
      {tab === 0 && (
        <CustomList activeRol={activeRol === "ondemand"}>
          {unidades.map((unidad, index) => {
            if (unidad.nombre !== "Office Hour") {
              return (
                <AsideListItem
                  currentUnit={currentUnit}
                  id={index}
                  setViewDelivery={setViewDelivery}
                  changeStatus={changeStatus}
                  setChangeStatus={setChangeStatus}
                  unidad={unidad}
                  currentMedia={currentMedia}
                  changeCurrentMedia={changeCurrentMedia}
                  index={index + 1}
                  key={index}
                  isEnabled={!!unitsEnabled.find((item) => item === unidad.id)}
                />
              );
            }
            return false;
          })}
          <AsideProgress
            handleProgress={handleProgress}
            currentMedia={currentMedia}
            workshop={workshop}
            userAction={userAction}
            activeRol={activeRol}
            certificate={certificate}
            objId={objId}
            certificateEnabled={certificateEnabled}
            avaliableCertificate={avaliableCertificate}
            menuItems={menuItems}
          />
        </CustomList>
      )}
      {tab === 1 && (
        <>
          {currentMedia?.formato === "audiovisual" ? (
            <MainComments currentMedia={currentMedia} />
          ) : (
            <BlockTabCourse
              msg="Lamentablemente, no es posible dejar comentarios 
                        en esta sección."
            />
          )}
        </>
      )}
      {tab === 2 && (
        <>
          {currentMedia?.formato === "audiovisual" ? (
            <MainNotes
              currentMedia={currentMedia}
              currentSecondVideo={currentSecondVideo}
              handleCurrentMinute={handleCurrentMinute}
              handleIsChangeMinute={handleIsChangeMinute}
              valuesNotes={valuesNotes}
              errorGetNotes={errorGetNotes}
              LoadingGetNotes={LoadingGetNotes}
              getNotes={getNotes}
            />
          ) : (
            <BlockTabCourse
              msg="Lamentablemente, no es posible agregar apuntes 
                            en esta sección."
            />
          )}
        </>
      )}
      {officeHour && officeHour.length > 0 && (
        <GridModal>
          <ModalTutor officeHour={officeHour} />
        </GridModal>
      )}
    </FakeDrawer>
  );
};

export default AsideCourse;

const FakeDrawer = styled.div`
  display: flex;
  flex-direction: column;
  position: ${(props) => (props.activeRol ? "" : "fixed")};
  top: 70px;
  right: 0;
  bottom: 0;
  width: ${(props) =>
    props.activeRol ? (!props.showAside ? "200px" : "100%") : "25%"};
  min-height: 500px;
  height: ${(props) => (props.activeRol ? "100vh" : "calc(100vh - 70px)")};
  background-color: ${(props) => (props.activeRol ? "transparent" : "#fff")};
  z-index: 1;

  @media (max-width: 1400px) {
    width: ${(props) => (props.activeRol ? "100%" : "30%")};
  }

  @media (max-width: 900px) {
    width: calc(100% - 4rem);
    position: relative;
    padding: 0 2rem;
    top: 0px;
  }

  @media (max-width: 768px) {
    width: calc(100% - 1.6rem);
    padding: 0 0.8rem;
  }
`;

// const CloseMobile = styled.div`
//     width: 100%;
//     background-color: #b41d16;
//     display: none;
//     align-items: center;
//     justify-content: center;
//     padding: 1rem 2rem;

//     @media (max-width: 768px) {
//         display: flex;
//     }
// `;

// const FakeIconButton = styled(IconButton)`
//     background-color: ${(p) => p.color};
//     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
// `;

const GridModal = styled.div`
  width: 100%;
  display: flex;
  align-self: flex-end;
  flex-grow: 1;
  box-sizing: border-box;
  padding: ${(props) => (props.activeRol === "ondemand" ? ".5rem" : "1.5rem")};
  justify-content: center;
  @media (max-width: 768px) {
    flex-grow: 0;
    align-self: flex-start;
  }
`;

const CustomList = styled(List)`
  height: ${(props) => (props.activeRol === "ondemand" ? "100%" : "70vh")};
  overflow-y: scroll;
  scrollbar-color: transparent transparent;

  @media (max-width: 768px) {
    height: 60vh;
  }
  @media (max-width: 361px) {
    height: 48vh;
  }
  @media (max-width: 320px) {
    height: 35vh;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
  margin-top: 2rem;

  @media (max-width: 900px) {
    display: none;
  }
`;

const TabsCloseWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 24px;
  margin-top: 2rem;
`;

const TabClose = styled.div`
  position: relative;
  width: 200px;
  height: ${(props) => (props.isOpen ? "fit-content" : "72px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  background-color: #f9f9f9;
  box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
  color: var(--primary-one);
  font-weight: 700;
  padding-bottom: ${(props) => props.isOpen && "24px"};
`;

const HeadTab = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 72px;
`;

const Unity = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  :hover {
    div {
      background-color: ${(props) =>
    props.isEnabled ? "var(--primary-one)" : "var(--secondary-four)"};
    }
    label {
      color: ${(props) =>
    props.isEnabled ? "var(--primary-one)" : "var(--secondary-four)"};
      font-weight: 600;
    }
  }
`;

const UnityIndex = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: ${(props) => (props.isEnabled ? "#CD2118" : "#C4C4C4")};
  color: var(--secondary-one);
`;

const UnityName = styled.label`
  width: 100px;
  font-weight: 500;
  color: ${(props) => (props.isEnabled ? "#CD2118" : "#C4C4C4")};
`;

const Line = styled.div`
  position: absolute;
  top: -24px;
  width: 2px;
  height: 25px;
  background-color: ${(props) => (props.isEnabled ? "#CD2118" : "#C4C4C4")};
  :hover {
    background-color: ${(props) =>
    props.isEnabled ? "var(--primary-one)" : "var(--secondary-four)"};
  }
`;

const SubLabel = styled.label`
  color: #222222;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  top: 60px;
  left: 1rem;
`;

const SliderTabs = styled.div`
  display: none;
  gap: 1rem;
  align-items: center;
  user-select: none;
  overflow-x: auto;
  padding: 0.1rem;

  :active {
    cursor: pointer;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 900px) {
    display: flex;
  }
`;

const ChipTab = styled.div`
  width: 100%;
  text-align: center;
  border-radius: 15px;
  padding: 13px 27px;
  box-shadow: 0px 4px 4px -2px rgba(24, 39, 75, 0.08),
    0px 2px 4px -2px rgba(24, 39, 75, 0.12);
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background-color: ${(p) =>
    p.tabSelected === p.currentId ? "#b31d15" : "#dcdcdc"};
`;
