import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PersonIcon from '@mui/icons-material/Person';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import LanguageIcon from '@mui/icons-material/Language';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { Span, Text } from '../../../common/Texts';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Product = (props) => {
    const { price, currency, about } = props;
    const user = useSelector((state) => state?.auth?.user?.partner_id);
    const { id } = useParams();

    return (
        <ProductWrapper>
            <Text
                fontWeight="700"
                color="#333333"
                fontSize="3rem"
            >{`${currency} ${price}`}</Text>
            <Content>
                <Link
                    href={`https://checkout${
                        process.env.REACT_APP_NAME === 'DESARROLLO'
                            ? '-test'
                            : ''
                    }.aden.org/ecommerce/${id}${!!user ? `?user=${user}` : ''}`}
                    target="_blank"
                >
                    <Button fullWidth color="primary" variant="contained">
                        Comprar
                    </Button>
                </Link>
            </Content>
            <Info>
                <ThumbUpIcon />
                <Span fontSize=".9rem" fontWeight="700" color="#999999">
                    90% Valoraciones positivas
                </Span>
            </Info>
            <Info>
                <PersonIcon />
                <Span fontSize=".9rem" fontWeight="700" color="#999999">
                    100% autogestionado
                </Span>
            </Info>
            <Info>
                <EqualizerIcon />
                <Span fontSize=".9rem" fontWeight="700" color="#999999">
                    No requiere conocimientos previos
                </Span>
            </Info>
            <Info>
                <RecordVoiceOverIcon />
                <Span fontSize=".9rem" fontWeight="700" color="#999999">
                    Networking con la red de alumnos ADEN
                </Span>
            </Info>
            <Info>
                <LanguageIcon />
                <Span fontSize=".9rem" fontWeight="700" color="#999999">
                    100% Online
                </Span>
            </Info>
            <Info>
                <QueryBuilderIcon />
                <Span fontSize=".9rem" fontWeight="700" color="#999999">
                    {about.duration}
                </Span>
            </Info>
            <Info>
                <VerifiedUserIcon />
                <Span fontSize=".9rem" fontWeight="700" color="#999999">
                    Certificado al completar curso
                </Span>
            </Info>
            <Info>
                <AllInclusiveIcon />
                <Span fontSize=".9rem" fontWeight="700" color="#999999">
                    Acceso ilimitado
                </Span>
            </Info>
        </ProductWrapper>
    );
};

export default Product;

const ProductWrapper = styled.div`
    width: calc(100% - 4rem);
    padding: 2rem;
    @media (max-width: 1280px) {
        div {
            font-size: 2rem;
        }
    }
`;

const Info = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    margin: 0.5rem 0;
    svg {
        color: #999999;
    }
    @media (max-width: 1280px) {
        span {
            font-size: 0.8rem;
        }
    }
    @media (max-width: 1175px) {
        display: none;
        width: 100vw;
    }
`;

const Content = styled.div`
    padding: 1.5rem 0;
`;

const Link = styled.a`
    color: #ffffff;
`;
