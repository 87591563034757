import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SubjectsSlider from '../common/SubjectsSlider';
import Greeting from '../../../../components/common/Greeting';
import AlertForDocumentation from '../common/AlertForDocumentation';
// import useDocumentState from '../../../../hooks/students/useDocumentState';
import MyCoursesSlider from '../common/MyCoursesSlider';
import DirectAccess from './DirectAccess';
import DiverseBanners from '../infoBanners/DiverseBanners';
import useStudentStatus from '../../hooks/useStundetStatus';

const MainCampus = () => {
    // const { ondemand } = useSelector((state) => state.ondemand);
    const { activeRol } = useSelector((state) => state.auth);
    // const enable_workshop = !!ondemand ? ondemand?.enable_workshop : false;
    // const { titulationState } = useDocumentState();
    const { statusStudentBlock } = useStudentStatus();
    // const { registered } = useSelector(state => state.summit)

    return (
        <Wrapper>
            {/* Saludos */}
            <Greeting />

            {/* Alerta por falta de documentación */}
            {activeRol === 'alumno' &&
                statusStudentBlock?.studentStatus === 'Bloqueado' && (
                    <AlertForDocumentation
                        statusStudentBlock={statusStudentBlock}
                    />
                )}

            <DirectAccess />

            {/* Mis cursos P100 - alumnos y visita */}
            {(activeRol === 'alumno' || activeRol === 'visita') && (
                <MyCoursesSlider title="Cursos disponibles" />
            )}

            {/* Banner de anuncios solo visita por ahora */}
            {/* {(activeRol === 'visita' || activeRol === 'alumno') && (
                <AdsBanner />
            )} */}

            {/* Cursos - Solo alumnos */}
            {activeRol === 'alumno' && <SubjectsSlider />}

            {/* Cursos de empresa - Solo ondemand */}
            {/* {activeRol === 'ondemand' && <BusinessSlider />} */}

            {/* Microcontenidos */}
            {/* {enable_workshop && <Microlearning />} */}

            {/* Cursos abiertos */}
            {/* {enable_workshop && activeRol !== 'visita' && <WorkshopsSlider />} */}

            {/*blog */}
            {/* <BlogSlider /> */}

            {/* Workshops and test */}
            <DiverseBanners />
        </Wrapper>
    );

    // RETURN
};

export default MainCampus;

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0.5rem 1rem;
    width: 100%;
    overflow: auto;
    z-index: 10;
`;
