import styled from "styled-components";
import VideocamIcon from '@mui/icons-material/Videocam';

const Messages = (props) => {

    const { unityId, publicationName, type, setTypeSelected, typeSelected } = props;

    return (
        <>
            <MessageContainer firstMessage>
                <p>
                    👋 ¡Hola! <b>Soy ADI, tu asistente virtual con inteligencia artificial.</b> Estoy aquí para apoyarte en tu cursado. <br />
                    ¿Cómo puedo ayudarte hoy con este video? <b>Selecciona alguna de las opciones para continuar.</b>
                </p>
            </MessageContainer>
            <MessageContainer>
                <VideoItem>
                    <IconContainer>
                        {
                            type === "resource"
                                ?
                                <VideocamIcon style={{ color: "#b31d15" }} />
                                : <label>{unityId}</label>
                        }
                    </IconContainer>
                    <label>
                        {publicationName}
                    </label>
                </VideoItem>
                {
                    type === "resource" ?
                        <ButtonsContainer>
                            <SelectionButton
                                onClick={() => setTypeSelected(1)}
                                selected={typeSelected === 1}
                            >
                                Resumen del video
                            </SelectionButton>
                            <SelectionButton
                                onClick={() => setTypeSelected(3)}
                                selected={typeSelected === 3}
                            >
                                Preguntas sobre el recurso
                            </SelectionButton>
                        </ButtonsContainer>
                        : <SelectionButton
                            onClick={() => setTypeSelected(2)}
                            selected={typeSelected === 2}
                        >
                            Preguntas sobre el módulo
                        </SelectionButton>
                }
            </MessageContainer>
        </>
    )
}

export default Messages;

const MessageContainer = styled.div`
    width: 100%;
    position: relative;
    background-color: #f1f1f1;
    color: #333;
    padding: 1rem;
    border-radius: ${({ firstMessage }) => firstMessage ? " 0 8px 8px 8px" : "8px"};
    max-width: 750px;
    z-index: 90;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 1rem;

    p {
        text-align: start;
    }

    ${({ firstMessage }) => firstMessage
        && `&::before {
            content: '';
            position: absolute;
            top: 9px;
            left: -2px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 17px 17px 0;
            border-color: transparent #f1f1f1 transparent transparent;
            transform: translate(-50%, -50%); 
        }`}
`;

const VideoItem = styled.div`
    min-width: 350px;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
    padding: 4px;
    background-color: #b31d15;
    list-style: none;
    border-radius: 20px;
    text-align: start;

    svg {
        color: '#b31d15';
    }
    :hover {
        background-color: #b31d15;
    }
    label {
        color: #fff;
        text-align: start;
        padding-right: 4px;
    }
`

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: start;
    gap: 1rem;
`

const SelectionButton = styled.button`
    cursor: pointer;
    padding: 12px 16px;
    border: 1px solid ${({ selected }) => selected ? "#B31D15" : "#616161"};
    border-radius: 100px;
    background-color: ${({ selected }) => selected ? "#FFF5F5" : "#fff"};
    color: ${({ selected }) => selected ? "#B31D15" : "#616161"};
    font-size: 13px;
    font-weight: ${({ selected }) => selected ? "700" : "400"};

    :hover {
        background-color: ${({ selected }) => !selected && "#E5E5E5"};
    }
`

const IconContainer = styled.div`
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 1000px;

    svg {
        width: 18px;
        height: 18px;
    }

    label {
        color: #b31d15;
        font-weight: 700;
    }
`