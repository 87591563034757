import * as React from 'react';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { Text } from '../../../../common/Texts';
import { highlightSchema } from '../../../../../utils/schemas';
import DefaultField from '../../../../common/DefaultField';
import StarIcon from '@mui/icons-material/Star';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};

export default function HighlightStudentModal(props) {
    const {
        show = false,
        title = 'Abrir modal',
        student = 'este estudiante',
        handleHighlight,
        backgroundColor,
        textColor, 
        border
    } = props;


    const [open, setOpen] = useState(show);
    const [reason, setReason] = useState(null);
    const [charRemain, setCharRemain] = useState(0);

    const maxLength = 200;
    const countChars = (obj) => {
        var strLength = obj.value.length;
        setCharRemain(/* maxLength -  */ strLength);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setReason(null);
    };

    const handleChange = (e) => {
        countChars(e.target);
        setReason(e.target.value);
    };

    const handleSubmit = () => {
        handleHighlight(reason);
        handleClose(); 
    };

    const initialValues = {
        highlight: ''
    };

    return (
        <div>
            <CustomButton
                id="highlight"
                variant="outlined"
                onClick={handleOpen}
                backgroundColor={backgroundColor}
                textColor={textColor}
                border={border}
            >
                <StarIcon style={{ marginRight: '0.5rem' }}/>
                {' '}
                <Text
                    fontSize="0.8rem"
                    fontWeight="900"
                    color={textColor}
                >{title}</Text>
                
            </CustomButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={highlightSchema}
                >
                    {() => (
                        <Box sx={style}>
                            <Form>
                                <ImageContainer
                                    src="/assets/svg/highlight.png"
                                    alt="higtligth"
                                ></ImageContainer>
                                <Text
                                    fontSize="24px"
                                    fontWeight="bold"
                                    color="#B31D15"
                                    textAlign="center"
                                >
                                    ¿Por qué destacas a {student}?
                                </Text>
                                <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 4 }}
                                >
                                    <DefaultFieldFake
                                        name="highlight"
                                        type="text"
                                        label="Explayate sobre los motivos"
                                        multiline
                                        maxRows={3}
                                        variant="outlined"
                                        value={reason}
                                        onChange={handleChange}
                                        helperText={`${charRemain}/${maxLength}`}
                                    />
                                </Typography>
                                <Footer>
                                    <Button
                                        onClick={handleClose}
                                        color="primary"
                                        variant="outlined"
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        disabled={!reason}
                                        onClick={handleSubmit}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Guardar
                                    </Button>
                                </Footer>
                            </Form>
                        </Box>
                    )}
                </Formik>
            </Modal>
        </div>
    );
}


const CustomButton = styled(Button)`
    font-weight: bold;
    border-color: ${(p) => p.border };
    color: ${(p) => p.textColor };
    background-color: ${(p) => p.backgroundColor };
    width: 100%;

    :disabled {
        border-color: 1px solid #999999;
    }

    &.MuiButton-root:hover {
        background-color:  ${(p) => p.backgroundColor };
    }
`;

const ImageContainer = styled.img`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin: auto;
    padding: 1rem;
`;

const DefaultFieldFake = styled(DefaultField)`
    width: 100%;
    min-height: 4rem;
    margin-top: 2rem;
    .MuiOutlinedInput-notchedOutline {
        border: none;
        border-bottom: 1px solid #666666;
        border-radius: 0;
    }
    .MuiFormHelperText-contained {
        display: flex;
        justify-content: flex-end;
    }
    .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense{
        height: 4rem;
    }
`;

const Footer = styled.div`
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 0 15px;
`;
