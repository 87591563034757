import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Img } from "../../../common/Image";
import { Href, Text } from "../../../common/Texts";

const ContactCardFav = (props) => {
    const { value, handleRemoveItem } = props;

    return (
        <Wrapper>
            {/* <TakeOutFav onClick={() => handleRemoveItem(value.partner_id, 'contact')}>x</TakeOutFav> */}
            <Photo>
                <ImgFake src={value.foto} w="80px" h="80px" />
            </Photo>
            <Content>
                <Text fontSize="1.2rem" fontWeight="700" color="#b31d15">
                    {value.name}
                </Text>
                <Href
                    href={`mailto:${value.personal_email}`}
                    target="_blank"
                    fontSize="1rem"
                    fontWeight="600"
                    color="#cccccc"
                >
                    {value.personal_email}
                </Href>
                <Button color="primary" variant="contained">
                    Chatear
                </Button>
            </Content>
        </Wrapper>
    );
};

export default ContactCardFav;

const Wrapper = styled.div`
    display: flex;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    min-height: 150px;
    border: 1px solid #f2f2f2;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 20px;
`;
const Photo = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ImgFake = styled(Img)`
    border-radius: 50%;
`;
const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
`;

const TakeOutFav = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #b31d15;
    font-weight: 600;
    font-size: 16px;
    position: absolute;
    right: 15px;
    top: 5px;
    :hover {
        cursor: pointer;
        background-color: #eeeeee;
    }
`;
