import React from "react";
import styled from "styled-components";
import TimerProgress from "../../../../../../acropolisCommon/components/TimerProgress";
import CloseIcon from "@mui/icons-material/Close";
const AutoPlay = (props) => {
    const {
        handleNext,
        handleStop,
        nextResource,
        player,
        dispatchState,
        currentTime,
        totalTime,
        handleCancelModal,
    } = props;

    return (
        <AutoPlayContent>
            <ButtonCancel onClick={handleCancelModal}>
                <CloseIcon />
            </ButtonCancel>
            <TimerContent>
                <TimerProgress
                    handleNext={handleNext}
                    player={player}
                    progress={1}
                    dispatchState={dispatchState}
                    currentTime={currentTime}
                    totalTime={totalTime}
                    handleStop={handleStop}
                />
                <Span>A continuación:</Span>
            </TimerContent>
            <Title>{nextResource?.nombre_publicacion}</Title>
            <Footer>
                <ContainerButtons>
                    <ButtonNext onClick={handleNext}>Avanzar</ButtonNext>
                </ContainerButtons>
            </Footer>
        </AutoPlayContent>
    );
};

export default AutoPlay;

const ContainerButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 2rem;
`;

const AutoPlayContent = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    padding: 1rem;
`;

const ButtonCancel = styled.button`
    position: absolute;
    top: 10px;
    right: 3rem;
    cursor: pointer;
    svg {
        color: #fff;
    }
`;

const Title = styled.div`
    width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1rem;
    font-weight: 700;
    color: white;
    padding: 1rem 0;
`;

const Span = styled.div`
    font-size: 0.9rem;
    color: white;
    font-weight: 400;
    padding-left: 1rem;
`;

const TimerContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
`;

const ButtonNext = styled.button`
    color: rgba(179, 29, 21, 0.8);
    cursor: pointer;
    width: auto;
    background-color: #fee6eb;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: 700;
    transition: all 0.3s ease-in-out;

    :hover {
        color: #b31d15;
        background-color: #f5f5f5;
    }
    svg {
        margin-left: 0.5rem;
        font-size: 1.2rem;
    }
`;
