import React, {useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SimpleLoading from './SimpleLoading';

const MenuFilter = (props) => {
    const { menuFilter, currentMenu, handleSelectMenu } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (menuFilter === null) {
        return <SimpleLoading />;
    }

    return (
        <MenuWrapper>
            <ButtonFake
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                {currentMenu === null
                    ? 'SELECCIONE CATEGORÍA'
                    : currentMenu.toUpperCase()}
            </ButtonFake>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItemFake
                    active={currentMenu === null ? true : false}
                    onClick={() => {
                        handleSelectMenu(null);
                        handleClose();
                    }}
                >
                    TODOS LOS CURSOS
                </MenuItemFake>
                {menuFilter.sort((a,b) => a.name > b.name).map((th) => {
                    return (
                        <MenuItemFake
                            active={currentMenu === th.name ? true : false}
                            onClick={() => {
                                handleSelectMenu(th.name);
                                handleClose();
                            }}
                        >
                            {th.name.toUpperCase()}
                        </MenuItemFake>
                    );
                })}
            </Menu>
        </MenuWrapper>
    );
};

export default MenuFilter;

const MenuWrapper = styled.div`
    width: 100%;
    /* min-height: 100px; */
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
    -webkit-box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
    -moz-box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
    display: flex;
    flex-direction: row;
`;

const MenuItemFake = styled(MenuItem)`
    background-color: ${(p) =>
        p.active === true ? 'rgba(219,219,219,1)' : 'white'};
    min-width: 300px;
`;

const ButtonFake = styled(Button)`
    width: 100%;
    height: 100%;
`;
