export const createEventsAdapter = (events) => {
  return events.map((event) => ({
    id: event.crm_id,
    title: event.title,
    type: event.type,
    date: event.date,
    schedule: event.schedule,
    eventLocation: event.event_location,
    description: event.description,
    country: event.country,
    image: event.image,
  }));
};
