import axios from "axios";

export async function getPublicInfoForEmail(email) {
    const URL = `${process.env.REACT_APP_SISAPI}`;
    try {
        const res = await axios.get(
            URL + `/v1/acropolis/users/public-email/${email}`
        );
        return res.data.response_data;
    } catch (error) {
        return { error };
    }
}