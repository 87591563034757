import React, { createContext, useContext, useState, useEffect } from 'react';

const SplashScreenContext = createContext();

export function SplashScreenProvider({ children }) {
    const [count, setCount] = useState(0);
    let visible = count > 0;

    useEffect(() => {
        const splashScreen = document.getElementById('splash-screen');

        // Show SplashScreen
        if (splashScreen && visible) {
            splashScreen.style.display = 'flex';

            return () => {
                splashScreen.style.display = 'none';
            };
        }

        // Hide SplashScreen
        let timeout;
        if (splashScreen && !visible) {
            timeout = setTimeout(() => {
                splashScreen.style.display = 'none';
            }, 3000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    return (
        <SplashScreenContext.Provider value={setCount}>
            {children}
        </SplashScreenContext.Provider>
    );
}

export function LayoutSplashScreen({ visible = true }) {
    const setCount = useContext(SplashScreenContext);

    useEffect(() => {
        if (!visible) {
            return;
        }

        setCount((prev) => {
            return prev + 1;
        });

        return () => {
            setCount((prev) => {
                return prev - 1;
            });
        };
    }, [setCount, visible]);

    return null;
}
