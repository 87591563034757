import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Card } from "@mui/material";
import CardJourney from "./CardJourney";
import { Text } from "../Texts";
import NoResultsFound from "../NoResultsFound";
import SimpleLoading from "../SimpleLoading";
import { getNotificationFake } from "../../../redux/api/notifications";
import CardNotifications from "../../ui/molecules/notifications/CardNotifications";
import { useSelector } from "react-redux";
import SplitButton from "../SplitButton";

const Binnacle = (props) => {
    const { from } = props;

    // STATES
    const [value, setValue] = useState(null);
    const options = ["Por defecto", "Más antiguo", "Más nuevo"];
    const [currentFilter, setCurrentFilter] = useState({
        index: 0,
        name: "Por defecto",
    });

    // REDUX
    const { activeRol } = useSelector((state) => state.auth);

    // EFFECTS
    useEffect(() => {
        !value && getNotifications(activeRol);
    }, [value]);

    // FUNCTIONS
    const getNotifications = async (is_teacher) => {
        const response = await getNotificationFake(is_teacher);

        if (!response.error) {
            let result = response.sort((a, b) => b.id - a.id);
            setValue(result);
        } else {
            setValue([]);
            console.error(response.error);
        }
    };

    const handleOption = (args) => {
        setCurrentFilter(args);
        args.index === 0 && setValue(value.sort((a, b) => b.id - a.id));
        args.index === 1 &&
            setValue(
                value.sort(
                    (a, b) =>
                        new Date(a?.date)?.getTime() -
                        new Date(b?.date)?.getTime()
                )
            );
        args.index === 2 &&
            setValue(
                value.sort(
                    (a, b) =>
                        new Date(b?.date)?.getTime() -
                        new Date(a?.date)?.getTime()
                )
            );
    };

    //RETURN
    if (!value) {
        return (
            <Container>
                <SimpleLoading padding="6" />
            </Container>
        );
    }

    return (
        <Container>
            <WrapperHeader>
                <Title>
                    <Text fontWeight="bold" fontSize="1.2rem" color="#555555">
                        Novedades
                    </Text>
                </Title>
                <SplitButton handleOption={handleOption} options={options} />
            </WrapperHeader>

            <Wrapper from={from}>
                {value.length === 0 ? (
                    <NoResultsFound message="¡Ups! Actualmente no se encontraron novedades." />
                ) : (
                    value.map((notifications) => {
                        return (
                            <CardNotifications
                                title={notifications.title}
                                date={notifications.date}
                                descriptions={notifications.message}
                                read={notifications.is_open}
                                launch_url={notifications.launch_url}
                                id={notifications.id}
                            />
                        );
                    })
                )}
            </Wrapper>
        </Container>
    );
};

export default Binnacle;

const Container = styled(Card)`
    display: flex;
    flex-direction: column;
    grid-area: actions;
    gap: 1rem;
    padding: 1rem 2rem;
    width: calc(100% - 4rem);
    border-radius: 20px;
    max-height: 500px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Title = styled.div`
    width: fit-content;
`;

const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: ${(p) =>
        p.from === "dashboard" ? "repeat(1, 1fr)" : "repeat(1, 1fr)"};
    gap: 1rem;
    overflow-y: auto;
    scrollbar-color: transparent transparent;

    ::-webkit-scrollbar {
        background-color: #f9f9f9;
        border-radius: 6px;
        width: 4px;
    }
`;

const WrapperHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;
