import axios from 'axios';
import Sentry from './sentry';

const captureException = (error, data) => {
    const formObject = {};
    data.forEach((value, key) => {
        formObject[key] = value;
    });
    const values = {
        name: 'Error carga actividad',
        dni: false,
        phone: false,
        email: false,
        product: 1,
        url: false,
        discount_code: false,
        action: `Error: Carga de actividad fallida. - Alumno ID:${formObject?.alumno} - Evaluacion ID:${formObject?.evaluacion} - Recurso: ${formObject?.recurso} - Objeto Aprendizaje: ${formObject?.objeto_aprendizaje} - Asignatura: ${formObject?.asignatura}`,
    };
    axios.post(
        `${process.env.REACT_APP_REPO}/v1/repo_aden/ecommerce/history/`,
        values
    );
    Sentry.captureException(error, {
        contexts: {
            form: formObject,
        },
    });
};

export default captureException;

export const sendHistory = (data) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/ecommerce/history/`;
    try {
        axios.post(URL, data);
    } catch (error) {
        console.log('History error:', error);
    }
};
