import React from 'react';
import styled from 'styled-components';

const Information = (props) => {
    const {} = props;

    return (
        <InformationContainer>
            <TitleInfo></TitleInfo>
        </InformationContainer>
    );
};

export default Information;

const InformationContainer = styled.div`
    padding: 0 2rem;
`;

const TitleInfo = styled.h2`
    font-size: 1rem;
    font-weight: 700;
`;
