import React from 'react';
import styled from 'styled-components';

const TicketCard = (props) => {
    const { title, text } = props;

    // RETURN
    return (
        <Container>
            <TextWrapper $color={true}>{title}</TextWrapper>
            <TextWrapper>{text}</TextWrapper>
        </Container>
    );
};

export default TicketCard;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 2px solid #b31d15;
    width: 100%;
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 0.25rem 1.5rem;
    white-space: nowrap;
    background-color: ${(p) => (p.$color ? '#b31d15' : 'transparent')};
    color: ${(p) => (p.$color ? '#ffffff' : '#222222')};
    font-size: ${(p) => (p.$color ? '0.9rem' : '1rem')};
`;
