import styled from "styled-components";

const CardInfo = (props) => {

    const { data } = props;

    return (
        <Container>
            <Row>
                <label>ORDEN</label>
                {data.name}
            </Row>
            <Row>
                <label>REFERENCIA</label>
                {data.ref}
            </Row>
            <Row>
                <label>ESTADO</label>
                {data.state}
            </Row>
            <Row last={true}>
                <label>MONTO</label>
                {data.currency + " " + data.amount}
            </Row>
        </Container>
    )

}

export default CardInfo;

const Container = styled.div`
    width: calc(100% - 2rem - 2px);
    height: 360px;
    border: 1px solid #b31d15;
    border-radius: 20px;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
`

const Row = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 2rem;
    border-bottom: ${(props) => !props.last && "1px solid #e5e5e5"};
    font-size: 14px;

    label {
        font-weight: 700;
        font-size: 14px;
        color: #b31d15;
    }
`