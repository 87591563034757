import moment from "moment";

export const convertToLocalTime = (date, timezone) => {
    // Convierte la fecha y hora del evento a la zona horaria especificada
    const eventTime = moment.tz(date, timezone);

    // Convierte la hora del evento a la hora local
    const localTime = eventTime.clone().tz(moment.tz.guess());

    return localTime.format('DD/MM/YYYY HH:mm:ss');
};