import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Material UI
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

const PickRecAudio = (props) => {
    const { handleSendAudio, audioFile } = props;

    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const [audioUrl, setAudioUrl] = useState("");
    const [recording, setRecording] = useState(false);
    const [openAudioModal, setOpenAudioModal] = useState(false);

    useEffect(() => {
        if (audioFile) {
            setAudioUrl(audioFile);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const startRecording = async () => {
        audioChunksRef.current = [];
        setAudioUrl("");
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
            const recorder = new MediaRecorder(stream);

            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            recorder.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, {
                    type: "audio/wav",
                });
                const url = URL.createObjectURL(audioBlob);
                setAudioUrl(url);
                handleSendAudio(audioBlob);
            };

            recorder.start();
            mediaRecorderRef.current = recorder;
            setRecording(true);
        } catch (error) {
            console.error("Error al obtener acceso al micrófono: ", error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setRecording(false);
            setOpenAudioModal(true);
        }
    };

    const handleCleanAudio = () => {
        mediaRecorderRef.current = null;
        audioChunksRef.current = [];
        setAudioUrl("");
        setRecording(false);
        setOpenAudioModal(false);
        handleSendAudio(null);
    };

    const handleClose = () => setOpenAudioModal(false);
    const handleOpen = () => setOpenAudioModal(true);

    return (
        <Container>
            {!recording && (
                <ButtonAudio title="Grabar audio" onClick={startRecording}>
                    <MicIcon />
                </ButtonAudio>
            )}
            {!!audioUrl && (
                <ButtonFile sound={audioUrl !== ""} title="Escuchar audio grabado" onClick={handleOpen}>
                    <AudioFileIcon />
                </ButtonFile>
            )}
            <Dialog open={!!recording}>
                <Content>
                    <LoadingContainer>
                        Grabando
                        <LoadingDots />
                    </LoadingContainer>
                    <Button variant="contained" onClick={stopRecording}>
                        Terminar grabación
                    </Button>
                </Content>
            </Dialog>
            <Dialog open={!!audioUrl && openAudioModal}>
                <ContentAudio>
                    <h4>Audio grabado</h4>
                    <audio
                        className="audio-player"
                        controls
                        src={audioUrl}
                    ></audio>
                    <Buttons>
                        <Button variant="contained" onClick={handleClose}>
                            Guardar audio
                        </Button>
                        <Button variant="outlined" onClick={handleCleanAudio}>
                            <DeleteForeverIcon />
                            Borrar audio
                        </Button>
                    </Buttons>
                </ContentAudio>
            </Dialog>
        </Container>
    );
};

export default PickRecAudio;

const Container = styled.div`
    display: flex;
    /* flex-direction: column; */
    gap: 0rem;
`;

const ButtonAudio = styled(IconButton)`
    width: 38px;
    height: 38px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    svg {
        font-size: 1.5rem;
        fill: #a9a9a9;
    }
`;

const ButtonFile = styled(IconButton)`
    width: 38px;
    height: 38px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${(props) => props.sound && "#b31d15"};
    :hover {
        background-color: ${(props) => props.sound && "#b31d15"};
    }
    svg {
        font-size: 1.5rem;
        fill: ${(props) => props.sound ? "#fff" : "#616161"};
    }
`;

const loadingAnimation = keyframes`
    0% {
        content: "";
    }
    25% {
        content: ".";
    }
    50% {
        content: "..";
    }
    75% {
        content: "...";
    }
    100% {
        content: "";
    }
`;

const LoadingContainer = styled.div`
    font-size: 16px;
    text-align: center;
    width: 150px;
    height: 150px;
    border: 2px solid #b31d15;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

// Estilo de los puntos suspensivos animados
const LoadingDots = styled.span`
    display: inline-block;

    &::before,
    &::after {
        content: "";
        width: 1em;
        text-align: left;
        animation: ${loadingAnimation} 1.5s steps(5) infinite;
    }
`;

const Content = styled.div`
    height: 200px;
    width: 200px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 18px;
`;

const ContentAudio = styled.div`
    padding: 1rem;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding-top: 1rem;
`;
