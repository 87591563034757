import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';

const WorkshopCardSkeleton = (props) => {
    return (
        <WorkshopCardContainer>
            <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={150}
            />
            <Body>
                <Skeleton animation="wave" height={30} />
                <TagsGrid>
                    <TagWrapper animation="wave" height={15} width={40} />
                    <TagWrapper animation="wave" height={15} width={50} />
                    <TagWrapper animation="wave" height={15} width={40} />
                    <TagWrapper animation="wave" height={15} width={60} />
                </TagsGrid>
                <Description>
                    <Skeleton animation="wave" height={8} width="100%" />
                    <Skeleton animation="wave" height={8} width="100%" />
                    <Skeleton animation="wave" height={8} width="100%" />
                    <Skeleton animation="wave" height={8} width="100%" />
                    <Skeleton animation="wave" height={8} width="100%" />
                    <Skeleton animation="wave" height={8} width="100%" />
                    <Skeleton animation="wave" height={8} width="100%" />
                    <Skeleton animation="wave" height={8} width="70%" />
                </Description>
            </Body>

            <Footer>
                <IconWrapper>
                    <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                    />
                </IconWrapper>
                <FakeButton animation="wave" />
            </Footer>
        </WorkshopCardContainer>
    );
};

export default WorkshopCardSkeleton;

const WorkshopCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    min-height: 320px;
    width: 100%;
    border: 1px solid #f2f2f2;
`;

const FakeImg = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 1221px) {
        height: 120px;
    }

    @media screen and (max-width: 768px) {
        height: 100px;
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
`;

const TagsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 0.5rem;
`;

const TagWrapper = styled(Skeleton)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem 0.5rem;
    border-radius: 19px;
    background-color: rgba(0, 0, 0, 0.04);
    width: auto;
`;

const Description = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    text-align: start;
    color: #949494;
    height: 7rem;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 1px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgb(200, 200, 200);
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgb(170, 170, 170);
    }
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #fafafa;
    margin-top: auto;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const FakeButton = styled(Skeleton)`
    width: 100px;
    height: 50px;
`;
