import React from 'react';
import styled from 'styled-components';
import CardPay from '../../molecules/faculty/CardPay';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const CardsPay = (props) => {
    const { authorization, dollar, localCurrency, draftPay } = props;

    return (
        <CardsPayWrapper>
            <CardPay
                color={'#28A986'}
                backgroundColor={'#EAFAF6'}
                icon={CheckCircleOutlineIcon}
                title="PAGOS AUTORIZADOS"
                data={authorization || 0}
            />
            <CardPay
                color={'#CEB269'}
                backgroundColor={'#F9F5EC'}
                icon={AccessTimeIcon}
                title="PAGOS NO AUTORIZADOS"
                data={draftPay || 0}
            />
            <Line />
            <CardPay
                color={'#D67539'}
                backgroundColor={'#F2D3C0'}
                icon={AttachMoneyIcon}
                title="DOLARES • A COBRAR"
                data={dollar || 0}
                money={'USD'}
            />
            <CardPay
                color={'#86A0E4'}
                backgroundColor={'#E6EBF9'}
                icon={AttachMoneyIcon}
                title="MONEDA LOCAL • A COBRAR"
                data={`$${localCurrency || 0}`}
            />
        </CardsPayWrapper>
    );
};

export default CardsPay;

const CardsPayWrapper = styled.div`
    width: calc(100% - 2rem);
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    background-color: #fff;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    padding: 1rem;
    border-radius: 100px;

    @media (width < 768px) {
        flex-direction: column;
        background-color: transparent;
        box-shadow: none;
    }
`;

const Line = styled.div`
    width: 2px;
    height: 34px;
    background-color: #b31d15;

    @media (width < 768px) {
        display: none;
    }
`
