import * as types from '../types';

const actions = {};

// GET Finances
actions.getAdvisorRequest = () => {
    return {
        type: types.GET_ACADEMIC_ADVISOR_REQUEST
    };
};

actions.getAdvisorSuccess = (advisor) => {
    return {
        type: types.GET_ACADEMIC_ADVISOR_SUCCESS,
        payload: advisor
    };
};

actions.getAdvisorFail = (error) => {
    return {
        type: types.GET_ACADEMIC_ADVISOR_FAIL,
        payload: error.response.status
    };
};

export { actions };
