import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import TitulationState from "../../../ui/organisms/academic/TitulationState";
import Documentation from "../../../ui/organisms/academic/Documentation";
import DefaultCardIcon from "../../../common/DefaultCardIcon";
import { Text } from "../../../common/Texts";

// Material UI
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelIcon from "@mui/icons-material/Cancel";

// Redux
import { documentsActions } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import GenericError from "../../../common/GenericError";
import { getSedes } from "../../../../redux/api/titulation/titulation";

const DocumentationTemplate = (props) => {
  const { handleSetStateDocumentation } = props;

  // REDUX
  const dispatch = useDispatch();
  const { documents, errorList } = useSelector((state) => state.documents);

  // STATES
  const [degreeStatus, setDegreeStatus] = useState("");
  const [statusLog, setStatusLog] = useState("");
  const [lastStep, setLastStep] = useState(0);
  const [indexState, setIndexState] = useState();
  const [documentState, setDocumentState] = useState(documents);
  const [isShowCard, setIsShowCard] = useState(false);
  const [statusPayments, setStatusPayments] = useState({
    program: "",
    gt: "",
  });
  const [addressComplete, setAddressComplete] = useState("");
  const [typeDelivery, setTypeDelivery] = useState("");
  const [data, setData] = useState({
    id_diploma: "",
    destino: "",
    id_sede: "",
    domicilio_confirmado: "",
    direccion: "",
    ciudad: "",
    id_pais: "",
    cod_postal: "",
  });
  const [sedes, setSedes] = useState([]);

  // Effect
  useEffect(() => {
    if (documents === null || documents === undefined) {
      dispatch(documentsActions.getDocumentsRequest());
    }
    if (documents !== null && documents !== undefined) {
      countDocumentation(documents);
      setDocumentState(documents);
    }
    getSede()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  // Function
  const countDocumentation = (documents) => {
    documents.map((doc) => {
      if (doc.estado === "Pendiente") {
        handleSetStateDocumentation(false);
        return false;
      }
      handleSetStateDocumentation(true);
      return true;
    });
  };

  const getSede = async () => {
    const response = await getSedes();
    if (response.error) {
      // alert("hay un error en la api");
      setSedes([]);
    } else {
      setSedes(response);
    }
  };

  if (!!errorList)
    return (
      <GenericError reloadFn={() => dispatch(documentsActions.getDocumentsRequest())} />
    )

  return (
    <>
      <DocWrapper>
        <ContainerWrapper>
          <Text color="#333333" fontWeight="600" fontSize="1.2rem">
            Documentación
          </Text>
          <div>
            <Documentation
              handleSetStateDocumentation={handleSetStateDocumentation}
              setDegreeStatus={setDegreeStatus}
              setStatusLog={setStatusLog}
              setLastStep={setLastStep}
              setStatusPayments={setStatusPayments}
              setAddressComplete={setAddressComplete}
              addressComplete={addressComplete}
              setTypeDelivery={setTypeDelivery}
              typeDelivery={typeDelivery}
              documents={documentState}
              setData={setData}
              data={data}
              setIsShowCard={setIsShowCard}
              setDocumentState={setDocumentState}
              setIndexState={setIndexState}
              indexState={indexState}
              sedes={sedes}
            />
          </div>
        </ContainerWrapper>
        <TitulationWrapper>
          <TitulationState
            degreeStatus={degreeStatus}
            statusLog={statusLog}
            lastStep={lastStep}
            addressComplete={addressComplete}
            typeDelivery={typeDelivery}
            documents={documentState}
            setData={setData}
            indexState={indexState}
            data={data}
            setTypeDelivery={setTypeDelivery}
            isShowCard={isShowCard}
          />
          {statusLog.length >= 1 && (
            <>
              {statusPayments.program === "rechazado" ||
                statusPayments.program === "plan_de_pago" ? (
                <DefaultCardIcon
                  subtitle="Programa"
                  type="pago"
                  title={
                    statusPayments.program === "rechazado"
                      ? "Tiene saldos pendientes, por favor contáctese con el área de finanzas para regularizar la situación"
                      : "Aún no ha finalizado su plan de pagos"
                  }
                  color={
                    statusPayments.program === "rechazado"
                      ? "#FC2958"
                      : "#FFBC00"
                  }
                  icon={
                    statusPayments.program === "rechazado" ? (
                      <CancelIcon />
                    ) : (
                      <AccessTimeIcon />
                    )
                  }
                />
              ) : null}
              {statusPayments.gt === "rechazado" ||
                statusPayments.gt === "plan_de_pago" ? (
                <DefaultCardIcon
                  subtitle="Gestión de título"
                  type="pago"
                  title={
                    statusPayments.gt === "rechazado"
                      ? "Tiene saldos pendientes, por favor contáctese con el área de finanzas para regularizar la situación"
                      : "Aún no ha finalizado su plan de pagos"
                  }
                  color={
                    statusPayments.gt === "rechazado" ? "#FC2958" : "#FFBC00"
                  }
                  icon={
                    statusPayments.gt === "rechazado" ? (
                      <CancelIcon />
                    ) : (
                      <AccessTimeIcon />
                    )
                  }
                />
              ) : null}
            </>
          )}
        </TitulationWrapper>
      </DocWrapper>
    </>
  );
};

export default DocumentationTemplate;

const DocWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  @media (max-width: 1080px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const Container = styled.div`
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
`;

const ContainerWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
`;

const TitulationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
