import React from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';

const StateErrorWrapper = (props) => {
    const {
        enrollmentState,
        setOpenModalBloqued,
        setOpenModalExpired,
        setOpenModalToExpire,
    } = props;

    const wrapperContent = {
        Bloqueado: (
            <ErrorWrapper onClick={() => setOpenModalBloqued(true)}>
                <Icon icon="ion:lock-closed-outline" />
                ¿Por qué está bloqueada?
            </ErrorWrapper>
        ),
        Vencido: (
            <ErrorWrapper onClick={() => setOpenModalExpired(true)}>
                <Icon icon="ph:calendar-x" />
                ¿Por qué está vencida?
            </ErrorWrapper>
        ),
        'En pausa': (
            <ErrorWrapper>
                <Icon icon="fa6-regular:circle-pause" />
                <div>
                    <p>Esta asignatura se encuentra en pausa.</p>
                    {''}
                    <strong>Recuerda reanudar tu cursado.</strong>
                </div>
            </ErrorWrapper>
        ),
        'Próximo a vencer': (
            <ErrorWrapper onClick={() => setOpenModalToExpire(true)}>
                <Icon icon="fluent:calendar-error-24-regular" />
                <div>
                    <strong>Esta asignatura está por expirar.</strong>
                    <p>Ver más información.</p>
                </div>
            </ErrorWrapper>
        ),
    };

    return <>{wrapperContent[enrollmentState] || null}</>;
};

export default StateErrorWrapper;

const ErrorWrapper = styled.div`
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #cd2118;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    svg {
        font-size: 25px;
        width: 1005;
        font-weight: 700;
    }

    :hover {
        font-weight: 700;
    }
`;
