import React from 'react';
import styled from 'styled-components';

import { ErrorMessage, Field } from 'formik';
import { Checkbox } from '@mui/material';

const DefaultCheck = (props) => {
    const { name } = props;

    // RETURN
    return (
        <Field
            as={Checkbox}
            name={name}
            helperText={<ErrorMessage name={name} component={CustomError} />}
            color="primary"
            {...props}
        />
    );
};

export default DefaultCheck;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #b31d15;
`;
