import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import TableHistoryGradeBook from './table/TableHistoryGradeBook';
import Filters from './Filters';
import useHistoryGradebook from '../../../../hooks/teacher/useHistoryGradebook';
import GenericPagination from '../../../common/GenericPagination';
import ErrorResponse from '../../../common/ErrorResponse';
import { ReactComponent as NotFoundSvg } from '../../../../assets/media/svg/404-Error-pana.svg';

const HistoricalGradebookSection = (props) => {
    const { id } = props;
    const [studentIdSelected, setStudentIdSelected] = useState(0);
    const [activityIdSelected, setActivityIdSelected] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(20);

    // CUSTOM HOOKS
    const {
        gradesData,
        loading,
        error,
        isLoading,
        totalStudents,
        fetchFilterGrades,
        loading_activitiesList,
        error_activitiesList,
        loading_studentsList,
        error_studentsList,
        studentsList,
        activitiesList,
    } = useHistoryGradebook(
        id,
        currentPage,
        pageSize,
        studentIdSelected,
        activityIdSelected
    );

    //Modificación de 1 filtro o ambos + clicke en botón buscar
    const updateFilters = (a, b) => {
        setStudentIdSelected(a);
        setActivityIdSelected(b);
        handleUpdateData(id, 0, pageSize, a, b);
    };

    //Click en botón limpiar filtros
    const handleClearFilters = () => {
        setCurrentPage(1);
        setStudentIdSelected(0);
        setActivityIdSelected(0);
        fetchFilterGrades(id, 1, pageSize, 0, 0);
    };

    //Cambio de página actual con filtros en simultaneo
    const handleCurrentPage = (e, value) => {
        setCurrentPage(value);
        fetchFilterGrades(
            id,
            value,
            pageSize,
            studentIdSelected,
            activityIdSelected
        );
    };

    const handleUpdateData = (id_assigment, page, limit, student, activity) => {
        fetchFilterGrades(id_assigment, page, limit, student, activity);
    };

    return (
        <Container>
            <Filters
                id={id}
                updateData={updateFilters} //Búsqueda de datos con filtros seleccionados
                handleClearFilters={handleClearFilters} //Limpieza de filtros y búsqueda de datos sin filtros
                filterStudents={studentsList} //lista inicial de estudiantes
                filterActivities={activitiesList} //lista inicial de actividades
                loading_activitiesList={loading_activitiesList}
                loading_studentsList={loading_studentsList}
                error_activitiesList={error_activitiesList}
                error_studentsList={error_studentsList}
            />
            {isLoading || loading ? (
            <TableContainer>
                <TableSkeleton>
                    {[...Array(5)].map((_, rowIndex) => (
                        <RowSkeleton key={rowIndex}>
                            {[...Array(6)].map((_, colIndex) => (
                                <CellSkeleton key={colIndex} />
                            ))}
                        </RowSkeleton>
                    ))}
                </TableSkeleton>
            </TableContainer>
            ) : error ? (
            <TableContainer>
                <ErrorWrapper>
                    <NotFoundSvg width="30%" />
                    <p>No se encontraron estudiantes.</p>
                </ErrorWrapper>
            </TableContainer>

            ) : gradesData && gradesData.length > 0 ? (
            <>
                <TableHistoryGradeBook
                    id_assignment={id}
                    gradesData={gradesData}
                    isLoading={isLoading}
                />
                <PaginationWrapper>
                    {totalStudents && (
                        <GenericPagination
                            handleCurrentPage={handleCurrentPage}
                            totalItems={totalStudents}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            loading={isLoading}
                        />
                    )}
                </PaginationWrapper>
            </>
            ) : (
            gradesData.length === 0 && (
            <TableContainer>
                <ErrorWrapper>
                    <ErrorResponse
                        message="No se encontraron calificaciones para esa
                                búsqueda."
                    />
                </ErrorWrapper>
            </TableContainer>
            )
            )}
        </Container>
    );
};

export default HistoricalGradebookSection;

const Container = styled.div`
    grid-area: container;
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const twinkle = keyframes`
    0%{
        background-color: #e1e1e1;
    }
    50%{
        background-color: #cccccc;
    }
    100%{
        background-color: #e1e1e1;
    }
`;

const TableSkeleton = styled.div`
    grid-area: container;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    gap: 0.5rem;
`;

const RowSkeleton = styled.div`
    position: relative;
    width: 100%;
    height: 30px;
    display: flex;
    margin-top: 0.5rem;
    justify-content: space-between;
    align-items: center;
    animation: ${twinkle} 2s linear infinite;
`;

const CellSkeleton = styled.div`
    width: 15%;
    height: 100%;
    background-color: #e0e0e0;
    border-radius: 4px;
`;

const PaginationWrapper = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
`;

const ErrorWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 700;
    color: #616161;
    width: 100%;
    align-self: center;
`;
