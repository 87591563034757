import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@mui/material';
import { EditRounded } from '@mui/icons-material';
import SaveSnackbar from '../../../../templates/profile/SaveSnackbar';
import ModalEditProfileTeacher from '../../../../templates/profile/teacher/ModalEditProfileTeacher';

const EditProfileTeacher = (props) => {
    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTIONS
    function handleOpenModal() {
        setIsOpen(true);
    }

    function handleCloseModal() {
        setIsOpen(false);
    }

    return (
        <>
            <ButtonWrapper>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<EditRounded />}
                    onClick={handleOpenModal}
                >
                    Editar
                </Button>
            </ButtonWrapper>
            <ModalEditProfileTeacher isOpen={isOpen} closeModal={handleCloseModal} />
            <SaveSnackbar />
        </>
    );
};

export default EditProfileTeacher;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: max-content;

    button {
        width: 100%;
    }
`;
