import React from 'react';
import styled from 'styled-components';

const PublicityWithoutEseade = () => {
    const videoUrl =
        'https://pbs.twimg.com/media/FHTc77MXsAUwOiS.jpg';

    return (
        <Container>
            <CustomVideo>
                <img alt="" src={videoUrl} />
            </CustomVideo>
            <Overlay />
            <TextContainer>
                <DivText>
                    <H1>Eseade</H1>
                    <H3>
                        Es una institución universitaria cuya misión es formar personas con carácter emprendedor para que con su accionar contribuyan al progreso humano, según los valores de libertad, integridad, excelencia y praxis. Así nos proponemos ser la universidad de referencia en el desarrollo integral de líderes emprendedores, a través de la excelencia académica, el estímulo al juicio crítico y el pensamiento independiente.
                    </H3>
                </DivText>
            </TextContainer>
        </Container>
    );
};

export default PublicityWithoutEseade;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    row-gap: 1rem;
    text-align: left;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    color: #ffffff;
    overflow-y: hidden;

    @media (max-width: 768px) {
        visibility: hidden !important;
        display: none !important;
    }
`;

const CustomVideo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
`;

const Overlay = styled.div`
    background: #1d87fd;
    background: linear-gradient(
        45deg,
        rgba(29, 135, 253, 0.9) 0%,
        rgba(29, 135, 253, 0.8) 50%,
        rgba(29, 135, 253, 0.7) 100%
    );
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
`;

const TextContainer = styled.div`
    padding: 0 10%;
    z-index: 99999;
    text-align: left;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 1024) {
        padding: 0 5%;
    }
`;

const DivText = styled.div`
    z-index: 99;
    color: #ffffff;
    text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const H3 = styled.h3`
    font-size: 1.5rem;
    line-height: 140%;
    font-weight: 400;
    @media (max-width: 1024px) {
        font-size: 1rem;
    }
`;

const H1 = styled.h1`
    font-size: 3.5rem;
    font-weight: 900;
    @media (max-width: 1024px) {
        font-size: 2.5rem;
    }
`;
