import { Button } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { authActions } from '../../../../redux/actions'
import { saveDracmaChallenge } from '../../../../redux/api/dracma.api'
import { Text } from '../../../common/Texts'

const InitialChallengeDracma = (props) => {

    const { handleNext, handleClose, values } = props

    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)

    const handleClick = () => handleNext(1)

    const handleLater = () => {
        // @TODO cambiar estado de redux
        sendResult()
        dispatch(authActions.setChallengeDracma({
            state: false,
            date: new Date().toLocaleDateString()
        }))
        handleClose()
    }

    const sendResult = async () => {
        const body = {
            repo_id: user.repo_id,
            crm_partner_id: user.partner_id,
            type: 'later',
            res_id: values?.question?.id
        }
        const request = await saveDracmaChallenge(body)
        return request
    }

    return (
        <div>
            <Header>
                <Title color='white' fontSize="48px" fontWeight="900">
                    ¡Desafío Dracma!
                </Title>
                <Text color='white' fontSize="18px" fontWeight="400">
                    Responde una pregunta diaria y gana puntos Dracma
                </Text>
            </Header>
            <Body>
                <Text color='#222222' textAlign="center" fontSize="24px" fontWeight="700">
                    ¿Listo para completar tu desafío diario?
                </Text>
                <Text color='#222222' textAlign="center" fontSize="16px" fontWeight="400">
                    Tendrás 60 segundos para responder una pregunta de un tema específico.
                </Text>
                <Buttons>
                    <Button onClick={handleLater} variant='outlined'>Lo haré mañana</Button>
                    <Button onClick={handleClick} color='primary' variant='contained'>Sí, ¡quiero hacerlo ahora!</Button>
                </Buttons>
            </Body>
        </div>
    );
}

export default InitialChallengeDracma

const Header = styled.div`
    width: 100%;
    height: 146px;
    background: linear-gradient(96.54deg, #990800 0%, #B31D15 49.19%, #E5271C 99.04%);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    @media(max-width: 768px){
        font-size: 16px;
    }
`

const Title = styled(Text)`
    font-style: italic;
    @media(max-width: 768px){
        font-size: 24px;
    }
`

const Body = styled.div`
    height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    gap: 25px;
`

const Buttons = styled.div`
    display: flex;
    gap: 30px;
`