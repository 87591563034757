import React from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import { Text } from '../../../common/Texts';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import styled from 'styled-components';

const DialogTask = (props) => {
    const { open, handleClose, values } = props;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '55vw',
        bgcolor: 'background.paper',
        borderRadius: 7,
        boxShadow: 24,
    };

    const convertDate = (originalDate) => {
        const date = new Date(originalDate);

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedDate = `Entregado el ${day < 10 ? '0' : ''}${day}/${
            month < 10 ? '0' : ''
        }${month}/${year} a las ${hours < 10 ? '0' : ''}${hours}:${
            minutes < 10 ? '0' : ''
        }${minutes} hs`;

        return formattedDate;
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <CustomBox sx={style}>
                <Header>
                    <p>{values.activity.name}</p>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Header>
                <Body>
                    <Info>
                        {/* <InfoHeader height="60px">
                            <Text fontWeight="700" color="#fff" fontSize="18px">
                                {values.activity.name}
                            </Text>
                        </InfoHeader> */}
                        <InfoContent>
                            <Text
                                fontWeight="700"
                                color="#B31D15"
                                fontSize="18px"
                            >
                                Actividad
                            </Text>
                            <Text
                                fontWeight="400"
                                color="#616161"
                                fontSize="16px"
                            >
                                {values?.description ||
                                    'Esta actividad no posee descripción'}
                            </Text>
                            <Text
                                fontWeight="700"
                                color="#B31D15"
                                fontSize="18px"
                            >
                                Corrección
                            </Text>
                            <InfoCorrection
                                state={!!values.redo ? 'rehacer' : values.state}
                            >
                                <p>
                                    <CheckCircleIcon fontSize="large" />
                                </p>
                                <InfoState
                                    state={
                                        !!values.redo ? 'rehacer' : values.state
                                    }
                                >
                                    <InfoCorrectionDate>
                                        <CorrectionState
                                            state={
                                                !!values.redo
                                                    ? 'rehacer'
                                                    : values.state
                                            }
                                        >
                                            {values?.order + 1}° Entrega
                                        </CorrectionState>
                                        <p>{convertDate(values.create_date)}</p>
                                    </InfoCorrectionDate>
                                    {values.state === 'pendiente' ? (
                                        <CorrectionStateText
                                            state={
                                                !!values.redo
                                                    ? 'rehacer'
                                                    : values.state
                                            }
                                        >
                                            {values.state}
                                        </CorrectionStateText>
                                    ) : (
                                        <InfoGrade>
                                            <WorkspacePremiumIcon />
                                            <Grade>
                                                {values.grade}
                                                <p> / 100</p>
                                            </Grade>
                                        </InfoGrade>
                                    )}
                                </InfoState>
                            </InfoCorrection>
                        </InfoContent>
                    </Info>
                </Body>
            </CustomBox>
        </Modal>
    );
};

export default DialogTask;

const CustomBox = styled(Box)`
    height: 65vh;
    overflow: hidden;
    background-color: #fff;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #b31d15;
    color: #fff;
    height: 50px;
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    padding: 0 1rem;
    box-sizing: border-box;
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

////////////////////////////////////////////////////////////////////////////////

const Info = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 20px 20px 0px 0px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media (max-width: 768px) {
        gap: 0;
    }
`;

const InfoContent = styled.div`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    padding: 0px 2rem;
    @media (max-width: 768px) {
        height: 100%;
        & > ${Text}:nth-child(1) {
            display: none;
        }
        & > ${Text}:nth-child(2) {
            display: none;
        }
        & > ${Text}:nth-child(3) {
            display: none;
        }
    }
`;

const InfoCorrection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    align-items: center;
    margin-bottom: 32px;
    > p {
        color: ${({ state }) =>
            state === 'pendiente'
                ? '#5E80DB'
                : state === 'corregido'
                ? '#1E8065'
                : '#B31D15'};
    }
    @media (max-width: 768px) {
        margin: 1rem 0;
        margin-bottom: 0;
        > p {
            display: none;
        }
    }
`;

const InfoState = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    height: 100px;
    width: 100%;
    -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
    border-left: 10px solid;
    border-color: ${({ state }) =>
        state === 'pendiente'
            ? '#E6EBF9'
            : state === 'corregido'
            ? '#EAFAF6'
            : '#FCE9E8'};
    border-radius: 10px;
    padding: 10px;
    @media (max-width: 768px) {
        padding: 0 1rem;
    }
`;

const InfoCorrectionDate = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;

    > p {
        font-size: 12px;
        font-weight: 400;
        color: #616161;
    }
`;

const CorrectionState = styled.div`
    background-color: ${({ state }) =>
        state === 'pendiente'
            ? '#DCDCDC'
            : state === 'corregido'
            ? '#EAFAF6'
            : '#FCE9E8'};
    color: ${({ state }) =>
        state === 'pendiente'
            ? '#616161'
            : state === 'corregido'
            ? '#1E8065'
            : '#B31D15'};
    padding: 4px 10px 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    @media (max-width: 768px) {
        white-space: nowrap;
    }
`;

const CorrectionStateText = styled.div`
    background-color: ${({ state }) =>
        state === 'pendiente'
            ? '#E6EBF9'
            : state === 'corregido'
            ? '#EAFAF6'
            : '#FCE9E8'};
    color: ${({ state }) =>
        state === 'pendiente'
            ? '#5E80DB'
            : state === 'corregido'
            ? '#1E8065'
            : '#B31D15'};
    padding: 7px 16px 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
`;

const InfoGrade = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 30px;
    color: #616161;
    font-size: 24px;
    @media (max-width: 768px) {
        > svg {
            display: none;
        }
    }
`;

const Grade = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    color: #000;
    font-weight: 900;
    > p {
        color: #616161;
        font-weight: 400;
    }
    @media (max-width: 768px) {
        font-size: 24px;
        > p {
            font-size: 24px;
        }
    }
`;
