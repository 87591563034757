import React from "react";
import WorkshopCard from "./WorkshopCard";
import Slider from "react-slick";
import styled from "styled-components";

const WorkshopsCards = (props) => {
    const { workshops } = props;

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: workshops.length < 4 ? workshops.length : 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    return (
        <Container>
            <DivSlider>
                <SliderFake {...settings}>
                    {workshops.map((workshop, index) => {
                        return <WorkshopCard key={index} workshop={workshop} />;
                    })}
                </SliderFake>
            </DivSlider>
        </Container>
    );
};

export default WorkshopsCards;

function NextArrow(props) {
    const { className, onClick } = props;
    return <DivNextArrow className={className} onClick={onClick} />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <DivPrevArrow className={className} onClick={onClick} />;
}

const Container = styled.div`
    width: 95%;
`;

const DivSlider = styled.div`
    display: flex;
    justify-content: center;
`;

const SliderFake = styled(Slider)`
    width: 80%;

    .slick-list {
        /* margin: 0 4rem; */
    }
    .slick-track {
        display: flex !important;
        align-items: stretch;
    }
    .slick-slide {
        height: inherit !important;
    }
    .slick-slide > div {
        padding: 2rem 1rem;
    }
`;

const DivNextArrow = styled.div`
    display: block;
    border-radius: 50%;
    &::before {
        font-size: 30px !important;
        line-height: 1 !important;
        opacity: 1 !important;
        color: #cd1f17 !important;
    }
`;

const DivPrevArrow = styled.div`
    display: block;
    border-radius: 50%;
    &::before {
        font-size: 30px !important;
        line-height: 1 !important;
        opacity: 1 !important;
        color: #cd1f17 !important;
    }
`;
