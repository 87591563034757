import React from 'react'
import styled, { keyframes } from 'styled-components'

const SkeletonSubject = () => {
    return (
        <Container>
            <BannerSkeleton />
            <BodySkeleton>
                <Title />
                <CardsContainer>
                    <CardSkeleton>
                        <CheckSkeleton />
                    </CardSkeleton>
                    <CardSkeleton>
                        <CheckSkeleton />
                    </CardSkeleton>
                </CardsContainer>
            </BodySkeleton>
        </Container>
    )
}

export default SkeletonSubject;

const twinkle = keyframes`
    0%{
        background-color: #e1e1e1;
    }
    50%{
        background-color: #cccccc;
    }
    100%{
        background-color: #e1e1e1;
    }
`;

const Container = styled.div`
    min-height: 100vh;
    height: 100%;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    align-items: center;
    margin-top: 4.25rem;
`

const BannerSkeleton = styled.div`
    border-radius: 20px;
    height: 300px;
    width: calc(100% - 13rem);
    animation: ${twinkle} 2s linear infinite;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const BodySkeleton = styled.div`
    border-radius: 5px;
    width: calc(100% - 13rem);
    display: flex;
    flex-direction: column;
    gap: 7.5rem;
`

const Title = styled.div`
    width: 22%;
    height: 24px;
    margin-left: 2.5rem;
    animation: ${twinkle} 2s linear infinite;
    border-radius: 5px;

    @media (width < 1650px) {
        width: 37%;
    }
`

const CardsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
    align-items: end;
    gap: 6rem;
`

const CardSkeleton = styled.div`
    position: relative;
    width: 95%;
    height: 120px;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    animation: ${twinkle} 2s linear infinite;
    border-radius: 20px;
`

const CheckSkeleton = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -5rem;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: ${twinkle} 2s linear infinite;

    @media (width < 1650px) {
        left: -3.5rem;
    }
`