import React from "react";
import styled from "styled-components";
import PublicityWithout from "../../components/templates/auth/PublicityWithout";
import AuthForm from "../../components/templates/auth/AuthForm";
import { titleHTML } from "../../helpers/title.helper";
import WhatsappChat from '../../components/shared/chat-layout/WhatsappChat'

const AuthPage = () => {
    titleHTML("Acrópolis");

    return (
        <LoginWrapper>
            <PublicityWithout />
            <AuthForm />
            <WhatsappChat />
        </LoginWrapper>
    );
};

export default AuthPage;

const LoginWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    background: linear-gradient(270.23deg, #B31D15 0.14%, #E01F14 99.79%);

    @media (width <= 1068px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
