import { Button } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import FilterAltIcon from '@mui/icons-material/FilterList';
import FilterSlider from './FilterSlider';

const MorFilter = (props) => {
    const {
        Component,
        title = 'Más filtros',
        color = '#616161',
        sentFilters
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleClickOpen = () => {
        setIsOpen(true);
        setChecked(!checked);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <ButtonFake
                onClick={handleClickOpen}
                variant="contained"
                bgcolor={color}
            >
                <FilterAltIcon />
                {/* Más filtros */}
                {title}
            </ButtonFake>
            {isOpen ? (
                <FilterSlider
                    Component={Component}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    checked={checked}
                    sentFilters={sentFilters}
                />
            ) : null}
        </>
    );
};

export default MorFilter;

const ButtonFake = styled(Button)`
    height: 40px;
    width: fit-content;
    background-color: ${(p) => (p.bgcolor ? p.bgcolor : '#616161')};
    color: #fff;
    &:hover {
        background-color: ${(p) => (p.bgcolor ? p.bgcolor : '#616161')};
    }
    svg {
        margin-right: 0.3rem;
    }
`;
