import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import SimpleLoading from "../../../common/SimpleLoading";

const MyCardReferred = (props) => {
  const { user, infoReferred } = props;

  const confirmed =
    infoReferred?.filter((item) => item.stage === "Ganadas").length || 0;

  return (
    <CardFake>
      <Header>
        <Image>
          <img src={user.foto} />
        </Image>
        <UserWrapper>
          <Name>{user.name}</Name>
          <Email>{user.personal_email}</Email>
          <Span>Legajo: {user.sis_id}</Span>
        </UserWrapper>
      </Header>
      {infoReferred === null ? (
        <SimpleLoading />
      ) : (
        <Body>
          <MiniCard>
            <span>{infoReferred.length || "0"}</span>
            <p>
              Amigos <br /> referidos
            </p>
          </MiniCard>
          <MiniCard>
            <span>{confirmed}</span>
            <p>
              Amigos <br /> confirmados
            </p>
          </MiniCard>
          <MiniCard color="#aaa">
            <span>{infoReferred?.benefits || "0"}</span>
            <p>
              Beneficios <br /> pendientes <br /> (próximamente)
            </p>
          </MiniCard>
        </Body>
      )}
    </CardFake>
  );
};

export default MyCardReferred;

const CardFake = styled(Card)`
  grid-area: userCard;
  background-color: #ffffff;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
  min-width: 338px;
  width: calc(100% - 4rem);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  max-width: 100%;
`;

const Image = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    border-radius: 100%;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const Name = styled.h3`
  font-size: 1.2rem;
  color: #222222;
`;

const Email = styled.p`
  font-size: 1rem;
  color: #616161;
  font-weight: bold;
`;

const Span = styled.span`
  color: #a8a8a8;
  font-size: 0.9rem;
  text-transform: uppercase;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  align-items: center;
`;

const MiniCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;

  span {
    color: ${(p) => (p.color ? p.color : "#000000")};
    font-weight: bold;
    font-size: 1.2rem;
  }

  p {
    color: ${(p) => (p.color ? p.color : "#000000")};
    font-size: 0.9rem;
    text-align: center;
  }
`;
