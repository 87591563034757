const URL_CMS = process.env.REACT_APP_CMS_ADEN
// const URL_CMS = "https://cms-test.aden.org"

export const getNewServices = async rol => {
    try {
        const request = await fetch(`${URL_CMS}/api/novedades-plataforma?filters[user_role][name]=${rol}`)
        const json = await request.json()
        return json.data
    } catch (error) {
        throw new Error(error)
    }
}