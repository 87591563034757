import axios from "axios";

export function getCourses(payload) {
  return axios.get(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/courses?page_size=${payload.size}&page_offset=${payload.offset}`
  );
}

export function getStudentsByCourse(payload) {
  return axios.get(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/courses/${payload.id}/students`
  );
}

export function getTeachersByCourse(payload) {
  return axios.get(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/courses/${payload.id}/teachers`
  );
}

export function enrollCourseById(payload) {
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/courses/enroll`,
    payload
  );
}

export const getDataEvaluationService = async ({
  id,
  user,
  obj,
  reso,
  assignmentId = "",
}) => {
  const assignmentIdQuery = `&id_asignatura=${assignmentId}`;
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/test/${id}?user=${user}&obj=${obj}&resource=${reso}`;

  try {
    if (assignmentId) {
      const response = await axios.get(`${URL}${assignmentIdQuery}`);
      return response.data.response_data;
    }
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return false;
  }
};

export const viewResource = async (body) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/progress/`;

  try {
    const response = await axios.post(URL, body);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

export const getCoursesServices = async (body) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/asignaturas`;

  try {
    const response = await axios.get(URL, body);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

export const getCoursesAAServices = async (repositorio_partner_id) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/academic_advisor/assignments/${repositorio_partner_id}`;
  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

export const getCourseByIdServices = async (id) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/${id}`;

  try {
    const response = await axios.get(URL);
    return (
      response.data.response_data.assignment[0] ||
      response.data.response_data.assignment
    );
  } catch (error) {
    return { error };
  }
};

export const getReviewById = async (id) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/score/${id}`;

  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

export const getInstructorsByCourse = async (id) => {
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/instructors/${id}`;

  try {
    const response = await axios.get(URL);
    return response.data.response_data;
  } catch (error) {
    return { error };
  }
};

// Actualiza el progreso del objeto aprendizaje
export async function postProgressOA(payload) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_REPO}/v1/repo_aden/progress/update/`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error update progress:", error);
    throw error;
  }
}

export function postGradeAlab(partnerId, oaId, aLabId, grade) {
  return axios.post(
    `${process.env.REACT_APP_REPO}/v1/repo_aden/ondemand/acropolis/save_grade/${partnerId}?oa_id=${oaId}&acropolislab_id=${aLabId}&grade=${grade}`
  );
}
