import axios from 'axios';

export function putLikes(payload) {
    return axios.post(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/resources/recurso/${payload.id}/${payload.type}`
    );
}

export function getLikes(payload) {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/resources/${payload.type}/${payload.id}/count_likes`
    );
}

export function getCalificacion(payload) {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/get_calificacion/${payload.type}/${payload.id}`
    );
}

export function getCountries() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/countries`
    );
}

export function getNationalities() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/nationalities`
    );
}

export function getProfessions() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/professions`
    );
}

export function getDocTypes() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/document_types`
    );
}

export function getProgramsOptions() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/academics/list`
    );
}

export function getJobsOptions() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/current_job_options`
    );
}

export function getAreasOptions() {
    return axios.get(
        `${process.env.REACT_APP_SISAPI}/v1/acropolis/miscs/area_contact_options`
    );
}

export async function sendEmail(body) {
    const URL = `${process.env.REACT_APP_REPO}/v1/acropolis/email/send`;
    try {
        const res = await axios.post(URL, body);
        return res;
    } catch (error) {
        return { error };
    }
}

export async function postFav(body) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/favorites/save/${body.repo_id}?type=${body.type}&resource_id=${body.resource}`;
    try {
        const res = await axios.post(URL);
        return res;
    } catch (error) {
        return { error };
    }
}

export async function postTakeOutFav(body) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/favorites/delete/${body.repo_id}?type=${body.type}&resource_id=${body.resource}`;
    try {
        const res = await axios.post(URL, body);
        return res;
    } catch (error) {
        return { error };
    }
}

export async function getFavsRep(id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/favorites/${id}`;
    try {
        const res = await axios.get(URL);
        return res.data.response_data.favorites;
    } catch (error) {
        return { error };
    }
}
