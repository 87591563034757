import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const SimpleLoading = (props) => {
    const { padding } = props;

    return (
        <SimpleLoadingContainer padding={padding}>
            <CircularProgress />
        </SimpleLoadingContainer>
    );
};

export default SimpleLoading;

const SimpleLoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: ${(props) => props.padding || '1rem'};
    padding-bottom: ${(props) => props.padding || '1rem'};
`;

const Spinner = styled.div`
    color: #b31d15;
`;
