import { useEffect, useState } from 'react';
import styled from 'styled-components';
import MainCampus from './components/fastAccess/MainCampus';
import { titleHTML } from '../../helpers/title.helper';
import ChallengeQuestions from './components/modals/ChallengeQuestions';
import AsideCampus from './components/easyAccess/AsideCampus';
import { useDispatch } from 'react-redux';
import { summitActions } from '../../redux/actions';
import CompleteProfile from './components/modals/CompleteProfile';
import DialogInfo from '../../modules/newInformation/DialogInfo';
import { checkRoles } from '../../helpers/roles.helper';
import { getUserSummitValidation } from '../../redux/api/summit';
import { useSelector } from 'react-redux';
import campusOrnament from '../../assets/media/campus-ornament-2.png';

const Campus = () => {
    titleHTML('Campus');

    const dispatch = useDispatch();

    const { personal_email, partner_id } = useSelector(
        (state) => state.auth.user
    );

    const [isValidated, setIsValidated] = useState(null);

    useEffect(() => {
        personal_email &&
            partner_id &&
            getSummitvalidation(1440, partner_id, personal_email);
    }, [personal_email, partner_id]);

    useEffect(() => {
        dispatch(summitActions.getSummitInscriptionRequest);
    }, []);

    const getSummitvalidation = async (eventId, partnerId, email) => {
        try {
            const response = await getUserSummitValidation(
                eventId,
                partnerId,
                email
            );
            setIsValidated(response.response_data);
        } catch (error) {
            return console.log(error);
        }
    };

    return (
        <>
            <CompleteProfile />
            <ChallengeQuestions />
            <DialogInfo />
            <HomeWrapper>
                <Image src={campusOrnament} />
                <MainCampus />
                {/* <AsideCampus /> */}
            </HomeWrapper>
        </>
    );
};

export default Campus;

const HomeWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    display: grid;
    /* grid-template-columns: 3.5fr 1fr; */
    gap: 15px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Skeleton = styled.div`
    background-color: #ededed;
    border-radius: 20px;
    height: ${(p) => p.h};
    width: ${(p) => p.w};
`;

const Gap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Image = styled.img`
    position: absolute;
    margin: auto;
    top: -31px;
    right: -32px;
    /* left: -30px; */
    width: 850px;
    /* width: calc(100% + 62px); */
    height: 600px;
    object-fit: center;
    object-position: center;
    mask-image: linear-gradient(#f9f9f9 80%);
    z-index: 0;

    @media (max-width: 768px) {
        right: -13px;
    }
`;

const SkeletonFake = () => {
    return (
        <HomeWrapper>
            <Gap>
                {/* <Skeleton w='100%' h='260px' /> */}
                <Skeleton w="100%" h="300px" />
                <Skeleton w="100%" h="300px" />
            </Gap>
            <Gap>
                <Skeleton w="100%" h="450px" />
                <Skeleton w="100%" h="450px" />
            </Gap>
        </HomeWrapper>
    );
};
