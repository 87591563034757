import React, { useEffect, useState } from "react";
import { getOrders } from "../../redux/api/faculty";

const usePurchaseOrder = (currentNameOrder, open) => {
    const [purchaseOrder, setPurchaseOrder] = useState(null);

    useEffect(() => {
        !!open &&
            purchaseOrder === null &&
            currentNameOrder !== null &&
            handlePurchaseOrder();
    }, [open, purchaseOrder, currentNameOrder]);

    useEffect(() => {
        !open && setPurchaseOrder(null);
    }, [open]);

    // FUNCTIONS
    const handlePurchaseOrder = async () => {
        try {
            const res = await getOrders(currentNameOrder);
            setPurchaseOrder(res[0]);
        } catch (error) {
            setPurchaseOrder(false);
        }
    };

    return {
        purchaseOrder,
    };
};

export default usePurchaseOrder;
