import React, { useEffect } from "react";

import { IconButton } from "@mui/material";
import { ForumRounded, Message } from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import Popover from "@mui/material/Popover";
import styled from "styled-components";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import CachedIcon from "@mui/icons-material/Cached";
import ContactsRounded from "@mui/icons-material/ContactsRounded";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import {
  deleteNotificationByIdService,
  deleteNotificationService,
  getNotificationService,
} from "../../../app/modules/Services/chat.services";
import { createChannel } from "../../../helpers/createChannelChat.helper";
import { changeChannel } from "../../../redux/chat/chatActions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import useTheme from "../../../hooks/useTheme";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(3),
  },
}));

const Messages = (props) => {
  const { messages } = props;

  const [msgs, setMsgs] = useState(false);
  const [alert, setAlert] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { primary } = useTheme();

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const sortChannel = (messages) => {
    let result = messages.sort((a, b) => a.date < b.date);
    // console.log(result);
    setMsgs(result);
  };

  useEffect(() => {
    // console.log(messages);
    if (messages) {
      sortChannel(messages);
    }
    if (messages.length == 0) {
      setAlert("No posees notificaciones");
    }
    return () => {};
  }, [messages]);

  const viewNotification = (id) => {
    const deleteNotification = async () => {
      const http = await deleteNotificationService(id);
      if (http.result.n && http.result.n > 0) {
        setMsgs([]);
      }
    };
    deleteNotification();
  };

  const viewNotificationByChannel = (to, from) => {
    const deleteNotification = async () => {
      const http = await deleteNotificationByIdService({ to, from });
      // console.log(http);
      if (http.notifications_pending && http.notifications_pending > 0) {
        setMsgs(http.notifications_pending);
      }
    };
    deleteNotification();
  };

  const selectChannel = (to, from) => {
    const hash = createChannel(to, from);
    dispatch(changeChannel(hash));
    handleClose();
    setTimeout(() => {
      if (history.location.pathname !== "/networking/chat") {
        history.push("/networking/chat");
      }
    }, 200);
  };

  const formatDate = (date) => {
    let hour = new Date(date);
    return (
      hour.getDay() +
      "/" +
      hour.getMonth() +
      " - " +
      hour.getHours() +
      ":" +
      hour.getMinutes()
    );
  };

  const reloadNotification = () => {
    setAlert("Cargando...");
    const getNotification = async () => {
      const messages = await getNotificationService(user.partner_id);
      // console.log(messages);
      setMsgs(messages.result);
      setTimeout(() => {
        setAlert("No posees notificaciones");
      }, 500);
    };
    getNotification();
  };

  const href = () => {
    history.push("/networking");
  };

  return (
    <div data-tut="reactour__messages" >
      <IconButton
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
        size="large">
        <ForumRounded htmlColor="#bfbfbf" />
        {anchorEl == null && msgs && msgs.length > 0 && (
          <Count color={primary}>{msgs.length}</Count>
        )}
      </IconButton>
      <PopoverFake
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <HeaderNotifications color={primary}>
          <Title>Chats</Title>
          <Buttons>
            <Reload onClick={() => reloadNotification()} />
            <Link onClick={handleClose} to="/networking/chat">
              <OpenChat />
            </Link>
          </Buttons>
        </HeaderNotifications>
        <BodyNotification>
          {msgs &&
            msgs.map((message, i) => {
              {
                /* console.log(message); */
              }
              return (
                <ChatConversation
                  onClick={() => {
                    viewNotificationByChannel(
                      parseInt(message.to.id, 10),
                      parseInt(message.from, 10)
                    );
                    selectChannel(message.to.id, message.from);
                  }}
                  key={i}
                >
                  <ImageContainer>
                    <Image src={message.to.image} />
                  </ImageContainer>
                  <User>
                    <Name>{message.to.name}</Name>
                    <Msg>{message.message}</Msg>
                  </User>
                  <DateMsg>
                    <CountMsg color={primary}>{message.count}</CountMsg>
                    <Hour>{formatDate(message.date)}</Hour>
                  </DateMsg>
                </ChatConversation>
              );
            })}
          {!msgs || msgs.length == 0 && (
              <NoNotiication>
                No hay mensajes nuevos en tu bandeja
                <br /> ¿Quieres iniciar un nuevo chat?
                <CustomButton
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<ContactsRounded />}
                  onClick={() => href()}
                >
                  Ir a networking
                </CustomButton>
                <CustomButton
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<Message />}
                  onClick={() => history.push("/networking/chat")}
                >
                  Chat
                </CustomButton>
              </NoNotiication>
            )}
        </BodyNotification>
        <FooterNotifications onClick={() => viewNotification(msgs[0] ? msgs[0].from : 0)}>
          <ViewNotification
            color={primary}
          >
            Marcar todo como leído
          </ViewNotification>
        </FooterNotifications>
      </PopoverFake>
    </div>
  );
};

export default Messages;

const PopoverFake = styled(Popover)`
  .MuiPaper-root {
    border-radius: 20px;
    background-color: #f9f9f9;

    @media (max-width: 768px) {
      width: 100vw;
    }
  }
`;

const Count = styled.h1`
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1rem;
  height: 1rem;
  font-size: 0.7rem;
  color: white;
  background-color: ${(p) => p.color};
  position: absolute;
  border-radius: 500px;
  top: 0.3rem;
  right: 0.3rem;
`;

const HeaderNotifications = styled.div`
  height: 2.8rem;
  width: 20rem;
  background-color: ${(p) => p.color};
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: calc(100% - 24px);
  }
`;

const FooterNotifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.06);
  height: 2.5rem;
  background-color: #ffffff;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 3.5rem;
  }
`;

const Title = styled.h6`
  color: white;
  font-size: 1.2rem;
`;

const OpenChat = styled(OpenInNewSharpIcon)`
  cursor: pointer;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
`;

const ViewNotification = styled.button`
  color: ${(p) => p.color};
  font-weight: bold;
  font-size: 0.8rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BodyNotification = styled.div`
  width: 100%;
  height: 25rem;
  overflow-y: auto;
  padding: 0.3rem;
  box-sizing: border-box;
`;

const ChatConversation = styled.button`
  width: calc(100% - 0.6rem);
  min-height: 66px;
  height: auto;
  display: grid;
  grid-template-columns: 30px 3fr 1fr;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  border-radius: 20px;
  background-color: #ffffff;
  margin: 0.5rem 0.3rem;
  padding: 0.5rem;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
`;

const User = styled.div`
  width: 100%;
  height: 100%;
  /* display: flex; */
  /* justify-content: start; */
  padding: 0.4rem 0.7rem;
  text-align: start;
`;

const Name = styled.h6`
  color: #525252;
  font-size: 1rem;
  font-weight: 600;
`;

const Msg = styled.h6`
  color: #4d4d4d;
  font-size: 0.7rem;
  width: 10rem;
  /* height: 30px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const DateMsg = styled.div`
  height: 100%;
  position: relative;
`;

const CountMsg = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: ${(p) => p.color};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.3rem;

  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
  }
`;

const Hour = styled.div`
  color: #525252;
  font-size: 0.7rem;
  font-weight: 600;
  position: absolute;
  top: 0.5rem;
  right: 0.3rem;
`;

const NoNotiication = styled.div`
  color: #222222;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  text-align: center;
`;

const CustomButton = styled(Button)`
  color: #ffffff;
  background-color: ${(p) => p.color};
  margin-top: 1rem;
  width: auto;

  &:hover,
  &:active {
    color: #ffffff;
  }
`;

const Reload = styled(CachedIcon)`
  cursor: pointer;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const Buttons = styled.div`
  display: flex;
`;
