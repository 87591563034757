import { Card } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { deleteNotificationByIdService } from '../../../../../app/modules/Services/chat.services';
import { createChannel } from '../../../../../helpers/createChannelChat.helper';
import { changeChannel } from '../../../../../redux/chat/chatActions';
import { Text } from '../../../../common/Texts';
import UserData from '../../../molecules/teacher/UserData';

const MessageTeacher = (props) => {
    const { pending = true, values, handleRefresh } = props;

    const date = new Date(values.date)
    const history = useHistory()
    const dispatch = useDispatch()

    const viewNotification = async (to, from) => {
        const http = await deleteNotificationByIdService({ to, from });
        if (http.notifications_pending && http.notifications_pending > 0) {
            handleRefresh(http.notifications_pending);
        }
    };

    const selectChannel = (to, from) => {
        const hash = createChannel(to, from);
        dispatch(changeChannel(hash));
        setTimeout(() => history.push("../../networking/chat"), 200);
    };

    return (
        <MessageWrapper onClick={() => {
            viewNotification(
                parseInt(values.to.id),
                parseInt(values.from)
            );
            selectChannel(values.to.id, values.from);
        }} title="Ir al chat" pending={!pending}>
            <Header>
                <UserData
                    img={values.to.image}
                    name={values.to.name}
                />
                <Text fontWeight="bold" fontSize="12px" color="#888888">{date.toLocaleDateString()}</Text>
            </Header>
            <Text fontSize=".8rem" fontWeight="600" color="#888888">
                {values.message}
            </Text>
        </MessageWrapper>
    );
};

export default MessageTeacher;

const MessageWrapper = styled(Card)`
    height: auto;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 6px 2px rgba(222, 222, 222, 0.75);
    -webkit-box-shadow: 0px 0px 6px 2px rgba(222, 222, 222, 0.75);
    -moz-box-shadow: 0px 0px 6px 2px rgba(222, 222, 222, 0.75);
    background-color: ${(p) => p.pending && '#f7f7f7'};
    cursor: pointer;
    :hover{
        box-shadow: 0px 0px 6px 2px rgba(155, 155, 155, 0.75);
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 0 0 0.3rem 0;
`;

const NewMessage = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fc2958;
`;
