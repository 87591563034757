import React from 'react'
import styled from 'styled-components'
import AuthFormLab from '../../components/templates/auth/AuthFormLab'
import useAlabLayout from '../../hooks/useAlabLayout'

const AlabAuth = () => {

    const [ subdomain ] = window.location.host.split(".") || [ false ]
    // const [ subdomain ] = [ "magdalena" ] || [ false ]
    const { primaryColor, secondaryColor, font, imageLeft, logoAlab, inverted, logoAlabColor } = useAlabLayout(subdomain)

    if (!subdomain) {
        return false
    }

    return (
        <LoginWrapper inverted={inverted} font={font} color={primaryColor}>
            <Banner id="banner" image={imageLeft} color={primaryColor}/>
            <AuthFormLab id="form" primaryColor={primaryColor} secondaryColor={secondaryColor} logoAlab={logoAlab} logoAlabColor={logoAlabColor}/>
        </LoginWrapper>
    )
}

export default AlabAuth

const LoginWrapper = styled.div`
    @font-face {
        font-family: 'Alab';
        src: url(${p => p.font && `data:@file/vnd.ms-opentype;base64,${p.font}`}) format("opentype");
    }
    ${p => p.font && `*
    {
        font-family: Alab !important;
    }`}
    .MuiButton-contained{
        background-color: ${p => p.color};
        :hover{
            background-color: ${p => `${p.color}cc`};
        }
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "left right";
    #banner{
        grid-area: ${p => p.inverted ? 'right' : 'left'};
    }
    #form{
        grid-area: ${p => p.inverted ? 'right' : 'left'};
    }
    width: 100%;
    height: 100vh;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const Banner = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${p => p.color};
    background-image: url(${p => p.image});
    background-size: cover;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
`

const Form = styled.div`
    width: 100%;
    height: 100%;
`