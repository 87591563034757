import React, { useContext, useState, useEffect, useRef } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import * as encode from 'nodejs-base64-encode';
import styled from 'styled-components';
import axios from 'axios';

// Components
import PageCorrectAnswers from './multipleChoice/PageCorrectAnswers';
import PageReviewAnswers from './multipleChoice/PageReviewAnswers';
import PageCalification from './multipleChoice/PageCalification';
import ModalStartExam from './multipleChoice/ModalStartExam';
import PageInitial from './multipleChoice/PageInitial';
import PageExam from './multipleChoice/PageExam';

// Redux
import { postGradeAlab } from '../../../../redux/api/courses';
import { useSelector } from 'react-redux';

// Hooks
import { useQueryParams } from '../../../../hooks/useQueryParams';

// Utils
import { optionsStyle } from '../../../../utils/snackStyles';

// Context
import CourseContext from "../../context/CourseSubjectContext"

const MultipleChoice = (props) => {
    // PROPS
    const {
        evaluationData,
        blockResourceChange,
        blockResocurce,
        objId,
        resourceId,
        idEvaluacion,
        handleNewTry,
        submitViewResource,
        statusStudentBlock = false,
        reasonBlock,
        publicationName
    } = props;

    const {
        subjectId
    } = useContext(CourseContext)

    // ID ASIGNATURA
    const params = useQueryParams();
    const idOrigin = params.get('origin');
    const ObjAId = useParams().id;

    const idOA = encode.decode(ObjAId + '', 'base64');

    // COMPONENT STATE
    const [evaluation, setEvaluation] = useState(null);
    const [qualification, setQualification] = useState(null);
    const [page, setpage] = useState(0);
    const [lastPage, setLastPage] = useState(1);
    const [exam, setExam] = useState([]);
    const [review, setReview] = useState(null)
    const [openModal, setOpenModal] = useState(false);
    const [submission, setSubmission] = useState(null);
    // REF
    const examRef = useRef();

    // HOOKS
    const [openSnackbar] = useSnackbar(optionsStyle);
    const user = useSelector((state) => state.auth.user);

    const { activeRol } = useSelector((state) => state.auth);

    const { repo_id, ondemand_id } = user;

    const gradeForOndemand =
        useSelector(
            (state) => state?.ondemand?.ondemand?.grade_for_certificate
        ) || null;

    // EFFECTS
    useEffect(() => {
        if (evaluationData !== false) {
            setEvaluation(evaluationData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaluation]);

    useEffect(() => {

        return () => {
            if (page === 1) {
                blockResourceChange(false);
                handleSendExam();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Ref
    examRef.current = {
        respuestas: exam,
        alumno: user.repo_id,
        evaluacion: evaluationData.id,
        recurso: parseInt(resourceId, 10),
        objeto_aprendizaje: parseInt(objId, 10),
        tipo: 'auto_corregible',
        asignatura: subjectId,
    };

    // FUNCTIONS
    const handleStartExam = () => {
        setOpenModal(false)
        setpage(1);
        blockResourceChange(true);
        openSnackbar(`¡Una vez iniciado la actividad, hay que finalizarla!`);
    };

    const handleDataQuestion = (data) => {
        let flag = true;
        let examInstance = exam;
        if (exam.length > 0) {
            exam.forEach((question, index) => {
                if (data.pregunta_id === question.pregunta_id) {
                    examInstance[index] = data;
                    setExam(examInstance);
                    flag = false;
                }
            });
            if (flag === true) {
                setExam([...exam, data]);
            }
        } else {
            setExam([data]);
        }
    };

    const handlePostGradeAlab = async (grade) => {
        await postGradeAlab(repo_id, idOA, ondemand_id, grade);
    };

    const handleSendExam = async () => {
        const response = await postExamService(examRef.current);
        setQualification(response);
        if (
            activeRol === 'ondemand' &&
            gradeForOndemand &&
            idOrigin === 'alab'
        ) {
            handlePostGradeAlab(response.nota);
        }
        blockResourceChange(false);
        submitViewResource();
        setpage(2);
    };

    const handleWatchResult = async () => {
        const body = {
            alumno: user.repo_id,
            evaluacion: evaluationData.id,
            recurso: parseInt(resourceId, 10),
            objeto_aprendizaje: parseInt(objId, 10),
            tipo: '',
            entrega_alumno: '',
            sub_tipo: '',
            asignatura: subjectId,
        };
        const response = await postWatchReview(body);
        setReview(response);
    };

    const handleReview = () => {
        blockResourceChange(false);
        handleWatchResult();
        setpage(3);
    };

    const handleLastPage = () => {
        setpage(page - 1);
    };

    const handleSimpleReview = (lastPage) => {
        setLastPage(lastPage);
        setpage(4);
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    // RETURNS
    if (evaluation === null) {
        return <h4>No hay ninguna evaluacion</h4>;
    }

    if (evaluation === null && evaluationData === false) {
        return <h4>Cargando</h4>;
    }

    return (
        <MultipleChoiceWrapper>
            <ModalStartExam 
                isOpen={openModal}
                handleCloseModal={() => setOpenModal(false)}
                handleClick={handleStartExam}
            />
            {page === 0 && (
                <PageInitial
                    note={evaluation.puntaje_evaluacion}
                    delivery={evaluation.submissions}
                    name={evaluation.name}
                    description={evaluation.descripcion}
                    time={evaluation.tiempo}
                    questions={evaluation.preguntas.length}
                    attempts={evaluation.cantidad_intentos}
                    isReview={review}
                    setSubmission={setSubmission}
                    submissions={evaluation.submissions}
                    handleStartExam={handleStartExam}
                    statusStudentBlock={statusStudentBlock}
                    reasonBlock={reasonBlock}
                    publicationName={publicationName}
                    handleOpenModal={handleOpenModal}
                    handleSimpleReview={handleSimpleReview}
                />
            )}
            {page === 1 && (
                <PageExam
                    handleDataQuestion={handleDataQuestion}
                    handleSendExam={handleSendExam}
                    evaluation={evaluation}
                />
            )}
            {page === 2 && (
                <PageCalification
                    qualification={qualification}
                    evaluation={evaluation}
                    objId={objId}
                    resourceId={resourceId}
                    idEvaluacion={idEvaluacion}
                    handleNewTry={handleNewTry}
                    handleReview={handleReview}
                    setSubmission={setSubmission}
                    handleSimpleReview={handleSimpleReview}
                />
            )}
            {page === 3 && (
                <PageCorrectAnswers
                    handleLastPage={handleLastPage}
                    studentRes={examRef.current}
                    evaluation={evaluation}
                    qualification={qualification}
                />
            )}
            {page === 4 && (
                <PageReviewAnswers
                    handleGoBack={() => setpage(lastPage)}
                    submission={submission}
                />
            )}
            <Prompt
                when={blockResocurce}
                message={(location) =>
                    `Se enviará la actividad como se encuantra actualmente!`
                }
            />
        </MultipleChoiceWrapper>
    );
};

export default MultipleChoice;

const postExamService = async (exam) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/corregir_test`;

    try {
        const response = await axios.post(URL, exam);

        return response.data;
    } catch (error) {
        return error;
    }
};

const postWatchReview = async (reviewData) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/test/watch_results`;

    try {
        const response = await axios.post(URL, reviewData);
        return response.data;
    } catch (error) {
        return error;
    }
};

const MultipleChoiceWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (width < 1150px) {
        max-width: 95vw;
    }

    @media (width < 768px) {
        max-width: 90vw;
    }
`;

