import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import RegisterForm from '../../ui/organisms/auth/RegisterForm';
import Text from '../../common/Text';

const Register = () => {
    return (
        <RegisterContainer>
            {/** Logo */}
            <Link to="/">
                <LogoAden
                    loading="lazy"
                    src="/assets/aden/logo-aden-simple-red.png"
                    alt="logo"
                />
            </Link>
            <FormWrapper>
                <InfoWrapper>
                    <Text
                        fontSize="1.25rem"
                        fontWeight="700"
                        color="primary"
                        component="h2"
                    >
                        Registrarme
                    </Text>
                    <Text fontSize="0.9rem" fontWeight="300" component="span">
                        Ingrese sus datos para crear su cuenta
                    </Text>
                </InfoWrapper>
                <RegisterForm />
            </FormWrapper>
            {/** Ir a login */}
            <ToLoginWrapper>
                <span>¿Ya tienes cuenta?</span>
                <CustomLink to="/auth">Ingresar</CustomLink>
            </ToLoginWrapper>
        </RegisterContainer>
    );
};

export default Register;

const RegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5% 0;
    background-color: #ffffff;
`;

const LogoAden = styled.img`
    max-width: 200px;

    @media (max-width: 1024px) {
        max-width: 180px;
    }
    @media (max-width: 768px) {
        max-width: 150px;
        margin-top: 2rem;
    }
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
`;

const InfoWrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        margin-top: 5rem;
    }
`;

const ToLoginWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-size: 1rem;

    a {
        font-weight: 700;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const CustomLink = styled(Link)`
    padding: 0.5rem 1rem;
`;
