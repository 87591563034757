import axios from "axios";

export function loginSisApi(payload) {
  return axios.post(`${process.env.REACT_APP_SISAPI}/v1/acropolis/auth/login`, {
    user: payload.user,
    password: payload.password,
  });
}

export function getUserByToken(payload) {
  const config = {
    headers: {
      Authorization: `Bearer ${payload}`,
    },
  };
  return axios.get(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/users/profile`,
    { ...config }
  );
}

export async function getPublicInfoContact(partnerId) {
  const URL = `${process.env.REACT_APP_SISAPI}`;
  try {
    const res = await axios.get(
      URL + `/v1/acropolis/users/public-contact/${partnerId}`
    );
    return res.data.response_data;
  } catch (error) {
    return { error };
  }
}
export async function getPublicInfoForEmail(email) {
  const URL = `${process.env.REACT_APP_SISAPI}`;
  try {
    const res = await axios.get(
      URL + `/v1/acropolis/users/public-email/${email}`
    );
    return res.data.response_data;
  } catch (error) {
    return { error };
  }
}

export function updateUserByToken(payload) {
  return axios.put(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/users/profile`,
    payload
  );
}

export function updateUserPhoto(photo) {
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/users/avatar`,
    photo
  );
}

export function registerSisApi(user) {
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/auth/register`,
    user
  );
}

export function registerOndemandSisApi(data) {
  // console.log("api: ", data);
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/ondemand/register/${data.ondemand_id}`,
    data
  );
}

export function registerStudent(user) {
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v1/acropolis/auth/change-password`,
    user
  );
}

export const loginRoles = (form) => {
  return axios.post(
    `${process.env.REACT_APP_SISAPI}/v2/acropolis/auth/login`,
    form
  );
};

export const userData = (rol) => {
  return axios.get(
    `${process.env.REACT_APP_SISAPI}/v2/acropolis/auth/get_user_data/${
      rol || "alumno"
    }`
  );
};

export async function setNewPassword(loginId, newPassword, changePasswordId) {
  const URL = `${process.env.REACT_APP_FA_URL}/user/change-password/${changePasswordId}`;
  const applicationId = `${process.env.REACT_APP_FA_APP_ID}`;

  const body = {
    applicationId,
    loginId,
    password: newPassword,
  };

  try {
    const response = await axios.post(URL, body, {
      headers: {
        Authorization: process.env.REACT_APP_FA_API_KEY,
      },
    });
    return response;
  } catch (error) {
    return { error };
  }
}

export function getRecoverPasswordId(loginId) {
  const URL = `${process.env.REACT_APP_FA_URL}/user/forgot-password`;
  const applicationId = `${process.env.REACT_APP_FA_APP_ID}`;

  const body = {
    applicationId,
    loginId,
    sendForgotPasswordEmail: false,
  };

  return axios.post(URL, body, {
    headers: {
      Authorization: process.env.REACT_APP_FA_API_KEY,
    },
  });
}

export async function sendRecoverEmail(email) {
  const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/auth/recover`;
  const body = {
    email,
    source_app: "acropolis"
  };

  return await axios.post(URL, body, {
    headers: {
      Authorization: process.env.REACT_APP_FA_API_KEY,
    },
  });
}

export async function changePasswordLogged(email, newPassword){
  const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/change_user_password?email=${email}&new_password=${newPassword}`
  return await axios.post(URL, {
    headers: {
      Authorization: process.env.REACT_APP_FA_API_KEY,
    },
  });
}