import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTeachersAAServices } from '../redux/api/teachersAA';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../utils/snackStyles';

const useTeachersAA = () => {
    const [teachers, setTeachers] = useState(null);
    const [initialTeachers, setInitialTeachers] = useState(null);
    const AARepositorioPartnerId = useSelector((state) => state?.auth?.user?.repositorio_data?.repositorio_id);

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!teachers) getTeachers(AARepositorioPartnerId);
    }, [teachers]);

    const getTeachers = async (id) => {
        const request = await getTeachersAAServices(id);
        if (request.error) {
            openSnackbar('¡No se pudieron cargar los profesores!');
            setTeachers(false);
            return;
        }
        setTeachers(request);
        setInitialTeachers(request);
    };

    const handleTeachers = (newTeachers) => {
        setTeachers(newTeachers);
    };

    return { initialTeachers, teachers, handleTeachers };
}

export default useTeachersAA