import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import MenuUser from "../navegation/MenuUser";
import Messages from "../navegation/Messages";
import { MenuRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { getNotificationService } from "../../../app/modules/Services/chat.services";
import UserDracmas from "../navegation/UserDracmas";
import useTheme from "../../../hooks/useTheme";
import Notifications from "../navegation/Notifications";
import { getNotificationFake } from "../../../redux/api/notifications";
import MenuOndemand from "./MenuOndemand";

const NavegationOndemand = (props) => {
    const { toggleMenu, menu, closeMenu } = props;
    // REDUX
    const { user, activeRol } = useSelector((state) => state.auth);
    const ondemand = useSelector((state) => state.ondemand.ondemand);
    const microlearnings = useSelector(
        (state) => state.microlearnings.microlearnings
    );
    const dispatch = useDispatch();

    const { secondary } = useTheme();

    // STATE
    const [messagesState, setMessagesState] = useState(false);
    const [notificationStateFake, setNotificationFake] = useState([]);

    // EFFECTS
    useEffect(() => {
        if (microlearnings === null && user) {
            getMessages();
        }
    }, [microlearnings]);

    useEffect(() => {
        if (notificationStateFake.length === 0) {
            getNotificacion();
        }
    }, []);

    // FUNCTIONS
    const getMessages = async () => {
        const notification = await getNotificationService(user.partner_id);
        if (Array.isArray(notification.result)) {
            setMessagesState(notification.result);
        }
    };

    const getNotificacion = async () => {
        const response = await getNotificationFake(activeRol);
        if (!response.error) {
            let result = response.sort((a, b) => b.id - a.id);
            setNotificationFake(result);
        } else {
            console.error(response.error);
        }
    };

    // Recargar Notificaciones
    const refreshNotification = () => {
        getNotificacion();
    };

    // RETURN
    return (
        <>
            <Header color={secondary}>
                <LogoWrapper>
                    <FakeIconButton onClick={toggleMenu} data-tut="reactour__inicio_mobile_ondemand">
                        <MenuRounded color="secondary" />
                    </FakeIconButton>
                    <Link to="/campus">
                        <FakeImg loading="lazy" src="/assets/logo-aden.svg" alt="logo" />
                    </Link>
                    {!!ondemand && <InCompany src={`${ondemand.logo_url}`} />}
                </LogoWrapper>
                <Nav>
                    {/** Dracmas */}
                    <UserDracmas />
                    {/* Notificaciones de mensajes */}

                    <Messages messages={messagesState} />

                    {/* Notificaciones */}
                    <Notifications
                        notifications={notificationStateFake}
                        refreshNotification={refreshNotification}
                    />

                    {/** Menu del usuario */}
                    <MenuUser />
                </Nav>
            </Header>
            <MenuOndemand menu={menu} closeMenu={closeMenu} />
        </>
    );
};

export default NavegationOndemand;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  position: fixed;
  right: 0;
  width: calc(100% - 115px);
  height: 70px;
  background-color: ${(p) => p.color};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 999;

  @media (max-width: 768px) {
    width: calc(100% - 30px);
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;

  @media (max-width: 425px) {
    column-gap: 0.5rem;
  }
`;

const FakeIconButton = styled(IconButton)`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const FakeImg = styled.img`
  max-height: 46px;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  column-gap: 1rem;

  @media (max-width: 425px) {
    column-gap: 0.5rem;
  }
`;

const InCompany = styled.img`
  height: 32px;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;
