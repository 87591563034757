import styled from "styled-components";

const MultipleChoiceCardHeader = (props) => {

    const { Icon, title, subtitle } = props;

    return (
        <Container>
            <span>
                {Icon}
            </span>
            <section>
                <label>{title}</label>
                <p>{subtitle}</p>
            </section>
        </Container>
    )
}

export default MultipleChoiceCardHeader;

const Container = styled.div`
    width: 150px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: .5rem;
    background: #F9F9F9;
    border: 1px solid #E5E5E5;
    border-radius: 50px;
    padding: .5rem;

    span {
        width: 32px;
        height: 32px;
        border-radius: 50px;
        background-color: var(--primary-one, #B31D15);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: white;
        }
    }

    section {
        display: flex;
        flex-direction: column;

        label {
            font-size: 12px;
            font-weight: 700;
            color: #616161;
        }

        p {
            font-size: 12px;
            font-weight: 700;
            color: #000000;
        }
    }

    @media (width < 768px) {
        width: calc(100% - 1rem);
    }
`;