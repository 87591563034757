import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getMotivations } from '../api/motivations';
import { motivationsActions } from '../actions';

function* motivationsRequest() {
    try {
        const res = yield call(getMotivations);
        yield put(
            motivationsActions.getMotivationsSuccess(res.data.response_data)
        );
    } catch (error) {
        yield put(motivationsActions.getMotivationsFail(error));
    }
}

function* motivationsWatcher() {
    yield takeEvery(types.GET_MOTIVATIONS_REQUEST, motivationsRequest);
}

export { motivationsWatcher };
