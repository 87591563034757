import React from 'react';
import styled from 'styled-components';
import { Text } from './Texts';

export default function BannerServices(props) {
    const {
        absolut = true,
        title = '¡Estamos en mantenimiento! Disculpe las molestias.'
    } = props;
    return (
        <BannerServicesWrapper absolut={absolut}>
            <Text fontSize="1rem" color="#fff">
                {title}
            </Text>
        </BannerServicesWrapper>
    );
}

const BannerServicesWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(p) => (p.absolut === true ? '80px' : '25px')};
    max-height: 80px;
    z-index: 9999;
    position: ${(p) => (p.absolut === true ? 'absolute' : 'fixed')};

    ${(p) => (p.absolut === false ? 'bottom: 0;' : 'top: 0;')}
    background-color: #b31d15;
    div {
        text-align: center;
    }
    @media screen and (max-width: 768px) {
        height: ${(p) => (p.absolut === true ? '80px' : '50px')};
    }
`;
