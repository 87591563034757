import React, { useRef, useState, useEffect } from 'react';
import { Icon } from "@iconify/react/dist/iconify.js";
import styled from 'styled-components';

// Components
import { Text } from '../../../../../components/common/Texts';
import PageFinish from './PageFinish';
import Pending from './Pending';

// Utils
import PresentationSubmission from './submission/PresentationSubmission';
import PresentationContent from './content/PresentationContent';
import PresentationHeader from './header/PresentationHeader';
import Correction from './correction/Correction';

const Presentation = (props) => {
    const {
        presentation,
        resourceId,
        objId,
        handleNewTry,
        nombre_publicacion,
        submitViewResource,
        setChangeStatus,
        viewDelivery,
        statusStudentBlock = false,
    } = props;

    // STATE
    const [page, setPage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [lastPresentation, setLastPresentation] = useState({});
    const [showHistorial, setShowHistorial] = useState(false);

    const qualificationRef = useRef(null);

    const presentationHaveSubmissions = presentation?.submissions?.length !== 0;

    const submissionsSorted = presentation?.submissions?.sort((a, b) => b?.submission_number - a?.submission_number);

    // USEEFFECT
    useEffect(() => {
        getLastPresentation();
        // presentation.tipo_entrega !== "con_entrega" && submitViewResource();

        if (viewDelivery !== null) {
            handleViewDelivery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presentation, viewDelivery]);

    // FUNCTIONS
    const handleViewDelivery = () => {
        if (presentation?.tipo_entrega === 'con_entrega') {
            if (presentationHaveSubmissions) {
                if (!viewDelivery) {
                    submitViewResource();
                }
            }
        }
    };

    const getLastPresentation = () => {
        const last = presentation?.submissions.sort(
            (a, b) => b.grade - a.grade
        )[0];
        !!last && setLastPresentation(last);
    };

    const handleOnClickScroll = () => {
        document.getElementById("info").scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <>
            <PageFinish
                isOpen={page === 1}
                title={"¡Actividad enviada!"}
                description={"Tu profesor la corregirá pronto."}
                handleCloseModal={handleNewTry}
                handleClick={handleNewTry}
            />
            <PresentationWrapper>
                <Activity>
                    <PresentationHeader
                        presentation={presentation}
                        lastPresentation={lastPresentation}
                        nombre_publicacion={nombre_publicacion}
                    />
                    {
                        presentationHaveSubmissions && (
                            <>
                                <GoToInfoButton onClick={handleOnClickScroll}>
                                    <Icon icon="ph:arrow-down-light" width="24px" height="24px" />
                                    <label>Ver consignas y espacio de entrega</label>
                                </GoToInfoButton>
                                <Qualification>
                                    <QualificationHeader>
                                        <Text
                                            ref={qualificationRef}
                                            color="#b31d15"
                                            fontSize="18px"
                                            fontWeight="700"
                                        >
                                            Calificaciones y comentarios
                                        </Text>
                                        <HistorialButton onClick={() => {setShowHistorial(!showHistorial)}}>
                                            <Icon icon="fluent:clipboard-bullet-list-ltr-16-regular" width="24px" height="24px" />
                                            <label>{showHistorial ? "Ocultar" : "Ver"} historial de entregas</label>
                                        </HistorialButton>
                                    </QualificationHeader>
                                    {presentationHaveSubmissions && (
                                        <Correction
                                            deliveries={!showHistorial ? submissionsSorted.slice(0, 1) : submissionsSorted}
                                            showHistorial={showHistorial}
                                        />
                                    )}
                                </Qualification>
                            </>
                        )
                    }
                    {
                        presentationHaveSubmissions && (
                            <InfoContainer id="info">
                                <Icon icon="octicon:info-24" width="24px" height="24px" />
                                <label>
                                    Ten en cuenta que la plataforma registrará esta actividad como completa, <b>una vez que hayas realizado la entrega.</b>
                                </label>
                            </InfoContainer>
                        )
                    }
                    <PresentationContent
                        presentation={presentation}
                    />
                    {
                        !presentationHaveSubmissions && (
                            <InfoContainer>
                                <Icon icon="octicon:info-24" width="24px" height="24px" />
                                <label>
                                    Ten en cuenta que la plataforma registrará esta actividad como completa, <b>una vez que hayas realizado la entrega.</b>
                                </label>
                            </InfoContainer>
                        )
                    }
                    {presentation?.tipo_entrega === 'con_entrega' && (
                        <PresentationSubmission
                            presentation={presentation}
                            resourceId={resourceId}
                            objId={objId}
                            submitViewResource={submitViewResource}
                            setChangeStatus={setChangeStatus}
                            statusStudentBlock={statusStudentBlock}
                            loading={loading}
                            setPage={setPage}
                            setLoading={setLoading}
                        />
                    )}
                    {
                        !presentationHaveSubmissions && (
                            <Qualification>
                                <Text
                                    ref={qualificationRef}
                                    color="#b31d15"
                                    fontSize="18px"
                                    fontWeight="700"
                                    style={{ borderBottom: '1px solid #A8A8A8', paddingBottom: '1rem', width: '100%' }}
                                >
                                    Calificaciones y comentarios
                                </Text>
                                <Pending />
                            </Qualification>
                        )
                    }
                </Activity>
            </PresentationWrapper>
        </>
    );
};

export default Presentation;

const PresentationWrapper = styled.div`
    width: 100%;
    gap: 15px;
`;

const Activity = styled.div`
    background: #ffffff;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const GoToInfoButton = styled.button`
    width: fit-content; 
    cursor: pointer;
    padding: 12px 24px 12px 16px;
    border: 1px solid #b31d15;
    border-radius: 100px;
    color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: all .3s;

    label {
        cursor: pointer;
        font-size: 15px;
    }

    :hover {
        box-shadow: 0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F;
        background-color: #b31d15;
        color: #fff;
    }
`

const Qualification = styled.div`
    width: calc(100% - 4rem);
    height: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 25px;
    border-radius: 30px;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
`;

const QualificationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const HistorialButton = styled.button`
    cursor: pointer;
    padding: 12px 24px 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    color: #616161;
    transition: all .3s;

    label {
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
    }

    :hover {
        box-shadow: 0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F;
        background-color: #f1f1f1;
    }
`

const InfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: .5rem;

    svg {
        color: #C29F43;
    }
`