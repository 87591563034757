import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getStudentAtRisk } from "../../redux/api/academic/studentsAtRisk.api";
import styled from "styled-components";
import SimpleLoading from "../../components/common/SimpleLoading";
import { Text } from "../../components/common/Texts";
import CoursesAcademic from "../../components/templates/academic/CoursesDashboard";
import MessagesTeacher from "../../components/templates/teacher/messages/MessagesTeacher";
import { randomGreeting } from "../../helpers/randomGreeting";
import { titleHTML } from "../../helpers/title.helper";
import ContactsAcademic from "../../components/templates/academic/ContactsDashboard";
import Binnacle from "../../components/common/teacher-academic/Binnacle";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../utils/snackStyles";

const AcademicDashboard = () => {
  titleHTML("Dashboard");

  const user = useSelector((state) => state.auth.user);
  const hello = useMemo(() => randomGreeting(), []);
  const [students, setStudents] = useState(null);
  const [openSnackbar] = useSnackbar(optionsStyle);

  //EFFETCS
  useEffect(() => {
    !students && getStudents();
  }, [students]);

  const getStudents = async () => {
    const request = await getStudentAtRisk();
    if (request.error) {
      openSnackbar("¡No se pudo obtener la información de alumnos en riesgo!");
      setStudents(false);
      return;
    }
    setStudents(request);
  };
  // const cursadoDemorado = students && students.filter(element => element.id_condition === 1)
  // const fueraDeTiempo = students && students.filter(element => element.id_condition === 2)
  // const bloqueo = students && students.filter(element => element.id_condition === 3)

  //RETURN
  if (students === null) {
    return (
      <Container>
        <SimpleLoading />
      </Container>
    );
  }
  return (
    <Container>
      <Messages>
        <Greeting>
          <TitleHeaderWrapper>
            {hello} &nbsp;{" "}
            <span className="user-name">
              {user.name.split(" ")[0].toLowerCase()}
            </span>
            !
          </TitleHeaderWrapper>
          <Text color="#616161" fontWeight="400" fontSize="0.875rem">
            ¡Qué alegría verte de nuevo por aquí! Échale un vistazo a tus
            pendientes y ten un excelente día.
          </Text>
        </Greeting>
        <MessagesTeacher id={user.partner_id} />
        {/* <KpiCards /> */}
      </Messages>

      <StudentsAndCourses>
        {/*<StudentsAtRiskDiv>
                    <ChipStudentsAtRisk 
                        students={cursadoDemorado}
                        title="Alumnos demorados"
                        total={cursadoDemorado.length}
                    />
                    <ChipStudentsAtRisk 
                        students={fueraDeTiempo}
                        title="Alumnos fuera de tiempo"
                        total={fueraDeTiempo.length}
                    />
                    <ChipStudentsAtRisk 
                        students={bloqueo}
                        title="Alumnos con riesgo de bloqueo"
                        total={bloqueo.length}
                    /> 
                </StudentsAtRiskDiv>
                <CoursesAcademic />*/}
      </StudentsAndCourses>

      <Binnacle student={""} from="dashboard" />
      <CoursesAcademic />
      <ContactsAcademic />
    </Container>
  );
};

export default AcademicDashboard;

const Container = styled.div`
  margin: 2rem 0;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 420px auto auto;
  grid-template-areas:
    "messages  actions actions"
    "courses   courses courses"
    "contacts contacts contacts";

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

const Messages = styled.div`
  display: grid;
  grid-area: messages;
  grid-template-rows: auto 1fr;
  row-gap: 1rem;
`;

const Greeting = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const TitleHeaderWrapper = styled.div`
  font-size: 2rem;
  line-height: 40px;
  display: inline;
  color: #222222;
  .user-name {
    font-weight: 700;
    font-size: 2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
    .user-name {
      font-size: 1.8rem;
    }
  }
`;

const StudentsAndCourses = styled.div`
  //     overflow: scroll;
  //     height: 1100px;
  //     border-radius: 2rem;
  //     padding: 2rem 1rem;

  //     &::-webkit-scrollbar-thumb {
  //         display: none;
  //     }
  //     @media (max-width: 1024px) {
  //         display: flex;
  //         flex-direction: column;
  //         padding: 0;
  //         height: auto;
  //     }
  //     @media (max-width: 768px) {
  //         height: 800px;
  //     }
`;

// const StudentsAtRiskDiv = styled.div`
//     grid-area: studentsrisk;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     gap: 1rem;
//     justify-content: center;
//     margin-bottom: 2rem ;
// `;
