import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import useSettlements from "../../../hooks/teacher/useSettlements";
import DialogGenericFG from "../../common/DialogGenericFG";
import SimpleLoading from "../../common/SimpleLoading";
import CardsPay from "../../ui/organisms/faculty/CardsPay";
import TableSettlements from "../../ui/organisms/faculty/TableSettlements";
import { Button } from "@mui/material";
import TabsComponent from "../../common/TabsComponent";
import MainPayOrder from "../../ui/organisms/faculty/MainPayOrder";
import usePayOrders from "../../../hooks/teacher/usePayOrders";

const MainSettlements = () => {
    const {
        handleSettlements,
        settlements,
        initialValues,
        renderTable,
        handleRefresh,
        faculty,
        states,
        open,
        handleClose,
        handleCancel,
    } = useSettlements();

    const { payOrders, loadingPayOrder } = usePayOrders();

    const tabs = [
        { label: "Ordenes de servicio", id: 1 },
        { label: "Ordenes de pago", id: 2 },
    ];

    const [tab, setTab] = useState(1);

    function handleTab(e, value) {
        setTab(value);
    }

    //STATES
    const [
        initialValuesWithoutCanceledOrders,
        setInitialValuesWithoutCanceledOrders,
    ] = useState(null);
    const [
        settlementsWithoutCanceledOrders,
        setSettlementsWithoutCanceledOrders,
    ] = useState(null);
    const [statesWithoutCanceledOrders, setStatesWithoutCanceledOrders] =
        useState(null);

    //EFFECT
    useEffect(() => {
        if (!!initialValues && !!settlements && !!states) {
            setInitialValuesWithoutCanceledOrders(
                initialValues?.filter((item) => item?.initialState !== "cancel")
            );
            setSettlementsWithoutCanceledOrders(
                settlements?.filter((item) => item?.initialState !== "cancel")
            );
            setStatesWithoutCanceledOrders(
                states?.filter((item) => item?.id !== "cancel")
            );
        }
    }, [initialValues, settlements, states]);

    //RETURN
    if (
        !initialValuesWithoutCanceledOrders ||
        !settlementsWithoutCanceledOrders ||
        !statesWithoutCanceledOrders
    ) {
        return <SimpleLoading />;
    }
    return (
        <MainWrapper>
            <CardsPay
                localCurrency={faculty?.localCurrency}
                dollar={faculty?.dollar}
                authorization={faculty?.draftPay}
                draftPay={faculty?.draftForPay}
            />
            <WrapperTabs>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
                <BodyTabs>
                    {tab === 1 && (
                        <TableSettlements
                            initialOrders={initialValuesWithoutCanceledOrders}
                            orders={settlementsWithoutCanceledOrders}
                            handleOrders={handleSettlements}
                            renderTable={renderTable}
                            handleRefresh={handleRefresh}
                            states={statesWithoutCanceledOrders}
                        />
                    )}
                    {tab === 2 && (
                        <MainPayOrder
                            payOrders={payOrders}
                            loadingPayOrder={loadingPayOrder}
                        />
                    )}
                </BodyTabs>
            </WrapperTabs>

            <DialogGenericFG
                message={
                    <WrapperDialog>
                        <p>Está a punto de cancelar la orden de liquidación</p>
                        <Button
                            onClick={handleCancel}
                            fullWidth
                            color="primary"
                            variant="contained"
                        >
                            Aceptar
                        </Button>
                    </WrapperDialog>
                }
                open={open}
                title="Orden de liquidación"
                handleClose={handleClose}
                customColor="#b31d15"
                titleColor="#b31d15"
                transitionType="grow"
            />
        </MainWrapper>
    );
};

export default MainSettlements;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    height: 100%;
`;

const WrapperDialog = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
`;

const WrapperTabs = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
`;

const BodyTabs = styled.div`
    width: calc(100%);
`;