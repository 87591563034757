import * as types from '../types';

const actions = {};

// SET LIKES
actions.resourceRequestLikes = (params) => {
    return {
        type: types.SET_LIKE_REQUEST,
        payload: params
    };
};

actions.resourceSuccessLikes = (likes) => {
    return {
        type: types.SET_LIKE_SUCCESS,
        payload: likes
    };
};

actions.resourceFailLikes = (error) => {
    return {
        type: types.SET_LIKE_FAIL,
        payload: error
    };
};

// GET LIKES
actions.getLikesRequest = (params) => {
    return {
        type: types.GET_LIKES_REQUEST,
        payload: params
    };
};

actions.getLikesSuccess = (likes) => {
    return {
        type: types.GET_LIKES_SUCCESS,
        payload: likes
    };
};

actions.getLikesFail = (error) => {
    return {
        type: types.GET_LIKES_FAIL,
        payload: error
    };
};

// GET CALIFICACION
actions.getCalificacionRequest = (params) => {
    return {
        type: types.GET_CALIFICACION_REQUEST,
        payload: params
    };
};

actions.getCalificacionSuccess = (calificacion) => {
    return {
        type: types.GET_CALIFICACION_SUCCESS,
        payload: calificacion
    };
};

actions.getCalificacionFail = (error) => {
    return {
        type: types.GET_CALIFICACION_FAIL,
        payload: error
    };
};

// SET LIKE LOCAL
actions.setLike = (likeType) => {
    return {
        type: types.SET_LIKE,
        payload: likeType
    };
};

actions.setDislike = (likeType) => {
    return {
        type: types.SET_DISLIKE,
        payload: likeType
    };
};

// GET COUNTRIES
actions.getCountriesRequest = () => {
    return {
        type: types.GET_COUNTRIES_REQUEST
    };
};

actions.getCountriesSuccess = (countries) => {
    return {
        type: types.GET_COUNTRIES_SUCCESS,
        payload: countries
    };
};

actions.getCountriesFail = (error) => {
    return {
        type: types.GET_COUNTRIES_FAIL,
        payload: error
    };
};

// GET NATIONALITIES
actions.getNationalitiesRequest = () => {
    return {
        type: types.GET_NATIONALITIES_REQUEST
    };
};

actions.getNationalitiesSuccess = (nationalities) => {
    return {
        type: types.GET_NATIONALITIES_SUCCESS,
        payload: nationalities
    };
};

actions.getNationalitiesFail = (error) => {
    return {
        type: types.GET_NATIONALITIES_FAIL,
        payload: error
    };
};

// GET DOCUMENT TYPES
actions.getDocTypesRequest = () => {
    return {
        type: types.GET_DOCTYPES_REQUEST
    };
};

actions.getDocTypesSuccess = (docTypes) => {
    return {
        type: types.GET_DOCTYPES_SUCCESS,
        payload: docTypes
    };
};

actions.getDocTypesFail = (error) => {
    return {
        type: types.GET_DOCTYPES_FAIL,
        payload: error
    };
};

// GET PROFESSIONS
actions.getProfessionsRequest = () => {
    return {
        type: types.GET_PROFESSIONS_REQUEST
    };
};

actions.getProfessionsSuccess = (professions) => {
    return {
        type: types.GET_PROFESSIONS_SUCCESS,
        payload: professions
    };
};

actions.getProfessionsFail = (error) => {
    return {
        type: types.GET_PROFESSIONS_FAIL,
        payload: error
    };
};

// GET PROGRAMS
actions.getProgramsOptionsRequest = () => {
    return {
        type: types.GET_PROGRAMS_OPTIONS_REQUEST
    };
};

actions.getProgramsOptionsSuccess = (programs) => {
    return {
        type: types.GET_PROGRAMS_OPTIONS_SUCCESS,
        payload: programs
    };
};

actions.getProgramsOptionsFail = (error) => {
    return {
        type: types.GET_PROGRAMS_OPTIONS_FAIL,
        payload: error
    };
};

///////////////////////////////////////Implementar

// GET JOBS
actions.getJobsOptionsRequest = () => {
    return {
        type: types.GET_JOBS_OPTIONS_REQUEST
    };
};

actions.getJobsOptionsSuccess = (jobs) => {
    return {
        type: types.GET_JOBS_OPTIONS_SUCCESS,
        payload: jobs
    };
};

actions.getJobsOptionsFail = (error) => {
    return {
        type: types.GET_JOBS_OPTIONS_FAIL,
        payload: error
    };
};

// GET AREAS
actions.getAreasOptionsRequest = () => {
    return {
        type: types.GET_AREAS_OPTIONS_REQUEST
    };
};

actions.getAreasOptionsSuccess = (areas) => {
    return {
        type: types.GET_AREAS_OPTIONS_SUCCESS,
        payload: areas
    };
};

actions.getAreasOptionsFail = (error) => {
    return {
        type: types.GET_AREAS_OPTIONS_FAIL,
        payload: error
    };
};

export { actions };
