import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import GenericProgress from "../../../common/GenericProgress";
import NoResultsFound from "../../../common/NoResultsFound";
import SimpleLoading from "../../../common/SimpleLoading";
import Document from "../../molecules/academic/Document";
import { Text } from "../../../common/Texts";
import ModalAddress from "./ModalAddress";

// Material UI
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

const Documentation = (props) => {
  const {
    handleSetStateDocumentation,
    setDegreeStatus,
    setStatusLog,
    setLastStep,
    setAddressComplete,
    addressComplete,
    setTypeDelivery,
    documents,
    setData,
    data,
    setDocumentState,
    setIndexState,
    indexState,
    sedes,
    setStatusPayments,
    setIsShowCard,
  } = props;

  // State
  const [expanded, setExpanded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  // const [documentCharger, setDocumentCharger] = useState(false);

  // Effect
  useEffect(() => {
    if (documents !== null && documents !== undefined) {
      countDocumentation(documents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  // Function
  const countDocumentation = (documents) => {
    documents.map((doc) => {
      if (doc.estado === "Pendiente") {
        handleSetStateDocumentation(false);
        return false;
      }
      handleSetStateDocumentation(true);
      return true;
    });
  };

  if (documents === null) {
    return (
      <Page>
        <SimpleLoading />
      </Page>
    );
  } else {
    // setDocumentCharger(true);
    if (documents.length === 0) {
      return (
        <NoResultsFound message="¡Ups! No se encontró ninguna documentación." />
      );
    }
  }
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = (document, i) => {
    setIndexState(i);
    setIsShowCard(!expanded);
    setStatusPayments({
      program: `${documents[i].estado_pago_programa}`,
      gt: `${documents[i].estado_pago_gt}`,
    });

    setDegreeStatus(document.estado);
    setData({ ...data, id_diploma: document.id });

    if (documents[i].destino_envio_titulo === "sede") {
      handleSede(documents[i].sede_id_envio_titulo);
    }
    if (documents[i].destino_envio_titulo === "domicilio") {
      setAddressComplete(
        `${documents[i].direccion_envio_titulo}, ${documents[i].ciudad_envio_titulo}, ${documents[i].cod_postal_envio_titulo}`
      );
    }
    setTypeDelivery(documents[i].destino_envio_titulo);
    setStatusLog(
      document.registro_cambio_estado_ids.sort((a, b) => {
        return a.estado_id - b.estado_id;
      })
    );
    setLastStep(documents[i].registro_cambio_estado_ids);
  };

  const handleSede = (value) => {
    if (sedes) {
      sedes.forEach((sede) => {
        if (sede.id === value) {
          setAddressComplete(`${sede.direccion}, ${sede.nombre}, ${sede.cod_postal}`);
        }
      });
    }
  };

  if (!expanded) {
    setStatusLog("");
  }

  return (
    <>
      {documents.map((document, i) => (
        <AccordionContainer
          expanded={expanded === `panel${i}`}
          onChange={handleChange(`panel${i}`)}
        >
          <AccordionSumm onClick={() => handleClick(document, i)}>
            <div>
              <Text fontWeight="700" color="#555555">
                {document.nombre}
              </Text>
              <Text fontSize=".8rem" fontWeight="600" color="#999999">
                {document.nombre_tipo}
              </Text>
            </div>
            <GenericProgress
              fontSize={"10px"}
              progress={document.porcentaje_documentacion}
              variant="determinate"
            />
          </AccordionSumm>
          <AccordionDetail>
            {/* {document.requerimientos.length > 0 && (
                            <TitleAcademic values={document} />
                        )} */}

            {document.requerimientos.length === 0 && (
              <Center>
                <Text fontWeight="bold" fontSize={"1.1rem"} color="#777777">
                  No requiere ninguna documentación
                </Text>
              </Center>
            )}
            <GridDocument>
              {document.requerimientos.map((req, index) => {
                return (
                  <Document key={index} data={{ ...req, id: document.id }} />
                );
              })}
            </GridDocument>
          </AccordionDetail>
        </AccordionContainer>
      ))}
      {expanded ? (
        (documents[indexState]?.domicilio_confirmado === false && documents[indexState]?.estado === "Diploma gestionado") ? (
          <ModalAddress
            modalIsOpen={modalIsOpen}
            setDocumentState={setDocumentState}
            setExpanded={setExpanded}
            documents={documents}
            indexState={indexState}
            setModalIsOpen={setModalIsOpen}
            setAddressComplete={setAddressComplete}
            addressComplete={addressComplete}
            setTypeDelivery={setTypeDelivery}
            setData={setData}
            data={data}
            sedes={sedes}
            setIsShowCard={setIsShowCard}
          />
        ) : null
      ) : null}
    </>
  );
};

export default Documentation;

const AccordionSumm = styled(AccordionSummary)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f9f9f9;
  box-shadow: none;
  border-radius: 6px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const AccordionContainer = styled(Accordion)`
  margin-bottom: 1rem;
  align-items: center;
  box-shadow: none;
  &::before {
    content: "";
    width: 0px;
    height: 0px;
  }
`;

const AccordionDetail = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
`;

const Center = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GridDocument = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
