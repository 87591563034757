import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const SetGlobalEmail = () => {
    const personal_email = useSelector((state) => state?.auth?.user?.personal_email);

    useEffect(() => {
        window.personal_email = personal_email;
    }, [personal_email]);

    return null; // This component does not render anything
};

export default SetGlobalEmail;