import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import CreateIcon from "@mui/icons-material/Create";
import { postChangeDate } from "../../../../redux/api/academic/studentsRepo";
import SimpleLoading from "../../../common/SimpleLoading";
import CheckIcon from "@mui/icons-material/Check";
import { useHistory } from "react-router-dom";

const CardSubjectRepo = (props) => {
  const { subject, repoId } = props;

  const { title, progress, id } = subject.detail;

  const history = useHistory();

  // STATES
  const [moreInfo, setMoreInfo] = useState(false);
  const [isEditDate, setIsEditDate] = useState(false);
  const [valueDate, setValueDate] = useState(subject?.expiration_date);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const handleMoreInfo = () => {
    setMoreInfo(!moreInfo);
  };

  const handleEditDate = () => {
    if (!isEditDate) {
      setIsEditDate(true);
    } else {
      handleDate();
    }
  };

  const handleDate = async () => {
    setLoadingEdit(true);
    try {
      await postChangeDate(valueDate, repoId, id);
      setIsEditDate(false);
      setLoadingEdit(false);
    } catch (error) {
      setIsEditDate(false);
      setLoadingEdit(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setValueDate(value);
  };

  const transformDate = (date) => {
    let parts = date.split("-");
    return parts[2] + "-" + parts[1] + "-" + parts[0];
  };

  return (
    <>
      <Container moreInfo={moreInfo}>
        <Wrapper>
          <LeftContent>
            <IconCheck icon="icon-park-solid:check-one" />
            <InfoNames moreInfo={moreInfo}>
              <p>ASIGNATURA</p>
              <h2>{title}</h2>
            </InfoNames>
          </LeftContent>
          <ProgressWrapper>
            <Progress progress={progress}></Progress>
            <p title={progress}>{progress}%</p>
          </ProgressWrapper>
          <ActivitiesButton
            onClick={() => {
              history.push(`../alumno-repo/asignaturas/${id}?repo=${repoId}`);
            }}
          >
            <IconCustom icon="ph:folder-open" />
          </ActivitiesButton>
          <GradeStatusWrapper>
            <State>
              <p>Estado:</p>
              <Chip state={subject?.state}>{subject?.state}</Chip>
            </State>
          </GradeStatusWrapper>
          <ArrowWrapper onClick={handleMoreInfo}>
            <IconArrow moreInfo={moreInfo} icon="ep:arrow-up-bold" />
          </ArrowWrapper>
        </Wrapper>
        <MoreInfoWrapper moreInfo={moreInfo}>
          <Note>
            <p>
              Nota final:{" "}
              {!!subject?.final_score
                ? subject?.final_score
                : "Sin calificación"}
            </p>
          </Note>
          <DatesWrapper>
            <CardDate>
              <EventAvailableIcon />
              <Col>
                <p>Inicio</p>
                <span>{transformDate(subject?.date)}</span>
              </Col>
            </CardDate>
            <Divider></Divider>
            <CardDate>
              <EventBusyIcon />
              <Col>
                <p>Fin</p>
                <span>{transformDate(subject?.expiration_date)}</span>
              </Col>
            </CardDate>
            <Divider></Divider>
            <CardDate>
              <EventBusyIcon />
              <Col isEditDate={isEditDate}>
                <p>Vencimiento</p>
                <div>
                  {!isEditDate ? (
                    <span>{transformDate(valueDate)}</span>
                  ) : (
                    <InputEdit
                      type="date"
                      onChange={handleChange}
                      onKeyUp={handleChange}
                      value={valueDate}
                      placeholder="AAAA-MM-DD"
                    />
                  )}

                  {loadingEdit ? (
                    <WrapperLoading>
                      <SimpleLoading />
                    </WrapperLoading>
                  ) : !isEditDate ? (
                    <CreateIcon onClick={handleEditDate} />
                  ) : (
                    <CheckIcon onClick={handleEditDate} />
                  )}
                </div>
              </Col>
            </CardDate>
          </DatesWrapper>
        </MoreInfoWrapper>
      </Container>
    </>
  );
};

export default CardSubjectRepo;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 4rem);
  background: #fff;
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.3s ease-in-out;
  min-height: ${(p) => (!p.moreInfo ? "67px" : "150px")};
  max-height: ${(p) => (!p.moreInfo ? "67px" : "150px")};
  box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1),
    0px 6px 14px -6px rgba(24, 39, 75, 0.12);
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;

  align-items: center;
  height: 100%;
  gap: 2rem;
`;

const LeftContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-basis: calc(30% - 1rem);
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
`;

const InfoNames = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  transition: all 0.3s ease-in-out;

  h2 {
    color: #b31d15;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: ${(p) => (p.moreInfo ? "2" : "1")};
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  p {
    font-size: 14px;
    color: #616161;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-basis: calc(36.66% - 1rem);
  align-items: center;
  width: 100%;
`;

const Progress = styled.div`
  width: 100%;
  height: 7px;
  border-radius: 50px;
  background-color: #eaeaea;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    border-radius: 50px;
    width: ${(p) => `${p.progress}%`};
    background-color: #35d0a5;
    z-index: 2;
  }
`;

const GradeStatusWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-basis: calc(18% - 0.33rem);
`;

const State = styled.div`
  display: flex;
  flex-basis: 50%;
  gap: 1rem;
  align-items: center;
  p {
    color: #616161;
    font-weight: 600;
  }
`;

const Chip = styled.div`
  width: fit-content;
  padding: 7px 16px;
  border-radius: 8px;

  background-color: ${(p) =>
    p.state === "preinscripto"
      ? "#f9f5ec"
      : p.state === "confirmado"
      ? "#E6EBF9"
      : p.state === "baja"
      ? "#FCE9E8"
      : p.state === "egresado"
      ? "#EAFAF6"
      : "#e6ebf9"};
  font-weight: 600;
  color: ${(p) =>
    p.state === "preinscripto"
      ? "#C29F43"
      : p.state === "confirmado"
      ? "#5E80DB"
      : p.state === "baja"
      ? "#B31D15"
      : p.state === "egresado"
      ? "#1E8065"
      : "#5E80DB"};
`;

const ActivitiesButton = styled.div`
  min-width: calc(40px - 20px);
  max-width: calc(40px - 20px);
  height: calc(40px - 20px);
  border-radius: 5px;
  background-color: #b31d15;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  :hover {
    background-color: #cd2118;
  }
`;

const IconCustom = styled(Icon)`
  color: white;
  font-size: 1.2rem;
`;

const ArrowWrapper = styled.div`
  flex-basis: 6%;
  text-align: end;
`;

const IconArrow = styled(Icon)`
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: ${(p) => (!p.moreInfo ? "rotate(180deg)" : "rotate(0deg)")};

  color: #000;
`;

const IconCheck = styled(Icon)`
  font-size: 2.5rem;
  color: #b31d15;
  min-width: 3rem;
  max-width: 3rem;
`;

const MoreInfoWrapper = styled.div`
  width: 100%;
  opacity: ${(p) => (!p.moreInfo ? "0" : "1")};
  display: flex;
  align-items: center;
  transition: all 0.3 ease-in-out;
  gap: 1rem;
`;

const DatesWrapper = styled.div`
  display: flex;
  flex-basis: calc(36.66% - 1rem);
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 1700px) {
    flex-basis: calc(40.66% - 1rem);
  }
  @media (max-width: 1500px) {
    flex-basis: calc(70% - 1rem);
  }
`;
const Note = styled.div`
  width: calc(100% - 3.5rem);
  padding-left: 3.5rem;
  color: #616161;
  font-size: 1.05rem;

  p {
    font-weight: bold;

    span {
      font-weight: normal;
    }
  }
  flex-basis: calc(30% - 3.5rem);
`;
const CardDate = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;

  color: #616161;

  p {
    font-size: ${(p) => (!p.isEditDate ? "1rem" : ".8rem")};
    color: ${(p) => (!p.isEditDate ? "#616161" : "#b31d15")};
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      color: ${(p) => (p.isEditDate ? "#35D0A5" : "#b31d15")};
      /* color: #b31d15; */
      cursor: pointer;
      font-size: 1.2rem;
    }
  }
`;

const Divider = styled.div`
  width: 2px;
  height: 36px;
  background-color: #616161;
`;

const InputEdit = styled.input`
  border-radius: 5px;
  border: solid 1.5px #b31d15;
  /* padding: 0.3rem; */
  height: 30px;

  ::placeholder {
    color: #e9e9e9;
  }
`;

const WrapperLoading = styled.div`
  width: 40px;
  height: 40px;
`;
