import { Button, Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
// import GenericChips from "./GenericChip";
import ForumIcon from "@mui/icons-material/Forum";
import DescriptionIcon from "@mui/icons-material/Description";
import { Text } from "../../../common/Texts";
import { Img } from "../../../common/Image";
import GenericChips from "../../../common/GenericChip";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const CourseCardCanvas = (props) => {
    const { values } = props;
    const { canvas_login } = useSelector((state) => state.auth);

    const history = useHistory();

    return (
        <CourseCardWrapper title={values.name || "Asignatura sin nombre"}>
            <ImageBanner>
                <ImgFake
                    w="100%"
                    height="100%"
                    loading="lazy"
                    src={values.image}
                />
                <Notifications>
                    <DescriptionIcon />
                    <ForumIcon />
                </Notifications>
            </ImageBanner>
            <Body>
                <GenericChips
                    title={`${values?.state?.toUpperCase() || ""}`}
                    color="#888888"
                    textColor="white"
                    padding="3px 6px"
                    fontSize=".7rem"
                />
                <Title fontWeight="600" fontSize="1.1rem">
                    {values.name || "Asignatura sin nombre"}
                </Title>
            </Body>
            <WrapperButtons>
                {!!values.repo_course_id ? (
                    <ButtonFake
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            history.push(`../profesor/curso/${values.repo_course_id}`);
                        }}
                    >
                        Ver asignatura
                    </ButtonFake>
                ) : (
                    <div></div>
                )}

                <ButtonFake
                    color="primary"
                    variant="outlined"
                    onClick={() =>
                        window.open(
                            canvas_login
                                ? `https://aden.instructure.com/courses/${values.canvas_course_id}`
                                : "https://aden.instructure.com"
                            // : "https://aden.instructure.com"
                        )
                    }
                >
                    Ir a canvas
                </ButtonFake>
            </WrapperButtons>
        </CourseCardWrapper>
    );
};

export default CourseCardCanvas;

const CourseCardWrapper = styled(Card)`
    width: 300px;
    height: 300px;
    border-radius: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    :hover {
        box-shadow: 0px 0px 5px 3px rgba(199, 195, 195, 0.73);
        -webkit-box-shadow: 0px 0px 5px 3px rgba(199, 195, 195, 0.73);
        -moz-box-shadow: 0px 0px 5px 3px rgba(199, 195, 195, 0.73);
        img {
            transform: scale(1.1);
        }
    }

    @media (max-width: 400px) {
        width: 265px;
        height: 265px;
    }
`;

const ImageBanner = styled.div`
    width: 100%;
    height: 160px;
    position: relative;
    border-bottom-left-radius: 30px;
`;

const Body = styled.div`
    height: 60px;
    background-color: white;
    position: relative;
    bottom: 20px;
    border-top-right-radius: 25px;
    padding: 15px;
    :before {
        content: "";
        width: 50px;
        height: 50px;
        position: absolute;
        top: -50px;
        left: 0;
        box-shadow: -30px 0 0px 0 white;
        border-bottom-left-radius: 25px;
    }
`;

const ImgFake = styled(Img)`
    transition: all 0.3s ease-in-out;
    position: relative;
    object-fit: cover;
`;

const Title = styled(Text)`
    margin: 20px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 1rem;
`;

const Notifications = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100px;
    height: 40px;
    position: absolute;
    right: 15px;
    bottom: 25px;
    svg {
        color: #fff;
    }
`;

const WrapperButtons = styled.div`
    display: flex;
    gap: 0.3rem;
    width: calc(100% - 2rem);
    padding: 0 1rem;
    align-items: center;
    justify-content: space-between;
`;

const ButtonFake = styled(Button)`
    padding: 0.1rem 0.2rem;
    font-size: 0.7rem !important;
`;
