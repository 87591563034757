import { useParams } from 'react-router-dom';
import styled from "styled-components";
import { useContext } from "react";

// Components
import SimpleLoading from "../../components/common/SimpleLoading";
import GenericError from "../../components/common/GenericError";
import SkeletonSubject from "../course/SkeletonSubject";
import MainSubject from "./components/MainSubject";

// Hooks
import { useQueryParams } from "../../hooks/useQueryParams";

// Helpers
import * as encode from 'nodejs-base64-encode';

// Context
import CourseSubjectContext from "./context/CourseSubjectContext";

const Subject = () => {

    const {
        data,
        dataLoading,
        dataError,
        resetData
    } = useContext(CourseSubjectContext);

    const { id } = useParams();

    const errors = [404, 500, 502];

    const params = useQueryParams();
    const origin = params.get('origin') || '';
    const program = params.get('program') || '';
    const programId = encode.decode(program, 'base64');

    if (dataLoading) {
        return <SkeletonSubject />;
    }

    if (errors.includes(data) || dataError) {
        return <GenericError reloadFn={resetData} />;
    }

    return (
        <CourseSubjectWrapper>
            {data.length !== 0 ? (
                <MainSubject
                    idCourse={id}
                    origin={origin}
                    programId={programId}
                />
            ) : (
                <SimpleLoading />
            )}
        </CourseSubjectWrapper>
    )
}

export default Subject;

const CourseSubjectWrapper = styled.div`
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
