import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CardArticleMain = () => {
  const tags = ["aviso", "recursos humanos"];

  return (
    <>
      <CardFake>
        <Header></Header>
        <Body>
          <WrapperTag>
            {tags.length > 0 && tags.map((tag) => <Chip>{tag}</Chip>)}
          </WrapperTag>
          <h2>¿Cómo ser asertivo en tu puesto de trabajo?</h2>
          <SubTitle>
            Una guía rápida de cómo mejorar tu asertividad en las tareas de tu
            día a día.
          </SubTitle>
          <Span>
            10/06/2022 | Publicado por <span>Mastercard</span>
          </Span>
        </Body>
        <DescriptionWrapper>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            pharetra velit a quam tristique, a vehicula mauris pharetra. Nulla
            facilisi. Praesent laoreet tincidunt metus at placerat. Nulla
            aliquam erat eu faucibus pretium. Aliquam lobortis lobortis iaculis.
            Suspendisse tincidunt urna in nibh scelerisque porttitor. Morbi
            libero tortor, mollis quis leo ut, consectetur fringilla nulla. Ut
            euismod tellus justo, eget ornare mauris pulvinar vel. Mauris
            tincidunt faucibus ipsum tincidunt volutpat. Nulla metus sem,
            tincidunt id sapien id, tempor posuere lacus. Proin dapibus odio
            vitae nunc suscipit pellentesque. Phasellus aliquet justo eget orci
            vulputate cursus. Suspendisse consectetur enim ut nisl rutrum
            interdum. Fusce quis nunc quis ante egestas laoreet. Nam sed neque
            ut augue pharetra sollicitudin.
            <br />
            <br />
            Maecenas fermentum arcu ex, et pulvinar dui maximus vel. Fusce vel
            viverra sem. Sed est nibh, consectetur quis lobortis et, vestibulum
            et quam. Curabitur feugiat, urna ac tempus tincidunt, nulla risus
            egestas odio, in congue ligula ex nec felis. Vestibulum ante ipsum
            primis in faucibus orci luctus et ultrices posuere cubilia curae;
            Etiam mollis, odio eget gravida accumsan, ex nisi consectetur erat,
            ac pulvinar odio lectus nec dui. Proin tincidunt sed arcu eget
            tempus. Vivamus consectetur tellus nec tincidunt rhoncus. Phasellus
            vitae cursus tortor. Nulla vehicula diam velit, a faucibus eros
            suscipit a. Morbi non fermentum turpis, quis molestie odio. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit. Donec nec finibus
            metus. Donec in venenatis metus.
            <br />
            Phasellus tempus ligula nec elit tristique tristique. Nam in tempor
            diam. Ut consectetur tempor faucibus. Vestibulum egestas orci eget
            nulla maximus dapibus. Suspendisse id augue nulla. Morbi tempor enim
            sodales nisl aliquam, sed hendrerit metus volutpat. Cras efficitur
            dui id aliquam mollis. Quisque ac pellentesque lectus, quis semper
            tellus. Aliquam non augue vitae sem molestie consequat. Nam elit
            nulla, finibus et viverra quis, dapibus luctus mi. Nullam non mauris
            eu sapien consequat pretium vitae vitae arcu. Phasellus hendrerit
            ipsum nec leo viverra placerat. Phasellus justo massa, tempor vel
            condimentum blandit, mattis in arcu. Donec a nulla felis. Mauris
            dapibus ipsum augue. Nullam erat nisl, tempor a vulputate et,
            consequat eu tellus. Fusce nibh turpis, imperdiet vel massa
            porttitor, pretium tempor mauris. Quisque at consectetur lorem.
            Vivamus tempor vel sapien eu dignissim. Suspendisse sed sapien sit
            amet odio ornare vulputate vitae sit amet augue.
          </p>
        </DescriptionWrapper>
      </CardFake>
    </>
  );
};

export default CardArticleMain;

const CardFake = styled(Card)`
  border-radius: 20px;
  width: 100%;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Header = styled.div`
  background-image: url("https://random.imagecdn.app/200/180");
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center center;
`;

const Body = styled.div`
  padding: 0.5rem 1rem;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  h2 {
    font-size: 1.5rem;
  }
`;

const WrapperTag = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Chip = styled.div`
  width: calc(min-content - 1.2rem);
  height: calc(auto - 0.6rem);
  font-weight: bold;

  padding: 0.3rem 0.6rem;
  background-color: #b31d15;
  color: #fff;
  border-radius: 5px;
`;

const SubTitle = styled.p`
  color: #757575;
  font-size: 1.1rem;
`;

const Span = styled.p`
  color: #a8a8a8;
  font-size: 0.9rem;

  span {
    font-weight: bold;
  }
`;

const DescriptionWrapper = styled.div`
  padding: 1rem;
  line-height: 140%;
`;
