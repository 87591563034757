import React from "react";
import styled from "styled-components";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import StarsComment from "./statistics/StarsComment";

const CardWrapper = (props) => {
  const { review } = props;

  return (
    <AverageBody>
      {review.map((reviews) => {
        return (
          <CardContent>
            <ImgContent>
              <img src={reviews.photo} />
            </ImgContent>
            <InfoContent>
              <h3>{reviews.name}</h3>
              <StarContent>
                <StarsComment value={reviews.rating} />
                <p>Hace 10 días</p>
              </StarContent>
              <Comment>{reviews.comment}</Comment>
              <ThumbContent>
                <ThumbUp /> <ThumbDown />
              </ThumbContent>
            </InfoContent>
          </CardContent>
        );
      })}
    </AverageBody>
  );
};

export default CardWrapper;

const AverageBody = styled.div`
  height: 240px;
  width: 95%;
  margin: 0 auto;
  border-radius: 8px;
`;
const CardContent = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  border-top: 1px solid #e5e5e5;
  padding: 0.7rem 0;
`;
const ImgContent = styled.div`
  flex-basis: calc(10% - 1rem);

  img {
    min-width: 70px;
    width: 100%;
  }
`;
const InfoContent = styled.div`
  flex-basis: calc(90% - 1rem);

  h3 {
    color: #222222;
    font-size: 1rem;
  }
`;
const StarContent = styled.div`
  margin: 0.5rem 0 0.8rem 0;
  display: flex;
  align-items: center;

  div {
    margin-right: 0.8rem;
  }
  p {
    margin: 0;
    padding: 0;
    color: #a8a8a8;
  }
`;

const Comment = styled.p`
  color: #616161;
  font-size: 1rem;
  margin-bottom: 1rem;
`;
const ThumbContent = styled.div`
  color: #bfbfbf;
  display: flex;
  justify-content: space-around;
  width: 10%;
`;
