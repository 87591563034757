import React, { useState } from "react";
import styled from "styled-components";
import Navbar from "../../components/ui/organisms/certificate/Navbar";
import LogoAden from "../../assets/media/aden/logo-aden-simple-red.png";
import { Button, TextField } from "@mui/material";
import { useEffect } from "react";
import { getInfoEvent, handleAttendance } from "../../redux/api/attendance";
import { useParams } from "react-router-dom";
import SimpleLoading from "../../modules/acropolisCommon/components/SimpleLoading";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const EventAttendace = () => {
    // HOOKS
    let { id: idEvent } = useParams();

    // STATES
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [attendanceSuccess, setAttendanceSuccess] = useState(null);
    const [dataEvent, setDataEvent] = useState(null);

    // FUNCTIONS
    const getEventInfo = async (id) => {
        try {
            const response = await getInfoEvent(id);
            setDataEvent(response);
        } catch (error) {
            setDataEvent(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await handleAttendance(idEvent, email);

            setLoading(false);
            setAttendanceSuccess(true);
        } catch (error) {
            setLoading(false);
            setAttendanceSuccess(false);
        }
    };

    //EFFECTS
    useEffect(() => {
        dataEvent === null && getEventInfo(idEvent);
    }, []);

    return (
        <Wrapper>
            <Navbar />
            <Container>
                {dataEvent === null ? (
                    <SimpleLoading />
                ) : (
                    <Card>
                        <Banner>
                            <img src={LogoAden} alt="Logo ADEN" />
                        </Banner>
                        <Header>Marcar asistencia del evento</Header>
                        <Body>
                            <Title>
                                {dataEvent?.publication_name ||
                                    "Nombre del evento no definido"}{" "}
                            </Title>
                            <Form>
                                <Legend>
                                    ingresa el mail con el que te inscribiste al
                                    evento
                                </Legend>

                                {attendanceSuccess === null ? (
                                    <>
                                        <TextField
                                            name="email"
                                            type="email"
                                            fullWidth
                                            label="E-mail"
                                            placeholder="tu email"
                                            variant="standard"
                                            value={email}
                                            disabled={!dataEvent}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                        />
                                        {loading ? (
                                            <SimpleLoading />
                                        ) : (
                                            <Button
                                                onClick={handleSubmit}
                                                variant="contained"
                                                color="primary"
                                                disabled={!email || !dataEvent}
                                            >
                                                Marcar asistencia
                                            </Button>
                                        )}
                                    </>
                                ) : !!attendanceSuccess ? (
                                    <WrapperConfirm confirmColor="#3BD39C">
                                        <DoneIcon /> Asistencia confirmada
                                    </WrapperConfirm>
                                ) : (
                                    <>
                                        <WrapperConfirm confirmColor="#F8423F">
                                            <ErrorOutlineIcon /> Error al
                                            confirmar asistencia
                                        </WrapperConfirm>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setAttendanceSuccess(null);
                                            }}
                                        >
                                            Volver a intentar
                                        </Button>
                                    </>
                                )}
                            </Form>
                        </Body>
                    </Card>
                )}
            </Container>
        </Wrapper>
    );
};

export default EventAttendace;

const Wrapper = styled.div`
    width: 100%;
    height: auto;
`;

const Container = styled.div`
    padding: 2rem 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Card = styled.div`
    width: 100%;
    max-width: 600px;
    height: auto;
    border: solid 1px #e1e1e1;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    background-color: #fff;
`;

const Banner = styled.div`
    background-color: #fff;
    width: calc(100% - 3rem);
    height: 100px;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 100%;
        object-fit: contain;
    }
`;

const Header = styled.div`
    width: calc(100% - 2rem);
    padding: 0.5rem 1rem;
    text-align: center;
    color: #fff;
    background-color: #b31d15;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
`;

const Body = styled.div`
    padding: 3rem;
    width: calc(100% - 6rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
`;

const Title = styled.h2`
    font-size: 1.4rem;
    color: #222222;
    font-weight: bold;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
`;

const Legend = styled.span`
    font-size: 0.9rem;
    color: #88bbe8;
    font-weight: bold;
    text-transform: uppercase;
`;

const WrapperConfirm = styled.div`
    width: calc(80% - 2rem);
    padding: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-weight: bold;
    color: ${(p) => p.confirmColor};
    background-color: ${(p) => `${p.confirmColor}30`};
    border: ${(p) => `solid 1px ${p.confirmColor}`};
`;
