import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as encode from 'nodejs-base64-encode';
import BarProgress from '../../../../components/common/BarProgress';
import LazyImg from '../../../../modules/acropolisCommon/components/LazyImg';

const P100Card = (props) => {
    const { value } = props;

    const hash = encode.encode(value.detail.id + '', 'base64');

    return (
        <BusinessCardContainer>
            <ImageWrapper>
                <LazyImg
                    src={
                        value.detail.image_url !== ''
                            ? value.detail.image_url
                            : 'https://www.aden.org/files/sites/9/2018/09/Workshop_Miami_magazine.jpg'
                    }
                    width="100%"
                    height="100px"
                    borderRadius="20px"
                    alt="objeto de aprendizaje"
                />
            </ImageWrapper>
            <Body>
                <BarProgress
                    value={
                        value.detail.progress > 100
                            ? 100
                            : value.detail.progress
                    }
                />
                <NombrePublicacion>
                    {value.detail.publication_name}
                </NombrePublicacion>
                <DescriptionWrapper>
                    <Description>
                        {value.detail.description !== false
                            ? value.detail.description
                            : 'No posee una descripción'}
                    </Description>
                </DescriptionWrapper>
            </Body>
            <Footer>
                <IconWrapper></IconWrapper>
                <FakeLink color="#b31d15" to={`/curso/${hash}?origin=p100`}>
                    Ir a cursar
                </FakeLink>
            </Footer>
        </BusinessCardContainer>
    );
};

export default P100Card;

const BusinessCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
    max-width: 380px;
    width: 100%;
    height: 360px;
    border-radius: 20px;
    overflow: hidden;
`;

const ImageWrapper = styled.div`
    position: relative;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
    height: 100%;
`;

const NombrePublicacion = styled.h1`
    font-size: 1rem;
    font-weight: 700;
    text-align: start;
    color: #222222;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media screen and (max-width: 996px) {
        font-size: 1.2rem;
    }
`;

const DescriptionWrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;
    height: 100px;
    overflow-y: auto;
`;

const Description = styled.span`
    font-size: 0.85rem;
    color: #999999;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #fafafa;
    margin-top: auto;
    background-color: #fafafa;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const FakeLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-weight: 700;
    background-color: ${(props) => props.color};
    color: #ffffff;

    :hover {
        background-color: #616161;
        color: #ffffff;
    }
`;
