import styled from 'styled-components';
import AboutYou from './personalInfo/AboutYou';
import PersonalData from './personalInfo/PersonalData';

const PersonalInfo = (props) => {

    const { values, setFieldValue } = props;

    return (
        <PersonalInfoContainer>
            <h2>Sobre ti</h2>
            <AboutYou />
            <h2>Datos personales</h2>
            <PersonalData values={values} setFieldValue={setFieldValue}/>
        </PersonalInfoContainer>
    )
}

export default PersonalInfo;

const PersonalInfoContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;

    h2 {
        color: #b31d15;
        font-size: 24px;
        font-weight: 700;
    }

    @media (width < 768px) {
        height: fit-content;
        overflow-y: scroll;
    }
`