import React from 'react';

// COMPONENTS
import Slider from 'react-slick';
import { ExperienceCard } from './ExperienceCard';
import { experiencias } from './ExperienceData';
import styled from 'styled-components';

//import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
//import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ExperienciasCards = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section>
            <Div>
                <SliderFake {...settings}>
                    {experiencias.map((experiencia, index) => (
                        <ExperienceCard experiencia={experiencia} key={index} />
                    ))}
                </SliderFake>
            </Div>
        </section>
    );
};

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                borderRadius: '50%'
            }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                borderRadius: '50%'
            }}
            onClick={onClick}
        />
    );
}

export default ExperienciasCards;

const Div = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const SliderFake = styled(Slider)`
    width: 80%;
    .slick-slide > div {
        padding: 4rem 2rem;
    }
`;
