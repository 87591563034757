import styled from 'styled-components'
import React from 'react'

// Components
import { Text } from '../../../../../../components/common/Texts';

// Assets
import AlertImg from '../../../../../../assets/media/aden/alert.png';


const WarningMessage = () => {
    return (
        <WarningMessageWrapper>
            <img src={AlertImg} alt="Alert" />
            <Text
                color="#222"
                fontSize="18px"
                fontWeight="700"
            >
                ¡Atención!
            </Text>
            <Text
                color="#000"
                fontSize="14px"
                fontWeight="400"
                textAlign="center"
            >
                Verifica que <b>todas las preguntas estén contestadas.</b><br />
                Después de finalizar, <b>no podrás modificar tus respuestas.</b>
            </Text>
        </WarningMessageWrapper>
    )
}

export default WarningMessage

const WarningMessageWrapper = styled.div`
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    margin: 1rem 0 0 0;
    svg{
        color: #A8A8A8;
        font-size: 2.5rem;
    }
`