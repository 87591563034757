import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ErrorResponse from '../../../common/ErrorResponse';
import SimpleLoading from '../../../common/SimpleLoading';
import { Icon } from '@iconify/react';

const OAList = (props) => {
    const { assignment, assignmentSelected, handleClick, toggleShow, show } =
        props;

    const [assignments, setAssigments] = useState([]);

    useEffect(() => {
        setAssigments(assignment);
    }, [assignment]);

    if (assignments === 'error') {
        return (
            <ListWrapper>
                <ErrorResponse message="No se encontro ninguna tarea" />
            </ListWrapper>
        );
    }

    if (!assignments) {
        return (
            <ListWrapper>
                <SimpleLoading />
            </ListWrapper>
        );
    }

    const handleItemClick = (itemId) => {
        if (assignments.length > 1) {
            setAssigments(assignments.filter((item) => item.id === itemId));
        } else {
            setAssigments(assignment);
        }
    };

    return (
        <Container>
            <ListWrapper>
                {assignments.map((assignment) => (
                    <>
                        <Item
                            key={assignment?.id}
                            active={assignmentSelected?.id === assignment?.id}
                            onClick={() => {
                                handleClick(assignment?.id);
                            }}
                        >
                            {assignment?.name || 'Asinatura sin nombre'}
                        </Item>
                        {/* <ItemMobile
                            key={assignment?.id}
                            active={assignmentSelected?.id === assignment?.id}
                            onClick={() => {
                                handleClick(assignment?.id)
                                handleItemClick(assignment?.id)
                                toggleShow()
                            }}
                            >
                                {assignment?.name || 'Asinatura sin nombre'}
                                <ArrowIcon>
                                    {
                                        show ? <Icon icon="mingcute:up-line" width="32" height="32" /> 
                                        : <Icon icon="mingcute:down-line" width="32" height="32" />
                                    }
                                </ArrowIcon>
                            </ItemMobile> */}
                    </>
                ))}
            </ListWrapper>
        </Container>
    );
};

export default OAList;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`;

const ListWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const Item = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
    border: ${(p) => (p.active ? 'solid 1px #616161' : 'solid 1px #c4c4c4')};
    border-radius: 30px;
    color: #222;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.08px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: ${(p) => (p.active ? '#f1f1f1' : '#fff')};
    box-shadow: ${(p) =>
        p.active
            ? '0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)'
            : 'none'};

    :hover {
        box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
            0px 4px 4px -2px rgba(24, 39, 75, 0.08);
        border: solid 1px #c4c4c4;
    }
`;

// const ItemMobile = styled.div`
//     display: none;
//     position: relative;
//     width: calc(100% - 72px);
//     height: 69px;
//     -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
//     -moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
//     box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
//     border-radius: 14px;
//     padding: 0 0 0 72px;
//     font-weight: 600;
//     color: #616161;
//     align-items: center;

//     @media (max-width: 768px) {
//         display: flex;
//         width: calc(100% - 32px);
//         padding: 0 16px 0 16px;
//         font-size: 16px;
//         gap: 22px;
//         justify-content: space-between;
//     }
// `;

const ArrowIcon = styled.div`
    display: none;
    color: #000;
    @media (max-width: 768px) {
        display: flex;
    }
`;
