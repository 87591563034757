import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DefaultField from '../../../common/DefaultField';
import { changePasswordLogged } from '../../../../redux/api/auth';
import { Icon } from "@iconify/react";

const OptionThree = (props) => {
    const { values, setFieldValue } = props;

    const { personal_email } = useSelector((state) => state.auth.user);

    const [error, setError] = useState(null)
    const [sending, setSending] = useState(null)

    const handleChangePassword = async () => {
        console.log('Enviado');
        if (values.new_password_modal === values.confirm_new_password) {
            if (values.confirm_new_password.length > 7) {
                try {
                    setSending(true)
                    await changePasswordLogged(personal_email, values.confirm_new_password);
                    setError(0)
                    setSending(false)
                } catch (e) {
                    console.log('Error: ', e);
                    setError(2)
                    setSending(false)
                }
            } else {
                setError(3)
            }
        } else {    
            setError(1);
        }
    }

    // RETURN
    return (
        <OverflowWrappper>
            <Container>
                <InputWrapper>
                    {/** Email principal */}
                    <span>
                        <label>Correo electrónico</label>
                        <p>{personal_email}</p>
                    </span>
                    <PasswordsWrapper>
                        <label>Cambiar contraseña</label>
                        <DefaultField
                            name="new_password_modal"
                            type="password"
                            label="Nueva contraseña"
                            value={values.new_password_modal}
                            setFieldValue={setFieldValue}
                        />
                        <DefaultField
                            name="confirm_new_password"
                            type="password"
                            label="Repetir nueva contraseña"
                            value={values.confirm_new_password}
                            setFieldValue={setFieldValue}
                        />
                    </PasswordsWrapper>
                    <ConfirmButton onClick={handleChangePassword}>
                        {
                            sending ? "Enviando..." : "Confirmar"
                        }
                    </ConfirmButton>
                    {
                        error === 0 && <StateMessage
                        backgroundColor={"#35D0A5"}
                        >
                            <Icon icon="ic:round-check" width="1.2em" height="1.2em" />
                            <text>La contraseña fué actualizada con éxito</text>
                        </StateMessage>
                    }
                    {
                        error === 1 && <StateMessage
                        backgroundColor={"#CD2118"}
                        >
                            <Icon icon="ic:round-close" width="1.2em" height="1.2em" />
                            <text>Las contraseñas no coinciden</text>
                        </StateMessage>
                    }
                    {
                        error === 2 && <StateMessage
                        backgroundColor={"#CD2118"}
                        >
                            <Icon icon="ic:round-close" width="1.2em" height="1.2em" />
                            <text>Hubo un error, inténtalo de nuevo</text>
                        </StateMessage>
                    }
                    {
                        error === 3 && <StateMessage
                        backgroundColor={"#CD2118"}
                        >
                            <Icon icon="ic:round-close" width="1.2em" height="1.2em" />
                            <text>La contraseña debe tener más de 7 dígitos</text>
                        </StateMessage>
                    }
                </InputWrapper>
            </Container>
        </OverflowWrappper>
    );
};

export default OptionThree;

const OverflowWrappper = styled.div`
    height: 100%;
    position: relative;
`;

const Container = styled.div`
    padding: 2rem 1rem 2rem 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;

const InputWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 0.5fr);
    grid-gap: 2rem;
    width: 50%;
    height: max-content;

    span {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-bottom: 1px solid #a1a1a1;
        padding-bottom: 8px;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem;
    }

    @media (max-width: 425px) {
        padding: 2rem 0;
    }
`;

const PasswordsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const ConfirmButton = styled.div`
    cursor: pointer;
    width: 110px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #b31d15;
    color: #b31d15;
    font-size: bold;

    &:hover {
        border: 1px solid #CD2118;
        box-shadow: 0px 4px 4px -2px #18274B14, 0px 2px 4px -2px #18274B1F;
        color: #CD2118;
    }
`

const StateMessage = styled.div`
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: ${(props) => props.backgroundColor};
    padding: 8px 0;
    border-radius: 5px;
    color: #fff;
`