import { useState } from "react";
import { enrollmentPause } from "../services/api";

const usePauseAssignment = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const postEnrollmentPause = async (body) => {
        setLoading(true);
        setError(null);
        try {
            await enrollmentPause(body);
            setLoading(false);
            setError(false);
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };
    return { loading, error, postEnrollmentPause };
};

export default usePauseAssignment;
