import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Redirect, Route, useLocation, useHistory } from 'react-router-dom';
import AcademicLayout from '../components/shared/academic-layout/AcademicLayout';
import { checkRoles } from '../helpers/roles.helper';
import useLayout from '../hooks/useLayout';

const AcademicRoute = (props) => {
    const {
        isAuthenticated,
        permissionRoles,
        blockedRoles,
        component: Component,
        user,
        title = false,
        ...rest
    } = props;

    // Hooks
    const history = useHistory()
    const activeRol = useSelector((state) => state.auth.activeRol);
    const { setMenu, clearMenu } = useLayout()

    if (!checkRoles(permissionRoles) || blockedRoles.includes(activeRol)) {
        history.push("../not-found")
    }

    // RETURN
    const { pathname } = useLocation();
    const urlRedirect = pathname.slice(1);

    return (
        <Route
            {...rest}
            component={(props) =>
                isAuthenticated ? (
                    <AcademicLayout>
                        <Component {...props} />
                    </AcademicLayout>
                ) : (
                    <Redirect to={urlRedirect === 'not-found' || urlRedirect === 'logout' || urlRedirect === 'cookies' ? '/auth' : `/auth?redirect=${urlRedirect}`} />
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.user
});

export default connect(mapStateToProps)(AcademicRoute);
