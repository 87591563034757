import React from "react";
import styled from "styled-components";

import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useHistory } from "react-router-dom";

const ConfirmDialog = (props) => {
    const { handleCloseBlock, openBlock } = props;

    const history = useHistory();

    return (
        <DialogCustom
            open={openBlock}
            onClose={handleCloseBlock}
            fullWidth
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CustomDialogTitle id="alert-dialog-title">
                Estimado Alumno
            </CustomDialogTitle>
            <Content>
                <p>
                    El sistema ha detectado que tu legajo presenta
                    inconvenientes con la documentación oficial de tu Maestría.
                    Por favor, actualiza tu documentación o contacta a tu asesor
                    académico, asesor de documentación o mesa de ayuda para
                    regularizar tu situación.
                </p>
            </Content>
            <CustomDialogActions>
                <ButtonCustom
                    hv="#E7355C"
                    bg="#FC2958"
                    ccolor="#fff"
                    onClick={() => history.push("/informacion-academica")}
                    variant="contained"
                >
                    Actualizar documentación
                </ButtonCustom>
                <ButtonCustom
                    onClick={() => history.push("/mi-academic-advisor")}
                    ccolor="#FC2958"
                    variant="outlined"
                >
                    Contactar Academic Advisor
                </ButtonCustom>
            </CustomDialogActions>
        </DialogCustom>
    );
};

export default ConfirmDialog;

const DialogCustom = styled(Dialog)`
    .MuiPaper-root {
        border: solid 2px #fc2958;
        border-radius: 15px;
        background-color: #fff5f7;
    }
`;

const CustomDialogActions = styled(DialogActions)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`;

const CustomDialogTitle = styled(DialogTitle)`
    text-align: center;
`;

const Content = styled.div`
    width: calc(100% - 2rem);
    margin: 0 auto;
    padding: 1rem;
    p {
        font-size: 0.9rem;
        text-align: center;
    }
`;

const ButtonCustom = styled(Button)`
    color: ${(p) => p.ccolor};
    background-color: ${(p) => p.bg};

    font-size: .8rem;

    :hover {
        background-color: ${(p) => p.hv};
    }
`;
