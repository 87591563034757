import { Card, Dialog, InputAdornment, TextField } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import React, { useState } from "react";
import styled from "styled-components";
import DefaultSelectFilter from "../../../common/DefaultSelectFilter";
import GenericTable from "../../../common/GenericTable";
import SimpleLoading from "../../../common/SimpleLoading";
import FormSettlements from "./FormSettlements";
import useSortSettlements from "../../../../hooks/useSortSettlements";
import SorterByType from "../../../common/SorterByType";
import ModalPurchaseOrder from "./ModalPurchaseOrder";
import { Icon } from "@iconify/react";
import TicketForm from "../../../templates/tickets/TicketForm";
import CardInfo from "./CardInfo";

const TableSettlements = (props) => {
    const { initialOrders, orders, handleOrders, handleRefresh, states } =
        props;

    const columns = [
        { id: "name", label: "Orden" },
        { id: "pay_order", label: "Orden de pago" },
        { id: "type_id_name", label: "Tipo" },
        { id: "ref", label: "Referencia" },
        { id: "date", label: "Fecha de carga" },
        { id: "activity_date", label: "Fecha de actividad" },
        // { id: "payment_date", label: "fecha de pago" },
        // { id: "effective_date_of_payment", label: "fecha efectiva de pago" },
        { id: "responsible", label: "Responsable" },
        { id: "state", label: "Estado" },
        { id: "amountNew", label: "Monto" },
        { id: "cancel", label: "Cancelar" },
        // { id: 'info', label: 'info' }
    ];

    const [fieldText, setFieldText] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [currentNameOrder, setCurrentNameOrder] = useState(null);
    const [openTicketModal, setOpenTicketModal] = useState(false);

    const {
        open,
        columnType,
        setColumnType,
        handleOpenList,
        handleCloseList,
        typeSorted,
        loading,
    } = useSortSettlements(orders);

    const handleOpenModal = (name) => {
        setCurrentNameOrder(name);
        setOpenModal(true);
    };

    const handleClose = () => {
        setCurrentNameOrder(null);
        setOpenModal(false);
    };

    const handleDialog = () => setOpenDialog(!openDialog);

    const handleChange = (e) => {
        const { value } = e.target;
        setFieldText(value);
        handleOrders(
            initialOrders.filter(
                (order) =>
                    order.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    order.ref
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) ||
                    order.date_order
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
            )
        );
    };

    const handleSelect = (values) => {
        handleOrders(values);
    };

    if (!orders) {
        return (
            <TableWrapper>
                <SimpleLoading />
            </TableWrapper>
        );
    }

    return (
        <TableWrapper>
            <ModalPurchaseOrder
                open={openModal}
                handleClose={handleClose}
                currentNameOrder={currentNameOrder}
            />
            <Header>
                <Filters>
                    <TextFieldFake
                        value={fieldText}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        label="Buscar"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchRounded />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Filters>
                <TicketButtonWrapper>
                    <ScheduleButton href="https://calendly.com/nbraconi" target="_blank">
                        <Icon icon="ph:calendar" width="24px" height="24px" />
                        <div>
                            <label>Agendar consulta</label>
                        </div>
                    </ScheduleButton>
                    <TicketButton onClick={() => { setOpenTicketModal(true) }}>
                        <Icon icon="ph:warning-bold" width="24px" height="24px" />
                        <div>
                            {
                                window.screen.width < 768
                                    ? <label>Reportar problema</label>
                                    : <label>Presentar una solicitud o reportar un problema</label>
                            }

                        </div>
                    </TicketButton>
                </TicketButtonWrapper>
                {
                    openTicketModal &&
                    <ModalWrapper>
                        <ModalContainer>
                            <TicketForm
                                open={openTicketModal}
                                setOpen={setOpenTicketModal}
                                // proyectID 56 es de Tesorería - Honorarios
                                proyectId={56}
                                title="Generación de ticket por solicitud o incidente"
                                subtitle="Completa el siguiente formulario para generar un ticket"
                            />
                        </ModalContainer>
                    </ModalWrapper>
                }
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDialog}
                >
                    Cargar liquidación
                </Button>
            </Header>
            <FiltersWrapper>
                <DefaultSelectFilter
                    handleFilter={handleSelect}
                    options={states}
                    arrayData={orders}
                    initialOrders={initialOrders}
                />
                <SorterByType
                    orders={orders}
                    columns={columns}
                    open={open}
                    handleOpenList={handleOpenList}
                    columnType={columnType}
                    setColumnType={setColumnType}
                    handleCloseList={handleCloseList}
                />
            </FiltersWrapper>
            <Wrapper>
                {
                    window.screen.width < 768
                        ? (
                            typeSorted.map((card) => (
                                <CardInfo 
                                    data={card}
                                />
                            ))
                        )
                        : <GenericTable
                            loading={loading}
                            pageSize={10}
                            rows={typeSorted}
                            columns={columns}
                            handleOpenModal={handleOpenModal}
                        />
                }
            </Wrapper>
            <Dialog open={openDialog} onClose={handleDialog}>
                <FormSettlements
                    handleRefresh={handleRefresh}
                    onClose={handleDialog}
                />
            </Dialog>
        </TableWrapper>
    );
};

export default TableSettlements;

const TableWrapper = styled(Card)`
    display: grid;
    /* row-gap: 10px; */
    width: calc(100% - 4rem);
    padding: 2rem;
    height: 100%;
    border-radius: 0 0 20px 20px;

    .table-generic {
        border-radius: 20px;

        .MuiTableContainer-root {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        thead tr th {
            color: #555555;
            font-weight: 700;
        }
    }
`;

const TextFieldFake = styled(TextField)`
    width: 350px;
    background-color: #f9f9f9;

    /* Comentado lo puse porque el cdn que hicieron fer y facu rompió los estilos de los inputs */
    input {
        border: none;
        background-color: transparent;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;
    }
`;

const FiltersWrapper = styled.div`
    width: 82%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Wrapper = styled.div`
    width: 100%;

    @media (max-width: 768px) {
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
`;

const Filters = styled.div`
    display: flex;
    gap: 12px;

    @media (width < 768px) {
        display: none;
    }
`;

const TicketButtonWrapper = styled.div`
    width: calc(100% - 4rem);
    display: flex;
    justify-content: end;
    gap: 2rem;
    padding: 0 2rem;

    @media (width < 768px) {
        width: 100%;
    }
`

const TicketButton = styled.button`
    cursor: pointer;
    width: fit-content;
    height: 40px;
    background-color: #fff;
    color: #CD2118;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0 1rem;
    border: 2px solid #CD2118;

    &:hover {
        box-shadow: none;
        color: #b31d15;
        border: 2px solid #b31d15;
        box-shadow: 0px 8px 8px -4px #18274B0A, 0px 4px 6px -4px #18274B0A;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 4px;

        label {
            cursor: pointer;
            color: #b31d15;
            font-weight: bold;

            @media (width < 768px) {
                white-space: nowrap;
            }
        }
    }

    @media (width < 768px) {
        width: calc(50%);
        padding: 0;
    }
`
const ScheduleButton = styled.a`
    cursor: pointer;
    width: fit-content;
    height: 36px;
    background-color: #fff;
    color: #CD2118;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0 1rem;
    border: 2px solid #CD2118;

    &:hover {
        box-shadow: none;
        color: #b31d15;
        border: 2px solid #b31d15;
        box-shadow: 0px 8px 8px -4px #18274B0A, 0px 4px 6px -4px #18274B0A;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 4px;

        label {
            cursor: pointer;
            color: #b31d15;
            font-weight: bold;
            font-size: 14px;

            @media (width < 768px) {
                font-size: 12px;
                white-space: nowrap;
            }
        }
    }

    @media (width < 768px) {
        width: calc(49%);
        padding: 0;
    }
`

const Button = styled.button`
    width: fit-content;
    height: 40px;
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #cd2118;
    color: #fff;
    white-space: nowrap;
    font-weight: 700;
    cursor: pointer;

    :hover {
        background-color: #b13d15;
        box-shadow: 0px 8px 8px -4px #18274B0A, 0px 4px 6px -4px #18274B0A;
    }

    @media (width < 768px) {
        width: 100%;
        padding: 12px 0;
    }
`

const ModalWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000080;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalContainer = styled.div`
    height: 85vh;
    width: 40vw;
    background-color: #fff;
    padding: 2rem;
    border-radius: 20px;
    overflow-y: auto;
    position: relative;
    scrollbar-color: transparent transparent;

    @media (width < 768px) {
        width: 80vw;
    }
`
