import React from "react";
import styled from "styled-components";
import {
  Collapse,
  ListItemButton,
  List,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const SorterByType = (props) => {
  const {
    open,
    columns,
    columnType,
    setColumnType,
    handleOpenList,
    handleCloseList,
  } = props;

  return (
    <Wrapper>
      <FakeList>
        <TextWrapper>
          <ListItemText primary="Ordenar por:" />
        </TextWrapper>
        <FakeListItemButton onClick={handleOpenList}>
          {columnType}
          {open ? <ExpandLess /> : <ExpandMore />}
        </FakeListItemButton>
        <FakeCollapse in={open}>
          {columns.map(
            (item, i) =>
              item.label !== "cancelar" && (
                <TypeListItemButton
                  columnType={columnType}
                  onClick={() => {
                    setColumnType(item.label);
                    handleCloseList();
                  }}
                >
                  {item.label}
                </TypeListItemButton>
              )
          )}
        </FakeCollapse>
      </FakeList>
    </Wrapper>
  );
};

export default SorterByType;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media (width < 768px) {
    display: none;
  }
`;

const FakeList = styled(List)`
  display: flex;
  padding-bottom: 20px;
  padding: 0;
`;

const TextWrapper = styled.div`
  color: #999999;
`;

const FakeListItemButton = styled(ListItemButton)`
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding: 6px;
  width: 250px;
  left: 110%;
  top: -12%;
  height: 40px;
  color: #b31d15;
  border-radius: 4px;
  font-weight: 600;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
`;

const FakeCollapse = styled(Collapse)`
  display: block;
  position: absolute;
  width: 250px;
  left: 110%;
  top: 100%;
  z-index: 10;
  color: #a9a9a9;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
    0px 8px 8px -4px rgba(24, 39, 75, 0.08);
`;

const TypeListItemButton = styled(ListItemButton)`
  /* &:nth-child(${(p) => p.key}n + 2) {
    color: blue;
    color: ${(p) => (p.itemColor === p.columnType ? "#000000" : "#A8A8A8")};
  } */
`;
