import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";

// Material UI
import { Button, Dialog } from "@mui/material";

const Attachments = (props) => {
    const { values } = props;

    const handleDonwload = () => window.open(values.file_correction.file);

    const [openModalVideo, setOpenModalVideo] = useState(false)
    const [openModalAudio, setOpenModalAudio] = useState(false)

    return (
        <AttachmentsWrapper>
            {values?.file_correction?.file && (
                <Click onClick={handleDonwload}>
                    <Icon icon="ic:round-attach-file" width="24px" height="24px" />
                    <label>Archivo</label>
                </Click>
            )}
            {values?.audio_correction?.file && (
                <Click onClick={() => setOpenModalAudio(true)}>
                    <Icon icon="f7:mic" width="24px" height="24px" />
                    <label>Nota de voz</label>
                </Click>
            )}
            {
                <FakeDialog open={openModalAudio}>
                    <ContentVideo>
                        <audio
                            style={{ position: "relative", top: "-8px" }}
                            controls
                        >
                            <source
                                src={values?.audio_correction?.file}
                            ></source>
                        </audio>
                        <ModalButton variant="outlined" onClick={() => setOpenModalAudio(false)}>
                            Salir
                        </ModalButton>
                    </ContentVideo>
                </FakeDialog>
            }
            {values?.video_correction?.file && (
                <Click onClick={() => setOpenModalVideo(true)}>
                    <Icon icon="basil:video-outline" width="24px" height="24px" />
                    <label>Ver video</label>
                </Click>
            )}
            {
                <FakeDialog open={openModalVideo}>
                    <ContentVideo>
                        <video
                            width="720"
                            className="video-player"
                            controls
                            autoplay
                            src={values?.video_correction?.file}
                        ></video>
                        <ModalButton variant="outlined" onClick={() => setOpenModalVideo(false)}>
                            Salir
                        </ModalButton>
                    </ContentVideo>
                </FakeDialog>
            }
        </AttachmentsWrapper>
    );
};

export default Attachments;

const Click = styled.button`
    max-height: 44px;
    cursor: pointer;
    padding: 12px 24px 12px 16px;
    transition: all 0.3s ease-in-out;
    border: 1px solid #B31D15;
    border-radius: 100px;
    color: #B31D15;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;

    label {
        cursor: pointer;
    }

    :hover {
        color: #fff;
        background-color: #B31D15;
    }
`;

const AttachmentsWrapper = styled.div`
    width: fit-content;
    display: flex;
    gap: 18px;

    @media (max-width: 768px) {
        flex-direction: column
    }
`;

const FakeDialog = styled(Dialog)`
    & > div {
        max-width: 100vw;
    }
    & > div:nth-child(3) {
        width: 100vw;
        max-height: 100%;

        & > div {
            max-width: fit-content;
            border-radius: 20px;
        }
    }

    .camera {
        width: calc(100% - 4rem);
    }
`

const ContentVideo = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    border-radius: 20px;
`;

const ModalButton = styled(Button)`
    min-width: 100px;
    border-radius: 100px;
`