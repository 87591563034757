import { useState, useEffect } from "react";
import { getNotesCourse } from "../../redux/api/notesCourse/notesCourseApi";
import { useSelector } from "react-redux";

const useGetNotesCourse = (idResource) => {
    const { repo_id } = useSelector((state) => state.auth.user);

    // STATES
    // GET NOTES
    const [LoadingGetNotes, setLoadingGetNotes] = useState(false);
    const [resourceId, setResourceId] = useState(idResource);
    const [errorGetNotes, setErrorGetNotes] = useState(null);
    const [valuesNotes, setValuesNotes] = useState(null);

    // EFFECTS
    useEffect(() => {
        (valuesNotes === null || resourceId !== idResource) && getNotes();
        // eslint-disable-next-line
    }, [valuesNotes, idResource]);

    // FUNCTIONS
    // TRAER NOTAS
    const getNotes = async () => {
        setLoadingGetNotes(true);
        setErrorGetNotes(null);
        setResourceId(idResource);

        try {
            const res = await getNotesCourse(idResource, repo_id);

            setValuesNotes(res);
            setLoadingGetNotes(false);
            setErrorGetNotes(false);
        } catch (error) {
            setValuesNotes(false);
            setErrorGetNotes(true);
            setLoadingGetNotes(false);
        }
    };

    return {
        valuesNotes,
        errorGetNotes,
        LoadingGetNotes,
        getNotes,
    };
};

export default useGetNotesCourse;
