import * as types from "../types/summit_types";
import { call, put, takeEvery } from "redux-saga/effects";
import { getUserSummitInscription } from "../api/summit";
import { summitActions } from "../actions";

const summit_id = 2308;
function* summitRegistrationRequest() {

  try {
    const request = yield call(getUserSummitInscription, summit_id);
    yield put(summitActions.getSummitInscriptionSucess(request.data.response_data));
  } catch (error) {
    yield put(summitActions.getSummitInscriptionFail(error));
  }
}

function* summitWatcher() {
  yield takeEvery(
    types.GET_SUMMIT_REGISTRATION_REQUEST,
    summitRegistrationRequest
  );
}

export { summitWatcher };
