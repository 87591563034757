import React, { useState } from 'react';
import styled from 'styled-components';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import TabsComponent from '../../../../common/TabsComponent';
// import { profileSchema } from "../../../../utils/schemas"; Agregar cuando se haya finalizado el completado de perfil
import { authActions } from '../../../../../redux/actions';
import OptionThree from '../../../organisms/profile/OptionThree';
import OptionFour from '../../../organisms/profile/OptionFour';
import Documentation from "../../../../templates/profile/teacher/Documentation";
import { postDocumentService, postProfileService } from '../../../../../redux/api/teacher';
import AboutInformation from '../../../../templates/profile/teacher/AboutInformation';
import EducationalInformation from '../../../../templates/profile/teacher/EducationalInformation';

const tabs = [
    { label: 'Información personal', id: 0 },
    { label: 'Información educacional', id: 1 },
    { label: 'Documentación', id: 2 },
    { label: 'Intereses y motivaciones', id: 3 }
];

const BodyModalEditProfileTeacher = (props) => {

    // REDUX
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const loading = useSelector((state) => state.auth.loading);
    const [loadingState, setLoadingState] = useState(false);

    const [file, setFile] = useState(null);
    const [cv, setCv] = useState(null);

    // STATE
    const initialValues = {
        // OptionOne
        name: user.name,
        biography: user.biography,
        document_type: user.document_type,
        document_number: user.document_number,
        birthday: user.birthday,
        nationality: user.nationality,
        country: user.country,
        city: user.city,
        gender: user.gender,
        profile_is_public: user.profile_is_public,
        zip: user.zip,
        street: user.street,
        // OptionTwo
        profession: user.profession,
        company: user.company,
        current_job: user.current_job,
        job_area: user.job_area,
        work_email: user.work_email,
        work_phone: user.work_phone,
        work_experience: user.work_experience,
        academic_training: user.academic_training,
        // OptionThree
        // personal_email: user.personal_email,
        phone: user.phone,
        mobile: user.mobile,
        whatsapp: user.whatsapp,
        facebook: user.facebook,
        instagram: user.instagram,
        twitter: user.twitter,
        skype: user.skype,
        linkedin: user.linkedin,
        // OptionFour
        motivaciones: user.motivaciones,
        intereses: user.intereses,
        // Repo profesor documentacion
        repo_document_type: null,
        repo_institution: null,
        repo_year: user?.repositorio_data?.info_educativa?.anos_en_industria,
        repo_description: null,
        repo_file_doc: null,
        // Repo profesor informacion personal
        repo_street: user?.repositorio_data?.info_personal?.street,
        repo_mobile: user?.repositorio_data?.info_personal?.mobile,
        repo_country: user?.repositorio_data?.info_personal?.nacionalidad,
        repo_phone: user?.repositorio_data?.info_personal?.phone,
        repo_type_document: user?.repositorio_data?.info_personal?.l10n_latam_identification_type_id,
        repo_document: user?.repositorio_data?.info_personal?.vat,
        repo_personal_email: user?.repositorio_data?.info_personal?.email,
        repo_email: user?.repositorio_data?.info_personal?.email_laboral,
        repo_birthday: user?.repositorio_data?.info_personal?.fecha_nacimiento,
        repo_vto_passport: user?.repositorio_data?.info_personal?.vencimiento_pasaporte,
        repo_gender: user?.repositorio_data?.info_personal?.genero,
        // Repo profesor informacion educativa
        repo_title: user?.repositorio_data?.info_educativa?.titulo_universitario,
        repo_max_title: user?.repositorio_data?.info_educativa?.titulo_maximo,
        repo_university: user?.repositorio_data?.info_educativa?.universidad,
        repo_calification: user?.repositorio_data?.info_educativa?.max_calificacion_academica_id
    };

    // STATE
    const [currentTab, setCurrentTab] = useState(0);

    // FUNCTIONS
    function changeTab(e, value) {
        setCurrentTab(value);
    }

    function handleSubmit(values) {
        if (currentTab === 0) {
            //console.log("Guardar info personal");
            const data = {
                email: values.repo_personal_email,
                email_laboral: values.repo_email,
                fecha_nacimiento: values.repo_birthday,
                genero: values.repo_gender,
                l10n_latam_identification_type_id: values.repo_type_document,
                mobile: values.repo_mobile,
                nacionalidad: values.repo_country,
                phone: values.repo_phone,
                street: values.repo_street,
                vat: values.repo_document,
                vencimiento_pasaporte: values.repo_vto_passport
            }
            if (!!cv) {
                data.cv = cv
            }
            saveInfoPersonal(user.repo_id, data)
        }
        else if (currentTab === 1) {
            const data = {
                anos_en_industria: values.repo_year,
                titulo_maximo: values.repo_max_title,
                max_calificacion_academica_id: values.repo_calification,
                titulo_universitario: values.repo_title,
                universidad: values.repo_university
            }
            saveInfoAcademic(user.repo_id, data)
        }
        else if (currentTab === 2) {
            const newDocument = !!file && file.split("base64,")
            const data = {
                tipo_documento: values.repo_document_type,
                descripcion: values.repo_description,
                institucion: values.repo_institution,
                anio_realizacion: values.repo_year + "",
                documento: newDocument[1]
            }
            saveDocumentation(user.repo_id, data, values)
        }
        else {
            dispatch(
                authActions.updateUserRequest({
                    ...values,
                    phone: values.phone.length > 4 ? values.phone : '',
                    mobile: values.mobile.length > 4 ? values.mobile : ''
                })
            );
        }
    }

    const handleB64File = file => {
        setFile(file)
    }

    const handleCV = file => {
        setCv(file.split("base64,")[1])
    }

    const saveDocumentation = async (id, body, values) => {
        setLoadingState(true)
        const response = await postDocumentService(id, body)
        if (response.error) return
        else {
            dispatch(authActions.updateUser({
                ...user,
                repositorio_data: {
                    ...user.repositorio_data,
                    documentacion: [...user.repositorio_data.documentacion, {
                        anio_realizacion: body.anio_realizacion,
                        tipo: body.tipo_documento,
                        titulo: body.descripcion,
                        institucion: body.institucion,
                        id_tipo_documentacion: body.tipo_documento
                    }]
                }
            }))
        }
        setLoadingState(false)
    }

    const saveInfoPersonal = async (id, body) => {
        setLoadingState(true)
        const response = await postProfileService(id, body)
        if (response.error) return
        else {
            dispatch(authActions.updateUser({
                ...user,
                repositorio_data: {
                    ...user.repositorio_data,
                    info_personal: {
                        ...user.repositorio_data.info_personal,
                        ...body
                    }
                }
            }))
        }
        setLoadingState(false)
    }

    const saveInfoAcademic = async (id, body) => {
        setLoadingState(true)
        const response = await postProfileService(id, body)
        if (response.error) return
        else {
            dispatch(authActions.updateUser({
                ...user,
                repositorio_data: {
                    ...user.repositorio_data,
                    info_educativa: {
                        ...user.repositorio_data.info_educativa,
                        ...body
                    }
                }
            }))
        }
        setLoadingState(false)
    }

    const renderTab = (tab, values, setFieldValue) => {
        const cases = {
            0: <AboutInformation values={values} setFieldValue={setFieldValue} handleCV={handleCV} />,
            1: <EducationalInformation values={values} setFieldValue={setFieldValue} />,
            2: <Documentation values={values} setFieldValue={setFieldValue} handleB64File={handleB64File} />,
            3: <OptionFour values={values} setFieldValue={setFieldValue} />
        }

        return cases[tab] || cases[0]
    }

    // RETURN
    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => (
                <>
                    <Container>
                        {/** Seleccionar opcion */}
                        <TabsComponent
                            tab={currentTab}
                            handleTab={changeTab}
                            tabs={tabs}
                        />
                        {/** Opciones */}
                        {renderTab(currentTab, values, setFieldValue)}
                        {/** Boton submit */}
                        <ButtonWrapper>
                            {loading || loadingState ? (
                                <ProgressWrapper>
                                    <CircularProgress color="primary" size={32} />
                                </ProgressWrapper>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                    disabled={
                                        currentTab === 2 && (
                                            !file ||
                                            !values.repo_description ||
                                            !values.repo_year ||
                                            !values.repo_institution ||
                                            !values.repo_document_type
                                        ) ? true : false
                                    }
                                >
                                    Guardar cambios
                                </Button>
                            )}
                        </ButtonWrapper>
                    </Container>

                </>
            )}
        </Formik>
    );
};

export default BodyModalEditProfileTeacher;

const Container = styled(Form)`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 2rem;
    height: 100%;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    /* width: max-content; */
    margin-top: auto;
    margin-left: auto;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ProgressWrapper = styled.div`
    padding: 0.25rem 6rem;
`;
