import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import { optionsStyle } from '../../../utils/snackStyles';
import { getEvaluation } from '../services/repositorio';

const useActivity = (idResource, idOa = 1000) => {

    const [presentation, setPresentation] = useState(false)
    const { repo_id } = useSelector(state => state.auth.user)
    const [openSnackbar] = useSnackbar(optionsStyle);
    const { id } = useParams()

    useEffect(() => {
        !presentation && getPresentation()
    }, [])

    const getPresentation = async () => {
        try {
            const evaluation = await getEvaluation(id, idResource, repo_id, idOa)
            setPresentation(evaluation)
        } catch (error) {
            openSnackbar('¡No se pudo obtener la información de esta evaluación!');
        }
    }


    return [presentation]
}

export default useActivity