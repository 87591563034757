import styled from 'styled-components';

// Components
import { Text } from '../../../../../../../components/common/Texts';
import { Icon } from '@iconify/react';

// Material UI
import { Modal } from '@mui/material';

// Assets
import CuriosityPeople from '../../../../../../../assets/media/aden/curiosity-people.png';

const ModalBlocked = (props) => {
    const { isOpen, title, description, handleCloseModal, handleClick } = props;

    return (
        <>
            <Modal
                onClose={handleCloseModal}
                open={isOpen}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.001)' }}
            >
                <Wrapper>
                    <CloseButton onClick={handleCloseModal}>
                        <Icon
                            icon="iconoir:cancel"
                            width="24px"
                            height="24px"
                        />
                    </CloseButton>
                    <img src={CuriosityPeople} alt="personas" />
                    <Text fontSize="24px" fontWeight={700} color="#B31D15">
                        {title}
                    </Text>
                    <Text
                        fontSize="16px"
                        fontWeight={500}
                        color="#222222"
                        textAlign="center"
                    >
                        {description}
                    </Text>
                    <ButtonGoTo onClick={handleClick}>
                        Contactar a mi Academic Advisor
                    </ButtonGoTo>
                </Wrapper>
            </Modal>
        </>
    );
};

export default ModalBlocked;

const Wrapper = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-radius: 20px;
    width: fit-content;
    max-width: 90vw;
    height: fit-content;
    max-height: 90vh;
    padding: 1rem 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    overflow-y: auto;

    @media (max-width: 768px) {
        width: 320px;
        height: auto;
        padding: 2rem 1rem;
    }

    img {
        width: 300px;
        height: 300px;
    }
`;

const ButtonGoTo = styled.button`
    cursor: pointer;
    height: 40px;
    padding: 0 24px;
    border-radius: 8px;
    background-color: #b31d15;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
`;

const CloseButton = styled.button`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #000;
`;
