import styled from "styled-components";
import parse from "html-react-parser";
import React from "react";

// Material UI
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

// Hooks
import useTheme from "../../../../../../hooks/useTheme";
import { Icon } from "@iconify/react/dist/iconify.js";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ModalDescription = (props) => {
	const { open, setOpen, description } = props;
	const { primary } = useTheme();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const sanitizerHtml = (html) => {
		const string = 'src="/';
		const regex = new RegExp(string, "g");
		return html.replace(regex, 'src="');
	};

	return (
		<div>
			<ButtonSeeDescription
				onClick={handleClickOpen}
			>
				<Icon icon="fa6-solid:file-circle-check" width="24px" height="24px" />
				<label>Ver consignas</label>
			</ButtonSeeDescription>
			<Dialog
				fullScreen
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitleFake color={primary}>{"Descripción"}</DialogTitleFake>
				<DialogContent>
					<DescriptionInitial>
						<ParseHtml>{parse(sanitizerHtml(description))}</ParseHtml>
					</DescriptionInitial>
				</DialogContent>
				<DialogActions>
					<ButtonFake color="primary" variant="outlined" onClick={handleClose}>
						Cerrar
					</ButtonFake>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ModalDescription;

const ButtonSeeDescription = styled.button`
    height: 40px;
    cursor: pointer;
    padding: 8px 20px 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    color: #fff;
    background-color: #cd2118;

    label {
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
    }

    :hover {
    	background-color: #b31d15;
    }
`;

const DialogTitleFake = styled(DialogTitle)`
	color: ${(p) => p.color};
	text-align: center;
	position: relative;
	text-transform: uppercase;
`;

const DescriptionInitial = styled.span`
  font-size: 0.7rem;
  color: #8a8a8a;
  font-weight: 700;
  margin: 0.8rem;
`;
const ParseHtml = styled.div`
  width: 70%;
  max-width: auto;
  margin: 0 auto;
  * {
    transform: scale(1.3);
  }
`;

const ButtonFake = styled(Button)`
  position: absolute;
  top: 15px;
  right: 15px;
`;
