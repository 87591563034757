import axios from 'axios';

export const postDeleteNote = async (id) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/delete_resource_note/${id}`;

    try {
        const result = await axios.post(URL);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getSubjectId = async (partnerId, OAId) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/subject/${partnerId}/${OAId}`;

    try {
        const result = await axios.get(URL);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const postLastEntry = async (repoId, subjectId, id) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/progress/update/course/`;

    const body = {
        partner_id: parseInt(repoId),
        subject_id: parseInt(subjectId),
        course_id: parseInt(id),
    };

    try {
        await axios.patch(URL, body);
    } catch (error) {
        throw new Error(error);
    }
};
