import React, { useEffect } from 'react'
import styled from 'styled-components';
import useOneStudent360 from '../../hooks/teacher/useOneStudent360';
import StudentCardForTeacher from '../../components/common/teacher-academic/StudentCardForTeacher';
import AccordionRight from '../../components/templates/teacher/persons/student/AccordionRight';
import SimpleLoading from '../../components/common/SimpleLoading';
import ErrorResponse from '../../components/common/ErrorResponse';
import useLayout from '../../hooks/useLayout';


const OneStudentPage = () => {
    const { publicProfileData,
        generalInfo,
        coursesStudent,
        programStudent,
        academicTraining,
        professionalInfo,
        moreInfo } = useOneStudent360();

    const { setMenu, clearMenu } = useLayout()

    useEffect(() => {
        publicProfileData && setMenu(publicProfileData.name)
    }, [publicProfileData])


    //RETURN 
    if (
        generalInfo === null ||
        publicProfileData === null
    ) {
        return <SimpleLoading />;
    }

    if (
        generalInfo === false ||
        publicProfileData === false
    ) {
        setMenu("Error")
        return (
            <>
                <Container>
                    <ErrorResponse />;
                </Container>
            </>
        )
    }

    return (
        <>
            <Container>
                <StudentInfo>
                    <StudentCardForTeacher
                        generalInfo={generalInfo}
                        publicProfileData={publicProfileData}
                        academicTraining={academicTraining}
                        professionalInfo={professionalInfo}
                    />
                    <AccordionRight
                        coursesStudent={coursesStudent}
                        publicProfileData={publicProfileData}
                        programStudent={programStudent}
                        academicTraining={academicTraining}
                        professionalInfo={professionalInfo}
                        moreInfo={moreInfo}
                    />
                </StudentInfo>
            </Container>
        </>

    )
}

export default OneStudentPage;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    align-items: center;

    @media (max-width: 1200px) {
        gap: 1rem;
    }
`;


const StudentInfo = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 3fr;
    gap: 2rem;
    /* overflow: hidden; */

    @media (max-width: 1440px) {
        grid-template-columns: 1fr 2fr;
    }

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
    }
`; 
