import { useRef, useEffect, useState } from "react";
import styled from "styled-components";

// Components
import AchievementsSlider from "./formationAndExperience/AchievementsSlider";
import EducationSlider from "./formationAndExperience/EducationSlider";
import TextToEdit from "./formationAndExperience/TextToEdit";

const FormationAndExperience = () => {

    const divRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
        if (divRef.current) {
            const divWidth = divRef.current.getBoundingClientRect().width;
            setContainerWidth(divWidth);
        }
    }, []);

    return (
        <Container ref={divRef}>
            <h4>Educación en ADEN</h4>
            <EducationSlider width={containerWidth} />
            <h4>Logros</h4>
            <AchievementsSlider width={containerWidth} />
            <h4>Educación en otras instituciones</h4>
            <TextToEdit property={{ formatted: "academicTraining", notFormatted: "academic_training"}} />
            <h4>Experiencia laboral</h4>
            <TextToEdit property={{ formatted: "workExperience", notFormatted: "work_experience"}} />
        </Container>
    )
}

export default FormationAndExperience;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`