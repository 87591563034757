import React, { useState } from 'react';
import styled from 'styled-components';

import HeaderAside from '../../molecules/chat/HeaderAside';
import BodyAside from '../../molecules/chat/BodyAside';

const AsideChat = (props) => {
    // Props
    const { channels, aside, setAside } = props;
    // State Component
    const [searchChannel, setSearchChannel] = useState('');

    // Function
    const handleFilterChannel = (channel) => {
        setSearchChannel(channel);
    };

    const handleButton = () => {
        setAside(!aside);
    };

    return (
        <>
            <GridLayout aside={aside}>
                <HeaderAside
                    channels={channels}
                    handleFilterChannel={handleFilterChannel}
                    aside={aside}
                    handleButton={handleButton}
                />
                <BodyAside
                    searchChannel={searchChannel}
                    aside={aside}
                    channels={channels}
                    handleButton={handleButton}
                />
            </GridLayout>
        </>
    );
};

export default AsideChat;

const GridLayout = styled.div`
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    border-radius: 20px;
    z-index: 9;
    padding: 0 1.5rem;
    box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.25),
        0px 12px 24px -15px rgba(0, 0, 0, 0.1);
`;
