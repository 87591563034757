import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MainBlog from "../../components/blog/MainBlog";
import { titleHTML } from "../../helpers/title.helper";
import useLayout from "../../hooks/useLayout";

const Blog = () => {
    titleHTML("Blog acrópolis");

    const { setMenu, clearMenu } = useLayout()

    const titleAcropolis =
        useSelector((state) => state?.ondemand?.ondemand?.acropolis_title) ||
        "";

    useEffect(() => {
        setMenu(`Blog ${titleAcropolis}`)
    }, [])

    return (
        <Container>
            <MainBlog />
        </Container>
    );
};

export default Blog;

const Container = styled.div`
    
`;