import React from 'react';
import GradeBook from '../../teacher/qualifications/GradeBook';
// import GradeBook from '../qualifications/GradeBook';
// import PresentationFeatured from '../qualifications/PresentationFeatured';
const Qualifications = (props) => {

    const { id } = props
    
    return (
        <div>
            <GradeBook viewAcademic={false} id={id} />
            {/* <PresentationFeatured /> */}
        </div>
    );
};

export default Qualifications;