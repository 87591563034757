import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';
import LinkToCalifications from './LinkToCalifications';
import ErrorResponse from '../../../../common/ErrorResponse';

const TableHistoryGradeBook = (props) => {
    const { id_assignment, gradesData } = props;

    if (!gradesData) {
        return <ErrorResponse />;
    }

    return (
        <>
            <Wrapper>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRowHeader>
                            <TableCell>Fecha de Entrega</TableCell>
                            <TableCell>Fecha de Correción</TableCell>
                            <TableCell align="left">Estudiante</TableCell>
                            <TableCell align="left">Profesor</TableCell>
                            <TableCell align="left">Actividad</TableCell>
                            <TableCell align="left">
                                Número de entrega
                            </TableCell>
                            <TableCell align="left">Nota</TableCell>
                        </TableRowHeader>
                    </TableHead>
                    <TableBody>
                        {gradesData.map((row) => (
                            <TableRowBody key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.presentationDate
                                        ? row.presentationDate
                                        : row.type === 'auto_corregible' &&
                                          row.qualificationDate}
                                </TableCell>
                                <TableCell align="left">
                                    {row.qualificationDate}
                                </TableCell>
                                <TableCell align="left">
                                    {row.student}
                                </TableCell>
                                <TableCell align="left">
                                    {row.type === 'auto_corregible'
                                        ? 'Autocorregible'
                                        : row.teacher ?? ''}
                                </TableCell>
                                <TableCell align="left">
                                    {row.activity}
                                </TableCell>
                                <TableCell align="center">
                                    {row.numberSubmission}
                                </TableCell>
                                <TableCell align="left">
                                    {row.type === 'auto_corregible' ? (
                                        <CellQualification>
                                            <p>
                                                {row.qualification}/
                                                {row.maxScore}
                                            </p>
                                            {/* <p>{row.state_submission || ''}</p> */}
                                        </CellQualification>
                                    ) : row.stateSubmission === 'pendiente' ? (
                                        <CellQualification>
                                            <p>Pendiente de corrección</p>
                                        </CellQualification>
                                    ) : (
                                        row.stateSubmission === 'corregido' &&
                                        row.type !== 'auto_corregible' && (
                                            <CellQualification>
                                                <p>
                                                    {row.qualification}/
                                                    {row.maxScore}
                                                </p>
                                                <p>
                                                    <LinkToCalifications
                                                        id_activity={row.idSubmission}
                                                        id_assignment={id_assignment}
                                                    />
                                                </p>
                                            </CellQualification>
                                        )
                                    )}
                                </TableCell>
                            </TableRowBody>
                        ))}
                    </TableBody>
                </Table>
            </Wrapper>
        </>
    );
};

export default TableHistoryGradeBook;

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    padding: 1rem 0;
`;

const TableRowBody = styled(TableRow)`
    th,
    td {
        border-bottom: 1px solid #616161;
        font-family: Lato;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
    }
`;

const TableRowHeader = styled(TableRow)`
    thead,
    tr,
    th {
        border: none !important;
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #222222;
    }
`;

const CellQualification = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
`;
